import React from 'react'

export default function Footer() {
    return (
        <div style={{ marginTop: 100, width: '100%', position: 'absolute', bottom: 0 }}>
            <footer className="u-align-center u-clearfix u-custom-color-3 u-footer u-valign-middle-sm u-footer footer-fixed" id="sec-daf7" style={{ backgroundColor: '#001A30' }}>
                {/* <p className="u-small-text u-text u-text-variant u-text-1" style={{ fontSize: 12 }}>Copyright © Genpact 2022. All Rights
                    Reserved.&nbsp;</p> */}
                {/* <div className="u-expanded-height-sm u-expanded-height-xs u-shape u-shape-svg u-text-custom-color-4 u-shape-1">
                    <svg className="u-svg-link" preserveAspectRatio="none" viewBox="0 0 160 160" style={{ marginTop: 3 }}>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-04f6" />
                    </svg>
                    <svg className="u-svg-content" id="svg-04f6">
                        <path d="M160,160H0V0L160,160z" />
                    </svg>
                </div> */}
                {/* <div className="u-expanded-height-sm u-flip-horizontal u-shape u-shape-svg u-text-custom-color-7 u-shape-2">
                    <svg className="u-svg-link" preserveAspectRatio="none" viewBox="0 0 160 160" style={{ marginTop: 3 }}>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-181f" />
                    </svg>
                    <svg className="u-svg-content" id="svg-181f">
                        <path d="M160,160H0V0L160,160z" />
                    </svg>
                </div> */}
            </footer>
        </div>

    )
}
