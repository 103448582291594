import React, { useState, useEffect, } from 'react';
import { useLocation } from 'react-router-dom';
import { GET_IDEA_URL, POWER_APP_URL, ACCESS_TOKEN } from '../assets/constants/constants';
import Toast from 'light-toast';
let jsonData = []

function TreeNode({ node, onToggle }) {

    const hasChildren = jsonData.some(item => item.ParentRecID === node.RecID);
    const isExpanded = node.expanded;

    const HostName =  window.location.hostname.trim();
    let IdeaRedirectLink = "";
    if(HostName === "xcelerateuat.genpact.com")
    {
        IdeaRedirectLink = "https://xcelerateuat.genpact.com/login"
    }
    else if (HostName === "ldtideapp.genpact.com")
    {
        IdeaRedirectLink = "https://ldtideapp.genpact.com/login"
    }
    else if (HostName === "localhost")
    {
        IdeaRedirectLink = "https://xcelerateuat.genpact.com/login"
    }
    else{
        // No action to be performed for localhost
    }
    let redirectURLParentdNode = "";
    let redirectURLChildNode = "";
    if(node.RecAlpha){
        if(node.RecAlpha.startsWith("L-"))
        {
            redirectURLParentdNode = IdeaRedirectLink;
        }
        else {
            redirectURLParentdNode = POWER_APP_URL
        }
    }

    {hasChildren && isExpanded && (
        jsonData.filter(item => item.ParentRecID === node.RecID)
        .map(childNode => {
            if(childNode.RecAlpha.startsWith("L-"))
            {
                redirectURLChildNode = IdeaRedirectLink;
            }
            else {
                redirectURLChildNode = POWER_APP_URL
            }
        } )
    )}
    


    return (
        <div className="hierarchy_block">
            <div onClick={() => onToggle(node)} className="hierarchy_parent_node">
                {hasChildren && (
                    <span style={{ marginRight: '5px' }}>
                        {isExpanded ? <img src={require("../assets/images/cluster_non_expanded.png")} alt="" className="" width={12} height={12}  />  : <img src={require("../assets/images/cluster_expanded.png")} alt="" className="" width={12} height={12}  />}  
                    </span>
                )}
                <a target="_blank" href={redirectURLParentdNode}>{node.RecAlpha}</a>
            </div>
            {hasChildren && isExpanded && (
                <div className="hierarchy_child_node">
                    {jsonData
                        .filter(item => item.ParentRecID === node.RecID)
                        .map(childNode => (
                            <>
                                <a href={redirectURLChildNode} target="_blank"></a>
                                <TreeNode node={childNode} onToggle={onToggle} />
                            </>
                        ))}
                </div>
            )}
        </div>
    );
}

function ShowTree(props) {
    const location = useLocation();
    const ID = new URLSearchParams(location.search).get('ID');
    
    const [data, setData] = useState([]);
    const [accessToken] = useState(localStorage.getItem(ACCESS_TOKEN))
    
    const [alpha,setAlpha]=useState("")
    const [ideaItem,setIdeaItem]=useState([])
    const [clusterID,setClusterID]=useState("")
    
    useEffect(() => {
        const fetchIdea = async () => {
            Toast.loading("Fetching cluster data...", () => { });
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization":  "Bearer " + accessToken,
                    }
                };
                const response = await fetch(`${GET_IDEA_URL}IdeaId=${ID}`, requestOptions);
                const data = await response.json();
                console.log("fetch data from show tree component=>", data)
                if(data && data.ResultSets && data.ResultSets.Table1){
                    let localDataItem = data.ResultSets.Table1[0];
                    setIdeaItem(localDataItem)
                }
                if (data && data.ClusterData && data.ClusterData.Table1) {
                    let localClusterData = data.ClusterData.Table1
                    setData(localClusterData)
                    jsonData = localClusterData
                }
                if(data && data.ResultSets && data.ResultSets.Table1)
                {
                    let localAlphaID = data.ResultSets.Table1[0].IdeaIDAlpha;
                    setAlpha(localAlphaID)
                }
                if(data && data.ClusterData && data.ClusterData.Table1 && data.ResultSets && data.ResultSets.Table1){
                    let localClusterData = data.ClusterData.Table1;
                    if(localClusterData){
                        //const targetRecID = data.ResultSets.Table1[0].LeanID;
                        // const filteredData = localClusterData.filter(item => item.RecAlpha === targetRecID);
                        // if(filteredData && filteredData.length > 0 && filteredData !== undefined && filteredData !== null){
                        //     let abc = filteredData[0].ParentAlpha;
                        //     console.log("abc",abc)
                        //     setClusterID(abc)
                        // }

                        localClusterData.map((item)=>{
                            if(item.Lvl === 0){
                                console.log("level 0 item",item)
                                setClusterID(item.RecAlpha)
                            }
                        })
                    }
                   
                }
                Toast.hide();
            } catch (error) {
                console.log(error);
                Toast.fail("Error while fetching cluster data. Please try again!", 3000, () => { });
            }
        };
        fetchIdea()
    }, [])


    const toggleNode = (node) => {
        node.expanded = !node.expanded;
        setData([...data]);
    };
    return (
        <div>

            <section className="u-clearfix u-section-1" id="sec-0f7f">
                <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                    <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                        <div className="u-container-layout u-container-layout-1">
                            <h4 className="cluster_hierarchy_header">Cluster Tree Hierarchy {alpha}</h4>
                            <div className="" style={{paddingRight:"50px"}}>
                                <div className="show_hierarchy_header">
                                    <div className="row" >
                                        <div className="col-md-4">
                                            <div className="show_hierarchy_individual_block">
                                                <p>Cluster ID: {clusterID}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="show_hierarchy_individual_block">
                                                <p>Account: {ideaItem.AccountManual}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="show_hierarchy_individual_block">
                                                <p>Vertical: {ideaItem.VerticalManual}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="display_cluster_parent_ID">  
                                {/* <p className="">{clusterID}</p> */}
                            </div>
                            
                            
                            <div className="">
                                {data
                                .filter(item => item.Lvl === 0)
                                .map(node => (
                                    <TreeNode key={node.RecID} node={node} onToggle={toggleNode} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            
        </div>
    );
}

export default ShowTree;
