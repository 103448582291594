import React, { Component } from "react";
import {
  GET_IDEA_URL,
  REVIEW_IDEA_URL,
  APPROVED,
  PENDING,
  SENT_BACK,
  REJECTED,
  OTHER_REGIONS,
  DISPLAYPROJECTTHEME,
  GET_METADATA,
  DATA_BRIDGE_CAMPAIGN,
  X_POLLINATION_CAMPAIGN,
  ACCESS_TOKEN,
  USER_DATA,
  REQUEST_CANCELLATION,
  YESORNO,SOLUTIONCOMPLEXITY,AIFIRST,AI_DOC_LINK,
} from "../assets/constants/constants";
import Toast from "light-toast";
import Select from "react-select";
// import DatePicker from "react-datepicker";
import {
  selectValidation as selectStyle,
  textBoxValidation,
} from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";
import UpdatedSpeech from "./UpdatedSpeech";
import ValueDelivery from "../utils/ValueDelivery";

export class E360SPOCApprovalPage extends Component {
  sessionData = JSON.parse(localStorage.getItem(USER_DATA));
  accessToken = localStorage.getItem(ACCESS_TOKEN);
  constructor(props) {
    super(props);
    console.log(props);
    const isApprovalPage = `${this.props.location.approval}`;
    let propsData = this.props.location;
    console.log(propsData);
    this.state = {
      ideaItem: {},
      workflowStatus: "",
      Comment: "",
      accountName: "",
      campaignName: "",
      campaignSelectedOption: null,
      serviceLineSelectedOption: null,
      subServiceLineSelectedOption: null,
      showAdditionalDetails: false,
      serviceLineList: [],
      subServiceLineList: [],
      workFlowStatusList: [],
      workFlowStatusSelectedOption: null,
      certificationLevelsList: [],
      certLevelsSelectedOption: null,
      certificationWorkTypeList: [],
      certWorkTypeSelectedOption: null,
      workTypeEnabled: false,
      digitalProjectEnabled: false,
      transformationLeversList: [],
      transSelectedOption: null,
      methodologyList: [],
      methodologySelectedOptions: null,
      databridgeThemeList: [],
      databridgeThemeSelectedOptions: null,
      dataBridgeProjectTheme: null,
      digitalProjectTypeList: [],
      digitalProjectTypeSelectedOptions: null,
      subCampaignList: [],
      subCampaignName: null,
      subCampaignOption: null,
      expectedDate: new Date(),
      leadNameList: [],
      blackbeltOHRList: [],
      LDTLeadSelectedOption: null,
      selectStyle: {},
      showBIForm: false,
      businessImpactMasterList: null,
      businessImpactValueArray: [],
      fteTotal: 0,
      dollarTotal: 0.0,
      dataBridgeCampaign: false,
      xPollinationCampaign: false,
      replicationIDValidated: true,
      approvalPage: isApprovalPage,
      campaignList: [],
      campaignTypeText: "",
      mbbSelectedOption: null,
      mbbList: [],
      mbbName: "",
      mbbOHR: "",
      propsData: propsData,
      cancelRequest: true,
      showDigital: false,
      cancelRequestOption: [
        {
          WorkflowStatusId: 1,
          WorkflowStatusName: "Approve Cancellation",
        },
        {
          WorkflowStatusId: 2,
          WorkflowStatusName: "Discard Cancellation",
        },
      ],
      Role: "BB",
      speechModal: false,
      previousTextForSpeech: "",
      CommentEmpty: false,
      chatGPTTheme: false,
      chatGptOptions: [
        {
          value: 0,
          label: "Predict The Next Best Action",
        },
        {
          value: 1,
          label: "Automated Narrative Building and Translation",
        },
        {
          value: 2,
          label: "Data Extraction and Leakage Prevention",
        },
        {
          value: 3,
          label: "Coding Effort Reduction",
        },
      ],
      chatGptSelectedOption: [],
      finalImpactDollar: 0,
      finalImpactFTE: 0,
      impactOptionSelected: null,
      finalImpactDollar: 0,
      finalImpactFTE: 0,
      businessImpactValueArray: [],
      monetizableProjectSelectedOption: null,
      monetizableCheckbox: null,
      monetizableStatus: false,
      monetizationStatusSelectedOption: null,
      SowID: "",
      FPnANameMonetization: "",
      FPnAOHRMonetization: "",
      FPnAFileNameMonetization: "",
      FPnAFileTypeMonetization: "",
      FPnAFileMonetization: "",
      appropriateAttachment: false,
      lastUpdaterAction: "",
      logRoleState: "",
      dataBridgeData: "",
      displayProjectTheme: false,
      ideaApprovalStatus: "",
    
      showHrefModal:false,
      campaignInsight:"",
      isE360SPOC:false,
      blackBeltSelectedOption:[],
      blackbeltList:[],
      BlackBeltUserName:"",
      BlackBeltUserId:"",
      BlackBeltUserEmail:"",
      
    };
    this.isExceedlimit = false;
  }

  async componentDidMount() {
    Toast.loading("Fetching Idea...", () => {});
    await this.fetchIdea();
    Toast.hide();
    Toast.loading("Fetching Metadata...", () => {});
    await this.fetchMetadata();
    Toast.hide();
  }

  fetchMetadata = () => {
    return new Promise((resolve, reject) => {
      const param = {
        EntityType: "",
        EntityName: "",
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + this.accessToken,
        },
        body: JSON.stringify(param),
      };
      fetch(GET_METADATA, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setState(
            {
              serviceLineList: data ? data.ServiceLines : [],
              subServiceLineList: data ? data.ServiceLines : [],
              workFlowStatusList: data ? data.WorkflowStatus : [],
              certificationLevelsList: data ? data.CertificationLevels : [],
              certificationWorkTypeList: data ? data.WorkType : [],
              transformationLeversList: data ? data.TransformationLevers : [],
              methodologyList: data ? data.Methodologies : [],
              databridgeThemeList: data ? data.DataBridgeThemes : [],
              subCampaignList: data ? data.SubCampaigns : [],
              digitalProjectTypeList: data ? data.DigitalProjectTypes : [],
              businessImpactMasterList: data ? data.BIMasterDataVerse : [],
              campaignList: data ? data.Campaigns : [],
            },
            () => {
             this.IsE360SPOCUser();
             this.fetchBlackBeltOptions()
              this.setSelectedOptions();
              // this.state.serviceLineList.map((item) => {
              //     if(){
              //         this.setState({
              //             serviceLineSelectedOption:{
              //                 value: ,
              //                 label:
              //             }
              //         })
              //     }
              // });
              if(this.state.ideaItem.CampaignName && this.state.campaignList){
                this.state.campaignList.map((campaign)=>{
                  if(campaign.CampaignName === this.state.ideaItem.CampaignName){
                    this.setState({campaignInsight:campaign.CampaignDescr},()=>{})
                  }
                })
              }
              this.setState({ Comment: "" });
              resolve();
            }
          );
        })
        .catch((error) => {
          Toast.fail(
            "Error while getting main metadata. Please try again!",
            3000,
            () => {
              console.log(JSON.stringify(error));
              this.setState(
                {
                  serviceLineList: [],
                  subServiceLineList: [],
                  workFlowStatusList: [],
                  certificationLevelsList: [],
                  certificationWorkTypeList: [],
                  transformationLeversList: [],
                  methodologyList: [],
                  databridgeThemeList: [],
                  subCampaignList: [],
                  digitalProjectTypeList: [],
                  businessImpactMasterList: [],
                },
                () => {
                  resolve();
                }
              );
            }
          );
        });
    });
  };
  IsE360SPOCUser = () => {
    return new Promise((resolve, reject) => {
      const param = {
        EntityType: "OHR",
        EntityName: this.sessionData ? this.sessionData.profile ? this.sessionData.profile.oHRId: "" : "",
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + this.accessToken,
        },
        body: JSON.stringify(param),
      };
      fetch(GET_METADATA, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          data.PowerAppUsers.filter((item) => {
            const userRole = JSON.parse(item.pg_Role).pg_role.trim();
            if (userRole === "E360SPOC" && parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
              this.setState({ isE360SPOC: true }, () => {})
            }
            if (userRole === "Administrator" && parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
              this.setState({ isE360SPOC: true }, () => {})
            }
          })
          resolve();
        })
        .catch((error) => {
          Toast.hide();
          console.log(JSON.stringify(error));
        });
    });
  };


  setSelectedOptions = () => {
    this.state.serviceLineList.map((item) => {
      if (this.state.ideaItem.ServiceLineName === item.ServiceLineName) {
        this.setState({
          serviceLineSelectedOption: {
            value: item.ServiceLineName,
            label: item.ServiceLineName,
          },
        });
      }
      return 1;
    });
    this.state.subServiceLineList.map((item) => {
      if (this.state.ideaItem.SubServiceLine === item.SubServiceLineName) {
        this.setState({
          subServiceLineSelectedOption: {
            value: item.SubServiceLineId,
            label: item.SubServiceLineName,
          },
        });
      }
      return 1;
    });
    this.state.campaignList.map((item) => {
      if (this.state.ideaItem.CampaignName === item.CampaignName) {
        this.setState(
          {
            campaignTypeText: item.CampaignName,
            campaignSelectedOption: {
              value: item.CampaignId,
              label: item.CampaignName,
            },
          },
          () => {
          }
        );
      }
      return 1;
    });
    this.state.databridgeThemeList.map((item) => {
      if (this.state.ideaItem.DataBridgeTheme === item.DBProjectThemeName) {
        this.setState({
          databridgeThemeSelectedOptions: {
            value: item.ThemeId,
            label: item.DBProjectThemeName,
          },
        });
      }
      return 1;
    });
    this.state.subCampaignList.map((item) => {
      if (this.state.ideaItem.SubCampaignName === item.SubCampaignName) {
        this.setState({
          subCampaignOption: {
            value: item.SubCampaignId,
            label: item.SubCampaignName,
          },
        });
      }
      return 1;
    });
  };

  fetchIdea = () => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + this.accessToken,
        },
      };
      // fetch(`${GET_IDEA_URL}IdeaId=941`, requestOptions)
      fetch(
        `${GET_IDEA_URL}IdeaId=${this.props.location.state}&RecID=''&isItIdeaOrGenAI='Idea'`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("fetch Idea", data);
          this.setState(
            {
              ideaItem: data.ResultSets.Table1[0],
              workflowStatus: data.ResultSets.Table1[0].WorkflowStatus,
              Comment: data.ResultSets.Table1[0].Comment,
              reviewComments: data.ReviewComments.Table1,
              finalReviewComments: data.FinalReviewComments.Table1,
              expectedDate:data.ResultSets.Table1[0].ExpectedFinishDate === null? new Date(): new Date(data.ResultSets.Table1[0].ExpectedFinishDate),
              dataBridgeCampaign:data.ResultSets.Table1[0].CampaignName === DATA_BRIDGE_CAMPAIGN,
              xPollinationCampaign:data.ResultSets.Table1[0].CampaignName === X_POLLINATION_CAMPAIGN,
              dataBridgeProjectTheme: data.ResultSets.Table1[0].DataBridgeTheme? data.ResultSets.Table1[0].DataBridgeTheme: "",
              subCampaignName: data.ResultSets.Table1[0].SubCampaignName? data.ResultSets.Table1[0].SubCampaignName: "",
              dataBridgeData: data.ResultSets? data.ResultSets.Table1[0].CampaignName: "",

              BlackBeltUserName:data.ResultSets ? data.ResultSets.Table1[0].BlackBeltUserName:"",
              BlackBeltUserId:data.ResultSets ? data.ResultSets.Table1[0].BlackBeltUserId:"",
              BlackBeltUserEmail:data.ResultSets ? data.ResultSets.Table1[0].BlackBeltUserEmail:"",
            },
            () => {

              if(this.state.BlackBeltUserId || this.state.BlackBeltUserName){
                this.setState(
                  {
                    blackBeltSelectedOption: {
                      value: this.state.BlackBeltUserId,
                      label: this.state.BlackBeltUserName,
                    },
                  },
                  () => { }
                );
              }


              if (this.state.ideaItem.CreatedDate) {
                let created = new Date(this.state.ideaItem.CreatedDate);
                let abc = new Date(DISPLAYPROJECTTHEME);
                if (created.getTime() < abc.getTime()) {
                  //console.log("created date is lesser")
                  this.setState({ displayProjectTheme: true });
                } else {
                  this.setState({ displayProjectTheme: false });
                  //console.log("created date is greater ")
                }
              }

              if (this.state.reviewComments) {
                let logAction = this.state.reviewComments[
                  this.state.reviewComments.length - 1
                ].UpdaterAction
                  ? this.state.reviewComments[
                      this.state.reviewComments.length - 1
                    ].UpdaterAction
                  : this.state.reviewComments[
                      this.state.reviewComments.length - 1
                    ].Action;
                let logRole =
                  this.state.reviewComments[
                    this.state.reviewComments.length - 1
                  ].UpdaterRole;
                this.setState(
                  {
                    logAction: logAction,
                    logRoleState: logRole,
                  },
                  () => {
                    //console.log("log action is",  this.state.reviewComments[this.state.reviewComments.length-1].UpdaterAction)
                  }
                );
              }
              if (
                this.state.reviewComments !== undefined &&
                this.state.reviewComments !== null
              ) {
                var keys = Object.keys(this.state.reviewComments);
                var last = keys[keys.length - 1];
                let varLastUpdaterAction =
                  this.state.reviewComments[last].UpdaterAction;
                this.setState(
                  { lastUpdaterAction: varLastUpdaterAction },
                  () => {}
                );
              }
              //this.setCampaignName();
              // console.log(this.state.dataBridgeData);
              if (this.state.dataBridgeData === "Data Bridge") {
                this.setState(
                  {
                    transSelectedOption: {
                      value: 0,
                      label: "Data",
                    },
                  },
                  () => {
                    // console.log(
                    //   "trans lever selected option is =>",
                    //   this.state.transSelectedOption
                    // );
                  }
                );
              }
              
              //this.setServiceLineName();
              // this.setState({Comment:""},()=>console.log(this.state.Comment))
              if (
                this.state.ideaItem.WorkflowStatus ===
                  "Cancellation Requested" &&
                this.state.ideaItem.ApprovalStatus === "NA"
              ) {
                this.setState({ CommentEmpty: true, Comment: "" }, () => {
                  console.log(this.state.CommentEmpty);
                });
              }
              if (
                this.state.ideaItem.WorkflowStatus === "Approve" &&
                this.state.ideaItem.ApprovalStatus === "Cancellation Requested"
              ) {
                this.setState({ CommentEmpty: true, Comment: "" }, () => {
                  console.log(this.state.CommentEmpty);
                });
              }
              console.log("campaign name from ideaitem =>",this.state.ideaItem.CampaignName)
              if (
                this.state.ideaItem.CampaignName === "Gen AI"
              ) {
                this.setState({ chatGPTTheme: true }, () => {
                  
                  if (this.state.chatGPTTheme) {
                    let options = [];
                    this.state.chatGptOptions.map((item) => {
                      if (item.label === this.state.ideaItem.SubCampaignName) {
                        options.push({
                          value: item.value,
                          label: item.label,
                        });
                        this.setState({ chatGptSelectedOption: options });
                      }
                    });
                  }
                });
              }

              resolve();
            }
          );
        })
        .catch((error) => {
          Toast.fail(
            "Error while fetching Idea details. Please try again!",
            3000,
            () => {}
          );
          reject();
        });
    });
  };

  handleCommentChange = (event) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.comment = {
      ...textBoxValidation(true),
      border: "1px solid lightgrey",
    };
    this.setState({
      selectStyle: styleStateObj,
    });
    this.setState({
      Comment: event.target.value,
    });
  };

  handleTranscriptCommentChange = (e) => {
    let trimmedText = e.trim();
    this.setState({
      Comment: trimmedText,
    });
  };

  validate = () => {
    let isValid = true;
    this.isExceedlimit = false;
    let styleStateObj = this.state.selectStyle;
    if (this.state.campaignTypeText === "") {
      isValid = false;
      styleStateObj.campaignTypeText = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.campaignTypeText = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
      
      if (this.state.campaignTypeText === X_POLLINATION_CAMPAIGN) {
        if (this.state.subCampaignName === "") {
          isValid = false;
          styleStateObj.subCampaignName = selectStyle(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.subCampaignName = selectStyle(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
      }
    }

   
    
    

    
    if (
      this.state.ideaItem.NewOrReplicatedIdea &&
      this.state.ideaItem.ReplicationProjectId === ""
    ) {
      isValid = false;
      styleStateObj.ReplicationProjectId = textBoxValidation(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      if (
        this.state.ideaItem.NewOrReplicatedIdea &&
        this.state.ideaItem.ReplicationProjectId !== "" &&
        !this.state.replicationIDValidated
      ) {
        isValid = false;
        styleStateObj.ReplicationProjectId = textBoxValidation(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.ReplicationProjectId = {
          ...textBoxValidation(true),
          border: "1px solid lightgrey",
        };
        this.setState({
          selectStyle: styleStateObj,
        });
      }
    }

    if (this.state.workFlowStatusSelectedOption === null) {
      isValid = false;
      styleStateObj.workFlowStatusSelectedOption = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.workFlowStatusSelectedOption = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }

    if (this.state.expectedDate === "") {
      isValid = false;
      styleStateObj.expectedDate = textBoxValidation(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.expectedDate = {
        ...textBoxValidation(true),
        border: "1px solid lightgrey",
      };
      this.setState({
        selectStyle: styleStateObj,
      });
    }

    if (!this.state.Comment || this.state.Comment === "") {
      isValid = false;
      styleStateObj.comment = textBoxValidation(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      if (this.state.Comment.length > 500) {
        Toast.fail(
          "Comment cannot be more than 500 characters, please change and resubmit.",
          3000
        );
        isValid = false;
        this.isExceedlimit = true;
        styleStateObj.comment = textBoxValidation(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.comment = {
          ...textBoxValidation(true),
          border: "1px solid lightgrey",
        };
        this.setState({
          selectStyle: styleStateObj,
        });
      }
    }
    
    if (this.state.chatGPTTheme === true) {
      if (
        this.state.chatGptSelectedOption.length === 0 ||
        this.state.chatGptSelectedOption == [] ||
        this.state.chatGptSelectedOption === null ||
        this.state.chatGptSelectedOption === "" ||
        this.state.chatGptSelectedOption === undefined ||
        !this.state.chatGptSelectedOption

      ) {
        isValid = false;
        styleStateObj.chatGptSelectedOption = selectStyle(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.chatGptSelectedOption = selectStyle(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }
    }


    if (
      this.state.blackBeltSelectedOption === null ||
      this.state.blackBeltSelectedOption.length === 0 ||
      this.state.blackBeltSelectedOption === undefined ||
      !this.state.blackBeltSelectedOption
    ) {
      isValid = false;
      styleStateObj.blackBeltSelectedOption = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.blackBeltSelectedOption = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }


    return isValid;
  };
  submitIdea = () => {
    if (this.validate()) {
      if (this.state.isE360SPOC) {
        let workflowstatus='';
        if(this.state.workFlowStatusSelectedOption){
          if(this.state.workFlowStatusSelectedOption.label==="Approve" || this.state.workFlowStatusSelectedOption.label==="Approved"){
            workflowstatus="Pending";
          }else{
            workflowstatus = this.state.workFlowStatusSelectedOption.label
          }
        }
        if (window.confirm("Are you sure you want to update the idea?")) {
          Toast.loading("Updating idea...", () => { });
          let param = {};
          if (this.state.workFlowStatusSelectedOption.label === "Approve Cancellation" || this.state.workFlowStatusSelectedOption.label === "Discard Cancellation") {
            if (this.state.workFlowStatusSelectedOption.label === "Approve Cancellation") {
              param = {
                IdeaId: this.props.location.state,
                ApprovalStatus: this.state.ideaItem.ApprovalStatus !== "NA" ? "Cancelled" : "NA",
                UpdaterOHR: this.sessionData.profile.oHRId,
                UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                UpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                comment: this.state.Comment,
                WorkflowStatus: this.state.ideaItem.WorkflowStatus !== REQUEST_CANCELLATION
                  ? this.state.ideaItem.WorkflowStatus
                  : "Cancelled",
                ProjectStatus:
                  this.state.ideaItem.WorkflowStatus === "Approve" ||
                    this.state.ideaItem.WorkflowStatus === "Approved"
                    ? "Pending"
                    : "NA",
                Role: "E360 SPOC",
                updaterAction: "Approved",
              };
            } else {
              if (this.state.ideaItem.WorkflowStatus === REQUEST_CANCELLATION) {
                param = {
                  IdeaId: this.props.location.state,
                  ApprovalStatus: "NA",
                  UpdaterOHR: this.sessionData.profile.oHRId,
                  UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                  UpdaterName:
                    this.sessionData.profile.lastName +
                    ", " +
                    this.sessionData.profile.firstName,
                  comment: this.state.Comment,
                  WorkflowStatus: "Pending",
                  RejectCancel: true,
                  Role: "E360 SPOC",
                  updaterAction: "Discard Cancellation",
                };
              } else {
                param = {
                  IdeaId: this.props.location.state,
                  ApprovalStatus: "Pending",
                  UpdaterOHR: this.sessionData.profile.oHRId,
                  UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                  UpdaterName:
                    this.sessionData.profile.lastName +
                    ", " +
                    this.sessionData.profile.firstName,
                  comment: this.state.Comment,
                  WorkflowStatus: "Approve",
                  RejectCancel: true,
                  Role: "E360 SPOC",
                  updaterAction: "Pending",
                };
              }
            }
          } else {
            param = {
              IdeaId:this.state.ideaItem.IdeaId,
              IdeaName: this.state.ideaItem.IdeaName,
              Problem: this.state.ideaItem.Problem,
              Solution: this.state.ideaItem.Solution,
              ServiceLineId: this.state.ideaItem.ServiceLineId,
              ServiceLineName: this.state.ideaItem.ServiceLineName,
              SubServiceLine: this.state.ideaItem.SubServiceLine,
              UpdaterOHR: this.sessionData.profile.oHRId,
              UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
              UpdaterName:this.sessionData.profile.lastName +", " +this.sessionData.profile.firstName,
              WorkflowStatusId:
                this.state.workFlowStatusSelectedOption !== null
                  ? this.state.workFlowStatusSelectedOption.value
                  : null,
              WorkflowStatus:workflowstatus,
              ExpectedFinishDate:
                this.state.workFlowStatusSelectedOption.label === "Approve"
                  ? new Date(this.state.expectedDate).toISOString()
                  : null,
              Comment: this.state.Comment,
              IdeaId: this.props.location.state,
              NewOrReplicatedIdea: this.state.ideaItem.NewOrReplicatedIdea,
              ReplicationProjectId: this.state.ideaItem.NewOrReplicatedIdea
                ? this.state.ideaItem.ReplicationProjectId
                : "",

              PotentialImpactFTE: this.state.ideaItem.PotentialImpactFTE,
              CertificationLevelId: null,
              CertificationWorkTypeName: null,
              CertificationLevelName: null,
              TransformationLeverId: null,
              TransformationLeverName: null,
              MethodologyTypeID: null,
              MethodologyName: null,
              LDTId: null,
              LDTName: null,
              IsReplicationVerified: this.state.ideaItem.IsReplicationVerified,
              IsDigital: false,
              BusinessImpactData: [],
              DigitalProjectType: null,
              DataBridgeTheme: this.state.dataBridgeProjectTheme,
              SubCampaignName: this.state.subCampaignName,
              CampaignName: this.state.campaignTypeText,


              Account:this.state.ideaItem.AccountManual,
              Vertical:this.state.ideaItem.VerticalManual,
              Region:this.state.ideaItem.RegionManual,
              OwnerName:this.state.ideaItem.OwnerName,
              OwnerEmail:this.state.ideaItem.OwnerEmail,
              IdeaIDAlpha:this.state.ideaItem.IdeaIDAlpha,
              BlackBeltUserEmail:this.state.ideaItem.BlackBeltUserEmail ? this.state.ideaItem.BlackBeltUserEmail : this.state.ideaItem.BlackBeltUserId+"@genpact.com",
              
           

              case: "new",
              casetype: "E360SPOC",
              DBSPocOHR: this.sessionData.profile.oHRId,
              DBSPoc: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
              ApprovalStatus:"NA",
              Role: "E360 SPOC",
              updaterRole: "E360 SPOC",
              updaterAction: this.state.workFlowStatusSelectedOption
                ? this.state.workFlowStatusSelectedOption.label === "Approve"
                  ? "Approved"
                  : this.state.workFlowStatusSelectedOption.label
                : "",
                BlackBeltUserName:this.state.blackBeltSelectedOption ? this.state.blackBeltSelectedOption.label : "",
                BlackBeltUserId:this.state.blackBeltSelectedOption ? this.state.blackBeltSelectedOption.value : "",
                BlackBeltUserEmail: this.state.blackBeltSelectedOption ? this.state.blackBeltSelectedOption.value+"@genpact.com" : "",
            };
          }
          console.log(param);
          let finalJsonData = Checksum(param);
          const requestOptions = {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param),
          };
          fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              Toast.hide();
              Toast.success("Updated Successfully.", 3000, () => {
                let redirectURL = "/E360-SPOC-Group-List-Page";
                window.location = redirectURL;
              });
            });
        }
      } else {
        Toast.fail('Users assigned with role of E360 SPOC or Administrator only can review the idea.', 5000, () => { });
        return;
      }
    } else {
      if (!this.isExceedlimit) {
        Toast.fail(
          "Required fields are missing or have invalid values. Please correct and try again.",
          3000,
          () => { }
        );
      }
    }
    return false;
  };
  handleProblemChange = (text) => {
    let ideaItem = this.state.ideaItem;
    ideaItem.Problem = text;
    this.setState({
      ideaItem,
    });
  };

  handleSolutionChange = (text) => {
    let ideaItem = this.state.ideaItem;
    ideaItem.Solution = text;
    this.setState({
      ideaItem,
    });
  };

  handleTranscriptProblemChange = (text) => {
    let ideaItem = this.state.ideaItem;
    if (text !== null && text !== undefined) {
      ideaItem.Problem = text;
      this.setState({
        ideaItem,
      });
    }
  };
  handleTranscriptSolutionChange = (text) => {
    let ideaItem = this.state.ideaItem;
    if (text !== null && text !== undefined) {
      ideaItem.Solution = text;
      this.setState({
        ideaItem,
      });
    }
  };
  toggleTranscriptFunction = (id) => {
    this.setState({ speechModal: !this.state.speechModal, modalIndex: id });
    if (id === 2) {
      // approval details comment
      if (this.state.Comment) {
        this.setState({ previousTextForSpeech: this.state.Comment }, () =>
          console.log(this.state.previousTextForSpeech)
        );
      } else {
        this.setState({ previousTextForSpeech: "" }, () =>
          console.log(this.state.previousTextForSpeech)
        );
      }
    }
    if (id === 10) {
      // request cancellation comment
      this.setState({ previousTextForSpeech: this.state.Comment }, () =>
        console.log(this.state.previousTextForSpeech)
      );
    }
    if (id === 11) {
      this.setState(
        { previousTextForSpeech: this.state.ideaItem.Problem },
        () => console.log(this.state.previousTextForSpeech)
      );
    }
    if (id === 12) {
      this.setState(
        { previousTextForSpeech: this.state.ideaItem.Solution },
        () => console.log(this.state.previousTextForSpeech)
      );
    }
  };

  closeModalFunction = () => {
    this.setState({ speechModal: false });
  };

  showHideDiv = () => {
    let ideaItem = this.state.ideaItem;
    ideaItem.NewOrReplicatedIdea = !ideaItem.NewOrReplicatedIdea;
    this.setState({
      ideaItem,
    });
  };

  showHideVerifyReplicationDiv = () => {
    let ideaItem = this.state.ideaItem;
    ideaItem.IsReplicationVerified = !ideaItem.IsReplicationVerified;
    this.setState({
      ideaItem,
    });
  };
  handleReplicationIdChange = (text) => {
    let ideaItem = this.state.ideaItem;
    ideaItem.ReplicationProjectId = text;
    this.setState({
      ideaArray: ideaItem,
      replicationIDValidated: false,
    });
  };

  renderStatusOptions = () => {
    let options = [];
      this.state.workFlowStatusList.map((item) => {
        options.push({
          value: item.WorkflowStatusId,
          label: item.WorkflowStatusName,
        });
      });
      return options;
    
  };

  renderCancelRequestOption = () => {
    let options = [];
    this.state.cancelRequestOption.map((item) => {
      options.push({
        value: item.WorkflowStatusId,
        label: item.WorkflowStatusName,
      });
      return options;
    });
    return options;
  };

  handleAccountNameChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.workFlowStatusSelectedOption = selectStyle(true);
    this.setState({
      workFlowStatusSelectedOption: {
        value: e.value,
        label: e.label,
      },selectStyle: styleStateObj,
    },()=>{

    })
   
  };

  handleCancelRequestChange = (e) => {
    this.setState({
      workFlowStatusSelectedOption: {
        value: e.value,
        label: e.label,
      },
    },()=>{});
  };

  

  renderServiceLineOptions = () => {
    let options = [];
    const uniqueServiceLineList = [
      ...new Map(
        this.state.serviceLineList.map((item) => [item.ServiceLineName, item])
      ).values(),
    ];
    uniqueServiceLineList.map((item) => {
      options.push({ value: item.ServiceLineId, label: item.ServiceLineName });
      return true;
    });
    return options;
  };

  renderSubServiceLineOptions = () => {
    let options = [];

    this.state.subServiceLineList.map((item) => {
      if (this.state.ideaItem.ServiceLineName === item.ServiceLineName) {
        options.push({
          value: item.SubServiceLineId,
          label: item.SubServiceLineName,
        });
        return true;
      }
      return true;
    });
    return options;
  };

  renderSubCampaignOptions = () => {
    let options = [];
    this.state.subCampaignList.map((item) => {
      options.push({ value: item.SubCampaignId, label: item.SubCampaignName });
      return true;
    });
    return options;
  };

  renderServiceLineOptions = () => {
    let options = [];
    const uniqueServiceLineList = [
      ...new Map(
        this.state.serviceLineList.map((item) => [item.ServiceLineName, item])
      ).values(),
    ];
    uniqueServiceLineList.map((item) => {
      options.push({ value: item.ServiceLineId, label: item.ServiceLineName });
      return true;
    });
    return options;
  };

  handleDataBridgeProjectThemeChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.dataBridgeProjectTheme = selectStyle(true);
    this.setState({
      selectStyle: styleStateObj,
      dataBridgeProjectTheme: e.label,
      databridgeThemeSelectedOptions: {
        value: e.label,
        label: e.label,
      },
    });
  };

  handleSubCampaignChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.subCampaignName = selectStyle(true);
    this.setState({
      selectStyle: styleStateObj,
      subCampaignName: e.label,
      subCampaignOption: {
        value: e.label,
        label: e.label,
      },
    });
  };

  handleServiceLineChange = (e) => {
    let ideaItem = this.state.ideaItem;
    ideaItem.ServiceLineId = e.value;
    ideaItem.ServiceLineName = e.label;
    ideaItem.SubServiceLineId = "";
    ideaItem.SubServiceLine = "";
    this.setState({
      subServiceLineSelectedOption: null,
      serviceLineSelectedOption: {
        value: e.value,
        label: e.label,
      },
      ideaItem,
    });
    this.renderSubServiceLineOptions(e);
  };

  handleSubServiceLineChange = (e, index) => {
    let ideaItem = this.state.ideaItem;
    ideaItem.SubServiceLine = e.label;
    this.setState({
      subServiceLineSelectedOption: {
        value: e.value,
        label: e.label,
      },
      ideaItem,
    });
  };

  fetchReplicationIdeaDetails = (IdeaId) => {
    Toast.loading(
      "Fetching Replication Idea Details. Please wait...",
      () => {}
    );
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":  "Bearer " + this.accessToken,
      },
    };
    return fetch(
      `${GET_IDEA_URL}IdeaId=-1&IdeaIDAlpha=` + IdeaId,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.ResultSets.Table1 === undefined) {
          Toast.fail(
            "We could not find the Idea based on the entered replication Idea ID. Please check and reenter.",
            5000,
            () => {}
          );
          let ideaItem = this.state.ideaItem;
          ideaItem.ReplicationProjectId = "";
          this.setState({
            ideaItem,
            replicationIDValidated: false,
          });
          return false;
        } else {
          Toast.hide();
          let ideaItem = this.state.ideaItem;
          ideaItem.ReplicationProjectId = IdeaId;
          let styleStateObj = this.state.selectStyle;
          styleStateObj.ReplicationProjectId = {
            ...textBoxValidation(true),
            border: "1px solid lightgrey",
          };
          this.setState({
            ideaItem,
            selectStyle: styleStateObj,
          });
          Toast.success(
            "Replication Idea ID validated successfully!",
            3000,
            () => {}
          );
          return true;
        }
      })
      .catch((error) => {
        Toast.hide();
        Toast.fail(
          "We could not find the Idea based on the entered replication Idea ID. Please check and reenter.",
          5000,
          () => {}
        );
        let ideaItem = this.state.ideaItem;
        ideaItem.ReplicationProjectId = "";
        this.setState({
          ideaItem,
          replicationIDValidated: false,
        });
        return false;
      });
  };

  validateReplicationId = (ideaId) => {
    if (!this.fetchReplicationIdeaDetails(ideaId)) {
      let ideaItem = this.state.ideaItem;
      ideaItem.ReplicationProjectId = "";
      this.setState({
        ideaItem,
        replicationIDValidated: false,
      });
      return false;
    } else {
      let ideaItem = this.state.ideaItem;
      ideaItem.ReplicationProjectId = ideaId;
      this.setState({
        ideaItem,
        replicationIDValidated: true,
      });
      return true;
    }
  };

  cancelEvent = () => {
    // this.props.history.push("/review-page?page=approval");
    let redirectURL = "/review-page";
    if (this.state.approvalPage) {
      // redirectURL = redirectURL + "?page=" + this.state.approvalPage;
      // this.props.history.push(redirectURL);
      this.props.history.push({
        pathname: redirectURL,
        search: "?page=" + this.state.approvalPage,
        propsList: this.state.propsData,
      });
    } else {
      this.props.history.push(redirectURL);
    }
  };

  renderCampaignOptions = () => {
    let options = [];
    let filteredCampaign = this.state.campaignList && this.state.campaignList.filter((item) => {
      return item.CampaignId !== 7;
    });

    filteredCampaign && filteredCampaign.map((item) => {
      options.push({ value: item.CampaignId, label: item.CampaignName });
      return true;
    });
    
    return options;
  };

  handleCampaignNameChange = (e) => {
    console.log("campaign name selected =>",e.label)
    if (e.label === "Gen AI") {
      this.setState({ chatGPTTheme: true }, () => {});
    } else {
      this.setState({ chatGPTTheme: false }, () => {});
    }
    this.setState({
      chatGptSelectedOption: [],
      IsIdeaReplicableSelectedOption:[],
      solutionFeasibleSelectedOption:[],
      solutionComplexitySelectedOption:[]
    });

    let styleStateObj = this.state.selectStyle;
    styleStateObj.campaignTypeText = selectStyle(true);

    let flagStatus = e.label === DATA_BRIDGE_CAMPAIGN ? true : false;
    let xPollinationflagStatus =
      e.label === X_POLLINATION_CAMPAIGN ? true : false;

    this.setState({
      dataBridgeCampaign: flagStatus,
      xPollinationCampaign: xPollinationflagStatus,
      dataBridgeProjectTheme: "",
      subCampaignName: "",
      selectStyle: styleStateObj,
      campaignSelectedOption: {
        value: e.value,
        label: e.label,
      },
      //campaignTypeId: e.value,//e.target.value,
      campaignTypeText: e.label,
    });

    if (e && e.label === "Data Bridge") {
      this.setState({
        transSelectedOption: {
          value: 0,
          label: "Data",
        },
      });
    } else if (e && (e.label === "AI First" || e.label==="AI First - Enterprise 360" || e.label==="AI First - C&H Gen AI hackathon")) {
      this.setState({
        transSelectedOption: {
          value: 0,
          label: "AI",
        },
      });
    } else {
      this.setState({
        transSelectedOption: null,
      });
    }
    this.state.campaignList.map((item,index)=>{
      if(e.value===item.CampaignId){
        this.setState({campaignInsight:item.CampaignDescr},()=>{})
      }
    })
  };

  formatDateStringWithTime(dateparam) {
    return dateparam.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  renderChatGPToptions = () => {
    let options = [];
    this.state.chatGptOptions.map((item) => {
      options.push({ value: item.value, label: item.label });
      return true;
    });
    return options;
  };
  handleChatGptOptionsChange = (e) => {
    if (e !== null || e !== undefined) {
      this.setState(
        {
          subCampaignName: e.label,
          chatGptSelectedOption: [
            {
              value: e.value,
              label: e.label,
            },
          ],
        },
        () => {
          console.log(
            "chatgpt selected option",
            this.state.chatGptSelectedOption
          );
        }
      );
    }
  };

  getStatus = () => {
    const { logAction, ideaItem } = this.state;
    if (this.state.ideaItem.WorkflowStatus) {
      if (this.state.ideaItem.WorkflowStatus === "Approve") {
        return "";
      }
      if (this.state.ideaItem.WorkflowStatus === "Reject") {
        return "REJECTED";
      }

      if (this.state.ideaItem.WorkflowStatus === "Send Back") {
        return "SENT_BACK";
      }
      if (
        this.state.ideaItem.WorkflowStatus === "Cancelled" ||
        this.state.ideaItem.ApprovalStatus === "Cancelled"
      ) {
        return "Cancelled";
      }
      if (
        this.state.ideaItem.WorkflowStatus === "Cancellation Requested" ||
        this.state.ideaItem.ApprovalStatus === "Cancellation Requested"
      ) {
        return "Cancellation Requested";
      }
    }
    if (logAction === "Approve") {
      return APPROVED;
    }
    if (logAction === "Send Back") {
      return SENT_BACK;
    }
    if (logAction === "Reject") {
      return REJECTED;
    }
    if (logAction === "Cancellation Requested") {
      return "Cancellation Requested";
    }
    if (logAction === "Pending") {
      return "Pending";
    }
    if(this.state.ideaItem.WorkflowStatus === "Pending"){
      if(logAction === "Delegate Action"){
        return "Delegated";
      }
      return "Pending";
    }

    if (ideaItem.Comment !== null) {
      if (logAction === "Idea Reinitiated by Admin") {
        return "Idea Reinitiated";
      } else {
        return "Discard Cancellation";
      }
    }
    //return "PENDING";
  };

  
  fetchBlackBeltOptions = () => {
    Toast.loading("Fetching BlackBelt users...", () => { });
    let entityType = "BB";
    let entityName = this.state.ideaItem.RegionManual;
    if (this.state.ideaItem.RegionManual === OTHER_REGIONS) {
        entityType = "BBOTHER";
        entityName = this.state.ideaItem.VerticalManual.replace(" - ", "-");
    }
    const param = {
        EntityType: entityType,
        EntityName: entityName
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization":  "Bearer " + this.accessToken,
        },
        body: JSON.stringify(param)
    };
    fetch(GET_METADATA, requestOptions)
        .then(response => response.json())
        .then(data => {
            Toast.hide();
            this.setState({
              blackbeltList: data.DataverseUserRoles !== undefined ? data.DataverseUserRoles : [],
            }, () => { })
        })
        .catch(error => {
            Toast.hide();
            Toast.fail('Error occured while getting metadata idea. Please try again!', 3000, () => {
                console.log(JSON.stringify(error));
            });
        });
}

  renderBlackBeltOptions = () => {
    let options = [];
    const uniqueOHRValues = new Set();
    this.state.blackbeltList.map((item) => {
        const userRole = JSON.parse(item.pg_Role).pg_role;
        if (userRole === "BB" || userRole === "QA") {
          if (!
            (
              parseInt(this.state.ideaItem.OwnerOHR) === parseInt(item.pg_ohr) ||
              parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr) ||
              parseInt(this.state.ideaItem.CreatorOHR) === parseInt(item.pg_ohr) ||
              parseInt(this.state.ideaItem.BlackBeltUserId) === parseInt(item.pg_ohr)
            )
          ){
            if (!uniqueOHRValues.has(item.pg_ohr)) {
              options.push({ value: item.pg_ohr, label: item.pg_username });
              uniqueOHRValues.add(item.pg_ohr);
            }
          }
        }
        return true;
    });
    return options;
}


handleBlackBeltNameChange = (e) => {
  let styleStateObj = this.state.selectStyle;
  styleStateObj.blackBeltSelectedOption = selectStyle(true);
  this.setState({
    selectStyle: styleStateObj,
    blackBeltUserId: e.value,
    blackBeltUserName: e.label,
    blackBeltUserEmail: e.value + "@genpact.com",
    blackBeltSelectedOption: e
  });
}




  render() {
    // this is for idea approval details -> Idea approval status
    this.state.ideaApprovalStatus = this.getStatus();
    // console.log(this.state.ideaApprovalStatus);

    let IDEA_APPROVED = this.state.ideaItem.WorkflowStatus === "Approve";
    let IDEA_REJECTED = this.state.ideaItem.WorkflowStatus === "Reject";
    let IDEA_SEND_BACK = this.state.ideaItem.WorkflowStatus === "Send Back";
    let IDEA_REQUEST_CANCELLATION =
      this.state.ideaItem.WorkflowStatus === "Cancellation Requested";
    let IDEA_REQUEST_CANCELLATION1 =
      this.state.ideaItem.ApprovalStatus === "Cancellation Requested";
    let IDEA_CANCELLED = this.state.ideaItem.WorkflowStatus === "Cancelled";
    let IDEA_CANCELLED1 = this.state.ideaItem.ApprovalStatus === "Cancelled";
    let IDEA_UPDATERACTION = this.state.ideaItem.updaterAction;


    



    return (
      <>
        <section className="u-clearfix u-section-1" id="sec-0f7f">
          <div
            className="u-clearfix u-sheet u-sheet-1"
            style={{ marginBottom: 20 }}
          >
            <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
              <div className="u-container-layout u-container-layout-1">
                <h4
                  style={{
                    textAlign: "center",
                    color: "#00AECF",
                    fontFamily: "Arial",
                    fontSize: 22,
                  }}
                >
                  Review idea :{" "}
                  {this.state.ideaItem.IdeaIDAlpha
                    ? this.state.ideaItem.IdeaIDAlpha
                    : this.state.ideaItem.IdeaId}{" "}
                </h4>
                <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                  <div
                    className="u-container-layout u-container-layout-2"
                    style={{ textAlign: "center" }}
                  >
                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-3">
                      <div className="u-container-layout u-container-layout-3">
                        <p
                          className="u-align-left u-text u-text-2"
                          style={{ marginBottom: 13 }}
                        >
                          Owner details
                        </p>
                        <div className="row">
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Owner OHR
                            </label>{" "}
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-input"
                              disabled
                              type="text"
                              value={this.state.ideaItem.OwnerOHR}
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: 15 }} />
                        <div className="row">
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Owner Name
                            </label>{" "}
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-input"
                              disabled
                              type="text"
                              value={this.state.ideaItem.OwnerName}
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: -10 }} />
                        <p
                          className="u-align-left u-text u-text-7 u-text-custom-color-3"
                          style={{ fontSize: 11, marginBottom: 10 }}
                        >
                          (If Idea was raised for someone else, their OHR ID is
                          below)
                        </p>
                        <div className="row">
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Request For OHR
                            </label>{" "}
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-input"
                              disabled
                              type="text"
                              defaultValue={this.state.ideaItem.CreatorOHR}
                              onChange={this.handleCreatorOHRChange}
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: 10 }} />
                        <div className="row">
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Name
                            </label>{" "}
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-input"
                              disabled
                              type="text"
                              defaultValue={this.state.ideaItem.CreatorName}
                              onChange={this.handleCreatorNameChange}
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: 10 }} />
                      </div>
                    </div>
                    <div
                      className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-4"
                      style={{ marginTop: "-255px" }}
                    >
                      <div
                        className=""
                        style={{ display: "flex", marginTop: "30px" }}
                      >
                        <div className="u-container-style u-group u-shape-rectangle u-group-5">
                          <div className="u-container-layout u-container-layout-5">
                            <p
                              className="u-align-left u-text u-text-8"
                              style={{ marginBottom: 15 }}
                            >
                              Account Details
                            </p>
                            <div className="row">
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  Account&nbsp;Name
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  list="accounts"
                                  name="accounts"
                                  placeholder="Choose Account"
                                  value={this.state.ideaItem.AccountManual}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  Holding&nbsp;Account
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  type="text"
                                  value={this.state.ideaItem.HoldingAccount}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  Vertical
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  list="vertical"
                                  name="vertical"
                                  placeholder="Choose Vertical"
                                  value={this.state.ideaItem.VerticalManual}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  Industry&nbsp;Vertical
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  list="ideationtype"
                                  name="ideationtype"
                                  placeholder="Choose Industry&nbsp;Vertical"
                                  value={this.state.ideaItem.SubVerticalName}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />

                            {/* units and segments starts here */}
                            <div className="row">
                              <div className="col-md-5">
                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Segment</label>
                              </div>
                              <div className="col-md-7">
                                <input className="form-input" disabled name="segment" value={this.state.ideaItem.Segment ? this.state.ideaItem.Segment : ""} style={{ fontWeight: 'normal !important' }} />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-5">
                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Unit</label>
                              </div>
                              <div className="col-md-7">
                                <input className="form-input" disabled name="unit" value={this.state.ideaItem.Unit ? this.state.ideaItem.Unit : ""} style={{ fontWeight: 'normal !important' }} />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            {/* units and segments ends here */}


                            
                            <div className="row">
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  
                                  Campaign&nbsp;Name
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input disabled className="form-input" placeholder="Campaign" value={this.state.ideaItem.CampaignName} /> 
                              </div>

                              <div className="">
                                <div className="campaign_modal_bulb campaign_tool_tip">
                                  <img 
                                      src={require("../assets/images/question_mark_2.png")}
                                      alt=""
                                      className=""
                                  />
                                  {
                                    this.state.campaignInsight
                                    ?
                                    <>
                                        <span className="my_tooltiptext">{this.state.campaignInsight}</span>
                                    </>
                                    :
                                    null
                                  }
                                </div>
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            {this.state.displayProjectTheme &&
                              this.state.dataBridgeCampaign && (
                                <>
                                  <div className="row">
                                    <div className="col-md-5">
                                      <label
                                        style={{
                                          verticalAlign: "bottom",
                                          marginBottom: -5,
                                        }}
                                      >
                                        <span style={{ color: "red" }}>*</span>
                                        DataBridge Project Theme
                                      </label>
                                    </div>
                                    <div className="col-md-7">
                                      <Select
                                        isSearchable={true}
                                        options={this.renderDataBridgeThemeOptions()}
                                        styles={
                                          this.state.selectStyle
                                            .dataBridgeProjectTheme
                                        }
                                        placeholder="Choose Project Theme"
                                        value={
                                          this.state
                                            .databridgeThemeSelectedOptions
                                        }
                                        onChange={
                                          this
                                            .handleDataBridgeProjectThemeChange
                                        }
                                      />
                                      {/* <input className="form-input" disabled list="ideationtype" name="ideationtype" placeholder="Choose Databridge Project Theme" value={this.state.ideaItem.DataBridgeTheme} /> */}
                                    </div>
                                  </div>
                                  <div style={{ marginBottom: 15 }} />
                                </>
                              )}
                            {this.state.xPollinationCampaign && (
                              <>
                                <div className="row">
                                  <div className="col-md-5">
                                    <label
                                      style={{
                                        verticalAlign: "bottom",
                                        marginBottom: -5,
                                      }}
                                    >
                                      Sub Campaign
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <Select
                                      isSearchable={true}
                                      options={this.renderSubCampaignOptions()}
                                      styles={
                                        this.state.selectStyle.subCampaignName
                                      }
                                      placeholder="Choose Sub Campaign"
                                      value={this.state.subCampaignOption}
                                      onChange={this.handleSubCampaignChange}
                                    />
                                    {/* <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Sub Campaign" value={this.state.ideaItem.SubCampaignName} /> */}
                                  </div>
                                </div>
                                <div style={{ marginBottom: 15 }} />
                              </>
                            )}

                            {this.state.chatGPTTheme ? (
                              <>
                                <div className="row">
                                  <div className="col-md-5">
                                    <label
                                      style={{
                                        verticalAlign: "bottom",
                                        marginBottom: -5,
                                      }}
                                    >
                                      <label
                                        style={{
                                          color: "red",
                                          verticalAlign: "bottom",
                                        }}
                                      >
                                        *
                                      </label>{" "}
                                      Gen AI Theme
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <Select
                                      tabIndex={5}
                                      isSearchable={true}
                                      options={this.renderChatGPToptions()}
                                      styles={
                                        this.state.selectStyle
                                          .chatGptSelectedOption
                                      }
                                      placeholder="Choose Gen AI Theme"
                                      onChange={this.handleChatGptOptionsChange}
                                      value={this.state.chatGptSelectedOption}
                                    />
                                  </div>
                                </div>
                                {/* <div style={{ marginBottom: 18 }}>&nbsp;</div> */}
                              </>
                            ) : null}
                            
                          </div>
                        </div>
                        <div className="u-container-layout u-container-layout-6">
                          <div className="row" style={{ marginTop: "10px" }}>
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Country
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                list="country"
                                name="country"
                                placeholder="Choose Country"
                                value={this.state.ideaItem.Country}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />
                          <div className="row">
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Region
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                list="regions"
                                name="regions"
                                placeholder="Choose Region"
                                value={this.state.ideaItem.RegionManual}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />
                          <div className="row">
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Sub-Region
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                list="subregions"
                                name="subregions"
                                placeholder="Choose Sub Region"
                                value={this.state.ideaItem.SubRegion}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />
                          <div className="row">
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Blackbelt&nbsp;Name
                              </label>
                            </div>
                            <div className="col-md-7">
                              <Select isSearchable={true} value={this.state.blackBeltSelectedOption} options={this.renderBlackBeltOptions()} styles={this.state.selectStyle.blackBeltSelectedOption} placeholder="Choose Blackbelt" onChange={this.handleBlackBeltNameChange} />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />
                        </div>
                      </div>
                    </div>

                    {this.state.dataBridgeCampaign ||
                    this.state.xPollinationCampaign ? (
                      <>
                        <div style={{ borderBottom: "1px solid lightgrey" }} />
                      </>
                    ) : (
                      <div
                        style={{
                          borderBottom: "1px solid lightgrey",
                          marginBottom: 15,
                        }}
                      />
                    )}

                    <p
                      className="visible-md-block visible-sm-block visible-xs-block"
                      style={{ marginTop: -30 }}
                    >
                      &nbsp;
                    </p>

                    <div
                      id="idea1"
                      className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                      style={{ marginTop: 15 }}
                    >
                      <div className="u-container-layout u-container-layout-7">
                        <div className="u-container-style u-group u-shape-rectangle u-group-8">
                          <div className="u-container-layout u-container-layout-8">
                            <p
                              id="idea1title"
                              className="u-align-left u-text u-text-2"
                              style={{ marginBottom: 10 }}
                            >
                              Idea details
                            </p>
                            <div className="row">
                              <div className="col-md-4">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                
                                  Service&nbsp;Line
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input disabled className="form-input" placeholder="Service Line" value={this.state.serviceLineSelectedOption && this.state.serviceLineSelectedOption.label} /> 
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-4">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  
                                  Sub-Service&nbsp;Line
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input disabled className="form-input" placeholder="Sub Service Line" value={this.state.subServiceLineSelectedOption && this.state.subServiceLineSelectedOption.label} /> 
                                
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-7">
                                <p className="u-align-left u-text u-text-custom-color-4 u-text-8">
                                  <span className="u-text-black form-attribute">
                                    &nbsp;What is the Problem ?&nbsp;
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 12,
                                        fontStyle: "italic",
                                        color: "grey",
                                      }}
                                    >
                                      (max. 2000 chars)
                                    </span>
                                  </span>
                                </p>
                              </div>
                              
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <textarea
                                  className="form-input"
                                  disabled
                                  rows={6}
                                  cols={80}
                                  style={{
                                    maxWidth: "100%",
                                    border: "1px solid lightgrey",
                                    ...this.state.selectStyle.Problem,
                                  }}
                                  value={this.state.ideaItem.Problem}
                                />
                              </div>
                            </div>
                          </div>
                          {this.state.speechModal ? (
                            <UpdatedSpeech
                              modalOpen={this.state.speechModal}
                              onReviewTranscriptProblem={
                                this.handleTranscriptProblemChange
                              }
                              onReviewTranscriptSolution={
                                this.handleTranscriptSolutionChange
                              }
                              onTranscriptComment={
                                this.handleTranscriptCommentChange
                              }
                              modalId={this.state.modalIndex}
                              closeModalFunction={this.closeModalFunction}
                              previousText={this.state.previousTextForSpeech}
                              onRequestCancellation={
                                this.handleTranscriptCommentChange
                              }
                            />
                          ) : null}
                        </div>
                        <div className="u-container-style u-group u-shape-rectangle u-group-9">
                          <div
                            className="u-container-layout u-container-layout-9"
                            style={{ marginTop: -5 }}
                          >
                            <div className="row">
                              <div className="col-md-4">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  Idea Name
                                </label>
                              </div>
                              <div
                                className="col-md-8"
                                style={{ alignItems: "flex-start" }}
                              >
                                <input
                                  className="form-input"
                                  disabled
                                  type="text"
                                  value={this.state.ideaItem.IdeaName}
                                  placeholder="Eg: Cash flow improvement through process restructuring"
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 70 }} />
                            <div className="row">
                              <div className="col-md-7">
                                <p
                                  className="u-align-left u-text u-text-custom-color-4 u-text-8"
                                  style={{ width: "287px" }}
                                >
                                  <span className="u-text-black form-attribute">
                                    &nbsp;How to resolve the problem&nbsp;
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 12,
                                        fontStyle: "italic",
                                        color: "grey",
                                      }}
                                    >
                                      (max. 2000 chars)
                                    </span>
                                  </span>
                                </p>
                              </div>
                              
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <textarea
                                  disabled
                                  className="form-input"
                                  rows={6}
                                  cols={80}
                                  style={{
                                    maxWidth: "100%",
                                    border: "1px solid lightgrey",
                                    ...this.state.selectStyle.Solution,
                                  }}
                                  value={this.state.ideaItem.Solution}
                                  
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: 20 }}>
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Are you trying to replicate an existing Idea?
                            </label>
                            {/* <span style={{ color: 'red' }}>*</span> */}
                          </div>
                          <div className="col-md-4">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  this.state.ideaItem.NewOrReplicatedIdea ===
                                  null
                                    ? false
                                    : this.state.ideaItem.NewOrReplicatedIdea
                                }
                                onChange={() => this.showHideDiv()}
                              />
                              <span className="slider round" />
                            </label>
                          </div>
                        </div>

                        {this.state.ideaItem.NewOrReplicatedIdea && (
                          <>
                            <div
                              className="col-md-8"
                              style={{ marginBottom: 15, width: "100%" }}
                            />
                            <div className="row">
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  <label style={{ color: "red" }}>*</label>{" "}
                                  Provide the ID of the executed Idea which you
                                  want to replicate?
                                </label>
                              </div>
                              <div className="col-md-2">
                                <input
                                  className="textarea-css form-attribute"
                                  type="text"
                                  value={
                                    this.state.ideaItem.ReplicationProjectId
                                  }
                                  onChange={(event) => {
                                    this.handleReplicationIdChange(
                                      event.target.value
                                    );
                                  }}
                                  onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                      this.validateReplicationId(
                                        event.target.value
                                      );
                                    }
                                  }}
                                  style={{
                                    border: "1px solid lightgrey",
                                    ...this.state.selectStyle
                                      .ReplicationProjectId,
                                  }}
                                />
                              </div>
                              <div className="col-md-1">
                                <i
                                  className="fa fa-check-square-o"
                                  style={{
                                    marginTop: 5,
                                    color: "#FF555F",
                                    fontSize: 24,
                                  }}
                                  onClick={(event) => {
                                    this.validateReplicationId(
                                      this.state.ideaItem.ReplicationProjectId
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div
                          className="col-md-8"
                          style={{ marginBottom: 15, marginTop: 5 }}
                        />
                      </div>
                    </div>

                    
                    {this.state.reviewComments && (
                      <>
                        <div
                          style={{
                            borderBottom: "1px solid lightgrey",
                            marginBottom: 15,
                          }}
                        />
                        <div
                          id="Approval Status"
                          className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                        >
                          <div className="u-container-layout u-container-layout-7">
                            <p
                              className="u-align-left u-text u-text-2"
                              style={{ marginBottom: 10 }}
                            >
                              Idea Approval details
                            </p>
                            <div className="row">
                              <div className="col-md-2">
                                <span className="form-attribute">
                                  Approval Status
                                </span>{" "}
                              </div>
                              <div className="col-md-2">
                                <input
                                  className="form-input"
                                  disabled
                                  value={this.state.ideaApprovalStatus}
                                />
                              </div>
                            </div>
                            <div className="row" style={{ marginTop: 15 }}>
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  Comments
                                </label>
                              </div>
                              <div
                                className="col-md-2"
                                style={{ marginLeft: 20 }}
                              >
                                <button
                                  className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                  onClick={(e) =>
                                    this.setState({
                                      commentLog: !this.state.commentLog,
                                    })
                                  }
                                >
                                  Comment Log
                                </button>
                              </div>
                            </div>
                            <div className="row" style={{ marginTop: 5 }}>
                              <div className="col-md-12">
                                <textarea
                                  className="form-input"
                                  disabled
                                  rows={3}
                                  cols={88}
                                  style={{
                                    maxWidth: "100%",
                                    border: "1px solid lightgrey",
                                  }}
                                  name="Comments"
                                  value={this.state.ideaItem.Comment}
                                />
                              </div>
                            </div>
                            <div className="text-left mt-4">
                              {this.state.commentLog &&
                              this.state.reviewComments ? (
                                <>
                                  <div className="custom_modal">
                                    <div className="modal_body">
                                      <div className="row">
                                        <div className="col-md-8">
                                          <h3 className="modal_title">
                                            Idea Approval Comment Log
                                          </h3>
                                        </div>
                                        <div className="col-md-4">
                                          <button
                                            type="button"
                                            className="close text-right"
                                            onClick={(e) =>
                                              this.setState({
                                                commentLog: false,
                                              })
                                            }
                                          >
                                            &times;
                                          </button>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div
                                          className="col-md-2 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            Date & Time
                                          </label>
                                        </div>
                                        <div
                                          className="col-md-2 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            OHR
                                          </label>
                                        </div>
                                        <div
                                          className="col-md-2 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            Name
                                          </label>
                                        </div>
                                        <div
                                          className="col-md-1 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            Role
                                          </label>
                                        </div>
                                        <div
                                          className="col-md-2 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            Action
                                          </label>
                                        </div>
                                        <div
                                          className="col-md-3 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            Comment
                                          </label>
                                        </div>
                                      </div>
                                      {this.state.reviewComments.map(
                                        (item, index) => {
                                          return (
                                            <div className="row" key={index}>
                                              <div
                                                className="col-md-2 text-center"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {this.formatDateStringWithTime(
                                                    new Date(item.CreatedDate)
                                                  )}
                                                </label>
                                              </div>
                                              <div
                                                className="col-md-2 text-center"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {item.UpdaterOHR}
                                                </label>
                                              </div>
                                              <div
                                                className="col-md-2 text-center"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {item.UpdaterName}
                                                </label>
                                              </div>
                                              <div
                                                className="col-md-1 text-center role_over_flow_class"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {item.UpdaterRole
                                                    ? item.UpdaterRole
                                                    : item.Action ===
                                                        "Idea Updated by Admin" ||
                                                      item.Action ===
                                                        "Idea Reinitiated by Admin"
                                                    ? "Admin"
                                                    : item.Action ===
                                                      "Delegate Action"
                                                    ? "BB"
                                                    : item.UpdaterOHR ===
                                                      this.state.ideaItem
                                                        .BlackBeltUserId
                                                    ? "BB"
                                                    : item.UpdaterOHR ===
                                                      this.state.ideaItem.MBBOHR
                                                    ? "MBB"
                                                    : item.UpdaterOHR ===
                                                      this.state.ideaItem
                                                        .OwnerOHR
                                                    ? "Idea Owner"
                                                    : item.UpdaterRole}
                                                </label>
                                              </div>
                                              <div
                                                className="col-md-2 text-center"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {item.UpdaterAction
                                                    ? item.UpdaterAction
                                                    : item.Action ===
                                                      "Idea Reinitiated by Admin"
                                                    ? "Idea Reinitiated"
                                                    : item.Action ===
                                                      "Idea Updated by Admin"
                                                    ? item.WorkflowStatus !==
                                                      "Pending"
                                                      ? item.WorkflowStatus
                                                      : "Idea Updated"
                                                    : item.Action ===
                                                      "Delegate Action"
                                                    ? "Delegated"
                                                    : item.WorkflowStatus ===
                                                      "Pending"
                                                    ? "Discard Cancellation"
                                                    : item.WorkflowStatus}
                                                </label>
                                              </div>
                                              <div
                                                className="col-md-3 text-center"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {item.Comment
                                                    ? item.Comment
                                                    : "-"}
                                                </label>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div
                      style={{
                        borderBottom: "1px solid lightgrey",
                        marginBottom: 15,
                      }}
                    />

                    {this.state.ideaItem.ApprovalStatus ===
                      REQUEST_CANCELLATION ||
                    this.state.ideaItem.WorkflowStatus ===
                      REQUEST_CANCELLATION ? (
                      <>
                        {this.state.ideaItem.ApprovalStatus ===
                          REQUEST_CANCELLATION && (
                          <div
                            id="Approval Status"
                            className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                          >
                            <div className="u-container-layout u-container-layout-7">
                              <p
                                className="u-align-left u-text u-text-2"
                                style={{ marginBottom: 10 }}
                              >
                                Project Approval details
                              </p>
                              <div className="row">
                                <div className="col-md-2">
                                  <span className="form-attribute">
                                    Approval Status
                                  </span>{" "}
                                </div>
                                <div className="col-md-2">
                                  <input
                                    className="form-input"
                                    disabled
                                    name="finalapprovalstatus"
                                    value={this.state.ideaItem.ApprovalStatus}
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: 15 }}>
                                <div className="col-md-5">
                                  <label
                                    style={{
                                      verticalAlign: "bottom",
                                      marginBottom: -5,
                                    }}
                                  >
                                    Approval Comments
                                  </label>
                                </div>
                                <div
                                  className="col-md-2"
                                  style={{ marginLeft: 20 }}
                                >
                                  <button
                                    className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                    onClick={(e) =>
                                      this.setState({
                                        finalCommentLog:
                                          !this.state.finalCommentLog,
                                      })
                                    }
                                  >
                                    Comment Log
                                  </button>
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: 5 }}>
                                <div className="col-md-12">
                                  <textarea
                                    className="form-input"
                                    disabled
                                    rows={3}
                                    cols={88}
                                    style={{
                                      maxWidth: "100%",
                                      border: "1px solid lightgrey",
                                    }}
                                    name="Comments"
                                    value={this.state.ideaItem.FinalComments}
                                  />
                                </div>
                              </div>
                              <div className="text-left mt-4">
                                {this.state.finalCommentLog &&
                                this.state.finalReviewComments ? (
                                  <>
                                    <div className="custom_modal">
                                      <div className="modal_body">
                                        <div className="row">
                                          <div className="col-md-8">
                                            <h3 className="modal_title">
                                              Lean Approval Comment Log
                                            </h3>
                                          </div>
                                          <div className="col-md-4">
                                            <button
                                              type="button"
                                              className="close text-right"
                                              onClick={(e) =>
                                                this.setState({
                                                  finalCommentLog: false,
                                                })
                                              }
                                            >
                                              &times;
                                            </button>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div
                                            className="col-md-2 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              Date & Time
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-2 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              OHR
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-2 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              Name
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-1 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              Role
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-2 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              Action
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-3 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              Comment
                                            </label>
                                          </div>
                                        </div>
                                        {this.state.finalReviewComments.map(
                                          (item, index) => {
                                            return (
                                              <div className="row" key={index}>
                                                <div
                                                  className="col-md-2 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {this.formatDateStringWithTime(
                                                      new Date(item.CreatedDate)
                                                    )}
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-2 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {item.UpdaterOHR}
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-2 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {item.UpdaterName}
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-1 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {item.UpdaterRole
                                                      ? item.UpdaterRole
                                                      : item.Action ===
                                                          "Idea Updated by Admin" ||
                                                        item.Action ===
                                                          "Idea Reinitiated by Admin"
                                                      ? "Admin"
                                                      : item.Action ===
                                                        "Idea was reviewed by MBB"
                                                      ? "MBB"
                                                      : item.Action ===
                                                        "Idea was reviewed by LDT"
                                                      ? "LDT"
                                                      : item.UpdaterOHR ===
                                                        this.state.ideaItem
                                                          .OwnerOHR
                                                      ? "Owner"
                                                      : "BB"}
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-2 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {item.UpdaterAction
                                                      ? item.UpdaterAction
                                                      : item.Action ===
                                                        "Idea Reinitiated by Admin"
                                                      ? "Idea Reinitiated"
                                                      : item.Action ===
                                                        "Idea Updated by Admin"
                                                      ? item.ApprovalStatus
                                                      : item.WorkflowStatus ===
                                                          "Completed" ||
                                                        item.WorkflowStatus ===
                                                          "Approve" ||
                                                        item.WorkflowStatus ===
                                                          "Approved"
                                                      ? "Approved"
                                                      : item.WorkflowStatus ===
                                                          "Rejected" ||
                                                        item.WorkflowStatus ===
                                                          "Reject"
                                                      ? "Rejected"
                                                      : item.WorkflowStatus ===
                                                        REQUEST_CANCELLATION
                                                      ? REQUEST_CANCELLATION
                                                      : item.WorkflowStatus ===
                                                        "Pending Documents"
                                                      ? "Discard Cancellation"
                                                      : item.WorkflowStatus ===
                                                        "Cancelled"
                                                      ? "Cancelled"
                                                      : "Sent Back"}
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-3 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {item.Comment
                                                      ? item.Comment
                                                      : "-"}
                                                  </label>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        )}
                        <div
                          id="Approval Status"
                          className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                        >
                          <div className="u-container-layout u-container-layout-7">
                            <p
                              className="u-align-left u-text u-text-2"
                              style={{ marginBottom: 10 }}
                            >
                              {" "}
                              Request Cancellation details
                            </p>
                            <div className="row">
                              <div className="col-md-2">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  <span style={{ color: "red" }}>*</span>{" "}
                                  Cancellation Status
                                </label>{" "}
                              </div>
                              <div className="col-md-2">
                                <Select
                                  isSearchable={false}
                                  options={this.renderCancelRequestOption()}
                                  styles={
                                    this.state.selectStyle
                                      .workFlowStatusSelectedOption
                                  }
                                  placeholder="Choose Status"
                                  onChange={this.handleCancelRequestChange}
                                />
                              </div>
                            </div>
                            <div
                              className="col-md-9"
                              style={{ marginBottom: 5, width: "100%" }}
                            />

                            <div className="row">
                              <div className="col-md-6">
                                <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                  <span className="u-text-black form-attribute">
                                    <span style={{ color: "red" }}>*</span>
                                    &nbsp;Comments&nbsp;
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 12,
                                        fontStyle: "italic",
                                        color: "grey",
                                      }}
                                    >
                                      {" "}
                                      (max. 500 chars)
                                    </span>
                                  </span>
                                </p>
                              </div>
                              <div
                                className="col-md-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  onClick={(e) =>
                                    this.toggleTranscriptFunction(10)
                                  }
                                  src={require("../assets/images/voice.png")}
                                  className="w100"
                                  alt=""
                                  style={{ width: "24px", cursor: "pointer" }}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                {this.state.CommentEmpty ? (
                                  <textarea
                                    className="textarea-css form-attribute"
                                    style={{
                                      maxWidth: "100%",
                                      border: "1px solid lightgrey",
                                      ...this.state.selectStyle.comment,
                                    }}
                                    placeholder="Please provide your comments regarding the cancellation request."
                                    onChange={this.handleCommentChange}
                                    rows={3}
                                    cols={88}
                                    value={this.state.Comment}
                                  />
                                ) : (
                                  <textarea
                                    className="textarea-css form-attribute"
                                    style={{
                                      maxWidth: "100%",
                                      border: "1px solid lightgrey",
                                      ...this.state.selectStyle.comment,
                                    }}
                                    placeholder="Please provide your comments regarding the cancellation request."
                                    onChange={this.handleCommentChange}
                                    rows={3}
                                    cols={88}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          id="Approval Status"
                          className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                        >
                          <div className="u-container-layout u-container-layout-7">
                            <p
                              className="u-align-left u-text u-text-2"
                              style={{ marginBottom: 10 }}
                            >
                              Approval details
                            </p>
                            <div className="row">
                              <div className="col-md-2">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  <span style={{ color: "red" }}>*</span>{" "}
                                  Approval Status
                                </label>{" "}
                              </div>
                              <div className="col-md-2">
                                <Select
                                  value={this.state.workFlowStatusSelectedOption}
                                  isSearchable={false}
                                  options={this.renderStatusOptions()}
                                  styles={this.state.selectStyle.workFlowStatusSelectedOption}
                                  placeholder="Choose Status"
                                  onChange={this.handleAccountNameChange}
                                />
                              </div>
                            </div>
                            <div
                              className="col-md-9"
                              style={{ marginBottom: 5, width: "100%" }}
                            />
                            <div className="row">
                              <div className="col-md-7">
                                <div className="row">
                                  <div className="col-md-11">
                                    <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                      <span className="u-text-black form-attribute">
                                        <span style={{ color: "red" }}>*</span>
                                        &nbsp;Comments&nbsp;
                                        <span
                                          style={{
                                            fontWeight: "normal",
                                            fontSize: 12,
                                            fontStyle: "italic",
                                            color: "grey",
                                          }}
                                        >
                                          (max. 500 chars)
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                  <div
                                    className="col-md-1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      onClick={(e) =>
                                        this.toggleTranscriptFunction(2)
                                      }
                                      src={require("../assets/images/voice.png")}
                                      className="w100"
                                      alt=""
                                      style={{
                                        width: "24px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <textarea
                                  className="textarea-css form-attribute"
                                  style={{
                                    maxWidth: "100%",
                                    border: "1px solid lightgrey",
                                    ...this.state.selectStyle.comment,
                                  }}
                                  rows={3}
                                  cols={88}
                                  placeholder="Please provide your comments in support of the Approval decision."
                                  onChange={this.handleCommentChange}
                                  value={this.state.Comment}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                   
                    

                   





                    

                    


                  

<div
                      className=""
                      style={{
                        borderTop: "1px solid lightgray",
                        margin: "20px 0px",
                      }}
                    ></div>


                    <div className="btn-group" style={{ marginTop: 15 }}>
                      <button
                        className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                        onClick={this.submitIdea}
                      >
                        Save
                      </button>
                      <button
                        className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                        onClick={this.cancelEvent}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default E360SPOCApprovalPage;
