import React, { Component } from "react";
import {
  GET_IDEA_URL,
  REVIEW_IDEA_URL,
  APPROVED,
  PENDING,
  SENT_BACK,
  REJECTED,
  OTHER_REGIONS,
  DISPLAYPROJECTTHEME,
  GET_METADATA,
  DATA_BRIDGE_CAMPAIGN,
  X_POLLINATION_CAMPAIGN,
  ACCESS_TOKEN,
  USER_DATA,
  REQUEST_CANCELLATION,
  YESORNO,SOLUTIONCOMPLEXITY,AIFIRST,AI_DOC_LINK,
} from "../assets/constants/constants";
import Toast from "light-toast";
import Select from "react-select";
// import DatePicker from "react-datepicker";
import {
  selectValidation as selectStyle,
  textBoxValidation,
} from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";
import UpdatedSpeech from "./UpdatedSpeech";
import ValueDelivery from "../utils/ValueDelivery";

export class ReviewIdeaPage extends Component {
  sessionData = JSON.parse(localStorage.getItem(USER_DATA));
  accessToken = localStorage.getItem(ACCESS_TOKEN);
  constructor(props) {
    super(props);
    console.log(props);
    const isApprovalPage = `${this.props.location.approval}`;
    let propsData = this.props.location;
    console.log(propsData);
    this.state = {
      ideaItem: {},
      workflowStatus: "",
      Comment: "",
      accountName: "",
      campaignName: "",
      campaignSelectedOption: null,
      serviceLineSelectedOption: null,
      subServiceLineSelectedOption: null,
      showAdditionalDetails: false,
      serviceLineList: [],
      subServiceLineList: [],
      workFlowStatusList: [],
      workFlowStatusSelectedOption: null,
      certificationLevelsList: [],
      certLevelsSelectedOption: null,
      certificationWorkTypeList: [],
      certWorkTypeSelectedOption: null,
      workTypeEnabled: false,
      digitalProjectEnabled: false,
      transformationLeversList: [],
      transSelectedOption: null,
      methodologyList: [],
      methodologySelectedOptions: null,
      databridgeThemeList: [],
      databridgeThemeSelectedOptions: null,
      dataBridgeProjectTheme: null,
      digitalProjectTypeList: [],
      digitalProjectTypeSelectedOptions: null,
      subCampaignList: [],
      subCampaignName: null,
      subCampaignOption: null,
      expectedDate: new Date(),
      leadNameList: [],
      blackbeltOHRList: [],
      LDTLeadSelectedOption: null,
      selectStyle: {},
      showBIForm: false,
      businessImpactMasterList: null,
      businessImpactValueArray: [],
      fteTotal: 0,
      dollarTotal: 0.0,
      dataBridgeCampaign: false,
      xPollinationCampaign: false,
      replicationIDValidated: true,
      approvalPage: isApprovalPage,
      campaignList: [],
      campaignTypeText: "",
      mbbSelectedOption: null,
      mbbList: [],
      mbbName: "",
      mbbOHR: "",
      propsData: propsData,
      cancelRequest: true,
      showDigital: false,
      cancelRequestOption: [
        {
          WorkflowStatusId: 1,
          WorkflowStatusName: "Approve Cancellation",
        },
        {
          WorkflowStatusId: 2,
          WorkflowStatusName: "Discard Cancellation",
        },
      ],
      Role: "BB",
      speechModal: false,
      previousTextForSpeech: "",
      CommentEmpty: false,
      chatGPTTheme: false,
      chatGptOptions: [
        {
          value: 0,
          label: "Predict The Next Best Action",
        },
        {
          value: 1,
          label: "Automated Narrative Building and Translation",
        },
        {
          value: 2,
          label: "Data Extraction and Leakage Prevention",
        },
        {
          value: 3,
          label: "Coding Effort Reduction",
        },
      ],
      chatGptSelectedOption: [],
      finalImpactDollar: 0,
      finalImpactFTE: 0,
      impactOptionSelected: null,
      finalImpactDollar: 0,
      finalImpactFTE: 0,
      businessImpactValueArray: [],
      monetizableProjectSelectedOption: null,
      monetizableCheckbox: null,
      monetizableStatus: false,
      monetizationStatusSelectedOption: null,
      SowID: "",
      FPnANameMonetization: "",
      FPnAOHRMonetization: "",
      FPnAFileNameMonetization: "",
      FPnAFileTypeMonetization: "",
      FPnAFileMonetization: "",
      appropriateAttachment: false,
      lastUpdaterAction: "",
      logRoleState: "",
      dataBridgeData: "",
      displayProjectTheme: false,
      ideaApprovalStatus: "",
      // AI First states starts here
      IsIdeaReplicableSelectedOption:[],
      replicabilityIndexOptions: [
        {
          value: 1,
          label: "1",
        },
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
        {
          value: 4,
          label: "4",
        },
        {
          value: 5,
          label: "5",
        },
      ],
      solutionFeasibleSelectedOption:[],
      solutionComplexitySelectedOption:[],
      
      AIBRD:"",
      AIReason:"",
      showHrefModal:false,
      campaignInsight:"",
      
      // QA role
      isQAUser:false


    };
    this.isExceedlimit = false;
  }

  async componentDidMount() {
    Toast.loading("Fetching Idea...", () => {});
    await this.fetchIdea();
    Toast.hide();
    Toast.loading("Fetching Metadata...", () => {});
    await this.fetchMetadata();
    await this.fetchBlackBeltOptions();

    Toast.hide();
  }

  fetchMetadata = () => {
    return new Promise((resolve, reject) => {
      const param = {
        EntityType: "",
        EntityName: "",
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.accessToken,
        },
        body: JSON.stringify(param),
      };
      fetch(GET_METADATA, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setState(
            {
              serviceLineList: data ? data.ServiceLines : [],
              subServiceLineList: data ? data.ServiceLines : [],
              workFlowStatusList: data ? data.WorkflowStatus : [],
              certificationLevelsList: data ? data.CertificationLevels : [],
              certificationWorkTypeList: data ? data.WorkType : [],
              transformationLeversList: data ? data.TransformationLevers : [],
              methodologyList: data ? data.Methodologies : [],
              databridgeThemeList: data ? data.DataBridgeThemes : [],
              subCampaignList: data ? data.SubCampaigns : [],
              digitalProjectTypeList: data ? data.DigitalProjectTypes : [],
              businessImpactMasterList: data ? data.BIMasterDataVerse : [],
              campaignList: data ? data.Campaigns : [],
            },
            () => {
              this.IsQAUser()
              this.setSelectedOptions();
              // this.state.serviceLineList.map((item) => {
              //     if(){
              //         this.setState({
              //             serviceLineSelectedOption:{
              //                 value: ,
              //                 label:
              //             }
              //         })
              //     }
              // });
              if(this.state.ideaItem.CampaignName && this.state.campaignList){
                this.state.campaignList.map((campaign)=>{
                  if(campaign.CampaignName === this.state.ideaItem.CampaignName){
                    this.setState({campaignInsight:campaign.CampaignDescr},()=>{})
                  }
                })
              }
              this.setState({ Comment: "" });
              resolve();
            }
          );
        })
        .catch((error) => {
          Toast.fail(
            "Error while getting main metadata. Please try again!",
            3000,
            () => {
              console.log(JSON.stringify(error));
              this.setState(
                {
                  serviceLineList: [],
                  subServiceLineList: [],
                  workFlowStatusList: [],
                  certificationLevelsList: [],
                  certificationWorkTypeList: [],
                  transformationLeversList: [],
                  methodologyList: [],
                  databridgeThemeList: [],
                  subCampaignList: [],
                  digitalProjectTypeList: [],
                  businessImpactMasterList: [],
                },
                () => {
                  resolve();
                }
              );
            }
          );
        });
    });
  };


  IsQAUser = () => {
    return new Promise((resolve, reject) => {
      const param = {
        EntityType: "OHR",
        EntityName: this.sessionData
          ? this.sessionData.profile
            ? this.sessionData.profile.oHRId
            : ""
          : "",
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.accessToken,
        },
        body: JSON.stringify(param),
      };
      fetch(GET_METADATA, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          data.PowerAppUsers.filter((item) => {
            const userRole = JSON.parse(item.pg_Role).pg_role.trim();
            if (userRole === "QA" && parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
              this.setState({ isQAUser: true }, () => {})
            }
          })
          resolve();
        })
        .catch((error) => {
          Toast.hide();
          console.log(JSON.stringify(error));
        });
    });
  };




  fetchBlackBeltOptions = () => {
    Toast.loading("Fetching Blackbelts. Please wait...", () => {});
    let entityType = "BB";
    let entityName = this.state.ideaItem.RegionManual;
    if (this.state.ideaItem.RegionManual === OTHER_REGIONS) {
      entityType = "BBOTHER";
      //DNA table has vertical with space around dash (' - ') whereas User Role table has NO space around dash ('-')
      entityName = this.state.ideaItem.VerticalManual.replace(" - ", "-");
    }
    const param = {
      EntityType: entityType,
      EntityName: entityName,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + this.accessToken,
      },
      body: JSON.stringify(param),
    };
    fetch(GET_METADATA, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        Toast.hide();
        this.setState({
          mbbList:
            data.DataverseUserRoles !== undefined
              ? data.DataverseUserRoles
              : [],
        });
      })
      .catch((error) => {
        Toast.hide();
        Toast.fail(
          "Error occured while getting BlackBelt details. Please try again!",
          15000,
          () => {
            console.log(JSON.stringify(error));
          }
        );
      });
  };

  fetchLDTLeadOptions = () => {
    return new Promise((resolve, reject) => {
      let entityType = "BB";
      let entityName = this.state.ideaItem.RegionManual;
      if (this.state.ideaItem.RegionManual === OTHER_REGIONS) {
        entityType = "BBOTHER";
        entityName = this.state.ideaItem.VerticalManual.replace(" - ", "-");
      }
      const param = {
        EntityType: entityType,
        EntityName: entityName,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.accessToken,
        },
        body: JSON.stringify(param),
      };
      fetch(GET_METADATA, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          Toast.hide();
          this.setState({
            leadNameList:
              data.DataverseUserRoles !== undefined
                ? data.DataverseUserRoles
                : [],
          });
        })
        .then((data) => {
          // let i=0;
          let filteredLDTLeadList = this.state.leadNameList && this.state.leadNameList.filter((item) => {
            const userRole = JSON.parse(item.pg_Role).pg_role;
            return (
              userRole === "LDT Lead" &&
              parseInt(this.state.ideaItem.OwnerOHR) !== parseInt(item.pg_ohr)
            );

            // if(i===0){
            //     let ideaItem = this.state.ideaItem;
            //     ideaItem.LDTId = item.pg_ohr;
            //     const leadUser = this.state.blackbeltOHRList.find(ohritem => ohritem.EMPLOYEE_OHR === item.pg_ohr);
            //     if (leadUser) {
            //         const leadName = leadUser.EMPLOYEE_NAME;
            //         ideaItem.LDTLeadName = leadName;
            //         this.setState({
            //             ideaItem,
            //             LDTLeadSelectedOption: {
            //                 label: leadName,
            //                 value: item.pg_ohr
            //             }
            //         });
            //     }
            // }
            // filteredIdeaArray = this.state.originalIdeaList.filter((ideaItem) => {
            //     return (parseInt(this.state.ownerOHR) === ideaItem.OwnerOHR);
            // });
          });
          let ideaItem = this.state.ideaItem;
          ideaItem.LDTId = filteredLDTLeadList[0].pg_ohr;
          ideaItem.LDTLeadName = filteredLDTLeadList[0].pg_username;
          this.setState({
            ideaItem,
            leadNameList: filteredLDTLeadList,
            LDTLeadSelectedOption: {
              label: filteredLDTLeadList[0].pg_username,
              value: filteredLDTLeadList[0].pg_ohr,
            },
          });
          resolve();
        })
        .catch((error) => {
          Toast.hide();
          Toast.fail(
            "Error while getting LDT Lead metadata idea. Please try again!",
            3000,
            () => {
              console.log(JSON.stringify(error));
            }
          );
        });
    });
  };

  setSelectedOptions = () => {
    if (this.state.ideaItem.MBBOHR) {
      this.setState({
        mbbSelectedOption: {
          value: this.state.ideaItem.MBBOHR,
          label: this.state.ideaItem.MBBName,
        },
        mbbOHR: this.state.ideaItem.MBBOHR,
        mbbName: this.state.ideaItem.MBBName,
      });
    }
    this.state.serviceLineList.map((item) => {
      if (this.state.ideaItem.ServiceLineName === item.ServiceLineName) {
        this.setState({
          serviceLineSelectedOption: {
            value: item.ServiceLineName,
            label: item.ServiceLineName,
          },
        });
      }
      return 1;
    });
    this.state.subServiceLineList.map((item) => {
      if (this.state.ideaItem.SubServiceLine === item.SubServiceLineName) {
        this.setState({
          subServiceLineSelectedOption: {
            value: item.SubServiceLineId,
            label: item.SubServiceLineName,
          },
        });
      }
      return 1;
    });
    this.state.campaignList.map((item) => {
      if (this.state.ideaItem.CampaignName === item.CampaignName) {
        this.setState(
          {
            campaignTypeText: item.CampaignName,
            campaignSelectedOption: {
              value: item.CampaignId,
              label: item.CampaignName,
            },
          },
          () => {}
        );
      }
      return 1;
    });
    this.state.databridgeThemeList.map((item) => {
      if (this.state.ideaItem.DataBridgeTheme === item.DBProjectThemeName) {
        this.setState({
          databridgeThemeSelectedOptions: {
            value: item.ThemeId,
            label: item.DBProjectThemeName,
          },
        });
      }
      return 1;
    });
    this.state.subCampaignList.map((item) => {
      if (this.state.ideaItem.SubCampaignName === item.SubCampaignName) {
        this.setState({
          subCampaignOption: {
            value: item.SubCampaignId,
            label: item.SubCampaignName,
          },
        });
      }
      return 1;
    });
  };

  fetchIdea = () => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.accessToken,
        },
      };
      // fetch(`${GET_IDEA_URL}IdeaId=941`, requestOptions)
      fetch(
        `${GET_IDEA_URL}IdeaId=${this.props.location.state}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("fetch Idea", data);
          this.setState(
            {
              ideaItem: data.ResultSets.Table1[0],
              workflowStatus: data.ResultSets.Table1[0].WorkflowStatus,
              Comment: data.ResultSets.Table1[0].Comment,
              reviewComments: data.ReviewComments.Table1,
              finalReviewComments: data.FinalReviewComments.Table1,
              expectedDate:data.ResultSets.Table1[0].ExpectedFinishDate === null? new Date(): new Date(data.ResultSets.Table1[0].ExpectedFinishDate),
              dataBridgeCampaign:data.ResultSets.Table1[0].CampaignName === DATA_BRIDGE_CAMPAIGN,
              xPollinationCampaign:data.ResultSets.Table1[0].CampaignName === X_POLLINATION_CAMPAIGN,
              dataBridgeProjectTheme: data.ResultSets.Table1[0].DataBridgeTheme? data.ResultSets.Table1[0].DataBridgeTheme: "",
              subCampaignName: data.ResultSets.Table1[0].SubCampaignName? data.ResultSets.Table1[0].SubCampaignName: "",
              dataBridgeData: data.ResultSets? data.ResultSets.Table1[0].CampaignName: "",
              
              isAI:data.ResultSets ? data.ResultSets.Table1[0].isAI:[],
              AIBRD: data.ResultSets ? data.ResultSets.Table2 ? data.ResultSets.Table2[0].LinkBRD : "":"",
              AIReason: data.ResultSets ? data.ResultSets.Table2 ? data.ResultSets.Table2[0].AIReason : "":"",
            },
            () => {

              


              if (this.state.ideaItem.CreatedDate) {
                let created = new Date(this.state.ideaItem.CreatedDate);
                let abc = new Date(DISPLAYPROJECTTHEME);
                if (created.getTime() < abc.getTime()) {
                  //console.log("created date is lesser")
                  this.setState({ displayProjectTheme: true });
                } else {
                  this.setState({ displayProjectTheme: false });
                  //console.log("created date is greater ")
                }
              }

              if (this.state.reviewComments) {
                let logAction = this.state.reviewComments[
                  this.state.reviewComments.length - 1
                ].UpdaterAction
                  ? this.state.reviewComments[
                      this.state.reviewComments.length - 1
                    ].UpdaterAction
                  : this.state.reviewComments[
                      this.state.reviewComments.length - 1
                    ].Action;
                let logRole =
                  this.state.reviewComments[
                    this.state.reviewComments.length - 1
                  ].UpdaterRole;
                this.setState(
                  {
                    logAction: logAction,
                    logRoleState: logRole,
                  },
                  () => {
                    //console.log("log action is",  this.state.reviewComments[this.state.reviewComments.length-1].UpdaterAction)
                  }
                );
              }
              if (
                this.state.reviewComments !== undefined &&
                this.state.reviewComments !== null
              ) {
                var keys = Object.keys(this.state.reviewComments);
                var last = keys[keys.length - 1];
                let varLastUpdaterAction =
                  this.state.reviewComments[last].UpdaterAction;
                this.setState(
                  { lastUpdaterAction: varLastUpdaterAction },
                  () => {}
                );
              }
              //this.setCampaignName();
              
              
              
              
              if (this.state.dataBridgeData === "Data Bridge") {
                this.setState(
                  {
                    transSelectedOption: {
                      value: 0,
                      label: "Data",
                    },
                  },
                  () => {}
                );
              }
              if ((this.state.dataBridgeData === "AI First" ||  this.state.dataBridgeData === "AI First - Enterprise 360" || this.state.dataBridgeData === "AI First - C&H Gen AI hackathon")) {
                this.setState(
                  {
                    transSelectedOption: {
                      value: 0,
                      label: "AI",
                    },
                  },
                  () => {}
                );
              }

              this.fetchLDTLeadOptions();
              //this.setServiceLineName();
              // this.setState({Comment:""},()=>console.log(this.state.Comment))
              if (
                this.state.ideaItem.WorkflowStatus ===
                  "Cancellation Requested" &&
                this.state.ideaItem.ApprovalStatus === "NA"
              ) {
                this.setState({ CommentEmpty: true, Comment: "" }, () => {
                  console.log(this.state.CommentEmpty);
                });
              }
              if (
                this.state.ideaItem.WorkflowStatus === "Approve" &&
                this.state.ideaItem.ApprovalStatus === "Cancellation Requested"
              ) {
                this.setState({ CommentEmpty: true, Comment: "" }, () => {
                  console.log(this.state.CommentEmpty);
                });
              }
              // console.log("campaign name from ideaitem =>",this.state.ideaItem.CampaignName)
              if (
                this.state.ideaItem.CampaignName === "Gen AI"
              ) {
                this.setState({ chatGPTTheme: true }, () => {
                  
                  if (this.state.chatGPTTheme) {
                    let options = [];
                    this.state.chatGptOptions.map((item) => {
                      if (item.label === this.state.ideaItem.SubCampaignName) {
                        options.push({
                          value: item.value,
                          label: item.label,
                        });
                        this.setState({ chatGptSelectedOption: options });
                      }
                    });
                  }
                });
              }
              if (this.state.chatGPTTheme) {
                let options = [];
                this.state.chatGptOptions.map((item) => {
                  if (item.label === this.state.ideaItem.SubCampaignName) {
                    
                    this.setState({ chatGptSelectedOption: options });
                  }
                });
              }
              resolve();
            }
          );
        })
        .catch((error) => {
          Toast.fail(
            "Error while fetching Idea details. Please try again!",
            3000,
            () => {}
          );
          reject();
        });
    });
  };

  // setCampaignName = () => {
  //     var opts = document.getElementById('ideationtype').childNodes;
  //     for (var i = 0; i < opts.length; i++) {
  //         if (i === this.state.ideaItem.CampaignTypeId - 1) {
  //             this.setState({
  //                 campaignName: opts[i].innerText
  //             });
  //         }
  //     }
  // }

  handleCommentChange = (event) => {
    this.setState({
      Comment: event.target.value,
    });
  };

  handleTranscriptCommentChange = (e) => {
    let trimmedText = e.trim();
    this.setState({
      Comment: trimmedText,
    });
  };

  validate = () => {
    let isValid = true;
    this.isExceedlimit = false;
    let styleStateObj = this.state.selectStyle;

    
    

    if (this.state.campaignTypeText === "") {
      isValid = false;
      styleStateObj.campaignTypeText = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.campaignTypeText = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
      if (this.state.campaignTypeText === DATA_BRIDGE_CAMPAIGN) {
        // if(this.state.dataBridgeProjectTheme === ""){
        //     isValid = false;
        //     styleStateObj.dataBridgeProjectTheme = selectStyle(false);
        //     this.setState({
        //         selectStyle: styleStateObj
        //     });
        // }else{
        //     styleStateObj.dataBridgeProjectTheme = selectStyle(true);
        //     this.setState({
        //         selectStyle: styleStateObj
        //     });
        // }
      }
      if (this.state.campaignTypeText === X_POLLINATION_CAMPAIGN) {
        if (this.state.subCampaignName === "") {
          isValid = false;
          styleStateObj.subCampaignName = selectStyle(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.subCampaignName = selectStyle(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
      }
    }

    if (this.state.ideaItem.ServiceLineName === "") {
      isValid = false;
      styleStateObj.ServiceLineName = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.ServiceLineName = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }

    if (this.state.ideaItem.SubServiceLine === "") {
      isValid = false;
      styleStateObj.subServiceLine = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.subServiceLine = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }

    if (this.state.ideaItem.IdeaName === "") {
      isValid = false;
      styleStateObj.IdeaName = textBoxValidation(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.IdeaName = textBoxValidation(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }

    
    if (this.state.ideaItem.Problem === "") {
      isValid = false;
      styleStateObj.Problem = textBoxValidation(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      if (this.state.ideaItem.Problem.length > 2000) {
        Toast.fail(
          "Problem statement cannot be more than 2000 characters, please change and resubmit.",
          3000
        );
        isValid = false;
        styleStateObj.Problem = textBoxValidation(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.Problem = {
          ...textBoxValidation(true),
          border: "1px solid lightgrey",
        };
        this.setState({
          selectStyle: styleStateObj,
        });
      }
    }

    if (this.state.ideaItem.Solution === "") {
      isValid = false;
      styleStateObj.Solution = textBoxValidation(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      if (this.state.ideaItem.Solution.length > 2000) {
        Toast.fail(
          "Solution statement cannot be more than 2000 characters, please change and resubmit.",
          3000
        );
        isValid = false;
        styleStateObj.Solution = textBoxValidation(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.Solution = {
          ...textBoxValidation(true),
          border: "1px solid lightgrey",
        };
        this.setState({
          selectStyle: styleStateObj,
        });
      }
    }
    if (
      this.state.ideaItem.NewOrReplicatedIdea &&
      this.state.ideaItem.ReplicationProjectId === ""
    ) {
      isValid = false;
      styleStateObj.ReplicationProjectId = textBoxValidation(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      if (
        this.state.ideaItem.NewOrReplicatedIdea &&
        this.state.ideaItem.ReplicationProjectId !== "" &&
        !this.state.replicationIDValidated
      ) {
        // if (!this.validateReplicationId(this.state.ideaItem.ReplicationProjectId)) {
        isValid = false;
        styleStateObj.ReplicationProjectId = textBoxValidation(false);
        this.setState({
          selectStyle: styleStateObj,
        });
        // } else {
        //     styleStateObj.ReplicationProjectId = { ...textBoxValidation(true), border: "1px solid lightgrey" };
        //     this.setState({
        //         selectStyle: styleStateObj
        //     })
        // }
      } else {
        styleStateObj.ReplicationProjectId = {
          ...textBoxValidation(true),
          border: "1px solid lightgrey",
        };
        this.setState({
          selectStyle: styleStateObj,
        });
      }
    }

    if (this.state.workFlowStatusSelectedOption === null) {
      isValid = false;
      styleStateObj.workFlowStatusSelectedOption = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.workFlowStatusSelectedOption = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }

    if (this.state.expectedDate === "") {
      isValid = false;
      styleStateObj.expectedDate = textBoxValidation(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.expectedDate = {
        ...textBoxValidation(true),
        border: "1px solid lightgrey",
      };
      this.setState({
        selectStyle: styleStateObj,
      });
    }

    if (!this.state.Comment || this.state.Comment === "") {
      isValid = false;
      styleStateObj.comment = textBoxValidation(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      if (this.state.Comment.length > 500) {
        Toast.fail(
          "Comment cannot be more than 500 characters, please change and resubmit.",
          3000
        );
        isValid = false;
        this.isExceedlimit = true;
        styleStateObj.comment = textBoxValidation(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.comment = {
          ...textBoxValidation(true),
          border: "1px solid lightgrey",
        };
        this.setState({
          selectStyle: styleStateObj,
        });
      }
    }
    if (
      this.state.showAdditionalDetails &&
      (this.state.certLevelsSelectedOption === null || !this.state.certLevelsSelectedOption || this.state.certLevelsSelectedOption===undefined)
    ) {
      isValid = false;
      styleStateObj.certLevelsSelectedOption = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.certLevelsSelectedOption = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }

    if (
      this.state.showAdditionalDetails &&
      this.state.ideaItem.LDTId === null
    ) {
      isValid = false;
      styleStateObj.LDTId = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.LDTId = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }
    if (
      this.state.showAdditionalDetails &&
      this.state.certWorkTypeSelectedOption === null
    ) {
      if (
        this.state.certLevelsSelectedOption &&
        this.state.transSelectedOption &&
        this.state.certLevelsSelectedOption.label === "GB" &&
        (this.state.transSelectedOption.label === "Continuous Improvement" ||
          this.state.transSelectedOption.label === "Data")
      ) {
        isValid = false;
        styleStateObj.certWorkTypeSelectedOption = selectStyle(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.certWorkTypeSelectedOption = selectStyle(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }
    } else {
      styleStateObj.certWorkTypeSelectedOption = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }

    // if (this.state.showAdditionalDetails && this.state.digitalProjectTypeSelectedOptions === null) {
    //     if (this.state.transSelectedOption && this.state.transSelectedOption.label === "Digital" ) {
    //         isValid = false;
    //         styleStateObj.digitalProjectTypeSelectedOptions = selectStyle(false);
    //         this.setState({
    //             selectStyle: styleStateObj
    //         })
    //     } else {
    //         styleStateObj.digitalProjectTypeSelectedOptions = selectStyle(true);
    //         this.setState({
    //             selectStyle: styleStateObj
    //         })
    //     }
    // }
    // else {
    //     styleStateObj.digitalProjectTypeSelectedOptions = selectStyle(true);
    //     this.setState({
    //         selectStyle: styleStateObj
    //     })
    // }

    if (
      this.state.certLevelsSelectedOption &&
      this.state.certLevelsSelectedOption.label === "Lean"
    ) {
      if (
        this.state.showAdditionalDetails &&
        this.state.digitalProjectTypeSelectedOptions === null
      ) {
        if (
          this.state.transSelectedOption &&
          this.state.transSelectedOption.label === "Digital"
        ) {
          isValid = false;
          styleStateObj.digitalProjectTypeSelectedOptions = selectStyle(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.digitalProjectTypeSelectedOptions = selectStyle(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
      } else {
        styleStateObj.digitalProjectTypeSelectedOptions = selectStyle(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }
    }
    if (
      this.state.certLevelsSelectedOption &&
      this.state.certLevelsSelectedOption.label === "GB"
    ) {
      if (
        this.state.showAdditionalDetails &&
        this.state.digitalProjectTypeSelectedOptions !== null
      ) {
        if (
          this.state.transSelectedOption &&
          this.state.transSelectedOption.label === "Digital"
        ) {
          isValid = false;
          styleStateObj.digitalProjectTypeSelectedOptions = selectStyle(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.digitalProjectTypeSelectedOptions = selectStyle(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
      } else {
        styleStateObj.digitalProjectTypeSelectedOptions = selectStyle(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }
    }

    if (
      this.state.showAdditionalDetails &&
      this.state.transSelectedOption === null
    ) {
      isValid = false;
      styleStateObj.transSelectedOption = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.transSelectedOption = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }
    
    if (
      this.state.showAdditionalDetails &&
      (this.state.methodologySelectedOptions === null || 
      this.state.methodologySelectedOptions === undefined ||
      !this.state.methodologySelectedOptions)
    ) {
      //Toast.fail(" methodologies selected -- test",3000,()=>{})
      isValid = false;
      styleStateObj.methodologySelectedOptions = selectStyle(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      styleStateObj.methodologySelectedOptions = selectStyle(true);
      this.setState({
        selectStyle: styleStateObj,
      });
    }

    if (
      !this.state.ideaItem.MBBOHR &&
      this.state.ideaItem.WorkflowStatus !== REQUEST_CANCELLATION &&
      this.state.ideaItem.ApprovalStatus !== REQUEST_CANCELLATION
    ) {
      if (this.state.mbbOHR === "") {
        isValid = false;
        styleStateObj.mbbUserName = selectStyle(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.mbbUserName = selectStyle(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }
    }
    if (this.state.chatGPTTheme === true) {
      if (
        this.state.chatGptSelectedOption.length === 0 ||
        this.state.chatGptSelectedOption == [] ||
        this.state.chatGptSelectedOption === null ||
        this.state.chatGptSelectedOption === "" ||
        this.state.chatGptSelectedOption === undefined ||
        !this.state.chatGptSelectedOption

      ) {
        isValid = false;
        styleStateObj.chatGptSelectedOption = selectStyle(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.chatGptSelectedOption = selectStyle(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }
    }

    if (
      this.state.campaignSelectedOption &&
      this.state.campaignSelectedOption.label === "AI First"
    ) {
      if (this.state.showAdditionalDetails) {
      }
    }

    // validations copied from final-review-idea-page starts here

    if (
      this.state.certLevelsSelectedOption &&
      this.state.certLevelsSelectedOption.label === "Lean"
    ) {
      if (this.state.impactOptionSelected === null) {
        isValid = false;
        Toast.fail("Please select Value Delivery Type", 3000, () => {});
      }
    }

    if (
      this.state.certLevelsSelectedOption &&
      this.state.certLevelsSelectedOption.label === "Lean" &&
      this.state.impactOptionSelected &&
      this.state.impactOptionSelected.label !== "No Value Delivery"
    ) {
      if (this.state.businessImpactValueArray.length === 0) {
        Toast.fail(
          "Value Delivery cannot be empty, please fill and resubmit.",
          3000,
          () => {}
        );
        isValid = false;
      } else {
        if (this.state.finalImpactDollar <= 0) {
          Toast.fail(
            "Total Dollar Impact cannot be zero. Please check and resubmit.",
            3000,
            () => {}
          );
          isValid = false;
        }
        if (
          this.state.finalImpactFTE > 0 &&
          this.state.finalImpactDollar <= 0
        ) {
          Toast.fail(
            "Total Impact Dollar cannot be zero when FTE is non-zero. Please check and resubmit.",
            3000,
            () => {}
          );
          isValid = false;
        }
        this.state.businessImpactValueArray.filter((item) => {
          if (
            parseFloat(item.DollarImpact) === 0 ||
            parseFloat(item.DollarImpact) === "" ||
            !item.DollarImpact ||
            item.DollarImpact === null
          ) {
            isValid = false;
            Toast.fail("Dollar Impact value cannot be zero.", 3000, () => {});
          }
          if (item && item.isFTEPresent === true) {
            if (parseFloat(item.FTEImpact)) {
            } else {
              isValid = false;
              Toast.fail("FTE Impact value cannot be zero", 3000, () => {});
            }
            if (parseFloat(item.FTEImpact) === 0) {
              isValid = false;
              Toast.fail("FTE Impact value cannot be zero", 3000, () => {});
            }
            if (parseFloat(item.FTEImpact) > parseFloat(item.DollarImpact)) {
              isValid = false;
              Toast.fail(
                "FTE impact value cannot be greater than dollar value",
                3000,
                () => {}
              );
            }
          }
        });
      }

      if (
        this.state.impactOptionSelected &&
        this.state.impactOptionSelected.label !== "No Value Delivery"
      ) {
        // if (this.state.monetizableProjectSelectedOption === null) {
        //   isValid = false;
        //   Toast.fail("Please select monetizatable project", 3000, () => {});
        // }
        if (this.state.monetizableProjectSelectedOption === null || 
          this.state.monetizableProjectSelectedOption.length === 0 ||
          this.state.monetizableProjectSelectedOption === undefined 
        ) {
          isValid = false;
          Toast.fail("Please select monetizatable project", 5000, () => { });
        }
      }

      if (
        this.state.impactOptionSelected.label ===
        "Value Delivered to Client + Genpact"
      ) {
        if (this.state.businessImpactValueArray.length > 0) {
          let impactGenpactTrue = this.state.businessImpactValueArray.some(
            (vendor) => vendor["ImpactType"] === "Impact at Genpact"
          );
          let impactClientTrue = this.state.businessImpactValueArray.some(
            (vendor) => vendor["ImpactType"] === "Impact at Client"
          );
          if (impactGenpactTrue === false) {
            isValid = false;
            Toast.fail("Please add both Impact types.", 3000, () => {});
          }
          if (impactClientTrue === false) {
            isValid = false;
            Toast.fail("Please add both Impact types.", 3000, () => {});
          }
        }
      }
      console.log("impact option selected =>",this.state.impactOptionSelected)
      console.log("monetization project selected option =>",this.state.monetizableProjectSelectedOption)
      console.log("monetization status selected option =>",this.state.monetizationStatusSelectedOption)
      

      if (this.state.impactOptionSelected) {
        if (
          this.state.monetizableProjectSelectedOption &&
          this.state.monetizableProjectSelectedOption.label === "Yes"
        ) {
          if (this.state.monetizationStatusSelectedOption === null || !this.state.monetizationStatusSelectedOption ||
            this.state.monetizationStatusSelectedOption === undefined || this.state.monetizationStatusSelectedOption.length === 0
            ) {
            isValid = false;
            Toast.fail(
              "Since this project has been marked as Monetizable, monetizable status has to be selected",
              5000,
              () => {}
            );
          }
        }
        if (
          this.state.monetizableProjectSelectedOption !== null &&
          this.state.monetizationStatusSelectedOption !== null
        ) {
          if (this.state.monetizableProjectSelectedOption.label === "Yes")
            if (
              this.state.businessImpactValueArray.filter(
                (item) => item.IsMonetized === "Yes"
              ).length > 0
            ) {
            } else {
              isValid = false;
              Toast.fail(
                "Since this is a monetizable project, there needs to be at least one impact type as ‘Impact at Genpact’ selected and category selected as a ‘Increase in Revenue’ .",
                5000,
                () => {}
              );
            }
        }
        if (
          this.state.monetizableProjectSelectedOption &&
          this.state.monetizableProjectSelectedOption.label === "Yes"
        ) {
          if (
            this.state.monetizationStatusSelectedOption &&
            this.state.monetizationStatusSelectedOption.label ===
              "Approved by Client"
          ) {
            if (
              this.state.SowID === "" ||
              this.state.SowID === null ||
              this.state.SowID === undefined ||
              this.state.SowID.length === 0 ||
              !this.state.SowID
            ) {
              isValid = false;
              Toast.fail(
                "Please fill SOW ID/SFDC/OLI ID for monetization",
                3000,
                () => {}
              );
            }
          }
        }
      }
    }
    // validations copied from final-review-idea-page ends here


    // AI First validations starts here

    if(this.state.workFlowStatusSelectedOption && this.state.workFlowStatusSelectedOption.label === "Approve"){
      if (this.state.campaignSelectedOption && this.state.campaignSelectedOption.label === AIFIRST) {
        if (
          this.state.IsIdeaReplicableSelectedOption === null ||
          this.state.IsIdeaReplicableSelectedOption.length === 0 ||
          this.state.IsIdeaReplicableSelectedOption === undefined ||
          !this.state.IsIdeaReplicableSelectedOption
        ) {
          isValid = false;
          styleStateObj.IsIdeaReplicableSelectedOption = selectStyle(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.IsIdeaReplicableSelectedOption = selectStyle(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }

        
  
        
  
        if (
          this.state.solutionFeasibleSelectedOption === null ||
          this.state.solutionFeasibleSelectedOption.length === 0 ||
          this.state.solutionFeasibleSelectedOption === undefined ||
          !this.state.solutionFeasibleSelectedOption
        ) {
          isValid = false;
          styleStateObj.solutionFeasibleSelectedOption = selectStyle(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.solutionFeasibleSelectedOption = selectStyle(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
  
        if (
          this.state.solutionComplexitySelectedOption === null ||
          this.state.solutionComplexitySelectedOption.length === 0 ||
          this.state.solutionComplexitySelectedOption === undefined ||
          !this.state.solutionComplexitySelectedOption
        ) {
          isValid = false;
          styleStateObj.solutionComplexitySelectedOption = selectStyle(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.solutionComplexitySelectedOption = selectStyle(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
      }
    }

    return isValid;
  };
  submitIdea = () => {
    if (this.validate()) {
      let ApprovalStatus = "";
      console.log("transformation lever selected option =>",this.state.transSelectedOption)
      // if(this.state.certLevelsSelectedOption && this.state.certLevelsSelectedOption.label === "GB" &&
      // this.state.campaignSelectedOption && this.state.campaignSelectedOption.label !== "AI First"
      // )
      // {
      //   ApprovalStatus="Awaiting Project Creation";
      // }
      // else{
      //   ApprovalStatus = "PD"
      // }

      if(this.state.certLevelsSelectedOption && this.state.certLevelsSelectedOption.label === "GB"){
        ApprovalStatus="Awaiting Project Creation";
      }
      if(this.state.certLevelsSelectedOption && this.state.certLevelsSelectedOption.label === "Lean"){
        ApprovalStatus = "PD"
      }


      if (this.props.location.approval === "approval") {
        if (window.confirm("Are you sure you want to update the idea?")) {
          Toast.loading("Updating idea...", () => {});
          let param = {};
          if (
            this.state.workFlowStatusSelectedOption.label ==="Approve Cancellation" ||
            this.state.workFlowStatusSelectedOption.label === "Discard Cancellation"
          ) {
            if (this.state.workFlowStatusSelectedOption.label ==="Approve Cancellation") {
              param = {
                IdeaId: this.props.location.state,
                ApprovalStatus:
                  this.state.ideaItem.ApprovalStatus !== "NA"? "Cancelled": "NA",
                UpdaterOHR: this.sessionData.profile.oHRId,
                UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                UpdaterName:
                  this.sessionData.profile.lastName +", " + this.sessionData.profile.firstName,
                comment: this.state.Comment,
                WorkflowStatus:
                  this.state.ideaItem.WorkflowStatus !== REQUEST_CANCELLATION
                    ? this.state.ideaItem.WorkflowStatus
                    : "Cancelled",
                ProjectStatus:
                  this.state.ideaItem.WorkflowStatus === "Approve" ||
                  this.state.ideaItem.WorkflowStatus === "Approved"
                    ? "Pending Documents"
                    : "NA",
                    Role: "Mentor",
                updaterAction: "Approved",
              };
            } else {
              if (this.state.ideaItem.WorkflowStatus === REQUEST_CANCELLATION) {
                param = {
                  IdeaId: this.props.location.state,
                  ApprovalStatus: "NA",
                  UpdaterOHR: this.sessionData.profile.oHRId,
                  UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                  UpdaterName:
                    this.sessionData.profile.lastName +
                    ", " +
                    this.sessionData.profile.firstName,
                  comment: this.state.Comment,
                  WorkflowStatus: "Pending",
                  RejectCancel: true,
                  Role: "Mentor" ,
                  updaterAction: "Discard Cancellation",
                };
              } else {
                param = {
                  IdeaId: this.props.location.state,
                  ApprovalStatus: "Pending Documents",
                  UpdaterOHR: this.sessionData.profile.oHRId,
                  UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                  UpdaterName:
                    this.sessionData.profile.lastName +
                    ", " +
                    this.sessionData.profile.firstName,
                  comment: this.state.Comment,
                  WorkflowStatus: "Approve",
                  RejectCancel: true,
                  Role: "Mentor" ,
                  updaterAction: "Pending Documents",
                };
              }
            }
          } else {
            this.state.businessImpactValueArray =
              this.state.businessImpactValueArray
                .map((item) => ({
                  ...item,
                  pg_srno: item.SrNo,
                  dollarValue: item.DollarImpact,
                  fteValue: item.FTEImpact,
                }))
                .map((item) => {
                  return item;
                });

            let filteredBIData = this.state.businessImpactValueArray.filter(
              function (e) {
                return e !== null && e !== undefined;
              }
            );
            param = {
              IdeaName: this.state.ideaItem.IdeaName,
              Problem: this.state.ideaItem.Problem,
              Solution: this.state.ideaItem.Solution,
              ServiceLineId: this.state.ideaItem.ServiceLineId,
              ServiceLineName: this.state.ideaItem.ServiceLineName,
              SubServiceLine: this.state.ideaItem.SubServiceLine,
              UpdaterOHR: this.sessionData.profile.oHRId,
              UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
              UpdaterName:
                this.sessionData.profile.lastName +
                ", " +
                this.sessionData.profile.firstName,
              WorkflowStatusId:
                this.state.workFlowStatusSelectedOption !== null
                  ? this.state.workFlowStatusSelectedOption.value
                  : null,
              WorkflowStatus:
                this.state.workFlowStatusSelectedOption !== null
                  ? this.state.workFlowStatusSelectedOption.label
                  : null,
              ExpectedFinishDate:
                this.state.workFlowStatusSelectedOption.label === "Approve"
                  ? new Date(this.state.expectedDate).toISOString()
                  : null,
              Comment: this.state.Comment,
              IdeaId: this.props.location.state,
              NewOrReplicatedIdea: this.state.ideaItem.NewOrReplicatedIdea,
              ReplicationProjectId: this.state.ideaItem.NewOrReplicatedIdea
                ? this.state.ideaItem.ReplicationProjectId
                : "",
              // PotentialImpactDollar: this.state.ideaItem.PotentialImpactDollar,
              PotentialImpactFTE: this.state.ideaItem.PotentialImpactFTE,
              CertificationLevelId:
                this.state.showAdditionalDetails &&
                this.state.certLevelsSelectedOption !== null
                  ? this.state.certLevelsSelectedOption.value
                  : null,
              CertificationWorkTypeName:
                this.state.showAdditionalDetails &&
                this.state.certWorkTypeSelectedOption !== null
                  ? this.state.certWorkTypeSelectedOption !== null
                    ? this.state.certWorkTypeSelectedOption.value
                    : null
                  : null,
              CertificationLevelName: this.state.showAdditionalDetails
                ? this.state.certLevelsSelectedOption !== null
                  ? this.state.certLevelsSelectedOption.label
                  : null
                : null,
              TransformationLeverId:
                this.state.showAdditionalDetails &&
                this.state.transSelectedOption !== null
                  ? this.state.transSelectedOption.value
                  : null,
              TransformationLeverName:
                this.state.showAdditionalDetails &&
                this.state.transSelectedOption !== null
                  ? this.state.transSelectedOption.label
                  : null,
              MethodologyTypeID:
                this.state.showAdditionalDetails &&
                this.state.methodologySelectedOptions !== null
                  ? this.state.methodologySelectedOptions.value
                  : null,
              MethodologyName:
                this.state.showAdditionalDetails &&
                this.state.methodologySelectedOptions !== null
                  ? this.state.methodologySelectedOptions.label
                  : null,
              LDTId: this.state.ideaItem.LDTId,
              LDTName: this.state.ideaItem.LDTLeadName,
              IsReplicationVerified: this.state.ideaItem.IsReplicationVerified,
              IsDigital:
                this.state.showAdditionalDetails &&
                this.state.transSelectedOption !== null
                  ? this.state.transSelectedOption.label === "Digital"
                    ? true
                    : false
                  : false,
              BusinessImpactData: filteredBIData,
              DigitalProjectType:
                this.state.showAdditionalDetails &&
                this.state.digitalProjectTypeSelectedOptions !== null
                  ? this.state.digitalProjectTypeSelectedOptions.label
                  : null,
              DataBridgeTheme: this.state.dataBridgeProjectTheme,
              SubCampaignName: this.state.subCampaignName,
              CampaignName: this.state.campaignTypeText,
              MBBOHR: Number(this.state.mbbOHR),
              MBBName: this.state.mbbName,
              Role: "Mentor" ,
              updaterAction: this.state.workFlowStatusSelectedOption
                ? this.state.workFlowStatusSelectedOption.label === "Approve"
                  ? "Approved"
                  : this.state.workFlowStatusSelectedOption.label
                : "",

              VDType: this.state.impactOptionSelected
                ? this.state.impactOptionSelected.label
                : this.state.ideaItem.VDType,
              FinalImpactDollar: this.state.finalImpactDollar
                ? this.state.finalImpactDollar
                : null,
              FinalImpactFTE: this.state.finalImpactFTE
                ? this.state.finalImpactFTE
                : null,
              FPnAOHRMonetization: this.state.FPnAOHRMonetization
                ? this.state.FPnAOHRMonetization
                : "",
              FPnANameMonetization: this.state.FPnAOHRMonetization
                ? this.state.FPnANameMonetization
                : "",
              SowID: this.state.SowID ? this.state.SowID : "",
              IsVDMonetizable: this.state.monetizableProjectSelectedOption
                ? this.state.monetizableProjectSelectedOption.label
                : "",
              MonetizationStatus: this.state.monetizationStatusSelectedOption
                ? this.state.monetizationStatusSelectedOption.label
                : "",
              //AI First

              
              isAI:this.state.isAI,
              ApprovalStatus: ApprovalStatus,
              IsIdeaReplicable:this.state.IsIdeaReplicableSelectedOption ? this.state.IsIdeaReplicableSelectedOption.label === "Yes" ? 1: 0 : "",
              IsSolnFeasible: this.state.solutionFeasibleSelectedOption ? this.state.solutionFeasibleSelectedOption.label === "Yes" ? 1 : 0 :"",
              SolnComplexity : this.state.solutionComplexitySelectedOption ? this.state.solutionComplexitySelectedOption.label :"",
              LinkBRD: this.state.AIBRD,
              AIReason: this.state.AIReason,
            };
          }
          console.log(param);
          let finalJsonData = Checksum(param);
          //console.log("final Json Data",finalJsonData );

          // console.log(JSON.stringify(param))
          const requestOptions = {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param),
          };
          fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              Toast.hide();
              Toast.success("Updated Successfully.", 3000, () => {
                // console.log(JSON.stringify(data));
                // window.location = "/review-page?page=approval";
                let redirectURL = "/review-page";
                if (this.state.approvalPage) {
                  redirectURL =
                    redirectURL + "?page=" + this.state.approvalPage;
                }
                window.location = redirectURL;
              });
            });
        }
      } else {
        if (window.confirm("Are you sure you want to update the Project?")) {
          Toast.loading("Updating Project...", () => {});
          let param = {};
          if (
            this.state.workFlowStatusSelectedOption.label ===
              "Approve Cancellation" ||
            this.state.workFlowStatusSelectedOption.label ===
              "Discard Cancellation"
          ) {
            if (
              this.state.workFlowStatusSelectedOption.label ===
              "Approve Cancellation"
            ) {
              param = {
                IdeaId: this.props.location.state,
                ApprovalStatus:
                  this.state.ideaItem.ApprovalStatus !== "NA"
                    ? "Cancelled"
                    : "NA",
                UpdaterOHR: this.sessionData.profile.oHRId,
                UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                UpdaterName:
                  this.sessionData.profile.lastName +
                  ", " +
                  this.sessionData.profile.firstName,
                comment: this.state.Comment,
                WorkflowStatus:
                  this.state.ideaItem.WorkflowStatus !== REQUEST_CANCELLATION
                    ? this.state.ideaItem.WorkflowStatus
                    : "Cancelled",
                ProjectStatus:
                  this.state.ideaItem.WorkflowStatus === "Approve" ||
                  this.state.ideaItem.WorkflowStatus === "Approved"
                    ? "Pending Documents"
                    : "NA",
                    Role: "Mentor" ,
                updaterAction: "Approved",
              };
            } else {
              if (this.state.ideaItem.WorkflowStatus === REQUEST_CANCELLATION) {
                param = {
                  IdeaId: this.props.location.state,
                  ApprovalStatus: "NA",
                  UpdaterOHR: this.sessionData.profile.oHRId,
                  UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                  UpdaterName:
                    this.sessionData.profile.lastName +
                    ", " +
                    this.sessionData.profile.firstName,
                  comment: this.state.Comment,
                  WorkflowStatus: "Pending",
                  RejectCancel: true,
                  Role: "Mentor" ,
                  updaterAction: "Discard Cancellation",
                };
              } else {
                param = {
                  IdeaId: this.props.location.state,
                  ApprovalStatus: "Pending Documents",
                  UpdaterOHR: this.sessionData.profile.oHRId,
                  UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                  UpdaterName:
                    this.sessionData.profile.lastName +
                    ", " +
                    this.sessionData.profile.firstName,
                  comment: this.state.Comment,
                  WorkflowStatus: "Approve",
                  RejectCancel: true,
                  Role: "Mentor" ,
                  updaterAction: "Pending Documents",
                };
              }
            }
          } else {
            this.state.businessImpactValueArray =
              this.state.businessImpactValueArray
                .map((item) => ({
                  ...item,
                  pg_srno: item.SrNo,
                  dollarValue: item.DollarImpact,
                  fteValue: item.FTEImpact,
                }))
                .map((item) => {
                  return item;
                });
            let filteredBIData = this.state.businessImpactValueArray.filter(
              function (e) {
                return e !== null && e !== undefined;
              }
            );
            param = {
              IdeaName: this.state.ideaItem.IdeaName,
              Problem: this.state.ideaItem.Problem,
              Solution: this.state.ideaItem.Solution,
              ServiceLineId: this.state.ideaItem.ServiceLineId,
              ServiceLineName: this.state.ideaItem.ServiceLineName,
              SubServiceLine: this.state.ideaItem.SubServiceLine,
              UpdaterOHR: this.sessionData.profile.oHRId,
              UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
              UpdaterName:
                this.sessionData.profile.lastName +
                ", " +
                this.sessionData.profile.firstName,
              WorkflowStatusId:
                this.state.workFlowStatusSelectedOption !== null
                  ? this.state.workFlowStatusSelectedOption.value
                  : null,
              WorkflowStatus:
                this.state.workFlowStatusSelectedOption !== null
                  ? this.state.workFlowStatusSelectedOption.label
                  : null,
              ExpectedFinishDate:
                this.state.workFlowStatusSelectedOption.label === "Approve"
                  ? new Date(this.state.expectedDate).toISOString()
                  : null,
              Comment: this.state.Comment,
              IdeaId: this.props.location.state,
              NewOrReplicatedIdea: this.state.ideaItem.NewOrReplicatedIdea,
              ReplicationProjectId: this.state.ideaItem.NewOrReplicatedIdea
                ? this.state.ideaItem.ReplicationProjectId
                : "",
              PotentialImpactDollar: this.state.ideaItem.PotentialImpactDollar,
              PotentialImpactFTE: this.state.ideaItem.PotentialImpactFTE,
              CertificationLevelId:
                this.state.showAdditionalDetails &&
                this.state.certLevelsSelectedOption !== null
                  ? this.state.certLevelsSelectedOption.value
                  : null,
              CertificationWorkTypeName:
                this.state.showAdditionalDetails &&
                this.state.certWorkTypeSelectedOption !== null
                  ? this.state.certWorkTypeSelectedOption !== null
                    ? this.state.certWorkTypeSelectedOption.value
                    : null
                  : null,
              CertificationLevelName: this.state.showAdditionalDetails
                ? this.state.certLevelsSelectedOption !== null
                  ? this.state.certLevelsSelectedOption.label
                  : null
                : null,
              TransformationLeverId:
                this.state.showAdditionalDetails &&
                this.state.transSelectedOption !== null
                  ? this.state.transSelectedOption.value
                  : null,
              TransformationLeverName:
                this.state.showAdditionalDetails &&
                this.state.transSelectedOption !== null
                  ? this.state.transSelectedOption.label
                  : null,
              MethodologyTypeID:
                this.state.showAdditionalDetails &&
                this.state.methodologySelectedOptions !== null
                  ? this.state.methodologySelectedOptions.value
                  : null,
              MethodologyName:
                this.state.showAdditionalDetails &&
                this.state.methodologySelectedOptions !== null
                  ? this.state.methodologySelectedOptions.label
                  : null,
              LDTId: this.state.ideaItem.LDTId,
              LDTName: this.state.ideaItem.LDTLeadName,
              IsReplicationVerified: this.state.ideaItem.IsReplicationVerified,
              IsDigital:
                this.state.showAdditionalDetails &&
                this.state.transSelectedOption !== null
                  ? this.state.transSelectedOption.label === "Digital"
                    ? true
                    : false
                  : false,
              BusinessImpactData: filteredBIData,
              DigitalProjectType:
                this.state.showAdditionalDetails &&
                this.state.digitalProjectTypeSelectedOptions !== null
                  ? this.state.digitalProjectTypeSelectedOptions.label
                  : null,
              DataBridgeTheme: this.state.dataBridgeProjectTheme,
              SubCampaignName: this.state.subCampaignName,
              CampaignName: this.state.campaignTypeText,
              MBBOHR: Number(this.state.mbbOHR),
              MBBName: this.state.mbbName,
              Role: "Mentor" ,
              updaterAction: this.state.workFlowStatusSelectedOption
                ? this.state.workFlowStatusSelectedOption.label === "Approve"
                  ? "Approved"
                  : this.state.workFlowStatusSelectedOption.label
                : "",

              VDType: this.state.impactOptionSelected
                ? this.state.impactOptionSelected.label
                : this.state.ideaItem.VDType,
              FinalImpactDollar: this.state.finalImpactDollar
                ? this.state.finalImpactDollar
                : null,
              FinalImpactFTE: this.state.finalImpactFTE
                ? this.state.finalImpactFTE
                : null,
              FPnAOHRMonetization: this.state.FPnAOHRMonetization
                ? this.state.FPnAOHRMonetization
                : "",
              FPnANameMonetization: this.state.FPnAOHRMonetization
                ? this.state.FPnANameMonetization
                : "",
              SowID: this.state.SowID ? this.state.SowID : "",
              IsVDMonetizable: this.state.monetizableProjectSelectedOption
                ? this.state.monetizableProjectSelectedOption.label
                : "",
              MonetizationStatus: this.state.monetizationStatusSelectedOption
                ? this.state.monetizationStatusSelectedOption.label
                : "",

              // AI First 
              isAI:this.state.isAI,
              ApprovalStatus: ApprovalStatus,
              IsIdeaReplicable:this.state.IsIdeaReplicableSelectedOption ? this.state.IsIdeaReplicableSelectedOption.label === "Yes" ? 1: 0 : "",
              IsSolnFeasible: this.state.solutionFeasibleSelectedOption ? this.state.solutionFeasibleSelectedOption.label === "Yes" ? 1 : 0 :"",
              SolnComplexity : this.state.solutionComplexitySelectedOption ? this.state.solutionComplexitySelectedOption.label :"",
              LinkBRD: this.state.AIBRD,
              AIReason: this.state.AIReason,

            };
          }
          console.log(param);
          let finalJsonData = Checksum(param);
          //console.log("final Json Data",finalJsonData );

          // console.log(JSON.stringify(param))
          const requestOptions = {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Authorization":"Bearer " + this.accessToken,
            },
            body: JSON.stringify(param),
          };
          fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              Toast.hide();
              Toast.success("Updated successfully.", 3000, () => {
                // console.log(JSON.stringify(data));
                // window.location = "/review-page?page=approval";
                let redirectURL = "/review-page";
                if (this.state.approvalPage) {
                  redirectURL =
                    redirectURL + "?page=" + this.state.approvalPage;
                }
                window.location = redirectURL;
              });
            });
        }
      }
    } else {
      if (!this.isExceedlimit) {
        Toast.fail(
          "Required fields are missing or have invalid values. Please correct and try again.",
          3000,
          () => {}
        );
      }
    }
    return false;
  };

  handleIdeaNameChange = (text) => {
    let ideaItem = this.state.ideaItem;
    ideaItem.IdeaName = text;
    this.setState({
      ideaItem,
    });
  };

  handleProblemChange = (text) => {
    let ideaItem = this.state.ideaItem;
    ideaItem.Problem = text;
    this.setState({
      ideaItem,
    });
  };

  handleSolutionChange = (text) => {
    let ideaItem = this.state.ideaItem;
    ideaItem.Solution = text;
    this.setState({
      ideaItem,
    });
  };

  handleTranscriptProblemChange = (text) => {
    let ideaItem = this.state.ideaItem;
    if (text !== null && text !== undefined) {
      ideaItem.Problem = text;
      this.setState({
        ideaItem,
      });
    }
  };
  handleTranscriptSolutionChange = (text) => {
    let ideaItem = this.state.ideaItem;
    if (text !== null && text !== undefined) {
      ideaItem.Solution = text;
      this.setState({
        ideaItem,
      });
    }
  };
  toggleTranscriptFunction = (id) => {
    this.setState({ speechModal: !this.state.speechModal, modalIndex: id });
    if (id === 2) {
      // approval details comment
      if (this.state.Comment) {
        this.setState({ previousTextForSpeech: this.state.Comment }, () =>
          console.log(this.state.previousTextForSpeech)
        );
      } else {
        this.setState({ previousTextForSpeech: "" }, () =>
          console.log(this.state.previousTextForSpeech)
        );
      }
    }
    if (id === 10) {
      // request cancellation comment
      this.setState({ previousTextForSpeech: this.state.Comment }, () =>
        console.log(this.state.previousTextForSpeech)
      );
    }
    if (id === 11) {
      this.setState(
        { previousTextForSpeech: this.state.ideaItem.Problem },
        () => console.log(this.state.previousTextForSpeech)
      );
    }
    if (id === 12) {
      this.setState(
        { previousTextForSpeech: this.state.ideaItem.Solution },
        () => console.log(this.state.previousTextForSpeech)
      );
    }
  };

  closeModalFunction = () => {
    this.setState({ speechModal: false });
  };

  showHideDiv = () => {
    let ideaItem = this.state.ideaItem;
    ideaItem.NewOrReplicatedIdea = !ideaItem.NewOrReplicatedIdea;
    this.setState({
      ideaItem,
    });
  };

  showHideVerifyReplicationDiv = () => {
    let ideaItem = this.state.ideaItem;
    ideaItem.IsReplicationVerified = !ideaItem.IsReplicationVerified;
    this.setState({
      ideaItem,
    });
  };
  handleReplicationIdChange = (text) => {
    let ideaItem = this.state.ideaItem;
    ideaItem.ReplicationProjectId = text;
    this.setState({
      ideaArray: ideaItem,
      replicationIDValidated: false,
    });
  };

  renderStatusOptions = () => {
    let options = [];
    if(this.state.campaignSelectedOption && this.state.campaignSelectedOption.label === "AI First - Enterprise 360"){
      this.state.workFlowStatusList.filter((item)=>{
        if(item.WorkflowStatusName === "Approve"){
          options.push({
            value: item.WorkflowStatusId,
            label: item.WorkflowStatusName,
          });
        }
      })
    }else{
      this.state.workFlowStatusList.map((item) => {
        options.push({
          value: item.WorkflowStatusId,
          label: item.WorkflowStatusName,
        });
      });
    }
    return options;
  };

  renderCancelRequestOption = () => {
    let options = [];
    this.state.cancelRequestOption.map((item) => {
      options.push({
        value: item.WorkflowStatusId,
        label: item.WorkflowStatusName,
      });
      return options;
    });
    return options;
  };

  renderLeadOptions = () => {
    let options = [];
    const uniqueLeadList = [
      ...new Map(
        this.state.leadNameList.map((item) => [item.pg_ohr, item])
      ).values(),
    ];

    uniqueLeadList.map((item) => {
      options.push({ value: item.pg_ohr, label: item.pg_username });
      return options;
    });
    return options;
  };

  handleAccountNameChange = (e) => {

    console.log("campaign selected option=>",this.state.campaignSelectedOption)
    console.log("is AI ",this.state.isAI)
    console.log("e =>", e)

    if (this.state.campaignSelectedOption &&
      this.state.campaignSelectedOption.label === AIFIRST &&
      this.state.isAI && this.state.isAI === "No" && e &&
      e.label === "Approve"
    ) {
      if (window.confirm("You are overriding the decision from AI Analyzer. Please provide a justification in the comments section.")) {
        if (e.value === 1) {
          this.setState({showAdditionalDetails: true},() => { });
        } else {
          this.setState({showAdditionalDetails: false},() => { });
        }
        this.setState({
          workFlowStatusSelectedOption: {
            value: e.value,
            label: e.label,
          },
        },
          () => {
            //console.log("work Flow StatusSelected Option is=> ", this.state.workFlowStatusSelectedOption);
            if (
              this.state.workFlowStatusSelectedOption.label === "Send Back" ||
              this.state.workFlowStatusSelectedOption.label === "Reject"
            ) {
              this.setState({ certLevelsSelectedOption: null }, () => {
              });

              if (this.state.campaignSelectedOption && this.state.campaignSelectedOption.label === AIFIRST) {
                this.setState({
                  IsIdeaReplicableSelectedOption: [],
                  solutionFeasibleSelectedOption: [],
                  solutionComplexitySelectedOption: [],
                })
              }
            }
          }
        );
      }
      else {
        //console.log("else block called")
        this.setState({workFlowStatusSelectedOption:[]},()=>{
          //console.log("workflow selected option when clicked on cancel",this.state.workFlowStatusSelectedOption)
        })
      }
    }
    else {
      
      if (e.value === 1) {
        this.setState(
          {
            showAdditionalDetails: true,
          },
          () => { }
        );
      } else {
        this.setState({
          showAdditionalDetails: false,
        });
      }
      this.setState({
        workFlowStatusSelectedOption: {
          value: e.value,
          label: e.label,
        },
      },
        () => {
          //console.log("work Flow StatusSelected Option is=> ", this.state.workFlowStatusSelectedOption);
          if (
            this.state.workFlowStatusSelectedOption.label === "Send Back" ||
            this.state.workFlowStatusSelectedOption.label === "Reject"
          ) {
            this.setState({ certLevelsSelectedOption: null }, () => {
              
            });

            if (this.state.campaignSelectedOption && this.state.campaignSelectedOption.label === AIFIRST) {
              this.setState({
                IsIdeaReplicableSelectedOption: [],
                solutionFeasibleSelectedOption: [],
                solutionComplexitySelectedOption: [],
              })
            }
          }
        }
      );
    }
  };

  handleCancelRequestChange = (e) => {
    this.setState({
      workFlowStatusSelectedOption: {
        value: e.value,
        label: e.label,
      },
    },()=>{});
  };

  handleLDTLeadOptionChange = (e) => {
    if (
      parseInt(e.value) === parseInt(this.state.ideaItem.OwnerOHR) ||
      parseInt(e.value) === parseInt(this.state.ideaItem.CreatorOHR)
    ) {
      Toast.fail(
        "LDT user cannot be the same as Idea Owner/Requester. Please select a different LDT user.",
        3000,
        () => {
          return;
        }
      );
      return;
    }
    let ideaItem = this.state.ideaItem;
    ideaItem.LDTId = e.value;
    ideaItem.LDTLeadName = e.label;
    this.setState({
      ideaItem,
      LDTLeadSelectedOption: {
        value: e.value,
        label: e.label,
      },
    });
  };

  handleMBBChange = (e) => {
    if (
      parseInt(e.value) === parseInt(this.state.ideaItem.OwnerOHR) ||
      parseInt(e.value) === parseInt(this.state.ideaItem.CreatorOHR)
    ) {
      Toast.fail(
        "Master Black Belt user cannot be the same as Idea Owner/Requester. Please select a different Master Black Belt user.",
        3000,
        () => {
          return;
        }
      );
      return;
    }
    let styleStateObj = this.state.selectStyle;
    styleStateObj.mbbUserName = selectStyle(true);
    this.setState({
      selectStyle: styleStateObj,
      mbbName: e.label,
      mbbOHR: e.value,
      mbbSelectedOption: {
        value: e.value,
        label: e.label,
      },
    });
  };

  renderMBBOptions = () => {
    let options = [];
    this.state.mbbList.map((item) => {
      const userRole = JSON.parse(item.pg_Role).pg_role;
      if (userRole === "MBB") {
        if (this.state.ideaItem.OwnerOHR !== item.pg_ohr) {
          options.push({ value: item.pg_ohr, label: item.pg_username });
        }
      }
      return true;
    });
    return options;
  };

  renderCertLevelsOptions = () => {
    let options = [];
    this.state.certificationLevelsList.map((item) => {
      options.push({
        value: item.CertificationLevelId,
        label: item.CertificationLevelName,
      });
      return options;
    });
    //this.state.isQAUser
    return options;
  };

  handleCertLevelChange = (e) => {
    // let styleStateObj = this.state.selectStyle;
    // styleStateObj.certLevelsSelectedOption = selectStyle(true);
    // this.setState({selectStyle: styleStateObj},()=>{})
    this.setState(
      {
        certLevelsSelectedOption: {
          value: e.value,
          label: e.label,
        },
      },
      () => {
        if(this.state.certLevelsSelectedOption && this.state.certLevelsSelectedOption.label === "GB"){
          if(this.state.isQAUser){
            Toast.fail("As a QA you can only approve Lean Projects. If this Idea needs to be approved as GB, please delegate the this Idea to a Black Belt.",7000,()=>{})
            this.setState({certLevelsSelectedOption:null,
              certWorkTypeSelectedOption: null,
              digitalProjectTypeSelectedOptions: null,
              transSelectedOption: null,
              digitalProjectEnabled: false,
              workTypeEnabled: false,
              methodologySelectedOptions:null
            })
            return;
          }
        }
        
      }
    );


    //Reset the Work Type upon change of Certification Level
    this.setState({
      certWorkTypeSelectedOption: null,
      digitalProjectTypeSelectedOptions: null,
      transSelectedOption: null,
      digitalProjectEnabled: false,
    });

    if (
      this.state.showAdditionalDetails &&
      this.state.transSelectedOption &&
      this.state.transSelectedOption.label === "Digital"
    ) {
      this.setState({
        workTypeEnabled: false,
      });
    }
    if (e.label === "Lean" || e.label === "YB") {
      this.setState({
        workTypeEnabled: false,
      });
    }

    if (
      e.label === "GB" &&
      this.state.transSelectedOption &&
      this.state.transSelectedOption.label === "Data"
    ) {
      this.setState({
        certWorkTypeSelectedOption: {
          value: "GB ProDG",
          label: "GB ProDG",
        },
        workTypeEnabled: false,
      });
    } else {
      this.setState({
        digitalProjectTypeSelectedOptions: null,
        workTypeEnabled: false,
      });
    }

    if (
      e.label === "GB" &&
      this.state.transSelectedOption &&
      this.state.transSelectedOption.label === "AI"
    ) {
      this.setState({
        certWorkTypeSelectedOption: {
          value: "GB ProDG",
          label: "GB ProDG",
        },
        workTypeEnabled: false,
      });
    } else {
      this.setState({
        digitalProjectTypeSelectedOptions: null,
        workTypeEnabled: false,
      });
    }
    if (
      e.label === "GB" &&
      this.state.transSelectedOption &&
      this.state.transSelectedOption.label === "Continuous Improvement"
    ) {
      if (
        this.state.transSelectedOption &&
        this.state.transSelectedOption === "Data Bridge"
      ) {
        this.setState(
          {
            workTypeEnabled: false,
          },
          () => {
            this.setState({
              certWorkTypeSelectedOption: {
                value: "GB ProDG",
                label: "GB ProDG",
              },
            });
          }
        );
      } else {
        this.setState({
          workTypeEnabled: true,
        });
      }
    } else {
      this.setState({
        digitalProjectTypeSelectedOptions: null,
      });
    }
    if (e.label === "Lean") {
      this.setState({ showDigital: true });
    }
    if (e.label === "GB") {
      this.setState({
        showDigital: false,
        transSelectedOption: null,
      });
    }
    if (
      this.state.campaignSelectedOption &&
      this.state.campaignSelectedOption.label === "Data Bridge"
    ) {
      this.setState(
        {
          transSelectedOption: {
            value: 0,
            label: "Data",
          },
        },
        () => {}
      );
    }
    if (
      this.state.campaignSelectedOption &&
      (
        this.state.campaignSelectedOption.label === "AI First" ||
        this.state.campaignSelectedOption.label === "AI First - Enterprise 360" ||
        this.state.campaignSelectedOption.label === "AI First - C&H Gen AI hackathon"

      )


    ) {
      this.setState(
        {
          transSelectedOption: {
            value: 0,
            label: "AI",
          },
        },
        () => {}
      );
    }

    // if(this.state.campaignSelectedOption && this.state.campaignSelectedOption.label === "Data Bridge"){
    //    this.setState({workTypeEnabled:true},()=>{})
    // }

    
  };

  renderCertWorkTypeOptions = (selectedCert) => {
    let options = [];
    this.state.certificationWorkTypeList.map((item) => {
      if (!selectedCert || selectedCert.label === item.CertifiationLevelName) {
        options.push({
          value: item.WorkTypeName,
          label: item.WorkTypeName,
        });
      }
      return options;
    });
    return options;
  };

  handleCertWorkTypeChange = (e) => {
    this.setState(
      {
        certWorkTypeSelectedOption: {
          value: e.value,
          label: e.label,
        },
        dataBridgeData: "",
        // digitalProjectTypeSelectedOptions: null,
        // digitalProjectEnabled:false,
        // transSelectedOption:null,
      },
      () => {}
    );
    const BIFormApplicableProjects = ["GB ProDG", "GB BPMS LIVE", "GB POV 3D"];
    if (
      this.state.certLevelsSelectedOption.label === "GB" &&
      BIFormApplicableProjects.includes(e.label)
    ) {
      this.setState({
        showBIForm: true,
      });
    } else {
      this.setState({
        showBIForm: false,
      });
    }
  };

  // renderTransLeversOptions = () => {
  //     let options = [];
  //     this.state.transformationLeversList.map((item) => {
  //         if(this.state.showDigital){
  //             options.push({
  //                 value: item.TransformationLeverId,
  //                 label: item.TransformationLeverName
  //             });
  //             options = options.slice(0,2);
  //             //return options;
  //         }else{
  //             options.push({
  //                 value: item.TransformationLeverId,
  //                 label: item.TransformationLeverName
  //             });
  //             // return options;
  //         }

  //     });
  //     return options;
  // }

  renderTransLeversOptions = () => {
    let options = [];
    this.state.transformationLeversList.map((item) => {
      options.push({
        value: item.TransformationLeverId,
        label: item.TransformationLeverName,
      });
      return options;
    });
    return options;
    // if(this.state.certLevelsSelectedOption){
      
    // }
  };

  handleTransLeversChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.transSelectedOption = selectStyle(true);
    this.setState({
      transSelectedOption: {
        value: e.value,
        label: e.label,
      },selectStyle: styleStateObj,
    });
    //Reset the Digital Project Type upon change of Transformation lever
    this.setState(
      {
        digitalProjectTypeSelectedOptions: null,
        certWorkTypeSelectedOption: null,
        digitalProjectEnabled: false,
        // workTypeEnabled: true
      },
      () => {}
    );

    if (this.state.showAdditionalDetails) {
      //&& this.state.certLevelsSelectedOption.label === "GB"

      if (
        this.state.certLevelsSelectedOption &&
        this.state.certLevelsSelectedOption.label === "Lean"
      ) {
        if (e.label === "Digital") {
          this.setState({
            workTypeEnabled: false,
            digitalProjectEnabled: true,
          });
        } else {
          this.setState({
            workTypeEnabled: true,
            digitalProjectEnabled: false,
          });
        }
      }

      if (
        this.state.certLevelsSelectedOption &&
        this.state.certLevelsSelectedOption.label === "Lean"
      ) {
        if (
          this.state.digitalProjectTypeSelectedOptions === null &&
          e.label === "Digital"
        ) {
          if (
            this.state.certLevelsSelectedOption &&
            (this.state.certLevelsSelectedOption.label === "GB" ||
              this.state.certLevelsSelectedOption.label === "Lean")
          ) {
            this.renderDigitalProjectTypeOptions();
          }
          this.setState({
            digitalProjectEnabled: true,
          });
        } else {
          this.setState({
            digitalProjectEnabled: false,
          });
        }
      }

      if (
        this.state.certLevelsSelectedOption &&
        this.state.certLevelsSelectedOption.label === "GB" &&
        (e.label === "Data" || e.label === "Digital")
      ) {
        this.setState({
          certWorkTypeSelectedOption: {
            value: "GB ProDG",
            label: "GB ProDG",
          },
          workTypeEnabled: false,
        });
      } else {
        this.setState({
          digitalProjectTypeSelectedOptions: null,
          workTypeEnabled: false,
        });
      }
      if (
        this.state.certLevelsSelectedOption &&
        this.state.certLevelsSelectedOption.label === "GB" &&
        e.label === "Continuous Improvement"
      ) {
        this.setState({
          workTypeEnabled: true,
        });
      } else {
        this.setState({
          digitalProjectTypeSelectedOptions: null,
        });
      }
    } else {
      this.setState({
        digitalProjectEnabled: false,
      });
    }
  };

  renderMethodologyOptions = () => {
    let options = [];
    this.state.methodologyList.map((item) => {
      options.push({
        value: item.MethodologyId,
        label: item.MethodologyName,
      });
      return options;
    });
    return options;
  };

  handleMethodologyChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.methodologySelectedOptions = selectStyle(true);
    
    this.setState({
      methodologySelectedOptions: {
        value: e.value,
        label: e.label,
      },selectStyle: styleStateObj,
    });
  };

  renderDataBridgeThemeOptions = () => {
    let options = [];
    this.state.databridgeThemeList.map((item) => {
      options.push({
        value: item.ThemeId,
        label: item.DBProjectThemeName,
      });
      return options;
    });
    return options;
  };

  renderDigitalProjectTypeOptions = () => {
    let options = [];
    this.state.digitalProjectTypeList.map((item) => {
      let skipOption = false;
      if (
        this.state.certLevelsSelectedOption &&
        this.state.certLevelsSelectedOption.label === "GB"
      ) {
        if (
          item.DigitalProjectTypeName === "QPA" ||
          item.DigitalProjectTypeName === "Other Digital"
        ) {
          skipOption = true;
        }
      }
      if (
        this.state.certLevelsSelectedOption &&
        this.state.certLevelsSelectedOption.label === "Lean"
      ) {
        if (item.DigitalProjectTypeName === "RPA") {
          skipOption = true;
        }
      }
      if (!skipOption) {
        options.push({
          value: item.ProjectTypeId,
          label: item.DigitalProjectTypeName,
        });
      }
      return options;
    });
    return options;
  };

  handleDigitalProjectTypeChange = (e) => {
    this.setState({
      digitalProjectTypeSelectedOptions: {
        value: e.value,
        label: e.label,
      },
    });
  };

  renderServiceLineOptions = () => {
    let options = [];
    const uniqueServiceLineList = [
      ...new Map(
        this.state.serviceLineList.map((item) => [item.ServiceLineName, item])
      ).values(),
    ];
    uniqueServiceLineList.map((item) => {
      options.push({ value: item.ServiceLineId, label: item.ServiceLineName });
      return true;
    });
    return options;
  };

  renderSubServiceLineOptions = () => {
    let options = [];

    this.state.subServiceLineList.map((item) => {
      if (this.state.ideaItem.ServiceLineName === item.ServiceLineName) {
        options.push({
          value: item.SubServiceLineId,
          label: item.SubServiceLineName,
        });
        return true;
      }
      return true;
    });
    return options;
  };

  renderSubCampaignOptions = () => {
    let options = [];
    this.state.subCampaignList.map((item) => {
      options.push({ value: item.SubCampaignId, label: item.SubCampaignName });
      return true;
    });
    return options;
  };

  renderServiceLineOptions = () => {
    let options = [];
    const uniqueServiceLineList = [
      ...new Map(
        this.state.serviceLineList.map((item) => [item.ServiceLineName, item])
      ).values(),
    ];
    uniqueServiceLineList.map((item) => {
      options.push({ value: item.ServiceLineId, label: item.ServiceLineName });
      return true;
    });
    return options;
  };

  handleDataBridgeProjectThemeChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.dataBridgeProjectTheme = selectStyle(true);
    this.setState({
      selectStyle: styleStateObj,
      dataBridgeProjectTheme: e.label,
      databridgeThemeSelectedOptions: {
        value: e.label,
        label: e.label,
      },
    });
  };

  handleSubCampaignChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.subCampaignName = selectStyle(true);
    this.setState({
      selectStyle: styleStateObj,
      subCampaignName: e.label,
      subCampaignOption: {
        value: e.label,
        label: e.label,
      },
    });
  };

  handleServiceLineChange = (e) => {
    let ideaItem = this.state.ideaItem;
    ideaItem.ServiceLineId = e.value;
    ideaItem.ServiceLineName = e.label;
    ideaItem.SubServiceLineId = "";
    ideaItem.SubServiceLine = "";
    this.setState({
      subServiceLineSelectedOption: null,
      serviceLineSelectedOption: {
        value: e.value,
        label: e.label,
      },
      ideaItem,
    });
    this.renderSubServiceLineOptions(e);
  };

  handleSubServiceLineChange = (e, index) => {
    let ideaItem = this.state.ideaItem;
    ideaItem.SubServiceLine = e.label;
    this.setState({
      subServiceLineSelectedOption: {
        value: e.value,
        label: e.label,
      },
      ideaItem,
    });
  };

  fetchReplicationIdeaDetails = (IdeaId) => {
    Toast.loading(
      "Fetching Replication Idea Details. Please wait...",
      () => {}
    );
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":  "Bearer " + this.accessToken,
      },
    };
    return fetch(
      `${GET_IDEA_URL}IdeaId=-1&IdeaIDAlpha=` + IdeaId,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.ResultSets.Table1 === undefined) {
          Toast.fail(
            "We could not find the Idea based on the entered replication Idea ID. Please check and reenter.",
            5000,
            () => {}
          );
          let ideaItem = this.state.ideaItem;
          ideaItem.ReplicationProjectId = "";
          this.setState({
            ideaItem,
            replicationIDValidated: false,
          });
          return false;
        } else {
          Toast.hide();
          let ideaItem = this.state.ideaItem;
          ideaItem.ReplicationProjectId = IdeaId;
          let styleStateObj = this.state.selectStyle;
          styleStateObj.ReplicationProjectId = {
            ...textBoxValidation(true),
            border: "1px solid lightgrey",
          };
          this.setState({
            ideaItem,
            selectStyle: styleStateObj,
          });
          Toast.success(
            "Replication Idea ID validated successfully!",
            3000,
            () => {}
          );
          return true;
        }
      })
      .catch((error) => {
        Toast.hide();
        Toast.fail(
          "We could not find the Idea based on the entered replication Idea ID. Please check and reenter.",
          5000,
          () => {}
        );
        let ideaItem = this.state.ideaItem;
        ideaItem.ReplicationProjectId = "";
        this.setState({
          ideaItem,
          replicationIDValidated: false,
        });
        return false;
      });
  };

  validateReplicationId = (ideaId) => {
    if (!this.fetchReplicationIdeaDetails(ideaId)) {
      let ideaItem = this.state.ideaItem;
      ideaItem.ReplicationProjectId = "";
      this.setState({
        ideaItem,
        replicationIDValidated: false,
      });
      return false;
    } else {
      let ideaItem = this.state.ideaItem;
      ideaItem.ReplicationProjectId = ideaId;
      this.setState({
        ideaItem,
        replicationIDValidated: true,
      });
      return true;
    }
  };

  cancelEvent = () => {
    // this.props.history.push("/review-page?page=approval");
    let redirectURL = "/review-page";
    if (this.state.approvalPage) {
      // redirectURL = redirectURL + "?page=" + this.state.approvalPage;
      // this.props.history.push(redirectURL);
      this.props.history.push({
        pathname: redirectURL,
        search: "?page=" + this.state.approvalPage,
        propsList: this.state.propsData,
      });
    } else {
      this.props.history.push(redirectURL);
    }
  };

  renderCampaignOptions = () => {
    let options = [];
    let filteredCampaign = this.state.campaignList && this.state.campaignList.filter((item) => {
      return item.CampaignId !== 7;
    });

    filteredCampaign && filteredCampaign.map((item) => {
      options.push({ value: item.CampaignId, label: item.CampaignName });
      return true;
    });
    
    // if(this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST){
    //   filteredCampaign.map((item) => {
    //     options.push({ value: item.CampaignId, label: item.CampaignName });
    //     return true;
    //   });
    // }else{
    //   this.state.campaignList.map((item) => {
    //     options.push({ value: item.CampaignId, label: item.CampaignName });
    //     return true;
    //   });
    // }

    return options;
  };

  handleCampaignNameChange = (e) => {
    console.log("campaign name selected =>",e.label)
    if (e.label === "Gen AI") {
      this.setState({ chatGPTTheme: true }, () => {});
    } else {
      this.setState({ chatGPTTheme: false }, () => {});
    }
    this.setState({
      chatGptSelectedOption: [],
      IsIdeaReplicableSelectedOption:[],
      solutionFeasibleSelectedOption:[],
      solutionComplexitySelectedOption:[]
    });

    let styleStateObj = this.state.selectStyle;
    styleStateObj.campaignTypeText = selectStyle(true);

    let flagStatus = e.label === DATA_BRIDGE_CAMPAIGN ? true : false;
    let xPollinationflagStatus =
      e.label === X_POLLINATION_CAMPAIGN ? true : false;

    this.setState({
      dataBridgeCampaign: flagStatus,
      xPollinationCampaign: xPollinationflagStatus,
      dataBridgeProjectTheme: "",
      subCampaignName: "",
      selectStyle: styleStateObj,
      campaignSelectedOption: {
        value: e.value,
        label: e.label,
      },
      //campaignTypeId: e.value,//e.target.value,
      campaignTypeText: e.label,
    });

    if (e && e.label === "Data Bridge") {
      this.setState({
        transSelectedOption: {
          value: 0,
          label: "Data",
        },
      });
    } else if (e && (e.label === "AI First" || e.label==="AI First - Enterprise 360" || e.label==="AI First - C&H Gen AI hackathon")) {
      
      this.setState({
        transSelectedOption: {
          value: 0,
          label: "AI",
        },
      });
    } else {
      this.setState({
        transSelectedOption: null,
      });
    }
    this.state.campaignList.map((item,index)=>{
      if(e.value===item.CampaignId){
        this.setState({campaignInsight:item.CampaignDescr},()=>{})
      }
    })
  };

  formatDateStringWithTime(dateparam) {
    return dateparam.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  renderChatGPToptions = () => {
    let options = [];
    this.state.chatGptOptions.map((item) => {
      options.push({ value: item.value, label: item.label });
      return true;
    });
    return options;
  };
  handleChatGptOptionsChange = (e) => {
    if (e !== null || e !== undefined) {
      this.setState(
        {
          subCampaignName: e.label,
          chatGptSelectedOption: [
            {
              value: e.value,
              label: e.label,
            },
          ],
        },
        () => {
          console.log(
            "chatgpt selected option",
            this.state.chatGptSelectedOption
          );
        }
      );
    }
  };

  addMonetizableValues = (e) => {
    let MonetizedBiArray = [];
    let fteValue = 0;
    let dollarValue = 0;
    this.state.businessImpactValueArray.filter((item) => {
      if (item.IsMonetized === "Yes") {
        MonetizedBiArray.push(item);
      }
    });
    if (MonetizedBiArray) {
      const groupedData = MonetizedBiArray.reduce(
        (accumulator, currentValue) => {
          const impactType = currentValue.impactType;
          const fteValue = parseFloat(currentValue.fteValue) || 0.0;
          const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;
          const existingRow = accumulator.find(
            (row) => row.impactType === impactType
          );
          if (existingRow) {
            existingRow.fteValue += fteValue;
            existingRow.dollarValue += dollarValue;
          } else {
            accumulator.push({ impactType, fteValue, dollarValue });
          }
          return accumulator;
        },
        []
      );
      // console.log("grouped data",groupedData)

      if (groupedData.length === 0) {
        fteValue = 0;
        dollarValue = 0;
      } else {
        fteValue = groupedData[0].fteValue;
        dollarValue = groupedData[0].dollarValue;
      }
      this.setState(
        {
          totalMonetizedFTE: fteValue,
          totalMonetizedDollar: dollarValue,
        },
        () => {}
      );
    }
  };
  newBusinessImpactSummary() {
    let myArray = [...this.state.businessImpactValueArray];
    const groupedData = myArray.reduce((accumulator, currentValue) => {
      const impactType = currentValue.impactType;
      const monetizableType = currentValue.monetizableType;
      const fteValue = parseFloat(currentValue.fteValue) || 0.0;
      const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;

      const existingRow = accumulator.find(
        (row) =>
          row.impactType === impactType &&
          row.monetizableType === monetizableType
      );
      if (existingRow) {
        existingRow.fteValue += fteValue;
        existingRow.dollarValue += dollarValue;
      } else {
        accumulator.push({
          impactType,
          monetizableType,
          fteValue,
          dollarValue,
        });
      }
      return accumulator;
    }, []);
    this.setState({ summaryArray: groupedData }, () => {});
    this.addMonetizableValues();
  }
  pull_data = ({ businessImpactValueArray }) => {
    if (businessImpactValueArray) {
      this.setState(
        { businessImpactValueArray: businessImpactValueArray },
        () => {
          this.newBusinessImpactSummary();
          let dollarTotal = 0.0;
          let fteValue = 0.0;
          this.state.businessImpactValueArray.map((item) => {
            if (item && item.DollarImpact) {
              dollarTotal += parseFloat(item.DollarImpact);
            }
            if (item && item.dollarValue) {
              dollarTotal += parseFloat(item.dollarValue);
            }
            if (item && item.FTEImpact) {
              fteValue += parseFloat(item.FTEImpact);
            }
            if (item && item.fteValue) {
              fteValue += parseFloat(item.fteValue);
            }
          });
          this.setState({ finalImpactDollar: dollarTotal }, () => {
            // console.log("inside call back function $ total",this.state.finalImpactDollar)
          });
          this.setState({ finalImpactFTE: fteValue }, () => {
            // console.log("inside call back function fte value",this.state.finalImpactFTE)
          });
        },
        () => {
          //console.log("from pull data function",this.state.businessImpactValueArray)
        }
      );
    }
  };
  pull_file_data = ({
    fileName,
    fileType,
    fileContent,
    showClientApprovalFields,
    FPnAOHRMonetization,
  }) => {
    if (fileName) {
      this.setState({ FPnAFileNameMonetization: fileName }, () => {});
    }
    if (fileType) {
      this.setState({ FPnAFileTypeMonetization: fileType }, () => {});
    }
    if (fileContent) {
      this.setState({ FPnAFileMonetization: fileContent }, () => {});
    }
    this.setState(
      { showClientApprovalFields: showClientApprovalFields },
      () => {}
    );
    this.setState({ FPnAOHRMonetization: FPnAOHRMonetization }, () => {
      //console.log("ohr from pull_file_data function =>",this.state.FPnAOHRMonetization)
    });
  };
  remove_bi_data_after_delete = (filteredIdeaArray) => {
    //console.log("bi recieved in pull data for delete function",filteredIdeaArray)
    if (filteredIdeaArray) {
      this.setState({ businessImpactValueArray: filteredIdeaArray }, () => {
        this.newBusinessImpactSummary();
        let dollarTotal = 0.0;
        let fteValue = 0.0;
        this.state.businessImpactValueArray.map((item) => {
          if (item && item.DollarImpact) {
            dollarTotal += parseFloat(item.DollarImpact);
          }
          if (item && item.dollarValue) {
            dollarTotal += parseFloat(item.dollarValue);
          }
          if (item && item.FTEImpact) {
            fteValue += parseFloat(item.FTEImpact);
          }
          if (item && item.fteValue) {
            fteValue += parseFloat(item.fteValue);
          }
        });
        this.setState({ finalImpactDollar: dollarTotal }, () => {
          // console.log("inside call back function $ total",this.state.finalImpactDollar)
        });
        this.setState({ finalImpactFTE: fteValue }, () => {
          // console.log("inside call back function fte value",this.state.finalImpactFTE)
        });
      });
    }
  };

  pull_monetization_sowId = (localSowId) => {
    this.setState({ SowID: localSowId }, () => {
      //console.log("sow id from pull_monetization_sowId function =>",this.state.SowID)
    });
  };
  pull_monetization_fpna_name = (localFpnaName) => {
    //console.log("fpna name from pull file function => ",localFpnaName)
    this.setState({ FPnANameMonetization: localFpnaName }, () => {
      //console.log("fpna name from pull_file_function",this.state.FPnANameMonetization)
    });
  };
  pull_monetization_fpna_ohr = (localFpnaohr) => {
    //console.log("fpna ohr from pull function => ",localFpnaohr)
    this.setState({ FPnAOHRMonetization: localFpnaohr }, () => {});
  };
  pull_monetizedTotal = ({ fteValue, dollarValue }) => {
    if (fteValue) {
      this.setState({ totalMonetizedDollar: fteValue }, () => {
        //console.log(this.state.totalMonetizedDollar)
      });
    }
    if (dollarValue) {
      this.setState({ totalMonetizedFTE: dollarValue }, () => {
        //console.log(this.state.totalMonetizedFTE)
      });
    }
  };
  pull_monetizableProject = (e) => {
    if (e) {
      this.setState({ monetizableProjectSelectedOption: e }, () => {
        console.log("monetization project Option =>",this.state.monetizableProjectSelectedOption)
      });
    }
  };
  pull_monetizableStatus = (e) => {
    if (e) {
      this.setState({ monetizationStatusSelectedOption: e }, () => {
        console.log("monetization Status SelectedOption =>",this.state.monetizationStatusSelectedOption)
      });
    }
  };

  handleVDTypeChange = ({ e }) => {
    if (e) {
      this.setState({ impactOptionSelected: e }, () => {
        //console.log("impact selected option is from review idea page",this.state.impactOptionSelected)
        if (this.state.impactOptionSelected.label === "No Value Delivery") {
          this.setState(
            {
              showClientFields: false,
              showGOLFields: false,
              finalImpactDollar: 0,
              monetizableProjectSelectedOption: null,
              monetizationStatusSelectedOption: null,
              showClientApprovalFields: false,
            },
            () => {}
          );
        }
        if (
          this.state.impactOptionSelected.label === "Value Delivered to Client"
        ) {
          this.setState(
            { showClientFields: true, showGOLFields: false },
            () => {}
          );
        }
        if (
          this.state.impactOptionSelected.label === "Value Delivered to Genpact"
        ) {
          this.setState({ showClientFields: false, showGOLFields: true });
        }
        if (
          this.state.impactOptionSelected.label ===
          "Value Delivered to Client + Genpact"
        ) {
          this.setState({ showClientFields: true, showGOLFields: true });
        }
      });
    }
  };
  fetchFPAOHRforIncreaseInRevenue = (e) => {
    if (e === true) {
      this.setState({ appropriateAttachment: true }, () => {});
    } else {
      this.setState({ appropriateAttachment: false }, () => {});
    }
  };

  pullMonetizationGOLName = (localGOLName) => {
    this.setState({ golMonetizationName: localGOLName }, () => {
      //console.log(this.state.golMonetizationName)
    });
  };
  pullMonetizationGOLOhr = (localGOLOhr) => {
    this.setState({ golMonetizationOhr: localGOLOhr }, () => {
      //console.log(this.state.golMonetizationOhr)
    });
  };
  pullCategoryNotListed = (localShowHref) => {
    this.setState({ showHrefModal: localShowHref }, () => {
      console.log("hrf modal =>",this.state.showHrefModal)
    });
  };
  getStatus = () => {
    const { logAction, ideaItem } = this.state;
    if (this.state.ideaItem.WorkflowStatus) {
      if (this.state.ideaItem.WorkflowStatus === "Approve") {
        return "";
      }
      if (this.state.ideaItem.WorkflowStatus === "Reject") {
        return "REJECTED";
      }

      if (this.state.ideaItem.WorkflowStatus === "Send Back") {
        return "SENT_BACK";
      }
      if (
        this.state.ideaItem.WorkflowStatus === "Cancelled" ||
        this.state.ideaItem.ApprovalStatus === "Cancelled"
      ) {
        return "Cancelled";
      }
      if (
        this.state.ideaItem.WorkflowStatus === "Cancellation Requested" ||
        this.state.ideaItem.ApprovalStatus === "Cancellation Requested"
      ) {
        return "Cancellation Requested";
      }
    }
    if (logAction === "Approve") {
      return APPROVED;
    }
    if (logAction === "Send Back") {
      return SENT_BACK;
    }
    if (logAction === "Reject") {
      return REJECTED;
    }
    if (logAction === "Cancellation Requested") {
      return "Cancellation Requested";
    }
    if (logAction === "Pending") {
      return "Pending";
    }
    if(this.state.ideaItem.WorkflowStatus === "Pending"){
      if(logAction === "Delegate Action"){
        return "Delegated";
      }
      return "Pending";
    }

    if (ideaItem.Comment !== null) {
      if (logAction === "Idea Reinitiated by Admin") {
        return "Idea Reinitiated";
      } else {
        return "Discard Cancellation";
      }
    }
    //return "PENDING";
  };

  handleIsIdeaReplicableChange= (e) => {
    console.log(e)
    let styleStateObj = this.state.selectStyle;
    styleStateObj.IsIdeaReplicableSelectedOption = selectStyle(true);
    this.setState({selectStyle: styleStateObj,IsIdeaReplicableSelectedOption: e},()=>{
      console.log("on changee",this.state.IsIdeaReplicableSelectedOption)
    })
  };

  handleSolutionFeasibleChange= (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.solutionFeasibleSelectedOption = selectStyle(true);
    this.setState({selectStyle: styleStateObj,solutionFeasibleSelectedOption: e})
  };
  handleSolutionComplexityChange= (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.solutionComplexitySelectedOption = selectStyle(true);
    this.setState({selectStyle: styleStateObj,solutionComplexitySelectedOption: e})
  };

  

  

  handleAIDocumentClick=(link)=>{
    window.open(link,"_blank");
  }




  render() {
    // this is for idea approval details -> Idea approval status
    this.state.ideaApprovalStatus = this.getStatus();
    // console.log(this.state.ideaApprovalStatus);

    let IDEA_APPROVED = this.state.ideaItem.WorkflowStatus === "Approve";
    let IDEA_REJECTED = this.state.ideaItem.WorkflowStatus === "Reject";
    let IDEA_SEND_BACK = this.state.ideaItem.WorkflowStatus === "Send Back";
    let IDEA_REQUEST_CANCELLATION =
      this.state.ideaItem.WorkflowStatus === "Cancellation Requested";
    let IDEA_REQUEST_CANCELLATION1 =
      this.state.ideaItem.ApprovalStatus === "Cancellation Requested";
    let IDEA_CANCELLED = this.state.ideaItem.WorkflowStatus === "Cancelled";
    let IDEA_CANCELLED1 = this.state.ideaItem.ApprovalStatus === "Cancelled";
    let IDEA_UPDATERACTION = this.state.ideaItem.updaterAction;


    



    return (
      <>
        <section className="u-clearfix u-section-1" id="sec-0f7f">
          <div
            className="u-clearfix u-sheet u-sheet-1"
            style={{ marginBottom: 20 }}
          >
            <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
              <div className="u-container-layout u-container-layout-1">
                <h4
                  style={{
                    textAlign: "center",
                    color: "#00AECF",
                    fontFamily: "Arial",
                    fontSize: 22,
                  }}
                >
                  Review idea :{" "}
                  {this.state.ideaItem.IdeaIDAlpha
                    ? this.state.ideaItem.IdeaIDAlpha
                    : this.state.ideaItem.IdeaId}{" "}
                </h4>
                <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                  <div
                    className="u-container-layout u-container-layout-2"
                    style={{ textAlign: "center" }}
                  >
                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-3">
                      <div className="u-container-layout u-container-layout-3">
                        <p
                          className="u-align-left u-text u-text-2"
                          style={{ marginBottom: 13 }}
                        >
                          Owner details
                        </p>
                        <div className="row">
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Owner OHR
                            </label>{" "}
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-input"
                              disabled
                              type="text"
                              value={this.state.ideaItem.OwnerOHR}
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: 15 }} />
                        <div className="row">
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Owner Name
                            </label>{" "}
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-input"
                              disabled
                              type="text"
                              value={this.state.ideaItem.OwnerName}
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: -10 }} />
                        <p
                          className="u-align-left u-text u-text-7 u-text-custom-color-3"
                          style={{ fontSize: 11, marginBottom: 10 }}
                        >
                          (If Idea was raised for someone else, their OHR ID is
                          below)
                        </p>
                        <div className="row">
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Request For OHR
                            </label>{" "}
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-input"
                              disabled
                              type="text"
                              defaultValue={this.state.ideaItem.CreatorOHR}
                              onChange={this.handleCreatorOHRChange}
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: 10 }} />
                        <div className="row">
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Name
                            </label>{" "}
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-input"
                              disabled
                              type="text"
                              defaultValue={this.state.ideaItem.CreatorName}
                              onChange={this.handleCreatorNameChange}
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: 10 }} />
                        {this.state.ideaItem.LeanID !== null ? (
                          <div className="row">
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Project ID
                              </label>{" "}
                            </div>
                            <div className="col-md-6">
                              <input
                                className="form-input"
                                disabled
                                type="text"
                                defaultValue={this.state.ideaItem.LeanID}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: 12,
                                }}
                              >
                                &nbsp;
                              </label>{" "}
                            </div>
                          </div>
                        )}
                        <div style={{ marginBottom: 10 }} />
                      </div>
                    </div>

                    <p
                      className="visible-md-block visible-sm-block visible-xs-block"
                      style={{ marginTop: 235 }}
                    >
                      &nbsp;
                    </p>

                    <div
                      className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-4"
                      style={{ marginTop: "-305px" }}
                    >
                      <div
                        className=""
                        style={{ display: "flex", marginTop: "30px" }}
                      >
                        <div className="u-container-style u-group u-shape-rectangle u-group-5">
                          <div className="u-container-layout u-container-layout-5">
                            <p
                              className="u-align-left u-text u-text-8"
                              style={{ marginBottom: 15 }}
                            >
                              Account Details
                            </p>
                            <div className="row">
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  Account&nbsp;Name
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  list="accounts"
                                  name="accounts"
                                  placeholder="Choose Account"
                                  value={this.state.ideaItem.AccountManual}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  Holding&nbsp;Account
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  type="text"
                                  value={this.state.ideaItem.HoldingAccount}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  Vertical
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  list="vertical"
                                  name="vertical"
                                  placeholder="Choose Vertical"
                                  value={this.state.ideaItem.VerticalManual}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  Industry&nbsp;Vertical
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  list="ideationtype"
                                  name="ideationtype"
                                  placeholder="Choose Industry&nbsp;Vertical"
                                  value={this.state.ideaItem.SubVerticalName}
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />

                            {/* units and segments starts here */}
                            <div className="row">
                              <div className="col-md-5">
                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Segment</label>
                              </div>
                              <div className="col-md-7">
                                <input className="form-input" disabled name="segment" value={this.state.ideaItem.Segment ? this.state.ideaItem.Segment : ""} style={{ fontWeight: 'normal !important' }} />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-5">
                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Unit</label>
                              </div>
                              <div className="col-md-7">
                                <input className="form-input" disabled name="unit" value={this.state.ideaItem.Unit ? this.state.ideaItem.Unit : ""} style={{ fontWeight: 'normal !important' }} />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            {/* units and segments ends here */}


                            
                            <div className="row">
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  <span style={{ color: "red" }}>* </span>
                                  Campaign&nbsp;Name
                                </label>
                              </div>
                              <div className="col-md-7">
                                {/* <input className="form-input" disabled list="ideationtype" name="ideationtype" placeholder="Choose Idea Type" value={this.state.ideaItem.CampaignName} /> */}
                                <Select
                                  tabIndex={4}
                                  isSearchable={true}
                                  options={this.renderCampaignOptions()}
                                  styles={
                                    this.state.selectStyle.campaignTypeText
                                  }
                                  placeholder="Choose Campaign"
                                  onChange={(e)=>this.handleCampaignNameChange(e)}
                                  value={this.state.campaignSelectedOption}
                                />
                              </div>

                              <div className="">
                                <div className="campaign_modal_bulb campaign_tool_tip">
                                  <img 
                                      src={require("../assets/images/question_mark_2.png")}
                                      alt=""
                                      className=""
                                  />
                                  {
                                    this.state.campaignInsight
                                    ?
                                    <>
                                        <span className="my_tooltiptext">{this.state.campaignInsight}</span>
                                    </>
                                    :
                                    null
                                  }
                                </div>
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            {this.state.displayProjectTheme &&
                              this.state.dataBridgeCampaign && (
                                <>
                                  <div className="row">
                                    <div className="col-md-5">
                                      <label
                                        style={{
                                          verticalAlign: "bottom",
                                          marginBottom: -5,
                                        }}
                                      >
                                        <span style={{ color: "red" }}>*</span>
                                        DataBridge Project Theme
                                      </label>
                                    </div>
                                    <div className="col-md-7">
                                      <Select
                                        isSearchable={true}
                                        options={this.renderDataBridgeThemeOptions()}
                                        styles={
                                          this.state.selectStyle
                                            .dataBridgeProjectTheme
                                        }
                                        placeholder="Choose Project Theme"
                                        value={
                                          this.state
                                            .databridgeThemeSelectedOptions
                                        }
                                        onChange={
                                          this
                                            .handleDataBridgeProjectThemeChange
                                        }
                                      />
                                      {/* <input className="form-input" disabled list="ideationtype" name="ideationtype" placeholder="Choose Databridge Project Theme" value={this.state.ideaItem.DataBridgeTheme} /> */}
                                    </div>
                                  </div>
                                  <div style={{ marginBottom: 15 }} />
                                </>
                              )}
                            {this.state.xPollinationCampaign && (
                              <>
                                <div className="row">
                                  <div className="col-md-5">
                                    <label
                                      style={{
                                        verticalAlign: "bottom",
                                        marginBottom: -5,
                                      }}
                                    >
                                      Sub Campaign
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <Select
                                      isSearchable={true}
                                      options={this.renderSubCampaignOptions()}
                                      styles={
                                        this.state.selectStyle.subCampaignName
                                      }
                                      placeholder="Choose Sub Campaign"
                                      value={this.state.subCampaignOption}
                                      onChange={this.handleSubCampaignChange}
                                    />
                                    {/* <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Sub Campaign" value={this.state.ideaItem.SubCampaignName} /> */}
                                  </div>
                                </div>
                                <div style={{ marginBottom: 15 }} />
                              </>
                            )}

                            {this.state.chatGPTTheme ? (
                              <>
                                <div className="row">
                                  <div className="col-md-5">
                                    <label
                                      style={{
                                        verticalAlign: "bottom",
                                        marginBottom: -5,
                                      }}
                                    >
                                      <label
                                        style={{
                                          color: "red",
                                          verticalAlign: "bottom",
                                        }}
                                      >
                                        *
                                      </label>{" "}
                                      Gen AI Theme
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <Select
                                      tabIndex={5}
                                      isSearchable={true}
                                      options={this.renderChatGPToptions()}
                                      styles={
                                        this.state.selectStyle
                                          .chatGptSelectedOption
                                      }
                                      placeholder="Choose Gen AI Theme"
                                      onChange={this.handleChatGptOptionsChange}
                                      value={this.state.chatGptSelectedOption}
                                    />
                                  </div>
                                </div>
                                {/* <div style={{ marginBottom: 18 }}>&nbsp;</div> */}
                              </>
                            ) : null}
                            


                            {
                              this.state.campaignSelectedOption && this.state.campaignSelectedOption.label === AIFIRST
                              ?
                              <>  
                                <div className="row">
                                  <div className="col-md-5">
                                    <label
                                      style={{
                                        verticalAlign: "bottom",
                                        marginBottom: -5,
                                      }}
                                    >
                                      <i>AI First Sample Ideas</i>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="ai_first_doc_link ai_first_doc_red_highlight">
                                      <a
                                        className="ldt-list1"
                                        href={AI_DOC_LINK}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ textAlign: "left", fontSize: 16,color:"red" }}
                                      >Document Link </a>
                                    </div>
                                    
                                  </div>
                                </div>

                                <div className="row d_flex_align_items_center" style={{marginTop:"20px"}} >
                                  <div className="col-md-5">
                                    <label
                                      style={{
                                        verticalAlign: "bottom",
                                        marginBottom: -5,
                                      }}
                                    >
                                      AI Recommendation 
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      disabled
                                      value={this.state.isAI}
                                      placeholder="Please choose value"
                                      className="form-input disabled_input"
                                    />
                                  </div>
                                </div>
                                <div style={{ marginBottom: 20 }} />


                                <div className="row d_flex_align_items_center" style={{marginTop:"20px"}}>
                                <div className="col-md-5">
                                  <label
                                    style={{
                                      verticalAlign: "bottom",
                                      marginBottom: 5,
                                    }}
                                  >
                                    AI First BRD
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <div className="ai_first_doc_link ">
                                    {/* <a
                                      className="ldt-list1"
                                      href={this.state.AIBRD}
                                      target="_blank"
                                      rel="noreferrer"
                                      style={{ textAlign: "left", fontSize: 16 }}
                                    >{this.state.AIBRD}</a> */}
                                    <textarea className="ai_first_brd_textarea" onClick={(e)=>this.handleAIDocumentClick(this.state.AIBRD)} value={this.state.AIBRD} ></textarea>
                                  </div>
                                </div>
                              </div>

                              </>
                              :
                              null
                            }
                          </div>
                        </div>
                        <div className="u-container-layout u-container-layout-6">
                          <div className="row" style={{ marginTop: "10px" }}>
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Country
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                list="country"
                                name="country"
                                placeholder="Choose Country"
                                value={this.state.ideaItem.Country}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />
                          <div className="row">
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Region
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                list="regions"
                                name="regions"
                                placeholder="Choose Region"
                                value={this.state.ideaItem.RegionManual}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />
                          <div className="row">
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Sub-Region
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                list="subregions"
                                name="subregions"
                                placeholder="Choose Sub Region"
                                value={this.state.ideaItem.SubRegion}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />
                          <div className="row">
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Blackbelt&nbsp;Name
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                list="blackbelt"
                                name="blackbelt"
                                placeholder="Choose Blackbelt"
                                title={
                                  "OHR ID: " +
                                  this.state.ideaItem.BlackBeltUserId
                                }
                                value={this.state.ideaItem.BlackBeltUserName}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />

                          <div className="row">
                            <div className="col-md-5">
                              <label
                                className="form-attribute"
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                <label
                                  style={{
                                    color: "red",
                                    verticalAlign: "bottom",
                                  }}
                                >
                                  *
                                </label>{" "}
                                MBB
                              </label>
                            </div>
                            <div className="col-md-7">
                              <Select
                                tabIndex={10}
                                isSearchable={true}
                                value={this.state.mbbSelectedOption}
                                options={this.renderMBBOptions()}
                                styles={this.state.selectStyle.mbbUserName}
                                placeholder="Choose MBB"
                                onChange={this.handleMBBChange}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />


                          {
                            this.state.campaignSelectedOption && this.state.campaignSelectedOption.label===AIFIRST
                            ?
                            <>  
                              

                              <div style={{ marginBottom: 20 }} />
                              <div className="row d_flex_align_items_center">
                                <div className="col-md-12">
                                  <label
                                    style={{
                                      verticalAlign: "bottom",
                                      marginBottom: 5,
                                    }}
                                  >
                                    AI First Reason 
                                  </label>
                                </div>
                                <div className="col-md-12">
                                  <textarea
                                    value={this.state.AIReason}
                                    placeholder="Please provide value"
                                    className="form-input disabled_input"
                                    disabled
                                    rows={4}
                                    style={{
                                      width: "100%",
                                      border: "1px solid lightgrey",
                                      resize:"none"
                                    }}
                                  />
                                </div>
                              </div>
                              <div style={{ marginBottom: 20 }} />


                            </>
                            :
                            null
                          }

                          <div style={{ marginBottom: 15 }} />
                        </div>
                      </div>
                    </div>

                    {this.state.dataBridgeCampaign ||
                    this.state.xPollinationCampaign ? (
                      <>
                        <div style={{ borderBottom: "1px solid lightgrey" }} />
                      </>
                    ) : (
                      <div
                        style={{
                          borderBottom: "1px solid lightgrey",
                          marginBottom: 15,
                        }}
                      />
                    )}

                    <p
                      className="visible-md-block visible-sm-block visible-xs-block"
                      style={{ marginTop: -30 }}
                    >
                      &nbsp;
                    </p>

                    <div
                      id="idea1"
                      className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                      style={{ marginTop: 15 }}
                    >
                      <div className="u-container-layout u-container-layout-7">
                        <div className="u-container-style u-group u-shape-rectangle u-group-8">
                          <div className="u-container-layout u-container-layout-8">
                            <p
                              id="idea1title"
                              className="u-align-left u-text u-text-2"
                              style={{ marginBottom: 10 }}
                            >
                              Idea details
                            </p>
                            <div className="row">
                              <div className="col-md-4">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  <span style={{ color: "red" }}>*</span>{" "}
                                  Service&nbsp;Line
                                </label>
                              </div>
                              <div className="col-md-8">
                                <Select
                                  isSearchable={true}
                                  options={this.renderServiceLineOptions(
                                    this.state.serviceLineSelectedOption
                                  )}
                                  styles={
                                    this.state.selectStyle.serviceLineText
                                  }
                                  placeholder="Choose Service Line"
                                  value={this.state.serviceLineSelectedOption}
                                  onChange={(e) =>
                                    this.handleServiceLineChange(e)
                                  }
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-4">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  <span style={{ color: "red" }}>*</span>{" "}
                                  Sub-Service&nbsp;Line
                                </label>
                              </div>
                              <div className="col-md-8">
                                <Select
                                  isSearchable={true}
                                  options={this.renderSubServiceLineOptions()}
                                  styles={this.state.selectStyle.subServiceLine}
                                  placeholder="Choose Sub-Service Line"
                                  value={
                                    this.state.subServiceLineSelectedOption
                                  }
                                  onChange={(e) =>
                                    this.handleSubServiceLineChange(e)
                                  }
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-7">
                                <p className="u-align-left u-text u-text-custom-color-4 u-text-8">
                                  <span className="u-text-black form-attribute">
                                    <span style={{ color: "red" }}>*</span>
                                    &nbsp;What is the Problem ?&nbsp;
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 12,
                                        fontStyle: "italic",
                                        color: "grey",
                                      }}
                                    >
                                      (max. 2000 chars)
                                    </span>
                                  </span>
                                </p>
                              </div>
                              <div
                                className="col-md-5"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  onClick={(e) =>
                                    this.toggleTranscriptFunction(11)
                                  }
                                  src={require("../assets/images/voice.png")}
                                  className="w100"
                                  alt=""
                                  style={{ width: "24px", cursor: "pointer" }}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <textarea
                                  className="textarea-css form-attribute"
                                  rows={6}
                                  cols={80}
                                  style={{
                                    maxWidth: "100%",
                                    border: "1px solid lightgrey",
                                    ...this.state.selectStyle.Problem,
                                  }}
                                  value={this.state.ideaItem.Problem}
                                  onChange={(event) =>
                                    this.handleProblemChange(event.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {this.state.speechModal ? (
                            <UpdatedSpeech
                              modalOpen={this.state.speechModal}
                              onReviewTranscriptProblem={
                                this.handleTranscriptProblemChange
                              }
                              onReviewTranscriptSolution={
                                this.handleTranscriptSolutionChange
                              }
                              onTranscriptComment={
                                this.handleTranscriptCommentChange
                              }
                              modalId={this.state.modalIndex}
                              closeModalFunction={this.closeModalFunction}
                              previousText={this.state.previousTextForSpeech}
                              onRequestCancellation={
                                this.handleTranscriptCommentChange
                              }
                            />
                          ) : null}
                        </div>
                        <div className="u-container-style u-group u-shape-rectangle u-group-9">
                          <div
                            className="u-container-layout u-container-layout-9"
                            style={{ marginTop: -5 }}
                          >
                            <div className="row">
                              <div className="col-md-4">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  Idea Name
                                </label>
                              </div>
                              <div
                                className="col-md-8"
                                style={{ alignItems: "flex-start" }}
                              >
                                <input
                                  className="form-input"
                                  disabled
                                  type="text"
                                  value={this.state.ideaItem.IdeaName}
                                  placeholder="Eg: Cash flow improvement through process restructuring"
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: 70 }} />
                            <div className="row">
                              <div className="col-md-7">
                                <p
                                  className="u-align-left u-text u-text-custom-color-4 u-text-8"
                                  style={{ width: "287px" }}
                                >
                                  <span className="u-text-black form-attribute">
                                    <span style={{ color: "red" }}>*</span>
                                    &nbsp;How to resolve the problem&nbsp;
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 12,
                                        fontStyle: "italic",
                                        color: "grey",
                                      }}
                                    >
                                      (max. 2000 chars)
                                    </span>
                                  </span>
                                </p>
                              </div>
                              <div
                                className="col-md-5"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  onClick={(e) =>
                                    this.toggleTranscriptFunction(12)
                                  }
                                  src={require("../assets/images/voice.png")}
                                  className="w100"
                                  alt=""
                                  style={{ width: "24px", cursor: "pointer" }}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <textarea
                                  className="textarea-css form-attribute"
                                  rows={6}
                                  cols={80}
                                  style={{
                                    maxWidth: "100%",
                                    border: "1px solid lightgrey",
                                    ...this.state.selectStyle.Solution,
                                  }}
                                  value={this.state.ideaItem.Solution}
                                  onChange={(event) =>
                                    this.handleSolutionChange(
                                      event.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: 20 }}>
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Are you trying to replicate an existing Idea?
                            </label>
                            {/* <span style={{ color: 'red' }}>*</span> */}
                          </div>
                          <div className="col-md-4">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  this.state.ideaItem.NewOrReplicatedIdea ===
                                  null
                                    ? false
                                    : this.state.ideaItem.NewOrReplicatedIdea
                                }
                                onChange={() => this.showHideDiv()}
                              />
                              <span className="slider round" />
                            </label>
                          </div>
                        </div>

                        {this.state.ideaItem.NewOrReplicatedIdea && (
                          <>
                            <div
                              className="col-md-8"
                              style={{ marginBottom: 15, width: "100%" }}
                            />
                            <div className="row">
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  <label style={{ color: "red" }}>*</label>{" "}
                                  Provide the ID of the executed Idea which you
                                  want to replicate?
                                </label>
                              </div>
                              <div className="col-md-2">
                                <input
                                  className="textarea-css form-attribute"
                                  type="text"
                                  value={
                                    this.state.ideaItem.ReplicationProjectId
                                  }
                                  onChange={(event) => {
                                    this.handleReplicationIdChange(
                                      event.target.value
                                    );
                                  }}
                                  onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                      this.validateReplicationId(
                                        event.target.value
                                      );
                                    }
                                  }}
                                  style={{
                                    border: "1px solid lightgrey",
                                    ...this.state.selectStyle
                                      .ReplicationProjectId,
                                  }}
                                />
                              </div>
                              <div className="col-md-1">
                                <i
                                  className="fa fa-check-square-o"
                                  style={{
                                    marginTop: 5,
                                    color: "#FF555F",
                                    fontSize: 24,
                                  }}
                                  onClick={(event) => {
                                    this.validateReplicationId(
                                      this.state.ideaItem.ReplicationProjectId
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div
                          className="col-md-8"
                          style={{ marginBottom: 15, marginTop: 5 }}
                        />
                      </div>
                    </div>

                    {this.state.ideaItem.WorkflowStatus &&
                      (this.state.ideaItem.WorkflowStatus === "Approve" ||
                        this.state.ideaItem.WorkflowStatus === "Approved") && (
                        <>
                          <div
                            style={{
                              borderBottom: "1px solid lightgrey",
                              marginBottom: 15,
                            }}
                          />
                          <div
                            id="Potential Impact"
                            className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                          >
                            <div className="u-container-layout u-container-layout-7">
                              <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                <div className="u-container-layout u-container-layout-8">
                                  <p
                                    id="Potential Impact"
                                    className="u-align-left u-text u-text-2"
                                    style={{ marginBottom: 10 }}
                                  >
                                    Additional details
                                  </p>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <span className="form-attribute">
                                        LDT Lead Name
                                      </span>
                                    </div>
                                    <div className="col-md-6">
                                      <input
                                        className="form-input"
                                        disabled
                                        list="ldtName"
                                        name="ldtName"
                                        placeholder="Choose LDT Lead"
                                        title={
                                          "OHR ID: " + this.state.ideaItem.LDTId
                                        }
                                        value={this.state.ideaItem.LDTLeadName}
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginBottom: 5 }} />
                                  <div
                                    style={{ marginTop: 10, marginBottom: 14 }}
                                  />
                                  {this.state.ideaItem.NewOrReplicatedIdea && (
                                    <div className="row">
                                      <div className="col-md-10">
                                        <label
                                          style={{
                                            verticalAlign: "bottom",
                                            marginBottom: -5,
                                          }}
                                        >
                                          Have you verified the replication id
                                          and confirm this to be replication
                                          project?
                                        </label>
                                      </div>
                                      <div className="col-md-2">
                                        <label className="switch">
                                          <input
                                            disabled
                                            type="checkbox"
                                            checked={
                                              this.state.ideaItem
                                                .IsReplicationVerified
                                            }
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                      <div style={{ marginBottom: 45 }} />
                                    </div>
                                  )}
                                  {!this.state.ideaItem.NewOrReplicatedIdea && (
                                    <div className="row">
                                      <div className="col-md-10">
                                        <label
                                          style={{
                                            verticalAlign: "bottom",
                                            marginBottom: 17,
                                          }}
                                        >
                                          &nbsp;
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <p
                                className="visible-md-block visible-sm-block visible-xs-block"
                                style={{ marginTop: 45 }}
                              >
                                &nbsp;
                              </p>

                              <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                <div
                                  className="u-container-layout u-container-layout-9"
                                  style={{ marginTop: "177px" }}
                                >
                                  <p
                                    className="visible-md-block visible-sm-block visible-xs-block"
                                    style={{ marginTop: "-260px" }}
                                  >
                                    &nbsp;
                                  </p>
                                  <div className="row">
                                    <div className="col-md-5">
                                      <span className="form-attribute">
                                        Certification Level
                                      </span>{" "}
                                    </div>
                                    <div className="col-md-7">
                                      <input
                                        className="form-input"
                                        disabled
                                        name="certlevel"
                                        value={
                                          this.state.ideaItem
                                            .CertificationLevelName
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginBottom: 15 }} />
                                  <div className="row">
                                    <div className="col-md-5">
                                      <span className="form-attribute">
                                        Transformation Lever
                                      </span>
                                    </div>
                                    <div className="col-md-7">
                                      <input
                                        className="form-input"
                                        disabled
                                        name="translever"
                                        value={
                                          this.state.ideaItem
                                            .TransformationLeverName
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginBottom: 15 }} />
                                  {this.state.ideaItem
                                    .TransformationLeverName === "Digital" && (
                                    <>
                                      <div className="row">
                                        <div className="col-md-5">
                                          <span className="form-attribute">
                                            Digital Project Type
                                          </span>
                                        </div>
                                        <div className="col-md-7">
                                          <input
                                            className="form-input"
                                            disabled
                                            name="translever"
                                            value={
                                              this.state.ideaItem
                                                .DigitalProjectType
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div style={{ marginBottom: 15 }} />
                                    </>
                                  )}

                                  <div className="row">
                                    <div className="col-md-5">
                                      <span className="form-attribute">
                                        Work Type
                                      </span>{" "}
                                    </div>
                                    <div className="col-md-7">
                                      <input
                                        className="form-input"
                                        disabled
                                        name="worktype"
                                        value={
                                          !this.state.ideaItem
                                            .CertificationWorkTypeName ||
                                          this.state.ideaItem
                                            .CertificationWorkTypeName === ""
                                            ? "None"
                                            : this.state.ideaItem
                                                .CertificationWorkTypeName
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginBottom: 15 }} />
                                  <div className="row">
                                    <div className="col-md-5">
                                      <span className="form-attribute">
                                        Tools/Methodology Used
                                      </span>
                                    </div>
                                    <div className="col-md-7">
                                      <input
                                        className="form-input"
                                        disabled
                                        name="methodology"
                                        value={
                                          this.state.ideaItem.MethodologyName
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginBottom: 25 }} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    {this.state.reviewComments && (
                      <>
                        <div
                          style={{
                            borderBottom: "1px solid lightgrey",
                            marginBottom: 15,
                          }}
                        />
                        <div
                          id="Approval Status"
                          className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                        >
                          <div className="u-container-layout u-container-layout-7">
                            <p
                              className="u-align-left u-text u-text-2"
                              style={{ marginBottom: 10 }}
                            >
                              Idea Approval details
                            </p>
                            <div className="row">
                              <div className="col-md-2">
                                <span className="form-attribute">
                                  Approval Status
                                </span>{" "}
                              </div>
                              <div className="col-md-2">
                                {/* <input className="form-input" disabled name="finalapprovalstatus" value={this.state.logWorkflowstatus} /> */}
                                {/* <input className="form-input" disabled name="Status" value={IDEA_APPROVED ? APPROVED : (IDEA_REJECTED ? REJECTED : (IDEA_SEND_BACK ? SENT_BACK : ((IDEA_REQUEST_CANCELLATION || IDEA_REQUEST_CANCELLATION1) ? "Cancellation Requested" :( (IDEA_CANCELLED || IDEA_CANCELLED1) ? "Cancelled" :(this.state.ideaItem.Comment !== null ? 'Reject Cancellation' :PENDING)))))}  /> */}
                                {/* <input className="form-input" disabled name="Status" value={IDEA_APPROVED ? APPROVED : (IDEA_REJECTED ? REJECTED : (IDEA_SEND_BACK ? SENT_BACK : ((IDEA_REQUEST_CANCELLATION || IDEA_REQUEST_CANCELLATION1) ? "Cancellation Requested" :( (IDEA_CANCELLED || IDEA_CANCELLED1) ? "Cancelled" :(this.state.ideaItem.Comment !== null ? (this.state.logAction ==='Idea Reinitiated by Admin' ? 'Idea Reinitiated' : 'Discard Cancellation') :PENDING)))))}  /> */}

                                {/* <input className="form-input" disabled name="Status" 
                                                                    value={this.state.logAction ? this.state.logAction: (IDEA_APPROVED ? APPROVED : (IDEA_REJECTED ? REJECTED : (IDEA_SEND_BACK ? SENT_BACK : ((IDEA_REQUEST_CANCELLATION || IDEA_REQUEST_CANCELLATION1) ? "Cancellation Requested" :( (IDEA_CANCELLED || IDEA_CANCELLED1) ? "Cancelled" :(this.state.ideaItem.Comment !== null ? (this.state.logAction ==='Idea Reinitiated by Admin' ? 'Idea Reinitiated' : 'Discard Cancellation') :PENDING))))))}  /> */}

                                <input
                                  className="form-input"
                                  disabled
                                  value={this.state.ideaApprovalStatus}
                                />
                              </div>
                            </div>
                            <div className="row" style={{ marginTop: 15 }}>
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  Comments
                                </label>
                              </div>
                              <div
                                className="col-md-2"
                                style={{ marginLeft: 20 }}
                              >
                                <button
                                  className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                  onClick={(e) =>
                                    this.setState({
                                      commentLog: !this.state.commentLog,
                                    })
                                  }
                                >
                                  Comment Log
                                </button>
                              </div>
                            </div>
                            <div className="row" style={{ marginTop: 5 }}>
                              <div className="col-md-12">
                                <textarea
                                  className="form-input"
                                  disabled
                                  rows={3}
                                  cols={88}
                                  style={{
                                    maxWidth: "100%",
                                    border: "1px solid lightgrey",
                                  }}
                                  name="Comments"
                                  value={this.state.ideaItem.Comment}
                                />
                              </div>
                            </div>
                            <div className="text-left mt-4">
                              {this.state.commentLog &&
                              this.state.reviewComments ? (
                                <>
                                  <div className="custom_modal">
                                    <div className="modal_body">
                                      <div className="row">
                                        <div className="col-md-8">
                                          <h3 className="modal_title">
                                            Idea Approval Comment Log
                                          </h3>
                                        </div>
                                        <div className="col-md-4">
                                          <button
                                            type="button"
                                            className="close text-right"
                                            onClick={(e) =>
                                              this.setState({
                                                commentLog: false,
                                              })
                                            }
                                          >
                                            &times;
                                          </button>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div
                                          className="col-md-2 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            Date & Time
                                          </label>
                                        </div>
                                        <div
                                          className="col-md-2 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            OHR
                                          </label>
                                        </div>
                                        <div
                                          className="col-md-2 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            Name
                                          </label>
                                        </div>
                                        <div
                                          className="col-md-1 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            Role
                                          </label>
                                        </div>
                                        <div
                                          className="col-md-2 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            Action
                                          </label>
                                        </div>
                                        <div
                                          className="col-md-3 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            Comment
                                          </label>
                                        </div>
                                      </div>
                                      {this.state.reviewComments.map(
                                        (item, index) => {
                                          return (
                                            <div className="row" key={index}>
                                              <div
                                                className="col-md-2 text-center"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {this.formatDateStringWithTime(
                                                    new Date(item.CreatedDate)
                                                  )}
                                                </label>
                                              </div>
                                              <div
                                                className="col-md-2 text-center"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {item.UpdaterOHR}
                                                </label>
                                              </div>
                                              <div
                                                className="col-md-2 text-center"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {item.UpdaterName}
                                                </label>
                                              </div>
                                              <div
                                                className="col-md-1 text-center role_over_flow_class"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {item.UpdaterRole
                                                    ? item.UpdaterRole
                                                    : item.Action ===
                                                        "Idea Updated by Admin" ||
                                                      item.Action ===
                                                        "Idea Reinitiated by Admin"
                                                    ? "Admin"
                                                    : item.Action ===
                                                      "Delegate Action"
                                                    ? "BB"
                                                    : item.UpdaterOHR ===
                                                      this.state.ideaItem
                                                        .BlackBeltUserId
                                                    ? "BB"
                                                    : item.UpdaterOHR ===
                                                      this.state.ideaItem.MBBOHR
                                                    ? "MBB"
                                                    : item.UpdaterOHR ===
                                                      this.state.ideaItem
                                                        .OwnerOHR
                                                    ? "Idea Owner"
                                                    : item.UpdaterRole}
                                                </label>
                                              </div>
                                              <div
                                                className="col-md-2 text-center"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {item.UpdaterAction
                                                    ? item.UpdaterAction
                                                    : item.Action ===
                                                      "Idea Reinitiated by Admin"
                                                    ? "Idea Reinitiated"
                                                    : item.Action ===
                                                      "Idea Updated by Admin"
                                                    ? item.WorkflowStatus !==
                                                      "Pending"
                                                      ? item.WorkflowStatus
                                                      : "Idea Updated"
                                                    : item.Action ===
                                                      "Delegate Action"
                                                    ? "Delegated"
                                                    : item.WorkflowStatus ===
                                                      "Pending"
                                                    ? "Discard Cancellation"
                                                    : item.WorkflowStatus}
                                                </label>
                                              </div>
                                              <div
                                                className="col-md-3 text-center"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {item.Comment
                                                    ? item.Comment
                                                    : "-"}
                                                </label>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div
                      style={{
                        borderBottom: "1px solid lightgrey",
                        marginBottom: 15,
                      }}
                    />

                    {this.state.ideaItem.ApprovalStatus ===
                      REQUEST_CANCELLATION ||
                    this.state.ideaItem.WorkflowStatus ===
                      REQUEST_CANCELLATION ? (
                      <>
                        {this.state.ideaItem.ApprovalStatus ===
                          REQUEST_CANCELLATION && (
                          <div
                            id="Approval Status"
                            className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                          >
                            <div className="u-container-layout u-container-layout-7">
                              <p
                                className="u-align-left u-text u-text-2"
                                style={{ marginBottom: 10 }}
                              >
                                Project Approval details
                              </p>
                              <div className="row">
                                <div className="col-md-2">
                                  <span className="form-attribute">
                                    Approval Status
                                  </span>{" "}
                                </div>
                                <div className="col-md-2">
                                  <input
                                    className="form-input"
                                    disabled
                                    name="finalapprovalstatus"
                                    value={this.state.ideaItem.ApprovalStatus}
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: 15 }}>
                                <div className="col-md-5">
                                  <label
                                    style={{
                                      verticalAlign: "bottom",
                                      marginBottom: -5,
                                    }}
                                  >
                                    Approval Comments
                                  </label>
                                </div>
                                <div
                                  className="col-md-2"
                                  style={{ marginLeft: 20 }}
                                >
                                  <button
                                    className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                    onClick={(e) =>
                                      this.setState({
                                        finalCommentLog:
                                          !this.state.finalCommentLog,
                                      })
                                    }
                                  >
                                    Comment Log
                                  </button>
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: 5 }}>
                                <div className="col-md-12">
                                  <textarea
                                    className="form-input"
                                    disabled
                                    rows={3}
                                    cols={88}
                                    style={{
                                      maxWidth: "100%",
                                      border: "1px solid lightgrey",
                                    }}
                                    name="Comments"
                                    value={this.state.ideaItem.FinalComments}
                                  />
                                </div>
                              </div>
                              <div className="text-left mt-4">
                                {this.state.finalCommentLog &&
                                this.state.finalReviewComments ? (
                                  <>
                                    <div className="custom_modal">
                                      <div className="modal_body">
                                        <div className="row">
                                          <div className="col-md-8">
                                            <h3 className="modal_title">
                                              Lean Approval Comment Log
                                            </h3>
                                          </div>
                                          <div className="col-md-4">
                                            <button
                                              type="button"
                                              className="close text-right"
                                              onClick={(e) =>
                                                this.setState({
                                                  finalCommentLog: false,
                                                })
                                              }
                                            >
                                              &times;
                                            </button>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div
                                            className="col-md-2 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              Date & Time
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-2 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              OHR
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-2 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              Name
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-1 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              Role
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-2 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              Action
                                            </label>
                                          </div>
                                          <div
                                            className="col-md-3 text-center"
                                            style={{
                                              border: "1px solid lightgrey",
                                              backgroundColor: "gainsboro",
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontWeight: 100,
                                                verticalAlign: "bottom",
                                              }}
                                            >
                                              Comment
                                            </label>
                                          </div>
                                        </div>
                                        {this.state.finalReviewComments.map(
                                          (item, index) => {
                                            return (
                                              <div className="row" key={index}>
                                                <div
                                                  className="col-md-2 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {this.formatDateStringWithTime(
                                                      new Date(item.CreatedDate)
                                                    )}
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-2 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {item.UpdaterOHR}
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-2 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {item.UpdaterName}
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-1 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {item.UpdaterRole
                                                      ? item.UpdaterRole
                                                      : item.Action ===
                                                          "Idea Updated by Admin" ||
                                                        item.Action ===
                                                          "Idea Reinitiated by Admin"
                                                      ? "Admin"
                                                      : item.Action ===
                                                        "Idea was reviewed by MBB"
                                                      ? "MBB"
                                                      : item.Action ===
                                                        "Idea was reviewed by LDT"
                                                      ? "LDT"
                                                      : item.UpdaterOHR ===
                                                        this.state.ideaItem
                                                          .OwnerOHR
                                                      ? "Owner"
                                                      : "BB"}
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-2 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {item.UpdaterAction
                                                      ? item.UpdaterAction
                                                      : item.Action ===
                                                        "Idea Reinitiated by Admin"
                                                      ? "Idea Reinitiated"
                                                      : item.Action ===
                                                        "Idea Updated by Admin"
                                                      ? item.ApprovalStatus
                                                      : item.WorkflowStatus ===
                                                          "Completed" ||
                                                        item.WorkflowStatus ===
                                                          "Approve" ||
                                                        item.WorkflowStatus ===
                                                          "Approved"
                                                      ? "Approved"
                                                      : item.WorkflowStatus ===
                                                          "Rejected" ||
                                                        item.WorkflowStatus ===
                                                          "Reject"
                                                      ? "Rejected"
                                                      : item.WorkflowStatus ===
                                                        REQUEST_CANCELLATION
                                                      ? REQUEST_CANCELLATION
                                                      : item.WorkflowStatus ===
                                                        "Pending Documents"
                                                      ? "Discard Cancellation"
                                                      : item.WorkflowStatus ===
                                                        "Cancelled"
                                                      ? "Cancelled"
                                                      : "Sent Back"}
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-3 text-center"
                                                  style={{
                                                    border:
                                                      "1px solid lightgrey",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      lineheight: 2,
                                                      verticalAlign: "bottom",
                                                    }}
                                                  >
                                                    {item.Comment
                                                      ? item.Comment
                                                      : "-"}
                                                  </label>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        )}
                        <div
                          id="Approval Status"
                          className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                        >
                          <div className="u-container-layout u-container-layout-7">
                            <p
                              className="u-align-left u-text u-text-2"
                              style={{ marginBottom: 10 }}
                            >
                              {" "}
                              Request Cancellation details
                            </p>
                            <div className="row">
                              <div className="col-md-2">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  <span style={{ color: "red" }}>*</span>{" "}
                                  Cancellation Status
                                </label>{" "}
                              </div>
                              <div className="col-md-2">
                                <Select
                                  isSearchable={false}
                                  options={this.renderCancelRequestOption()}
                                  styles={
                                    this.state.selectStyle
                                      .workFlowStatusSelectedOption
                                  }
                                  placeholder="Choose Status"
                                  onChange={this.handleCancelRequestChange}
                                />
                              </div>
                            </div>
                            <div
                              className="col-md-9"
                              style={{ marginBottom: 5, width: "100%" }}
                            />

                            <div className="row">
                              <div className="col-md-6">
                                <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                  <span className="u-text-black form-attribute">
                                    <span style={{ color: "red" }}>*</span>
                                    &nbsp;Comments&nbsp;
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        fontSize: 12,
                                        fontStyle: "italic",
                                        color: "grey",
                                      }}
                                    >
                                      {" "}
                                      (max. 500 chars)
                                    </span>
                                  </span>
                                </p>
                              </div>
                              <div
                                className="col-md-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  onClick={(e) =>
                                    this.toggleTranscriptFunction(10)
                                  }
                                  src={require("../assets/images/voice.png")}
                                  className="w100"
                                  alt=""
                                  style={{ width: "24px", cursor: "pointer" }}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                {this.state.CommentEmpty ? (
                                  <textarea
                                    className="textarea-css form-attribute"
                                    style={{
                                      maxWidth: "100%",
                                      border: "1px solid lightgrey",
                                      ...this.state.selectStyle.comment,
                                    }}
                                    placeholder="Please provide your comments regarding the cancellation request."
                                    onChange={this.handleCommentChange}
                                    rows={3}
                                    cols={88}
                                    value={this.state.Comment}
                                  />
                                ) : (
                                  <textarea
                                    className="textarea-css form-attribute"
                                    style={{
                                      maxWidth: "100%",
                                      border: "1px solid lightgrey",
                                      ...this.state.selectStyle.comment,
                                    }}
                                    placeholder="Please provide your comments regarding the cancellation request."
                                    onChange={this.handleCommentChange}
                                    rows={3}
                                    cols={88}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          id="Approval Status"
                          className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                        >
                          <div className="u-container-layout u-container-layout-7">
                            <p
                              className="u-align-left u-text u-text-2"
                              style={{ marginBottom: 10 }}
                            >
                              Approval details
                            </p>
                            <div className="row">
                              <div className="col-md-2">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  <span style={{ color: "red" }}>*</span>{" "}
                                  Approval Status
                                </label>{" "}
                              </div>
                              <div className="col-md-2">
                                <Select
                                  value={this.state.workFlowStatusSelectedOption}
                                  isSearchable={false}
                                  options={this.renderStatusOptions()}
                                  styles={this.state.selectStyle.workFlowStatusSelectedOption}
                                  placeholder="Choose Status"
                                  onChange={this.handleAccountNameChange}
                                />
                              </div>
                            </div>
                            <div
                              className="col-md-9"
                              style={{ marginBottom: 5, width: "100%" }}
                            />
                            <div className="row">
                              <div className="col-md-7">
                                <div className="row">
                                  <div className="col-md-11">
                                    <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                      <span className="u-text-black form-attribute">
                                        <span style={{ color: "red" }}>*</span>
                                        &nbsp;Comments&nbsp;
                                        <span
                                          style={{
                                            fontWeight: "normal",
                                            fontSize: 12,
                                            fontStyle: "italic",
                                            color: "grey",
                                          }}
                                        >
                                          (max. 500 chars)
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                  <div
                                    className="col-md-1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      onClick={(e) =>
                                        this.toggleTranscriptFunction(2)
                                      }
                                      src={require("../assets/images/voice.png")}
                                      className="w100"
                                      alt=""
                                      style={{
                                        width: "24px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <textarea
                                  className="textarea-css form-attribute"
                                  style={{
                                    maxWidth: "100%",
                                    border: "1px solid lightgrey",
                                    ...this.state.selectStyle.comment,
                                  }}
                                  rows={3}
                                  cols={88}
                                  placeholder="Please provide your comments in support of the Approval decision."
                                  onChange={this.handleCommentChange}
                                  value={this.state.Comment}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {this.state.showAdditionalDetails && (
                      <div
                        style={{
                          borderBottom: "1px solid lightgrey",
                          marginBottom: 15,
                          marginTop: 15,
                        }}
                      />
                    )}
                    {this.state.showAdditionalDetails && (
                      <div
                        id="Potential Impact"
                        className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                      >
                        <div className="u-container-layout u-container-layout-7" style={{display:"flex"}}>
                          <div className="u-container-style u-group u-shape-rectangle u-group-8">
                            <div className="u-container-layout u-container-layout-8">
                              <p
                                id="Potential Impact"
                                className="u-align-left u-text u-text-2"
                                style={{ marginBottom: 10 }}
                              >
                                Additional details
                              </p>
                              <div className="row d_flex_align_items_center">
                                <div className="col-md-6">
                                  <label
                                    style={{
                                      verticalAlign: "bottom",
                                      marginBottom: -5,
                                    }}
                                  >
                                    <span style={{ color: "red" }}>*</span> LDT
                                    Lead Name
                                  </label>{" "}
                                </div>
                                <div className="col-md-6">
                                  <Select
                                    isSearchable={false}
                                    value={this.state.LDTLeadSelectedOption}
                                    options={this.renderLeadOptions()}
                                    styles={this.state.selectStyle.LDTId}
                                    placeholder="Choose LDT Lead"
                                    onChange={this.handleLDTLeadOptionChange}
                                  />
                                </div>
                              </div>
                              <div style={{ marginBottom: 10 }} />


                              {this.state.campaignSelectedOption &&
                              this.state.campaignSelectedOption.label ===
                                AIFIRST ? (
                                <>
                                  <div style={{ marginBottom: 10 }} />
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-6">
                                      <label
                                        style={{
                                          verticalAlign: "bottom",
                                          marginBottom: -5,
                                        }}
                                      >
                                        <span style={{ color: "red" }}>*</span>{" "}
                                        Is the solution feasible ?
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <Select
                                        isSearchable={false}
                                        options={
                                          YESORNO
                                        }
                                        value={
                                          this.state.solutionFeasibleSelectedOption
                                        }
                                        styles={
                                          this.state.selectStyle
                                            .solutionFeasibleSelectedOption
                                        }
                                        onChange={(e)=>
                                          this.handleSolutionFeasibleChange(e)
                                        }
                                        placeholder="Please Choose"
                                      />
                                    </div>
                                  </div>
                                   
                                  <div style={{ marginBottom: 10 }} />
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-6">
                                      <label
                                        style={{
                                          verticalAlign: "bottom",
                                          marginBottom: -5,
                                        }}
                                      >
                                        <span style={{ color: "red" }}>*</span>{" "}
                                        Solution Complexity
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <Select
                                        isSearchable={false}
                                        options={
                                          SOLUTIONCOMPLEXITY
                                        }
                                        value={
                                          this.state.solutionComplexitySelectedOption
                                        }
                                        styles={
                                          this.state.selectStyle
                                            .solutionComplexitySelectedOption
                                        }
                                        onChange={(e)=>
                                          this.handleSolutionComplexityChange(e)
                                        }
                                        placeholder="Please Choose"
                                      />
                                    </div>
                                  </div>
                                    
                                  <div style={{ marginBottom: 10 }} />
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-6">
                                      <label
                                        style={{
                                          verticalAlign: "bottom",
                                          marginBottom: -5,
                                        }}
                                      >
                                        <span style={{ color: "red" }}>*</span>{" "}
                                        Is the Idea replicable
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <Select
                                        isSearchable={false}
                                        options={
                                          YESORNO
                                        }
                                        value={
                                          this.state.IsIdeaReplicableSelectedOption
                                        }
                                        styles={
                                          this.state.selectStyle
                                            .IsIdeaReplicableSelectedOption
                                        }
                                        onChange={(e)=>
                                          this.handleIsIdeaReplicableChange(e)
                                        }
                                        placeholder="Choose replicability Index"
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginBottom: 10 }} />
                                </>
                              ) : null}



                              
                              <div
                                style={{ marginTop: 10, marginBottom: 14 }}
                              />
                              

                              
                              {this.state.ideaItem.NewOrReplicatedIdea && (
                                <div className="row">
                                  <div className="col-md-10">
                                    <label
                                      style={{
                                        verticalAlign: "bottom",
                                        marginBottom: -5,
                                      }}
                                    >
                                      <span style={{ color: "red" }}>*</span>{" "}
                                      Have you verified the replication id and
                                      confirm this to be replication project?
                                    </label>
                                  </div>
                                  <div className="col-md-2">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={
                                          this.state.ideaItem
                                            .IsReplicationVerified === null
                                            ? false
                                            : this.state.ideaItem
                                                .IsReplicationVerified
                                        }
                                        onChange={() =>
                                          this.showHideVerifyReplicationDiv()
                                        }
                                      />
                                      <span className="slider round" />
                                    </label>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <p
                            className="visible-md-block visible-sm-block visible-xs-block"
                            style={{ marginTop: 45 }}
                          >
                            &nbsp;
                          </p>

                          <div className="u-container-style u-group u-shape-rectangle u-group-9" style={{marginTop:"-10px"}}>
                            <div className="u-container-layout u-container-layout-9" >
                              <div className="row d_flex_align_items_center">
                                <div className="col-md-5">
                                  <label
                                    style={{
                                      verticalAlign: "bottom",
                                      marginBottom: -5,
                                    }}
                                  >
                                    <span style={{ color: "red" }}>*</span>{" "}
                                    Certification Level
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <Select
                                    isSearchable={false}
                                    options={this.renderCertLevelsOptions()}
                                    styles={
                                      this.state.selectStyle
                                        .certLevelsSelectedOption
                                    }
                                    value={this.state.certLevelsSelectedOption}
                                    onChange={this.handleCertLevelChange}
                                    placeholder="Choose Certification Level"
                                  />
                                </div>
                              </div>
                              <div style={{ marginBottom: 10 }} />
                              <div className="row d_flex_align_items_center">
                                <div className="col-md-5">
                                  <label
                                    style={{
                                      verticalAlign: "bottom",
                                      marginBottom: -5,
                                    }}
                                  >
                                    <span style={{ color: "red" }}>*</span>{" "}
                                    Transformation Lever
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <Select
                                    className="transformation_lever_disabled"
                                    isSearchable={false}
                                    isDisabled={
                                      this.state.campaignSelectedOption 
                                      &&
                                      ( this.state.campaignSelectedOption.label === "Data Bridge" ||
                                        this.state.campaignSelectedOption.label === "AI First" ||
                                        this.state.campaignSelectedOption.label === "AI First - C&H Gen AI hackathon" ||
                                        this.state.campaignSelectedOption.label === "AI First - Enterprise 360"
                                      )
                                        ? true
                                        : false
                                    }
                                    options={this.renderTransLeversOptions()}
                                    value={this.state.transSelectedOption}
                                    styles={
                                      this.state.selectStyle.transSelectedOption
                                    }
                                    onChange={this.handleTransLeversChange}
                                    placeholder="Choose Transformation Lever"

                                    
                                  />
                                </div>
                              </div>
                              <div style={{ marginBottom: 10 }} />
                              <div className="row d_flex_align_items_center">
                                {this.state.digitalProjectEnabled ? (
                                  <>
                                    <div className="col-md-5">
                                      <label
                                        style={{
                                          verticalAlign: "bottom",
                                          marginBottom: -5,
                                        }}
                                      >
                                        <span style={{ color: "red" }}>*</span>{" "}
                                        Digital Project Type
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-7"
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <Select
                                        disabled
                                        isSearchable={false}
                                        options={this.renderDigitalProjectTypeOptions()}
                                        styles={
                                          this.state.selectStyle
                                            .digitalProjectTypeSelectedOptions
                                        }
                                        value={
                                          this.state
                                            .digitalProjectTypeSelectedOptions
                                        }
                                        onChange={
                                          this.handleDigitalProjectTypeChange
                                        }
                                        placeholder="Choose Project Type"
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </div>
                              <div className="row d_flex_align_items_center">
                                {this.state.workTypeEnabled && (
                                  <>
                                    <div className="col-md-5">
                                      <label
                                        style={{
                                          verticalAlign: "bottom",
                                          marginBottom: -5,
                                        }}
                                      >
                                        <span style={{ color: "red" }}>*</span>{" "}
                                        Work Type
                                      </label>
                                    </div>
                                    <div
                                      className="col-md-7"
                                      style={{ marginBottom: 10 }}
                                    >
                                      <Select
                                        disabled
                                        isSearchable={false}
                                        isDisabled={
                                          this.state.campaignSelectedOption &&
                                          this.state.campaignSelectedOption
                                            .label === "Data Bridge"
                                            ? true
                                            : false
                                        }
                                        options={this.renderCertWorkTypeOptions(
                                          this.state.certLevelsSelectedOption
                                        )}
                                        styles={
                                          this.state.selectStyle
                                            .certWorkTypeSelectedOption
                                        }
                                        value={
                                          this.state.certWorkTypeSelectedOption
                                        }
                                        onChange={this.handleCertWorkTypeChange}
                                        placeholder="Choose Work Type"
                                      />
                                    </div>
                                  </>
                                )}
                                {this.state.certLevelsSelectedOption &&
                                  this.state.certLevelsSelectedOption.label ===
                                    "GB" &&
                                  !this.state.workTypeEnabled && (
                                    <>
                                      <div className="col-md-5">
                                        <label
                                          style={{
                                            verticalAlign: "bottom",
                                            marginBottom: -5,
                                          }}
                                        >
                                          Work Type
                                        </label>
                                      </div>
                                      <div
                                        className="col-md-7"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <input
                                          className="form-input"
                                          disabled
                                          type="text"
                                          value={
                                            this.state
                                              .certWorkTypeSelectedOption
                                              ? this.state
                                                  .certWorkTypeSelectedOption
                                                  .label === null
                                                ? ""
                                                : this.state
                                                    .certWorkTypeSelectedOption
                                                    .label
                                              : ""
                                          }
                                          style={{ fontStyle: "normal" }}
                                        />
                                      </div>
                                    </>
                                  )}
                              </div>
                              {/* <div style={{ marginBottom: 15 }} /> */}
                              <div className="row d_flex_align_items_center">
                                <div className="col-md-5">
                                  <label
                                    style={{
                                      verticalAlign: "bottom",
                                      marginBottom: -5,
                                    }}
                                  >
                                    <span style={{ color: "red" }}>*</span>{" "}
                                    Tools/Methodology Used
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <Select
                                    isSearchable={true}
                                    options={this.renderMethodologyOptions()}
                                    value={
                                      this.state.methodologySelectedOptions
                                    }
                                    styles={
                                      this.state.selectStyle
                                        .methodologySelectedOptions
                                    }
                                    onChange={this.handleMethodologyChange}
                                    placeholder="Choose Methodology"
                                    maxMenuHeight={160}
                                  />
                                </div>
                              </div>
                              <div style={{ marginBottom: 20 }} />
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {this.state.workFlowStatusSelectedOption &&
                    this.state.workFlowStatusSelectedOption.label ===
                      "Approve" ? (
                      <>
                        {this.state.certLevelsSelectedOption &&
                        this.state.certLevelsSelectedOption.label === "Lean" ? (
                          <>
                            <div
                              className=""
                              style={{
                                borderBottom: "1px solid lightgray",
                                marginBottom: "30px",
                              }}
                            ></div>
                            <ValueDelivery
                              func={this.pull_data}
                              viewIdea={false}
                              vdType={this.handleVDTypeChange}
                              IdeaId={this.state.ideaItem.IdeaId}
                              fetchMonetizationFileDetails={this.pull_file_data}
                              fetchMonetizationFpnaOhr={
                                this.pull_monetization_fpna_ohr
                              }
                              fetchMonetizationFpnaName={
                                this.pull_monetization_fpna_name
                              }
                              fetchMonetizationSowID={
                                this.pull_monetization_sowId
                              }
                              bbMonetizationTotal={this.pull_monetizedTotal}
                              monetizableProject={this.pull_monetizableProject}
                              monetizableStatus={this.pull_monetizableStatus}
                              fetchFPAOHRforIncreaseInRevenue={
                                this.fetchFPAOHRforIncreaseInRevenue
                              }
                              bbMonetizationApproval={false}
                              postFpnaValid={false}
                              fetchDeletedBIRows={
                                this.remove_bi_data_after_delete
                              }
                              fetchMonetizationGOLName={
                                this.pullMonetizationGOLName
                              }
                              fetchMonetizationGOLOhr={
                                this.pullMonetizationGOLOhr
                              }
                              fetchCategoryNotListed={this.pullCategoryNotListed}
                            />
                          </>
                        ) : null}
                      </>
                    ) : null}



{this.state.showHrefModal ? (
                      <>
                        <div className="custom_modal href_modal">
                          <div className="modal_body href_modal_body">
                            <div className="href_modal_dialog">
                              <p>
                                To add a new category please raise a Helpmate
                                ticket, Click here to View SOP
                              </p>
                              <div className="href_modal_body_buttons">
                                <div className="btn-group href_modal_body_buttons">
                                  <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3">
                                    <a
                                      href="https://genpactonline.sharepoint.com/:b:/r/sites/LeanDigitalTransformation/Documents/KM%20Page%20-%202021/Certification%20Guidelines,%20SOPs%20%26%20Handbooks/Certification%20guidelines,%20SOPs%20%26%20Handbooks/Systems%20%26%20Tools/Helpmate-Data%20Related%20Request/ProGEAR%20Value%20Delivery%20Category%20Addition%20SOP.pdf?csf=1&web=1&e=5kKbFg"
                                      style={{ color: "#FFF" }}
                                      target="_blank"
                                    >
                                      Click Here
                                    </a>
                                  </button>
                                  <button
                                    className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                    onClick={(e) =>
                                      this.setState({ showHrefModal: false })
                                    }
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    

                    


                  {
                    this.state.impactOptionSelected && this.state.impactOptionSelected.label === 'No Value Delivery'
                    && this.state.ideaItem.WorkflowStatus === "Pending"
                    ?
                    <>
                      
                    </>

                    :
                    <div
                      className=""
                      style={{
                        borderTop: "1px solid lightgray",
                        margin: "20px 0px",
                      }}
                    ></div>
                  }


                    <div className="btn-group" style={{ marginTop: 15 }}>
                      <button
                        className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                        onClick={this.submitIdea}
                      >
                        Save
                      </button>
                      <button
                        className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                        onClick={this.cancelEvent}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ReviewIdeaPage;
