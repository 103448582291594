import React, { Component } from 'react'
import Toast from 'light-toast';
import Select from 'react-select';
import { selectValidation as selectStyle, textBoxValidation } from "./FieldValidation";
import { GET_IDEA_URL, TEMPLATE_UPDATE, UPDATE_IDEA_URL, AWAITING_MBB_APPROVAL, GET_METADATA, APPROVED, PENDING, SENT_BACK, REJECTED, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, ACCESS_TOKEN, COMPLETED_STATUS, USER_DATA, SIGNOFF_CONTENT, PPT_CONTENT, REQUEST_CANCELLATION, BI_FORM_DATE, GOL_APPROVAL_CONTENT, AWAITING_QAULITY_CHECK_REVIEW } from '../assets/constants/constants';
import Checksum from "./Checksum";
import { withRouter } from 'react-router-dom';


const clientApprovalOptions = [
    { value: '0', label: 'Yes' },
    { value: '1', label: 'No' },
];

export default class DiagnosticOrPrescriptiveTemplate extends Component {

    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);

    constructor(props) {
        super(props);
        let propsData = this.props.location;
        console.log("props data=>", propsData)
        console.log(propsData.templateType)
        this.state = {
            templateFromProps:propsData.templateType,
            ideaID: propsData.state,
            LeanID: "",
            ideaItem: {},
            RecType:"",
            selectStyle: {},
            showModal: false,
            data: [],

            currentData: {
                id: 0,
                SOrd: "",
                Problem: "",
                Technique: "",
                Cause: "",
                Recomm: ""
            },
            editingIndex: null,
            keyInsightsDrawn: "",
            additionalSolutions: [],
            additionSolDeployed: ""
        };

    }
    async componentDidMount() {
        // const location = this.props.location;
        // const ID = new URLSearchParams(location.search).get('SUQ');
        // console.log("ID =>",ID)
        // this.setState({
        //     ideaID: ID,
        // },()=>{
        //     console.log(this.state.ideaID)
        // });
        //const decodedID = atob(ID);
        //console.log("decorded =>",decodedID)
        console.log("idea id =>",this.state.ideaID)

        Toast.loading("Fetching template data...", () => { });
        // setTimeout(() => {console.log("loading")}, 3000);
        // await this.fetchIdea(decodedID);
        await this.fetchIdea();
        Toast.hide();
    }


    fetchIdea = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
            };
            fetch(`${GET_IDEA_URL}IdeaId=${this.state.ideaID}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log("fetch idea", data);
                    console.log("template type=>",this.state.templateFromProps)
                    this.setState(
                        {
                            ideaItem: data.ResultSets.Table1[0] ? data.ResultSets.Table1[0] : [],
                            LeanID: data.ResultSets.Table1 ? data.ResultSets.Table1[0].LeanID : "",
                            data: data.Templates.ResultSets.Table2 && data.Templates.ResultSets.Table2[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table2,
                            toolsUsed:data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table1[0].Tools,
                            keyInsightsDrawn:data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table1[0].Insights,
                            metricsHighlighted:data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table1[0].Metrics,
                            notesOrComments:data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table1[0].Comment,
                            additionSolDeployed:data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? "" : data.Templates.ResultSets.Table1[0].AddSolution,
                            RecType: data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? "" : data.Templates.ResultSets.Table1[0].RecType,

                        },
                        () => {
                            console.log(data)
                            if(this.state.data){
                                let localData = [...this.state.data]
                                localData.sort((a, b) => a.SOrd - b.SOrd);
                                this.setState({data:localData},()=>{
                                    console.log("sorted in ascending order =>",this.state.data)
                                })
                            }
                            
                            
                            if (this.state.additionSolDeployed) {
                                let options = {
                                    label: "Yes",
                                    value: 0
                                }
                                this.setState({ additionalSolutions: options }, () => {})
                            } else {
                                let options = {
                                    label: "No",
                                    value: 0
                                }
                                this.setState({ additionalSolutions: options }, () => {})
                            }
                            resolve();
                        }
                    );
                })
                .catch((error) => {
                    console.log("error =>", error)
                    Toast.fail("Error while fetching idea. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    };



    openModal = () => {
        if (this.state.data.length < 20){
            this.setState({ showModal: true });
        }else{
            alert("You can add maximum of 20 rows.");
            return;
        }
        
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            currentData: { Problem: '', Recomm: '', Cause: '', Technique: '' },
            editingIndex: null,
        });
        let styleStateObj = this.state.selectStyle;
        styleStateObj.Problem = textBoxValidation(true);
        styleStateObj.Technique = textBoxValidation(true);
        styleStateObj.Cause = textBoxValidation(true);
        styleStateObj.Recomm = textBoxValidation(true);
        this.setState({selectStyle: styleStateObj})
    };

    addRow = () => {
       
        if(this.validateModal()){
            const { currentData, editingIndex, data } = this.state;
            if (editingIndex !== null) {
                // Editing existing row
                const updateddata = [...data];
                updateddata[editingIndex] = currentData;
                this.setState({
                    data: updateddata,
                    editingIndex: null,
                }, () => { });
            }
            else {
                    if (data.length < 20) {
                        console.log("data =>",data)
                        const newData = {
                            ...currentData,
                            SOrd: data.length,
                            id: data.length,
                        }
                        this.setState((prevState) => ({
                            data: [...prevState.data, newData],
                        }));
                    }
                    else {
                        alert("You can add maximum of 20 rows.");
                        return;
                    }
                
            }
            this.closeModal();
        }
        
    };

    editRow = (index) => {
        const { data } = this.state;
        const employeeToEdit = data[index];
        this.setState({
            showModal: true,
            currentData: { ...employeeToEdit },
            editingIndex: index,
        },()=>{
            if(this.state.currentData){
                let styleStateObj = this.state.selectStyle;
                styleStateObj.Problem = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj
                })
                styleStateObj.Technique = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj
                })
                styleStateObj.Cause = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj
                })
                styleStateObj.Recomm = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
        });
    };

    deleteRow = (index) => {
        if (window.confirm("Are you sure you want to delete this row ?")) {
            const { data } = this.state;
            const updateddata = [...data];
            updateddata.splice(index, 1);
            this.setState({
                data: updateddata,
            });
        }

    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace= value.replace(/^\s+|\s+$/gm,'');;
        if(name === "Problem"){
            if(nonWhiteSpace.length <= 1500){
                this.setState((prevState) => ({
                    currentData: {
                        ...prevState.currentData,
                        [name]: value,
                    },
                }));
            }else{
                //window.alert("Problem cannot be more than 1500 characters.");
                const trimmedValue = nonWhiteSpace.slice(0, 1500);
                this.setState((prevState) => ({
                    currentData: {
                        ...prevState.currentData,
                        [name]: trimmedValue,
                    },
                }));
            }
            styleStateObj.Problem = textBoxValidation(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        if(name === "Technique"){
            if(nonWhiteSpace.length <= 255){
                this.setState((prevState) => ({
                    currentData: {
                        ...prevState.currentData,
                        [name]: value,
                    },
                }));
            }else{
                //window.alert("Technique cannot be more than 1500 characters.");
                const trimmedValue = nonWhiteSpace.slice(0, 255);
                this.setState((prevState) => ({
                    currentData: {
                        ...prevState.currentData,
                        [name]: trimmedValue,
                    },
                }));
            }
            styleStateObj.Technique = textBoxValidation(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        if(name === "Cause"){
            if(nonWhiteSpace.length <= 1500){
                this.setState((prevState) => ({
                    currentData: {
                        ...prevState.currentData,
                        [name]: value,
                    },
                }));
            }else{
                //window.alert("Cause cannot be more than 1500 characters.");
                const trimmedValue = nonWhiteSpace.slice(0, 1500);
                this.setState((prevState) => ({
                    currentData: {
                        ...prevState.currentData,
                        [name]: trimmedValue,
                    },
                }));
            }
            styleStateObj.Cause = textBoxValidation(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        if(name === "Recomm"){
            if(nonWhiteSpace.length <= 1500){
                this.setState((prevState) => ({
                    currentData: {
                        ...prevState.currentData,
                        [name]: value,
                    },
                }));
            }else{
                //window.alert("Recomm cannot be more than 1500 characters.");
                const trimmedValue = nonWhiteSpace.slice(0, 1500);
                this.setState((prevState) => ({
                    currentData: {
                        ...prevState.currentData,
                        [name]: trimmedValue,
                    },
                }));
            }
            styleStateObj.Recomm = textBoxValidation(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }
    };

    handleKeyInsightsDrawn = (e) => {
        let nonWhiteSpace= e.replace(/^\s+|\s+$/gm,'');
        console.log("insights drawn =>",e.length)
        let styleStateObj = this.state.selectStyle;
        styleStateObj.keyInsightsDrawn = textBoxValidation(true);
        this.setState({
            selectStyle: styleStateObj
        })
        if(nonWhiteSpace.length<1500){
            this.setState({ keyInsightsDrawn: e }, () => { })
        }else{
            // window.alert("Key insights drawn cannot be more than 1500 characters.");
            // return;
            const trimmedValue = nonWhiteSpace.slice(0, 1500);
            this.setState({ keyInsightsDrawn: trimmedValue }, () => { })
        }
    }
    handleAdditonalSolutionDeployed = (e) => {
        let nonWhiteSpace= e.replace(/^\s+|\s+$/gm,'');
        let styleStateObj = this.state.selectStyle;
        styleStateObj.additionSolDeployed = textBoxValidation(true);
        this.setState({
            selectStyle: styleStateObj
        })
        if(nonWhiteSpace.length<1500){
            this.setState({ additionSolDeployed: e }, () => { })
        }else{
            const trimmedValue = nonWhiteSpace.slice(0, 1500);
            this.setState({ additionSolDeployed: trimmedValue }, () => { })
        }
    }

    additionalSolutiononChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.additionalSolutions = selectStyle(true);
        this.setState({
            additionalSolutions: {
                value: e.value,
                label: e.label
            },
            selectStyle: styleStateObj
        }, () => {
            if (this.state.additionalSolutions.label === "No") {
                this.setState({ additionSolDeployed: "" }, () => { })
            }
        });
    };

    validateModal = ()=>{
        const { currentData, editingIndex, data } = this.state;
        this.isExceedlimit = false;
        let styleStateObj = this.state.selectStyle;
        let isValid = true;
        if (
            currentData.Problem.trim() === '' ||
            currentData.Technique.trim() === '' ||
            currentData.Cause.trim() === '' ||
            currentData.Recomm.trim() === '') 
        {
            alert('Please fill in all fields before saving.');
            if(currentData.Problem === ""){
                styleStateObj.Problem = textBoxValidation(false);
                isValid=false;
                this.setState({selectStyle: styleStateObj})
            }
            if(currentData.Technique === ""){
                isValid=false;
                styleStateObj.Technique = textBoxValidation(false);
                this.setState({selectStyle: styleStateObj})
            }
            if(currentData.Cause === ""){
                isValid=false;
                styleStateObj.Cause = textBoxValidation(false);
                this.setState({selectStyle: styleStateObj})
            }
            if(currentData.Recomm === ""){
                isValid=false;
                styleStateObj.Recomm = textBoxValidation(false);
                this.setState({selectStyle: styleStateObj})
            }
            return;
        }
        else
        {
            if (currentData.Problem.trim().length > 1501){
                isValid=false;
                styleStateObj.Problem = textBoxValidation(false);
                this.setState({selectStyle: styleStateObj})
                Toast.fail("Problem statement cannot be more than 1500 characters. Please check and resubmit", 3000, () => { })
            }
            if (currentData.Technique.length > 256){
                isValid=false;
                styleStateObj.Technique = textBoxValidation(false);
                this.setState({selectStyle: styleStateObj})
                Toast.fail("Technique statement cannot be more than 255 characters. Please check and resubmit", 3000, () => { })
            }
            if (currentData.Cause.length > 1501){
                isValid=false;
                styleStateObj.Cause = textBoxValidation(false);
                this.setState({selectStyle: styleStateObj})
                Toast.fail("Root Cause statement cannot be more than 1500 characters. Please check and resubmit", 3000, () => { })
            }
            if (currentData.Recomm.length > 1501){
                isValid=false;
                styleStateObj.Recomm = textBoxValidation(false);
                this.setState({selectStyle: styleStateObj})
                Toast.fail("Recommendation statement cannot be more than 1500 characters. Please check and resubmit", 3000, () => { })
            }
        }
        return isValid;
    }

    validate = () => {
        let isValid = true;
        this.isExceedlimit = false;
        let styleStateObj = this.state.selectStyle;

        console.log("lean id=>",this.state.ideaItem.LeanID)

        // if (this.state.ideaItem.LeanID === "" && this.state.ideaItem.LeanID === null || this.state.ideaItem.LeanID === undefined || !this.state.ideaItem.LeanID){
        //     isValid = false;
        //     Toast.fail("Only Projects which have been converted into Lean can only be saved", 3000, () => { })
        // }

        // if((parseInt(this.state.ideaItem.OwnerOHR) === parseInt(this.sessionData.profile.oHRId))){
        //     if(this.state.RecType && this.state.RecType ==="DA"){
        //         isValid=false;
        //         Toast.fail("Template cannot be saved if the user has already filled the Descriptive or Prescriptive template for this Idea. User can only save one template among Descriptive, Diagnostic or Prescriptive", 5000, () => { })
                
        //     }
        //     if(this.state.RecType && this.state.RecType ==="DPA"){
        //         isValid=false;
        //         Toast.fail("Template can only be saved once by the User", 3000, () => { })
        //     }
        // }

        if (this.state.keyInsightsDrawn === "" || this.state.keyInsightsDrawn === null || this.state.keyInsightsDrawn === undefined || !this.state.keyInsightsDrawn) {
            isValid = false;
            this.isExceedlimit = true;
            styleStateObj.keyInsightsDrawn = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            if (this.state.keyInsightsDrawn.length > 1501) {
                Toast.fail("Insights drawn cannot be more than 1500 characters. Please check and resubmit", 3000, () => { })
                isValid = false;
                this.isExceedlimit = true;
                styleStateObj.keyInsightsDrawn = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            } else {
                styleStateObj.keyInsightsDrawn = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            styleStateObj.keyInsightsDrawn = textBoxValidation(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }

        if (!this.state.additionalSolutions || this.state.additionalSolutions === null || this.state.additionalSolutions === undefined || this.state.additionalSolutions.length === 0) {
            isValid = false;
            this.isExceedlimit = true
            styleStateObj.additionalSolutions = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.additionalSolutions = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        if (this.state.additionalSolutions && this.state.additionalSolutions.label === "Yes") {
            if (this.state.additionSolDeployed === "" || this.state.additionSolDeployed === null || this.state.additionSolDeployed === undefined || !this.state.additionSolDeployed) {
                isValid = false;
                this.isExceedlimit = true;
                styleStateObj.additionSolDeployed = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else {
                if (this.state.additionSolDeployed.length > 1500) {
                    Toast.fail("Solution deployed cannot be more than 1500 characters. Please check and resubmit", 3000, () => { })
                    isValid = false;
                    this.isExceedlimit = true;
                    styleStateObj.additionSolDeployed = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                } else {
                    styleStateObj.additionSolDeployed = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }

            }
        }


        console.log("data =>",this.state.data)
        console.log("data length=>",this.state.data.length)

        if(this.state.data.length === 0){
            Toast.fail("Please fill up the table data before saving.", 3000, () => { })
            isValid = false;
        }

        this.state.data && this.state.data.map((item, index) => {
            if (item.Problem.length > 1501) {
                Toast.fail("Problem statement cannot be more than 1500 characters. Please check and resubmit", 3000, () => { })
                isValid = false;
                this.isExceedlimit=true;
                styleStateObj.Problem = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            if (item.Technique.length > 256) {
                Toast.fail("Technique statement cannot be more than 255 characters. Please check and resubmit", 3000, () => { })
                isValid = false;
                // this.isExceedlimit=true;
                // styleStateObj.keyInsightsDrawn = textBoxValidation(false);
                // this.setState({
                //     selectStyle: styleStateObj
                // })
            }
            if (item.Cause.length > 1501) {
                Toast.fail("Root Cause statement cannot be more than 1500 characters. Please check and resubmit", 3000, () => { })
                isValid = false;
                // this.isExceedlimit=true;
                // styleStateObj.keyInsightsDrawn = textBoxValidation(false);
                // this.setState({
                //     selectStyle: styleStateObj
                // })
            }
            if (item.Recomm.length > 1501) {
                Toast.fail("Recommendation statement cannot be more than 1500 characters. Please check and resubmit", 3000, () => { })
                isValid = false;
                // this.isExceedlimit=true;
                // styleStateObj.keyInsightsDrawn = textBoxValidation(false);
                // this.setState({
                //     selectStyle: styleStateObj
                // })
            }


        })



        

        if (!isValid) {
            Toast.fail('Required fields are missing or have invalid values. Please correct and try again.', 3000, () => { });
        }
        return isValid;
    }


    saveTemplate = () => {
        if ((parseInt(this.state.ideaItem.OwnerOHR) === parseInt(this.sessionData.profile.oHRId)) || (parseInt(this.state.ideaItem.BlackBeltUserId) === parseInt(this.sessionData.profile.oHRId))) {
            if (this.validate()) {
                if (window.confirm("Are you sure you want to save this template?")) {
                    Toast.loading("saving template...", () => { });
                    let param = {
                        ProjectID: this.state.LeanID ? this.state.LeanID : "",
                        // TemplateType: "UpdateDiagnosticOrPrescriptive",
                        TemplateType: "updateDPA",
                        TemplateData: this.state.data,
                        Insights: this.state.keyInsightsDrawn ? this.state.keyInsightsDrawn : "",
                        AddSolution: this.state.additionSolDeployed ? this.state.additionSolDeployed : ""
                    }
                    console.log(param)
                    let finalJsonData = Checksum(param);
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization":  "Bearer " + this.accessToken,
                        },
                        body: JSON.stringify(param)
                    };
                    fetch(`${TEMPLATE_UPDATE}Checksum=${finalJsonData}`, requestOptions)
                        .then(response => response.json())
                        .then(data => {
                            Toast.hide();
                            Toast.success('Template saved successfully!', 3000, () => {
                                window.history.go(-1);
                            });
                        })
    
                }
            }
            
        }else{
            Toast.fail("Only Project owner and Project Mentor can save template.", 3000, () => { });
            return;
        }

    }


    cancelEvent = () => {
        if(window.confirm("Are you sure you want to close this template ?")){
            window.history.go(-1);
        }
    }

    render() {
        const { showModal, currentData, data } = this.state;
        return (
            <div className="">
                <div id="Upload" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                    <div className="u-container-layout u-container-layout-1 " style={{paddingLeft:"30px"}}>
                        {/* <div className="discriptiveAnalyticsGoBack">
                            <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3 template_save_btn" onClick={(e) => this.templateGoBack()}>Go Back</button>
                        </div> */}
                        <div className="dynamic_form_title_block">
                            {/* <h2 className="" style={{textAlign: "center",margin:"40px 0px 0px 0px"}}>{this.state.templateFromProps ? this.state.templateFromProps : null} Analytics</h2> */}
                            <h2 className="" style={{textAlign: "center",margin:"40px 0px 0px 0px"}}>{this.state.templateFromProps ?  this.state.templateFromProps : this.state.ideaItem.DBProjectType} Analytics</h2>

                            <h4 style={{
                                textAlign: "center",
                                color: "#00AECF",
                                fontFamily: "Arial",
                                fontSize: 22,
                                marginTop: "15px",
                                paddingBottom: "20px",
                            }}>Project Id: {this.state.LeanID ? this.state.LeanID : ""}
                            </h4>
                            
                        </div>

                        <div className="dynamic_form_btn_block">
                            <div className="row">
                                <div className="col-md-8">
                                    <button className="dynamic_form_max_row_btn" disabled> Max Limit: 30 Rows</button>
                                </div>
                                <div className="col-md-4" style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button className="dynamic_form_add_row_btn" style={{ marginRight: "0px" }} onClick={this.openModal} disabled={this.state.counter === 30 ? true : false}>Add Row</button>
                                </div>
                            </div>
                        </div>

                        <div className="text-left mt-4">
                            {
                                showModal
                                    ?
                                    <>
                                        <div className="">
                                            <div className="custom_modal hide_modal_scroll_bars">
                                                <div className="modal_body" style={{}}>
                                                    <div className="row">
                                                        <div className="col-md-11">
                                                            <h3 className="modal_title text-center" >Add / Update Data</h3>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <button type="button" className="close text-right" onClick={this.closeModal}>&times;</button>
                                                        </div>
                                                    </div>
                                                    <div className="edit_row_field_blocks">
                                                        <div className="">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="">
                                                                        <label className="form-attribute"><span style={{ color: 'red' }}> *</span> Problem
                                                                            <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 1500 chars)</span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="diagnostic_prescriptive_template_textarea">
                                                                        <textarea
                                                                            tabindex="0"
                                                                            rows={3}
                                                                            className="textarea-css form-attribute"
                                                                            placeholder="Provide problem statement"
                                                                            type="text"
                                                                            name="Problem"
                                                                            value={currentData.Problem}
                                                                            onChange={this.handleInputChange}
                                                                            style={{ maxWidth: '100%', ...this.state.selectStyle.Problem }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="">
                                                                        <label className="form-attribute"><span style={{ color: 'red' }}> *</span> Technique
                                                                            <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 255 chars)</span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="diagnostic_prescriptive_template_textarea">
                                                                        <textarea
                                                                            rows={3}
                                                                            placeholder="Provide technique statement"
                                                                            className="textarea-css form-attribute"
                                                                            type="text"
                                                                            name="Technique"
                                                                            value={currentData.Technique}
                                                                            onChange={this.handleInputChange}
                                                                            style={{ maxWidth: '100%', ...this.state.selectStyle.Technique }}

                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="edit_row_gap_between_rows">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="">
                                                                        <label className="form-attribute"><span style={{ color: 'red' }}> *</span> Root Cause
                                                                            <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 1500 chars)</span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="diagnostic_prescriptive_template_textarea">
                                                                        <textarea
                                                                            rows={3}
                                                                            placeholder="Provide root cause"
                                                                            className="textarea-css form-attribute"
                                                                            type="text"
                                                                            name="Cause"
                                                                            value={currentData.Cause}
                                                                            onChange={this.handleInputChange}
                                                                            style={{ maxWidth: '100%', ...this.state.selectStyle.Cause }}

                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="">
                                                                        <label className="form-attribute"><span style={{ color: 'red' }}> *</span> Recommendations
                                                                            <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 1500 chars)</span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="diagnostic_prescriptive_template_textarea">
                                                                        <textarea
                                                                            rows={3}
                                                                            placeholder="Provide recommendations"
                                                                            className="textarea-css form-attribute"
                                                                            type="text"
                                                                            name="Recomm"
                                                                            value={currentData.Recomm}
                                                                            onChange={this.handleInputChange}
                                                                            style={{ maxWidth: '100%', ...this.state.selectStyle.Recomm }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="cluster_modal_btn_group">
                                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3 saveEditedRowBtn" onClick={this.addRow}>Save</button>
                                                        <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.closeModal} style={{ marginRight: "15px" }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                    :
                                    null
                            }
                        </div>
                        <div className="dynamic_form_table diagnostic_prescriptive_template_table">
                            <table className="table review-idea-table">
                                <thead>
                                    <tr>
                                        <th className="dynamic_form_sl_no_th">Sr No</th>
                                        <th>Problem</th>
                                        <th>Technique</th>
                                        <th>Root Cause</th>
                                        <th>Recommendations</th>
                                        <th style={{width:"85px"}}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data && this.state.data.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="dynamic_form_sl_no"><label>{index + 1}</label></td>
                                                    <td className='overflow_td_alignment'><label data-toggle="tooltip" title={item.Problem}>{item.Problem}</label></td>
                                                    <td className='overflow_td_alignment'><label data-toggle="tooltip" title={item.Technique}>{item.Technique}</label></td>
                                                    <td className='overflow_td_alignment'><label data-toggle="tooltip" title={item.Cause}>{item.Cause}</label></td>
                                                    <td className='overflow_td_alignment'><label data-toggle="tooltip" title={item.Recomm}>{item.Recomm}</label></td>
                                                    <td className="" style={{display:'flex'}}>
                                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "5px 8px",margin:'0px',marginRight:"15px" }} title="Edit" onClick={() => this.editRow(index)}><i  className="fa fa-pencil"></i></button>
                                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "5px 8px",margin:'0px' }} title="Delete" onClick={() => this.deleteRow(index)}><i  className="fa fa-trash" aria-hidden="true"></i></button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="form_below_fields_block">
                            <div className="form_below_fields">
                                <div className="" style={{ marginBottom: "20px" }}>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="row">
                                                <div className="col-md-12 gol_field_div text-left">
                                                    <span className="form-attribute">
                                                        <span style={{ color: 'red' }}>* </span>
                                                        Key Insights Drawn
                                                        <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 1500 chars)</span>
                                                    </span>
                                                </div>

                                                <div className="col-md-12">
                                                    <textarea
                                                        value={this.state.keyInsightsDrawn}
                                                        className="textarea-css form-input discriptive_textarea textarea_with_1500_chars"
                                                        placeholder='Please provide key insights drawn'
                                                        onChange={(e) => this.handleKeyInsightsDrawn(e.target.value)}
                                                        style={this.state.selectStyle.keyInsightsDrawn}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-5">
                                            <div className="row">
                                                <div className="col-md-7 gol_field_div text-left">
                                                    <span className="form-attribute">
                                                        <span style={{ color: 'red' }}>* </span>
                                                        Are there any additional solutions deployed as part of this project?
                                                    </span>
                                                </div>
                                                <div className="col-md-5">
                                                    <Select
                                                        options={clientApprovalOptions}
                                                        onChange={(e) => this.additionalSolutiononChange(e)}
                                                        value={this.state.additionalSolutions}
                                                        placeholder="Please choose"
                                                        styles={this.state.selectStyle.additionalSolutions}
                                                    />
                                                </div>
                                            </div>


                                            {
                                                this.state.additionalSolutions && this.state.additionalSolutions.label === "Yes"
                                                    ?
                                                    <>
                                                        <div className="" style={{marginTop:"30px"}}>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form_below_fields_textarea">
                                                                        <div className="gol_field_div text-left">
                                                                            <span className="form-attribute">
                                                                                <span style={{ color: 'red' }}>* </span>
                                                                                Additional solutions deployed
                                                                                <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 1500 chars)</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <textarea
                                                                        rows={4}
                                                                        value={this.state.additionSolDeployed}
                                                                        className="textarea-css form-input discriptive_textarea textarea_with_1500_chars"
                                                                        placeholder='Please provide if any additional solution deployed'
                                                                        onChange={(e) => this.handleAdditonalSolutionDeployed(e.target.value)}
                                                                        style={this.state.selectStyle.additionSolDeployed}
                                                                    />
                                                                </div>
                                                               
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }


                                        </div>
                                    </div>
                                </div>
                                

                            </div>
                        </div>

                        <div className="" style={{ padding: "0px 30px" }}>
                            <div className="templateGoBackBtnBlock">
                                <div className="btn-group">
                                    <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3 dynamic_form_add_row_btn" style={{ marginRight: "15px " }} onClick={(e) => this.saveTemplate()}>Save</button>
                                    <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={(e) => this.cancelEvent()}>Cancel</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>


        )
    }

}











