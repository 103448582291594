import React, { Component } from 'react'
import { GET_IDEA_URL, APPROVED, PENDING, SENT_BACK, REJECTED, GET_METADATA, REVIEW_IDEA_URL, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, ACCESS_TOKEN, COMPLETED_STATUS, AWAITING_LDT_APPROVAL, USER_DATA, SIGNOFF_CONTENT, PPT_CONTENT, REQUEST_CANCELLATION,BI_FORM_DATE,AWAITING_QAULITY_CHECK_REVIEW } from '../assets/constants/constants';
import Select from 'react-select';
import Toast from 'light-toast';
import { selectValidation as selectStyle, textBoxValidation } from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";
import UpdatedSpeech from './UpdatedSpeech'


export class LDTReviewIdeaPage extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);
    constructor(props) {
        super(props)

        const isApprovalPage = `${ this.props.location.approval }`;
        let propsData= this.props.location;
        this.state = {
            ideaItem: {},
            WorkflowStatus: "",
            Comment: "",
            accountName: "",
            campaignName: "",
            serviceLineName: "",
            dataBridgeCampaign: false,
            xPollinationCampaign: false,
            approvalPage: isApprovalPage,
            businessImpactMasterList: [],
            businessImpactValueArray: [],
            summaryArray: [],
            fteTotal: 0,
            dollarTotal: 0.0,
            selectStyle: {},    
            ldtComment: "",
            finalApproveOptionsValue: [{
                WorkflowStatusId: 1,
                WorkflowStatusName: COMPLETED_STATUS
            },
            {
                WorkflowStatusId: 2,
                WorkflowStatusName: "Rejected"
            },
            {
                WorkflowStatusId: 3,
                WorkflowStatusName: "Send Back"
            },
            ],
            approvalSelectedOption: null,
            propsData:propsData,
            Role:"",
            speechModal:false,
            previousTextForSpeech:"",
            duplicateBIValueArray:[{
                ImpactType:"",
                MonetizableType:"",
                biSubCategory:"",
                fteValue:"",
                dollarValue:""
            }],
            biFormDate:BI_FORM_DATE,
            showHistoricalBI:false,
            sortedBiImpactMasterList:[],
            ideaCreatedDate:"",
            showClientFields:false,
            showGOLFields:false,
            
            golFilename:"",
            golContent:"",
            golFileType:"",
            golOhr:"",
            golEmail:"",
            golFullname:"",

            fpnaFilename:"",
            fpnaContent:"",
            fpnaFileType:"",
            fpnaName:"",
            fpnaEmail:"",
            fpnaOhr:"",
            fpnaApprovalAttachedSelectedOption:null,
            
            clientName:"",
            clientRole:"",
            clientSignOffDate:"",
            clientApprovalEmailSelectedOption:null,
            signOffFilename:""
        }
        this.isExceedlimit= false;
    }

    async componentDidMount() {
        Toast.loading("Fetching Idea & Metadata...", () => { });
        await this.fetchIdea();
        // Toast.loading("Fetching Metadata...", () => { });
        await this.fetchMetadata();
        Toast.hide();            
    }

    formatDateString(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
    }
    
    fetchIdea = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            // fetch(`${GET_IDEA_URL}IdeaId=944`, requestOptions)
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    
                    // const projectDeckMetadata = data.ResultSets.Table1[0].ProjectPPTDeck ? data.ResultSets.Table1[0].ProjectPPTDeck : "";
                    // const signOffMetadata = data.ResultSets.Table1[0].CustomerLeadSignOff ? data.ResultSets.Table1[0].CustomerLeadSignOff : "";
                    const projectFilename = data.ResultSets.Table1[0].ProjectPPTFileName ? data.ResultSets.Table1[0].ProjectPPTFileName : "";
                    const signOffFilename = data.ResultSets.Table1[0].CustomerSignoffFileName ? data.ResultSets.Table1[0].CustomerSignoffFileName : "";
                    const projectFileType = data.ResultSets.Table1[0].ProjectPPTType ? data.ResultSets.Table1[0].ProjectPPTType : "";
                    const signOffFileType = data.ResultSets.Table1[0].CustomerSignoffType ? data.ResultSets.Table1[0].CustomerSignoffType : "";

                    // const projectContent = "data:" + projectFileType + ";base64," + projectDeckMetadata;
                    // const signOffContent = "data:" + signOffFileType + ";base64," + signOffMetadata;

                    this.setState({
                        ideaItem: data.ResultSets.Table1[0],
                        reviewComments: data.ReviewComments.Table1,
                        finalReviewComments: data.FinalReviewComments.Table1,
                        WorkflowStatus: data.ResultSets.Table1[0].WorkflowStatus,
                        Comment: data.ResultSets.Table1[0].Comment,
                        showSourceId: data.ResultSets.Table1[0].TransformationLeverName === "Digital" ? true : false,
                        projectDeckFilename: projectFilename,
                        signOffFilename: signOffFilename,
                        projectFileType: projectFileType,
                        signOffFileType: signOffFileType,
                        // projectDeckContent: projectContent,
                        // signOffContent: signOffContent,
                        sourceId: data.ResultSets.Table1[0].SourceId,
                        dataBridgeCampaign: (data.ResultSets.Table1[0].CampaignName === DATA_BRIDGE_CAMPAIGN),
                        xPollinationCampaign: (data.ResultSets.Table1[0].CampaignName === X_POLLINATION_CAMPAIGN),
                        ideaCreatedDate:data.ResultSets.Table1[0].CreatedDate,
                        businessImpactValueArray: data.BIData ? (data.BIData.Table1 ? (data.BIData.Table1.length > 0 ? data.BIData.Table1 : [] ) : [] ) : [],

                    }, () => {
                        console.log("idea item",this.state.ideaItem)
                        let localSummaryArray = [];
                        let dataArray = [...this.state.businessImpactValueArray];
                        let localIndex = 0;
                        let dataElement;
                        for (let dataIndex = 0; dataIndex < dataArray.length; dataIndex++) {
                            dataElement = dataArray[dataIndex];
                            localIndex = 0;
                            for (; localIndex < localSummaryArray.length; localIndex++) {
                                const element = localSummaryArray[localIndex];
                                if (element.impacttype === dataElement.ImpactType && element.monetizableType === dataElement.MonetizableType) {
                                    localSummaryArray[localIndex].fteValue = parseFloat(element.fteValue) + parseFloat(dataElement.FTEImpact);
                                    localSummaryArray[localIndex].dollarValue = parseFloat(element.dollarValue) + parseFloat(dataElement.DollarImpact);
                                    break;
                                }
                            }
                            if (localIndex === localSummaryArray.length) {
                                localSummaryArray[localIndex] = {};
                                localSummaryArray[localIndex].impacttype = dataElement.ImpactType;
                                localSummaryArray[localIndex].monetizableType = dataElement.MonetizableType;
                                localSummaryArray[localIndex].fteValue = dataElement.FTEImpact ? parseFloat(dataElement.FTEImpact) : 0.0
                                localSummaryArray[localIndex].dollarValue = dataElement.DollarImpact ? parseFloat(dataElement.DollarImpact) : 0.0;
                            }
                        }
                        
                        this.setState({
                            summaryArray: localSummaryArray,
                            duplicateBIValueArray:this.state.businessImpactValueArray
                        },()=>{});

                        if(this.state.ideaItem.CustomerSignoffFileName !== null && this.state.ideaItem.CustomerSignoffFileName !== undefined && this.state.ideaItem.CustomerSignoffFileName.length !== 0){
                            this.setState({showClientFields:true},()=>{})
                        }
                        if(this.state.ideaItem.GOLFileName !== null && this.state.ideaItem.GOLFileName !== undefined && this.state.ideaItem.GOLFileName.length !== 0){
                            this.setState({showGOLFields:true},()=>{})
                        }
                        if(this.state.ideaItem.FPnAFileName !== null && this.state.ideaItem.FPnAFileName !== undefined && this.state.ideaItem.FPnAFileName.length !== 0){
                            this.setState({showGOLFields:true},()=>{})
                        }
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching idea. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }

    fetchMetadata = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "",
                EntityName: ""
            }
            const requestOptions = {
                method: 'POST',
                headers: { 
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
                .then(response => response.json())
                .then(data => {

                    if(new Date(this.state.ideaCreatedDate) > new Date(this.state.biFormDate)){
                        this.setState({sortedBiImpactMasterList:data ? data.BIMasterDataVerseNew:[],showHistoricalBI:false},()=>{})
                    }else{
                        this.setState({sortedBiImpactMasterList:data ? data.BIMasterDataVerse:[],showHistoricalBI:true},()=>{})
                    }
                    this.setState({
                        businessImpactMasterList: this.state.sortedBiImpactMasterList
                    }, () => {
                        console.log(this.state.businessImpactMasterList)
                        resolve();
                    })
                })
                .catch(error => {
                    Toast.fail('Error occured while getting metadata idea. Please try again!', 3000, () => {
                        console.log(JSON.stringify(error));
                        this.setState({
                            businessImpactMasterList: []
                        }, () => {
                            resolve();
                        })
                    });

                });
        });
    }
    
    saveEvent = () => {
        let redirectURL = "/review-page";
        if( this.state.approvalPage) {
            redirectURL = redirectURL + "?page="+this.state.approvalPage;
            this.props.history.push(redirectURL);
        }
        else{
            this.props.history.push(redirectURL);
        }
    }
    
    renderStatusOptions = () => {
        let options = [];
        this.state.finalApproveOptionsValue.map((item) => {
            options.push({
                value: item.WorkflowStatusId,
                label: item.WorkflowStatusName
            })
            return options;
        });
        return options;
    }

    handleApprovalStatus = (e) => {
        this.setState({
            approvalSelectedOption: {
                value: e.value,
                label: e.label
            }
        },()=>{});
    }
    
    submitIdea = () => {
        if (this.validate()) {
            if (window.confirm("Are you sure you want to submit the lean project?")) {
                Toast.loading("lean project updating...", () => { });
                let param = {};
                if(this.state.approvalSelectedOption.label==="Completed"){
                    param = {
                        IdeaId: this.props.location.state,
                        ApprovalStatus: this.state.approvalSelectedOption.label,
                        FinalComments: this.state.finalComment,
                        IsMBBApproval: "LDT",
                        UpdaterOHR: this.sessionData.profile.oHRId,
                        UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                        UpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                        comment:this.state.finalComment,
                        Role:"LDT",
                        CompletedDate: new Date().toLocaleString()
                    }
                }else{
                    param = {
                        IdeaId: this.props.location.state,
                        ApprovalStatus: this.state.approvalSelectedOption.label,
                        FinalComments: this.state.finalComment,
                        IsMBBApproval: "LDT",
                        UpdaterOHR: this.sessionData.profile.oHRId,
                        UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                        UpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                        comment:this.state.finalComment,
                        Role:"LDT",
                    }
                }
                console.log(param)
                let finalJsonData = Checksum(param);
                //console.log("final Json Data",finalJsonData );
                const requestOptions = {
                    method: 'PUT',           
                    headers: { 
                        'Content-Type': 'application/json',
                        "Authorization":  "Bearer " + this.accessToken,
                    },
                    body: JSON.stringify(param)
                };
                fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        Toast.hide();
                        Toast.success('Updated successfully.', 3000, () => {
                            console.log(JSON.stringify(data));
                            let redirectURL = "/review-page";
                            if (this.state.approvalPage) {
                                redirectURL = redirectURL + "?page=" + this.state.approvalPage;
                            }
                            window.location = redirectURL;
                        });

                    });
            }
        }else {
            if(!this.isExceedlimit){
                Toast.fail('Some of the required fields for Idea are not set. Please set and resubmit.', 3000, () => { });
            }
        }
        return false;
    }
    
    validate = () => {
        let isValid = true;
        this.isExceedlimit=false;
        let styleStateObj = this.state.selectStyle;

        if (this.state.approvalSelectedOption === null) {
            isValid = false;
            styleStateObj.approvalSelectedOption = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.approvalSelectedOption = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        
        if (!this.state.finalComment || this.state.finalComment === "") {
            isValid = false;
            styleStateObj.comment = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            if (this.state.finalComment.length > 500){
                Toast.fail("Comment cannot be more than 500 characters, please change and resubmit.", 3000);
                isValid = false;
                this.isExceedlimit=true;
                styleStateObj.comment = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else{
                styleStateObj.comment = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                this.setState({
                    selectStyle: styleStateObj
                })
            }
        }
        return isValid;
    }

    cancelEvent = () => {
        // this.props.history.push("/review-page?page=approval");
        let redirectURL = "/review-page";
        if (this.state.approvalPage) {
            // redirectURL = redirectURL + "?page=" + this.state.approvalPage;
            // this.props.history.push(redirectURL);
            this.props.history.push({
                pathname:redirectURL,
                search: '?page='+ this.state.approvalPage,
                propsList:this.state.propsData
            })
        }
        else {
            // this.props.history.push(redirectURL);
            this.props.history.push({
                pathname: redirectURL,
                propsList:this.state.propsData
            })
        }
    }

    handleFinalCommentChange = (event) => {
        this.setState({
            finalComment: event.target.value
        });
    }
    handleTranscriptLDTComment=(e)=>{
        this.setState({
            finalComment: e
        });
    }

    fetchPPTContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let projectFileType = this.state.projectFileType;
            // fetch(`${GET_IDEA_URL}IdeaId=944&${PPT_CONTENT}=true`, requestOptions)
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    const projectDeckMetadata = data.PPTContent ? data.PPTContent : "";
                    const projectContent = "data:" + projectFileType + ";base64," + projectDeckMetadata;
                    this.setState({
                        projectDeckContent: projectContent,
                    }, () => {
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching Project Deck content. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }

    fetchSignoffContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let signoffFileType = this.state.signOffFileType;
            // fetch(`${GET_IDEA_URL}IdeaId=944&${SIGNOFF_CONTENT}=true`, requestOptions)
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${SIGNOFF_CONTENT}=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    const signoffMetadata = data.SignoffContent ? data.SignoffContent : "";
                    const signoffContent = "data:" + signoffFileType + ";base64," + signoffMetadata;
                    this.setState({
                        signOffContent: signoffContent,
                    }, () => {
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching Project Deck content. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }

    showPPTContent = () => {
        Toast.loading("Fetching Project PPT Deck content...", () => { });
        this.fetchPPTContent()
            .then(() => {
                var fileData = this.state.projectDeckContent;
                var fileName = this.state.projectDeckFilename;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    showSignOffContent = () => {
        Toast.loading("Fetching Customer Sign-off content...", () => { });
        this.fetchSignoffContent()
            .then(() => {
                var fileData = this.state.signOffContent;
                var fileName = this.state.signOffFilename;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    // function to fetch gol file data
    fetchGolOffContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let golFileType = this.state.ideaItem.GOLFileType;
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&golcontent=true`, requestOptions)
            .then(response => response.json())
            .then(data => {
                
                const golMetadata = data.golContent ? data.golContent : "";
                const golContent = "data:" + golFileType + ";base64," + golMetadata;
                this.setState({
                    golContent: golContent,
                }, () => {
                    resolve();
                    Toast.hide()
                });

            })
            .catch(error => {
                Toast.fail("Error while fetching GOL Approval content. Please try again!", 3000, () => { });
                resolve();
            });
        });
    }
    showGolOffContent = () => {
        Toast.loading("Fetching GOL Approval content...", () => { });
        this.fetchGolOffContent()
            .then(() => {
                var fileData = this.state.golContent;
                var fileName = this.state.ideaItem.GOLFileName;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }


    // function to fetch fpna file data
    fetchFpnaContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let fpnaFileType = this.state.ideaItem.FPnAFileType;
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&fpnacontent=true`, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                const golMetadata = data.fpnaContent ? data.fpnaContent : "";
                const golContent = "data:" + fpnaFileType + ";base64," + golMetadata;
                this.setState({
                    fpnaContent: golContent,
                }, () => {
                    resolve();
                    Toast.hide()
                });

            })
            .catch(error => {
                Toast.fail("Error while fetching FPNA Approval content. Please try again!", 3000, () => { });
                resolve();
            });
        });
    }
    showFpnaContent = () => {
        Toast.loading("Fetching FPNA Approval content...", () => { });
        this.fetchFpnaContent()
            .then(() => {
                var fileData = this.state.fpnaContent;
                var fileName = this.state.ideaItem.FPnAFileName;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    formatDateStringWithTime(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        });
    }
    toggleTranscriptFunction=(id)=>{
        this.setState({speechModal: !this.state.speechModal,modalIndex:id})
        // console.log(this.state.finalComment)
         if(id===9){
            // LDT approval comments
            this.setState({previousTextForSpeech:this.state.finalComment},()=>console.log(this.state.previousTextForSpeech))
        }else{
            this.setState({previousTextForSpeech:""},()=>console.log(this.state.previousTextForSpeech))
        }
    }

    closeModalFunction=()=>{
        this.setState({speechModal:false})
    }
    render() {
        let IDEA_APPROVED = (this.state.ideaItem.WorkflowStatus === "Approve");
        let IDEA_REJECTED = (this.state.ideaItem.WorkflowStatus === "Reject");
        let IDEA_SEND_BACK = (this.state.ideaItem.WorkflowStatus === "Send Back");
        // let redirectURL = "/review-page";
        // if( this.state.approvalPage) {
        //     redirectURL = redirectURL + "?page=approval";
        // }

        return (
            <section className="u-clearfix u-section-1" id="sec-0f7f">
                <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                    <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                        <div className="u-container-layout u-container-layout-1">
                            <h4 style={{ textAlign:'center', color:'#00AECF', fontFamily:'Arial', fontSize:22 }}>LDT Review idea : {this.state.ideaItem.IdeaIDAlpha ? this.state.ideaItem.IdeaIDAlpha : this.state.ideaItem.IdeaId} </h4>
                            <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                                <div className="u-container-layout u-container-layout-2" style={{textAlign:'center'}}>
                                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-3">
                                        <div className="u-container-layout u-container-layout-3">
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: 15 }}>Owner details </p>
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom:-5 }}>Owner OHR</label> </div>
                                                <div className="col-md-6"><input className ="form-input" disabled type="text" value={this.state.ideaItem.OwnerOHR} /></div>
                                            </div>
                                            <div style={{ marginBottom: 15 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner Name</label> </div>
                                                <div className="col-md-6"><input className ="form-input" disabled type="text" value={this.state.ideaItem.OwnerName} /></div>
                                            </div>
                                            <div style={{ marginBottom: -10 }} />
                                            <p className="u-align-left u-text u-text-7 u-text-custom-color-3" style={{ fontSize: 11, marginBottom: 10 }}>(If Idea was raised for someone else, their OHR ID is below)</p>
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Request For OHR</label> </div>
                                                <div className="col-md-6"><input className ="form-input" disabled type="text" defaultValue={this.state.ideaItem.CreatorOHR} onChange={this.handleCreatorOHRChange} /></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Name</label> </div>
                                                <div className="col-md-6"><input className ="form-input" disabled type="text" defaultValue={this.state.ideaItem.CreatorName} onChange={this.handleCreatorNameChange} /></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Project ID</label> </div>
                                                <div className="col-md-6"><input className ="form-input" disabled type="text" defaultValue={this.state.ideaItem.LeanID}/></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                        </div>
                                    </div>

                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 235 }}>&nbsp;</p>

                                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-4" style={{ marginTop: "-305px" }}>
                                        <div className="u-container-layout u-container-layout-4">
                                            <div className="u-container-style u-group u-shape-rectangle u-group-5">
                                                <div className="u-container-layout u-container-layout-5">
                                                    <p className="u-align-left u-text u-text-8" style={{ marginBottom: 15 }}>Account Details</p>
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Account&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            <input className ="form-input" disabled list="accounts" name="accounts" placeholder="Choose Account" value={this.state.ideaItem.AccountManual} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Holding&nbsp;Account</label></div>
                                                        <div className="col-md-7">
                                                            <input className ="form-input" disabled type="text" value={this.state.ideaItem.HoldingAccount} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Vertical</label></div>
                                                        <div className="col-md-7">
                                                            <input className ="form-input" disabled name="vertical" placeholder="Choose Vertical" value={this.state.ideaItem.VerticalManual} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Industry&nbsp;Vertical</label></div>
                                                        <div className="col-md-7">
                                                            <input className ="form-input" disabled name="subverticallist" placeholder="Choose Industry&nbsp;Vertical" value={this.state.ideaItem.SubVerticalName} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Campaign&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            <input className ="form-input" disabled name="ideationtype" placeholder="Choose Idea Type" value={this.state.ideaItem.CampaignName} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />                                                    
                                                    {
                                                        this.state.dataBridgeCampaign && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Data Bridge Project Theme</label></div>
                                                                    <div className="col-md-7">
                                                                        <input className ="form-input" disabled list="databridgetype" name="databridgetype" placeholder="Choose Data Bridge Project Type" value={this.state.ideaItem.DataBridgeTheme} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 26 }} />
                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                            this.state.xPollinationCampaign && (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Campaign</label></div>
                                                                        <div className="col-md-7">
                                                                            <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Sub Campaign" value={this.state.ideaItem.SubCampaignName} />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: 26 }} />
                                                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                                </>
                                                            )
                                                        }                                                        
                                                        {
                                                        !this.state.dataBridgeCampaign && !this.state.xPollinationCampaign && (
                                                            <>
                                                                <div className="row" style={{ marginBottom: 65 }}>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    <div style={{ marginBottom: 18 }}>&nbsp;</div>
                                                </div>
                                            </div>

                                            <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -75 }}>&nbsp;</p>

                                            <div className="u-container-style u-group u-shape-rectangle u-group-6">
                                                <div className="u-container-layout u-container-layout-6" style={{marginTop:-10}}>
                                                    <p className="u-align-left u-text u-text-8" style={{ marginTop: -190 }}>&nbsp;</p>
                                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 175 }}>&nbsp;</p>
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Country</label></div>
                                                        <div className="col-md-7">
                                                            <input className ="form-input" disabled name="regions" placeholder="Choose Country" value={this.state.ideaItem.Country} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />                                                    
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Region</label></div>
                                                        <div className="col-md-7">
                                                            <input className ="form-input" disabled name="regions" placeholder="Choose Region" value={this.state.ideaItem.RegionManual} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub-Region</label></div>
                                                        <div className="col-md-7">
                                                            <input className ="form-input" disabled name="regions" placeholder="Choose Sub-Region" value={this.state.ideaItem.SubRegion} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />                                                    
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Blackbelt&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            <input className ="form-input" disabled name="blackbelt" placeholder="Choose Blackbelt" title={"OHR ID: " +  this.state.ideaItem.BlackBeltUserId} value={this.state.ideaItem.BlackBeltUserName} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>MBB</label></div>
                                                        <div className="col-md-7">
                                                            <input className ="form-input" disabled name="blackbelt" placeholder="Choose MBB" title={"OHR ID: " +  this.state.ideaItem.MBBOHR} value={this.state.ideaItem.MBBName} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    {/* <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>LDA</label></div>
                                                        <div className="col-md-7">
                                                            <input className ="form-input" disabled name="blackbelt" placeholder="Choose LDA" title={"OHR ID: " +  this.state.ideaItem.LDAOHR} value={this.state.ideaItem.LDA} />
                                                        </div>
                                                    </div> */}
                                                    <div style={{ marginBottom: 15 }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />

                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -30 }}>&nbsp;</p>

                                    <div id="idea1" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ marginTop: 15 }}>
                                            <div className="u-container-layout u-container-layout-7">
                                                <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                    <div className="u-container-layout u-container-layout-8">
                                                        <p id="idea1title" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Idea details</p>
                                                        <div className="row">
                                                        <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Service Line</label> </div>
                                                            <div className="col-md-8">
                                                                <input className ="form-input" disabled name="serviceline" placeholder="Choose Service Line" value={this.state.ideaItem.ServiceLineName} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Service Line</label> </div>
                                                            <div className="col-md-8">
                                                                <input className ="form-input" disabled name="serviceline" placeholder="Choose Sub Service Line" value={this.state.ideaItem.SubServiceLine} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                            <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                            <span className="u-text-black form-attribute"> Problem</span>&nbsp;
                                                        </p>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <textarea className ="form-input" disabled rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Problem} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                    <div className="u-container-layout u-container-layout-9" style={{ marginTop: 4 }}>
                                                        <div className="row">
                                                        <div className="col-md-3"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Idea Name</label> </div>
                                                            <div className="col-md-9" style={{ alignItems: 'flex-start' }}><input className ="form-input" disabled type="text" value={this.state.ideaItem.IdeaName} placeholder="Eg: Cash flow improvement through process restructuring" /></div>
                                                        </div>
                                                        <div style={{ marginBottom: 66 }} />
                                                        <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                            <span className="u-text-black form-attribute"> Solution</span>&nbsp;
                                                        </p>
                                                        <div className="row" style={{marginBottom:15}}>
                                                            <div className="col-md-12">
                                                                <textarea className ="form-input" disabled rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Solution} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{marginTop:15}}>
                                                    <div className="col-md-5"><span className="form-attribute">Are you trying to replicate an existing Idea?</span>

                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="switch">
                                                            <input disabled type="checkbox" checked={this.state.ideaItem.NewOrReplicatedIdea === null ? false : this.state.ideaItem.NewOrReplicatedIdea} />
                                                            <span className="slider round" />
                                                        </label>
                                                    </div>
                                                </div>

                                                {
                                                    this.state.ideaItem.NewOrReplicatedIdea && (
                                                        <>
                                                            <div className="col-md-8" style={{ marginBottom: 15, width: "100%" }} />
                                                            <div className="row">
                                                                <div className="col-md-5"><span className="form-attribute">Provide the ID of the executed Idea which you want to replicate?</span>
                                                                </div>
                                                                <div className="col-md-2"><input className ="form-input" disabled type="text" value={this.state.ideaItem.ReplicationProjectId} /></div>
                                                            </div>
                                                        </>
                                                    )
                                                }

                                                <div className="col-md-8" style={{ marginBottom: 15 }} />
                                            </div>
                                        </div>

                                        {
                                        (this.state.ideaItem.WorkflowStatus && this.state.ideaItem.WorkflowStatus !== "Pending") && (
                                            <>
                                            <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />
                                            <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                <div className="u-container-layout u-container-layout-7">
                                                    <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Idea Approval details</p>
                                                    <div className="row">
                                                        <div className="col-md-2"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Approval Status</label></div>
                                                        <div className="col-md-2">
                                                                <input className="form-input" disabled name="Status" value={IDEA_APPROVED ? APPROVED : (IDEA_REJECTED ? REJECTED : (IDEA_SEND_BACK ? SENT_BACK : PENDING))}  />
                                                        </div>
                                                        {/* <div className="col-md-3"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Expected timeline for execution</label></div>
                                                        <div className="col-md-2">
                                                            <input disabled name="ExpectedDate" value={this.state.ideaItem.ExpectedFinishDate ? this.formatDateString(new Date(this.state.ideaItem.ExpectedFinishDate)): ""} />
                                                        </div> */}
                                                    </div>
                                                    <div className="row" style={{marginTop:15}}>
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Comments</label></div>
                                                        <div className="col-md-2" style={{marginLeft:20}}>
                                                            <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e)=>this.setState({commentLog:!this.state.commentLog})}>Comment Log</button>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{marginTop:5}}>
                                                        <div className="col-md-12">
                                                            <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.Comment} />
                                                        </div>
                                                    </div>
                                                    <div className="text-left mt-4">
                                                        {
                                                            this.state.commentLog && this.state.reviewComments
                                                                ?
                                                                <>
                                                                    <div className="custom_modal">
                                                                        <div className="modal_body">
                                                                            <div className="row">
                                                                                <div className="col-md-8">
                                                                                    <h3 className="modal_title">Idea Approval Comment Log</h3>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <button type="button" className="close text-right" onClick={(e) => this.setState({ commentLog: false })}>&times;</button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                </div>
                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                </div>
                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                </div>
                                                                                <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                </div>
                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                </div>
                                                                                <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                this.state.reviewComments.map((item, index) => {

                                                                                    return (
                                                                                        <div className="row" key={index}>
                                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                            </div>
                                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                            </div>
                                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                            </div>
                                                                                            <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{(item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin' ) ? 'Admin' : (item.Action === 'Delegate Action' ? 'BB' : (item.UpdaterOHR === this.state.ideaItem.BlackBeltUserId ? "BB" : (item.UpdaterOHR === this.state.ideaItem.MBBOHR ? "MBB" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Owner" : item.UpdaterRole))))}</label>
                                                                                            </div>
                                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? (item.WorkflowStatus !== 'Pending' ? item.WorkflowStatus : 'Idea Updated') : (item.Action === 'Delegate Action' ? 'Delegated' : (item.WorkflowStatus==='Pending' ? 'Reject Cancellation' : item.WorkflowStatus)))}</label>
                                                                                            </div>
                                                                                            <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment !== '' ? item.Comment:'-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    {/* <div className="col-md-9" style={{ marginBottom: 5, width: '100%' }} />
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                                <span className="u-text-black form-attribute">Comments</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.Comment} />
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15, marginTop:15 }} />

                                            {
                                                    (this.state.ideaItem.WorkflowStatus && (this.state.ideaItem.WorkflowStatus === "Approve" || this.state.ideaItem.WorkflowStatus === "Approved")) && (
                                                <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                    <div className="u-container-layout u-container-layout-7">
                                                        <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                            <div className="u-container-layout u-container-layout-8">
                                                                <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Additional details</p>
                                                                <div className="row">
                                                                    <div className="col-md-6"><span className="form-attribute">LDT Lead Name</span></div>
                                                                    <div className="col-md-6">
                                                                        <input className="form-input" disabled list="ldtName" name="ldtName" placeholder="Choose LDT Lead" title={"OHR ID: " +  this.state.ideaItem.LDTId} value={this.state.ideaItem.LDTLeadName} />
                                                                    </div>
                                                                </div>
                                                                {/* <div style={{ marginBottom: 15 }} />
                                                                        <div className="row">
                                                                            <div className="col-md-6"><span className="form-attribute">Potential Impact (# of FTE)</span></div>
                                                                            <div className="col-md-6">
                                                                                <input className="form-input" disabled type="number" value={this.state.ideaItem.PotentialImpactFTE} placeholder={0} max={10} />
                                                                            </div>
                                                                        </div>
                                                                <div style={{ marginBottom: 15 }} />
                                                                        <div className="row">
                                                                            <div className="col-md-6"><span className="form-attribute">Potential Impact ($)</span></div>
                                                                            <div className="col-md-6">
                                                                                <input className="form-input" disabled type="number" value={this.state.ideaItem.PotentialImpactDollar} placeholder={0.0} />
                                                                            </div>
                                                                        </div>
                                                                <div style={{ marginBottom: 5 }} />
                                                                <div className="u-align-left u-text u-text-custom-color-3" style={{ fontSize: 11, fontStyle: 'italic' }}>(If Potential Impact $ is greater than 250K OR FTE number is greater than 10, please review thoroughly)
                                                                </div> */}
                                                                <div style={{ marginBottom: 5 }} />
                                                                <div style={{ marginTop: 10, marginBottom: 14 }} />
                                                                {
                                                                    this.state.ideaItem.NewOrReplicatedIdea && (
                                                                        <div className="row">
                                                                            <div className="col-md-10">
                                                                                <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}><span style={{ color: 'red' }}>*</span> Have you verified the replication id and confirm this to be replication project?</label>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label className="switch">
                                                                                    <input disabled type="checkbox" checked={this.state.ideaItem.IsReplicationVerified} />
                                                                                    <span className="slider round" />
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }                                                                
                                                            </div>
                                                        </div>

                                                        <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 45 }}>&nbsp;</p>

                                                        <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                            <div className="u-container-layout u-container-layout-9" style={{ marginTop: '180px' }}>
                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: '-210px' }}>&nbsp;</p>
                                                                <div className="row">
                                                                    <div className="col-md-5"><span className="form-attribute">Certification Level</span> </div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled name="certlevel" value={this.state.ideaItem.CertificationLevelName} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 15 }} />
                                                                <div className="row">
                                                                    <div className="col-md-5"><span className="form-attribute">Transformation Lever</span> </div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled name="translever" value={this.state.ideaItem.TransformationLeverName} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 15 }} />
                                                                {
                                                                    this.state.ideaItem.TransformationLeverName === "Digital" && (
                                                                        <>
                                                                            <div className="row">
                                                                                        <div className="col-md-5"><span className="form-attribute">Digital Project Type</span> </div>
                                                                                <div className="col-md-7">
                                                                                            <input className="form-input" disabled name="translever" value={this.state.ideaItem.DigitalProjectType} />
                                                                                </div>
                                                                            </div>                                                                        
                                                                            <div style={{ marginBottom: 15 }} />
                                                                        </>
                                                                    )
                                                                }
                                                                <div className="row">
                                                                    <div className="col-md-5"><span className="form-attribute">Work Type</span> </div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled name="worktype" value={(!this.state.ideaItem.CertificationWorkTypeName || this.state.ideaItem.CertificationWorkTypeName === "") ? "None" : this.state.ideaItem.CertificationWorkTypeName} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 15 }} />
                                                                
                                                                <div className="row">
                                                                    <div className="col-md-5"><span className="form-attribute">Tools/Methodology Used</span></div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled name="methodology" value={this.state.ideaItem.MethodologyName} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 5 }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                            }
                                            </>
                                         )
                                    }
                                    {
                                        (this.state.ideaItem.ApprovalStatus
                                            && this.state.ideaItem.ApprovalStatus !== "NA"
                                            && this.state.ideaItem.ApprovalStatus !== "Pending Documents"
                                            && (this.state.ideaItem.ApprovalStatus === "Documents Uploaded" || this.state.ideaItem.ApprovalStatus === COMPLETED_STATUS || this.state.ideaItem.ApprovalStatus === AWAITING_LDT_APPROVAL)
                                        )
                                        && (
                                            <>
                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -20 }}>&nbsp;</p>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15, marginTop: 5 }} />
                                                <div id="Upload" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                    <div className="u-container-layout u-container-layout-7">
                                                        <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                            <div className="u-container-layout u-container-layout-8">

                                                                <p id="Upload" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Uploaded documents</p>
                                                                <div className="row">
                                                                    <div className="col-md-8"><label style={{ verticalAlign: 'bottom' }}>Project Presentation Deck</label> </div>
                                                                    <div className="col-md-4">
                                                                        {/* <a download={this.state.projectDeckFilename} href={this.state.projectDeckContent} rel="noreferrer" target="_blank" >{this.state.projectDeckFilename}</a> */}
                                                                        <button style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0 }} onClick={this.showPPTContent}>{this.state.projectDeckFilename}</button>
                                                                    </div>
                                                                </div>

                                                                <div style={{ marginTop: 5, marginBottom: 10 }} />
                                                                <div className="row">
                                                                    <div className="col-md-8"><label style={{ verticalAlign: 'bottom' }}>Customer/Ops Lead Sign-off</label> </div>

                                                                    <div className="col-md-4">
                                                                        {/* <a download={this.state.signOffFilename} href={this.state.signOffContent} rel="noreferrer" target="_blank">{this.state.signOffFilename}</a> */}
                                                                        <button style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0 }} onClick={this.showSignOffContent}>{this.state.signOffFilename}</button>
                                                                    </div>
                                                                </div>

                                                                <div style={{ marginTop: 5, marginBottom: 5 }} />
                                                                {/* {
                                                                    this.state.ideaItem.TransformationLeverName === "Digital" && (
                                                                        <>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Source ID</label>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <input className="form-input" disabled type="text" value={this.state.sourceId}
                                                                                        onChange={this.handleSourceIdChange} placeholder="Enter Source ID" style={{ border: '1px solid lightgrey' }} />
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ marginTop: 5, marginBottom: -25 }} />
                                                                        </>
                                                                    )
                                                                } */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    {/* Final Approval Section  */}
                                    {
                                        (this.state.ideaItem.ApprovalStatus
                                            && this.state.ideaItem.ApprovalStatus !== "NA"
                                            && this.state.ideaItem.ApprovalStatus !== "Pending Documents"
                                            && this.state.ideaItem.ApprovalStatus !=="Documents Uploaded"
                                        )
                                            && (
                                            <>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 10 }} />

                                                <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                    <div className="u-container-layout u-container-layout-7">
                                                        <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Lean Approval details</p>
                                                        <div className="row">
                                                            <div className="col-md-2"><span className="form-attribute">Approval Status</span> </div>
                                                            <div className="col-md-2">
                                                                <input className="form-input" disabled name="finalapprovalstatus" value={this.state.ideaItem.ApprovalStatus} />
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{marginTop:15}}>
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Approval Comments</label></div>
                                                            <div className="col-md-2" style={{marginLeft:20}}>
                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e)=>this.setState({finalCommentLog:!this.state.finalCommentLog})}>Comment Log</button>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{marginTop:5}}>
                                                            <div className="col-md-12">
                                                                <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.FinalComments} />
                                                            </div>
                                                        </div>
                                                        <div className="text-left mt-4">
                                                            {
                                                                this.state.finalCommentLog && this.state.finalReviewComments
                                                                    ?
                                                                    <>
                                                                        <div className="custom_modal">
                                                                            <div className="modal_body">
                                                                                <div className="row">
                                                                                    <div className="col-md-8">
                                                                                        <h3 className="modal_title">Lean Approval Comment Log</h3>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ finalCommentLog: false })}>&times;</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                    </div>
                                                                                    <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                    </div>
                                                                                    <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    this.state.finalReviewComments.map((item, index) => {

                                                                                        return (
                                                                                            <div className="row" key={index}>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                </div>
                                                                                                <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{(item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === 'Idea was reviewed by MBB' ? "MBB" : (item.Action === 'Idea was reviewed by LDT' ? "LDT" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Owner" : "BB")))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? item.ApprovalStatus : (item.WorkflowStatus === 'Completed' || item.WorkflowStatus === 'Approve' || item.WorkflowStatus === 'Approved') ? 'Approved' : ((item.WorkflowStatus === 'Rejected' || item.WorkflowStatus === 'Reject') ? "Rejected" : (item.WorkflowStatus === REQUEST_CANCELLATION ? REQUEST_CANCELLATION : (item.WorkflowStatus === 'Pending Documents' ? 'Reject Cancellation' : item.WorkflowStatus === 'Cancelled' ? 'Cancelled' : "Sent Back"))))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment !== null ? item.Comment :'-'}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        {/* <div className="col-md-8" style={{ marginBottom: 5, width: "100%" }} />
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                                    <span className="u-text-black form-attribute"> Approval Comments</span>&nbsp;
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.FinalComments} />
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                {
                                                    (this.state.ideaItem.ApprovalStatus && (this.state.ideaItem.ApprovalStatus === COMPLETED_STATUS || this.state.ideaItem.ApprovalStatus === AWAITING_LDT_APPROVAL || this.state.ideaItem.ApprovalStatus === AWAITING_QAULITY_CHECK_REVIEW) ) && (
                                                        <>
                                                            <div style={{ borderBottom: '1px solid lightgrey', marginTop:10, marginBottom: 5 }} />
                                                            <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                                <div className="u-container-layout u-container-layout-7">
                                                                    <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                                        <div className="u-container-layout u-container-layout-8">
                                                                            <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Final additional details</p>
                                                                            <div className="row">
                                                                                <div className="col-md-6"><span className="form-attribute">Final Impact (# of FTE)</span></div>
                                                                                <div className="col-md-6">
                                                                                    <input className="form-input" disabled name="finalimpactFTE" value={this.state.ideaItem.FinalImpactFTE} />
                                                                                </div>
                                                                            </div>
                                                                                <div style={{ marginBottom: 10 }} />
                                                                            <div className="row">
                                                                                <div className="col-md-6"><span className="form-attribute">Final Impact ($)</span> </div>
                                                                                <div className="col-md-6">
                                                                                    <input className="form-input" disabled name="finalimpactdollar" value={this.state.ideaItem.FinalImpactDollar} />
                                                                                </div>
                                                                            </div>
                                                                                <div style={{ marginBottom: 5 }} />
                                                                                <div className="u-align-left u-text u-text-custom-color-3" style={{ fontSize: 11, fontStyle: 'italic' }}>(If Final Impact $ is greater than 250K OR FTE number is greater than 10, please review thoroughly)
                                                                                </div>
                                                                                <div style={{ marginTop: 7, marginBottom: 10 }} />
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <span className="form-attribute">Is it a Zero Impact Project</span>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <label className="switch">
                                                                                        <input disabled type="checkbox" id="isZeroImpactProject" checked={this.state.ideaItem.IsZeroImpactProject === null ? false : this.state.ideaItem.IsZeroImpactProject} />
                                                                                        <span className="slider round" />
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ marginTop: 5, marginBottom: 22 }} />
                                                                        </div>
                                                                    </div>

                                                                        <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 22 }}>&nbsp;</p>

                                                                        <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                                            <div className="u-container-layout u-container-layout-9" style={{ marginTop: '80px' }}>
                                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: '-120px' }}>&nbsp;</p>
                                                                                {/* <div className="row">
                                                                                    <div className="col-md-6"><span style={{ fontWeight: 'bold' }}>Type of Impact</span></div>
                                                                                    <div className="col-md-6">
                                                                                        <input disabled name="typeofimpact" value={this.state.ideaItem.TypeOfImpactName} />
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />
                                                                                <div className="row">
                                                                                    <div className="col-md-6"><span style={{ fontWeight: 'bold' }}>Business Impact Category</span></div>
                                                                                    <div className="col-md-6">
                                                                                        <input disabled name="finalbusinessimpact" value={this.state.ideaItem.BusinessImpactName} />
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />
                                                                                <div className="row">
                                                                                    <div className="col-md-6"><span style={{ fontWeight: 'bold' }}>Business Impact Sub Category</span></div>
                                                                                    <div className="col-md-6">
                                                                                        <input disabled name="finalbusinesssubname" value={this.state.ideaItem.BusinessImpactSubName} />
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} /> */}
                                                                                {/* <div className="row">
                                                                                    <div className="col-md-5"><span className="form-attribute">Certification Level</span> </div>
                                                                                    <div className="col-md-7">
                                                                                        <input className="form-input" disabled name="certlevel" value={this.state.ideaItem.CertificationLevelName} />
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ marginBottom: 15 }} />
                                                                                <div className="row">
                                                                                    <div className="col-md-6"><span className="form-attribute">Transformation Lever</span></div>
                                                                                    <div className="col-md-6">
                                                                                        <input className="form-input" disabled name="finaltransformlever" value={this.state.ideaItem.FinalTransformationLeverName} />
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ marginBottom: 15 }} />
                                                                                <div className="row">
                                                                                    <div className="col-md-6"><span className="form-attribute">Other Tools</span> </div>
                                                                                    <div className="col-md-6">
                                                                                        <input className="form-input" disabled name="finaltools" value={this.state.ideaItem.FinalOtherTools} />
                                                                                    </div>
                                                                                </div> */}
                                                                                
                                                                            <div style={{ marginBottom: 15 }} />
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <span className="form-attribute">Best in Class</span>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <label className="switch">
                                                                                        <input disabled type="checkbox" checked={this.state.ideaItem.IsBestInClass === null ? false : this.state.ideaItem.IsBestInClass} />
                                                                                        <span className="slider round" />
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                this.state.ideaItem.ReplicabilityIndex !== null && (
                                                                                <div className="row" style={{marginTop:15}}>
                                                                                    <div className="col-md-6"><span className="form-attribute">Replicability Index</span> </div>
                                                                                    <div className="col-md-6"> 
                                                                                        <input className ="form-input" disabled value={this.state.ideaItem.ReplicabilityIndex} />
                                                                                    </div>
                                                                                </div>
                                                                                )
                                                                            }
                                                                            <div style={{ marginTop: 5, marginBottom: 10 }} />
                                                                                {
                                                                                    // this.state.showSourceId && (
                                                                                    //     <>
                                                                                    //         <div className="row">
                                                                                    //             <div className="col-md-6">
                                                                                    //                 <span className="form-attribute">Source ID</span>
                                                                                    //             </div>
                                                                                    //             <div className="col-md-6">
                                                                                    //                 <input className="form-input" disabled name="sourceid" value={this.state.ideaItem.SourceId} />
                                                                                    //             </div>
                                                                                    //         </div>
                                                                                    //     </>
                                                                                    // )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    <div style={{ marginTop: 5, marginBottom: 30 }} />
                                                                </div>
                                                            </div>

                                                            <div style={{ borderBottom: '1px solid lightgrey', marginTop: 80, marginBottom: 15 }} />
                                                            {!this.state.ideaItem.IsZeroImpactProject && this.state.summaryArray.length > 0 && (
                                                                <>
                                                                <p id="BI Summary" className="u-align-left u-text u-text-2" style={{ marginBottom: 15, marginTop: 15 }}>Value Delivery Summary</p>
                                                                <div className="row" style={{ marginLeft: 10, marginRight: 10 }}>
                                                                    <div className="col-md-2 col-xs-12" style={{ border: "1px solid lightgrey", backgroundColor: 'gainsboro' }}><label >Impact Type</label></div>
                                                                    <div className="col-md-3 col-xs-6" style={{ border: "1px solid lightgrey", backgroundColor: 'gainsboro' }}><label>Category</label></div>
                                                                    <div className="col-md-2 col-xs-6" style={{ border: "1px solid lightgrey", backgroundColor: 'gainsboro', textAlign: 'center' }}><label>FTE Impact (#)</label></div>
                                                                    <div className="col-md-2 col-xs-6" style={{ border: "1px solid lightgrey", backgroundColor: 'gainsboro', textAlign: 'center' }}><label>$ Impact</label></div>
                                                                </div>
                                                            </>   
                                                            )}

                                                            {!this.state.ideaItem.IsZeroImpactProject && this.state.summaryArray.length > 0 && this.state.summaryArray.map((currItem, index) => {
                                                                return (
                                                                    <>
                                                                        <div className="row" style={{ marginLeft: 10, marginRight: 10 }}>
                                                                            <div className="col-md-2 col-xs-12" style={{ border: "1px solid lightgrey" }}><label style={{ fontWeight: 100, verticalAlign: 'bottom' }}>{currItem.impacttype}</label></div>
                                                                            <div className="col-md-3 col-xs-6" style={{ border: "1px solid lightgrey" }}><label style={{ fontWeight: 100, verticalAlign: 'bottom' }}>{currItem.monetizableType}</label></div>
                                                                            <div className="col-md-2 col-xs-6" style={{ border: "1px solid lightgrey", textAlign: 'center' }}>
                                                                                <label style={{ fontWeight: 100, verticalAlign: 'bottom' }}>{isNaN(currItem.fteValue) ? 0 : currItem.fteValue}</label>
                                                                            </div>
                                                                            <div className="col-md-2 col-xs-6" style={{ border: "1px solid lightgrey", textAlign: 'center' }}>
                                                                                <label style={{ fontWeight: 100, verticalAlign: 'bottom' }}>{isNaN(currItem.dollarValue) ? 0 : currItem.dollarValue}</label>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}

                                                            {
                                                                this.state.showHistoricalBI
                                                                ?
                                                                <>

                                                                    {
                                                                        !this.state.ideaItem.IsZeroImpactProject && (
                                                                            <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                                                <div className="u-container-layout u-container-layout-7" style={{ marginTop: 5 }}>
                                                                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -120 }}>&nbsp;</p>
                                                                                    <div style={{ borderBottom: '1px solid lightgrey',   marginBottom: 25 }} />
                                                                                    <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Value Delivery Form</p>
                                                                                <div className="row">
                                                                                        <div className="col-md-2 col-xs-12"><label>Impact Type</label></div>
                                                                                        <div className="col-md-3 col-xs-6"><label>Category</label></div>
                                                                                        <div className="col-md-3 col-xs-6"><label>Business Impact Sub-Category</label></div>
                                                                                        <div className="col-md-2 col-xs-6"><label>FTE Impact (#)</label></div>
                                                                                        <div className="col-md-2 col-xs-6"><label>$ Impact</label></div>
                                                                                    </div>
                                                                                    {
                                                                                        this.state.businessImpactMasterList && this.state.businessImpactMasterList.length > 0 && (
                                                                                            this.state.businessImpactMasterList.map((currItem, index) => {
                                                                                            var isFTEImpact = currItem.pg_isfteimpact === "Yes";
                                                                                            var isDollarImpact = currItem.pg_isimpact === "Yes";
                                                                                            var currFteValue = 0;
                                                                                            var currDollarValue = 0;
                                                                                            if (this.state.businessImpactValueArray && this.state.businessImpactValueArray.length > 0){
                                                                                                var valueIndex = this.state.businessImpactValueArray.findIndex((valueItem) => 
                                                                                                    currItem.pg_impacttype === valueItem.ImpactType &&
                                                                                                    currItem.pg_monetizabletype === valueItem.MonetizableType &&
                                                                                                    currItem.pg_bisubcategory === valueItem.BISubCategory);
                                                                                                if(valueIndex >= 0){
                                                                                                    currFteValue = this.state.businessImpactValueArray[valueIndex].FTEImpact ? this.state.businessImpactValueArray[valueIndex].FTEImpact : 0;
                                                                                                    currDollarValue = this.state.businessImpactValueArray[valueIndex].DollarImpact ? this.state.businessImpactValueArray[valueIndex].DollarImpact : 0;
                                                                                                }
                                                                                            }
                                                                                            return (
                                                                                                <>
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-2 col-xs-12"><label style={{ fontWeight: 100, verticalAlign: 'bottom', marginBottom: -5 }}>{currItem.pg_impacttype}</label></div>
                                                                                                        <div className="col-md-3 col-xs-6"><label style={{ fontWeight: 100, verticalAlign: 'bottom', marginBottom: -5 }}>{currItem.pg_monetizabletype}</label></div>
                                                                                                        <div className="col-md-3 col-xs-6"><label style={{ fontWeight: 100, verticalAlign: 'bottom', marginBottom: -5 }}>{currItem.pg_bisubcategory}</label></div>
                                                                                                        {isFTEImpact && (
                                                                                                            <>
                                                                                                                <div className="col-md-2 col-xs-6">
                                                                                                                    <input className="form-input" disabled type="number" min="0" placeholder={0} max={99} value={currFteValue} />
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                        }
                                                                                                        {!isFTEImpact && (
                                                                                                            <>
                                                                                                                <div className="col-md-2 col-xs-6">
                                                                                                                    <label>&nbsp;</label>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                        }
                                                                                                        {isDollarImpact && (
                                                                                                            <>
                                                                                                                <div className="col-md-2 col-xs-6">
                                                                                                                    <input className="form-input" disabled type="number" min="0" max="500000000" placeholder={0.0} value={currDollarValue} />
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                        }
                                                                                                        {!isDollarImpact && (
                                                                                                            <>
                                                                                                                <div className="col-md-2 col-xs-6">
                                                                                                                    <label>&nbsp;</label>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                        }
                                                                                                    </div>
                                                                                                    {/* <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 2 }} /> */}
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    } 
                                                                </>
                                                                :
                                                                null
                                                            }

                                                             



                                                            {/* .........latest bi changes start here... */}
                                                            {
                                                                    !this.state.showHistoricalBI
                                                                    ?
                                                                    <>
                                                                            {
                                                                                this.state.businessImpactMasterList && this.state.businessImpactMasterList.length > 0 && (
                                                                                    <div className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-radius-6 u-shape-round u-group-7">
                                                                                        <div className="u-container-layout u-container-layout-7 bi_summary_latest" style={{marginBottom:"50px"}}>
                                                                                            <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />                                                    
                                                                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: 15, marginTop: 15 }}>Value Delivery Form</p>
                                                                                            <div className="row" style={{ marginLeft:10, marginRight: 10, justifyContent:"center"}}>
                                                                                                <div className="col-md-2 col-xs-12 bi_summary_latest_header_div">
                                                                                                    <label>Impact Type</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 col-xs-12 bi_summary_latest_header_div ">
                                                                                                    <label>Category</label>
                                                                                                </div>
                                                                                                <div className="col-md-3 col-xs-12 bi_summary_latest_header_div ">
                                                                                                    <label>Sub Category</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 col-xs-12 bi_summary_latest_header_div">
                                                                                                    <label>FTE Impact (#)</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 col-xs-12 bi_summary_latest_header_div">
                                                                                                    <label>$ Impact</label>
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                this.state.duplicateBIValueArray.map((item,index)=>{
                                                                                                    console.log(item)
                                                                                                    return(
                                                                                                        <div className="row" style={{ marginLeft: 10, marginRight: 10,justifyContent:"center" }}>
                                                                                                            <div className="col-md-2 col-xs-12 bi_summary_latest_body_text_elements">
                                                                                                                <label style={{ fontWeight: 100, verticalAlign: 'bottom' }}>{item.ImpactType}</label>
                                                                                                            </div>
                                                                                                            <div className="col-md-2 col-xs-12 bi_summary_latest_body_text_elements">
                                                                                                                <label style={{ fontWeight: 100, verticalAlign: 'bottom' }}>{item.MonetizableType}</label>
                                                                                                            </div>
                                                                                                            <div className="col-md-3 col-xs-12 bi_summary_latest_body_text_elements">
                                                                                                                <label style={{ fontWeight: 100, verticalAlign: 'bottom' }}>{item.BISubCategory}</label>
                                                                                                            </div>
                                                                                                            <div className="col-md-2 col-xs-12 bi_summary_latest_body_text_elements">
                                                                                                                {
                                                                                                                    this.state.businessImpactMasterList.map((pg_item)=>{
                                                                                                                        if(item.ImpactType === pg_item.pg_impacttype && item.MonetizableType === pg_item.pg_monetizabletype  &&  item.BISubCategory===pg_item.pg_bisubcategory && pg_item.pg_isfteimpact==="Yes"){
                                                                                                                        return(
                                                                                                                            <>
                                                                                                                                <input style={{ border: '1px solid lightgrey' }}  className="form-input" disabled type="number" value={item.FTEImpact}  />    
                                                                                                                            </>
                                                                                                                        )
                                                                                                                        }
                                                                                                                    })
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="col-md-2 col-xs-12 bi_summary_latest_body_text_elements">
                                                                                                                <input className="form-input" type="number" disabled defaultValue={item.DollarImpact} style={{ border: '1px solid lightgrey' }}   />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                            
                                                                                            
                                                                                            
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                            {/* ....latest bi changes completes here.... */} 

                                                            {/* ....latest changes to GOL starts here.... */}

                                                                {
                                                                    this.state.showClientFields
                                                                    ?
                                                                    <>
                                                                        <div className="gol_fields_block">
                                                                            <p className="u-align-left u-text u-text-2" style={{margin: "10px 0px 20px"}}>Client Approval Details</p>
                                                                            <div className="row u-align-left">
                                                                                <div className="col-md-5">
                                                                                    <div className="row">
                                                                                        <div className="col-md-3 gol_field_div">
                                                                                            <span className="form-attribute">Client Name</span> 
                                                                                        </div>
                                                                                        <div className="col-md-9">
                                                                                            <input disabled value={this.state.ideaItem.CustName} className="form-input disabled_input" type="text" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                    <div className="row">
                                                                                        <div className="col-md-3 gol_field_div">
                                                                                            <span className="form-attribute">Client Role</span> 
                                                                                        </div>
                                                                                        <div className="col-md-9">
                                                                                            <input disabled value={this.state.ideaItem.CustDesgn}  className="form-input disabled_input" type="text" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-2"></div>
                                                                                <div className="col-md-5">
                                                                                    <div className="row">
                                                                                        <div className="col-md-5 gol_field_div">
                                                                                            <span className="form-attribute">Client Sign-off Date</span> 
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input disabled value={ this.formatDateString(new Date(this.state.ideaItem.CustSignOffDate))} className="form-input disabled_input" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                    <div className="row">
                                                                                        <div className="col-md-5 gol_field_div">
                                                                                            <span className="form-attribute">Is the Client approval email attached?</span> 
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input disabled value={this.state.ideaItem.IsClientApprovalAttached} className="form-input disabled_input" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {
                                                                                this.state.signOffFilename
                                                                                ?
                                                                                <>
                                                                                    <div className="gol_uploaded_documents">
                                                                                        <div className="row u-align-left">
                                                                                            <div className="col-md-6">
                                                                                                <div className="row gol_uploaded_documents_row">
                                                                                                    <div className="col-md-5">
                                                                                                        <label>Client Approval</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-5">
                                                                                                        <button onClick={this.showSignOffContent} className="file_name">{this.state.signOffFilename}</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                                }

                                                                {
                                                                    this.state.showGOLFields
                                                                    ?
                                                                    <>

                                                                        <div className="gol_fields_block" style={{borderBottom:"0px"}}>
                                                                            <p className="u-align-left u-text u-text-2" style={{margin: "10px 0px 20px"}}>GOL Approval Details</p>
                                                                            <div className="row u-align-left">
                                                                                <div className="col-md-5">
                                                                                    <div className="row">
                                                                                        <div className="col-md-3 gol_field_div">
                                                                                            <span className="form-attribute">GOL OHR</span> 
                                                                                        </div>
                                                                                        <div className="col-md-9">
                                                                                            <input className="form-input hide_numeric_arrows disabled_input" type="number" disabled value={this.state.ideaItem.GOLOHR} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 15 }} />

                                                                                    <div className="row">
                                                                                        <div className="col-md-3 gol_field_div">
                                                                                            <span className="form-attribute">FPNA OHR</span> 
                                                                                        </div>
                                                                                        <div className="col-md-9">
                                                                                            <input className="form-input hide_numeric_arrows disabled_input" type="number" disabled value={this.state.ideaItem.FPnAOHR} />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div style={{ marginBottom: 15 }} />

                                                                                    <div className="row">
                                                                                        <div className="col-md-5 gol_field_div">
                                                                                            <span className="form-attribute">Are Appropriate GOL and FPNA approvals attached ?</span> 
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input value={this.state.ideaItem.IsFPnAApprovalAttached} className="form-input disabled_input" disabled />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-2"></div>
                                                                                <div className="col-md-5">
                                                                                    <div className="row">
                                                                                        <div className="col-md-3 gol_field_div">
                                                                                            <span className="form-attribute">GOL Full Name</span> 
                                                                                        </div>
                                                                                        <div className="col-md-9">
                                                                                            <input disabled value={this.state.ideaItem.GOLName} className="form-input disabled_input" type="text" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 15 }} />

                                                                                    <div className="row">
                                                                                        <div className="col-md-3 gol_field_div">
                                                                                            <span className="form-attribute">FPNA Name</span> 
                                                                                        </div>
                                                                                        <div className="col-md-9">
                                                                                            <input disabled className="form-input disabled_input" type="text" value={this.state.ideaItem.FPnAName} />
                                                                                        </div>
                                                                                    </div>

                                                                                    
                                                                                </div>
                                                                            </div>

                                                                            <div className="gol_uploaded_documents">
                                                                                <div className="row u-align-left">
                                                                                    <div className="col-md-6">
                                                                                        {
                                                                                            this.state.ideaItem.GOLFileName
                                                                                            ?
                                                                                            <>
                                                                                                <div className="row gol_uploaded_documents_row">
                                                                                                    <div className="col-md-5">
                                                                                                        <label>GOL Approval</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-5">
                                                                                                        <button onClick={(e)=>this.showGolOffContent()} className="file_name">{this.state.ideaItem.GOLFileName}</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                        }

                                                                                        {
                                                                                            this.state.ideaItem.FPnAFileName
                                                                                            ?
                                                                                            <>
                                                                                                <div className="row gol_uploaded_documents_row">
                                                                                                    <div className="col-md-5">
                                                                                                        <label>FPnA Approval</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-5">
                                                                                                        <button onClick={(e)=>this.showFpnaContent()} className="file_name">{this.state.ideaItem.FPnAFileName}</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                    </div>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                                }


                                                            {/* ....latest changes to GOL ends here.... */}                                                             
                                                        </>
                                                        )
                                                    }
                                            </>
                                        )
                                    }

                                    <div style={{ borderBottom: '1px solid lightgrey', marginTop: 80, marginBottom: 5 }} />

                                    <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                        <div className="u-container-layout u-container-layout-7">
                                            {/* <div style={{ borderBottom: '1px solid lightgrey', marginTop: 20 }} /> */}
                                            <p className="u-align-left u-text u-text-2">Approval details</p>
                                            <div className="row">
                                                <div className="col-md-2"><span className="form-attribute"><span style={{ color: 'red' }}>*</span> Approval Status</span> </div>
                                                <div className="col-md-2">
                                                    <Select isSearchable={false} options={this.renderStatusOptions()} value={this.state.approvalSelectedOption} placeholder="Choose Status" onChange={this.handleApprovalStatus} styles={this.state.selectStyle.approvalSelectedOption} />
                                                </div>
                                            </div>
                                            <div className="col-md-8" style={{ marginBottom: 5, width: "100%" }} />
                                            {/* <div className="row">
                                                <div className="col-md-12">
                                                    <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                        <span className="u-text-black form-attribute"><span style={{ color: 'red' }}>*</span>&nbsp;Approval Comments<span style={{ fontWeight: 'normal', fontSize:12, fontStyle: 'italic', color: 'grey' }}>(max. 500 chars)</span></span>
                                                    </p>
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <div className="row">
                                                        <div className="col-md-10">
                                                            <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                                <span className="u-text-black form-attribute"><span style={{ color: 'red' }}>*</span>&nbsp;Approval Comments<span style={{ fontWeight: 'normal', fontSize:12, fontStyle: 'italic', color: 'grey' }}>(max. 500 chars)</span></span>
                                                            </p>
                                                        </div>
                                                        <div className="col-md-2" style={{display:'flex',justifyContent:"flex-end",alignItems:"center"}}>
                                                            <img onClick={(e)=>this.toggleTranscriptFunction(9)} src={require("../assets/images/voice.png")} className="w100" alt="" style={{width:"24px",cursor:"pointer"}} />
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col-md-12">
                                                    <textarea className="textarea-css form-attribute"  style={{ maxWidth: '100%', border: '1px solid lightgrey', ...this.state.selectStyle.comment }} placeholder="Please provide your comments in support of the Approval decision." 
                                                        onChange={this.handleFinalCommentChange} 
                                                        rows={3} cols={88}    
                                                        value={this.state.finalComment}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.speechModal
                                            ?
                                            <UpdatedSpeech modalOpen={true} 
                                                onLDTApprovalComment={this.handleTranscriptLDTComment} 
                                                modalId={this.state.modalIndex} 
                                                closeModalFunction={this.closeModalFunction} 
                                                previousText={this.state.previousTextForSpeech}
                                            />
                                            :
                                            null
                                        }
                                    </div>

                                    <div style={{ borderBottom: '1px solid lightgrey', marginTop: 10, marginBottom: 15 }} />
                                    <div className="btn-group" style={{ marginTop: 15 }}>
                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.submitIdea}>Save</button>
                                        <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.cancelEvent}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default LDTReviewIdeaPage
