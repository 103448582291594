import React, { Component } from 'react'
import { GET_IDEA_URL, GET_METADATA, DATA_BRIDGE_CAMPAIGN,GET_ALL_IDEA_URL, X_POLLINATION_CAMPAIGN, ACCESS_TOKEN  } from '../assets/constants/constants';
import Toast from 'light-toast';

export class ViewProjectPage extends Component {
    accessToken = localStorage.getItem(ACCESS_TOKEN);    
    constructor(props) {
        super(props)

        const isApprovalPage = `${ this.props.location.approval }`;
        const projectDetails = this.props.location.data;
        let isViewAllProject = this.props.location.isViewAllProject; 
        // console.log("Project Details:" + JSON.stringify(projectDetails));
        let propsData= this.props.location;
        console.log("props data",propsData)
       
        this.state = {
            ideaItem: projectDetails,
            WorkflowStatus: "",
            Comment: "",
            accountName: "",
            campaignName: "",
            serviceLineName: "",
            dataBridgeCampaign: (projectDetails.pg_campaignname === DATA_BRIDGE_CAMPAIGN),
            xPollinationCampaign: (projectDetails.pg_campaignname === X_POLLINATION_CAMPAIGN),
            approvalPage: isApprovalPage,
            isViewAllProject:isViewAllProject,
            businessImpactMasterList: [],
            businessImpactValueArray: [],
            fteTotal: 0,
            dollarTotal: 0.0,
            propsData:propsData,
            projectBIData:[]
        }
    }

    async componentDidMount() {
        // Toast.loading("Fetching Idea & Metadata...", () => { });
        // await this.fetchIdea();
        // // Toast.loading("Fetching Metadata...", () => { });
        // await this.fetchMetadata();
        // Toast.hide();      
        await this.fetchProjects();      
    }

    formatDateString(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
    }
    
    fetchIdea = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            // fetch(`${GET_IDEA_URL}IdeaId=384`, requestOptions)
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    const projectDeckMetadata = data.ResultSets.Table1[0].ProjectPPTDeck ? data.ResultSets.Table1[0].ProjectPPTDeck : "";
                    const signOffMetadata = data.ResultSets.Table1[0].CustomerLeadSignOff ? data.ResultSets.Table1[0].CustomerLeadSignOff : "";
                    const projectFilename = data.ResultSets.Table1[0].ProjectPPTFileName ? data.ResultSets.Table1[0].ProjectPPTFileName : "";
                    const signOffFilename = data.ResultSets.Table1[0].CustomerSignoffFileName ? data.ResultSets.Table1[0].CustomerSignoffFileName : "";
                    const projectFileType = data.ResultSets.Table1[0].ProjectPPTType ? data.ResultSets.Table1[0].ProjectPPTType : "";
                    const signOffFileType = data.ResultSets.Table1[0].CustomerSignoffType ? data.ResultSets.Table1[0].CustomerSignoffType : "";

                    const projectContent = "data:" + projectFileType + ";base64," + projectDeckMetadata;
                    const signOffContent = "data:" + signOffFileType + ";base64," + signOffMetadata;

                    this.setState({
                        ideaItem: data.ResultSets.Table1[0],
                        WorkflowStatus: data.ResultSets.Table1[0].WorkflowStatus,
                        Comment: data.ResultSets.Table1[0].Comment,
                        showSourceId: data.ResultSets.Table1[0].TransformationLeverName === "Digital" ? true : false,
                        projectDeckFilename: projectFilename,
                        signOffFilename: signOffFilename,
                        projectDeckContent: projectContent,
                        signOffContent: signOffContent,
                        sourceId: data.ResultSets.Table1[0].SourceId,
                        dataBridgeCampaign: (data.ResultSets.Table1[0].pg_campaignname === DATA_BRIDGE_CAMPAIGN),
                        xPollinationCampaign: (data.ResultSets.Table1[0].pg_campaignname === X_POLLINATION_CAMPAIGN),
                        businessImpactValueArray: data.BIData ? (data.BIData.Table1 ? (data.BIData.Table1.length > 0 ? data.BIData.Table1 : null ) : null ) : null
                    }, () => {
                        console.log("Idea Details:"+JSON.stringify(data));
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching idea. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }

    // fetch project is used to fetch project's BI values
    fetchProjects = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            fetch(`${GET_ALL_IDEA_URL}UserOHR=''&WhereClause=${this.state.WhereClause}&case=new&type=getdvproject&ideaidalpha=${this.props.location.data.pg_ideationid}&RecID=''&isItIdeaOrGenAI='Idea'`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("fetch project BI data",data)
                    this.setState({
                        projectBIData: data[1],
                    }, () => {
                        resolve();
                        console.log(this.state.projectItem)
                    });
                })
                .catch(error => {
                    console.log(error)
                    Toast.fail("Error while fetching Project. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }
    fetchMetadata = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "",
                EntityName: ""
            }
            const requestOptions = {
                method: 'POST',
                headers: { 
                "Content-Type": "application/json",
                "Authorization":  "Bearer " + this.accessToken,
            },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("BI Data:" + JSON.stringify(data.BIMasterDataVerse));
                    this.setState({
                        businessImpactMasterList: data ? data.BIMasterDataVerse : []
                    }, () => {
                        resolve();
                    })
                })
                .catch(error => {
                    Toast.fail('Error occured while getting metadata idea. Please try again!', 3000, () => {
                        console.log(JSON.stringify(error));
                        this.setState({
                            businessImpactMasterList: []
                        }, () => {
                            resolve();
                        })
                    });

                });
        });
    }
    
    saveEvent = () => {
        let redirectURL = "/list-projects-page";
        if( this.state.approvalPage) {
            if(this.state.isViewAllProject){
                redirectURL = "/view-all-projects-page";
                // this.props.history.push(redirectURL);
                this.props.history.push({
                    pathname: redirectURL,
                    propsList:this.state.propsData
                })
            }
            else{
                // redirectURL = redirectURL + "?page=" + this.state.approvalPage;
                // this.props.history.push(redirectURL);
                this.props.history.push({
                    pathname:redirectURL,
                    search: '?page='+ this.state.approvalPage,
                    propsList:this.state.propsData
                })
            }
        }
        else{
            this.props.history.push(redirectURL);
        }
    }

    render() {
        // let IDEA_APPROVED = (this.state.ideaItem.WorkflowStatus === "Approve");
        // let IDEA_REJECTED = (this.state.ideaItem.WorkflowStatus === "Reject");
        // let IDEA_SEND_BACK = (this.state.ideaItem.WorkflowStatus === "Send Back");
        
        let redirectURL = "/review-page";
        if( this.state.approvalPage) {
            redirectURL = redirectURL + "?page=approval";
        }

        return (
            <section className="u-clearfix u-section-1" id="sec-0f7f">
                <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                    <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                        <div className="u-container-layout u-container-layout-1">
                            <h4 style={{ textAlign: 'center', color: '#00AECF', fontFamily: 'Arial', fontSize: 22 }}>Project Summary - {this.state.ideaItem.pg_projectid}</h4>
                            <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                                <div className="u-container-layout u-container-layout-2" style={{textAlign:'center'}}>
                                    <div className="form-attribute">
                                        <div id="idea1" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                            <div className="u-container-layout u-container-layout-7">
                                                <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                    <div className="u-container-layout u-container-layout-8" >
                                                        <p id="idea1title" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Basic Information</p>
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Project ID</label> </div>
                                                            <div className="col-md-7"><input className="form-input" disabled type="text" value={this.state.ideaItem.pg_projectid} /></div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Project Name</label> </div>
                                                            <div className="col-md-7"><input className="form-input" disabled type="text" value={this.state.ideaItem.msdyn_subject} /></div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Status</label> </div>
                                                            <div className="col-md-7"><input className="form-input" disabled type="text" value={this.state.ideaItem.pg_projectstatus} /></div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>State</label> </div>
                                                            <div className="col-md-7"><input className="form-input" disabled type="text" value={this.state.ideaItem.pg_projectstate} /></div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Creation Date</label> </div>
                                                            <div className="col-md-7"><input className="form-input" disabled type="text" value={this.state.ideaItem.pg_projcreatedate} /></div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Actual Start Date</label> </div>
                                                            <div className="col-md-7"><input className="form-input" disabled type="text" value={this.state.ideaItem.msdyn_scheduledstart} /></div>
                                                        </div>
                                                        <div style={{ marginBottom: 10 }} />
                                                    </div>
                                                </div>

                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 20 }}>&nbsp;</p>

                                                <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                    <div className="u-container-layout u-container-layout-9" style={{ marginTop: -28 }}>
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Account&nbsp;Name</label></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled list="accounts" name="accounts" placeholder="Choose Account" value={this.state.ideaItem.pg_account} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Vertical</label></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled name="vertical" placeholder="Choose Vertical" value={this.state.ideaItem.pg_vertical} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub&nbsp;Vertical</label></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled name="subverticallist" placeholder="Choose Sub&nbsp;Vertical" value={this.state.ideaItem.pg_subvertical} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Country</label></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled name="regions" placeholder="Choose Country" value={this.state.ideaItem.pg_country} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Region</label></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled name="regions" placeholder="Choose Region" value={this.state.ideaItem.pg_region} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub-Region</label></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled name="regions" placeholder="Choose Sub-Region" value={this.state.ideaItem.pg_subregion} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Service Line</label> </div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled name="serviceline" placeholder="Choose Service Line" value={this.state.ideaItem.pg_serviceline1txt} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Service Line</label> </div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled name="serviceline" placeholder="Choose Sub Service Line" value={this.state.ideaItem.pg_serviceline2txt} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-7" style={{ marginBottom: 15 }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 30 }} />
                                    
                                    <div className="form-attribute">
                                        <div id="idea1" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ marginTop: 15 }}>
                                                <div className="u-container-layout u-container-layout-7">
                                                    <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                        <div className="u-container-layout u-container-layout-8">
                                                            <p id="idea1title" className="u-align-left u-text u-text-2" style={{ marginBottom: 10  }}>Team Member</p>
                                                            <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Project Owner</label> </div>
                                                                <div className="col-md-7">
                                                                <input className ="form-input" disabled name="serviceline" placeholder="Choose Project Owner" value={this.state.ideaItem.pg_projowner} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginBottom: 15 }} />
                                                            <div className="row">
                                                                <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Champion</label> </div>
                                                                <div className="col-md-7">
                                                                <input className ="form-input" disabled name="serviceline" placeholder="Choose Champion" value={this.state.ideaItem.pg_champion} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginBottom: 15 }} />
                                                            <div className="row">
                                                                <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Mentor</label> </div>
                                                                <div className="col-md-7">
                                                                <input className ="form-input" disabled name="serviceline" placeholder="Choose Mentor" value={this.state.ideaItem.pg_mentor} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginBottom: 15 }} />
                                                            <div className="row">
                                                                <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Certify authority</label> </div>
                                                                <div className="col-md-7">
                                                                <input className ="form-input" disabled name="serviceline" placeholder="Choose Certify authority" value={this.state.ideaItem.pg_ca} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                <div className="u-container-style u-group u-shape-rectangle u-group-9" >
                                                        <div className="u-container-layout u-container-layout-9" style={{ marginTop: 62 }}>
                                                            <p id="idea1title" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Business Impact</p>
                                                            <div className="row">
                                                                <div className="col-md-6"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Zero impact project </label> </div>
                                                                <div className="col-md-5"><input className="form-input" disabled type="text" value={this.state.projectBIData.zero_impact_project} placeholder="Business impact delivered (FTE)" /></div>
                                                            </div>
                                                            <div style={{ marginBottom: 15 }} />
                                                            <div className="row">
                                                                <div className="col-md-6"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Business impact delivered (FTE) </label> </div>
                                                                <div className="col-md-5"><input className="form-input" disabled type="text" value={this.state.projectBIData.impact_delivered_fte} placeholder="Business impact delivered (FTE)" /></div>
                                                            </div>
                                                            <div style={{ marginBottom: 15 }} />
                                                            <div className="row">
                                                                <div className="col-md-6"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Business impact delivered ($)</label> </div>
                                                                <div className="col-md-5">
                                                                <input className ="form-input" disabled name="serviceline" placeholder="Business impact delivered" value={this.state.projectBIData.impact_delivered_doller === "" || this.state.projectBIData.impact_delivered_doller === undefined ? "0" : this.state.projectBIData.impact_delivered_doller } />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 15 }} />
                                                            <p id="idea1title" className="u-align-left u-text u-text-2" style={{ marginBottom: 10,marginTop: 10 }}>Certificate details</p>
                                                            <div className="row">
                                                            <div className="col-md-11"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Certificate under following category </label> </div>
                                                            </div>
                                                            <div style={{ marginBottom: 15 }} />
                                                            <div className="row">
                                                            <div className="col-md-11"><input className ="form-input" disabled type="text" value={this.state.ideaItem.msdyn_subject} placeholder="Certificate under following category" /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7" style={{ marginBottom: 15 }} />
                                                </div>
                                            </div>
                                        </div>
                                    <div className="btn-group" style={{marginTop:20}}>
                                        <button href={redirectURL} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.saveEvent}>Done</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ViewProjectPage
