import React, { Component } from 'react'
import Toast from 'light-toast';
import Select from 'react-select';
import { selectValidation as selectStyle, textBoxValidation } from "./FieldValidation";
import { GET_IDEA_URL, TEMPLATE_UPDATE, UPDATE_IDEA_URL, AWAITING_MBB_APPROVAL, GET_METADATA, APPROVED, PENDING, SENT_BACK, REJECTED, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, ACCESS_TOKEN, COMPLETED_STATUS, USER_DATA, SIGNOFF_CONTENT, PPT_CONTENT, REQUEST_CANCELLATION, BI_FORM_DATE, GOL_APPROVAL_CONTENT, AWAITING_QAULITY_CHECK_REVIEW } from '../assets/constants/constants';
import Checksum from "./Checksum";
import { withRouter } from 'react-router-dom';

const clientApprovalOptions = [
    { value: '0', label: 'Yes' },
    { value: '1', label: 'No' },
];

export default class DescriptiveAnalyticsTemplate extends Component {

    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);

    constructor(props) {
        super(props)
        let propsData = this.props.location;
        this.state = {
            ideaID: propsData.state,
            LeanID: "",
            RecType:"",
            toolsUsed: "",
            insightsDrawn: "",
            metricsHighlighted: "",
            notesOrComments: "",
            additionalSolutions: null,
            additionalSolutionsDeployed: "",
            selectStyle: {},
            ideaItem: {}
        }
    }
    async componentDidMount() {
        // const location = this.props.location;
        // const ID = new URLSearchParams(location.search).get('SUQ');
        // console.log("ID =>",ID)
        // this.setState({
        //     ideaID: ID,
        // },()=>{
        //     console.log(this.state.ideaID)
        // });
        // const decodedID = atob(ID);
        // console.log("decorded =>",decodedID)
        console.log("idea id =>",this.state.ideaID)
        Toast.loading("Fetching template data...", () => { });
        await this.fetchIdea();
        Toast.hide();

        
    }


    fetchIdea = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
            };
            fetch(`${GET_IDEA_URL}IdeaId=${this.state.ideaID}&RecID=''&isItIdeaOrGenAI='Idea'`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    this.setState(
                        {
                            ideaItem: data.ResultSets.Table1[0] ? data.ResultSets.Table1[0]:[],
                            LeanID: data.ResultSets.Table1 ? data.ResultSets.Table1[0].LeanID : "",
                            toolsUsed: data.Templates.ResultSets.Table1[0] ? data.Templates.ResultSets.Table1[0].Tools : "",
                            insightsDrawn: data.Templates.ResultSets.Table1[0] ? data.Templates.ResultSets.Table1[0].Insights : "",
                            metricsHighlighted: data.Templates.ResultSets.Table1[0] ? data.Templates.ResultSets.Table1[0].Metrics : "",
                            notesOrComments: data.Templates.ResultSets.Table1[0] ? data.Templates.ResultSets.Table1[0].Comment : "",
                            additionalSolutionsDeployed: data.Templates.ResultSets.Table1[0] ? data.Templates.ResultSets.Table1[0].AddSolution : "",
                            RecType: data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? "" : data.Templates.ResultSets.Table1[0].RecType,
                        },
                        () => {
                            if (this.state.additionalSolutionsDeployed) {
                                let options = {
                                    label: "Yes",
                                    value: 0
                                }
                                this.setState({ additionalSolutions: options }, () => { console.log(this.state.additionalSolutions) })
                            } else {
                                let options = {
                                    label: "No",
                                    value: 0
                                }
                                this.setState({ additionalSolutions: options }, () => { console.log(this.state.additionalSolutions) })
                            }
                            resolve();
                        }
                    );
                })
                .catch((error) => {
                    Toast.fail("Error while fetching idea. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    };


    handleToolsUsedChange = (e) => {
        let nonWhiteSpace= e.replace(/^\s+|\s+$/gm,'');
        let styleStateObj = this.state.selectStyle;
        styleStateObj.toolsUsed = textBoxValidation(true);
        this.setState({selectStyle: styleStateObj})
        if(nonWhiteSpace.length<160){
            this.setState({ toolsUsed: e }, () => { })
        }else{
            const trimmedValue = nonWhiteSpace.slice(0, 160);
            this.setState({ toolsUsed: trimmedValue }, () => { })
        }
    };
    insightsDrawnChange = (e) => {
        let nonWhiteSpace= e.replace(/^\s+|\s+$/gm,'');
        let styleStateObj = this.state.selectStyle;
        styleStateObj.insightsDrawn = textBoxValidation(true);
        this.setState({selectStyle: styleStateObj})
        if(nonWhiteSpace.length<1500){
            this.setState({ insightsDrawn: e }, () => { })
        }else{
            const trimmedValue = nonWhiteSpace.slice(0, 1500);
            this.setState({ insightsDrawn: trimmedValue }, () => { })
        }
    };
    metricsHighlightedChange = (e) => {
        let nonWhiteSpace= e.replace(/^\s+|\s+$/gm,'');
        let styleStateObj = this.state.selectStyle;
        styleStateObj.metricsHighlighted = textBoxValidation(true);
        this.setState({selectStyle: styleStateObj})
        if(nonWhiteSpace.length<255){
            this.setState({ metricsHighlighted: e }, () => { })
        }else{
            const trimmedValue = nonWhiteSpace.slice(0, 255);
            this.setState({ metricsHighlighted: trimmedValue }, () => { })
        }
    };
    noteorCommentsChange = (e) => {
        let nonWhiteSpace= e.replace(/^\s+|\s+$/gm,'');
        let styleStateObj = this.state.selectStyle;
        styleStateObj.notesOrComments = textBoxValidation(true);
        this.setState({selectStyle: styleStateObj})
        if(nonWhiteSpace.length<1500){
            this.setState({ notesOrComments: e }, () => { })
        }else{
            const trimmedValue = nonWhiteSpace.slice(0, 1500);
            this.setState({ notesOrComments: trimmedValue }, () => { })
        }
    };
    additionalSolutiononChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.additionalSolutions = selectStyle(true);
        this.setState({
            additionalSolutions: {
                value: e.value,
                label: e.label
            }, selectStyle: styleStateObj
        }, () => {
            if (this.state.additionalSolutions.label === "No") {
                this.setState({ additionalSolutionsDeployed: "" }, () => { })
            }
        });
        
    };
    additionalSolutionTextonChange = (e) => {
        console.log("additional solution change =>",e.length)
        const inputValue = e.replace(/^\s+|\s+$/gm,'');
        if (inputValue.length <= 1500) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.additionalSolutionsDeployed = textBoxValidation(true);
            this.setState({ additionalSolutionsDeployed: e }, () => { })
        } 
        else {
            const trimmedValue = inputValue.slice(0, 1500);
            this.setState({ additionalSolutionsDeployed: trimmedValue },()=>{
                console.log("notesOrComments  drawn =>",this.state.additionalSolutionsDeployed.length)
            });
        }
    };

    validate = () => {
        let isValid = true;
        let styleStateObj = selectStyle;

        // if (this.state.ideaItem.LeanID === "" || this.state.ideaItem.LeanID === null || this.state.ideaItem.LeanID === undefined || !this.state.ideaItem.LeanID){
        //     isValid = false;
        //     Toast.fail("Only Projects which have been converted into lean can only be viewed", 3000, () => { })
        // }
        // if((parseInt(this.state.ideaItem.OwnerOHR) === parseInt(this.sessionData.profile.oHRId))){
        //     if(this.state.RecType && this.state.RecType ==="DA"){
        //         isValid=false;
        //         Toast.fail("Descriptive template can only be saved once by the User", 3000, () => { })
        //     }
        //     if(this.state.RecType && this.state.RecType ==="DPA"){
        //         isValid=false;
        //         Toast.fail("Descriptive template cannot be saved if the user has already filled the Diagnostic or Prescriptive template for this Idea. User can only save one template among Descriptive, Diagnostic or Prescriptive", 5000, () => { })
        //     }
        // }

        

         // tools used textbox validation
         if (this.state.toolsUsed === null || this.state.toolsUsed === "" || this.state.toolsUsed === undefined) {
            isValid = false;
            styleStateObj.toolsUsed = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj
            }, () => { })
        }
        else {
            console.log(this.state.toolsUsed.length)
            console.log(typeof this.state.toolsUsed.length)
            if (this.state.toolsUsed.length > 161) {
                Toast.fail("Tools Used cannot be more than 160 characters, please change and resubmit.", 3000,()=>{});
                isValid = false;
                styleStateObj.toolsUsed = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                }, () => { })
            } else {
                styleStateObj.toolsUsed = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                this.setState({
                    selectStyle: styleStateObj
                }, () => { })
            }

        }

        // insights drawn textbox validation
        
        if (this.state.insightsDrawn === null || this.state.insightsDrawn === "" || this.state.insightsDrawn === undefined) {
            isValid = false;
            styleStateObj.insightsDrawn = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj
            }, () => { })
        }
        else {
            if (this.state.insightsDrawn.length > 1501) {
                console.log("insightsDrawn =>",this.state.insightsDrawn.length)
                Toast.fail("Insights Drawn cannot be more than 1500 characters, please change and resubmit.", 3000);
                isValid = false;
                styleStateObj.insightsDrawn = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                }, () => { })
            } else {
                styleStateObj.insightsDrawn = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                this.setState({
                    selectStyle: styleStateObj
                }, () => { })
            }

        }

        // metrics highlighted textbox validation
        if (this.state.metricsHighlighted === null || this.state.metricsHighlighted === "" || this.state.metricsHighlighted === undefined) {
            isValid = false;
            styleStateObj.metricsHighlighted = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj
            }, () => { })
        }
        else {
            if (this.state.metricsHighlighted.length > 256) {
                console.log("metricsHighlighted =>",this.state.metricsHighlighted.length)
                Toast.fail("Metrics highlighted cannot be more than 255 characters, please change and resubmit.", 3000);
                isValid = false;
                styleStateObj.metricsHighlighted = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                }, () => { })
            } else {
                styleStateObj.metricsHighlighted = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                this.setState({
                    selectStyle: styleStateObj
                }, () => { })
            }
        }


        // notes or comments textbox validation
        if (this.state.notesOrComments === null || this.state.notesOrComments === "" || this.state.notesOrComments === undefined) {
            isValid = false;
            styleStateObj.notesOrComments = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj
            }, () => { })
        }
        else {
            if (this.state.notesOrComments.length > 1501) {
                console.log("notes or comments =>",this.state.notesOrComments.length)
                Toast.fail("Comments cannot be more than 1500 characters, please change and resubmit.", 3000);
                isValid = false;
                styleStateObj.notesOrComments = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                }, () => { })
            } else {
                styleStateObj.notesOrComments = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                this.setState({
                    selectStyle: styleStateObj
                }, () => { })
            }


        }

        // additional tools yes/no select validation
        if (this.state.additionalSolutions === null || this.state.additionalSolutions === "" || this.state.additionalSolutions === undefined || !this.state.additionalSolutions) {
            isValid = false;
            styleStateObj.additionalSolutions = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            }, () => { })
        }
        else {
            styleStateObj.additionalSolutions = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            }, () => { })
        }

        // additional Solutions textbox validation
        if (this.state.additionalSolutions && this.state.additionalSolutions.label === "Yes") {
            if (this.state.additionalSolutionsDeployed === null || this.state.additionalSolutionsDeployed === "" || this.state.additionalSolutionsDeployed === undefined) {
                isValid = false;
                styleStateObj.additionalSolutionsDeployed = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                }, () => { })
            }
            else {

                if (this.state.additionalSolutionsDeployed.length > 1501) {
                    console.log("add solution comment =>",this.state.additionalSolutionsDeployed.length)
                    Toast.fail("Solution deployed cannot be more than 1500 characters, please change and resubmit.", 3000);
                    isValid = false;
                    styleStateObj.additionalSolutionsDeployed = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj
                    }, () => { })
                } else {
                    styleStateObj.additionalSolutionsDeployed = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                    this.setState({
                        selectStyle: styleStateObj
                    }, () => { })
                }
            }
        }

        

        

        if (!isValid) {
            Toast.fail('Required fields are missing or have invalid values. Please correct and try again.', 3000, () => { });
        }
        return isValid;
    }
    saveTemplate = () => {
        if ((parseInt(this.state.ideaItem.OwnerOHR) === parseInt(this.sessionData.profile.oHRId)) || (parseInt(this.state.ideaItem.BlackBeltUserId) === parseInt(this.sessionData.profile.oHRId))) {
            if (this.validate()) {
                if (window.confirm("Are you sure you want to save this template?")) {
                    Toast.loading("Saving template...", () => { });
                    let param = {};
                    param = {
                        ProjectID: this.state.LeanID ? this.state.LeanID : "",
                        RecType: "DA",
                        TemplateType: "UpdateDescriptiveAnalytics",
                        Tools: this.state.toolsUsed ? this.state.toolsUsed : "",
                        Insights: this.state.insightsDrawn ? this.state.insightsDrawn : "",
                        Metrics: this.state.metricsHighlighted ? this.state.metricsHighlighted : "",
                        Comment: this.state.notesOrComments ? this.state.notesOrComments : "",
                        //AddSolution:(this.state.additionalSolutions && this.state.additionalSolutions.label === "Yes" ? this.state.additionalSolutionsDeployed : ""),
                        AddSolution: this.state.additionalSolutionsDeployed ? this.state.additionalSolutionsDeployed : ""
                    }
                    console.log(param)
                    let finalJsonData = Checksum(param);
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization":  "Bearer " + this.accessToken,
                        },
                        body: JSON.stringify(param)
                    };
                    fetch(`${TEMPLATE_UPDATE}Checksum=${finalJsonData}`, requestOptions)
                        .then(response => response.json())
                        .then(data => {
                            console.log(data)
                            Toast.hide();
                            Toast.success('Template saved successfully!', 3000, () => {
                                window.history.go(-1);
                            });
    
                        });
                }
            } 
           
        }else{
            Toast.fail("Only Project owner and Project Mentor can save template.", 3000, () => { })
            return;
        }
        
    }

    cancelEvent = () => {
        if(window.confirm("Are you sure you want to close this template ?")){
            window.history.go(-1);
        }
    }

    render() {

        return (
            <div>
                <div className="">
                    <div id="Upload" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                        <div className="u-container-layout u-container-layout-1 " style={{paddingLeft:"30px"}}>
                            {/* <div className="discriptiveAnalyticsGoBack">
                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3 template_save_btn" onClick={(e) => this.templateGoBack()}>Go Back</button>
                            </div> */}
                            <div className="dynamic_form_title_block">
                                <h2  className="" style={{textAlign: "center",margin:"40px 0px 0px 0px"}}>Descriptive Analytics</h2>
                                <h4 style={{
                                    textAlign: "center",
                                    color: "#00AECF",
                                    fontFamily: "Arial",
                                    fontSize: 22,
                                    marginTop: "15px",
                                    paddingBottom: "20px",
                                }}>Project Id: {this.state.LeanID ? this.state.LeanID : ""}
                                </h4>
                                
                            </div>
                            <div className="" style={{ padding: "0px 30px 30px 30px" }}>
                                <div className=""  >
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="">
                                                <div className="text-left pb-4">
                                                    <div className="row" style={{ marginTop: "10px" }}>
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-12 text-left">
                                                                    <span className="form-attribute">
                                                                        <span style={{ color: 'red' }}>* </span>
                                                                        Tools Used
                                                                        <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 160 chars)</span>
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <textarea
                                                                        tab={1}
                                                                        value={this.state.toolsUsed}
                                                                        className="textarea-css form-input discriptive_textarea "
                                                                        placeholder='Please provide tools used'
                                                                        onChange={(e) => this.handleToolsUsedChange(e.target.value)}
                                                                        style={this.state.selectStyle.toolsUsed}
                                                                       
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-5">
                                            <div className="">
                                                <div className="">
                                                    <div className="text-left pb-4">
                                                        <div className="row" style={{ marginTop: "10px" }}>
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-12 text-left">
                                                                        <span className="form-attribute">
                                                                            <span style={{ color: 'red' }}>* </span>
                                                                            Insights Drawn
                                                                            <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 1500 chars)</span>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <textarea
                                                                            tab={2}
                                                                            value={this.state.insightsDrawn}
                                                                            className="textarea-css form-attribute discriptive_textarea textarea_with_1500_chars"
                                                                            placeholder="Please provide insights drawn"
                                                                            onChange={(e) => this.insightsDrawnChange(e.target.value)}
                                                                            style={this.state.selectStyle.insightsDrawn}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="">
                                                    <div className="text-left pb-4">
                                                        <div className="row" style={{}}>
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-12 text-left">
                                                                        <span className="form-attribute">
                                                                            <span style={{ color: 'red' }}>* </span>
                                                                            Metrics Highlighted
                                                                            <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 255 chars)</span>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <textarea
                                                                            tab={3}
                                                                            value={this.state.metricsHighlighted}
                                                                            className="textarea-css form-input discriptive_textarea"
                                                                            placeholder='Please provide metrics highlighted'
                                                                            onChange={(e) => this.metricsHighlightedChange(e.target.value)}
                                                                            style={this.state.selectStyle.metricsHighlighted}
                                                                           
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <div className="">
                                                    <div className="text-left pb-4">
                                                        <div className="row" style={{}}>
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-12 text-left">
                                                                        <span className="form-attribute">
                                                                            {/* <span style={{ color: 'red' }}>* </span> */}
                                                                            Notes / Comments
                                                                            <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 1500 chars)</span>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <textarea
                                                                            tab={4}
                                                                            value={this.state.notesOrComments}
                                                                            className="textarea-css form-input discriptive_textarea textarea_with_1500_chars"
                                                                            placeholder='Please provide notes or comments'
                                                                            onChange={(e) => this.noteorCommentsChange(e.target.value)}
                                                                            style={this.state.selectStyle.notesOrComments}
                                                                           
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="">
                                                <div className="text-left pb-4">
                                                    <div className="row" style={{}}>
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-7 text-left">
                                                                    <span className="form-attribute">
                                                                        <span style={{ color: 'red' }}>* </span>
                                                                        Are there any additional solutions deployed as part of this project ?
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-5">
                                                                    <Select
                                                                        tab={5}
                                                                        options={clientApprovalOptions}
                                                                        onChange={(e) => this.additionalSolutiononChange(e)}
                                                                        value={this.state.additionalSolutions}
                                                                        placeholder="Please choose"
                                                                        styles={this.state.selectStyle.additionalSolutions}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {
                                                this.state.additionalSolutions && this.state.additionalSolutions.label === "Yes"
                                                    ?
                                                    <>
                                                        <div className="">
                                                            <div className="text-left pb-4">
                                                                <div className="row" style={{ marginTop: "10px" }}>
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-md-12 text-left">
                                                                                <span className="form-attribute">
                                                                                    <span style={{ color: 'red' }}>* </span>
                                                                                    Additional solutions deployed
                                                                                    <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 1500 chars)</span>
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <textarea
                                                                                    tab={6}
                                                                                    value={this.state.additionalSolutionsDeployed}
                                                                                    className="textarea-css form-input discriptive_textarea textarea_with_1500_chars"
                                                                                    placeholder='Please provide additional solutions deployed'
                                                                                    onChange={(e) => this.additionalSolutionTextonChange(e.target.value)}
                                                                                    style={this.state.selectStyle.additionalSolutionsDeployed}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }


                                            </div>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-5">

                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="template_save_btn_group">
                                    <div className="btn-group">
                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3 template_save_btn" onClick={(e) => this.saveTemplate()} style={{ marginRight: "15px" }}>Save</button>
                                        <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={(e) => this.cancelEvent()}>Cancel</button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
