import React, { Component } from 'react'
import Toast from 'light-toast';
import Select from 'react-select';
import { selectValidation as selectStyle, textBoxValidation } from "./FieldValidation";
import { GET_IDEA_URL, TEMPLATE_UPDATE, UPDATE_IDEA_URL, AWAITING_MBB_APPROVAL, GET_METADATA, APPROVED, PENDING, SENT_BACK, REJECTED, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, ACCESS_TOKEN, COMPLETED_STATUS, USER_DATA, SIGNOFF_CONTENT, PPT_CONTENT, REQUEST_CANCELLATION, BI_FORM_DATE, GOL_APPROVAL_CONTENT, AWAITING_QAULITY_CHECK_REVIEW } from '../assets/constants/constants';
import Checksum from "./Checksum";
import { withRouter } from 'react-router-dom';

export default class DisplayTemplates extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);
    constructor(props) {
        super(props)
        // console.log("props data =>", props)
        let propsData = this.props.location;
        //console.log("props data=>",propsData)
        this.state = {
            ideaID: "",
            LeanID: "",
            ideaItem: {},
            selectStyle: {},
            showModal: false,
            data: [],
            templateType:props.templateType,
            RecType: "",
            currentData: {
                id: 0,
                SOrd: "",
                Problem: "",
                Technique: "",
                Cause: "",
                Recomm: ""
            },
            editingIndex: null,
            insightsDrawn: "",
            additionalSolutions: [],
            additionalSolutionsDeployed: "",

            // predective template data variables starts here
            roles: [
                {
                    label: "Target",
                    value: 0,
                },
                {
                    label: "Feature",
                    value: 1,
                },
                {
                    label: "Derived",
                    value: 2,
                },
            ],
            editingIndex: null,

            //checkbox states
            classificationChecked: false,
            regressionChecked: false,
            forecastChecked: false,
            othersChecked: false,
            setimentValuesChecked: false,
            modelTypeSelectedForPipeline: [],
            modelOneLabel: "",
            modelTwoLabel: "",
            modelThreeLabel: "",

            // sentiment analysis fields
            showSentimentTemplate: false,
            sentimentValues: {
                goodToHave: "",
                mixedSentiments: "",
                noChecksNeeded: "",
                priorityChecks: "",
                sentimentDuplicates: "",
            },
            sentimentData:{},
            sentimentTotal: 0,
            // Outcome type fields
            outcomeTypeOptions: [
                {
                    label: "2*2",
                    value: 2,
                },
                {
                    label: "3*3",
                    value: 3,
                },
                {
                    label: "4*4",
                    value: 4,
                },
                {
                    label: "5*5",
                    value: 5,
                },
            ],

            classificationTypeOptions: [
                {
                    label: "Categorical",
                    value: 0,
                },
                {
                    label: "Numeric",
                    value: 1,
                },
            ],

            // regression variables
            showRegressionTemplate: false,
            regressionData: [],

            regressionMeanAbsoluteError: "",
            regressionMeanSquaredError: "",
            regressionRootMeanSquaredError: "",
            regressionMeanAbsolutePercentageError:"",


            regressionMetricesOne: false,
            regressionMetricesOneLabel: "",
            regressionMetricesOneInput: "",

            regressionMetricesTwo: false,
            regressionMetricesTwoLabel: "",
            regressionMetricesTwoInput: "",

            regressionMetricesThree: false,
            regressionMetricesThreeLabel: "",
            regressionMetricesThreeInput: "",

            regressionMetricesFour: false,
            regressionMetricesFourLabel: "",
            regressionMetricesFourInput: "",

            regressionMetricesFive: false,
            regressionMetricesFiveLabel: "",
            regressionMetricesFiveInput: "",
            regressionOtherFields: {},

            // forecast variables
            showForecastTemplate: false,
            forecastData: [],

            forecastMeanAbsoluteError: "",
            forecastMeanSquaredError: "",
            forecastRootMeanSquaredError: "",
            forecastMeanAbsolutePercentageError:"",
            forecastMetricesOne: false,
            forecastMetricesOneLabel: "",
            forecastMetricesOneInput: "",

            forecastMetricesTwo: false,
            forecastMetricesTwoLabel: "",
            forecastMetricesTwoInput: "",

            forecastMetricesThree: false,
            forecastMetricesThreeLabel: "",
            forecastMetricesThreeInput: "",

            forecastMetricesFour: false,
            forecastMetricesFourLabel: "",
            forecastMetricesFourInput: "",

            forecastMetricesFive: false,
            forecastMetricesFiveLabel: "",
            forecastMetricesFiveInput: "",

            forecastOtherFields: {},
            forecastPostData: [],

            // Anamoly variables starts here
            showAnamolyTemplate: false,
            anomolyData: [{}],

            // other variables starts here
            showOthersTemplate: false,
            othersData: [{}],

            // classification Module 0 variables
            classificationModelSelected: [],
            classificationModels: [
                {
                    value: 0,
                    label: "Random Forest",
                },
                {
                    value: 1,
                    label: "DNN",
                },
                {
                    value: 2,
                    label: "ETC",
                },
            ],
            showClassificationTemplate: true,
            showClassificationModule: false,
            classificationModule: [],


            // classification Module 1 variables
            classificationModelSelected1:[],
            showClassificationTemplate1: true,
            showClassificationModule1: false,
            classificationModule1: [],


            // classification Module 2 variables
            classificationModelSelected2:[],
            showClassificationTemplate2: false,
            showClassificationModule2: false,
            classificationModule2: [],


            // Confusion matrix 1
            confusionMatrixTableSelectedOption1: [],
            confusionMatrixData1: [],
            confusionMatrixPrecisionResults1: {},
            confusionMatrixRecallResults1: {},
            overAllAccuray:"",
            // Confusion matrix 2
            confusionMatrixTableSelectedOption2: [],
            confusionMatrixData2: [],
            confusionMatrixPrecisionResults2: {},
            confusionMatrixRecallResults2: {},
            overAllAccuray1:"",
            // Confusion matrix 3
            confusionMatrixTableSelectedOption3: [],
            confusionMatrixData3: [],
            confusionMatrixPrecisionResults3: {},
            confusionMatrixRecallResults3: {},
            overAllAccuray2:"",

            DPA: [],
            displayPredictiveTemplate: false
        };
    }

    async componentDidMount() {
        Toast.loading("Fetching data..", () => { });
        await this.fetchIdea();
        Toast.hide();
        //console.log("template type =>",this.state.templateType)
        // these functions are called in CDM to load precision rows at page load 
        // this.confusionMatrixprecision();
        //this.calculateRecall1()
        // this.confusionMatrixprecision1();
        //this.calculateRecall2()
        // this.confusionMatrixprecision2();
        //this.calculateRecall3()


    }
    fetchIdea = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
            };

            fetch(`${GET_IDEA_URL}IdeaId=${this.props.IdeaId}&RecID=''&isItIdeaOrGenAI='Idea'`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    this.setState(
                        {
                            ideaItem: data.ResultSets.Table1[0],
                            LeanID: data.ResultSets.Table1 ? data.ResultSets.Table1[0].LeanID : "",
                            RecType: data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table1[0].RecType,
                            toolsUsed: data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? "" : data.Templates.ResultSets.Table1[0].Tools,
                            insightsDrawn: data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table1[0].Insights,
                            metricsHighlighted: data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table1[0].Metrics,
                            notesOrComments: data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table1[0].Comment,
                            additionalSolutionsDeployed: data.Templates.ResultSets.Table1[0] ? data.Templates.ResultSets.Table1[0].AddSolution : "",
                            DPA: data.Templates.ResultSets.Table2 && data.Templates.ResultSets.Table2[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table2,
                            SOrdTable: data.Templates.ResultSets.Table5 && data.Templates.ResultSets.Table5[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table5,
                            classificationModule: data.Templates.ResultSets.Table6 && data.Templates.ResultSets.Table6[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table6,
                            confusionMatrixData1: data.Templates.ResultSets.Table7 && data.Templates.ResultSets.Table7[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table7,
                            regressionData: data.Templates.ResultSets.Table8 && data.Templates.ResultSets.Table8[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table8,
                            regressionOtherFields: data.Templates.ResultSets.Table4 && data.Templates.ResultSets.Table4[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table4,
                            anomolyData: data.Templates.ResultSets.Table9 && data.Templates.ResultSets.Table9[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table9,
                            sentimentData:data.Templates.ResultSets.Table3 && data.Templates.ResultSets.Table3[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table3[0]
                        },
                        () => {
                            if(this.state.DPA){
                                let localData = [...this.state.DPA]
                                localData.sort((a, b) => a.SOrd - b.SOrd);
                                this.setState({DPA:localData},()=>{
                                    //console.log("sorted in ascending order =>",this.state.DPA)
                                })
                            }
                            

                            this.displaySordTable(this.state.SOrdTable);
                            this.displayClassification(this.state.classificationModule, this.state.confusionMatrixData1);
                            this.displayRegression(this.state.regressionData,this.state.regressionOtherFields);
                            this.displayForecast(this.state.regressionData,this.state.regressionOtherFields);
                            this.displayAnamoly(this.state.anomolyData);
                            this.displayOthers(this.state.anomolyData);
                            this.displaySentimentAnalysis(this.state.sentimentData);
                            resolve();
                        }
                    );
                })
                .catch((error) => {
                    Toast.fail("Error while fetching idea. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    };


    displaySordTable = (data) => {
        if (data.length > 0) {
            let selectedModelType = [];
            data.map((item, index) => {
                if (item.SOrd === 0) {
                    if (item.ModelType) {
                        let splitValues = item.ModelType.split("|");
                        if (splitValues.includes("RG")) {
                            this.setState(
                                { showRegressionTemplate: true, displayPredictiveTemplate: true },
                                () => { }
                            );
                        }
                        if (splitValues.includes("FO")) {
                            this.setState(
                                { showForecastTemplate: true, displayPredictiveTemplate: true },
                                () => { }
                            );
                        }
                        if (splitValues.includes("CL")) {
                            this.setState(
                                { classificationChecked: true, displayPredictiveTemplate: true },
                                () => { }
                            );
                        }
                        if (splitValues.includes("AN")) {
                            this.setState(
                                { showAnamolyTemplate: true, displayPredictiveTemplate: true },
                                () => { }
                            );
                        }
                        if (splitValues.includes("OT")) {
                            this.setState({ showOthersTemplate: true, displayPredictiveTemplate: true }, () => { });
                        }
                        if (splitValues.includes("SA")) {
                            this.setState({ showSentimentTemplate: true, displayPredictiveTemplate: true }, () => { });
                        }
                    }
                }
                if (item.SOrd === 1) {
                    if (item.ModelName) {
                      let toBeReplaced = item.ModelName
                      toBeReplaced = toBeReplaced.replace(/[|,]/g, ",");
                      const wordsArray = toBeReplaced.split(',').map(word => word.trim());
                      const wordObjects = wordsArray.map((word, index) => ({ value: index, label: word }));
                      this.setState({classificationModelSelected:wordObjects},()=>{
                      })
                    }
                  }
                if (item.SOrd === 2) {
                    if (item.ModelName) {
                        let toBeReplaced = item.ModelName
                        toBeReplaced = toBeReplaced.replace(/[|,]/g, ",");
                        const wordsArray = toBeReplaced.split(',').map(word => word.trim());
                        const wordObjects = wordsArray.map((word, index) => ({ value: index, label: word }));
                        this.setState({classificationModelSelected1:wordObjects},()=>{
                        })
                    }
                }
                if (item.SOrd === 3) {
                    if (item.ModelName) {
                        let toBeReplaced = item.ModelName
                        toBeReplaced = toBeReplaced.replace(/[|,]/g, ",");
                        const wordsArray = toBeReplaced.split(',').map(word => word.trim());
                        const wordObjects = wordsArray.map((word, index) => ({ value: index, label: word }));
                        this.setState({classificationModelSelected2:wordObjects},()=>{
                        })
                    }
                }
                

            });
        }
    }
    displayClassification = (classificationData, confusionData) => {
        // console.log("classificaiton module =>",classificationData)
        // console.log("confusionMatrixData1 module =>",confusionData)

        if (classificationData.length > 0) {
            let classificationModule = classificationData.filter((item, index) => {
                return item.SOrd === 1;
            })

            if (classificationData.length > 0) {
                const classificationResult = classificationModule.map((item) => {
                    const { ProjectID, ...txtValues } = item;
                    return txtValues;
                });
                if (classificationResult) {
                    classificationResult.map((item, index) => {
                        if (item.Role) {
                            classificationResult[index].Role = {
                                value: 0,
                                label: classificationResult[index].Role,
                            };
                        }
                        if (item.Type) {
                            classificationResult[index].Type = {
                                value: 0,
                                label: classificationResult[index].Type,
                            };
                        }
                    });
                }
                this.setState({ classificationModule: classificationResult }, () => {
                    this.setState({ showClassificationTemplate: true }, () => { })
                })
            }
        }

        if (classificationData.length > 0) {
            let classificationModule1 = classificationData.filter((item, index) => {
                return item.SOrd === 2
            })
            if (classificationModule1) {
                const classificationResult1 = classificationModule1.map((item) => {
                    const { ProjectID, ...txtValues } = item;
                    return txtValues;
                });
                if (classificationResult1) {
                    classificationResult1.map((item, index) => {
                        if (item.Role) {
                            classificationResult1[index].Role = {
                                value: 0,
                                label: classificationResult1[index].Role,
                            };
                        }
                        if (item.Type) {
                            classificationResult1[index].Type = {
                                value: 0,
                                label: classificationResult1[index].Type,
                            };
                        }
                    });
                }
                this.setState({ classificationModule1: classificationResult1 }, () => {
                    this.setState({ showClassificationTemplate1: true }, () => { })
                })
            }
        }

        if (classificationData.length > 0) {
            let classificationModule2 = classificationData.filter((item, index) => {
                return item.SOrd === 3
            })
            if (classificationModule2) {
                const classificationResult2 = classificationModule2.map((item) => {
                    const { ProjectID, ...txtValues } = item;
                    return txtValues;
                });
                if (classificationResult2) {
                    classificationResult2.map((item, index) => {
                        if (item.Role) {
                            classificationResult2[index].Role = {
                                value: 0,
                                label: classificationResult2[index].Role,
                            };
                        }
                        if (item.Type) {
                            classificationResult2[index].Type = {
                                value: 0,
                                label: classificationResult2[index].Type,
                            };
                        }
                    });
                }
                this.setState({ classificationModule2: classificationResult2 }, () => {

                    this.setState({ showClassificationTemplate2: true }, () => { })
                })
            }
        }
        if (confusionData.length > 0) {
            let confusionMatrixData1 = confusionData.filter((item, index) => {
                return item.SOrd === 1
            })
            this.state.outcomeTypeOptions.map((item) => {
                if (item.value === confusionMatrixData1.length) {
                    this.setState({ confusionMatrixTableSelectedOption1: item }, () => { })
                }
            })
            if (confusionMatrixData1) {
                const removeSOrdSrNoProjectID = confusionMatrixData1.map((item) => {
                    const { ProjectID, SOrd, SrNo, ...txtValues } = item;
                    return txtValues;
                });

                const trimmedConfusionMatrixData = removeSOrdSrNoProjectID.map((item) => {
                    const txtValues = Object.fromEntries(
                        Object.entries(item).filter(([key, value]) => (value !== "" || value !== 0))
                    );
                    return txtValues;
                });

                this.setState({ confusionMatrixData1: trimmedConfusionMatrixData }, () => {
                    this.confusionMatrixprecision();
                    this.calculateRecall1()
                    this.calculateOverallAccuracy()
                })
            }
        }
        if (confusionData.length > 0) {
            let confusionMatrixData2 = confusionData.filter((item, index) => {
                return item.SOrd === 2
            })  

            this.state.outcomeTypeOptions.map((item) => {
                if (item.value === confusionMatrixData2.length) {
                    this.setState({ confusionMatrixTableSelectedOption2: item }, () => {

                    })
                }
            })
            if (confusionMatrixData2) {
                const removeSOrdSrNoProjectID1 = confusionMatrixData2.map((item) => {
                    const { ProjectID, SOrd, SrNo, ...txtValues } = item;
                    return txtValues;
                });
                const trimmedConfusionMatrixData2 = removeSOrdSrNoProjectID1.map((item) => {
                    const txtValues = Object.fromEntries(
                        Object.entries(item).filter(([key, value]) => (value !== ""))
                    );
                    return txtValues;
                });
                this.setState({ confusionMatrixData2: trimmedConfusionMatrixData2 }, () => { 
                    this.confusionMatrixprecision1();
                    this.calculateRecall2()
                    this.calculateOverallAccuracy1()
                })
            }
        }


        if (confusionData.length > 0) {
            let confusionMatrixData3 = confusionData.filter((item, index) => {
                return item.SOrd === 3;
            })
            this.state.outcomeTypeOptions.map((item) => {
                if (item.value === confusionMatrixData3.length) {
                    this.setState({ confusionMatrixTableSelectedOption3: item }, () => { })
                }
            })
            if (confusionMatrixData3) {
                const removeSOrdSrNoProjectID2 = confusionMatrixData3.map((item) => {
                    const { ProjectID, SOrd, SrNo, ...txtValues } = item;
                    return txtValues;
                });
                const trimmedConfusionMatrixData = removeSOrdSrNoProjectID2.map((item) => {
                    const txtValues = Object.fromEntries(
                        Object.entries(item).filter(([key, value]) => (value !== ""))
                    );
                    return txtValues;
                });
                this.setState({ confusionMatrixData3: trimmedConfusionMatrixData }, () => { 
                    this.confusionMatrixprecision2();
                    this.calculateRecall3()
                    this.calculateOverallAccuracy2()
                })
            }
        }
    }
    displayRegression = (data, regressionOtherFieldsData) => {
        // console.log("regression Data  =>",data)
        // console.log("regression other fields Data  =>",regressionOtherFieldsData)
        // regression fetch api starts here
        if (data.length > 0) {
            let regressionData = data.filter((item, index) => {
                return item.SOrd === 1;
            })
            if (regressionData) {
                const result = regressionData.map((item) => {
                    const { ProjectID, ...txtValues } = item;
                    return txtValues;
                });
                if (result) {
                    result.map((item, index) => {
                        if (item.Role) {
                            result[index].Role = {
                                value: 0,
                                label: result[index].Role,
                            };
                        }
                        if (item.Type) {
                            result[index].Type = {
                                value: 0,
                                label: result[index].Type,
                            };
                        }
                    });
                }
                this.setState({ regressionData: result }, () => {
                    this.setState({ showRegressionTemplate: true }, () => { })
                })
            }
        }

       
        if (regressionOtherFieldsData.length > 0) {
            let regressionOtherFields = regressionOtherFieldsData.filter((item, index) => {
                return item.SOrd === 1;
            })
            if (regressionOtherFields.length > 0) {
                this.setState(
                    {
                        regressionMeanAbsoluteError: regressionOtherFields[0].Value1,
                        regressionMeanSquaredError: regressionOtherFields[0].Value2,
                        regressionRootMeanSquaredError: regressionOtherFields[0].Value3,
                        regressionMeanAbsolutePercentageError:regressionOtherFields[0].Value4,
                        regressionMetricesOneLabel: regressionOtherFields[0].Label5,
                        regressionMetricesOneInput: regressionOtherFields[0].Value5,
                        regressionMetricesTwoLabel: regressionOtherFields[0].Label6,
                        regressionMetricesTwoInput: regressionOtherFields[0].Value6,
                        regressionMetricesThreeLabel: regressionOtherFields[0].Label7,
                        regressionMetricesThreeInput: regressionOtherFields[0].Value7,
                        regressionMetricesFourLabel: regressionOtherFields[0].Label8,
                        regressionMetricesFourInput: regressionOtherFields[0].Value8,
                    },
                    () => {

                        if (this.state.regressionMetricesOneLabel && this.state.regressionMetricesOneInput) {
                            this.setState(
                              { regressionMetricesOne: true },
                              () => { }
                            );
                        }
                        if (this.state.regressionMetricesTwoLabel && this.state.regressionMetricesTwoInput) {
                        this.setState(
                            { regressionMetricesTwo: true },
                            () => { }
                        );
                        }
                        if (this.state.regressionMetricesThreeLabel && this.state.regressionMetricesThreeInput) {
                            this.setState(
                                { regressionMetricesThree: true },
                                () => { }
                            );
                        }
                        if (this.state.regressionMetricesFourLabel && this.state.regressionMetricesFourInput) {
                            this.setState(
                                { regressionMetricesFour: true },
                                () => { }
                            );
                        }
                    }
                );
            }
        }
    }
    displayForecast = (data, forecastOtherfieldsData) => {
        // console.log("forecast =>",data)
        // console.log("forecast other fields =>",forecastOtherfieldsData)
        if (data.length > 0) {
            let forecastData = data.filter((item, index) => {
                return item.SOrd === 2;
            })
            // console.log("fore cast data =>",forecastData)
            if (forecastData) {
                const result = forecastData.map((item) => {
                    const { ProjectID, ...txtValues } = item;
                    return txtValues;
                });
                if (result) {
                    result.map((item, index) => {
                        if (item.Role) {
                            result[index].Role = {
                                value: 0,
                                label: result[index].Role,
                            };
                        }
                        if (item.Type) {
                            result[index].Type = {
                                value: 0,
                                label: result[index].Type,
                            };
                        }
                    });
                }
                this.setState({ forecastData: result }, () => {
                    this.setState({ showForecastTemplate: true }, () => { })
                })
            }
        }
        if (forecastOtherfieldsData) {
            let regressionOtherFields = forecastOtherfieldsData.filter((item, index) => {
                return item.SOrd === 2;
            })
            if (regressionOtherFields.length > 0) {
                this.setState(
                    {
                        forecastMeanAbsoluteError: regressionOtherFields[0].Value1,
                        forecastMeanSquaredError: regressionOtherFields[0].Value2,
                        forecastRootMeanSquaredError: regressionOtherFields[0].Value3,
                        forecastMeanAbsolutePercentageError:regressionOtherFields[0].Value4,
                        forecastMetricesOneLabel: regressionOtherFields[0].Label5,
                        forecastMetricesOneInput: regressionOtherFields[0].Value5,
                        forecastMetricesTwoLabel: regressionOtherFields[0].Label6,
                        forecastMetricesTwoInput: regressionOtherFields[0].Value6,
                        forecastMetricesThreeLabel: regressionOtherFields[0].Label7,
                        forecastMetricesThreeInput: regressionOtherFields[0].Value7,
                        forecastMetricesFourLabel: regressionOtherFields[0].Label8,
                        forecastMetricesFourInput: regressionOtherFields[0].Value8,
                    },
                    () => {
                        if (
                            this.state.forecastMetricesOneLabel &&
                            this.state.forecastMetricesOneInput
                        ) {
                            this.setState({ forecastMetricesOne: true }, () => { });
                        }
                        if (
                            this.state.forecastMetricesTwoLabel &&
                            this.state.forecastMetricesTwoInput
                        ) {
                            this.setState({ forecastMetricesTwo: true }, () => { });
                        }
                        if (
                            this.state.forecastMetricesThreeLabel &&
                            this.state.forecastMetricesThreeInput
                        ) {
                            this.setState(
                                { forecastMetricesThree: true },
                                () => { }
                            );
                        }
                        if (
                            this.state.forecastMetricesFourLabel &&
                            this.state.forecastMetricesFourInput
                        ) {
                            this.setState({ forecastMetricesFour: true }, () => { });
                        }
                        // if (
                        //     this.state.forecastMetricesFiveLabel &&
                        //     this.state.forecastMetricesFiveInput
                        // ) {
                        //     this.setState({ forecastMetricesFive: true }, () => { });
                        // }
                    }
                );
            }

        }
        
    }
    displayAnamoly = (data) => {
        // console.log("anamoly data",data)
        // anamoly data fetch starts here
        let anomolyData = data.filter((item, index) => {
            return item.SOrd === 1
        })

        if (anomolyData.length > 0) {
            const trimmedAnamolyData = anomolyData.map((item) => {
                const txtValues = Object.fromEntries(
                    Object.entries(item).filter(([key, value]) => key.startsWith("Txt") && value !== "")
                );
                return txtValues;
            });
            if (trimmedAnamolyData.length === 1 && Object.keys(trimmedAnamolyData[0]).length === 0) {
                return false;
            } else {
                this.setState({ anomolyData: trimmedAnamolyData }, () => {
                    this.setState({ showAnamolyTemplate: true }, () => { })
                })
            }

        }
        // anamoly data fetch ends here
    }
    displayOthers = (data) => {
        // console.log("others data =>",data)
        // others data fetch ends here
        let othersData = data.filter((item, index) => {
            return item.SOrd === 2;
        })

        if (othersData.length > 0) {
            const trimmedOthersData = othersData.map((item) => {
                const txtValues = Object.fromEntries(
                    Object.entries(item).filter(([key, value]) => key.startsWith("Txt") && value !== "")
                );
                return txtValues;
            });

            if (trimmedOthersData.length === 1 && Object.keys(trimmedOthersData[0]).length === 0) {
                return false;
            } else {
                this.setState({ othersData: trimmedOthersData }, () => {
                    this.setState({ showOthersTemplate: true }, () => { })
                })
            }

        }
        // others data fetch ends here
    }
  
    displaySentimentAnalysis = (data) => {
        if (data && (typeof data === 'object' || Array.isArray(data))) {
            let localSentimentArray = data;
    
            if (localSentimentArray && (localSentimentArray.Value1 || localSentimentArray.Value2 || localSentimentArray.Value3 || localSentimentArray.Value4 || localSentimentArray.Value5)) {
                let localSentimentValues = {
                    goodToHave: localSentimentArray.Value1 || 0,
                    mixedSentiments: localSentimentArray.Value2 || 0,
                    noChecksNeeded: localSentimentArray.Value3 || 0,
                    priorityChecks: localSentimentArray.Value4 || 0,
                    sentimentDuplicates: localSentimentArray.Value5 || 0,
                };
    
                let localSentimentTotal =
                    localSentimentValues.mixedSentiments +
                    localSentimentValues.goodToHave +
                    localSentimentValues.noChecksNeeded +
                    localSentimentValues.priorityChecks +
                    localSentimentValues.sentimentDuplicates;
    
                this.setState(
                    {
                        sentimentValues: localSentimentValues,
                        sentimentTotal: localSentimentTotal,
                    },
                    () => {
                        if (this.state.sentimentValues) {
                            this.setState({ showSentimentTemplate: true }, () => {});
                        }
                    }
                );
            } else {
                // Handle case where data is present but does not contain the expected values
                // console.log("Invalid data content:", data);
            }
        } else {
            // Handle case where data is undefined, null, or not an object/array
            // console.log("Invalid or missing data:", data);
        }
    }
    
    



    // confusion matrix 1 function starts here
    handleConfusionMatrixSelectedOption = (e) => {
        this.setState({ confusionMatrixData1: [], confusionMatrixPrecisionResults1: {} }, () => { });
        this.setState({ confusionMatrixTableSelectedOption1: e }, () => {
            let confusionMatrixData1 = this.state.confusionMatrixData1;
            for (let i = 0; i < this.state.confusionMatrixTableSelectedOption1.label; i++) {
                confusionMatrixData1.push({
                    Value1: "",
                    Label: "",
                    SrNo: (i + 1).toString(),
                });
            }
            this.setState({ confusionMatrixData1 }, () => { });
        });
    };
    handleConfusionMatrixTableLabelChange = (index, e) => {
        let confusionMatrixData1 = [...this.state.confusionMatrixData1];
        confusionMatrixData1[index].Label = e;
        this.setState({ confusionMatrixData1 });
    };
    handleConfusionMatrixTableInputCellChange = (rowIndex, headerIndex, cellValue) => {
        let confusionMatrixData1 = [...this.state.confusionMatrixData1];
        confusionMatrixData1[rowIndex][`Value${headerIndex + 1}`] = cellValue;
        this.setState({ confusionMatrixData1 }, () => {
            this.confusionMatrixprecision();
            this.calculateRecall1()
        });
    };

    handleConfusionMatrixGetCellData = (rowIndex, headerIndex) => {
        return this.state.confusionMatrixData1[rowIndex][`Value${headerIndex + 1}`] || "";
    };

    confusionMatrixprecision = () => {
        const { confusionMatrixData1 } = this.state;
        if (confusionMatrixData1) {
            let resultForSrNo1 = 0;
            let resultForSrNo2 = 0;
            let resultForSrNo3 = 0;
            let resultForSrNo4 = 0;
            let resultForSrNo5 = 0;

            let localData = confusionMatrixData1.map((item, index) => ({
                ...item,
                SrNo: index + 1,
            }));

            localData.map((item, index) => {
                if (item.Value1) {
                  const sumOfTxt1 = localData.reduce((sum, row) => sum + Number(row.Value1), 0);
                  resultForSrNo1 =  Number(localData.find((row) => row.SrNo === 1).Value1)/sumOfTxt1;
                  resultForSrNo1  = resultForSrNo1*100;
                  resultForSrNo1 = Math.round(resultForSrNo1) 
                  resultForSrNo1 = Math.trunc(resultForSrNo1) 
                }
                if (item.Value2) {
                  const sumOfTxt2 = localData.reduce((sum, row) => sum + Number(row.Value2), 0);
                  resultForSrNo2 =  Number(localData.find((row) => row.SrNo === 2).Value2) / sumOfTxt2;
                  resultForSrNo2  = resultForSrNo2*100;
                  resultForSrNo2 = Math.round(resultForSrNo2) 
                  resultForSrNo2 = Math.trunc(resultForSrNo2) 
                }
                if (item.Value3) {
                  const sumOfTxt3 = localData.reduce((sum, row) => sum + Number(row.Value3), 0);
                  resultForSrNo3 =  Number(localData.find((row) => row.SrNo === 3).Value3) / sumOfTxt3;
                  resultForSrNo3  = resultForSrNo3*100;
                  resultForSrNo3 = Math.round(resultForSrNo3)
                  resultForSrNo3 = Math.trunc(resultForSrNo3)
                }
                if (item.Value4) {
                  const sumOfTxt4 = localData.reduce((sum, row) => sum + Number(row.Value4), 0);
                  resultForSrNo4 = Number(localData.find((row) => row.SrNo === 4).Value4)/sumOfTxt4;
                  resultForSrNo4  = resultForSrNo4*100;
                  resultForSrNo4 = Math.round(resultForSrNo4)
                  resultForSrNo4 = Math.trunc(resultForSrNo4)
                }
                if (item.Value5) {
                    const sumOfTxt5 = localData.reduce((sum, row) => sum + Number(row.Value5), 0);
                    resultForSrNo5 = Number(localData.find((row) => row.SrNo === 5).Value5)/sumOfTxt5;
                    resultForSrNo5  = resultForSrNo5*100;
                    resultForSrNo5 = Math.round(resultForSrNo5)
                    resultForSrNo5 = Math.trunc(resultForSrNo5)
                }
              });
            this.setState(
                {
                    confusionMatrixPrecisionResults1: {
                        resultForSrNo1,
                        resultForSrNo2,
                        resultForSrNo3,
                        resultForSrNo4,
                        resultForSrNo5
                    },
                },
                () => {

                    //console.log("calculate precision =>", this.state.confusionMatrixPrecisionResults1);
                }
            );
        }
    };

    confusionMatrixPrecisionRow1 = () => {
        //console.log("confusionMatrix Precision Results1 => ", this.state.confusionMatrixPrecisionResults1);
        let localData = this.state.confusionMatrixData1.map((item, index) => ({
            ...item,
            SrNo: index + 1,
        }));
        let precisionRow = (
            <tr key="precision" style={{ background: "#15315f" }}>
                <td style={{ textAlign: "left" }}>
                    <label style={{ color: "#fff" }}>Precision</label>
                </td>
                {localData.map((item, index) => (
                    <td style={{ textAlign: "left", color: "#fff" }} key={index}>
                        <label style={{ color: "#fff", marginBottom: "0px" }}>

                            {this.state.confusionMatrixPrecisionResults1 && this.state.confusionMatrixPrecisionResults1[`resultForSrNo${item.SrNo}`]
                                ? this.state.confusionMatrixPrecisionResults1[`resultForSrNo${item.SrNo}`]+ " %"
                                : ""}
                        </label>
                    </td>

                ))}
                <td style={{ textAlign: "left" }}>
                    <label style={{ color: "#fff" }}></label>
                </td>
            </tr>
        );
        return precisionRow;
    };
    // confusion matrix 1 function ends here





    // confusion matrix 2 function starts here
    handleConfusionMatrixSelectedOption1 = (e) => {
        this.setState({ confusionMatrixData2: [], confusionMatrixPrecisionResults2: {} }, () => { });
        this.setState({ confusionMatrixTableSelectedOption2: e }, () => {
            let confusionMatrixData2 = this.state.confusionMatrixData2;
            for (let i = 0; i < this.state.confusionMatrixTableSelectedOption2.label; i++) {
                confusionMatrixData2.push({
                    Value1: "",
                    Label: "",
                    SrNo: (i + 1).toString(),
                });
            }
            this.setState({ confusionMatrixData2 }, () => { });
        });
    };

    handleConfusionMatrixTableLabelChange1 = (index, e) => {
        let confusionMatrixData2 = [...this.state.confusionMatrixData2];
        confusionMatrixData2[index].Label = e;
        this.setState({ confusionMatrixData2 });
    };
    handleSecondConfusionMatrixTableInputCellChange = (rowIndex, headerIndex, cellValue) => {
        let confusionMatrixData2 = [...this.state.confusionMatrixData2];
        confusionMatrixData2[rowIndex][`Value${headerIndex + 1}`] = cellValue;
        this.setState({ confusionMatrixData2 }, () => {
            //console.log("confusion matrix data 2 at input change function", this.state.confusionMatrixData2)
            this.confusionMatrixprecision1();
            this.calculateRecall2();
        });
    };

    handleSecondConfusionMatrixGetCellData = (rowIndex, headerIndex) => {
        return this.state.confusionMatrixData2[rowIndex][`Value${headerIndex + 1}`] || "";
    };

    confusionMatrixprecision1 = () => {
        const { confusionMatrixData2 } = this.state;
        if (confusionMatrixData2) {
          let resultForSrNo1 = 0;
          let resultForSrNo2 = 0;
          let resultForSrNo3 = 0;
          let resultForSrNo4 = 0;
          let resultForSrNo5 = 0;

          let localDataforPrecisionRow = confusionMatrixData2.map((item, index) => ({
            ...item,
            SrNo: index + 1,
          }));
    
          localDataforPrecisionRow.map((item, index) => {
            if (item.Value1) {
              const sumOfTxt1 = localDataforPrecisionRow.reduce((sum, row) => sum + Number(row.Value1), 0);
              resultForSrNo1 =  Number(localDataforPrecisionRow.find((row) => row.SrNo === 1).Value1) /sumOfTxt1;
              resultForSrNo1 = resultForSrNo1*100;
              resultForSrNo1 = Math.round(resultForSrNo1);
              resultForSrNo1 = Math.trunc(resultForSrNo1);
            }
            if (item.Value2) {
              const sumOfTxt2 = localDataforPrecisionRow.reduce((sum, row) => sum + Number(row.Value2), 0);
              resultForSrNo2 = Number(localDataforPrecisionRow.find((row) => row.SrNo === 2).Value2) /sumOfTxt2;
              resultForSrNo2 = resultForSrNo2*100;
              resultForSrNo2 = Math.round(resultForSrNo2);
              resultForSrNo2 = Math.trunc(resultForSrNo2);
            }
            if (item.Value3) {
              const sumOfTxt3 = localDataforPrecisionRow.reduce((sum, row) => sum + Number(row.Value3), 0);
              resultForSrNo3 = Number(localDataforPrecisionRow.find((row) => row.SrNo === 3).Value3) /sumOfTxt3;
              resultForSrNo3 = resultForSrNo3*100;
              resultForSrNo3 = Math.round(resultForSrNo3);
              resultForSrNo3 = Math.trunc(resultForSrNo3);
            }
            if (item.Value4) {
              const sumOfTxt4 = localDataforPrecisionRow.reduce((sum, row) => sum + Number(row.Value4), 0);
              resultForSrNo4 = Number(localDataforPrecisionRow.find((row) => row.SrNo === 4).Value4) / sumOfTxt4;
              resultForSrNo4 = resultForSrNo4*100;
              resultForSrNo4 = Math.round(resultForSrNo4);
              resultForSrNo4 = Math.trunc(resultForSrNo4);
            }
            if (item.Value5) {
                const sumOfTxt5 = localDataforPrecisionRow.reduce((sum, row) => sum + Number(row.Value5), 0);
                resultForSrNo5 = Number(localDataforPrecisionRow.find((row) => row.SrNo === 5).Value5) / sumOfTxt5;
                resultForSrNo5 = resultForSrNo5*100;
                resultForSrNo5 = Math.round(resultForSrNo5);
                resultForSrNo5 = Math.trunc(resultForSrNo5);
            }
          });
          this.setState(
            {
              confusionMatrixPrecisionResults2: {
                resultForSrNo1,
                resultForSrNo2,
                resultForSrNo3,
                resultForSrNo4,
                resultForSrNo5
              },
            },
            () => {
              console.log(this.state.confusionMatrixPrecisionResults2)
            }
          );
    
        }
    
    };

    confusionMatrixPrecisionRow2 = () => {
        let localDataforPrecisionRow = this.state.confusionMatrixData2.map((item, index) => ({
            ...item,
            SrNo: index + 1,
        }));

        let precisionRow = (
            <tr key="precision" style={{ background: "#15315f" }}>
                <td style={{ textAlign: "left" }}>
                    <label style={{ color: "#fff" }}>Precision</label>
                </td>
                {localDataforPrecisionRow.map((item, index) => (
                    <td style={{ textAlign: "left", color: "#fff", marginBottom: "0px" }} key={index}>
                        <label style={{ color: "#fff" }}>
                            {this.state.confusionMatrixPrecisionResults2 && this.state.confusionMatrixPrecisionResults2[`resultForSrNo${item.SrNo}`]
                                ? this.state.confusionMatrixPrecisionResults2[`resultForSrNo${item.SrNo}`]+ " %"
                                : ""}
                        </label>
                    </td>
                ))}
                <td style={{ textAlign: "left" }}>
                    <label style={{ color: "#fff" }}></label>
                </td>
            </tr>
        );
        return precisionRow;
    };
    // confusion matrix 2 function ends here



    // confusion matrix 3 function starts here
    handleConfusionMatrixSelectedOption2 = (e) => {
        this.setState({ confusionMatrixData3: [], confusionMatrixTableSelectedOption3: {} }, () => { });
        this.setState({ confusionMatrixTableSelectedOption3: e }, () => {
            let confusionMatrixData3 = this.state.confusionMatrixData3;
            for (let i = 0; i < this.state.confusionMatrixTableSelectedOption3.label; i++) {
                confusionMatrixData3.push({
                    Txt1: "",
                    Label: "",
                    SrNo: (i + 1).toString(),
                });
            }
            this.setState({ confusionMatrixData3 }, () => { });
        });
    };

    handleConfusionMatrixTableLabelChange2 = (index, e) => {
        let confusionMatrixData3 = [...this.state.confusionMatrixData3];
        confusionMatrixData3[index].Label = e;
        this.setState({ confusionMatrixData3 });
    };
    handleConfusionMatrixTableInputCellChange2 = (rowIndex, headerIndex, cellValue) => {
        let confusionMatrixData3 = [...this.state.confusionMatrixData3];
        confusionMatrixData3[rowIndex][`Value${headerIndex + 1}`] = cellValue;
        this.setState({ confusionMatrixData3 }, () => {
            this.confusionMatrixprecision2();
            this.calculateRecall3()
        });
    };

    handleConfusionMatrixGetCellData2 = (rowIndex, headerIndex) => {
        return this.state.confusionMatrixData3[rowIndex][`Value${headerIndex + 1}`] || "";
    };

    confusionMatrixprecision2 = () => {
        const { confusionMatrixData3 } = this.state;
        if (confusionMatrixData3) {
          let resultForSrNo1 = 0;
          let resultForSrNo2 = 0;
          let resultForSrNo3 = 0;
          let resultForSrNo4 = 0;
          let resultForSrNo5 = 0;
    
          let localDataforPrecisionRow2 = confusionMatrixData3.map((item, index) => ({
            ...item,
            SrNo: index + 1,
          }));
          localDataforPrecisionRow2.map((item, index) => {
            if (item.Value1) {
              const sumOfTxt1 = localDataforPrecisionRow2.reduce((sum, row) => sum + Number(row.Value1), 0);
              resultForSrNo1 = Number(localDataforPrecisionRow2.find((row) => row.SrNo === 1).Value1) / sumOfTxt1;
              resultForSrNo1 = resultForSrNo1*100;
              resultForSrNo1 = Math.round(resultForSrNo1);
              resultForSrNo1 = Math.trunc(resultForSrNo1);
            }
            if (item.Value2) {
              const sumOfTxt2 = localDataforPrecisionRow2.reduce((sum, row) => sum + Number(row.Value2), 0);
              resultForSrNo2 = Number(localDataforPrecisionRow2.find((row) => row.SrNo === 2).Value2) / sumOfTxt2;
              resultForSrNo2 = resultForSrNo2*100;
              resultForSrNo2 = Math.round(resultForSrNo2);
              resultForSrNo2 = Math.trunc(resultForSrNo2);
            }
            if (item.Value3) {
              const sumOfTxt3 = localDataforPrecisionRow2.reduce((sum, row) => sum + Number(row.Value3), 0);
              resultForSrNo3 = Number(localDataforPrecisionRow2.find((row) => row.SrNo === 3).Value3) / sumOfTxt3;
              resultForSrNo3 = resultForSrNo3*100;
              resultForSrNo3 = Math.round(resultForSrNo3)
              resultForSrNo3 = Math.trunc(resultForSrNo3);
            }
            if (item.Value4) {
              const sumOfTxt4 = localDataforPrecisionRow2.reduce((sum, row) => sum + Number(row.Value4), 0);
              resultForSrNo4 = Number(localDataforPrecisionRow2.find((row) => row.SrNo === 4).Value4) /sumOfTxt4;
              resultForSrNo4 = resultForSrNo4*100;
              resultForSrNo4 = Math.round(resultForSrNo4);
              resultForSrNo4 = Math.trunc(resultForSrNo4);
            }

            if (item.Value5) {
                const sumOfTxt5 = localDataforPrecisionRow2.reduce((sum, row) => sum + Number(row.Value5), 0);
                resultForSrNo5 = Number(localDataforPrecisionRow2.find((row) => row.SrNo === 5).Value5) /sumOfTxt5;
                resultForSrNo5 = resultForSrNo5*100;
                resultForSrNo5 = Math.round(resultForSrNo5);
                resultForSrNo5 = Math.trunc(resultForSrNo5);
            }

          });
          this.setState(
            {
              confusionMatrixPrecisionResults3: {
                resultForSrNo1,
                resultForSrNo2,
                resultForSrNo3,
                resultForSrNo4,
                resultForSrNo5
              },
            },
            () => {
              //console.log(this.state.confusionMatrixPrecisionResults3, this.state.confusionMatrixData3);
            }
          );
        }
    };


    confusionMatrixPrecisionRow3 = () => {
        let localData = this.state.confusionMatrixData3.map((item, index) => ({
            ...item,
            SrNo: index + 1,
        }));
        let precisionRow = (
            <tr key="precision" style={{ background: "#15315f" }}>
                <td style={{ textAlign: "left" }}>
                    <label style={{ color: "#fff" }}>Precision</label>
                </td>
                {localData.map((item, index) => (
                    <td style={{ textAlign: "left", color: "#fff" }} key={index}>
                        <label style={{ color: "#fff", marginBottom: "0px" }}>
                            {this.state.confusionMatrixPrecisionResults3 && this.state.confusionMatrixPrecisionResults3[`resultForSrNo${item.SrNo}`]
                                ? this.state.confusionMatrixPrecisionResults3[`resultForSrNo${item.SrNo}`] + " %"
                                : ""}
                        </label>
                    </td>
                ))}
                <td style={{ textAlign: "left" }}>
                    <label style={{ color: "#fff" }}></label>
                </td>
            </tr>
        );
        return precisionRow;
    };
    // confusion matrix 3 function ends here



    getRecallValues1 = (index) => {
        const key = `recall${index + 1}`;
        return this.state.confusionMatrixRecallResults1[key]+" %";
    };
    getRecallValues2 = (index) => {
        //console.log(index)
        const key = `recall${index + 1}`;
        return this.state.confusionMatrixRecallResults2[key]+" %";
    };
    getRecallValues3 = (index) => {
        const key = `recall${index + 1}`;
        return this.state.confusionMatrixRecallResults3[key]+" %";
    };




    calculateRecall1 = () => {
        //console.log("this is called at CDM")
        const { confusionMatrixData1 } = this.state;
        if (confusionMatrixData1) {
            let recall1 = 0;
            let recall2 = 0;
            let recall3 = 0;
            let recall4 = 0;
            let recall5 = 0;

            let localData = confusionMatrixData1.map((item, index) => ({
                ...item,
                SrNo: index + 1,
            }));
            const sumOfRows = localData.map(row => Object.entries(row).filter(([key, value]) => key.startsWith('Value') && !isNaN(value)).map(([key, value]) => Number(value)).reduce((acc, val) => acc + val, 0));
            localData.map((item, index) => {
                if (item.Value1) {
                    recall1 = Number(localData.find((row) => row.SrNo === 1).Value1) / sumOfRows[0];
                    recall1 = recall1*100;
                    recall1 = Math.round(recall1);
                    recall1 = Math.trunc(recall1);
                }
                if (item.Value2) {
                    recall2 = Number(localData.find((row) => row.SrNo === 2).Value2) / sumOfRows[1];
                    recall2 = recall2*100;
                    recall2 = Math.round(recall2);
                    recall2 = Math.trunc(recall2);
                }
                if (item.Value3) {
                    recall3 = Number(localData.find((row) => row.SrNo === 3).Value3) / sumOfRows[2];
                    recall3 = recall3*100;
                    recall3 = Math.round(recall3);
                    recall3 = Math.trunc(recall3);
                }
                if (item.Value4) {
                    recall4 = Number(localData.find((row) => row.SrNo === 4).Value4) / sumOfRows[3];
                    recall4 = recall4*100;
                    recall4 = Math.round(recall4);
                    recall4 = Math.trunc(recall4);
                }

                if (item.Value5) {
                    recall5 = Number(localData.find((row) => row.SrNo === 5).Value5) / sumOfRows[4];
                    recall5 = recall5*100;
                    recall5 = Math.round(recall5);
                    recall5 = Math.trunc(recall5);
                }
                
            });
            this.setState(
                {
                    confusionMatrixRecallResults1: {
                        recall1,
                        recall2,
                        recall3,
                        recall4,
                        recall5
                    },
                },
                () => {
                    //console.log("calculate recall =>", this.state.confusionMatrixRecallResults1);
                }
            );
        }
    }

    calculateOverallAccuracy=()=>{
        const { confusionMatrixData1 } = this.state;
        if (confusionMatrixData1){
          
            let diagnal1 = 0;
          let diagnal2 = 0;
          let diagnal3 = 0;
          let diagnal4 = 0;
          let diagnal5 = 0;

          let localData = confusionMatrixData1.map((item, index) => ({
            ...item,
            SrNo: index + 1,
          }));
          localData.map((item, index) => {
            if (item.Value1) {
              diagnal1 = Number(localData.find((row) => row.SrNo === 1).Value1);
            }
            if (item.Value2) {
              diagnal2 = Number(localData.find((row) => row.SrNo === 2).Value2);
            }
            if (item.Value3) {
              diagnal3 = Number(localData.find((row) => row.SrNo === 3).Value3);
            }
            if (item.Value4) {
              diagnal4 = Number(localData.find((row) => row.SrNo === 4).Value4);
            }
            if (item.Value5) {
                diagnal5 = Number(localData.find((row) => row.SrNo === 5).Value5);
            }
          });
          let sumofDiagnols = 0;
          sumofDiagnols = diagnal1+diagnal2+diagnal3+diagnal4+diagnal5;
          const sumOfRows = localData.map(row => Object.entries(row).filter(([key, value]) => key.startsWith('Value') && !isNaN(value)).map(([key, value]) => Number(value)).reduce((acc, val) => acc + val, 0));
          let sum = 0;
          sumOfRows.forEach((el) => sum += el);
          let overAllAccuray = (parseInt(sumofDiagnols) / parseInt(sum)*100);
          overAllAccuray = Math.round(overAllAccuray);
          overAllAccuray = Math.trunc(overAllAccuray);
          this.setState({overAllAccuray:overAllAccuray},()=>{})
        }
      }

    calculateRecall2 = () => {
        console.log("confuion matrix data 2=>",this.state.confusionMatrixData2)
        const { confusionMatrixData2 } = this.state;
        if (confusionMatrixData2) {
            let recall1 = 0;
            let recall2 = 0;
            let recall3 = 0;
            let recall4 = 0;
            let recall5 = 0;


            let localData = confusionMatrixData2.map((item, index) => ({
                ...item,
                SrNo: index + 1,
            }));
            const sumOfRows = localData.map(row => Object.entries(row).filter(([key, value]) => key.startsWith('Value') && !isNaN(value)).map(([key, value]) => Number(value)).reduce((acc, val) => acc + val, 0));
            console.log("local data=>",localData)
            localData.map((item, index) => {
                if (item.Value1) {
                    recall1 = Number(localData.find((row) => row.SrNo === 1).Value1) / sumOfRows[0];
                    recall1 = recall1*100;
                    recall1 = Math.round(recall1);
                    recall1 = Math.trunc(recall1);
                }
                if (item.Value2) {
                    recall2 = Number(localData.find((row) => row.SrNo === 2).Value2) / sumOfRows[1];
                    recall2 = recall2*100;
                    recall2 = Math.round(recall2);
                    recall2 = Math.trunc(recall2);
                }
                if (item.Value3) {
                    recall3 = Number(localData.find((row) => row.SrNo === 3).Value3) / sumOfRows[2];
                    recall3 = recall3*100;
                    recall3 = Math.round(recall3);
                    recall3 = Math.trunc(recall3);
                }
                if (item.Value4) {
                    recall4 = Number(localData.find((row) => row.SrNo === 4).Value4) / sumOfRows[3];
                    recall4 = recall4*100;
                    recall4 = Math.round(recall4);
                    recall4 = Math.trunc(recall4);
                }

                if (item.Value5) {
                    recall5 = Number(localData.find((row) => row.SrNo === 5).Value5) / sumOfRows[4];
                    recall5 = recall5*100;
                    recall5 = Math.round(recall5);
                    recall5 = Math.trunc(recall5);
                }


            });
            this.setState(
                {
                    confusionMatrixRecallResults2: {
                        recall1,
                        recall2,
                        recall3,
                        recall4,
                        recall5
                    },
                },
                () => {
                    console.log("calculate recall for 2nd module =>", this.state.confusionMatrixRecallResults2);
                }
            );
        }
    }


    calculateOverallAccuracy1=()=>{
        const { confusionMatrixData2 } = this.state;
        if (confusionMatrixData2){
          let diagnal1 = 0;
          let diagnal2 = 0;
          let diagnal3 = 0;
          let diagnal4 = 0;
          let diagnal5 = 0;
          let localData = confusionMatrixData2.map((item, index) => ({
            ...item,
            SrNo: index + 1,
          }));
          localData.map((item, index) => {
            if (item.Value1) {
              diagnal1 = Number(localData.find((row) => row.SrNo === 1).Value1);
            }
            if (item.Value2) {
              diagnal2 = Number(localData.find((row) => row.SrNo === 2).Value2);
            }
            if (item.Value3) {
              diagnal3 = Number(localData.find((row) => row.SrNo === 3).Value3);
            }
            if (item.Value4) {
              diagnal4 = Number(localData.find((row) => row.SrNo === 4).Value4);
            }
            if (item.Value5) {
                diagnal5 = Number(localData.find((row) => row.SrNo === 5).Value5);
            }
          });
          let sumofDiagnols = 0;
          sumofDiagnols = diagnal1+diagnal2+diagnal3+diagnal4+diagnal5;
          const sumOfRows = localData.map(row => Object.entries(row).filter(([key, value]) => key.startsWith('Value') && !isNaN(value)).map(([key, value]) => Number(value)).reduce((acc, val) => acc + val, 0));
          let sum = 0;
          sumOfRows.forEach((el) => sum += el);
          let overAllAccuray = (parseInt(sumofDiagnols) / parseInt(sum)*100);
          overAllAccuray = Math.round(overAllAccuray);
          overAllAccuray = Math.trunc(overAllAccuray);
          this.setState({overAllAccuray1:overAllAccuray},()=>{})
        }
      }


    calculateRecall3 = () => {
        const { confusionMatrixData3 } = this.state;
        if (confusionMatrixData3) {
            let recall1 = 0;
            let recall2 = 0;
            let recall3 = 0;
            let recall4 = 0;
            let recall5 = 0;
            
            let localData = confusionMatrixData3.map((item, index) => ({
                ...item,
                SrNo: index + 1,
            }));
            const sumOfRows = localData.map(row => Object.entries(row).filter(([key, value]) => key.startsWith('Value') && !isNaN(value)).map(([key, value]) => Number(value)).reduce((acc, val) => acc + val, 0));
            localData.map((item, index) => {
                if (item.Value1) {
                    recall1 = Number(localData.find((row) => row.SrNo === 1).Value1) / sumOfRows[0];
                    recall1 = recall1*100;
                    recall1 = Math.round(recall1);
                    recall1 = Math.trunc(recall1);
                }
                if (item.Value2) {
                    recall2 = Number(localData.find((row) => row.SrNo === 2).Value2) / sumOfRows[1];
                    recall2 = recall2*100;
                    recall2 = Math.round(recall2);
                    recall2 = Math.trunc(recall2);
                }
                if (item.Value3) {
                    recall3 = Number(localData.find((row) => row.SrNo === 3).Value3) / sumOfRows[2];
                    recall3 = recall3*100;
                    recall3 = Math.round(recall3);
                    recall3 = Math.trunc(recall3);
                }
                if (item.Value4) {
                    recall4 = Number(localData.find((row) => row.SrNo === 4).Value4) / sumOfRows[3];
                    recall4 = recall4*100;
                    recall4 = Math.round(recall4);
                    recall4 = Math.trunc(recall4);
                }
                if (item.Value5) {
                    recall5 = Number(localData.find((row) => row.SrNo === 5).Value5) / sumOfRows[4];
                    recall5 = recall5*100;
                    recall5 = Math.round(recall5);
                    recall5 = Math.trunc(recall5);
                }
            });
            this.setState(
                {
                    confusionMatrixRecallResults3: {
                        recall1,
                        recall2,
                        recall3,
                        recall4,
                        recall5
                    },
                },
                () => { }
            );
        }
    }


    calculateOverallAccuracy2=()=>{
        const { confusionMatrixData3 } = this.state;
        if (confusionMatrixData3){
          let diagnal1 = 0;
          let diagnal2 = 0;
          let diagnal3 = 0;
          let diagnal4 = 0;
          let diagnal5 = 0;
          let localData = confusionMatrixData3.map((item, index) => ({
            ...item,
            SrNo: index + 1,
          }));
          localData.map((item, index) => {
            if (item.Value1) {
              diagnal1 = Number(localData.find((row) => row.SrNo === 1).Value1);
            }
            if (item.Value2) {
              diagnal2 = Number(localData.find((row) => row.SrNo === 2).Value2);
            }
            if (item.Value3) {
              diagnal3 = Number(localData.find((row) => row.SrNo === 3).Value3);
            }
            if (item.Value4) {
              diagnal4 = Number(localData.find((row) => row.SrNo === 4).Value4);
            }
            if (item.Value5) {
                diagnal5 = Number(localData.find((row) => row.SrNo === 5).Value5);
            }
          });
          let sumofDiagnols = 0;
          sumofDiagnols = diagnal1+diagnal2+diagnal3+diagnal4+diagnal5;
          const sumOfRows = localData.map(row => Object.entries(row).filter(([key, value]) => key.startsWith('Value') && !isNaN(value)).map(([key, value]) => Number(value)).reduce((acc, val) => acc + val, 0));
          let sum = 0;
          sumOfRows.forEach((el) => sum += el);
          let overAllAccuray = (parseInt(sumofDiagnols) / parseInt(sum)*100);
          overAllAccuray = Math.round(overAllAccuray);
          overAllAccuray = Math.trunc(overAllAccuray);
          this.setState({overAllAccuray2:overAllAccuray},()=>{})
        }
      }




    render() {
        // console.log(this.state.regressionData)
        // console.log(this.state.showRegressionTemplate)
        // console.log(this.state.displayPredictiveTemplate)
        // confusion matrix 1 render function starts here
        const confusionMatrixHeader1 = this.state.confusionMatrixData1.map((item, index) => (
            // <th style={{ height: "37px" }} key={index}>
            //     {item.Label}
            // </th>
            <th className="display_confusion_predicted_header" key={index}><span><label>Predicted -</label> {item.Label}</span></th>
        ));
        // confusion matrix 1 render function starts here

        // confusion matrix 2 render function starts here
        const confusionMatrixHeader2 = this.state.confusionMatrixData2.map((item, index) => (
            // <th style={{ height: "37px" }} key={index}>
            //     {item.Label}
            // </th>
            <th className="display_confusion_predicted_header" key={index}><span><label>Predicted -</label> {item.Label}</span></th>
        ));
        // confusion matrix 2 render function starts here



        // confusion matrix 3 render function starts here
        const confusionMatrixHeader3 = this.state.confusionMatrixData3.map((item, index) => (
            // <th style={{ height: "37px" }} key={index}>
            //     {item.Label}
            // </th>
            <th className="display_confusion_predicted_header" key={index}><span><label>Predicted -</label> {item.Label}</span></th>
        ));
        // confusion matrix 3 render function starts here

        const AnamolyColumns = Object.keys(this.state.anomolyData.reduce((acc, row) => ({ ...acc, ...row }), {}));
        const OthersColumns = Object.keys(this.state.othersData.reduce((acc, row) => ({ ...acc, ...row }), {}));
        // console.log("anamoly data =>", this.state.othersData)
        // console.log("anamoly column =>", AnamolyColumns)

        return (
            <div>
                {/* .....Descriptive Analytics template display starts here..... */}

                {
                    this.state.RecType === "DA"
                        ?
                        <>
                            <div className=" " style={{ paddingTop: "30px", marginTop: "30px", paddingRight: "30px", borderTop: "1px solid lightgrey" }}>
                                <p className="u-align-left u-text u-text-2" style={{ marginBottom: "10px" }}>Descriptive Analytics</p>
                                <div className="" style={{ textAlign: "left" }}>
                                    <div className=""  >
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="">
                                                    <div className="text-left pb-4">
                                                        <div className="row" style={{ marginTop: "10px" }}>
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-12 text-left">
                                                                        <span className="form-attribute">
                                                                            Tools Used
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <textarea
                                                                            value={this.state.toolsUsed}
                                                                            style={{ width: "100%", padding: "5px" }}
                                                                            rows={3}
                                                                            className="form-input textarea_resize_none scrollbar_width"
                                                                            placeholder='Please provide tools used'
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <div className="">
                                                    <div className="">
                                                        <div className="text-left pb-4">
                                                            <div className="row" style={{ marginTop: "10px" }}>
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-12 text-left">
                                                                            <span className="form-attribute">
                                                                                Insights Drawn
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <textarea
                                                                                value={this.state.insightsDrawn}
                                                                                style={{ width: "100%", padding: "5px" }}
                                                                                rows={3}
                                                                                className="form-input disabled_input textarea_resize_none scrollbar_width"
                                                                                placeholder="Please provide insights drawn"
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="">
                                                        <div className="text-left pb-4">
                                                            <div className="row" style={{}}>
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-12 text-left">
                                                                            <span className="form-attribute">
                                                                                Metrics Highlighted
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <textarea
                                                                                value={this.state.metricsHighlighted}
                                                                                style={{ width: "100%", padding: "5px" }}
                                                                                rows={3}
                                                                                className="form-input disabled_input textarea_resize_none scrollbar_width"
                                                                                placeholder='Please provide metrics highlighted'
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-5">
                                                    <div className="">
                                                        <div className="text-left pb-4">
                                                            <div className="row" style={{}}>
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-12 text-left">
                                                                            <span className="form-attribute">
                                                                                Notes / Comments
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <textarea
                                                                                style={{ width: "100%", padding: "5px" }}
                                                                                rows={3}
                                                                                value={this.state.notesOrComments}
                                                                                className="form-input disabled_input textarea_resize_none scrollbar_width"
                                                                                disabled
                                                                                placeholder='Please provide notes or comments'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="">
                                                    <div className="text-left pb-4">
                                                        <div className="row" style={{}}>
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-9 gol_field_div text-left">
                                                                        <span className="form-attribute">
                                                                            Are there any additional solutions deployed as part of this project ?
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <input
                                                                            value={this.state.additionalSolutionsDeployed ? "Yes" : "No"}
                                                                            placeholder="Please choose"
                                                                            disabled
                                                                            className='form-input disabled_input'
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {
                                                                this.state.additionalSolutionsDeployed
                                                                    ?
                                                                    <>
                                                                        <div className="" >
                                                                            <div className="text-left pb-4">
                                                                                <div className="row" style={{ marginTop: "20px" }}>
                                                                                    <div className="col-md-12">
                                                                                        <div className="row">
                                                                                            <div className="col-md-12 text-left">
                                                                                                <span className="form-attribute">
                                                                                                    Additional solutions deployed
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="col-md-12">
                                                                                                <textarea
                                                                                                    value={this.state.additionalSolutionsDeployed}
                                                                                                    className="form-input disabled_input textarea_resize_none scrollbar_width"
                                                                                                    placeholder='Please provide additional solutions deployed'
                                                                                                    disabled
                                                                                                    style={{ width: "100%", padding: "5px" }}
                                                                                                    rows={3}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">

                                                
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                        :
                        null

                }


                {/* .....Descriptive Analytics template display ends here..... */}


                {/* ....Descriptive or prescriptive template display starts here.... */}
                {
                    this.state.DPA.length > 0
                        ?
                        <>
                            <div className="" style={{ borderTop: "1px solid lightgray", paddingTop: "40px", marginTop: "20px", paddingRight: "30px" }}>
                                <p className="u-align-left u-text u-text-2">{this.state.ideaItem.DBProjectType ? this.state.ideaItem.DBProjectType :""} Analytics</p>
                                <div className="" style={{ margin: "20px 0px 20px 0px" }}>
                                    <table className="table review-idea-table display_template_table">
                                        <thead className="view-idea-thead">
                                            <tr>
                                                <th className="dynamic_form_sl_no_th" style={{maxWidth: "8%"}}>Sr No</th>
                                                <th>Problem</th>
                                                <th>Technique</th>
                                                <th>Root Cause</th>
                                                <th>Recommendations</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DPA && this.state.DPA.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="dynamic_form_sl_no"><label>{index + 1}</label></td>
                                                            <td className='overflow_td_alignment over_flow_width_alignment'><label data-toggle="tooltip" title={item.Problem}>{item.Problem}</label></td>
                                                            <td className='overflow_td_alignment over_flow_width_alignment'><label data-toggle="tooltip" title={item.Technique}>{item.Technique}</label></td>
                                                            <td className='overflow_td_alignment over_flow_width_alignment'><label data-toggle="tooltip" title={item.Cause}>{item.Cause}</label></td>
                                                            <td className='overflow_td_alignment over_flow_width_alignment'><label data-toggle="tooltip" title={item.Recomm}>{item.Recomm}</label></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="">
                                    <div className="">
                                        <div className="" style={{ marginBottom: "20px" }}>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="row">
                                                        <div className="col-md-12 gol_field_div text-left">
                                                            <span className="form-attribute">
                                                                Key Insights Drawn
                                                            </span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <textarea
                                                                value={this.state.insightsDrawn}
                                                                className="form-input disabled_input discriptive_textarea"
                                                                placeholder='Please provide metrics highlighted'
                                                                disabled
                                                                style={{ width: "100%", padding: "5px" }}
                                                                rows={3}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-5">
                                                    <div className="row">
                                                        <div className="col-md-7 gol_field_div text-left">
                                                            <span className="form-attribute">
                                                                Are there any additional solutions deployed as part of this project?
                                                            </span>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <input
                                                                value={this.state.additionalSolutionsDeployed ? "Yes" : "No"}
                                                                className="form-input disabled_input"
                                                                placeholder='Please provide metrics highlighted'
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>


                                                    {
                                                        this.state.additionalSolutionsDeployed
                                                            ?
                                                            <>
                                                                <div className="">
                                                                    <div className="text-left pb-4">
                                                                        <div className="row" style={{ marginTop: "10px" }}>
                                                                            <div className="col-md-12">
                                                                                <div className="row">
                                                                                    <div className="col-md-12 gol_field_div text-left">
                                                                                        <span className="form-attribute">
                                                                                            Additional solutions deployed
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <textarea
                                                                                            value={this.state.additionalSolutionsDeployed}
                                                                                            className="form-input disabled_input discriptive_textarea"
                                                                                            placeholder='Please provide additional solutions deployed'
                                                                                            disabled
                                                                                            rows={3}
                                                                                            style={{ width: "100%", padding: "5px" }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                        

                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        null
                }




                {/* ..... descriptive or prescriptive template display ends here..... */}


                {/* ....predective template starts here...... */}

                


                <div className="">
                    <div className="" style={{ textAlign: "left" }}>
                        <div className="">
                            <div className="" style={{paddingRight:"30px"}}>
                                {
                                    this.state.displayPredictiveTemplate
                                    ?
                                    <>
                                        <div style={{borderTop: "1px solid lightgrey", paddingTop: "40px", textAlign: "left", paddingRight: "30px", marginTop: "20px"}} ></div>
                                        <p className="u-align-left u-text u-text-2">Predictive Analytics</p>
                                        <div className="">
                                            <div className="predictive_template_checkbox_block" style={{ margin: "20px 0px" }}>
                                                <label>
                                                    Please choose category
                                                </label>
                                                <ul>
                                                    <li>
                                                        <label htmlFor="classificationCheckbox">
                                                            <input
                                                                id="classificationCheckbox"
                                                                type="checkbox"
                                                                checked={this.state.classificationModule.length > 0 ? true : false}
                                                                readOnly={true}
                                                            />
                                                            Classification
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label htmlFor="regressionCheckbox">
                                                            <input
                                                                id="regressionCheckbox"
                                                                type="checkbox"
                                                                checked={this.state.regressionData.length ? true : false}
                                                                readOnly={true}
                                                            />
                                                            Regression
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label htmlFor="forecastcheckbox">
                                                            <input
                                                                id="forecastcheckbox"
                                                                type="checkbox"
                                                                checked={this.state.forecastData.length ? true : false}
                                                                readOnly={true}
                                                            />
                                                            Forecast
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label htmlFor="sentimentValuescheckbox">
                                                            <input
                                                                id="sentimentValuescheckbox"
                                                                type="checkbox"
                                                                checked={this.state.showSentimentTemplate ? true : false}
                                                                readOnly={true}
                                                            />
                                                            Sentiment&nbsp;Analysis
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label htmlFor="anamolycheckbox">
                                                            <input
                                                                id="anamolycheckbox"
                                                                type="checkbox"
                                                                checked={this.state.showAnamolyTemplate ? true : false}
                                                                readOnly={true}
                                                            />
                                                            Anamoly
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label htmlFor="othersCheckbox">
                                                            <input
                                                                id="othersCheckbox"
                                                                type="checkbox"
                                                                checked={this.state.showOthersTemplate ? true : false}
                                                                readOnly={true}
                                                            />
                                                            Others
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                

                                {/* classification module 0 template starts here */}

                                {this.state.classificationModule.length > 0 ? (
                                    <>
                                        <div className="" style={{ marginTop: '20px' }}>
                                            <p className="u-align-left u-text u-text-2" style={{ margin: "20px 0px" }}>Classification Module 1</p>
                                        </div>

                                        <div className="">
                                            <div
                                                className="classification_module_multi_select"
                                                style={{
                                                    paddingBottom: "20px",
                                                    marginBottom:"20px",
                                                    borderBottom:"1px solid lightgrey",
                                                    fontSize:"14px"
                                                }}
                                            >
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label className="form-attribute">
                                                            Please Select Model
                                                        </label>
                                                        <Select
                                                            isMulti={true}
                                                            style={{fontSize:"14px"}}
                                                            isDisabled={true}
                                                            value={this.state.classificationModelSelected}
                                                            placeholder="Please choose Model"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="">
                                            <table className="table review-idea-table display_template_table">
                                                <thead className="view-idea-thead">
                                                    <tr>
                                                        <th className="dynamic_form_sl_no_th">Sr No</th>
                                                        <th>Variables</th>
                                                        <th>Type</th>
                                                        <th>Role</th>
                                                        <th>Importance Score</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.classificationModule &&
                                                        this.state.classificationModule.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td
                                                                        style={{ width: "4%" }}
                                                                        className="dynamic_form_sl_no"
                                                                    >
                                                                        <label>{index + 1}</label>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            width: "30%",
                                                                            breakWord: "break-word",
                                                                        }}
                                                                    >
                                                                        <label>{item.Feature}</label>
                                                                    </td>
                                                                    <td style={{ width: "20%" }}>
                                                                        <label>{item.Type ? item.Type.label : item.Type}</label>
                                                                    </td>
                                                                    <td style={{ width: "20%" }}>
                                                                        <label>{item.Role ? item.Role.label : item.Role}</label>
                                                                    </td>
                                                                    <td style={{ width: "10%" }}>
                                                                        <label>{item.Score}</label>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>

                                        {/* ...confusion matrix 1 starts here.... */}
                                        <div className="" style={{ paddingTop: "20px" }}>
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: "10px" }}>Confusion Matrix 1</p>
                                            <div className="">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label className="form-attribute">
                                                            Please select Metric type
                                                        </label>
                                                        <input
                                                            disabled
                                                            className="form-input disabled_input"
                                                            value={this.state.confusionMatrixTableSelectedOption1.label}
                                                            placeholder="Please confusion matrix number"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="" style={{ marginTop: "15px" }}>
                                                    {this.state.confusionMatrixData1.length > 0 && (
                                                        <table className="table review-idea-table confusion_matrix_display_table">
                                                            <thead className="view-idea-thead">
                                                                <tr>
                                                                    <th></th>
                                                                    {confusionMatrixHeader1}
                                                                    <th>Recall %</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.confusionMatrixData1.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className="actual_label_td">
                                                                            <label>Actual - </label>&nbsp;
                                                                            <input
                                                                                value={item.Label}
                                                                                disabled
                                                                                type="text"
                                                                                className="form-input"
                                                                                placeholder="Please enter row label"
                                                                            />
                                                                        </td>
                                                                        {confusionMatrixHeader1.map((header, headerIndex) => (
                                                                            <td key={headerIndex}>
                                                                                <input
                                                                                    value={this.handleConfusionMatrixGetCellData(index, headerIndex)}
                                                                                    className="form-input hide_numeric_input_arrows"
                                                                                    type="number"
                                                                                    min="0"
                                                                                    disabled
                                                                                />
                                                                            </td>

                                                                        ))}
                                                                        <td style={{ display: "flex", alignItems: "center", marginTop: "10px", justifyContent: "center" }}>{this.getRecallValues1(index)}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                            <tfoot>{this.confusionMatrixPrecisionRow1()}</tfoot>
                                                        </table>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="" style={{marginTop:"30px", marginBottom:"20px"}}>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <div className="">
                                                            <div className="row d_flex_align_items_center">
                                                                <div className="col-md-5">
                                                                <label className="form-attribute">Overall Accuracy</label>
                                                                </div>
                                                                <div className="col-md-5">
                                                                <input type="text" value={this.state.overAllAccuray + " %"} className="form-input disabled_input" disabled={true} />
                                                                </div> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* ...confusion matrix 1 ends here..... */}
                                    </>
                                ) : null}

                                {/* classification module 0 template ends here */}

                                {/* classification module 1 template starts here */}

                                {this.state.classificationModule1.length > 0 ? (
                                    <>
                                        <div className="" style={{ borderTop: "1px solid lightgrey", paddingTop: "40px" }}>
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: "20px" }}>Classification Module 2</p>
                                        </div>
                                        <div className="">
                                            <div className="">
                                                <div
                                                    className="classification_module_multi_select"
                                                    style={{
                                                        paddingBottom: "20px",
                                                        marginBottom:"20px",
                                                        borderBottom:"1px solid lightgrey",
                                                        fontSize:"14px"
                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label className="form-attribute">
                                                                Please Select Model
                                                            </label>
                                                            <Select
                                                                isMulti={true}
                                                                style={{fontSize:"14px"}}
                                                                isDisabled={true}
                                                                value={this.state.classificationModelSelected1}
                                                                placeholder="Please choose Model"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <table className="table review-idea-table display_template_table">
                                                <thead className="view-idea-thead">
                                                    <tr>
                                                        <th className="dynamic_form_sl_no_th">Sr No</th>
                                                        <th>Variables</th>
                                                        <th>Type</th>
                                                        <th>Role</th>
                                                        <th>Importance Score</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.classificationModule1 &&
                                                        this.state.classificationModule1.map(
                                                            (item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td
                                                                            style={{ width: "4%" }}
                                                                            className="dynamic_form_sl_no"
                                                                        >
                                                                            <label>{index + 1}</label>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: "30%",
                                                                                breakWord: "break-word",
                                                                            }}
                                                                        >
                                                                            <label>{item.Feature}</label>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: "20%",
                                                                                breakWord: "break-word",
                                                                            }}
                                                                        >
                                                                            <label>{item.Type ? item.Type.label : item.Type}</label>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: "20%",
                                                                                breakWord: "break-word",
                                                                            }}
                                                                        >
                                                                            <label>{item.Role ? item.Role.label : item.Role}</label>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: "10%",
                                                                                breakWord: "break-word",
                                                                            }}
                                                                        >
                                                                            <label>{item.Score}</label>
                                                                        </td>

                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>

                                        {/* .... confusion matrix 2 starts here..... */}
                                        <div className="" style={{ paddingTop: "20px" }}>
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: "10px" }}>Confusion Matrix 2</p>
                                            <div className="">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label className="form-attribute">
                                                            Please select Metric type
                                                        </label>
                                                        <input
                                                            disabled
                                                            value={this.state.confusionMatrixTableSelectedOption2.label}
                                                            placeholder="Please confusion matrix number"
                                                            className="form-input"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="" style={{ marginTop: "15px" }}>
                                                    {this.state.confusionMatrixData2.length > 0 && (
                                                        <table className="table review-idea-table confusion_matrix_display_table">
                                                            <thead className="view-idea-thead">
                                                                <tr>
                                                                    <th></th>
                                                                    {confusionMatrixHeader2}
                                                                    <th>Recall %</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.confusionMatrixData2.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className="actual_label_td">
                                                                            <label>Actual - </label>&nbsp;
                                                                            <input
                                                                                value={item.Label}
                                                                                type="text"
                                                                                disabled
                                                                                className="form-input"
                                                                                placeholder="Please enter row label"
                                                                            />
                                                                        </td>
                                                                        {confusionMatrixHeader2.map((header, headerIndex) => (
                                                                            <td key={headerIndex}>
                                                                                <input
                                                                                    value={this.handleSecondConfusionMatrixGetCellData(index, headerIndex)}
                                                                                    className="form-input hide_numeric_input_arrows"
                                                                                    type="number"
                                                                                    min="0"
                                                                                    disabled
                                                                                />
                                                                            </td>
                                                                        ))}
                                                                        <td style={{ display: "flex", alignItems: "center", marginTop: "10px", justifyContent: "center" }}>{this.getRecallValues2(index)}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                            <tfoot>{this.confusionMatrixPrecisionRow2()}</tfoot>
                                                        </table>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="" style={{marginTop:"30px"}}>
                                                <div className="row">
                                                <div className="col-md-5">
                                                    <div className="">
                                                    <div className="row d_flex_align_items_center">
                                                        <div className="col-md-5">
                                                        <label className="form-attribute">Overall Accuracy</label>
                                                        </div>
                                                        <div className="col-md-5">
                                                        <input type="text" value={this.state.overAllAccuray1 + " %"} className="form-input disabled_input" disabled={true} />
                                                        </div> 
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* .... confusion matrix 2 ends here */}
                                    </>
                                ) : null}

                                {/* classification module 1 template ends here */}

                                {/* classification module 2 template starts here */}

                                {this.state.classificationModule2.length > 0 ? (
                                    <>
                                        <div className="" style={{ borderTop: "1px solid lightgrey", paddingTop: "30px", marginTop: "30px" }}>
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: "20px" }}>Classification Module 3</p>
                                        </div>


                                        <div className="">
                                                <div
                                                    className="classification_module_multi_select"
                                                    style={{
                                                        paddingBottom: "20px",
                                                        marginBottom:"20px",
                                                        borderBottom:"1px solid lightgrey",
                                                        fontSize:"14px"
                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label className="form-attribute">
                                                                Please Select Model
                                                            </label>
                                                            <Select
                                                                isMulti={true}
                                                                style={{fontSize:"14px"}}
                                                                isDisabled={true}
                                                                value={this.state.classificationModelSelected2}
                                                                placeholder="Please choose Model"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        <div className="">
                                            <table className="table review-idea-table display_template_table">
                                                <thead className="view-idea-thead ">
                                                    <tr>
                                                        <th className="dynamic_form_sl_no_th">Sr No</th>
                                                        <th>Variables</th>
                                                        <th>Type</th>
                                                        <th>Role</th>
                                                        <th>Score</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.classificationModule2 &&
                                                        this.state.classificationModule2.map(
                                                            (item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td
                                                                            style={{ width: "4%" }}
                                                                            className="dynamic_form_sl_no"
                                                                        >
                                                                            <label>{index + 1}</label>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: "30%",
                                                                                breakWord: "break-word",
                                                                            }}
                                                                        >
                                                                            <label>{item.Feature}</label>
                                                                        </td>
                                                                        <td style={{ width: "20%" }}>
                                                                            <label>{item.Type ? item.Type.label : item.Type}</label>
                                                                        </td>
                                                                        <td style={{ width: "20%" }}>
                                                                            <label>{item.Role ? item.Role.label : item.Role}</label>
                                                                        </td>
                                                                        <td style={{ width: "10%" }}>
                                                                            <label>{item.Score}</label>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="">
                                            <div className="" style={{ paddingTop: "20px", marginTop: "20px" }} />
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: "10px" }}>Confusion Matrix 3</p>
                                            <div className="">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label className="form-attribute">
                                                            Please select Metric type
                                                        </label>
                                                        <input
                                                            disabled
                                                            className="form-input disabled_input"
                                                            value={this.state.confusionMatrixTableSelectedOption3.label}
                                                            placeholder="Please confusion matrix number"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="" style={{ marginTop: "15px" }}>
                                                    {this.state.confusionMatrixData3.length > 0 && (
                                                        <table className="table review-idea-table confusion_matrix_display_table">
                                                            <thead className="view-idea-thead">
                                                                <tr>
                                                                    <th></th>
                                                                    {confusionMatrixHeader3}
                                                                    <th>Recall %</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.confusionMatrixData3.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className="actual_label_td">
                                                                            <label>Actual - </label>&nbsp;
                                                                            <input
                                                                                value={item.Label}
                                                                                disabled
                                                                                type="text"
                                                                                className="form-input"
                                                                                placeholder="Please enter row label"
                                                                            />
                                                                        </td>
                                                                        {confusionMatrixHeader3.map((header, headerIndex) => (
                                                                            <td key={headerIndex}>
                                                                                <input
                                                                                    value={this.handleConfusionMatrixGetCellData2(index, headerIndex)}
                                                                                    disabled
                                                                                    className="form-input hide_numeric_input_arrows"
                                                                                    type="number"
                                                                                    min="0"
                                                                                />
                                                                            </td>
                                                                        ))}
                                                                        <td style={{ display: "flex", alignItems: "center", marginTop: "10px", justifyContent: "center" }}>{this.getRecallValues3(index)}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                            <tfoot>{this.confusionMatrixPrecisionRow3()}</tfoot>
                                                        </table>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="" style={{marginTop:"30px"}}>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                    <div className="">
                                                        <div className="row d_flex_align_items_center">
                                                        <div className="col-md-5">
                                                            <label className="form-attribute">Overall Accuracy</label>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <input type="text" value={this.state.overAllAccuray2 + " %"} className="form-input disabled_input" disabled={true} />
                                                        </div> 
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                ) : null}

                                {/* classification module 2 template ends here */}

                                {/* regression template starts here */}
                                {this.state.regressionData.length > 0 ? (
                                    <>
                                        <div className="" style={{ paddingTop: "40px", borderTop: "1px solid lightgrey", marginTop: "30px" }}>
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: "20px" }}>Regression</p>

                                        </div>


                                        <div className="">
                                            <table className="table review-idea-table display_template_table">
                                                <thead className="view-idea-thead">
                                                    <tr>
                                                        <th className="dynamic_form_sl_no_th">Sr No</th>
                                                        <th>Variables</th>
                                                        <th>Type</th>
                                                        <th>Role</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.regressionData.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td
                                                                    style={{ width: "4%" }}
                                                                    className="dynamic_form_sl_no"
                                                                >
                                                                    <label>{index + 1}</label>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: "30%",
                                                                        breakWord: "break-word",
                                                                    }}
                                                                >
                                                                    <label>{item.Feature}</label>
                                                                </td>
                                                                <td style={{ width: "20%" }}>
                                                                    <label>{item.Type ? item.Type.label : item.Type}</label>
                                                                </td>
                                                                <td style={{ width: "20%" }}>
                                                                    <label>{item.Role ? item.Role.label : item.Role}</label>
                                                                </td>

                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="">
                                            <div
                                                className=""
                                                style={{
                                                    marginTop: "20px",
                                                }}
                                            >

                                                <div className="">
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <div className="" style={{ marginBottom: "20px" }}>
                                                                <div className="row d_flex_align_items_center">
                                                                    <div className="col-md-7">
                                                                        <div className="">
                                                                            <label className="form-attribute">
                                                                                Mean
                                                                                Absolute Error (MAE)
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div className="">
                                                                            <input
                                                                                placeholder="Please provide Mean Absolute Error"
                                                                                className="form-input hide_numeric_input_arrows"
                                                                                type="number"
                                                                                min="0"
                                                                                value={this.state.regressionMeanAbsoluteError}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="" style={{ marginBottom: "20px" }}>
                                                                <div className="row d_flex_align_items_center">
                                                                    <div className="col-md-7">
                                                                        <div className="">
                                                                            <label className="form-attribute">
                                                                                Root Mean
                                                                                Squared Error (RMSE)
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div className="">
                                                                            <input
                                                                                placeholder="Please provide Root Mean Squared Error"
                                                                                className="form-input hide_numeric_input_arrows"
                                                                                type="number"
                                                                                min="0"
                                                                                value={this.state.regressionRootMeanSquaredError}
                                                                                disabled
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                {this.state.regressionMetricesOne ? (
                                                                    <div className="row d_flex_align_items_center" style={{ marginBottom: "20px" }}>
                                                                        <div className="col-md-7">
                                                                            <div className="">
                                                                                <input
                                                                                    placeholder="Please fill label value for Metrics 1"
                                                                                    className="form-input label_input"
                                                                                    type="text"
                                                                                    value={this.state.regressionMetricesOneLabel}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="">
                                                                                <input
                                                                                    className="form-input hide_numeric_input_arrows"
                                                                                    type="number"
                                                                                    min="0"
                                                                                    value={this.state.regressionMetricesOneInput}
                                                                                    disabled
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                            <div className="">
                                                                {this.state.regressionMetricesThree ? (
                                                                    <div className="row d_flex_align_items_center" style={{ marginBottom: "20px" }}>
                                                                        <div className="col-md-7">
                                                                            <div className="">
                                                                                <input
                                                                                    placeholder="Please fill label value for Metrics 1"
                                                                                    className="form-input label_input"
                                                                                    type="text"
                                                                                    value={this.state.regressionMetricesThreeLabel}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="">
                                                                                <input
                                                                                    placeholder="Please fill input value for Metrics 1"
                                                                                    className="form-input hide_numeric_input_arrows"
                                                                                    type="number"
                                                                                    min="0"
                                                                                    value={this.state.regressionMetricesThreeInput}
                                                                                    disabled
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                            <div className="">
                                                                <div className="row d_flex_align_items_center">
                                                                    {/* {this.state.regressionMetricesFive ? (
                                                                        <div className="" style={{ marginBottom: "20px" }}>
                                                                            <div className="col-md-7">
                                                                                <div className="">
                                                                                    <input
                                                                                        placeholder="Please fill label value for Metrics 1"
                                                                                        className="form-input label_input"
                                                                                        type="text"
                                                                                        value={this.state.regressionMetricesFiveLabel}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <div className="">
                                                                                    <input
                                                                                        placeholder="Please fill input value for Metrics 1"
                                                                                        className="form-input hide_numeric_input_arrows"
                                                                                        type="number"
                                                                                        min="0"
                                                                                        value={this.state.regressionMetricesFiveInput}
                                                                                        disabled
                                                                                    />
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    ) : null} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-5">
                                                            <div className="" style={{ marginBottom: "20px" }}>
                                                                <div className="row d_flex_align_items_center">
                                                                    <div className="col-md-7">
                                                                        <div className="">
                                                                            <label className="form-attribute">
                                                                                Mean
                                                                                Squared Error (MSE)
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div className="">
                                                                            <input
                                                                                placeholder="Please provide Mean Squared Error"
                                                                                className="form-input hide_numeric_input_arrows"
                                                                                type="number"
                                                                                min="0"
                                                                                value={this.state.regressionMeanSquaredError}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="" style={{ marginBottom: "20px" }}>
                                                                <div className="row d_flex_align_items_center">
                                                                    <div className="col-md-7">
                                                                        <div className="">
                                                                            <label className="form-attribute">
                                                                                Mean Absolute Percentage Error (MAPE)
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div className="">
                                                                            <input
                                                                                placeholder="Please provide Mean Squared Error"
                                                                                className="form-input hide_numeric_input_arrows"
                                                                                type="number"
                                                                                min="0"
                                                                                value={this.state.regressionMeanAbsolutePercentageError}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                {this.state.regressionMetricesTwo ? (
                                                                    <div className="row d_flex_align_items_center" style={{ marginBottom: "20px" }}>
                                                                        <div className="col-md-7">
                                                                            <div className="">
                                                                                <input
                                                                                    placeholder="Please fill label value for Metrics 1"
                                                                                    className="form-input label_input"
                                                                                    type="text"
                                                                                    value={this.state.regressionMetricesTwoLabel}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="">
                                                                                <input
                                                                                    placeholder="Please fill input value for Metrics 1"
                                                                                    className="form-input hide_numeric_input_arrows"
                                                                                    type="number"
                                                                                    min="0"
                                                                                    value={this.state.regressionMetricesTwoInput}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                            <div className="">
                                                                {this.state.regressionMetricesFour ? (
                                                                    <div className="row d_flex_align_items_center" style={{ marginBottom: "20px" }}>
                                                                        <div className="col-md-7">
                                                                            <div className="">
                                                                                <input
                                                                                    placeholder="Please fill label value for Metrics 1"
                                                                                    className="form-input label_input"
                                                                                    type="text"
                                                                                    value={this.state.regressionMetricesFourLabel}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <div className="">
                                                                                <input
                                                                                    placeholder="Please fill input value for Metrics 1"
                                                                                    className="form-input hide_numeric_input_arrows"
                                                                                    type="number"
                                                                                    min="0"
                                                                                    value={this.state.regressionMetricesFourInput}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </>
                                ) : null}
                                {/* regression template ends here */}

                                {/* forecast template starts here */}
                                {this.state.forecastData.length > 0 ? (
                                    <>
                                        <div className="" style={{ paddingTop: "40px", borderTop: "1px solid lightgrey", marginTop: "30px" }}>
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: "20px" }}>Forecast</p>
                                        </div>


                                        <div className="">
                                            <table className="table review-idea-table display_template_table">
                                                <thead className="view-idea-thead">
                                                    <tr>
                                                        <th className="dynamic_form_sl_no_th">Sr No</th>
                                                        <th>Variables</th>
                                                        <th>Type</th>
                                                        <th>Role</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.forecastData.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td
                                                                    style={{ width: "4%" }}
                                                                    className="dynamic_form_sl_no"
                                                                >
                                                                    <label>{index + 1}</label>
                                                                </td>
                                                                <td style={{ width: "30%" }}>
                                                                    <label>{item.Feature}</label>
                                                                </td>
                                                                <td style={{ width: "10%" }}>
                                                                    <label>{item.Type ? item.Type.label : item.Type}</label>
                                                                </td>
                                                                <td style={{ width: "10%" }}>
                                                                    <label>{item.Role ? item.Role.label : item.Role}</label>
                                                                </td>

                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>





                                        <div className="">
                                            <div
                                                className=""
                                                style={{
                                                    marginTop: "20px",
                                                }}
                                            >

                                                <div className="">
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <div className="" style={{ marginBottom: "20px" }}>
                                                                <div className="row d_flex_align_items_center">
                                                                    <div className="col-md-7">
                                                                        <div className="">
                                                                            <label className="form-attribute">
                                                                                Mean
                                                                                Absolute Error (MAE)
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div className="">
                                                                            <input
                                                                                placeholder="Please provide Mean Absolute Error"
                                                                                className="form-input hide_numeric_input_arrows"
                                                                                type="number"
                                                                                min="0"
                                                                                value={this.state.forecastMeanAbsoluteError}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="" style={{ marginBottom: "20px" }}>
                                                                <div className="row d_flex_align_items_center">
                                                                    <div className="col-md-7">
                                                                        <div className="">
                                                                            <label className="form-attribute">
                                                                                Root Mean
                                                                                Squared Error (RMSE)
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div className="">
                                                                            <input
                                                                                placeholder="Please provide Root Mean Squared Error"
                                                                                className="form-input hide_numeric_input_arrows"
                                                                                type="number"
                                                                                min="0"
                                                                                value={this.state.forecastRootMeanSquaredError}

                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                    {this.state.forecastMetricesOne ? (
                                                                        <div className="row d_flex_align_items_center" style={{ marginBottom: "20px" }}>
                                                                            <div className="col-md-7">
                                                                                <div className="">
                                                                                    <input
                                                                                        placeholder="Please fill label value for Metrics 1"
                                                                                        className="form-input label_input"
                                                                                        type="text"
                                                                                        value={this.state.forecastMetricesOneLabel}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <div className="">
                                                                                    <input
                                                                                        placeholder="Please fill input value for Metrics 1"
                                                                                        className="form-input hide_numeric_input_arrows"
                                                                                        type="number"
                                                                                        min="0"
                                                                                        value={this.state.forecastMetricesOneInput}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="">
                                                                    {this.state.forecastMetricesThree ? (
                                                                        <div className="row d_flex_align_items_center" style={{ marginBottom: "20px" }}>
                                                                            <div className="col-md-7">
                                                                                <div className="">
                                                                                    <input
                                                                                        placeholder="Please fill label value for Metrics 1"
                                                                                        className="form-input label_input"
                                                                                        type="text"
                                                                                        value={this.state.forecastMetricesThreeLabel}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <div className="">
                                                                                    <input
                                                                                        placeholder="Please fill input value for Metrics 1"
                                                                                        className="form-input hide_numeric_input_arrows"
                                                                                        type="number"
                                                                                        min="0"
                                                                                        value={this.state.forecastMetricesThreeInput}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            {/* <div className="">
                                                                <div className="row d_flex_align_items_center">
                                                                    {this.state.regressionMetricesFive ? (
                                                                        <div className="" style={{ marginBottom: "20px" }}>
                                                                            <div className="col-md-7">
                                                                                <div className="">
                                                                                    <input
                                                                                        placeholder="Please fill label value for Metrics 1"
                                                                                        className="form-input label_input"
                                                                                        type="text"
                                                                                        value={this.state.regressionMetricesFiveLabel}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <div className="">
                                                                                    <input
                                                                                        placeholder="Please fill input value for Metrics 1"
                                                                                        className="form-input hide_numeric_input_arrows"
                                                                                        type="number"
                                                                                        min="0"
                                                                                        value={this.state.regressionMetricesFiveInput}
                                                                                        disabled
                                                                                    />
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-5">
                                                            <div className="" style={{ marginBottom: "20px" }}>
                                                                <div className="row d_flex_align_items_center">
                                                                    <div className="col-md-7">
                                                                        <div className="">
                                                                            <label className="form-attribute">
                                                                                Mean
                                                                                Squared Error (MSE)
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div className="">
                                                                            <input
                                                                                placeholder="Please provide Mean Squared Error"
                                                                                className="form-input hide_numeric_input_arrows"
                                                                                type="number"
                                                                                min="0"
                                                                                value={this.state.forecastMeanSquaredError}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="" style={{ marginBottom: "20px" }}>
                                                                <div className="row d_flex_align_items_center">
                                                                    <div className="col-md-7">
                                                                        <div className="">
                                                                            <label className="form-attribute">
                                                                                Mean
                                                                                Absolute Percentage Error (MAPE)
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div className="">
                                                                            <input
                                                                                placeholder="Please provide Mean Squared Error"
                                                                                className="form-input hide_numeric_input_arrows"
                                                                                type="number"
                                                                                min="0"
                                                                                value={this.state.forecastMeanAbsolutePercentageError}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                    {this.state.forecastMetricesTwo ? (
                                                                        <div className="row d_flex_align_items_center" style={{ marginBottom: "20px" }}>
                                                                            <div className="col-md-7">
                                                                                <div className="">
                                                                                    <input
                                                                                        placeholder="Please fill label value for Metrics 1"
                                                                                        className="form-input label_input"
                                                                                        type="text"
                                                                                        value={this.state.forecastMetricesTwoLabel}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <div className="">
                                                                                    <input
                                                                                        placeholder="Please fill input value for Metrics 1"
                                                                                        className="form-input hide_numeric_input_arrows"
                                                                                        type="number"
                                                                                        min="0"
                                                                                        value={this.state.forecastMetricesTwoInput}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="">
                                                                    {this.state.forecastMetricesFour ? (
                                                                        <div className="row d_flex_align_items_center" style={{ marginBottom: "20px" }}>
                                                                            <div className="col-md-7">
                                                                                <div className="">
                                                                                    <input
                                                                                        placeholder="Please fill label value for Metrics 1"
                                                                                        className="form-input label_input"
                                                                                        type="text"
                                                                                        value={this.state.forecastMetricesFourLabel}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <div className="">
                                                                                    <input
                                                                                        placeholder="Please fill input value for Metrics 1"
                                                                                        className="form-input hide_numeric_input_arrows"
                                                                                        type="number"
                                                                                        min="0"
                                                                                        value={this.state.forecastMetricesFourInput}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                {/* forecast template ends here */}

                                {/* Sentiment analysis calculation table starts here */}

                                {this.state.showSentimentTemplate ? (
                                    <>
                                        <div className="">
                                            <div className="sentiment_analysis_block" style={{ paddingTop: "40px", borderTop: "1px solid lightgrey", marginTop: "30px" }}>
                                                <p className="u-align-left u-text u-text-2" style={{ marginBottom: "20px" }}>Sentiment Analysis</p>
                                                <div className="edit_row_gap_between_rows">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label className="form-attribute">
                                                                Good to Have - Checks
                                                            </label>
                                                            <input
                                                                value={this.state.sentimentValues.goodToHave}
                                                                className="form-input hide_arrow_keys"
                                                                type="number"
                                                                name="goodToHave"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-attribute">
                                                                Mixed Sentiments
                                                            </label>
                                                            <input
                                                                value={this.state.sentimentValues.mixedSentiments}
                                                                className="form-input"
                                                                type="number"
                                                                name="mixedSentiments"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-attribute">
                                                                No checks needed
                                                            </label>
                                                            <input
                                                                value={this.state.sentimentValues.noChecksNeeded}
                                                                className="form-input"
                                                                type="number"
                                                                name="noChecksNeeded"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="edit_row_gap_between_rows">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label className="form-attribute">
                                                                Priority checks
                                                            </label>
                                                            <input
                                                                value={this.state.sentimentValues.priorityChecks}
                                                                className="form-input form-attribute"
                                                                type="number"
                                                                name="priorityChecks"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-attribute">
                                                                Duplicates
                                                            </label>
                                                            <input
                                                                value={this.state.sentimentValues.sentimentDuplicates}
                                                                className="form-input "
                                                                type="number"
                                                                name="sentimentDuplicates"
                                                                disabled

                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-attribute">Total</label>
                                                            <input
                                                                placeholder="Total"
                                                                className="form-input"
                                                                type="number"
                                                                disabled={true}
                                                                min="0"
                                                                value={this.state.sentimentTotal}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                ) : null}

                                {/* Sentiment analysis calculation table ends here */}

                                {/* Anamoly Template starts here */}
                                {this.state.showAnamolyTemplate ? (
                                    <>
                                        <div className="" style={{ paddingTop: "40px", borderTop: "1px solid lightgrey", marginTop: "30px" }}>
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: '10px' }}>Anamoly</p>
                                            <div className="">
                                                <table className="table review-idea-table table-bordered">
                                                    <tbody>
                                                        {this.state.anomolyData.map((row, rowIndex) => (
                                                            <tr key={rowIndex}>
                                                                {AnamolyColumns.map((column, columnIndex) => (
                                                                    <td key={columnIndex}>
                                                                        <textarea
                                                                            className="form-attribute anamoly_textarea textarea_resize_none"
                                                                            type="text"
                                                                            value={row[column] || ""}
                                                                            disabled
                                                                        />
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>


                                    </>
                                ) : null}
                                {/* Anamoly Template ends here */}

                                {/* Others Template starts here */}
                                {OthersColumns.length > 0 ? (
                                    <>
                                        <div className="" style={{ paddingTop: "40px", borderTop: "1px solid lightgrey", marginTop: "30px" }}>
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: "10px" }}>Others</p>
                                            <div className="">
                                                <table className="table review-idea-table table-bordered">
                                                    <tbody>
                                                        {this.state.othersData.map((row, rowIndex) => (
                                                            <tr key={rowIndex}>
                                                                {OthersColumns.map((column, columnIndex) => (
                                                                    <td key={columnIndex}>
                                                                        <textarea
                                                                            className="form-attribute anamoly_textarea textarea_resize_none"
                                                                            type="text"
                                                                            value={row[column] || ""}
                                                                            disabled
                                                                        />
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                {/* Others Template ends here */}


                            </div>
                        </div>
                    </div>
                </div>

                {/* ......predective template ends here...... */}



            </div>
        )
    }
}
