export let LOCAL_MODE = false;
export const TEST_MODE = false;
export let PROD_MODE = false;
export let API_SERVER_URL = "";

const HostName = window.location.hostname.trim();

if (HostName === "localhost") {
  LOCAL_MODE = true;
  PROD_MODE = false;
  // API_SERVER_URL = "https://gen-fd-dev-progear-01.azurefd.net/";
  API_SERVER_URL = "https://corp-api-ind-uat-tas-progear-01.azure-api.net/";
} else if (HostName === "xceleratedev.genpact.com") {
  LOCAL_MODE = false;
  PROD_MODE = false;
  // API_SERVER_URL = "https://corp-api-ind-dev-tas-progear-01.azure-api.net/";
  API_SERVER_URL = "https://gen-fd-dev-progear-01.azurefd.net/";
} else if (HostName === "xcelerateuat.genpact.com") {
  LOCAL_MODE = false;
  PROD_MODE = false;
  API_SERVER_URL = "https://corp-api-ind-uat-tas-progear-01.azure-api.net/";
} 
else if (HostName === "ldtideapp.genpact.com") {
  LOCAL_MODE = false;
  PROD_MODE = true;
  API_SERVER_URL = "https://corp-api-ind-prod-tas-pgr-01.azure-api.net/";
}
// else if (HostName === "progear2.genpact.com") {
//   LOCAL_MODE = false;
//   PROD_MODE = true;
//   API_SERVER_URL = "https://corp-api-ind-prod-tas-pgr-01.azure-api.net/";
// }

export const GET_METADATA = API_SERVER_URL + "idea-metadata" ;
export const GET_ALL_IDEA_URL = API_SERVER_URL + "get-all-ideas" + "?";
export const GET_IDEA_URL = API_SERVER_URL + "get-idea" + "?";
export const SUBMIT_IDEA_URL = API_SERVER_URL + "create-idea" + "?";
export const DELEGATE_IDEA_URL = API_SERVER_URL + "delegate-idea" + "?";
export const REVIEW_IDEA_URL = API_SERVER_URL + "review-idea" + "?";
export const UPDATE_IDEA_URL = API_SERVER_URL + "update-idea" + "?";
export const HOMEPAGE_URL = API_SERVER_URL + "home-page-data" + "?";
export const TEMPLATE_UPDATE = API_SERVER_URL + "template-update" + "?";
export const SUBMIT_GENAI_URL = API_SERVER_URL + "genai" + "?";

export const POWER_APP_URL = PROD_MODE ?
  "https://apps.powerapps.com/play/eb24a69e-c80c-4af5-bfec-ab16fa90ef42?tenantId=bdef8a20-aaac-4f80-b3a0-d9a32f99fd33"
  :
  "https://apps.powerapps.com/play/43ccae55-a0b4-4087-8974-b2877eeb1f48?tenantId=bdef8a20-aaac-4f80-b3a0-d9a32f99fd33";

  export const DASHBOARD_URL = PROD_MODE ?
  "https://app.powerbi.com/groups/5adbbdb0-5338-4cae-88d5-30f4bce9b82d/dashboards/e51fac98-60ba-49f1-9b59-4c5cb612126a"
  :
  "https://app.powerbi.com/groups/76f90f24-3c78-48ae-a905-ceac69f4ea6a/dashboards/2d8820d3-548d-4309-8b80-c45cae877586";

// export const OKTA_BASE_URL = 'https://dev-94209304.okta.com/oauth2/default'; //LOCAL
// export const OKTA_CLIENT_ID = '0oah9trlqHyQUfgwR5d6'; //LOCAL

// export const OKTA_BASE_URL = 'https://genpact.oktapreview.com/oauth2/default'; //UAT
// export const OKTA_CLIENT_ID = '0oa1hlcw4wszdRmUf0x7'; //UAT
// export const OKTA_REDIRECT_URL = 'https://xceleratedev.genpact.com/callback'; //UAT

export const OKTA_BASE_URL = PROD_MODE
  ? "https://genpact.okta-emea.com/oauth2/default" //PROD
  : "https://genpact.oktapreview.com/oauth2/default"; //UAT
export const OKTA_CLIENT_ID = PROD_MODE
  ? "0oa7hodheesQyHj5T0i7" //PROD
  : "0oa1hlcw4wszdRmUf0x7"; //UAT
// export const OKTA_REDIRECT_URL = (LOCAL_MODE)
//     ? 'http://localhost:3000/callback'
//     : (PROD_MODE)
//         ? 'https://ldtideapp.genpact.com/callback' //PROD
//         : 'https://xceleratedev.genpact.com/callback'; //UAT

export const OKTA_REDIRECT_URL = LOCAL_MODE
  ? "http://" + HostName + ":3000/callback"
  : "https://" + HostName + "/callback";

export const POSTLOGOUTREDIRECTURI = LOCAL_MODE
  ? "http://" + HostName + ":3000/"
  : "https://" + HostName + "/";

//Application Constants
export const OTHER_REGIONS = "Other Regions";
export const PENDING = "Pending";
export const APPROVED = "Approved";
export const REJECTED = "Rejected";
export const SENT_BACK = "Sent Back";
export const REQUEST_CANCELLATION = "Cancellation Requested";
export const COMPLETED_STATUS = "Completed";
export const AWAITING_MBB_APPROVAL = "Awaiting MBB Approval";
export const AWAITING_LDT_APPROVAL = "Awaiting LDT Approval";
export const PPT_CONTENT = "PPTContent";
export const SIGNOFF_CONTENT = "SignoffContent";
export const GOL_APPROVAL_CONTENT = "golApprovalContent";
export const FPnA_APPROVAL_CONTENT = "fpnaApprovalContent";
export const AWAITING_QAULITY_CHECK_REVIEW = "Awaiting Quality Check Review";
export const AWAITING_AI_BOT_REVIEW = "Awaiting AI Bot Review";
export const AWAITING_AI_ANALYTICS_REVIEW = "Awaiting Analytics Review";
export const AWAITING_DB_SPOC_APPROVAL = "Awaiting DBSPOC Approval";
export const AWAITING_E360_SPOC_APPROVAL = "Awaiting E360 SPOC Review";



//Actions
export const VIEW = "view";
export const EDIT = "edit";
export const DELEGATE = "delegate";
export const UPDATE = "update";
export const APPROVAL = "approval";
export const LEAN_APPROVAL = "leanapproval";
export const PROJECT_VIEW = "projectview";

//Business Constants
export const DATA_BRIDGE_CAMPAIGN = "Data Bridge";
export const X_POLLINATION_CAMPAIGN = "X-Pollination";

//Logo images
export const LOGO_IMAGE =
  "https://xceleratedev.genpact.com/static/media/genpactlogohorizontal.b520869d.png";

//Storage Key Constants
export const USER_DATA = "userData";
export const ACCESS_TOKEN = "accessToken";
export const HOME_DATA = "homeData";

export const BI_FORM_DATE = "03/18/2023";

export const DB_PEF_DATE = PROD_MODE ? "04/20/2024" : "03/01/2024";

export const DISPLAYPROJECTTHEME = "11/5/2023";

export const PROJECT_TYPE = [
  {
    value: 0,
    label: "GB",
  },
  {
    value: 1,
    label: "BB",
  },
  {
    value: 2,
    label: "MBB",
  },
];

export const DATABRIDGETYPES = [
  {
    value: 0,
    label: "Diagnostic",
  },
  {
    value: 1,
    label: "Descriptive",
  },
  {
    value: 2,
    label: "Predictive",
  },
  {
    value: 3,
    label: "Prescriptive",
  },

];

export const LEANTOOLSUSED = [
  {
    value: 0,
    label: "3M",
  },
  {
    value: 1,
    label: "5s",
  },
  {
    value: 2,
    label: "Poka-Yoke",
  },
  {
    value: 3,
    label: "Jidoka",
  },
  {
    value: 4,
    label: "Others",
  },
];

export const MUCID = [
  {
    value: 0,
    label: "1",
  },
  {
    value: 1,
    label: "2",
  },
];

export const YESORNO = [
  { value: 0, label: "Yes" },
  { value: 1, label: "No" },
];

export const DISCUSSIONLEDBY = [
  { value: 0, label: "Transition led" },
  { value: 1, label: "Analytics led" },
  { value: 2, label: "Ops led" },
  { value: 3, label: "LDT led" },
]

export const dbSpocApprovalOptions = [
  { value: 0, label: "Valid Model Build" },
  { value: 1, label: "Invalid Model Build" },
  { value: 2, label: "Send Back" },
];
export const modelOperationOptions = [
  { value: 0, label: "Deployed in Client Environment" },
  { value: 1, label: "Deployed in Genpact Environment" },
  { value: 2, label: "Not Deployed" },
];
export const sustenanceMetricFreqOptions = [
  { value: 0, label: "Daily" },
  { value: 1, label: "Weekly" },
  { value: 2, label: "Fortnightly" },
  { value: 3, label: "Monthly" },
];


export const SOLUTIONCOMPLEXITY = [
  { value: 0, label: "High" },
  { value: 1, label: "Medium" },
  { value: 2, label: "Low" },
]

export const REPLICABILITYOPTIONS = [
  { value: 1, label: "Low" },
  { value: 2, label: "Medium" },
  { value: 3, label: "High" },
]


export const AI_DOC_LINK = "https://genpactonline.sharepoint.com/:p:/s/LeanDigitalTransformation/EWNpW9slnDFFp2INIyH8_zoBq9bstWZVevJp6E3Wx8WxsA?e=0cqNVV";
export const AIFIRST = "AI First";
export const AIFIRSTDATE = "02/01/2024";


export const DOBTOOLS = [
  { value: 0, label: "AiPlay" },
  { value: 1, label: "Decisive Genie" },
  { value: 2, label: "Tensor Flow" },
  { value: 3, label: "arCheck" },
  { value: 4, label: "PairWith" },
  { value: 5, label: "OCRit" },
  { value: 6, label: "Organize.ai" },
  { value: 7, label: "Qualia" },
  { value: 8, label: "M-JAD" },
  { value: 9, label: "auto EDA" },
  { value: 10, label: "MASKit" },
  { value: 11, label: "Celonis" },
  { value: 12, label: "Power BI" },
  { value: 13, label: "Others" },
]

// Gen AI Constatnts
export const OPPMASTERSTAGES = [
  {
    value: 1,
    label: "1. Client discussion - Not Started"
  },
  {
    value: 2,
    label: "2. Client Discussion - Ongoing"
  },
  {
    value: 3,
    label: "3. Demonstrable POC in Flight"
  },
  {
    value: 4,
    label: "4. Demonstrable POC Complete"
  },
  {
    value: 5,
    label: "5. Production grade Build in Flight"
  },
  {
    value: 6,
    label: "6. Production Grade Build Complete"
  },
  {
    value: 7,
    label: "7. Declined by Client"
  },
  {
    value: 8,
    label: "8. Descoped"
  },
  {
    value: 9,
    label: "9. On-hold"
  }

]

export const OPPMASTERSTATE = [
  {
    value: 1,
    label: "Inactive"
  },
  {
    value: 2,
    label: "Active"
  },
  {
    value: 3,
    label: "Pending Approval from MBB"
  },
  {
    value: 4,
    label: "Pending Approval from Unit SPOC"
  },
  {
    value: 5,
    label: "Pending Approval from Solution Owner"
  },
  {
    value: 6,
    label: "Pending Approval from Central Team"
  },
  {
    value: 7,
    label: "Completed"
  }
]

export const GenAI_ENVIRONMENT = [
  {
    value: 1,
    label: "Client"
  },
  {
    value: 2,
    label: "Genpact"
  }
]


export const GenAI_FUNDING = [
  {
    value: 1,
    label: "Client"
  },
  {
    value: 2,
    label: "Genpact"
  },
  {
    value: 3,
    label: "Client + Genpact"
  }
]

export const OPPAPPROVALOPTIONS = [
  {
    value: 1,
    label: "Approved",
  },
  {
    value: 2,
    label: "Send Back",
  }
]

export const OPPAPPROVALOPTIONSSOLUTIONOWNER = [
  {
    value: 1,
    label: "Approved",
  },
  {
    value: 2,
    label: "Rejected",
  }
]



export const PENDING_APPROVAL_FROM_MBB = "Pending Approval from MBB";
export const PENDING_APPROVAL_FROM_SOLUTION_OWNER = "Pending Approval from Solution Owner";
export const PENDING_APPROVAL_FROM_UNIT_SPOC = "Pending Approval from Unit SPOC";
export const PENDING_APPROVAL_FROM_CENTRAL_TEAM = "Pending Approval from Central Team";
export const ACTIVE = "Active";
export const INACTIVE = "Inactive";

















