// import React, { Component } from 'react';
// import { withOktaAuth } from '@okta/okta-react';
// import { OktaAuth } from '@okta/okta-auth-js';
// import bgImage from "../assets/images/genpactsigninbg.png";
// import { ACCESS_TOKEN, USER_DATA } from '../assets/constants/constants';
// import Toast from 'light-toast';

// class OktaSignInWidget extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       sessionToken: null,
//       email: '',
//       password: ''
//     };

//     this.oktaAuth = new OktaAuth({
//       issuer: props.baseUrl,
//       clientId: props.clientId,
//       pkce: true
//     });

//     this.handleEmailChange = this.handleEmailChange.bind(this);
//     this.handlePasswordChange = this.handlePasswordChange.bind(this);
//     this.signIn = this.signIn.bind(this);
//   }

//   handleEmailChange(event) {
//     this.setState({ email: event.target.value });
//   }

//   handlePasswordChange(event) {
//     this.setState({ password: event.target.value });
//   }

//   async signIn(event) {
//     event.preventDefault();
//     Toast.loading("Signing In...", () => { });
//     try {
//       const transaction = await this.oktaAuth.signIn({
//         username: this.state.email,
//         password: this.state.password
//       });

//       if (transaction.status === 'SUCCESS') {
//         console.log("Logged in successfully");
//         Toast.hide();
//         Toast.success('Sign-In Successful!', 3000, async () => {
//           await this.props.oktaAuth.signInWithRedirect({
//             responseType: ['id_token', 'token', 'access_token'],
//             sessionToken: transaction.sessionToken 
//           }).then(()=>{
//             let accessToken = this.props.oktaAuth.idToken.value;
//             console.log("Access Token:", accessToken);
//             localStorage.setItem(ACCESS_TOKEN, accessToken);
//           });
//         });

//         // const token = await this.props.oktaAuth.token.getUserInfo();
//         console.log("First Name:", transaction.user.profile.firstName);
//         console.log("Last Name:", transaction.user.profile.lastName);
//         console.log("Email:", transaction.user.profile.login);
//         if(transaction.user.profile.login !== ""){
//           transaction.user.profile.oHRId = transaction.user.profile.login.split("@")[0];
//           //TODO: Comment out before UAT
//           // transaction.user.profile.oHRId = 
//                                                                 // '850051495'; //Sajid
//                                                                 //'850051496'; //Shweta
//                                                                 //'850050649'; //Manish
//                                                                 // '730003800'; //Daniel
//                                                                 // '850054658';//Sameer
//         }
//         window.sessionUser = "foo";
//         sessionStorage.setItem(USER_DATA, JSON.stringify(transaction.user));
//         localStorage.setItem(USER_DATA, JSON.stringify(transaction.user));
//       } else {
//         Toast.hide();
//         throw new Error('Could not sign in: ' + transaction.status);
//       }
//     } catch (error) {
//       Toast.hide();
//       console.log("error in catch", error);
//       Toast.fail('Authentication failed. Please check your Username or Password is correct!', 3000, () => { });
//     }
//   }

//   render() {
//     return (
//       <div style={{ width: '100%', height: '85vh', verticalAlign: 'middle',  backgroundImage: 'url(' + bgImage + ')' }}>
//         <div style={{ width: '100%', height: '85vh', verticalAlign: 'middle', padding: 20, backgroundColor: 'rgba(0,0,0,0.3)' }}>
//           <form onSubmit={this.signIn} className="login-form" style={{ margin: 20, padding: 20, backgroundColor: 'white', borderRadius: 0, maxWidth: '90vw', height: 'auto' }}>
//             <img src={require("../assets/images/genpactlogohorizontal.png")} alt="" style={{ alignContent: 'center', width: '50%' }} />
//             <hr></hr>
//             <h4 style={{ color: '#001A30', fontWeight: 'bold' }}>Ideation App</h4>
//             <h6>Sign In</h6>
//             <p></p>
//             <label className="full-width-input" style={{ textAlign: 'left', backgroundColor: 'transparent', marginRight: 10 }}>
//               &nbsp;&nbsp;&nbsp;Username
//               <input type="text" placeholder="Genpact OHR" value={this.state.email} onChange={this.handleEmailChange} required />
//             </label>
//             <label className="full-width-input" style={{ textAlign: 'left', backgroundColor: 'transparent', marginRight: 10  }}>
//               &nbsp;&nbsp;&nbsp;Password
//               <input type="password" placeholder="Password" value={this.state.password} onChange={this.handlePasswordChange} required autoComplete="off" />
//             </label>
//             <button className="button" style={{ backgroundColor: 'rgb(255, 85, 95)', borderRadius: 0, color: 'white', marginTop: 20, marginBottom: 20 }}>Sign In</button>
//           </form>
//         </div>
//       </div>
//     );
//   }
// };

// export default withOktaAuth(OktaSignInWidget);

import React, { Component } from "react";
import { withOktaAuth } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";

class OktaSignInWidget extends Component {
  constructor(props) {
    super(props);

    this.oktaAuth = new OktaAuth({
      issuer: props.baseUrl,
      clientId: props.clientId,
      pkce: true
    });
  }
  async componentDidMount() {
    this.login();
  }
  async login() {
    await this.props.oktaAuth.signInWithRedirect({
      scopes: ['openid', 'profile', 'email', 'offline_access']
    });
  }

  render() {
    return (
      <div>
        <p>Redirecting to Okta...</p>
      </div>
    );
  }
}

export default withOktaAuth(OktaSignInWidget);