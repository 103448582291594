import React from 'react';

function Private() {
  return (
    <div className="page">
      <div className="warning">
        <h1>Employee Access</h1>
        <h2>Idea Creation & Idea Review</h2>
      </div>
    </div>
  );
}

export default Private;
