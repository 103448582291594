import React,{useState,useEffect, useRef} from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Select from 'react-select';

const languageOptions = [
  { value: 'en-GB', label: 'English' },
  { value: 'zh-CN', label: 'Mandarin Chinese' },
  { value: 'fr-FR', label: 'French' },
  { value: 'es-US', label: 'Spanish' },
];

function UpdatedSpeech({closeModalFunction,onTranscriptProblem,ideaArrayIndex,modalId,onTranscriptSolution,previousText
}) {
    
    if(!localStorage.getItem("Language_Selected")){
      localStorage.setItem("Language_Selected", "English");
    }

    let selectedLanguageLabel=localStorage.getItem("Language_Selected")
    let selectedLanguageValue=localStorage.getItem("Language_Selected_value")

    const [selectedOption, setSelectedOption] = useState(selectedLanguageLabel);
    const [text,setText]=useState(previousText)
    const [opacity, setOpacity] = useState(false);
    const [opacity_one, setOpacity_one] = useState(false);
    const [opacity_two, setOpacity_two] = useState(false);
    let [vstmptxt,setvstemptxt]=useState("");
    let vstxt = ""

    function myListeningChange(l){
      
    }

    function myTranscriptChange(vit,vft){
      if(vit==""){
        document.querySelector('#tx').value=vstmptxt+vft+" ";
        setvstemptxt(document.querySelector('#tx').value);
      }
      else{
        document.querySelector('#tx').value=vstmptxt+vit;
      }
    }

    var callbacks = {
      onTranscriptChange: myTranscriptChange,onListeningChange:myListeningChange,
      
    };
    SpeechRecognition.getRecognitionManager().subscribe("myid",callbacks);
    SpeechRecognition.getRecognition().addEventListener("error", function(e){
      handlePauseFunctionality()
    })

    const {transcript, listening,resetTranscript,interimTranscript,
      finalTranscript,abortListening} = useSpeechRecognition();

    useEffect(()=>{
       // problem
       if(modalId === 0){
        onTranscriptProblem(text,ideaArrayIndex,modalId);
      }
      // solution
      if(modalId === 1){
        onTranscriptSolution(text,ideaArrayIndex,modalId)
      }
    },[text,transcript])

    const handlePauseFunctionality=()=>{
      SpeechRecognition.stopListening()
      setOpacity(true)
      setOpacity_one(false)
      setOpacity_two(false)
    }
    const handleReset=()=>{
      SpeechRecognition.abortListening()
      setOpacity(false)
      setOpacity_one(true)
      setOpacity_two(true)
      resetTranscript()
      document.querySelector('#tx').value=""
    }

    const handleStartListening=()=>{
      SpeechRecognition.startListening({ language: selectedLanguageValue,continuous: true })
      setOpacity(false)
      setOpacity_one(true)
      setOpacity_two(true)
      vstmptxt=setvstemptxt((document.querySelector('#tx').value));
    }

    const handleConfirm= async()=>{
      let tempVar = "";
      if(!transcript){
        window.alert("text to speech transcript cannot be empty")
      }
      else{
        setTimeout(() => {
          tempVar = text +" " +document.querySelector('#tx').value;
          setText(tempVar)
          closeModalFunction()
          SpeechRecognition.abortListening()
          resetTranscript()
        },1000)
      }
    }

    const closeModal=()=>{
      SpeechRecognition.stopListening()
      closeModalFunction()
    }

    const handleChangeOption=(e)=>{
      localStorage.setItem("Language_Selected", e.label)
      localStorage.setItem("Language_Selected_value", e.value)
      setTimeout(()=>{
        resetTranscript() 
      },1000)
    }

  return (
      <div className="custom_modal" id="speechModal"> 
      <div className='modal_body' style={{maxWidth: "703px",overflowY:"hidden"}}>
        <p className="speech_modal_title"> Speech to text</p>
        <div className="">
          <div className="row" style={{alignItems:"Center"}}>
            <div className="col-md-4">
              <label className="form-attribute" style={{backgroundColor:"#fff"}}>Please select your language</label>
            </div>
            <div className="col-md-8">
              <Select
                defaultValue={selectedOption}
                onChange={(e)=>handleChangeOption(e)}
                options={languageOptions}
                placeholder={selectedOption}
                isDisabled={listening ? true : false}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <label className="form-attribute" style={{backgroundColor:"#fff",marginTop:"25px"}}>Microphone: {listening ? 'on' : 'off'}</label>
          </div>
          <div className="col-md-8">
            <div className="custom_ul">
              <ul style={{float:"left"}}>
                <li><img className={opacity ? "opacity_class" : null} title="Start Recording" style={{width:"32px",cursor:"pointer",marginRight:"10px"}} src={require("../assets/images/mic-start.png")} onClick={(e)=>handleStartListening()} /></li>
                <li><img className={opacity_one ? "opacity_class" : null} title="Pause Recording" style={{width:"32px",cursor:"pointer",marginRight:"10px"}} src={require("../assets/images/mic-pause.png")} onClick={(e)=>handlePauseFunctionality()} /></li>
                <li><img className={opacity_two ? "opacity_class" : null} title="Reset Transcript" style={{width:"32px",cursor:"pointer",marginRight:"10px"}} src={require("../assets/images/mic-reset.png")} onClick={(e)=> listening ? "" : handleReset()} /></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="submit_speec">
            <div className="transcripted_text" >
              <p style={{marginBottom:"10px"}}>{previousText}</p>
              <textarea disabled={listening ? true : false} id="tx" rows={6} cols={105}></textarea><br></br>
            </div>
            <div className='' style={{display:"flex",justifyContent:'center', alignItems:"center",marginTop:"20px"}}>
              <div className='btn-group'>
                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e)=>handleConfirm(transcript)}>Confirm</button>
                <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={(e)=>closeModal()}>Cancel</button>
              </div>
            </div>
          
        </div>
      </div>
    </div>

  )
}

export default UpdatedSpeech



 