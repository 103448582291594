import React, { Component } from 'react'
import { GET_METADATA, GET_IDEA_URL, USER_DATA, ACCESS_TOKEN, YESORNO, DISCUSSIONLEDBY, OPPMASTERSTAGES, OPPMASTERSTATE, GenAI_ENVIRONMENT, GenAI_FUNDING, OPPAPPROVALOPTIONS, SUBMIT_GENAI_URL, PENDING_APPROVAL_FROM_MBB, PENDING_APPROVAL_FROM_SOLUTION_OWNER, PENDING_APPROVAL_FROM_UNIT_SPOC, PENDING_APPROVAL_FROM_CENTRAL_TEAM, ACTIVE, INACTIVE } from '../assets/constants/constants';

import Toast from 'light-toast';
import Select from 'react-select';
import { selectValidation as selectStyle, textBoxValidation } from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";
import GenAIValueDelivery from './GenAIValueDelivery';
import GenAIFPNAGOL from './GenAIFPNAGOL'

export class UpdateOpp extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);

    constructor(props) {
        super(props);
        //console.log(props)
        if (this.sessionData === null) {
            Toast.loading("This component cannot be accessed directly. Redirecting to application home.", 3000, () => {
            });
            window.location = "/login";
            return;
        }
        this.state = {
            selectStyle: {},
            oppDetails: [],
            RecID: this.props.location.state,
            RecIDAlpha: "",
            OppName: "",
            ParentID: "",
            ParentIDAlpha: "",
            ModifiedDate: "",
            ModifiedBy: "",
            PlanDiscussDate: "",
            DiscussEstDate: "",
            accountList: [],
            AccountSelectedOption: [],

            Account: "",
            Unit: "",
            MangingDept: "",
            Vertical: "",
            Segment: "",
            Archetype: "",
            AccFTE: '',

            SpocOHR_1: "",
            Spoc_1: "",

            SpocOHR_2: "",
            Spoc_2: "",

            BBOHR: "",
            BB: "",

            MBBOHR: "",
            MBB: "",

            SolnOwnerOHR: "",
            SolnOwner: "",

            CentTeamOHR: "",
            CentTeam: "",

            Cohort: "",
            CohortList: [],
            CohorSelectedOption: [],

            serviceLineList: [],
            ServiceLine: "",
            ServiceSelectedOption: [],
            ServiceLineMain: [],

            Process_L2: "",
            Process_L2SelectedOption: [],

            // audit log
            auditLog: false,
            auditLogData: [],



            ClientDtls: "",
            Problem: "",
            Solution: "",
            OverallFTE: null,
            OverallSOP: null,
            CXO: "",
            LeadHelp: "",
            IsProposalShared: "",
            IsProposalSharedSelectedOption: [],
            // ProposalFileName
            // ProposalFileType,

            SolnEnviron: [],
            SolnFund: [],
            ClientDollar: null,
            GenpactDollar: null,
            IsSOWScope: [],
            IsSOWSigned: [],
            // SOWFileName
            // SOWFileType
            SOWSubDate: "",
            SOWCloseDate: "",
            CompletionDate: "",
            TechOHR: "",
            Tech: "",
            FunctionalOHR: "",
            Functional: "",
            TransitionLed: [],
            Knowledge: "",
            Algo: "",
            Data: "",
            Infra: "",
            ValueCreated: "",
            // GOLFileName
            // GOLFileType
            FTEUsage: null,
           


            handleBIEditLog: false,
            OppStage: "",
            OppStageSelectedOption: [],
            OppState: "",


            IsHero: "",

            GenAIOfferingList: [],
            Offering_1: "",
            Offering_1SelectedOption: [],


            Offering_2: "",
            Offering_2SelectedOption: [],
            Offering_2_Other: "",


            userRole: "",
            Comment: "",

            OLINum: "",
            OppID: "",


            CentTeamApproval: "",
            CentTeamComment: "",

            BiData: false,


            isUserMBB: false,
            isUserUnitSPOC: false,
            isUserOppSPOC: false,
            isUserBB:false,
            isUserCentralTeam: false,
            isUserSolOwner: false,
            isFpnaRequired: false,

            fieldsEditable: false,
            unitSPOCApprovalRequired: false,
            isSolutionOwnerApprovalRequired: false,
            isCentralTeamApprovalRequired:false,

            showHrefModal: false,
            CustName: "",
            CustDesgn: "",
            CustSignOffDate: "",
            GOLOHRVD: "",
            GOLNameVD: "",
            FPnAName: "",
            FPnAOHR: "",

            GOLOHRMonetization: "",
            GOLNameMonetization: "",
            FPnAOHRMonetization: "",
            FPnANameMonetization: "",
            SowID: "",


            ProcessUsers: "",
            AccessUsers: "",
            DailyUsers: "",
            WeeklyUsers: "",
            WeeklyTrans: "",
            SolnTrans: "",
            Upvotes: "",
            UpvotesPercent: null,
            Accuracy:null,
            UptimePercent:null,
        }
    }

    async componentDidMount() {
        await this.fetchMetadata()
        await this.fetchOpportunity()
        await this.fetchUserRole()
    }
    fetchMetadata = async () => {
        Toast.loading("Fetching GenAI meta data. Please wait...", () => { });
        const param = {
            EntityType: "",
            EntityName: ""
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.accessToken
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("meta data ", data)
                this.setState({
                    accountList: data ? data.Accounts.sort((a, b) => { return a.ACCOUNT > b.ACCOUNT ? 1 : -1; }) : [],
                    serviceLineList: data ? data.ServiceLines : [],
                    GenAIOfferingList: data ? data.GenAIOffering : [],
                    CohortList: data ? data.Cohort : [],
                }, () => {

                    if (this.state.GenAIOfferingList) {
                        if (this.state.Offering_1) {
                            const uniqueOfferingL1 = [...new Map(this.state.GenAIOfferingList.map(item => [item["Offering_L1"], item])).values()]
                            uniqueOfferingL1.map((item, index) => {
                                if (item.Offering_L1 === this.state.Offering_1) {
                                    this.setState({ Offering_1: item.Offering_L1, Offering_1SelectedOption: { label: item.Offering_L1, value: index } }, () => { })
                                }

                            })
                            if (this.state.Offering_1 === "Bespoke") {
                                this.setState({ IsHero: "No" }, () => { })
                            }

                        }
                        if (this.state.Offering_1 && this.state.Offering_1 === "Bespoke") {
                            this.setState({ Offering_2_Other: this.state.oppDetails.Offering_2_Other }, () => { })
                        } else {
                            let filteredItems = this.state.GenAIOfferingList.filter((item) =>
                                item.Offering_L1 === this.state.Offering_1
                            )
                            filteredItems.map((item, index) => {
                                if(item.Offering_L1 === this.state.Offering_1 && item.Offering_L2 === this.state.Offering_2){
                                    this.setState({ Offering_2: item.Offering_L2, Offering_2SelectedOption: { label: item.Offering_L2, value: index } }, () => { })
                                }
                            })
                        }

                        if (this.state.Offering_1 && this.state.Offering_2) {
                            this.state.GenAIOfferingList.map((item, index) => {
                                if (item.Offering_L1 === this.state.Offering_1 && item.Offering_L2 === this.state.Offering_2) {
                                    this.setState({ IsHero: item.IsHero })
                                }
                            })
                        }
                    }

                    this.renderServiceLineOptions()
                })


                if (this.state.ServiceLine) {
                    let localServiceLine = this.state.ServiceLine.replace(/[|,]/g, ",")
                    localServiceLine = localServiceLine.split(",");
                    let options = [];
                    const uniqueServiceLineList = [...new Map(this.state.serviceLineList.map(item => [item.ServiceLineName, item])).values()];
                    let lengthOfServiceLine = uniqueServiceLineList.length + 1;
                    uniqueServiceLineList.push({ value: lengthOfServiceLine, ServiceLineName: "All" })
                    uniqueServiceLineList.map((item) => {
                        localServiceLine.map((local) => {
                            if (local === item.ServiceLineName) {
                                options.push({
                                    value: item.ServiceLineId,
                                    label: item.ServiceLineName
                                })
                            }


                        })
                    });

                    uniqueServiceLineList.map(item => {
                        if (item.ServiceLineName === this.state.oppDetails.ServiceLineMain) {
                            this.setState({ ServiceLineMain: { value: item.ServiceLineId, label: item.ServiceLineName } }, () => { })
                        }
                    })

                    this.setState({ ServiceSelectedOption: options }, () => { })

                    if (this.state.oppDetails.Process_L2) {
                        let localSubServiceLine = this.state.oppDetails.Process_L2.replace(/[|,]/g, ",")
                        localSubServiceLine = localSubServiceLine.split(",");
                        let options = [];
                        const uniqueServiceLineList = [...new Map(this.state.serviceLineList.map(item => [item.SubServiceLineName, item])).values()];
                        let lengthOfServiceLine = uniqueServiceLineList.length + 1;
                        uniqueServiceLineList.push({ value: lengthOfServiceLine, label: "All" })

                        uniqueServiceLineList.map((item) => {
                            localSubServiceLine.map((local) => {
                                if (local === item.SubServiceLineName) {
                                    options.push({
                                        value: item.SubServiceLineId,
                                        label: item.SubServiceLineName
                                    })
                                    this.setState({ Process_L2SelectedOption: options }, () => {
                                        let selectedLabels = "";
                                        selectedLabels = this.state.Process_L2SelectedOption.map(option => `${option.label}`).join('|');
                                        this.setState({ Process_L2: selectedLabels }, () => { })
                                    })
                                }
                            })
                        })
                    }
                }
                if (this.state.Cohort) {
                    this.state.CohortList && this.state.CohortList.map((item, index) => {
                        if (item.Cohort === this.state.Cohort) {
                            this.setState({
                                CohorSelectedOption: {
                                    value: index,
                                    label: item.Cohort
                                },
                                Cohort: item.Cohort
                            }, () => { })
                        }
                    })
                }
                Toast.hide();
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting Metadata. Please try again!', 3000, () => {
                    console.log(JSON.stringify(error));

                });
            });

    }


    fetchOpportunity = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.accessToken,
                },
            };
            fetch(`${GET_IDEA_URL}IdeaId=''&RecID=${this.props.location.state}&case=new&casetype=genAI`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log("fetch opportunity details => ", data)
                    this.setState({
                        oppDetails: data ? data.oppDetails ? data.oppDetails.Table1[0] : [] : [],
                        auditLogData: data ? data.oppDetails ? data.oppDetails.Table2 : [] : [],
                        BiData: data ? data.oppDetails ? data.oppDetails.Table3 : [] : [],

                    }, () => { })

                    resolve();
                    if (this.state.oppDetails) {
                        this.setState({
                            OppName: this.state.oppDetails.OppName,
                            RecID: this.state.oppDetails.RecID,
                            RecIDAlpha: this.state.oppDetails.RecIDAlpha,
                            CreatedDate: this.state.oppDetails.ParentIDAlpha,
                            CreatedName: this.state.oppDetails.CreatedName,
                            CreatedBy: this.state.oppDetails.CreatedBy,

                            ModifiedBy: this.state.oppDetails.ModifiedBy,
                            ModifiedDate: this.state.oppDetails.ModifiedDate,

                            OppStage: this.state.oppDetails.OppStage,
                            OppState: this.state.oppDetails.OppState,
                            Cohort: this.state.oppDetails.Cohort,

                            ParentID: this.state.oppDetails.ParentID,
                            ParentIDAlpha: this.state.oppDetails.ParentIDAlpha,


                            Account: this.state.oppDetails.Account,
                            Vertical: this.state.oppDetails.Vertical,
                            Segment: this.state.oppDetails.Segment,
                            Unit: this.state.oppDetails.Unit,
                            MangingDept: this.state.oppDetails.MangingDept,
                            AccFTE: this.state.oppDetails.AccFTE,
                            Archetype: this.state.oppDetails.Archetype,


                            SpocOHR_1: this.state.oppDetails.SpocOHR_1,
                            Spoc_1: this.state.oppDetails.Spoc_1,
                            SpocOHR_2: this.state.oppDetails.SpocOHR_2,
                            Spoc_2: this.state.oppDetails.Spoc_2,
                            BBOHR: this.state.oppDetails.BBOHR,
                            BB: this.state.oppDetails.BB,
                            MBBOHR: this.state.oppDetails.MBBOHR,
                            MBB: this.state.oppDetails.MBB,
                            SolnOwnerOHR: this.state.oppDetails.SolnOwnerOHR,
                            SolnOwner: this.state.oppDetails.SolnOwner,

                            Algo: this.state.oppDetails.Algo,
                            CXO: this.state.oppDetails.CXO,
                            ClientDollar: this.state.oppDetails.ClientDollar,
                            ClientDtls: this.state.oppDetails.ClientDtls,
                            CompletionDate: this.state.oppDetails.CompletionDate,

                            Data: this.state.oppDetails.Data,
                            DiscussEstDate: this.state.oppDetails.DiscussEstDate,
                            FTEUsage: this.state.oppDetails.FTEUsage,
                            GenpactDollar: this.state.oppDetails.GenpactDollar,
                            Infra: this.state.oppDetails.Infra,
                            Knowledge: this.state.oppDetails.Knowledge,
                            LeadHelp: this.state.oppDetails.LeadHelp,

                            IsVDMonetizable: this.state.oppDetails.IsVDMonetizable,
                            MonetizationStatus: this.state.oppDetails.MonetizationStatus,


                            OverallFTE: this.state.oppDetails.OverallFTE,
                            OverallSOP: this.state.oppDetails.OverallSOP,
                            PlanDiscussDate: this.state.oppDetails.PlanDiscussDate,
                            Problem: this.state.oppDetails.Problem,

                            SOWCloseDate: this.state.oppDetails.SOWCloseDate,
                            SOWSubDate: this.state.oppDetails.SOWSubDate,
                            Solution: this.state.oppDetails.Solution,
                            ValueCreated: this.state.oppDetails.ValueCreated,
                            ServiceLine: this.state.oppDetails.ServiceLine,

                            TechOHR: this.state.oppDetails.TechOHR,
                            Tech: this.state.oppDetails.Tech,
                            FunctionalOHR: this.state.oppDetails.FunctionalOHR,
                            Functional: this.state.oppDetails.Functional,

                            Offering_1: this.state.oppDetails.Offering_1,
                            Offering_2: this.state.oppDetails.Offering_2,

                            OLINum: this.state.oppDetails.OLINum,
                            OppID: this.state.oppDetails.OppID,

                            ProcessUsers: this.state.oppDetails.ProcessUsers,
                            AccessUsers: this.state.oppDetails.AccessUsers,
                            DailyUsers: this.state.oppDetails.DailyUsers,
                            WeeklyUsers: this.state.oppDetails.WeeklyUsers,
                            WeeklyTrans: this.state.oppDetails.WeeklyTrans,
                            SolnTrans: this.state.oppDetails.SolnTrans,
                            Upvotes: this.state.oppDetails.Upvotes,
                            UpvotesPercent: this.state.oppDetails.UpvotesPercent,
                            Accuracy: this.state.oppDetails.Accuracy,
                            UptimePercent: this.state.oppDetails.UptimePercent,
                        },
                            () => {
                                if (this.state.OppStage) {
                                    OPPMASTERSTAGES.map((item, index) => {
                                        if (item.label === this.state.OppStage) {
                                            this.setState({ OppStageSelectedOption: item }, () => {
                                                if (this.state.oppDetails.OppStage && this.state.oppDetails.OppStage === "1. Client discussion - Not Started") {
                                                    if (this.state.OppStageSelectedOption && this.state.OppStageSelectedOption.value === 1) {
                                                        this.setState({ fieldsEditable: true }, () => { })
                                                    }
                                                }
                                            })
                                        }
                                    })

                                }
                                if (this.state.OppState && this.state.OppState === "Active") {
                                    this.setState({ fieldsEditable: true }, () => { })
                                }
                                console.log("fields editable => ", this.state.fieldsEditable)
                                if (this.state.oppDetails.ModifiedDate) {
                                    let localVariable = this.formatDate(this.state.oppDetails.ModifiedDate)
                                    this.setState({ ModifiedDate: localVariable })
                                }

                                if (this.state.PlanDiscussDate) {
                                    if (this.state.PlanDiscussDate === "1900-01-01T00:00:00") {
                                        this.setState({ PlanDiscussDate: "" }, () => {
                                            //console.log("this.state.PlanDiscussDate at fetch",this.state.PlanDiscussDate)
                                        })
                                    } else {
                                        let localVariable = new Date(this.state.PlanDiscussDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                        this.setState({ PlanDiscussDate: localVariable }, () => {
                                            //console.log("this.state.PlanDiscussDate at fetch",this.state.PlanDiscussDate)
                                        })
                                    }
                                }
                                if (this.state.DiscussEstDate) {
                                    if (this.state.DiscussEstDate === "1900-01-01T00:00:00") {
                                        this.setState({ DiscussEstDate: "" }, () => {
                                            //console.log("this.state.DiscussEstDate at fetch",this.state.DiscussEstDate)
                                        })
                                    } else {
                                        let localVariable = new Date(this.state.DiscussEstDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                        this.setState({ DiscussEstDate: localVariable }, () => {
                                            //console.log("this.state.DiscussEstDate at fetch",this.state.DiscussEstDate)
                                        })
                                    }
                                }

                                if (this.state.SOWCloseDate) {
                                    if (this.state.SOWCloseDate === "1900-01-01T00:00:00") {
                                        this.setState({ SOWCloseDate: "" }, () => { })
                                    } else {
                                        let localVariable = new Date(this.state.SOWCloseDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                        this.setState({ SOWCloseDate: localVariable }, () => { })
                                    }
                                }

                                if (this.state.SOWSubDate) {
                                    if (this.state.SOWSubDate === "1900-01-01T00:00:00") {
                                        this.setState({ SOWSubDate: "" })
                                    } else {
                                        let localVariable = new Date(this.state.SOWSubDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                        this.setState({ SOWSubDate: localVariable })
                                    }

                                }

                                if (this.state.oppDetails.CompletionDate) {
                                    if (this.state.oppDetails.CompletionDate === "1900-01-01T00:00:00") {
                                        this.setState({ CompletionDate: "" })
                                    } else {
                                        let localVariable = new Date(this.state.oppDetails.CompletionDate).toLocaleDateString('en-CA').split('/').reverse().join('-')
                                        this.setState({ CompletionDate: localVariable }, () => {
                                            //console.log("completion date=> ",this.state.CompletionDate)
                                        })
                                    }

                                }

                                if (this.state.oppDetails.IsVDMonetizable && this.state.oppDetails.IsVDMonetizable === "No") {
                                    this.setState({ ClientDollar: 0 }, () => { })
                                }

                                if (this.state.oppDetails.IsSOWScope) {
                                    YESORNO.map((item) => {
                                        if (item.label === this.state.oppDetails.IsSOWScope) {
                                            this.setState({ IsSOWScope: item })
                                        }
                                    })
                                }



                                if (this.state.oppDetails.IsSOWSigned) {
                                    YESORNO.map((item) => {
                                        if (item.label === this.state.oppDetails.IsSOWSigned) {
                                            this.setState({ IsSOWSigned: item })
                                        }
                                    })
                                }
                                if (this.state.oppDetails.TransitionLed) {
                                    DISCUSSIONLEDBY.map((item) => {
                                        if (item.label === this.state.oppDetails.TransitionLed) {
                                            this.setState({ TransitionLed: item })
                                        }
                                    })
                                }
                                if (this.state.oppDetails.IsProposalShared) {
                                    YESORNO.map((item) => {
                                        if (item.label === this.state.oppDetails.IsProposalShared) {
                                            this.setState({ IsProposalShared: item.label, IsProposalSharedSelectedOption: item, })
                                        }
                                    })
                                }
                                if (this.state.oppDetails.SolnEnviron) {
                                    GenAI_ENVIRONMENT.map((item) => {
                                        if (item.label === this.state.oppDetails.SolnEnviron) {
                                            this.setState({ SolnEnviron: item })
                                        }
                                    })
                                }

                                if (this.state.oppDetails.SolnFund) {
                                    GenAI_FUNDING.map((item) => {
                                        if (item.label === this.state.oppDetails.SolnFund) {
                                            this.setState({ SolnFund: item })
                                        }
                                    })
                                }

                                if (this.state.oppDetails.OLINum) {
                                    let trimmedOLI = this.state.oppDetails.OLINum.match(/\d+/g).join('');
                                    this.setState({ OLINum: trimmedOLI }, () => { })
                                }
                                if (this.state.oppDetails.OppID) {
                                    let trimmedOpp = this.state.oppDetails.OppID.match(/\d+/g).join('');
                                    this.setState({ OppID: trimmedOpp }, () => {
                                        console.log(this.state.OppID)
                                    })
                                }
                            }
                        );
                    }

                })
                .catch((error) => {
                    console.log(error)
                    Toast.fail(
                        "Error while fetching opportunity details. Please try again!",
                        3000,
                        () => { }
                    );
                    resolve();
                });
        });
    };

    fetchUserRole = () => {
        return new Promise((resolve, reject) => {
            Toast.loading("Fetching user role. Please wait...", () => { });
            const param = {
                EntityType: "OHR",
                EntityName: this.sessionData ? this.sessionData.profile ? this.sessionData.profile.oHRId : "" : "",
            };
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param),
            };
            fetch(GET_METADATA, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    data.PowerAppUsers.filter((item) => {
                        const userRole = JSON.parse(item.pg_Role).pg_role.trim();
                        if (parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
                            this.setState({ userRole: userRole }, () => {
                                if (this.state.userRole === "MBB") {
                                    if (item.pg_ohr === this.state.oppDetails.MBBOHR && item.pg_username === this.state.oppDetails.MBB) {
                                        this.setState({ isUserMBB: true }, () => {
                                            console.log('is user mbb or no =>', this.state.isUserMBB)
                                        })
                                    }
                                }
                                if (this.state.userRole === "Unit SPOC") {
                                    // console.log("user role => ",this.state.userRole)
                                    // console.log("item ohr",item.pg_ohr)
                                    // console.log("typeof item pg ohr =>",typeof item.pg_ohr)
                                    // console.log("oppDetails UNIT OHR =>",this.state.oppDetails.UnitOHR)
                                    // console.log("typeof oppDetails UNIT OHR =>",typeof this.state.oppDetails.UnitOHR)
                                    // console.log("item ohr name =>",item.pg_username)
                                    // console.log("typeof item username =>",typeof item.pg_username)
                                    // console.log("oppDetails UNIT OHR name=>",this.state.oppDetails.UnitOHRName)
                                    // console.log("typeof oppDetails UNIT username =>",typeof this.state.oppDetails.UnitOHRName)
                                    // if(item.pg_ohr === this.state.oppDetails.UnitOHR){
                                    //     console.log("OHR are same")
                                    // }else{
                                    //     console.log("OHR are not same")
                                    // }
                                    // if(item.pg_username === this.state.oppDetails.UnitOHRName){
                                    //     console.log("username are same")
                                    // }else{
                                    //     console.log("username are not same")
                                    // }
                                    // if(item.pg_ohr === this.state.oppDetails.UnitOHR && item.pg_username === this.state.oppDetails.UnitOHRName){
                                    //     console.log("and operator true")
                                    // }else{
                                    //     console.log("false")
                                    // }
                                    if (item.pg_ohr === this.state.oppDetails.UnitOHR && item.pg_username === this.state.oppDetails.UnitOHRName) {
                                        this.setState({ isUserUnitSPOC: true }, () => {
                                            console.log('is user unit spoc =>', this.state.isUserUnitSPOC)
                                        })
                                    }
                                }
                                if (this.state.userRole === "Opp SPOC") {
                                    if (item.pg_ohr === this.state.oppDetails.SpocOHR_1 && item.pg_username === this.state.oppDetails.Spoc_1) {
                                        this.setState({ isUserOppSPOC: true }, () => {
                                            console.log('is user opp spoc =>', this.state.isUserOppSPOC)
                                        })
                                    }
                                    if (item.pg_ohr === this.state.oppDetails.SpocOHR_2 && item.pg_username === this.state.oppDetails.Spoc_2) {
                                        this.setState({ isUserOppSPOC: true }, () => {
                                            console.log('is user opp spoc =>', this.state.isUserOppSPOC)
                                        })
                                    }
                                }
                                if (this.state.userRole === "Central Team") {
                                    this.setState({ isUserCentralTeam: true }, () => {
                                        console.log('is user central team =>', this.state.isUserCentralTeam)
                                    })

                                }

                                if (this.state.userRole === "Opp Solution Owner") {
                                    if (item.pg_ohr === this.state.oppDetails.SolnOwnerOHR && item.pg_username === this.state.oppDetails.SolnOwner) {
                                        this.setState({ isUserSolOwner: true }, () => {
                                            console.log('is user solution owner =>', this.state.isUserSolOwner)
                                        })
                                    }
                                }
                                if (this.state.userRole === "BB") {
                                    if (item.pg_ohr === this.state.oppDetails.BBOHR && item.pg_username === this.state.oppDetails.BB) {
                                        this.setState({ isUserBB: true }, () => {
                                            console.log('is user BB =>', this.state.isUserBB)
                                        })
                                    }
                                }
                            })
                            resolve();
                            Toast.hide();
                        }
                    })
                    
                })
                .catch((error) => {
                    Toast.hide();
                    console.log(JSON.stringify(error));
                });
        });
    }

    formatDate = (columnName) => {
        return new Date(columnName).toLocaleDateString('en-CA').split('/').reverse().join('-');
    }


    // file upload functionality starts here
    uploadFile = () => {

    }
    // file upload functionality ends here


    // fields functionality starts here

    handleOppStageChange = (e) => {
        this.setState({ OppStage: e.label, OppStageSelectedOption: e }, () => {
            // if (this.state.OppStageSelectedOption.value === 1) {
            //     this.setState({ OppState: "Active",fieldsEditable:true,}, () => { 
            //         console.log("fields editable during stage 1 =>",this.state.fieldsEditable)
            //     })
            // }
            if (this.state.OppStageSelectedOption.value === 2) {
                this.setState({ OppState: "Active" }, () => { })
            }
            if (this.state.OppStageSelectedOption.value === 4) {
                this.setState({ OppState: "Active" }, () => { })
            }
            if (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 5) {
                this.setState({ OppState: "Active" }, () => {
                    if (this.state.isUserMBB || this.state.isUserOppSPOC || this.state.isUserSolOwner || this.state.isUserBB) {
                        this.setState({ unitSPOCApprovalRequired: true }, () => { })
                    }
                })
            }
            if (this.state.OppStageSelectedOption.value === 1 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 || this.state.OppStageSelectedOption.value === 9) {
                this.setState({ OppState: "Inactive" }, () => { })
            }
            if (this.state.OppStageSelectedOption.value === 6) {
                this.setState({ OppState: "Active" }, () => {
                    if (this.state.isUserMBB || this.state.isUserOppSPOC || this.state.isUserUnitSPOC || this.state.isUserBB) {
                        this.setState({ isSolutionOwnerApprovalRequired: true }, () => { })
                    }
                    if (this.state.isUserMBB || this.state.isUserOppSPOC || this.state.isUserUnitSPOC || this.state.isUserBB || this.state.isUserSolOwner) {
                        this.setState({ isCentralTeamApprovalRequired: true }, () => { })
                    }
                })
            }
        })


        let styleStateObj = this.state.selectStyle;

        styleStateObj.TransitionLed = selectStyle(true);
        styleStateObj.ServiceSelectedOption = selectStyle(true);
        styleStateObj.ServiceLineMain = selectStyle(true);
        styleStateObj.Process_L2SelectedOption = selectStyle(true);
        styleStateObj.Offering_1SelectedOption = selectStyle(true);

        styleStateObj.Offering_2SelectedOption = selectStyle(true);
        styleStateObj.Offering_2_Other = textBoxValidation(true);
        styleStateObj.ClientDtls = textBoxValidation(true);
        styleStateObj.Problem = textBoxValidation(true);
        styleStateObj.Solution = textBoxValidation(true);
        styleStateObj.OverallFTE = textBoxValidation(true);
        styleStateObj.OverallSOP = textBoxValidation(true);
        styleStateObj.CXO = textBoxValidation(true);
        styleStateObj.PlanDiscussDate = textBoxValidation(true);
        styleStateObj.DiscussEstDate = textBoxValidation(true);
        styleStateObj.IsProposalSharedSelectedOption = selectStyle(true);
        styleStateObj.SolnEnviron = selectStyle(true);
        styleStateObj.SolnFund = selectStyle(true);
        styleStateObj.ClientDollar = textBoxValidation(true);
        styleStateObj.GenpactDollar = textBoxValidation(true);
        styleStateObj.IsSOWScope = selectStyle(true);
        styleStateObj.IsSOWSigned = selectStyle(true);
        styleStateObj.SOWSubDate = textBoxValidation(true);
        styleStateObj.SOWCloseDate = textBoxValidation(true);
        styleStateObj.CompletionDate = textBoxValidation(true);

        styleStateObj.FunctionalOHR = textBoxValidation(true);
        styleStateObj.Functional = textBoxValidation(true);
        styleStateObj.TechOHR = textBoxValidation(true);
        styleStateObj.Tech = textBoxValidation(true);

        styleStateObj.Knowledge = textBoxValidation(true);
        styleStateObj.Algo = textBoxValidation(true);
        styleStateObj.Data = textBoxValidation(true);
        styleStateObj.Infra = textBoxValidation(true);
        styleStateObj.FTEUsage = textBoxValidation(true);
        
        styleStateObj.ProcessUsers = textBoxValidation(true);
        styleStateObj.AccessUsers = textBoxValidation(true);
        styleStateObj.DailyUsers = textBoxValidation(true);
        styleStateObj.WeeklyUsers = textBoxValidation(true);
        styleStateObj.WeeklyTrans = textBoxValidation(true);
        styleStateObj.SolnTrans = textBoxValidation(true);
        styleStateObj.Upvotes = textBoxValidation(true);
        styleStateObj.UpvotesPercent = textBoxValidation(true);
        styleStateObj.Accuracy = textBoxValidation(true);
        styleStateObj.UptimePercent = textBoxValidation(true);


        this.setState({ selectStyle: styleStateObj, });


       

    }
    renderOppMasterStages = () => {
        return OPPMASTERSTAGES;
    }

    renderCohort = () => {
        let options = [];
        this.state.CohortList && this.state.CohortList.map((item, index) => {
            options.push({
                value: index,
                label: item.Cohort
            })
        })
        return options;
    }

    handleCohortChange = (e) => {
        this.setState({ Cohort: e.label, CohorSelectedOption: e }, () => { })
    }
    renderGenAIOfferingL1 = () => {
        let options = [];
        const uniqueOfferingL1 = [...new Map(this.state.GenAIOfferingList.map(item => [item["Offering_L1"], item])).values()]
        uniqueOfferingL1.map((item, index) => {
            options.push({ value: index, label: item.Offering_L1 });
            options.sort((a, b) => a.label.localeCompare(b.label))
            return true
        })
        return options;
    }

    heroOfferingL1Change = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.Offering_1SelectedOption = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            Offering_1: e.label, Offering_1SelectedOption: e,
            OverallSOP: null,
            Offering_2: "", Offering_2SelectedOption: [],
            Offering_2_Other: ""

        });
        if (e && e.label === "Bespoke") {
            this.setState({ IsHero: "No" })
        }

    }

    renderGenAIOfferingL2 = () => {
        let options = [];
        if (this.state.GenAIOfferingList) {
            let filteredItems = this.state.GenAIOfferingList.filter((item) =>
                item.Offering_L1 === this.state.Offering_1
            )
            filteredItems.map((item, index) => {
                options.push({ value: index, label: item.Offering_L2 })
                options.sort((a, b) => a.label.localeCompare(b.label))
                return true
            })
            return options;
        }
    }
    heroOfferingL2Change = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.Offering_2SelectedOption = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            Offering_2: e.label, Offering_2SelectedOption: e,
        }, () => {
            this.state.GenAIOfferingList.map((item, index) => {
                if (item.Offering_L1 === this.state.Offering_1 && item.Offering_L2 === e.label) {
                    this.setState({ IsHero: item.IsHero })
                }
            })
        });
    }

    heroOfferingBeSpokeChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 100) {
            styleStateObj.Offering_2_Other = textBoxValidation(true);
            this.setState({
                Offering_2_Other: e,
                selectStyle: styleStateObj,
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 100);
            this.setState({
                Offering_2_Other: trimmedValue,
                selectStyle: styleStateObj,
            });
        }


    }

    handleServiceLineChange = (e) => {
        if (e.length <= 10) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.ServiceSelectedOption = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj,
                ServiceSelectedOption: e,
                Process_L2SelectedOption: [],
                Process_L2: "",
                ServiceLineMain: []
            });
            if (e && e.length === 1) {
                console.log('e[0]=>',e[0])
                this.setState({ ServiceLineMain: e[0] }, () => { 
                    console.log("service line main =>",this.state.ServiceLineMain)
                })
            }
            let selectedLabels = "";
            selectedLabels = e.map(option => `${option.label}`).join('|');
            this.setState({ ServiceLine: selectedLabels }, () => { })
            this.renderSubServiceLineOptions();
        } else {
            window.alert("You can add upto 10 service line options only.")
            return false;
        }
    }

    handleProcessChange = (e) => {
        if (e.length <= 5) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.Process_L2SelectedOption = selectStyle(true);
            this.setState({ Process_L2SelectedOption: e, selectStyle: styleStateObj }, () => { })
            let selectedLabels = "";
            selectedLabels = e.map(option => `${option.label}`).join('|');
            this.setState({ Process_L2: selectedLabels }, () => { })
        } else {
            window.alert("You can add upto 5 Area / Process line options only.")
            return false;
        }

    }
    clientBGChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 2000) {
            styleStateObj.ClientDtls = textBoxValidation(true);
            this.setState({
                ClientDtls: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.ClientDtls.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 2000);
            this.setState({
                ClientDtls: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.ClientDtls.replace(/'/g, "\\'");
            });
        }
    }
    clientProblemChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 2000) {
            styleStateObj.Problem = textBoxValidation(true);
            this.setState({
                Problem: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Problem.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 2000);
            this.setState({
                Problem: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Problem.replace(/'/g, "\\'");
            });
        }
    }
    genAISolutionChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 2000) {
            styleStateObj.Solution = textBoxValidation(true);
            this.setState({
                Solution: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Solution.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 2000);
            this.setState({
                Solution: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Solution.replace(/'/g, "\\'");
            });
        }
    }

    overAllFTEinScopeChange = (e) => {
        if (e <= 5000) {
            if (/^\d{0,4}(\.\d{0,2})?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.OverallFTE = textBoxValidation(true);
                this.setState({ OverallFTE: e, selectStyle: styleStateObj }, () => { })
            } else {
                window.alert("You can add upto 4 digits and 2 decimal values only allowed.");
                return;
            }
        }
        if (e === null || e === undefined || e === "") {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.OverallFTE = textBoxValidation(false);
            this.setState({ selectStyle: styleStateObj }, () => { })
        }
    }
    overAllNumberofSOPChange = (e) => {
        if (e <= 1000) {
            if (/^\d{0,4}(\.\d{0,2})?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.OverallSOP = textBoxValidation(true);
                this.setState({ OverallSOP: e, selectStyle: styleStateObj }, () => { })
            } else {
                window.alert("You can add upto 4 digits and 2 decimal values only allowed.");
                return;
            }
        } else {
            // let styleStateObj = this.state.selectStyle;
            // styleStateObj.OverallSOP = textBoxValidation(false);
            // this.setState({ selectStyle: styleStateObj }, () => { })
        }

    }


    needHelpChange = (e) => {

        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.LeadHelp = textBoxValidation(true);
            this.setState({
                LeadHelp: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.LeadHelp.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                LeadHelp: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.LeadHelp.replace(/'/g, "\\'");
            });
        }



    }

    CXOReportChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.CXO = textBoxValidation(true);
            this.setState({
                CXO: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.CXO.replace(/'/g, "\\'");
                //console.log("CXO =>",this.state.CXO)
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                CXO: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.CXO.replace(/'/g, "\\'");
            });
        }

    }

    IsProposalSharedChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsProposalSharedSelectedOption = selectStyle(true);
        this.setState({ IsProposalShared: e.label, IsProposalSharedSelectedOption: e, selectStyle: styleStateObj }, () => { })
    }

    planDiscussionDateChange = (e) => {
        if (e !== null && e !== undefined) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.PlanDiscussDate = textBoxValidation(true);
            this.setState({ PlanDiscussDate: e, selectStyle: styleStateObj }, () => { })
        }

    }
    DiscussionEstimateDateChange = (e) => {
        if (e !== null && e !== undefined) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.DiscussEstDate = textBoxValidation(true);
            this.setState({ DiscussEstDate: e, selectStyle: styleStateObj }, () => { })
        }
    }
    SolutionFundingChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.SolnFund = selectStyle(true);
        if (this.state.oppDetails.IsVDMonetizable && this.state.oppDetails.IsVDMonetizable === "No") {
            this.setState({ ClientDollar: 0 }, () => { })
        } else {
            this.setState({ ClientDollar: "" }, () => { })
        }
        this.setState({
            SolnFund: e, selectStyle: styleStateObj,
            GenpactDollar: "",
        }, () => { })
    }

    clientFundingChange = (e) => {
        if (/^\d{0,10}(\.\d{0,2})?$/.test(e)) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.ClientDollar = textBoxValidation(true);
            this.setState({
                ClientDollar: e, selectStyle: styleStateObj,

            }, () => {

            })
        } else {
            window.alert("You can add upto 10 digits and 2 decimal values only allowed.");
            return;
        }
    }
    clientFundingDisable = () => {
        let localValue = false;
        if (this.state.OppStageSelectedOption) {
            if (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) {
                localValue = true;
            } else {
                if (this.state.oppDetails.IsVDMonetizable && this.state.oppDetails.IsVDMonetizable === "No") {
                    localValue = true;
                }
            }
        }

        if (this.state.fieldsEditable === false) {
            localValue = true;
        }
        return localValue
    }



    costToGenpactBuildChange = (e) => {
        if (/^\d{0,10}(\.\d{0,2})?$/.test(e)) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.GenpactDollar = textBoxValidation(true);
            this.setState({ GenpactDollar: e, selectStyle: styleStateObj }, () => { })
        } else {
            window.alert("You can add upto 10 digits and 2 decimal values only allowed.");
            return;
        }
    }

    sowScopePeiceOfWorkChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsSOWScope = selectStyle(true);
        this.setState({ IsSOWScope: e, selectStyle: styleStateObj, IsSOWSigned: [] }, () => { })


    }

    sowScopeSignedChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsSOWSigned = selectStyle(true);
        this.setState({
            IsSOWSigned: e, selectStyle: styleStateObj
            , SOWSubDate: "",
            SOWCloseDate: "",
        }, () => { })

    }




    expectedSOWSubmissionDateorSowSubmissionDateChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.SOWSubDate = textBoxValidation(true);
        this.setState({ SOWSubDate: e, selectStyle: styleStateObj }, () => { })

    }
    expectedSOWClosureDateorSowClosureDateChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.SOWCloseDate = textBoxValidation(true);
        this.setState({ SOWCloseDate: e, selectStyle: styleStateObj }, () => { })

    }
    expectedDeploymentDateDateorGoLiveeDateChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.CompletionDate = textBoxValidation(true);
        this.setState({ CompletionDate: e, selectStyle: styleStateObj }, () => { })

    }


    transitionLedChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.TransitionLed = selectStyle(true);
        this.setState({ TransitionLed: e, selectStyle: styleStateObj }, () => { })
    }
    domainKnowledgeChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.Knowledge = textBoxValidation(true);
            this.setState({
                Knowledge: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Knowledge.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                Knowledge: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Knowledge.replace(/'/g, "\\'");
            });
        }

    }
    algorithmChanged = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.Algo = textBoxValidation(true);
            this.setState({
                Algo: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Algo.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                Algo: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Algo.replace(/'/g, "\\'");
            });
        }

    }

    dataUsedChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.Data = textBoxValidation(true);
            this.setState({
                Data: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Data.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                Data: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Data.replace(/'/g, "\\'");
            });
        }

    }
    techInfraUsedChanged = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.Infra = textBoxValidation(true);
            this.setState({
                Infra: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Infra.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                Infra: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.Infra.replace(/'/g, "\\'");
            });
        }

    }

    shareHolderValueCreated = (e) => {
        let styleStateObj = this.state.selectStyle;
        let nonWhiteSpace = e.replace(/^\s+|\s+$/gm, '');;
        if (nonWhiteSpace.length < 300) {
            styleStateObj.ValueCreated = textBoxValidation(true);
            this.setState({
                ValueCreated: e,
                selectStyle: styleStateObj,
            }, () => {
                this.state.ValueCreated.replace(/'/g, "\\'");
            });
        } else {
            const trimmedValue = nonWhiteSpace.slice(0, 300);
            this.setState({
                ValueCreated: trimmedValue,
                selectStyle: styleStateObj,
            }, () => {
                this.state.ValueCreated.replace(/'/g, "\\'");
            });
        }

    }

    noOfFTEUsageChange = (e) => {
        if (e <= 5000) {
            if (/^\d{0,5}(\.\d{0,2})?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.FTEUsage = textBoxValidation(true);
                this.setState({ FTEUsage: e, selectStyle: styleStateObj }, () => {
                })
            }
            // e = Number(Math.floor(e+'e2')+'e-2');
        }
    }

    handleSFDCOLIChange = (e) => {
        let tempValue = e;
        if (tempValue.length <= 6) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.OLINum = textBoxValidation(true);
            this.setState({ OLINum: e, selectStyle: styleStateObj }, () => { })
        } else {
            window.alert("OLI number cannot be not more than 6 characters.")
        }
    }

    handleSFDCOppIDChange = (e) => {
        if (e.length <= 6) {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.OppID = textBoxValidation(true);
            this.setState({ OppID: e, selectStyle: styleStateObj }, () => { })
        } else {
            window.alert("Opp ID cannot be not more than 6 characters.")
        }
    }





    TotalPPLInTheProcess = (e) => {
        if (e <= 5000) {
            if (/^\d{0,5}?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.ProcessUsers = textBoxValidation(true);
                this.setState({ ProcessUsers: e, selectStyle: styleStateObj }, () => {
                })
            }
            
        }
    }

    PPLAccessSoln = (e) => {
        if (e <= 5000) {
            if (/^\d{0,5}?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.AccessUsers = textBoxValidation(true);
                this.setState({ AccessUsers: e, selectStyle: styleStateObj }, () => {
                })
            }
        }
    }

    PPLUsingDaily = (e) => {
        if (e <= 5000) {
            if (/^\d{0,5}?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.DailyUsers = textBoxValidation(true);
                this.setState({ DailyUsers: e, selectStyle: styleStateObj }, () => {
                })
            }
        }
    }

    PPLUsingWeekly = (e) => {
        if (e <= 5000) {
            if (/^\d{0,5}?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.WeeklyUsers = textBoxValidation(true);
                this.setState({ WeeklyUsers: e, selectStyle: styleStateObj }, () => {
                })
            }
        }
    }


    TotalWeeklyTransaction = (e) => {
        if (e <= 100000) {
            if (/^\d{0,6}?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.WeeklyTrans = textBoxValidation(true);
                this.setState({ WeeklyTrans: e, selectStyle: styleStateObj }, () => {
                })
            }
        }
    }

    GenAITransactionProcessedSoln = (e) => {
        if (e <= 100000) {
            if (/^\d{0,6}?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.SolnTrans = textBoxValidation(true);
                this.setState({ SolnTrans: e, selectStyle: styleStateObj }, () => {
                })
            }
        }
    }
    noOfUpVotes = (e) => {
        if (e <= 5000) {
            if (/^\d{0,5}?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.Upvotes = textBoxValidation(true);
                this.setState({ Upvotes: e, selectStyle: styleStateObj }, () => {
                })
            }
        }
    }

    PercentOfUpVotes = (e) => {
        if (e <= 100) {
            if (/^\d{0,3}(\.\d{0,2})?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.UpvotesPercent = textBoxValidation(true);
                this.setState({ UpvotesPercent: e, selectStyle: styleStateObj }, () => { })
            } else {
                window.alert("You can add upto 10 digits and 2 decimal values only allowed.");
                return;
            }
        }
    }

    AccuracyOfOutcome = (e) => {
        if (e <= 100) {
            if (/^\d{0,3}(\.\d{0,2})?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.Accuracy = textBoxValidation(true);
                this.setState({ Accuracy: e, selectStyle: styleStateObj }, () => { })
            } else {
                window.alert("You can add upto 10 digits and 2 decimal values only allowed.");
                return;
            }
        }
    }

    UptimeOfSoln = (e) => {
        if (e <= 100) {
            if (/^\d{0,3}(\.\d{0,2})?$/.test(e)) {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.UptimePercent = textBoxValidation(true);
                this.setState({ UptimePercent: e, selectStyle: styleStateObj }, () => { })
            } else {
                window.alert("You can add upto 10 digits and 2 decimal values only allowed.");
                return;
            }
        }
    }


    // fields functionality ends here





    // BI Function starts here
    addMonetizableValues = (e) => {
        let MonetizedBiArray = [];
        let fteValue = 0;
        let dollarValue = 0;
        this.state.businessImpactValueArray.filter((item) => {
            if (item.IsMonetized === "Yes") {
                MonetizedBiArray.push(item);
            }
        });
        if (MonetizedBiArray) {
            const groupedData = MonetizedBiArray.reduce(
                (accumulator, currentValue) => {
                    const impactType = currentValue.impactType;
                    const fteValue = parseFloat(currentValue.fteValue) || 0.0;
                    const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;
                    const existingRow = accumulator.find(
                        (row) => row.impactType === impactType
                    );
                    if (existingRow) {
                        existingRow.fteValue += fteValue;
                        existingRow.dollarValue += dollarValue;
                    } else {
                        accumulator.push({ impactType, fteValue, dollarValue });
                    }
                    return accumulator;
                },
                []
            );
            // console.log("grouped data",groupedData)

            if (groupedData.length === 0) {
                fteValue = 0;
                dollarValue = 0;
            } else {
                fteValue = groupedData[0].fteValue;
                dollarValue = groupedData[0].dollarValue;
            }
            this.setState(
                {
                    totalMonetizedFTE: fteValue,
                    totalMonetizedDollar: dollarValue,
                },
                () => { }
            );
        }
    };


    newBusinessImpactSummary() {
        let myArray = [...this.state.businessImpactValueArray];
        const groupedData = myArray.reduce((accumulator, currentValue) => {
            const impactType = currentValue.impactType;
            const monetizableType = currentValue.monetizableType;
            const fteValue = parseFloat(currentValue.fteValue) || 0.0;
            const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;

            const existingRow = accumulator.find(
                (row) =>
                    row.impactType === impactType &&
                    row.monetizableType === monetizableType
            );
            if (existingRow) {
                existingRow.fteValue += fteValue;
                existingRow.dollarValue += dollarValue;
            } else {
                accumulator.push({
                    impactType,
                    monetizableType,
                    fteValue,
                    dollarValue,
                });
            }
            return accumulator;
        }, []);
        this.setState({ summaryArray: groupedData }, () => { });
        this.addMonetizableValues();
    }

    pull_data = ({ businessImpactValueArray }) => {
        if (businessImpactValueArray) {
            this.setState(
                { businessImpactValueArray: businessImpactValueArray },
                () => {
                    this.newBusinessImpactSummary();
                    let dollarTotal = 0.0;
                    let fteValue = 0.0;
                    this.state.businessImpactValueArray.map((item) => {
                        if (item && item.DollarImpact) {
                            dollarTotal += parseFloat(item.DollarImpact);
                        }
                        if (item && item.dollarValue) {
                            dollarTotal += parseFloat(item.dollarValue);
                        }
                        if (item && item.FTEImpact) {
                            fteValue += parseFloat(item.FTEImpact);
                        }
                        if (item && item.fteValue) {
                            fteValue += parseFloat(item.fteValue);
                        }

                        if (item.ImpactType === "Impact at Genpact") {
                            if (item.MonetizableType === "Increase in Revenue" || item.MonetizableType === "Reduction in Cost") {
                                this.setState({ isFpnaRequired: true }, () => { })
                            }
                        }
                    });
                    this.setState({ finalImpactDollar: dollarTotal }, () => {
                        //console.log("final impact dollar =>",this.state.finalImpactDollar)
                    });
                    this.setState({ finalImpactFTE: fteValue }, () => {
                        //console.log("final impact FTE =>",this.state.finalImpactFTE)
                    });
                },
                () => {
                }
            );
        }

    };
    pull_file_data = ({
        fileName,
        fileType,
        fileContent,
        showClientApprovalFields,
        FPnAOHRMonetization,
    }) => {
        if (fileName) {
            this.setState({ FPnAFileNameMonetization: fileName }, () => { });
        }
        if (fileType) {
            this.setState({ FPnAFileTypeMonetization: fileType }, () => { });
        }
        if (fileContent) {
            this.setState({ FPnAFileMonetization: fileContent }, () => { });
        }
        this.setState(
            { showClientApprovalFields: showClientApprovalFields },
            () => { }
        );
        this.setState({ FPnAOHRMonetization: FPnAOHRMonetization }, () => {

        });
    };
    remove_bi_data_after_delete = (filteredIdeaArray) => {
        if (filteredIdeaArray) {
            this.setState({ businessImpactValueArray: filteredIdeaArray }, () => {
                this.newBusinessImpactSummary();
                let dollarTotal = 0.0;
                let fteValue = 0.0;
                this.state.businessImpactValueArray.map((item) => {
                    if (item && item.DollarImpact) {
                        dollarTotal += parseFloat(item.DollarImpact);
                    }
                    if (item && item.dollarValue) {
                        dollarTotal += parseFloat(item.dollarValue);
                    }
                    if (item && item.FTEImpact) {
                        fteValue += parseFloat(item.FTEImpact);
                    }
                    if (item && item.fteValue) {
                        fteValue += parseFloat(item.fteValue);
                    }
                });
                if (this.state.businessImpactValueArray.some(e => e.ImpactType === 'Impact at Genpact' && ['Increase in Revenue', 'Reduction in Cost'].includes(e.MonetizableType))) {
                    this.setState({isFpnaRequired:true},()=>{})
                }else{
                    this.setState({isFpnaRequired:false},()=>{})
                }
                this.setState({ finalImpactDollar: dollarTotal }, () => { });
                this.setState({ finalImpactFTE: fteValue }, () => { });
            });
        }
    };

    pull_monetization_sowId = (localSowId) => {
        this.setState({ SowID: localSowId }, () => { });
    };
    pull_monetization_fpna_name = (localFpnaName) => {
        this.setState({ FPnANameMonetization: localFpnaName }, () => { });
    };
    pull_monetization_fpna_ohr = (localFpnaohr) => {
        this.setState({ FPnAOHRMonetization: localFpnaohr }, () => { });
    };
    pull_monetizedTotal = ({ fteValue, dollarValue }) => {
        if (fteValue) {
            this.setState({ totalMonetizedDollar: fteValue }, () => { });
        }
        if (dollarValue) {
            this.setState({ totalMonetizedFTE: dollarValue }, () => { });
        }
    };
    pull_monetizableProject = (e) => {
        if (e) {
            this.setState({ monetizableProjectSelectedOption: e }, () => {
                if (this.state.monetizableProjectSelectedOption.label === "No") {
                    this.setState({ ClientDollar: 0 })
                }
            });
        }
    };
    pull_monetizableStatus = (e) => {
        if (e) {
            this.setState({ monetizationStatusSelectedOption: e }, () => {
                if (this.state.monetizationStatusSelectedOption.label !== this.state.oppDetails.MonetizationStatus) { }
            });
        }
    };

    handleVDTypeChange = ({ e }) => {
        if (e) {
            this.setState({ impactOptionSelected: e }, () => {
                if (this.state.impactOptionSelected.label === "No Value Delivery") {
                    this.setState(
                        {
                            showClientFields: false,
                            showGOLFields: false,
                            finalImpactDollar: 0,
                            monetizableProjectSelectedOption: null,
                            monetizationStatusSelectedOption: null,
                            showClientApprovalFields: false,
                            isFpnaRequired:false
                        },
                        () => { }
                    );
                }
                if (
                    this.state.impactOptionSelected.label === "Value Delivered to Client"
                ) {
                    this.setState(
                        { showClientFields: true, showGOLFields: true },
                        () => { }
                    );
                }
                if (
                    this.state.impactOptionSelected.label === "Value Delivered to Genpact"
                ) {
                    this.setState({ showClientFields: false, showGOLFields: true });
                }
                if (
                    this.state.impactOptionSelected.label ===
                    "Value Delivered to Client + Genpact"
                ) {
                    this.setState({ showClientFields: true, showGOLFields: true });
                }
            });
        }
    };
    fetchFPAOHRforIncreaseInRevenue = (e) => {
        if (e === true) {
            this.setState({ appropriateAttachment: true }, () => { });
        } else {
            this.setState({ appropriateAttachment: false }, () => { });
        }
    };

    pullMonetizationGOLName = (localGOLName) => {
        this.setState({ GOLNameMonetization: localGOLName }, () => {
            //console.log("pull monetization gol name =>",this.state.GOLNameMonetization)
        });
    };
    pullMonetizationGOLOhr = (localGOLOhr) => {
        this.setState({ GOLOHRMonetization: localGOLOhr }, () => {
            //console.log("pull monetization gol ohr =>",this.state.GOLOHRMonetization)
        });
    };
    pullCategoryNotListed = (localShowHref) => {
        this.setState({ showHrefModal: localShowHref }, () => {

        });
    };


    pullPPTDeck = ({ fileName, fileType, fileContent }) => {
        //console.log(fileName,fileType,fileContent)
        this.setState({ filename1: fileName, fileType1: fileType, base64File1: fileContent }, () => { })
    }
    pullClientDocName = (fileName) => {
        this.setState({ signOffFilename: fileName, }, () => { })
    }
    pullClientDocType = (fileType) => {
        if (fileType) {
            this.setState({ signOffFileType: fileType }, () => { })
        }
    }
    pullClientDocContent = (fileContent) => {
        if (fileContent) {
            this.setState({ signOffContent: fileContent }, () => { })
        }
    }

    // gol file details
    pullgolFileName = (fileName) => {
        this.setState({ filename3: fileName }, () => { })
    }
    pullgolFileType = (fileType) => {
        this.setState({ fileType3: fileType }, () => { })
    }
    pullgolContent = (fileContent) => {
        this.setState({ base64File3: fileContent }, () => { })
    }

    // fpna file details
    pullfpnafileName = (fileName) => {
        if (fileName) {
            this.setState({ filename4: fileName }, () => {
                // console.log("fpna doc name",this.state.filename4)
            })
        }
    }
    pullfpnafileType = (fileType) => {
        if (fileType) {
            this.setState({ fileType4: fileType }, () => {
                // console.log("fpna doc type",this.state.fileType4)
            })
        }
    }
    pullfpnafileContent = (fileContent) => {
        this.setState({ base64File4: fileContent }, () => {
            // console.log("fpna doc file content",this.state.base64File4)
        })
    }

    pullCustName = (e) => {
        this.setState({ CustName: e }, () => {
            //console.log(this.state.CustName)
        })
    }
    pullClientDesign = (e) => {
        this.setState({ CustDesgn: e }, () => {
            //console.log(this.state.CustDesgn)
        })
    }
    pullCustSignOffDate = (e) => {
        this.setState({ CustSignOffDate: e }, () => { })
    }
    pullGOLOHR = (e) => {
        this.setState({ GOLOHRVD: e }, () => {
            //console.log("pull GOL OHR VD=>",this.state.GOLOHRVD)
        })
    }
    pullGolName = (name) => {
        this.setState({ GOLNameVD: name }, () => {
            //console.log("pull GOL Name VD =>",this.state.GOLNameVD)
        })
    }
    pullFpnaName = (name) => {
        this.setState({ FPnAName: name }, () => {
            //console.log(this.state.FPnAName)
        })
    }
    pullFpnaOhr = (e) => {
        this.setState({ FPnAOHR: e }, () => {
            //console.log(this.state.FPnAOHR)
        })
    }
    pull_fileDetails = (obj) => {
        this.setState({ golFpnaFileDetails: obj }, () => { })
    }
    // BI Function ends here

    // validate function starts here
    validate = () => {
        let isValid = true;
        this.isExceedlimit = false;
        let styleStateObj = this.state.selectStyle;

        console.log("business impact item =>", this.state.businessImpactValueArray)

        console.log("local opp state =>", this.setOpportunityState())
        console.log("updater role => ", this.setUpdaterRole())
        console.log("check if vd is updated or no =>", this.checkIfVDUpdated())
        //console.log("email to be trigged for sow signed =>",this.triggerEmailIfSOWisSigned())


        if (this.state.OppState !== PENDING_APPROVAL_FROM_MBB) {
            // stages 2-3-4-5-6
            if (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5)) {
                if (!this.state.IsProposalSharedSelectedOption || this.state.IsProposalSharedSelectedOption === "" || this.state.IsProposalSharedSelectedOption === null || this.state.IsProposalSharedSelectedOption === undefined || this.state.IsProposalSharedSelectedOption.length === 0) {
                    //Toast.fail("1",3000,()=>{})
                    isValid = false;
                    styleStateObj.IsProposalSharedSelectedOption = selectStyle(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.IsProposalSharedSelectedOption = selectStyle(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }

            }

            if (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6)) {
                if (!this.state.FunctionalOHR || this.state.FunctionalOHR === "" || this.state.FunctionalOHR === null || this.state.FunctionalOHR === undefined || this.state.FunctionalOHR.length === 0) {
                    isValid = false;
                    //Toast.fail("2",3000,()=>{})
                    styleStateObj.FunctionalOHR = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.FunctionalOHR = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
                if (!this.state.Functional || this.state.Functional === "" || this.state.Functional === null || this.state.Functional === undefined || this.state.Functional.length === 0) {
                    isValid = false;
                    //Toast.fail("3",3000,()=>{})
                    styleStateObj.Functional = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.Functional = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
                if (!this.state.TransitionLed || this.state.TransitionLed === "" || this.state.TransitionLed === null || this.state.TransitionLed === undefined || this.state.TransitionLed.length === 0) {
                    isValid = false;
                    // Toast.fail("4",3000,()=>{})
                    styleStateObj.TransitionLed = selectStyle(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.TransitionLed = selectStyle(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
            }
            
            // stages 3-4-5
            if (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5)) {
                //service line and area/process validation starts here
                if (!this.state.ServiceSelectedOption || this.state.ServiceSelectedOption === "" || this.state.ServiceSelectedOption === null || this.state.ServiceSelectedOption === undefined || this.state.ServiceSelectedOption.length === 0) {
                    isValid = false;
                    //Toast.fail("6",3000,()=>{})
                    styleStateObj.ServiceSelectedOption = selectStyle(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.ServiceSelectedOption = selectStyle(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }

                // validation to be added for the predominant service line
                if (!this.state.ServiceLineMain || this.state.ServiceLineMain === "" || this.state.ServiceLineMain === null || this.state.ServiceLineMain === undefined || this.state.ServiceLineMain.length === 0) {
                    isValid = false;
                    //Toast.fail("6",3000,()=>{})
                    styleStateObj.ServiceLineMain = selectStyle(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.ServiceLineMain = selectStyle(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }

                if (this.state.ServiceSelectedOption) {
                    if (!this.state.Process_L2SelectedOption || this.state.Process_L2SelectedOption === "" || this.state.Process_L2SelectedOption === null || this.state.Process_L2SelectedOption === undefined || this.state.Process_L2SelectedOption.length === 0) {
                        isValid = false;
                        //Toast.fail(" 7",3000,()=>{})
                        styleStateObj.Process_L2SelectedOption = selectStyle(false);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    } else {
                        styleStateObj.Process_L2SelectedOption = selectStyle(true);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    }
                }
                //service line and area/process validation ends here

                // Hero Product Offering  validation starts here

                if (!this.state.Offering_1SelectedOption || this.state.Offering_1SelectedOption === "" || this.state.Offering_1SelectedOption === null || this.state.Offering_1SelectedOption === undefined || this.state.Offering_1SelectedOption.length === 0) {
                    isValid = false;
                    //Toast.fail(" 8",3000,()=>{})
                    styleStateObj.Offering_1SelectedOption = selectStyle(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.Offering_1SelectedOption = selectStyle(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }

                if (this.state.Offering_1SelectedOption && this.state.Offering_1SelectedOption.label === "Bespoke") {
                    if (!this.state.Offering_2_Other || this.state.Offering_2_Other === "" || this.state.Offering_2_Other === null || this.state.Offering_2_Other === undefined || this.state.Offering_2_Other.length === 0) {
                        isValid = false;
                        //Toast.fail("11",3000,()=>{})
                        styleStateObj.Offering_2_Other = textBoxValidation(false);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    } else {
                        styleStateObj.Offering_2_Other = textBoxValidation(true);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    }
                } else {
                    if (!this.state.Offering_2SelectedOption || this.state.Offering_2SelectedOption === "" || this.state.Offering_2SelectedOption === null || this.state.Offering_2SelectedOption === undefined || this.state.Offering_2SelectedOption.length === 0) {
                        isValid = false;
                        //Toast.fail("10",3000,()=>{})
                        styleStateObj.Offering_2SelectedOption = selectStyle(false);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    } else {
                        styleStateObj.Offering_2SelectedOption = selectStyle(true);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    }

                }

                // Hero Product Offering  validation ends here

                if (!this.state.ClientDtls || this.state.ClientDtls === "" || this.state.ClientDtls === null || this.state.ClientDtls === undefined || this.state.ClientDtls.length === 0) {
                    isValid = false;
                    //Toast.fail("12",3000,()=>{})
                    styleStateObj.ClientDtls = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.ClientDtls = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }

                if (!this.state.Problem || this.state.Problem === "" || this.state.Problem === null || this.state.Problem === undefined || this.state.Problem.length === 0) {
                    isValid = false;
                    //Toast.fail("13",3000,()=>{})
                    styleStateObj.Problem = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.Problem = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }

                if (!this.state.Solution || this.state.Solution === "" || this.state.Solution === null || this.state.Solution === undefined || this.state.Solution.length === 0) {
                    isValid = false;
                    //Toast.fail("14",3000,()=>{})
                    styleStateObj.Solution = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.Solution = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }

                if (this.state.OverallFTE === "" || this.state.OverallFTE === null || this.state.OverallFTE === undefined) {
                    isValid = false;
                    //Toast.fail("15",3000,()=>{})
                    styleStateObj.OverallFTE = textBoxValidation(false);
                    this.setState({ selectStyle: styleStateObj, });
                } else {
                    styleStateObj.OverallFTE = textBoxValidation(true);
                    this.setState({ selectStyle: styleStateObj, });
                }

                if (this.state.Offering_1SelectedOption && this.state.Offering_1SelectedOption.label === "CKA") {
                    if (this.state.OverallSOP === "" || this.state.OverallSOP === null || this.state.OverallSOP === undefined) {
                        isValid = false;
                        //Toast.fail("16",3000,()=>{})
                        styleStateObj.OverallSOP = textBoxValidation(false);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    } else {
                        styleStateObj.OverallSOP = textBoxValidation(true);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    }
                }



            }
            // stages 2-3-4-5
            if (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) {
                if (!this.state.CXO || this.state.CXO === "" || this.state.CXO === null || this.state.CXO === undefined || this.state.CXO.length === 0) {
                    isValid = false;
                    //Toast.fail("17",3000,()=>{})
                    styleStateObj.CXO = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.CXO = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
            }

            // stage 2
            if (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2)) {
                if (!this.state.PlanDiscussDate || this.state.PlanDiscussDate === "" || this.state.PlanDiscussDate === null || this.state.PlanDiscussDate === undefined || this.state.PlanDiscussDate.length === 0) {
                    isValid = false;
                    //Toast.fail("18",3000,()=>{})
                    styleStateObj.PlanDiscussDate = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.PlanDiscussDate = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }

                if (!this.state.DiscussEstDate || this.state.DiscussEstDate === "" || this.state.DiscussEstDate === null || this.state.DiscussEstDate === undefined || this.state.DiscussEstDate.length === 0) {
                    isValid = false;
                    //Toast.fail("19",3000,()=>{})
                    styleStateObj.DiscussEstDate = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.DiscussEstDate = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }


            }

            if (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6)) {

                if (!this.state.SolnEnviron || this.state.SolnEnviron === "" || this.state.SolnEnviron === null || this.state.SolnEnviron === undefined || this.state.SolnEnviron.length === 0) {
                    isValid = false;
                    //Toast.fail("20",3000,()=>{})
                    styleStateObj.SolnEnviron = selectStyle(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.SolnEnviron = selectStyle(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }

                // solution funding starts here
                if (!this.state.SolnFund || this.state.SolnFund === "" || this.state.SolnFund === null || this.state.SolnFund === undefined || this.state.SolnFund.length === 0) {
                    isValid = false;
                    //Toast.fail("21",3000,()=>{})
                    styleStateObj.SolnFund = selectStyle(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.SolnFund = selectStyle(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }

                if (this.state.SolnFund && (this.state.SolnFund.value === 1 || this.state.SolnFund.value === 3)) {
                    if (this.state.oppDetails.IsVDMonetizable && this.state.oppDetails.IsVDMonetizable === "No") {
                        styleStateObj.ClientDollar = textBoxValidation(true);
                        this.setState({ selectStyle: styleStateObj, });
                    }
                    if (this.state.oppDetails.IsVDMonetizable === "" || this.state.oppDetails.IsVDMonetizable === null || this.state.oppDetails.IsVDMonetizable === undefined || this.state.oppDetails.IsVDMonetizable === "Yes") {
                        if (this.state.ClientDollar === "" || this.state.ClientDollar === null || this.state.ClientDollar === undefined) {
                            isValid = false;
                            //Toast.fail("22",3000,()=>{})
                            styleStateObj.ClientDollar = textBoxValidation(false);
                            this.setState({
                                selectStyle: styleStateObj,
                            });

                        } else {
                            styleStateObj.ClientDollar = textBoxValidation(true);
                            this.setState({
                                selectStyle: styleStateObj,
                            });
                        }
                    }
                }
                //console.log("genpact dollar =>", this.state.GenpactDollar)

                if (this.state.SolnFund && (this.state.SolnFund.value === 2 || this.state.SolnFund.value === 3)) {
                    if (this.state.GenpactDollar === "" || this.state.GenpactDollar === null || this.state.GenpactDollar === undefined || this.state.GenpactDollar.length === 0) {
                        isValid = false;
                        //Toast.fail("23",3000,()=>{})
                        styleStateObj.GenpactDollar = textBoxValidation(false);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    } else {
                        styleStateObj.GenpactDollar = textBoxValidation(true);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    }
                }
                // solution funding ends here

                //sow validation starts here
                if (!this.state.IsSOWScope || this.state.IsSOWScope === "" || this.state.IsSOWScope === null || this.state.IsSOWScope === undefined || this.state.IsSOWScope.length === 0) {
                    isValid = false;
                    //Toast.fail("24",3000,()=>{})
                    styleStateObj.IsSOWScope = selectStyle(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.IsSOWScope = selectStyle(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
                if (this.state.IsSOWScope && this.state.IsSOWScope.label === "Yes") {
                    if (!this.state.IsSOWSigned || this.state.IsSOWSigned === "" || this.state.IsSOWSigned === null || this.state.IsSOWSigned === undefined || this.state.IsSOWSigned.length === 0) {
                        isValid = false;
                        //Toast.fail("25",3000,()=>{})
                        styleStateObj.IsSOWSigned = selectStyle(false);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    } else {
                        styleStateObj.IsSOWSigned = selectStyle(true);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    }
                    if (!this.state.SOWSubDate || this.state.SOWSubDate === "" || this.state.SOWSubDate === null || this.state.SOWSubDate === undefined || this.state.SOWSubDate.length === 0) {
                        isValid = false;
                        //Toast.fail("26",3000,()=>{})
                        styleStateObj.SOWSubDate = textBoxValidation(false);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    } else {
                        styleStateObj.SOWSubDate = textBoxValidation(true);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    }

                    if (!this.state.SOWCloseDate || this.state.SOWCloseDate === "" || this.state.SOWCloseDate === null || this.state.SOWCloseDate === undefined || this.state.SOWCloseDate.length === 0) {
                        isValid = false;
                        //Toast.fail("27",3000,()=>{})
                        styleStateObj.SOWCloseDate = textBoxValidation(false);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    } else {
                        styleStateObj.SOWCloseDate = textBoxValidation(true);
                        this.setState({
                            selectStyle: styleStateObj,
                        });
                    }


                }
                if (!this.state.CompletionDate || this.state.CompletionDate === "" || this.state.CompletionDate === null || this.state.CompletionDate === undefined || this.state.CompletionDate.length === 0) {
                    isValid = false;
                    //Toast.fail("28",3000,()=>{})
                    styleStateObj.CompletionDate = textBoxValidation(false);
                    this.setState({ selectStyle: styleStateObj, });
                } else {
                    styleStateObj.CompletionDate = textBoxValidation(true);
                    this.setState({ selectStyle: styleStateObj });
                }
                //sow validation ends here
                if (!this.state.TechOHR || this.state.TechOHR === "" || this.state.TechOHR === null || this.state.TechOHR === undefined || this.state.TechOHR.length === 0) {
                    isValid = false;
                    //Toast.fail("29",3000,()=>{})
                    styleStateObj.TechOHR = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.TechOHR = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }

                if (!this.state.Tech || this.state.Tech === "" || this.state.Tech === null || this.state.Tech === undefined || this.state.Tech.length === 0) {
                    isValid = false;
                    //Toast.fail("30",3000,()=>{})
                    styleStateObj.Tech = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.Tech = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
                if (!this.state.Knowledge || this.state.Knowledge === "" || this.state.Knowledge === null || this.state.Knowledge === undefined || this.state.Knowledge.length === 0) {
                    isValid = false;
                    //Toast.fail("31",3000,()=>{})
                    styleStateObj.Knowledge = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.Knowledge = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }

                if (!this.state.Algo || this.state.Algo === "" || this.state.Algo === null || this.state.Algo === undefined || this.state.Algo.length === 0) {
                    isValid = false;
                    //Toast.fail("32",3000,()=>{})
                    styleStateObj.Algo = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.Algo = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }

                if (!this.state.Data || this.state.Data === "" || this.state.Data === null || this.state.Data === undefined || this.state.Data.length === 0) {
                    isValid = false;
                    //Toast.fail("33",3000,()=>{})
                    styleStateObj.Data = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.Data = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }

                if (!this.state.Infra || this.state.Infra === "" || this.state.Infra === null || this.state.Infra === undefined || this.state.Infra.length === 0) {
                    isValid = false;
                    //Toast.fail("34",3000,()=>{})
                    styleStateObj.Infra = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.Infra = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
            }

            if (this.state.OppStageSelectedOption && this.state.OppStageSelectedOption.value === 6) {
                if (this.state.FTEUsage === "" || this.state.FTEUsage === null || this.state.FTEUsage === undefined) {
                    isValid = false;
                    //Toast.fail("35",3000,()=>{})
                    styleStateObj.FTEUsage = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.FTEUsage = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
            }
        }
        // BI Validation starts here
        if (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6)) {
            if (this.state.impactOptionSelected === undefined || this.state.impactOptionSelected === null || !this.state.impactOptionSelected) {
                isValid = false;
                //Toast.fail("36",3000,()=>{})
                Toast.fail("Please fill up value delivery and submit.", 5000, () => { })
            }
        }
        if (this.state.OppStageSelectedOption) {
            if (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) {
                if (this.state.impactOptionSelected === null) {
                    isValid = false;
                    //Toast.fail("37",3000,()=>{})
                    Toast.fail("Please select Value Delivery Type", 3000, () => { });
                }
                console.log(this.state.businessImpactValueArray)
                if (this.state.impactOptionSelected && this.state.impactOptionSelected.label !== "No Value Delivery") {
                    if (this.state.businessImpactValueArray.length === 0) {
                        Toast.fail("Value Delivery cannot be empty, please fill and resubmit.", 3000, () => { });
                        //Toast.fail("38",3000,()=>{})
                        isValid = false;
                    } else {
                        if (this.state.finalImpactDollar <= 0) {
                            Toast.fail("Total $ Impact cannot be zero. Please check and resubmit.", 3000, () => { });
                            isValid = false;
                            //Toast.fail("39",3000,()=>{})

                        }
                        if (this.state.finalImpactFTE > 0 && this.state.finalImpactDollar <= 0) {
                            Toast.fail("Total $ Impact cannot be zero when FTE Impact is non-zero. Please check and resubmit.", 3000, () => { });
                            isValid = false;
                            //Toast.fail("40",3000,()=>{})
                        }

                        let showDollarZeroErrorAnnualised = false;
                        let showDollarZeroErrorDeploymentYear = false;

                        this.state.businessImpactValueArray.filter((item, index) => {
                            // console.log("business impact value array =>",item)
                            if (parseFloat(item.DollarImpact) === 0 || parseFloat(item.DollarImpact) === "" || !item.DollarImpact || item.DollarImpact === null) {
                                isValid = false;
                                showDollarZeroErrorAnnualised = true;
                            }
                            if (parseFloat(item.DollarImpactYear) === 0 || parseFloat(item.DollarImpactYear) === "" || !item.DollarImpactYear || item.DollarImpactYear === null) {
                                isValid = false;
                                showDollarZeroErrorDeploymentYear = true;
                            }
                            if (item && item.isFTEPresent === true) {
                                if (parseFloat(item.FTEImpact)) {
                                } else {
                                    isValid = false;
                                    Toast.fail("Annualised FTE Impact value cannot be zero.", 3000, () => { });
                                }
                                if (parseFloat(item.FTEImpact) === 0) {
                                    isValid = false;
                                    Toast.fail("Annualised FTE Impact value cannot be zero.", 3000, () => { });
                                }
                                if (parseFloat(item.FTEImpact) > parseFloat(item.DollarImpact)) {
                                    isValid = false;
                                    Toast.fail("Annualised FTE Impact value cannot be greater than $ Impact value", 3000, () => { });
                                }

                                // deployment year condition starts here
                                if (parseFloat(item.FTEImpactYear)) {
                                } else {
                                    isValid = false;
                                    Toast.fail("Deployment Year FTE Impact value cannot be zero.", 3000, () => { });
                                }
                                if (parseFloat(item.FTEImpactYear) === 0) {
                                    isValid = false;
                                    Toast.fail(" Deployment Year FTE Impact value cannot be zero.", 3000, () => { });
                                }
                                if (parseFloat(item.FTEImpactYear) > parseFloat(item.DollarImpactYear)) {
                                    isValid = false;
                                    Toast.fail("Deployment Year FTE Impact cannot be greater than Deployment Year $ Impact value.", 3000, () => { });
                                }
                                // deployment year condition ends here
                            }
                        });

                        if(showDollarZeroErrorAnnualised){
                            Toast.fail("Annualised $ Impact value cannot be zero.", 3000, () => { });
                        }
                        if(showDollarZeroErrorDeploymentYear){
                            Toast.fail("Deployment Year $ Impact value cannot be zero.", 3000, () => { });
                        }
                    }

                    if (this.state.impactOptionSelected && this.state.impactOptionSelected.label !== "No Value Delivery") {
                        if (this.state.monetizableProjectSelectedOption === null ||
                            this.state.monetizableProjectSelectedOption.length === 0 ||
                            this.state.monetizableProjectSelectedOption === undefined
                        ) {
                            isValid = false;
                            Toast.fail("Please select monetizatable project", 5000, () => { });
                            //Toast.fail("45",3000,()=>{})
                        }
                    }

                    if (this.state.impactOptionSelected.label === "Value Delivered to Client + Genpact") {
                        if (this.state.businessImpactValueArray.length > 0) {
                            let impactGenpactTrue = this.state.businessImpactValueArray.some((vendor) => vendor["ImpactType"] === "Impact at Genpact");
                            let impactClientTrue = this.state.businessImpactValueArray.some((vendor) => vendor["ImpactType"] === "Impact at Client");
                            if (impactGenpactTrue === false) {
                                isValid = false;
                                Toast.fail("Please add both Impact types.", 3000, () => { });
                                //Toast.fail("46",3000,()=>{})
                            }
                            if (impactClientTrue === false) {
                                isValid = false;
                                Toast.fail("Please add both Impact types.", 3000, () => { });
                                //Toast.fail("47",3000,()=>{})
                            }
                        }
                    }
                    if (this.state.impactOptionSelected) {
                        if (this.state.monetizableProjectSelectedOption && this.state.monetizableProjectSelectedOption.label === "Yes") {
                            if (this.state.monetizationStatusSelectedOption === null || !this.state.monetizationStatusSelectedOption ||
                                this.state.monetizationStatusSelectedOption === undefined || this.state.monetizationStatusSelectedOption.length === 0
                            ) {
                                isValid = false;
                                Toast.fail("Since this project has been marked as Monetizable, monetizable status has to be selected", 5000, () => { });
                            }

                            if (this.state.monetizationStatusSelectedOption && this.state.monetizationStatusSelectedOption.label === "Approved by Client") {
                                if (this.state.OppStageSelectedOption && this.state.OppStageSelectedOption.value === 6) {
                                    if (this.state.GOLOHRMonetization === "" || this.state.GOLOHRMonetization === null || !this.state.GOLOHRMonetization || this.state.GOLOHRMonetization === undefined) {
                                        isValid = false;
                                        Toast.fail("Please fill up GOL OHR to fetch GOL Name for Monetization", 3000, () => { })
                                    }
                                    if (this.state.GOLOHRMonetization) {
                                        if (this.state.GOLNameMonetization === "" || this.state.GOLNameMonetization === null || this.state.GOLNameMonetization === undefined || !this.state.GOLNameMonetization) {
                                            isValid = false;
                                            Toast.fail("Please fill up GOL OHR for GOL Name Monetization", 3000, () => { })
                                        }
                                    }
                                    if (this.state.FPnAOHRMonetization === null || this.state.FPnAOHRMonetization === "" || this.state.FPnAOHRMonetization === undefined || !this.state.FPnAOHRMonetization) {
                                        isValid = false;
                                        Toast.fail("Please fill up FP&A OHR Monetization for Monetization", 3000, () => { })
                                    }
                                    if (this.state.FPnAOHRMonetization) {
                                        if (this.state.FPnANameMonetization === "" || this.state.FPnANameMonetization === null || this.state.FPnANameMonetization === undefined || !this.state.FPnANameMonetization) {
                                            isValid = false;
                                            Toast.fail("Please fill up FP&A OHR to fetch FP&A Name for Monetization", 3000, () => { })
                                        }
                                    }
                                    if (this.state.SowID === "" || this.state.SowID === null || this.state.SowID === undefined || this.state.SowID.length === 0) {
                                        isValid = false;
                                        Toast.fail("Please fill SOW ID/SFDC/OLI ID for monetization.", 3000, () => { })
                                    }
                                }


                            }
                        }
                        if (this.state.monetizableProjectSelectedOption !== null && this.state.monetizationStatusSelectedOption !== null) {
                            if (this.state.monetizableProjectSelectedOption.label === "Yes")
                                if (this.state.businessImpactValueArray.filter((item) => item.IsMonetized === "Yes").length > 0) { }
                                else {
                                    isValid = false;
                                    Toast.fail("Since this is a monetizable project, there needs to be at least one impact type as ‘Impact at Genpact’ selected and category selected as a ‘Increase in Revenue’ .", 5000, () => { });
                                }
                        }
                    }
                }


                if (this.state.OppStageSelectedOption && this.state.OppStageSelectedOption.value === 6) {
                    if (this.state.showClientFields) {
                        if (this.state.CustDesgn === "" || this.state.CustDesgn === null || this.state.CustDesgn === undefined || !this.state.CustDesgn) {
                            isValid = false
                            Toast.fail("Please fill Client Role.", 3000, () => { });
                        }
                        if (this.state.CustSignOffDate === null || this.state.CustSignOffDate === undefined || this.state.CustSignOffDate === "" || !this.state.CustSignOffDate) {
                            isValid = false
                            Toast.fail("Please fill Client Sign-off date.", 3000, () => { });
                        }
                        if (this.state.CustName === null || this.state.CustName === undefined || this.state.CustName === "" || !this.state.CustName) {
                            isValid = false
                            Toast.fail("Please fill Client Name.", 3000, () => { });
                        }

                    }

                    if (this.state.showGOLFields) {
                        if (this.state.GOLOHRVD === "" || this.state.GOLOHRVD === null || this.state.GOLOHRVD === undefined || !this.state.GOLOHRVD) {
                            isValid = false;
                            Toast.fail("Please fill GOL OHR.", 3000, () => { })
                        }

                        if (this.state.oppDetails.GOLOHRVD) {
                            if (this.state.GOLNameVD === "" || this.state.GOLNameVD === null || this.state.GOLNameVD === undefined || this.state.GOLNameVD.length === 0 || !this.state.GOLNameVD) {
                                isValid = false;
                                Toast.fail("Please fill GOL OHR to fetch GOL Name.", 3000, () => { })
                            }
                        }

                        // if (this.state.golFpnaFileDetails !== null || !this.state.golFpnaFileDetails) {
                        //     if (this.state.golFpnaFileDetails[0].label === "" && this.state.golFpnaFileDetails[1].label === "") {
                        //         isValid = false;
                        //         Toast.fail("Please upload the GOL / FP&A Approval Document", 3000, () => { })
                        //     }
                        // }
                    }
                }


                if (this.state.impactOptionSelected && this.state.impactOptionSelected.label !== "No Value Delivery") {
                    if (this.state.showGOLFields) {
                        this.state.businessImpactValueArray && this.state.businessImpactValueArray.map((item) => {
                            if (item.ImpactType === "Impact at Genpact") {
                                if (item.MonetizableType === "Increase in Revenue" || item.MonetizableType === "Reduction in Cost") {
                                    if (!this.state.FPnAOHR || this.state.FPnAOHR === "" || this.state.FPnAOHR === undefined || this.state.FPnAOHR === null) {
                                        isValid = false;
                                        Toast.fail("Please fill FP&A OHR.", 3000, () => { })
                                    }
                                    if (this.state.FPnAOHR) {
                                        if (!this.state.FPnAName || this.state.FPnAName === null || this.state.FPnAName === undefined || this.state.FPnAName === "") {
                                            isValid = false;
                                            Toast.fail("Please fill FP&A OHR to fetch FP&A Name.", 3000, () => { })
                                        }
                                    }
                                }
                            }
                        });
                    }
                }
            }
        }
        // BI Validation ends here

        console.log("opp details opp state =>", this.state.oppDetails.OppState)

        if (this.state.oppDetails.OppState === PENDING_APPROVAL_FROM_SOLUTION_OWNER) {
            //console.log("inside solution owner condition")
            isValid = false;
            Toast.fail("When opportunity state is Pending Approval from Solution Owner, it has to be approved from the solution owner's queue.", 8000, () => { })
        }
        if (this.state.oppDetails.OppState === PENDING_APPROVAL_FROM_CENTRAL_TEAM) {
            isValid = false;
            Toast.fail("When opportunity state is Pending Approval from central team, it has to be approved from the central team's queue.", 8000, () => { })
        }


        if (this.state.OppState === PENDING_APPROVAL_FROM_MBB) {
            isValid = false;
            Toast.fail("When opportunity state is Pending Approval from MBB, it has to be approved from the Master Blackbelt's queue.", 8000, () => { })
        }
        if (this.state.oppDetails.OppState === PENDING_APPROVAL_FROM_UNIT_SPOC) {
            isValid = false;
            Toast.fail("When opporutnity is Pending Approval from Unit SPOC, it has to be approved from the Unit SPOC's queue.", 8000, () => { })
        }
        if (this.state.OppStageSelectedOption && this.state.OppStageSelectedOption.value === 6) {
            if (this.state.isUserCentralTeam) {
                isValid = false;
                Toast.fail("Central team cannot update the opportunity.", 5000, () => { })
            }
            if (this.state.oppDetails.OppState === PENDING_APPROVAL_FROM_MBB) {
                Toast.fail("To proceed with the opportunity deployment, value delivery must be approved by the MBB. ", 5000, () => { })
                isValid = false;
            }
        }


        if (this.state.oppDetails.OppStage) {
            if (this.state.oppDetails.OppStage === "1. Client discussion - Not Started") {
                if (
                    this.state.OppStageSelectedOption.value === 1 ||
                    this.state.OppStageSelectedOption.value === 2 ||
                    this.state.OppStageSelectedOption.value === 3 ||
                    this.state.OppStageSelectedOption.value === 5 ||
                    this.state.OppStageSelectedOption.value === 7 ||
                    this.state.OppStageSelectedOption.value === 8 ||
                    this.state.OppStageSelectedOption.value === 9

                ) {

                } else {
                    isValid = false;
                    Toast.fail("From 1st stage, you cannot select 4th or 6th stages.", 3000, () => { })
                }
            }
            if (this.state.oppDetails.OppStage === "2. Client Discussion - Ongoing") {
                if (
                    this.state.OppStageSelectedOption.value === 1 ||
                    this.state.OppStageSelectedOption.value === 2 ||
                    this.state.OppStageSelectedOption.value === 3 ||
                    this.state.OppStageSelectedOption.value === 5 ||
                    this.state.OppStageSelectedOption.value === 7 ||
                    this.state.OppStageSelectedOption.value === 8 ||
                    this.state.OppStageSelectedOption.value === 9
                ) {

                } else {
                    isValid = false;
                    Toast.fail("From 2nd stage, you can only select 3rd, 5th, 7th, 8th or 9th stages.", 3000, () => { })
                }
            }
            if (this.state.oppDetails.OppStage === "3. Demonstrable POC in Flight") {
                if (this.state.OppStageSelectedOption.value === 1 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 9) {

                }
                else {
                    isValid = false;
                    Toast.fail("From 3rd stage, you can only select 4th or 9th stages only.", 3000, () => { })
                }


            }

            if (this.state.oppDetails.OppStage === "4. Demonstrable POC Complete") {
                if (
                    this.state.OppStageSelectedOption.value === 1 ||
                    this.state.OppStageSelectedOption.value === 2 ||
                    this.state.OppStageSelectedOption.value === 3 ||
                    this.state.OppStageSelectedOption.value === 6 ||
                    this.state.OppStageSelectedOption.value === 8
                ) {
                    isValid = false;
                    Toast.fail("From 4th stage, you can only select 5th, 7th or 9th stages only.", 3000, () => { })
                }
            }

            if (this.state.oppDetails.OppStage === "5. Production grade Build in Flight") {
                if (
                    this.state.OppStageSelectedOption.value === 1 ||
                    this.state.OppStageSelectedOption.value === 2 ||
                    this.state.OppStageSelectedOption.value === 3 ||
                    this.state.OppStageSelectedOption.value === 4 ||
                    //this.state.OppStageSelectedOption.value === 5 ||
                    this.state.OppStageSelectedOption.value === 7 ||
                    this.state.OppStageSelectedOption.value === 8

                ) {
                    isValid = false;
                    Toast.fail("From 5th stage, you can only select 6th or 9th stage only.", 3000, () => { })
                }
            }

            if (this.state.oppDetails.OppStage === "6. Production Grade Build Complete" || this.state.oppDetails.OppStage === "7. Declined by Client" || this.state.oppDetails.OppStage === "8. Descoped") {
                if (
                    this.state.OppStageSelectedOption.value === 1 ||
                    this.state.OppStageSelectedOption.value === 2 ||
                    this.state.OppStageSelectedOption.value === 3 ||
                    this.state.OppStageSelectedOption.value === 4 ||
                    this.state.OppStageSelectedOption.value === 5 ||
                    //this.state.OppStageSelectedOption.value === 6 ||
                    this.state.OppStageSelectedOption.value === 7 ||
                    this.state.OppStageSelectedOption.value === 8 ||
                    this.state.OppStageSelectedOption.value === 9
                ) {
                    isValid = false;
                    Toast.fail("During this stage, you cannot make the stage changes.", 3000, () => { })
                }

            }

            if (this.state.oppDetails.OppStage === "9. On-hold") {
                if (
                    this.state.OppStageSelectedOption.value === 6 ||
                    this.state.OppStageSelectedOption.value === 7 ||
                    this.state.OppStageSelectedOption.value === 8
                ) {
                    isValid = false;
                    Toast.fail("From 9th stage, you can select 1st,2nd ,3rd ,4th or 5th stages only.", 3000, () => { })

                }
            }
        }


        return isValid;
    }
    // validate function ends here


    // submit opp function starts here
    setSaveType = () => {
        let localSaveType = 'UpdateGenAIFields';
        return localSaveType;
    }

    triggerEmailIfSOWisSigned = () => {
        let localValue = false;
        if (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 5)) {
            if (this.state.oppDetails.IsSOWSigned) {
                if (this.state.oppDetails.IsSOWSigned === "Yes") {
                    localValue = false;
                }
                if (this.state.oppDetails.IsSOWSigned === "No") {
                    localValue = false;
                }
            }
            if (this.state.oppDetails.IsSOWSigned === "" || this.state.oppDetails.IsSOWSigned === null || this.state.oppDetails.IsSOWSigned === undefined || !this.state.oppDetails.IsSOWSigned) {
                if (this.state.IsSOWSigned && this.state.IsSOWSigned.label === "Yes") {
                    localValue = true
                }
            }
        }
        return localValue;
    }

    checkIfVDUpdated = () => {
        let localValue = false;
        if (this.state.isUserBB || this.state.isUserOppSPOC || this.state.isUserUnitSPOC || this.state.isUserSolOwner) {
            if (this.state.impactOptionSelected && this.state.oppDetails.VDType) {
                if (this.state.impactOptionSelected.label !== this.state.oppDetails.VDType) {
                    localValue = true;
                }
            }

            if (this.state.monetizableProjectSelectedOption && this.state.oppDetails.IsVDMonetizable) {
                if (this.state.monetizableProjectSelectedOption.label !== this.state.oppDetails.IsVDMonetizable) {
                    localValue = true;
                }
            }
            if (this.state.monetizationStatusSelectedOption && this.state.oppDetails.MonetizationStatus) {
                if (this.state.monetizationStatusSelectedOption.label !== this.state.oppDetails.MonetizationStatus) {
                    localValue = true;
                }
            }
            if (this.state.finalImpactFTE && this.state.oppDetails.FinalImpactFTE) {
                if (this.state.finalImpactFTE !== this.state.oppDetails.FinalImpactFTE) {
                    localValue = true;
                }
            }
            if (this.state.finalImpactDollar && this.state.oppDetails.FinalImpactDollar) {
                if (parseInt(this.state.finalImpactDollar) !== parseInt(this.state.oppDetails.FinalImpactDollar)) {
                    localValue = true;
                }
            }

            if(this.state.BiData){
                let oppDetailsDollarImpactYear = 0.0;
                let oppDetailsFTEImpactYear = 0.0;
                let BiDollarImpactYear = 0.0;
                let BiFTEImpactYear = 0.0;
                
                this.state.BiData.map((item) => {
                    if (item && item.DollarImpactYear) {
                        oppDetailsDollarImpactYear += parseFloat(item.DollarImpactYear);
                    }
                    if (item && item.FTEImpactYear) {
                        oppDetailsFTEImpactYear += parseFloat(item.FTEImpactYear);
                    }
                })
                this.state.businessImpactValueArray && this.state.businessImpactValueArray.map((item)=>{
                    if (item && item.DollarImpactYear) {
                        BiDollarImpactYear += parseFloat(item.DollarImpactYear);
                    }
                    if (item && item.FTEImpactYear) {
                        BiFTEImpactYear += parseFloat(item.FTEImpactYear);
                    }
                })
                if(oppDetailsDollarImpactYear !== BiDollarImpactYear){
                    localValue = true;
                }
                if(oppDetailsFTEImpactYear !== BiFTEImpactYear){
                    localValue = true;
                }
            }

            

            if (this.state.CustName && this.state.oppDetails.CustName) {
                if (this.state.CustName !== this.state.oppDetails.CustName) {
                    localValue = true;
                }
            }
            if (this.state.CustDesgn && this.state.oppDetails.CustDesgn) {
                if (this.state.CustDesgn !== this.state.oppDetails.CustDesgn) {
                    localValue = true;
                }
            }
            if (this.state.CustSignOffDate && this.state.oppDetails.CustSignOffDate) {
                let localClientSignOffDate = new Date(this.state.oppDetails.CustSignOffDate).toLocaleDateString('en-CA').split('/').reverse().join('-');
                if (this.state.CustSignOffDate !== localClientSignOffDate) {
                    localValue = true;
                }
            }
            if (this.state.GOLOHRVD && this.state.oppDetails.GOLOHRVD) {
                if (this.state.GOLOHRVD !== this.state.oppDetails.GOLOHRVD) {
                    localValue = true;
                }
            }
            if (this.state.GOLNameVD && this.state.oppDetails.GOLNameVD) {
                if (this.state.GOLNameVD !== this.state.oppDetails.GOLNameVD) {
                    localValue = true;
                }
            }
            if (this.state.FPnAOHR && this.state.oppDetails.FPnAOHR) {
                if (this.state.FPnAOHR !== this.state.oppDetails.FPnAOHR) {
                    localValue = true;
                }
            }
            if (this.state.FPnAName && this.state.oppDetails.FPnAName) {
                if (this.state.FPnAName !== this.state.oppDetails.FPnAName) {
                    localValue = true;
                }
            }
            if (this.state.SowID && this.state.oppDetails.SowID) {
                if (this.state.SowID !== this.state.oppDetails.SowID) {
                    localValue = true;
                }
            }
            if (this.state.GOLOHRMonetization && this.state.oppDetails.GOLOHRMonetization) {
                if (this.state.GOLOHRMonetization !== this.state.oppDetails.GOLOHRMonetization) {
                    localValue = true;
                }
            }
            if (this.state.GOLNameMonetization && this.state.oppDetails.GOLNameMonetization) {
                if (this.state.GOLNameMonetization !== this.state.oppDetails.GOLNameMonetization) {
                    localValue = true;
                }
            }
            if (this.state.FPnANameMonetization && this.state.oppDetails.FPnANameMonetization) {
                if (this.state.FPnANameMonetization !== this.state.oppDetails.FPnANameMonetization) {
                    localValue = true;
                }
            }
            if (this.state.FPnAOHRMonetization && this.state.oppDetails.FPnAOHRMonetization) {
                if (this.state.FPnAOHRMonetization !== this.state.oppDetails.FPnAOHRMonetization) {
                    localValue = true;
                }
            }
            if (this.state.oppDetails.VDType === null || this.state.oppDetails.VDType === undefined || !this.state.oppDetails.VDType) {
                if (this.state.impactOptionSelected) {
                    localValue = true;
                } else {
                    localValue = false;
                }
            }
        }
        return localValue;
    }

    setOpportunityState = () => {
        let localStateType = '';
        if (this.state.OppStageSelectedOption) {

            if (this.state.OppStageSelectedOption.value === 1 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 || this.state.OppStageSelectedOption.value === 9) {
                localStateType = "Inactive";
            }

            if (this.state.OppStageSelectedOption.value === 2) {
                if (this.state.oppDetails.VDType === null || this.state.oppDetails.VDType === undefined || !this.state.oppDetails.VDType) {
                    if (this.state.impactOptionSelected) {
                        if (this.state.isUserMBB) {
                            localStateType = "Active";
                        } else {
                            localStateType = PENDING_APPROVAL_FROM_MBB;
                        }
                    } else {
                        localStateType = "Active";
                    }
                } else {
                    if (this.checkIfVDUpdated()) {
                        if (this.state.isUserMBB) {
                            localStateType = "Active";
                        } else {
                            localStateType = PENDING_APPROVAL_FROM_MBB;
                        }
                    } else {
                        localStateType = "Active";
                    }
                }
            }


            if (this.state.OppStageSelectedOption.value === 3) {
                if (this.state.oppDetails.VDType === null || this.state.oppDetails.VDType === undefined || !this.state.oppDetails.VDType) {
                    if (this.state.impactOptionSelected) {
                        if (this.state.isUserMBB) {
                            localStateType = "Active";
                        } else {
                            localStateType = PENDING_APPROVAL_FROM_MBB;
                        }
                    } else {
                        if (this.state.unitSPOCApprovalRequired) {
                            localStateType = PENDING_APPROVAL_FROM_UNIT_SPOC;
                        } else {
                            localStateType = "Active";
                        }
                    }
                }
                if (this.state.oppDetails.VDType) {
                    if (this.checkIfVDUpdated()) {
                        if (this.state.isUserMBB) {
                            localStateType = "Active";
                        } else {
                            localStateType = PENDING_APPROVAL_FROM_MBB;
                        }
                    } else {
                        if (this.state.unitSPOCApprovalRequired) {
                            localStateType = PENDING_APPROVAL_FROM_UNIT_SPOC;
                        } else {
                            localStateType = "Active";
                        }
                    }
                }
            }
            if (this.state.OppStageSelectedOption.value === 4) {
                if (this.state.oppDetails.VDType === null || this.state.oppDetails.VDType === undefined || !this.state.oppDetails.VDType) {
                    if (this.state.impactOptionSelected) {
                        if (this.state.isUserMBB) {
                            localStateType = "Active";
                        } else {
                            localStateType = PENDING_APPROVAL_FROM_MBB;
                        }
                    } else {
                        localStateType = "Active";
                    }
                }
                if (this.state.oppDetails.VDType) {
                    if (this.checkIfVDUpdated()) {
                        if (this.state.isUserMBB) {
                            localStateType = "Active";
                        } else {
                            localStateType = PENDING_APPROVAL_FROM_MBB;
                        }
                    } else {
                        localStateType = "Active";
                    }
                }

            }

            if (this.state.OppStageSelectedOption.value === 5) {
                if (this.state.oppDetails.VDType === null || this.state.oppDetails.VDType === undefined || !this.state.oppDetails.VDType) {
                    if (this.state.isUserMBB) {
                        localStateType = "Active";
                    } else {
                        localStateType = PENDING_APPROVAL_FROM_MBB;
                    }
                }
                if (this.state.oppDetails.VDType) {
                    if (this.checkIfVDUpdated()) {
                        if (this.state.isUserMBB) {
                            localStateType = "Active";
                        } else {
                            localStateType = PENDING_APPROVAL_FROM_MBB;
                        }
                    } else {
                        if (this.state.unitSPOCApprovalRequired) {
                            localStateType = PENDING_APPROVAL_FROM_UNIT_SPOC;
                        } else {
                            localStateType = "Active";
                        }
                    }
                }
            }
            if (this.state.OppStageSelectedOption.value === 6) {
                if (this.state.oppDetails.VDType) {
                    if (this.checkIfVDUpdated()) {
                        if (this.state.isUserMBB) {
                            localStateType = "Active";
                        } else {
                            localStateType = PENDING_APPROVAL_FROM_MBB;
                        }

                    } else {
                        if (this.state.isSolutionOwnerApprovalRequired) {
                            localStateType = PENDING_APPROVAL_FROM_SOLUTION_OWNER;
                        } 
                        else if(this.state.isCentralTeamApprovalRequired) {
                            localStateType = PENDING_APPROVAL_FROM_CENTRAL_TEAM;
                        }else{
                            localStateType = "Active";
                        }
                    }
                }
            }
        }
        return localStateType;
    }

    setAction = () => {
        let localActionType = 'Opportunity Updated';
        if (this.checkIfVDUpdated()) {
            localActionType = "BI Approval"
        } else {
            localActionType = 'Opportunity Updated';
        }
        return localActionType;
    }

    setUpdaterRole = () => {
        let role = '';
        if (this.state.isUserMBB) {
            role = "MBB";
        }
        else if (this.state.isUserUnitSPOC) {
            role = "Unit SPOC";
        }
        else if (this.state.isUserOppSPOC) {
            role = "Opp SPOC";
        }
        else if (this.state.isUserSolOwner) {
            role = "Solution Owner";
        }
        else if (this.state.isUserBB) {
            role = "BB";
        } else {
            return this.state.userRole;
        }
        console.log("set role is =>", role)
        return role;
    }
    updateOpp = () => {
        console.log("is user solution owner", this.state.isUserSolOwner)
        console.log("business impact value array =>", this.state.businessImpactValueArray)
        if (this.state.isUserMBB || this.state.isUserUnitSPOC || this.state.isUserOppSPOC || this.state.isUserSolOwner || this.state.isUserBB) {
            if (this.validate()) {
                if (window.confirm("Are you sure you want to update the opportunity?")) {
                    Toast.loading("updating opportunity...", () => { });
                    let filteredBIData = this.state.businessImpactValueArray && this.state.businessImpactValueArray.filter(
                        function (e) {
                            return e !== null && e !== undefined;
                        }
                    );
                    let param = {
                        SaveType: this.setSaveType(),
                        isItIdeaOrGenAI: "GenAI",
                        OppName: this.state.OppName,
                        RecID: this.state.RecID,
                        RecIDAlpha: this.state.RecIDAlpha,

                        ModifiedBy: this.sessionData.profile.oHRId ? this.sessionData.profile.oHRId : "",
                        PlanDiscussDate: this.state.PlanDiscussDate ? this.state.PlanDiscussDate : "",
                        DiscussEstDate: this.state.DiscussEstDate ? this.state.DiscussEstDate : "",

                        Account: this.state.Account,
                        Unit: this.state.Unit,
                        MangingDept: this.state.MangingDept,
                        Vertical: this.state.Vertical,
                        Segment: this.state.Segment,
                        Archetype: this.state.Archetype,
                        //AccFTE: this.state.AccFTE,


                        SpocOHR_1: this.state.SpocOHR_1,
                        Spoc_1: this.state.Spoc_1,
                        SpocOHR_2: this.state.SpocOHR_2,
                        Spoc_2: this.state.Spoc_2,
                        BBOHR: this.state.BBOHR,
                        BB: this.state.BB,
                        MBBOHR: this.state.MBBOHR,
                        MBB: this.state.MBB,
                        SolnOwnerOHR: this.state.SolnOwnerOHR,
                        SolnOwner: this.state.SolnOwner,

                        ServiceLine: this.state.ServiceLine,
                        ServiceLineMain: this.state.ServiceLineMain ? this.state.ServiceLineMain.label : "",
                        Process_L2: this.state.Process_L2,

                        ClientDtls: this.state.ClientDtls,
                        Problem: this.state.Problem,
                        Solution: this.state.Solution,
                        OverallFTE: this.state.OverallFTE,
                        OverallSOP: this.state.OverallSOP,

                        CXO: this.state.CXO,
                        LeadHelp: this.state.LeadHelp,
                        IsProposalShared: this.state.IsProposalSharedSelectedOption ? this.state.IsProposalSharedSelectedOption.label : "",
                        SolnEnviron: this.state.SolnEnviron.label,
                        SolnFund: this.state.SolnFund.label,
                        ClientDollar: this.state.ClientDollar ? this.state.ClientDollar : 0,
                        GenpactDollar: this.state.GenpactDollar ? this.state.GenpactDollar : 0,

                        IsSOWScope: this.state.IsSOWScope.label,
                        IsSOWSigned: this.state.IsSOWSigned.label,

                        SOWSubDate: this.state.SOWSubDate ? this.state.SOWSubDate : null,
                        SOWCloseDate: this.state.SOWCloseDate ? this.state.SOWCloseDate : null,
                        CompletionDate: this.state.CompletionDate ? this.state.CompletionDate : null,

                        TechOHR: this.state.TechOHR,
                        Tech: this.state.Tech,
                        FunctionalOHR: this.state.FunctionalOHR,
                        Functional: this.state.Functional,
                        TransitionLed: this.state.TransitionLed ? this.state.TransitionLed.label : '',
                        Knowledge: this.state.Knowledge,
                        Algo: this.state.Algo,
                        Data: this.state.Data,
                        Infra: this.state.Infra,
                        ValueCreated: this.state.ValueCreated,

                        FTEUsage: this.state.FTEUsage,

                        IsHero: this.state.IsHero,
                        Offering_1: this.state.Offering_1,
                        Offering_2: this.state.Offering_2 ? this.state.Offering_2 : "",
                        Offering_2_Other:this.state.Offering_1 && this.state.Offering_1 === "Bespoke" ? (this.state.Offering_2_Other ? this.state.Offering_2_Other : "") : "",

                        Cohort: this.state.CohorSelectedOption ? this.state.CohorSelectedOption.label : "",
                        OppStage: this.state.OppStageSelectedOption ? this.state.OppStageSelectedOption.label : "",
                        OppState: this.setOpportunityState(),

                        Action: this.setAction(),
                        UpdaterOHR: this.sessionData.profile.oHRId ? this.sessionData.profile.oHRId : "",
                        Updater: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                        UpdaterRole: this.setUpdaterRole(),

                        UnitOHR: this.state.oppDetails.UnitOHR,
                        UnitOHRName: this.state.oppDetails.UnitOHRName,
                        CentTeamOHR: this.state.oppDetails.CentTeamOHR ? this.state.oppDetails.CentTeamOHR : "",
                        CentTeam: this.state.oppDetails.CentTeam ? this.state.oppDetails.CentTeamOHR : "",

                        OLINum: this.state.OLINum ? "OLI-".concat(this.state.OLINum) : "",
                        OppID: this.state.OppID ? "Opp-".concat(this.state.OppID) : "",

                        VDupdated: this.checkIfVDUpdated() ? true : false,
                        SowSignedEmailtrigger: this.triggerEmailIfSOWisSigned() ? true : false,



                        // ProjectPPTDeck: this.state.base64File1,
                        // ProjectPPTFilename: this.state.filename1 ? this.state.filename1 : this.state.ideaItem.ProjectPPTFileName,
                        // ProjectPPTType: this.state.fileType1 ? this.state.fileType1 : this.state.ideaItem.ProjectPPTType,
                        // CustomerLeadSignOff: this.state.signOffContent,
                        // CustomerSignoffFilename: this.state.signOffFilename ? this.state.signOffFilename : this.state.ideaItem.CustomerSignoffFileName,
                        // CustomerSignoffType: this.state.signOffFileType ? this.state.signOffFileType : this.state.ideaItem.CustomerSignoffType,
                        // golApproval: this.state.base64File3,
                        // golApprovalFileName: this.state.filename3,
                        // golApprovalType: this.state.fileType3,
                        // fpnaApproval: this.state.base64File4,
                        // fpnaApprovalFileName: this.state.filename4,
                        // fpnaApprovalType: this.state.fileType4,

                        // BI Details
                        businessImpactValueArray: filteredBIData,
                        VDType: this.state.impactOptionSelected ? this.state.impactOptionSelected.label : this.state.oppDetails.VDType,
                        IsVDMonetizable: this.state.monetizableProjectSelectedOption ? this.state.monetizableProjectSelectedOption.label : "",
                        MonetizationStatus: this.state.monetizationStatusSelectedOption ? this.state.monetizationStatusSelectedOption.label : "",
                        FinalImpactDollar: this.state.finalImpactDollar ? this.state.finalImpactDollar : "",
                        FinalImpactFTE: this.state.finalImpactFTE ? this.state.finalImpactFTE : "",

                        // Client details
                        CustName: this.state.CustName ? this.state.CustName : "",
                        CustDesgn: this.state.CustDesgn ? this.state.CustDesgn : "",
                        CustSignOffDate: this.state.CustSignOffDate ? this.state.CustSignOffDate : "",

                        // GOL details
                        GOLNameVD: this.state.GOLNameVD ? this.state.GOLNameVD : "",
                        GOLOHRVD: this.state.GOLOHRVD ? this.state.GOLOHRVD : "",

                        // FPNA Details
                        FPnAName: this.state.FPnAName ? this.state.FPnAName : "",
                        FPnAOHR: this.state.FPnAName ? this.state.FPnAOHR : "",

                        // GOL OHR Monetization
                        GOLOHRMonetization: this.state.GOLOHRMonetization ? this.state.GOLOHRMonetization : "",
                        GOLNameMonetization: this.state.GOLNameMonetization ? this.state.GOLNameMonetization : "",

                        // FPNAOHR Monetization
                        FPnAOHRMonetization: this.state.FPnAOHRMonetization ? this.state.FPnAOHRMonetization : "",
                        FPnANameMonetization: this.state.FPnAOHRMonetization ? this.state.FPnANameMonetization : "",
                        SowID: this.state.SowID ? this.state.SowID : "",


                        // stage 6 fields for sustenenace
                        ProcessUsers: this.state.ProcessUsers,
                        DailyUsers: this.state.DailyUsers,
                        WeeklyUsers: this.state.WeeklyUsers ,
                        WeeklyTrans: this.state.WeeklyTrans ,
                        AccessUsers: this.state.AccessUsers ,
                        Upvotes: this.state.Upvotes ,
                        SolnTrans: this.state.SolnTrans ,
                        UpvotesPercent: this.state.UpvotesPercent,
                        Accuracy:this.state.Accuracy,
                        UptimePercent:this.state.UptimePercent
                    }
                    console.log("param =>", param)
                    console.log(JSON.stringify(param))
                    let finalJsonData = Checksum(param);
                    let str1 = finalJsonData.slice(0, 15);
                    let str2 = finalJsonData.slice(15, 30);
                    let str3 = finalJsonData.slice(30, 40);
                    let str4 = finalJsonData.slice(-10);
                    let finalData = str3 + str1 + str4 + str2;
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.accessToken
                        },
                        body: JSON.stringify(param)
                    };
                    fetch(`${SUBMIT_GENAI_URL}Checksum=${finalData}`, requestOptions)
                        .then(response => response.json())
                        .then(data => {
                            if (data.message === "Opportunity data succesfully Updated") {
                                Toast.hide();
                                Toast.info("Opportunity updated successfully!", 3000, () => {
                                    window.location = "/view-all-opportunities";
                                })
                            }
                        })
                        .catch(error => {
                            //reject(error);
                        });


                }
            } else {
                if (!this.isExceedlimit) {
                    Toast.fail('Required fields are missing or have invalid values. Please correct and try again.', 3000, () => { });
                }
            }
        } else {
            Toast.fail('Only Users assigned with role of Unit SPOC or Blackbelt or Master Blackbelt or Opportunity SPOC or Solution Owner of this opportunity can approve.', 10000, () => {
                return false;
            });
        }
    }
    // submit opp function ends here



    cancelOpp = () => {
        this.props.history.push("/view-opportunities");
    }

    viewDone = () => {
        this.props.history.push("/view-opportunities");
    }

    raiseFlag = () => {

    }

    handleAuditLog = () => {
        this.setState({ auditLog: true }, () => { })
    }








    formatDateStringWithTime(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
    }



    handleParentIDChange = (e) => {

        this.setState({ ParentIDAlpha: e }, () => {

        })
    }


    renderServiceLineOptions = () => {
        let options = [];
        const uniqueServiceLineList = [...new Map(this.state.serviceLineList.map(item => [item.ServiceLineName, item])).values()];
        uniqueServiceLineList.map((item) => {
            options.push({ value: item.ServiceLineId, label: item.ServiceLineName });
            return true;
        });
        return options;

    }

    renderPredominantServiceLine = () => {
        let options = [];
        this.state.ServiceSelectedOption && this.state.ServiceSelectedOption.map((item) => {
            options.push({ value: item.value, label: item.label })
        })
        return options;
    }

    handlePredominantSeriveLineChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.ServiceLineMain = selectStyle(true);
        this.setState({ ServiceLineMain: e, selectStyle: styleStateObj }, () => { })
    }

    renderSubServiceLineOptions = () => {
        let options = [];
        if (this.state.ServiceLine) {
            let localServiceLine = this.state.ServiceLine.replace(/[|,]/g, ",")
            localServiceLine = localServiceLine.split(",");
            this.state.serviceLineList && this.state.serviceLineList.map((item) => {
                localServiceLine.map((local) => {
                    if (local === item.ServiceLineName) {
                        options.push({ value: item.SubServiceLineId, label: item.SubServiceLineName });
                        return true;
                    }
                    if (local === "All") {
                        options.push({ value: item.SubServiceLineId, label: item.SubServiceLineName });
                        return true;
                    }
                })
                return true;
            });
        }
        return options;
    }

    // ... auto fetch functional owner function starts here
    handleFunctionalOhronCut = (event) => {
        if (event.target.value.length === 9) {
            this.handleFunctionalOhrChange()
        } else if (event.target.value.length === 0) {
            this.setState({ FunctionalOHR: "", Functional: "" }, () => { })
            return null
        }
        else {
            Toast.fail("Functional owner OHR should be exactly of 9 digits", 3000, () => { })
            this.setState({ Functional: "" }, () => { })
        }
    }

    handleFunctionalOhrChange = (event) => {
        var numbers = /^[0-9]+$/;
        if (event !== null && event !== undefined) {
            if (event.target.value.match(numbers) && event.target.value.length !== 0) {
                if (event.target.value.length === 9) {
                    let localFunctionalOhr = event.target.value.trim();
                    this.setState({ FunctionalOHR: localFunctionalOhr }, () => { })
                    if (localFunctionalOhr) {
                        this.fetchSubmitFunctionalOHRDetails(localFunctionalOhr)
                    }
                }
                else {
                    this.setState({ FunctionalOHR: "", Functional: "" }, () => { })
                }
            } else {
                this.setState({ FunctionalOHR: "", Functional: "" }, () => { })
            }
        }
    }
    fetchSubmitFunctionalOHRDetails = async (localFunctionalOhr) => {
        Toast.loading("Fetching Functional Owner Full Name Details. Please wait...", () => { });
        try {
            const param = {
                EntityType: "SearchOHR",
                EntityName: localFunctionalOhr
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.accessToken
                },
                body: JSON.stringify(param)
            };
            const response = await fetch(GET_METADATA, requestOptions);
            const data = await response.json();
            console.log(data)
            let requestByName = data.oUser ? data.oUser[0].DisplayName : null
            if (requestByName === "") {
                requestByName = data.PowerAppUsers ? (data.PowerAppUsers.length > 0 ? data.PowerAppUsers[0].pg_username : "") : "";
            }
            if (requestByName === "" || requestByName === null || !requestByName) {
                Toast.fail("We could not find Functional Owner full name details based on the entered OHR. Please check and re-enter.", 5000, () => { });
                this.setState({ Functional: "" }, () => { })
            }
            else {
                setTimeout(() => { }, 2000);
                let styleStateObj = this.state.selectStyle;
                styleStateObj.FunctionalOHR = textBoxValidation(true);
                styleStateObj.Functional = textBoxValidation(true);
                this.setState({
                    Functional: requestByName,
                    selectStyle: styleStateObj,
                });
            }
            Toast.hide();
        } catch (error) {
            Toast.hide();
            Toast.fail('Please enter a valid Functional Owner OHR', 5000, () => {
                console.log(JSON.stringify(error));
            });
            let styleStateObj = this.state.selectStyle;
            styleStateObj.FunctionalOHR = textBoxValidation(false);
            styleStateObj.Functional = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj,
            });
            this.setState({ Functional: "", FunctionalOHR: "" }, () => { })
        }
    };
    //.. auto fetch function owner function ends here



    // ... auto fetch technical owner function starts here
    handleTechnicalOhronCut = (event) => {
        if (event.target.value.length === 9) {
            this.handleTechnicalOhrChange()
        } else if (event.target.value.length === 0) {
            this.setState({ TechOHR: "", Tech: "" }, () => { })
            return null
        }
        else {
            Toast.fail("Technical owner OHR should be exactly of 9 digits", 3000, () => { })
            this.setState({ Tech: "" }, () => { })
        }
    }

    handleTechnicalOhrChange = (event) => {
        var numbers = /^[0-9]+$/;
        if (event !== null && event !== undefined) {
            if (event.target.value.match(numbers) && event.target.value.length !== 0) {
                if (event.target.value.length === 9) {
                    let localTechnicalOhr = event.target.value.trim();
                    this.setState({ TechOHR: localTechnicalOhr }, () => { })
                    if (localTechnicalOhr) {
                        this.fetchSubmitTechnicalOHRDetails(localTechnicalOhr)
                    }
                }
                else {
                    this.setState({ TechOHR: "", Tech: "" }, () => { })
                }
            } else {
                this.setState({ TechOHR: "", Tech: "" }, () => { })
            }
        }

    }
    fetchSubmitTechnicalOHRDetails = async (localTechnicalOhr) => {
        Toast.loading("Fetching Technical Owner Full Name Details. Please wait...", () => { });
        try {
            const param = {
                EntityType: "SearchOHR",
                EntityName: localTechnicalOhr
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.accessToken
                },
                body: JSON.stringify(param)
            };
            const response = await fetch(GET_METADATA, requestOptions);
            const data = await response.json();
            console.log(data)
            let requestByName = data.oUser ? data.oUser[0].DisplayName : null
            if (requestByName === "") {
                requestByName = data.PowerAppUsers ? (data.PowerAppUsers.length > 0 ? data.PowerAppUsers[0].pg_username : "") : "";
            }
            if (requestByName === "" || requestByName === null || !requestByName) {
                Toast.fail("We could not find Technical Owner full name details based on the entered OHR. Please check and re-enter.", 5000, () => { });
                this.setState({ Tech: "" }, () => { })
            }
            else {
                setTimeout(() => { }, 2000);
                let styleStateObj = this.state.selectStyle;
                styleStateObj.Tech = textBoxValidation(true);
                styleStateObj.TechOHR = textBoxValidation(true);
                this.setState({
                    Tech: requestByName,
                    selectStyle: styleStateObj,
                });

            }
            Toast.hide();
        } catch (error) {
            Toast.hide();
            Toast.fail('Please enter a valid Functional Owner OHR', 5000, () => {
                console.log(JSON.stringify(error));
            });
            let styleStateObj = this.state.selectStyle;
            styleStateObj.Tech = textBoxValidation(false);
            styleStateObj.TechOHR = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj,
            });
            this.setState({ Tech: "", TechOHR: "" }, () => { })
        }
    };
    //.. auto fetch technical owner function ends here


    handleEnvironmentChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.SolnEnviron = selectStyle(true);
        this.setState({ SolnEnviron: e, selectStyle: styleStateObj }, () => { })
    }


    render() {
        // console.log("opp state =>",this.state.OppState)

        const today = new Date().toISOString().split("T")[0];
        return (
            <section className="u-clearfix u-section-1 opp_create_page" id="sec-0f7f">
                <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                    <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                        <div className="u-container-layout u-container-layout-1">
                            <div className="">
                                <div className="view_idea_top_header_btn_block">
                                    <div className="">
                                        {/* <button style={{ marginRight: "15px" }} className="u-active-custom-color-6 u-border-2 u-btn u-btn-round u-radius-6 update_parent_btn"
                                            onClick={this.showClusterModal}>Update Parent {this.state.RecID}
                                        </button> */}
                                    </div>
                                    <div className="">
                                        {/* <button style={{ marginRight: "15px" }} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                            onClick={this.raiseFlag}>Raise Flag
                                        </button> */}
                                    </div>
                                    <div className="">
                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                            onClick={(e) => this.handleAuditLog(e)}
                                        //disabled={this.state.auditLogData && this.state.auditLogData.length === 0 ? false : true}
                                        >
                                            Audit Trail
                                        </button>
                                    </div>




                                </div>
                                <div className="text-left mt-4">
                                    {this.state.showHrefModal ? (
                                        <>
                                            <div className="custom_modal href_modal">
                                                <div className="modal_body href_modal_body">
                                                    <div className="href_modal_dialog">
                                                        <p>
                                                            To add a new category please raise a Helpmate
                                                            ticket, Click here to View SOP
                                                        </p>
                                                        <div className="href_modal_body_buttons">
                                                            <div className="btn-group href_modal_body_buttons">
                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3">
                                                                    <a
                                                                        href="https://genpactonline.sharepoint.com/:b:/r/sites/LeanDigitalTransformation/Documents/KM%20Page%20-%202021/Certification%20Guidelines,%20SOPs%20%26%20Handbooks/Certification%20guidelines,%20SOPs%20%26%20Handbooks/Systems%20%26%20Tools/Helpmate-Data%20Related%20Request/ProGEAR%20Value%20Delivery%20Category%20Addition%20SOP.pdf?csf=1&web=1&e=5kKbFg"
                                                                        style={{ color: "#FFF" }}
                                                                        target="_blank"
                                                                    >
                                                                        Click Here
                                                                    </a>
                                                                </button>
                                                                <button
                                                                    className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                                                    onClick={(e) =>
                                                                        this.setState({ showHrefModal: false })
                                                                    }
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                    {/* {this.state.clusterModal ? (
                                            <>
                                                <div className="cluster_modal_main_block">
                                                    <div className="custom_modal cluster_modal_block">
                                                        <div className="modal_body" style={{ width: "400px" }}>
                                                            <div className="row">
                                                                <div className="col-md-11">
                                                                    <h3 className="modal_title text-center">
                                                                        Update Parent
                                                                    </h3>
                                                                </div>
                                                                <div className="col-md-1">
                                                                    <button
                                                                        type="button"
                                                                        className="close text-right"
                                                                        onClick={(e) =>
                                                                            this.setState({ clusterModal: false })
                                                                        }
                                                                    >
                                                                        &times;
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="clustor_modal_fields_block">
                                                                <div className="clustor_modal_field">
                                                                    <label className="form-attribute">
                                                                        <span style={{ color: "red" }}>*</span> Project
                                                                        Type:
                                                                    </label>
                                                                    {this.state.displayParentAlpha ? (
                                                                        <>
                                                                            <input
                                                                                disabled={true}
                                                                                value={
                                                                                    this.state.projectTypeSelectedOption
                                                                                        ? this.state.projectTypeSelectedOption
                                                                                            .label
                                                                                        : ""
                                                                                }
                                                                                className="form-input"
                                                                                placeholder="Choose project type"
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <Select
                                                                            isDisabled={
                                                                                this.state.enableClusterAction
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            isSearchable={false}
                                                                            options={projectType}
                                                                            value={this.state.projectTypeSelectedOption}
                                                                            styles={this.state.selectStyle.projectType}
                                                                            placeholder="Choose project type"
                                                                            onChange={this.changeProjectType}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="clustor_modal_field">
                                                                    <label className="form-attribute">
                                                                        <span style={{ color: "red" }}>*</span> Project
                                                                        ID:
                                                                    </label>

                                                                    {this.state.displayParentAlpha ? (
                                                                        <>
                                                                            <input
                                                                                value={
                                                                                    this.state.projectIDSelectedOption &&
                                                                                        this.state.projectIDSelectedOption.label
                                                                                        ? this.state.projectIDSelectedOption
                                                                                            .label
                                                                                        : ""
                                                                                }
                                                                                disabled={true}
                                                                                className="form-input"
                                                                                placeholder="Choose project ID"
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <Select
                                                                            isSearchable
                                                                            onInputChange={
                                                                                this.handleClusterProjectInputChange
                                                                            }
                                                                            options={this.displayProjectID()}
                                                                            value={this.state.projectIDSelectedOption}
                                                                            styles={
                                                                                this.state.selectStyle
                                                                                    .projectIDSelectedOption
                                                                            }
                                                                            isDisabled={
                                                                                this.state.enableClusterAction
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            placeholder="Choose project ID"
                                                                            onChange={this.changeProjectID}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="cluster_modal_btn_group">
                                                                <button
                                                                    className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                                                    disabled={
                                                                        this.state.isClusterSaveDisabled ? true : false
                                                                    }
                                                                    onClick={this.saveParentLink}
                                                                >
                                                                    Save
                                                                </button>
                                                                <button
                                                                    className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                                                    onClick={this.removeParentFunc}
                                                                    style={{ marginRight: "15px" }}
                                                                    disabled={
                                                                        this.state.enableClusterAction ? false : true
                                                                    }
                                                                >
                                                                    Remove Parent
                                                                </button>
                                                                {this.state.displayParentAlpha ? (
                                                                    <>
                                                                        <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6 show_hierarchy_link_btn">
                                                                            <Link
                                                                                className="show_hierarchy_link_btn_anchor"
                                                                                to={`/showTree?ID=${this.state.ideaItem.IdeaId}`}
                                                                                target="_blank"
                                                                            >
                                                                                Show Hierarchy
                                                                            </Link>
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6 show_hierarchy_link_btn">
                                                                        Show Hierarchy
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null} */}


                                </div>

                                <div className="gen_ai_audit_log">
                                    {
                                        this.state.auditLog
                                            ?
                                            <>
                                                <div className="custom_modal">
                                                    <div className="modal_body">
                                                        <div className="col-md-12">
                                                            <div className="">
                                                                <div className="row">
                                                                    <div className="col-md-10">
                                                                        <h3 className="modal_title">
                                                                            Audit Trail
                                                                        </h3>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ auditLog: false, })}>
                                                                            &times;
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="genai_popup_fields">
                                                                <div className="qc_responsive_comment_log">
                                                                    <table className='table-responsive table-bordered' style={{ width: "100%" }}>
                                                                        <thead className="">
                                                                            <tr>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Date & Time</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>OHR</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Name</label>
                                                                                </th>
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Role</label>
                                                                                </th>
                                                                                {/* <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Action</label>
                                                                                </th> 
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Opportunity Stage</label>
                                                                                </th>*/}
                                                                                <th className=" text-center audit_log_sub_fields_header">
                                                                                    <label>Opportunity State</label>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.auditLogData && this.state.auditLogData.map((item, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td className="text-center audit_log_sub_fields_value_block">
                                                                                            <label>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                        </td>
                                                                                        <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.UpdaterOHR}
                                                                                            </label>
                                                                                        </td>
                                                                                        <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.Updater}
                                                                                            </label>
                                                                                        </td>
                                                                                        <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.UpdaterRole}
                                                                                            </label>
                                                                                        </td>
                                                                                        {/* <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.Action}
                                                                                            </label>
                                                                                        </td>
                                                                                        <td className="text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.OppStage}
                                                                                            </label>
                                                                                        </td> */}
                                                                                        <td className=" text-center audit_log_sub_fields_value_block">
                                                                                            <label>
                                                                                                {item.OppState}
                                                                                            </label>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }

                                </div>
                            </div>
                            <h4 style={{ marginTop: 45, marginBottom: -35, textAlign: 'center', color: '#00AECF', fontFamily: 'Arial', fontSize: 22 }}>Update opportunity: {this.state.RecIDAlpha}</h4>
                            <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                                <div className="u-container-layout u-container-layout-2">
                                    <div className="main_fields_block">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Submitter OHR</label>
                                                    <input tabIndex={1} className="form-input" type="text" value={this.state.CreatedBy} disabled />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Submitter Name</label>
                                                    <input tabIndex={2} className="form-input" type="text" value={this.state.CreatedName} disabled />
                                                </div>
                                                {/* <div className="genai_fields">
                                                    <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> Parent Opportunity Id</label>
                                                    <input tabIndex={3} className="textarea-css form-attribute" type="text" value={this.state.ParentIDAlpha} onChange={(e)=>this.handleParentIDChange(e.target.value)} />
                                                </div> */}
                                                <div className="genai_fields">
                                                    <label className="form-attribute" > Opportunity Name</label>
                                                    <input tabIndex={4} className="form-input" type="text" disabled value={this.state.OppName} />
                                                </div>
                                                {
                                                    this.state.ModifiedDate
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" > Last Modified on</label>
                                                                <input tabIndex={4} className="form-input" type="text" disabled value={this.state.ModifiedDate} />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Account</label>
                                                    <input disabled placeholder="Account" value={this.state.Account} className="form-input" />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute" > Vertical</label>
                                                    <input tabIndex={8} className="form-input" type="text" disabled value={this.state.Vertical} placeholder="Vertical" />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Owning Units </label>
                                                    <input tabIndex={6} className="form-input" type="text" disabled value={this.state.Unit} placeholder="Unit" />
                                                </div>
                                                {
                                                    this.state.ModifiedBy
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" > Last Modified by OHR</label>
                                                                <input tabIndex={4} className="form-input" type="text" disabled value={this.state.ModifiedBy} />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" > Managing Entity</label>
                                                    <input tabIndex={7} placeholder="Managing Entity" value={this.state.MangingDept ? this.state.MangingDept : "NA"} className="form-input" disabled />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Segment</label>
                                                    <input tabIndex={9} className="form-input" type="text" disabled value={this.state.Segment} placeholder="Segment" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute"> Archetype</label>
                                                    <input tabIndex={10} className="form-input" type="text" disabled value={this.state.Archetype} placeholder="Archetype" />
                                                </div>
                                                <div className="genai_fields">
                                                    <label className="form-attribute" > FTE Count (inclusive of all regions)</label>
                                                    <input placeholder="Number of FTEs" disabled value={this.state.AccFTE} type='number' className="form-input" />
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" > Unit SPOC</label>
                                                        <input placeholder="Unit SPOC" disabled value={this.state.oppDetails.UnitOHRName} className="form-input" />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" > Black Belt</label>
                                                        <input placeholder="Black Belt" disabled value={this.state.BB} className="form-input" />
                                                    </div>
                                                </div>




                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" > Opportunity SPOC - 1</label>
                                                        <input placeholder="Opportunity SPOC 1" disabled value={this.state.oppDetails.Spoc_1} className="form-input" />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" > Master Black Belt</label>
                                                        <input placeholder="Master Black Belt" disabled value={this.state.MBB} className="form-input" />
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="col-md-3">

                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute"> Opportunity SPOC - 2</label>
                                                        <input placeholder="Opportunity SPOC 2" disabled value={this.state.Spoc_2} className="form-input" />
                                                    </div>
                                                </div>
                                                <div className="">

                                                    <div className="genai_fields">
                                                        <label className="form-attribute" > GOL OHR</label>
                                                        <input type="text" value={this.state.oppDetails.GOLOHR} disabled className="form-input" placeholder='GOL OHR here' />
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute"> Opportunity Solution Owner</label>
                                                        <input placeholder="Solution Owner" disabled value={this.state.SolnOwner} className="form-input" />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute"> GOL</label>
                                                        <input className="form-input" type="text" disabled value={this.state.oppDetails.GOL} placeholder="GOL Name" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>



                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" ><label className="mandatery_asterisk">*</label> Opportunity Stage</label>
                                                    <Select
                                                        isSearchable={true}
                                                        options={this.renderOppMasterStages()}
                                                        placeholder="Select Opportunity Stage"
                                                        onChange={(e) => this.handleOppStageChange(e)}
                                                        value={this.state.OppStageSelectedOption}
                                                        classNamePrefix={(this.state.OppState === PENDING_APPROVAL_FROM_MBB || this.state.OppState === PENDING_APPROVAL_FROM_SOLUTION_OWNER || this.state.OppState === PENDING_APPROVAL_FROM_UNIT_SPOC || this.state.OppState === PENDING_APPROVAL_FROM_CENTRAL_TEAM || this.state.OppState === "Completed") ? "an-simple-select" : ""}
                                                        isDisabled={(this.state.OppState === PENDING_APPROVAL_FROM_MBB || this.state.OppState === PENDING_APPROVAL_FROM_SOLUTION_OWNER || this.state.OppState === PENDING_APPROVAL_FROM_UNIT_SPOC || this.state.OppState === PENDING_APPROVAL_FROM_CENTRAL_TEAM || this.state.OppState === "Completed") ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" >
                                                            {/* <label className="mandatery_asterisk">&nbsp;</label>  */}
                                                            Opportunity State</label>
                                                        <input disabled value={this.state.OppState} className="form-input" />
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="col-md-4">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> Cohort</label>
                                                    <Select
                                                        placeholder="Cohort Value"
                                                        value={this.state.CohorSelectedOption}
                                                        onChange={(e) => this.handleCohortChange(e)}
                                                        options={this.renderCohort()}
                                                        isDisabled={ this.state.OppState && this.state.OppState === "Completed" ? true : false}
                                                        classNamePrefix={ this.state.OppState && this.state.OppState === "Completed" ? "an-simple-select" : ""}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                            </div>
                                        </div>
                                    </div>



                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : " "}&nbsp;</label>
                                                            Service Line
                                                        </label>
                                                        <Select
                                                            isSearchable={true}
                                                            placeholder="Select service line"
                                                            value={this.state.ServiceSelectedOption}
                                                            styles={this.state.selectStyle.ServiceSelectedOption}
                                                            options={this.renderServiceLineOptions()}
                                                            onChange={(e) => this.handleServiceLineChange(e)}
                                                            isMulti={true}
                                                            // isDisabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 ) || !this.state.fieldsEditable)  ? true : false}
                                                            // classNamePrefix={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 ) || !this.state.fieldsEditable ) ? "an-simple-select" : ""}
                                                            isDisabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                            classNamePrefix={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "an-simple-select" : ""}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : " "}&nbsp;</label>
                                                        Predominant Service Line
                                                    </label>
                                                    <Select
                                                        isSearchable={true}
                                                        placeholder="Select predominant service line"
                                                        value={this.state.ServiceLineMain}
                                                        styles={this.state.selectStyle.ServiceLineMain}
                                                        options={this.renderPredominantServiceLine()}
                                                        onChange={(e) => this.handlePredominantSeriveLineChange(e)}
                                                        isDisabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || (this.state.ServiceSelectedOption && this.state.ServiceSelectedOption.length === 1) || !this.state.fieldsEditable) ? true : false}
                                                        classNamePrefix={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || (this.state.ServiceSelectedOption && this.state.ServiceSelectedOption.length === 1) || !this.state.fieldsEditable) ? "an-simple-select" : ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    <div className="genai_fields">
                                                        <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : " "}&nbsp;</label>
                                                            Area / Process</label>
                                                        <Select
                                                            isSearchable={true}
                                                            styles={this.state.selectStyle.Process_L2SelectedOption}
                                                            placeholder="Select Area/Process"
                                                            onChange={(e) => this.handleProcessChange(e)}
                                                            value={this.state.Process_L2SelectedOption}
                                                            isMulti={true}
                                                            options={this.renderSubServiceLineOptions()}
                                                            isDisabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                            classNamePrefix={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "an-simple-select" : ""}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-3">


                                            </div>
                                        </div>
                                    </div>



                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : " "}&nbsp;</label>
                                                                    GenAI Offering L1</label>
                                                                <div className="">
                                                                    <Select
                                                                        placeholder="GenAI Offering L1 value"
                                                                        onChange={(e) => this.heroOfferingL1Change(e)}
                                                                        isDisabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                        classNamePrefix={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "an-simple-select" : ""}
                                                                        value={this.state.Offering_1SelectedOption} options={this.renderGenAIOfferingL1()}
                                                                        styles={this.state.selectStyle.Offering_1SelectedOption}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }


                                            </div>
                                            <div className="col-md-3">

                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : " "}&nbsp;</label>
                                                                    GenAI Offering L2</label>
                                                                <div className="">
                                                                    {
                                                                        this.state.Offering_1SelectedOption && this.state.Offering_1SelectedOption.label === "Bespoke"
                                                                            ?
                                                                            <>
                                                                                <input
                                                                                    placeholder="Provide GenAI Offering bespoke value"
                                                                                    onChange={(e) => this.heroOfferingBeSpokeChange(e.target.value)}
                                                                                    className={((this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input" : "textarea-css form-attribute"}
                                                                                    value={this.state.Offering_2_Other}
                                                                                    disabled={((this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Offering_2_Other }}
                                                                                    title={this.state.Offering_2_Other}
                                                                                />
                                                                            </>
                                                                            :
                                                                            <Select
                                                                                placeholder="GenAI Offering L2 value" onChange={(e) => this.heroOfferingL2Change(e)}
                                                                                value={this.state.Offering_2SelectedOption} options={this.renderGenAIOfferingL2()}
                                                                                styles={this.state.selectStyle.Offering_2SelectedOption}
                                                                                isDisabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                                classNamePrefix={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "an-simple-select" : ""}
                                                                            />
                                                                    }

                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : " "}&nbsp;</label>
                                                                        Is a hero Product ?
                                                                    </label>
                                                                    <input
                                                                        className="form-input"
                                                                        placeholder="if its hero product"
                                                                        value={this.state.IsHero}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>

                                            </div>
                                            <div className="col-md-3">


                                            </div>
                                        </div>
                                    </div>



                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : " "}&nbsp;</label>
                                                                        Client Background
                                                                        <span className="field_max_characters">(max. 2000 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            className={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input textarea_width" : "textarea-css form-attribute textarea_width"}
                                                                            type="text" placeholder='Provide value'
                                                                            onChange={(e) => this.clientBGChange(e.target.value)}
                                                                            value={this.state.ClientDtls}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.ClientDtls }}
                                                                            disabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : " "}&nbsp;</label>
                                                                        GenAI Solution
                                                                        <span className="field_max_characters">(max. 2000 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            className={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input textarea_width" : "textarea-css form-attribute textarea_width"}
                                                                            type="text"
                                                                            placeholder='Provide value' onChange={(e) => this.genAISolutionChange(e.target.value)} value={this.state.Solution}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Solution }}
                                                                            disabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>

                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : " "}&nbsp;</label>
                                                                        Client Problem
                                                                        <span className="field_max_characters">(max. 2000 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea placeholder='Provide value'
                                                                            className={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input textarea_width" : "textarea-css form-attribute textarea_width"}
                                                                            type="text" onChange={(e) => this.clientProblemChange(e.target.value)} value={this.state.Problem}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Problem }}
                                                                            disabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-3">
                                            {
                                                this.state.OppStageSelectedOption
                                                    ?
                                                    <>
                                                        <div className="">
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : " "}&nbsp;</label>
                                                                    Overall number of FTEs in scope</label>
                                                                <input
                                                                    className={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input hide_numeric_input_arrows " : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                                    type="number"
                                                                    placeholder="Max limit of 5000 FTEs"
                                                                    onChange={(e) => this.overAllFTEinScopeChange(e.target.value)}
                                                                    value={this.state.OverallFTE}
                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.OverallFTE }}
                                                                    onKeyDown={(evt) =>
                                                                        (evt.key === "e" ||
                                                                            evt.key === "E" ||
                                                                            evt.key === "+" ||
                                                                            evt.key === "-") &&
                                                                        evt.preventDefault()
                                                                    }
                                                                    onWheel={(e) => e.target.blur()}
                                                                    disabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }

                                        </div>
                                        <div className="col-md-3">
                                            <div className="">
                                                {
                                                    this.state.OppStageSelectedOption && (this.state.Offering_1SelectedOption && this.state.Offering_1SelectedOption.label === "CKA")
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : " "}&nbsp;</label>
                                                                    Overall number of SOPs in scope
                                                                </label>
                                                                <input
                                                                    className={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                                    type="number"
                                                                    maxLength={1000}
                                                                    onChange={(e) => this.overAllNumberofSOPChange(e.target.value)}
                                                                    value={this.state.OverallSOP}
                                                                    placeholder="Max limit of 1000 SOPs"
                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.OverallSOP }}
                                                                    onKeyDown={(evt) =>
                                                                        (evt.key === "e" ||
                                                                            evt.key === "E" ||
                                                                            evt.key === "+" ||
                                                                            evt.key === "-") &&
                                                                        evt.preventDefault()
                                                                    }
                                                                    onWheel={(e) => e.target.blur()}
                                                                    disabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                        </div>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-3"></div>
                                    </div>



                                    <div className="row">
                                        <div className="col-md-5">
                                            {
                                                this.state.OppStageSelectedOption
                                                    ?
                                                    <div className="genai_fields">
                                                        <label className="form-attribute">
                                                            <label className="mandatery_asterisk">&nbsp;</label>
                                                            &nbsp;Help Needed from Leadership
                                                            <span className="field_max_characters">(max. 300 chars)</span>
                                                        </label>
                                                        <div className="">
                                                            <textarea
                                                                placeholder="Provide value"
                                                                type="text"
                                                                onChange={(e) => this.needHelpChange(e.target.value)}
                                                                value={this.state.LeadHelp}
                                                                //className="textarea-css form-attribute textarea_width"
                                                                className={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input textarea_width" : "textarea-css form-attribute textarea_width"}
                                                                disabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-5">
                                            <div className="">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute">
                                                                    <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : " "}&nbsp;</label>
                                                                    CXO Report - Brief Status
                                                                    <span className="field_max_characters">(max. 300 chars)</span>
                                                                </label>
                                                                <div className="">
                                                                    <textarea
                                                                        placeholder="Provide value"
                                                                        type="text"
                                                                        onChange={(e) => this.CXOReportChange(e.target.value)}
                                                                        value={this.state.CXO}
                                                                        disabled={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                        className={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? " form-input textarea_width" : "textarea-css form-attribute textarea_width"}
                                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.CXO }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                        </div>
                                    </div>


                                    {
                                        this.state.OppStageSelectedOption
                                            ?
                                            <>
                                                <div className="gapping_class">
                                                    <div className="">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="">
                                                                    <div className="genai_fields">
                                                                        <label htmlFor="futureDate" className="form-attribute" >
                                                                            <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2) ? "*" : " "}&nbsp;</label>
                                                                            Next Planned Discussion Date</label>
                                                                        <input
                                                                            type="date"
                                                                            onChange={(e) => this.planDiscussionDateChange(e.target.value)}
                                                                            value={this.state.PlanDiscussDate} min={today} id="futureDate"
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.PlanDiscussDate, color: "#122539" }}
                                                                            className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? 'form-input' : "textarea-css form-attribute"}
                                                                            disabled={(this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="">
                                                                    <div className="genai_fields">
                                                                        <label className="form-attribute" style={{ whiteSpace: "nowrap" }}><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2) ? "*" : " "}&nbsp;</label>
                                                                            Date by when discussion outcome will be known ?
                                                                        </label>
                                                                        <input
                                                                            type="date"
                                                                            min={today}
                                                                            onChange={(e) => this.DiscussionEstimateDateChange(e.target.value)}
                                                                            value={this.state.DiscussEstDate}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.DiscussEstDate, color: "#122539" }}
                                                                            className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? 'form-input' : "textarea-css form-attribute"}
                                                                            disabled={(this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }


                                    <div className="gapping_class">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="">
                                                        <div className="">
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : ""}</label> Proposal Shared ?</label>
                                                                <div className="">
                                                                    <Select
                                                                        placeholder="Proposal shared ?"
                                                                        onChange={(e) => this.IsProposalSharedChange(e)}
                                                                        value={this.state.IsProposalSharedSelectedOption}
                                                                        options={YESORNO}
                                                                        styles={this.state.selectStyle.IsProposalSharedSelectedOption}
                                                                        classNamePrefix={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "an-simple-select" : ""}
                                                                        isDisabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    {/* <div className="genai_fields">
                                                        <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> Proposal File <span className="file_max_size">Max size: 5MB</span></label>
                                                        <div className="file_upload_plus_mark_block">
                                                        
                                                            <label htmlFor="proposed-file" className="file_upload_plus_mark">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                            <input id="proposed-file" 
                                                                type="file" style={{ display: 'none' }} 
                                                                onChange={(event) => this.uploadFile(event, "file1")} 
                                                                accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                            />
                                                            <button className="file_name">File name here</button>
                                                        </div>
                                                        <div className="">
                                                            <label className="file_upload_instruction">[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="col-md-3">
                                                </div>
                                                <div className="col-md-3">
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        this.state.OppStageSelectedOption
                                            ?
                                            <>
                                                <div className="gapping_class">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                        Environment</label>
                                                                    <div className="">
                                                                        <Select
                                                                            onChange={(e) => this.handleEnvironmentChange(e)}
                                                                            placeholder={"Select solution environment"}
                                                                            value={this.state.SolnEnviron}
                                                                            options={GenAI_ENVIRONMENT}
                                                                            styles={this.state.selectStyle.SolnEnviron}
                                                                            classNamePrefix={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "an-simple-select" : ""}
                                                                            isDisabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.OppStageSelectedOption
                                                                    ?
                                                                    <>
                                                                        <div className="genai_fields">
                                                                            <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                                Funding
                                                                            </label>
                                                                            <Select
                                                                                options={GenAI_FUNDING}
                                                                                isSearchable={true} placeholder="Select Solution Funding"
                                                                                value={this.state.SolnFund}
                                                                                onChange={(e) => this.SolutionFundingChange(e)}
                                                                                styles={this.state.selectStyle.SolnFund}
                                                                                classNamePrefix={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "an-simple-select" : ""}
                                                                                isDisabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            <div className="">


                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.SolnFund && (this.state.SolnFund.value === 1 || this.state.SolnFund.value === 3)
                                                                    ?
                                                                    <>
                                                                        <div className="">
                                                                            <div className="genai_fields">
                                                                                <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                                    Client Funding (USD)
                                                                                </label>
                                                                                <input
                                                                                    type="number"
                                                                                    value={this.state.ClientDollar}
                                                                                    placeholder='Enter $ value'
                                                                                    onChange={(e) => this.clientFundingChange(e.target.value)}
                                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.ClientDollar }}
                                                                                    onKeyDown={(evt) =>
                                                                                        (evt.key === "e" ||
                                                                                            evt.key === "E" ||
                                                                                            evt.key === "+" ||
                                                                                            evt.key === "-") &&
                                                                                        evt.preventDefault()
                                                                                    }
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                    className={((this.state.oppDetails.IsVDMonetizable && this.state.oppDetails.IsVDMonetizable === "No") || !this.state.fieldsEditable || (this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption === 8))) ? "form-input" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                                                    disabled={this.clientFundingDisable() ? true : false}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }

                                                        </div>
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.SolnFund && (this.state.SolnFund.value === 2 || this.state.SolnFund.value === 3)
                                                                    ?
                                                                    <>
                                                                        <div className="genai_fields">
                                                                            <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                                Cost to Genpact for Build (USD)</label>
                                                                            <input
                                                                                type="number"
                                                                                placeholder='Enter $ value'
                                                                                value={this.state.GenpactDollar} onChange={(e) => this.costToGenpactBuildChange(e.target.value)}
                                                                                style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.GenpactDollar }}
                                                                                onKeyDown={(evt) =>
                                                                                    (evt.key === "e" ||
                                                                                        evt.key === "E" ||
                                                                                        evt.key === "+" ||
                                                                                        evt.key === "-") &&
                                                                                    evt.preventDefault()
                                                                                }
                                                                                onWheel={(e) => e.target.blur()}
                                                                                className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute  hide_numeric_input_arrows"}
                                                                                disabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }

                                                        </div>


                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }



                                    {
                                        this.state.OppStageSelectedOption
                                            ?
                                            <>
                                                <div className="gapping_class">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.OppStageSelectedOption
                                                                    ?
                                                                    <>
                                                                        <div className="genai_fields">
                                                                            <label className="form-attribute" style={{ whiteSpace: "nowrap" }}><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                                Is a SOW in scope for this piece of work ?</label>
                                                                            <Select
                                                                                isSearchable={true}
                                                                                placeholder="Choose value"
                                                                                value={this.state.IsSOWScope}
                                                                                onChange={(e) => this.sowScopePeiceOfWorkChange(e)}
                                                                                options={YESORNO}
                                                                                styles={this.state.selectStyle.IsSOWScope}
                                                                                classNamePrefix={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "an-simple-select" : ""}
                                                                                isDisabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.IsSOWScope && this.state.IsSOWScope.label === "Yes"
                                                                    ?
                                                                    <>
                                                                        {
                                                                            this.state.OppStageSelectedOption
                                                                                ?
                                                                                <>
                                                                                    <div className="">
                                                                                        <div className="genai_fields">
                                                                                            <label className="form-attribute" style={{ whiteSpace: "nowrap" }}><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                                                SOW signed & agreed with the client ?</label>
                                                                                            <Select
                                                                                                isSearchable={true}
                                                                                                placeholder="Choose SOW Signed"
                                                                                                value={this.state.IsSOWSigned}
                                                                                                onChange={(e) => this.sowScopeSignedChange(e)}
                                                                                                options={YESORNO}
                                                                                                styles={this.state.selectStyle.IsSOWSigned}
                                                                                                classNamePrefix={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "an-simple-select" : ""}
                                                                                                isDisabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                null
                                                                        }
                                                                    </>
                                                                    :
                                                                    null
                                                            }


                                                        </div>
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.IsSOWScope && this.state.IsSOWScope.label === "Yes"
                                                                    ?
                                                                    <>
                                                                        <div className="genai_fields">
                                                                            <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                                Expected SOW submission Date / SOW submission Date
                                                                            </label>
                                                                            <input
                                                                                onChange={(e) => this.expectedSOWSubmissionDateorSowSubmissionDateChange(e.target.value)}
                                                                                type="date"
                                                                                value={this.state.SOWSubDate}
                                                                                min={this.state.IsSOWSigned && this.state.IsSOWSigned.label === "Yes" ? null : today}
                                                                                style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.SOWSubDate, color: "#122539" }}
                                                                                className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input" : "textarea-css form-attribute"}
                                                                                disabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            <div className="">
                                                                {/* <div className="genai_fields">
                                                    <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> Upload a copy of the SOW <span className="file_max_size">Max size: 5MB</span></label>
                                                    <div className="file_upload_plus_mark_block">
                                                        <label htmlFor="SOW-COPY" className="file_upload_plus_mark">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                        <input id="SOW-COPY" 
                                                            type="file" style={{ display: 'none' }} 
                                                            onChange={(event) => this.uploadFile(event, "file1")} 
                                                            accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                        />
                                                        <button className="file_name">File name here</button>
                                                    </div>
                                                    <div className="">
                                                        <label className="file_upload_instruction">[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                                    </div>
                                                    
                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.IsSOWScope && this.state.IsSOWScope.label === "Yes"
                                                                    ?
                                                                    <>
                                                                        <div className="">
                                                                            <div className="genai_fields">
                                                                                <label className="form-attribute"><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                                    Expected SOW Closure Date / SOW closure Date
                                                                                </label>
                                                                                <input

                                                                                    type="date"
                                                                                    min={this.state.IsSOWSigned && this.state.IsSOWSigned.label === "Yes" ? null : today}
                                                                                    onChange={(e) => this.expectedSOWClosureDateorSowClosureDateChange(e.target.value)}
                                                                                    value={this.state.SOWCloseDate}
                                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.SOWCloseDate, color: "#122539" }}
                                                                                    className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input" : "textarea-css form-attribute"}
                                                                                    disabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                this.state.OppStageSelectedOption
                                                                    ?
                                                                    <>
                                                                        <div className="">
                                                                            <div className="genai_fields">
                                                                                <label className="form-attribute" style={{ whiteSpace: "nowrap" }}><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                                    Expected Deployment Date/ Go-Live Date</label>
                                                                                <input

                                                                                    type="date"
                                                                                    //min={this.state.OppState && this.state.OppState === "Completed" ? null : today}
                                                                                    onChange={(e) => this.expectedDeploymentDateDateorGoLiveeDateChange(e.target.value)}
                                                                                    value={this.state.CompletionDate}
                                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.CompletionDate, color: "#122539" }}
                                                                                    className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input" : "textarea-css form-attribute"}
                                                                                    disabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            {/* <div className="genai_fields">
                                                <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> SOW File</label>
                                                <div className="">
                                                    <button className="file_name">SOW File</button>
                                                </div>
                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }


                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : ""}&nbsp;</label>
                                                                        Functional Owner OHR</label>
                                                                    <input
                                                                        pattern="\d*" maxLength="9"
                                                                        placeholder='Enter functional owner OHR'
                                                                        defaultValue={this.state.FunctionalOHR}
                                                                        onChange={(e) => this.handleFunctionalOhrChange(e)}
                                                                        onCut={(e) => this.handleFunctionalOhronCut(e)}
                                                                        onKeyDown={(evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault()}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.FunctionalOHR }}
                                                                        //key={this.state.FunctionalOHR}
                                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                                        disabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : ""}&nbsp;</label>
                                                                        Functional Owner</label>
                                                                    <input className="form-input" placeholder="Functional Owner" type="text" disabled value={this.state.Functional} style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Functional }} />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="genai_fields">
                                                                <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : ""}&nbsp;</label>
                                                                    Technical Owner OHR</label>
                                                                <input
                                                                    type='number' maxLength={9}
                                                                    placeholder='Enter Technical owner OHR'
                                                                    defaultValue={this.state.TechOHR}
                                                                    onChange={(e) => this.handleTechnicalOhrChange(e)}
                                                                    onCut={(e) => this.handleTechnicalOhronCut(e)}
                                                                    onKeyDown={(evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault()}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.TechOHR }}
                                                                    className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                                    disabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                //key={this.state.TechOHR}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="col-md-3">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5) ? "*" : ""}&nbsp;</label>
                                                                        Technical Owner</label>
                                                                    <input className="form-input" placeholder="Technical Owner" type="text" disabled value={this.state.Tech} style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Tech }} />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-3">
                                            {
                                                this.state.OppStageSelectedOption
                                                    ?
                                                    <>
                                                        <div className="genai_fields">
                                                            <label className="form-attribute" ><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                Discussion Led By
                                                            </label>
                                                            <Select
                                                                isSearchable={true}
                                                                placeholder="Choose value"
                                                                onChange={(e) => this.transitionLedChange(e)}
                                                                value={this.state.TransitionLed}
                                                                options={DISCUSSIONLEDBY}
                                                                styles={this.state.selectStyle.TransitionLed}
                                                                classNamePrefix={((this.state.OppStageSelectedOption.value === 8 || this.state.OppStageSelectedOption.value === 7) || !this.state.fieldsEditable) ? "an-simple-select" : ""}
                                                                isDisabled={((this.state.OppStageSelectedOption.value === 8 || this.state.OppStageSelectedOption.value === 7) || !this.state.fieldsEditable) ? true : false}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }

                                        </div>
                                    </div>


                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                        Domain Knowledge
                                                                        <span className="field_max_characters">(max. 300 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            placeholder="Provide value"
                                                                            type="text"
                                                                            onChange={(e) => this.domainKnowledgeChange(e.target.value)}
                                                                            value={this.state.Knowledge}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Knowledge }}
                                                                            disabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                            className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input textarea_width" : "textarea-css form-attribute textarea_width"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }


                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                        Data used
                                                                        <span className="field_max_characters">(max. 300 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            placeholder="Provide value" type="text"
                                                                            onChange={(e) => this.dataUsedChange(e.target.value)}
                                                                            value={this.state.Data}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Data }}
                                                                            disabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                            className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input textarea_width" : "textarea-css form-attribute textarea_width"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                        Algorithms Used
                                                                        <span className="field_max_characters">(max. 300 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            placeholder="Provide value" type="text"
                                                                            onChange={(e) => this.algorithmChanged(e.target.value)}
                                                                            value={this.state.Algo}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Algo }}
                                                                            disabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                            className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input textarea_width" : "textarea-css form-attribute textarea_width"}
                                                                        />
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }


                                                {
                                                    this.state.OppStageSelectedOption
                                                        ?
                                                        <>
                                                            <div className="">
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        <label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                        Tech Infrastructure used
                                                                        <span className="field_max_characters">(max. 300 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            placeholder="Provide value"
                                                                            type="text"
                                                                            onChange={(e) => this.techInfraUsedChanged(e.target.value)}
                                                                            value={this.state.Infra}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Infra }}
                                                                            disabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                            className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input textarea_width" : "textarea-css form-attribute textarea_width"}

                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }


                                            </div>
                                        </div>
                                    </div>


                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="">
                                                    {
                                                        this.state.OppStageSelectedOption
                                                            ?
                                                            <>
                                                                <div className="genai_fields">
                                                                    <label className="form-attribute" >
                                                                        <label className="mandatery_asterisk">&nbsp;</label>Share holder value created
                                                                        <span className="field_max_characters">(max. 300 chars)</span>
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            disabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                                            className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input textarea_width" : "textarea-css form-attribute textarea_width"}
                                                                            placeholder="Provide value"
                                                                            type="text"
                                                                            value={this.state.ValueCreated}
                                                                            onChange={(e) => this.shareHolderValueCreated(e.target.value)}
                                                                            style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.ValueCreated }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">

                                            </div>
                                        </div>
                                    </div>



                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="genai_fields">
                                                <label className="form-attribute" >&nbsp;SFDC OLI Number</label>
                                                <div className="OLI_Number_format">
                                                    <span><label>OLI-</label></span>
                                                    <input 
                                                        value={this.state.OLINum}
                                                        type="number"
                                                        onChange={(e) => this.handleSFDCOLIChange(e.target.value)}
                                                        placeholder='Provide OLI XXXXXXX number'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.OLINum }}
                                                        disabled={this.state.OppState && this.state.OppState === "Completed" ? true : false}
                                                        className={this.state.OppState && this.state.OppState === "Completed" ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="genai_fields">
                                                <label className="form-attribute" >&nbsp;SFDC OPP ID</label>
                                                <div className="OLI_Number_format">
                                                    <span><label>Opp-</label></span>
                                                    <input
                                                        value={this.state.OppID}
                                                        type="number"
                                                        onChange={(e) => this.handleSFDCOppIDChange(e.target.value)}
                                                        placeholder='Provide XXXXXXX number'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.OppID }}
                                                        disabled={this.state.OppState === "Completed" ? true : false}
                                                        className={this.state.OppState === "Completed" ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                    />
                                                </div>
                                            </div>


                                        </div>
                                        <div className="col-md-3">
                                            {
                                                this.state.OppStageSelectedOption
                                                    ?
                                                    <>
                                                        <div className="genai_fields">
                                                            <label className="form-attribute" style={{ whiteSpace: "nowrap" }}><label className="mandatery_asterisk">{this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 6) ? "*" : ""}&nbsp;</label>
                                                                Number of FTEs currently using this solution
                                                            </label>
                                                            <input
                                                                value={this.state.FTEUsage}
                                                                type="number"
                                                                onChange={(e) => this.noOfFTEUsageChange(e.target.value)}
                                                                placeholder='Max limit of 5000'
                                                                style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.FTEUsage }}
                                                                onKeyDown={(evt) =>
                                                                    (evt.key === "e" ||
                                                                        evt.key === "E" ||
                                                                        evt.key === "+" ||
                                                                        evt.key === "-") &&
                                                                    evt.preventDefault()
                                                                }
                                                                onWheel={(e) => e.target.blur()}
                                                                className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                                disabled={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? true : false}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }

                                        </div>
                                    </div>


                                    <div className="gapping_class">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Total People in the process
                                                    </label>
                                                    <input
                                                        value={this.state.ProcessUsers}
                                                        type="number"
                                                        onChange={(e) => this.TotalPPLInTheProcess(e.target.value)}
                                                        placeholder='Max limit of 5000'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.ProcessUsers }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) || !this.state.fieldsEditable) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;# of people with access to the solution
                                                    </label>
                                                    <input
                                                        value={this.state.AccessUsers}
                                                        type="number"
                                                        onChange={(e) => this.PPLAccessSoln(e.target.value)}
                                                        placeholder='Max limit of 5000'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.AccessUsers }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) || !this.state.fieldsEditable) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;People using it daily
                                                    </label>
                                                    <input
                                                        value={this.state.DailyUsers}
                                                        type="number"
                                                        onChange={(e) => this.PPLUsingDaily(e.target.value)}
                                                        placeholder='Max limit of 5000'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.DailyUsers }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) || !this.state.fieldsEditable) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;People using it weekly
                                                    </label>
                                                    <input
                                                        value={this.state.WeeklyUsers}
                                                        type="number"
                                                        onChange={(e) => this.PPLUsingWeekly(e.target.value)}
                                                        placeholder='Max limit of 5000'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.WeeklyUsers }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) || !this.state.fieldsEditable) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Total Weekly transactions
                                                    </label>
                                                    <input
                                                        value={this.state.WeeklyTrans}
                                                        type="number"
                                                        max={100000}
                                                        onChange={(e) => this.TotalWeeklyTransaction(e.target.value)}
                                                        placeholder='Max limit of 100000'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.WeeklyTrans }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) || !this.state.fieldsEditable) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" >
                                                        &nbsp;Transactions processed through GenAI solution
                                                    </label>
                                                    <input
                                                        value={this.state.SolnTrans}
                                                        type="number"
                                                        max={100000}
                                                        onChange={(e) => this.GenAITransactionProcessedSoln(e.target.value)}
                                                        placeholder='Max limit of 100000'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.SolnTrans }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) || !this.state.fieldsEditable) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Number of upvotes
                                                    </label>
                                                    <input
                                                        value={this.state.Upvotes}
                                                        type="number"
                                                        onChange={(e) => this.noOfUpVotes(e.target.value)}
                                                        placeholder='Max limit of 5000'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Upvotes }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) || !this.state.fieldsEditable) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;% of upvotes
                                                    </label>
                                                    <input
                                                        value={this.state.UpvotesPercent}
                                                        type="number"
                                                        onChange={(e) => this.PercentOfUpVotes(e.target.value)}
                                                        placeholder='Max limit of 100 and 2 decimal places allowed'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.UpvotesPercent }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) || !this.state.fieldsEditable) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Accuracy of outcome
                                                    </label>
                                                    <input
                                                        value={this.state.Accuracy}
                                                        type="number"
                                                        onChange={(e) => this.AccuracyOfOutcome(e.target.value)}
                                                        placeholder='Max limit of 100 and 2 decimal places allowed'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.Accuracy }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) || !this.state.fieldsEditable) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="genai_fields">
                                                    <label className="form-attribute" style={{ whiteSpace: "nowrap" }}>
                                                        &nbsp;Uptime of the solution
                                                    </label>
                                                    <input
                                                        value={this.state.UptimePercent}
                                                        type="number"
                                                        onChange={(e) => this.UptimeOfSoln(e.target.value)}
                                                        placeholder='Max limit of 100 and 2 decimal places allowed'
                                                        style={{ maxWidth: "100%", border: "1px solid lightgrey", ...this.state.selectStyle.UptimePercent }}
                                                        onKeyDown={(evt) =>
                                                            (evt.key === "e" ||
                                                                evt.key === "E" ||
                                                                evt.key === "+" ||
                                                                evt.key === "-") &&
                                                            evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        className={((this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8) || !this.state.fieldsEditable) ? "form-input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                        disabled={((this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8)) || !this.state.fieldsEditable) ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-3">
                                            {/* <div className="genai_fields">
                                                <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> Go-Live Upload <span className="file_max_size">Max size: 5MB</span></label>
                                                <div className="file_upload_plus_mark_block">
                                                    <label htmlFor="GO-LIVE" className="file_upload_plus_mark">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                    <input id="GO-LIVE" 
                                                        type="file" style={{ display: 'none' }} 
                                                        onChange={(event) => this.uploadFile(event, "file1")} 
                                                        accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                    />
                                                    <button className="file_name">File name here</button>
                                                </div>
                                                <div className="">
                                                        <label className="file_upload_instruction">[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                                    </div>
                                            </div> */}
                                        </div>
                                        <div className="col-md-3">
                                            {/* <div className="genai_fields">
                                                <label className="form-attribute" ><label className="mandatery_asterisk">*</label> Go-Live email file name</label>
                                                <button className="file_name" > Go-Live email file name</button>
                                            </div> */}
                                        </div>
                                        <div className="col-md-3">
                                            {/* <div className="genai_fields">
                                                <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> GOL/FP&A/Client Approval <span className="file_max_size">Max size: 5MB</span></label>
                                                <div className="file_upload_plus_mark_block">
                                                    <label htmlFor="GOL/FP&A/Client-Approval" className="file_upload_plus_mark">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                    <input id="GOL/FP&A/Client-Approval" 
                                                        type="file" style={{ display: 'none' }} 
                                                        onChange={(event) => this.uploadFile(event, "file1")} 
                                                        accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                    />
                                                    <button className="file_name">File name here</button>
                                                </div>
                                                <div className="">
                                                        <label className="file_upload_instruction">[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                                    </div>
                                                
                                            </div> */}
                                        </div>
                                        <div className="col-md-3">

                                            {/* <div className="genai_fields">
                                                <label className="form-attribute" ><label className="mandatery_asterisk">&nbsp;</label> GOL/FP&A/Client Approval</label>
                                                <button className="file_name" >GOL/FP&A/Client Approval- filename</button>
                                            </div> */}
                                        </div>
                                    </div>


                                    {
                                        this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 2 || this.state.OppStageSelectedOption.value === 3 || this.state.OppStageSelectedOption.value === 4 || this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6 || this.state.OppStageSelectedOption.value === 7 || this.state.OppStageSelectedOption.value === 8 || this.state.OppStageSelectedOption.value === 9)
                                            ?
                                            <>
                                                <div className="">
                                                    <GenAIFPNAGOL
                                                        IdeaId={this.state.RecID}
                                                        //viewIdea={(this.state.OppState === PENDING_APPROVAL_FROM_MBB || this.state.OppState === PENDING_APPROVAL_FROM_SOLUTION_OWNER || this.state.OppState === PENDING_APPROVAL_FROM_UNIT_SPOC || this.state.OppState === PENDING_APPROVAL_FROM_CENTRAL_TEAM) ? true : false}
                                                        viewIdea={this.state.fieldsEditable ? false : true}
                                                        vdSelected={this.state.impactOptionSelected ? this.state.impactOptionSelected.label : this.state.oppDetails.VDType}
                                                        fetchPPTDeck={this.pullPPTDeck}
                                                        fetchClientDocName={this.pullClientDocName}
                                                        fetchClientDocType={this.pullClientDocType}
                                                        fetchClientDocContent={this.pullClientDocContent}
                                                        fetchGolDocName={this.pullgolFileName}
                                                        fetchGolDocType={this.pullgolFileType}
                                                        fetchGolDocContent={this.pullgolContent}
                                                        fetchfpnaDocName={this.pullfpnafileName}
                                                        fetchfpnaDocType={this.pullfpnafileType}
                                                        fetchfpnaDocContent={this.pullfpnafileContent}

                                                        fetchClientName={this.pullCustName}
                                                        fetchClientRole={this.pullClientDesign}
                                                        fetchClientSignOffDate={this.pullCustSignOffDate}

                                                        fetchGolOhr={this.pullGOLOHR}
                                                        fetchGolName={this.pullGolName}

                                                        fetchFpnaOhr={this.pullFpnaOhr}
                                                        fetchFpnaName={this.pullFpnaName}

                                                        fetchFileDetails={this.pull_fileDetails}
                                                        sendBitoFPNA={this.state.businessImpactValueArray}
                                                        sendIsFpnaRequired={this.state.isFpnaRequired}
                                                        showMandatory={(this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6)) ? true : false}
                                                    />
                                                </div>
                                                <div className="gapping_class">
                                                    <GenAIValueDelivery
                                                        func={this.pull_data}
                                                        //viewIdea={(this.state.OppState === PENDING_APPROVAL_FROM_MBB || this.state.OppState === PENDING_APPROVAL_FROM_SOLUTION_OWNER || this.state.OppState === PENDING_APPROVAL_FROM_UNIT_SPOC || this.state.OppState === PENDING_APPROVAL_FROM_CENTRAL_TEAM) ? true : false}
                                                        viewIdea={this.state.fieldsEditable ? false : true}
                                                        vdType={this.handleVDTypeChange}
                                                        IdeaId={this.state.RecID}
                                                        fetchMonetizationFileDetails={this.pull_file_data}

                                                        fetchMonetizationFpnaOhr={this.pull_monetization_fpna_ohr}
                                                        fetchMonetizationFpnaName={this.pull_monetization_fpna_name}
                                                        fetchMonetizationSowID={this.pull_monetization_sowId}

                                                        bbMonetizationTotal={this.pull_monetizedTotal}
                                                        monetizableProject={this.pull_monetizableProject}
                                                        monetizableStatus={this.pull_monetizableStatus}

                                                        fetchFPAOHRforIncreaseInRevenue={this.fetchFPAOHRforIncreaseInRevenue}
                                                        bbMonetizationApproval={false}
                                                        postFpnaValid={true}
                                                        fetchDeletedBIRows={this.remove_bi_data_after_delete}

                                                        fetchMonetizationGOLName={this.pullMonetizationGOLName}
                                                        fetchMonetizationGOLOhr={this.pullMonetizationGOLOhr}

                                                        fetchCategoryNotListed={this.pullCategoryNotListed}
                                                        showMandatory={this.state.OppStageSelectedOption && (this.state.OppStageSelectedOption.value === 5 || this.state.OppStageSelectedOption.value === 6) ? true : false}

                                                    />
                                                </div>
                                            </>
                                            :
                                            null
                                    }



                                    <div className="create_opp_btn_group">
                                        <div className="btn-group">
                                            {
                                                this.state.OppState && this.state.OppState === "Completed"
                                                ?
                                                <>
                                                    <button tabIndex={20} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.viewDone}>Done</button>
                                                </>
                                                :
                                                <button tabIndex={20} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                                    onClick={this.updateOpp}
                                                >Update</button>
                                                
                                            }
                                            
                                            <button tabIndex={21} className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.cancelOpp}>Cancel</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        )
    }



}

export default UpdateOpp
