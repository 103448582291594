
import React, { Component, useState } from 'react';
import './App.css';
import Header from './views/Header';
import Footer from './views/Footer';
import Home from './views/Home';
import Login from './views/Login';
import Private from './views/Private';
import SubmitPage from './views/SubmitPage';
import ReviewPage from './views/ReviewPage';
import ReviewIdeaPage from "./views/ReviewIdeaPage";
import DelegatePage from "./views/DelegatePage";
import UpdateIdeaPage from "./views/UpdateIdeaPage";
import ViewIdeaPage from "./views/ViewIdeaPage";
import EditIdeaPage from './views/EditIdeaPage';
import MBBReviewIdeaPage from './views/MBBReviewIdeaPage';
import LDTReviewIdeaPage from './views/LDTReviewIdeaPage';
import { OKTA_BASE_URL, OKTA_CLIENT_ID, OKTA_REDIRECT_URL, USER_DATA, PROD_MODE, ACCESS_TOKEN, GET_METADATA } from './assets/constants/constants';
import FinalReviewIdeaPage from "./views/FinalReviewIdeaPage";
import { Route } from 'react-router-dom';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { useHistory } from 'react-router-dom';
import { ProjectListPage } from './views/ProjectListPage';
import { BbMonetizationApproval } from './views/BbMonetizationApproval';
import { MbbMonetizationApproval } from './views/MbbMonetizationApproval';
import { ApprovalHistory } from './views/ApprovalHistory';


import ValueDelivery from './utils/ValueDelivery';


import ViewProjectPage from './views/ViewProjectPage';

import ViewAllIdeaPage from './views/ViewAllIdeaPage';
import ViewAllProjectsPage from './views/ViewAllProjectsPage';
import AdminEditPage from './views/AdminEditPage';
// import PrivateRoutes from "./views/PrivateRoutes";
import CustomProjectPage from './views/CustomProjectPage';
import UpdatedSpeech from './views/UpdatedSpeech';
import SpeechSubmitPage from './views/SpeechToTextSubmitPage'
import ProjectReviewGroup from './views/ProjectReviewGroup'
import AuditorReview from './views/AuditerReview'
import MyQCReview from './views/MyQcReview';
import AdminDataEdit from './views/AdminDataEdit';
import QCMonetizationApproval from './views/QCMonetizationApproval';
import FpnaGol from './utils/FPNAGOL';
import ShowTree from './utils/ShowTree';
import Hirearchy from './utils/Hirearchy';
import DescriptiveAnalyticsTemplate from './utils/DescriptiveAnalyticsTemplate';
import DBApprovalListPage from './views/DBApprovalListPage';
import DbSpocApproval from './views/DbSpocApproval';
import SustenanceMetricsPerformanceSurvey from './views/SustenanceMetricsPerformanceSurvey'
import SustenanceMetricsApprovalPage from './views/SustenanceMetricsApprovalPage';


import DiagnosticOrPrescriptiveTemplate from './utils/DiagnosticOrPrescriptiveTemplate';
import PredictiveTemplate from './utils/PredictiveTemplate';
import DisplayTemplates from './utils/DisplayTemplates';
import RenewToken from './utils/RenewToken'


import E360SPOCGroupListPage from './views/E360SPOCGroupListPage';
import E360SPOCListPage from './views/E360SPOCListPage';
import E360SPOCGroupApprovalPage from './views/E360SPOCGroupApprovalPage';
import E360SPOCApprovalPage from './views/E360SPOCApprovalPage';


// Gen AI
import CreateOpp from './views/GenAICreateOpp';
import ViewOpp from './views/GenAIViewOpp';
import ViewAllOpps from './views/GenAIViewAllOpps';
import GenAIValueDelivery from './views/GenAIValueDelivery';
import GenAIFPNAGOL from './views/GenAIFPNAGOL';
import UpdateOpp from './views/GenAIUpdateOpp';
import ViewOpps from './views/GenAIViewOpps';
import GenAISolutionApproval from './views/GenAISolutionApproval'
import GenAISolutionApprovalQueue from './views/GenAISolutionApprovalQueue'

import GenAIMbbApproval from './views/GenAIMbbApproval';
import GenAIMbbApprovalQueue from './views/GenAIMbbApprovalQueue';

import GenAICentralTeamApprovalQueue from './views/GenAICentralTeamApprovalQueue'
import GenAICentralTeamApproval from './views/GenAICentralTeamApproval'

import GenAICentralApprovalQueue from './views/GenAICentralApprovalQueue';
import GenAICentralApproval from './views/GenAICentralApproval';


import GenAIUnitSPOCApprovalQueue from './views/GenAIUnitSPOCApprovalQueue';
import GenAIUnitSPOCApproval from './views/GenAIUnitSPOCApproval';




const oktaAuth = new OktaAuth({
  issuer: `${OKTA_BASE_URL}`,
  clientId: `${OKTA_CLIENT_ID}`,
  redirectUri: `${OKTA_REDIRECT_URL}`
  
});

// //Local OKTA My SPA
// const oktaAuth = new OktaAuth({
//   issuer: 'https://dev-94209304.okta.com/oauth2/default',
//   clientId: '0oak8h4jaGBbZt4OQ5d6',
//   redirectUri: 'http://localhost:3000/callback'
// });

// //Local OKTA My Web App
// const oktaAuth = new OktaAuth({
//   issuer: 'https://dev-94209304.okta.com/oauth2/default',
//   clientId: '0oah4q48p5wXJNN9Y5d6',
//   redirectUri: 'http://localhost:3000/callback'
// });

// //Local OKTA Ideation App
// const oktaAuth = new OktaAuth({
//   issuer: 'https://dev-94209304.okta.com/oauth2/default',
//   clientId: '0oah9trlqHyQUfgwR5d6',
//   redirectUri: 'http://localhost:3000/callback'
// });


// // //Genpact OKTA environment
// const oktaAuth = new OktaAuth({
//   issuer: 'https://genpact.oktapreview.com/oauth2/default',
//   clientId: '0oa1f8kqebfCS7yiX0x7',
//   redirectUri: 'http://localhost:3000/callback'
// });


// export let devmode = "false"
// const queryParameters = new URLSearchParams(window.location.search);
// devmode = queryParameters.get("devmode")
// console.log("devmode",devmode)

const HostName = window.location.hostname.trim();
console.log(HostName)
const userData = JSON.parse(localStorage.getItem(USER_DATA))
if (userData) {
  if (HostName === "xceleratedev.genpact.com") {
    if (userData.profile.oHRId === "850065233" || userData.profile.oHRId === "850075058" || userData.profile.oHRId === "850074422" || userData.profile.oHRId === "850075361" ||userData.profile.oHRId === "850054658" || userData.profile.oHRId === "850051497" || userData.profile.oHRId === "850061318" || userData.profile.oHRId === "850051496") {
      console.log("Permitted only for developers")
    } else {
      window.location.replace('https://xcelerateuat.genpact.com');
    }
  }
}

// const generateNonce = () => {
//   const array = new Uint8Array(16); // Create a byte array
//   window.crypto.getRandomValues(array); // Fill the array with random values
//   console.log("nounce code =>",btoa(String.fromCharCode.apply(null, array)))
//   return btoa(String.fromCharCode.apply(null, array)); // Convert to base64
// };
// generateNonce()


const App = (props) => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };
  const onAuthRequired = function () {
    history.push('/login')
  }
  return (
    <div className="App">
      <div className="page">
        <div className="content">
          <Security oktaAuth={oktaAuth}
            restoreOriginalUri={restoreOriginalUri}
            onAuthRequired={onAuthRequired}>
            <Header />
            <RenewToken />
            <Route path='/' exact={true} component={Home} />
            <Route path='/login' exact={true} component={Login} />
            <Route path='/home' component={Home} />
            <SecureRoute path="/submit-page" component={SubmitPage} />
            <SecureRoute path="/review-page" render={props => <ReviewPage {...props} />} />
            <Route path="/review-idea-page" render={props => <ReviewIdeaPage {...props} />} />
            <Route path="/delegate-page" render={props => <DelegatePage {...props} />} />
            <Route path="/update-idea-page" render={props => <UpdateIdeaPage {...props} />} />
            <Route path="/view-idea-page" render={props => <ViewIdeaPage {...props} />} />
            <Route path="/edit-idea-page" render={props => <EditIdeaPage {...props} />} />
            <Route path="/final-review-idea-page" render={props => <FinalReviewIdeaPage {...props} />} />
            <Route path="/mbb-review-idea-page" render={props => <MBBReviewIdeaPage {...props} />} />
            <Route path="/ldt-review-idea-page" render={props => <LDTReviewIdeaPage {...props} />} />
            <Route path="/list-projects-page" render={props => <ProjectListPage {...props} />} />
            <Route path="/view-project-page" render={props => <ViewProjectPage {...props} />} />
            <Route path="/view-all-ideas-page" render={props => <ViewAllIdeaPage {...props} />} />
            <Route path="/view-all-projects-page" render={props => <ViewAllProjectsPage {...props} />} />
            <Route path="/admin-edit-page" render={props => <AdminEditPage {...props} />} />
            <Route path="/updated" render={props => <UpdatedSpeech {...props} />} />
            <Route path="/speech-submit-page" render={props => <SpeechSubmitPage {...props} />} />
            <Route path="/view-project-summary-page" render={props => <CustomProjectPage {...props} />} />
            <Route path="/project-review-group-page" render={props => <ProjectReviewGroup {...props} />} />
            <Route path="/auditor-review-page" render={props => <AuditorReview {...props} />} />

            <Route path="/my-qc-reviews-page" render={props => <MyQCReview {...props} />} />
            <Route path="/bb-monetization-approval-page" render={props => <BbMonetizationApproval {...props} />} />
            <Route path="/mbb-monetization-approval-page" render={props => <MbbMonetizationApproval {...props} />} />

            <Route path="/approval-history-page" render={props => <ApprovalHistory {...props} />} />
            <SecureRoute exact path="/ideationhome" render={() => { window.location.href = "idea/index.html" }} />
            <SecureRoute path='/private' exact={true} component={Private} />
            <Route path='/callback' component={LoginCallback} />
            <Route path='/valueDelivery' component={ValueDelivery} />
            <Route path='/admin-data-edit' component={AdminDataEdit} />
            <Route path='/qc-monetization-approval-page' component={QCMonetizationApproval} />
            <Route path='/fpnaGol' component={FpnaGol} />
            <Route path='/showTree' component={ShowTree} />
            <Route path='/hierarchy' component={Hirearchy} />

            {/* DB SPOC  */}
            <Route path='/dbspoc-list-page' component={DBApprovalListPage} />
            <Route path='/dbspoc-approval-page' component={DbSpocApproval} />
            {/* Template */}
            <Route path='/descriptive-analytics-template' component={DescriptiveAnalyticsTemplate} />
            <Route path='/diagnostic-or-prescriptive-template' component={DiagnosticOrPrescriptiveTemplate} />
            <Route path='/predictive-template' component={PredictiveTemplate} />
            <Route path='/display-template' component={DisplayTemplates} />
            {/* Sustenance survey */}
            <Route path='/sustenance-metrics-performance-survey-list-page' component={SustenanceMetricsPerformanceSurvey} />
            <Route path='/sustenance-metrics-approval-page' component={SustenanceMetricsApprovalPage} />


            
            {/* E360 SPOC list and Approval pages */}
            <Route path='/E360-SPOC-Group-List-Page' component={E360SPOCGroupListPage} />
            <Route path='/E360-SPOC-List-Page' component={E360SPOCListPage} />
            <Route path='/E360-SPOC-Group-Approval-Page' component={E360SPOCGroupApprovalPage} />
            <Route path='/E360-SPOC-Approval-Page' component={E360SPOCApprovalPage} />


            <Route path='/create-opportunity' component={CreateOpp} />
            <Route path='/view-opportunity' component={ViewOpp} />
            <Route path='/view-all-opportunities' component={ViewAllOpps} />
            <Route path='/gen-ai-value-delivery' component={GenAIValueDelivery} />
            <Route path='/update-opportunity' component={UpdateOpp} />
            <Route path='/view-opportunities' component={ViewOpps} />


            <Route path='/gen-ai-solution-owner-approval' component={GenAISolutionApproval} />
            <Route path='/gen-ai-solution-owner-approval-queue' component={GenAISolutionApprovalQueue} />
            
            <Route path='/gen-ai-mbb-approval-queue' component={GenAIMbbApprovalQueue} />
            <Route path='/gen-ai-mbb-approval' component={GenAIMbbApproval} />

            <Route path='/gen-ai-central-team-approval-queue' component={GenAICentralTeamApprovalQueue} />
            <Route path='/gen-ai-central-team-approval' component={GenAICentralTeamApproval} />

            <Route path='/gen-ai-central-approval-queue' component={GenAICentralApprovalQueue} />
            <Route path='/gen-ai-central-approval' component={GenAICentralApproval} />
            <Route path='/gen-ai-gol-fpna' component={GenAIFPNAGOL} />
            
            <Route path='/gen-ai-unitspoc-approval-queue' component={GenAIUnitSPOCApprovalQueue} />
            <Route path='/gen-ai-unitspoc-approval' component={GenAIUnitSPOCApproval} />

            
            
            

            
            
            
            

           

            
            



            <div style={{ paddingTop: 30 }}>&nbsp;</div>
            <Footer />
          </Security>
          {/* <br /><br /><br />
          <label>Please use 'SIGN IN' or 'Login' link above to logon to Ideation Home Page.</label> */}
        </div>
      </div>
    </div>
  );
}

export default App;
