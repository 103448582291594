import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import {
  GET_METADATA,
  HOMEPAGE_URL,
  POWER_APP_URL,
  DASHBOARD_URL,
  USER_DATA,
  ACCESS_TOKEN
  
} from "../assets/constants/constants";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import Toast from "light-toast";

function Home() {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [, setIsUserApprover] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const [ldtLinks, setLdtLink] = useState([]);
  const [upcomingEvent, setUpcomingEvent] = useState([]);
  const [importantLink, setImportantLink] = useState([]);
  const [isBBUser, setBBUser] = useState(false);
  const [isApproverUser, setApproverUser] = useState(false);
  const [isQCUser, setQCUser] = useState(false);
  const [isDBSPOCUser, setIsDBSPOCUser] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isE360SPOCUser,setIsE360SPOCUser]=useState(false)
  const [oppCreator, setOppCreator] = useState(false)
  const [oppSolutionOwner, setOppSolutionOwner] = useState(false)
  const [oppCentralTeam, setOppCentralTeam] = useState(false)
  const [oppMBB, setoppMBB] = useState(false)
  const [oppUnitSPOC, setOppUnitSPOC] = useState(false)

  const [viewOpportunities, setViewOpportunities] = useState(false)

  

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      setUserInfo(authState.idToken.claims);
    }
  }, [authState, oktaAuth, userInfo]);

  useEffect(() => {
    if (!authState.isPending && !authState.isAuthenticated) {
      window.location = "/login";
    }
    if (userInfo && userInfo.mail) {
      const userData = {
        id: userInfo.idp,
        profile: {
          firstName: userInfo.name.split(", ")[1],
          lastName: userInfo.name.split(", ")[0],
          locale: "en_US",
          login: userInfo.preferred_username,
          oHRId: userInfo.preferred_username.substring(0, 9),
          timeZone: "America/Los_Angeles",
        },
      };
      localStorage.setItem(ACCESS_TOKEN, authState.idToken.value);
      localStorage.setItem(USER_DATA, JSON.stringify(userData));
      sessionStorage.setItem(USER_DATA, JSON.stringify(userData));

      if (!authState.isPending && !authState.isAuthenticated) {
        window.location = "/login";
      }

      const accessToken = localStorage.getItem(ACCESS_TOKEN)
        ? localStorage.getItem(ACCESS_TOKEN)
        : authState.idToken.value;

      let sessionData = localStorage.getItem(USER_DATA)
        ? JSON.parse(localStorage.getItem(USER_DATA))
        : JSON.stringify(userData);
      const param = {
        EntityType: "OHR",
        EntityName: sessionData
          ? sessionData.profile
            ? sessionData.profile.oHRId
            : ""
          : "",
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:  "Bearer " + accessToken,
          
        },
        body: JSON.stringify(param),
      };
      fetch(GET_METADATA, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          let requestByName = data.DNAUsers
            ? data.DNAUsers.Table1
              ? data.DNAUsers.Table1.length > 0
                ? data.DNAUsers.Table1[0].pg_username
                : ""
              : ""
            : "";
          if (requestByName === "") {
            requestByName = data.PowerAppUsers
              ? data.PowerAppUsers.length > 0
                ? data.PowerAppUsers[0].pg_username
                : ""
              : "";
          }
          if (requestByName !== "") {
            setIsUserApprover(true);
          } else {
            setIsUserApprover(false);
          }
          // Toast.hide();
          data.PowerAppUsers.filter((item) => {
            const userRole = JSON.parse(item.pg_Role).pg_role.trim();
            console.log("user role =>",userRole)


            if (
              userRole === "Administrator" &&
              parseInt(sessionData.profile.oHRId) === parseInt(item.pg_ohr)
            ) {
              setIsAdmin(true);
            }
            if (
              (userRole === "BB" || userRole === "QA" || userRole === "Administrator") &&
              parseInt(sessionData.profile.oHRId) === parseInt(item.pg_ohr)
            ) {
              setBBUser(true);
            }

           
            if (
              (userRole === "BB" ||
                userRole === "Administrator" ||
                userRole === "MBB" ||
                userRole === "LDT Lead" || userRole === "QA") &&
              parseInt(sessionData.profile.oHRId) === parseInt(item.pg_ohr)
            ) {
              setApproverUser(true);
            }
            if ((userRole === "QC") &&  parseInt(sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
              setQCUser(true);
            } 

            if ((userRole === "DBSPOC") && parseInt(sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
              setIsDBSPOCUser(true);
            }
            
            if ((userRole === "E360SPOC" || userRole === "Administrator") &&
              parseInt(sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
              setIsE360SPOCUser(true)
            }
            if ((userRole === "Unit SPOC" || userRole === "Opp SPOC" || userRole === "MBB") &&
              parseInt(sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
              setOppCreator(true)
            }

            if ((userRole === "Unit SPOC" || userRole === "BB" || userRole === "Opp SPOC" || userRole === "MBB") &&
              parseInt(sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
              setViewOpportunities(true)
            }
            if(userRole === "Unit SPOC"){
              setOppUnitSPOC(true)
            }
            if ((userRole === "Opp Solution Owner") &&
              parseInt(sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
              setOppSolutionOwner(true)
            }
            if ((userRole === "Central Team") &&
              parseInt(sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
              setOppCentralTeam(true)
            }
            if ((userRole === "MBB") &&
            parseInt(sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
            setoppMBB(true)
          }
            
          });
            
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });

      Toast.info("Fetching Home data. Please wait...", 6000, () => { });

      const requestOptions2 = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + accessToken,
        },
        body: JSON.stringify(),
      };
      fetch(HOMEPAGE_URL, requestOptions2)
        .then((response) => response.json())
        .then((data) => {
          if (data.Banners.value) {
            setTimeout(() => {
              setBannerData(data.Banners.value);
            }, 2000);
          }
          handleFilterChange(data.Notifications.value);
        });
      // }
    }
    // const getTokens = async () => {
    //   if (authState && authState.isAuthenticated) {
    //     const accessToken = await oktaAuth.getAccessToken();
    //     const refreshToken = await oktaAuth.getRefreshToken();
    //     console.log("Access Token:", accessToken);
    //     console.log("Refresh Token:", refreshToken);
    //   }
    // };

    // getTokens();
    


  }, [authState, userInfo]);

  const handleFilterChange = (notificationParam) => {
    notificationParam.filter((item) => {
      if (item.pg_notificationtype === "LDT Training") {
        setLdtLink((ldtLinks) =>
          [...ldtLinks, item].sort((a, b) => {
            return a.modifiedon > b.modifiedon ? -1 : 1;
          })
        );
      } else if (item.pg_notificationtype === "Important Links") {
        setImportantLink((importantLink) =>
          [...importantLink, item].sort((a, b) => {
            return a.pg_title > b.pg_title ? 1 : -1;
          })
        );
      } else if (item.pg_notificationtype === "Upcoming Events") {
        setUpcomingEvent((upcomingEvent) =>
          [...upcomingEvent, item].sort((a, b) => {
            return a.pg_expirydate > b.pg_expirydate ? 1 : -1;
          })
        );
      }
      return true;
    });
  };

  


  if (!authState.isPending && authState.isAuthenticated) {
    return (
      <section
        className="u-clearfix u-section-1"
        id="sec-0f7f"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <Carousel
          showArrows={false}
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={8000}
          showStatus={false}
        >
          {bannerData.map((item) => (
            <a
              href={item.pg_url}
              target="_blank"
              className="home_page_anchor_tag"
            >
              <div>
                <img
                  src={`${item.pg_txtimage}`
                    .replace('"', "")
                    .replace('"', "")
                    .replace('="', "=")}
                  alt="banner"
                />
                <div className="imagetext bannerHead1 georgia">
                  {item.pg_title}
                </div>
                <div className="imagetext1 arial">{item.pg_description}</div>
                {/* <div className="explore"><a className="exploreMore arial" href={item.pg_url}>explore more</a></div> */}
              </div>
            </a>
          ))}
        </Carousel>
        <div className="u-clearfix u-sheet u-sheet-1" style={{ width: "100%" }}>
          <div
            style={{ paddingBottom: 40 }}
            className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1"
          >
            <div
              className="u-container-layout u-container-layout-1"
              style={{ marginTop: -80 }}
            >
              <div
                id="idea1"
                className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
              >
                <div className="u-container-layout u-container-layout-7">
                  <div className="u-container-style u-group u-shape-rectangle">
                    <div className="u-container-layout">
                      <div className="container pt-5">
                        <div className="pageHead georgia headColor">
                          Overview
                        </div>
                        <div className="textColor pageText arial mt-2">
                          <p>
                            ProGear 2.0 is a unified Project Management solution
                            for Genpact encompassing the entire journey of Lean
                            Digital Transformation (LDT) projects, covering the
                            project lifecycle, from the point of genesis as an
                            idea through to implementation and eventual closure
                            as a project. It also acts as a cornerstone for the
                            entire LDT function to centrally run and track
                            projects of varying sizes and scope along with the
                            value delivered to our stakeholders.
                          </p>
                        </div>
                      </div>



                      
                      <div className="container pt-4">
                        <div className="pageHead georgia headColor">
                          Ideation
                        </div>
                        <div className="">
                          <img
                            src={require("../assets/images/bar.png")}
                            className="w100"
                            alt=""
                          />
                        </div>
                        <div className="row arial px-md-5 px-lg-5">
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                              <a
                                href="/submit-page"
                                className="textColor p-2 d-block"
                              >
                                Submit ideas
                              </a>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                              <a
                                href="/review-page"
                                className="textColor p-2 d-block"
                              >
                                View ideas
                              </a>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                              {isBBUser ? (
                                <a
                                  href="/review-page?page=approval"
                                  className="textColor p-2 d-block"
                                >
                                  Approve ideas
                                </a>
                              ) : (
                                <a
                                  style={{ opacity: 0.4, cursor: "default" }}
                                  className="textColor p-2 d-block"
                                >
                                  Approve ideas
                                </a>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* ........... */}

                        <div className="row arial px-md-5 px-lg-5">
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                              {isE360SPOCUser ? (
                                <a
                                  href="/E360-SPOC-Group-List-Page"
                                  className="textColor p-2 d-block"
                                >
                                  E360 SPOC approval 
                                </a>
                              ) : (
                                <a
                                  style={{ opacity: 0.4, cursor: "default" }}
                                  className="textColor p-2 d-block"
                                >
                                  E360 SPOC approval
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                          

                        {/* ............. */}


                      </div>
                      <div className="container pt-5">
                        <div className="pageHead georgia headColor">
                          Project management
                        </div>
                        <div className="">
                          <img
                            src={require("../assets/images/bar.png")}
                            className="w100"
                            alt=""
                          />
                        </div>
                        <div className="row arial px-md-5 px-lg-5">
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                              {isApproverUser ? (
                                <a
                                  href="/review-page?page=leanapproval"
                                  className="textColor p-2 d-block"
                                >
                                  Approve lean projects
                                </a>
                              ) : (
                                <a
                                  style={{ opacity: 0.4, cursor: "default" }}
                                  className="textColor p-2 d-block"
                                >
                                  Approve lean projects
                                </a>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                              <a
                                href="/list-projects-page"
                                className="textColor p-2 d-block"
                              >
                                View projects
                              </a>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                              <a
                                href={POWER_APP_URL}
                                className="textColor p-2 d-block"
                                target="_blank"
                                rel="noreferrer"
                              >
                                GB+ Center
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="row arial px-md-5 px-lg-5">
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                              {isQCUser || isAdmin ? (
                                <>
                                  <a
                                    href="/project-review-group-page"
                                    className="textColor p-2 d-block"
                                  >
                                    QC approvals
                                  </a>
                                </>
                              ) : (
                                <a
                                  style={{ opacity: 0.4, cursor: "default" }}
                                  className="textColor p-2 d-block"
                                >
                                  QC approvals
                                </a>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                              {isDBSPOCUser || isAdmin ? (
                                <>
                                  <a
                                    href="/dbspoc-list-page"
                                    className="textColor p-2 d-block"
                                  >
                                    DB SPOC approvals
                                  </a>
                                </>
                              ) : (
                                <a
                                  style={{ opacity: 0.4, cursor: "default" }}
                                  className="textColor p-2 d-block"
                                >
                                  DB SPOC approvals
                                </a>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                              {isBBUser ? (
                                  <a
                                    href="/sustenance-metrics-performance-survey-list-page"
                                    className="textColor p-2 d-block"
                                  >
                                    Sustenance Survey
                                  </a>
                                ) : (
                                  <a
                                    style={{ opacity: 0.4, cursor: "default" }}
                                    className="textColor p-2 d-block"
                                  >
                                    Sustenance Survey
                                  </a>
                                )}
                            </div>
                          </div>
                        </div>

                        
                      </div>

                      {/* .....gen ai..... */}
                      <div className="container pt-5">
                        <div className="pageHead georgia headColor">
                          Gen AI
                        </div>
                        <div className="">
                          <img
                            src={require("../assets/images/bar.png")}
                            className="w100"
                            alt=""
                          />
                        </div>
                        <div className="row arial px-md-5 px-lg-5">
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                              {
                                oppCreator
                                  ?
                                  (
                                    <a href="/create-opportunity" className="textColor p-2 d-block">Create opportunity</a>
                                  ) :
                                  (
                                    <a style={{ opacity: 0.4, cursor: "default" }} className="textColor p-2 d-block">Create opportunity</a>
                                  )
                              }
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                            <a href="/view-opportunities" className="textColor p-2 d-block">View opportunities</a>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                              {
                                oppUnitSPOC
                                  ?
                                  (
                                    <a href="/gen-ai-unitspoc-approval-queue" className="textColor p-2 d-block">Unit SPOC approval</a>
                                  ) :
                                  (
                                    <a style={{ opacity: 0.4, cursor: "default" }} className="textColor p-2 d-block">Unit SPOC approval</a>
                                  )
                              }
                            </div>
                          </div>
                        </div>

                        <div className="row arial px-md-5 px-lg-5">
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                              {
                                oppMBB
                                  ?
                                  (
                                    <a href="/gen-ai-mbb-approval-queue" className="textColor p-2 d-block">MBB approval</a>
                                  ) :
                                  (
                                    <a style={{ opacity: 0.4, cursor: "default" }} className="textColor p-2 d-block">MBB approval</a>
                                  )
                              }
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                              {
                                oppSolutionOwner
                                  ?
                                  (
                                    <a href="/gen-ai-solution-owner-approval-queue" className="textColor p-2 d-block">Solution Owner Approval</a>
                                  ) :
                                  (
                                    <a style={{ opacity: 0.4, cursor: "default" }} className="textColor p-2 d-block">Solution Owner Approval</a>
                                  )
                              }
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                              {
                                oppCentralTeam
                                  ?
                                  (
                                    <a href="/gen-ai-central-team-approval-queue" className="textColor p-2 d-block">Central team approval</a>
                                  ) :
                                  (
                                    <a style={{ opacity: 0.4, cursor: "default" }} className="textColor p-2 d-block">Central team approval</a>
                                  )
                              }

                            </div>
                          </div>
                          
                        </div>
                        <div className="row arial px-md-5 px-lg-5">
                          <div className="col-lg-4 col-md-4 col-sm- col-xs-12 mt-3 mb-2">
                            <div className="boxStart p-4 text-center">
                              <a style={{ opacity: 0.4, cursor: "default" }} className="textColor p-2 d-block">Sustenance tracking</a>
                              {/* <a
                                href="/"
                                className="textColor p-2 d-block"
                              >
                                Sustenance tracking
                              </a> */}
                            </div>
                          </div>
                        </div>


                      </div> 

                      {/* .....gen ai.... */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="bgColor1 py-5" style={{ width: "100%" }}>
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  style={{ textAlign: "left" }}
                >
                  <div className="pageHead georgia text-white">
                    Reports and Dashboards
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-lg-7 col-md-7 col-sm-7 col-xs-12 mt-2"
                  style={{ textAlign: "left" }}
                >
                  <div
                    style={{ fontWeight: 400 }}
                    className="pageText arial text-white mt-3 pr-md-5  pr-lg-5"
                  >
                    The fully integrated reports and dashboards section of
                    ProGear 2.0 provides an in-depth view of all facets of the
                    LDT Project Lifecycle covering all the essential metrics on
                    Ideation, Project Performance, the Value Delivered to the
                    Clients and the Credits accrued by the core LDT
                    practitioners. It also allows users to download reports for
                    further analysis and bespoke reporting.
                  </div>
                  <div className="pt-4 georgia" style={{ marginTop: 40 }}>
                    <a
                      className="exploreMore arial"
                      href={DASHBOARD_URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Explore More{" "}
                    </a>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 mt-2">
                  <img
                    src={require("../assets/images/dashboard.png")}
                    align="right"
                    className="dashImg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container mt-4">
            <div className="row" style={{ marginBottom: 60 }}>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-4">
                <div className="box1Start py-4 pl-3 pr-2">
                  <div className="pageHead2 georgia genpactCoral text-center">
                    LDT trainings
                  </div>
                  <ul id="menulist" className="arial pt-4 boxHeight1">
                    <ul style={{ marginTop: -45, textAlign: "left" }}>
                      {ldtLinks.map((item) => (
                        <li style={{ width: "100%" }}>
                          <a
                            className="ldt-list1 ldt-list"
                            href={item.pg_url}
                            target="_blank"
                            rel="noreferrer"
                            style={{ textAlign: "left", fontSize: 16 }}
                          >
                            {item.pg_title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-4">
                <div className="box1Start py-4 pl-3 pr-2">
                  <div className="pageHead2 georgia genpactCoral text-center">
                    Important links
                  </div>
                  <ul className="arial pt-4 boxHeight1">
                    <ul style={{ marginTop: -45, textAlign: "left" }}>
                      {importantLink.map((item) => (
                        <li style={{ width: "100%" }}>
                          <a
                            className="ldt-list1"
                            href={item.pg_url}
                            target="_blank"
                            rel="noreferrer"
                            style={{ textAlign: "left", fontSize: 16 }}
                          >
                            {item.pg_title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-4">
                <div className="box1Start py-4 pl-3 pr-2">
                  <div className="pageHead2 georgia genpactCoral text-center pb-4">
                    Upcoming events
                  </div>
                  <div className="boxHeight2 pr-3">
                    {upcomingEvent.map((item) => (
                      <>
                        <a
                          href={item.pg_url}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          <div className="d-table arial eventStart mb-3">
                            <div className="d-table-cell event1 text-white">
                              <div className="event1_1">
                                {moment(
                                  item.pg_expirydate,
                                  "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                ).format("MMM")}
                              </div>
                              <div className="event1_2">
                                {moment(
                                  item.pg_expirydate,
                                  "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                ).format("DD")}
                              </div>
                            </div>
                            <div className="d-table-cell event2">
                              <div className="event2_1">{item.pg_title}</div>
                              <div className="event2_2">
                                {item.pg_description}
                              </div>
                            </div>
                          </div>
                        </a>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <></>;
  }
}

export default Home;