import React, { Component } from 'react'
import {  OPPMASTERSTAGES, OPPMASTERSTATE, GET_ALL_IDEA_URL, GET_METADATA, } from '../assets/constants/constants';
import { SORT_DOWN, SORT_UP, SEARCH_ICON, ANGLE_LEFT, ANGLE_RIGHT, ANGLE_DOUBLE_LEFT, ANGLE_DOUBLE_RIGHT, FILTER, CLOSE } from "../assets/constants/Icons.js";
import { VIEW, USER_DATA, ACCESS_TOKEN } from "../assets/constants/constants";
import SortByColumn from "../utils/SortByColumns";
import Toast from 'light-toast';
import Select from 'react-select';
import { selectValidation as textBoxValidation } from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";

export class ViewAllOpps extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);

    constructor(props) {
        super(props)
        let propsData = this.props.location.propsList ? this.props.location.propsList : null;
        if (this.sessionData === null) {
            Toast.loading("This component cannot be accessed directly. Redirecting to application home.", 3000, () => {
            });
            window.location = "/login";
            return;
        }
        const windowUrl = window.location.search;
        this.state = {
            ideaList: [],
            originalIdeaList: [],
            accountSortIconRotate: false,
            ideaNameSortIconRotate: false,
            createdDateSortIconRotate: false,
            reviewStatusSortIcon: false,
            ownerOHR: this.sessionData === null ? null : this.sessionData.profile.oHRId,
            ownerName: this.sessionData === null ? null : this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
            topCountOption: [5, 10, 25, 50, 75, 100],
            topCount: propsData !== null ? parseInt(propsData.topCount) : 5,
            skipCount: 0,
            isPrevEnabled: false,
            isNextEnabled: true,
            IdeaLength: 0,
            totalPages: 0,
            currentPage: 0,
            pageNo: '',
            filter: false,
            WorkflowStatus: '',
            IdeaId: '',
            AccountManual: '',
            RegionManual: '',
            VerticalManual: '',
            TransformationLeverName: '',
            CertificationLevelName: '',
            SubVerticalName: '',
            ServiceLineName: '',
            SubServiceLine: '',
            startDate: '',
            toDate: '',
            isTypedData: false,
            WhereClause: '',
            fromFilterSelection: '',
            globalsearchText: '',
            isTypedInFilter: '',
            isTypedInGlobalSearch: false,
            tempWorkflowStatus: '',
            tempIdeaId: '',
            tempAccountManual: '',
            tempRegionManual: '',
            tempVerticalManual: '',
            tempTransformationLeverName: '',
            tempCertificationLevelName: '',
            tempSubVerticalName: '',
            tempServiceLineName: '',
            tempSubServiceLine: '',
            tempstartDate: '',
            temptoDate: '',
            accountList: [],
            accountSelectionOption: null,
            workFlowStatusList: [],
            workFlowStatusSelectedOption: null,
            transformationLeversList: [],
            transSelectedOption: null,
            serviceLineList: [],
            subServiceLineList: [],
            serviceLineSelectedOption: null,
            subServiceLineSelectedOption: null,
            regionList: [],
            verticalList: [],
            subVerticalList: [],
            regionSelectedOption: null,
            verticalSelectedOption: null,
            subVerticalSelectedOption: null,
            dateList: ["Created date"],
            dateSelectedOption: null,
            selectedDate: '',
            propsData: propsData !== null ? propsData : null,
            certificationLevelsList: [],
            certLevelsSelectedOption: null,
            selectedIdea: [],
            bulkCancel: false,
            bulkActive: false,
            bulkOperation: false,
            selectStyle: {},
            adminUserList: [],
            admin: false,
            Comment: "",
            approvalStatusSelectedOption: null,
            approvalStatusList: [{
                ApprovalStatusId: 1,
                ApprovalStatusName: "NA"
            },
            {
                ApprovalStatusId: 2,
                ApprovalStatusName: "Pending Documents"
            },
            {
                ApprovalStatusId: 3,
                ApprovalStatusName: "Documents Uploaded"
            },
            {
                ApprovalStatusId: 4,
                ApprovalStatusName: "Awaiting MBB Approval"
            },
            {
                ApprovalStatusId: 5,
                ApprovalStatusName: "Awaiting LDT Approval"
            },
            {
                ApprovalStatusId: 6,
                ApprovalStatusName: "Rejected"
            },
            {
                ApprovalStatusId: 7,
                ApprovalStatusName: "Completed"
            },
            {
                ApprovalStatusId: 8,
                ApprovalStatusName: "Awaiting Project Creation"
            },
            {
                ApprovalStatusId: 9,
                ApprovalStatusName: "Awaiting Quality Check Review"
            },
            {
                ApprovalStatusId: 10,
                ApprovalStatusName: "Awaiting Project Review"
            }],
            sortColumnName: "CreatedDate",
            sortOrder: "desc",
            qcApprovalStatusSelectedOption: [],
            newApprovalListForQC: [{
                id: 1,
                label: "Awaiting QC Review"
            },
            {
                id: 2,
                label: "Awaiting Project Review"
            }],
            searchOwner: "",
            searchMentor: "",
            projectPhase: "",
            monetizableSelectedOption: [],
            zeroVDSelectedOption: [],
            zeroVDSelectedLabel: "",
            monetizableSelectedLabel: "",
            searchSegment: "",
            searchUnit: "",
            uniqueUnits: [],
            uniqueSegments: [],
            searchSegmentSelectedOption: [],
            searchUnitSelectedOption: []

        }
    }

    async componentDidMount() {
        await this.fetchIdea();
        await this.fetchMetedata();
    }





    fetchIdea = () => {
        Toast.loading("Fetching opportunities...", () => { });
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  'Bearer ' + this.accessToken
                }
            };
            if (this.state.skipCount === 0) {
                this.setState({
                    isPrevEnabled: false,
                })
            }
            else {
                this.setState({
                    isPrevEnabled: true
                })
            }
            fetch(`${GET_ALL_IDEA_URL}UserOHR=''&WhereClause=''&skipCount=${this.state.skipCount}&topCount=${this.state.topCount}&sortingorder=${this.state.sortOrder}&sortingcolumn=${this.state.sortColumnName}&isItIdeaOrGenAI='GenAI'&CreatedBy=''`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        ideaList: data.IdeaList.value === undefined ? [] : data.IdeaList.value,
                        originalIdeaList: data.IdeaList.value === undefined ? [] : data.IdeaList.value,
                        IdeaLength: data.TotalIdea[0].length,
                    }, () => {
                        console.log("search result =>",this.state.ideaList)
                        let totalPages = Math.ceil(parseInt(this.state.IdeaLength) / parseInt(this.state.topCount));
                        let currentPage = Math.ceil(parseInt(this.state.skipCount) / (parseInt(this.state.topCount)) + 1);
                        this.setState({
                            totalPages: totalPages,
                            currentPage: currentPage
                        }, () => {

                            if (this.state.currentPage === this.state.totalPages) {
                                this.setState({
                                    isNextEnabled: false
                                })
                            }
                            else {
                                this.setState({
                                    isNextEnabled: true
                                })
                            }
                        });
                        this.handleFilterChange({ value: 0 });
                        resolve();
                        Toast.hide();
                    });
                })
                .catch(error => {
                    console.log(error);
                    resolve();
                    Toast.hide();
                });
        });
    }

    fetchFilterIdea = () => {
        Toast.loading("Fetching opportunities...", () => { });
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.accessToken
                }
            };

            if (this.state.skipCount === 0) {
                this.setState({
                    isPrevEnabled: false,
                })
            }
            else {
                this.setState({
                    isPrevEnabled: true
                })
            }

            fetch(`${GET_ALL_IDEA_URL}UserOHR=''&WhereClause=${this.state.WhereClause}&skipCount=${this.state.skipCount} &topCount=${this.state.topCount}&isItIdeaOrGenAI='GenAI'&CreatedBy=''`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    this.setState({
                        ideaList: data.oppList.ResultSets ? data.oppList.ResultSets.Table1 ? data.oppList.ResultSets.Table1 : [] : [],
                        originalIdeaList: data.oppList.ResultSets ? data.oppList.ResultSets.Table1 ? data.oppList.ResultSets.Table1 : [] : [],
                        IdeaLength: data.TotalOpp.Table1[0].length,
                        fromFilterSelection: data.filter
                    }, () => {
                        let totalPages = Math.ceil(parseInt(this.state.IdeaLength) / parseInt(this.state.topCount));
                        let currentPage = Math.ceil(parseInt(this.state.skipCount) / (parseInt(this.state.topCount)) + 1);
                        let sortedData = SortByColumn(this.state.ideaList, "CreatedDate", "desc");

                        if (totalPages === 0) {
                            totalPages = 1
                        }
                        this.setState({
                            ideaList: sortedData,
                            createdDateSortIconRotate: "desc",
                            totalPages: totalPages,
                            currentPage: currentPage
                        }, () => {
                            console.log("currentPage", this.state.currentPage);
                            console.log("totalPages", this.state.totalPages);
                            if (this.state.currentPage === this.state.totalPages) {
                                this.setState({
                                    isNextEnabled: false
                                })
                            }
                            else {
                                this.setState({
                                    isNextEnabled: true
                                })
                            }
                        });

                        this.handleFilterChange({ value: 0 });
                        Toast.hide();
                        resolve();
                    });
                })
                .catch(error => {
                    console.log(error);
                    Toast.hide();
                    resolve();
                });
        });
    }

    // to fetch using columnName and sort order
    fetchFilterIdeaLatest = () => {
        Toast.loading("Fetching opportunities...", () => { });
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  'Bearer ' + this.accessToken
                }
            };

            if (this.state.skipCount === 0) {
                this.setState({
                    isPrevEnabled: false,
                })
            }
            else {
                this.setState({
                    isPrevEnabled: true
                })
            }

            fetch(`${GET_ALL_IDEA_URL}UserOHR=''&WhereClause=''&skipCount=${this.state.skipCount}&topCount=${this.state.topCount}&sortingorder=${this.state.sortOrder}&sortingcolumn=${this.state.sortColumnName}&isItIdeaOrGenAI='GenAI'&CreatedBy=''`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    this.setState({
                        ideaList: data.IdeaList.ResultSets ? data.IdeaList.ResultSets.Table1 ? data.IdeaList.ResultSets.Table1 : [] : [],
                        // ideaList: data.IdeaList.ResultSets.Table1 === undefined ? [] : data.IdeaList.ResultSets.Table1,
                        // originalIdeaList: data.IdeaList.ResultSets.Table1 === undefined ? [] : data.IdeaList.ResultSets.Table1,
                        // IdeaLength:data.TotalIdea.Table1[0].length,
                        // fromFilterSelection:data.filter
                    }, () => {
                        let totalPages = Math.ceil(parseInt(this.state.IdeaLength) / parseInt(this.state.topCount));
                        let currentPage = Math.ceil(parseInt(this.state.skipCount) / (parseInt(this.state.topCount)) + 1);
                        let sortedData = SortByColumn(this.state.ideaList, "CreatedDate", "desc");

                        if (totalPages === 0) {
                            totalPages = 1
                        }
                        this.setState({
                            ideaList: sortedData,
                            createdDateSortIconRotate: "desc",
                            totalPages: totalPages,
                            currentPage: currentPage
                        }, () => {
                            console.log("currentPage", this.state.currentPage);
                            console.log("totalPages", this.state.totalPages);
                            if (this.state.currentPage === this.state.totalPages) {
                                this.setState({
                                    isNextEnabled: false
                                })
                            }
                            else {
                                this.setState({
                                    isNextEnabled: true
                                })
                            }
                        });

                        this.handleFilterChange({ value: 0 });
                        Toast.hide();
                        resolve();
                    });
                })
                .catch(error => {
                    console.log(error);
                    Toast.hide();
                    resolve();
                });
        });
    }



    fetchMetedata = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "",
                EntityName: ""
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.accessToken
                },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
                .then(response => response.json())
                .then(data => {
                    Toast.hide();
                    this.setState({
                        accountList: data ? data.Accounts.sort((a, b) => { return a.ACCOUNT > b.ACCOUNT ? 1 : -1; }) : [],
                        serviceLineList: data ? data.ServiceLines : [],
                        subServiceLineList: data ? data.ServiceLines : [],
                        workFlowStatusList: data ? data.WorkflowStatus : [],
                        certificationLevelsList: data ? data.CertificationLevels : [],
                        transformationLeversList: data ? data.TransformationLevers : [],
                        regionList: data ? data.Region : [],
                        adminUserList: data ? data.DataverseUserRole : [],
                        verticalList: data ? data.VerticalData : [],
                        subVerticalList: data ? data.SubVerticalData : [],
                        uniqueUnits: data ? data.UnitSegment ? data.UnitSegment.ResultSets ? data.UnitSegment.ResultSets.Table1 : [] : [] : [],
                        uniqueSegments: data ? data.UnitSegment ? data.UnitSegment.ResultSets ? data.UnitSegment.ResultSets.Table2 : [] : [] : [],
                    }, () => {
                        this.state.adminUserList.map((item) => {
                            if (parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
                                this.setState({ admin: true });
                                return false;
                            }
                            return false;
                        });
                    });
                    resolve();
                })
                .catch(error => {
                    console.log("error=>", error)
                    Toast.hide();
                    Toast.fail('Error occured while getting Idea Metadata. Please try again!', 3000, () => {
                        console.log(JSON.stringify(error));
                    });
                });
        });
    }

    renderVerticalOptions = () => {
        let options = [];
        this.state.verticalList.map((item) => {
            if (item.Vertical !== "TS") {
                options.push({
                    value: item.Vertical,
                    label: item.Vertical
                })
            }
            return options;
        });
        return options;
    }

    renderSubVerticalOptions = () => {
        let options = [];
        this.state.subVerticalList.map((item) => {
            if (item.SubVertical !== "corporate" && item.SubVertical !== "Unmapped") {
                options.push({
                    value: item.SubVertical,
                    label: item.SubVertical
                })
            }
            return options;
        });
        return options;
    }

    renderTopCountOptions = () => {
        if (this.state.topCountOption.length > 0) {
            return this.state.topCountOption.map((item) => {
                let isSelected = false;
                if (item === this.state.topCount) {
                    isSelected = true;
                }
                return (
                    <option selected={isSelected}>{item}</option>
                );
            });
        }
    }

    topCountChangeHandler = async (event) => {
        const index = event.nativeEvent.target.selectedIndex;
        this.setState({
            topCount: event.nativeEvent.target[index].text
        }, async () => {
            if (this.state.WhereClause) {
                await this.fetchFilterIdea();
            }
            else {
                await this.fetchIdea();
            }
        });
    }

    handleIdeaAction = (ideaId, action) => {
        if (action === "View") {
            this.props.history.push({
                pathname: "/view-opportunity",
                state: ideaId,
            })
        }
        if (action === "Update") {
            this.props.history.push({
                pathname: "/update-opportunity",
                state: ideaId,

            })
        }
    }

    handleAccountSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                accountSortIconRotate: !prevState.accountSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.accountSortIconRotate ? "desc" : "asc";
            let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                ideaList: sortedData
            });
        })
    }

    handleIdeaIdSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                ideaIdSortIconRotate: !prevState.ideaIdSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.ideaIdSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleIdeaNameSortClick = async (columnName) => {
        this.setState(prevState => {
            return {
                ideaNameSortIconRotate: !prevState.ideaNameSortIconRotate,
            }
        }, () => {
            let sortOrder = this.state.ideaNameSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })

    }

    handleServiceLineSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                serviceLineSortIconRotate: !prevState.serviceLineSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.serviceLineSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }


    handleSegmentSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                segmentSortIconRotate: !prevState.segmentSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.segmentSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleManagingEntitySortClick = (columnName) => {
        this.setState(prevState => {
            return {
                managingEntitySortIconRotate: !prevState.managingEntitySortIconRotate
            }
        }, () => {
            let sortOrder = this.state.managingEntitySortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }



    handleApproverSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                approverSortIconRotate: !prevState.approverSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.approverSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleSubmitterSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                submitterSortIconRotate: !prevState.submitterSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.submitterSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleCreatedDateSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                createdDateSortIconRotate: !prevState.createdDateSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.createdDateSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleReviewStatusSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                reviewStatusSortIcon: !prevState.reviewStatusSortIcon
            }
        }, () => {
            let sortOrder = this.state.reviewStatusSortIcon ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleReviewDateSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                reviewDateSortIconRotate: !prevState.reviewDateSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.reviewDateSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleCertTypeSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                certTypeSortIconRotate: !prevState.certTypeSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.certTypeSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleTransformationLeverSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                transformationLeverSortIcon: !prevState.transformationLeverSortIcon
            }
        }, () => {
            let sortOrder = this.state.transformationLeverSortIcon ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleProjectStatusSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                projectStatusSortIcon: !prevState.projectStatusSortIcon
            }
        }, () => {
            let sortOrder = this.state.projectStatusSortIcon ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleFilterChange = (e) => {
        let filteredIdeaArray = [];
        if (e.value === 0) {
            filteredIdeaArray = this.state.originalIdeaList;
        }
        this.setState({
            ideaList: filteredIdeaArray,
            originalIdeaList: filteredIdeaArray
        })
    }

    resetData = () => {
        this.setState({
            WorkflowStatus: '',
            IdeaId: '',
            AccountManual: '',
            RegionManual: '',
            VerticalManual: '',
            TransformationLeverName: '',
            CertificationLevelName: '',
            SubVerticalName: '',
            ServiceLineName: '',
            SubServiceLine: '',
            startDate: '',
            toDate: '',
            filter: false
        }, () => {
            window.location.reload();
        })
    }

    globalSearch = () => {
        if (this.state.currentPage === this.state.totalPages) {
            this.setState({
                skipCount: 0
            })
        }

        let WhereClause = '';
        if (this.state.globalsearchText !== null && this.state.globalsearchText !== '') {
            console.log("this.state.isTypedInFilter:", this.state.isTypedInFilter);

            WhereClause = this.state.isTypedInFilter !== '' ? this.state.isTypedInFilter + ' and ' : '';
            WhereClause=WhereClause+encodeURIComponent( "Concat(RecID,RecIDAlpha,OppName,CreatedDate,CreatedBy,Account,Unit,MangingDept,Vertical,Segment,GOL,OppStage,OppState,Spoc_1,Spoc_2,BB,MBB,SolnOwner,UnitOHRName,UnitOHR, SpocOHR_2,SpocOHR_1,MBBOHR,BBOHR,GOLOHR,Segment,CentTeamOHR,CentTeam,SolnOwnerOHR) like '%"+this.state.globalsearchText+"%'")

            if (WhereClause !== '') {
                this.setState({
                    WhereClause: WhereClause,
                    filter: false
                }, async () => {
                    await this.fetchFilterIdea();
                })
            }
        }
        else {
            Toast.fail("Please type something then search.");
            return;
        }
    }

    searchData = async () => {
        let WhereClause = '';
        if (this.state.WorkflowStatus !== '') {
            WhereClause = 'WorkflowStatus' + ' ' + 'like' + " '" + this.state.WorkflowStatus + '%' + "'";
        }

        if (this.state.RecID || this.state.RecIDAlpha) {
            WhereClause = WhereClause !== '' ? WhereClause + ' and ' : '';
            WhereClause = WhereClause + '(' + 'RecID' + ' ' + 'like' + " '" + this.state.RecID + '%' + "'" + ' or ' + 'RecIDAlpha' + ' ' + 'like' + " '" + this.state.RecIDAlpha + '%' + "'" + ')';
        }
        if (this.state.Account) {
            WhereClause = WhereClause !== '' ? WhereClause + ' and ' : '';
            WhereClause = WhereClause + 'Account' + ' ' + 'like' + " '" + this.state.Account + '%' + "'";
        }
        if (this.state.Vertical) {
            WhereClause = WhereClause !== '' ? WhereClause + ' and ' : '';
            WhereClause = WhereClause + 'Vertical' + ' ' + 'like' + " '" + this.state.Vertical + '%' + "'";
        }

        if (this.state.ServiceLine) {
            WhereClause = WhereClause !== '' ? WhereClause + ' and ' : '';
            WhereClause = WhereClause + 'ServiceLine' + ' ' + 'like' + "'" + encodeURIComponent(this.state.ServiceLine) + '%' + "'";
        }
        if (this.state.OppStage) {
            WhereClause = WhereClause !== '' ? WhereClause + ' and ' : '';
            WhereClause = WhereClause + 'OppStage' + ' ' + 'like' + " '" + this.state.OppStage + '%' + "'";
        }
        if (this.state.OppState) {
            WhereClause = WhereClause !== '' ? WhereClause + ' and ' : '';
            WhereClause = WhereClause + 'OppState' + ' ' + 'like' + " '" + this.state.OppState + '%' + "'";
        }
        if (this.state.CreatedName) {
            WhereClause = WhereClause !== '' ? WhereClause + ' and ' : '';
            WhereClause = WhereClause + '(' + 'CreatedName' + ' ' + 'like' + " '" + this.state.CreatedName + '%' + "'" + ')';
        }
        if (this.state.CreatedBy) {
            WhereClause = WhereClause !== '' ? WhereClause + ' and ' : '';
            WhereClause = WhereClause + '(' + 'CreatedBy' + ' ' + 'like' + " '" + this.state.CreatedBy + '%' + "'" + ')';
        }

        if (this.state.searchSegment !== '') {
            WhereClause = WhereClause !== '' ? WhereClause + ' and ' : '';
            WhereClause = WhereClause + 'Segment' + ' ' + 'like' + "'" + encodeURIComponent(this.state.searchSegment) + '%' + "'";
        }
        if (this.state.searchUnit !== '') {
            WhereClause = WhereClause !== '' ? WhereClause + ' and ' : '';
            WhereClause = WhereClause + 'Unit' + ' ' + 'like' + "'" + encodeURIComponent(this.state.searchUnit) + '%' + "'";
        }

        if (this.state.startDate !== '' && this.state.toDate !== '') {
            WhereClause = WhereClause !== '' ? WhereClause + ' and ' : '';
            if (this.state.selectedDate === "ModifiedDate") {
                WhereClause = WhereClause + '(' + '(' + this.state.selectedDate + ' ' + 'between ' + "'" + this.state.startDate + 'T00:00:00.00' + "'" + ' and ' + "'" + this.state.toDate + 'T23:59:00.00' + "'" + ')' + ' and ' + 'WorkflowStatus' + ' <> ' + "'" + 'Pending' + "'" + ')';
            }
            else {
                WhereClause = WhereClause + '(' + this.state.selectedDate + ' ' + 'between ' + "'" + this.state.startDate + 'T00:00:00.00' + "'" + ' and ' + "'" + this.state.toDate + 'T23:59:00.00' + "'" + ')';
            }
        }
        if (this.state.startDate !== '') {
            if (!this.state.selectedDate) {
                Toast.fail("Please select date type first.");
                return;
            }
            if (this.state.toDate === '') {
                Toast.fail("Please enter both the start date and end date.");
                return;
            }
        }

        if (this.state.toDate !== '') {
            if (!this.state.selectedDate) {
                Toast.fail("Please select date type first.");
                return;
            }
            if (this.state.startDate === '') {
                Toast.fail("Please enter both the start date and end date.");
                return;
            }
        }

        if (this.state.selectedDate && (this.state.startDate === '' || this.state.toDate === '')) {
            Toast.fail("Please enter both the start date and end date.");
            return;
        }

        if (this.state.startDate && this.state.toDate) {
            if (this.state.startDate > this.state.toDate) {
                Toast.fail("Start date should not be greater than end date.");
                return;
            }
        }

        if (WhereClause !== '') {
            this.setState({
                WhereClause: WhereClause,
                isTypedInFilter: WhereClause,
                tempWorkflowStatus: this.state.WorkflowStatus,
                tempApprovalStatus: this.state.ApprovalStatus,
                tempIdeaId: this.state.IdeaId,
                tempAccountManual: this.state.AccountManual,
                tempRegionManual: this.state.RegionManual,
                tempVerticalManual: this.state.VerticalManual,
                tempTransformationLeverName: this.state.TransformationLeverName,
                tempCertificationLevelName: this.state.CertificationLevelName,
                tempSubVerticalName: this.state.SubVerticalName,
                tempServiceLineName: this.state.ServiceLineName,
                tempSubServiceLine: this.state.SubServiceLine,
                tempstartDate: this.state.startDate,
                temptoDate: this.state.toDate,
            }, async () => {
                console.log("whereclause", WhereClause);
                // await this.fetchFilterIdea();
                await this.fetchFilterIdea();
            })
        }
        else {
            if (this.state.tempIdeaId) {
                this.refreshSearch();
            }
            else {
                Toast.fail("Please type something then search.");
            }
        }
    }

    renderDateOptions = () => {
        let options = [];
        this.state.dateList.map((item) => {
            options.push({
                value: item,
                label: item
            })
            return options;
        });
        return options;
    }

    renderStatusOptions = () => {
        let options = [
            { value: 0, label: "Approve" },
            { value: 1, label: "Reject" },
            { value: 2, label: "Send Back" }

        ];
        return options;
    }

    renderApprovalStatusOptions = () => {
        let options = [];
        this.state.approvalStatusList.map((item) => {
            options.push({
                value: item.ApprovalStatusId,
                label: item.ApprovalStatusName
            })
            return options;
        });
        return options;
    }

    renderQCApprovalStatusOptions = () => {
        let options = [];
        this.state.newApprovalListForQC && this.state.newApprovalListForQC.map((item) => {
            options.push({
                value: item.id,
                label: item.label
            })
            return options;
        });
        return options;
    }

    renderAccountOptions = () => {
        let options = [];
        let accountName = "";
        this.state.accountList.map((item) => {
            accountName = item.ACCOUNT.toString().toLowerCase();
            if (!(accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact"))) {
                options.push({
                    value: item.ACCOUNT_L2,
                    label: item.ACCOUNT
                })
            }
            return true;
        });
        return options;
    }

    renderTransLeversOptions = () => {
        let options = [];
        this.state.transformationLeversList.map((item) => {
            options.push({
                value: item.TransformationLeverId,
                label: item.TransformationLeverName
            });
            return options;
        });
        return options;
    }

    renderCertLevelsOptions = () => {
        let options = [];
        this.state.certificationLevelsList.map((item) => {
            options.push({
                value: item.CertificationLevelId,
                label: item.CertificationLevelName
            });
            return options;
        });
        return options;
    }

    renderRegionOptions = () => {
        let options = [];
        this.state.regionList.map((item) => {
            options.push({
                value: item.Region,
                label: item.Region
            })
            return options;
        });
        return options;
    }

    renderServiceLineOptions = () => {
        let options = [];
        const uniqueServiceLineList = [...new Map(this.state.serviceLineList.map(item =>
            [item.ServiceLineName, item])).values()];
        uniqueServiceLineList.map((item) => {
            options.push({ value: item.ServiceLineId, label: item.ServiceLineName });
            return true;
        });
        return options;
    }

    renderSubServiceLineOptions = () => {
        let options = [];
        this.state.subServiceLineList.map((item) => {
            if (this.state.ServiceLineName === item.ServiceLineName) {
                options.push({ value: item.SubServiceLineId, label: item.SubServiceLineName });
                return true;
            }
            return true;
        });
        return options;
    }

    handleDateOptionChange = (e) => {
        this.setState({
            isTypedData: true,
            dateSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount: 0
        }, () => {
            if (e.label === "Created date") {
                this.setState({
                    selectedDate: "CreatedDate"
                })
            }
            else if (e.label === "Reviewed date") {
                this.setState({
                    selectedDate: "ModifiedDate"
                })
            }
        });
    }

    handleAccountNameChange = (e) => {
        this.setState({
            Account: e.label,
            accountName: e.label,
            isTypedData: true,
            accountSelectionOption: {
                value: e.value,
                label: e.label
            },
            skipCount: 0
        }, async () => {
            await this.searchData();
        });
    }

    handleReviewStatusFilterChange = (e) => {
        this.setState({
            WorkflowStatus: e.label,
            isTypedData: true,
            workFlowStatusSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount: 0
        }, async () => {
            await this.searchData();
        })
    }

    handleProjectStatusFilterChange = (e) => {
        this.setState({
            ApprovalStatus: e.label,
            isTypedData: true,
            approvalStatusSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount: 0
        }, async () => {
            await this.searchData();
        })
    }

    handleQCApprovalStatusFilterChange = (e) => {
        this.setState({
            qcApprovalStatus: e.label,
            isTypedData: true,
            qcApprovalStatusSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount: 0
        }, async () => {
            await this.searchData();
        })
    }




    handleIdeaIDFilterChange = (searchText) => {
        this.setState({
            RecID: searchText,
            RecIDAlpha: searchText,
            isTypedData: true
        })
    }

    handleVerticalFilterChange = (e) => {
        console.log("e", e);
        this.setState({

            Vertical: e.label,
            verticalSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount: 0,
            isTypedData: true
        }, async () => {
            await this.searchData();
        })
    }

    handleRegionFilterChange = (e) => {
        this.setState({
            RegionManual: e.label,
            isTypedData: true,
            regionSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount: 0
        }, async () => {
            await this.searchData();
        })
    }

    handleTransformationLeverNameFilterChange = (e) => {
        this.setState({
            TransformationLeverName: e.label,
            isTypedData: true,
            transSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount: 0
        }, async () => {
            await this.searchData();
        })
    }

    handleCertLevelChange = (e) => {
        this.setState({
            CertificationLevelName: e.label,
            isTypedData: true,
            certLevelsSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount: 0
        }, async () => {
            await this.searchData();
        })
    }

    handleSubVerticalNameFilterChange = (e) => {
        this.setState({
            SubVerticalName: e.label,
            subVerticalSelectedOption: {
                value: e.value,
                label: e.label
            },
            isTypedData: true,
            skipCount: 0
        }, async () => {
            await this.searchData();
        })
    }

    handleService = (e) => {
        this.setState({
            ServiceLineName: e.label,
            isTypedData: true,
            serviceLineSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount: 0,
            subServiceLineSelectedOption: null,
            SubServiceLine: ''
        }, async () => {
            await this.searchData();
        })
    }

    handleSubServiceLine = (e) => {
        this.setState({
            SubServiceLine: e.label,
            isTypedData: true,
            subServiceLineSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount: 0
        }, async () => {
            await this.searchData();
        })
    }

    handleCreatedDate = (dates) => {
        this.setState({
            startDate: dates,
            isTypedData: true
        });
    }

    handleToDate = (dates) => {
        this.setState({
            toDate: dates,
            isTypedData: true
        });

    }

    handleGlobalSearchFilterChange = (searchText) => {
        console.log("SEARCHED DATA", searchText.target.value);
        this.setState({
            globalsearchText: searchText.target.value,
            isTypedData: true,
            isTypedInGlobalSearch: true
        })
        if (searchText === '') {
            window.location.reload();
        }
    }

    formatDateString(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
    }

    handleGetFirstPage = () => {
        this.setState({
            skipCount: 0
        }, async () => {
            if (this.state.fromFilterSelection) {
                await this.fetchFilterIdea();
            }
            else {
                await this.fetchIdea();
            }
        })
    }

    handleGetLastPage = () => {
        let skipCount = (parseInt(this.state.totalPages) - 1) * parseInt(this.state.topCount);
        this.setState({
            skipCount: skipCount
        }, async () => {
            if (this.state.fromFilterSelection) {
                await this.fetchFilterIdea();
            }
            else {
                await this.fetchIdea();
            }
        })
    }

    handlePrevAction = () => {
        let skipCount = this.state.skipCount;
        let topCount = this.state.topCount;
        this.setState({
            skipCount: parseInt(skipCount) - parseInt(topCount),
        }, async () => {
            console.log("skip prev", this.state.skipCount);
            console.log("top count", this.state.topCount);
            if (this.state.fromFilterSelection) {
                await this.fetchFilterIdea();
            }
            else {
                await this.fetchIdea();
            }
        })
    }

    handleNextAction = async () => {
        let skipCount = this.state.skipCount;
        let topCount = this.state.topCount;
        this.setState({
            skipCount: parseInt(skipCount) + parseInt(topCount)
        }, async () => {
            console.log("skip next", this.state.skipCount);
            console.log("top count", this.state.topCount);
            if (this.state.fromFilterSelection) {
                await this.fetchFilterIdea();
            }
            else {
                await this.fetchIdea();
            }
        })
    }

    handlePageChangeHandler = (event) => {
        this.setState({
            pageNo: event.target.value
        })
    }

    goToPage = () => {
        this.setState({
            skipCount: (parseInt(this.state.pageNo) - 1) * parseInt(this.state.topCount)
        }, async () => {
            await this.fetchIdea();
        })
    }

    refreshSearch = () => {
        window.location.reload();
    }

    filterHandler = () => {
        if (this.state.propsData) {
            this.setState({
                workFlowStatusSelectedOption: this.state.propsData.WorkflowStatus ? { value: this.state.tempWorkflowStatus, label: this.state.tempWorkflowStatus } : null,
                approvalStatusSelectedOption: this.state.propsData.ApprovalStatus ? { value: this.state.tempApprovalStatus, label: this.state.tempApprovalStatus } : null,
                accountSelectionOption: this.state.propsData.AccountManual ? { value: this.state.tempAccountManual, label: this.state.tempAccountManual } : null,
                transSelectedOption: this.state.propsData.TransformationLeverName ? { value: this.state.tempTransformationLeverName, label: this.state.tempTransformationLeverName } : null,
                certLevelsSelectedOption: this.state.propsData.CertificationLevelName ? { value: this.state.tempCertificationLevelName, label: this.state.tempCertificationLevelName } : null,
                serviceLineSelectedOption: this.state.propsData.ServiceLineName ? { value: this.state.tempServiceLineName, label: this.state.tempServiceLineName } : null,
                subServiceLineSelectedOption: this.state.propsData.SubServiceLine ? { value: this.state.tempSubServiceLine, label: this.state.tempSubServiceLine } : null,
                regionSelectedOption: this.state.propsData.RegionManual ? { value: this.state.tempRegionManual, label: this.state.tempRegionManual } : null,
                verticalSelectedOption: this.state.propsData.VerticalManual ? { value: this.state.tempVerticalManual, label: this.state.tempVerticalManual } : null,
                subVerticalSelectedOption: this.state.propsData.SubVerticalName ? { value: this.state.tempSubVerticalName, label: this.state.tempSubVerticalName } : null,
                dateSelectedOption: this.state.propsData.selectedDate ? { value: this.state.selectedDate, label: this.state.selectedDate } : null,
                globalsearchText: this.state.propsData.globalsearchText ? this.state.propsData.globalsearchText : null
            });
        }
        this.setState({
            filter: !this.state.filter,
            IdeaId: this.state.tempIdeaId,
            WorkflowStatus: this.state.tempWorkflowStatus,
            ApprovalStatus: this.state.tempApprovalStatus,
            AccountManual: this.state.tempAccountManual,
            RegionManual: this.state.tempRegionManual,
            VerticalManual: this.state.tempVerticalManual,
            TransformationLeverName: this.state.tempTransformationLeverName,
            CertificationLevelName: this.state.tempCertificationLevelName,
            SubVerticalName: this.state.tempSubVerticalName,
            ServiceLineName: this.state.tempServiceLineName,
            SubServiceLine: this.state.tempSubServiceLine,
            startDate: this.state.tempstartDate,
            toDate: this.state.temptoDate
        });
    }



    inputChanged = (event) => {
        event.target.parentElement.parentElement.className =
            event.target.checked ? 'selected' : '';
    }



    selectAll = () => {
        if (document.getElementById('selectAll').checked) {
            var ele = document.getElementsByName('chk');
            let selectTotal = ele.length < 25 ? ele.length : 25;
            for (var i = 0; i < selectTotal; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = true;
                ele[i].parentElement.parentElement.className =
                    ele[i].checked ? 'selected' : '';
            }
        } else {
            var ele = document.getElementsByName('chk');
            let selectTotal = ele.length < 5 ? ele.length : 5;
            for (var i = 0; i < selectTotal; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;
            }
        }
    }



    validate = () => {
        let isValid = true;
        let styleStateObj = this.state.selectStyle;
        if (!this.state.Comment || this.state.Comment === '') {
            isValid = false;
            styleStateObj.comment = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            if (this.state.Comment.length > 500) {
                Toast.fail("Comment cannot be more than 500 characters, please change and resubmit.", 3000);
                isValid = false;
                styleStateObj.comment = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else {
                styleStateObj.comment = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                this.setState({
                    selectStyle: styleStateObj
                })
            }
        }
        return isValid;
    }

    timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }




    handleMonetizableOptionChange = (e) => {
        this.setState({
            isTypedData: true,
            monetizableSelectedLabel: e.label,
            monetizableSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount: 0
        }, async () => {
            await this.searchData();
        })
    }


    handleZeroVDOptionChange = (e) => {
        if (e.label === "Yes") {
            this.setState({
                isTypedData: true,
                zeroVDSelectedLabel: true,
                zeroVDSelectedOption: {
                    value: e.value,
                    label: e.label
                },
                skipCount: 0
            }, async () => {
                await this.searchData();
            })
        }
        if (e.label === "No") {
            this.setState({
                isTypedData: true,
                zeroVDSelectedLabel: false,
                zeroVDSelectedOption: {
                    value: e.value,
                    label: e.label
                },
                skipCount: 0
            }, async () => {
                await this.searchData();
            })
        }

    }


    handleOwnerSearchByName = (searchText) => {
        this.setState({
            CreatedName: searchText,
            isTypedData: true
        })
    }
    handleOwnerSearchByOHR = (searchText) => {
        this.setState({
            CreatedBy: searchText,
            isTypedData: true
        })
    }
    renderUniqueSegment = () => {
        let options = [];
        let accountName = "";
        this.state.uniqueSegments.map((item, index) => {
            console.log()
            accountName = item.SEGMENT ? item.SEGMENT.toString().toLowerCase() : "";
            if (!(accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact") ||
                accountName === "" || accountName === null || accountName === undefined)) {
                options.push({
                    value: index,
                    label: item.SEGMENT
                })
            }
            return true;
        });
        return options;
    }
    handleSearchMentor = (searchText) => {
        this.setState({
            searchMentor: searchText,
            isTypedData: true
        }, () => {

        })
    }

    renderUnits = () => {
        let options = [];
        let accountName = "";
        this.state.uniqueSegments.map((item, index) => {
            console.log()
            accountName = item.SEGMENT ? item.SEGMENT.toString().toLowerCase() : "";
            if (!(accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact") ||
                accountName === "" || accountName === null || accountName === undefined)) {
                options.push({
                    value: index,
                    label: item.SEGMENT
                })
            }
            return true;
        });
        console.log("segment options =>", options)
        return options;
    }

    renderUniqueUnits = () => {
        let options = [];
        let accountName = "";
        this.state.uniqueUnits.map((item, index) => {
            accountName = item.UNIT ? item.UNIT.toString().toLowerCase() : "";
            if (!(accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact") ||
                accountName === "" || accountName === null || accountName === undefined)) {
                options.push({
                    value: index,
                    label: item.UNIT
                })
            }
            return true;
        });
        return options;
    }


    handleSegmentOptionChange = (e) => {
        this.setState({
            isTypedData: true,
            Segment: e.label,
            searchSegmentSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount: 0
        }, async () => {
            await this.searchData();
        })
    }
    handleUnitOptionChange = (e) => {
        this.setState({
            isTypedData: true,
            Unit: e.label,
            searchUnitSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount: 0
        }, async () => {
            await this.searchData();
        })
    }

    handleProjectPhaseChange = (searchText) => {
        this.setState({
            projectPhase: searchText,
            isTypedData: true
        })
    }

    handleOppStageFilterChange = (e) => {
        this.setState({
            OppStage: e.label,
            OppStageSelectedOption: {
                value: e.value,
                label: e.label
            },
            isTypedData: true,
            skipCount: 0
        }, async () => {
            await this.searchData();
        })
    }
    handleOppStateChange = (e) => {
        this.setState({
            OppState: e.label,
            isTypedData: true,
            OppStateSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount: 0
        }, async () => {
            await this.searchData();
        })
    }

    handleSPOC1SortClick = (columnName) => {
        this.setState(prevState => {
            return {
                SPOC1SortIconRotate: !prevState.SPOC1SortIconRotate
            }
        }, () => {
            let sortOrder = this.state.SPOC1SortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleUnitSPOCSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                unitSPOCSortIconRotate: !prevState.unitSPOCSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.unitSPOCSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }


    handleSPOC2SortClick = (columnName) => {
        this.setState(prevState => {
            return {
                SPOC2SortIconRotate: !prevState.SPOC2SortIconRotate
            }
        }, () => {
            let sortOrder = this.state.SPOC2SortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleSolutionOwnerSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                SolutionOwnerSortIconRotate: !prevState.SolutionOwnerSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.SolutionOwnerSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleVerticialSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                verticalSortIconRotate: !prevState.verticalSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.verticalSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }
    handleOppStageSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                OppStageSortIconRotate: !prevState.OppStageSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.OppStageSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleoppStateSortIconSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                OppStateSortIconRotate: !prevState.OppStateSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.OppStateSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleBBSortIconSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                BBSortIconRotate: !prevState.BBSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.BBSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleMBBSortIconSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                MBBSortIconRotate: !prevState.MBBSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.MBBSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }


    handleGolSortIconSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                GolSortIconRotate: !prevState.GolSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.GolSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleModifiedDateSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                modifiedDateSortIconRotate: !prevState.modifiedDateSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.modifiedDateSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }


    render() {
        return (
            <>
                <div>
                    <h4 className="u-text" style={{ color: 'white', fontSize: '32px', position: 'absolute', left: '43%', top: '90px' }}>View all opportunities</h4>
                    <img src={require("../assets/images/listbanner.png")} alt="" style={{ width: '100%' }} />
                </div>
                <section className="u-clearfix u-section-1" id="sec-0f7f">
                    <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20, width: '100%' }}>
                        <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                            <div className="u-container-layout u-container-layout-1 view-idea-table">
                                <div id="idea1" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round">
                                    <div className="u-container-layout u-container-layout-7">
                                        <div className="row" style={{ marginTop: 15 }}>
                                            <div className="col-md-3" >
                                                <div className="" style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div className="">
                                                        <i style={{ marginTop: 5, position: 'absolute', fontSize: 18 }} className={`${FILTER} filterblock_title`} onClick={this.filterHandler} title="Search" aria-hidden="true"></i>
                                                    </div>
                                                    <div className="">
                                                        <a style={{ fontSize: 14, color: '#122539', textDecoration: 'none' }} href="/view-opportunities">My opportunities</a>
                                                    </div>
                                                    <div className="">
                                                        <a style={{ fontSize: 14, color: '#122539', borderBottom: '4px solid #259bca', textDecoration: 'none' }} href="/view-all-opportunities">All opportunities</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5"></div>

                                            <div className="col-md-2" style={{ paddingRight: "0px" }}>
                                                <div className="filter_search_block">
                                                    <input value={this.state.globalsearchText} onChange={(event) => this.handleGlobalSearchFilterChange(event)} placeholder="Search here" onKeyPress={event => {

                                                        if (event.key === 'Enter') {
                                                            this.globalSearch()
                                                        }
                                                    }}
                                                    />
                                                    <i style={{ marginTop: 9, position: 'absolute', color: '#001A30', marginLeft: -35, cursor: 'pointer' }} className={this.state.isTypedInGlobalSearch ? `${SEARCH_ICON} icon-style` : `${SEARCH_ICON} fa-disabled`} onClick={this.globalSearch} title="search"></i>
                                                    <i style={{ marginTop: 9, position: 'absolute', color: '#001A30', marginLeft: -15, cursor: 'pointer' }} className={this.state.isTypedInGlobalSearch ? `${CLOSE} icon-style` : `${CLOSE} fa-disabled`} onClick={this.refreshSearch} title="close"></i>
                                                </div>
                                            </div>
                                            <div className="col-md-2" style={{ paddingleft: "5px" }}>
                                                <select style={{ width: 60, height: 33, border: '1px solid lightgrey' }} onChange={this.topCountChangeHandler} >
                                                    {
                                                        this.renderTopCountOptions()
                                                    }
                                                </select>
                                                <span style={{ verticalAlign: 'center', paddingLeft: 4 }}> Rows per page</span>
                                            </div>
                                        </div>

                                        {
                                            this.state.filter
                                                ?
                                                <>
                                                    <div className="filter_main_block">
                                                        <div className="filter_block">
                                                            <h3>Filters:<span style={{ float: 'right', cursor: 'pointer' }}><i className={CLOSE} onClick={() => this.setState({ filter: false })}></i></span></h3>
                                                            <div>
                                                                <Select isSearchable={true} options={this.renderDateOptions()} placeholder="Choose date" onChange={this.handleDateOptionChange} value={this.state.dateSelectedOption} />
                                                            </div>
                                                            <div className="filter_input">
                                                                <input type="date" max="2099-01-01" min="1970-01-01" value={this.state.startDate} onChange={(event) => this.handleCreatedDate(event.target.value)} placeholder="Select from date" required />
                                                            </div>
                                                            <div className="filter_input">
                                                                <input type="date" max="2099-01-01" min="1970-01-01" value={this.state.toDate} onChange={(event) => this.handleToDate(event.target.value)} placeholder="Select to date" required />
                                                            </div>
                                                            <div className="filter_input">
                                                                <input type="text" value={this.state.OppId} onChange={(event) => this.handleIdeaIDFilterChange(event.target.value)} placeholder="Search Opportunity by ID" onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.searchData()
                                                                    }
                                                                }}
                                                                />
                                                            </div>
                                                            <div className="filter_input">
                                                                <input type="text" value={this.state.CreatedName} onChange={(event) => this.handleOwnerSearchByName(event.target.value)} placeholder="Search opp submitter by name" onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.searchData()
                                                                    }
                                                                }}
                                                                />
                                                            </div>
                                                            <div className="filter_input">
                                                                <input type="text" value={this.state.CreatedBy} onChange={(event) => this.handleOwnerSearchByOHR(event.target.value)} placeholder="Search opp submitter by ohr" onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.searchData()
                                                                    }
                                                                }}
                                                                />
                                                            </div>

                                                            <div style={{ marginTop: 7 }}>
                                                                <Select tabIndex={1} isSearchable={true} options={this.renderAccountOptions()} placeholder="Choose Account" onChange={this.handleAccountNameChange} value={this.state.accountSelectionOption} />
                                                            </div>
                                                            <div style={{ marginTop: 7 }}>
                                                                <Select tabIndex={2} isSearchable={true} value={this.state.verticalSelectedOption} options={this.renderVerticalOptions()} placeholder="Choose Vertical" onChange={this.handleVerticalFilterChange} />
                                                            </div>
                                                            <div style={{ marginTop: 7 }}>
                                                                <Select isSearchable={true} options={this.renderUnits()} placeholder="Choose Owning Unit" onChange={(e) => this.handleUnitOptionChange(e)} value={this.state.UnitSelectedOption} />
                                                            </div>
                                                            <div className="" style={{ marginTop: "7px" }}>
                                                                <Select isSearchable={true} options={this.renderUniqueSegment()} placeholder="Choose Segment" onChange={(e) => this.handleSegmentOptionChange(e)} value={this.state.SegmentSelectedOption} />
                                                            </div>
                                                            <div style={{ marginTop: 7 }} >
                                                                <Select tabIndex={3} isSearchable={true} value={this.state.OppStageSelectedOption} options={OPPMASTERSTAGES} placeholder="Choose Stage" onChange={this.handleOppStageFilterChange} />
                                                            </div>
                                                            <div style={{ marginTop: 7 }}>
                                                                <Select isSearchable={true} options={OPPMASTERSTATE} placeholder="Choose State" onChange={this.handleOppStateChange} value={this.state.OppStateSelectedOption} />
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    {
                                                                        this.state.isTypedData ? (
                                                                            <button onClick={() => this.searchData() && this.setState({ filter: false })} className="btn-block u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6">Search</button>
                                                                        ) : (
                                                                            <button onClick={(e) => window.alert("Please enter value to search.")} className="btn-block u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6">Search</button>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <button onClick={() => this.resetData()} className="btn-block u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3">Reset</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                null
                                        }

                                        <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 10 }} />


                                        <div className="scrollbar_table_container">
                                            <table className="table review-idea-table " style={{ fontSize: 12 }}>
                                                <thead style={{ textAlign: 'center !important' }} className="view-idea-thead">
                                                    <tr>
                                                        <th scope="col" style={{ paddingLeft: 25, paddingRight: 25 }}>Action</th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={(e)=>this.handleIdeaIdSortClick("RecIDAlpha")}>
                                                            Opportunity Id
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.ideaIdSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>

                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleSubmitterSortClick("CreatedName")}>
                                                            Opportunity creator
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.submitterSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleCreatedDateSortClick("CreatedDate")}>
                                                            Opportunity created on
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.createdDateSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleModifiedDateSortClick("ModifiedDate")}>
                                                            Opportunity modified On
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.modifiedDateSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th scope="col" onClick={(e) => this.handleIdeaNameSortClick("OppName")}>
                                                            Opportunity name
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.ideaNameSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="col-md-1 hidden-xs hidden-sm" scope="col" onClick={() => this.handleAccountSortClick("AccountManual")}>
                                                            Account
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.accountSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleVerticialSortClick("Vertical")}>
                                                            Vertical
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.verticalSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th scope="col" onClick={() => this.handleManagingEntitySortClick("MangingDept")}>
                                                            Managing entity
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.managingEntitySortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleOppStageSortClick("OppStage")}>
                                                            Opportunity Stage
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.OppStageSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>

                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleoppStateSortIconSortClick("OppState")}>
                                                            Opportunity state
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.OppStateSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th scope="col" onClick={() => this.handleUnitSPOCSortClick("UnitOHRName")}>
                                                            Unit SPOC
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.unitSPOCSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th scope="col" onClick={() => this.handleSPOC1SortClick("Spoc_1")}>
                                                            SPOC - 1
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.SPOC1SortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th scope="col" onClick={() => this.handleSPOC2SortClick("Spoc_2")}>
                                                            SPOC - 2
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.SPOC2SortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th scope="col" onClick={() => this.handleSolutionOwnerSortClick("SolnOwner")}>
                                                            Solution Owner
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.solutionOwnerSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleMBBSortIconSortClick("MBB")}>
                                                            MBB
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.MBBSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleBBSortIconSortClick("BB")}>
                                                            BB
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.BBSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleGolSortIconSortClick("GOL")}>
                                                            GOL
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.GolSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="review-idea-tbody">
                                                    {
                                                        this.state.ideaList.length > 0 ? (
                                                            this.state.ideaList.map((ideaItem, index) => {
                                                                //console.log("idea item =>",ideaItem)
                                                                let createdDate = new Date(ideaItem.CreatedDate);
                                                                let modifiedDate = ideaItem.ModifiedDate ? new Date(ideaItem.ModifiedDate) : "";
                                                                let formattedCreateDate = this.formatDateString(createdDate);
                                                                let formattedModifiedDate = modifiedDate ? this.formatDateString(modifiedDate) : "-";
                                                                let action = VIEW;
                                                                return (
                                                                    <tr key={ideaItem.IdeaId}>
                                                                        <td>
                                                                            <div className="row">
                                                                                <button onClick={() => this.handleIdeaAction(ideaItem.RecID, "View")} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-hover-custom-color-6 u-radius-6 u-btn-2 new-btn-style" style={{ minWidth: 60, borderRadius: 0, marginLeft: 19, height: 20 }}>
                                                                                    View</button>
                                                                            </div>
                                                                            <div className="row">
                                                                                <button
                                                                                    onClick={(e) => this.handleIdeaAction(ideaItem.RecID, "Update")}
                                                                                    className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-hover-custom-color-6 u-radius-6 u-btn-2 new-btn-style"
                                                                                    style={{ minWidth: 60, borderRadius: 0, marginLeft: 19, height: 20, marginTop:"5px"}}
                                                                                >
                                                                                    Update
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                        <td className="hidden-xs hidden-sm" style={{ width: '6%' }}>{ideaItem.RecIDAlpha ? ideaItem.RecIDAlpha : ideaItem.RecIDAlpha}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word' }}>{ideaItem.CreatedName ? ideaItem.CreatedName : "-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word', maxWidth: '80px' }}>{formattedCreateDate ? formattedCreateDate : "-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word', maxWidth: '80px' }}>{formattedModifiedDate ? formattedModifiedDate : "-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word' }}>{ideaItem.OppName ? ideaItem.OppName : "-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word' }}>{ideaItem.Account ? ideaItem.Account : "-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word' }}>{ideaItem.Vertical ? ideaItem.Vertical : "-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word' }}>{ideaItem.MangingDept ? ideaItem.MangingDept : "-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word' }}>{ideaItem.OppStage ? ideaItem.OppStage : "-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word' }}>{ideaItem.OppState ? ideaItem.OppState : "-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word' }}>{ideaItem.UnitOHRName ? ideaItem.UnitOHRName : "-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word' }}>{ideaItem.Spoc_1 ? ideaItem.Spoc_1 : "-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word' }}>{ideaItem.Spoc_2 ? ideaItem.Spoc_2 : "-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word' }}>{ideaItem.SolnOwner ? ideaItem.SolnOwner : "-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word' }}>{ideaItem.MBB ? ideaItem.MBB : "-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word' }}>{ideaItem.BB ? ideaItem.BB : "-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word' }}>{ideaItem.GOL ? ideaItem.GOL : "-"}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="100%"> No items found! </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        <div style={{ float: 'right' }}>
                                            <div className="col-md-1">
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize: 18 }} className={this.state.isPrevEnabled ? `${ANGLE_DOUBLE_LEFT} icon-style` : `${ANGLE_DOUBLE_LEFT} fa-disabled`} onClick={() => this.state.isPrevEnabled ? this.handleGetFirstPage() : ''} title="first" aria-hidden="true"></i>
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize: 18, marginLeft: 30 }} className={this.state.isPrevEnabled ? `${ANGLE_LEFT} icon-style` : `${ANGLE_LEFT} fa-disabled`} onClick={() => this.state.isPrevEnabled ? this.handlePrevAction() : ''} title="previous" aria-hidden="true"></i>
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize: 18, marginLeft: 60 }} className={this.state.isNextEnabled ? `${ANGLE_RIGHT} icon-style` : `${ANGLE_RIGHT} fa-disabled`} onClick={() => this.state.isNextEnabled ? this.handleNextAction() : ''} title="next" aria-hidden="true"></i>
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize: 18, marginLeft: 90, marginRight: 10 }} className={this.state.isNextEnabled ? `${ANGLE_DOUBLE_RIGHT} icon-style` : `${ANGLE_DOUBLE_RIGHT} fa-disabled`} onClick={() => this.state.isNextEnabled ? this.handleGetLastPage() : ''} title="last" aria-hidden="true"></i>
                                            </div>
                                            <div className="col-md-7" style={{ marginLeft: 120, marginTop: 5 }}>Page {this.state.currentPage} of {this.state.totalPages}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default ViewAllOpps


