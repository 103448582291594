import React, { Component } from "react";
import Toast from "light-toast";
import Select from "react-select";
import { selectValidation as selectStyle, textBoxValidation, } from "./FieldValidation";
import { GET_IDEA_URL, TEMPLATE_UPDATE, ACCESS_TOKEN, COMPLETED_STATUS, USER_DATA, } from "../assets/constants/constants";
import Checksum from "./Checksum";

export default class PredictiveTemplate extends Component {
  sessionData = JSON.parse(localStorage.getItem(USER_DATA));
  accessToken = localStorage.getItem(ACCESS_TOKEN);
  constructor(props) {
    super(props);
    console.log(props)
    let propsData = this.props.location;
    this.state = {
      selectStyle: {},
      ideaID: this.props.location.state,
      ideaAlpha: "",
      LeanID: "",
      roles: [
        {
          label: "Target",
          value: 0,
        },
        {
          label: "Feature",
          value: 1,
        },
        {
          label: "Derived",
          value: 2,
        },
      ],
      editingIndex: null,

      RecType: "",

      //checkbox states
      classificationChecked: false,
      regressionChecked: false,
      forecastChecked: false,
      anamolyChecked: false,
      othersChecked: false,
      setimentValuesChecked: false,
      modelTypeSelectedForPipeline: [],
      modelOneLabel: "",
      modelTwoLabel: "",
      modelThreeLabel: "",

      // sentiment analysis fields
      showSentimentTemplate: false,
      sentimentValues: {
        goodToHave: "",
        mixedSentiments: "",
        noChecksNeeded: "",
        priorityChecks: "",
        sentimentDuplicates: "",
      },
      sentimentTotal: 0,
      // Outcome type fields
      outcomeTypeOptions: [
        {
          label: "2*2",
          value: 2,
        },
        {
          label: "3*3",
          value: 3,
        },
        {
          label: "4*4",
          value: 4,
        },
        {
          label: "5*5",
          value: 5,
        },
      ],

      classificationTypeOptions: [
        {
          label: "Categorical",
          value: 0,
        },
        {
          label: "Numeric",
          value: 1,
        },
      ],

      // regression variables
      showRegressionTemplate: false,
      showRegressionModal: false,
      regressionData: [],
      currentRegressionData: {
        SrNo: 0,
        SOrd: "",
        Feature: "",
        Type: [],
        Role: [],
      },
      regressionPostData: [],

      regressionMeanAbsoluteError: "",
      regressionMeanSquaredError: "",
      regressionRootMeanSquaredError: "",
      regressionMeanAbsolutePercentageError:"",

      regressionCounter: 0,

      regressionMetricesOne: false,
      regressionMetricesOneLabel: "",
      regressionMetricesOneInput: "",

      regressionMetricesTwo: false,
      regressionMetricesTwoLabel: "",
      regressionMetricesTwoInput: "",

      regressionMetricesThree: false,
      regressionMetricesThreeLabel: "",
      regressionMetricesThreeInput: "",

      regressionMetricesFour: false,
      regressionMetricesFourLabel: "",
      regressionMetricesFourInput: "",

      regressionMetricesFive: false,
      regressionMetricesFiveLabel: "",
      regressionMetricesFiveInput: "",
      regressionOtherFields: {},

      // forecast variables
      showForecastTemplate: false,
      showForecastModal: false,
      forecastData: [],
      currentForecastData: {
        SrNo: 0,
        SOrd: "",
        Feature: "",
        Type: [],
        Role: [],
      },

      forecastMeanAbsoluteError: "",
      forecastMeanSquaredError: "",
      forecastRootMeanSquaredError: "",
      forecastMeanAbsolutePercentageError:"",

      forecastCounter: 0,
      forecastMetricesOne: false,
      forecastMetricesOneLabel: "",
      forecastMetricesOneInput: "",

      forecastMetricesTwo: false,
      forecastMetricesTwoLabel: "",
      forecastMetricesTwoInput: "",

      forecastMetricesThree: false,
      forecastMetricesThreeLabel: "",
      forecastMetricesThreeInput: "",

      forecastMetricesFour: false,
      forecastMetricesFourLabel: "",
      forecastMetricesFourInput: "",

      forecastMetricesFive: false,
      forecastMetricesFiveLabel: "",
      forecastMetricesFiveInput: "",

      forecastOtherFields: {},
      forecastPostData: [],

      // Anamoly variables starts here
      showAnamolyTemplate: false,
      anomolyData: [{}],
      displayAnamolyRowButton: false,

      // other variables starts here
      showOthersTemplate: false,
      othersData: [{}],
      displayOthersRowButton: false,

      // classification Module 0 variables
      classificationModelSelected: [],
      classificationModels: [
        {
          value: 0,
          label: "Light Gradient Boost",
        },
        {
          value: 1,
          label: "Random Forest",
        },
        {
          value: 2,
          label: "Deep Neural Network",
        },
        {
          value: 3,
          label: "CatBoost Classifier",
        },
        {
          value: 4,
          label: "XGBoost",
        },
        {
          value: 5,
          label: "Support Vector Machine",
        },
        {
          value: 6,
          label: "Naïve Bayes",
        },
        {
          value: 7,
          label: "K-Nearest Neighbors",
        },
        {
          value: 8,
          label: "K-Means",
        },
        {
          value: 9,
          label: "Others / Custom",
        }
      ],
      showClassificationTemplate: false,
      showClassificationModule: false,
      classificationModule: [],
      currentClassificationModule: {
        SrNo: 0,
        Feature: "",
        Type: [],
        Role: [],
        Score: "",
      },

      // classification Module 1 variables
      classificationModelSelected1: [],
      showClassificationTemplate1: false,
      showClassificationModule1: false,
      classificationModule1: [],
      currentClassificationModule1: {
        SrNo: 0,
        slNo: "",
        Feature: "",
        Type: [],
        Role: [],
        Score: "",
      },

      

      selectStyle: {
        currentClassificationModule:[
          {
            Feature: "",
            Type: [],
            Role: [],
            Score: "",
          }
        ],
        currentClassificationModule1: [{
          Feature: "",
          Type: [],
          Role: [],
          Score: "",
        }],
        currentClassificationModule2: [{
          Feature: "",
          Type: [],
          Role: [],
          Score: "",
        }],
        currentForecastData: [{
          Feature: "",
          Type: [],
          Role: [],
        }],
        currentRegressionData: [{
          Feature: "",
          Type: [],
          Role: [],
        }],
      },



      // classification Module 2 variables
      classificationModelSelected2: [],
      showClassificationTemplate2: false,
      showClassificationModule2: false,
      classificationModule2: [],
      currentClassificationModule2: {
        SrNo: 0,
        slNo: "",
        Feature: "",
        Type: [],
        Role: [],
        Score: "",
      },


      // Confusion matrix 1
      confusionMatrixTableSelectedOption1: [],
      confusionMatrixData1: [],
      confusionMatrixPrecisionResults1: {},
      confusionMatrixRecallResults1: {},
      overAllAccuray:[],
      // Confusion matrix 2
      confusionMatrixTableSelectedOption2: [],
      confusionMatrixData2: [],
      confusionMatrixPrecisionResults2: {},
      confusionMatrixRecallResults2: {},
      overAllAccuray1:null,

      // Confusion matrix 3
      confusionMatrixTableSelectedOption3: [],
      confusionMatrixData3: [],
      confusionMatrixPrecisionResults3: {},
      confusionMatrixRecallResults3: {},
      overAllAccuray2:null,


      // capture SOrd number to display tempaltes
      SOrdTable: [],




    };
  }
  async componentDidMount() {
    // const location = this.props.location;
    // const ID = new URLSearchParams(location.search).get('SUQ');
    // console.log("ID =>", ID)
    // this.setState({
    //   ideaID: ID,
    // }, () => {
    //   console.log(this.state.ideaID)
    // });
    // const decodedID = atob(ID);
    // console.log("decorded =>", decodedID)
    console.log("idea id =>",this.state.ideaID)
    Toast.loading("Fetching template data...", () => { });
    await this.fetchIdea();
    Toast.hide();

    // these functions are called in CDM to load precision rows at page load 
    this.confusionMatrixprecision();
    this.calculateRecall1()
    this.calculateOverallAccuracy()

    this.confusionMatrixprecision1();
    this.calculateRecall2()
    this.calculateOverallAccuracy1()

    this.confusionMatrixprecision2();
    this.calculateRecall3()
    this.calculateOverallAccuracy2()
  }

  fetchIdea = (ideaID) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + this.accessToken,
        },
      };
      fetch(`${GET_IDEA_URL}IdeaId=${this.state.ideaID}&RecID=''&isItIdeaOrGenAI='Idea'`, requestOptions)
        .then((response) => response.json())
        .then((data) => {

          // t1: T_DB_ALL
          // t2: T_DB_DPA  predictive template
          // t3: T_DB_PA_SA sentiment analysis
          // t4: T_DB_PA_RE_MX anamoly n others
          // t5: T_DB_PA
          // t6: T_DB_PA_CL
          // t7: T_DB_PA_CL_CO
          // t8: T_DB_PA_RE
          // t9: T_DB_PA_AN


          console.log("fetch idea console", data);

          // sentiment analysis fetch starts here
          let localSentimentArray = data.Templates.ResultSets.Table3 && data.Templates.ResultSets.Table3[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table3[0]
          if (localSentimentArray && (localSentimentArray.Value1 || localSentimentArray.Value2 || localSentimentArray.Value3 || localSentimentArray.Value4 || localSentimentArray.Value5)) {
            let localSentimentValues = {
              goodToHave: localSentimentArray.Value1,
              mixedSentiments: localSentimentArray.Value2,
              noChecksNeeded: localSentimentArray.Value3,
              priorityChecks: localSentimentArray.Value4,
              sentimentDuplicates: localSentimentArray.Value5,
            };
            let localSentimentTotal =
              localSentimentValues.mixedSentiments +
              localSentimentValues.goodToHave +
              localSentimentValues.noChecksNeeded +
              localSentimentValues.priorityChecks +
              localSentimentValues.sentimentDuplicates;

            this.setState(
              {
                sentimentValues: localSentimentValues,
                sentimentTotal: localSentimentTotal,
              },
              () => {
                if (this.state.sentimentValues) {
                  this.setState({ showSentimentTemplate: true }, () => {
                    console.log("show sentiement analysis", this.state.showSentimentTemplate)
                  })
                }
              }
            );
          }
          // sentiment analysis fetch ends here


          this.setState(
            {
              ideaItem: data.ResultSets.Table1[0],
              ideaAlpha: data.ResultSets.Table1 ? data.ResultSets.Table1[0].IdeaIDAlpha : "",
              LeanID: data.ResultSets.Table1 ? data.ResultSets.Table1[0].LeanID : "",
              SOrdTable: data.Templates ? data.Templates.ResultSets.Table5 : [],



              // classificaiton module 0
              classificationModule: data.Templates.ResultSets.Table6 && data.Templates.ResultSets.Table6[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table6,
              // confusion matrix 1
              confusionMatrixData1: data.Templates.ResultSets.Table7 && data.Templates.ResultSets.Table7[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table7,

              // regression
              regressionData: data.Templates.ResultSets.Table8 && data.Templates.ResultSets.Table8[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table8,
              regressionOtherFields: data.Templates.ResultSets.Table4 && data.Templates.ResultSets.Table4[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table4,

              // Forecast
              forecastData: data.Templates.ResultSets.Table8 && data.Templates.ResultSets.Table8[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table8,


              //Anamoly:
              anomolyData: data.Templates.ResultSets.Table9 && data.Templates.ResultSets.Table9[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table9,
              RecType: data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? "" : data.Templates.ResultSets.Table1[0].RecType,
            },
            () => {

              console.log("lean id is =>", this.state.LeanID)

              // anamoly data fetch starts here
              let anomolyData = this.state.anomolyData.filter((item, index) => {
                return item.SOrd === 1
              })
              if (anomolyData.length > 0) {
                const trimmedAnamolyData = anomolyData.map((item) => {
                  const txtValues = Object.fromEntries(
                    Object.entries(item).filter(([key, value]) => key.startsWith("Txt") && value !== "")
                  );
                  return txtValues;
                });
                this.setState({ anomolyData: trimmedAnamolyData }, () => {
                  this.setState({ showAnamolyTemplate: true, displayAnamolyRowButton:true }, () => { })
                })
              }
              // anamoly data fetch ends here

              // others data fetch ends here
              let othersData = this.state.anomolyData.filter((item, index) => {
                return item.SOrd === 2
              })
              if (othersData.length > 0) {
                const trimmedOthersData = othersData.map((item) => {
                  const txtValues = Object.fromEntries(
                    Object.entries(item).filter(([key, value]) => key.startsWith("Txt") && value !== "")
                  );
                  return txtValues;
                });
                this.setState({ othersData: trimmedOthersData }, () => {
                  this.setState({ showOthersTemplate: true, displayOthersRowButton:true }, () => { })
                })
              }
              // others data fetch ends here


              //console.log(this.state.SOrdTable);
              if (this.state.SOrdTable) {
                let selectedModelType = [];
                this.state.SOrdTable &&
                  this.state.SOrdTable.map((item, index) => {
                    if (item.SOrd === 0) {
                      if (item.ModelType) {
                        let splitValues = item.ModelType.split("|");
                        if (splitValues.includes("RE")) {
                          this.setState(
                            { showRegressionTemplate: true },
                            () => { }
                          );
                        }
                        if (splitValues.includes("FO")) {
                          this.setState(
                            { showForecastTemplate: true },
                            () => { }
                          );
                        }
                        if (splitValues.includes("CL")) {
                          this.setState(
                            { classificationChecked: true },
                            () => { }
                          );
                        }
                        if (splitValues.includes("AN")) {
                          this.setState(
                            { showAnamolyTemplate: true },
                            () => { }
                          );
                        }
                        if (splitValues.includes("OT")) {
                          this.setState({ showOthersTemplate: true }, () => { });
                        }
                      }
                    }
                    if (item.SOrd === 1) {
                      if (item.ModelName) {
                        let toBeReplaced = item.ModelName
                        toBeReplaced = toBeReplaced.replace(/[|,]/g, ",");
                        const wordsArray = toBeReplaced.split(',').map(word => word.trim());
                        const wordObjects = wordsArray.map((word, index) => ({ value: index, label: word }));
                        this.setState({classificationModelSelected:wordObjects},()=>{
                        })
                      }
                    }
                    if (item.SOrd === 2) {
                      if (item.ModelName) {
                        let toBeReplaced = item.ModelName
                        toBeReplaced = toBeReplaced.replace(/[|,]/g, ",");
                        const wordsArray = toBeReplaced.split(',').map(word => word.trim());
                        const wordObjects = wordsArray.map((word, index) => ({ value: index, label: word }));
                        this.setState({classificationModelSelected1:wordObjects},()=>{
                        })
                      }
                    }
                    if (item.SOrd === 3) {
                      if (item.ModelName) {
                        let toBeReplaced = item.ModelName
                        toBeReplaced = toBeReplaced.replace(/[|,]/g, ",");
                        const wordsArray = toBeReplaced.split(',').map(word => word.trim());
                        const wordObjects = wordsArray.map((word, index) => ({ value: index, label: word }));
                        this.setState({classificationModelSelected2:wordObjects},()=>{
                        })
                      }
                    }

                  });
              }

              //console.log("classification one module is =>", this.state.classificationModule)
              if (this.state.classificationModule.length > 0) {
                let classificationModule = this.state.classificationModule.filter((item, index) => {
                  return item.SOrd === 1
                })
                // console.log("classification module local data =>", classificationModule)
                // console.log("classification module local data length  =>", classificationModule.length)
                if (classificationModule.length > 0) {
                  const classificationResult = classificationModule.map((item) => {
                    const { ProjectID, ...txtValues } = item;
                    return txtValues;
                  });
                  if (classificationResult) {
                    classificationResult.map((item, index) => {
                      if (item.Role) {
                        classificationResult[index].Role = {
                          value: 0,
                          label: classificationResult[index].Role,
                        };
                      }
                      if (item.Type) {
                        classificationResult[index].Type = {
                          value: 0,
                          label: classificationResult[index].Type,
                        };
                      }
                    });
                  }
                  this.setState({ classificationModule: classificationResult }, () => {
                    this.setState({ showClassificationTemplate: true }, () => { })
                  })
                }
              }



              // classification module 1 fetch starts here
              if (this.state.classificationModule.length > 0) {
                let classificationModule1 = this.state.classificationModule.filter((item, index) => {
                  return item.SOrd === 2
                })
                // console.log(classificationModule1)
                if (classificationModule1.length > 0) {
                  const classificationResult1 = classificationModule1.map((item) => {
                    const { ProjectID, ...txtValues } = item;
                    return txtValues;
                  });
                  if (classificationResult1) {
                    classificationResult1.map((item, index) => {
                      if (item.Role) {
                        classificationResult1[index].Role = {
                          value: 0,
                          label: classificationResult1[index].Role,
                        };
                      }
                      if (item.Type) {
                        classificationResult1[index].Type = {
                          value: 0,
                          label: classificationResult1[index].Type,
                        };
                      }
                    });
                  }
                  this.setState({ classificationModule1: classificationResult1 }, () => {
                    this.setState({ showClassificationTemplate1: true }, () => { })
                  })
                }
              }

              // classification module 1 fetch ends here


              // classification module 2 fetch starts here
              if (this.state.classificationModule.length > 0) {
                let classificationModule2 = this.state.classificationModule.filter((item, index) => {
                  return item.SOrd === 3
                })
                if (classificationModule2.length > 0) {
                  const classificationResult2 = classificationModule2.map((item) => {
                    const { ProjectID, ...txtValues } = item;
                    return txtValues;
                  });
                  if (classificationResult2) {
                    classificationResult2.map((item, index) => {
                      if (item.Role) {
                        classificationResult2[index].Role = {
                          value: 0,
                          label: classificationResult2[index].Role,
                        };
                      }
                      if (item.Type) {
                        classificationResult2[index].Type = {
                          value: 0,
                          label: classificationResult2[index].Type,
                        };
                      }
                    });
                  }
                  this.setState({ classificationModule2: classificationResult2 }, () => {

                    this.setState({ showClassificationTemplate2: true }, () => { })
                  })
                }
              }
              // classification module 2 fetch ends here


              if (this.state.confusionMatrixData1.length > 0) {
                let confusionMatrixData1 = this.state.confusionMatrixData1.filter((item, index) => {
                  return item.SOrd === 1
                })
                this.state.outcomeTypeOptions.map((item) => {
                  if (item.value === confusionMatrixData1.length) {
                    this.setState({ confusionMatrixTableSelectedOption1: item }, () => { })
                  }
                })
                if (confusionMatrixData1) {

                  const removeSOrdSrNoProjectID = confusionMatrixData1.map((item) => {
                    const { ProjectID, SOrd, SrNo, ...txtValues } = item;
                    return txtValues;
                  });

                  const trimmedConfusionMatrixData = removeSOrdSrNoProjectID.map((item) => {
                    const txtValues = Object.fromEntries(
                      Object.entries(item).filter(([key, value]) => (value !== "" || value !== 0))
                    );
                    return txtValues;
                  });

                  this.setState({ confusionMatrixData1: trimmedConfusionMatrixData }, () => { })
                }
              }

              if (this.state.confusionMatrixData1.length > 0) {
                let confusionMatrixData2 = this.state.confusionMatrixData1.filter((item, index) => {
                  return item.SOrd === 2
                })

                this.state.outcomeTypeOptions.map((item) => {
                  if (item.value === confusionMatrixData2.length) {
                    this.setState({ confusionMatrixTableSelectedOption2: item }, () => {

                    })
                  }
                })
                if (confusionMatrixData2) {
                  const removeSOrdSrNoProjectID1 = confusionMatrixData2.map((item) => {
                    const { ProjectID, SOrd, SrNo, ...txtValues } = item;
                    return txtValues;
                  });
                  const trimmedConfusionMatrixData2 = removeSOrdSrNoProjectID1.map((item) => {
                    const txtValues = Object.fromEntries(
                      Object.entries(item).filter(([key, value]) => (value !== ""))
                    );
                    return txtValues;
                  });
                  this.setState({ confusionMatrixData2: trimmedConfusionMatrixData2 }, () => {
                    //console.log("confusion data matrix 2 at fetch idea =>", this.state.confusionMatrixData2)
                  })
                }
              }


              if (this.state.confusionMatrixData1.length > 0) {
                let confusionMatrixData3 = this.state.confusionMatrixData1.filter((item, index) => {
                  return item.SOrd === 3
                })
                this.state.outcomeTypeOptions.map((item) => {
                  if (item.value === confusionMatrixData3.length) {
                    this.setState({ confusionMatrixTableSelectedOption3: item }, () => { })
                  }
                })
                if (confusionMatrixData3) {
                  const removeSOrdSrNoProjectID2 = confusionMatrixData3.map((item) => {
                    const { ProjectID, SOrd, SrNo, ...txtValues } = item;
                    return txtValues;
                  });
                  const trimmedConfusionMatrixData = removeSOrdSrNoProjectID2.map((item) => {
                    const txtValues = Object.fromEntries(
                      Object.entries(item).filter(([key, value]) => (value !== ""))
                    );
                    return txtValues;
                  });
                  this.setState({ confusionMatrixData3: trimmedConfusionMatrixData }, () => {
                    //console.log("confusion matrix at fetch function =>", this.state.confusionMatrixData3)
                  })
                }
              }


              // regression fetch api starts here

              if (this.state.regressionData.length > 0) {
                let regressionData = this.state.regressionData.filter((item, index) => {
                  return item.SOrd === 1;
                })

                if (regressionData.length > 0) {
                  const result = regressionData.map((item) => {
                    const { ProjectID, ...txtValues } = item;
                    return txtValues;
                  });
                  if (result) {
                    result.map((item, index) => {
                      if (item.Role) {
                        result[index].Role = {
                          value: 0,
                          label: result[index].Role,
                        };
                      }
                      if (item.Type) {
                        result[index].Type = {
                          value: 0,
                          label: result[index].Type,
                        };
                      }
                    });
                  }
                  this.setState({ regressionData: result }, () => {
                    //console.log("regression data is =>", this.state.regressionData)
                    this.setState({ showRegressionTemplate: true }, () => { })
                  })
                }
              }

              if (this.state.regressionOtherFields) {
                let regressionOtherFields = this.state.regressionOtherFields.filter((item, index) => {
                  return item.SOrd === 1
                })
                if (regressionOtherFields.length > 0) {
                  this.setState(
                    {
                      regressionMeanAbsoluteError: regressionOtherFields[0].Value1,
                      regressionMeanSquaredError: regressionOtherFields[0].Value2,
                      regressionRootMeanSquaredError: regressionOtherFields[0].Value3,
                      regressionMeanAbsolutePercentageError:regressionOtherFields[0].Value4,
                      regressionMetricesOneLabel: regressionOtherFields[0].Label5,
                      regressionMetricesOneInput: regressionOtherFields[0].Value5,
                      regressionMetricesTwoLabel: regressionOtherFields[0].Label6,
                      regressionMetricesTwoInput: regressionOtherFields[0].Value6,
                      regressionMetricesThreeLabel: regressionOtherFields[0].Label7,
                      regressionMetricesThreeInput: regressionOtherFields[0].Value7,
                      regressionMetricesFourLabel: regressionOtherFields[0].Label8,
                      regressionMetricesFourInput: regressionOtherFields[0].Value8,
                      
                    },
                    () => {

                      if (this.state.regressionMetricesOneLabel && this.state.regressionMetricesOneInput) {
                        this.setState(
                          { regressionMetricesOne: true, regressionCounter:1 },
                          () => { }
                        );
                      }
                      if (
                        this.state.regressionMetricesTwoLabel &&
                        this.state.regressionMetricesTwoInput
                      ) {
                        this.setState(
                          { regressionMetricesTwo: true,regressionCounter:2 },
                          () => { }
                        );
                      }
                      if (
                        this.state.regressionMetricesThreeLabel &&
                        this.state.regressionMetricesThreeInput
                      ) {
                        this.setState(
                          { regressionMetricesThree: true,regressionCounter:3 },
                          () => { }
                        );
                      }
                      if (
                        this.state.regressionMetricesFourLabel &&
                        this.state.regressionMetricesFourInput
                      ) {
                        this.setState(
                          { regressionMetricesFour: true,regressionCounter:4 },
                          () => { }
                        );
                      }
                      // if (
                      //   this.state.regressionMetricesFiveLabel &&
                      //   this.state.regressionMetricesFiveInput
                      // ) {
                      //   this.setState(
                      //     { regressionMetricesFive: true },
                      //     () => { }
                      //   );
                      // }
                    }
                  );
                }


              }
              // regression fetch api starts here

              // forecast fetch api starts here
              //console.log(this.state.forecastData)
              if (this.state.forecastData.length > 0) {
                let forecastData = this.state.forecastData.filter((item, index) => {
                  return item.SOrd === 2;
                })
                console.log(forecastData)
                console.log(forecastData.length)
                if (forecastData.length > 0) {
                  const result = forecastData.map((item) => {
                    const { ProjectID, ...txtValues } = item;
                    return txtValues;
                  });
                  if (result) {
                    result.map((item, index) => {
                      if (item.Role) {
                        result[index].Role = {
                          value: 0,
                          label: result[index].Role,
                        };
                      }
                      if (item.Type) {
                        result[index].Type = {
                          value: 0,
                          label: result[index].Type,
                        };
                      }
                    });
                  }
                  this.setState({ forecastData: result }, () => {
                    this.setState({ showForecastTemplate: true }, () => { })
                    //console.log("forecast data is =>", this.state.forecastData)
                  })
                }
              }
              if (this.state.regressionOtherFields) {
                let regressionOtherFields = this.state.regressionOtherFields.filter((item, index) => {
                  return item.SOrd === 2
                })
                if (regressionOtherFields.length > 0) {
                  this.setState(
                    {
                      forecastMeanAbsoluteError: regressionOtherFields[0].Value1,
                      forecastMeanSquaredError: regressionOtherFields[0].Value2,
                      forecastRootMeanSquaredError: regressionOtherFields[0].Value3,
                      forecastMeanAbsolutePercentageError:regressionOtherFields[0].Value4,
                      forecastMetricesOneLabel: regressionOtherFields[0].Label5,
                      forecastMetricesOneInput: regressionOtherFields[0].Value5,
                      forecastMetricesTwoLabel: regressionOtherFields[0].Label6,
                      forecastMetricesTwoInput: regressionOtherFields[0].Value6,
                      forecastMetricesThreeLabel: regressionOtherFields[0].Label7,
                      forecastMetricesThreeInput: regressionOtherFields[0].Value7,
                      forecastMetricesFourLabel: regressionOtherFields[0].Label8,
                      forecastMetricesFourInput: regressionOtherFields[0].Value8,
                      
                    },
                    () => {
                      if (
                        this.state.forecastMetricesOneLabel &&
                        this.state.forecastMetricesOneInput
                      ) {
                        this.setState({ forecastMetricesOne: true, forecastCounter:1 }, () => { });
                      }
                      if (
                        this.state.forecastMetricesTwoLabel &&
                        this.state.forecastMetricesTwoInput
                      ) {
                        this.setState({ forecastMetricesTwo: true,forecastCounter:2 }, () => { });
                      }
                      if (
                        this.state.forecastMetricesThreeLabel &&
                        this.state.forecastMetricesThreeInput
                      ) {
                        this.setState(
                          { forecastMetricesThree: true, forecastCounter:3 },
                          () => { }
                        );
                      }
                      if (
                        this.state.forecastMetricesFourLabel &&
                        this.state.forecastMetricesFourInput
                      ) {
                        this.setState({ forecastMetricesFour: true, forecastCounter:4 }, () => { });
                      }
                      // if (
                      //   this.state.forecastMetricesFiveLabel &&
                      //   this.state.forecastMetricesFiveInput
                      // ) {
                      //   this.setState({ forecastMetricesFive: true }, () => { });
                      // }
                    }
                  );
                }

              }
              // forecast fetch api starts here

              resolve();
            }
          );
        })
        .catch((error) => {
          Toast.fail(
            "Error while fetching idea. Please try again!",
            3000,
            () => { }
          );
          resolve();
        });
    });
  };

  displayClassificationModule = () => {

    this.setState(
      {
        showClassificationTemplate: !this.state.showClassificationTemplate,
        classificationModule: [],
        confusionMatrixData1: [],
        classificationModelSelected: [],
        confusionMatrixTableSelectedOption1: [],
        confusionMatrixRecallResults1: {},

        showClassificationTemplate1: false,
        classificationModelSelected1: [],
        classificationModule1: [],
        confusionMatrixData2: [],
        confusionMatrixPrecisionResults2: [],
        confusionMatrixRecallResults2: {},

        showClassificationTemplate2: false,
        classificationModelSelected2: [],
        classificationModule2: [],
        confusionMatrixData3: [],
        confusionMatrixPrecisionResults3: [],
        confusionMatrixRecallResults3: {},
      },
      () => { }
    );



  };

  displayRegressionModule = () => {
    this.setState(
      {
        showRegressionTemplate: !this.state.showRegressionTemplate,
        regressionData: [],
        regressionOtherFields: {},

        regressionMetricesOne: false,
        regressionMetricesOneLabel: "",
        regressionMetricesOneInput: "",

        regressionMeanAbsoluteError: "",
        regressionMeanSquaredError: "",
        regressionRootMeanSquaredError: "",
        regressionMeanAbsolutePercentageError:"",

        regressionMetricesTwo: false,
        regressionMetricesTwoLabel: "",
        regressionMetricesTwoInput: "",

        regressionMetricesThree: false,
        regressionMetricesThreeLabel: "",
        regressionMetricesThreeInput: "",

        regressionMetricesFour: false,
        regressionMetricesFourLabel: "",
        regressionMetricesFourInput: "",

        regressionMetricesFive: false,
        regressionMetricesFiveLabel: "",
        regressionMetricesFiveInput: "",
        regressionCounter: 0
      },
      () => { }
    );
  };
  displayForecastModule = () => {
    this.setState(
      {
        showForecastTemplate: !this.state.showForecastTemplate,
        forecastData: [],
        forecastCounter: 0,
        forecastOtherFields: {},

        forecastMeanAbsoluteError: "",
        forecastMeanSquaredError: "",
        forecastRootMeanSquaredError: "",
        forecastMeanAbsolutePercentageError:"",

        forecastMetricesOne: false,
        forecastMetricesOneLabel: "",
        forecastMetricesOneInput: "",

        forecastMetricesTwo: false,
        forecastMetricesTwoLabel: "",
        forecastMetricesTwoInput: "",

        forecastMetricesThree: false,
        forecastMetricesThreeLabel: "",
        forecastMetricesThreeInput: "",

        forecastMetricesFour: false,
        forecastMetricesFourLabel: "",
        forecastMetricesFourInput: "",

        forecastMetricesFive: false,
        forecastMetricesFiveLabel: "",
        forecastMetricesFiveInput: "",

      },
      () => { }
    );
  };
  displayAnamolyModule = () => {
    this.setState(
      {
        showAnamolyTemplate: !this.state.showAnamolyTemplate,
        anomolyData: [{}],
        displayAnamolyRowButton: false
      },
      () => { }
    );
  };
  displaySentimentModule = () => {
    this.setState(
      {
        showSentimentTemplate: !this.state.showSentimentTemplate,
        sentimentValues: {},
        sentimentTotal: 0
      },
      () => { }
    );
  };
  displayOtherstModule = () => {
    this.setState(
      {
        showOthersTemplate: !this.state.showOthersTemplate,
        othersData: [{}],
      },

      () => { }
    );
  };
  // regression template function starts here

  openRegressionModal = () => {
    if (this.state.regressionData.length < 30) {
      this.setState({ showRegressionModal: true });
    } else {
      alert("You can add maximum of 30 rows.");
      return;
    }
  };

  closeRegressionModal = () => {
    this.setState({
      showRegressionModal: false,
      currentRegressionData: { Feature: "", Type: [], Role: [] },
      editingIndex: null,
    });

    let styleStateObj = this.state.selectStyle;
    styleStateObj.currentRegressionData.Feature = textBoxValidation(true);
    styleStateObj.currentRegressionData.Type = textBoxValidation(true);
    styleStateObj.currentRegressionData.Role = textBoxValidation(true);
    this.setState({selectStyle: styleStateObj})
  };


  validateRegressionModal = ()=>{
    let styleStateObj = this.state.selectStyle;
    const { currentRegressionData, editingIndex, regressionData } = this.state;
    let isValid = true;

    if (
      currentRegressionData.Feature.trim() === "" ||
      currentRegressionData.Type.length === 0 ||
      currentRegressionData.Role.length === 0
    ) {
      alert("Please fill in all fields before saving.");
      if(currentRegressionData.Feature === "" ||  currentRegressionData.Feature.length === 0 ){
        styleStateObj.currentRegressionData.Feature = textBoxValidation(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
      if(currentRegressionData.Type === undefined ||  currentRegressionData.Type.length === 0 || currentRegressionData.Type === null){
        styleStateObj.currentRegressionData.Type = selectStyle(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
      if(currentRegressionData.Role === undefined ||  currentRegressionData.Role.length === 0 || currentRegressionData.Role === null){
        styleStateObj.currentRegressionData.Role = selectStyle(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
    }
    if(currentRegressionData.Feature){
      if (currentRegressionData.Feature.length > 30) {
        Toast.fail(
          "Regression Template Feature field characters cannot be more than 10 characaters. Please recheck and submit",
          3000,
          () => { }
        );
        styleStateObj.currentRegressionData.Feature = textBoxValidation(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
        
      }
    }
    return isValid;
  }

  addRegressionRow = () => {
    if(this.validateRegressionModal()){
      const { currentRegressionData, editingIndex, regressionData } = this.state;
    if (editingIndex !== null) {
      // Editing existing row
      const updateddata = [...regressionData];
      updateddata[editingIndex] = currentRegressionData;
      this.setState(
        {
          regressionData: updateddata,
          editingIndex: null,
        },
        () => { }
      );
    } else {
      if (regressionData.length < 30) {
        const newData = {
          ...currentRegressionData,
          SOrd: regressionData.length,
          SrNo: Math.max(regressionData.length, 0),
        };
        console.log(newData);
        this.setState((prevState) => ({
          regressionData: [...prevState.regressionData, newData],
        }));
      } else {
        alert("You can add maximum of 30 rows.");
        return;
      }
    }
    this.closeRegressionModal();
    }
    
  };

  editRegressionRow = (index) => {
    const { regressionData } = this.state;
    const employeeToEdit = regressionData[index];
    this.setState({
      showRegressionModal: true,
      currentRegressionData: { ...employeeToEdit },
      editingIndex: index,
    });
  };

  deleteRegressionRow = (index) => {
    if (window.confirm("Are you sure you want to delete this row ?")) {
      const { regressionData } = this.state;
      const updateddata = [...regressionData];
      updateddata.splice(index, 1);
      this.setState({
        regressionData: updateddata,
      });
    }
  };

  handleRegressionInputChange = (e) => {
    const { name, value } = e.target;
    let nonWhiteSpace= value.replace(/^\s+|\s+$/gm,'');;
    let styleStateObj = this.state.selectStyle;
    if(name === "Feature"){
      if(nonWhiteSpace.length < 30){
        this.setState((prevState) => ({
          currentRegressionData: {
            ...prevState.currentRegressionData,
            [name]: value,
          },
        }));
      }else{
        // window.alert("Regression Feature cannot be more than 30 characters.");
        const trimmedValue = nonWhiteSpace.slice(0, 30);
        this.setState((prevState) => ({
          currentRegressionData: {
            ...prevState.currentRegressionData,
            [name]: trimmedValue,
          },
        }));
      }
      styleStateObj.currentRegressionData.Feature = textBoxValidation(true);
      this.setState({selectStyle: styleStateObj})
    }
  };
  handleRegressionSelectChange = (e, currentRegressionData, fieldName) => {
    let styleStateObj = this.state.selectStyle;
    if (fieldName === "role") {
      let localRole = currentRegressionData;
      localRole.Role = e;
      styleStateObj.currentRegressionData.Role = selectStyle(true);
      this.setState({ currentRegressionData: localRole,selectStyle: styleStateObj }, () => { });
    }
    if (fieldName === "type") {
      let localType = currentRegressionData;
      localType.Type = e;
      styleStateObj.currentRegressionData.Type = selectStyle(true);
      this.setState({ currentRegressionData: localType,selectStyle: styleStateObj }, () => { });
    }
  };
  renderRegressionRole = () => {
    let options = [];
    this.state.roles.map((item) => {
      options.push({
        value: item.id,
        label: item.value,
      });
      return true;
    });
    return options;
  };

  addMoreRegressionMatrices = (counter) => {
    if (this.state.regressionCounter >= 4) {
      Toast.fail("You can add upto 4 metrics only.", 3000, () => { });
    } else {
      this.setState({ regressionCounter: counter + 1 }, () => {
        if (this.state.regressionCounter === 1) {
          this.setState({ regressionMetricesOne: true }, () => { });
        }
        if (this.state.regressionCounter === 2) {
          this.setState({ regressionMetricesTwo: true }, () => { });
        }
        if (this.state.regressionCounter === 3) {
          this.setState({ regressionMetricesThree: true }, () => { });
        }
        if (this.state.regressionCounter === 4) {
          this.setState({ regressionMetricesFour: true }, () => { });
        }
      });
    }
  };

  deleteMoreRegressionMatrices=(counter)=>{
    if(window.confirm("Are you sure you want to delete the metrics ?")){
      if (counter === 4) {
        this.setState({ regressionMetricesFour: false,regressionMetricesFourLabel:"",regressionMetricesFourInput :"" }, () => { 
          this.setState({ regressionCounter:counter - 1 }, () => {})
        });
        return;
      }
      if(counter === 3){
        this.setState({ regressionMetricesThree: false, regressionMetricesThreeLabel:"",regressionMetricesThreeInput:"" }, () => { 
          this.setState({regressionCounter:counter - 1 }, () => {})
        });
        return;
      }
      if(counter === 2){
        this.setState({ regressionMetricesTwo: false, regressionMetricesTwoLabel:"",regressionMetricesTwoInput:"" }, () => { 
          this.setState({regressionCounter:counter - 1 }, () => {})
        });
        return;
      }
      if(counter === 1){
        this.setState({ regressionMetricesOne: false, regressionMetricesOneLabel:"",regressionMetricesOneInput:"" }, () => { 
          this.setState({regressionCounter:counter - 1 }, () => {})
        });
        return;
      }
    }
  }


  regressionMeanAbsoluteErrorInputChange = (e) => {
    if (/^-?\d{0,10}(\.\d{0,6})?$/.test(e)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.regressionMeanAbsoluteError = textBoxValidation(true);
      this.setState({regressionMeanAbsoluteError:e,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }
  };
  regressionMeanSquaredErrorInputChange = (e) => {
    if (/^\d{0,10}(\.\d{0,6})?$/.test(e)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.regressionMeanSquaredError = textBoxValidation(true);
      this.setState({regressionMeanSquaredError:e,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }
  };
  regressionRootMeanSquaredErrorInputChange = (e) => {
    if (/^\d{0,10}(\.\d{0,6})?$/.test(e)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.regressionRootMeanSquaredError = textBoxValidation(true);
      this.setState({regressionRootMeanSquaredError:e,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }
    
  };

  regressionMeanAbsolutePercentageErrorInputChange = (e) => {
    if (/^-?\d{0,10}(\.\d{0,6})?$/.test(e)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.regressionMeanAbsolutePercentageError = textBoxValidation(true);
      this.setState({regressionMeanAbsolutePercentageError:e,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits and 6 decimal values only allowed.");
      return;
    }
  };

  regressionMetricesLabelChange = (event) => {
    let nonWhiteSpace= event.target.value.replace(/^\s+|\s+$/gm,'');
    let styleStateObj = this.state.selectStyle;
    styleStateObj.regressionMetricesOneLabel = textBoxValidation(true);
    this.setState({ selectStyle: styleStateObj})
    if(nonWhiteSpace.length <= 60){
      this.setState({ regressionMetricesOneLabel: event.target.value}, () => { });
    }else{
      const trimmedValue = nonWhiteSpace.slice(0, 60);
      this.setState({ regressionMetricesOneLabel: trimmedValue}, () => { });
    }
  };
  regressionMetricesInputChange = (event) => {
    if (/^\d{0,10}(\.\d{0,6})?$/.test(event.target.value)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.regressionMetricesOneInput = textBoxValidation(true);
      this.setState({regressionMetricesOneInput:event.target.value,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }
  };
  regressionMetricesLabelTwoChange = (event) => {
    let nonWhiteSpace= event.target.value.replace(/^\s+|\s+$/gm,'');
    let styleStateObj = this.state.selectStyle;
    styleStateObj.regressionMetricesTwoLabel = textBoxValidation(true);
    this.setState({ selectStyle: styleStateObj})
    if(nonWhiteSpace.length <= 60){
      this.setState({ regressionMetricesTwoLabel: event.target.value}, () => { });
    }else{
      const trimmedValue = nonWhiteSpace.slice(0, 60);
      this.setState({ regressionMetricesTwoLabel: trimmedValue}, () => { });
    }
  };
  regressionMetricesInputTwoChange = (event) => {
    if (/^\d{0,10}(\.\d{0,6})?$/.test(event.target.value)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.regressionMetricesTwoInput = textBoxValidation(true);
      this.setState({regressionMetricesTwoInput:event.target.value,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }
  };
  regressionMetricesLabelThreeChange = (event) => {
    let nonWhiteSpace= event.target.value.replace(/^\s+|\s+$/gm,'');
    let styleStateObj = this.state.selectStyle;
    styleStateObj.regressionMetricesThreeLabel = textBoxValidation(true);
    this.setState({ selectStyle: styleStateObj})
    if(nonWhiteSpace.length <= 60){
      this.setState({ regressionMetricesThreeLabel: event.target.value}, () => { });
    }else{
      const trimmedValue = nonWhiteSpace.slice(0, 60);
      this.setState({ regressionMetricesThreeLabel: trimmedValue}, () => { });
    }
  };
  regressionMetricesInputThreeChange = (event) => {
    if (/^\d{0,10}(\.\d{0,6})?$/.test(event.target.value)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.regressionMetricesThreeInput = textBoxValidation(true);
      this.setState({regressionMetricesThreeInput:event.target.value,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }
  };
  regressionMetricesLabelFourChange = (event) => {
    let nonWhiteSpace= event.target.value.replace(/^\s+|\s+$/gm,'');
    let styleStateObj = this.state.selectStyle;
    styleStateObj.regressionMetricesFourLabel = textBoxValidation(true);
    this.setState({ selectStyle: styleStateObj})
    if(nonWhiteSpace.length <= 60){
      this.setState({ regressionMetricesFourLabel: event.target.value}, () => { });
    }else{
      const trimmedValue = nonWhiteSpace.slice(0, 60);
      this.setState({ regressionMetricesFourLabel: trimmedValue}, () => { });
    }
  };
  regressionMetricesInputFourChange = (event) => {
    if (/^\d{0,10}(\.\d{0,6})?$/.test(event.target.value)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.regressionMetricesFourInput = textBoxValidation(true);
      this.setState({regressionMetricesFourInput:event.target.value,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }
   
  };
  regressionMetricesLabelFiveChange = (event) => {
    let nonWhiteSpace= event.target.value.replace(/^\s+|\s+$/gm,'');
    let styleStateObj = this.state.selectStyle;
    styleStateObj.regressionMetricesFiveLabel = textBoxValidation(true);
    this.setState({ selectStyle: styleStateObj})
    if(nonWhiteSpace.length <= 60){
      this.setState({ regressionMetricesFiveLabel: event.target.value}, () => { });
    }else{
      const trimmedValue = nonWhiteSpace.slice(0, 60);
      this.setState({ regressionMetricesFiveLabel: trimmedValue}, () => { });
    }
  };
  regressionMetricesInputFiveChange = (event) => {
    if (/^\d{0,10}(\.\d{0,6})?$/.test(event.target.value)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.regressionMetricesFiveInput = textBoxValidation(true);
      this.setState({regressionMetricesFiveInput:event.target.value,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }
  };

  // regression  template function ends here

  //  forecast template function starts here
  openForecastModal = () => {
    if (this.state.forecastData.length < 30) {
      this.setState({ showForecastModal: true });
    } else {
      alert("You can add maximum of 30 rows.");
      return;
    }
    
  };

  closeForecastModal = () => {
    this.setState({
      showForecastModal: false,
      currentForecastData: { Feature: "", Type: [], Role: [] },
      editingIndex: null,
    });
    let styleStateObj = this.state.selectStyle;
    styleStateObj.currentForecastData.Feature = textBoxValidation(true);
    styleStateObj.currentForecastData.Type = selectStyle(true);
    styleStateObj.currentForecastData.Role = selectStyle(true);
    this.setState({ selectStyle: styleStateObj }, () => { });
  };

  validateForecastModal=()=>{
    let styleStateObj = this.state.selectStyle;
    let isValid = true;
    const { currentForecastData, editingIndex, forecastData } = this.state;
    
    if (
      currentForecastData.Feature.trim() === "" ||
      currentForecastData.Type.length === 0 ||
      currentForecastData.Role.length === 0
    ) {
      alert("Please fill in all fields before saving.");
      if(currentForecastData.Feature === "" ||  currentForecastData.Feature.length === 0 ){
        styleStateObj.currentForecastData.Feature = textBoxValidation(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
      if(currentForecastData.Type === undefined ||  currentForecastData.Type.length === 0 || currentForecastData.Type === null){
        styleStateObj.currentForecastData.Type = selectStyle(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
      if(currentForecastData.Role === undefined ||  currentForecastData.Role.length === 0 || currentForecastData.Role === null){
        styleStateObj.currentForecastData.Role = selectStyle(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
    }


    if(currentForecastData.Feature){
      if (currentForecastData.Feature.length > 30) {
        Toast.fail(
          "Forecast Template Feature field characters cannot be more than 10 characaters. Please recheck and submit",
          3000,
          () => { }
        );
        styleStateObj.currentForecastData.Feature = textBoxValidation(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
        
      }
    }
    return isValid;
  }

  addForecastRow = () => {
    if(this.validateForecastModal()){
      const { currentForecastData, editingIndex, forecastData } = this.state;
    if (editingIndex !== null) {
      // Editing existing row
      const updateddata = [...forecastData];
      updateddata[editingIndex] = currentForecastData;
      this.setState(
        {
          forecastData: updateddata,
          editingIndex: null,
        },
        () => { }
      );
    } else {
      if (forecastData.length < 30) {
        const newData = {
          ...currentForecastData,
          SOrd: forecastData.length,
          SrNo: Math.max(forecastData.length),
        };

        this.setState((prevState) => ({
          forecastData: [...prevState.forecastData, newData],
        }));
      } else {
        alert("You can add maximum of 30 rows.");
        return;
      }
    }
    this.closeForecastModal();
    }
    
  };

  editForecastRow = (index) => {
    const { forecastData } = this.state;
    const employeeToEdit = forecastData[index];
    this.setState({
      showForecastModal: true,
      currentForecastData: { ...employeeToEdit },
      editingIndex: index,
    });
  };

  deleteForecastRow = (index) => {
    if (window.confirm("Are you sure you want to delete this row ?")) {
      const { forecastData } = this.state;
      const updateddata = [...forecastData];
      updateddata.splice(index, 1);
      this.setState({
        forecastData: updateddata,
      });
    }
    let styleStateObj = this.state.selectStyle;
    styleStateObj.currentForecastData.Feature = textBoxValidation(true);
    styleStateObj.currentForecastData.Type = textBoxValidation(true);
    styleStateObj.currentForecastData.Role = textBoxValidation(true);
    this.setState({selectStyle: styleStateObj})
  };

  handleForecastInputChange = (e) => {
    const { name, value } = e.target;
    let nonWhiteSpace= value.replace(/^\s+|\s+$/gm,'');;
    let styleStateObj = this.state.selectStyle;
    if(name === "Feature"){
      if(nonWhiteSpace.length < 30){
        this.setState((prevState) => ({
          currentForecastData: {
            ...prevState.currentForecastData,
            [name]: value,
          },
        }));
      }else{
        // window.alert("Forecast Feature cannot be more than 30 characters.");
        const trimmedValue = nonWhiteSpace.slice(0, 30);
        this.setState((prevState) => ({
          currentForecastData: {
            ...prevState.currentForecastData,
            [name]: trimmedValue,
          },
        }));
      }
      styleStateObj.currentForecastData.Feature = textBoxValidation(true);
      this.setState({selectStyle: styleStateObj})
    }

  };

  handleForecastSelectChange = (e, currentForecastData, fieldName) => {
    let styleStateObj = this.state.selectStyle;
    if (fieldName === "type") {
      let localType = currentForecastData;
      localType.Type = e;
      styleStateObj.currentForecastData.Type = selectStyle(true);
      this.setState({ currentForecastData: localType,selectStyle: styleStateObj }, () => { });
    }
    if (fieldName === "role") {
      let localRole = currentForecastData;
      localRole.Role = e;
      styleStateObj.currentForecastData.Role = selectStyle(true);
      this.setState({ currentForecastData: localRole,selectStyle: styleStateObj }, () => { });
    }
  };
  renderRegressionRole = () => {
    let options = [];
    this.state.roles.map((item) => {
      options.push({
        value: item.id,
        label: item.value,
      });
      return true;
    });
    return options;
  };

  addMoreForecastMatrices = (counter) => {
    if (this.state.forecastCounter >= 4) {
      Toast.fail("You can add upto 5 metrics only.", 3000, () => { });
    } else {
      this.setState({ forecastCounter: counter + 1 }, () => {
        if (this.state.forecastCounter === 1) {
          this.setState({ forecastMetricesOne: true }, () => { });
        }
        if (this.state.forecastCounter === 2) {
          this.setState({ forecastMetricesTwo: true }, () => { });
        }
        if (this.state.forecastCounter === 3) {
          this.setState({ forecastMetricesThree: true }, () => { });
        }
        if (this.state.forecastCounter === 4) {
          this.setState({ forecastMetricesFour: true }, () => { });
        }
        // if (this.state.forecastCounter === 5) {
        //   this.setState({ forecastMetricesFive: true }, () => { });
        // }
      });
    }
  };

  deleteMoreForecastMatrices=(counter)=>{
    if(window.confirm("Are you sure you want to delete the metrics ?")){
      if (counter === 4) {
        this.setState({ forecastMetricesFour: false,forecastMetricesFourLabel:"",forecastMetricesFourInput :"" }, () => { 
          this.setState({ forecastCounter:counter - 1 }, () => {})
        });
        return;
      }
      if(counter === 3){
        this.setState({ forecastMetricesThree: false, forecastMetricesThreeLabel:"",forecastMetricesThreeInput:"" }, () => { 
          this.setState({forecastCounter:counter - 1 }, () => {})
        });
        return;
      }
      if(counter === 2){
        this.setState({ forecastMetricesTwo: false, forecastMetricesTwoLabel:"",forecastMetricesTwoInput:"" }, () => { 
          this.setState({forecastCounter:counter - 1 }, () => {})
        });
        return;
      }
      if(counter === 1){
        this.setState({ forecastMetricesOne: false, forecastMetricesOneLabel:"",forecastMetricesOneInput:"" }, () => { 
          this.setState({forecastCounter:counter - 1 }, () => {})
        });
        return;
      }
    }
  }
  forecastMeanAbsoluteErrorInputChange = (event) => {
    if (/^-?\d{0,10}(\.\d{0,6})?$/.test(event.target.value)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.forecastMeanAbsoluteError = textBoxValidation(true);
      this.setState({forecastMeanAbsoluteError:event.target.value,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }
  };
  forecastMeanSquaredErrorInputChange = (event) => {
    if (/^\d{0,10}(\.\d{0,6})?$/.test(event.target.value)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.forecastMeanSquaredError = textBoxValidation(true);
      this.setState({forecastMeanSquaredError:event.target.value,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }
  };
  forecastRootMeanSquaredErrorInputChange = (event) => {
    if (/^\d{0,10}(\.\d{0,6})?$/.test(event.target.value)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.forecastRootMeanSquaredError = textBoxValidation(true);
      this.setState({forecastRootMeanSquaredError:event.target.value,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }
  };

  forecastMeanAbsolutePercentageErrorInputChange=(event)=>{
    if (/^-?\d{0,10}(\.\d{0,6})?$/.test(event.target.value)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.forecastMeanAbsolutePercentageError = textBoxValidation(true);
      this.setState({forecastMeanAbsolutePercentageError:event.target.value,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }
  }
  forcastMetricesLabelChange = (event) => {
    let nonWhiteSpace= event.target.value.replace(/^\s+|\s+$/gm,'');
    let styleStateObj = this.state.selectStyle;
    styleStateObj.forecastMetricesOneLabel = textBoxValidation(true);
    this.setState({ selectStyle: styleStateObj})
    if(nonWhiteSpace.length <= 60){
      this.setState({ forecastMetricesOneLabel: event.target.value}, () => { });
    }else{
      const trimmedValue = nonWhiteSpace.slice(0, 60);
      this.setState({ forecastMetricesOneLabel: trimmedValue}, () => { });
    }
  };
  forcastMetricesInputChange = (event) => {
    if (/^\d{0,10}(\.\d{0,6})?$/.test(event.target.value)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.forecastMetricesOneInput = textBoxValidation(true);
      this.setState({forecastMetricesOneInput:event.target.value,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }

  };
  forcastMetricesLabelTwoChange = (event) => {
    let nonWhiteSpace= event.target.value.replace(/^\s+|\s+$/gm,'');
    let styleStateObj = this.state.selectStyle;
    styleStateObj.forecastMetricesTwoLabel = textBoxValidation(true);
    this.setState({ selectStyle: styleStateObj})
    if(nonWhiteSpace.length <= 60){
      this.setState({ forecastMetricesTwoLabel: event.target.value}, () => { });
    }else{
      const trimmedValue = nonWhiteSpace.slice(0, 60);
      this.setState({ forecastMetricesTwoLabel: trimmedValue}, () => { });
    }
  };
  forcastMetricesInputTwoChange = (event) => {
    if (/^\d{0,10}(\.\d{0,6})?$/.test(event.target.value)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.forecastMetricesTwoInput = textBoxValidation(true);
      this.setState({forecastMetricesTwoInput:event.target.value,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }
  };
  forcastMetricesLabelThreeChange = (event) => {
    let nonWhiteSpace= event.target.value.replace(/^\s+|\s+$/gm,'');
    let styleStateObj = this.state.selectStyle;
    styleStateObj.forecastMetricesThreeLabel = textBoxValidation(true);
    this.setState({ selectStyle: styleStateObj})
    if(nonWhiteSpace.length <= 60){
      this.setState({ forecastMetricesThreeLabel: event.target.value}, () => { });
    }else{
      const trimmedValue = nonWhiteSpace.slice(0, 60);
      this.setState({ forecastMetricesThreeLabel: trimmedValue}, () => { });
    }
  };
  forcastMetricesInputThreeChange = (event) => {
    if (/^\d{0,10}(\.\d{0,6})?$/.test(event.target.value)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.forecastMetricesThreeInput = textBoxValidation(true);
      this.setState({forecastMetricesThreeInput:event.target.value,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }


  };
  forcastMetricesLabelFourChange = (event) => {
    let nonWhiteSpace= event.target.value.replace(/^\s+|\s+$/gm,'');
    let styleStateObj = this.state.selectStyle;
    styleStateObj.forecastMetricesFourLabel = textBoxValidation(true);
    this.setState({ selectStyle: styleStateObj})
    if(nonWhiteSpace.length <= 60){
      this.setState({ forecastMetricesFourLabel: event.target.value}, () => { });
    }else{
      const trimmedValue = nonWhiteSpace.slice(0, 60);
      this.setState({ forecastMetricesFourLabel: trimmedValue}, () => { });
    }
  };
  forcastMetricesInputFourChange = (event) => {
    if (/^\d{0,10}(\.\d{0,6})?$/.test(event.target.value)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.forecastMetricesFourInput = textBoxValidation(true);
      this.setState({forecastMetricesFourInput:event.target.value,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }
  };
  forcastMetricesLabelFiveChange = (event) => {
    let nonWhiteSpace= event.target.value.replace(/^\s+|\s+$/gm,'');
    let styleStateObj = this.state.selectStyle;
    styleStateObj.forecastMetricesFiveLabel = textBoxValidation(true);
    this.setState({ selectStyle: styleStateObj})
    if(nonWhiteSpace.length <= 60){
      this.setState({ forecastMetricesFiveLabel: event.target.value}, () => { });
    }else{
      const trimmedValue = nonWhiteSpace.slice(0, 60);
      this.setState({ forecastMetricesFiveLabel: trimmedValue}, () => { });
    }
  };
  forcastMetricesInputFiveChange = (event) => {
    if (/^\d{0,10}(\.\d{0,6})?$/.test(event.target.value)) {
      let styleStateObj = this.state.selectStyle;
      styleStateObj.forecastMetricesFiveInput = textBoxValidation(true);
      this.setState({forecastMetricesFiveInput:event.target.value,selectStyle: styleStateObj})
    }else{
      window.alert("You can add upto 10 digits or 999,999,9999 and 6 decimal values only allowed.");
      return;
    }
  };

  //  forecast template function ends here

  // Sentiment analysis input change function starts here
  handleSentimentInputChange = (e) => {
    let convertNumber = Number(e.target.value)
    if (/^\d{0,10}$/.test(convertNumber)) {
      let abc = this.state.sentimentValues;
      if (e.target.name === "mixedSentiments") {
        abc.mixedSentiments = Number(e.target.value);
      }
      if (e.target.name === "goodToHave") {
        abc.goodToHave = Number(e.target.value);
      }
      if (e.target.name === "noChecksNeeded") {
        abc.noChecksNeeded = Number(e.target.value);
      }
      if (e.target.name === "priorityChecks") {
        abc.priorityChecks = Number(e.target.value);
      }
      if (e.target.name === "sentimentDuplicates") {
        abc.sentimentDuplicates = Number(e.target.value);
      }
      let total =
        abc.mixedSentiments +
        abc.goodToHave +
        abc.noChecksNeeded +
        abc.priorityChecks +
        abc.sentimentDuplicates;
      this.setState({ sentimentTotal: total, sentimentValues: abc }, () => { });
    }else{
      window.alert("You cannot add value more than 10 billion.");
      return;
    }


      


  };
  // Sentiment analysis input change function starts here

  // Anamoly function starts here
  handleAnamolyTableCellChange = (rowIndex, columnName, value) => {
    let nonWhiteSpace = value.replace(/^\s+|\s+$/gm,'');
    console.log(value.length)
    if(nonWhiteSpace.length < 500){
      this.setState((prevState) => {
        const updatedData = [...prevState.anomolyData];
        updatedData[rowIndex][columnName] = value;
        return { anomolyData: updatedData };
      });
    }else{
      // window.alert("You can add only 500 characters for the Anamoly text field");
      const trimmedValue = nonWhiteSpace.slice(0, 500);
      this.setState((prevState) => {
        const updatedData = [...prevState.anomolyData];
        updatedData[rowIndex][columnName] = trimmedValue;
        return { anomolyData: updatedData };
      });
    }
    
  };
  addAnamolyRow = () => {
    if (this.state.anomolyData.length < 30) {
      this.setState((prevState) => ({
        anomolyData: [...prevState.anomolyData, {}],
      }), () => {  });
    }else{
      window.alert("You can add upto 30 rows max");
      return;
    }
  };
  addAnamolyColumn = () => {
    this.setState({ displayAnamolyRowButton: true }, () => { })
    if (Object.keys(this.state.anomolyData[0] || {}).length <= 4) {
      const newColumn = `Txt${Object.keys(this.state.anomolyData[0] || {}).length + 1}`;
      this.setState((prevState) => ({
        anomolyData: prevState.anomolyData.map((row) => ({ ...row, [newColumn]: "" })),
      }));
    } else {
      window.alert("Upto 5 columns can be added");
    }
  };

  handleAnamolyRowDelete=(item,index)=>{
    if (window.confirm("Are you sure you want to delete this row ?")) {
      const { anomolyData } = this.state;
      const updateddata = [...anomolyData];
      updateddata.splice(index, 1);
      this.setState({
        anomolyData: updateddata,
      },()=>{
        if(anomolyData.length === 1){
          this.setState({anomolyData:[{}],displayAnamolyRowButton:false})
        }
      });
    }
  }
  handleAnamolyReset=()=>{
    if(window.confirm('Are you sure you want to reset the anamoly analysis ?')){
      this.setState({anomolyData:[{}],displayAnamolyRowButton:false})
    }
  }

  deleteAnamolyColumn = (index,row) => {
    const {anomolyData} = this.state;
    const toBeDeleted = `Txt${index}`;
    console.log(toBeDeleted)
    // console.log(anomolyData)
    // this.setState((prevState) => ({
    //   anomolyData: prevState.anomolyData.map((row) => ({ ...row, [newColumn]: "" })),
    // }));
  };
  // Anamoly function ends here

  // Others template function starts here

  
  handleOthersTableCellChange = (rowIndex, columnName, value) => {
    let nonWhiteSpace = value.replace(/^\s+|\s+$/gm,'');
    console.log(value.length)
    if(nonWhiteSpace.length < 500){
      this.setState((prevState) => {
        const updatedData = [...prevState.othersData];
        updatedData[rowIndex][columnName] = value;
        return { othersData: updatedData };
      });
    }else{
      // window.alert("You can add only 500 characters for the Others text field");
      const trimmedValue = nonWhiteSpace.slice(0, 500);
      this.setState((prevState) => {
        const updatedData = [...prevState.othersData];
        updatedData[rowIndex][columnName] = trimmedValue;
        return { othersData: updatedData };
      });
    }
    
  };
  addOthersRow = () => {
    if (this.state.othersData.length < 30) {
      this.setState((prevState) => ({
        othersData: [...prevState.othersData, {}],
      }));
    }else{
      window.alert("You can add upto 30 rows max");
      return;
    }
  };
  addOthersColumn = () => {
    this.setState({ displayOthersRowButton: true }, () => { })
    if (Object.keys(this.state.othersData[0] || {}).length <= 4) {
      const newColumn = `Txt${Object.keys(this.state.othersData[0] || {}).length + 1}`;
      this.setState((prevState) => ({
        othersData: prevState.othersData.map((row) => ({ ...row, [newColumn]: "" })),
      }));
    } else {
      window.alert("Upto 5 columns can be added");
    }

  };
  handleOthersReset=()=>{
    if(window.confirm('Are you sure you want to reset the Others analysis ?')){
      this.setState({othersData:[{}],displayOthersRowButton:false})
    }
  }


  handleOthersRowDelete=(item,index)=>{
    if (window.confirm("Are you sure you want to delete this row ?")) {
      const { othersData } = this.state;
      const updateddata = [...othersData];
      updateddata.splice(index, 1);
      this.setState({
        othersData: updateddata,
      },()=>{
        if(othersData.length === 1){
          this.setState({othersData:[{}],displayOthersRowButton:false})
        }
      });
    }
  }


  // Others template function ends here







  validate = () => {
    let isValid = true;
    let styleStateObj = this.state.selectStyle;

    // if (this.state.RecType && (this.state.RecType === "DA" || this.state.RecType === "DPA")) {
    //   isValid = false;
    //   Toast.fail("Only one template can be saved amoong all the templates.", 3000, () => { });
    // }

    if (!this.state.showClassificationTemplate && !this.state.showRegressionTemplate && !this.state.showForecastTemplate
      && !this.state.showAnamolyTemplate && !this.state.showOthersTemplate && !this.state.showSentimentTemplate
    ) {
      isValid = false;
      Toast.fail("Select atleast one model before saving", 3000, () => { });
    }

    let confusionMatrixLimit1 = false;
    let confusionMatrixLimit2 = false;
    let confusionMatrixLimit3 = false;
    // classification module validation starts here
    console.log(this.state.showClassificationTemplate)
    if (this.state.showClassificationTemplate) {
      let verifyClassificationModuleData = Object.entries(this.state.classificationModule).length > 0;
      let isClassificationModuleValid = true;
      console.log(verifyClassificationModuleData)
      if (verifyClassificationModuleData) {
        console.log("classification module =>",this.state.classificationModule)
        this.state.classificationModule.map((item, index) => {
          if (item.Feature == null || item.Feature == undefined || item.Feature == "" ||
          item.Role.length === 0 || !item.Role || item.Role === null || item.Role === undefined ||
          item.Score === null || item.Score === undefined || item.Score === "" ||
          item.Type.length === 0 || !item.Type || item.Type === null || item.Type === undefined
          ){
            isValid = false;
            isClassificationModuleValid = false;
            Toast.fail(
              "Feature",
              3000,
              () => { }
            );
          }
          if (item.Feature.length > 30) {
            isValid = false;
            isClassificationModuleValid = false;
            Toast.fail(
              "Classification Module 1 Feature field characters cannot be more than 30 characaters. Please recheck and submit",
              3000,
              () => { }
            );
          }
        });
        const sumOfScores = this.state.classificationModule.reduce((acc, obj) => {
          const score = Number(obj['Score']);
          if (!isNaN(score)) {
            acc += score;
          }
          return acc;
        }, 0);
        if(sumOfScores > 100){
          isValid = false;
          isClassificationModuleValid = false;
          Toast.fail(
            "Please make sure that sum of all the rows of Classification Module 1 importance score value is not greater than 100",
            6000,
            () => { }
          );
        }
        
      } else {
        isValid = false;
        Toast.fail("Please fill classification Module 1 data", 3000, () => { });
      }

      let verifyOutComeNumber = Object.entries(this.state.confusionMatrixTableSelectedOption1).length > 0;
      if (verifyOutComeNumber) {
        let confusionMatrixZeroError = false;
        console.log(this.state.confusionMatrixData1)
        console.log("confusion matrix data =>",this.state.confusionMatrixData1)
        this.state.confusionMatrixData1 && this.state.confusionMatrixData1.forEach((row, index) => {
          for (const key in row) {
            if (row[key] === null || row[key] === "" || row[key] === undefined) {
              confusionMatrixLimit1 = true;
              isValid = false;
            }
            if(row[key] === "0"){
              confusionMatrixZeroError=true;
              isValid=false;
            }
          }
        });
        if (confusionMatrixZeroError) {
          isValid=false;
          Toast.fail("Confusion Matrix 1 table values cannot be zero, please recheck and submit.", 3000, () => { });
        }
        if (confusionMatrixLimit1) {
          isValid=false;
          Toast.fail("Please fill all the fields of confusion Matrix 1", 3000, () => { });
        }
      } else {
        isValid = false;
        Toast.fail("Please select outcome number of confusion Matrix 1", 3000, () => { });
      }
      console.log("classification model selected =>",this.state.classificationModelSelected)
      if (this.state.classificationModelSelected.length > 0) {
        this.state.classificationModelSelected.map((item) => {
          if (item.label === "" || item.label === null || item.label === undefined) {
            isValid = false;
            Toast.fail("Please select model type for Classification module 1", 3000, () => { })
            styleStateObj.classificationModelSelected = selectStyle(false);
            this.setState({ selectStyle: styleStateObj })
          }
          else {
            styleStateObj.classificationModelSelected = selectStyle(true);
            this.setState({
              selectStyle: styleStateObj
            })
          }
        })
      } else {
        isValid = false;
        Toast.fail("Please select model type for Classification module 1", 3000, () => { })
        styleStateObj.classificationModelSelected = selectStyle(false);
        this.setState({ selectStyle: styleStateObj })
      }


    }
    // classification module validation ends here

    // classification module 1 validation starts here
    if (this.state.showClassificationTemplate1) {
      let isClassificationModule1Valid = true;
      let verifyClassificationModuleData = Object.entries(this.state.classificationModule1).length > 0;
      if (verifyClassificationModuleData) {
        this.state.classificationModule1.map((item, index) => {
          if (
            item.Feature == null || item.Feature == undefined || item.Feature == "" || item.Type.length === 0 ||
            !item.Type || item.Type === null || item.Type === undefined || item.Score === null ||
            item.Score === undefined || item.Score === "" || item.Role.length === 0 ||
            !item.Role || item.Role === null || item.Role === undefined
          ) {
            isValid = false;
            isClassificationModule1Valid = false;
            Toast.fail(
              "Please fill classification Module 2 data",
              3000,
              () => { }
            );
          }
          if (item.Feature.length > 30) {
            isValid = false;
            isClassificationModule1Valid = false;
            Toast.fail(
              "Please make sure that sum of all the rows of Classification Module 2 importance score value is not greater than 100",
              3000,
              () => { }
            );
          }
          
          
        });

        const sumOfScores = this.state.classificationModule1.reduce((acc, obj) => {
          const score = Number(obj['Score']);
          if (!isNaN(score)) {
            acc += score;
          }
          return acc;
        }, 0);
        if(sumOfScores > 100){
          isValid = false;
          isClassificationModule1Valid = false;
          Toast.fail(
            "Classification Module 2 Importance score cannot be greater than 100. Please recheck and submit.",
            3000,
            () => { }
          );
        }
      } else {
        isValid = false;
        Toast.fail("Please fill classification Module 2 data", 3000, () => { });
      }

      let verifyOutComeNumber1 = Object.entries(this.state.confusionMatrixTableSelectedOption2).length > 0;
      if (verifyOutComeNumber1) {
        let confusionMatrixZeroError1 = false;
        this.state.confusionMatrixData2 && this.state.confusionMatrixData2.forEach((row, index) => {
          console.log("confusion matrix 2 =>",this.state.confusionMatrixData2)
          for (const key in row) {
            console.log(row[key])
            if (row[key] === null || row[key] === "" || row[key] === undefined) {
              confusionMatrixLimit2 = true;
              isValid = false;
            }
            if(row[key] === "0"){
              confusionMatrixZeroError1=true;
              isValid=false;
            }
          }
        });
        if (confusionMatrixZeroError1) {
          isValid=false;
          Toast.fail("Confusion Matrix 2 table values cannot be zero, please recheck and submit.", 3000, () => { });
        }
        if(confusionMatrixLimit2){
          isValid=false;
          Toast.fail("Please fill all the fields of confusion Matrix 2", 3000, () => { });
        }
        
      } else {
        isValid = false;
        Toast.fail("Please select outcome number of confusion Matrix 2", 3000, () => { });
      }


      if (this.state.classificationModelSelected1.length > 0) {
        console.log("classification model 1 selected =>",this.state.classificationModelSelected1)
        this.state.classificationModelSelected1.map((item) => {
          if (item.label === "" || item.label === null || item.label === undefined) {
            isValid = false;
            Toast.fail("Please select model type for Classification module 2", 3000, () => { })
            styleStateObj.classificationModelSelected1 = selectStyle(false);
            this.setState({ selectStyle: styleStateObj })
          }
          else {
            styleStateObj.classificationModelSelected1 = selectStyle(true);
            this.setState({
              selectStyle: styleStateObj
            })
          }
        })
      } else {
        isValid = false;
        Toast.fail("Please select model type for Classification module 2", 3000, () => { })
        styleStateObj.classificationModelSelected1 = selectStyle(false);
        this.setState({ selectStyle: styleStateObj })
      }


    }
    // classification module 1 validation ends here

    // classification module 2 validation starts here
    if (this.state.showClassificationTemplate2) {
      let isClassificationModule2Valid = true;
      let verifyClassificationModuleData =
        Object.entries(this.state.classificationModule2).length > 0;
      if (verifyClassificationModuleData) {
        this.state.classificationModule2.map((item, index) => {
          if (
            item.Feature == null || item.Feature == undefined || item.Feature == "" || item.Type.length === 0 ||
            !item.Type || item.Type === null || item.Type === undefined || item.Score === null ||
            item.Score === undefined || item.Score === "" || item.Role.length === 0 || !item.Role ||
            item.Role === null || item.Role === undefined
          ) {
            isValid = false;
            isClassificationModule2Valid = false;
            Toast.fail("Please fill classification Module 3 data", 3000, () => { });
          }
          if (item.Feature.length > 30) {
            isValid = false;
            isClassificationModule2Valid = false;
            Toast.fail(
              "Classification Module 3 Feature field characters cannot be more than 10 characaters. Please recheck and submit",
              3000,
              () => { }
            );
          }
          
        });
        const sumOfScores = this.state.classificationModule2.reduce((acc, obj) => {
          const score = Number(obj['Score']);
          if (!isNaN(score)) {
            acc += score;
          }
          return acc;
        }, 0);
        if(sumOfScores > 100){
          isValid = false;
          isClassificationModule2Valid = false;
          Toast.fail(
            "Please make sure that sum of all the rows of Classification Module 3 importance score value is not greater than 100",
            3000,
            () => { }
          );
        }
      } else {
        isValid = false;
        Toast.fail("Please fill classification Module 3 data", 3000, () => { });
      }


      let verifyConfusionOutComeOption = Object.entries(this.state.confusionMatrixTableSelectedOption3).length > 0;
      if (verifyConfusionOutComeOption) {
        let confusionMatrixZeroError2 = false;
        this.state.confusionMatrixData3 && this.state.confusionMatrixData3.forEach((row, index) => {
          for (const key in row) {
            if (row[key] === null || row[key] === "" || row[key] === undefined) {
              confusionMatrixLimit3 = true;
              isValid = false;
            }
            if (row[key] === "0") {
              confusionMatrixZeroError2 = true;
              isValid = false;
            }
          }
        });
        if(confusionMatrixZeroError2){
          Toast.fail("Confusion Matrix 3 table values cannot be zero, please recheck and submit.", 3000, () => { });
        }
        if (confusionMatrixLimit3) {
          isValid = false;
          Toast.fail("Please fill all the fields of confusion Matrix 3", 3000, () => { });
        }
      } else {
        isValid = false;
        Toast.fail("Please select outcome number of confusion Matrix 3", 3000, () => { });
      }


      if (this.state.classificationModelSelected2.length > 0) {
        console.log("classification model 2 selected =>",this.state.classificationModelSelected2)
        this.state.classificationModelSelected2.map((item) => {
          if (item.label === "" || item.label === null || item.label === undefined) {
            isValid = false;
            Toast.fail("Please select model type for Classification module 3", 3000, () => { })
            styleStateObj.classificationModelSelected2 = selectStyle(false);
            this.setState({ selectStyle: styleStateObj })
          }
          else {
            styleStateObj.classificationModelSelected2 = selectStyle(true);
            this.setState({
              selectStyle: styleStateObj
            })
          }
        })
      } else {
        isValid = false;
        Toast.fail("Please select model type for Classification module 3", 3000, () => { })
        styleStateObj.classificationModelSelected2 = selectStyle(false);
        this.setState({ selectStyle: styleStateObj })
      }


    }
    // classification module 2 validation starts here


    // Regression validation starts here
    if (this.state.showRegressionTemplate) {
      let verifyRegressionData =
        Object.entries(this.state.regressionData).length > 0;
      let isRegressionTemplateValid = true;
      if (verifyRegressionData) {
        this.state.regressionData.map((item, index) => {
          if (
            item.Feature == null ||
            item.Feature == undefined ||
            item.Feature == "" ||
            item.Type.length === 0 ||
            item.Role.length === 0
          ) {
            isValid = false;
            isRegressionTemplateValid = false;
            Toast.fail("Please fill regression template data", 3000, () => { });
          }
          if (item.Feature.length > 30) {
            isValid = false;
            isRegressionTemplateValid = false;
            Toast.fail(
              "Regression Template Feature field characters cannot be more than 10 characaters. Please recheck and submit",
              3000,
              () => { }
            );
          }
          if (item.Role.length > 30) {
            isValid = false;
            isRegressionTemplateValid = false;
            Toast.fail(
              "Regression Template Type field characters cannot be more than 10 characaters. Please recheck and submit",
              3000,
              () => { }
            );
          }
        });
      } else {
        isValid = false;
        isRegressionTemplateValid = false;
        Toast.fail("Please fill regresion template data", 3000, () => { });
      }

      if (
        this.state.regressionMeanAbsoluteError === "" ||
        this.state.regressionMeanAbsoluteError === null ||
        this.state.regressionMeanAbsoluteError === undefined ||
        !this.state.regressionMeanAbsoluteError
      ) {
        isValid = false;
        isRegressionTemplateValid = false;
        styleStateObj.regressionMeanAbsoluteError = textBoxValidation(false);
        // Toast.fail("regression mean absolyte error", 3000, () => { })
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.regressionMeanAbsoluteError = textBoxValidation(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }
      if (
        this.state.regressionMeanSquaredError === "" ||
        this.state.regressionMeanSquaredError === null ||
        this.state.regressionMeanSquaredError === undefined ||
        !this.state.regressionMeanSquaredError
      ) {
        isValid = false;
        isRegressionTemplateValid = false;
        // Toast.fail("rg mean square error", 3000, () => { })
        styleStateObj.regressionMeanSquaredError = textBoxValidation(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.regressionMeanSquaredError = textBoxValidation(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }
      if (
        this.state.regressionRootMeanSquaredError === "" ||
        this.state.regressionRootMeanSquaredError === null ||
        this.state.regressionRootMeanSquaredError === undefined ||
        !this.state.regressionRootMeanSquaredError
      ) {
        isValid = false;
        isRegressionTemplateValid = false;
        // Toast.fail("rg root mean square error", 3000, () => { })
        styleStateObj.regressionRootMeanSquaredError = textBoxValidation(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.regressionRootMeanSquaredError = textBoxValidation(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }


      if (
        this.state.regressionMeanAbsolutePercentageError === "" ||
        this.state.regressionMeanAbsolutePercentageError === null ||
        this.state.regressionMeanAbsolutePercentageError === undefined ||
        !this.state.regressionMeanAbsolutePercentageError
      ) {
        isValid = false;
        isRegressionTemplateValid = false;
        styleStateObj.regressionMeanAbsolutePercentageError = textBoxValidation(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.regressionMeanAbsolutePercentageError = textBoxValidation(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }


      if (this.state.regressionMetricesOne) {
        if (
          this.state.regressionMetricesOneLabel === "" ||
          this.state.regressionMetricesOneLabel === null ||
          this.state.regressionMetricesOneLabel === undefined ||
          !this.state.regressionMetricesOneLabel
        ) {
          isValid = false;
          isRegressionTemplateValid = false;
          styleStateObj.regressionMetricesOneLabel = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          // Toast.fail("rg regression metric one label error", 3000, () => { })
          styleStateObj.regressionMetricesOneLabel = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
        if (
          this.state.regressionMetricesOneInput === "" ||
          this.state.regressionMetricesOneInput === null ||
          this.state.regressionMetricesOneInput === undefined ||
          !this.state.regressionMetricesOneInput
        ) {
          isValid = false;
          isRegressionTemplateValid = false;
          // Toast.fail("rg regression metric one input error", 3000, () => { })
          styleStateObj.regressionMetricesOneInput = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.regressionMetricesOneInput = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
      }
      if (this.state.regressionMetricesTwo) {
        if (
          this.state.regressionMetricesTwoLabel === "" ||
          this.state.regressionMetricesTwoLabel === null ||
          this.state.regressionMetricesTwoLabel === undefined ||
          !this.state.regressionMetricesTwoLabel
        ) {
          isValid = false;
          isRegressionTemplateValid = false;
          // Toast.fail("rg regression metric two label error", 3000, () => { })
          styleStateObj.regressionMetricesTwoLabel = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.regressionMetricesTwoLabel = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }

        if (
          this.state.regressionMetricesTwoInput === "" ||
          this.state.regressionMetricesTwoInput === null ||
          this.state.regressionMetricesTwoInput === undefined ||
          !this.state.regressionMetricesTwoInput
        ) {
          isValid = false;
          isRegressionTemplateValid = false;
          // Toast.fail("rg regression metric two input error", 3000, () => { })
          styleStateObj.regressionMetricesTwoInput = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.regressionMetricesTwoInput = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
      }
      if (this.state.regressionMetricesThree) {
        if (
          this.state.regressionMetricesThreeLabel === "" ||
          this.state.regressionMetricesThreeLabel === null ||
          this.state.regressionMetricesThreeLabel === undefined ||
          !this.state.regressionMetricesThreeLabel
        ) {
          isValid = false;
          isRegressionTemplateValid = false;
          // Toast.fail("rg regression metric three label error", 3000, () => { })
          styleStateObj.regressionMetricesThreeLabel = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.regressionMetricesThreeLabel = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
        if (
          this.state.regressionMetricesThreeInput === "" ||
          this.state.regressionMetricesThreeInput === null ||
          this.state.regressionMetricesThreeInput === undefined ||
          !this.state.regressionMetricesThreeInput
        ) {
          isValid = false;
          isRegressionTemplateValid = false;
          // Toast.fail("rg regression metric three input error", 3000, () => { })
          styleStateObj.regressionMetricesThreeInput = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.regressionMetricesThreeInput = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
      }
      if (this.state.regressionMetricesFour) {
        if (
          this.state.regressionMetricesFourLabel === "" ||
          this.state.regressionMetricesFourLabel === null ||
          this.state.regressionMetricesFourLabel === undefined ||
          !this.state.regressionMetricesFourLabel
        ) {
          isValid = false;
          isRegressionTemplateValid = false;
          // Toast.fail("rg regression metric four label error", 3000, () => { })
          styleStateObj.regressionMetricesFourLabel = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.regressionMetricesFourLabel = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
        if (
          this.state.regressionMetricesFourInput === "" ||
          this.state.regressionMetricesFourInput === null ||
          this.state.regressionMetricesFourInput === undefined ||
          !this.state.regressionMetricesFourInput
        ) {
          isValid = false;
          isRegressionTemplateValid = false;
          // Toast.fail("rg regression metric four input error", 3000, () => { })
          styleStateObj.regressionMetricesFourInput = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.regressionMetricesFourInput = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
      }
      if (this.state.regressionMetricesFive) {
        if (
          this.state.regressionMetricesFiveLabel === "" ||
          this.state.regressionMetricesFiveLabel === null ||
          this.state.regressionMetricesFiveLabel === undefined ||
          !this.state.regressionMetricesFiveLabel
        ) {
          isValid = false;
          isRegressionTemplateValid = false;
          // Toast.fail("rg regression metric five label error", 3000, () => { })
          styleStateObj.regressionMetricesFiveLabel = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.regressionMetricesFiveLabel = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
        if (
          this.state.regressionMetricesFiveInput === "" ||
          this.state.regressionMetricesFiveInput === null ||
          this.state.regressionMetricesFiveInput === undefined ||
          !this.state.regressionMetricesFiveInput
        ) {
          isValid = false;
          isRegressionTemplateValid = false;
          // Toast.fail("rg regression metric five input error", 3000, () => { })
          styleStateObj.regressionMetricesFiveInput = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.regressionMetricesFiveInput = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
      }
      // if (isRegressionTemplateValid) {
      //   this.state.regressionData.map((item, index) => {
      //     if (item.Role) {
      //       let localData = [...this.state.regressionData];
      //       localData[index].Role = localData[index].Role.label;
      //       this.setState({ regressionPostData: localData }, () => { });
      //     }
      //     if (item.Type) {
      //       let localData = [...this.state.regressionData];
      //       localData[index].Type = localData[index].Type.label;
      //       this.setState({ regressionPostData: localData }, () => { });
      //     }
      //   });
      // }
    }

    // forecast validation starts here
    if (this.state.showForecastTemplate) {
      let isForecastTemplateValid = true;
      let verifyForecastData =
        Object.entries(this.state.forecastData).length > 0;
      if (verifyForecastData) {
        this.state.forecastData.map((item, index) => {
          if (
            item.Feature == null ||
            item.Feature == undefined ||
            item.Feature == "" ||
            item.Type.length === 0 ||
            item.Role.length === 0
          ) {
            isValid = false;
            isForecastTemplateValid = false;
            Toast.fail("Please fill forecast template data", 3000, () => { });
          }
          if (item.Feature.length > 30) {
            isValid = false;
            isForecastTemplateValid = false;
            Toast.fail(
              "Forecast Template Feature field characters cannot be more than 10 characaters. Please recheck and submit",
              3000,
              () => { }
            );
          }
        });
      } else {
        isValid = false;
        Toast.fail("Please fill forecast template data", 3000, () => { });
      }
      if (
        this.state.forecastMeanAbsoluteError === "" ||
        this.state.forecastMeanAbsoluteError === null ||
        this.state.forecastMeanAbsoluteError === undefined ||
        !this.state.forecastMeanAbsoluteError
      ) {
        isValid = false;
        isForecastTemplateValid = false;
        // Toast.fail("forecast mean abs error", 3000, () => { });
        styleStateObj.forecastMeanAbsoluteError = textBoxValidation(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.forecastMeanAbsoluteError = textBoxValidation(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }
      if (
        this.state.forecastMeanSquaredError === "" ||
        this.state.forecastMeanSquaredError === null ||
        this.state.forecastMeanSquaredError === undefined ||
        !this.state.forecastMeanSquaredError
      ) {
        isValid = false;
        isForecastTemplateValid = false;
        // Toast.fail("forecast mean sq error", 3000, () => { });
        styleStateObj.forecastMeanSquaredError = textBoxValidation(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.forecastMeanSquaredError = textBoxValidation(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }
      if (
        this.state.forecastRootMeanSquaredError === "" ||
        this.state.forecastRootMeanSquaredError === null ||
        this.state.forecastRootMeanSquaredError === undefined ||
        !this.state.forecastRootMeanSquaredError
      ) {
        isValid = false;
        isForecastTemplateValid = false;
        // Toast.fail("forecast root mean square error", 3000, () => { });
        styleStateObj.forecastRootMeanSquaredError = textBoxValidation(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.forecastRootMeanSquaredError = textBoxValidation(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }


      if (
        this.state.forecastMeanAbsolutePercentageError === "" ||
        this.state.forecastMeanAbsolutePercentageError === null ||
        this.state.forecastMeanAbsolutePercentageError === undefined ||
        !this.state.forecastMeanAbsolutePercentageError
      ) {
        isValid = false;
        isForecastTemplateValid = false;
        styleStateObj.forecastMeanAbsolutePercentageError = textBoxValidation(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.forecastMeanAbsolutePercentageError = textBoxValidation(true);
        this.setState({
          selectStyle: styleStateObj,
        });
      }


      


      if (this.state.forecastMetricesOne) {
        if (
          this.state.forecastMetricesOneLabel === "" ||
          this.state.forecastMetricesOneLabel === null ||
          this.state.forecastMetricesOneLabel === undefined ||
          !this.state.forecastMetricesOneLabel
        ) {
          isValid = false;
          isForecastTemplateValid = false;
          // Toast.fail("forecast metrics one label", 3000, () => { });
          styleStateObj.forecastMetricesOneLabel = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.forecastMetricesOneLabel = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
        if (
          this.state.forecastMetricesOneInput === "" ||
          this.state.forecastMetricesOneInput === null ||
          this.state.forecastMetricesOneInput === undefined ||
          !this.state.forecastMetricesOneInput
        ) {
          isValid = false;
          isForecastTemplateValid = false;
          // Toast.fail("forecast metrics one input", 3000, () => { });
          styleStateObj.forecastMetricesOneInput = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.forecastMetricesOneInput = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
      }
      if (this.state.forecastMetricesTwo) {
        if (
          this.state.forecastMetricesTwoLabel === "" ||
          this.state.forecastMetricesTwoLabel === null ||
          this.state.forecastMetricesTwoLabel === undefined ||
          !this.state.forecastMetricesTwoLabel
        ) {
          isValid = false;
          isForecastTemplateValid = false;
          // Toast.fail("forecast metrics two label", 3000, () => { });
          styleStateObj.forecastMetricesTwoLabel = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.forecastMetricesTwoLabel = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
        if (
          this.state.forecastMetricesTwoInput === "" ||
          this.state.forecastMetricesTwoInput === null ||
          this.state.forecastMetricesTwoInput === undefined ||
          !this.state.forecastMetricesTwoInput
        ) {
          isValid = false;
          isForecastTemplateValid = false;
          // Toast.fail("forecast metrics two input", 3000, () => { });
          styleStateObj.forecastMetricesTwoInput = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.forecastMetricesTwoInput = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
      }
      if (this.state.forecastMetricesThree) {
        if (
          this.state.forecastMetricesThreeLabel === "" ||
          this.state.forecastMetricesThreeLabel === null ||
          this.state.forecastMetricesThreeLabel === undefined ||
          !this.state.forecastMetricesThreeLabel
        ) {
          isValid = false;
          isForecastTemplateValid = false;
          // Toast.fail("forecast metrics three label", 3000, () => { });
          styleStateObj.forecastMetricesThreeLabel = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.forecastMetricesThreeLabel = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }

        if (
          this.state.forecastMetricesThreeInput === "" ||
          this.state.forecastMetricesThreeInput === null ||
          this.state.forecastMetricesThreeInput === undefined ||
          !this.state.forecastMetricesThreeInput
        ) {
          isValid = false;
          isForecastTemplateValid = false;
          // Toast.fail("forecast metrics three input", 3000, () => { });
          styleStateObj.forecastMetricesThreeInput = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.forecastMetricesThreeInput = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
      }
      if (this.state.forecastMetricesFour) {
        if (
          this.state.forecastMetricesFourLabel === "" ||
          this.state.forecastMetricesFourLabel === null ||
          this.state.forecastMetricesFourLabel === undefined ||
          !this.state.forecastMetricesFourLabel
        ) {
          isValid = false;
          isForecastTemplateValid = false;
          // Toast.fail("forecast metrics four label", 3000, () => { });
          styleStateObj.forecastMetricesFourLabel = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.forecastMetricesFourLabel = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
        if (
          this.state.forecastMetricesFourInput === "" ||
          this.state.forecastMetricesFourInput === null ||
          this.state.forecastMetricesFourInput === undefined ||
          !this.state.forecastMetricesFourInput
        ) {
          isValid = false;
          isForecastTemplateValid = false;
          // Toast.fail("forecast metrics 4 input", 3000, () => { });
          styleStateObj.forecastMetricesFourInput = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.forecastMetricesFourInput = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
      }
      if (this.state.forecastMetricesFive) {
        if (
          this.state.forecastMetricesFiveLabel === "" ||
          this.state.forecastMetricesFiveLabel === null ||
          this.state.forecastMetricesFiveLabel === undefined ||
          !this.state.forecastMetricesFiveLabel
        ) {
          isValid = false;
          isForecastTemplateValid = false;
          // Toast.fail("forecast metrics 5 label", 3000, () => { });
          styleStateObj.forecastMetricesFiveLabel = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.forecastMetricesFiveLabel = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
        if (
          this.state.forecastMetricesFiveInput === "" ||
          this.state.forecastMetricesFiveInput === null ||
          this.state.forecastMetricesFiveInput === undefined ||
          !this.state.forecastMetricesFiveInput
        ) {
          isValid = false;
          isForecastTemplateValid = false;
          // Toast.fail("forecast metrics 5 inpt", 3000, () => { });
          styleStateObj.forecastMetricesFiveInput = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.forecastMetricesFiveInput = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
      }
      // if (isForecastTemplateValid) {
      //   this.state.forecastData.map((item, index) => {
      //     if (item.Role) {
      //       let localData = [...this.state.forecastData];
      //       console.log(localData)
      //       localData[index].Role = localData[index].Role.label;
      //       this.setState({ forecastPostData: localData }, () => { });
      //     }
      //     if (item.Type) {
      //       let localData = [...this.state.forecastData];
      //       localData[index].Type = localData[index].Type.label;
      //       this.setState({ forecastPostData: localData }, () => { });
      //     }
      //   });
      // }
    }

    
    if (this.state.showSentimentTemplate) {
      if (this.state.sentimentValues) {
        console.log("good to have =>", this.state.sentimentValues.goodToHave)
        if (this.state.sentimentValues.goodToHave === "" || this.state.sentimentValues.goodToHave === undefined || this.state.sentimentValues.goodToHave === null) {
          Toast.fail("Please fill Sentiment Analysis - Good to have value.", 3000, () => { });
          isValid = false;
        }
        if (this.state.sentimentValues.mixedSentiments === "" || this.state.sentimentValues.mixedSentiments === null || this.state.sentimentValues.mixedSentiments === undefined) {
          Toast.fail("Please fill Sentiment Analysis - mixed sentiment value.", 3000, () => { });
          isValid = false;
        }
        if (this.state.sentimentValues.noChecksNeeded === "" || this.state.sentimentValues.noChecksNeeded === undefined || this.state.sentimentValues.noChecksNeeded === null) {
          Toast.fail("Please fill Sentiment Analysis - no checks needed value.", 3000, () => { });
          isValid = false;
        }
        if (this.state.sentimentValues.priorityChecks === "" || this.state.sentimentValues.priorityChecks === null || this.state.sentimentValues.priorityChecks === undefined) {
          Toast.fail("Please fill Sentiment Analysis - priority checks value.", 3000, () => { });
          isValid = false;
        }
        if (this.state.sentimentValues.sentimentDuplicates === "" || this.state.sentimentValues.sentimentDuplicates === null || this.state.sentimentValues.sentimentDuplicates === undefined) {
          Toast.fail("Please fill Sentiment Analysis - sentiment duplicates value.", 3000, () => { });
          isValid = false;
        }
      } else {
        Toast.fail(
          "Please fill sentiment analysis template data.",
          3000,
          () => { }
        );
        isValid = false;
      }
    }
    // anamoly validation starts here
    if (this.state.showAnamolyTemplate) {
      let showAnamolyToast = false;
      if (this.state.anomolyData.length === 1 && Object.keys(this.state.anomolyData[0]).length === 0) {
        showAnamolyToast = true;
      }
      for (const row of this.state.anomolyData) {
        for (const key in row) {
          if (!row[key] && row[key] !== 0) {
            showAnamolyToast = true;
          }
        }
      }
      if (this.state.anomolyData.length > 0) {
        this.state.anomolyData.map((e) => {
          if (e) {
            for (let [key, value] of Object.entries(e)) {
              if (value === undefined || value === "" || value === null || value.length === 0) {
                showAnamolyToast = true;
              }
            }
          }
          if (Object.keys(e).length === 0) {
            showAnamolyToast = true;
          }
        })
      }
      if (showAnamolyToast) {
        Toast.fail(
          "Please fill all the values of anamoly template.",
          3000,
          () => { }
        );
        isValid = false;
      }
    }
    // anamoly validation ends here

    // Others template validation starts here
    if (this.state.showOthersTemplate) {
      let showOthersToast = false;
      if (this.state.othersData.length === 1 && Object.keys(this.state.othersData[0]).length === 0) {
        showOthersToast = true;
      }
      for (const row of this.state.othersData) {
        for (const key in row) {
          if (!row[key] && row[key] !== 0) {
            showOthersToast = true;
          }
        }
      }
      if (this.state.othersData.length > 0) {
        this.state.othersData.map((e) => {
          if (e) {
            for (let [key, value] of Object.entries(e)) {
              if (value === undefined || value === "" || value === null || value.length === 0) {
                showOthersToast = true;
              }
            }
          }
          if (Object.keys(e).length === 0) {
            showOthersToast = true;
          }
        })
      }
      if (showOthersToast) {
        Toast.fail(
          "Please fill all the values of Others template.",
          3000,
          () => { }
        );
        isValid = false;
      }
    }
    // Others template validation ends here
    return isValid;
  };



  savePredictiveTemplate = () => {
    if ((parseInt(this.state.ideaItem.OwnerOHR) === parseInt(this.sessionData.profile.oHRId)) || (parseInt(this.state.ideaItem.BlackBeltUserId) === parseInt(this.sessionData.profile.oHRId))) {
      if (this.validate()) {
        if (window.confirm("Are you sure you want to save the template ? ")) {
          Toast.loading("saving template...", () => { });
          
          // Classification module 1 model type 
          let classificationModelSelectedArray = [];
          let classificationModelPipelines = "";
          if(this.state.classificationModelSelected){
            this.state.classificationModelSelected.map((item,index)=>{
              classificationModelSelectedArray.push(item.label)
            })
            classificationModelPipelines = classificationModelSelectedArray.join("|");
          }


          // Classification module 2 model type 
          let classificationModelSelectedArray1 = [];
          let classificationModelPipelines1 = "";
          if(this.state.classificationModelSelected1){
            this.state.classificationModelSelected1.map((item,index)=>{
              classificationModelSelectedArray1.push(item.label)
            })
            classificationModelPipelines1 = classificationModelSelectedArray1.join("|");
          }


          // Classification module 3 model type 
          let classificationModelSelectedArray2 = [];
          let classificationModelPipelines2 = "";
          if(this.state.classificationModelSelected2){
            this.state.classificationModelSelected2.map((item,index)=>{
              classificationModelSelectedArray2.push(item.label)
            })
            classificationModelPipelines2 = classificationModelSelectedArray2.join("|");
          }



          // construct anamoly data object
        let finalAnamolyData = [];
        if (this.state.showAnamolyTemplate) {
          if (this.state.anomolyData.length > 0 && Object.keys(this.state.anomolyData)) {
            finalAnamolyData = this.state.anomolyData.map((item, index) => ({
              ...item,
              SrNo: index + 1,
              SOrd: 1
            }));
          }
        }


        // construct others data object
        let finalOthersData = [];
        if (this.state.showOthersTemplate) {
          if (this.state.othersData.length > 0 && Object.keys(this.state.othersData)) {
            finalOthersData = this.state.othersData.map((item, index) => ({
              ...item,
              SrNo: index + 1,
              SOrd: 2
            }));
          }
        }



        // construct confusion matrix 1 data object
        let finalConfusionMatrix1 = [];
        if (this.state.confusionMatrixData1) {
          finalConfusionMatrix1 = this.state.confusionMatrixData1.map((item, index) => ({
            ...item,
            SrNo: index + 1,
            SOrd: index + 1
          }));
        }

        // construct confusion matrix 2 data object
        //console.log("confusion matrix 2 data at save function", this.state.confusionMatrixData2)
        let finalConfusionMatrix2 = [];
        if (this.state.confusionMatrixData2) {
          finalConfusionMatrix2 = this.state.confusionMatrixData2.map((item, index) => ({
            ...item,
            SrNo: index + 1,
            SOrd: index + 1
          }));
        }
        //console.log("final confusion matrix at save function", finalConfusionMatrix2)

        // construct confusion matrix 3 data object
        let finalConfusionMatrix3 = [];
        if (this.state.confusionMatrixData3) {
          finalConfusionMatrix3 = this.state.confusionMatrixData3.map((item, index) => ({
            ...item,
            SrNo: index + 1,
            SOrd: index + 1
          }));
        }
        //console.log("final confusion matrix 3 at save function", finalConfusionMatrix3)




        // construct regression final data
        let forecastPostData = [];
        if(this.state.showForecastTemplate){
          this.state.forecastData.map((item, index) => {
            let localData = [...this.state.forecastData];
            if (item.Role) {
              localData[index].Role = localData[index].Role.label;
            }
            if(item.Type){
              localData[index].Type = localData[index].Type.label;
            }
            forecastPostData = localData;
          });
        }
        

        // construct forecast other fields data here
        let varforecastOtherFields = {};
        if (this.state.showForecastTemplate) {
          varforecastOtherFields = {
            Label1: "Mean Absolute Error (MAE)",
            Value1: this.state.forecastMeanAbsoluteError,

            Label2: "Mean Squared Error (MSE)",
            Value2: this.state.forecastMeanSquaredError,

            Label3: "Root Mean Absolute Error (RMSE)",
            Value3: this.state.forecastRootMeanSquaredError,

            Label4: "Mean Absolute Percentage Error (MAPE)",
            Value4: this.state.forecastMeanAbsolutePercentageError,

            Value5: this.state.forecastMetricesOneInput ,
            Label5: this.state.forecastMetricesOneLabel,

            Value6: this.state.forecastMetricesTwoInput,
            Label6: this.state.forecastMetricesTwoLabel,

            Value7: this.state.forecastMetricesThreeInput,
            Label7: this.state.forecastMetricesThreeLabel,

            Value8: this.state.forecastMetricesFourInput,
            Label8: this.state.forecastMetricesFourLabel,

            
          };

        }


        

        // construct regression final data
        let regressionPostData = [];
        if(this.state.showRegressionTemplate){
          this.state.regressionData.map((item, index) => {
            let localData = [...this.state.regressionData];
            if (item.Role) {
              localData[index].Role = localData[index].Role.label;
            }
            if (item.Type) {
              localData[index].Type = localData[index].Type.label;
            }
            regressionPostData = localData;
          });
        }
        console.log("rg data =>",this.state.regressionData)
        console.log("regression post data=>",regressionPostData)

        // construct regression Other fields data here

        let varRegressionOtherFields = {};
        if (this.state.showRegressionTemplate) {
          varRegressionOtherFields = {
            Label1: "Mean Absolute Error (MAE)",
            Value1: this.state.regressionMeanAbsoluteError,

            Label2: "Mean Squared Error (MSE)",
            Value2: this.state.regressionMeanSquaredError,

            Label3: "Root Mean Absolute Error (RMSE)",
            Value3: this.state.regressionRootMeanSquaredError,

            Label4: "Mean Absolute Percentage Error (MAPE)",
            Value4: this.state.regressionMeanAbsolutePercentageError,

            Value5: this.state.regressionMetricesOneInput,
            Label5: this.state.regressionMetricesOneLabel,

            Value6: this.state.regressionMetricesTwoInput,
            Label6: this.state.regressionMetricesTwoLabel,

            Value7: this.state.regressionMetricesThreeInput,
            Label7: this.state.regressionMetricesThreeLabel,

            Value8: this.state.regressionMetricesFourInput,
            Label8: this.state.regressionMetricesFourLabel,
          };

        }


        // construct Templates selected Pipeline
        let myArr = [];
        if (this.state.showClassificationTemplate) {
          myArr.push("CL");
        }
        if (this.state.showRegressionTemplate) {
          myArr.push("RG");
        }
        if (this.state.showForecastTemplate) {
          myArr.push("FO");
        }
        if (this.state.showAnamolyTemplate) {
          myArr.push("AN");
        }
        if (this.state.showOthersTemplate) {
          myArr.push("OT");
        }
        if (this.state.showSentimentTemplate) {
          myArr.push("SA");
        }
        let joinedStringForPipeline = "";
        if (myArr) {
          joinedStringForPipeline = myArr.join("|");
        }

        // construct Classification 1 module object
        let classificationPostData = [];
        this.state.classificationModule.map((item, index) => {
          if (item.Role) {
            let localData = [...this.state.classificationModule];
            localData[index].Role = localData[index].Role.label;
            localData[index].Type = localData[index].Type.label;
            classificationPostData = localData;
          }
        });

        // construct Classification 2 module object
        let classificationPostData1 = [];
        this.state.classificationModule1.map((item, index) => {
          if (item.Role) {
            let localData = [...this.state.classificationModule1];
            localData[index].Role = localData[index].Role.label;
            localData[index].Type = localData[index].Type.label;
            classificationPostData1 = localData;
          }
        });
        // construct Classification 3 module object
        let classificationPostData2 = [];
        this.state.classificationModule2.map((item, index) => {
          if (item.Role) {
            let localData = [...this.state.classificationModule2];
            localData[index].Role = localData[index].Role.label;
            localData[index].Type = localData[index].Type.label;
            classificationPostData2 = localData;
          }
        });


          let param = {
            TemplateType: "updatePredictiveTemplate",

            ProjectID: this.state.LeanID ? this.state.LeanID : "",
            ModelType: joinedStringForPipeline,

            ModelName1: classificationModelPipelines,
            ModelName2: classificationModelPipelines1,
            ModelName3: classificationModelPipelines2,

            // classification modules
            Classification1: classificationPostData,
            Classification2: classificationPostData1,
            Classification3: classificationPostData2,


            // confusion matrix
            ConfusionMatrix1: finalConfusionMatrix1,
            ConfusionMatrix2: finalConfusionMatrix2,
            ConfusionMatrix3: finalConfusionMatrix3,

            //Regression
            Regression: regressionPostData,
            RegressionOtherFields: varRegressionOtherFields,

            // Forecast
            Forecast: forecastPostData,
            ForecastOtherFileds: varforecastOtherFields,

            // Anamoly
            Anomoly: finalAnamolyData ? finalAnamolyData : [],

            // Others
            Others: finalOthersData ? finalOthersData : [],

            // Sentiment Analysis
            Value1: this.state.sentimentValues
              ? this.state.sentimentValues.goodToHave
              : "",
            Value2: this.state.sentimentValues
              ? this.state.sentimentValues.mixedSentiments
              : "",
            Value3: this.state.sentimentValues
              ? this.state.sentimentValues.noChecksNeeded
              : "",
            Value4: this.state.sentimentValues
              ? this.state.sentimentValues.priorityChecks
              : "",
            Value5: this.state.sentimentValues
              ? this.state.sentimentValues.sentimentDuplicates
              : "",
          };
          console.log(param);
          let finalJsonData = Checksum(param);
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param),
          };
          fetch(`${TEMPLATE_UPDATE}Checksum=${finalJsonData}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              Toast.hide();
              Toast.success("Template saved successfully!", 3000, () => {
                window.history.go(-1);
              });
            })
            .catch((error) => {
              console.log(error)
            })
        }
        else {
          // Toast.fail("Required fields are missing or have invalid values. Please correct and try again.", 3000, () => { });
        }
      }
    } else {
      Toast.fail("Only Project owner and Project Mentor can save template.", 3000, () => { })
      return;
    }
  };
  templateGoBack = () => {
    window.history.go(-1);
  };
  cancelEvent = () => {
    if (window.confirm("Are you sure you want to close this template ?")) {
      window.history.go(-1);
    }
  }

  // classification module 0 function starts here

  handleModelSelectChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.classificationModelSelected = selectStyle(true);
    this.setState({ classificationModelSelected: e, selectStyle: styleStateObj}, () => {});
  };

  openClassificationModuleModal = () => {
    if (this.state.classificationModule.length < 30) {
      this.setState({ showClassificationModule: true });
    } else {
      alert("You can add maximum of 30 rows.");
      return;
    }
    
  };

  closeClassificationModuleModal = () => {
    this.setState({
      showClassificationModule: false,
      currentClassificationModule: {
        Feature: "",
        Type: "",
        Role: [],
        Score: "",
      },
      editingIndex: null,
    });
    let styleStateObj = this.state.selectStyle;
    styleStateObj.currentClassificationModule.Feature = textBoxValidation(true);
    styleStateObj.currentClassificationModule.Score = textBoxValidation(true);
    styleStateObj.currentClassificationModule.Type = selectStyle(true);
    styleStateObj.currentClassificationModule.Role = selectStyle(true);
    this.setState({selectStyle: styleStateObj})
  };

  validateClassificationModal = ()=>{
    let styleStateObj = this.state.selectStyle;
    const { currentClassificationModule, editingIndex, classificationModule } = this.state;
    let isValid = true;
    if(currentClassificationModule.Score > 100){
      alert("Importance score cannot be greater than 100. Please recheck and submit");
      isValid=false;
    }
    if (
      currentClassificationModule.Feature.trim() === "" ||
      currentClassificationModule.Type.length === 0 ||
      currentClassificationModule.Role.length === 0 ||
      currentClassificationModule.Score === ""
    ) {
      alert("Please fill in all fields before saving.");
      if(currentClassificationModule.Feature === "" ||  currentClassificationModule.Feature.length === 0 ){
        console.log("styleobj feature =>",styleStateObj.currentClassificationModule)
        styleStateObj.currentClassificationModule.Feature = textBoxValidation(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
      if(currentClassificationModule.Type === undefined || currentClassificationModule.Type === null ||  currentClassificationModule.Type.length === 0 ){
        styleStateObj.currentClassificationModule.Type = selectStyle(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
      if(currentClassificationModule.Role === undefined || currentClassificationModule.Role === null ||  currentClassificationModule.Role.length === 0 ){
        styleStateObj.currentClassificationModule.Role = selectStyle(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
      if(currentClassificationModule.Score === "" || currentClassificationModule.Score === null ||  currentClassificationModule.Score === undefined ){
        styleStateObj.currentClassificationModule.Score = textBoxValidation(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
    }

    if(currentClassificationModule.Feature){
      if (currentClassificationModule.Feature.length > 30) {
        Toast.fail("Classification Module 1 Feature field characters cannot be more than 30 characaters. Please recheck and submit",
          3000,
          () => { }
        );
        styleStateObj.currentClassificationModule.Feature = textBoxValidation(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
    }
    return isValid;
  }

  addClassificationModuleRow = () => {
    if(this.validateClassificationModal()){
      const { currentClassificationModule, editingIndex, classificationModule } = this.state;
      if (editingIndex !== null) {
        // Editing existing row
        const updateddata = [...classificationModule];
        updateddata[editingIndex] = currentClassificationModule;
        this.setState(
          {
            classificationModule: updateddata,
            editingIndex: null,
          },
          () => { }
        );
      } else {
        if (classificationModule.length < 30) {
          const newData = {
            ...currentClassificationModule,
            SOrd: classificationModule.length,
            SrNo: classificationModule.length,
          };
          this.setState((prevState) => ({
            classificationModule: [...prevState.classificationModule, newData],
          }));
        } else {
          alert("You can add maximum of 30 rows.");
          return;
        }
      }
      this.closeClassificationModuleModal();
    }
  };

  editClassificationModule = (index) => {
    const { classificationModule } = this.state;
    const employeeToEdit = classificationModule[index];
    this.setState({
      showClassificationModule: true,
      currentClassificationModule: { ...employeeToEdit },
      editingIndex: index,
    });
  };

  deleteClassificationModuleRow = (index) => {
    if (window.confirm("Are you sure you want to delete this row ?")) {
      const { classificationModule } = this.state;
      const updateddata = [...classificationModule];
      updateddata.splice(index, 1);
      this.setState({
        classificationModule: updateddata,
      });
    }
  };

  handleClassificationModuleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "Score") {
      const scoreValue = e.target.value;
      const regex = /^[0-9]{0,3}(\.[0-9]{0,2})?$/;
      if (regex.test(scoreValue)) {
      } else {
        window.alert(
          "Importance score should be numeric and cannot be greater than 100 and only two decimal values are allowed."
        );
        return;
      }
      
    }
    let styleStateObj = this.state.selectStyle;
    if(name === "Feature"){
      let nonWhiteSpace= value.replace(/^\s+|\s+$/gm,'');
      if(nonWhiteSpace.length < 30){
        this.setState((prevState) => ({
          currentClassificationModule: {
            ...prevState.currentClassificationModule,
            [name]: value,
          },
        }));
      }else{
        // window.alert("Classification Module 1 Feature cannot be more than 30 characters.");
        const trimmedValue = nonWhiteSpace.slice(0, 30);
        this.setState((prevState) => ({
          currentClassificationModule: {
            ...prevState.currentClassificationModule,
            [name]: trimmedValue,
          },
        }));
      }
      styleStateObj.currentClassificationModule.Feature = textBoxValidation(true);
      this.setState({selectStyle: styleStateObj})
    }
    if(name === "Score"){
      this.setState((prevState) => ({
        currentClassificationModule: {
          ...prevState.currentClassificationModule,
          [name]: value,
        },
      }));
      styleStateObj.currentClassificationModule.Score = textBoxValidation(true);
      this.setState({selectStyle: styleStateObj})
    }

  };

  handleClassificationModuleSelectChange = (
    e,
    currentClassificationModule,
    fieldName
  ) => {
    
    let styleStateObj = this.state.selectStyle;
    if (fieldName === "role") {
      currentClassificationModule.Score="";
      if(e && e.label === "Target"){
        let setScorevalue = 0;
        let localData = currentClassificationModule;
        localData.Score = setScorevalue;
        this.setState({currentClassificationModule: localData});
      }
      let localRole = currentClassificationModule;
      localRole.Role = e;
      styleStateObj.currentClassificationModule.Role = selectStyle(true);
      this.setState({ currentClassificationModule: localRole,selectStyle: styleStateObj }, () => { });
    }
    if (fieldName === "type") {
      let localType = currentClassificationModule;
      localType.Type = e;
      styleStateObj.currentClassificationModule.Type = selectStyle(true);
      this.setState({ currentClassificationModule: localType,selectStyle: styleStateObj }, () => {
        console.log(this.state.currentClassificationModule);
      });
    }


    


  };
  renderClassificationModuleRole = () => {
    let options = [];
    this.state.roles.map((item) => {
      options.push({
        value: item.id,
        label: item.value,
      });
      return true;
    });
    return options;
  };

  // classification module 0  template function ends here

  // classification module 1 function starts here
  handleModelSelectChange1 = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.classificationModelSelected1 = selectStyle(true);
    this.setState({ classificationModelSelected1: e, selectStyle: styleStateObj }, () => {});
  };

  handleClassificationModuleIncrement = () => {
    this.setState({ showClassificationTemplate1: true }, () => { });
  };

  openClassificationModuleModal1 = () => {
    if (this.state.classificationModule1.length < 30) {
      this.setState({ showClassificationModule1: true });
    } else {
      alert("You can add maximum of 30 rows.");
      return;
    }
  };

  closeClassificationModuleModal1 = () => {
    this.setState({
      showClassificationModule1: false,
      currentClassificationModule1: {
        Feature: "",
        Type: "",
        Role: [],
        Score: "",
      },
      editingIndex: null,
    });
    let styleStateObj = this.state.selectStyle;
    styleStateObj.currentClassificationModule1.Feature = textBoxValidation(true);
    styleStateObj.currentClassificationModule1.Type = selectStyle(true);
    styleStateObj.currentClassificationModule1.Role = selectStyle(true);
    styleStateObj.currentClassificationModule1.Score = textBoxValidation(true);
    this.setState({selectStyle: styleStateObj})
  };

  validateClassificationModal1=()=>{
    let isValid = true;
    let styleStateObj = this.state.selectStyle;
    const {
      currentClassificationModule1,
      editingIndex,
      classificationModule1,
    } = this.state;

    if(currentClassificationModule1.Score > 100){
      alert("Importance score cannot be more than 100. Please recheck and resubmit");
      return false;
    }

    if (
      currentClassificationModule1.Feature.trim() === "" ||
      currentClassificationModule1.Type.length === 0 ||
      currentClassificationModule1.Role.length === 0 ||
      currentClassificationModule1.Score === ""
    ) {
      alert("Please fill in all fields before saving.");
      if(currentClassificationModule1.Feature === "" ||  currentClassificationModule1.Feature.length === 0 ){
        styleStateObj.currentClassificationModule1.Feature = textBoxValidation(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
      if(currentClassificationModule1.Type === undefined || currentClassificationModule1.Type === null ||  currentClassificationModule1.Type.length === 0 ){
        styleStateObj.currentClassificationModule1.Type = selectStyle(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
      if(currentClassificationModule1.Role === undefined || currentClassificationModule1.Role === null ||  currentClassificationModule1.Role.length === 0 ){
        styleStateObj.currentClassificationModule1.Role = selectStyle(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
      if(currentClassificationModule1.Score === "" || currentClassificationModule1.Score === null ||  currentClassificationModule1.Score === undefined ){
        styleStateObj.currentClassificationModule1.Score = textBoxValidation(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
    }
    if(currentClassificationModule1.Feature){
      if (currentClassificationModule1.Feature.length > 30) {
        Toast.fail(
          "Classification Module 2 Feature field characters cannot be more than 10 characaters. Please recheck and submit",
          3000,
          () => { }
        );
        isValid=false;
        styleStateObj.currentClassificationModule1.Feature = textBoxValidation(false);
        this.setState({selectStyle: styleStateObj})
      }
    }
    return isValid;
  }

  addClassificationModuleRow1 = () => {
    if(this.validateClassificationModal1()){
      const {
        currentClassificationModule1,
        editingIndex,
        classificationModule1,
      } = this.state;
      
      
      if (editingIndex !== null) {
        // Editing existing row
        const updateddata = [...classificationModule1];
        updateddata[editingIndex] = currentClassificationModule1;
        this.setState(
          {
            classificationModule1: updateddata,
            editingIndex: null,
          },
          () => { }
        );
      } else {
        if (classificationModule1.length < 30) {
          const newData = {
            ...currentClassificationModule1,
            SOrd: classificationModule1.length,
            SrNo: classificationModule1.length,
          };
          this.setState((prevState) => ({
            classificationModule1: [...prevState.classificationModule1, newData],
          }));
        } else {
          alert("You can add maximum of 30 rows.");
          return;
        }
      }
      
      this.closeClassificationModuleModal1();
    }
    

    
  };

  editClassificationModule1 = (index) => {
    const { classificationModule1 } = this.state;
    const employeeToEdit = classificationModule1[index];
    this.setState({
      showClassificationModule1: true,
      currentClassificationModule1: { ...employeeToEdit },
      editingIndex: index,
    });
  };

  deleteClassificationModuleRow1 = (index) => {
    if (window.confirm("Are you sure you want to delete this row ?")) {
      const { classificationModule1 } = this.state;
      const updateddata = [...classificationModule1];
      updateddata.splice(index, 1);
      this.setState({
        classificationModule1: updateddata,
      });
    }
  };

  handleClassificationModuleInputChange1 = (e) => {
    const { name, value } = e.target;
    if (name === "Score") {
      const scoreValue = e.target.value;
      const regex = /^[0-9]{0,3}(\.[0-9]{0,2})?$/;
      if (regex.test(scoreValue)) {
      } else {
        window.alert(
          "Importance score should be numeric and cannot be greater than 100 and only two decimal values are allowed."
        );
        return;
      }
    }
    

    let styleStateObj = this.state.selectStyle;
    if(name === "Feature"){
      let nonWhiteSpace= value.replace(/^\s+|\s+$/gm,'');;
      if(nonWhiteSpace.length < 30){
        this.setState((prevState) => ({
          currentClassificationModule1: {
            ...prevState.currentClassificationModule1,
            [name]: value,
          },
        }));
      }else{
        // window.alert("Classification module 2 Feature cannot be more than 30 characters.");
        const trimmedValue = nonWhiteSpace.slice(0, 30);
        this.setState((prevState) => ({
          currentClassificationModule1: {
            ...prevState.currentClassificationModule1,
            [name]: trimmedValue,
          },
        }));
      }
      styleStateObj.currentClassificationModule1.Feature = textBoxValidation(true);
      this.setState({selectStyle: styleStateObj})
    }
    if(name === "Score"){
      this.setState((prevState) => ({
        currentClassificationModule1: {
          ...prevState.currentClassificationModule1,
          [name]: value,
        },
      }));
      styleStateObj.currentClassificationModule1.Score = textBoxValidation(true);
      this.setState({selectStyle: styleStateObj})
    }
  };

  handleClassificationModuleSelectChange1 = (
    e,
    currentClassificationModule1,
    fieldName
  ) => {
    let styleStateObj = this.state.selectStyle;
    if (fieldName === "role") {
      currentClassificationModule1.Score="";
      if(e && e.label === "Target"){
        let setScorevalue = 0;
        let localData = currentClassificationModule1;
        localData.Score = setScorevalue;
        this.setState({currentClassificationModule1: localData});
      }
      let localRole = currentClassificationModule1;
      localRole.Role = e;
      this.setState({ currentClassificationModule1: localRole }, () => { });
      styleStateObj.currentClassificationModule1.Role = selectStyle(true);
      this.setState({selectStyle: styleStateObj})
    }
    if (fieldName === "type") {
      let localType = currentClassificationModule1;
      localType.Type = e;
      styleStateObj.currentClassificationModule1.Type = selectStyle(true);
      this.setState({selectStyle: styleStateObj})
      this.setState({ currentClassificationModule1: localType }, () => { });
    }
  };
  renderClassificationModuleRole1 = () => {
    let options = [];
    this.state.roles.map((item) => {
      options.push({
        value: item.id,
        label: item.value,
      });
      return true;
    });
    return options;
  };

  // classification module 1  template function ends here

  // classification module 2 function starts here
  handleModelSelectChange2 = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.classificationModelSelected2 = selectStyle(true);
    this.setState({ classificationModelSelected2: e, selectStyle: styleStateObj }, () => {
    });
  };
  handleClassificationModuleIncrement1 = () => {
    this.setState({ showClassificationTemplate2: true }, () => { });
  };

  openClassificationModuleModal2 = () => {
    if (this.state.classificationModule2.length < 30) {
      this.setState({ showClassificationModule2: true });
    } else {
      alert("You can add maximum of 30 rows.");
      return;
    }
  };

  closeClassificationModuleModal2 = () => {
    this.setState({
      showClassificationModule2: false,
      currentClassificationModule2: {
        Feature: "",
        Type: "",
        Role: [],
        Score: "",
      },
      editingIndex: null,
    });
    let styleStateObj = this.state.selectStyle;
    styleStateObj.currentClassificationModule2.Feature = textBoxValidation(true);
    styleStateObj.currentClassificationModule2.Type = selectStyle(true);
    styleStateObj.currentClassificationModule2.Role = selectStyle(true);
    styleStateObj.currentClassificationModule2.Score = textBoxValidation(true);
    this.setState({selectStyle: styleStateObj})
  };

  validateClassificationModal2=()=>{
    let isValid = true;
    let styleStateObj = this.state.selectStyle;
    const {
      currentClassificationModule2,
      editingIndex,
      classificationModule2,
    } = this.state;

    if(currentClassificationModule2.Score > 100){
      alert("Importance score cannot be greater than 100. Please recheck and submit");
    }

    if (
      currentClassificationModule2.Feature.trim() === "" ||
      currentClassificationModule2.Type.length === 0 ||
      currentClassificationModule2.Role.length === 0 ||
      currentClassificationModule2.Score === ""
    ) {
      alert("Please fill in all fields before saving.");
      if(currentClassificationModule2.Feature === "" ||  currentClassificationModule2.Feature.length === 0 ){
        styleStateObj.currentClassificationModule2.Feature = textBoxValidation(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
      if(currentClassificationModule2.Type === undefined || currentClassificationModule2.Type === null ||  currentClassificationModule2.Type.length === 0 ){
        styleStateObj.currentClassificationModule2.Type = selectStyle(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
      if(currentClassificationModule2.Role === undefined || currentClassificationModule2.Role === null ||  currentClassificationModule2.Role.length === 0 ){
        styleStateObj.currentClassificationModule2.Role = selectStyle(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
      if(currentClassificationModule2.Score === "" || currentClassificationModule2.Score === null ||  currentClassificationModule2.Score === undefined ){
        styleStateObj.currentClassificationModule2.Score = textBoxValidation(false);
        isValid=false;
        this.setState({selectStyle: styleStateObj})
      }
    }


    if(currentClassificationModule2.Feature){
      if (currentClassificationModule2.Feature.length > 30) {
        Toast.fail(
          "Classification Module 3 Feature field characters cannot be more than 10 characaters. Please recheck and submit",
          3000,
          () => { }
          
        );
        isValid=false;
        styleStateObj.currentClassificationModule2.Feature = textBoxValidation(false);
        this.setState({selectStyle: styleStateObj})
        return;
      }
    }
    

    return isValid;
  }

  addClassificationModuleRow2 = () => {
    if(this.validateClassificationModal2()){
      const {
        currentClassificationModule2,
        editingIndex,
        classificationModule2,
      } = this.state;
      if (editingIndex !== null) {
        // Editing existing row
        const updateddata = [...classificationModule2];
        updateddata[editingIndex] = currentClassificationModule2;
        this.setState(
          {
            classificationModule2: updateddata,
            editingIndex: null,
          },
          () => { }
        );
      } else {
        if (classificationModule2.length < 30) {
          const newData = {
            ...currentClassificationModule2,
            SOrd: classificationModule2.length,
            SrNo: classificationModule2.length,
          };
          this.setState((prevState) => ({
            classificationModule2: [...prevState.classificationModule2, newData],
          }));
        } else {
          alert("You can add maximum of 30 rows.");
          return;
        }
      }
      this.closeClassificationModuleModal2();

    }
    

    
  };

  editClassificationModule2 = (index) => {
    const { classificationModule2 } = this.state;
    const abc = classificationModule2[index];
    this.setState({
      showClassificationModule2: true,
      currentClassificationModule2: { ...abc },
      editingIndex: index,
    });
  };

  deleteClassificationModuleRow2 = (index) => {
    if (window.confirm("Are you sure you want to delete this row ?")) {
      const { classificationModule2 } = this.state;
      const updateddata = [...classificationModule2];
      updateddata.splice(index, 1);
      this.setState({
        classificationModule2: updateddata,
      });
    }
  };

  ModuleInputChange2 = (e) => {
    const { name, value } = e.target;
    if (name === "Score") {
      const scoreValue = e.target.value;
      const regex = /^[0-9]{0,5}(\.[0-9]{0,2})?$/;
      if (regex.test(scoreValue)) {
      } else {
        window.alert(
          "Importance score should be numeric and cannot be greater than 100 and only two decimal values are allowed."
        );
        return;
      }
    }
    let styleStateObj = this.state.selectStyle;
    if(name === "Feature"){
      let nonWhiteSpace= value.replace(/^\s+|\s+$/gm,'');
      if(nonWhiteSpace.length<30){
        this.setState((prevState) => ({
          currentClassificationModule2: {
            ...prevState.currentClassificationModule2,
            [name]: value,
          },
        }));
      }else{
        // window.alert("Classification module 3 Feature cannot be more than 30 characters.");
        const trimmedValue = nonWhiteSpace.slice(0, 30);
        this.setState((prevState) => ({
          currentClassificationModule2: {
            ...prevState.currentClassificationModule2,
            [name]: trimmedValue,
          },
        }));
      }
      
      styleStateObj.currentClassificationModule2.Feature = textBoxValidation(true);
      this.setState({selectStyle: styleStateObj})
    }
    if(name === "Score"){
      this.setState((prevState) => ({
        currentClassificationModule2: {
          ...prevState.currentClassificationModule2,
          [name]: value,
        },
      }));
      styleStateObj.currentClassificationModule2.Score = textBoxValidation(true);
      this.setState({selectStyle: styleStateObj})
    }

    //console.log("currentClassificationModule2 at input change function",this.state.currentClassificationModule2)
  };

  handleClassificationModuleSelectChange2 = (
    e,
    currentClassificationModule2,
    fieldName
  ) => {
    let styleStateObj = this.state.selectStyle;
    if (fieldName === "role") {
      currentClassificationModule2.Score="";
      if(e && e.label === "Target"){
        let setScorevalue = 0;
        let localData = currentClassificationModule2;
        localData.Score = setScorevalue;
        this.setState({currentClassificationModule2: localData});
      }
      let localRole = currentClassificationModule2;
      localRole.Role = e;
      styleStateObj.currentClassificationModule2.Role = selectStyle(true);
      this.setState({ currentClassificationModule2: localRole ,selectStyle: styleStateObj}, () => {
        console.log(this.state.currentClassificationModule2);
      });
    }
    if (fieldName === "type") {
      let localType = currentClassificationModule2;
      localType.Type = e;
      styleStateObj.currentClassificationModule2.Type = selectStyle(true);
      this.setState({ currentClassificationModule2: localType,selectStyle: styleStateObj }, () => {
        console.log(this.state.currentClassificationModule2);
      });
    }
  };
  renderClassificationModuleRole1 = () => {
    let options = [];
    this.state.roles.map((item) => {
      options.push({
        value: item.id,
        label: item.value,
      });
      return true;
    });
    return options;
  };

  // classification module 2 template function ends here


  // confusion matrix 1 function starts here
  handleConfusionMatrixSelectedOption = (e) => {
    this.setState({ 
      confusionMatrixData1: [], 
      confusionMatrixPrecisionResults1: {},
      confusionMatrixRecallResults1: {},
      overAllAccuray: "" 
    }, () => { 
      this.setState({confusionMatrixTableSelectedOption1:e},()=>{})
      let confusionMatrixData1 = this.state.confusionMatrixData1;
      for (let i = 0; i < e.value; i++) {
        let rowData = {
          Label: "",
          SrNo: (i + 1).toString()
        };
        for (let j = 0; j < e.value; j++) {
          rowData[`Value${j + 1}`] = "";
        }
        confusionMatrixData1.push(rowData);
      }
      this.setState({ confusionMatrixData1 }, () => {});
    });
  };
  
  handleConfusionMatrixTableLabelChange = (index, e) => {
    if(e.length < 51){
      let confusionMatrixData1 = [...this.state.confusionMatrixData1];
      confusionMatrixData1[index].Label = e;
      this.setState({ confusionMatrixData1 });
    }else{
      window.alert("You can add upto 50 characters of the label value");
      return;
    }
   
  };

  handleConfusionMatrixTableInputCellChange = (rowIndex, headerIndex, cellValue) => {
    let confusionMatrixData1 = [...this.state.confusionMatrixData1];
    confusionMatrixData1[rowIndex][`Value${headerIndex + 1}`] = cellValue;
    this.setState({ confusionMatrixData1 }, () => {
      this.confusionMatrixprecision();
      this.calculateRecall1()
      this.calculateOverallAccuracy();
    });
  };

  handleConfusionMatrixGetCellData = (rowIndex, headerIndex) => {
    return this.state.confusionMatrixData1[rowIndex][`Value${headerIndex + 1}`] || "";
  };


  getRecallValues1 = (index) => {
    if(this.state.confusionMatrixRecallResults1){
      const key = `recall${index + 1}`;
      if(this.state.confusionMatrixRecallResults1[key]){
        return this.state.confusionMatrixRecallResults1[key] + " %";
      }
    }
  };

  confusionMatrixprecision = () => {
    const { confusionMatrixData1 } = this.state;

    if (confusionMatrixData1) {
      let resultForSrNo1 = 0;
      let resultForSrNo2 = 0;
      let resultForSrNo3 = 0;
      let resultForSrNo4 = 0;
      let resultForSrNo5 = 0;

      let localData = confusionMatrixData1.map((item, index) => ({
        ...item,
        SrNo: index + 1,
      }));
      localData.map((item, index) => {
        if (item.Value1) {
          const sumOfTxt1 = localData.reduce((sum, row) => sum + Number(row.Value1), 0);
          resultForSrNo1 =  Number(localData.find((row) => row.SrNo === 1).Value1)/sumOfTxt1;
          resultForSrNo1  = resultForSrNo1*100;
          resultForSrNo1 = Math.round(resultForSrNo1);
          resultForSrNo1 = Math.trunc(resultForSrNo1) 
        }
        if (item.Value2) {
          const sumOfTxt2 = localData.reduce((sum, row) => sum + Number(row.Value2), 0);
          resultForSrNo2 =  Number(localData.find((row) => row.SrNo === 2).Value2) / sumOfTxt2;
          resultForSrNo2  = resultForSrNo2*100;
          resultForSrNo2 = Math.round(resultForSrNo2)
          resultForSrNo2 = Math.trunc(resultForSrNo2) 
        }
        if (item.Value3) {
          const sumOfTxt3 = localData.reduce((sum, row) => sum + Number(row.Value3), 0);
          resultForSrNo3 =  Number(localData.find((row) => row.SrNo === 3).Value3) / sumOfTxt3;
          resultForSrNo3  = resultForSrNo3*100;
          resultForSrNo3 = Math.round(resultForSrNo3)
          resultForSrNo3 = Math.trunc(resultForSrNo3)
        }
        if (item.Value4) {
          const sumOfTxt4 = localData.reduce((sum, row) => sum + Number(row.Value4), 0);
          resultForSrNo4 = Number(localData.find((row) => row.SrNo === 4).Value4)/sumOfTxt4;
          resultForSrNo4  = resultForSrNo4*100;
          resultForSrNo4 = Math.round(resultForSrNo4)
          resultForSrNo4 = Math.trunc(resultForSrNo4)
        }
        if (item.Value5) {
          const sumOfTxt5 = localData.reduce((sum, row) => sum + Number(row.Value5), 0);
          resultForSrNo5 = Number(localData.find((row) => row.SrNo === 5).Value5)/sumOfTxt5;
          resultForSrNo5  = resultForSrNo5*100;
          resultForSrNo5 = Math.round(resultForSrNo5)
          resultForSrNo5 = Math.trunc(resultForSrNo5)
        }
      });
      this.setState(
        {
          confusionMatrixPrecisionResults1: {
            resultForSrNo1,
            resultForSrNo2,
            resultForSrNo3,
            resultForSrNo4,
            resultForSrNo5
          },
        },
        () => {
          //console.log("calculate precision =>", this.state.confusionMatrixPrecisionResults1);
        }
      );
    }
  };

  calculateRecall1 = () => {
    const { confusionMatrixData1 } = this.state;
    if (confusionMatrixData1) {
      let recall1 = 0;
      let recall2 = 0;
      let recall3 = 0;
      let recall4 = 0;
      let recall5 = 0;
      let localData = confusionMatrixData1.map((item, index) => ({
        ...item,
        SrNo: index + 1,
      }));
      const sumOfRows = localData.map(row => Object.entries(row).filter(([key, value]) => key.startsWith('Value') && !isNaN(value)).map(([key, value]) => Number(value)).reduce((acc, val) => acc + val, 0));
      localData.map((item, index) => {
        if (item.Value1) {
          recall1 = Number(localData.find((row) => row.SrNo === 1).Value1) / sumOfRows[0];
          recall1 = recall1*100;
          recall1 = Math.round(recall1);
          recall1 = Math.trunc(recall1);
          
        }
        if (item.Value2) {
          recall2 = Number(localData.find((row) => row.SrNo === 2).Value2) / sumOfRows[1];
          recall2 = recall2*100;
          recall2 = Math.round(recall2);
          recall2 = Math.trunc(recall2);
          
        }
        if (item.Value3) {
          recall3 = Number(localData.find((row) => row.SrNo === 3).Value3) / sumOfRows[2];
          recall3 = recall3*100;
          recall3 = Math.round(recall3);
          recall3 = Math.trunc(recall3);
          
        }
        if (item.Value4) {
          recall4 = Number(localData.find((row) => row.SrNo === 4).Value4) / sumOfRows[3];
          recall4 = recall4*100;
          recall4 = Math.round(recall4);
          recall4 = Math.trunc(recall4);
         
        }

        if (item.Value5) {
          recall5 = Number(localData.find((row) => row.SrNo === 5).Value5) / sumOfRows[4];
          recall5 = recall5*100;
          recall5 = Math.round(recall5);
          recall5 = Math.trunc(recall5);
        }
      });
      this.setState(
        {
          confusionMatrixRecallResults1: {
            recall1,
            recall2,
            recall3,
            recall4,
            recall5
          },
        },
        () => {
          //console.log("calculate recall =>", this.state.confusionMatrixRecallResults1);
        }
      );
    }
  }


  calculateOverallAccuracy=()=>{
    const { confusionMatrixData1 } = this.state;
    if (confusionMatrixData1){
      let diagnal1 = 0;
      let diagnal2 = 0;
      let diagnal3 = 0;
      let diagnal4 = 0;
      let diagnal5 = 0;
      
      let localData = confusionMatrixData1.map((item, index) => ({
        ...item,
        SrNo: index + 1,
      }));
      localData.map((item, index) => {
        if (item.Value1) {
          diagnal1 = Number(localData.find((row) => row.SrNo === 1).Value1);
        }
        if (item.Value2) {
          diagnal2 = Number(localData.find((row) => row.SrNo === 2).Value2);
        }
        if (item.Value3) {
          diagnal3 = Number(localData.find((row) => row.SrNo === 3).Value3);
        }
        if (item.Value4) {
          diagnal4 = Number(localData.find((row) => row.SrNo === 4).Value4);
        }
        if (item.Value5) {
          diagnal5 = Number(localData.find((row) => row.SrNo === 5).Value5);
        }
      });
      let sumofDiagnols = 0;
      sumofDiagnols = diagnal1+diagnal2+diagnal3+diagnal4+diagnal5;
      const sumOfRows = localData.map(row => Object.entries(row).filter(([key, value]) => key.startsWith('Value') && !isNaN(value)).map(([key, value]) => Number(value)).reduce((acc, val) => acc + val, 0));
      let sum = 0;
      sumOfRows.forEach((el) => sum += el);
      let overAllAccuray = (parseInt(sumofDiagnols) / parseInt(sum)*100);
      overAllAccuray = Math.round(overAllAccuray);
      overAllAccuray = Math.trunc(overAllAccuray);
      this.setState({overAllAccuray:overAllAccuray},()=>{})
    }
  }



  confusionMatrixPrecisionRow1 = () => {
    //console.log("confusionMatrix Precision Results1 => ", this.state.confusionMatrixPrecisionResults1);
    let localData = this.state.confusionMatrixData1.map((item, index) => ({
      ...item,
      SrNo: index + 1,
    }));
    let precisionRow = (
      <tr key="precision" style={{ background: "#15315f" }}>
        <td style={{ textAlign: "left" }}>
          <label style={{ color: "#fff", marginBottom: "0px" }}>Precision</label>
        </td>
        {localData.map((item, index) => (
          <td style={{ textAlign: "left", color: "#fff" }} key={index}>
            <label style={{ color: "#fff", marginBottom: "0px" }}>

              {this.state.confusionMatrixPrecisionResults1 && this.state.confusionMatrixPrecisionResults1[`resultForSrNo${item.SrNo}`]
                ? this.state.confusionMatrixPrecisionResults1[`resultForSrNo${item.SrNo}`] + " %"
                : ""}
            </label>
          </td>
        ))}
        <td style={{ textAlign: "left" }}>
          <label style={{ color: "#fff", marginBottom: "0px" }}></label>
        </td>
      </tr>
    );
    return precisionRow;
  };
  // confusion matrix 1 function ends here





  // confusion matrix 2 function starts here
  handleConfusionMatrixSelectedOption1 = (e) => {
    this.setState({ confusionMatrixData2: [], confusionMatrixPrecisionResults2: {},confusionMatrixRecallResults2:{}, overAllAccuray1:"" }, () => { });
    this.setState({ confusionMatrixTableSelectedOption2: e }, () => {
      let confusionMatrixData2 = this.state.confusionMatrixData2;
      for (let i = 0; i < e.value; i++) {
        let rowData = {
          Label: "",
          SrNo: (i + 1).toString()
        };
        for (let j = 0; j < e.value; j++) {
          rowData[`Value${j + 1}`] = "";
        }
        confusionMatrixData2.push(rowData);
      }
      this.setState({ confusionMatrixData2 }, () => { });
    });
  };

  handleConfusionMatrixTableLabelChange1 = (index, e) => {
    if(e.length < 51){
      let confusionMatrixData2 = [...this.state.confusionMatrixData2];
      confusionMatrixData2[index].Label = e;
      this.setState({ confusionMatrixData2 });
    }else{
      window.alert("You can add upto 50 characters of the label value");
      return;
    }
    
  };
  handleSecondConfusionMatrixTableInputCellChange = (rowIndex, headerIndex, cellValue) => {
    let confusionMatrixData2 = [...this.state.confusionMatrixData2];
    confusionMatrixData2[rowIndex][`Value${headerIndex + 1}`] = cellValue;
    this.setState({ confusionMatrixData2 }, () => {
      console.log("confusion matrix data 2 at input change function", this.state.confusionMatrixData2)
      this.confusionMatrixprecision1();
      this.calculateRecall2();
      this.calculateOverallAccuracy1()
    });

  };

  handleSecondConfusionMatrixGetCellData = (rowIndex, headerIndex) => {
    return this.state.confusionMatrixData2[rowIndex][`Value${headerIndex + 1}`] || "";
  };

  confusionMatrixprecision1 = () => {
    const { confusionMatrixData2 } = this.state;
    if (confusionMatrixData2) {
      let resultForSrNo1 = 0;
      let resultForSrNo2 = 0;
      let resultForSrNo3 = 0;
      let resultForSrNo4 = 0;
      let resultForSrNo5 = 0;

      let localDataforPrecisionRow = confusionMatrixData2.map((item, index) => ({
        ...item,
        SrNo: index + 1,
      }));

      localDataforPrecisionRow.map((item, index) => {
        if (item.Value1) {
          const sumOfTxt1 = localDataforPrecisionRow.reduce((sum, row) => sum + Number(row.Value1), 0);
          resultForSrNo1 =  Number(localDataforPrecisionRow.find((row) => row.SrNo === 1).Value1) /sumOfTxt1;
          resultForSrNo1 = resultForSrNo1*100;
          resultForSrNo1 = Math.round(resultForSrNo1);
          resultForSrNo1 = Math.trunc(resultForSrNo1);
          // resultForSrNo1.toFixed(2);
          
        }
        if (item.Value2) {
          const sumOfTxt2 = localDataforPrecisionRow.reduce((sum, row) => sum + Number(row.Value2), 0);
          resultForSrNo2 = Number(localDataforPrecisionRow.find((row) => row.SrNo === 2).Value2) /sumOfTxt2;
          resultForSrNo2 = resultForSrNo2*100;
          resultForSrNo2 = Math.round(resultForSrNo2)
          resultForSrNo2 = Math.trunc(resultForSrNo2);
          // resultForSrNo2.toFixed(2);
        }
        if (item.Value3) {
          const sumOfTxt3 = localDataforPrecisionRow.reduce((sum, row) => sum + Number(row.Value3), 0);
          resultForSrNo3 = Number(localDataforPrecisionRow.find((row) => row.SrNo === 3).Value3) /sumOfTxt3;
          resultForSrNo3 = resultForSrNo3*100;
          resultForSrNo3 = Math.round(resultForSrNo3)
          resultForSrNo3 = Math.trunc(resultForSrNo3);
          // resultForSrNo3.toFixed(2);
        }
        if (item.Value4) {
          const sumOfTxt4 = localDataforPrecisionRow.reduce((sum, row) => sum + Number(row.Value4), 0);
          resultForSrNo4 = Number(localDataforPrecisionRow.find((row) => row.SrNo === 4).Value4) / sumOfTxt4;
          resultForSrNo4 = resultForSrNo4*100;
          resultForSrNo4 = Math.round(resultForSrNo4)
          resultForSrNo4 = Math.trunc(resultForSrNo4);
          // resultForSrNo4.toFixed(2);
        }

        if (item.Value5) {
          const sumOfTxt5 = localDataforPrecisionRow.reduce((sum, row) => sum + Number(row.Value5), 0);
          resultForSrNo5 = Number(localDataforPrecisionRow.find((row) => row.SrNo === 5).Value5)/sumOfTxt5;
          resultForSrNo5  = resultForSrNo5*100;
          resultForSrNo5 = Math.round(resultForSrNo5)
          resultForSrNo5 = Math.trunc(resultForSrNo5)
        }


      });
      this.setState(
        {
          confusionMatrixPrecisionResults2: {
            resultForSrNo1,
            resultForSrNo2,
            resultForSrNo3,
            resultForSrNo4,
            resultForSrNo5
          },
        },
        () => {
          //console.log(this.state.confusionMatrixPrecisionResults2)
        }
      );

    }


  };

  confusionMatrixPrecisionRow2 = () => {
    let localDataforPrecisionRow = this.state.confusionMatrixData2.map((item, index) => ({
      ...item,
      SrNo: index + 1,
    }));

    let precisionRow = (
      <tr key="precision" style={{ background: "#15315f" }}>
        <td style={{ textAlign: "left" }}>
          <label style={{ color: "#fff", marginBottom: "0px" }}>Precision</label>
        </td>
        {localDataforPrecisionRow.map((item, index) => (
          <td style={{ textAlign: "left", color: "#fff" }} key={index}>
            <label style={{ color: "#fff", marginBottom: "0px" }}>
              {this.state.confusionMatrixPrecisionResults2 && this.state.confusionMatrixPrecisionResults2[`resultForSrNo${item.SrNo}`]
                ? this.state.confusionMatrixPrecisionResults2[`resultForSrNo${item.SrNo}`] + " %"
                : ""}
            </label>
          </td>
        ))}
        <td>
          <label style={{ color: "#fff", marginBottom: "0px" }}></label>
        </td>
      </tr>
    );
    return precisionRow;
  };



  calculateRecall2 = () => {
    const { confusionMatrixData2 } = this.state;
    if (confusionMatrixData2) {
      let recall1 = 0;
      let recall2 = 0;
      let recall3 = 0;
      let recall4 = 0;
      let recall5 = 0;

      let localData = confusionMatrixData2.map((item, index) => ({
        ...item,
        SrNo: index + 1,
      }));
      const sumOfRows = localData.map(row => Object.entries(row).filter(([key, value]) => key.startsWith('Value') && !isNaN(value)).map(([key, value]) => Number(value)).reduce((acc, val) => acc + val, 0));
      localData.map((item, index) => {
        if (item.Value1) {
          recall1 = Number(localData.find((row) => row.SrNo === 1).Value1) / sumOfRows[0];
          recall1 = recall1*100;
          recall1 = Math.round(recall1);
          recall1 = Math.trunc(recall1);

          // recall1 = parseFloat(recall1).toFixed(2);
        }
        if (item.Value2) {
          recall2 = Number(localData.find((row) => row.SrNo === 2).Value2) / sumOfRows[1];
          recall2 = recall2*100;
          recall2 = Math.round(recall2);
          recall2 = Math.trunc(recall2);
          //recall2 = parseFloat(recall2).toFixed(2);
        }
        if (item.Value3) {
          recall3 = Number(localData.find((row) => row.SrNo === 3).Value3) / sumOfRows[2];
          recall3 = recall3*100;
          recall3 = Math.round(recall3);
          recall3 = Math.trunc(recall3);
          //recall3 = parseFloat(recall3).toFixed(2);
        }
        if (item.Value4) {
          recall4 = Number(localData.find((row) => row.SrNo === 4).Value4) / sumOfRows[3];
          recall4 = recall4*100;
          recall4 = Math.round(recall4);
          recall4 = Math.trunc(recall4);
          //recall4 = parseFloat(recall4).toFixed(2);
        }
        if (item.Value5) {
          recall5 = Number(localData.find((row) => row.SrNo === 5).Value5) / sumOfRows[4];
          recall5 = recall5*100;
          recall5 = Math.round(recall5);
          recall5 = Math.trunc(recall5);
          // recall4 = parseFloat(recall4).toFixed(2);
        }
      });
      this.setState(
        {
          confusionMatrixRecallResults2: {
            recall1,
            recall2,
            recall3,
            recall4,
            recall5
          },
        },
        () => {
          //console.log("calculate recall =>", this.state.confusionMatrixRecallResults2);
        }
      );
    }
  }
  getRecallValues2 = (index) => {
    if(this.state.confusionMatrixRecallResults2){
      const key = `recall${index + 1}`;
      if(this.state.confusionMatrixRecallResults2[key]){
        return this.state.confusionMatrixRecallResults2[key] + " %";
      }
      
    }
  };


  calculateOverallAccuracy1=()=>{
    const { confusionMatrixData2 } = this.state;
    if (confusionMatrixData2){
      let diagnal1 = 0;
      let diagnal2 = 0;
      let diagnal3 = 0;
      let diagnal4 = 0;
      let diagnal5 = 0;

      let localData = confusionMatrixData2.map((item, index) => ({
        ...item,
        SrNo: index + 1,
      }));
      localData.map((item, index) => {
        if (item.Value1) {
          diagnal1 = Number(localData.find((row) => row.SrNo === 1).Value1);
        }
        if (item.Value2) {
          diagnal2 = Number(localData.find((row) => row.SrNo === 2).Value2);
        }
        if (item.Value3) {
          diagnal3 = Number(localData.find((row) => row.SrNo === 3).Value3);
        }
        if (item.Value4) {
          diagnal4 = Number(localData.find((row) => row.SrNo === 4).Value4);
        }
        if (item.Value5) {
          diagnal5 = Number(localData.find((row) => row.SrNo === 5).Value5);
        }
      });
      let sumofDiagnols = 0;
      sumofDiagnols = diagnal1+diagnal2+diagnal3+diagnal4+diagnal5;
      const sumOfRows = localData.map(row => Object.entries(row).filter(([key, value]) => key.startsWith('Value') && !isNaN(value)).map(([key, value]) => Number(value)).reduce((acc, val) => acc + val, 0));
      let sum = 0;
      sumOfRows.forEach((el) => sum += el);
      let overAllAccuray = (parseInt(sumofDiagnols) / parseInt(sum)*100);
      overAllAccuray = Math.round(overAllAccuray);
      overAllAccuray = Math.trunc(overAllAccuray);
      this.setState({overAllAccuray1:overAllAccuray},()=>{})
    }
  }


  // confusion matrix 2 function ends here



  // confusion matrix 3 function starts here
  handleConfusionMatrixSelectedOption2 = (e) => {
    this.setState({ confusionMatrixData3: [], confusionMatrixTableSelectedOption3: {},confusionMatrixRecallResults3:{}, overAllAccuray2:"" }, () => { });
    this.setState({ confusionMatrixTableSelectedOption3: e }, () => {
      let confusionMatrixData3 = this.state.confusionMatrixData3;
      for (let i = 0; i < e.value; i++) {
        let rowData = {
          Label: "",
          SrNo: (i + 1).toString()
        };
        for (let j = 0; j < e.value; j++) {
          rowData[`Value${j + 1}`] = "";
        }
        confusionMatrixData3.push(rowData);
      }
      this.setState({ confusionMatrixData3 }, () => { });
    });
  };

  handleConfusionMatrixTableLabelChange2 = (index, e) => {
    if(e.length < 51){
      let confusionMatrixData3 = [...this.state.confusionMatrixData3];
      confusionMatrixData3[index].Label = e;
      this.setState({ confusionMatrixData3 });
    }else{
      window.alert("You can add upto 50 characters of the label value");
      return;
    }
   

   
  };
  handleConfusionMatrixTableInputCellChange2 = (rowIndex, headerIndex, cellValue) => {
    let confusionMatrixData3 = [...this.state.confusionMatrixData3];
    confusionMatrixData3[rowIndex][`Value${headerIndex + 1}`] = cellValue;
    this.setState({ confusionMatrixData3 }, () => {
      this.confusionMatrixprecision2();
      this.calculateRecall3()
      this.calculateOverallAccuracy2()
    });
  };

  handleConfusionMatrixGetCellData2 = (rowIndex, headerIndex) => {
    return this.state.confusionMatrixData3[rowIndex][`Value${headerIndex + 1}`] || "";
  };

  confusionMatrixprecision2 = () => {
    const { confusionMatrixData3 } = this.state;
    if (confusionMatrixData3) {
      let resultForSrNo1 = 0;
      let resultForSrNo2 = 0;
      let resultForSrNo3 = 0;
      let resultForSrNo4 = 0;
      let resultForSrNo5 = 0;


      let localDataforPrecisionRow2 = confusionMatrixData3.map((item, index) => ({
        ...item,
        SrNo: index + 1,
      }));
      //console.log("local Data for Precision Row2 =>", localDataforPrecisionRow2)

      localDataforPrecisionRow2.map((item, index) => {
        if (item.Value1) {
          const sumOfTxt1 = localDataforPrecisionRow2.reduce((sum, row) => sum + Number(row.Value1), 0);
          resultForSrNo1 = Number(localDataforPrecisionRow2.find((row) => row.SrNo === 1).Value1) / sumOfTxt1;
          resultForSrNo1 = resultForSrNo1*100;
          resultForSrNo1 = Math.round(resultForSrNo1);
          resultForSrNo1 = Math.trunc(resultForSrNo1);
          // resultForSrNo1.toFixed(2);
        }
        if (item.Value2) {
          const sumOfTxt2 = localDataforPrecisionRow2.reduce((sum, row) => sum + Number(row.Value2), 0);
          resultForSrNo2 = Number(localDataforPrecisionRow2.find((row) => row.SrNo === 2).Value2) / sumOfTxt2;
          resultForSrNo2 = resultForSrNo2*100;
          resultForSrNo2 = Math.round(resultForSrNo2);
          resultForSrNo2 = Math.trunc(resultForSrNo2);
          // resultForSrNo2.toFixed(2);
        }
        if (item.Value3) {
          const sumOfTxt3 = localDataforPrecisionRow2.reduce((sum, row) => sum + Number(row.Value3), 0);
          resultForSrNo3 = Number(localDataforPrecisionRow2.find((row) => row.SrNo === 3).Value3) / sumOfTxt3;
          resultForSrNo3 = resultForSrNo3*100;
          resultForSrNo3 = Math.round(resultForSrNo3);
          resultForSrNo3 = Math.trunc(resultForSrNo3);
          // resultForSrNo3.toFixed(2);
        }
        if (item.Value4) {
          const sumOfTxt4 = localDataforPrecisionRow2.reduce((sum, row) => sum + Number(row.Value4), 0);
          resultForSrNo4 = Number(localDataforPrecisionRow2.find((row) => row.SrNo === 4).Value4) /sumOfTxt4;
          resultForSrNo4 = resultForSrNo4*100;
          resultForSrNo4 = Math.round(resultForSrNo4);
          resultForSrNo4 = Math.trunc(resultForSrNo4);
          //resultForSrNo4.toFixed(2);
        }
        if (item.Value5) {
          const sumOfTxt5 = localDataforPrecisionRow2.reduce((sum, row) => sum + Number(row.Value5), 0);
          resultForSrNo5 = Number(localDataforPrecisionRow2.find((row) => row.SrNo === 5).Value5)/sumOfTxt5;
          resultForSrNo5  = resultForSrNo5*100;
          resultForSrNo5 = Math.round(resultForSrNo5)
          resultForSrNo5 = Math.trunc(resultForSrNo5)
        }
      });
      this.setState(
        {
          confusionMatrixPrecisionResults3: {
            resultForSrNo1,
            resultForSrNo2,
            resultForSrNo3,
            resultForSrNo4,
            resultForSrNo5
          },
        },
        () => {
          //console.log(this.state.confusionMatrixPrecisionResults3, this.state.confusionMatrixData3);
        }
      );
    }
  };


  confusionMatrixPrecisionRow3 = () => {
    let localData = this.state.confusionMatrixData3.map((item, index) => ({
      ...item,
      SrNo: index + 1,
    }));
    let precisionRow = (
      <tr key="precision" style={{ background: "#15315f" }}>
        <td style={{ textAlign: "left" }}>
          <label style={{ color: "#fff", marginBottom: "0px" }}>Precision</label>
        </td>
        {localData.map((item, index) => (
          <td style={{ textAlign: "left", color: "#fff" }} key={index}>
            <label style={{ color: "#fff", marginBottom: "0px" }}>
              {this.state.confusionMatrixPrecisionResults3 && this.state.confusionMatrixPrecisionResults3[`resultForSrNo${item.SrNo}`]
                ? this.state.confusionMatrixPrecisionResults3[`resultForSrNo${item.SrNo}`] + " %"
                : ""}
            </label>
          </td>
        ))}
        <td>
          <label style={{ color: "#fff", marginBottom: "0px" }}></label>
        </td>
      </tr>
    );
    return precisionRow;
  };


  calculateRecall3 = () => {
    const { confusionMatrixData3 } = this.state;
    if (confusionMatrixData3) {
      let recall1 = 0;
      let recall2 = 0;
      let recall3 = 0;
      let recall4 = 0;
      let recall5 = 0;

      let localData = confusionMatrixData3.map((item, index) => ({
        ...item,
        SrNo: index + 1,
      }));
      const sumOfRows = localData.map(row => Object.entries(row).filter(([key, value]) => key.startsWith('Value') && !isNaN(value)).map(([key, value]) => Number(value)).reduce((acc, val) => acc + val, 0));
      localData.map((item, index) => {
        if (item.Value1) {
          recall1 = Number(localData.find((row) => row.SrNo === 1).Value1) / sumOfRows[0];
          recall1 = recall1*100;
          recall1 = Math.round(recall1);
          recall1 = Math.trunc(recall1);
        }
        if (item.Value2) {
          recall2 = Number(localData.find((row) => row.SrNo === 2).Value2) / sumOfRows[1];
          recall2 = recall2*100;
          recall2 = Math.round(recall2);
          recall2 = Math.trunc(recall2);
        }
        if (item.Value3) {
          recall3 = Number(localData.find((row) => row.SrNo === 3).Value3) / sumOfRows[2];
          recall3 = recall3*100;
          recall3 = Math.round(recall3);
          recall3 = Math.trunc(recall3);
        }
        if (item.Value4) {
          recall4 = Number(localData.find((row) => row.SrNo === 4).Value4) / sumOfRows[3];
          recall4 = recall4*100;
          recall4 = Math.round(recall4);
          recall4 = Math.trunc(recall4);
        }

        if (item.Value5) {
          recall5 = Number(localData.find((row) => row.SrNo === 5).Value5) / sumOfRows[4];
          recall5 = recall5*100;
          recall5 = Math.round(recall5);
          recall5 = Math.trunc(recall5);
        }
      });
      this.setState(
        {
          confusionMatrixRecallResults3: {
            recall1,
            recall2,
            recall3,
            recall4,
            recall5
          },
        },
        () => {
          //console.log("calculate recall =>", this.state.confusionMatrixRecallResults3);
        }
      );
    }
  }

  getRecallValues3 = (index) => {
    if(this.state.confusionMatrixRecallResults3){
      const key = `recall${index + 1}`;
      if(this.state.confusionMatrixRecallResults3[key]){
        return this.state.confusionMatrixRecallResults3[key]+" %";
      }
    }
  };


  calculateOverallAccuracy2=()=>{
    const { confusionMatrixData3 } = this.state;
    if (confusionMatrixData3){
      let diagnal1 = 0;
      let diagnal2 = 0;
      let diagnal3 = 0;
      let diagnal4 = 0;
      let diagnal5 = 0;

      let localData = confusionMatrixData3.map((item, index) => ({
        ...item,
        SrNo: index + 1,
      }));
      localData.map((item, index) => {
        if (item.Value1) {
          diagnal1 = Number(localData.find((row) => row.SrNo === 1).Value1);
        }
        if (item.Value2) {
          diagnal2 = Number(localData.find((row) => row.SrNo === 2).Value2);
        }
        if (item.Value3) {
          diagnal3 = Number(localData.find((row) => row.SrNo === 3).Value3);
        }
        if (item.Value4) {
          diagnal4 = Number(localData.find((row) => row.SrNo === 4).Value4);
        }
        if (item.Value5) {
          diagnal5 = Number(localData.find((row) => row.SrNo === 5).Value5);
        }
      });
      let sumofDiagnols = 0;
      sumofDiagnols = diagnal1+diagnal2+diagnal3+diagnal4+diagnal5;
      const sumOfRows = localData.map(row => Object.entries(row).filter(([key, value]) => key.startsWith('Value') && !isNaN(value)).map(([key, value]) => Number(value)).reduce((acc, val) => acc + val, 0));
      let sum = 0;
      sumOfRows.forEach((el) => sum += el);
      let overAllAccuray = (parseInt(sumofDiagnols) / parseInt(sum)*100);
      overAllAccuray = Math.round(overAllAccuray);
      overAllAccuray = Math.trunc(overAllAccuray);
      this.setState({overAllAccuray2:overAllAccuray},()=>{})
    }
  }


  // confusion matrix 3 function ends here


  handleClassificationModuleDelete = () => {
    if (window.confirm("Are you sure you want to delete Classification Module 1 ?")) {
      this.setState({
        showClassificationTemplate: !this.state.showClassificationTemplate,
        classificationModule: [],
        confusionMatrixData1: [],
        confusionMatrixTableSelectedOption1: [],
      })
    }
  }
  handleClassificationModuleDelete1 = () => {
    if (window.confirm("Are you sure you want to delete Classification Module 2 ?")) {
      this.setState({
        showClassificationTemplate1: !this.state.showClassificationTemplate1,
        classificationModule1: [],
        confusionMatrixData2: [],
        confusionMatrixTableSelectedOption2: [],
      })
    }
  }
  handleClassificationModuleDelete2 = () => {
    if (window.confirm("Are you sure you want to delete Classification Module 3 ?")) {
      this.setState({
        showClassificationTemplate2: !this.state.showClassificationTemplate2,
        classificationModule2: [],
        confusionMatrixData3: [],
        confusionMatrixTableSelectedOption3: []
      })
    }
  }


  render() {
    const {
      currentClassificationModule,
      currentClassificationModule1,
      currentClassificationModule2,
      currentData,
      data,
      sentimentValues,
      sentimentTotal,
      outComeTableData,
      showRegressionModal,
      currentRegressionData,
      regressionData,
      currentForecastData,
      forecastData,
    } = this.state;


    // confusion matrix 1 render function starts here
    const confusionMatrixHeader1 = this.state.confusionMatrixData1.map((item, index) => (
      // <th style={{ height: "37px" }} key={index}>Predicted - {item.Label}</th>
      <th className="confusion_matrix_header" key={index}><label>Predicted - {item.Label}</label></th>
    ));
    // confusion matrix 1 render function starts here

    // confusion matrix 2 render function starts here
    const confusionMatrixHeader2 = this.state.confusionMatrixData2.map((item, index) => (
      <th className="confusion_matrix_header" key={index}><label>Predicted - {item.Label}</label></th>
    ));
    // confusion matrix 2 render function starts here



    // confusion matrix 3 render function starts here
    const confusionMatrixHeader3 = this.state.confusionMatrixData3.map((item, index) => (
      <th className="confusion_matrix_header" key={index}><label>Predicted - {item.Label}</label></th>
    ));
    // confusion matrix 3 render function starts here

    const AnamolyColumns = Object.keys(this.state.anomolyData.reduce((acc, row) => ({ ...acc, ...row }), {}));
    const OthersColumns = Object.keys(this.state.othersData.reduce((acc, row) => ({ ...acc, ...row }), {}));

    
    return (
      <div className="">
        <div className="">
          <div
            id="Upload"
            className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
          >
            <div className="u-container-layout u-container-layout-1 ">
              {/* <div className="discriptiveAnalyticsGoBack">
                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3 template_save_btn" onClick={(e) => this.templateGoBack()}>Go Back</button>
              </div> */}
              <div className="dynamic_form_title_block">
                <h2 className="" style={{ textAlign: "center", margin: "40px 0px 0px 0px" }}>Predictive Analytics</h2>
                <h4
                  style={{
                    textAlign: "center",
                    color: "#00AECF",
                    fontFamily: "Arial",
                    fontSize: 22,
                    marginTop: "15px",
                    paddingBottom: "20px",
                  }}
                >
                  Project Id: {this.state.LeanID ? this.state.LeanID : ""}
                </h4>

              </div>
              <div className="choose_model_type_main_select_block">
                <div className="predictive_template_checkbox_block">
                  <label>
                    <span style={{ color: "red" }}>*</span> Please choose
                    template types
                  </label>
                  <ul>
                    <li>
                      <label htmlFor="classificationCheckbox">
                        <input
                          id="classificationCheckbox"
                          type="checkbox"
                          value={this.state.classificationChecked}
                          checked={this.state.showClassificationTemplate ? true : false}
                          onChange={(e) => this.displayClassificationModule()}
                        />
                        Classification
                      </label>
                    </li>
                    <li>
                      <label htmlFor="regressionCheckbox">
                        <input
                          id="regressionCheckbox"
                          type="checkbox"
                          value={this.state.regressionChecked}
                          checked={this.state.showRegressionTemplate ? true : false}
                          onClick={(e) => this.displayRegressionModule()}
                        />
                        Regression
                      </label>
                    </li>
                    <li>
                      <label htmlFor="forecastcheckbox">
                        <input
                          id="forecastcheckbox"
                          type="checkbox"
                          value={this.state.forecastChecked}
                          checked={this.state.showForecastTemplate ? true : false}
                          onClick={(e) => this.displayForecastModule()}
                        />
                        Forecast
                      </label>
                    </li>
                    <li>
                      <label htmlFor="sentimentValuescheckbox">
                        <input
                          id="sentimentValuescheckbox"
                          type="checkbox"
                          value={this.state.setimentValuesChecked}
                          checked={this.state.showSentimentTemplate ? true : false}
                          onClick={(e) => this.displaySentimentModule()}
                        />
                        Sentiment&nbsp;Analysis
                      </label>
                    </li>
                    <li>
                      <label htmlFor="anamolycheckbox">
                        <input
                          id="anamolycheckbox"
                          type="checkbox"
                          value={this.state.anamolyChecked}
                          checked={this.state.showAnamolyTemplate ? true : false}
                          onClick={(e) => this.displayAnamolyModule()}
                        />
                        Anamoly
                      </label>
                    </li>
                    <li>
                      <label htmlFor="othersCheckbox">
                        <input
                          id="othersCheckbox"
                          type="checkbox"
                          value={this.state.othersChecked}
                          checked={this.state.showOthersTemplate ? true : false}
                          onClick={(e) => this.displayOtherstModule()}
                        />
                        Others
                      </label>
                    </li>
                  </ul>
                </div>
              </div>

              {/* classification module 0 template starts here */}

              {this.state.showClassificationTemplate ? (
                <>
                  <div className="dynamic_form_table">
                    <h2>Classification Module 1</h2>
                    <div
                      className=""
                      style={{
                        borderBottom: "1px solid lightgray",
                        paddingBottom: "25px",
                        margin:"20px 0px"
                      }}
                    >
                      <div className="row">
                        <div className="col-md-5">
                          <label className="form-attribute">
                            <span style={{ color: "red" }}> *</span> Please Select Model 
                          </label>
                          <div className="classification_module_multi_select">
                            <Select
                              isMulti={true}
                              isSearchable={false}
                              options={this.state.classificationModels}
                              onChange={(e) => this.handleModelSelectChange(e)}
                              value={this.state.classificationModelSelected}
                              placeholder="Please choose model"
                              styles={this.state.selectStyle.classificationModelSelected}
                            />
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-md-8">
                        <button className="dynamic_form_max_row_btn" disabled>
                          {" "}
                          Max Limit: 30 Rows
                        </button>
                      </div>
                      <div
                        className="col-md-4"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          className="dynamic_form_add_row_btn"
                          style={{ marginRight: "0px" }}
                          onClick={this.openClassificationModuleModal}
                          disabled={this.state.counter === 30 ? true : false}
                        >
                          Add Row
                        </button>
                        <button
                          className="dynamic_form_add_row_btn dynamic_table_save_btn"
                          onClick={(e) =>
                            this.handleClassificationModuleIncrement()
                          }
                        >
                          Add Module
                        </button>
                        {/* <button
                          className="dynamic_form_add_row_btn dynamic_table_save_btn"
                          onClick={(e) =>
                            this.handleClassificationModuleDelete()
                          }
                        >
                          Delete Module
                        </button> */}



                      </div>
                    </div>
                  </div>

                  <div className="text-left mt-4">
                    {this.state.showClassificationModule ? (
                      <>
                        <div className="">
                          <div className="custom_modal classification_template_modal">
                            <div className="modal_body" style={{}}>
                              <div className="row">
                                <div className="col-md-11">
                                  <h3 className="modal_title text-center">
                                    Add / Update Data
                                  </h3>
                                </div>
                                <div className="col-md-1">
                                  <button
                                    type="button"
                                    className="close text-right"
                                    onClick={
                                      this.closeClassificationModuleModal
                                    }
                                  >
                                    &times;
                                  </button>
                                </div>
                              </div>
                              <div className="edit_row_field_blocks">
                                <div className="row">
                                  <div className="col-md-6">
                                    <label className="form-attribute">
                                      <span style={{ color: "red" }}> *</span>{" "}
                                      Features
                                      <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 30 chars)</span>
                                    </label>
                                    <input
                                      placeholder="Please provide features"
                                      className="textarea-css form-attribute"
                                      type="text"
                                      name="Feature"
                                      value={
                                        currentClassificationModule.Feature
                                      }
                                      onChange={
                                        this
                                          .handleClassificationModuleInputChange
                                      }
                                      style={{ maxWidth: '100%', ...this.state.selectStyle.currentClassificationModule.Feature }}

                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <label className="form-attribute">
                                      <span style={{ color: "red" }}> *</span>{" "}
                                      Type
                                    </label>
                                    <Select
                                      isSearchable={false}
                                      name="Type"
                                      options={
                                        this.state.classificationTypeOptions
                                      }
                                      onChange={(e) =>
                                        this.handleClassificationModuleSelectChange(
                                          e,
                                          currentClassificationModule,
                                          "type"
                                        )
                                      }
                                      value={currentClassificationModule.Type}
                                      placeholder="Please choose type"
                                      styles={this.state.selectStyle.currentClassificationModule.Type}
                                    />
                                  </div>
                                </div>
                                <div className="edit_row_gap_between_rows">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label className="form-attribute">
                                        <span style={{ color: "red" }}> *</span>{" "}
                                        Role
                                      </label>
                                      <Select
                                        isSearchable={false}
                                        name="Role"
                                        options={this.state.roles}
                                        onChange={(e) =>
                                          this.handleClassificationModuleSelectChange(
                                            e,
                                            currentClassificationModule,
                                            "role"
                                          )
                                        }
                                        value={currentClassificationModule.Role}
                                        placeholder="Please choose role"

                                        styles={this.state.selectStyle.currentClassificationModule.Role}
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <label className="form-attribute">
                                        <span style={{ color: "red" }}> *</span>{" "}
                                        Importance Score
                                      </label>
                                      <input
                                        placeholder="Please provide importance score"
                                        className={currentClassificationModule.Role && currentClassificationModule.Role.label === "Target" ? "form-input disabled_input" : "textarea-css form-attribute hide_numeric_input_arrows"} 
                                        name="Score"
                                        min="0"
                                        max="100"
                                        pattern="\d*"
                                        type="text"
                                        value={
                                          currentClassificationModule.Score
                                        }
                                        onChange={
                                          this
                                            .handleClassificationModuleInputChange
                                        }
                                        onKeyDown={(evt) =>
                                          (
                                            evt.key === "e" ||
                                            evt.key === "E" ||
                                            evt.key === "+" ||
                                            evt.key === "-") &&
                                          evt.preventDefault()
                                        }
                                        onWheel={(e) => e.target.blur()}
                                        style={{ maxWidth: '100%', ...this.state.selectStyle.currentClassificationModule.Score }}
                                        disabled={currentClassificationModule.Role && currentClassificationModule.Role.label === "Target" ? true : false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="cluster_modal_btn_group">
                                <button
                                  className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3 saveEditedRowBtn"
                                  onClick={this.addClassificationModuleRow}
                                >
                                  Save
                                </button>
                                <button
                                  className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                  onClick={this.closeClassificationModuleModal}
                                  style={{ marginRight: "15px" }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="dynamic_form_table">
                    <table className="table review-idea-table">
                      <thead>
                        <tr>
                          <th className="dynamic_form_sl_no_th">Sr No</th>
                          <th>Variables</th>
                          <th>Type</th>
                          <th>Role</th>
                          <th>Importance Score</th>
                          <th style={{width:"0%"}}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.classificationModule &&
                          this.state.classificationModule.map((item, index) => {
                            let localType = "";
                            if(item.Type){
                              if(item.Type.label){
                                localType = item.Type.label
                              }else{
                                localType = item.Type
                              }
                            }
                            let localRole = "";
                            if(item.Role){
                              if(item.Role.label){
                                localRole = item.Role.label
                              }else{
                                localRole = item.Role
                              }
                            }
                            return (
                              <tr key={index}>
                                <td
                                  style={{ width: "4%" }}
                                  className="dynamic_form_sl_no"
                                >
                                  <label>{index + 1}</label>
                                </td>
                                <td
                                  style={{
                                    width: "30%",
                                    breakWord: "break-word",
                                  }}
                                >
                                  <label>{item.Feature}</label>
                                </td>
                                <td style={{ width: "20%" }}>
                                  <label>{localType}</label>
                                  {/* <label>{item.Type ? item.Type.label : item.Type}</label> */}
                                </td>
                                <td style={{ width: "20%" }}>
                                  <label>{localRole}</label>
                                  {/* <label>{item.Role ? item.Role.label : item.Role}</label> */}
                                </td>
                                <td style={{ width: "10%" }}>
                                  <label>{item.Score}</label>
                                </td>
                                <td style={{display:'flex'}}>
                                  <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "5px 8px",margin:'0px',marginRight:"15px" }} title="Edit" onClick={() => this.editClassificationModule(index)}><i  className="fa fa-pencil"></i></button>
                                  <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "5px 8px",margin:'0px' }} title="Delete" onClick={() => this.deleteClassificationModuleRow(index)}><i  className="fa fa-trash" aria-hidden="true"></i></button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>

                  {/* ...confusion matrix 1 starts here.... */}
                  <div className="dynamic_form_table">
                    <div
                      className=""
                      style={{ borderTop: "1px solid lightgrey" }}
                    />
                    <h2>Confusion Matrix 1</h2>
                    <div className="">
                      <div className="row">
                        <div className="col-md-3">
                          <label className="form-attribute">
                            <span style={{ color: "red" }}> *</span> Please select Metric type
                          </label>
                          <Select
                            options={this.state.outcomeTypeOptions}
                            onChange={(e) => this.handleConfusionMatrixSelectedOption(e)}
                            value={this.state.confusionMatrixTableSelectedOption1}
                            placeholder="Please select a number"
                          />
                        </div>
                      </div>
                      <div className="" style={{ marginTop: "15px" }}>
                        {this.state.confusionMatrixData1.length > 0 && (
                          <>
                          <table className="table review-idea-table">
                            <thead>
                              <tr>
                                <th></th>
                                {confusionMatrixHeader1}
                                <th>Recall %</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.confusionMatrixData1.map((item, index) =>  (
                                <tr key={index}>
                                  <td className="actual_label_td">
                                    <label>Actual - </label>&nbsp;
                                    <input
                                      value={item.Label}
                                      onChange={(e) =>
                                        this.handleConfusionMatrixTableLabelChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      type="text"
                                      className="form-attribute"
                                      placeholder="Please enter actual label (Max: 50 characters)"
                                    />
                                    
                                  </td>
                                  {confusionMatrixHeader1.map((header, headerIndex) => (
                                    <td key={headerIndex}>
                                      <input
                                        value={this.handleConfusionMatrixGetCellData(index, headerIndex)}
                                        onChange={(e) =>
                                          this.handleConfusionMatrixTableInputCellChange(
                                            index,
                                            headerIndex,
                                            e.target.value
                                          )
                                        }
                                        className="form-attribute hide_numeric_input_arrows"
                                        type="number"
                                        min="0"
                                        onKeyDown={(evt) =>
                                          (evt.key === "." ||
                                            evt.key === "e" ||
                                            evt.key === "E" ||
                                            evt.key === "+" ||
                                            evt.key === "-") &&
                                          evt.preventDefault()
                                        }
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    </td>
                                  ))}
                                  <label style={{ display: "flex", alignItems: "center", marginTop: "10px", justifyContent: "center" }}>{this.getRecallValues1(index)}</label>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>{this.confusionMatrixPrecisionRow1()}</tfoot>
                          </table>
                          <div className="" style={{marginTop:"30px"}}>
                            <div className="row">
                              <div className="col-md-5">
                                <div className="">
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-5">
                                      <label className="form-attribute">Overall Accuracy</label>
                                    </div>
                                    <div className="col-md-5">
                                      <input type="text" value={this.state.overAllAccuray + " %"} className="form-input disabled_input" disabled={true} />
                                    </div> 
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </>
                        )}
                      </div>
                      
                    </div>

                  </div>

                  {/* ...confusion matrix 1 ends here..... */}
                </>
              ) : null}

              {/* classification module 0 template ends here */}

              {/* classification module 1 template starts here */}

              {this.state.showClassificationTemplate1 ? (
                <>
                  <div className="dynamic_form_table">
                    <div style={{ borderTop: "1px solid lightgrey" }} />
                    <h2>Classification Module 2</h2>
                    <div
                      className=""
                      style={{
                        borderBottom: "1px solid lightgray",
                        paddingBottom: "25px",
                        margin:"20px 0px"
                      }}
                    >
                      <div className="row">
                        <div className="col-md-5">
                          <label className="form-attribute">
                            <span style={{ color: "red" }}> *</span> Please Select Model
                          </label>
                          <div className="classification_module_multi_select">
                            <Select
                              isMulti={true}
                              isSearchable={false}
                              options={this.state.classificationModels}
                              onChange={(e) => this.handleModelSelectChange1(e)}
                              value={this.state.classificationModelSelected1}
                              placeholder="Please choose model"
                              styles={this.state.selectStyle.classificationModelSelected1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <button className="dynamic_form_max_row_btn" disabled>
                          {" "}
                          Max Limit: 30 Rows
                        </button>
                      </div>
                      <div
                        className="col-md-4"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          className="dynamic_form_add_row_btn"
                          style={{ marginRight: "0px" }}
                          onClick={this.openClassificationModuleModal1}
                          disabled={this.state.counter === 30 ? true : false}
                        >
                          Add Row
                        </button>
                        <button
                          className="dynamic_form_add_row_btn dynamic_table_save_btn"
                          onClick={(e) =>
                            this.handleClassificationModuleIncrement1()
                          }
                        >
                          Add Module
                        </button>
                        <button
                          className="dynamic_form_add_row_btn dynamic_table_save_btn"
                          // disabled={this.state.classificationModule1.length > 0 ? false : true}
                          onClick={(e) =>
                            this.handleClassificationModuleDelete1()
                          }
                        >
                          Delete Module
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="text-left mt-4">
                    {this.state.showClassificationModule1 ? (
                      <>
                        <div className="">
                          <div className="custom_modal classification_template_modal">
                            <div className="modal_body" style={{}}>
                              <div className="row">
                                <div className="col-md-11">
                                  <h3 className="modal_title text-center">
                                    Add / Update Data
                                  </h3>
                                </div>
                                <div className="col-md-1">
                                  <button
                                    type="button"
                                    className="close text-right"
                                    onClick={
                                      this.closeClassificationModuleModal1
                                    }
                                  >
                                    &times;
                                  </button>
                                </div>
                              </div>
                              <div className="edit_row_field_blocks">
                                <div className="row">
                                  <div className="col-md-6">
                                    <label className="form-attribute">
                                      <span style={{ color: "red" }}> *</span>{" "}
                                      Feature
                                      <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 30 chars)</span>
                                    </label>
                                    <input
                                      placeholder="Please provide feature"
                                      className="textarea-css form-attribute"
                                      type="text"
                                      name="Feature"
                                      value={
                                        currentClassificationModule1.Feature
                                      }
                                      onChange={
                                        this
                                          .handleClassificationModuleInputChange1
                                      }
                                      style={{ maxWidth: '100%', ...this.state.selectStyle.currentClassificationModule1.Feature }}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <label className="form-attribute">
                                      <span style={{ color: "red" }}> *</span>{" "}
                                      Type
                                    </label>

                                    <Select
                                      isSearchable={false}
                                      name="Type"
                                      options={
                                        this.state.classificationTypeOptions
                                      }
                                      onChange={(e) =>
                                        this.handleClassificationModuleSelectChange1(
                                          e,
                                          currentClassificationModule1,
                                          "type"
                                        )
                                      }
                                      value={currentClassificationModule1.Type}
                                      placeholder="Please choose type"
                                      styles={this.state.selectStyle.currentClassificationModule1.Type}
                                    />
                                  </div>
                                </div>
                                <div className="edit_row_gap_between_rows">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label className="form-attribute">
                                        <span style={{ color: "red" }}> *</span>{" "}
                                        Role
                                      </label>
                                      <Select
                                        isSearchable={false}
                                        name="Role"
                                        options={this.state.roles}
                                        onChange={(e) =>
                                          this.handleClassificationModuleSelectChange1(
                                            e,
                                            currentClassificationModule1,
                                            "role"
                                          )
                                        }
                                        value={
                                          currentClassificationModule1.Role
                                        }
                                        styles={this.state.selectStyle.currentClassificationModule1.Role}
                                        placeholder="Please choose role"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <label className="form-attribute">
                                        <span style={{ color: "red" }}> *</span>{" "}
                                        Importance Score
                                      </label>
                                      <input
                                        placeholder="Please provide importance score"
                                        className={currentClassificationModule1.Role && currentClassificationModule1.Role.label==="Target" ?"form-input disabled_input" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                        type="text"
                                        pattern="\d*"
                                        name="Score"
                                        min="0"
                                        max={100}
                                        value={
                                          currentClassificationModule1.Score
                                        }
                                        onChange={
                                          this
                                            .handleClassificationModuleInputChange1
                                        }
                                        onKeyDown={(evt) =>
                                          (
                                            evt.key === "e" ||
                                            evt.key === "E" ||
                                            evt.key === "+" ||
                                            evt.key === "-") &&
                                          evt.preventDefault()
                                        }
                                        onWheel={(e) => e.target.blur()}
                                        style={{ maxWidth: '100%', ...this.state.selectStyle.currentClassificationModule1.Score }}
                                        disabled={currentClassificationModule1.Role && currentClassificationModule1.Role.label==="Target" ? true : false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="cluster_modal_btn_group">
                                <button
                                  className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3 saveEditedRowBtn"
                                  onClick={this.addClassificationModuleRow1}
                                >
                                  Save
                                </button>
                                <button
                                  className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                  onClick={this.closeClassificationModuleModal1}
                                  style={{ marginRight: "15px" }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="dynamic_form_table">
                    <table className="table review-idea-table">
                      <thead>
                        <tr>
                          <th className="dynamic_form_sl_no_th">Sr No</th>
                          <th>Variables</th>
                          <th>Type</th>
                          <th>Role</th>
                          <th>Importance Score</th>
                          <th style={{width:"0%"}}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.classificationModule1 &&
                          this.state.classificationModule1.map(
                            (item, index) => {
                              let localType = "";
                              if(item.Type){
                                if(item.Type.label){
                                  localType = item.Type.label
                                }else{
                                  localType = item.Type
                                }
                              }
                              let localRole = "";
                              if(item.Role){
                                if(item.Role.label){
                                  localRole = item.Role.label
                                }else{
                                  localRole = item.Role
                                }
                              }
                              return (
                                <tr key={index}>
                                  <td
                                    style={{ width: "4%" }}
                                    className="dynamic_form_sl_no"
                                  >
                                    <label>{index + 1}</label>
                                  </td>
                                  <td
                                    style={{
                                      width: "30%",
                                      breakWord: "break-word",
                                    }}
                                  >
                                    <label>{item.Feature}</label>
                                  </td>
                                  <td
                                    style={{
                                      width: "20%",
                                      breakWord: "break-word",
                                    }}
                                  >
                                    {/* <label>{item.Type ? item.Type.label : item.Type}</label> */}
                                    <label>{localType}</label>
                                  </td>
                                  <td
                                    style={{
                                      width: "20%",
                                      breakWord: "break-word",
                                    }}
                                  >
                                    <label>{localRole}</label>
                                    {/* <label>{item.Role ? item.Role.label : item.Role}</label> */}
                                  </td>
                                  <td
                                    style={{
                                      width: "10%",
                                      breakWord: "break-word",
                                    }}
                                  >
                                    <label>{item.Score}</label>
                                  </td>
                                  <td style={{display:'flex'}}>
                                    <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "5px 8px",margin:'0px',marginRight:"15px" }} title="Edit" onClick={() =>this.editClassificationModule1(index)}><i  className="fa fa-pencil"></i></button>
                                    <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "5px 8px",margin:'0px' }} title="Delete" onClick={() =>this.deleteClassificationModuleRow1(index)}><i  className="fa fa-trash" aria-hidden="true"></i></button>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  </div>

                  {/* .... confusion matrix 2 starts here..... */}
                  <div className="dynamic_form_table">
                    <div
                      className=""
                      style={{ borderTop: "1px solid lightgrey" }}
                    />
                    <h2>Confusion Matrix 2</h2>
                    <div className="">
                      <div className="row">
                        <div className="col-md-3">
                          <label className="form-attribute">
                            <span style={{ color: "red" }}> *</span> Please select Metric type
                          </label>
                          <Select
                            options={this.state.outcomeTypeOptions}
                            onChange={(e) => this.handleConfusionMatrixSelectedOption1(e)}
                            value={this.state.confusionMatrixTableSelectedOption2}
                            placeholder="Please select a number"
                          />
                        </div>
                      </div>
                      <div className="" style={{ marginTop: "15px" }}>
                        {this.state.confusionMatrixData2.length > 0 && (
                          <>
                          <table className="table review-idea-table">
                            <thead>
                              <tr>
                                <th></th>
                                {confusionMatrixHeader2}
                                <th>Recall %</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.confusionMatrixData2.map((item, index) => (
                                <tr key={index}>
                                  <td className="actual_label_td">
                                    <label>Actual - </label>&nbsp;
                                    <input
                                      value={item.Label}
                                      onChange={(e) =>
                                        this.handleConfusionMatrixTableLabelChange1(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      type="text"
                                      className="form-attribute"
                                      placeholder="Please enter actual label (Max: 50 characters)"
                                    />
                                  </td>
                                  {confusionMatrixHeader2.map((header, headerIndex) => (
                                    <td key={headerIndex}>
                                      <input
                                        value={this.handleSecondConfusionMatrixGetCellData(index, headerIndex)}
                                        onChange={(e) =>
                                          this.handleSecondConfusionMatrixTableInputCellChange(
                                            index,
                                            headerIndex,
                                            e.target.value
                                          )
                                        }
                                        className="form-attribute hide_numeric_input_arrows"
                                        type="number"
                                        min="0"
                                        onKeyDown={(evt) =>
                                          (evt.key === "." ||
                                            evt.key === "e" ||
                                            evt.key === "E" ||
                                            evt.key === "+" ||
                                            evt.key === "-") &&
                                          evt.preventDefault()
                                        }
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    </td>
                                  ))}
                                  <label style={{ display: "flex", alignItems: "center", marginTop: "10px", justifyContent: "center" }}>{this.getRecallValues2(index)}</label>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>{this.confusionMatrixPrecisionRow2()}</tfoot>
                          </table>
                          <div className="" style={{marginTop:"30px"}}>
                            <div className="row">
                              <div className="col-md-5">
                                <div className="">
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-5">
                                      <label className="form-attribute">Overall Accuracy</label>
                                    </div>
                                    <div className="col-md-5">
                                      <input type="text" value={this.state.overAllAccuray1 + " %"} className="form-input disabled_input" disabled={true} />
                                    </div> 
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </>
                        )}
                      </div>

                      


                    </div>
                  </div>
                  {/* .... confusion matrix 2 ends here */}
                </>
              ) : null}

              {/* classification module 1 template ends here */}

              {/* classification module 2 template starts here */}

              {this.state.showClassificationTemplate2 ? (
                <>
                  <div className="dynamic_form_table">
                    <div style={{ borderTop: "1px solid lightgrey" }} />
                    <h2>Classification Module 3</h2>
                    <div
                      className=""
                      style={{
                        borderBottom: "1px solid lightgray",
                        paddingBottom: "25px",
                        margin:"20px 0px"
                      }}
                    >
                      <div className="row">
                        <div className="col-md-5">
                          <label className="form-attribute">
                            <span style={{ color: "red" }}> *</span> Please Select Model
                          </label>
                          <div className="classification_module_multi_select">
                            <Select
                              isMulti={true}
                              isSearchable={false}
                              options={this.state.classificationModels}
                              onChange={(e) => this.handleModelSelectChange2(e)}
                              value={this.state.classificationModelSelected2}
                              placeholder="Please choose model"
                              styles={this.state.selectStyle.classificationModelSelected2}
                            />
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-md-8">
                        <button className="dynamic_form_max_row_btn" disabled>
                          {" "}
                          Max Limit: 30 Rows
                        </button>
                      </div>
                      <div
                        className="col-md-4"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          className="dynamic_form_add_row_btn"
                          style={{ marginRight: "0px" }}
                          onClick={this.openClassificationModuleModal2}
                          disabled={this.state.counter === 30 ? true : false}
                        >
                          Add Row
                        </button>
                        <button
                          className="dynamic_form_add_row_btn"
                          style={{ marginRight: "0px" }}
                          onClick={this.handleClassificationModuleDelete2}
                        // disabled={this.state.classificationModule2.length > 0 ? false : true}
                        >
                          Delete Module
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="text-left mt-4">
                    {this.state.showClassificationModule2 ? (
                      <>
                        <div className="">
                          <div className="custom_modal classification_template_modal">
                            <div className="modal_body" style={{}}>
                              <div className="row">
                                <div className="col-md-11">
                                  <h3 className="modal_title text-center">
                                    Add / Update Data
                                  </h3>
                                </div>
                                <div className="col-md-1">
                                  <button
                                    type="button"
                                    className="close text-right"
                                    onClick={
                                      this.closeClassificationModuleModal2
                                    }
                                  >
                                    &times;
                                  </button>
                                </div>
                              </div>
                              <div className="edit_row_field_blocks">
                                <div className="row">
                                  <div className="col-md-6">
                                    <label className="form-attribute">
                                      <span style={{ color: "red" }}> *</span>{" "}
                                      Feature
                                      <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 30 chars)</span>
                                    </label>
                                    <input
                                      placeholder="Please provide feature"
                                      className="textarea-css form-attribute"
                                      type="text"
                                      name="Feature"
                                      value={
                                        currentClassificationModule2.Feature
                                      }
                                      max={100}
                                      onChange={(e) =>
                                        this.ModuleInputChange2(e)
                                      }
                                      style={{ maxWidth: '100%', ...this.state.selectStyle.currentClassificationModule2.Feature }}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <label className="form-attribute">
                                      <span style={{ color: "red" }}> *</span>{" "}
                                      Type
                                    </label>
                                    <Select
                                      isSearchable={false}
                                      name="Type"
                                      options={
                                        this.state.classificationTypeOptions
                                      }
                                      onChange={(e) =>
                                        this.handleClassificationModuleSelectChange2(
                                          e,
                                          currentClassificationModule2,
                                          "type"
                                        )
                                      }
                                      styles={this.state.selectStyle.currentClassificationModule2.Type}
                                      value={currentClassificationModule2.Type}
                                      placeholder="Please choose type"
                                    />
                                  </div>
                                </div>
                                <div className="edit_row_gap_between_rows">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label className="form-attribute">
                                        <span style={{ color: "red" }}> *</span>{" "}
                                        Role
                                      </label>
                                      <Select
                                        isSearchable={false}
                                        name="Role"
                                        options={this.state.roles}
                                        onChange={(e) =>
                                          this.handleClassificationModuleSelectChange2(
                                            e,
                                            currentClassificationModule2,
                                            "role"
                                          )
                                        }
                                        value={
                                          currentClassificationModule2.Role
                                        }
                                        styles={this.state.selectStyle.currentClassificationModule2.Role}
                                        placeholder="Please choose role"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <label className="form-attribute">
                                        <span style={{ color: "red" }}> *</span>{" "}
                                        Importance Score
                                      </label>
                                      <input
                                        placeholder="Please provide importance score"
                                        className={currentClassificationModule2.Role && currentClassificationModule2.Role.label === "Target" ? "form-input disabled_input" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                        type="text"
                                        pattern="\d*"
                                        name="Score"
                                        min="0"
                                        value={
                                          currentClassificationModule2.Score
                                        }
                                        onChange={(e) =>
                                          this.ModuleInputChange2(e)
                                        }
                                        onKeyDown={(evt) =>
                                          (
                                            evt.key === "e" ||
                                            evt.key === "E" ||
                                            evt.key === "+" ||
                                            evt.key === "-") &&
                                          evt.preventDefault()
                                        }
                                        onWheel={(e) => e.target.blur()}
                                        style={{ maxWidth: '100%', ...this.state.selectStyle.currentClassificationModule2.Score }}
                                        disabled={currentClassificationModule2.Role && currentClassificationModule2.Role.label === "Target" ? true : false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="cluster_modal_btn_group">
                                <button
                                  className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3 saveEditedRowBtn"
                                  onClick={this.addClassificationModuleRow2}
                                >
                                  Save
                                </button>
                                <button
                                  className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                  onClick={this.closeClassificationModuleModal2}
                                  style={{ marginRight: "15px" }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="dynamic_form_table">
                    <table className="table review-idea-table">
                      <thead>
                        <tr>
                          <th className="dynamic_form_sl_no_th">Sr No</th>
                          <th>Variables</th>
                          <th>Type</th>
                          <th>Role</th>
                          <th>Score</th>
                          <th style={{width:"0%"}}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.classificationModule2 &&
                          this.state.classificationModule2.map(
                            (item, index) => {
                              let localType = "";
                              if(item.Type){
                                if(item.Type.label){
                                  localType = item.Type.label
                                }else{
                                  localType = item.Type
                                }
                              }
                              let localRole = "";
                              if(item.Role){
                                if(item.Role.label){
                                  localRole = item.Role.label
                                }else{
                                  localRole = item.Role
                                }
                              }
                              return (
                                <tr key={index}>
                                  <td
                                    style={{ width: "4%" }}
                                    className="dynamic_form_sl_no"
                                  >
                                    <label>{index + 1}</label>
                                  </td>
                                  <td
                                    style={{
                                      width: "30%",
                                      breakWord: "break-word",
                                    }}
                                  >
                                    <label>{item.Feature}</label>
                                  </td>
                                  <td style={{ width: "20%" }}>
                                    {/* <label>{item.Type ? item.Type.label : item.Type}</label> */}
                                    <label>{localType}</label>
                                    
                                  </td>
                                  <td style={{ width: "20%" }}>
                                    {/* <label>{item.Role ? item.Role.label : item.Role}</label> */}
                                    <label>{localRole}</label>
                                    
                                  </td>
                                  <td style={{ width: "10%" }}>
                                    <label>{item.Score}</label>
                                  </td>
                                  <td style={{display:'flex'}}>
                                    <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "5px 8px",margin:'0px',marginRight:"15px" }} title="Edit" onClick={() =>this.editClassificationModule2(index)}><i  className="fa fa-pencil"></i></button>
                                    <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "5px 8px",margin:'0px' }} title="Delete" onClick={() =>this.deleteClassificationModuleRow2(index)}><i  className="fa fa-trash" aria-hidden="true"></i></button>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  </div>

                  <div className="dynamic_form_table">
                    <div
                      className=""
                      style={{ borderTop: "1px solid lightgrey" }}
                    />
                    <h2>Confusion Matrix 3</h2>
                    <div className="">
                      <div className="row">
                        <div className="col-md-3">
                          <label className="form-attribute">
                            <span style={{ color: "red" }}> *</span> Please select Metric type
                          </label>
                          <Select
                            options={this.state.outcomeTypeOptions}
                            onChange={(e) => this.handleConfusionMatrixSelectedOption2(e)}
                            value={this.state.confusionMatrixTableSelectedOption3}
                            placeholder="Please select a number"
                          />
                        </div>
                      </div>
                      <div className="" style={{ marginTop: "15px" }}>
                        {this.state.confusionMatrixData3.length > 0 && (
                          <>
                          <table className="table review-idea-table">
                            <thead>
                              <tr>
                                <th></th>
                                {confusionMatrixHeader3}
                                <th>Recall %</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.confusionMatrixData3.map((item, index) => (
                                <tr key={index}>
                                  <td className="actual_label_td">
                                    <label>Actual - </label>&nbsp;
                                    <input
                                      value={item.Label}
                                      onChange={(e) =>
                                        this.handleConfusionMatrixTableLabelChange2(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      type="text"
                                      className="form-attribute"
                                      placeholder="Please enter actual label (Max: 50 characters)"
                                    />
                                  </td>
                                  {confusionMatrixHeader3.map((header, headerIndex) => (
                                    <td key={headerIndex}>
                                      <input
                                        value={this.handleConfusionMatrixGetCellData2(index, headerIndex)}
                                        onChange={(e) =>
                                          this.handleConfusionMatrixTableInputCellChange2(
                                            index,
                                            headerIndex,
                                            e.target.value
                                          )
                                        }
                                        className="form-attribute hide_numeric_input_arrows"
                                        type="number"
                                        min="0"
                                        onKeyDown={(evt) =>
                                          (evt.key === "." ||
                                            evt.key === "e" ||
                                            evt.key === "E" ||
                                            evt.key === "+" ||
                                            evt.key === "-") &&
                                          evt.preventDefault()
                                        }
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    </td>
                                  ))}
                                  <label style={{ display: "flex", alignItems: "center", marginTop: "10px", justifyContent: "center" }}>{this.getRecallValues3(index)}</label>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>{this.confusionMatrixPrecisionRow3()}</tfoot>
                          </table>

                          <div className="" style={{marginTop:"30px"}}>
                            <div className="row">
                              <div className="col-md-5">
                                <div className="">
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-5">
                                      <label className="form-attribute">Overall Accuracy</label>
                                    </div>
                                    <div className="col-md-5">
                                      <input type="text" value={this.state.overAllAccuray2 + " %"} className="form-input disabled_input" disabled={true} />
                                    </div> 
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          </>
                        )}
                      </div>
                    </div>


                    

                  </div>
                </>
              ) : null}

              {/* classification module 2 template ends here */}

              {/* regression template starts here */}
              {this.state.showRegressionTemplate ? (
                <>
                  <div className="dynamic_form_table">
                    <div
                      className=""
                      style={{ borderTop: "1px solid lightgrey" }}
                    />
                    <h2>Regression</h2>
                    <div className="row">
                      <div className="col-md-8">
                        <button className="dynamic_form_max_row_btn" disabled>
                          {" "}
                          Max Limit: 30 Rows
                        </button>
                      </div>
                      <div
                        className="col-md-4"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          className="dynamic_form_add_row_btn"
                          style={{ marginRight: "0px" }}
                          onClick={this.openRegressionModal}
                          disabled={this.state.counter === 30 ? true : false}
                        >
                          Add Row
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="text-left mt-4">
                    {showRegressionModal ? (
                      <>
                        <div className="">
                          <div className="custom_modal classification_template_modal">
                            <div className="modal_body" style={{}}>
                              <div className="row">
                                <div className="col-md-11">
                                  <h3 className="modal_title text-center">
                                    Add / Update Data
                                  </h3>
                                </div>
                                <div className="col-md-1">
                                  <button
                                    type="button"
                                    className="close text-right"
                                    onClick={this.closeRegressionModal}
                                  >
                                    &times;
                                  </button>
                                </div>
                              </div>
                              <div className="edit_row_field_blocks">
                                <div className="row">
                                  <div className="col-md-6">
                                    <label className="form-attribute">
                                      <span style={{ color: "red" }}> *</span>{" "}
                                      Variables
                                      <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 30 chars)</span>
                                    </label>
                                    <input
                                      placeholder="Please provide Variables"
                                      className="textarea-css form-attribute"
                                      type="text"
                                      name="Feature"
                                      value={currentRegressionData.Feature}
                                      onChange={
                                        this.handleRegressionInputChange
                                      }
                                      style={{ maxWidth: '100%', ...this.state.selectStyle.currentRegressionData.Feature }}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <label className="form-attribute">
                                      <span style={{ color: "red" }}> *</span>{" "}
                                      Type
                                    </label>
                                    <Select
                                      isSearchable={false}
                                      name="Type"
                                      options={
                                        this.state.classificationTypeOptions
                                      }
                                      onChange={(e) =>
                                        this.handleRegressionSelectChange(
                                          e,
                                          currentRegressionData,
                                          "type"
                                        )
                                      }
                                      value={currentRegressionData.Type}
                                      placeholder="Please choose type"
                                      styles={this.state.selectStyle.currentRegressionData.Type}
                                    />
                                  </div>
                                </div>
                                <div className="edit_row_gap_between_rows">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label className="form-attribute">
                                        <span style={{ color: "red" }}> *</span>{" "}
                                        Role
                                      </label>
                                      <Select
                                        isSearchable={false}
                                        name="Role"
                                        options={this.state.roles}
                                        onChange={(e) =>
                                          this.handleRegressionSelectChange(
                                            e,
                                            currentRegressionData,
                                            "role"
                                          )
                                        }
                                        value={currentRegressionData.Role}
                                        placeholder="Please choose role"
                                        styles={this.state.selectStyle.currentRegressionData.Role}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="cluster_modal_btn_group">
                                <button
                                  className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3 saveEditedRowBtn"
                                  onClick={this.addRegressionRow}
                                >
                                  Save
                                </button>
                                <button
                                  className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                  onClick={this.closeRegressionModal}
                                  style={{ marginRight: "15px" }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="dynamic_form_table">
                    <table className="table review-idea-table">
                      <thead>
                        <tr>
                          <th className="dynamic_form_sl_no_th">Sr No</th>
                          <th>Variables</th>
                          <th>Type</th>
                          <th>Role</th>
                          <th style={{width:"0%"}}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.regressionData.map((item, index) => {
                          let localType = "";
                          if(item.Type){
                            if(item.Type.label){
                              localType = item.Type.label
                            }else{
                              localType = item.Type
                            }
                          }
                          let localRole = "";
                          if(item.Role){
                            if(item.Role.label){
                              localRole = item.Role.label
                            }else{
                              localRole = item.Role
                            }
                          }

                          return (
                            <tr key={index}>
                              <td
                                style={{ width: "4%" }}
                                className="dynamic_form_sl_no"
                              >
                                <label>{index + 1}</label>
                              </td>
                              <td
                                style={{
                                  width: "30%",
                                  breakWord: "break-word",
                                }}
                              >
                                <label>{item.Feature}</label>
                              </td>
                              <td style={{ width: "20%" }}>
                                {/* <label>{item.Type ? item.Type.label : item.Type}</label>  */}
                                <label>{localType}</label>
                              </td>
                              <td style={{ width: "20%" }}>
                                {/* <label>{item.Role ? item.Role.label : item.Role}</label>  */}
                                <label>{localRole}</label> 
                                
                              </td>
                              <td style={{display:'flex'}}>
                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "5px 8px",margin:'0px',marginRight:"15px" }} title="Edit" onClick={() => this.editRegressionRow(index)}><i  className="fa fa-pencil"></i></button>
                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "5px 8px",margin:'0px' }} title="Delete" onClick={() =>this.deleteRegressionRow(index)}><i className="fa fa-trash" aria-hidden="true"></i></button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className="dynamic_form_table">
                    <div className="" style={{ borderTop: "1px solid lightgray", paddingTop: "25px",marginTop: "50px"}}>
                      <div className="">
                        <div className="">
                          <div className="row d_flex_align_items_center">
                            <div className="col-md-4">
                              <label className="form-attribute">
                                <span style={{ color: "red" }}> *</span> Mean
                                Absolute Error (MAE)
                                <span style={{fontWeight: "normal",fontSize: 12,fontStyle: "italic",color: "grey"}}>&nbsp;(max. 100,000,0000)</span>
                              </label>
                              <input
                                placeholder="Please provide Mean Absolute Error"
                                className="textarea-css form-attribute hide_numeric_input_arrows"
                                type="number"
                                value={this.state.regressionMeanAbsoluteError}
                                onChange={(e) =>
                                  this.regressionMeanAbsoluteErrorInputChange(e.target.value)
                                }
                                onKeyDown={(evt) =>
                                  ( evt.key === "e" ||
                                    evt.key === "E" ||
                                    evt.key === "+" ||
                                    evt.key === "-"
                                  )&&
                                  evt.preventDefault()
                                }
                                onWheel={(e) => e.target.blur()}
                                style={
                                  this.state.selectStyle.regressionMeanAbsoluteError
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label className="form-attribute">
                                <span style={{ color: "red" }}> *</span> Mean
                                Squared Error (MSE)
                                <span style={{fontWeight: "normal",fontSize: 12,fontStyle: "italic",color: "grey"}}>&nbsp;(max. 100,000,0000)</span>
                              </label>
                              <input
                                placeholder="Please provide Mean Squared Error"
                                className="textarea-css form-attribute hide_numeric_input_arrows"
                                type="number"
                                min="0"
                                value={this.state.regressionMeanSquaredError}
                                onChange={(e) =>
                                  this.regressionMeanSquaredErrorInputChange(e.target.value)
                                }
                                onKeyDown={(evt) =>
                                  (
                                    evt.key === "e" ||
                                    evt.key === "E" ||
                                    evt.key === "+" ||
                                    evt.key === "-") &&
                                  evt.preventDefault()
                                }
                                onWheel={(e) => e.target.blur()}
                                style={
                                  this.state.selectStyle.regressionMeanSquaredError
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label className="form-attribute">
                                <span style={{ color: "red" }}> *</span> Root Mean
                                Squared Error (RMSE)
                                <span style={{fontWeight: "normal",fontSize: 12,fontStyle: "italic",color: "grey"}}>&nbsp;(max. 100,000,0000)</span>
                              </label>
                              <input
                                placeholder="Please provide Root Mean Squared Error"
                                className="textarea-css form-attribute hide_numeric_input_arrows"
                                type="number"
                                min="0"
                                value={this.state.regressionRootMeanSquaredError}
                                onChange={(e) =>
                                  this.regressionRootMeanSquaredErrorInputChange(e.target.value)
                                }
                                onKeyDown={(evt) =>
                                  (
                                    evt.key === "e" ||
                                    evt.key === "E" ||
                                    evt.key === "+" ||
                                    evt.key === "-") &&
                                  evt.preventDefault()
                                }
                                onWheel={(e) => e.target.blur()}
                                style={
                                  this.state.selectStyle
                                    .regressionRootMeanSquaredError
                                }
                              />
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div className="" style={{marginTop:"20px"}}>
                        <div className="row d_flex_align_items_center">
                          <div className="col-md-4">
                            <label className="form-attribute">
                              <span style={{ color: "red" }}> *</span> Mean Absolute Percentage Error (MAPE)
                              <span style={{fontWeight: "normal",fontSize: 12,fontStyle: "italic",color: "grey"}}>&nbsp;(max. 100,000,0000)</span>
                            </label>
                            <input
                              placeholder="Please provide mean absolute percentage error value"
                              className="textarea-css form-attribute hide_numeric_input_arrows"
                              type="number"
                              value={this.state.regressionMeanAbsolutePercentageError}
                              onChange={(e) =>this.regressionMeanAbsolutePercentageErrorInputChange(e.target.value)}
                              onKeyDown={(evt) =>
                                (evt.key === "e" ||
                                  evt.key === "E" ||
                                  evt.key === "+" ||
                                  evt.key === "-") &&
                                evt.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                              style={this.state.selectStyle.regressionMeanAbsolutePercentageError}
                            />
                          </div>
                          <div className="col-md-4">
                            <div className="">
                              <button
                                className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3 dynamic_form_add_row_btn"
                                onClick={(e) =>
                                  this.addMoreRegressionMatrices(
                                    this.state.regressionCounter
                                  )
                                }
                                style={{ marginTop: "28px" }}
                              >
                                Add Metrics
                              </button>
                              {
                                this.state.regressionCounter > 0 
                                ?
                                <>
                                  <button
                                    className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3 dynamic_form_add_row_btn"
                                    onClick={(e) =>
                                      this.deleteMoreRegressionMatrices(
                                        this.state.regressionCounter
                                      )
                                    }
                                    style={{ marginTop: "23px" }}
                                  >
                                    Delete Metrics
                                  </button>
                                </>
                                :
                                null
                              }
                              
                            </div>
                          </div>
                          <div className="col-md-2">
                            
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="regression_metrices_block">
                      <div className="edit_row_field_blocks">
                        <div className="row d_flex_align_items_center">
                          <div className="col-md-3">
                            {this.state.regressionMetricesOne ? (
                              <>
                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                    placeholder="Please fill label value for Metrics 1. (Max 60 Chars)"
                                    className="textarea-css form-attribute label_input"
                                    type="text"
                                    value={this.state.regressionMetricesOneLabel}
                                    onChange={this.regressionMetricesLabelChange}
                                    style={
                                      this.state.selectStyle
                                        .regressionMetricesOneLabel
                                    }
                                  />
                                </div>
                                
                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                  placeholder="Please fill input value for Metrics 1"
                                  className="textarea-css form-attribute hide_numeric_input_arrows"
                                  type="number"
                                  value={this.state.regressionMetricesOneInput}
                                  onChange={this.regressionMetricesInputChange}
                                  onKeyDown={(evt) =>
                                    (
                                      evt.key === "e" ||
                                      evt.key === "E" ||
                                      evt.key === "+" ||
                                      evt.key === "-") &&
                                    evt.preventDefault()
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  style={
                                    this.state.selectStyle
                                      .regressionMetricesOneInput
                                  }
                                />
                                </div>
                                
                              </>
                            ) : null}
                          </div>
                          <div className="col-md-3">
                            {this.state.regressionMetricesTwo ? (
                              <>
                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                    placeholder="Please fill label value for Metrics 2. (Max 60 Chars)"
                                    className="textarea-css form-attribute label_input"
                                    type="text"
                                    value={this.state.regressionMetricesTwoLabel}
                                    onChange={
                                      this.regressionMetricesLabelTwoChange
                                    }
                                    style={
                                      this.state.selectStyle
                                        .regressionMetricesTwoLabel
                                    }
                                  />

                                </div>
                                

                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                    placeholder="Please fill input value for Metrics 2"
                                    className="textarea-css form-attribute hide_numeric_input_arrows"
                                    type="number"
                                    value={this.state.regressionMetricesTwoInput}
                                    onChange={
                                      this.regressionMetricesInputTwoChange
                                    }
                                    onKeyDown={(evt) =>
                                      ( evt.key === "e" ||
                                        evt.key === "E" ||
                                        evt.key === "+" ||
                                        evt.key === "-") &&
                                      evt.preventDefault()
                                    }
                                    onWheel={(e) => e.target.blur()}
                                    style={
                                      this.state.selectStyle
                                        .regressionMetricesTwoInput
                                    }
                                  />
                                </div>
                                
                              </>
                            ) : null}
                          </div>
                          <div className="col-md-3">
                            {this.state.regressionMetricesThree ? (
                              <>
                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                    placeholder="Please fill label value for Metrics 3. (Max 60 Chars)"
                                    className="textarea-css form-attribute label_input"
                                    type="text"
                                    value={
                                      this.state.regressionMetricesThreeLabel
                                    }
                                    onChange={
                                      this.regressionMetricesLabelThreeChange
                                    }
                                    style={
                                      this.state.selectStyle
                                        .regressionMetricesThreeLabel
                                    }
                                  />
                                </div>
                                
                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                    placeholder="Please fill input value for Metrics 3"
                                    className="textarea-css form-attribute hide_numeric_input_arrows"
                                    type="number"
                                    value={
                                      this.state.regressionMetricesThreeInput
                                    }
                                    onChange={
                                      this.regressionMetricesInputThreeChange
                                    }
                                    onKeyDown={(evt) =>
                                      (
                                        evt.key === "e" ||
                                        evt.key === "E" ||
                                        evt.key === "+" ||
                                        evt.key === "-") &&
                                      evt.preventDefault()
                                    }
                                    onWheel={(e) => e.target.blur()}
                                    style={
                                      this.state.selectStyle
                                        .regressionMetricesThreeInput
                                    }
                                  />
                                </div>
                                
                              </>
                            ) : null}
                          </div>


                          <div className="col-md-3">
                            {this.state.regressionMetricesFour ? (
                              <>
                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                    placeholder="Please fill label value for Metrics 4. (Max 60 Chars)"
                                    className="textarea-css form-attribute label_input"
                                    type="text"
                                    value={this.state.regressionMetricesFourLabel}
                                    onChange={
                                      this.regressionMetricesLabelFourChange
                                    }
                                    style={
                                      this.state.selectStyle
                                        .regressionMetricesFourLabel
                                    }
                                  />
                                </div>
                                

                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                    placeholder="Please fill input value for Metrics 4"
                                    className="textarea-css form-attribute hide_numeric_input_arrows"
                                    type="number"
                                    value={this.state.regressionMetricesFourInput}
                                    onChange={
                                      this.regressionMetricesInputFourChange
                                    }
                                    onKeyDown={(evt) =>
                                      (
                                        evt.key === "e" ||
                                        evt.key === "E" ||
                                        evt.key === "+" ||
                                        evt.key === "-") &&
                                      evt.preventDefault()
                                    }
                                    onWheel={(e) => e.target.blur()}
                                    style={
                                      this.state.selectStyle
                                        .regressionMetricesFourInput
                                    }
                                  />
                                </div>


                                
                              </>
                            ) : null}
                          </div>


                        </div>
                      </div>
                      <div className="edit_row_field_blocks">
                        <div className="row d_flex_align_items_center">
                          
                          <div className="col-md-3">
                            {this.state.regressionMetricesFive ? (
                              <>

                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                    placeholder="Please fill label value for Metrics 5. (Max 60 Chars)"
                                    className="textarea-css form-attribute label_input"
                                    type="text"
                                    value={this.state.regressionMetricesFiveLabel}
                                    onChange={
                                      this.regressionMetricesLabelFiveChange
                                    }
                                    style={
                                      this.state.selectStyle
                                        .regressionMetricesFiveLabel
                                    }
                                  />
                                </div>

                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                    placeholder="Please fill input value for Metrics 5"
                                    className="textarea-css form-attribute hide_numeric_input_arrows"
                                    type="number"
                                    value={this.state.regressionMetricesFiveInput}
                                    onChange={
                                      this.regressionMetricesInputFiveChange
                                    }
                                    onKeyDown={(evt) =>
                                      (
                                        evt.key === "e" ||
                                        evt.key === "E" ||
                                        evt.key === "+" ||
                                        evt.key === "-") &&
                                      evt.preventDefault()
                                    }
                                    onWheel={(e) => e.target.blur()}
                                    style={
                                      this.state.selectStyle
                                        .regressionMetricesFiveInput
                                    }
                                  />
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {/* regression template ends here */}

              {/* forecast template starts here */}
              {this.state.showForecastTemplate ? (
                <>
                  <div className="dynamic_form_table">
                    <div
                      className=""
                      style={{ borderTop: "1px solid lightgrey" }}
                    />
                    <h2>Forecast</h2>
                    <div className="row">
                      <div className="col-md-8">
                        <button className="dynamic_form_max_row_btn" disabled>
                          {" "}
                          Max Limit: 30 Rows
                        </button>
                      </div>
                      <div
                        className="col-md-4"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          className="dynamic_form_add_row_btn"
                          style={{ marginRight: "0px" }}
                          onClick={this.openForecastModal}
                          disabled={this.state.counter === 30 ? true : false}
                        >
                          Add Row
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="text-left mt-4">
                    {this.state.showForecastModal ? (
                      <>
                        <div className="">
                          <div className="custom_modal classification_template_modal">
                            <div className="modal_body" style={{}}>
                              <div className="row">
                                <div className="col-md-11">
                                  <h3 className="modal_title text-center">
                                    Add / Update Data
                                  </h3>
                                </div>
                                <div className="col-md-1">
                                  <button
                                    type="button"
                                    className="close text-right"
                                    onClick={this.closeForecastModal}
                                  >
                                    &times;
                                  </button>
                                </div>
                              </div>
                              <div className="edit_row_field_blocks">
                                <div className="row">
                                  <div className="col-md-6">
                                    <label className="form-attribute">
                                      <span style={{ color: "red" }}> *</span>{" "}
                                      Variables
                                      <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 30 chars)</span>
                                    </label>
                                    <input
                                      placeholder="Please provide variables"
                                      className="textarea-css form-attribute"
                                      type="text"
                                      name="Feature"
                                      value={currentForecastData.Feature}
                                      onChange={this.handleForecastInputChange}
                                      style={{ maxWidth: '100%', ...this.state.selectStyle.currentForecastData.Feature }}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <label className="form-attribute">
                                      <span style={{ color: "red" }}> *</span>{" "}
                                      Type
                                    </label>
                                    <Select
                                      isSearchable={false}
                                      name="Type"
                                      options={
                                        this.state.classificationTypeOptions
                                      }
                                      onChange={(e) =>
                                        this.handleForecastSelectChange(
                                          e,
                                          currentForecastData,
                                          "type"
                                        )
                                      }
                                      value={currentForecastData.Type}
                                      placeholder="Please choose type"
                                      styles={this.state.selectStyle.currentForecastData.Type}
                                    />
                                  </div>
                                </div>
                                <div className="edit_row_gap_between_rows">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label className="form-attribute">
                                        <span style={{ color: "red" }}> *</span>{" "}
                                        Role
                                      </label>
                                      <Select
                                        isSearchable={false}
                                        name="Role"
                                        options={this.state.roles}
                                        onChange={(e) =>
                                          this.handleForecastSelectChange(
                                            e,
                                            currentForecastData,
                                            "role"
                                          )
                                        }
                                        value={currentForecastData.Role}
                                        placeholder="Please choose role"
                                        styles={this.state.selectStyle.currentForecastData.Role}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="cluster_modal_btn_group">
                                <button
                                  className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3 saveEditedRowBtn"
                                  onClick={this.addForecastRow}
                                >
                                  Save
                                </button>
                                <button
                                  className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                  onClick={this.closeForecastModal}
                                  style={{ marginRight: "15px" }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="dynamic_form_table">
                    <table className="table review-idea-table">
                      <thead>
                        <tr>
                          <th className="dynamic_form_sl_no_th">Sr No</th>
                          <th>Variables</th>
                          <th>Type</th>
                          <th>Role</th>
                          <th style={{width:"0%"}}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.forecastData.map((item, index) => {
                          let localType = "";
                          if(item.Type){
                            if(item.Type.label){
                              localType = item.Type.label
                            }else{
                              localType = item.Type
                            }
                          }
                          let localRole = "";
                          if(item.Role){
                            if(item.Role.label){
                              localRole = item.Role.label
                            }else{
                              localRole = item.Role
                            }
                          }
                          return (
                            <tr key={index}>
                              <td
                                style={{ width: "4%" }}
                                className="dynamic_form_sl_no"
                              >
                                <label>{index + 1}</label>
                              </td>
                              <td style={{ width: "30%" }}>
                                <label>{item.Feature}</label>
                              </td>
                              <td style={{ width: "10%" }}>
                                {/* <label>{item.Type ? item.Type.label : item.Type}</label> */}
                                <label>{localType}</label>
                              </td>
                              <td style={{ width: "10%" }}>
                                {/* <label>{item.Role ? item.Role.label : item.Role}</label> */}
                                <label>{localRole}</label> 
                              </td>
                              <td style={{display:'flex'}}>
                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "5px 8px",margin:'0px',marginRight:"15px" }} title="Edit" onClick={() => this.editForecastRow(index)}><i  className="fa fa-pencil"></i></button>
                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "5px 8px",margin:'0px' }} title="Delete" onClick={() => this.deleteForecastRow(index)}><i className="fa fa-trash" aria-hidden="true"></i></button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="dynamic_form_table">
                    <div
                      className=""
                      style={{
                        borderTop: "1px solid lightgray",
                        paddingTop: "25px",
                        marginTop: "50px",
                      }}
                    >
                      <div className="row d_flex_align_items_center">
                        <div className="col-md-4">
                          <label className="form-attribute">
                            <span style={{ color: "red" }}> *</span> Mean
                            Absolute Error (MAE)
                            <span style={{fontWeight: "normal",fontSize: 12,fontStyle: "italic",color: "grey"}}>&nbsp;(max. 100,000,0000)</span>
                          </label>
                          <input
                            placeholder="Please provide Mean Absolute Error"
                            className="textarea-css form-attribute hide_numeric_input_arrows"
                            type="number"
                            value={this.state.forecastMeanAbsoluteError}
                            onChange={this.forecastMeanAbsoluteErrorInputChange}
                            onKeyDown={(evt) =>
                              (
                                evt.key === "e" ||
                                evt.key === "E" ||
                                evt.key === "+" ||
                                evt.key === "-") &&
                              evt.preventDefault()
                            }
                            onWheel={(e) => e.target.blur()}
                            style={
                              this.state.selectStyle.forecastMeanAbsoluteError
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="form-attribute">
                            <span style={{ color: "red" }}> *</span> Mean
                            Squared Error (MSE)
                            <span style={{fontWeight: "normal",fontSize: 12,fontStyle: "italic",color: "grey"}}>&nbsp;(max. 100,000,0000)</span>
                          </label>
                          <input
                            placeholder="Please provide Mean Squared Error"
                            className="textarea-css form-attribute hide_numeric_input_arrows"
                            type="number"
                            min="0"
                            value={this.state.forecastMeanSquaredError}
                            onChange={this.forecastMeanSquaredErrorInputChange}
                            onKeyDown={(evt) =>
                              (
                                evt.key === "e" ||
                                evt.key === "E" ||
                                evt.key === "+" ||
                                evt.key === "-") &&
                              evt.preventDefault()
                            }
                            onWheel={(e) => e.target.blur()}
                            style={
                              this.state.selectStyle.forecastMeanSquaredError
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="form-attribute">
                            <span style={{ color: "red" }}> *</span> Root Mean
                            Squared Error (RMSE)
                            <span style={{fontWeight: "normal",fontSize: 12,fontStyle: "italic",color: "grey"}}>&nbsp;(max. 100,000,0000)</span>
                          </label>
                          <input
                            placeholder="Please provide Root Mean Squared Error"
                            className="textarea-css form-attribute hide_numeric_input_arrows"
                            type="number"
                            min="0"
                            value={this.state.forecastRootMeanSquaredError}
                            onChange={
                              this.forecastRootMeanSquaredErrorInputChange
                            }
                            onKeyDown={(evt) =>
                              (
                                evt.key === "e" ||
                                evt.key === "E" ||
                                evt.key === "+" ||
                                evt.key === "-") &&
                              evt.preventDefault()
                            }
                            onWheel={(e) => e.target.blur()}
                            style={
                              this.state.selectStyle
                                .forecastRootMeanSquaredError
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="" style={{marginTop:'20px'}}>
                      <div className="row">
                        <div className="col-md-4">
                          <label className="form-attribute">
                            <span style={{ color: "red" }}> *</span>Mean Absolute Percentage Error (MAPE)
                            <span style={{fontWeight: "normal",fontSize: 12,fontStyle: "italic",color: "grey"}}>&nbsp;(max. 100,000,0000)</span>
                          </label>
                          <input
                            placeholder="Please provide mean absolute percentage error"
                            className="textarea-css form-attribute hide_numeric_input_arrows"
                            type="number"
                            value={this.state.forecastMeanAbsolutePercentageError}
                            onChange={this.forecastMeanAbsolutePercentageErrorInputChange}
                            onKeyDown={(evt) =>
                              (
                                evt.key === "e" ||
                                evt.key === "E" ||
                                evt.key === "+" ||
                                evt.key === "-") &&
                              evt.preventDefault()
                            }
                            onWheel={(e) => e.target.blur()}
                            style={this.state.selectStyle.forecastMeanAbsolutePercentageError}
                          />
                        </div>
                        
                        <div className="col-md-4">
                          <div className="">
                            <button
                              className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3 dynamic_form_add_row_btn"
                              onClick={(e) =>
                                this.addMoreForecastMatrices(
                                  this.state.forecastCounter
                                )
                              }
                              style={{ marginTop: "28px" }}
                            >
                              Add Metrics
                            </button>
                            {
                              this.state.forecastCounter > 0
                              ?
                              <>
                                <button
                                className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3 dynamic_form_add_row_btn"
                                onClick={(e) =>
                                  this.deleteMoreForecastMatrices(
                                    this.state.forecastCounter
                                  )
                                }
                                style={{ marginTop: "23px" }}
                              >
                                Delete Metrics
                              </button>
                              </>
                              :
                              null
                            }
                            
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="regression_metrices_block">
                      <div className="edit_row_field_blocks">
                        <div className="row d_flex_align_items_center">
                          <div className="col-md-3">
                            {this.state.forecastMetricesOne ? (
                              <>
                              <div className="predictive_metrics_mandatory">
                                <span>*</span>
                                <input
                                  placeholder="Please fill label value for Metrics 1. (Max 60 chars)"
                                  className="textarea-css form-attribute label_input"
                                  type="text"
                                  value={this.state.forecastMetricesOneLabel}
                                  onChange={this.forcastMetricesLabelChange}
                                  style={
                                    this.state.selectStyle
                                      .forecastMetricesOneLabel
                                  }
                                />
                              </div>
                                
                              <div className="predictive_metrics_mandatory">
                                <span>*</span>
                                <input
                                  placeholder="Please fill input value for Metrics 1"
                                  className="textarea-css form-attribute hide_numeric_input_arrows"
                                  type="number"
                                  value={this.state.forecastMetricesOneInput}
                                  onChange={this.forcastMetricesInputChange}
                                  onKeyDown={(evt) =>
                                    (evt.key === "e" ||
                                      evt.key === "E" ||
                                      evt.key === "+" ||
                                      evt.key === "-") &&
                                    evt.preventDefault()
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  style={
                                    this.state.selectStyle
                                      .forecastMetricesOneInput
                                  }
                                />
                              </div>
                                
                              </>
                            ) : null}
                          </div>
                          <div className="col-md-3">
                            {this.state.forecastMetricesTwo ? (
                              <>
                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                    placeholder="Please fill label value for Metrics 2. (Max 60 chars)"
                                    className="textarea-css form-attribute label_input"
                                    type="text"
                                    value={this.state.forecastMetricesTwoLabel}
                                    onChange={this.forcastMetricesLabelTwoChange}
                                    style={
                                      this.state.selectStyle
                                        .forecastMetricesTwoLabel
                                    }
                                  />
                                </div>
                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                    placeholder="Please fill input value for Metrics 2"
                                    className="textarea-css form-attribute hide_numeric_input_arrows"
                                    type="number"
                                    value={this.state.forecastMetricesTwoInput}
                                    onChange={this.forcastMetricesInputTwoChange}
                                    onKeyDown={(evt) =>
                                      (evt.key === "e" ||
                                        evt.key === "E" ||
                                        evt.key === "+" ||
                                        evt.key === "-") &&
                                      evt.preventDefault()
                                    }
                                    onWheel={(e) => e.target.blur()}
                                    style={
                                      this.state.selectStyle
                                        .forecastMetricesTwoLabel
                                    }
                                  />
                                </div>
                              </>
                            ) : null}
                          </div>
                          <div className="col-md-3">
                            {this.state.forecastMetricesThree ? (
                              <>
                              <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                    placeholder="Please fill label value for Metrics 3. (Max 60 chars)"
                                    className="textarea-css form-attribute label_input"
                                    type="text"
                                    value={this.state.forecastMetricesThreeLabel}
                                    onChange={
                                      this.forcastMetricesLabelThreeChange
                                    }
                                    style={
                                      this.state.selectStyle
                                        .forecastMetricesThreeLabel
                                    }
                                  />
                                </div>
                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                  placeholder="Please fill input value for Metrics 3"
                                  className="textarea-css form-attribute hide_numeric_input_arrows"
                                  type="number"
                                  value={this.state.forecastMetricesThreeInput}
                                  onChange={
                                    this.forcastMetricesInputThreeChange
                                  }
                                  onKeyDown={(evt) =>
                                    ( evt.key === "e" ||
                                      evt.key === "E" ||
                                      evt.key === "+" ||
                                      evt.key === "-") &&
                                    evt.preventDefault()
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  style={
                                    this.state.selectStyle
                                      .forecastMetricesThreeLabel
                                  }
                                />
                                </div>
                                
                              </>
                            ) : null}
                          </div>
                          <div className="col-md-3">
                            {this.state.forecastMetricesFour ? (
                              <>
                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                  placeholder="Please fill label value for Metrics 4. (Max 60 chars)"
                                  className="textarea-css form-attribute label_input"
                                  type="text"
                                  value={this.state.forecastMetricesFourLabel}
                                  onChange={this.forcastMetricesLabelFourChange}
                                  style={
                                    this.state.selectStyle
                                      .forecastMetricesFourLabel
                                  }
                                />
                                </div>
                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                  placeholder="Please fill input value for Metrics 4"
                                  className="textarea-css form-attribute hide_numeric_input_arrows"
                                  type="number"
                                  value={this.state.forecastMetricesFourInput}
                                  onChange={this.forcastMetricesInputFourChange}
                                  onKeyDown={(evt) =>
                                    ( evt.key === "e" ||
                                      evt.key === "E" ||
                                      evt.key === "+" ||
                                      evt.key === "-") &&
                                    evt.preventDefault()
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  style={
                                    this.state.selectStyle
                                      .forecastMetricesFourLabel
                                  }
                                />
                                </div>
                                
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {/* <div className="edit_row_field_blocks">
                        <div className="row d_flex_align_items_center">
                          
                          <div className="col-md-3">
                            {this.state.forecastMetricesFive ? (
                              <>

                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                  placeholder="Please fill label value for Metrics 5"
                                  className="textarea-css form-attribute label_input"
                                  type="text"
                                  value={this.state.forecastMetricesFiveLabel}
                                  onChange={this.forcastMetricesLabelFiveChange}
                                  style={
                                    this.state.selectStyle
                                      .forecastMetricesFiveLabel
                                  }
                                />
                                </div>
                                

                                <div className="predictive_metrics_mandatory">
                                  <span>*</span>
                                  <input
                                  placeholder="Please fill input value for Metrics 5"
                                  className="textarea-css form-attribute hide_numeric_input_arrows"
                                  type="number"
                                  value={this.state.forecastMetricesFiveInput}
                                  onChange={this.forcastMetricesInputFiveChange}
                                  onKeyDown={(evt) =>
                                    (
                                      evt.key === "e" ||
                                      evt.key === "E" ||
                                      evt.key === "+" ||
                                      evt.key === "-") &&
                                    evt.preventDefault()
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  style={
                                    this.state.selectStyle
                                      .forecastMetricesFiveInput
                                  }
                                />
                                </div>
                                
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </>
              ) : null}
              {/* forecast template ends here */}

              {/* Sentiment analysis calculation table starts here */}

              {this.state.showSentimentTemplate ? (
                <>
                  <div className="dynamic_form_table">
                    <div className="sentiment_analysis_block">
                      <h2>Sentiment Analysis</h2>
                      <div className="edit_row_gap_between_rows">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="form-attribute">
                              <span style={{ color: "red" }}> *</span> Good to
                              Have - Checks
                              <span style={{fontWeight: "normal",fontSize: 12,fontStyle: "italic",color: "grey"}}>&nbsp;(max. 100,000,0000)</span>
                            </label>
                            <input
                              value={this.state.sentimentValues.goodToHave}
                              placeholder="Please provide Good to have checks value"
                              className="textarea-css form-attribute hide_arrow_keys"
                              type="number"
                              name="goodToHave"
                              onChange={this.handleSentimentInputChange}
                              min="0"
                              
                              onKeyDown={(evt) =>
                                (evt.key === "." ||
                                  evt.key === "e" ||
                                  evt.key === "E" ||
                                  evt.key === "+" ||
                                  evt.key === "-") &&
                                evt.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                            />
                          </div>
                          <div className="col-md-6">
                            <label className="form-attribute">
                              <span style={{ color: "red" }}> *</span> Mixed
                              Sentiments
                              <span style={{fontWeight: "normal",fontSize: 12,fontStyle: "italic",color: "grey"}}>&nbsp;(max. 100,000,0000)</span>
                            </label>
                            <input
                              value={this.state.sentimentValues.mixedSentiments}
                              placeholder="Please provide Mixed Sentiments value"
                              className="textarea-css form-attribute"
                              type="number"
                              name="mixedSentiments"
                              onChange={this.handleSentimentInputChange}
                              min="0"
                              onKeyDown={(evt) =>
                                (evt.key === "." ||
                                  evt.key === "e" ||
                                  evt.key === "E" ||
                                  evt.key === "+" ||
                                  evt.key === "-") &&
                                evt.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="edit_row_gap_between_rows">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="form-attribute">
                              <span style={{ color: "red" }}> *</span> No checks
                              needed
                              <span style={{fontWeight: "normal",fontSize: 12,fontStyle: "italic",color: "grey"}}>&nbsp;(max. 100,000,0000)</span>
                            </label>
                            <input
                              value={this.state.sentimentValues.noChecksNeeded}
                              placeholder="Please provide no checks needed value"
                              className="textarea-css form-attribute"
                              type="number"
                              name="noChecksNeeded"
                              onChange={this.handleSentimentInputChange}
                              min="0"
                              onKeyDown={(evt) =>
                                (evt.key === "." ||
                                  evt.key === "e" ||
                                  evt.key === "E" ||
                                  evt.key === "+" ||
                                  evt.key === "-") &&
                                evt.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                            />
                          </div>
                          <div className="col-md-6">
                            <label className="form-attribute">
                              <span style={{ color: "red" }}> *</span> Priority
                              checks
                              <span style={{fontWeight: "normal",fontSize: 12,fontStyle: "italic",color: "grey"}}>&nbsp;(max. 100,000,0000)</span>
                            </label>
                            <input
                              value={this.state.sentimentValues.priorityChecks}
                              placeholder="Please provide priority checks value"
                              className="textarea-css form-attribute"
                              type="number"
                              name="priorityChecks"
                              onChange={this.handleSentimentInputChange}
                              min="0"
                              onKeyDown={(evt) =>
                                (evt.key === "." ||
                                  evt.key === "e" ||
                                  evt.key === "E" ||
                                  evt.key === "+" ||
                                  evt.key === "-") &&
                                evt.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="edit_row_gap_between_rows">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="form-attribute">
                              <span style={{ color: "red" }}> *</span>{" "}
                              Duplicates
                              <span style={{fontWeight: "normal",fontSize: 12,fontStyle: "italic",color: "grey"}}>&nbsp;(max. 100,000,0000)</span>
                            </label>
                            <input
                              value={this.state.sentimentValues.sentimentDuplicates}
                              placeholder="Please provide duplicates value"
                              className="textarea-css form-attribute"
                              type="number"
                              name="sentimentDuplicates"
                              onChange={this.handleSentimentInputChange}
                              min="0"
                              onKeyDown={(evt) =>
                                (evt.key === "." ||
                                  evt.key === "e" ||
                                  evt.key === "E" ||
                                  evt.key === "+" ||
                                  evt.key === "-") &&
                                evt.preventDefault()
                              }
                              onWheel={(e) => e.target.blur()}
                            />
                          </div>
                          <div className="col-md-6">
                            <label className="form-attribute">Total</label>
                            <input
                              placeholder="Total"
                              className="form-input"
                              type="number"
                              disabled={true}
                              min="0"
                              value={this.state.sentimentTotal}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {/* Sentiment analysis calculation table ends here */}

              {/* Anamoly Template starts here */}
              {this.state.showAnamolyTemplate ? (
                <>
                  <div className="dynamic_form_table">
                    <div className="" style={{borderTop:"1px solid lightgrey"}}></div>
                    <h2>Anamoly</h2>
                    <div className="row" style={{ marginBottom: "20px" }}>
                      <div className="col-md-8">
                        <button
                          className="dynamic_form_max_row_btn"
                          disabled
                        >
                          {" "}
                          Max Rows Limit: 30 & Columns Limit: 5
                        </button>
                        <button style={{marginLeft:"15px"}} className="dynamic_form_add_row_btn" onClick={(e)=>this.handleAnamolyReset()}>Reset</button>
                      </div>

                      <div
                        className="col-md-4"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {
                          this.state.displayAnamolyRowButton ?
                            <>
                              <button className="dynamic_form_add_row_btn"
                                onClick={(e) => this.addAnamolyRow()}
                              >Add Row
                              </button>
                            </>
                            :
                            null
                        }

                        <button
                          className="dynamic_form_add_row_btn"
                          style={{ marginRight: "0px" }}
                          onClick={this.addAnamolyColumn}
                        >
                          {this.state.displayAnamolyRowButton ? "Add Column" : "Add Row"}
                        </button>
                      </div>
                    </div>
                    <div className="">
                    <div className="">
                      <table className="table review-idea-table">
                        <tbody>
                          {this.state.anomolyData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {
                                this.state.displayAnamolyRowButton
                                ?
                                <>
                                  <td style={{width: "39px"}}><label>{rowIndex+1}</label></td>
                                </>
                                :
                                null
                              }
                              
                              {AnamolyColumns.map((column, columnIndex) => (
                                <td key={columnIndex}>
                                  <textarea
                                    placeholder="Max 500 characters"
                                    data-toggle="tooltip" title={row[column]}
                                    className="textarea-css form-attribute anamoly_textarea textarea_resize_none"
                                    type="text"
                                    value={row[column] || ""}
                                    onChange={(e) =>
                                      this.handleAnamolyTableCellChange(rowIndex, column, e.target.value)
                                    }
                                  />
                                </td>
                              ))}
                              {
                                this.state.displayAnamolyRowButton
                                ?
                                <>
                                  <td style={{width:"2%"}}><button className="anamoly_delete_btn" onClick={(e)=>this.handleAnamolyRowDelete(row, rowIndex)}><i  className="fa fa-trash" aria-hidden="true"></i></button></td>
                                </>
                                :
                                null
                              }
                              
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                      
                    </div>
                  </div>


                </>
              ) : null}
              {/* Anamoly Template ends here */}

              {/* Others Template starts here */}
              {this.state.showOthersTemplate ? (
                <>
                  <div className="dynamic_form_table">
                    <div className="" style={{borderTop:"1px solid lightgrey"}}></div>
                    <h2>Others</h2>
                    <div className="row" style={{ marginBottom: "20px" }}>
                      <div className="col-md-8">
                        <button
                          className="dynamic_form_max_row_btn"
                          disabled
                        >
                          {" "}
                          Max Rows Limit: 30 & Columns Limit: 5
                        </button>
                        <button style={{marginLeft:"15px"}} className="dynamic_form_add_row_btn" onClick={(e)=>this.handleOthersReset()}>Reset</button>
                      </div>
                      <div
                        className="col-md-4"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {
                          this.state.displayOthersRowButton
                            ?
                            <>
                              <button
                                className="dynamic_form_add_row_btn"
                                onClick={this.addOthersRow}
                              >
                                Add Row
                              </button>
                            </>
                            :
                            null
                        }

                        <button
                          className="dynamic_form_add_row_btn"
                          style={{ marginRight: "0px" }}
                          onClick={this.addOthersColumn}
                        >
                          {this.state.displayOthersRowButton ? "Add Column" : "Add Row"}
                        </button>
                      </div>
                    </div>
                    <div className="">
                      <table className="table review-idea-table">
                        <tbody>
                          {this.state.othersData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {
                                this.state.displayOthersRowButton
                                ?
                                <>
                                  <td style={{width: "39px"}}><label>{rowIndex+1}</label></td>
                                </>
                                :
                                null
                              }
                              {OthersColumns.map((column, columnIndex) => (
                                <td key={columnIndex}>
                                  <textarea
                                    placeholder="Max 500 characters"
                                    data-toggle="tooltip" title={row[column]}
                                    className="textarea-css form-attribute anamoly_textarea textarea_resize_none"
                                    type="text"
                                    value={row[column] || ""}
                                    onChange={(e) =>
                                      this.handleOthersTableCellChange(rowIndex, column, e.target.value)
                                    }
                                  />
                                </td>
                              ))}
                              {
                                this.state.displayOthersRowButton
                                ?
                                <>
                                  <td style={{width:"2%"}}><button className="anamoly_delete_btn" onClick={(e)=>this.handleOthersRowDelete(row, rowIndex)}><i  className="fa fa-trash" aria-hidden="true"></i></button></td>
                                </>
                                :
                                null
                              }
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              ) : null}
              {/* Others Template ends here */}

              <div className="" style={{ padding: "0px 30px" }}>
                <div className="templateGoBackBtnBlock">
                  <div className="btn-group">
                    <button
                      className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3 dynamic_form_add_row_btn"
                      style={{ marginRight: "15px " }}
                      onClick={(e) => this.savePredictiveTemplate()}
                    >
                      Save
                    </button>
                    <button
                      className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                      onClick={(e) => this.cancelEvent()}
                    >
                      Cancel
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
