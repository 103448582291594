import React, { Component } from 'react'
import { APPROVED, GET_ALL_IDEA_URL, PENDING, SENT_BACK, REJECTED, AWAITING_AI_BOT_REVIEW,APPROVAL, LEAN_APPROVAL,YESORNO, GET_METADATA, REVIEW_IDEA_URL,SUBMIT_IDEA_URL,AWAITING_E360_SPOC_APPROVAL } from '../assets/constants/constants';
import { SORT_DOWN, SORT_UP, SEARCH_ICON, ANGLE_LEFT, ANGLE_RIGHT, ANGLE_DOUBLE_LEFT, ANGLE_DOUBLE_RIGHT, FILTER, CLOSE } from "../assets/constants/Icons.js";
import { VIEW, USER_DATA, ACCESS_TOKEN } from "../assets/constants/constants";
import SortByColumn from "../utils/SortByColumns";
import Toast from 'light-toast';
import Select from 'react-select';
import { selectValidation as textBoxValidation } from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";

export class ViewAllIdeaPage extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);
    
    constructor(props) {
        super(props)
        let propsData= this.props.location.propsList ? this.props.location.propsList : null;
        if (this.sessionData === null) {
            Toast.loading("This component cannot be accessed directly. Redirecting to application home.", 3000, () => {
            });
            window.location = "/login";
            return;
        }
        const windowUrl = window.location.search;
        const isApprovalPage = windowUrl.indexOf(APPROVAL) > 0;
        const isLeanApprovalPage = windowUrl.indexOf(LEAN_APPROVAL) > 0; 
        this.state = {
            ideaList: [],
            originalIdeaList: [],
            accountSortIconRotate: false,
            ideaNameSortIconRotate: false,
            createdDateSortIconRotate: false,
            reviewStatusSortIcon: false,
            ownerOHR: this.sessionData === null ? null : this.sessionData.profile.oHRId,
            ownerName: this.sessionData === null ? null : this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
            approvalPage: isApprovalPage,
            leanApprovalPage: isLeanApprovalPage,
            pageTitle: isApprovalPage ? ( isLeanApprovalPage ? "Approve lean projects" : "Approve ideas") : "My ideas",
            topCountOption: [5,10,25, 50,75,100],
            topCount:propsData !== null ? parseInt(propsData.topCount) :5,
            skipCount:0,
            isPrevEnabled:false,
            isNextEnabled:true,
            IdeaLength:0,
            totalPages:0,
            currentPage:0,
            pageNo:'',
            filter:false,
            WorkflowStatus:'',
            IdeaId:'',
            AccountManual:'',
            RegionManual:'',
            VerticalManual:'',
            TransformationLeverName:'',
            CertificationLevelName:'',
            SubVerticalName:'', 
            ServiceLineName:'', 
            SubServiceLine:'',
            startDate:'', 
            toDate:'',
            isTypedData:false,
            WhereClause:'',
            fromFilterSelection:'',
            globalsearchText:'',
            isTypedInFilter:'',
            isTypedInGlobalSearch:false,
            tempWorkflowStatus:'',
            tempIdeaId:'',
            tempAccountManual:'',
            tempRegionManual:'',
            tempVerticalManual:'',
            tempTransformationLeverName:'',
            tempCertificationLevelName:'',
            tempSubVerticalName:'', 
            tempServiceLineName:'', 
            tempSubServiceLine:'',
            tempstartDate:'', 
            temptoDate:'',
            accountList: [],
            accountSelectionOption: null,
            workFlowStatusList: [],
            workFlowStatusSelectedOption: null,
            transformationLeversList: [],
            transSelectedOption: null,
            serviceLineList: [],
            subServiceLineList: [],
            serviceLineSelectedOption: null,
            subServiceLineSelectedOption: null,
            regionList: [],
            verticalList: [],
            subVerticalList: [],
            regionSelectedOption: null,
            verticalSelectedOption: null,
            subVerticalSelectedOption: null,
            dateList: ["Created date","Reviewed date"],
            dateSelectedOption:null,
            selectedDate:'',
            propsData:propsData !== null ? propsData: null,
            certificationLevelsList: [],
            certLevelsSelectedOption: null,
            selectedIdea:[],
            bulkCancel:false,
            bulkActive:false,
            bulkOperation:false,
            selectStyle: {},
            adminUserList:[],
            admin:false,
            Comment: "",
            approvalStatusSelectedOption: null,
            approvalStatusList:[{
                ApprovalStatusId:1,
                ApprovalStatusName:"NA"
            },
            {
                ApprovalStatusId:2,
                ApprovalStatusName:"Pending Documents"
            },
            {
                ApprovalStatusId:3,
                ApprovalStatusName:"Documents Uploaded"
            },
            {
                ApprovalStatusId:4,
                ApprovalStatusName:"Awaiting MBB Approval"
            },
            {
                ApprovalStatusId:5,
                ApprovalStatusName:"Awaiting LDT Approval"
            },
            {
                ApprovalStatusId:6,
                ApprovalStatusName:"Rejected"
            },
            {
                ApprovalStatusId:7,
                ApprovalStatusName:"Completed"
            },
            {
                ApprovalStatusId:8,
                ApprovalStatusName:"Awaiting Project Creation"
            },
            {
                ApprovalStatusId:9,
                ApprovalStatusName:"Awaiting Quality Check Review"
            },
            {
                ApprovalStatusId:10,
                ApprovalStatusName:"Awaiting Project Review"
            }],
            sortColumnName:"CreatedDate",
            sortOrder:"desc",
            qcApprovalStatusSelectedOption:[],
            newApprovalListForQC:[{
                id:1,
                label:"Awaiting QC Review"
            },
            {
                id:2,
                label:"Awaiting Project Review"
            }],
            searchOwner:"",
            searchMentor:"",
            projectPhase:"",
            monetizableSelectedOption:[],
            zeroVDSelectedOption:[],
            zeroVDSelectedLabel:"",
            monetizableSelectedLabel:"",
            searchSegment:"",
            searchUnit:"",
            uniqueUnits:[],
            uniqueSegments:[],
            searchSegmentSelectedOption:[],
            searchUnitSelectedOption:[]

        }
    }

    async componentDidMount() {
        if(this.state.propsData){
            if(this.state.propsData.WhereClause){
                if(this.state.propsData.selectedDate === 'CreatedDate'){
                    this.setState({selectedDate:'Created Date'});
                }
                else if(this.state.propsData.selectedDate === 'ModifiedDate'){
                    this.setState({selectedDate:'Reviewed Date'});
                }

                this.setState({
                    WhereClause:this.state.propsData.WhereClause,
                    topCount:this.state.topCount,
                    skipCount:this.state.propsData.skipCount,
                    tempWorkflowStatus:this.state.propsData.WorkflowStatus,
                    tempApprovalStatus:this.state.propsData.ApprovalStatus,
                    tempIdeaId:this.state.propsData.IdeaId,
                    tempAccountManual:this.state.propsData.AccountManual,
                    tempRegionManual:this.state.propsData.RegionManual,
                    tempVerticalManual:this.state.propsData.VerticalManual,
                    tempTransformationLeverName:this.state.propsData.TransformationLeverName,
                    tempCertificationLevelName:this.state.propsData.CertificationLevelName,
                    tempSubVerticalName:this.state.propsData.SubVerticalName, 
                    tempServiceLineName:this.state.propsData.ServiceLineName, 
                    tempSubServiceLine:this.state.propsData.SubServiceLine,
                    tempstartDate:this.state.propsData.startDate, 
                    temptoDate:this.state.propsData.toDate,
                    filter:!this.state.filter
                }, async () => {
                    await this.filterHandler();
                    await this.fetchFilterIdea();
                })
            }
            else if(!this.state.propsData.WhereClause && this.state.propsData.skipCount){
                this.setState({
                    topCount:this.state.propsData.topCount,
                    skipCount:this.state.propsData.skipCount
                }, async() => {
                    await this.fetchIdea();
                })
            } 
            else{
                await this.fetchIdea();
            }
        }
        else{
            await this.fetchIdea();
            
        }
        await this.fetchMetedata();
        Toast.hide(); 
                 
    }

    

    

    fetchIdea = () => {
        Toast.loading("Fetching Ideas...", () => { });
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            if(this.state.skipCount===0){
                this.setState({
                    isPrevEnabled:false,
                })
            }
            else{
                this.setState({
                    isPrevEnabled:true
                })
            }
            fetch(`${GET_ALL_IDEA_URL}UserOHR=''&WhereClause=''&skipCount=${this.state.skipCount}&topCount=${this.state.topCount}&sortingorder=${this.state.sortOrder}&sortingcolumn=${this.state.sortColumnName}&isItIdeaOrGenAI='Idea'&CreatedBy=''`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        ideaList: data.IdeaList.value === undefined ? [] : data.IdeaList.value,
                        originalIdeaList: data.IdeaList.value === undefined ? [] : data.IdeaList.value,
                        IdeaLength:data.TotalIdea[0].length,
                    }, () => {
                        console.log(this.state.ideaList)
                        //console.log("total number of idea",this.state.IdeaLength);
                        let totalPages = Math.ceil(parseInt(this.state.IdeaLength)/parseInt(this.state.topCount));
                        let currentPage = Math.ceil(parseInt(this.state.skipCount)/(parseInt(this.state.topCount)) +1);
                        // let sortedData = SortByColumn(this.state.ideaList, "CreatedDate", "desc");
                       
                        this.setState({
                            // ideaList: sortedData,
                            // createdDateSortIconRotate: "desc",
                            totalPages:totalPages,
                            currentPage:currentPage
                        }, () => {
                            // console.log("currentPage",this.state.currentPage);
                            // console.log("totalPages",this.state.totalPages);
                            //console.log("created date icon",this.state.createdDateSortIconRotate)
                            if(this.state.currentPage === this.state.totalPages){
                                this.setState({
                                    isNextEnabled:false
                                })
                            }
                            else{
                                this.setState({
                                    isNextEnabled:true
                                })
                            }
                        });
                         
                        this.handleFilterChange({ value: 0 }); 
                        Toast.hide();
                        resolve();
                    });
                })
                .catch(error => {
                    console.log(error);
                    Toast.hide();
                    resolve();
                }); 
        });
    }

    fetchFilterIdea = () => {
        Toast.loading("Fetching Ideas...", () => { });
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            
            if(this.state.skipCount===0){
                this.setState({
                    isPrevEnabled:false,
                })
            }
            else{
                this.setState({
                    isPrevEnabled:true
                })
            }

            fetch(`${GET_ALL_IDEA_URL}UserOHR=''&WhereClause=${this.state.WhereClause}&skipCount=${this.state.skipCount}&topCount=${this.state.topCount}&isItIdeaOrGenAI='Idea'&CreatedBy=''`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    this.setState({
                        ideaList: data.IdeaList.ResultSets.Table1 === undefined ? [] : data.IdeaList.ResultSets.Table1,
                        originalIdeaList: data.IdeaList.ResultSets.Table1 === undefined ? [] : data.IdeaList.ResultSets.Table1,
                        IdeaLength:data.TotalIdea.Table1[0].length,
                        fromFilterSelection:data.filter
                    }, () => {
                        let totalPages = Math.ceil(parseInt(this.state.IdeaLength)/parseInt(this.state.topCount));
                        let currentPage = Math.ceil(parseInt(this.state.skipCount)/(parseInt(this.state.topCount)) +1);
                        let sortedData = SortByColumn(this.state.ideaList, "CreatedDate", "desc");
                       
                        if(totalPages===0){
                            totalPages=1
                        }
                        this.setState({
                            ideaList: sortedData,
                            createdDateSortIconRotate: "desc",
                            totalPages:totalPages,
                            currentPage:currentPage
                        }, () => {
                            console.log("currentPage",this.state.currentPage);
                            console.log("totalPages",this.state.totalPages);
                            if(this.state.currentPage === this.state.totalPages){
                                this.setState({
                                    isNextEnabled:false
                                })
                            }
                            else{
                                this.setState({
                                    isNextEnabled:true
                                })
                            }
                        });
                         
                        this.handleFilterChange({ value: 0 }); 
                        Toast.hide();
                        resolve();
                    });
                })
                .catch(error => {
                    console.log(error);
                    Toast.hide();
                    resolve();
                }); 
        });
    }

    // to fetch using columnName and sort order
    fetchFilterIdeaLatest = () => {
        Toast.loading("Fetching Ideas...", () => { });
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            
            if(this.state.skipCount===0){
                this.setState({
                    isPrevEnabled:false,
                })
            }
            else{
                this.setState({
                    isPrevEnabled:true
                })
            }

            fetch(`${GET_ALL_IDEA_URL}UserOHR=''&WhereClause=${this.state.WhereClause}&skipCount=${this.state.skipCount}&topCount=${this.state.topCount}&sortingorder=${this.state.sortOrder}&sortingcolumn=${this.state.sortColumnName}&isItIdeaOrGenAI='Idea'&CreatedBy=''`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    this.setState({
                        ideaList: data.IdeaList.ResultSets.Table1 === undefined ? [] : data.IdeaList.ResultSets.Table1,
                        originalIdeaList: data.IdeaList.ResultSets.Table1 === undefined ? [] : data.IdeaList.ResultSets.Table1,
                        IdeaLength:data.TotalIdea.Table1[0].length,
                        fromFilterSelection:data.filter
                    }, () => {
                        let totalPages = Math.ceil(parseInt(this.state.IdeaLength)/parseInt(this.state.topCount));
                        let currentPage = Math.ceil(parseInt(this.state.skipCount)/(parseInt(this.state.topCount)) +1);
                        let sortedData = SortByColumn(this.state.ideaList, "CreatedDate", "desc");
                       
                        if(totalPages===0){
                            totalPages=1
                        }
                        this.setState({
                            ideaList: sortedData,
                            createdDateSortIconRotate: "desc",
                            totalPages:totalPages,
                            currentPage:currentPage
                        }, () => {
                            console.log("currentPage",this.state.currentPage);
                            console.log("totalPages",this.state.totalPages);
                            if(this.state.currentPage === this.state.totalPages){
                                this.setState({
                                    isNextEnabled:false
                                })
                            }
                            else{
                                this.setState({
                                    isNextEnabled:true
                                })
                            }
                        });
                         
                        this.handleFilterChange({ value: 0 }); 
                        Toast.hide();
                        resolve();
                    });
                })
                .catch(error => {
                    console.log(error);
                    Toast.hide();
                    resolve();
                }); 
        });
    }



    fetchMetedata = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "",
                EntityName: ""
            }
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                Toast.hide();
                this.setState({
                    accountList: data ? data.Accounts.sort((a, b) => { return a.ACCOUNT > b.ACCOUNT ? 1 : -1; }) : [],
                    serviceLineList: data ? data.ServiceLines : [],
                    subServiceLineList: data ? data.ServiceLines : [],
                    workFlowStatusList: data ? data.WorkflowStatus : [],
                    certificationLevelsList: data ? data.CertificationLevels : [],
                    transformationLeversList: data ? data.TransformationLevers : [],
                    regionList:data?data.Region:[],
                    adminUserList: data ?  data.DataverseUserRole:[],
                    verticalList:data?data.VerticalData:[],
                    subVerticalList: data?data.SubVerticalData:[],
                    uniqueUnits:data ? data.UnitSegment ? data.UnitSegment.ResultSets ? data.UnitSegment.ResultSets.Table1 : []: []:[],
                    uniqueSegments:data ? data.UnitSegment ? data.UnitSegment.ResultSets ? data.UnitSegment.ResultSets.Table2 : []: []:[],
                }, () => {
                    this.state.adminUserList.map((item) => {
                        if(parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr)){
                            console.log("this is admin")
                            this.setState({admin:true});
                            return false;
                        }
                        return false;
                    });
                });
                resolve();
            })
            .catch(error => {
                console.log("error=>",error)
                Toast.hide();
                Toast.fail('Error occured while getting Idea Metadata. Please try again!', 3000, () => {
                    console.log(JSON.stringify(error));
                });
            }); 
        });
    }

    renderVerticalOptions = () => {
        let options = [];
        this.state.verticalList.map((item) => {
            if(item.Vertical !== "TS"){
                options.push({
                    value: item.Vertical,
                    label: item.Vertical
                })
            }
            return options;
        });
        return options;
    }

    renderSubVerticalOptions = () => {
        let options = [];
        this.state.subVerticalList.map((item) => {
            if(item.SubVertical !== "corporate" && item.SubVertical !== "Unmapped"){
                options.push({
                    value: item.SubVertical,
                    label: item.SubVertical
                })
            }
            return options;
        });
        return options; 
    }

    renderTopCountOptions = () => {
        if(this.state.topCountOption.length > 0){
            return this.state.topCountOption.map((item) => {
                let isSelected = false;
                if(item === this.state.topCount){
                    isSelected = true;
                }
                return(
                    <option selected= { isSelected }>{ item }</option>
                );
            });
        }
    }

    topCountChangeHandler = async (event) => {
        const index = event.nativeEvent.target.selectedIndex;
        this.setState({
            topCount: event.nativeEvent.target[index].text
        }, async () => { 
            if(this.state.WhereClause){
                await this.fetchFilterIdea();
            }
            else{
                await this.fetchIdea();
            }
        });
    }

    handleIdeaAction = (ideaId, action) => {
        if(action === VIEW){
            this.props.history.push({
                pathname: "/view-idea-page",
                state: ideaId,
                isViewAllIdea:true,
                WhereClause:this.state.WhereClause,
                topCount:this.state.topCount,
                skipCount:this.state.skipCount,
                WorkflowStatus:this.state.WorkflowStatus,
                ApprovalStatus:this.state.ApprovalStatus,
                IdeaId:this.state.IdeaId,
                AccountManual:this.state.AccountManual,
                RegionManual:this.state.RegionManual,
                VerticalManual:this.state.VerticalManual,
                TransformationLeverName:this.state.TransformationLeverName,
                CertificationLevelName:this.state.CertificationLevelName,
                SubVerticalName:this.state.SubVerticalName, 
                ServiceLineName:this.state.ServiceLineName, 
                SubServiceLine:this.state.SubServiceLine,
                startDate:this.state.startDate, 
                toDate:this.state.toDate,
                selectedDate:this.state.selectedDate,
                globalsearchText:this.state.globalsearchText
            })
        } 
    }

    handleAccountSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                accountSortIconRotate: !prevState.accountSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.accountSortIconRotate ? "desc" : "asc";
            let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                ideaList: sortedData
            });
        })
    }

    handleIdeaIdSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                ideaIdSortIconRotate: !prevState.ideaIdSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.ideaIdSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);

            this.setState({
                // ideaList: sortedData,
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
            
        })
    }

    handleIdeaNameSortClick = async(columnName) => {
        this.setState(prevState => {
            return {
                ideaNameSortIconRotate: !prevState.ideaNameSortIconRotate,
            }
        }, () => {
            let sortOrder = this.state.ideaNameSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
        
    }

    handleServiceLineSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                serviceLineSortIconRotate: !prevState.serviceLineSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.serviceLineSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }


    handleSegmentSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                segmentSortIconRotate: !prevState.segmentSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.segmentSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleUnitSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                unitSortIconRotate: !prevState.unitSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.unitSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }



    handleApproverSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                approverSortIconRotate: !prevState.approverSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.approverSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleSubmitterSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                submitterSortIconRotate: !prevState.submitterSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.submitterSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleCreatedDateSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                createdDateSortIconRotate: !prevState.createdDateSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.createdDateSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleReviewStatusSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                reviewStatusSortIcon: !prevState.reviewStatusSortIcon
            }
        }, () => {
            let sortOrder = this.state.reviewStatusSortIcon ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleReviewDateSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                reviewDateSortIconRotate: !prevState.reviewDateSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.reviewDateSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleCertTypeSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                certTypeSortIconRotate: !prevState.certTypeSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.certTypeSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleTransformationLeverSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                transformationLeverSortIcon: !prevState.transformationLeverSortIcon
            }
        }, () => {
            let sortOrder = this.state.transformationLeverSortIcon ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleProjectStatusSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                projectStatusSortIcon: !prevState.projectStatusSortIcon
            }
        }, () => {
            let sortOrder = this.state.projectStatusSortIcon ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleFilterChange = (e) => {
        let filteredIdeaArray = [];
        if (e.value === 0) {
            filteredIdeaArray = this.state.originalIdeaList;
        }
        this.setState({
            ideaList: filteredIdeaArray,
            originalIdeaList: filteredIdeaArray
        })
    }
    
    resetData = () => {
        this.setState({
            WorkflowStatus:'',
            IdeaId:'',
            AccountManual:'',
            RegionManual:'',
            VerticalManual:'',
            TransformationLeverName:'',
            CertificationLevelName:'',
            SubVerticalName:'', 
            ServiceLineName:'', 
            SubServiceLine:'',
            startDate:'',
            toDate:'',
            filter:false
        }, () => {
            window.location.reload();
        })
    }

    globalSearch = () =>{
        if(this.state.currentPage === this.state.totalPages){
            this.setState({
                skipCount:0
            })
        }
        console.log("global search");
        console.log("search Text is:", this.state.globalsearchText);
        let WhereClause = '';
        if(this.state.globalsearchText !== null && this.state.globalsearchText !== ''){
            console.log("this.state.isTypedInFilter:", this.state.isTypedInFilter);

            WhereClause= this.state.isTypedInFilter !== '' ? this.state.isTypedInFilter + ' and ': '';
            WhereClause=WhereClause+encodeURIComponent( "Concat(WorkflowStatus,IdeaIDAlpha,AccountManual,VerticalManual,RegionManual,TransformationLeverName,CertificationLevelName,SubVerticalName, ServiceLineName ,OwnerOHR, CreatorOHR , BlackBeltUserId , OwnerName, CreatorName , BlackBeltUserName , IdeaName , ApprovalStatus ,SubServiceLine,MBBName,BlackBeltUserId,LeanID, Unit, Segment) like '%"+this.state.globalsearchText+"%'")


            // WhereClause= this.state.isTypedInFilter !== '' ? this.state.isTypedInFilter + ' and ': '';
            // WhereClause=WhereClause+ '('+ 'WorkflowStatus'+ ' ' + 'like' + " '" +  this.state.globalsearchText + '%'+"'" + 
            // ' or '  + 'IdeaIDAlpha'+ ' ' + 'like' + " '%" + this.state.globalsearchText + '%'+"'" + 
            // ' or '  +  'AccountManual'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'VerticalManual'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'RegionManual'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'TransformationLeverName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'CertificationLevelName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'SubVerticalName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'ServiceLineName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'OwnerOHR'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'CreatorOHR'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'BlackBeltUserId'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'OwnerName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'CreatorName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'BlackBeltUserName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'IdeaName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'ApprovalStatus'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'SubServiceLine'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + ')';

            if(WhereClause !== ''){
                this.setState({
                    WhereClause:WhereClause,
                    filter:false
                }, async() =>{
                    await this.fetchFilterIdea();
                })
            }
        }
        else{
            Toast.fail("Please type something then search.");
            return;
        }
    }

    searchData = async() =>{
        let WhereClause = '';
        if(this.state.WorkflowStatus !== ''){
            WhereClause = 'WorkflowStatus'+ ' ' + 'like' + " '" + this.state.WorkflowStatus + '%'+"'";
        }
        if(this.state.ApprovalStatus !== '' && this.state.ApprovalStatus !== undefined){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause = WhereClause + 'ApprovalStatus'+ ' ' + 'like' + " '" + this.state.ApprovalStatus + '%'+"'";
        }
        if(this.state.IdeaId !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'IdeaIDAlpha'+ ' ' + 'like' + " '" + this.state.IdeaId + '%'+"'";
        }
        if(this.state.AccountManual !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'AccountManual'+ ' ' + 'like' + " '" + this.state.AccountManual + '%'+"'";
        }
        if(this.state.VerticalManual !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'VerticalManual'+ ' ' + 'like' + " '" + this.state.VerticalManual + '%'+"'";
        }
        if(this.state.RegionManual !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'RegionManual'+ ' ' + 'like' + " '" + this.state.RegionManual + '%'+"'";
        }
        if(this.state.CertificationLevelName !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'CertificationLevelName'+ ' ' + 'like' + " '" + this.state.CertificationLevelName + '%'+"'";
        }
        if(this.state.TransformationLeverName !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'TransformationLeverName'+ ' ' + 'like' + " '" + this.state.TransformationLeverName + '%'+"'";
        }
        if(this.state.SubVerticalName !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'SubVerticalName'+ ' ' + 'like' + " '" + this.state.SubVerticalName + '%'+"'";
        }
        if(this.state.ServiceLineName !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            // WhereClause =  WhereClause + 'ServiceLineName'+ ' ' + 'like' + "'" + this.state.ServiceLineName.replace("&","%26") + '%'+"'";
            WhereClause =  WhereClause + 'ServiceLineName'+ ' ' + 'like' + "'" + encodeURIComponent(this.state.ServiceLineName) + '%'+"'";
        }
        if(this.state.SubServiceLine !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'SubServiceLine'+ ' ' + 'like' + "'" + encodeURIComponent(this.state.SubServiceLine) + '%'+"'";
        }


        if(this.state.searchOwner !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + '('+ 'OwnerName'+ ' ' + 'like' + " '" + this.state.searchOwner + '%'+"'" + ' or ' + 'OwnerOHR'+ ' ' + 'like' + " '" + this.state.searchOwner + '%'+"'" + ')' + ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }
        if(this.state.searchMentor !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + '('+ 'BlackBeltUserName'+ ' ' + 'like' + " '" + this.state.searchMentor + '%'+"'" + ' or ' + 'BlackBeltUserId'+ ' ' + 'like' + " '" + this.state.searchMentor + '%'+"'" + ')' + ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }


        if(this.state.searchSegment !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'Segment'+ ' ' + 'like' + "'" + encodeURIComponent(this.state.searchSegment) + '%'+"'";
        }
        if(this.state.searchUnit !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'Unit'+ ' ' + 'like' + "'" + encodeURIComponent(this.state.searchUnit) + '%'+"'";
        }


        if(this.state.projectPhase !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + '('+ 'ApprovalStatus'+ ' ' + 'like' + " '" + this.state.projectPhase + '%'+"'" + ')' + ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }

        if(this.state.monetizableSelectedLabel !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'IsVDMonetizable'+ ' ' + 'like' + " '" + this.state.monetizableSelectedLabel + '%'+"'"+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }
        



        if(this.state.startDate !== '' && this.state.toDate !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            if(this.state.selectedDate === "ModifiedDate"){
                WhereClause =  WhereClause + '(' + '('+ this.state.selectedDate + ' ' + 'between ' + "'"+ this.state.startDate+'T00:00:00.00' +"'"+ ' and ' +"'"+ this.state.toDate+'T23:59:00.00' +"'"+ ')' + ' and ' + 'WorkflowStatus' + ' <> '  +"'" +'Pending'+ "'" + ')';
            }
            else{
                WhereClause =  WhereClause + '('+ this.state.selectedDate + ' ' + 'between ' + "'"+ this.state.startDate+'T00:00:00.00' +"'"+ ' and ' +"'"+ this.state.toDate+'T23:59:00.00' +"'"+ ')';
            }
        }
        if(this.state.startDate !== ''){
            if(!this.state.selectedDate){
                Toast.fail("Please select date type first.");
                return;
            }
            if(this.state.toDate === ''){
                Toast.fail("Please enter both the start date and end date.");
                return;
            }
        }

        if(this.state.toDate !== ''){
            if(!this.state.selectedDate){
                Toast.fail("Please select date type first.");
                return;
            }
            if(this.state.startDate === ''){
                Toast.fail("Please enter both the start date and end date.");
                return;  
            }
        }

        if(this.state.selectedDate && (this.state.startDate === '' || this.state.toDate === '')){
            Toast.fail("Please enter both the start date and end date.");
            return; 
        }
        
        if(this.state.startDate && this.state.toDate ){
            if(this.state.startDate > this.state.toDate){
                Toast.fail("Start date should not be greater than end date.");
                return; 
            }
        }


        if(this.state.zeroVDSelectedLabel !== ''){
            WhereClause = WhereClause !== '' ? WhereClause + ' and ': '';
            let convertedBooleanZeroVD = "";
            if(this.state.zeroVDSelectedOption){
                if(this.state.zeroVDSelectedOption.label === "Yes"){
                    convertedBooleanZeroVD = "1";
                }
                if(this.state.zeroVDSelectedOption.label === "No"){
                    convertedBooleanZeroVD = "0";
                }
            }
            console.log("convertedBooleanZeroVD",convertedBooleanZeroVD)
            WhereClause =  WhereClause + 'IsZeroImpactProject'+ ' ' + 'like' + " '" + convertedBooleanZeroVD + '%'+"'"+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }




        if(WhereClause !== ''){
            this.setState({
                WhereClause:WhereClause,
                isTypedInFilter:WhereClause,
                tempWorkflowStatus:this.state.WorkflowStatus,
                tempApprovalStatus:this.state.ApprovalStatus,
                tempIdeaId:this.state.IdeaId,
                tempAccountManual:this.state.AccountManual,
                tempRegionManual:this.state.RegionManual,
                tempVerticalManual:this.state.VerticalManual,
                tempTransformationLeverName:this.state.TransformationLeverName,
                tempCertificationLevelName:this.state.CertificationLevelName,
                tempSubVerticalName:this.state.SubVerticalName, 
                tempServiceLineName:this.state.ServiceLineName, 
                tempSubServiceLine:this.state.SubServiceLine,
                tempstartDate:this.state.startDate, 
                temptoDate:this.state.toDate,
            }, async() =>{
                console.log("whereclause",WhereClause);
                // await this.fetchFilterIdea();
                await this.fetchFilterIdeaLatest();
            })
        }
        else{
            if(this.state.tempIdeaId){
                this.refreshSearch();
            }
            else{
                Toast.fail("Please type something then search.");
            }
        }
    }

    renderDateOptions = () => {
        let options = [];
        this.state.dateList.map((item) => {
            options.push({
                value: item,
                label: item
            })
            return options;
        });
        return options;
    }

    renderStatusOptions = () => {
        let options = [
            { value: 0, label: "Approve" },
            { value: 1, label: "Reject" },
            { value: 2, label: "Send Back" },
            { value: 3, label: AWAITING_AI_BOT_REVIEW }
        ];
        return options;
    }

    renderApprovalStatusOptions = () => {
        let options = [];
        this.state.approvalStatusList.map((item) => {
            options.push({
                value: item.ApprovalStatusId,
                label: item.ApprovalStatusName
            })
            return options;
        });
        return options;
    }

    renderQCApprovalStatusOptions=()=>{
        let options = [];
        this.state.newApprovalListForQC && this.state.newApprovalListForQC.map((item) => {
            options.push({
                value: item.id,
                label: item.label
            })
            return options;
        });
        return options;
    }

    renderAccountOptions = () => {
        let options = [];
        let accountName = "";
        this.state.accountList.map((item) => {
            accountName = item.ACCOUNT.toString().toLowerCase();
            if ( !( accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact") ) ){
                options.push({
                    value: item.ACCOUNT_L2,
                    label: item.ACCOUNT
                })
            }
            return true;
        });
        return options;
    }

    renderTransLeversOptions = () => {
        let options = [];
        this.state.transformationLeversList.map((item) => {
            options.push({
                value: item.TransformationLeverId,
                label: item.TransformationLeverName
            });
            return options;
        });
        return options;
    }

    renderCertLevelsOptions = () => {
        let options = [];
        this.state.certificationLevelsList.map((item) => {
            options.push({
                value: item.CertificationLevelId,
                label: item.CertificationLevelName
            });
            return options;
        });
        return options;
    }

    renderRegionOptions  = () => {
        let options = [];
        this.state.regionList.map((item) => {
            options.push({
                value: item.Region,
                label: item.Region
            })
            return options;
        });
        return options;
    }

    renderServiceLineOptions = () => {
        let options = [];
        const uniqueServiceLineList = [...new Map(this.state.serviceLineList.map(item =>
            [item.ServiceLineName, item])).values()];
        uniqueServiceLineList.map((item) => {
            options.push({ value: item.ServiceLineId, label: item.ServiceLineName });
            return true;
        });
        return options;
    }
    
    renderSubServiceLineOptions = () => {
        let options = [];
        this.state.subServiceLineList.map((item) => {
            if (this.state.ServiceLineName === item.ServiceLineName) {
                options.push({ value: item.SubServiceLineId, label: item.SubServiceLineName });
                return true;
            }
            return true;
        });
        return options;
    }

    handleDateOptionChange= (e) => {
        this.setState({
            isTypedData:true,
            dateSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        },() => {
            if(e.label === "Created date"){
                this.setState({
                    selectedDate:"CreatedDate"
                })
            }
            else if(e.label === "Reviewed date"){
                this.setState({
                    selectedDate:"ModifiedDate"
                })
            }
        });
    }

    handleAccountNameChange = (e) => {
        this.setState({
            AccountManual:e.label,
            accountName: e.label,
            isTypedData:true,
            accountSelectionOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        });
    }

    handleReviewStatusFilterChange = (e) => {
        this.setState({
            WorkflowStatus:e.label,
            isTypedData:true,
            workFlowStatusSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }

    handleProjectStatusFilterChange = (e) => {
        this.setState({
            ApprovalStatus:e.label,
            isTypedData:true,
            approvalStatusSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }

    handleQCApprovalStatusFilterChange = (e) => {
        this.setState({
            qcApprovalStatus:e.label,
            isTypedData:true,
            qcApprovalStatusSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }

    


    handleIdeaIDFilterChange = (searchText) => {
        this.setState({
            IdeaId:searchText,
            isTypedData:true
        })
    }

    handleVerticalFilterChange = (e) => {
        console.log("e",e);
        this.setState({
            subVerticalSelectedOption: null,
            VerticalManual: e.label,
            verticalSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0,
            isTypedData:true
        }, async () => {
            await this.searchData();
        })
    }

    handleRegionFilterChange = (e) => {
        this.setState({
            RegionManual:e.label,
            isTypedData:true,
            regionSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })   
    }

    handleTransformationLeverNameFilterChange = (e) => {
        this.setState({
            TransformationLeverName:e.label,
            isTypedData:true,
            transSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }

    handleCertLevelChange = (e) => {
        this.setState({
            CertificationLevelName:e.label,
            isTypedData:true,
            certLevelsSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }

    handleSubVerticalNameFilterChange = (e) => {
        this.setState({
            SubVerticalName: e.label,
            subVerticalSelectedOption: {
                value: e.value,
                label: e.label
            },
            isTypedData:true,
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }

    handleService =(e)=>{
        this.setState({
            ServiceLineName:e.label,
            isTypedData:true,
            serviceLineSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0,
            subServiceLineSelectedOption:null,
            SubServiceLine:''
        }, async () => {
            await this.searchData();
        })
    }

    handleSubServiceLine =(e)=>{
        this.setState({
            SubServiceLine:e.label,
            isTypedData:true,
            subServiceLineSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }

    handleCreatedDate=(dates)=>{
        this.setState({
            startDate:dates,
            isTypedData:true
        });
    }

    handleToDate=(dates)=>{
        this.setState({
            toDate:dates,
            isTypedData:true
        });
    
    }

    handleGlobalSearchFilterChange = (searchText) => {
        this.setState({
            globalsearchText:searchText,
            isTypedData:true,
            isTypedInGlobalSearch:true
        })
        if(searchText === ''){
            window.location.reload();
        }
    }

    formatDateString(dateparam){
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
    }

    handleGetFirstPage = () => {
        this.setState({
            skipCount:0
        }, async () => {
            if(this.state.fromFilterSelection){
                await this.fetchFilterIdea();
            }
            else{
                await this.fetchIdea();
            }
        })
    }

    handleGetLastPage = () => {
        let skipCount = (parseInt(this.state.totalPages) - 1) * parseInt(this.state.topCount);
        this.setState({
            skipCount:skipCount
        }, async () => {
            if(this.state.fromFilterSelection){
                await this.fetchFilterIdea();
            }
            else{
                await this.fetchIdea();
            }
        })
    }

    handlePrevAction = () => {
        let skipCount = this.state.skipCount;
        let topCount = this.state.topCount;
        this.setState({
            skipCount:parseInt(skipCount)-parseInt(topCount),
        }, async () => {
            console.log("skip prev", this.state.skipCount);
            console.log("top count",this.state.topCount);
            if(this.state.fromFilterSelection){
                await this.fetchFilterIdea();
            }
            else{
                await this.fetchIdea();
            }
        })
    }

    handleNextAction = async() =>{
        let skipCount = this.state.skipCount;
        let topCount = this.state.topCount;
        this.setState({
            skipCount: parseInt(skipCount) + parseInt(topCount)
        }, async () => {
            console.log("skip next", this.state.skipCount);
            console.log("top count",this.state.topCount);
            if(this.state.fromFilterSelection){
                await this.fetchFilterIdea();
            }
            else{
                await this.fetchIdea();
            }
        })
    }
    
    handlePageChangeHandler = (event) => {
        this.setState({
            pageNo:event.target.value
        })
    }

    goToPage = () => {
        this.setState({
            skipCount: (parseInt(this.state.pageNo)-1) * parseInt(this.state.topCount)
        }, async () => {
            await this.fetchIdea();
        })
    }

    refreshSearch = () => {
        window.location.reload();
    }

    filterHandler = () => {
        if(this.state.propsData){
            this.setState({
                workFlowStatusSelectedOption: this.state.propsData.WorkflowStatus ? {value: this.state.tempWorkflowStatus,label: this.state.tempWorkflowStatus} : null,
                approvalStatusSelectedOption: this.state.propsData.ApprovalStatus ? {value: this.state.tempApprovalStatus,label: this.state.tempApprovalStatus} : null,
                accountSelectionOption: this.state.propsData.AccountManual ? {value: this.state.tempAccountManual,label: this.state.tempAccountManual} : null,
                transSelectedOption: this.state.propsData.TransformationLeverName ? {value: this.state.tempTransformationLeverName,label: this.state.tempTransformationLeverName} : null,
                certLevelsSelectedOption: this.state.propsData.CertificationLevelName ? {value: this.state.tempCertificationLevelName,label: this.state.tempCertificationLevelName} : null,
                serviceLineSelectedOption: this.state.propsData.ServiceLineName ? {value: this.state.tempServiceLineName,label: this.state.tempServiceLineName} : null,
                subServiceLineSelectedOption: this.state.propsData.SubServiceLine ? {value: this.state.tempSubServiceLine,label: this.state.tempSubServiceLine} : null,
                regionSelectedOption: this.state.propsData.RegionManual ? {value: this.state.tempRegionManual,label: this.state.tempRegionManual} : null,
                verticalSelectedOption: this.state.propsData.VerticalManual ? {value: this.state.tempVerticalManual,label: this.state.tempVerticalManual} : null,
                subVerticalSelectedOption: this.state.propsData.SubVerticalName ? {value: this.state.tempSubVerticalName,label: this.state.tempSubVerticalName} : null,
                dateSelectedOption:this.state.propsData.selectedDate ? {value: this.state.selectedDate,label: this.state.selectedDate} : null,
                globalsearchText:this.state.propsData.globalsearchText ? this.state.propsData.globalsearchText : null
            });
        }
        this.setState({
            filter:!this.state.filter,
            IdeaId:this.state.tempIdeaId,
            WorkflowStatus:this.state.tempWorkflowStatus,
            ApprovalStatus:this.state.tempApprovalStatus,
            AccountManual:this.state.tempAccountManual,
            RegionManual:this.state.tempRegionManual,
            VerticalManual:this.state.tempVerticalManual,
            TransformationLeverName:this.state.tempTransformationLeverName,
            CertificationLevelName:this.state.tempCertificationLevelName,
            SubVerticalName:this.state.tempSubVerticalName, 
            ServiceLineName:this.state.tempServiceLineName, 
            SubServiceLine:this.state.tempSubServiceLine,
            startDate:this.state.tempstartDate, 
            toDate:this.state.temptoDate
        });
    }

    handleCommentChange = (event) => {
        this.setState({
            Comment: event.target.value
        });
    }

    inputChanged = (event) => {
        event.target.parentElement.parentElement.className =
          event.target.checked ? 'selected' : '';
    }

    handleIdeaSelected = () => {
        let selectedIdea=[];
        var selectedRows = document.getElementsByClassName('selected');
        if(selectedRows.length > 0){
            for (var i = 0; i < selectedRows.length; ++i) {
                selectedIdea.push(selectedRows[i].textContent.slice(0,9));
            }
            this.setState({
                selectedIdea:selectedIdea,
                bulkOperation:!this.state.bulkOperation,
            });
        }
        else{
            Toast.fail("Idea not selected, please select",3000);
        }
    }

    selectAll = () => {  
        if (document.getElementById('selectAll').checked) {
            var ele=document.getElementsByName('chk');  
            let selectTotal = ele.length < 25 ? ele.length : 25;
            for(var i=0; i<selectTotal; i++){  
                if(ele[i].type=='checkbox')  
                    ele[i].checked= true;
                    ele[i].parentElement.parentElement.className =
                    ele[i].checked ? 'selected' : '';
            } 
        } else {
            var ele=document.getElementsByName('chk');  
            let selectTotal = ele.length < 5 ? ele.length : 5;
            for(var i=0; i<selectTotal; i++){  
                if(ele[i].type=='checkbox')  
                    ele[i].checked=false;
            } 
        }
    } 

    bulkCancellation = async () => {
        await this.handleIdeaSelected();
        this.setState({bulkCancel:true, bulkActive:false});
    }

    bulkReinitiate = async () => {
        await this.handleIdeaSelected();
        this.setState({bulkActive:true, bulkCancel:false});
    }

    validate = () => {
        let isValid = true;
        let styleStateObj = this.state.selectStyle;
        if (!this.state.Comment || this.state.Comment === ''){
            isValid = false;
            styleStateObj.comment = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else{
            if (this.state.Comment.length > 500){
                Toast.fail("Comment cannot be more than 500 characters, please change and resubmit.", 3000);
                isValid = false;
                styleStateObj.comment = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else{
                styleStateObj.comment = {...textBoxValidation(true), border: "1px solid lightgrey"};
                this.setState({
                    selectStyle: styleStateObj
                })
            }
        }
        return isValid;
    }

    timeout = (delay)=> {
        return new Promise( res => setTimeout(res, delay) );
    }

    submitEvent = async () => {
        if(this.state.bulkCancel){
            if(this.validate()){
                let param = {};
                if (window.confirm("Are you sure you want to cancel selected ideas?")) {
                    Toast.loading("Bulk cancellation is in process...", () => { });
                    this.state.ideaList.map((ideaItem) => {
                        this.state.selectedIdea.map((item)=>{
                            if(ideaItem.IdeaIDAlpha === item && (ideaItem.WorkflowStatus !== 'Cancelled' && ideaItem.ApprovalStatus !== 'Cancelled')){
                                param = {
                                    IdeaId: ideaItem.IdeaId,
                                    ApprovalStatus: ideaItem.ApprovalStatus === 'NA' ? ideaItem.ApprovalStatus : 'Cancelled',
                                    UpdaterOHR: this.sessionData.profile.oHRId,
                                    UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                                    UpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                                    comment:this.state.Comment,
                                    WorkflowStatus: ideaItem.ApprovalStatus === 'NA' ? 'Cancelled' : ideaItem.WorkflowStatus,
                                    ProjectStatus:'Admin Cancel'
                                }
                                // console.log("param...",param);
                                // return;
                                let finalJsonData = Checksum(param);
                                const requestOptions = {
                                    method: 'PUT',
                                    headers: { 
                                        'Content-Type': 'application/json',
                                        "Authorization":  "Bearer " + this.accessToken,
                                    },
                                    body: JSON.stringify(param)
                                };
                                fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
                                    .then(response => response.json())
                                    .then(data => {
                                        // Toast.hide();
                                        // Toast.success('Selected Ideas has been cancelled successfully!', 3000, () => {
                                            console.log(JSON.stringify(data));
                                        //     window.location = "/view-all-ideas-page";
                                        // });
                                    });
                                return;
                            }                            
                        })
                        return;
                    });
                    await this.timeout(8000);
                    Toast.hide();
                    Toast.success('Selected Ideas has been cancelled successfully!', 3500, () => {
                    window.location = "/view-all-ideas-page";
                    });
                }
            }
            else{
                Toast.fail("Please add reason for bulk cancellation!", 3000, () => { });
            }
        }
        else{
            if(this.validate()){
                let param = {};
                if (window.confirm("Are you sure you want to reinitiate this selected ideas?")) {
                    Toast.loading("Bulk reinitiation is in process...", () => { });
                    this.state.ideaList.map((ideaItem) => {
                        this.state.selectedIdea.map((item)=>{
                            if(ideaItem.IdeaIDAlpha === item && (ideaItem.WorkflowStatus === 'Cancelled' || ideaItem.ApprovalStatus === 'Cancelled')){
                                param = {
                                    IdeaId: ideaItem.IdeaId,
                                    IdeaName:ideaItem.IdeaName,
                                    UpdaterOHR: this.sessionData.profile.oHRId,
                                    UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                                    UpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                                    WorkflowStatus: ideaItem.PrevWorkflowStatus,
                                    ApprovalStatus: ideaItem.PrevApprovalStatus,
                                    SaveType: "Update",
                                    Action:"Active",
                                    Comment:this.state.Comment
                                }
                                // console.log("Param", param);
                                // return;
                                let finalJsonData = Checksum(param);
                                let str1 = finalJsonData.slice(0, 15);
                                let str2 = finalJsonData.slice(15, 30);
                                let str3 = finalJsonData.slice(30, 40);
                                let str4 = finalJsonData.slice(-10);
                                let finalData = str3+str1+str4+str2;
                                console.log("final data",finalData);
                                const requestOptions = {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "Authorization":  "Bearer " + this.accessToken,
                                    },
                                    body: JSON.stringify(param)
                                };
                                fetch(`${SUBMIT_IDEA_URL}Checksum=${finalData}`, requestOptions)
                                    .then(response => response.json())
                                    .then(data => {
                                        // Toast.hide();
                                        // Toast.success('Selected Ideas reinitiated successfully!', 3000, () => {
                                            console.log(JSON.stringify(data));
                                            // window.location = "/view-all-ideas-page";
                                        // });
                                    });
                                return false;
                            }
                            return;
                        });
                        return;
                    });
                    await this.timeout(8000);
                    Toast.hide();
                    Toast.success('Selected Ideas reinitiated successfully!', 3500, () => {
                        window.location = "/view-all-ideas-page";
                    });
                }
            }
            else{
                Toast.fail("Please add reason for bulk reinitiate!", 3000, () => { });
            }
        }
    }


    handleMonetizableOptionChange=(e)=>{
        this.setState({
            isTypedData:true,
            monetizableSelectedLabel:e.label,
            monetizableSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }


    handleZeroVDOptionChange=(e)=>{
        if(e.label === "Yes"){
            this.setState({
                isTypedData:true,
                zeroVDSelectedLabel:true,
                zeroVDSelectedOption: {
                    value: e.value,
                    label: e.label
                },
                skipCount:0
            }, async () => {
                await this.searchData();
            })
        }
        if(e.label === "No"){
            this.setState({
                isTypedData:true,
                zeroVDSelectedLabel:false,
                zeroVDSelectedOption: {
                    value: e.value,
                    label: e.label
                },
                skipCount:0
            }, async () => {
                await this.searchData();
            })
        }
        
    }


    handleOwnerSearch= (searchText) => {
        this.setState({
            searchOwner:searchText,
            isTypedData:true
        })
    }
    handleSearchMentor= (searchText) => {
        this.setState({
            searchMentor:searchText,
            isTypedData:true
        },()=>{
            
        })
    }

    renderUniqueSegment=()=>{
        let options = [];
        let accountName = "";
        this.state.uniqueSegments.map((item,index) => {
            console.log()
            accountName = item.SEGMENT ? item.SEGMENT.toString().toLowerCase() : "";
            if ( !( accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact") || 
            accountName === "" || accountName === null || accountName === undefined) ){
                options.push({
                    value: index,
                    label: item.SEGMENT
                })
            }
            return true;
        });
        console.log("segment options =>",options)
        return options;
    }

    renderUniqueUnits=()=>{
        let options = [];
        let accountName = "";
        this.state.uniqueUnits.map((item,index) => {
            accountName = item.UNIT ? item.UNIT.toString().toLowerCase():"";
            if ( !( accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact") || 
            accountName === "" || accountName === null || accountName === undefined) ){
                options.push({
                    value: index,
                    label: item.UNIT
                })
            }
            return true;
        });
        return options;
    }


    handleSegmentOptionChange=(e)=>{
        this.setState({
            isTypedData:true,
            searchSegment:e.label,
            searchSegmentSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }
    handleUnitOptionChange=(e)=>{
        this.setState({
            isTypedData:true,
            searchUnit:e.label,
            searchUnitSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }

    handleProjectPhaseChange= (searchText) => {
        this.setState({
            projectPhase:searchText,
            isTypedData:true
        })
    }

    render() {
        return (
            <>
                <div>
                    <h4 className="u-text" style={{ color: 'white', fontSize: '32px', position: 'absolute', left: '43%', top: '90px' }}>View all ideas</h4>
                    <img src={require("../assets/images/listbanner.png")} alt="" style={{ width: '100%' }} />
                </div>
                <section className="u-clearfix u-section-1" id="sec-0f7f">
                    <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20, width: '100%' }}>
                        <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                            <div className="u-container-layout u-container-layout-1 view-idea-table">
                                <div id="idea1" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round">
                                    <div className="u-container-layout u-container-layout-7">
                                        <div className="row">
                                            <div className="col-md-3" style={{marginTop:15}}>
                                                <div className="filter_main_block">
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize:18}} className={`${ FILTER } filterblock_title`} onClick={this.filterHandler} title="Search" aria-hidden="true"></i>
                                                {
                                                    this.state.filter
                                                    ?
                                                    <>
                                                        <div className="filter_block">
                                                            <h3>Filters:<span style={{float:'right', cursor:'pointer'}}><i className={CLOSE} onClick={()=>this.setState({filter:false})}></i></span></h3>
                                                            <div>
                                                                <Select isSearchable={true} options={this.renderDateOptions()} placeholder="Choose date" onChange={this.handleDateOptionChange} value={this.state.dateSelectedOption} />
                                                            </div>
                                                            <div className="filter_input">
                                                                <input type="date" max="2099-01-01" min ="1970-01-01" value={this.state.startDate} onChange={(event) => this.handleCreatedDate(event.target.value)} placeholder="Select from date" required />
                                                            </div>
                                                            <div className="filter_input">
                                                                <input type="date" max="2099-01-01" min ="1970-01-01" value={this.state.toDate} onChange={(event)=>this.handleToDate(event.target.value)} placeholder="Select to date" required/>
                                                            </div>
                                                            <div className="filter_input">
                                                                <input type="text" value={this.state.IdeaId} onChange={(event) => this.handleIdeaIDFilterChange(event.target.value)} placeholder="Search idea ID" onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.searchData()
                                                                    }
                                                                    } } 
                                                                />
                                                            </div>
                                                            <div>
                                                                <Select isSearchable={true} options={this.renderStatusOptions()} placeholder="Choose Review Status" onChange={this.handleReviewStatusFilterChange} value={this.state.workFlowStatusSelectedOption} />
                                                            </div>
                                                            <div style={{marginTop:7}}>
                                                                <Select isSearchable={true} options={this.renderApprovalStatusOptions()} placeholder="Choose Project Status" onChange={this.handleProjectStatusFilterChange} value={this.state.approvalStatusSelectedOption} />
                                                            </div>
                                                            <div style={{marginTop:7}}>
                                                                <Select isSearchable={true} options={this.renderQCApprovalStatusOptions()} placeholder="Choose QC Approval Status" onChange={this.handleQCApprovalStatusFilterChange} value={this.state.qcApprovalStatusSelectedOption} />
                                                            </div>
                                                            <div style={{marginTop:7}}>
                                                                <Select tabIndex={1} isSearchable={true} options={this.renderAccountOptions()} placeholder="Choose Account" onChange={this.handleAccountNameChange} value={this.state.accountSelectionOption} />
                                                            </div>
                                                            <div style={{marginTop:7}}>
                                                                <Select tabIndex={2} isSearchable={true} value={this.state.verticalSelectedOption} options={this.renderVerticalOptions()} placeholder="Choose Vertical" onChange={this.handleVerticalFilterChange} />
                                                            </div>
                                                            <div style={{marginTop:7}} >
                                                                <Select tabIndex={3} isSearchable={true} value={this.state.subVerticalSelectedOption} options={this.renderSubVerticalOptions()} placeholder="Choose Industry Vertical" onChange={this.handleSubVerticalNameFilterChange} />
                                                            </div>
                                                            <div  style={{marginTop:7}}>
                                                                <Select isSearchable={true} options={this.renderRegionOptions()} placeholder="Choose Region" onChange={this.handleRegionFilterChange} value={this.state.regionSelectedOption} />
                                                            </div>
                                                            <div style={{marginTop:7}}>
                                                                <Select isSearchable={true} options={this.renderCertLevelsOptions()} onChange={this.handleCertLevelChange} placeholder="Choose Certification Level" value={this.state.certLevelsSelectedOption}  />
                                                            </div>
                                                            <div style={{marginTop:7}}>
                                                                <Select isSearchable={true} options={this.renderTransLeversOptions()} onChange={this.handleTransformationLeverNameFilterChange} placeholder="Choose Transformation" value={this.state.transSelectedOption}  />
                                                            </div>
                                                            <div style={{marginTop:7}}>
                                                                <Select isSearchable={true} options={this.renderServiceLineOptions()} placeholder="Choose Service Line" onChange={this.handleService} value={this.state.serviceLineSelectedOption} />
                                                            </div>
                                                            <div style={{marginTop:7}}> 
                                                                <Select isSearchable={true} options={this.renderSubServiceLineOptions()} placeholder="Choose Sub-Service Line" onChange={this.handleSubServiceLine} value={this.state.subServiceLineSelectedOption}/>
                                                            </div>


                                                            <div style={{marginTop:7}}>
                                                                <Select isSearchable={true} options={YESORNO} placeholder="Choose Monetizable Project" onChange={this.handleMonetizableOptionChange} value={this.state.monetizableSelectedOption} />
                                                            </div>
                                                            <div style={{marginTop:7}}>
                                                                <Select isSearchable={true} options={YESORNO} placeholder="Choose Zero Value Delivery Project" onChange={this.handleZeroVDOptionChange} value={this.state.zeroVDSelectedOption} />
                                                            </div>


                                                            <div className="filter_input">
                                                                <input type="text" value={this.state.searchOwner} onChange={(event) => this.handleOwnerSearch(event.target.value)} placeholder="Search owner" onKeyPress={event => {
                                                                if (event.key === 'Enter') {
                                                                    this.searchData()
                                                                    }
                                                                } } 
                                                                />
                                                            </div>
                                                            <div className="filter_input">
                                                                <input type="text" value={this.state.searchMentor} onChange={(event) => this.handleSearchMentor(event.target.value)} placeholder="Search mentor" onKeyPress={event => {
                                                                if (event.key === 'Enter') {
                                                                    this.searchData()
                                                                    }
                                                                } } 
                                                                />
                                                            </div>
                                                            {/* <div className="filter_input">
                                                                <input type="text" value={this.state.projectPhase} onChange={(event) => this.handleProjectPhaseChange(event.target.value)} placeholder="Search project phase" onKeyPress={event => {
                                                                if (event.key === 'Enter') {
                                                                    this.searchData()
                                                                    }
                                                                } } 
                                                                />
                                                            </div> */}


                                                            <div className="" style={{marginTop:"7px"}}>
                                                                <Select isSearchable={true} options={this.renderUniqueSegment()} placeholder="Choose Segment" onChange={(e)=>this.handleSegmentOptionChange(e)} value={this.state.searchSegmentSelectedOption} />
                                                            </div>

                                                            <div className="" style={{marginTop:"7px"}}>
                                                                <Select isSearchable={true} options={this.renderUniqueUnits()} placeholder="Choose Unit" onChange={(e)=>this.handleUnitOptionChange(e)} value={this.state.searchUnitSelectedOption} />
                                                            </div>


                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    {
                                                                        this.state.isTypedData ? (
                                                                            <button onClick={()=>this.searchData() && this.setState({filter:false})} className="btn-block u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6">Search</button>
                                                                        ):(
                                                                            <button onClick={(e)=>window.alert("Please type something then search.")} className="btn-block u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6">Search</button>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <button onClick={()=>this.resetData()} className="btn-block u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3">Reset</button>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                }
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6"></div> */}
                                          
                                            <div className="col-md-1" style={{marginLeft:'-280px', marginTop:15}}>
                                                <a style={{ fontSize: 14, color:'#122539', textDecoration:'none'}} href="/review-page">{this.state.pageTitle}</a>
                                            </div>
                                            <div className="col-md-4" style={{marginTop:15}}>
                                                <a style={{ fontSize: 14, color:'#122539', borderBottom:'4px solid #259bca', textDecoration:'none'}} href="/view-all-ideas-page">All ideas</a>
                                            </div>
                                            {
                                                this.state.admin ? (
                                                    <>
                                                    <div className="col-md-1">
                                                        <button tabIndex={20} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.bulkCancellation}>Bulk Cancel</button>
                                                    </div>
                                                    <div className="col-md-1">
                                                        <button tabIndex={20} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.bulkReinitiate}>Bulk Reinitiate</button>
                                                    </div>
                                                    </>
                                                ):(
                                                    <div className="col-md-2">&nbsp;</div>
                                                )
                                            }
                                            <div className="text-left mt-4">
                                                {
                                                    this.state.bulkOperation
                                                        ?
                                                        <>
                                                            <div className="custom_modal">
                                                                <div className="modal_body" style={{width:'700px', overflow:'hidden'}}>
                                                                    <div className="row">
                                                                        <div className="col-md-8">
                                                                            <h3 className="modal_title">{this.state.bulkCancel ? "Bulk Cancellation" : "Bulk Reinitiate"}</h3>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <button type="button" className="close text-right" onClick={(e) => this.setState({ bulkOperation: false })}>&times;</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                                                <span className="u-text-black form-attribute"><span style={{ color: 'red' }}>*</span>&nbsp;Comments<span style={{ fontWeight: 'normal', fontSize:12, fontStyle: 'italic', color: 'grey' }}> (max. 500 chars)</span></span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style = {{marginTop:5}}>
                                                                        <div className="col-md-12">
                                                                            <textarea className="textarea-css form-attribute" rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey', ...this.state.selectStyle.comment }} placeholder="Please provide your comments" onChange={this.handleCommentChange} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn-group" style={{marginTop:10}}>
                                                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.submitEvent}>Submit</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-md-2" style={{marginLeft:60}}>
                                                <div className="filter_search_block">
                                                    <input value={this.state.globalsearchText} onChange={(event) => this.handleGlobalSearchFilterChange(event.target.value)} placeholder="Search here"  onKeyPress={event => {
                                                        if (event.key === 'Enter') {
                                                            this.globalSearch()
                                                        }
                                                        } } 
                                                    />
                                                    <i style={{ marginTop: 9, position: 'absolute', color: '#001A30', marginLeft:-35, cursor:'pointer' }} className={this.state.isTypedInGlobalSearch ? `${ SEARCH_ICON } icon-style` : `${ SEARCH_ICON } fa-disabled`} onClick={this.globalSearch} title="search"></i>
                                                    <i style={{ marginTop: 9, position: 'absolute', color: '#001A30', marginLeft:-15, cursor:'pointer' }} className={this.state.isTypedInGlobalSearch ? `${ CLOSE } icon-style` : `${ CLOSE } fa-disabled`} onClick={this.refreshSearch} title="close"></i>
                                                </div>
                                            </div>
                                            <div className="col-md-2" style={{marginLeft:-15}}>
                                                <select style={{width:60, height:35,border: '1px solid lightgrey'}} onChange={this.topCountChangeHandler} >
                                                    {
                                                        this.renderTopCountOptions()
                                                    }
                                                </select>
                                                <span style={{ verticalAlign: 'center', paddingLeft:4 }}> Rows per page</span>
                                            </div>
                                        </div>

                                        <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 10 }} />
                                        

                                        <div className="scrollbar_non_sticky_table">
                                            <table className="table review-idea-table" style={{ fontSize: 12 }}>
                                                <thead style={{textAlign:'center !important'}} className = "view-idea-thead">
                                                    <tr>
                                                        {/* <th scope="col">#</th> */}
                                                        {
                                                            this.state.admin && (
                                                                <th style={{padding:0.5}}>Select<input id='selectAll' name='check' type="checkbox" onChange={this.selectAll} /></th>
                                                            )
                                                        }
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleIdeaIdSortClick("IdeaId")}>
                                                            Idea ID
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.ideaIdSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        {
                                                            this.state.leanApprovalPage && (
                                                                <th className="hidden-xs hidden-sm" scope="col">Project ID</th>
                                                            )
                                                        }
                                                        {/* <th className="hidden-xs hidden-sm" scope="col">Project ID</th> */}
                                                        <th scope="col" onClick={(e) => this.handleIdeaNameSortClick("IdeaName")}>
                                                            Idea name
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.ideaNameSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="col-md-1 hidden-xs hidden-sm" scope="col" onClick={() => this.handleAccountSortClick("AccountManual")}>
                                                            Account
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.accountSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col">Owner</th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleSubmitterSortClick("OwnerName")}>
                                                            Submitter
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.submitterSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleApproverSortClick("BlackBeltUserName")}>
                                                            Approver
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.approverSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>

                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleCreatedDateSortClick("CreatedDate")}>
                                                            Create date
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.createdDateSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleReviewStatusSortClick("WorkflowStatus")}>
                                                            Idea review status
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.reviewStatusSortIcon ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleReviewDateSortClick("ModifiedDate")}>
                                                            Review date
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.reviewDateSortIcon ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleProjectStatusSortClick("ApprovalStatus")}>
                                                            Project status
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.projectStatusSortIcon ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleCertTypeSortClick("CertificationLevelName")}>
                                                            Certification type
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.certTypeSortIcon ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleTransformationLeverSortClick("TransformationLeverName")}>
                                                            Transformation lever
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.transformationLeverSortIcon ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th scope="col" onClick={() => this.handleServiceLineSortClick("ServiceLineName")}>
                                                            Service line
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.serviceLineSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th scope="col" onClick={() => this.handleSegmentSortClick("Segment")}>
                                                            Segment
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.segmentSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th scope="col" onClick={() => this.handleUnitSortClick("Unit")}>
                                                            Unit
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.unitSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th scope="col" style={{ paddingLeft: 25, paddingRight:25 }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className = "review-idea-tbody">
                                                    {
                                                        this.state.ideaList.length > 0 ? (
                                                            this.state.ideaList.map((ideaItem, index) => {
                                                                let createdDate = new Date(ideaItem.CreatedDate);
                                                                let modifiedDate = new Date(ideaItem.ModifiedDate);
                                                                let formattedCreateDate = this.formatDateString(createdDate);
                                                                let formattedModifiedDate = this.formatDateString(modifiedDate);

                                                                let IDEA_PENDING = (ideaItem.WorkflowStatus === "Pending");
                                                                let IDEA_APPROVED = (ideaItem.WorkflowStatus === "Approve");
                                                                let IDEA_REJECTED = (ideaItem.WorkflowStatus === "Reject");
                                                                let IDEA_SEND_BACK = (ideaItem.WorkflowStatus === "Send Back");
                                                                let IDEA_REQUEST_CANCELLATION = (ideaItem.WorkflowStatus === "Cancellation Requested");
                                                                let IDEA_CANCELLED = (ideaItem.WorkflowStatus === "Cancelled");
                                                                let action = VIEW;
                                                                let IDEA_AI_BOT_REVIEW = (ideaItem.WorkflowStatus === AWAITING_AI_BOT_REVIEW);
                                                                let IDEA_AI_FIRST_360_Enterprise = (ideaItem.WorkflowStatus === AWAITING_E360_SPOC_APPROVAL)
                                                                return (
                                                                    <tr key={ideaItem.IdeaId}>
                                                                        {
                                                                            this.state.admin && (
                                                                                (ideaItem.ApprovalStatus !== 'Awaiting Project Creation' && ideaItem.ApprovalStatus !== 'Rejected') ? (
                                                                                // (ideaItem.WorkflowStatus !== 'Cancelled' && ideaItem.ApprovalStatus !== 'Cancelled' && ideaItem.ApprovalStatus !== 'Completed' && ideaItem.ApprovalStatus !== 'Awaiting Project Creation') ? (
                                                                                    <td><input name="chk" type="checkbox" onChange={(event) => this.inputChanged(event,ideaItem.IdeaId)} /></td>
                                                                                ):(
                                                                                    <td></td>
                                                                                )
                                                                            )
                                                                        }
                                                                        <td className="hidden-xs hidden-sm" style={{width:'6%'}}>{ideaItem.IdeaIDAlpha ? ideaItem.IdeaIDAlpha : ideaItem.IdeaId}</td>
                                                                        <td className="idea_name_overFlow_height">
                                                                            {this.state.approvalPage ? ideaItem.IdeaName : (<a  data-toggle="tooltip" title={ideaItem.IdeaName} onClick={() => this.handleIdeaAction(ideaItem.IdeaId, action)}> {ideaItem.IdeaName}</a> ) }
                                                                        </td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word', maxWidth:'80px' }}>{ideaItem.AccountManual === null ? "--" : ideaItem.AccountManual}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word', maxWidth:'80px' }} title={ideaItem.CreatorOHR === '' ? ("OHR ID: " + ideaItem.OwnerOHR) : ("OHR ID: " + ideaItem.CreatorOHR)} >{ideaItem.CreatorName === '' ? ideaItem.OwnerName : ideaItem.CreatorName}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word', maxWidth:'80px' }} title={"OHR ID: " +  ideaItem.OwnerOHR} >{ideaItem.OwnerName === null ? "--" : ideaItem.OwnerName}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left', wordWrap: 'break-word', maxWidth:'80px' }} title={"OHR ID: " +  ideaItem.BlackBeltUserId} >{ideaItem.BlackBeltUserName === null ? "--" : ideaItem.BlackBeltUserName}</td>
                                                                        <td className="hidden-xs hidden-sm">{formattedCreateDate}</td>
                                                                        <td className="hidden-xs hidden-sm">{IDEA_APPROVED ? APPROVED : (IDEA_REJECTED ? REJECTED : (IDEA_SEND_BACK ? SENT_BACK : (IDEA_REQUEST_CANCELLATION ? "Cancellation Requested" : (IDEA_CANCELLED ? "Cancelled" : (IDEA_AI_BOT_REVIEW ? AWAITING_AI_BOT_REVIEW : IDEA_AI_FIRST_360_Enterprise ? AWAITING_E360_SPOC_APPROVAL : PENDING)))))}</td>
                                                                        <td className="hidden-xs hidden-sm">{IDEA_PENDING ? "NA" :formattedModifiedDate}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left' }}>{ideaItem.ApprovalStatus}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left' }}>{ideaItem.CertificationLevelName ===  null ? "NA" : ideaItem.CertificationLevelName }</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left' }}>{ideaItem.TransformationLeverName === null ? "NA" : ideaItem.TransformationLeverName}</td>
                                                                        <td style={{ textAlign: 'left', wordWrap: 'break-word', maxWidth:'80px' }}>{ideaItem.ServiceLineName}</td>
                                                                        <td style={{ textAlign: 'left', wordWrap: 'break-word', maxWidth:'80px' }}>{ideaItem.Segment}</td>
                                                                        <td style={{ textAlign: 'left', wordWrap: 'break-word', maxWidth:'80px' }}>{ideaItem.Unit}</td>
                                                                        <td>
                                                                            <div className="row">
                                                                                <button onClick={() => this.handleIdeaAction(ideaItem.IdeaId, action)} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-hover-custom-color-6 u-radius-6 u-btn-2 new-btn-style" style={{ minWidth: 60, borderRadius: 0, marginLeft:25, height:20 }}>
                                                                                    View</button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        ) : (
                                                            <tr>
                                                                    <td colSpan="100%"> No items found! </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* <div className="col-md-8">&nbsp;</div> */}
                                        {/* <div className="col-md-1" style={{ height: 34, marginRight: 5, width: 80}}>
                                        </div> */}
                                        {/* <div className="col-md-1" style={{ border: '1px solid lightgrey', height: 34, marginRight: 5, width: 80, backgroundColor: 'white'}}>
                                            <input type="number" value={this.state.pageNo} style={{ fontSize: 10, outline: 'none', marginLeft:-5, padding:'6px 0px'}} onChange={ this.handlePageChangeHandler } placeholder="Search page No" />
                                            <i style={{marginTop:9, position:'absolute', color:'#001A30', cursor:'pointer'}} className={SEARCH_ICON} onClick={this.goToPage} title="Go to"></i>
                                        </div> */}
                                        <div style={{float:'right'}}>
                                            <div className="col-md-1">
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize:18}} className={this.state.isPrevEnabled ? `${ ANGLE_DOUBLE_LEFT } icon-style` : `${ ANGLE_DOUBLE_LEFT } fa-disabled`} onClick={() => this.state.isPrevEnabled ? this.handleGetFirstPage():''} title="first" aria-hidden="true"></i>
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize:18, marginLeft:30}} className={this.state.isPrevEnabled ? `${ ANGLE_LEFT } icon-style` : `${ ANGLE_LEFT } fa-disabled`} onClick={() => this.state.isPrevEnabled ? this.handlePrevAction():''} title="previous" aria-hidden="true"></i>
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize:18, marginLeft:60}} className={this.state.isNextEnabled ? `${ ANGLE_RIGHT } icon-style` : `${ ANGLE_RIGHT } fa-disabled`}  onClick={() => this.state.isNextEnabled ? this.handleNextAction(): ''} title="next" aria-hidden="true"></i>
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize:18, marginLeft:90, marginRight:10}} className={this.state.isNextEnabled ? `${ ANGLE_DOUBLE_RIGHT } icon-style` : `${ ANGLE_DOUBLE_RIGHT } fa-disabled`} onClick={() => this.state.isNextEnabled ? this.handleGetLastPage() : ''} title="last" aria-hidden="true"></i>
                                            </div>
                                            <div className="col-md-7" style ={{marginLeft:120, marginTop:5}}>Page {this.state.currentPage} of {this.state.totalPages}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default ViewAllIdeaPage


