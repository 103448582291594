function sortByColumns(dataArray, column, orderFlag) {
  let sortedData = [];
  if (orderFlag === "asc") {
    sortedData = dataArray.sort((a, b) => {
      if (a[column] < b[column]) return -1;
      if (a[column] > b[column]) return 1;
      if (a[column] === b[column]) return 1;
      return 1;
    });
  } else {
    sortedData = dataArray.sort((a, b) => {
      if (a[column] > b[column]) return -1;
      if (a[column] < b[column]) return 1;
      if (a[column] === b[column]) return 1;
      return 1;
    });
  }
  return sortedData;
}

export default sortByColumns;