export const selectValidation = (flag) => {
    let validatestyle = {
        control: (base, state) => ({
            ...base,
            // state.isFocused can display different borderColor if you need it
            borderColor: state.isFocused ? '#2684FF' : !flag ? "red" : "lightgrey",
            // overwrittes hover style
            '&:hover': {
                borderColor: state.isFocused ? '#2684FF' : !flag ? 'red' : '#2684FF'
            }
        })
    }
    return validatestyle;
}

export const textBoxValidation = (flag) => {
    let styleObje = flag ? { border: '1px solid lightgrey' } : { border: '1px solid red' }
    return styleObje;
}