import React, { Component } from 'react'
import { APPROVED, GET_ALL_IDEA_URL, PENDING,AWAITING_AI_BOT_REVIEW, SENT_BACK, REJECTED, APPROVAL, LEAN_APPROVAL, COMPLETED_STATUS, AWAITING_MBB_APPROVAL, GET_METADATA, OPPMASTERSTAGES,OPPMASTERSTATE } from '../assets/constants/constants';
import { SORT_DOWN, SORT_UP, SEARCH_ICON, ANGLE_LEFT, ANGLE_RIGHT, ANGLE_DOUBLE_LEFT, ANGLE_DOUBLE_RIGHT, FILTER, CLOSE  } from "../assets/constants/Icons.js";
import { VIEW, DELEGATE, UPDATE, EDIT, USER_DATA, ACCESS_TOKEN } from "../assets/constants/constants";
import SortByColumn from "../utils/SortByColumns";
import Toast from 'light-toast';
import Select from 'react-select';

export class GenAIMbbApprovalQueue extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);
    
    constructor(props) {
        super(props)
      
        if (this.sessionData === null) {
            Toast.loading("This component cannot be accessed directly. Redirecting to application home.", 3000, () => {
            });
            window.location = "/login";
            return;
        }
        const windowUrl = window.location.search;
        const isApprovalPage = windowUrl.indexOf(APPROVAL) > 0 ;
        const isLeanApprovalPage = windowUrl.indexOf(LEAN_APPROVAL) > 0; 

        this.state = {
            ideaList: [],
            originalIdeaList: [],
            myIdeaList:[],
            accountSortIconRotate: false,
            ideaNameSortIconRotate: false,
            createdDateSortIconRotate: false,
            reviewStatusSortIcon: false,
            ownerOHR: this.sessionData === null ? null : this.sessionData.profile.oHRId,
            // ownerOHR: Number(this.sessionData.profile.oHRId), //TODO: Remove before UAT
            ownerName: this.sessionData === null ? null : this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
            approvalPage: isApprovalPage,
            leanApprovalPage: isLeanApprovalPage,
            pageTitle:  "Review Opportunities",
            topCountOption: [5,10,25,50,75,100],
            topCount:5,
            skipCount:0,
            isPrevEnabled:false,
            isNextEnabled:true,
            IdeaLength:0,
            totalPages:0,
            currentPage:0,
            pageNo:'',
            filter:false,
            WorkflowStatus:'',
            IdeaId:'',
            AccountManual:'',
            RegionManual:'',
            VerticalManual:'',
            TransformationLeverName:'',
            CertificationLevelName:'',
            SubVerticalName:'', 
            ServiceLineName:'', 
            SubServiceLine:'',
            startDate:'', 
            toDate:'',
            isTypedData:false,
            WhereClause:'',
            fromFilterSelection:'',
            globalsearchText:'',
            isTypedInFilter:'',
            isTypedInGlobalSearch:false,
            tempWorkflowStatus:'',
            tempIdeaId:'',
            tempAccountManual:'',
            tempRegionManual:'',
            tempVerticalManual:'',
            tempTransformationLeverName:'',
            tempCertificationLevelName:'',
            tempSubVerticalName:'', 
            tempServiceLineName:'', 
            tempSubServiceLine:'',
            tempstartDate:'', 
            temptoDate:'',
            accountList: [],
            accountSelectionOption: null,
            // workFlowStatusList: [],
            workFlowStatusList: [{
                WorkflowStatusId: 1,
                WorkflowStatusName: "Approve"
            },
            {
                WorkflowStatusId: 2,
                WorkflowStatusName: "Reject"
            },
            {
                WorkflowStatusId: 3,
                WorkflowStatusName: "Send Back"
            },
            {
                WorkflowStatusId: 4,
                WorkflowStatusName: "Pending"
            }
            ],
            projectStatusList: [{
                WorkflowStatusId: 1,
                WorkflowStatusName: "Completed"
            },
            {
                WorkflowStatusId: 2,
                WorkflowStatusName: "Rejected"
            },
            {
                WorkflowStatusId: 3,
                WorkflowStatusName: "Awaiting LDT Approval"
            },
            {
                WorkflowStatusId: 4,
                WorkflowStatusName: "Awaiting MBB Approval"
            },
            {
                WorkflowStatusId: 5,
                WorkflowStatusName: "Pending Documents"
            },
            {
                WorkflowStatusId: 6,
                WorkflowStatusName: "Documents Uploaded"
            }
            ],
            workFlowStatusSelectedOption: null,
            transformationLeversList: [],
            transSelectedOption: null,
            serviceLineList: [],
            subServiceLineList: [],
            serviceLineSelectedOption: null,
            subServiceLineSelectedOption: null,
            regionList: [],
            verticalList: [],
            subVerticalList: [],
            regionSelectedOption: null,
            verticalSelectedOption: null,
            subVerticalSelectedOption: null,
            dateList: ["Created date"],
            dateSelectedOption:null,
            selectedDate:'',
            
            certificationLevelsList: [],
            certLevelsSelectedOption: null,
            bestInClassList: ["Yes","No"],
            bestInClassOption:null,
            selectedBestInClass:'',
            replicaIndexOptionList: [1,2,3,4,5],     
            replicaIndexOption:null,
            selectedReplicaIndex:'',
            sortColumnName:"CreatedDate",
            sortOrder:"desc",
            adminUserList:[],
            IsAdmin:false,
            searchOwner:"",
            searchSegment:"",
            searchUnit:"",
            uniqueUnits:[],
            uniqueSegments:[],
            searchSegmentSelectedOption:[],
            searchUnitSelectedOption:[],
            
        }
    }


    async componentDidMount() {
        await this.searchData();
        await this.fetchMetedata();
    }

    fetchIdea = () => {
        Toast.loading("Fetching Opportunities...", () => { });
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            
            if(this.state.skipCount===0){
                this.setState({
                    isPrevEnabled:false,
                })
            }
            else{
                this.setState({
                    isPrevEnabled:true
                })
            }

            fetch(`${GET_ALL_IDEA_URL}UserOHR=''&CreatedBy=''&WhereClause=${this.state.WhereClause}&skipCount=${this.state.skipCount}&topCount=${this.state.topCount}&sortingorder=${this.state.sortOrder}&sortingcolumn=${this.state.sortColumnName}&isItIdeaOrGenAI='GenAI'`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    
                    this.setState({
                        ideaList: data.oppList.ResultSets.Table1 === undefined ? [] : data.oppList.ResultSets.Table1,
                        originalIdeaList: data.oppList.ResultSets.Table1 === undefined ? [] : data.oppList.ResultSets.Table1,
                        IdeaLength:data.TotalOpp.Table1[0].length,
                        fromFilterSelection:data.filter
                    }, () => {
                        
                        let totalPages = Math.ceil(parseInt(this.state.IdeaLength)/parseInt(this.state.topCount));
                        let currentPage = Math.ceil(parseInt(this.state.skipCount)/(parseInt(this.state.topCount)) +1);
                        if(totalPages===0){
                            totalPages=1
                        }
                        this.setState({
                            totalPages:totalPages,
                            currentPage:currentPage
                        }, () => {
                            if(this.state.currentPage === this.state.totalPages){
                                this.setState({
                                    isNextEnabled:false
                                })
                            }
                            else{
                                this.setState({
                                    isNextEnabled:true
                                })
                            }
                        });
                         
                        this.handleFilterChange({ value: 0 }); 
                        Toast.hide();
                        resolve();
                    });
                })
                .catch(error => {
                    console.log(error);
                    Toast.hide();
                    resolve();
                }); 
        });
    }

    fetchMetedata = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "",
                EntityName: ""
            }
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                //console.log(data)
                Toast.hide();
                this.setState({
                    accountList: data ? data.Accounts.sort((a, b) => { return a.ACCOUNT > b.ACCOUNT ? 1 : -1; }) : [],
                    serviceLineList: data ? data.ServiceLines : [],
                    regionList:data?data.Region:[],
                    verticalList:data?data.VerticalData:[],
                    uniqueUnits:data ? data.UnitSegment ? data.UnitSegment.ResultSets ? data.UnitSegment.ResultSets.Table1 : []: []:[],
                    uniqueSegments:data ? data.UnitSegment ? data.UnitSegment.ResultSets ? data.UnitSegment.ResultSets.Table2 : []: []:[],
                },()=>{});
                resolve();
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting Metadata. Please try again!', 3000, () => {
                    console.log(JSON.stringify(error));
                });
            }); 
        });
    }
    
    renderVerticalOptions = () => {
        let options = [];
        this.state.verticalList.map((item) => {
            if(item.Vertical !== "TS"){
                options.push({
                    value: item.Vertical,
                    label: item.Vertical
                })
            }
            return options;
        });
        return options;
    }

    renderTopCountOptions = () => {
        if(this.state.topCountOption.length > 0){
            return this.state.topCountOption.map((item) => {
                let isSelected = false;
                if(item === this.state.topCount){
                    isSelected = true;
                }
                return(
                    <option selected= { isSelected }>{ item }</option>
                );
            });
        }
    }

    topCountChangeHandler = async (event) => {
        const index = event.nativeEvent.target.selectedIndex;
        this.setState({
            topCount: event.nativeEvent.target[index].text
        }, async () => { 
            await this.fetchIdea();
        });
    }

    handleIdeaAction = (ideaId, action) => {
        if(action === "View"){
            this.props.history.push({
                pathname: "/view-opportunity",
                state: ideaId,
            })
        } 
        if (action === "Update") {
            this.props.history.push({
                pathname: "/gen-ai-mbb-approval",
                state: ideaId,
              
            })
        } 
    }

    handleIdeaIdSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                ideaIdSortIconRotate: !prevState.ideaIdSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.ideaIdSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleAccountSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                accountSortIconRotate: !prevState.accountSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.accountSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleIdeaNameSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                ideaNameSortIconRotate: !prevState.ideaNameSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.ideaNameSortIconRotate ? "desc" : "asc";
             this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleServiceLineSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                serviceLineSortIconRotate: !prevState.serviceLineSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.serviceLineSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }


    handleRegionSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                regionSortIconRotate: !prevState.regionSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.regionSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }
    

    handleUnitSortClick= (columnName) => {
        this.setState(prevState => {
            return {
                unitSortIconRotate: !prevState.unitSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.unitSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleSegmentSortClick= (columnName) => {
        this.setState(prevState => {
            return {
                segmentSortIconRotate: !prevState.segmentSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.segmentSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    





    handleApproverSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                approverSortIconRotate: !prevState.approverSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.approverSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleSubmitterSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                submitterSortIconRotate: !prevState.submitterSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.submitterSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleCreatedDateSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                createdDateSortIconRotate: !prevState.createdDateSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.createdDateSortIconRotate ? "desc" : "asc";
         
            this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleReviewStatusSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                reviewStatusSortIcon: !prevState.reviewStatusSortIcon
            }
        }, () => {
            let sortOrder = this.state.reviewStatusSortIcon ? "desc" : "asc";
            this.setState({
                // ideaList: sortedData
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleReviewDateSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                reviewDateSortIconRotate: !prevState.reviewDateSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.reviewDateSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleCertTypeSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                certTypeSortIconRotate: !prevState.certTypeSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.certTypeSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleTransformationLeverSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                transformationLeverSortIcon: !prevState.transformationLeverSortIcon
            }
        }, () => {
            let sortOrder = this.state.transformationLeverSortIcon ? "desc" : "asc";
            this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleProjectStatusSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                projectStatusSortIcon: !prevState.projectStatusSortIcon
            }
        }, () => {
            let sortOrder = this.state.projectStatusSortIcon ? "desc" : "asc";
            this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    renderFilterOptions = () => {
        return [
            { value: 0, label: "All Ideas" },
            { value: 1, label: "My ideas" },
            { value: 2, label: "My Approvals" }
        ]
    }

    handleFilterChange = (e) => {
        let filteredIdeaArray = [];
        if (e.value === 0) {
            filteredIdeaArray = this.state.originalIdeaList;
        }
        this.setState({
            ideaList: filteredIdeaArray,
            originalIdeaList: filteredIdeaArray
        })
    }

    formatDateString(dateparam){
        if(dateparam){
            return dateparam.toLocaleDateString("en-GB", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
        }else{
            return null
        }
        
    }

    totalIdeaCount = () => {
        let totalCount = this.state.myOppLength;
        return totalCount;
    }

    totalPendingIdeaCount = () => {
        let totalCount = 0;
        this.state.ideaList.map((oppList, index) => {
            if(oppList.WorkflowStatus === "Pending"){
                totalCount = totalCount +1 ;
            }
            return true;
        })
        return (totalCount < 10 ? '0' : '') + totalCount;
    }

    totalApprovedIdeaCount= () => {
        let totalCount = 0;
        this.state.myIdeaList.map((ideaItem, index) => {
            if(ideaItem.WorkflowStatus === "Approve"){
                totalCount = totalCount +1 ;
            }
            return true;
        })
        return (totalCount < 10 ? '0' : '') + totalCount;
    }

    totalRejectedIdeaCount= () => {
        let totalCount = 0;
        this.state.myIdeaList.map((ideaItem, index) => {
            if(ideaItem.WorkflowStatus === "Reject"){
                totalCount = totalCount +1 ;
            }
            return true;
        })
        return (totalCount < 10 ? '0' : '') + totalCount;
    }

    totalSendBackIdeaCount= () => {
        let totalCount = 0;
        this.state.myIdeaList.map((ideaItem, index) => {
            if(ideaItem.WorkflowStatus === "Send Back"){
                totalCount = totalCount +1 ;
            }
            return true;
        })
        return (totalCount < 10 ? '0' : '') + totalCount;
    }

    resetData = () => {
        this.setState({
            WorkflowStatus:'',
            IdeaId:'',
            AccountManual:'',
            RegionManual:'',
            VerticalManual:'',
            TransformationLeverName:'',
            CertificationLevelName:'',
            SubVerticalName:'', 
            ServiceLineName:'', 
            SubServiceLine:'',
            startDate:'',
            toDate:'',
            filter:false
        }, () => {
            window.location.reload();
        })
    }

    globalSearch = () =>{
        if(this.state.currentPage === this.state.totalPages){
            this.setState({
                skipCount:0
            })
        }
        let WhereClause = '';
        if(this.state.globalsearchText !== null && this.state.globalsearchText !== ''){
            
            WhereClause= this.state.isTypedInFilter !== '' ? this.state.isTypedInFilter + ' and ': '';
            WhereClause=WhereClause+encodeURIComponent( "Concat(RecID,RecIDAlpha,OppName,CreatedDate,CreatedBy,Account,Unit,MangingDept,Vertical,Segment,GOL,OppStage,OppState,Spoc_1,Spoc_2,BB,MBB,SolnOwner,UnitOHRName,UnitOHR, SpocOHR_2,SpocOHR_1,MBBOHR,BBOHR,GOLOHR,Segment,CentTeamOHR,CentTeam,SolnOwnerOHR) like '%"+this.state.globalsearchText+"%'")
            
           
            if(WhereClause !== ''){
                this.setState({
                    WhereClause:WhereClause,
                    filter:false
                }, async() =>{
                    await this.fetchIdea();
                })
            }
        }
        else{
            Toast.fail("Please type something then search.");
            return;
        }
    }

    searchData = async() =>{
        this.setState({filter:false})
        let WhereClause = '';
        WhereClause = "(OppState = 'Pending Approval from MBB' and MBBOHR = '" +this.state.ownerOHR +"')";
        
        if(this.state.RecID || this.state.RecIDAlpha ){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + '(' + 'RecID'+ ' ' + 'like' + " '" + this.state.RecID + '%'+"'" + ' or ' + 'RecIDAlpha'+ ' ' + 'like' + " '" + this.state.RecIDAlpha + '%'+"'" + ')';
        }
        if(this.state.Account){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'Account'+ ' ' + 'like' + " '" + this.state.Account + '%'+"'";
        }
        if(this.state.Vertical){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'Vertical'+ ' ' + 'like' + " '" + this.state.Vertical + '%'+"'";
        }
        if (this.state.OppStage) {
            WhereClause = WhereClause !== '' ? WhereClause + ' and ' : '';
            WhereClause = WhereClause + 'OppStage' + ' ' + 'like' + " '" + this.state.OppStage + '%' + "'";
        }
        if (this.state.OppState) {
            WhereClause = WhereClause !== '' ? WhereClause + ' and ' : '';
            WhereClause = WhereClause + 'OppState' + ' ' + 'like' + " '" + this.state.OppState + '%' + "'";
        }
        
        if(this.state.startDate !== '' && this.state.toDate !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            if(this.state.selectedDate === "ModifiedDate"){
                WhereClause =  WhereClause + '(' + '('+ this.state.selectedDate + ' ' + 'between ' + "'"+ this.state.startDate+'T00:00:00.00' +"'"+ ' and ' +"'"+ this.state.toDate+'T23:59:00.00' +"'"+ ')' + ' and ' + 'WorkflowStatus' + ' <> '  +"'" +'Pending'+ "'" + ')';
            }
            else{
                WhereClause =  WhereClause + '('+ this.state.selectedDate + ' ' + 'between ' + "'"+ this.state.startDate+'T00:00:00.00' +"'"+ ' and ' +"'"+ this.state.toDate+'T23:59:00.00' +"'"+ ')';
            }
        }
        if(this.state.startDate !== ''){
            if(!this.state.selectedDate){
                Toast.fail("Please select date type first.");
                return;
            }
            if(this.state.toDate === ''){
                Toast.fail("Please enter both the start date and end date.");
                return;
            }
        }

        if(this.state.CreatedName){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + '(' + 'CreatedName'+ ' ' + 'like' + " '" + this.state.CreatedName + '%'+"'" + ')';
        }

        if(this.state.Segment){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + '(' + 'Segment'+ ' ' + 'like' + " '" + encodeURIComponent(this.state.Segment) + '%'+"'" +  ')';
        }
        if(this.state.Unit){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + '(' + 'Unit'+ ' ' + 'like' + " '" + encodeURIComponent(this.state.Unit) + '%'+"'" +  ')';
        }
        

        if(this.state.toDate !== ''){
            if(!this.state.selectedDate){
                Toast.fail("Please select date type first.");
                return;
            }
            if(this.state.startDate === ''){
                Toast.fail("Please enter both the start date and end date.");
                return;  
            }
        }

        if(this.state.selectedDate && (this.state.startDate === '' || this.state.toDate === '')){
            Toast.fail("Please enter both the start date and end date.");
            return; 
        }
        
        if(this.state.startDate && this.state.toDate ){
            if(this.state.startDate > this.state.toDate){
                Toast.fail("Start date should not be greater than end date.");
                return; 
            }
        }

        if (this.state.CreatedBy) {
            WhereClause = WhereClause !== '' ? WhereClause + ' and ' : '';
            WhereClause = WhereClause + '(' + 'CreatedBy' + ' ' + 'like' + " '" + this.state.CreatedBy + '%' + "'" + ')';
        }

        if(WhereClause !== ''){
            this.setState({
                WhereClause:WhereClause,
                isTypedInFilter:WhereClause,
                tempWorkflowStatus:this.state.WorkflowStatus,
                tempIdeaId:this.state.IdeaId,
                tempAccountManual:this.state.AccountManual,
                tempRegionManual:this.state.RegionManual,
                tempVerticalManual:this.state.VerticalManual,
                tempServiceLineName:this.state.ServiceLineName, 
                tempstartDate:this.state.startDate, 
                temptoDate:this.state.toDate,
            }, async() =>{
                await this.fetchIdea();
            })
        }
        else{
            if(this.state.tempIdeaId){
                this.refreshSearch();
            }
            else{
                Toast.fail("Please type something then search.");
            }
        }
    }

    renderDateOptions = () => {
        let options = [];
        this.state.dateList.map((item) => {
            options.push({
                value: item,
                label: item
            })
            return options;
        });
        return options;
    }

    renderStatusOptions = () => {
        let options = [];
        this.state.workFlowStatusList.map((item) => {
          if (this.state.approvalPage) {
            if (item.WorkflowStatusName !== "Send Back") {
              options.push({
                value: item.WorkflowStatusId,
                label: item.WorkflowStatusName,
              });
            }
    
          } else {
            // options.push({
            //   value: item.WorkflowStatusId,
            //   label: item.WorkflowStatusName,
            // });
            options = [
              {value:0,label:"Approve"},
              {value:1,label:"Reject"},
              {value:2,label:"Send Back"},
              {value:3,label:"Pending"},
              {value:4,label:AWAITING_AI_BOT_REVIEW},
             
            ]
          }
          return options;
        });
        return options;
      };

    renderProjectStatusOptions = () => {
        let options = [];
        this.state.projectStatusList.map((item) => {
            options.push({
                value: item.WorkflowStatusId,
                label: item.WorkflowStatusName
            })
            return options;
        });
        return options;
    }

    renderAccountOptions = () => {
        let options = [];
        let accountName = "";
        this.state.accountList.map((item) => {
            accountName = item.ACCOUNT.toString().toLowerCase();
            if ( !( accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact") ) ){
                options.push({
                    value: item.ACCOUNT_L2,
                    label: item.ACCOUNT
                })
            }
            return true;
        });
        return options;
    }

    renderTransLeversOptions = () => {
        let options = [];
        this.state.transformationLeversList.map((item) => {
            options.push({
                value: item.TransformationLeverId,
                label: item.TransformationLeverName
            });
            return options;
        });
        return options;
    }

    renderCertLevelsOptions = () => {
        let options = [];
        this.state.certificationLevelsList.map((item) => {
            options.push({
                value: item.CertificationLevelId,
                label: item.CertificationLevelName
            });
            return options;
        });
        return options;
    }

    renderRegionOptions  = () => {
        let options = [];
        this.state.regionList.map((item) => {
            options.push({
                value: item.Region,
                label: item.Region
            })
            return options;
        });
        return options;
    }

    renderServiceLineOptions = () => {
        let options = [];
        const uniqueServiceLineList = [...new Map(this.state.serviceLineList.map(item =>
            [item.ServiceLineName, item])).values()];
        uniqueServiceLineList.map((item) => {
            options.push({ value: item.ServiceLineId, label: item.ServiceLineName });
            return true;
        });
        return options;
    }
    
    renderSubServiceLineOptions = () => {
        let options = [];
        this.state.subServiceLineList.map((item) => {
            if (this.state.ServiceLineName === item.ServiceLineName) {
                options.push({ value: item.SubServiceLineId, label: item.SubServiceLineName });
                return true;
            }
            return true;
        });
        return options;
    }

    renderReplicaIndexOption = () => {
        let options = [];
        this.state.replicaIndexOptionList.map((item) => {
            options.push({
                value: item,
                label: item
            })
            return options;
        });
        return options;
    }

    renderBestInClassOptions = () => {
        let options = [];
        this.state.bestInClassList.map((item) => {
            options.push({
                value: item,
                label: item
            })
            return options;
        });
        return options;
    }

    handleDateOptionChange= (e) => {
        this.setState({
            isTypedData:true,
            dateSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        },() => {
            if(e.label === "Created date"){
                this.setState({
                    selectedDate:"CreatedDate"
                })
            }
            else if(e.label === "Reviewed date"){
                this.setState({
                    selectedDate:"ModifiedDate"
                })
            }
        });
    }

    handleAccountNameChange = (e) => {
        this.setState({
            AccountManual:e.label,
            Account: e.label,
            isTypedData:true,
            accountSelectionOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        });
    }

    handleReviewStatusFilterChange = (e) => {
        this.setState({
            WorkflowStatus:e.label,
            isTypedData:true,
            workFlowStatusSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }

    handleIdeaIDFilterChange = (searchText) => {
        this.setState({
            RecID:searchText,
            RecIDAlpha:searchText,
            isTypedData:true
        })
    }
    handleOwnerSearchByName = (searchText) => {
        this.setState({
            CreatedName: searchText,
            isTypedData: true
        })
    }
    handleOwnerSearchByOHR = (searchText) => {
        this.setState({
            CreatedBy: searchText,
            isTypedData: true
        })
    }

    handleVerticalFilterChange = (e) => {
        this.setState({
            subVerticalSelectedOption: null,
            Vertical: e.label,
            verticalSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0,
            isTypedData:true
        }, async () => {
            await this.searchData();
        })
    }
    handleOppStageFilterChange = (e) => {
        this.setState({
            OppStage: e.label,
            OppStageSelectedOption: {
                value: e.value,
                label: e.label
            },
            isTypedData: true,
            skipCount: 0
        }, async () => {
            await this.searchData();
        })
    }
    handleOppStateChange = (e) => {
        this.setState({
            OppState: e.label,
            isTypedData: true,
            OppStateSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount: 0
        }, async () => {
            await this.searchData();
        })
    }
    
    
    handleServiceFilter =(e)=>{
        this.setState({
            ServiceLine:e.label,
            isTypedData:true,
            serviceLineSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0,
          
        }, async () => {
            await this.searchData();
        })
    }


    handleCreatedDate=(dates)=>{
        this.setState({
            startDate:dates,
            isTypedData:true
        });
    }

    handleToDate=(dates)=>{
        this.setState({
            toDate:dates,
            isTypedData:true
        });
    
    }

    handleGlobalSearchFilterChange = (searchText) => {
        this.setState({
            globalsearchText:searchText,
            isTypedData:true,
            isTypedInGlobalSearch:true
        })
        if(searchText === ''){
            window.location.reload();
        }
    }

    handleGetFirstPage = () => {
        this.setState({
            skipCount:0
        }, async () => {
            await this.fetchIdea();
        })
    }

    handleGetLastPage = () => {
        let skipCount = (parseInt(this.state.totalPages) - 1) * parseInt(this.state.topCount);
        this.setState({
            skipCount:skipCount
        }, async () => {
            await this.fetchIdea();
        })
    }

    handlePrevAction = () => {
        let skipCount = this.state.skipCount;
        let topCount = this.state.topCount;
        this.setState({
            skipCount:parseInt(skipCount)-parseInt(topCount),
        }, async () => {
            await this.fetchIdea();
        })
    }

    handleNextAction = async() =>{
        let skipCount = this.state.skipCount;
        let topCount = this.state.topCount;
        this.setState({
            skipCount: parseInt(skipCount) + parseInt(topCount)
        }, async () => {
            await this.fetchIdea();
        })
    }
    
    handlePageChangeHandler = (event) => {
        this.setState({
            pageNo:event.target.value
        })
    }

    goToPage = () => {
        this.setState({
            skipCount: (parseInt(this.state.pageNo)-1) * parseInt(this.state.topCount)
        }, async () => {
            await this.fetchIdea();
        })
    }

    refreshSearch = () => {
        window.location.reload();
    }

    filterHandler = () => {
        this.setState({
            filter:!this.state.filter,
            IdeaId:this.state.tempIdeaId,
            WorkflowStatus:this.state.tempWorkflowStatus,
            AccountManual:this.state.tempAccountManual,
            RegionManual:this.state.tempRegionManual,
            VerticalManual:this.state.tempVerticalManual,
            TransformationLeverName:this.state.tempTransformationLeverName,
            CertificationLevelName:this.state.tempCertificationLevelName,
            SubVerticalName:this.state.tempSubVerticalName, 
            ServiceLineName:this.state.tempServiceLineName, 
            SubServiceLine:this.state.tempSubServiceLine,
            startDate:this.state.tempstartDate, 
            toDate:this.state.temptoDate
        });
    }

    renderUniqueSegment=()=>{
        let options = [];
        let accountName = "";
        this.state.uniqueSegments.map((item,index) => {
            console.log()
            accountName = item.SEGMENT ? item.SEGMENT.toString().toLowerCase() : "";
            if ( !( accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact") || 
            accountName === "" || accountName === null || accountName === undefined) ){
                options.push({
                    value: index,
                    label: item.SEGMENT
                })
            }
            return true;
        });
                return options;
    }

    renderUniqueUnits=()=>{
        let options = [];
        let accountName = "";
        this.state.uniqueUnits.map((item,index) => {
            accountName = item.UNIT ? item.UNIT.toString().toLowerCase():"";
            if ( !( accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact") || 
            accountName === "" || accountName === null || accountName === undefined) ){
                options.push({
                    value: index,
                    label: item.UNIT
                })
            }
            return true;
        });
        return options;
    }


    handleSegmentOptionChange=(e)=>{
        this.setState({
            isTypedData:true,
            Segment:e.label,
            searchSegmentSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }
    handleUnitOptionChange=(e)=>{
        this.setState({
            isTypedData:true,
            Unit:e.label,
            searchUnitSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }

    handleUnitSPOCSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                unitSPOCSortIconRotate: !prevState.unitSPOCSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.unitSPOCSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
        
    }

    handleSPOC1SortClick = (columnName) => {
        this.setState(prevState => {
            return {
                SPOC1SortIconRotate: !prevState.SPOC1SortIconRotate
            }
        }, () => {
            let sortOrder = this.state.SPOC1SortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }
    handleSPOC2SortClick = (columnName) => {
        this.setState(prevState => {
            return {
                SPOC2SortIconRotate: !prevState.SPOC2SortIconRotate
            }
        }, () => {
            let sortOrder = this.state.SPOC2SortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleSolutionOwnerSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                SolutionOwnerSortIconRotate: !prevState.SolutionOwnerSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.SolutionOwnerSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleModifiedDateSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                modifiedDateSortIconRotate: !prevState.modifiedDateSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.modifiedDateSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }
    handleVerticialSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                verticalSortIconRotate: !prevState.verticalSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.verticalSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }
    handleOppStageSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                OppStageSortIconRotate: !prevState.OppStageSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.OppStageSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }
    handleoppStateSortIconSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                OppStateSortIconRotate: !prevState.OppStateSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.OppStateSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    handleBBSortIconSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                BBSortIconRotate: !prevState.BBSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.BBSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

handleMBBSortIconSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                MBBSortIconRotate: !prevState.MBBSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.MBBSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }


handleGolSortIconSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                GolSortIconRotate: !prevState.GolSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.GolSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName: columnName,
                sortOrder: sortOrder
            }, async () => {
                await this.fetchIdea()
            });
        })
    }

    renderUnits = () => {
        let options = [];
        let accountName = "";
        this.state.uniqueSegments.map((item, index) => {
            console.log()
            accountName = item.SEGMENT ? item.SEGMENT.toString().toLowerCase() : "";
            if (!(accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact") ||
                accountName === "" || accountName === null || accountName === undefined)) {
                options.push({
                    value: index,
                    label: item.SEGMENT
                })
            }
            return true;
        });
        console.log("segment options =>", options)
        return options;
    }

    render() {
        return (
            <>
                <div>
                    <h4 className="u-text" style={{ fontFamily:'Georgia, Arial sans-serif', color: '#ffffff', fontSize: '24px', position: 'absolute', left: '42%', top: '105px' }}>{"Review opportunities"}</h4>
                    {/* <div className="row" style={{ fontFamily:'Arial, Georgia, sans-serif', color: '#ffffff', fontSize: '14px', position: 'absolute', left: '36%', top: '130px', lineHeight:1.3}}>
                        <div className="col-md-2"><span style={{color:'#ff545e', fontSize: '48px'}}>{this.totalIdeaCount()}</span> <br/>Total Opportunities</div>
                        <div className="col-md-2"><span style={{color:'#ff545e', fontSize: '48px'}}>{this.totalPendingIdeaCount()}</span> Pending</div>
                        <div className="col-md-2"><span style={{color:'#ff545e', fontSize: '48px'}}>{this.totalApprovedIdeaCount()}</span> Approved</div>
                        <div className="col-md-2"><span style={{color:'#ff545e', fontSize: '48px'}}>{this.totalRejectedIdeaCount()}</span> Rejected</div>
                        <div className="col-md-2"><span style={{color:'#ff545e', fontSize: '48px'}}>{this.totalSendBackIdeaCount()}</span> <br/>Send back</div>
                    </div> */}
                    <img src={require("../assets/images/listbanner.png")} alt="" style={{ width: '100%', height:'8rem' }} />
                     
                </div>
                <section className="u-clearfix u-section-1" id="sec-0f7f">
                    <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20, width: '100%' }}>
                        <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                            <div className="u-container-layout u-container-layout-1 view-idea-table">
                                <div id="idea1" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round">
                                    <div className="u-container-layout u-container-layout-7">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="" style={{display: "flex",justifyContent: 'flex-start' }}>
                                                    <div className="" style={{marginTop:19, marginRight:"25px"}}>
                                                        <i style={{ marginTop: 5, position: 'absolute', fontSize: 18 }} className={`${FILTER} filterblock_title`} onClick={this.filterHandler} title="Search" aria-hidden="true"></i>
                                                    </div>
                                                    <div className="" style={{ marginTop:15}}>
                                                        <a style={{ fontSize: 14, color:'#122539', borderBottom:'4px solid #259bca', textDecoration:'none'}} href="/gen-ai-mbb-approval-queue">{this.state.pageTitle}</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5"></div>
                                            <div className="col-md-2" >
                                                <div className="filter_search_block">
                                                    <input value={this.state.globalsearchText} onChange={(event) => this.handleGlobalSearchFilterChange(event.target.value)} placeholder="Search here"  onKeyPress={event => {
                                                        if (event.key === 'Enter') {
                                                            this.globalSearch()
                                                        }
                                                        } } 
                                                    />
                                                    <i style={{ marginTop: 9, position: 'absolute', color: '#001A30', marginLeft:-35, cursor:'pointer' }} className={this.state.isTypedInGlobalSearch ? `${ SEARCH_ICON } icon-style` : `${ SEARCH_ICON } fa-disabled`} onClick={this.globalSearch} title="search"></i>
                                                    <i style={{ marginTop: 9, position: 'absolute', color: '#001A30', marginLeft:-15, cursor:'pointer' }} className={this.state.isTypedInGlobalSearch ? `${ CLOSE } icon-style` : `${ CLOSE } fa-disabled`} onClick={this.refreshSearch} title="close"></i>
                                                </div>
                                            </div>
                                            <div className="col-md-2" style={{marginLeft:-15}}>
                                                <select style={{width:60, height:35,border: '1px solid lightgrey'}} onChange={this.topCountChangeHandler} >
                                                    {
                                                        this.renderTopCountOptions()
                                                    }
                                                </select>
                                                <span style={{ verticalAlign: 'center', paddingLeft:4 }}> Rows per page</span>
                                            </div>
                                        </div>

                                        <div className="filter_main_block">
                                            {this.state.filter
                                                ?
                                                <>
                                                    <div className="filter_main_block">
                                                        <div className="filter_block">
                                                            <h3>Filters:<span style={{ float: 'right', cursor: 'pointer' }}><i className={CLOSE} onClick={() => this.setState({ filter: false })}></i></span></h3>
                                                            <div>
                                                                <Select isSearchable={true} options={this.renderDateOptions()} placeholder="Choose date" onChange={this.handleDateOptionChange} value={this.state.dateSelectedOption} />
                                                            </div>
                                                            <div className="filter_input">
                                                                <input type="date" max="2099-01-01" min="1970-01-01" value={this.state.startDate} onChange={(event) => this.handleCreatedDate(event.target.value)} placeholder="Select from date" required />
                                                            </div>
                                                            <div className="filter_input">
                                                                <input type="date" max="2099-01-01" min="1970-01-01" value={this.state.toDate} onChange={(event) => this.handleToDate(event.target.value)} placeholder="Select to date" required />
                                                            </div>
                                                            <div className="filter_input">
                                                                <input type="text" value={this.state.OppId} onChange={(event) => this.handleIdeaIDFilterChange(event.target.value)} placeholder="Search Opportunity by ID" onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.searchData()
                                                                    }
                                                                }}
                                                                />
                                                            </div>
                                                            <div className="filter_input">
                                                                <input type="text" value={this.state.CreatedName} onChange={(event) => this.handleOwnerSearchByName(event.target.value)} placeholder="Search opp submitter by name" onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.searchData()
                                                                    }
                                                                }}
                                                                />
                                                            </div>
                                                            <div className="filter_input">
                                                                <input type="text" value={this.state.CreatedBy} onChange={(event) => this.handleOwnerSearchByOHR(event.target.value)} placeholder="Search opp submitter by ohr" onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.searchData()
                                                                    }
                                                                }}
                                                                />
                                                            </div>

                                                            <div style={{ marginTop: 7 }}>
                                                                <Select tabIndex={1} isSearchable={true} options={this.renderAccountOptions()} placeholder="Choose Account" onChange={this.handleAccountNameChange} value={this.state.accountSelectionOption} />
                                                            </div>
                                                            <div style={{ marginTop: 7 }}>
                                                                <Select tabIndex={2} isSearchable={true} value={this.state.verticalSelectedOption} options={this.renderVerticalOptions()} placeholder="Choose Vertical" onChange={this.handleVerticalFilterChange} />
                                                            </div>
                                                            <div style={{ marginTop: 7 }}>
                                                                <Select isSearchable={true} options={this.renderUnits()} placeholder="Choose Owning Unit" onChange={(e) => this.handleUnitOptionChange(e)} value={this.state.UnitSelectedOption} />
                                                            </div>
                                                            <div className="" style={{ marginTop: "7px" }}>
                                                                <Select isSearchable={true} options={this.renderUniqueSegment()} placeholder="Choose Segment" onChange={(e) => this.handleSegmentOptionChange(e)} value={this.state.SegmentSelectedOption} />
                                                            </div>
                                                            <div style={{ marginTop: 7 }} >
                                                                <Select tabIndex={3} isSearchable={true} value={this.state.OppStageSelectedOption} options={OPPMASTERSTAGES} placeholder="Choose Stage" onChange={this.handleOppStageFilterChange} />
                                                            </div>
                                                            <div style={{ marginTop: 7 }}>
                                                                <Select isSearchable={true} options={OPPMASTERSTATE} placeholder="Choose State" onChange={this.handleOppStateChange} value={this.state.OppStateSelectedOption} />
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    {
                                                                        this.state.isTypedData ? (
                                                                            <button onClick={() => this.searchData() && this.setState({ filter: false })} className="btn-block u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6">Search</button>
                                                                        ) : (
                                                                            <button onClick={(e) => window.alert("Please enter value to search.")} className="btn-block u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6">Search</button>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <button onClick={() => this.resetData()} className="btn-block u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3">Reset</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                null
                                        }
                                        </div>


                                        <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 10 }} />

                                        <div className="scrollbar_table_container gen_queue_table">
                                            <table className="table review-idea-table" style={{ fontSize: 12 }}>
                                                <thead style={{textAlign:'center !important'}} className = "view-idea-thead">
                                                    <tr>
                                                        <th scope="col" style={{ paddingLeft: 25, paddingRight:25 }}>Action</th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleIdeaIdSortClick("RecID")}>
                                                            Opportunity ID
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.ideaIdSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleSubmitterSortClick("CreatedBy")}>
                                                            Opportunity creator
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.submitterSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleCreatedDateSortClick("CreatedDate")}>
                                                            Opportunity created on
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.createdDateSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleModifiedDateSortClick("ModifiedDate")}>
                                                            Opportunity modified On
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.modifiedDateSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th scope="col" onClick={(e) => this.handleIdeaNameSortClick("OppName")}>
                                                            Opportunity name
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.ideaNameSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="col-md-1 hidden-xs hidden-sm" scope="col" onClick={() => this.handleAccountSortClick("Account")}>
                                                            Account
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.accountSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleVerticialSortClick("Vertical")}>
                                                            Vertical
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.verticalSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th scope="col" onClick={() => this.handleUnitSortClick("Unit")}>
                                                            Managing entity
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.unitSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleOppStageSortClick("OppStage")}>
                                                            Opportunity Stage
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.OppStageSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleoppStateSortIconSortClick("OppState")}>
                                                            Opportunity state
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.OppStateSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th scope="col" onClick={() => this.handleUnitSPOCSortClick("UnitOHRName")}>
                                                            Unit SPOC 
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.unitSPOCSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th scope="col" onClick={() => this.handleSPOC1SortClick("Spoc_1")}>
                                                            SPOC - 1 
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.SPOC1SortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th scope="col" onClick={() => this.handleSPOC2SortClick("Spoc_2")}>
                                                            SPOC - 2 
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.SPOC2SortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th scope="col" onClick={() => this.handleSolutionOwnerSortClick("SolnOwner")}>
                                                            Solution Owner
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.solutionOwnerSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleMBBSortIconSortClick("MBB")}>
                                                            MBB
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.MBBSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleBBSortIconSortClick("BB")}>
                                                            BB
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.BBSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleGolSortIconSortClick("GOL")}>
                                                            GOL
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.GolSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className = "review-idea-tbody">
                                                    {
                                                        this.state.ideaList && this.state.ideaList.length > 0 ? (
                                                            this.state.ideaList.map((oppItem, index) => {
                                                                let createdDate = new Date(oppItem.CreatedDate);
                                                                let modifiedDate = oppItem.ModifiedDate ?  new Date(oppItem.ModifiedDate) : "";
                                                                let formattedCreateDate = this.formatDateString(createdDate);
                                                                let formattedModifiedDate = this.formatDateString(modifiedDate);
                                                                let action = VIEW;
                                                               
                                                                return (
                                                                    <tr key={oppItem.IdeaId}>
                                                                        {/* <th scope="row">{index + 1}</th> */}
                                                                        <td>
                                                                            {/* <div className="row">
                                                                                <button
                                                                                    onClick={(e) => this.handleIdeaAction(oppItem.RecID,"View")}
                                                                                    className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-hover-custom-color-6 u-radius-6 u-btn-2 new-btn-style"
                                                                                    style={{ minWidth: 60, borderRadius: 0, marginLeft: 19, height: 20 }}
                                                                                >
                                                                                    View
                                                                                </button>
                                                                            </div> */}
                                                                            <div className="row">
                                                                                <button
                                                                                    onClick={(e) => this.handleIdeaAction(oppItem.RecID,"Update")}
                                                                                    className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-hover-custom-color-6 u-radius-6 u-btn-2 new-btn-style"
                                                                                    style={{ minWidth: 60, borderRadius: 0, marginLeft: 19, height: 20}}
                                                                                >
                                                                                    Review
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    
                                                                        <td className="hidden-xs hidden-sm" style={{width:'6%'}}>{oppItem.RecIDAlpha? oppItem.RecIDAlpha:"-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word'}}>{oppItem.CreatedName?  oppItem.CreatedName:"-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word', maxWidth:'80px'}}>{formattedCreateDate}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word', maxWidth:'80px'}}>{formattedModifiedDate ? formattedModifiedDate : ""}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word'}}>{oppItem.OppName?  oppItem.OppName:"-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word'}}>{oppItem.Account?  oppItem.Account:"-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word'}}>{oppItem.Vertical?  oppItem.Vertical:"-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word'}}>{oppItem.MangingDept?  oppItem.MangingDept:"-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word'}}>{oppItem.OppStage?  oppItem.OppStage:"-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word'}}>{oppItem.OppState?  oppItem.OppState:"-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word'}}>{oppItem.UnitOHRName?  oppItem.UnitOHRName:"-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word'}}>{oppItem.Spoc_1?  oppItem.Spoc_1:"-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word'}}>{oppItem.Spoc_2?  oppItem.Spoc_2:"-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word'}}>{oppItem.SolnOwner?  oppItem.SolnOwner:"-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word'}}>{oppItem.MBB?  oppItem.MBB:"-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word'}}>{oppItem.BB?  oppItem.BB:"-"}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{textAlign: 'left', wordWrap: 'break-word'}}>{oppItem.GOL?  oppItem.GOL:"-"}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        ) : (
                                                            <tr>
                                                                    <td colSpan="100%"> No items found! </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div style={{float:'right'}}>
                                            <div className="col-md-1">
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize:18}} className={this.state.isPrevEnabled ? `${ ANGLE_DOUBLE_LEFT } icon-style` : `${ ANGLE_DOUBLE_LEFT } fa-disabled`} onClick={() => this.state.isPrevEnabled ? this.handleGetFirstPage():''} title="first" aria-hidden="true"></i>
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize:18, marginLeft:30}} className={this.state.isPrevEnabled ? `${ ANGLE_LEFT } icon-style` : `${ ANGLE_LEFT } fa-disabled`} onClick={() => this.state.isPrevEnabled ? this.handlePrevAction():''} title="previous" aria-hidden="true"></i>
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize:18, marginLeft:60}} className={this.state.isNextEnabled ? `${ ANGLE_RIGHT } icon-style` : `${ ANGLE_RIGHT } fa-disabled`}  onClick={() => this.state.isNextEnabled ? this.handleNextAction(): ''} title="next" aria-hidden="true"></i>
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize:18, marginLeft:90, marginRight:10}} className={this.state.isNextEnabled ? `${ ANGLE_DOUBLE_RIGHT } icon-style` : `${ ANGLE_DOUBLE_RIGHT } fa-disabled`} onClick={() => this.state.isNextEnabled ? this.handleGetLastPage() : ''} title="last" aria-hidden="true"></i>
                                            </div>
                                            <div className="col-md-7" style ={{marginLeft:120, marginTop:5}}>Page {this.state.currentPage} of {this.state.totalPages}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default GenAIMbbApprovalQueue



