import React, { Component } from 'react'
import { GET_IDEA_URL, DELEGATE_IDEA_URL, DISPLAYPROJECTTHEME,AIFIRST,AI_DOC_LINK, GET_METADATA, OTHER_REGIONS, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, USER_DATA, ACCESS_TOKEN } from '../assets/constants/constants';
import Toast from 'light-toast';
import Select from 'react-select';
import { selectValidation } from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";

export class DelegatePage extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);

    constructor(props) {
        super(props)
        let propsData = this.props.location;
        this.state = {
            ideaItem: {},
            blackBeltUserId: 0,
            blackBeltUserName: "",
            serviceLineName: "",
            campaignName: "",
            blackbeltList: [],
            regionText: "",
            blackbeltSelectedOption: null,
            selectStyle: {},
            dataBridgeCampaign: false,
            xPollinationCampaign: false,
            propsData: propsData,
            displayProjectTheme: false,
            clusterData: [],
            isClusterSaveDisabled: true,
            displayParentAlpha: '',
            projectTypeSelectedOption: null,
            projectIDSelectedOption: null,
            ideasDB: [],
            ideasDBDoc: [],
            campaignList: [],
            campaignInsight:"",

            ideasAI:[],
            // QA role
            isQAUser:false
            
        }
    }

    async componentDidMount() {
        Toast.loading("Fetching Idea...", () => { });
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json",
                "Authorization":  "Bearer " + this.accessToken,
            },
        };
        fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&RecID=''&isItIdeaOrGenAI='Idea'`, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("fetch idea", data)
                this.setState({
                    ideaItem: data.ResultSets.Table1[0],
                    blackBeltUserName: '',//data.ResultSets.Table1[0].BlackBeltUserName,
                    regionText: data.ResultSets.Table1[0].RegionManual,
                    dataBridgeCampaign: (data.ResultSets.Table1[0].CampaignName === DATA_BRIDGE_CAMPAIGN),
                    xPollinationCampaign: (data.ResultSets.Table1[0].CampaignName === X_POLLINATION_CAMPAIGN),
                    clusterData: data.ClusterData.Table1 ? data.ClusterData.Table1 : [],
                    // DB
                    ideasDB: data.ResultSets.Table3 && data.ResultSets.Table3[0].Result === "NULL" ? [] : data.ResultSets.Table3[0],
                    ideasDBDoc: data.ResultSets.Table4 && data.ResultSets.Table4[0].Result === "NULL" ? [] : data.ResultSets.Table4[0],
                    ideasAI: data.ResultSets.Table2 ? data.ResultSets.Table2[0] : [],
                }, () => {
                    this.setCampaignName();
                    this.fetchBlackBeltOptions();
                    if (this.state.ideaItem.CreatedDate) {
                        let created = new Date(this.state.ideaItem.CreatedDate);
                        let abc = new Date(DISPLAYPROJECTTHEME);
                        if (created.getTime() < abc.getTime()) {
                            //console.log("created date is lesser")
                            this.setState({ displayProjectTheme: true })
                        } else {
                            this.setState({ displayProjectTheme: false })
                            //console.log("created date is greater ")
                        }
                    }

                    if (this.state.clusterData && this.state.clusterData.length > 0) {
                        const targetRecID = this.state.ideaItem.LeanID;
                        const filteredData = this.state.clusterData.filter(item => item.RecAlpha === targetRecID);
                        //console.log(filteredData)
                        if (filteredData && filteredData.length > 0 && filteredData !== undefined && filteredData !== null) {
                            this.setState({ displayParentAlpha: filteredData[0].ParentAlpha }, () => { })
                            let options = [];
                            options.push({ value: 0, label: filteredData[0].ParentAlpha })
                            this.setState({ projectIDSelectedOption: options }, () => { })
                        } else {
                            this.setState({ displayParentAlpha: "" }, () => { })
                        }
                    } else {
                        this.setState({ displayParentAlpha: "" }, () => { })
                    }

                    Toast.hide();
                });
        });
        await this.fetchMetadata();
        Toast.hide();
    }


    fetchMetadata = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "",
                EntityName: ""
            }
            const requestOptions = {
                method: 'POST',
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        campaignList: data ? data.Campaigns : [],
                    }, () => {
                        if(this.state.ideaItem.CampaignName && this.state.campaignList){
                            this.state.campaignList.map((campaign)=>{
                              if(campaign.CampaignName === this.state.ideaItem.CampaignName){
                                this.setState({campaignInsight:campaign.CampaignDescr},()=>{})
                              }
                            })
                           
                          }
                        resolve();
                    })
                })
                .catch(error => {
                    Toast.fail('Error occured while getting metadata idea. Please try again!', 3000, () => {
                        console.log(error);
                        console.log(JSON.stringify(error));
                        this.setState({
                            businessImpactMasterList: [],
                            serviceLineList: [],
                            subServiceLineList: [],
                        }, () => {
                            resolve();
                        })
                    });

                });
        });
    }

    fetchBlackBeltOptions = () => {
        Toast.loading("Fetching BlackBelt users...", () => { });
        // const param = {
        //     EntityType: "BB",
        //     EntityName: this.state.regionText
        // }
        let entityType = "BB";
        let entityName = this.state.ideaItem.RegionManual;
        if (this.state.ideaItem.RegionManual === OTHER_REGIONS) {
            entityType = "BBOTHER";
            entityName = this.state.ideaItem.VerticalManual.replace(" - ", "-");
        }
        const param = {
            EntityType: entityType,
            EntityName: entityName
        }
        const requestOptions = {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json",
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("fetch BB data =>", data)
                Toast.hide();
                this.setState({
                    blackbeltList: data.DataverseUserRoles !== undefined ? data.DataverseUserRoles : [],
                }, () => {})
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting metadata idea. Please try again!', 3000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }

    renderBlackBeltOptions = () => {
        // let options = [];
        // const uniqueBBList = [...new Map(this.state.blackbeltList.map(item => [item.pg_ohr, item])).values()];        
        // uniqueBBList.map((item) => {
        //     const userRole = JSON.parse(item.pg_Role).pg_role;
        //     if (userRole === "BB") {
        //         if (!(parseInt(this.state.ideaItem.OwnerOHR) === parseInt(item.pg_ohr) || parseInt(this.state.ideaItem.CreatorOHR) === parseInt(item.pg_ohr) || parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr) ) ) {
        //             options.push({ value: item.pg_ohr, label: item.pg_username });
        //         }
        //     }
        //     return true;
        // });
        // return options;
        let options = [];
        const uniqueOHRValues = new Set();
        this.state.blackbeltList.map((item) => {
            const userRole = JSON.parse(item.pg_Role).pg_role;
            if (userRole === "BB" || userRole === "QA") {
                if (!
                    (
                        parseInt(this.state.ideaItem.OwnerOHR) === parseInt(item.pg_ohr) ||
                        parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr) ||
                        parseInt(this.state.ideaItem.CreatorOHR) === parseInt(item.pg_ohr)
                    )
                ) {
                    if (!uniqueOHRValues.has(item.pg_ohr)) {
                        options.push({ value: item.pg_ohr, label: item.pg_username });
                        uniqueOHRValues.add(item.pg_ohr);
                    }
                }
            } 
            return true;
        });
        return options;
    }

    setCampaignName = () => {
        var opts = [DATA_BRIDGE_CAMPAIGN, "Rapid Automation", "Hackathon–X", DATA_BRIDGE_CAMPAIGN];
        for (var i = 0; i < opts.length; i++) {
            if (i === this.state.ideaItem.CampaignTypeId - 1) {
                this.setState({
                    ideaItem: {
                        CampaignTypeId: opts[i]
                    }
                });
            }
        }
    }

    

    handleBlackBeltNameChange = (e) => {
        if(e){
            return new Promise((resolve,reject)=>{
                Toast.loading("Please wait...", () => { });
                const param = {
                    EntityType: "OHR",
                    EntityName: e.value
                }
                const requestOptions = {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      "Authorization":  "Bearer " + this.accessToken,
                    },
                    body: JSON.stringify(param),
                };
                fetch(GET_METADATA, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        Toast.hide();
                        let flag = false;
                        data.PowerAppUsers.filter((item) => {
                            const userRole = JSON.parse(item.pg_Role).pg_role.trim();
                            if (userRole === "QA") {
                                flag = true;
                                this.setState({ isQAUser: true }, () => {})
                            }
                        })
                        if(flag || flag == true || flag === true){
                            Toast.info("You are about to delegate this Idea approval to a QA role who can approve only Lean projects. Are you sure you want to delegate this Idea ?",10000,()=>{
                                if(window.confirm("You are about to delegate this Idea approval to a QA role who can approve only Lean projects. Are you sure you want to delegate this Idea ?")){
                                    this.setState({
                                        blackBeltUserId: e.value,
                                        blackBeltUserName: e.label,
                                        blackbeltSelectedOption: { value: e.value, label: e.label }
                                    },()=>{});
                                }
                                else{
                                    this.setState({
                                        blackBeltUserId: "",
                                        blackBeltUserName: "",
                                        blackbeltSelectedOption: {}
                                    },()=>{});
                                }
                            })
                        }else{
                            this.setState({
                                blackBeltUserId: e.value,
                                blackBeltUserName: e.label,
                                blackbeltSelectedOption: { value: e.value, label: e.label }
                            },()=>{});
                        }
                        resolve();
                    })
                    .catch((error) => {
                        Toast.hide();
                        console.log(JSON.stringify(error));
                    });
            })
        }
    }

    validate = () => {
        let isValid = true;
        let styleStateObj = this.state.selectStyle;

        if (this.state.blackBeltUserName === "") {
            isValid = false;
            styleStateObj.blackBeltUserName = selectValidation(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.blackBeltUserName = selectValidation(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        return isValid;
    }

    submitIdea = () => {
        if (this.validate()) {
            if (window.confirm("Are you sure you want to delegate the idea?")) {
                Toast.loading("Delegating Idea...", () => { });
                //let testOHR = (parseInt(this.sessionData.profile.oHRId) === 850051497) ? 850051496 : 850051497;
                let param = {
                    BlackBeltUserId: this.state.blackBeltUserId,
                    BlackBeltUserName: this.state.blackBeltUserName,
                    BlackBeltUserEmail: this.state.blackBeltUserId + "@genpact.com",
                    UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                    UpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                    UpdaterOHR: this.sessionData.profile.oHRId,
                    IdeaId: this.props.location.state,
                    Role: "Mentor",
                    UpdaterRole: "Mentor",
                }
                console.log(param)
                let finalJsonData = Checksum(param);
                // let objJsonStr = JSON.stringify(param);
                // let objJsonB64 = Buffer.from(objJsonStr).toString("base64");
                // console.log("encoded objJsonB64 :",objJsonB64);

                // let str1 = objJsonB64.slice(0, 23);
                // let str2 = objJsonB64.slice(23, 51);
                // let str3 = objJsonB64.slice(51, 58);
                // let str4 = objJsonB64.slice(58);
                // let finalJsonData =str3+str1+str4+str2;
                console.log("final Json Data", finalJsonData);

                const requestOptions = {
                    method: 'PUT',
                    headers: { 
                        "Content-Type": "application/json",
                        "Authorization":  "Bearer " + this.accessToken,
                    },
                    body: JSON.stringify(param)
                };
                fetch(`${DELEGATE_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        Toast.hide();
                        Toast.success('Idea Delegated successfully!', 3000, () => {
                            // console.log(JSON.stringify(data));
                            window.location = "/review-page?page=approval";
                        });
                    })
                    .catch(error => {
                        Toast.hide();
                        Toast.fail('Idea delegation failed', 3000);
                        console.log('Idea delegation failed:' + error);
                    });
            }
        }
        return false;
    }

    cancelEvent = () => {
        let redirectURL = "/review-page";
        // this.props.history.push("/review-page?page=approval");
        this.props.history.push({
            pathname: redirectURL,
            search: '?page=approval',
            propsList: this.state.propsData
        })
    }

    render() {
        return (
            <>
                <section className="u-clearfix u-section-1" id="sec-0f7f">
                    <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                        <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                            <div className="u-container-layout u-container-layout-1">
                                <h4 style={{ textAlign: 'center', color: '#00AECF', fontFamily: 'Arial', fontSize: 22 }}>Delegate  idea : {this.state.ideaItem.IdeaIDAlpha ? this.state.ideaItem.IdeaIDAlpha : this.state.ideaItem.IdeaId} </h4>
                                <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                                    <div className="u-container-layout u-container-layout-2" style={{ textAlign: 'center' }}>
                                        <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-3">
                                            <div className="u-container-layout u-container-layout-3">
                                                <p className="u-align-left u-text u-text-2" style={{ marginBottom: 18 }}>Owner details</p>
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner OHR</label> </div>
                                                    <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerOHR} /></div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner Name</label> </div>
                                                    <div className="col-md-6"><input className="form-input" type="text" value={this.state.ideaItem.OwnerName} disabled /></div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <p className="u-align-left u-text u-text-7 u-text-custom-color-3" style={{ fontSize: 11, marginBottom: 10 }}>(If Idea was raised for someone else, their OHR ID is below)</p>
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Request For OHR</label> </div>
                                                    <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.CreatorOHR} /></div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Name</label> </div>
                                                    <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.CreatorName} /></div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Project ID</label> </div>
                                                    <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.LeanID} /></div>
                                                </div>
                                                <div style={{ marginBottom: 10 }} />

                                            </div>
                                        </div>

                                        <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 260 }}>&nbsp;</p>

                                        <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-4" style={{ marginTop: -330 }}>
                                            <div className="" style={{ display: "flex", marginTop: "30px" }}>
                                                <div className="u-container-style u-group u-shape-rectangle u-group-5">
                                                    <div className="u-container-layout u-container-layout-5">
                                                        <p className="u-align-left u-text u-text-8" style={{ marginBottom: 20 }}>Account Details</p>
                                                        <div className="row">
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Account&nbsp;Name</label></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled type="text" value={this.state.ideaItem.AccountManual} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Holding&nbsp;Account</label></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled type="text" value={this.state.ideaItem.HoldingAccount} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Vertical</label></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled type="text" value={this.state.ideaItem.VerticalManual} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Industry&nbsp;Vertical</label></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled type="text" value={this.state.ideaItem.SubVerticalName} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />

                                                        {/* units and segments starts here */}
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Segment</label>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled name="segment" value={this.state.ideaItem.Segment ? this.state.ideaItem.Segment : ""} style={{ fontWeight: 'normal !important' }} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Unit</label>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled name="unit" value={this.state.ideaItem.Unit ? this.state.ideaItem.Unit : ""} style={{ fontWeight: 'normal !important' }} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        {/* units and segments ends here */}


                                                        <div className="row">
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Campaign</label></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled type="text" value={this.state.ideaItem.CampaignName} />
                                                            </div>
                                                            <div className="">
                                                                <div className="campaign_modal_bulb campaign_tool_tip">
                                                                    <img 
                                                                        src={require("../assets/images/question_mark_2.png")}
                                                                        alt=""
                                                                        className=""
                                                                    />
                                                                    {
                                                                        this.state.campaignInsight
                                                                        ?
                                                                        <>
                                                                            <span className="my_tooltiptext">{this.state.campaignInsight}</span>
                                                                        </>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />

                                                        {
                                                            this.state.ideaItem.CampaignName === "Gen AI"
                                                                ?
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-5">
                                                                            <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Gen AI Theme</label></div>
                                                                        <div className="col-md-7">
                                                                            <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Gen AI Theme" value={this.state.ideaItem.SubCampaignName} />
                                                                        </div>
                                                                    </div>

                                                                </>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            this.state.displayProjectTheme && this.state.dataBridgeCampaign && (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Data Bridge Project Theme</label></div>
                                                                        <div className="col-md-7">
                                                                            <input className="form-input" disabled list="databridgetype" name="databridgetype" placeholder="Choose Data Bridge Project Type" value={this.state.ideaItem.DataBridgeTheme} />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: 26 }} />
                                                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            this.state.xPollinationCampaign && (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Campaign</label></div>
                                                                        <div className="col-md-7">
                                                                            <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Sub Campaign" value={this.state.ideaItem.SubCampaignName} />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: 26 }} />
                                                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            this.state.displayParentAlpha && this.state.displayParentAlpha.length > 0 && this.state.displayParentAlpha !== null && this.state.displayParentAlpha !== undefined && this.state.displayParentAlpha !== ""
                                                                ?
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-5">
                                                                            <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Parent ID</label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <input className="form-input" disabled placeholder="Choose Parent ID" value={this.state.displayParentAlpha} />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                        }


                                                        {
                                                            this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST
                                                                ?
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                <i>AI First Sample Ideas</i>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <div className="ai_first_doc_link ai_first_doc_red_highlight">
                                                                                <a
                                                                                    className="ldt-list1"
                                                                                    href={AI_DOC_LINK}
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                    style={{ textAlign: "left", fontSize: 16, color: "red" }}
                                                                                >Document Link </a>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No")
                                                                            ?
                                                                            <>
                                                                                <div style={{ marginBottom: 20 }} />
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-5">
                                                                                        <span className="form-attribute">
                                                                                            AI Recommendation
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-7">
                                                                                        <input
                                                                                            className="form-input"
                                                                                            disabled
                                                                                            name="methodology"
                                                                                            value={this.state.ideaItem.isAI}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null

                                                                    }


                                                                    {
                                                                        this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No")
                                                                            ?
                                                                            <>
                                                                                <div style={{ marginBottom: 20 }} />
                                                                                <div className="row">
                                                                                    <div className="col-md-5">
                                                                                        <span className="form-attribute">
                                                                                            AI First BRD
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-md-7">
                                                                                        <div className="ai_first_doc_link">
                                                                                            <textarea className="ai_first_brd_textarea" disabled value={this.state.ideasAI.LinkBRD} ></textarea>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }

                                                                </>
                                                                :
                                                                null
                                                        }

                                                    </div>
                                                </div>



                                                <div className="u-container-layout u-container-layout-6">
                                                    <div className="row" style={{ marginTop: "10px" }}>
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Country</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled type="text" value={this.state.ideaItem.Country} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Region</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled type="text" value={this.state.ideaItem.RegionManual} />
                                                        </div>
                                                    </div>

                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub-Region</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled type="text" value={this.state.ideaItem.SubRegion} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}><span style={{ color: 'red' }}>*</span> Blackbelt&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            <Select isSearchable={true} options={this.renderBlackBeltOptions(this.state.blackBeltUserName)} styles={this.state.selectStyle.blackBeltUserName} value={this.state.blackbeltSelectedOption} placeholder="Choose Blackbelt" onChange={this.handleBlackBeltNameChange} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    {
                                                        this.state.ideaItem.MBBOHR
                                                        ?
                                                        <>  
                                                            <div className="row">
                                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>MBB</label></div>
                                                                <div className="col-md-7">
                                                                    <input className="form-input" disabled name="blackbelt" placeholder="Choose MBB" title={"OHR ID: " + this.state.ideaItem.MBBOHR} value={this.state.ideaItem.MBBName} />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    }


                                                    {
                                                        this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST &&
                                                            this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No")
                                                            ?
                                                            <>
                                                                <div style={{ marginBottom: 20 }} />
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <span className="form-attribute">
                                                                            AI First Reason
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <textarea
                                                                            disabled
                                                                            value={this.state.ideasAI.AIReason}
                                                                            placeholder="Please provide value"
                                                                            className="form-input"
                                                                            rows={4}
                                                                            cols={80}
                                                                            style={{
                                                                                width: "100%",
                                                                                border: "1px solid lightgrey",
                                                                                resize: "none"
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 20 }} />
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    

                                                    <div style={{ marginBottom: 15 }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{borderTop:"1px solid lightgrey", marginTop:"20px"}} />


                                        <div className="btn-group" style={{ marginTop: 15 }}>
                                            <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.submitIdea}>Save</button>
                                            <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.cancelEvent}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default DelegatePage
