import React, { Component } from 'react'
import { GET_IDEA_URL, APPROVED,UPDATE_IDEA_URL, PENDING, SENT_BACK, REJECTED, GET_METADATA, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, ACCESS_TOKEN, PROJECT_VIEW, COMPLETED_STATUS, AWAITING_MBB_APPROVAL, AWAITING_LDT_APPROVAL, PPT_CONTENT, SIGNOFF_CONTENT, USER_DATA, REQUEST_CANCELLATION, OTHER_REGIONS, SUBMIT_IDEA_URL, BI_FORM_DATE,AWAITING_QAULITY_CHECK_REVIEW } from '../assets/constants/constants';
import Toast from 'light-toast';
import Checksum from "../utils/Checksum";
import Select from 'react-select';
import { selectValidation as selectStyle, textBoxValidation } from "../utils/FieldValidation";


export class AdminEditPage extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);
    constructor(props) {
        super(props)

        const isApprovalPage = `${this.props.location.approval}`;
        let isViewAllIdea = this.props.location.isViewAllIdea;
        let isViewAllProject = this.props.location.isViewAllProject;
        let propsData = this.props.location;
        // console.log(propsData)
        this.state = {
            ideaItem: {},
            WorkflowStatus: "",
            Comment: "",
            accountName: "",
            campaignName: "",
            serviceLineName: "",
            dataBridgeCampaign: false,
            xPollinationCampaign: false,
            ideaValidation: false,
            showAdditionalDetails: false,
            showFinalAdditionalDetails: false,
            approvalPage: isApprovalPage,
            isViewAllIdea: isViewAllIdea,
            propsData: propsData,
            isViewAllProject: isViewAllProject,
            reviewComments: [],
            businessImpactMasterList: [],
            businessImpactValueArray: [],
            summaryArray: [],
            fteTotal: 0,
            dollarTotal: 0.0,
            commentLog: false,
            cancelRequest: false,
            selectStyle: {},
            adminUserList: [],
            admin: false,
            adminOHR: '',
            accountList: [],
            regionList: [],
            subRegionList: [],
            countryList: [],
            verticalList: [],
            subVerticalList: [],
            blackbeltList: [],
            mbbList: [],
            ldaList: [],
            campaignList: [],
            dataBridgeProjectThemeList: [],
            digitalProjectTypeList:[],
            digitalProjectTypeSelectedOptions: null,
            certificationLevelsList:[],
            certLevelsSelectedOption: null,
            certificationWorkTypeList: [],
            certWorkTypeSelectedOption: null,
            transformationLeversList: [],
            transSelectedOption: null,
            subCampaignList: [],
            serviceLineList: [],
            subServiceLineList: [],
            serviceLineList: [],
            subServiceLineList: [],
            accountId: 111,
            accountName: "",
            blackBeltUserId: 111,
            blackBeltUserName: "",
            blackBeltUserEmail: "",
            mbbName: "",
            mbbOHR: "",
            lda: "",
            ldaOHR: "",
            campaignTypeId: 111,
            campaignTypeText: "",
            dataBridgeProjectTheme: "",
            subCampaignName: "",
            regionId: 111,
            regionManual: "",
            regionManualId: 111,
            regionText: "",
            regionSelectedOption: null,
            subRegionSelectedOption: null,
            countrySelectedOption: null,
            verticalSelectedOption: null,
            subVerticalSelectedOption: null,
            blackBeltSelectedOption: null,
            mbbSelectedOption: null,
            ldaSelectedOption: null,
            databridgeProjectThemeOption: null,
            campaignSelectedOption: null,
            dataBridgeSelectedOption: null,
            subCampaignSelectedOption: null,
            verticalId: 111,
            verticalManual: "",
            verticalManualId: 111,
            subVerticalManual: "",
            requestForIdea: false,
            subRegion: "",
            country: "",
            serviceLineSelectedOption: null,
            subServiceLineSelectedOption: null,
            workFlowStatusList: [],
            workFlowStatusSelectedOption: null,
            approvalSelectedOption: null,
            LDTLeadSelectedOption: null,
            leadNameList: [],
            methodologyList: [],
            methodologySelectedOptions: null,
            replicaIndexOption: [
                {
                    value:"1",
                    label:"1"
                },
                {
                    value:"2",
                    label:"2"    
                },
                {
                    value:"3",
                    label:"3"    
                },
                {
                    value:"4",
                    label:"4"    
                },
                {
                    value:"5",
                    label:"5"    
                }
            ],
            replicaIndex: 0,
            otherTools: "",
            finalComments:'',
            comment:'',
            IsBestInClass: false,
            IsZeroImpactProject: false,
            IsSharedToSixSigma: false,
            digitalProjectEnabled: false,
            workTypeEnabled: false,
            base64File1: "",
            base64File2: "",
            base64File1: "",
            base64File2: "",
            base64File3: "",
            base64File4: "",
            filename1: "",
            filename2: "",
            filename3: "",
            filename4: "",
            fileType1: "",
            fileType2: "",
            fileType3: "",
            fileType4: "",
            sourceId: "",
            flagBIFTE: true,
            FinalImpactFTE: 0.0,
            FinalImpactDollar: 0.0,
            Role:"",
            finalApproveOptionsValue: [{
                WorkflowStatusId: 1,
                WorkflowStatusName: COMPLETED_STATUS
            },
            {
                WorkflowStatusId: 2,
                WorkflowStatusName: "Rejected"
            },
            {
                WorkflowStatusId: 3,
                WorkflowStatusName: "Send Back"
            },
            ],
            showDigital:false,
            rolesUpdated:false,
            // ideaArray: [{
            //     problem: "",
            //     solution: "",
            //     ideaName: "",
            //     serviceLineId: 111,
            //     serviceLineText: "",
            //     subServiceLineId: 0,
            //     subServiceLineText: "",
            //     subServiceLineSelectedOption: null,
            //     newOrReplicatedIdea: false,
            //     replicationProjectId: "",
            //     replicationIDValidated: false,
            //     removeIdea: false,
            // }],
            // selectStyle: {
            //     ideaArray: [{
            //         problem: { maxWidth: '100%', border: '1px solid lightgrey' },
            //         solution: { maxWidth: '100%', border: '1px solid lightgrey' },
            //         ideaName: { maxWidth: '100%', border: '1px solid transparent' },
            //         serviceLineId: 111,
            //         serviceLineText: "",
            //         newOrReplicatedIdea: false,
            //         replicationProjectId: { maxWidth: '100%', border: '1px solid lightgrey' },
            //         removeIdea: false
            //     }]
            // }
            filteredImpactTypeState:[],
            filteredImpactTypeSelectedOption:null,
            filteredMonetizableTypeState:[],
            filteredMonetizableSelectedOption:null,
            filteredBISubCategoryState:[],
            filteredBISubCategorySelectedOption:null,
            ideaCreatedDate:"",
            biFormDate:BI_FORM_DATE,
            showHistoricalBI:false,
            sortedBiImpactMasterList:[],
            showHrefModal:false,
            zeroImpactForLastestBI:false,
            zeroImpact:true,
            biMasterListAll:[],

            showClientFields:false,
            showGOLFields:false,
            
            golFilename:"",
            golContent:"",
            golFileType:"",
            golOhr:"",
            golEmail:"",
            golFullname:"",

            fpnaFilename:"",
            fpnaContent:"",
            fpnaFileType:"",
            fpnaName:"",
            fpnaEmail:"",
            fpnaOhr:"",
            fpnaApprovalAttachedSelectedOption:null,
            fpnaApprovalSelectedOptionObj:"",
            
            clientName:"",
            clientRole:"",
            clientSignOffDate:"",
            clientApprovalEmailSelectedOption:null,
            clientApprovalEmailSelectedOptionObj:null,
            signOffFilename:"",
            signOffFileType:"",

            IsVDType:"",
            showBBFieldsValue:"",
            showCheckBox:false,
            signOffCheckbox:"",
            checkBoxFalseSelected:false,
            appropriateAttachmentValue:[],
            appropriateAttachment:false,
            clientApprovalOptions :[
                { value: '0', label: 'Yes' },
                { value: '1', label: 'No' },
            ],
            monetizableValues:[
                {
                    value:0,
                    label:"Improvement in transaction based billing"
                },
                {
                    value:1,
                    label:"Increase in billable HC"
                },
                {
                    value:2,
                    label:"Increase in billable hours"
                },
                {
                    value:3,
                    label:"Revenue from digital deployment"
                },
                {
                    value:4,
                    label:"Revenue from special projects"
                },
                {
                    value:5,
                    label:"Value share from improvement project"
                },
                {
                    value:6,
                    label:"Fixed Fee"
                },
            ],
            postMonetizableOptions:[
                {
                    value:1,
                    label:"Discussion to be initiated with Client"
                },
                {
                    value:2,
                    label:"Discussion ongoing with Client"
                },
                {
                    value:3,
                    label:"Rejected by Client"
                },
                {
                    value:4,
                    label:"Approved by Client"
                },
               
            ],
            monetizableProjectSelectedOption:null,
            monetizableCheckbox:null,
            monetizableStatus:false,
            showClientApprovalFields:false,
            
            
            SowID:"",
            IsVDMonetizable:[], 
            monetizableStatus:"",
            FPnAOHRMonetization:"",
            FPnANameMonetization:"",
            FPnAEmailMonetization:"",
            FPnAFileNameMonetization:"",
            FPnAFileTypeMonetization:"",
            FPnAFileMonetization:"",
            IsSampleCheck:false,
            chatGPTTheme:false,
            chatGptOptions:[
                {
                    value:0,
                    label:"Predict The Next Best Action"
                },
                {
                    value:1,
                    label:"Automated Narrative Building and Translation"
                },
                {
                    value:2,
                    label:"Data Extraction and Leakage Prevention"
                },
                {
                    value:3,
                    label:"Coding Effort Reduction"
                },
            ],
            chatGptSelectedOption:[],
           
            FPnAOHRMonetizationInput:"",
            FPnANameMonetizationInput:"",
            SowIDInput:"",
            digitalSelected:[],
            impactTagChangeSelected:"",
            deliveryCategorizedSelect:"",
            deliveryComputedCorrectlySelected:"",
            signOffDeliverySelected:"",
            qcCommentLog:false,
            qcComments:[],
            impactOptions:[
                {
                    value:0,
                    label:"No Value Delivery"
                },
                {
                    value:1,
                    label:"Value Delivered to Client"
                },
                {
                    value:2,
                    label:"Value Delivered to Genpact"
                },
                {
                    value:3,
                    label:"Value Delivered to Client + Genpact"
                }
            ],
            impactOptionSelected:[],
            potentialDollar:'',
            bbReview:[],
            bbReviewCheckbox:[],
            bbEditable:false,
            biThreshold:"",
            vrconditiontype:"",
            vrMapping:"",
            vrRegionMapping:"",
            leanProjectLastComment:"",
            lastQCComment:"",
            finalReviewComments:[],
            isAccountChange:false,
            oldVDType:false,
            leanYesNoApproval:[],
            updaterActionState:""
            
        }
        this.isExceedlimit= false;
        
    }   

    async componentDidMount() {
        Toast.loading("Fetching Idea & Metadata...", () => { });
        await this.fetchIdea();
        await this.fetchMetadata();
        Toast.hide();
    }

    formatDateString(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
        
    }

    formatDateStringWithTime(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        });
    }

    fetchPPTContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let projectFileType = this.state.projectFileType;
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    const projectDeckMetadata = data.PPTContent ? data.PPTContent : "";
                    const projectContent = "data:" + projectFileType + ";base64," + projectDeckMetadata;
                    this.setState({
                        projectDeckContent: projectContent,
                    }, () => {
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching Project Deck content. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }

    fetchSignoffContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let signoffFileType = this.state.signOffFileType;
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${SIGNOFF_CONTENT}=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    const signoffMetadata = data.SignoffContent ? data.SignoffContent : "";
                    const signoffContent = "data:" + signoffFileType + ";base64," + signoffMetadata;
                    this.setState({
                        signOffContent: signoffContent,
                    }, () => {
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching Project Deck content. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }

    fetchIdea = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };

            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("fetch idea",data)
                    const projectFilename = data.ResultSets.Table1[0].ProjectPPTFileName ? data.ResultSets.Table1[0].ProjectPPTFileName : "";
                    const signOffFilename = data.ResultSets.Table1[0].CustomerSignoffFileName ? data.ResultSets.Table1[0].CustomerSignoffFileName : "";
                    const projectFileType = data.ResultSets.Table1[0].ProjectPPTType ? data.ResultSets.Table1[0].ProjectPPTType : "";
                    const signOffFileType = data.ResultSets.Table1[0].CustomerSignoffType ? data.ResultSets.Table1[0].CustomerSignoffType : "";
                    this.setState({
                        ideaItem: data.ResultSets.Table1[0],
                        WorkflowStatus: data.ResultSets.Table1[0].WorkflowStatus,
                        Comment: data.ResultSets.Table1[0].Comment,
                        showSourceId: data.ResultSets.Table1[0].TransformationLeverName === "Digital" ? true : false,
                        projectDeckFilename: projectFilename,
                        signOffFilename: signOffFilename,
                        projectFileType: projectFileType,
                        signOffFileType: signOffFileType,
                        reviewComments: data.ReviewComments.Table1,
                        finalReviewComments: data.FinalReviewComments.Table1,
                        sourceId: data.ResultSets.Table1[0].SourceId,
                        campaignName: data.ResultSets.Table1[0].CampaignName,
                        dataBridgeCampaign: (data.ResultSets.Table1[0].CampaignName === DATA_BRIDGE_CAMPAIGN),
                        xPollinationCampaign: (data.ResultSets.Table1[0].CampaignName === X_POLLINATION_CAMPAIGN),
                        businessImpactValueArray: data.BIData ? (data.BIData.Table1 ? (data.BIData.Table1.length > 0 ? data.BIData.Table1 : []) : []) : [],
                        ideaCreatedDate:data.ResultSets.Table1[0].CreatedDate,
                        FinalImpactFTE:data.ResultSets.Table1[0].FinalImpactFTE,
                        FinalImpactDollar:data.ResultSets.Table1[0].FinalImpactDollar,
                        
                        clientSignOffDate: data.ResultSets.Table1[0].CustSignOffDate ? new Date(data.ResultSets.Table1[0].CustSignOffDate).toLocaleDateString('en-CA').split('/').reverse().join('-') : null,
                        golOhr:data ? data.ResultSets.Table1[0].GOLOHR : "",
                        fpnaOhr:data ? data.ResultSets.Table1[0].FPnAOHR : null,
                        golFilename:data.ResultSets.Table1[0].GOLFileName,
                        golFileType:data.ResultSets.Table1[0].GOLFileType,
                        fpnaFileType:data.ResultSets.Table1[0].FPnAFileType,
                        fpnaName:data.ResultSets.Table1[0].FPnAFileName,
                        fpnaApprovalAttachedSelectedOption:data.ResultSets.Table1[0].IsFPnAApprovalAttached,
                        clientApprovalEmailSelectedOption:data.ResultSets.Table1[0].IsClientApprovalAttached,
                        clientName:data ? data.ResultSets.Table1[0].CustName : null,
                        clientRole:data ? data.ResultSets.Table1[0].CustDesgn : null,
                        golFullname:data ? data.ResultSets.Table1[0].GOLName:null,
                        fpnaName:data ? data.ResultSets.Table1[0].FPnAName:null,


                        replicaIndex:data ? data.ResultSets.Table1[0].ReplicabilityIndex : null,

                        IsVDType:data.ResultSets.Table1[0].VDType,
                        IsVDMonetizable:data.ResultSets.Table1[0].IsVDMonetizable,
                        showCheckBox:data.ResultSets.Table1[0].IsVDReviewed,
                        bbReviewCheckbox:data.ResultSets.Table1[0].IsVDReviewed,
                        bbReview:data.ResultSets.Table1[0].IsFPnAApprovalAttached,
                        monetizableStatus:data.ResultSets.Table1[0].MonetizationStatus,

                        digitalSelected: data.ResultSets.Table1[0].DigitalProjectType ? data.ResultSets.Table1[0].DigitalProjectType : "" ,
                        qcComments:data.QcComments.Table1 ? data.QcComments.Table1 : [],



                        impactTagChangeSelected:data.ResultSets.Table1[0].IsVDImpactTagged,
                        deliveryCategorizedSelect:data.ResultSets.Table1[0].IsVDCategorized,
                        deliveryComputedCorrectlySelected:data.ResultSets.Table1[0].IsVDComputed,
                        signOffDeliverySelected:data.ResultSets.Table1[0].IsVDClientSignOff,
                        FPnAFileNameMonetization:data.ResultSets.Table1[0].FPnAFileNameMonetization ? data.ResultSets.Table1[0].FPnAFileNameMonetization : "",

                        FPnAOHRMonetization:data.ResultSets.Table1[0].FPnAOHRMonetization,
                        FPnANameMonetization: data.ResultSets.Table1[0].FPnANameMonetization,
                        SowID: data.ResultSets.Table1[0].SowID,
                        potentialDollar:data.ResultSets.Table1[0].PotentialImpactDollar,

                        holdingAccount:data.ResultSets.Table1[0].HoldingAccount,
                        subVerticalSelectedOption:data.ResultSets.Table1[0].SubVerticalName,
                    }, () => {
                        console.log("review comments ",this.state.reviewComments)

                        if(this.state.ideaItem.ApprovalStatus==="Completed" && this.state.ideaItem.VDType === null){
                            this.setState({oldVDType:true},()=>{})
                        }
                        
                        if(this.state.ideaItem.ApprovalStatus==="Awaiting Quality Check Review")
                        {
                            this.setState({showFinalAdditionalDetails:true},()=>{})
                        }
                        if(this.state.ideaItem.ApprovalStatus==="Completed")
                        {
                            this.setState({showFinalAdditionalDetails:true},()=>{})
                        }

                        if(this.state.finalReviewComments){
                            var keys = Object.keys(this.state.finalReviewComments);
                            var last = keys[keys.length - 1];
                            let varFinalLastComment = this.state.finalReviewComments[last].Comment
                            this.setState({leanProjectLastComment:varFinalLastComment},()=>{})
                        }

                        if(this.state.qcComments !== null || this.state.qcComments.length !== 0 || this.state.qcComments !== undefined) {
                            var keys = Object.keys(this.state.qcComments);
                            var last = keys[keys.length - 1];
                            // let varFinalLastComment = this.state.qcComments[last].Comment
                            // this.setState({lastQCComment:varFinalLastComment},()=>{
                            //     console.log("last comment for qc=>",this.state.lastQCComment);
                            // })
                            
                        }
                        
                        this.state.clientApprovalOptions.map(item=>{
                            if(item.label===this.state.IsVDMonetizable){
                                this.setState({IsVDMonetizable:item},()=>{ }) 
                            }
                            if(item.label===this.state.impactTagChangeSelected){
                                this.setState({impactTagChangeSelected:item},()=>{ }) 
                            }
                            if(item.label===this.state.deliveryCategorizedSelect){
                                this.setState({deliveryCategorizedSelect:item},()=>{ }) 
                            }
                            if(item.label===this.state.deliveryComputedCorrectlySelected){
                                this.setState({deliveryComputedCorrectlySelected:item},()=>{ }) 
                            }
                            if(item.label===this.state.signOffDeliverySelected){
                                this.setState({signOffDeliverySelected:item},()=>{ }) 
                            }
                            if(item.label===this.state.bbReview){
                                this.setState({bbReview:item},()=>{ }) 
                            }
                            if(item.label===this.state.bbReviewCheckbox){
                                this.setState({bbReviewCheckbox:item},()=>{ }) 
                            }
                        })
                        if( this.state.ideaItem.TransformationLeverName === "Digital"){
                            this.setState({digitalProjectEnabled:true})
                        }

                        this.state.replicaIndexOption.map(item=>{
                            if(parseInt(item.value)===parseInt(this.state.replicaIndex)){
                                let options={
                                    value:item.value,
                                    label:item.label
                                }
                                this.setState({replicaIndex:options},()=>{ }) 
                            }
                        })

                        this.state.postMonetizableOptions.map(item=>{
                            if(item.label===this.state.monetizableStatus){
                                this.setState({monetizableStatus:item},()=>{
                                    if(this.state.monetizableStatus.label==="Approved by Client"){
                                        this.setState({showClientApprovalFields:true},()=>{})
                                    }
                                }) 
                            }
                        })

                        if( this.state.IsVDType === "Value Delivered to Client + Genpact"){
                            this.setState({showClientFields:true,showGOLFields:true},()=>{})
                        }
                        if( this.state.IsVDType === "No Value Delivery"){
                            this.setState({showClientFields:false,showGOLFields:false},()=>{})
                        }
                        if( this.state.IsVDType === "Value Delivered to Client"){
                            this.setState({showClientFields:true,showGOLFields:false},()=>{})
                        }
                        if( this.state.IsVDType === "Value Delivered to Genpact"){
                            this.setState({showClientFields:false,showGOLFields:true},()=>{})
                        }

                        this.state.impactOptions.map((item)=>{
                            if(item.label===this.state.IsVDType){
                                this.setState({impactOptionSelected:item})
                            }
                        })
                        
                        this.setState(prevState => ({
                                businessImpactValueArray: prevState.businessImpactValueArray.map(item => ({
                                ...item,
                                pg_srno: item.SrNo,
                                dollarValue: item.DollarImpact,
                                fteValue: item.FTEImpact
                            })).map(item => {
                                delete item.SrNo;
                                delete item.DollarImpact;
                                delete item.FTEImpact;
                                return item;
                            })
                            }), () => {
                        });


                        let myArray = [...this.state.businessImpactValueArray];
                        const groupedData = myArray.reduce((accumulator, currentValue) => {
                        const ImpactType = currentValue.ImpactType;
                        const MonetizableType = currentValue.MonetizableType;
                        const FTEImpact = parseFloat(currentValue.FTEImpact) || 0.0;
                        const DollarImpact = parseFloat(currentValue.DollarImpact) || 0.0;
                  
                        const existingRow = accumulator.find(row => row.ImpactType === ImpactType && row.MonetizableType === MonetizableType);
                        if (existingRow) {
                            existingRow.FTEImpact += FTEImpact
                            existingRow.DollarImpact += DollarImpact
                        } else {
                            accumulator.push({ ImpactType, MonetizableType, FTEImpact, DollarImpact });
                        }
                  
                        return accumulator;
                        }, []);

                        
                        let sortedSummaryIndex =   groupedData.sort((a, b) => a.ImpactType.toLowerCase().localeCompare(b.ImpactType.toLowerCase()))
                        this.setState({summaryArray: sortedSummaryIndex},()=>{});

                        if(this.state.ideaItem.CampaignName === "Gen AI"){
                            this.setState({chatGPTTheme:true},()=>{
                                if(this.state.chatGPTTheme){
                                    let options = [];
                                    this.state.chatGptOptions.map(item=>{
                                        if(item.label=== this.state.ideaItem.SubCampaignName){
                                            options.push({
                                                value:item.value,
                                                label:item.label
                                            })
                                            this.setState({chatGptSelectedOption:options})
                                        }
                                    })
                                    
                                }
                            })
                        }

                        if(this.state.ideaItem.IsVDMonetizable==="Yes" && this.state.ideaItem.MonetizationStatus==="Approved by Client"){
                            let MonetizedBiArray =[];
                            this.state.businessImpactValueArray.filter((item)=>{
                                if(item.IsMonetized==="Yes" ){
                                    MonetizedBiArray.push(item)
                                }
                            })
                            if(MonetizedBiArray){
                                const monetizedGroup = MonetizedBiArray.reduce((accumulator, currentValue) => 
                                {
                                    const ImpactType = currentValue.ImpactType;
                                    const FTEImpact = parseFloat(currentValue.FTEImpact) || 0.0;
                                    const DollarImpact = parseFloat(currentValue.DollarImpact) || 0.0;
                                    const existingRow = accumulator.find(row => row.ImpactType === ImpactType );
                                    if (existingRow) 
                                    {
                                        existingRow.FTEImpact += FTEImpact
                                        existingRow.DollarImpact += DollarImpact
                                    } 
                                    else 
                                    {
                                        accumulator.push({ ImpactType, FTEImpact, DollarImpact });
                                    }
                                    return accumulator;
                                }, []);
                                    this.setState({totalMonetizedFTE:monetizedGroup[0].FTEImpact, totalMonetizedDollar:monetizedGroup[0].DollarImpact},()=>{})
                            }
                            
                        }
                        
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching idea. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }

    fetchMetadata = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "",
                EntityName: ""
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        businessImpactMasterList: data ? data.BIMasterDataVerseNew : [],
                        biMasterListAll:data ? data.BIMasterDataVerse : [],
                        adminUserList: data ? data.DataverseUserRole : [],
                        accountList: data ? data.Accounts.sort((a, b) => { return a.ACCOUNT > b.ACCOUNT ? 1 : -1; }) : [],
                        campaignList: data ? data.Campaigns : [],
                        serviceLineList: data ? data.ServiceLines : [],
                        subServiceLineList: data ? data.ServiceLines : [],
                        countryList: data ? data.Countries.sort((a, b) => { return a.COUNTRY > b.COUNTRY ? 1 : -1; }) : [],
                        dataBridgeProjectThemeList: data ? data.DataBridgeThemes : [],
                        subCampaignList: data ? data.SubCampaigns : [],
                        workFlowStatusList: data ? data.WorkflowStatus : [],
                        certificationLevelsList: data ? data.CertificationLevels : [],
                        certificationWorkTypeList: data ? data.WorkType : [],
                        transformationLeversList: data ? data.TransformationLevers : [],
                        methodologyList: data ? data.Methodologies : [],
                        digitalProjectTypeList: data ? data.DigitalProjectTypes : [],

                        biThreshold: data.BIQCThreshold[0] ? data.BIQCThreshold[0].pg_threshold: 0,
                        vrconditiontype: data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_vrconditiontype : [],
                        vrVerticalMapping:data.BIQCVRMapping[0].pg_vertical ? data.BIQCVRMapping[0].pg_vertical.split("|") : "ALL",
                        vrRegionMapping:data.BIQCVRMapping[0].pg_region ? data.BIQCVRMapping[0].pg_region.split("|") : "ALL",


                    }, () => {
                        this.state.digitalProjectTypeList.map((item) => {
                            if(item.DigitalProjectTypeName === this.state.ideaItem.DigitalProjectType){
                                const options={
                                    value:item.ProjectTypeId,
                                    label:item.DigitalProjectTypeName
                                }
                                this.setState({digitalSelected:options},()=>{
                                     console.log(this.state.digitalSelected)
                                })
                            }
                        })
                       
                        
                        this.state.adminUserList.map((item) => {
                            if (parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
                                this.setState({ admin: true, adminOHR: parseInt(item.pg_ohr) });
                                return false;
                            }
                            return false;
                        });
                        this.setSelectedOptions();

                        let filteredImpactType = this.state.businessImpactMasterList.map(item=>item.pg_impacttype).filter(
                            (value,index,self)=>self.indexOf(value)===index
                        )
                        filteredImpactType = Object.entries(filteredImpactType)
                        this.setState({filteredImpactTypeState:filteredImpactType},()=>{})
                        
                        let filtereedMonetizabletype = this.state.businessImpactMasterList.map(item=>item.pg_monetizabletype).filter(
                            (value,index,self)=>self.indexOf(value)===index
                        )
                        filtereedMonetizabletype = Object.entries(filtereedMonetizabletype)
                        this.setState({filteredMonetizableTypeState:filtereedMonetizabletype},()=>{})
                        
                        let filteredBiSubCategory = this.state.businessImpactMasterList.map(item=>item.pg_bisubcategory).filter(
                            (value,index,self)=>self.indexOf(value)===index
                        )
                        filteredBiSubCategory = Object.entries(filteredBiSubCategory)
                        this.setState({filteredBISubCategoryState:filteredBiSubCategory},()=>{})


                        let data = [];
                        this.state.businessImpactMasterList.map((biItem, index) => {
                            data.push({});
                            this.state.businessImpactValueArray.map((item) => {
                                if (biItem.pg_impacttype === item.ImpactType && biItem.pg_monetizabletype === item.MonetizableType && biItem.pg_bisubcategory === item.BISubCategory) {
                                    data[index] = item;
                                }
                            })
                        });

                        
                        resolve();
                    })
                })
                .catch(error => {
                    Toast.fail('Error occured while getting metadata idea. Please try again!', 3000, () => {
                        console.log(JSON.stringify(error));
                        this.setState({
                            businessImpactMasterList: [],
                            serviceLineList: [],
                            subServiceLineList: [],
                        }, () => {
                            resolve();
                        })
                    });

                });
        });
    }

    fetchLDTLeadOptions = () => {
        return new Promise((resolve, reject) => {
            let entityType = "BB";
            let entityName = this.state.regionText;
            if (this.state.regionText === OTHER_REGIONS) {
                entityType = "BBOTHER";
                entityName = this.state.verticalManual.replace(" - ", "-");
            }
            const param = {
                EntityType: entityType,
                EntityName: entityName
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
                .then(response => response.json())
                .then(data => {
                    Toast.hide();
                    this.setState({
                        leadNameList: data.DataverseUserRoles !== undefined ? data.DataverseUserRoles : [],
                    })
                },()=>{
                    let filteredLDTLeadList = this.state.leadNameList.filter((item) => {
                        const userRole = JSON.parse(item.pg_Role).pg_role;
                        return ((userRole === "LDT Lead") && (parseInt(this.state.ideaItem.OwnerOHR) !== parseInt(item.pg_ohr)));
                    });
                    if(this.state.ideaItem.WorkflowStatus === 'Pending'){
                        let ideaItem = this.state.ideaItem;
                        ideaItem.LDTId = filteredLDTLeadList[0].pg_ohr;
                        ideaItem.LDTLeadName = filteredLDTLeadList[0].pg_username;
                        this.setState({
                            ideaItem,
                                LDTLeadSelectedOption: {
                                label: filteredLDTLeadList[0].pg_username,
                                value: filteredLDTLeadList[0].pg_ohr
                            }
                        })
                    }
                    this.setState({
                        leadNameList: filteredLDTLeadList,
                    });
                    resolve();
                })
                .catch(error => {
                    Toast.hide();
                    Toast.fail('Error while getting LDT Lead metadata idea. Please try again!', 3000, () => {
                        console.log(JSON.stringify(error));
                    });
                });
        });
    }

    setSelectedOptions = () => {
        console.log("mbb ",this.state.ideaItem.MBBOHR)
        if (this.state.ideaItem.BlackBeltUserId) {
            this.setState({
                blackBeltSelectedOption: {
                    value: this.state.ideaItem.BlackBeltUserId,
                    label: this.state.ideaItem.BlackBeltUserName
                },
                blackBeltUserId: this.state.ideaItem.BlackBeltUserId,
                blackBeltUserName: this.state.ideaItem.BlackBeltUserName
            })
        }
        if (this.state.ideaItem.MBBOHR) {
            this.setState({
                mbbSelectedOption: {
                    value: this.state.ideaItem.MBBOHR,
                    label: this.state.ideaItem.MBBName
                },
                mbbOHR: this.state.ideaItem.MBBOHR,
                mbbName: this.state.ideaItem.MBBName
            })
        }
        
        if (this.state.ideaItem.LDAOHR) {
            this.setState({
                ldaSelectedOption: {
                    value: this.state.ideaItem.LDAOHR,
                    label: this.state.ideaItem.LDA
                },
                ldaOHR: this.state.ideaItem.LDAOHR,
                lda: this.state.ideaItem.LDA
            },()=>{})
        }
        this.state.accountList.map((item) => {
            if (this.state.ideaItem.AccountManual === item.ACCOUNT) {
                this.setState({
                    accountSelectedOption: {
                        label: item.ACCOUNT
                    },
                    //holdingAccount: item.ACCOUNT,
                    accountName: item.ACCOUNT
                }, () => {
                    this.fetchVerticalOptions();
                })
            }
            return 1;
        });
        this.state.campaignList.map((item) => {
            if (this.state.ideaItem.CampaignName === item.CampaignName) {
                this.setState({
                    campaignTypeText: item.CampaignName,
                    campaignSelectedOption: {
                        value: item.CampaignId,
                        label: item.CampaignName
                    }
                })
            }
            return 1;
        });
        if (this.state.dataBridgeProjectThemeList) {
            this.state.dataBridgeProjectThemeList.map((item) => {
                if (this.state.ideaItem.DataBridgeTheme === item.DBProjectThemeName) {
                    this.setState({
                        dataBridgeSelectedOption: {
                            value: item.ThemeId,
                            label: item.DBProjectThemeName
                        }
                    },()=>{})
                }
                return 1;
            });
        }
        this.state.subCampaignList.map((item) => {
            if (this.state.ideaItem.SubCampaignName === item.SubCampaignName) {
                this.setState({
                    subCampaignSelectedOption: {
                        value: item.SubCampaignId,
                        label: item.SubCampaignName
                    }
                })
            }
            return 1;
        });
        this.state.countryList.map((item) => {
            if (this.state.ideaItem.Country === item.COUNTRY) {
                this.setState({
                    countrySelectedOption: { label: item.COUNTRY },
                    country: item.COUNTRY,
                }, () => {
                    this.fetchRegionOptions();
                })
            }
            return 1;
        });
        this.state.serviceLineList.map((item) => {
            if (this.state.ideaItem.ServiceLineName === item.ServiceLineName) {
                this.setState({
                    serviceLineSelectedOption: {
                        value: item.ServiceLineId,
                        label: item.ServiceLineName
                    }
                })
            }
            return 1;
        });
        this.state.subServiceLineList.map((item) => {
            if (this.state.ideaItem.SubServiceLine === item.SubServiceLineName) {
                this.setState({
                    subServiceLineSelectedOption: {
                        value: item.SubServiceLineId,
                        label: item.SubServiceLineName
                    }
                })
            }
            return 1;
        });
        this.state.methodologyList.map((item) => {
            if (this.state.ideaItem.MethodologyName === item.MethodologyName) {
                this.setState({
                    methodologySelectedOptions: {
                        value: item.MethodologyTypeID,
                        label: item.MethodologyName
                    }
                })
            }
            return 1;
        });
        this.state.certificationLevelsList.map((item) => {
            if (this.state.ideaItem.CertificationLevelName === item.CertificationLevelName) {
                this.setState({
                    certLevelsSelectedOption: {
                        value: item.CertificationLevelId,
                        label: item.CertificationLevelName
                    }
                })
            }
            return 1;
        });
        this.state.transformationLeversList.map((item) => {
            if (this.state.ideaItem.TransformationLeverName === item.TransformationLeverName) {
                this.setState({
                    transSelectedOption: {
                        value: item.TransformationLeverId,
                        label: item.TransformationLeverName
                    }
                })
            }
            return 1;
        });
        
        this.state.workFlowStatusList.map((item) => {
            if (this.state.ideaItem.WorkflowStatus === item.WorkflowStatusName) {
                this.setState({
                    workFlowStatusSelectedOption: {
                        value: item.WorkflowStatusId,
                        label: item.WorkflowStatusName
                    }
                })
            }
            return 1;
        });
        this.state.finalApproveOptionsValue.map((item, index) => {
            if (this.state.ideaItem.ApprovalStatus === item.WorkflowStatusName) {
                this.setState({
                    approvalSelectedOption: {
                        value: item.WorkflowStatusId,
                        label: item.WorkflowStatusName
                    }
                })
            }
            return 1;
        });
        if (this.state.ideaItem) {
            this.setState({
                replicaIndex: { value: this.state.ideaItem.ReplicabilityIndex, label: this.state.ideaItem.ReplicabilityIndex },
                otherTools: this.state.ideaItem.FinalOtherTools,
                Problem: this.state.ideaItem.Problem,
                Solution: this.state.ideaItem.Solution,
                ReplicationProjectId: this.state.ideaItem.ReplicationProjectId,
                IsBestInClass: this.state.ideaItem.IsBestInClass,
                IsZeroImpactProject: this.state.ideaItem.IsZeroImpactProject,
                IsSharedToSixSigma: this.state.ideaItem.IsSharedToSixSigma,
                LDTLeadSelectedOption: { label: this.state.ideaItem.LDTLeadName, value: this.state.ideaItem.LDTId }
            })
        }
    }

    saveEvent = () => {
        let redirectURL = "/review-page";
        if (this.state.approvalPage) {
            if (this.state.isViewAllProject) {
                redirectURL = "/view-all-projects-page";
                this.props.history.push({
                    pathname: redirectURL,
                    propsList: this.state.propsData
                })
            }
            else if (this.state.isViewAllIdea) {
                redirectURL = "/view-all-ideas-page";
                this.props.history.push({
                    pathname: redirectURL,
                    propsList: this.state.propsData
                })
            }
            else if (this.state.approvalPage === PROJECT_VIEW) {
                redirectURL = "/list-projects-page";
                this.props.history.push({
                    pathname: redirectURL,
                    propsList: this.state.propsData
                })
            } else {
                this.props.history.push({
                    pathname: redirectURL,
                    search: '?page=' + this.state.approvalPage,
                    propsList: this.state.propsData
                })
            }
        }
        else {
            this.props.history.push({
                pathname: redirectURL,
                propsList: this.state.propsData
            })
        }
    }

    showPPTContent = () => {
        Toast.loading("Fetching Project PPT Deck content...", () => { });
        this.fetchPPTContent()
            .then(() => {
                var fileData = this.state.projectDeckContent;
                var fileName = this.state.projectDeckFilename;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    showSignOffContent = () => {
        Toast.loading("Fetching Customer Sign-off content...", () => { });
        this.fetchSignoffContent()
            .then(() => {
                var fileData = this.state.signOffContent;
                var fileName = this.state.signOffFilename;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    renderAccountOptions = () => {
        let options = [];
        let accountName = "";
        this.state.accountList.map((item) => {
            accountName = item.ACCOUNT.toString().toLowerCase();
            if (!(accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact"))) {
                options.push({
                    value: item.ACCOUNT_L2,
                    label: item.ACCOUNT
                })
            }
            return true;
        });
        return options;
    }

    renderVerticalOptions = () => {
        let options = [];
        const uniqueVerticals = [...new Map(this.state.verticalList.map(item => [item["VERTICAL"], item])).values()]
        uniqueVerticals.map((item) => {
            if (!(item.VERTICAL.toString().toLowerCase() === "ts")) {
                options.push({ value: item.VERTICAL, label: item.VERTICAL });
            }
            return true;
        });
        return options;
    }

    renderSubVerticalOptions = () => {
        let options = [];
        this.state.subVerticalList = [];
        this.state.verticalList.map((item) => {
            if (this.state.verticalSelectedOption.label === item.VERTICAL) {
                if (item.SUB_VERTICAL.toString().toLowerCase() !== "corporate" || this.state.accountName.toLowerCase().includes("support")) {
                    options.push({ value: item.SUB_VERTICAL, label: item.SUB_VERTICAL });
                    this.state.subVerticalList.push(item.SUB_VERTICAL);
                }
            }
            return true;
        });
        
        return options;
    }

    renderBlackBeltOptions = () => {
        let options = [];
        this.state.blackbeltList.map((item) => {
            const userRole = JSON.parse(item.pg_Role).pg_role;
            if (userRole === "BB") {
                if (this.state.ownerOHR !== item.pg_ohr) {
                    options.push({ value: item.pg_ohr, label: item.pg_username });
                }
            }
            return true;
        });
        return options;
    }

    renderMBBOptions = () => {
        let options = [];
        this.state.mbbList.map((item) => {
            const userRole = JSON.parse(item.pg_Role).pg_role;
            if (userRole === "MBB") {
                if (this.state.ownerOHR !== item.pg_ohr) {
                    options.push({ value: item.pg_ohr, label: item.pg_username });
                }
            }
            return true;
        });
        return options;
    }

    // renderLDAOptions = () => {
    //     let options = [];
    //     this.state.ldaList.map((item) => {
    //         const userRole = JSON.parse(item.pg_Role).pg_role;
    //         if (userRole === "LDA") {
    //             if (this.state.ownerOHR !== item.pg_ohr) {
    //                 options.push({ value: item.pg_ohr, label: item.pg_username });
    //             }
    //         }
    //         return true;
    //     });
    //     return options;
    // }

    renderLeadOptions = () => {
        let options = [];
        const uniqueLeadList = [...new Map(this.state.leadNameList.map(item => [item.pg_ohr, item])).values()];

        uniqueLeadList.map((item) => {
            options.push({ value: item.pg_ohr, label: item.pg_username });
            return options;
        });
        return options;
    }

    renderMethodologyOptions = () => {
        let options = [];
        this.state.methodologyList.map((item) => {
            options.push({
                value: item.MethodologyId,
                label: item.MethodologyName
            })
            return options;
        });
        return options;
    }

    renderRegionOptions = () => {
        let options = [];
        if (this.state.regionList.length > 0) {
            this.state.regionList.map((item) => {
                options.push({ value: item.Region, label: item.Region });
                return true;
            });
        }
        return options;
    }
    renderSubRegionOptions = () => {
        let options = [];
        if (this.state.subRegionList.length > 0) {
            this.state.subRegionList.map((item) => {
                options.push({ value: item.Region, label: item.Region });
                return true;
            });
        }
        return options;
    }

    renderCountryOptions = () => {
        let options = [];
        this.state.countryList.map((item) => {
            options.push({ value: item.COUNTRY, label: item.COUNTRY });
            return true;
        });
        return options;
    }

    renderCampaignOptions = () => {
        let options = [];
        this.state.campaignList.map((item) => {
            options.push({ value: item.CampaignId, label: item.CampaignName });
            return true;
        });
        return options;
    }

    renderDataBridgeProjectThemeOptions = (e) => {
        let options = [];
        this.state.dataBridgeProjectThemeList.map((item) => {
            options.push({ value: item.ThemeId, label: item.DBProjectThemeName });
            return true;
        });
        return options;
    }

    renderSubCampaignOptions = () => {
        let options = [];
        this.state.subCampaignList.map((item) => {
            options.push({ value: item.SubCampaignId, label: item.SubCampaignName });
            return true;
        });
        return options;
    }

    renderServiceLineOptions = () => {
        let options = [];
        const uniqueServiceLineList = [...new Map(this.state.serviceLineList.map(item =>
            [item.ServiceLineName, item])).values()];
        uniqueServiceLineList.map((item) => {
            options.push({ value: item.ServiceLineId, label: item.ServiceLineName });
            return true;
        });
        return options;
    }

    renderSubServiceLineOptions = () => {
        let options = [];

        this.state.subServiceLineList.map((item) => {
            if (this.state.ideaItem.ServiceLineName === item.ServiceLineName) {
                options.push({ value: item.SubServiceLineId, label: item.SubServiceLineName });
                return true;
            }
            return true;
        });
        return options;
    }

    renderReplicaIndexOptions = () => {
        let options = [];
        this.state.replicaIndexOption.map((item) => {
            options.push({
                value: item.value,
                label: item.label
            })
            return options;
        });
        return options;
    }

    replicaIndexChangeHandler = (e) => {

        this.setState({
            replicaIndex: {
                value: e.value,
                label: e.label
            }
        });
    }

    handleAccountNameChange = (e) => {
        
        this.setState({
            verticalList: [],
            isAccountChange: true,
            verticalSelectedOption: null,
            subVerticalSelectedOption: null,
            regionSelectedOption: null,
            subRegionSelectedOption: null,
            countrySelectedOption: null,
            blackBeltSelectedOption: null,
            mbbSelectedOption: null,
            accountName: e.label,
            holdingAccount: e.value,
            accountSelectedOption: { label: e.label },
        }, () => {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.accountName = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj,
                isAccountChange:true
            });
            this.fetchVerticalOptions();
            this.fetchCountryOptions();
        }, () => {
            this.renderVerticalOptions();
            this.renderCountryOptions();
        });
    }

    handleVerticalChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.verticalManual = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            subVerticalSelectedOption: null,
            verticalManual: e.label,
            verticalSelectedOption: {
                value: e.value,
                label: e.label
            }
        }, () => {
            this.setState({ subVerticalList: [] });
            this.renderSubVerticalOptions();
            this.setState({
                subVerticalSelectedOption: {
                    value: (this.state.subVerticalList.length > 0) ? this.state.subVerticalList[0] : "",
                    label: (this.state.subVerticalList.length > 0) ? this.state.subVerticalList[0] : ""
                }
            });
            this.fetchBlackBeltOptions();
        });
    }

    handleSubVerticalChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.subVerticalManual = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            subVerticalManual: e.label,
            subVerticalSelectedOption: {
                value: e.value,
                label: e.label
            }
        });
    }

    handleCampaignNameChange = (e) => {
        if(e.label === "Gen AI"){
            this.setState({chatGPTTheme:true},()=>{})
        }else{
            this.setState({chatGPTTheme:false},()=>{
                this.setState({chatGptSelectedOption:[]})
            })
        }

        let styleStateObj = this.state.selectStyle;
        styleStateObj.campaignTypeText = selectStyle(true);

        let flagStatus = (e.label === DATA_BRIDGE_CAMPAIGN) ? true : false;
        let xPollinationflagStatus = (e.label === X_POLLINATION_CAMPAIGN) ? true : false;

        this.setState({
            dataBridgeCampaign: flagStatus,
            xPollinationCampaign: xPollinationflagStatus,
            dataBridgeProjectTheme: "",
            subCampaignName: "",
            selectStyle: styleStateObj,
            campaignTypeText: e.label,
            campaignSelectedOption: { label: e.label }
        });
    }

    handleDataBridgeProjectThemeChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.dataBridgeProjectTheme = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            dataBridgeProjectTheme: e.label,
            dataBridgeSelectedOption: { label: e.label, value: e.value, }
        });
    }

    handleSubCampaignChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.subCampaignName = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            subCampaignName: e.label,
            subCampaignSelectedOption: { label: e.label }
        });
    }

    renderChatGPToptions=()=>{
        let options = [];
        this.state.chatGptOptions.map((item) => {
            options.push({ value: item.value, label: item.label });
            return true;
        });
        return options;
    }
    handleChatGptOptionsChange=(e)=>{
        let styleStateObj = this.state.selectStyle;
        styleStateObj.campaignTypeText = selectStyle(true);
        if(e !== null || e !== undefined){
            this.setState({
                subCampaignName: e.label,
            },()=>{})
            this.setState({
                chatGptSelectedOption:{
                    value:e.value,
                    label:e.label
                }
            },()=>{})
        }
        
    }



    handleBlackBeltNameChange = (e) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.blackBeltUserName = e.label;
        if ((parseInt(e.value) === parseInt(this.state.ideaItem.OwnerOHR)) || (parseInt(e.value) === parseInt(this.state.ideaItem.CreatorOHR))) {
            Toast.fail('Black Belt user cannot be the same as Idea Owner/Requester. Please select a different Black Belt user.', 3000, () => {
                return;
            });
            return;
        }
        let styleStateObj = this.state.selectStyle;
        styleStateObj.blackBeltUserName = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            blackBeltUserId: e.value,
            blackBeltUserName: e.label,
            blackBeltUserEmail: e.value + "@genpact.com",
            blackBeltSelectedOption: {
                value: e.value,
                label: e.label
            },
            rolesUpdated:true
        });
    }

    handleMBBChange = (e) => {
        if ((parseInt(e.value) === parseInt(this.state.ideaItem.OwnerOHR)) || (parseInt(e.value) === parseInt(this.state.ideaItem.CreatorOHR))) {
            Toast.fail('MBB user cannot be the same as Idea Owner/Requester. Please select a different Black Belt user.', 3000, () => {
                return;
            });
            return;
        }
        let styleStateObj = this.state.selectStyle;
        styleStateObj.mbbUserName = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            mbbName: e.label,
            mbbOHR: e.value,
            mbbSelectedOption: {
                value: e.value,
                label: e.label
            },
            rolesUpdated:true
        });
    }

    handleLDTLeadOptionChange = (e) => {
        if ((parseInt(e.value) === parseInt(this.state.ideaItem.OwnerOHR)) || (parseInt(e.value) === parseInt(this.state.ideaItem.CreatorOHR))) {
            Toast.fail('LDT user cannot be the same as Idea Owner/Requester. Please select a different LDT user.', 3000, () => {
                return;
            });
            return;
        }
        let ideaItem = this.state.ideaItem;
        ideaItem.LDTId = e.value;
        ideaItem.LDTLeadName = e.label;
        this.setState({
            ideaItem,
            LDTLeadSelectedOption: {
                value: e.value,
                label: e.label
            },
            rolesUpdated:true
        });
    }

    handleRegionChange = (e) => {
        if (e.label === OTHER_REGIONS && (!this.state.verticalManual || this.state.verticalManual === "")) {
            this.setState({
                regionSelectedOption: null
            });
            Toast.fail('Please select Account & Vertical to fetch BlackBelt users for Other Regions.', 3000, () => {
                return;
            });
        } else {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.regionManual = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj,
                regionManual: e.label,
                regionText: e.label,
                regionSelectedOption: {
                    value: e.value,
                    label: e.label
                },
                blackBeltSelectedOption: null,
                mbbSelectedOption: null,
            }, () => {
                this.fetchBlackBeltOptions(e);
            });
        }
    }

    handleMethodologyChange = (e) => {
        this.setState({
            methodologySelectedOptions: {
                value: e.value,
                label: e.label
            }
        });
    }

    handleSubRegionChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.subRegion = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            subRegion: e.label,
            subRegionSelectedOption: {
                value: e.value,
                label: e.label
            }
        });
    }

    handleCountryChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.country = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            country: e.value,
            regionSelectedOption: null,
            subRegionSelectedOption: null,
            blackBeltSelectedOption: null,
            mbbSelectedOption: null,
            ldaSelectedOption: null,
            countrySelectedOption: {
                value: e.value,
                label: e.label
            }
        }, () => {
            this.fetchRegionOptions(e);
        });
    }

    handleServiceLineChange = (e) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.ServiceLineId = e.value;
        ideaItem.ServiceLineName = e.label;
        ideaItem.SubServiceLineId = "";
        ideaItem.SubServiceLine = "";
        this.setState({
            subServiceLineSelectedOption: null,
            serviceLineSelectedOption: {
                value: e.value,
                label: e.label
            },
            ideaItem
        });
        this.renderSubServiceLineOptions(e);
    }

    handleSubServiceLineChange = (e, index) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.SubServiceLine = e.label;
        this.setState({
            subServiceLineSelectedOption: {
                value: e.value,
                label: e.label
            },
            ideaItem
        });
    }

    handleProblemChange = (text) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.Problem = text;
        this.setState({
            ideaItem
        },()=>{});
    }

    handleSolutionChange = (text) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.Solution = text;
        this.setState({
            ideaItem:ideaItem
        },()=>{});
    }

    handleCommentChange = (text) => {
        if(text){
            this.setState({comment:text},()=>{console.log(this.state.comment)})
        }
        let styleStateObj = this.state.selectStyle;
        styleStateObj.comment = textBoxValidation(true);
        this.setState({
            selectStyle: styleStateObj
        })

        let ideaItem = this.state.ideaItem;
        ideaItem.Comment = text;
        this.setState({ideaItem:ideaItem},()=>{});
    }

    handleFinalCommentChange = (text) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.FinalComments = text;
        this.setState({
            ideaItem:ideaItem,
            finalComments : text
        },()=>{});
    }

    showHideDiv = () => {
        let ideaItem = this.state.ideaItem;
        ideaItem.NewOrReplicatedIdea = !ideaItem.NewOrReplicatedIdea;
        this.setState({
            ideaItem
        },()=>{});
    }

    showHideBestInClass = () => {
        let ideaItem = this.state.ideaItem;
        ideaItem.IsBestInClass = !ideaItem.IsBestInClass;
        this.setState({
            ideaItem
        });
    }

    showHideZeroImpactProject = (event) => {
        this.setState({
            dollarTotal: 0.0,
            fteTotal: 0.0,
            zeroImpact:!this.state.zeroImpact,
            summaryArray:[],
            businessImpactValueArray:[],
            zeroImpactForLastestBI:true,
            FinalImpactDollar:0.0,
            FinalImpactFTE:0.0
        },()=>{});
    }

    showHideIsSharedToSixSigma = () => {
        let ideaItem = this.state.ideaItem;
        ideaItem.IsSharedToSixSigma = !ideaItem.IsSharedToSixSigma;
        this.setState({
            ideaItem
        });
    }

    handleReplicationIdChange = (text) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.ReplicationProjectId = text;
        this.setState({
            ideaArray: ideaItem
        });
    }

    handleOtherToolsChange = (event) => {
        this.setState({
            otherTools: event.target.value
        });
    }

    validateReplicationId = () => {
        if (!this.fetchReplicationIdeaDetails(this.state.ideaItem.ReplicationProjectId)) {
            let ideaItem = this.state.ideaItem;
            ideaItem.ReplicationProjectId = "";
            this.setState({
                ideaItem: ideaItem
            });
            return false;
        } else {
            return true;
        }
    }

    fetchRegionOptions = () => {
        Toast.loading("Fetching Regions. Please wait...", () => { });
        let entityType = "REGION";
        let entityName = this.state.country + "::" + this.state.accountName;

        const param = {
            EntityType: entityType,
            EntityName: entityName
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                Toast.hide();
                let styleStateObj = this.state.selectStyle;
                styleStateObj.regionManual = selectStyle(true);
                styleStateObj.subRegionManual = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                    regionList: data.Region.Table1 !== undefined ? data.Region.Table1 : [],
                    regionSelectedOption: {
                        label: data.Region.Table1[0].Region,
                        value: data.Region.Table1[0].Region
                    },
                    subRegionList: data.Region.Table1 !== undefined ? data.Region.Table1 : [],
                    subRegionSelectedOption: {
                        label: data.Region.Table1[0].SubRegion,
                        value: data.Region.Table1[0].SubRegion
                    },
                    regionText: data.Region.Table1[0].Region,
                    regionManual: data.Region.Table1[0].Region,
                    subRegion: data.Region.Table1[0].SubRegion
                });
                // console.log("Region List:" + JSON.stringify(this.state.regionList));
            })
            .then(() => {
                this.fetchBlackBeltOptions();
                this.fetchLDTLeadOptions();
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting Region details. Please try again!', 15000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }

    fetchVerticalOptions = () => {
        Toast.loading("Fetching Verticals. Please wait...", () => { });
        const param = {
            EntityType: "Vertical",
            EntityName: this.state.accountName
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                Toast.hide();
                let styleStateObj = this.state.selectStyle;
                styleStateObj.verticalManual = selectStyle(true);
                styleStateObj.subVerticalManual = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                    verticalList: data.Table1 !== undefined ? data.Table1 : [],
                    // verticalSelectedOption: {
                    //     label: data.Table1[0].VERTICAL,
                    //     value: data.Table1[0].VERTICAL
                    // },
                    // verticalManual: data.Table1[0].VERTICAL,
                    // subVerticalManual: data.Table1[0].SUB_VERTICAL
                    verticalSelectedOption: {
                        label: this.state.ideaItem.VerticalManual,
                        value: this.state.ideaItem.VerticalId
                    },
                    verticalManual: this.state.ideaItem.VerticalManual,
                    subVerticalManual: this.state.ideaItem.SubVerticalName
                }
                
                );
            }).then(() => {
                if( this.state.isAccountChange){
                    this.setState({
                        verticalSelectedOption: {
                            label: null,
                            value: null
                        },
                        isAccountChange: false
                
                    })
                    
                }
                this.renderVerticalOptions();
                this.renderSubVerticalOptions();
                this.setState({
                    subVerticalSelectedOption: {
                        value: (this.state.subVerticalList.length > 0) ? this.state.subVerticalList[0] : "",
                        label: (this.state.subVerticalList.length > 0) ? this.state.subVerticalList[0] : ""
                    },
                    subVerticalManual: (this.state.subVerticalList.length > 0) ? this.state.subVerticalList[0] : ""
                });
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting Vertical details. Please try again!', 10000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }

    fetchCountryOptions = () => {
        const param = {
            EntityType: "COUNTRY",
            EntityName: this.state.accountName
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.country = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                    countryList: data.Countries.Table1 !== undefined ? data.Countries.Table1 : [],
                });
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting Account specific Country details. Please try again!', 10000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }

    fetchBlackBeltOptions = () => {
        // Toast.loading("Fetching Blackbelts. Please wait...", () => { });
        let entityType = "BB";
        let entityName = this.state.regionText;
        if (this.state.regionText === OTHER_REGIONS) {
            entityType = "BBOTHER";
            entityName = this.state.verticalManual.replace(" - ", "-");
        }
        const param = {
            EntityType: entityType,
            EntityName: entityName
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                Toast.hide();
                this.setState({
                    blackbeltList: data.DataverseUserRoles !== undefined ? data.DataverseUserRoles : [],
                    mbbList: data.DataverseUserRoles !== undefined ? data.DataverseUserRoles : [],
                    ldaList: data.DataverseUserRoles !== undefined ? data.DataverseUserRoles : [],
                });
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting BlackBelt details. Please try again!', 15000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }

    fetchReplicationIdeaDetails = (IdeaId) => {
        let replicaID=IdeaId;
        console.log("length ideaID",IdeaId.length);
        if(IdeaId.length > 7){
            replicaID=IdeaId.slice(2,11)
        }
        Toast.loading("Fetching Replication Idea Details. Please wait...", () => { });
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            }
        };
        return fetch(`${GET_IDEA_URL}IdeaId=`+replicaID, requestOptions)
        // return fetch(`${GET_IDEA_URL}IdeaId=-1&IdeaIDAlpha=` + IdeaId, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.ResultSets.Table1 === undefined) {
                    Toast.fail("We could not find Idea details based on the entered Idea ID. Please check and reenter.", 5000, () => { });
                    return false;
                } else {
                    Toast.hide();
                    Toast.success('Replication Idea ID validated successfully!', 3000, () => { });
                    return true;
                }
            })
            .catch(error => {
                Toast.hide();
                Toast.fail("Could not find the Idea, please check and re-enter correct Idea ID.", 5000, () => { });
                return false;
            });
    }

    renderStatusOptions = () => {
        let options = [];
        this.state.workFlowStatusList.map((item) => {
            if (this.state.ideaItem.WorkflowStatus === 'Reject') {
                if (item.WorkflowStatusName !== 'Reject') {
                    options.push({
                        value: item.WorkflowStatusId,
                        label: item.WorkflowStatusName
                    })
                }
            }
            else {
                options.push({
                    value: item.WorkflowStatusId,
                    label: item.WorkflowStatusName
                })
            }
            return options;
        });
        return options;
    }

    renderApprovalStatusOptions = () => {
        let options = [];
        this.state.finalApproveOptionsValue.map((item) => {
            if (this.state.ideaItem.ApprovalStatus === 'Rejected') {
                if (item.WorkflowStatusName !== 'Rejected') {
                    options.push({
                        value: item.WorkflowStatusId,
                        label: item.WorkflowStatusName
                    })
                }
            }
            else {
                options.push({
                    value: item.WorkflowStatusId,
                    label: item.WorkflowStatusName
                })
            }

            return options;
        });
        return options;
    }

    renderCertLevelsOptions = () => {
        let options = [];
        this.state.certificationLevelsList.map((item) => {
            options.push({
                value: item.CertificationLevelId,
                label: item.CertificationLevelName
            });
            return options;
        });
        return options;
    }

    handleCertLevelChange = (e) => {
        this.setState({
            certLevelsSelectedOption: {
                value: e.value,
                label: e.label
            }
        });
        this.setState({potentialDollar:""},()=>{})
        

        // if(this.state.certLevelsSelectedOption.label === "Lean" && this.state.certification){
        //     this.setState({showDigital:true})
        // }

        //Reset the Work Type upon change of Certification Level
        this.setState({ 
            certWorkTypeSelectedOption: null, 
            digitalProjectTypeSelectedOptions: null,
            transSelectedOption:null
        });
        
        if (this.state.showAdditionalDetails && this.state.transSelectedOption && this.state.transSelectedOption.label === "Digital" ) {
            this.setState({
                workTypeEnabled: false
            });
        }
         if (e.label === "Lean" || e.label === "YB") {
            this.setState({
                workTypeEnabled: false
            });
        }
        
        if (e.label === "GB" && this.state.transSelectedOption && this.state.transSelectedOption.label === "Data") {
            this.setState({
                certWorkTypeSelectedOption: {
                    value: "GB ProDG",
                    label: "GB ProDG"
                },
                workTypeEnabled: false
            });
        } else {
            this.setState({
                digitalProjectTypeSelectedOptions: null,
                workTypeEnabled: false
            });
        }
        if (e.label === "GB" && this.state.transSelectedOption && this.state.transSelectedOption.label === "Continuous Improvement") {
            this.setState({
                workTypeEnabled: true
            });
        } else {
            this.setState({
                digitalProjectTypeSelectedOptions: null
            });
        }
        // if (e.label === "Lean"){
        //     this.setState({showDigital:true})
        // }
        // if (e.label === "GB"){
        //     this.setState({
        //         showDigital:false,
        //         transSelectedOption:null
        //     })
        // }          
    }

    renderTransLeversOptions = () => {
        let options = [];
        this.state.transformationLeversList.map((item) => {
            options.push({
                value: item.TransformationLeverId,
                label: item.TransformationLeverName
            });
        });
        return options;
    }
    renderTransLeversOptionsForLeanApproval=()=>{
        let options = [];
        // if(this.state.ideaItem.ApprovalStatus === "Pending Documents"){
        //     if(this.state.certLevelsSelectedOption !== "GB" ){
        //         this.state.transformationLeversList.map((item) => {
        //             options.push({
        //                 value: item.TransformationLeverId,
        //                 label: item.TransformationLeverName
        //             });
        //             options = options.slice(0,2);
        //         });
        //     }else{
        //         this.state.transformationLeversList.map((item) => {
        //             options.push({
        //                 value: item.TransformationLeverId,
        //                 label: item.TransformationLeverName
        //             });
        //         });
        //     }
        //     return options;
        // }
        if(this.state.ideaItem.CertificationLevelName === "Lean"){
            if(this.state.certLevelsSelectedOption !== "GB" ){
                this.state.transformationLeversList.map((item) => {
                    options.push({
                        value: item.TransformationLeverId,
                        label: item.TransformationLeverName
                    });
                    // options = options.slice(0,2);
                });
            }else{
                this.state.transformationLeversList.map((item) => {
                    options.push({
                        value: item.TransformationLeverId,
                        label: item.TransformationLeverName
                    });
                });
            }
            return options;
        }

        // this.state.transformationLeversList.map((item) => {
        //     options.push({
        //         value: item.TransformationLeverId,
        //         label: item.TransformationLeverName
        //     });
        // });
        // return options;
    }


    handleTransLeversChange = (e) => {
        if(e !== null || e !== undefined){
            const options={
                value:e.value,
                label:e.label
            }
            this.setState({transSelectedOption: options},()=>{
                if(this.state.transSelectedOption.label === "Digital"){
                    this.setState({digitalProjectEnabled:true})
                }
                if(this.state.transSelectedOption.label === "Data" || this.state.transSelectedOption.label== "Continuous Improvement"){
                    this.setState({digitalProjectTypeSelectedOptions:null,digitalSelected:null,digitalProjectEnabled:false})
                }
            });
        }    
    }


  

    renderDigitalProjectTypeOptions = () => {
        let options = [];
        this.state.digitalProjectTypeList.map((item) => {
            let skipOption = false;
            if (this.state.certLevelsSelectedOption && this.state.certLevelsSelectedOption.label === "GB"){
                if(item.DigitalProjectTypeName === "QPA" || item.DigitalProjectTypeName === "Other Digital"){
                    skipOption = true;
                }
            }
            if (this.state.certLevelsSelectedOption && this.state.certLevelsSelectedOption.label === "Lean") {
                if (item.DigitalProjectTypeName === "RPA") {
                    skipOption = true;
                }
            }
            if (!skipOption){
                options.push({
                    value: item.ProjectTypeId,
                    label: item.DigitalProjectTypeName
                })
            }
            return options;
        });
        return options;
    }

    handleDigitalProjectTypeChange = (e) => {
        // this.setState({
        //     digitalProjectTypeSelectedOptions: {
        //         value: e.value,
        //         label: e.label
        //     }
        // });
        this.setState({
            digitalSelected: {
                value: e.value,
                label: e.label
            }
        },()=>{
            console.log(this.state.digitalSelected)
        });
    }

    renderCertWorkTypeOptions = (selectedCert) => {
        let options = [];
        this.state.certificationWorkTypeList.map((item) => {
            if (!selectedCert || selectedCert.label === item.CertifiationLevelName){
                options.push({
                    value: item.WorkTypeName,
                    label: item.WorkTypeName
                });
            }
            return options;
        });
        return options;
    }
    
    handleCertWorkTypeChange = (e) => {
        this.setState({
            certWorkTypeSelectedOption: {
                value: e.value,
                label: e.label
            }
        });
        const BIFormApplicableProjects = ["GB ProDG", "GB BPMS LIVE", "GB POV 3D"];
        if (this.state.certLevelsSelectedOption.label === "GB" && 
            BIFormApplicableProjects.includes(e.label))
        {
            this.setState({
                showBIForm: true
            });
        }else{
            this.setState({
                showBIForm: false
            });
        }
    }

    showAdditionalDetails = (e) => {
        if (e.value === 1) {
            this.setState({
                showAdditionalDetails: true
            });
        }
        else {
            this.setState({
                showAdditionalDetails: false
            });
        }
        this.setState({
            workFlowStatusSelectedOption: {
                value: e.value,
                label: e.label
            }
        },()=>{
            console.log("work flow selected option is ",this.state.workFlowStatusSelectedOption)
        });
    }

    handleApprovalStatus = (e) => {
        if (this.state.ideaItem.ApprovalStatus === 'Documents Uploaded' || this.state.ideaItem.ApprovalStatus === 'Rejected') {
            if (e.label === COMPLETED_STATUS) {
                this.setState({
                    showFinalAdditionalDetails: true,
                    bbEditable:true,
                    IsVDMonetizable:[{
                        value:0,
                        label:"Choose monetizable project"
                    }]
                });
            }
            else {
                this.setState({
                    showFinalAdditionalDetails: false,
                    finalImpactDollar: 0,
                    FinalImpactDollar:0,
                    finalImpactFTE: 0,
                    fteTotal: 0,
                    dollarTotal: 0.0,
                    zeroImpact: false,
                    bestClass: false,
                    summaryArray: [],
                    otherTools: null,
                    replicaIndex: 0,
                    showBIForm: true,
                    businessImpactValueArray: [],
                    IsVDMonetizable:null,
                    bbReview:null,
                    bbReviewCheckbox:null
                });
            }
        }
        this.setState({
            approvalSelectedOption: {
                value: e.value,
                label: e.label
            }
        },()=>{console.log(this.state.approvalSelectedOption)});

        
    }

    // onFileChange = (event, fileType) => {
    //     var file = event.target.files[0];
    //     var currFilename = file.name;
    //     var currFilesize = file.size;
    //     if (currFilesize > 10000000) {
    //         alert("The size of the document you are uploading, is more than 10MB. Please upload a smaller document.");
    //         return;
    //     }

    //     var validExtensions = ['.xls', '.xlsx', '.xlsm', '.csv', '.doc', '.docx', '.pdf', '.ppt', '.pptx', '.pdf', '.jpeg', '.jpg', '.png', '.msg', '.png', '.mpp'];
    //     var currFileExtension = currFilename.substring(currFilename.lastIndexOf("."));
    //     var isFileTypeValid = validExtensions.filter((val) => val.toLowerCase().includes(currFileExtension.toLowerCase()));
    //     if (isFileTypeValid.length <= 0) {
    //         alert("The type of document you are uploading is not supported. Please upload only supported file types.");
    //         return;
    //     }

    //     if (fileType === "file1") {
    //         if (currFilename === this.state.filename2) {
    //             alert("You are trying to upload the same document. Please upload a different document.");
    //             return;
    //         }
    //     } else {
    //         if (currFilename === this.state.filename1) {
    //             alert("You are trying to upload the same document. Please upload a different document.");
    //             return;
    //         }
    //     }
    //     var reader = new FileReader();
    //     reader.readAsBinaryString(file);
    //     let self = this;
    //     reader.onload = function () {
    //         let fileContents = btoa(reader.result);
    //         if (fileType === "file1") {
    //             // self.setState({ base64File1: '{"filename":"'+event.target.files[0].name+'", "content":"'+reader.result+'"}', filename1: event.target.files[0].name })
    //             self.setState({ filename1: file.name, fileType1: file.type, base64File1: fileContents })
    //         }
    //         else {
    //             // self.setState({ base64File2: '{"filename":"' + event.target.files[0].name + '", "content":"' + reader.result + '"}', filename2: event.target.files[0].name })
    //             self.setState({ filename2: file.name, fileType2: file.type, base64File2: fileContents })
    //         }
    //     };
    //     reader.onerror = function () {
    //         console.log('There are some problems loading document contents.');
    //     };
    // };

    

    cancelEvent = () => {
        this.props.history.push("/view-all-ideas-page");
    }

    validate = () => {
        this.FinalApproverCheck()
        this.handleUpdaterAction()
        let isValid = true;
        let styleStateObj = this.state.selectStyle;
        this.isExceedlimit=false;

        
        // console.log("ideaitem comment log ",this.state.ideaItem.Comment)
        // if(this.state.ideaItem.WorkflowStatus==="Pending"){
        //     if(this.state.workFlowStatusSelectedOption){
        //         if(this.state.comment === "" || this.state.comment === null || this.state.comment === undefined || !this.state.comment){
        //             console.log("condition has met")
        //             Toast.fail("Idea approval comment log cannot be emppty",3000,()=>{})
        //             isValid = false;
        //             styleStateObj.comment = { ...textBoxValidation(false), border: "1px solid red" };
        //             this.setState({selectStyle: styleStateObj})
        //         }else{
        //             console.log("condition not met")
        //             styleStateObj.comment = textBoxValidation(true);
        //             this.setState({selectStyle: styleStateObj})
        //         }

        //         if(!this.state.ideaItem.Comment){
        //             console.log("condition has met")
        //             Toast.fail("Idea approval comment log cannot be emppty",3000,()=>{})
        //             isValid = false;
        //             styleStateObj.comment = { ...textBoxValidation(false), border: "1px solid red" };
        //             styleStateObj.comment = textBoxValidation(false);
        //             this.setState({selectStyle: styleStateObj})
        //         }else{
        //             console.log("condition not met")
        //             styleStateObj.comment = textBoxValidation(true);
        //             this.setState({selectStyle: styleStateObj})
        //         }
        //     }
        // }
        
        
        
        if(this.state.approvalSelectedOption && this.state.ideaItem.ApprovalStatus !== 'Completed'){
            if(this.state.approvalSelectedOption.label !== this.state.ideaItem.ApprovalStatus){
                if (this.state.finalComments.length > 500){
                    Toast.fail("Comment cannot be more than 500 characters, please change and resubmit.", 3000);
                    isValid = false;
                    console.log("approval selected option")
                    this.isExceedlimit=true;
                    styleStateObj.finalcomments = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
                else{
                    styleStateObj.finalcomments = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
            }
            else{
                if(this.state.approvalSelectedOption.label === this.state.ideaItem.ApprovalStatus){
                    Toast.fail("Please select other Idea Approval Status", 3000);
                    isValid = false;
                    styleStateObj.approvalSelectedOption = selectStyle(false);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
            }
        }

        
        if(this.state.comment && this.state.workFlowStatusSelectedOption){
            if(this.state.workFlowStatusSelectedOption.label === this.state.ideaItem.WorkflowStatus){
                Toast.fail("Please select other Idea Approval Status", 3000);
                isValid = false;
                styleStateObj.workFlowStatusSelectedOption = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else{
                styleStateObj.workFlowStatusSelectedOption = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
        }
        
        if(this.state.ideaItem.WorkflowStatus !== 'Approve'){
            if(this.state.workFlowStatusSelectedOption){
                if (this.state.comment.length > 500){
                    Toast.fail("Comment cannot be more than 500 characters, please change and resubmit.", 3000);
                    isValid = false;
                    this.isExceedlimit=true;
                    styleStateObj.comment = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
                else{
                    styleStateObj.comment = {...textBoxValidation(true), border: "1px solid lightgrey"};
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
            }
        }


        
        if(this.state.showAdditionalDetails && this.state.certLevelsSelectedOption === null){
            isValid = false;
            styleStateObj.certLevelsSelectedOption = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else{
            styleStateObj.certLevelsSelectedOption = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }


        
        if(this.state.showAdditionalDetails && this.state.transSelectedOption === null){
            isValid = false;
            styleStateObj.transSelectedOption = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else{
            styleStateObj.transSelectedOption = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }


        if(this.state.showAdditionalDetails && this.state.certWorkTypeSelectedOption === null){
            if (this.state.certLevelsSelectedOption && this.state.certLevelsSelectedOption.label === "GB"){
                isValid = false;
                Toast.fail("GB projects are not allowed to be updated by admin",3000,()=>{})
                styleStateObj.certWorkTypeSelectedOption = selectStyle(false);
                this.setState({selectStyle: styleStateObj})
            }else{
                styleStateObj.certWorkTypeSelectedOption = selectStyle(true);
                this.setState({selectStyle: styleStateObj})
            }
        }
        else{
            styleStateObj.certWorkTypeSelectedOption = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }

        
        // if(this.state.showAdditionalDetails && this.state.certWorkTypeSelectedOption === null){
        //     if (this.state.certLevelsSelectedOption && this.state.transSelectedOption &&
        //         (this.state.certLevelsSelectedOption.label === "GB" && (this.state.transSelectedOption.label === "Continuous Improvement" || this.state.transSelectedOption.label === "Data"))){
        //         isValid = false;
        //         Toast.fail("GB and CI n Data selected", 5000,()=>{})
        //         styleStateObj.certWorkTypeSelectedOption = selectStyle(false);
        //         this.setState({
        //             selectStyle: styleStateObj
        //         })
        //     }else{
        //         styleStateObj.certWorkTypeSelectedOption = selectStyle(true);
        //         this.setState({
        //             selectStyle: styleStateObj
        //         })
        //     }
        // }
        // else{
        //     styleStateObj.certWorkTypeSelectedOption = selectStyle(true);
        //     this.setState({
        //         selectStyle: styleStateObj
        //     })
        // }
        
        
        // if (this.state.showAdditionalDetails && this.state.digitalProjectTypeSelectedOptions === null) {
        //     if (this.state.transSelectedOption && this.state.transSelectedOption.label === "Digital" ) {
        //         isValid = false;
        //         Toast.fail("digital project type", 5000,()=>{})
        //         styleStateObj.digitalProjectTypeSelectedOptions = selectStyle(false);
        //         this.setState({
        //             selectStyle: styleStateObj
        //         })
        //     } else {
        //         styleStateObj.digitalProjectTypeSelectedOptions = selectStyle(true);
        //         this.setState({
        //             selectStyle: styleStateObj
        //         })
        //     }
        // }
        // else {
        //     styleStateObj.digitalProjectTypeSelectedOptions = selectStyle(true);
        //     this.setState({
        //         selectStyle: styleStateObj
        //     })
        // }

        
        if(this.state.showAdditionalDetails && this.state.methodologySelectedOptions === null){
            isValid = false;
            styleStateObj.methodologySelectedOptions = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else{
            styleStateObj.methodologySelectedOptions = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }

        // if((this.state.showAdditionalDetails || this.state.workFlowStatusSelectedOption) && this.state.mbbSelectedOption === null){
        //     isValid = false;
        //     styleStateObj.mbbUserName = selectStyle(false);
        //     this.setState({
        //         selectStyle: styleStateObj
        //     })
        // }else {
        //     styleStateObj.mbbUserName = selectStyle(true);
        //     this.setState({
        //         selectStyle: styleStateObj
        //     })
        // }

        if(this.state.rolesUpdated){
            if(this.state.approvalSelectedOption === null && this.state.finalComments===''&& this.state.comment===''){
                isValid = true;
            }
        }

       
        if(this.state.certLevelsSelectedOption && this.state.certLevelsSelectedOption.label==="Lean"){
            if( parseInt(this.state.potentialDollar) < 0 ){
                isValid = false;
                styleStateObj.potentialDollar = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else{
                styleStateObj.potentialDollar = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
        }
        

       
        if(this.state.IsVDType === "Value Delivered to Client + Genpact"){
            if(this.state.businessImpactValueArray.length > 0){
                let impactGenpactTrue = this.state.businessImpactValueArray.some( vendor => vendor['ImpactType'] === 'Impact at Genpact')
                let impactClientTrue = this.state.businessImpactValueArray.some( vendor => vendor['ImpactType'] === 'Impact at Client')
                if(impactGenpactTrue===false ){
                    isValid = false; 
                    Toast.fail('Please add both Impact types!', 3000, () => {})
                }
                if(impactClientTrue===false){
                    isValid = false; 
                    Toast.fail('Please add both Impact types!', 3000, () => {})
                }
            }
        }

        if(this.state.monetizableCheckbox){
            if(this.state.IsVDMonetizable==null || this.state.IsVDMonetizable=="" || this.state.IsVDMonetizable==[]){
                isValid = false;
                Toast.fail("Please mark the project as Monetizable",5000,()=>{})
                styleStateObj.IsVDMonetizable = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else {
                styleStateObj.IsVDMonetizable = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
        }

       
        if(this.state.IsVDMonetizable !== null){
            if(this.state.IsVDMonetizable.label === "Yes"){
                if(this.state.monetizableStatus === null || this.state.monetizableStatus === ""){
                    isValid=false;
                    Toast.fail("Since this project has been marked as Monetizable, monetizable status has to be selected",5000,()=>{})
                    styleStateObj.monetizableStatus = selectStyle(false);
                    this.setState({selectStyle: styleStateObj})
                }
            }
        }
        if(this.state.monetizableStatus !== null){
            if(this.state.monetizableStatus.label === "Approved by Client"){
                if( (this.state.businessImpactValueArray.filter(item => item.IsMonetized === "Yes")).length > 0){} 
                else
                {
                    isValid = false;
                    Toast.fail("Since, this is a monetizable project, there needs to be at least one ‘Monetizable’ check box ticked in the Value Delivery category",5000,()=>{})
                }
            }
        }

        
        if(this.state.ideaItem.ApprovalStatus && (this.state.ideaItem.ApprovalStatus === COMPLETED_STATUS || this.state.ideaItem.ApprovalStatus === AWAITING_MBB_APPROVAL || this.state.showFinalAdditionalDetails || this.state.ideaItem.ApprovalStatus === AWAITING_QAULITY_CHECK_REVIEW )){
            if(this.state.businessImpactValueArray.length === 0 && this.state.impactOptionSelected.label !== "No Value Delivery"){
                Toast.fail("Value Delivery cannot be empty, please fill and resubmit.", 3000, () => { });
                isValid = false;
            }
        }

        
        console.log("bi",this.state.businessImpactValueArray)
        if(this.state.businessImpactValueArray.length > 0){
            this.state.businessImpactValueArray.filter((item) => {
                console.log(item)

                if(parseFloat(item.dollarValue) === 0 || parseFloat(item.dollarValue) === "" || !item.dollarValue || item.dollarValue===null){
                    isValid = false;
                    Toast.fail("Impact dollar value cannot be zero",3000,()=>{})
                    console.log("dollar empty")
                }
                if(item && item.isFTEPresent ===true){
                    if(parseFloat(item.fteValue)){}
                    else{
                        console.log("FTE empty")
                        isValid = false;
                        Toast.fail("FTE Impact value cannot be zero",3000,()=>{})
                    }
                    if(parseFloat(item.fteValue) === 0){
                        console.log("FTE empty")
                        isValid = false;
                        Toast.fail("FTE Impact value cannot be zero",3000,()=>{})
                    }
                    if(parseInt(item.fteValue) > parseInt(item.dollarValue)){
                        console.log("FTE greater than dollar")
                        isValid = false;
                        Toast.fail("FTE impact value cannot be greater than dollar value",3000,()=>{})
                    }
                }
            });

            
        }

        
        if(this.state.monetizableOptionChanged && this.state.monetizableStatus.label==="Approved by Client"){
            if(this.state.FPnAOHRMonetization === null || this.state.FPnAOHRMonetization === ""){
                isValid = false;
                styleStateObj.FPnAOHRMonetization = textBoxValidation(false);
                this.setState({selectStyle: styleStateObj})
                Toast.fail("FPnAOHRMonetization empty",3000,()=>{})
            }
            else {
                styleStateObj.FPnAOHRMonetization = textBoxValidation(true);
                this.setState({selectStyle: styleStateObj})
            }
            if(this.state.SowID === "" || this.state.SowID === null || this.state.SowID === undefined || this.state.SowID.length === 0){
                isValid = false;
                styleStateObj.SowID = textBoxValidation(false);
                this.setState({selectStyle: styleStateObj})
                Toast.fail("SowID empty",3000,()=>{})
            }
            else {
                styleStateObj.SowID = textBoxValidation(true);
                this.setState({selectStyle: styleStateObj})
            }
            if(this.state.FPnANameMonetization === "" || this.state.FPnANameMonetization === null){
                isValid = false;
                styleStateObj.FPnANameMonetization = textBoxValidation(false);
                this.setState({selectStyle: styleStateObj})
                Toast.fail("FPnANameMonetization empty",3000,()=>{})

            }
            else {
                styleStateObj.FPnANameMonetization = textBoxValidation(true);
                styleStateObj.FPnANameMonetization = textBoxValidation(true);
                this.setState({selectStyle: styleStateObj})
            }
          
        }

       
        if(this.state.monetizableStatus){
            if(this.state.monetizableOptionChanged && this.state.monetizableStatus.label==="Approved by Client"){
                if (this.state.FPnAFileNameMonetization === "" || this.state.FPnAFileNameMonetization === null ) {
                    isValid = false;
                    console.log("FPnAFileNameMonetization empty")
                    Toast.fail("Please upload FP&A Sign-off document for monetization",3000,()=>{})
                }
            }
        }



        return isValid;
    }

    

    handleBIFTEChange = (impactType, monetizableType, biSubCategory, fteValue, index, event, isDollarPresent) => {
        
        // fteValue = Number((Math.floor(fteValue * 100) / 100).toFixed(2));
        fteValue = Number(Math.floor(fteValue+'e2')+'e-2');
        event.target.value = fteValue;
        if (fteValue < 0) {
            alert("You cannot set Potential Impact (# of FTE) to a negative value.");
            event.target.value = 0;
            fteValue = 0;
        }
        if (fteValue > parseInt(100) || fteValue === parseInt(100)) {
            alert("You cannot set impact (# of FTE) to more than or equal to 100. Please update accordingly.");
            event.target.value = '';
            fteValue = 0;
        }
        
        let businessImpactItem = [...this.state.businessImpactValueArray];
        if (!businessImpactItem[index]) {
            businessImpactItem[index] = {};
            businessImpactItem[index].fteValue = 0.0;
            businessImpactItem[index].dollarValue = 0.0;
            businessImpactItem[index].isFTEPresent = true;
        }
        businessImpactItem[index].fteValue = fteValue;
        console.log("array from after bi fte change function",this.state.businessImpactValueArray)
        
        if (isDollarPresent){
            if (businessImpactItem[index].dollarValue && businessImpactItem[index].dollarValue !== "") {
                if (parseInt(businessImpactItem[index].dollarValue) < parseInt(fteValue)) {
                    alert("FTE Impact cannot be more than $ Impact, please recheck.");
                }
            } 
        }

        let fteTotal = 0.0;
        businessImpactItem.map((item) => {
            if (item && item.fteValue) {
                fteTotal += parseFloat(item.fteValue);
            }
            return true;
        });
        
        if(this.state.zeroImpactForLastestBI){
            this.setState({
                dollarTotal: 0.0,
                fteTotal: 0.0,
                summaryArray:[],
                businessImpactValueArray:[]
            },()=>{})
        }
        this.setState({
            businessImpactValueArray: businessImpactItem,
            fteTotal: fteTotal,
            FinalImpactFTE: fteTotal
        },()=>{
            console.log(this.state.fteTotal)
            console.log(this.state.FinalImpactFTE)
            this.newBusinessImpactSummary();
        });
        
    }

    handleBIDollarChange = (impactType, monetizableType, biSubCategory, dollarValue, index, event, isFTEPresent) => {
        console.log(isFTEPresent)
        this.setState({
            flagBIFTE: false
        })
        // dollarValue = Number((Math.floor(dollarValue * 100) / 100).toFixed(2));
        event.target.value = dollarValue;

        if (dollarValue < 0) {
            alert("You cannot set Potential $ Impact to a negative value.");
            event.target.value = 0;
            dollarValue = 0;
        }
        if (dollarValue > 500000000) {
            alert("You cannot set Potential $ Impact to more than 500 million. Please update accordingly.");
            event.target.value = '';
            dollarValue = 0;
        }
        let businessImpactItem = [...this.state.businessImpactValueArray];
        if (!businessImpactItem[index]) {
            businessImpactItem[index] = {};
            businessImpactItem[index].DollarImpact = 0.0;
            businessImpactItem[index].FTEImpact = 0.0;
        }else{
            businessImpactItem[index].ImpactType = impactType;
            businessImpactItem[index].MonetizableType = monetizableType;
            businessImpactItem[index].BISubCategory = biSubCategory;
            businessImpactItem[index].dollarValue = dollarValue;
            businessImpactItem[index].isFTEPresent = isFTEPresent;
          
        }
        if (isFTEPresent) {
            if (businessImpactItem[index].fteValue && businessImpactItem[index].fteValue !== "") {
                if (parseInt(businessImpactItem[index].fteValue) > parseInt(dollarValue)) {
                    alert("FTE Impact cannot be more than $ Impact, please recheck.");
                }
            }
        }
        console.log(businessImpactItem)
        let dollarTotal = 0.0;
        businessImpactItem.map((item) => {
            if (item && item.dollarValue) {
                dollarTotal += parseFloat(item.dollarValue);
            }
            return true;
        });

        
        this.setState({
            businessImpactValueArray: businessImpactItem,
            dollarTotal: dollarTotal,
            FinalImpactDollar: dollarTotal
        }, () => {
            this.newBusinessImpactSummary();
        })
            
    }

    newBusinessImpactSummary() {
        let myArray = this.state.businessImpactValueArray;
        const groupedData = myArray.reduce((accumulator, currentValue) => 
        {
            const ImpactType = currentValue.ImpactType;
            const MonetizableType = currentValue.MonetizableType;
            const FTEImpact = parseFloat(currentValue.fteValue) || 0.0;
            const DollarImpact = parseFloat(currentValue.dollarValue) || 0.0;
        
            const existingRow = accumulator.find(row => row.ImpactType === ImpactType && row.MonetizableType === MonetizableType);
            if (existingRow) 
            {
                existingRow.FTEImpact += FTEImpact
                existingRow.DollarImpact += DollarImpact
            } 
            else 
            {
                accumulator.push({ ImpactType, MonetizableType, FTEImpact, DollarImpact });
            }
            return accumulator;
        }, []);
        this.setState({summaryArray: groupedData},()=>{});
        this.addMonetizableValues()
    }

    FinalApproverCheck() {
        // if(this.state.IsVDType !== "No Value Delivery"){
        //     if (this.state.dollarTotal >= this.state.biThreshold && this.state.biThreshold !== 0) {
        //         if (this.state.vrconditiontype === "AND") {
        //             if ((this.state.vrVerticalMapping.includes(this.state.ideaItem.VerticalManual) || this.state.vrVerticalMapping === "ALL" ) && (this.state.vrRegionMapping.includes(this.state.ideaItem.RegionManual) || this.state.vrRegionMapping === "ALL" )) {
        //                 Approver = AWAITING_QAULITY_CHECK_REVIEW
        //             } 
        //         } else {
        //             if ((this.state.vrVerticalMapping.includes(this.state.ideaItem.VerticalManual)|| this.state.vrVerticalMapping === "ALL" ) || (this.state.vrRegionMapping.includes(this.state.ideaItem.RegionManual) || this.state.vrRegionMapping === "ALL")) {
        //                     Approver = AWAITING_QAULITY_CHECK_REVIEW
        //                 }
        //         }
        //     }
        // }
        // .....approval status for admin starts here.........

        let ApprovalStatus;
        if (this.state.approvalSelectedOption !== null) {
            if(this.state.approvalSelectedOption.label === 'Send Back')
            {
                ApprovalStatus = 'Pending Documents';
            }
            else if(this.state.ideaItem.ApprovalStatus === "Documents Uploaded"){
                if(parseInt(this.state.FinalImpactDollar) >= parseInt(50000))
                {
                    ApprovalStatus = AWAITING_MBB_APPROVAL
                }
                if(parseInt(this.state.FinalImpactDollar) <= parseInt(50000))
                {
                    ApprovalStatus = "Completed"
                }
                if(this.state.IsVDMonetizable)
                {
                    if(this.state.IsVDMonetizable.label === "Yes"){
                        ApprovalStatus = AWAITING_MBB_APPROVAL
                    }
                }
                
            }
            
            else if(this.state.ideaItem.ApprovalStatus === "Awaiting MBB Approval")
            {
                
                if(this.state.approvalSelectedOption.label === "Completed")
                {
                    ApprovalStatus = COMPLETED_STATUS
                }
                else
                {
                    ApprovalStatus = this.state.approvalSelectedOption.label;
                }
            }
            else
            {
                ApprovalStatus = this.state.approvalSelectedOption.label;
            }
        } 
        else 
        {
            if(this.state.ideaItem.ApprovalStatus === 'NA') 
            {
                if(this.state.workFlowStatusSelectedOption !== null)
                {
                    if(this.state.workFlowStatusSelectedOption.label === 'Approve') 
                    {
                        ApprovalStatus = 'Pending Documents';
                        // if(this.state.certLevelsSelectedOption.label !== 'GB' && this.state.transSelectedOption.label !== 'Digital') 
                        // {
                        //     ApprovalStatus = 'Pending Documents';
                        // }
                        // else if(this.state.certLevelsSelectedOption.label === 'GB' || this.state.transSelectedOption.label === 'Digital') 
                        // {
                        //     ApprovalStatus = 'Awaiting Project Creation';
                        // } 
                        // else 
                        // {
                        //     ApprovalStatus = this.state.ideaItem.ApprovalStatus;
                        // }
                    }
                    else
                    {
                        ApprovalStatus = this.state.ideaItem.ApprovalStatus;
                    }
                } 
                else
                {
                    ApprovalStatus = this.state.ideaItem.ApprovalStatus;
                }
            }
            
            else
            {
                if((this.state.base64File1 !== '' || this.state.base64File2 !== '') && this.state.ideaItem.ApprovalStatus === 'Pending Documents') {
                    ApprovalStatus = 'Documents Uploaded';
                } else {
                    ApprovalStatus = this.state.ideaItem.ApprovalStatus;
                }
            }
        }

        if(this.state.ideaItem.ApprovalStatus === "Documents Uploaded" && this.state.impactOptionSelected && this.state.impactOptionSelected.label==="No Value Delivery" && this.state.approvalSelectedOption){
            if(this.state.approvalSelectedOption.label==="Send Back"){
                ApprovalStatus = 'Pending Documents';
            }else{
                ApprovalStatus = this.state.approvalSelectedOption.label;
            }
        }

        console.log("approval status before updating state",ApprovalStatus)
        this.state.approvalStatusState = ApprovalStatus;
        this.setState({approvalStatusState: ApprovalStatus},()=>{console.log("approval state",this.state.approvalStatusState)})
        // ........approval status for admin ends here.........
    }


    renderFilteredImpactType(){
        let options = [];
        if( this.state.IsVDType === "Value Delivered to Client + Genpact" ){
            this.state.filteredImpactTypeState.map((item) => {
                options.push({
                    value: item[0],
                    label: item[1]
                })
                return options;
            });
        }

        if( this.state.IsVDType === "Value Delivered to Genpact"){
            this.state.filteredImpactTypeState.slice(-1).map((item) => {
                options.push({
                    value: item[0],
                    label: item[1]
                })
                return options;
            });
        }
        if( this.state.IsVDType === "Value Delivered to Client"){
            this.state.filteredImpactTypeState.slice(0,1).map((item) => {
                options.push({
                    value: item[0],
                    label: item[1]
                })
                return options;
            });
        }
        return options;
    }
    renderFilteredMonetizableType(){
        let options = [];
        if(this.state.filteredImpactTypeSelectedOption !== null){
            this.state.filteredMonetizableTypeState.map((item) => {
                options.push({
                    value: item[0],
                    label: item[1]
                })
                return options;
            });
            return options;
        }
    }
    renderFilteredBiSubCategoryType(){
        let options = [];
        if(this.state.filteredImpactTypeSelectedOption !== null && this.state.filteredMonetizableSelectedOption){
            this.state.businessImpactMasterList.map((item,index)=>{
                if(item.pg_impacttype===this.state.filteredImpactTypeSelectedOption.label && item.pg_monetizabletype===this.state.filteredMonetizableSelectedOption.label)      
                {
                    options.push({
                        value: index,
                        label: item.pg_bisubcategory
                    })
                    return options;
                }
            })
        }
        return options;
    }

    handleImpactTypeChange=(e)=>{
        this.setState({
            filteredImpactTypeSelectedOption:{
                value: e.value,
                label: e.label
            }
        },()=>{this.setState({filteredMonetizableSelectedOption:null,filteredBISubCategorySelectedOption:null})})
    }
    handleMonetizableTypeChange(e){
        this.setState({
            filteredMonetizableSelectedOption:{
                value: e.value,
                label: e.label
            }
        },()=>{this.setState({filteredBISubCategorySelectedOption:null})})
    }
    handleBiSubCategoryChange(e){
        this.setState({
            filteredBISubCategorySelectedOption:{
                value: e.value,
                label: e.label
            }
        },()=>{})
    }

    addSelectedValues=(event)=>{
        let businessImpactValueArray = this.state.businessImpactValueArray;
        if(this.state.filteredBISubCategorySelectedOption.label !== "Category Not Listed"){
            if( this.state.filteredImpactTypeSelectedOption !== null && 
                this.state.filteredMonetizableSelectedOption !== null && 
                this.state.filteredBISubCategorySelectedOption !== null )
            {
                this.state.businessImpactMasterList.map((item)=>{
                    if(item.pg_impacttype === this.state.filteredImpactTypeSelectedOption.label && item.pg_monetizabletype === this.state.filteredMonetizableSelectedOption.label && item.pg_bisubcategory === this.state.filteredBISubCategorySelectedOption.label){
                        let obj = {
                            ImpactType:this.state.filteredImpactTypeSelectedOption.label,
                            MonetizableType:this.state.filteredMonetizableSelectedOption.label,
                            BISubCategory:this.state.filteredBISubCategorySelectedOption.label,
                            pg_srno:item.pg_srno,                  
                        }
                        let isDuplicate = businessImpactValueArray.find(item => item.pg_srno === obj.pg_srno);
                        if (!isDuplicate) {
                            businessImpactValueArray.push(obj);
                            businessImpactValueArray =   businessImpactValueArray.sort((a, b) => a.ImpactType.toLowerCase().localeCompare(b.ImpactType.toLowerCase()))
                            this.setState({businessImpactValueArray:businessImpactValueArray},()=>{console.log("from add selected values",this.state.businessImpactValueArray)})
                        }else{
                            window.alert("Same combination of Impact type, Category and Sub category cannot be added again")
                        }
                    }
                    if( this.state.filteredImpactTypeSelectedOption.label==="Impact at Genpact"  && (this.state.filteredMonetizableSelectedOption.label === "Increase in Revenue" || this.state.filteredMonetizableSelectedOption.label === "Reduction in Cost")){
                        this.setState({appropriateAttachment:true},()=>{})
                    }
                })
            }
        }else{
            this.setState({
                filteredImpactTypeSelectedOption:null,
                filteredMonetizableSelectedOption:null,
                filteredBISubCategorySelectedOption:null,
                showHrefModal:true,
                setGreyOutButton:true
            },()=>{})
        }
        this.setState({
            filteredImpactTypeSelectedOption:null,
            filteredMonetizableSelectedOption:null,
            filteredBISubCategorySelectedOption:null,
        },()=>{})
    }

    removeBiFormRow = (index) => {
        let filteredIdeaArray = this.state.businessImpactValueArray.filter((value, filterindex, arr) => {
            return filterindex !== index;
        });
        this.setState({businessImpactValueArray: filteredIdeaArray},()=>{this.newBusinessImpactSummary()})

        this.state.businessImpactValueArray.map((item)=>{
            if(item.MonetizableType !== "Increase in Revenue" || item.MonetizableType !== "Reduction in Cost"){
                this.setState({appropriateAttachment:false},()=>{})
            }
        })
        console.log(this.state.businessImpactValueArray)
        let dollarTotal=0.0;
        let fteTotal = 0.0;
        filteredIdeaArray.map(item=>{
            if(item && item.FTEImpact){
                fteTotal += parseFloat(item.FTEImpact)
            }
            if(item && item.DollarImpact){
                dollarTotal += parseFloat(item.DollarImpact)
            }
        })
        this.setState({
            fteTotal: fteTotal,
            FinalImpactFTE: fteTotal,
            dollarTotal: dollarTotal,
            FinalImpactDollar: dollarTotal
        },()=>{
            console.log(this.state.FinalImpactFTE)
            console.log(this.state.FinalImpactDollar)
        })
    }



    updateIdeaDocuments=()=>{
        let param = {
            IdeaId: this.props.location.state,
            ModifiedByOHR: this.sessionData.profile.oHRId,
            Role:"Admin",


            ProjectPPTDeck: this.state.base64File1,
            ProjectPPTFilename: this.state.projectDeckFilename ? this.state.projectDeckFilename : this.state.ideaItem.ProjectPPTFileName,
            ProjectPPTType: this.state.fileType1 ? this.state.fileType1 :  this.state.ideaItem.ProjectPPTType,
            
            CustomerLeadSignOff: this.state.signOffContent,
            CustomerSignoffFilename: this.state.signOffFilename ? this.state.signOffFilename : this.state.ideaItem.CustomerSignoffFileName,
            CustomerSignoffType: this.state.signOffFileType ? this.state.signOffFileType : this.state.ideaItem.CustomerSignoffType,
            
            golApproval:this.state.golContent,
            golApprovalFileName:this.state.golFilename ? this.state.golFilename : this.state.ideaItem.GOLFileName,
            golApprovalType:this.state.golFileType ? this.state.golFileType : this.state.ideaItem.GOLFileType,
            
            fpnaApproval:this.state.fpnaContent,
            fpnaApprovalFileName:this.state.fpnaFilename ? this.state.fpnaFilename : this.state.ideaItem.FPnAFileName,
            fpnaApprovalType:this.state.fpnaFileType ? this.state.fpnaFileType : this.state.ideaItem.FPnAFileType,

            FPnAFileNameMonetization:this.state.FPnAFileNameMonetization ? this.state.FPnAFileNameMonetization : "",
            FPnAFileTypeMonetization:this.state.FPnAFileTypeMonetization ? this.state.FPnAFileTypeMonetization: "",
            FPnAFileMonetization:this.state.FPnAFileMonetization ? this.state.FPnAFileMonetization : "",
        }
        console.log(param)
        let finalJsonData = Checksum(param);
        let str1 = finalJsonData.slice(0, 15);
        let str2 = finalJsonData.slice(15, 30);
        let str3 = finalJsonData.slice(30, 40);
        let str4 = finalJsonData.slice(-10);
        let finalData = str3+str1+str4+str2;
           
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(`${UPDATE_IDEA_URL}Checksum=${finalData}&monetizationdocumentsonly=true`, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log("upload document succesfull",data)
        });
    }


    UpdateIdea = async () => {
        if (this.validate()) {
            this.updateIdeaDocuments();
            Toast.loading("Updating Idea...", () => { });
            let filteredBIData = this.state.businessImpactValueArray.filter(function (e) {
                return (e !== null && e !== undefined && parseFloat(e.dollarValue) > 0 &&
                    ((e.isFTEPresent && parseFloat(e.fteValue) > 0) || (!e.isFTEPresent)));
            });
            console.log(filteredBIData)
            let param = {
                IdeaId: this.props.location.state,
                OwnerEmail: this.state.ideaItem.OwnerEmail,
                OwnerName: this.state.ideaItem.OwnerName,
                OwnerOHR: this.state.ideaItem.OwnerOHR,
                CreatorEmail: this.state.ideaItem.CreatorEmail,
                CreatorName: this.state.ideaItem.CreatorName,
                CreatorOHR: this.state.ideaItem.CreatorOHR,
                AccountId: this.state.accountId,
                AccountManual: this.state.accountName,
                HoldingAccount: this.state.holdingAccount,
                VerticalId: Number(this.state.verticalId),
                VerticalManual: this.state.verticalManual,
                VerticalManualId: Number(this.state.verticalManualId),
                SubVerticalManual: this.state.subVerticalManual,
                CampaignTypeId: Number(this.state.campaignTypeId),
                CampaignName: this.state.campaignTypeText,
                DataBridgeTheme: this.state.dataBridgeProjectTheme,
                SubCampaignName: this.state.subCampaignName ?  this.state.subCampaignName : this.state.ideaItem.SubCampaignName,
                Country: this.state.country,
                RegionId: Number(this.state.regionId),
                RegionManual: this.state.regionManual,
                RegionManualId: Number(this.state.regionManualId),
                SubRegion: this.state.subRegion,
                BlackBeltUserId: Number(this.state.blackBeltUserId),
                BlackBeltUserName: this.state.blackBeltUserName,
                BlackBeltUserEmail: this.state.blackBeltUserEmail,
                MBBOHR: Number(this.state.mbbOHR) ? Number(this.state.mbbOHR) : this.state.ideaItem.MBBOHR,
                MBBName: this.state.mbbName,
                IdeaName: this.state.ideaItem.IdeaName,
                ServiceLineId: 111,
                ServiceLineName: this.state.serviceLineSelectedOption !== null ? this.state.serviceLineSelectedOption.label : null,
                SubServiceLine: this.state.subServiceLineSelectedOption !== null ? this.state.subServiceLineSelectedOption.label : null,
                Problem: this.state.ideaItem.Problem,
                Solution: this.state.ideaItem.Solution,
                ReplicationProjectId: this.state.ideaItem.ReplicationProjectId,
                NewOrReplicatedIdea: this.state.ideaItem.NewOrReplicatedIdea,
                IsReplicationVerified: false,
                LDTId: this.state.ideaItem.LDTId,
                LDTName: this.state.ideaItem.LDTLeadName,
                CertificationLevelId: (this.state.showAdditionalDetails && this.state.certLevelsSelectedOption !== null) ? this.state.certLevelsSelectedOption.value : this.state.ideaItem.CertificationLevelId,
                CertificationLevelName: (this.state.showAdditionalDetails && this.state.certLevelsSelectedOption !== null) ? this.state.certLevelsSelectedOption.label : this.state.ideaItem.CertificationLevelName,
                CertificationWorkTypeName: (this.state.showAdditionalDetails && this.state.certWorkTypeSelectedOption !== null) ? this.state.certWorkTypeSelectedOption.value : this.state.ideaItem.CertificationWorkTypeName,
                TransformationLeverId: (this.state.showAdditionalDetails && this.state.transSelectedOption !== null) ? this.state.transSelectedOption.value : (this.state.transSelectedOption !== null ? this.state.transSelectedOption.value : this.state.ideaItem.TransformationLeverId),
                TransformationLeverName: (this.state.showAdditionalDetails && this.state.transSelectedOption !== null) ? this.state.transSelectedOption.label : (this.state.transSelectedOption !== null ? this.state.transSelectedOption.label : this.state.ideaItem.TransformationLeverName),
                // DigitalProjectType: (this.state.showAdditionalDetails && this.state.digitalProjectTypeSelectedOptions !== null) ? this.state.digitalProjectTypeSelectedOptions.label : this.state.ideaItem.DigitalProjectType,
                
                DigitalProjectType: this.state.digitalSelected ? this.state.digitalSelected.label : this.state.ideaItem.DigitalProjectType,

                MethodologyTypeID: this.state.methodologySelectedOptions !== null ? this.state.methodologySelectedOptions.value : null,
                MethodologyName: this.state.methodologySelectedOptions !== null ? this.state.methodologySelectedOptions.label : null,
                IsZeroImpactProject: this.state.ideaItem.IsZeroImpactProject,
                IsSharedToSixSigma: this.state.ideaItem.IsSharedToSixSigma,
                IsBestInClass: this.state.ideaItem.IsBestInClass,
                ReplicabilityIndex: this.state.replicaIndex ? this.state.replicaIndex.label : "",
                FinalOtherTools: this.state.otherTools,
                UpdaterOHR: this.sessionData.profile.oHRId,
                UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                UpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                WorkflowStatusId: this.state.workFlowStatusSelectedOption !== null ? this.state.workFlowStatusSelectedOption.value : null,
                WorkflowStatus: this.state.workFlowStatusSelectedOption !== null ? this.state.workFlowStatusSelectedOption.label : this.state.ideaItem.WorkflowStatus,
                ApprovalStatus: this.state.approvalStatusState,
               
                BusinessImpactData: filteredBIData,
                FinalImpactDollar: this.state.FinalImpactDollar,
                FinalImpactFTE: this.state.FinalImpactFTE,
                SaveType: "Update",
                Comment: this.state.ideaItem.Comment,
                FinalComments: this.state.ideaItem.FinalComments,
                LogComments: this.state.comment !== '' ? this.state.comment : (this.state.finalComments !== ''? this.state.finalComments : null),
                Role:"Admin",
                CompletedDate: new Date().toLocaleString(),
                VDType:this.state.impactOptionSelected ? this.state.impactOptionSelected.label : this.state.ideaItem.VDType,

                //IsVDReviewed: this.state.showCheckBox ? "Yes" : "No",
                IsVDReviewed: this.state.bbReviewCheckbox ? this.state.bbReviewCheckbox.label : this.state.ideaItem.IsVDReviewed,
                FpnaApprovalAttachedSelectedOption:this.state.bbReview ? this.state.bbReview.label : this.state.ideaItem.IsFPnAApprovalAttached,
                // FpnaApprovalAttachedSelectedOption:this.state.appropriateAttachmentValue.label,
                // FpnaApprovalAttachedSelectedOption:this.state.ideaItem.IsFPnAApprovalAttached,
                

                ClientName:this.state.clientName,
                ClientRole:this.state.clientRole,
                ClientSignOffDate:this.state.clientSignOffDate,
                GolOhr:this.state.golFullname ? this.state.golOhr : "",
                GolFullname:this.state.golFullname,
                FpnaOhr:this.state.fpnaName ? this.state.fpnaOhr : "",
                FpnaName:this.state.fpnaName,


                FPnAOHRMonetization:this.state.FPnAOHRMonetization ? this.state.FPnAOHRMonetization :"",
                FPnANameMonetization:this.state.FPnANameMonetization ? this.state.FPnANameMonetization :this.state.FPnANameMonetization,
                SowID:this.state.Sow ? this.state.Sow :this.state.SowID,
                IsVDMonetizable:this.state.IsVDMonetizable ? this.state.IsVDMonetizable.label : "No",
                MonetizationStatus:this.state.monetizableStatus ? this.state.monetizableStatus.label : "",
                IsMbbMonetizable: this.state.ideaItem.IsMbbMonetizable ? this.state.ideaItem.IsMbbMonetizable : "",


                ImpactTyepChanged:this.state.impactTagChangeSelected ? this.state.impactTagChangeSelected.label : "",
                DeliveryCategorizedSelect:this.state.deliveryCategorizedSelect ? this.state.deliveryCategorizedSelect.label : "",
                DeliveryComputedCorrectlySelected:this.state.deliveryComputedCorrectlySelected ? this.state.deliveryComputedCorrectlySelected.label : "",
                SignOffDeliverySelected: this.state.signOffDeliverySelected ? this.state.signOffDeliverySelected.label : null,
                PotentialImpactDollar: this.state.potentialDollar,
                updaterAction: this.state.updaterActionState

            }
            console.log("Param", param);
            // return;
            let finalJsonData = Checksum(param);
            let str1 = finalJsonData.slice(0, 15);
            let str2 = finalJsonData.slice(15, 30);
            let str3 = finalJsonData.slice(30, 40);
            let str4 = finalJsonData.slice(-10);
            let finalData = str3+str1+str4+str2;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };
            fetch(`${SUBMIT_IDEA_URL}Checksum=${finalData}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    Toast.hide();
                    Toast.success('Updated successfully.', 3000, () => {
                        console.log(JSON.stringify(data));
                        window.location = "/view-all-ideas-page";
                    });
    
                });
        }
        else {
            if(!this.isExceedlimit){
                Toast.fail('Some of the required fields for Idea are not set. Please set and resubmit.', 3000, () => { });
            }
        }
        return false;
    }


    // function to fetch gol file data
    fetchGolOffContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let golFileType = this.state.ideaItem.GOLFileType;
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&golcontent=true`, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                const golMetadata = data.golContent ? data.golContent : "";
                const golContent = "data:" + golFileType + ";base64," + golMetadata;
                this.setState({
                    golContent: golContent,
                }, () => {
                    resolve();
                    Toast.hide()
                });

            })
            .catch(error => {
                Toast.fail("Error while fetching GOL Approval content. Please try again!", 3000, () => { });
                resolve();
            });
        });
    }
    showGolOffContent = () => {
        Toast.loading("Fetching GOL Approval content...", () => { });
        this.fetchGolOffContent()
            .then(() => {
                var fileData = this.state.golContent;
                var fileName = this.state.ideaItem.GOLFileName;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }


    // function to fetch fpna file data
    fetchFpnaContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let fpnaFileType = this.state.ideaItem.FPnAFileType;
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&fpnacontent=true`, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                const golMetadata = data.fpnaContent ? data.fpnaContent : "";
                const golContent = "data:" + fpnaFileType + ";base64," + golMetadata;
                this.setState({
                    fpnaContent: golContent,
                }, () => {
                    resolve();
                    Toast.hide()
                });

            })
            .catch(error => {
                Toast.fail("Error while fetching FP&A Approval content. Please try again!", 3000, () => { });
                resolve();
            });
        });
    }
    showFpnaContent = () => {
        Toast.loading("Fetching FP&A Approval content...", () => { });
        this.fetchFpnaContent()
            .then(() => {
                var fileData = this.state.fpnaContent;
                var fileName = this.state.ideaItem.FPnAFileName;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }


    showBBFields=(e)=>{
        this.setState({bbFieldValidationState:e,checkBoxFalseSelected:false},()=>{
            let styleStateObj = this.state.selectStyle;
            styleStateObj.showBBFieldsValidation = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        })
        if(e !== null && e!== undefined && e.label==="Yes"){
            this.setState({showBBFieldsValue:e},()=>{
                this.setState({showCheckBox:true,approvalSelectedOption:""},()=>{})
            })
        }
        if(e.label==="No"){
            this.setState({
                showFinalAdditionalDetails: false,
                finalImpactDollar:0,
                finalImpactFTE:0,
                fteTotal: 0,
                dollarTotal: 0.0,
                zeroImpact: false,
                bestClass: false,
                summaryArray: [],
                otherTools:null,
                replicaIndex:0,
                showBIForm: true,
                businessImpactValueArray: [],
                BiArray:[],
                showCheckBox:false,
                approvalSelectedOption:null
            });
        }
        
        

    }
    handleCheckBoxChange=(e)=>{
        if(e.target.checked ){
            this.setState({checkBoxFalseSelected:true},()=>{})
        }else{
            this.setState({checkBoxFalseSelected:false},()=>{})
        }
    }
    fpnaAttachmentChange = (e)=>{
        if(e !== null && e !== undefined){
            this.setState({appropriateAttachmentValue:e},()=>{console.log(this.state.appropriateAttachmentValue.label)})
        }
    }

    checkIsFtePresent(item) {
        const { businessImpactMasterList } = this.state;
        for (const pg_item of businessImpactMasterList) {
            if (
                item.ImpactType === pg_item.pg_impacttype &&
                item.MonetizableType === pg_item.pg_monetizabletype &&
                item.BISubCategory === pg_item.pg_bisubcategory &&
                pg_item.pg_isfteimpact === "Yes" 
                // item.pg_srno === pg_item.pg_srno
            ) {
                
                return true; // FTE impact is present
            }
        }
       
        return false; // FTE impact is not present
    }

    renderClientApproval=()=>{
        let options = [];
        this.state.clientApprovalOptions.map((item) => {
            options.push({
                value: item.value,
                label: item.label
            })
            return options;
        });
        return options;

    }
    handleMonetizableProjectChange=(e)=>{
        console.log("monetizable status before",this.state.monetizableStatus)
        this.setState({IsVDMonetizable:e},()=>{
            if(this.state.IsVDMonetizable.label === "Yes"){
                this.setState({monetizableStatus:true},()=>{})
            }
            if(this.state.IsVDMonetizable.label === "No"){
                this.setState({monetizableStatus:false,showClientApprovalFields:false
                    ,FPnAOHRMonetization:"",FPnANameMonetization:"",SowID:"",FPnAFileNameMonetization:"", 
                    totalMonetizedDollar:0.0,totalMonetizedFTE:0.0
                },()=>{})
                this.state.businessImpactValueArray =  this.state.businessImpactValueArray.map(item => ({
                    ...item,
                    IsMonetized: 'No',
                    })).map(item => {
                        return item;
                    
                });
            }
            
        })
        console.log("monetizable status after",this.state.monetizableStatus)

    }

    handleMonetizableCheckboxChange=(impactType,pg_srno, monetizableType, biSubCategory, dollarValue, index, event, isFTEPresent)=>{
        if(event.target.checked){
            this.setState({monetizableCheckbox:true},()=>{
                if(this.state.monetizableCheckbox){
                    let businessImpactItem = [...this.state.businessImpactValueArray];
                    businessImpactItem = businessImpactItem.map(item => {
                        if (item.pg_srno === pg_srno) {
                            item.IsMonetized = "Yes";
                        }
                        return item;
                    });
                    this.setState({businessImpactValueArray:businessImpactItem},()=>{})
                    this.addMonetizableValues()
                }
                
            })
        }
        else{
            this.setState({monetizableCheckbox:false},()=>{
                if(this.state.monetizableCheckbox === false){
                    let businessImpactItem = [...this.state.businessImpactValueArray];
                    businessImpactItem = businessImpactItem.map(item => {
                        if (item.pg_srno === pg_srno) {
                            item.IsMonetized = "No";
                        }
                        return item;
                    });
                    this.setState({businessImpactValueArray:businessImpactItem},()=>{})
                    this.addMonetizableValues()
                }
            })
        }
    }

    handleSowIdChange=(e)=>{
        let styleStateObj = this.state.selectStyle;
        styleStateObj.SowID = textBoxValidation(true);
        this.setState({SowID:e}) 
    }

    renderMonetizableOptions=()=>{
        let options = [];
        this.state.postMonetizableOptions.map((item) => {
            options.push({
                value: item.value,
                label: item.label
            })
            return options;
        });
        return options;
    }
    handleMonetizableOptionsChange=(e)=>{
        this.state.businessImpactValueArray =  this.state.businessImpactValueArray.map(item => ({
            ...item,
            IsMonetized: 'No',
            })).map(item => {
                return item;
            
        });
        this.setState({FPnANameMonetization:"",monetizableOptionChanged:true,FPnAFileNameMonetization: "", FPnAFileTypeMonetization: "", FPnAFileMonetization: "",SowID:""})
        let options={
            value:e.value,
            label:e.label
        }
        this.setState({monetizableStatus:options},()=>{
            console.log(this.state.monetizableStatus)
            if(this.state.monetizableStatus.label==="Approved by Client"){
                this.setState({showClientApprovalFields:true})
            }else{
                this.setState({showClientApprovalFields:false,monetizableCheckbox:false,MonetizationState:"Active"},()=>{
                    if(this.state.showClientApprovalFields === false){
                        this.setState({FPnAOHRMonetization:"",FPnANameMonetization:"",SowID:"",FPnAFileNameMonetization:"",totalMonetizedDollar:0.0,totalMonetizedFTE:0.0})
                    }
                })
            }
        })
       
    }


    myFIleChange = (event, fileType) => {
        var file = event.target.files[0];
        var currFilename = file.name;
        var currFilesize = file.size;
       
        if(currFilesize > 5000000){
            alert("The size of the document you are uploading, is more than 5MB. Please upload a smaller document.");
            return;            
        }
        
        var validExtensions = ['.xls', '.xlsx', '.xlsm', '.csv', '.doc', '.docx', '.pdf', '.ppt', '.pptx', '.pdf', '.jpeg', '.jpg', '.png', '.msg', '.png', '.mpp'];
        var currFileExtension = currFilename.substring(currFilename.lastIndexOf("."));
        var isFileTypeValid = validExtensions.filter((val) => val.toLowerCase().includes(currFileExtension.toLowerCase()));
        if (isFileTypeValid.length <= 0) {
            alert("The type of document you are uploading is not supported. Please upload only supported file types.");
            return;
        }
        
        
        if(fileType === "projectPresentationDeck"){
            if(currFilesize > 10000000){
                alert("The size of the document you are uploading, is more than 10MB. Please upload a smaller document.");
                return;  
            }
        }else{
            if(currFilesize > 5000000){
                alert("The size of the document you are uploading, is more than 5MB. Please upload a smaller document.");
                return;            
            }
        }
        if(fileType === "golfilename" || fileType === "fpnaFileName"){
            if(currFilesize > 2621440){
                alert("The size of the document you are uploading, is more than 2.5MB. Please upload a smaller document.");
                return;            
            }
            this.setState({fpnaUpload:true},()=>{})
        }
        if(fileType === "fileForMonetization" ){
            if(currFilesize > 5000000){
                alert("The size of the document you are uploading, is more than 5MB. Please upload a smaller document.");
                return;            
            }
            this.setState({fpnaUpload:true},()=>{})
        }


        if (fileType === "projectPresentationDeck") {
            if (currFilename === this.state.signOffFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
        } else {
            if (currFilename === this.state.projectDeckFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
        }
        // validation for 2nd file
        if (fileType === "signofffilename") {
            if (currFilename === this.state.signOffFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
        } 
        else 
        {
            if (currFilename === this.state.projectDeckFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === this.state.golFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === this.state.fpnaFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === this.state.FPnAFileNameMonetization) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
        }


        // validation for 3rd file
        if (fileType === "golfilename") {
            if (currFilename === this.state.golFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
        } 
        else 
        {
            if (currFilename === this.state.projectDeckFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === this.state.signOffFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === this.state.fpnaFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === this.state.FPnAFileNameMonetization) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
        }
        // validation for 4th file
        if (fileType === "fpnaFileName") {
            if (currFilename === this.state.fpnaFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
        } 
        else 
        {
            if (currFilename === this.state.projectDeckFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === this.state.signOffFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === this.state.golFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === this.state.FPnAFileNameMonetization) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
        }

        // validation for 5th file
        if (fileType === "fileForMonetization") {
            if (currFilename === this.state.FPnAFileNameMonetization) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
        } 
        else 
        {
            if (currFilename === this.state.projectDeckFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === this.state.signOffFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === this.state.golFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === this.state.fpnaFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
        }
        var reader = new FileReader();
        reader.readAsBinaryString(file);
        let self = this;
        reader.onload = function () {
            let fileContents = btoa(reader.result);

            if (fileType === "projectPresentationDeck") {
                self.setState({ projectDeckFilename: file.name, fileType1: file.type, projectDeckContent: fileContents },()=>{})
            }
            if (fileType === "signofffilename") {
                self.setState({ signOffFilename: file.name, signOffFileType: file.type, signOffContent: fileContents },()=>{})
            }
            if (fileType === "golfilename") {
                self.setState({ golFilename: file.name, golFileType: file.type, golContent: fileContents },()=>{
                })
            }
            if (fileType === "fpnaFileName") {
                self.setState({ fpnaFilename: file.name, fpnaFileType: file.type, fpnaContent: fileContents },()=>{
                })
            }
            if(fileType === "fileForMonetization"){
                self.setState({ FPnAFileNameMonetization: file.name, FPnAFileTypeMonetization: file.type, FPnAFileMonetization: fileContents },()=>{})
            }
        };
        reader.onerror = function () {
            console.log('There are some problems loading document contents.');
        };
    };



    handleClientNameChange=(e)=>{
        if(e !== null && e !== undefined){
            this.setState({clientName:e},()=>{
                let styleStateObj = this.state.selectStyle;
                styleStateObj.clientName = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj
            });
            })
        }
    }
    handleClientRoleChange=(e)=>{
        if(e !== null && e !== undefined){
            this.setState({clientRole:e},()=>{console.log(this.state.clientRole)})
            let styleStateObj = this.state.selectStyle;
            styleStateObj.clientRole = textBoxValidation(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }
    }
    handleClientSignOffDateChange=(e)=>{
        if(e !== null && e !== undefined){
            if(new Date(e) > new Date()){
                window.alert("Sign off date cannot be greater than current date")
                this.setState({clientSignOffDate:""},()=>{})
            }else{
                this.setState({clientSignOffDate:e},()=>{})
                let styleStateObj = this.state.selectStyle;
                styleStateObj.clientSignOffDate = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            
        }
    }

    // auto fetch gol full name starts here 

    handleGOLOhronCut=(event)=>{
        if(event.target.value.length === 9) 
        {
            this.handleGOLOhrChange()
        }else if(event.target.value.length === 0){
            this.setState({ golFullname: "",golOhr:"" },()=>{})
            return null
        }
        else
        {
            Toast.fail("GOL OHR should be exactly of 9 digits",3000,()=>{})
            this.setState({ golFullname: "" })
        }
    }

    handleGOLOhrChange=(event)=>{
        var numbers = /^[0-9]+$/;
        if(event !== null && event !== undefined){
            if(event.target.value.match(numbers) && event.target.value.length !== 0){
                if(event.target.value.length === 9){
                    this.setState({
                        golOhr: event.target.value.trim(),
                    },()=>{
                        console.log(this.state.golOhr)
                    });
                    this.handleSubmitGolOHRChange()
                }
                else{
                    this.setState({golFullname:""},()=>{console.log(this.state.golFullname)})
                }
            }
        }
    }
    
    handleSubmitGolOHRChange = () => {
        this.setState({
            golEmail: this.state.golOhr + "@genpact.com"
        }, () => {
            this.fetchSubmitGolOHRDetails();
        });
    }
    fetchSubmitGolOHRDetails = () => {
        Toast.loading("Fetching GOL Full Name Details. Please wait...", () => { });
        const param = {
            EntityType: "SearchOHR",
            EntityName: this.state.golOhr
        }
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log(data)
            let requestByName = data.oUser ? data.oUser[0].DisplayName : null
            if(requestByName === ""){
                requestByName = data.PowerAppUsers ? (data.PowerAppUsers.length > 0 ? data.PowerAppUsers[0].pg_username : "") : "";
            }
            Toast.hide();
            if (requestByName === "") {
                Toast.fail("We could not find GOL full name details based on the entered OHR. Please check and reenter.", 5000, () => { });
                this.setState({
                    golFullname: "",
                });
            }else{
                this.setState({golFullname: requestByName });
            }
        })
        .catch(error => 
        {
            Toast.hide();
            Toast.fail('Please enter a valid GOL OHR', 5000, () => {
                console.log(JSON.stringify(error));
            });
            this.setState({golFullname: "",golOhr:null},()=>{
                
            });
        });
    }
    // auto fetch gol full name ends here 


    // auto fetch fpna full name starts here 
    
    handleFpnaOhrChange=(event)=>{
        this.setState({ fpnaOhr: event.target.value.trim() }, () => {});
        var numbers = /^[0-9]+$/;
        if(event !== null && event !== undefined){
            if(event.target.value.match(numbers) && event.target.value.length !== 0){
                if (event.target.value.length === 9) {
                    this.handleSubmitFpnaOHRChange()
                }
                else{this.setState({fpnaName:""})}
            }
        }
    }
    handleFpnaOhronCut=(event)=>{
        this.setState({ fpnaOhr: event.value }, () => { console.log(this.state.fpnaOhr) });
        if (event.value === undefined) {
            this.setState({ fpnaOhr: "",fpnaName: ""  })
        } else
        if ( event.value.length === 9) { this.handleFpnaOhrChange() } else {
            this.setState({ fpnaName: "" })
        }
    }
    
    handleSubmitFpnaOHRChange = () => {
        this.setState({
            fpnaEmail: this.state.fpnaOhr + "@genpact.com"
        }, () => {
            this.fetchSubmitFpnaOHRDetails();
        });
    }
    fetchSubmitFpnaOHRDetails = () => {
        Toast.loading("Fetching FP&A Full Name Details. Please wait...", () => { });
        const param = {
            EntityType: "SearchOHR",
            EntityName: this.state.fpnaOhr
        }
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                let requestByName = data.oUser ? data.oUser[0].DisplayName : null
                if(requestByName === ""){
                    requestByName = data.PowerAppUsers ? (data.PowerAppUsers.length > 0 ? data.PowerAppUsers[0].pg_username : "") : "";
                }
                Toast.hide();
                if (requestByName === "") {
                    Toast.fail("We could not find FP&A full name details based on the entered OHR. Please check and reenter.", 5000, () => { });
                    this.setState({ fpnaName: "", fpnaOhr: "" }, () => {
                        console.log(this.state.fpnaOhr)
                    });
                }else{
                    this.setState({fpnaName: requestByName,},()=>{
                        console.log(this.state.fpnaName)
                    });
                }
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Please enter a valid FP&A OHR', 5000, () => { });
                this.setState({fpnaName:""},()=>{console.log(this.state.fpnaName)})
                let styleStateObj = this.state.selectStyle;
                styleStateObj.fpnaName = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            });
    }
    // auto fetch fpna full name ends here 


    
    // function to fetch fpna file for monetization
    showMonetizationContent = () => {
        Toast.loading("Fetching FP&A Sign-off for monetization content...", () => { });
        this.fetchMonetizationContent()
            .then(() => {
                var fileData = this.state.monetizationFileContent;
                var fileName = this.state.ideaItem.FPnAFileNameMonetization;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }
    fetchMonetizationContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let signoffFileType = this.state.ideaItem.FPnAFileTypeMonetization;
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&fpnacontent=true&fpnamonetizationcontent=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    const signoffMetadata = data.fpnaContent ? data.fpnaContent : "";
                    const signoffContent = "data:" + signoffFileType + ";base64," + signoffMetadata;
                    this.setState({
                        monetizationFileContent: signoffContent,
                    }, () => {
                        console.log(this.state.monetizationFileContent)
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching FP&A Sign-off for monetization content. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }


     // auto fetch fpna full name for monetization starts here 
    
     handleFpnaOHRMonetizationChange=(e)=>{
        let styleStateObj = this.state.selectStyle;
        styleStateObj.FPnANameMonetization = textBoxValidation(true);
        styleStateObj.FPnAOHRMonetization = textBoxValidation(true);
        this.setState({ FPnAOHRMonetization: e}, () => {});
        var numbers = /^[0-9]+$/;
        if(e !== null && e !== undefined){
            if(e.match(numbers) && e.length !== 0){
                if (e.length === 9) {
                    this.handleSubmitFpnaOHRMonetizationChange()
                }
                else{this.setState({FPnANameMonetization:"",FPnANameMonetization: ""})}
            }
        }
    }
    handleFpnaOhrMonetizationonCut=(event)=>{
        this.setState({ FPnAOHRMonetization: event.value }, () => { console.log(this.state.FPnAOHRMonetization) });
        if (event.value === undefined) {
            this.setState({ FPnAOHRMonetization: "",FPnANameMonetization: ""  })
        } else
        if ( event.value.length === 9) { this.handleFpnaOHRMonetizationChange() } else {
            this.setState({ FPnANameMonetization: "" })
        }
    }

    
    handleSubmitFpnaOHRMonetizationChange = () => {
        this.setState({
            FPNAOHRMonetization: this.state.FPnAOHRMonetization + "@genpact.com"
        }, () => {
            this.fetchSubmitFpnaOHRMonetizationDetails();
        });
    }
    fetchSubmitFpnaOHRMonetizationDetails = () => {
        Toast.loading("Fetching FP&A Full Name Details for monetization. Please wait...", () => { });
        const param = {
            EntityType: "SearchOHR",
            EntityName: this.state.FPnAOHRMonetization
        }
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                let requestByName = data.oUser ? data.oUser[0].DisplayName : null
                if(requestByName === ""){
                    requestByName = data.PowerAppUsers ? (data.PowerAppUsers.length > 0 ? data.PowerAppUsers[0].pg_username : "") : "";
                }
                Toast.hide();
                if (requestByName === "") {
                    Toast.fail("We could not find FP&A full name details based on the entered OHR. Please check and reenter.", 5000, () => { });
                    this.setState({ FPnANameMonetization: "", FPnAOHRMonetization: "" }, () => {
                        console.log(this.state.FPnAOHRMonetization)
                    });
                }else{
                    this.setState({FPnANameMonetization: requestByName,},()=>{
                        console.log(this.state.FPnANameMonetization)
                    });
                }
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Please enter a valid FP&A OHR for Monetization', 5000, () => { });
                this.setState({FPnANameMonetization:""},()=>{console.log(this.state.FPnANameMonetization)})
                let styleStateObj = this.state.selectStyle;
                styleStateObj.FPnANameMonetization = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            });
    }
    // auto fetch fpna full name for monetization ends here 

    addMonetizableValues=(e)=>{
        let MonetizedBiArray =[];
        let fteValue = 0;
        let dollarValue = 0;
        this.state.businessImpactValueArray.filter((item)=>{
            if(item.IsMonetized==="Yes" ){
                MonetizedBiArray.push(item)
            }
        })
        console.log(MonetizedBiArray)
        if(MonetizedBiArray){
            const groupedData = MonetizedBiArray.reduce((accumulator, currentValue) => 
            {
                const ImpactType = currentValue.ImpactType;
                const fteValue = parseFloat(currentValue.fteValue) || 0.0;
                const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;
                const existingRow = accumulator.find(row => row.ImpactType === ImpactType );
                if (existingRow) 
                {
                    existingRow.fteValue += fteValue
                    existingRow.dollarValue += dollarValue
                } 
                else 
                {
                    accumulator.push({ ImpactType, fteValue, dollarValue });
                }
                return accumulator;
            }, []);
            if(groupedData.length === 0) {
                fteValue = 0
                dollarValue = 0
            } else{
                fteValue = groupedData[0].fteValue
                dollarValue = groupedData[0].dollarValue
            }
            this.setState({
                totalMonetizedFTE: fteValue,
                totalMonetizedDollar:dollarValue
            },()=>{})
        }
    }


    handleImpactTagChange=(e)=>{
        if(e !== null && e !== undefined){
            this.setState({impactTagChangeSelected:e},()=>{})
        }
    }
    handleDeliveryCategorizedChange=(e)=>{
        if(e !== null && e !== undefined){
            this.setState({deliveryCategorizedSelect:e},()=>{})
        }
    }
    handledeliveryComputedCorrectlySelectedChange=(e)=>{
        if(e !== null && e !== undefined){
            this.setState({deliveryComputedCorrectlySelected:e},()=>{})
        }
    }
    handleSignOffDeliveryChange=(e)=>{
        if(e !== null && e !== undefined){
            this.setState({signOffDeliverySelected:e},()=>{})
        }
    }

    showCommentModal=(modalId)=>{
        if(modalId === 3){
            this.setState({ qcCommentLog: !this.state.qcCommentLog })
        }
        
    }

    renderImpactOptions=()=>{
        let options = [];
        this.state.impactOptions.map((item) => {
            options.push({
                value: item.value,
                label: item.label
            })
            return options;
        });
        return options;
    }

    handleImpactChange=(e)=>{
        this.setState({impactOptionSelected:e, IsVDType:e.label},()=>{
            console.log("IsVDType ",this.state.IsVDType)
            
            if(this.state.impactOptionSelected.label==="No Value Delivery")
            {
                this.setState({showClientFields:false,showGOLFields:false,IsVDMonetizable:false,monetizableStatus:"",
                summaryArray:"",businessImpactValueArray:[],FinalImpactFTE:0, FinalImpactDollar:0,businessImpactMasterList:null,zeroImpact:false
            },()=>{})
            }
            if(this.state.impactOptionSelected.label==="Value Delivered to Client" && this.state.IsVDType==="Value Delivered to Client")
            {
                this.setState({showClientFields:true,showGOLFields:false,IsVDMonetizable:false,monetizableStatus:"",showClientApprovalFields:false},()=>{
                    console.log(this.state.IsVDMonetizable)
                })
            }
            if(this.state.impactOptionSelected.label==="Value Delivered to Genpact" && this.state.IsVDType==="Value Delivered to Genpact")
            {
                this.setState({showClientFields:false,showGOLFields:true},()=>{})
            }
            if(this.state.impactOptionSelected.label==="Value Delivered to Client + Genpact" && this.state.IsVDType==="Value Delivered to Client + Genpact")
            {
                this.setState({showClientFields:true,showGOLFields:true},()=>{})
            }
        })

        if(this.state.ideaItem.ApprovalStatus===COMPLETED_STATUS && this.state.ideaItem.IsFPnAApprovalAttached === null && this.state.impactOptionSelected){
            this.setState({bbEditable:true},()=>{
                console.log("bb editable true " ,this.state.bbEditable)
            })
        }
       
    }


    deleteUploadedDoc=(e)=>{
        if(e === 1){
            this.setState({projectDeckFilename:""})
        }
        if(e===2){
            this.setState({signOffFilename:""})
        }
        if(e===3){
            this.setState({golFilename:""})
        }
        if(e===4){
            this.setState({fpnaFilename:""})
        }
        if(e===5){
            this.setState({FPnAFileNameMonetization:"",FPnAFileTypeMonetization:"",FPnAFileMonetization:""})
        }
    }

    handlePotentialDollarChange=(potentialValue)=>{
        let abc = Number(Math.floor(potentialValue+'e2')+'e-2');
        this.setState({potentialDollar:abc})
        let styleStateObj = this.state.selectStyle;
            styleStateObj.potentialDollar = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
        });
        console.log(this.state.potentialDollar)
    }

    handleBBReviewIdeaChange=(e)=>{
        if(e !== null || e !== undefined){
            this.setState({bbReview:e},()=>{
                if(this.state.bbReview.label === "Yes"){
                    this.setState({showCheckBox:true})
                }
                if(this.state.bbReview.label==="No"){
                    this.setState({
                        showFinalAdditionalDetails: false,
                        FinalImpactFTE:0,
                        FinalImpactDollar:0,
                        summaryArray: [],
                        replicaIndex:0,
                        businessImpactValueArray: [],
                        showCheckBox:false,
                        approvalSelectedOption:null,
                        showClientApprovalFields:false,
                        IsVDMonetizable:null,
                        monetizableStatus:null,
                        bbReviewCheckbox:null
                    });
                }
            })
        }
    }
    handleBBCheckboxChange=(e)=>{
        if(e.target.checked){
            let options={
                value:0,
                label:"Yes"
            }
            this.setState({bbReviewCheckbox:options},()=>{
                if(this.state.impactOptionSelected){
                    if(this.state.impactOptionSelected.label==="Value Delivered to Genpact" || this.state.impactOptionSelected.label==="Value Delivered to Client + Genpact"){
                        this.setState({IsVDMonetizable:true})
                    }
                }
            })
        }
        if(!e.target.checked){
            let options={
                value:1,
                label:"No"
            }
            this.setState({bbReviewCheckbox:options,IsVDMonetizable:false,monetizableStatus:null},()=>{})
        }

        console.log(this.state.bbReviewCheckbox)
    }

    handleUpdaterAction=()=>{
        console.log("updater function is being called")
        let localUpdater = "";
        if(this.state.ideaItem.ApprovalStatus === "NA" && this.state.ideaItem.WorkflowStatus === "Pending"){
            if(this.state.workFlowStatusSelectedOption){
                if(this.state.workFlowStatusSelectedOption.label==="Approve"){
                    localUpdater = "Approved"
                }else{
                    localUpdater = this.state.workFlowStatusSelectedOption.label
                }
            }
        }
        if(this.state.ideaItem.ApprovalStatus === "Documents Uploaded"){
            if(this.state.approvalSelectedOption){
                if(this.state.approvalSelectedOption.label==="Completed"){
                    localUpdater = "Approved"
                }else{
                    localUpdater = this.state.approvalSelectedOption.label
                }
            }
        }
        console.log("local updater",localUpdater)
        this.state.updaterActionState = localUpdater;
        // this.setState({updaterActionState:localUpdater},()=>{console.log(this.state.updaterActionState)})
        console.log("updater state action ",this.state.updaterActionState)
    }


    render() {
        let IDEA_APPROVED = (this.state.ideaItem.WorkflowStatus === "Approve");
        let IDEA_REJECTED = (this.state.ideaItem.WorkflowStatus === "Reject");
        let IDEA_SEND_BACK = (this.state.ideaItem.WorkflowStatus === "Send Back");
        let IDEA_REQUEST_CANCELLATION = (this.state.ideaItem.WorkflowStatus === "Cancellation Requested");
        let IDEA_REQUEST_CANCELLATION1 = (this.state.ideaItem.ApprovalStatus === "Cancellation Requested");
        let IDEA_CANCELLED = (this.state.ideaItem.WorkflowStatus === "Cancelled");
        let IDEA_CANCELLED1 = (this.state.ideaItem.ApprovalStatus === "Cancelled");

        let redirectURL = "/review-page";
        if (this.state.approvalPage) {
            redirectURL = redirectURL + "?page=approval";
        }
        
        return (
            <section className="u-clearfix u-section-1" id="sec-0f7f">
                <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                    <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                        <div className="u-container-layout u-container-layout-1">
                            <h4 style={{ textAlign: 'center', color: '#00AECF', fontFamily: 'Arial', fontSize: 22 }}>Edit idea : {this.state.ideaItem.IdeaIDAlpha ? this.state.ideaItem.IdeaIDAlpha : this.state.ideaItem.IdeaId} </h4>
                            <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                                <div className="u-container-layout u-container-layout-2" style={{ textAlign: 'center' }}>
                                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-3">
                                        <div className="u-container-layout u-container-layout-3">
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: 15 }}>Owner details </p>
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner OHR</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerOHR} /></div>
                                            </div>
                                            <div style={{ marginBottom: 15 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner Name</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerName} /></div>
                                            </div>
                                            <div style={{ marginBottom: -10 }} />
                                            <p className="u-align-left u-text u-text-7 u-text-custom-color-3" style={{ fontSize: 11, marginBottom: 10 }}>(If Idea was raised for someone else, their OHR ID is below)</p>
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Request For OHR</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.CreatorOHR} onChange={this.handleCreatorOHRChange} /></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Name</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.CreatorName} onChange={this.handleCreatorNameChange} /></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                            {
                                                (this.state.ideaItem.LeanID !== null && ((this.state.ideaItem.CertificationLevelName === "Lean" || this.state.ideaItem.CertificationLevelName === "YB") && this.state.ideaItem.TransformationLeverName !== "Digital")) ? (
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Project ID</label> </div>
                                                        <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.LeanID} /></div>
                                                    </div>
                                                ) : (
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: 12 }}>&nbsp;</label> </div>
                                                    </div>
                                                )
                                            }
                                            <div style={{ marginBottom: 10 }} />
                                        </div>
                                    </div>

                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 255, marginLeft: -20 }}>&nbsp;</p>

                                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-4" style={{ marginTop: "-305px" }}>
                                        <div className="u-container-layout u-container-layout-4">
                                            <div className="u-container-style u-group u-shape-rectangle u-group-5">
                                                <div className="u-container-layout u-container-layout-5">
                                                    <p className="u-align-left u-text u-text-8" style={{ marginBottom: 15 }}>Account Details</p>
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Account&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            {/* <input className ="form-input" disabled list="accounts" name="accounts" placeholder="Choose Account" value={this.state.ideaItem.AccountManual} /> */}
                                                            <Select isSearchable={true} options={this.renderAccountOptions(this.state.accountSelectedOption)} styles={this.state.selectStyle.accountName} placeholder="Choose Account" value={this.state.accountSelectedOption} onChange={(e) => this.handleAccountNameChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Holding&nbsp;Account</label></div>
                                                        <div className="col-md-7">
                                                            {/* <input className ="form-input" disabled type="text" value={this.state.ideaItem.HoldingAccount} /> */}
                                                            <input className="form-input" disabled list="holdingaccount" name="holdingaccount" value={this.state.holdingAccount} style={{ fontWeight: 'normal !important' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Vertical</label></div>
                                                        <div className="col-md-7">
                                                            <Select tabIndex={2} isSearchable={true} value={this.state.verticalSelectedOption} options={this.renderVerticalOptions(this.state.verticalSelectedOption)} styles={this.state.selectStyle.verticalManual} placeholder="Choose Vertical" onChange={(e) => this.handleVerticalChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Industry Vertical</label></div>
                                                        <div className="col-md-7">
                                                            <Select tabIndex={2} isSearchable={true} value={this.state.subVerticalSelectedOption} options={this.renderSubVerticalOptions(this.state.subVerticalSelectedOption)} styles={this.state.selectStyle.subVerticalManual} placeholder="Choose Industry&nbsp;Vertical" onChange={(e) => this.handleSubVerticalChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Campaign Name</label></div>
                                                        <div className="col-md-7">
                                                            <Select tabIndex={2} isSearchable={true} value={this.state.campaignSelectedOption} options={this.renderCampaignOptions(this.state.campaignSelectedOption)} styles={this.state.selectStyle.campaignTypeText} placeholder="Choose Campaign" onChange={(e) => this.handleCampaignNameChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />

                                                    {
                                                        this.state.chatGPTTheme
                                                        ?
                                                        <>
                                                            <div className="row" > 
                                                                <div className="col-md-5">
                                                                    <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Gen AI Theme</label></div>
                                                                <div className="col-md-7">
                                                                    <Select tabIndex={5} isSearchable={true} options={this.renderChatGPToptions()} placeholder="Choose Gen AI Theme" onChange={this.handleChatGptOptionsChange} value={this.state.chatGptSelectedOption} />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    }



                                                    {
                                                        this.state.dataBridgeCampaign && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Data Bridge Project Theme</label></div>
                                                                    <div className="col-md-7">
                                                                        {/* <input className ="form-input" disabled list="databridgetype" name="databridgetype" placeholder="Choose Data Bridge Project Type" value={this.state.ideaItem.DataBridgeTheme} /> */}
                                                                        {/* <Select tabIndex={4} isSearchable={true} options={this.renderDataBridgeProjectThemeOptions()} styles={this.state.selectStyle.dataBridgeProjectTheme} placeholder="Choose Project Theme" onChange={this.handleDataBridgeProjectThemeChange} /> */}
                                                                        <Select tabIndex={2} isSearchable={true} value={this.state.dataBridgeSelectedOption} options={this.renderDataBridgeProjectThemeOptions(this.state.dataBridgeSelectedOption)} styles={this.state.selectStyle.dataBridgeProjectTheme} placeholder="Choose Project Theme" onChange={(e) => this.handleDataBridgeProjectThemeChange(e)} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 26 }} />
                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        this.state.xPollinationCampaign && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Campaign</label></div>
                                                                    <div className="col-md-7">
                                                                        {/* <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Sub Campaign" value={this.state.ideaItem.SubCampaignName} /> */}
                                                                        <Select tabIndex={2} isSearchable={true} value={this.state.subCampaignSelectedOption} options={this.renderSubCampaignOptions(this.state.subCampaignSelectedOption)} styles={this.state.selectStyle.subCampaignName} placeholder="Choose Sub Campaign" onChange={(e) => this.handleSubCampaignChange(e)} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 26 }} />
                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        !this.state.dataBridgeCampaign && !this.state.xPollinationCampaign && (
                                                            <>
                                                                <div className="row" style={{ marginBottom: 58 }}>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    <div style={{ marginBottom: 18 }}>&nbsp;</div>
                                                </div>
                                            </div>

                                            <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -75 }}>&nbsp;</p>

                                            <div className="u-container-style u-group u-shape-rectangle u-group-6">
                                                <div className="u-container-layout u-container-layout-6">
                                                    {
                                                        this.state.ideaItem.CampaignName === "Gen AI"
                                                        ?
                                                        <>
                                                            <p className="u-align-left u-text u-text-8"  style={{   marginTop: -236  }}>&nbsp;</p>
                                                        </>
                                                        :
                                                        <p className="u-align-left u-text u-text-8" style={{ marginTop: -193 }}>&nbsp;</p>
                                                    }
                                                    
                                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 175 }}>&nbsp;</p>
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Country</label></div>
                                                        <div className="col-md-7">
                                                            {/* <input className ="form-input" disabled name="regions" placeholder="Choose Country" value={this.state.ideaItem.Country} /> */}
                                                            {/* <Select tabIndex={6} isSearchable={true} value={this.state.countrySelectedOption} options={this.renderCountryOptions()} styles={this.state.selectStyle.country} placeholder="Choose Country" onChange={this.handleCountryChange} /> */}
                                                            <Select tabIndex={2} isSearchable={true} value={this.state.countrySelectedOption} options={this.renderCountryOptions(this.state.countrySelectedOption)} styles={this.state.selectStyle.country} placeholder="Choose Country" onChange={(e) => this.handleCountryChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Region</label></div>
                                                        <div className="col-md-7">
                                                            {/* <input className ="form-input" disabled name="regions" placeholder="Choose Region" value={this.state.ideaItem.RegionManual} /> */}
                                                            <Select tabIndex={2} isSearchable={true} value={this.state.regionSelectedOption} options={this.renderRegionOptions(this.state.regionSelectedOption)} styles={this.state.selectStyle.regionManual} placeholder="Choose Region" onChange={(e) => this.handleRegionChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub-Region</label></div>
                                                        <div className="col-md-7">
                                                            {/* <input className ="form-input" disabled name="regions" placeholder="Choose Sub-Region" value={this.state.ideaItem.SubRegion} /> */}
                                                            <Select tabIndex={2} isSearchable={true} value={this.state.subRegionSelectedOption} options={this.renderSubRegionOptions(this.state.subRegionSelectedOption)} styles={this.state.selectStyle.subRegionManual} placeholder="Choose Sub-Region" onChange={(e) => this.handleSubRegionChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Blackbelt&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            {/* <input className ="form-input" disabled name="blackbelt" placeholder="Choose Blackbelt" title={"OHR ID: " +  this.state.ideaItem.BlackBeltUserId} value={this.state.ideaItem.BlackBeltUserName} /> */}
                                                            <Select tabIndex={2} isSearchable={true} value={this.state.blackBeltSelectedOption} options={this.renderBlackBeltOptions(this.state.blackBeltSelectedOption)} styles={this.state.selectStyle.blackBeltUserName} placeholder="Choose Blackbelt" onChange={(e) => this.handleBlackBeltNameChange(e)} />
                                                        </div>
                                                    </div>
                                                    {
                                                        (this.state.ideaItem.MBBOHR !== null || this.state.showAdditionalDetails || this.state.workFlowStatusSelectedOption) && (
                                                            // (this.state.ideaItem.WorkflowStatus !== "Pending") && (
                                                            <>
                                                                <div style={{ marginBottom: 15 }} />
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>MBB</label></div>
                                                                    <div className="col-md-7">
                                                                        {/* <input className ="form-input" disabled name="blackbelt" placeholder="Choose MBB" title={"OHR ID: " +  this.state.ideaItem.MBBOHR} value={this.state.ideaItem.MBBName} /> */}
                                                                        <Select tabIndex={2} isSearchable={true} value={this.state.mbbSelectedOption} options={this.renderMBBOptions(this.state.mbbSelectedOption)} styles={this.state.selectStyle.mbbUserName} placeholder="Choose MBB" onChange={(e) => this.handleMBBChange(e)} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }

                                                    <div style={{ marginBottom: 15 }} />
                                                    {/* <div className="row">
                                                        <div className="col-md-5">
                                                            <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>LDA</label>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <Select tabIndex={2} isSearchable={true} value={this.state.ldaSelectedOption} options={this.renderLDAOptions(this.state.ldaSelectedOption)} styles={this.state.selectStyle.ldaName} placeholder="Choose LDA" onChange={(e) => this.handleLDAChange(e)} />
                                                        </div>
                                                    </div> */}
                                                    <div style={{ marginBottom: 15 }} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />

                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -30 }}>&nbsp;</p>

                                    <div id="idea1" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ marginTop: 15 }}>
                                        <div className="u-container-layout u-container-layout-7">
                                            <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                <div className="u-container-layout u-container-layout-8">
                                                    <p id="idea1title" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Idea details</p>
                                                    <div className="row">
                                                        <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Service Line</label> </div>
                                                        <div className="col-md-8">
                                                            {/* <input className ="form-input" disabled name="serviceline" placeholder="Choose Service Line" value={this.state.ideaItem.ServiceLineName} /> */}
                                                            <Select isSearchable={true} options={this.renderServiceLineOptions(this.state.serviceLineSelectedOption)} styles={this.state.selectStyle.serviceLineText} placeholder="Choose Service Line" value={this.state.serviceLineSelectedOption} onChange={(e) => this.handleServiceLineChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Service Line</label> </div>
                                                        <div className="col-md-8">
                                                            {/* <input className ="form-input" disabled name="serviceline" placeholder="Choose Sub Service Line" value={this.state.ideaItem.SubServiceLine} /> */}
                                                            <Select isSearchable={true} options={this.renderSubServiceLineOptions()} styles={this.state.selectStyle.subServiceLine} placeholder="Choose Sub Service Line" value={this.state.subServiceLineSelectedOption} onChange={(e) => this.handleSubServiceLineChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                        <span className="u-text-black form-attribute"> What is the Problem ?</span>&nbsp;
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            {/* <textarea className ="form-input" disabled rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Problem} /> */}
                                                            <textarea className="textarea-css form-attribute" rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey'}} value={this.state.ideaItem.Problem} onChange={(event) => this.handleProblemChange(event.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 10 }}>&nbsp;</p>
                                            <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                <div className="u-container-layout u-container-layout-9" style={{ marginTop: 4 }}>
                                                    <div className="row">
                                                        <div className="col-md-3"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Idea Name</label> </div>
                                                        <div className="col-md-9" style={{ alignItems: 'flex-start' }}><input className="form-input" disabled type="text" value={this.state.ideaItem.IdeaName} placeholder="Eg: Cash flow improvement through process restructuring" /></div>
                                                        {/* <div className="col-md-8" style={{ alignItems: 'flex-start' }}><input className="textarea-css form-attribute" type="text" defaultValue={this.state.ideaItem.IdeaName} style={this.state.selectStyle.IdeaName} placeholder="Eg: Cash flow improvement through process restructuring" onChange={(event) => this.handleIdeaNameChange(event.target.value)} /></div> */}
                                                    </div>
                                                    <div style={{ marginBottom: 66 }} />
                                                    <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                        <span className="u-text-black form-attribute"> How to resolve the problem</span>&nbsp;
                                                    </p>
                                                    <div className="row" style={{ marginBottom: 15 }}>
                                                        <div className="col-md-12">
                                                            {/* <textarea className ="form-input" disabled rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Solution} /> */}
                                                            <textarea className="textarea-css form-attribute" rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Solution} onChange={(event) => this.handleSolutionChange(event.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 15 }}>
                                                <div className="col-md-5"><span className="form-attribute">Are you trying to replicate an existing Idea?</span>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="switch">
                                                        {/* <input disabled type="checkbox" checked={this.state.ideaItem.NewOrReplicatedIdea === null ? false : this.state.ideaItem.NewOrReplicatedIdea} /> */}
                                                        <input type="checkbox" checked={this.state.ideaItem.NewOrReplicatedIdea === null ? false : this.state.ideaItem.NewOrReplicatedIdea} onChange={() => this.showHideDiv()} />
                                                        <span className="slider round" />
                                                    </label>
                                                </div>
                                            </div>

                                            {
                                                this.state.ideaItem.NewOrReplicatedIdea && (
                                                    <>
                                                        <div className="col-md-8" style={{ marginBottom: 15, width: "100%" }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><span className="form-attribute">Provide the ID of the executed Idea which you want to replicate?</span>
                                                            </div>
                                                            {/* <div className="col-md-2"><input className ="form-input" disabled type="text" value={this.state.ideaItem.ReplicationProjectId} /></div> */}
                                                            <div className="col-md-2">
                                                                <input className="textarea-css form-attribute" type="text" value={this.state.ideaItem.ReplicationProjectId} style={{ border: '1px solid lightgrey', ...this.state.selectStyle.ReplicationProjectId }} onChange={event => { this.handleReplicationIdChange(event.target.value) }} onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.validateReplicationId();
                                                                    }
                                                                }} />
                                                            </div>
                                                            <div className="col-md-1"><i className="fa fa-check-square-o" style={{ marginTop: 5, color: '#FF555F', fontSize: 24 }} onClick={event => { this.validateReplicationId() }} /></div>
                                                            <div className="col-md-8" style={{ marginBottom: 15 }} />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <div className="col-md-8" style={{ marginBottom: 15 }} />
                                        </div>
                                    </div>

                                    {
                                        (this.state.ideaItem.WorkflowStatus) && (
                                            <>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />
                                                <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                    <div className="u-container-layout u-container-layout-7">
                                                        <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Idea Approval details</p>
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Approval Status</label>
                                                            </div>
                                                            <div className="col-md-2">
                                                                {
                                                                    (this.state.ideaItem.WorkflowStatus === 'Pending' || this.state.ideaItem.WorkflowStatus === 'Reject') && (
                                                                        <Select isSearchable={true} options={this.renderStatusOptions(this.state.workFlowStatusSelectedOption)} styles={this.state.selectStyle.workFlowStatusSelectedOption} placeholder="Choose Status" value={this.state.workFlowStatusSelectedOption} onChange={(e) => this.showAdditionalDetails(e)} />
                                                                    )
                                                                }
                                                                {
                                                                    (this.state.ideaItem.WorkflowStatus !== 'Pending' && this.state.ideaItem.WorkflowStatus !== 'Reject') && (
                                                                        <input className="form-input" disabled name="Status" value={IDEA_APPROVED ? APPROVED : (IDEA_REJECTED ? REJECTED : (IDEA_SEND_BACK ? SENT_BACK : ((IDEA_REQUEST_CANCELLATION || IDEA_REQUEST_CANCELLATION1) ? "Cancellation Requested" : ((IDEA_CANCELLED || IDEA_CANCELLED1) ? "Cancelled" : PENDING))))} />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: 15 }}>
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Comments</label></div>
                                                            <div className="col-md-2" style={{ marginLeft: 20 }}>
                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ commentLog: !this.state.commentLog })}>Comment Log</button>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: 5 }}>
                                                            <div className="col-md-12">
                                                                {
                                                                    (this.state.ideaItem.WorkflowStatus === 'Pending' || this.state.ideaItem.WorkflowStatus === 'Reject') && (
                                                                        <textarea className="textarea-css form-attribute" rows={3} cols={88} 
                                                                            style={{ maxWidth: '100%', border: '1px solid lightgrey', ...this.state.selectStyle.comment }} 
                                                                            placeholder="Please provide your comments in support of the Approval decision." 
                                                                            onChange={(event) => this.handleCommentChange(event.target.value)} 
                                                                        />
                                                                    )
                                                                }
                                                                {
                                                                    (this.state.ideaItem.WorkflowStatus !== 'Pending' && this.state.ideaItem.WorkflowStatus !== 'Reject') && (
                                                                        <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.Comment} />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="text-left mt-4">
                                                            {
                                                                this.state.commentLog && this.state.reviewComments
                                                                    ?
                                                                    <>
                                                                        <div className="custom_modal">
                                                                            <div className="modal_body">
                                                                                <div className="row">
                                                                                    <div className="col-md-8">
                                                                                        <h3 className="modal_title">Idea Approval Comment Log</h3>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ commentLog: false })}>&times;</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                    </div>
                                                                                    <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                    </div>
                                                                                    <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    this.state.reviewComments.map((item, index) => {

                                                                                        return (
                                                                                            <div className="row" key={index}>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                </div>
                                                                                                <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{(item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin' ) ? 'Admin' : (item.Action === 'Delegate Action' ? 'BB' : (item.UpdaterOHR === this.state.ideaItem.BlackBeltUserId ? "BB" : (item.UpdaterOHR === this.state.ideaItem.MBBOHR ? "MBB" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Owner" : item.UpdaterRole))))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? (item.WorkflowStatus !== 'Pending' ? item.WorkflowStatus : 'Idea Updated') : (item.Action === 'Delegate Action' ? 'Delegated' : (item.WorkflowStatus==='Pending' ? 'Reject Cancellation' : item.WorkflowStatus)))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment ? item.Comment:'-'}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15, marginTop: 15 }} />

                                                {
                                                    (this.state.ideaItem.WorkflowStatus && (this.state.ideaItem.WorkflowStatus === "Approve" || this.state.ideaItem.WorkflowStatus === "Approved" || this.state.showAdditionalDetails)) && (
                                                        <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                            <div className="u-container-layout u-container-layout-7">
                                                                <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                                    <div className="u-container-layout u-container-layout-8">
                                                                        <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Additional details</p>
                                                                        <div className="row">
                                                                            <div className="col-md-6"><span className="form-attribute">LDT Lead Name</span></div>
                                                                            <div className="col-md-6">
                                                                                {/* <input className="form-input" disabled list="ldtName" name="ldtName" placeholder="Choose LDT Lead" title={"OHR ID: " +  this.state.ideaItem.LDTId} value={this.state.ideaItem.LDTLeadName} /> */}
                                                                                <Select tabIndex={2} isSearchable={true} 
                                                                                    value={this.state.LDTLeadSelectedOption} 
                                                                                    options={this.state.showAdditionalDetails ? this.renderLeadOptions() : this.renderLeadOptions(this.state.LDTLeadSelectedOption)} 
                                                                                    styles={this.state.selectStyle.LDTLeadName} placeholder="Choose LDT Lead" onChange={(e) => this.handleLDTLeadOptionChange(e)} 
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 5 }} />
                                                                        <div style={{ marginTop: 10, marginBottom: 14 }} />
                                                                        {
                                                                            this.state.ideaItem.NewOrReplicatedIdea && (
                                                                                <div className="row">
                                                                                    <div className="col-md-10">
                                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Have you verified the replication id and confirm this to be replication project?</label>
                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        <label className="switch">
                                                                                            <input disabled type="checkbox" checked={this.state.ideaItem.IsReplicationVerified} />
                                                                                            <span className="slider round" />
                                                                                        </label>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 45 }} />
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            !this.state.ideaItem.NewOrReplicatedIdea && (
                                                                                <div className="row">
                                                                                    <div className="col-md-10">
                                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: 17 }}>&nbsp;</label>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 45 }}>&nbsp;</p>

                                                                <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                                    <div className="u-container-layout u-container-layout-9" style={{ marginTop: '177px' }}>
                                                                        <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: '-260px' }}>&nbsp;</p>
                                                                        <div className="row">
                                                                            <div className="col-md-5"><span className="form-attribute">Certification Level</span> </div>
                                                                            <div className="col-md-7">
                                                                                {
                                                                                    this.state.showAdditionalDetails ? (
                                                                                        <Select isSearchable={false} options={this.renderCertLevelsOptions()} styles={ this.state.selectStyle.certLevelsSelectedOption } value={this.state.certLevelsSelectedOption} onChange={this.handleCertLevelChange} placeholder="Choose Certification Level" />
                                                                                    ):(
                                                                                        <input className="form-input" disabled name="certlevel" value={this.state.ideaItem.CertificationLevelName} />
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 15 }} />
                                                                        <div className="row">
                                                                            <div className="col-md-5"><span className="form-attribute">Transformation Lever</span> </div>
                                                                            <div className="col-md-7">
                                                                                {
                                                                                    this.state.showAdditionalDetails ? (
                                                                                        <Select isSearchable={false} options={this.renderTransLeversOptions()} value={this.state.transSelectedOption} styles={this.state.selectStyle.transSelectedOption} onChange={(e)=>this.handleTransLeversChange(e)} placeholder="Choose Transformation Lever" />
                                                                                    ):(
                                                                                        this.state.ideaItem.CertificationLevelName === "Lean" ? (
                                                                                            <Select isSearchable={false} options={this.renderTransLeversOptionsForLeanApproval()} value={this.state.transSelectedOption} styles={this.state.selectStyle.transSelectedOption} onChange={(e)=>this.handleTransLeversChange(e)} placeholder="Choose Transformation Lever" />
                                                                                        ):(
                                                                                            <input className="form-input" disabled name="translever" value={this.state.ideaItem.TransformationLeverName} />
                                                                                        )
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 15 }} />
                                                                        {
                                                                            

                                                                            
                                                                        
                                                                        /* {
                                                                            !this.state.digitalProjectTypeSelectedOptions
                                                                            ?
                                                                            <>
                                                                                <div className="row">  
                                                                                    <div className="col-md-5"><span className="form-attribute">Digital Project Type</span> </div>
                                                                                    <div className="col-md-7">
                                                                                        <input className="form-input" disabled name="translever" value={this.state.ideaItem.DigitalProjectType} />
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ marginBottom: 15 }} />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className="row">
                                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', color: 'lightgrey' }}>Digital Project Type</label></div>
                                                                                    <div className="col-md-7">
                                                                                        <input className="form-input" disabled type="text" value="Not Applicable" style={{ fontWeight: 100, color: 'lightgrey' }} />
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ marginBottom: 15 }} />
                                                                            </>
                                                                        } */}
                                                                        
                                                                        {/* {
                                                                            this.state.digitalSelected
                                                                            ?
                                                                            <>
                                                                                <div className="row">
                                                                                    <div className="col-md-5">
                                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}><span style={{ color: 'red' }}>*</span> Digital Project Type</label></div>
                                                                                    <div className="col-md-7">
                                                                                        <Select isSearchable={false} 
                                                                                            options={this.renderDigitalProjectTypeOptions()} 
                                                                                            value={this.state.digitalSelected} 
                                                                                            onChange={this.handleDigitalProjectTypeChange} 
                                                                                            placeholder="Choose Project Type" 
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ marginBottom: 15 }} />
                                                                            </>
                                                                            :
                                                                            null
                                                                        } */}
                                                                        {
                                                                            this.state.digitalProjectEnabled
                                                                            ?
                                                                            <>
                                                                                <div className="row">
                                                                                    <div className="col-md-5">
                                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Digital Project Type</label></div>
                                                                                    <div className="col-md-7">
                                                                                        <Select isSearchable={false} 
                                                                                            options={this.renderDigitalProjectTypeOptions()} 
                                                                                            value={this.state.digitalSelected} 
                                                                                            onChange={this.handleDigitalProjectTypeChange} 
                                                                                            placeholder="Choose Project Type" 
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ marginBottom: 15 }} />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className="row">
                                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', color: 'lightgrey' }}>Digital Project Type</label></div>
                                                                                    <div className="col-md-7">
                                                                                        <input className="form-input" disabled type="text" value="Not Applicable" style={{ fontWeight: 100, color: 'lightgrey' }} />
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ marginBottom: 15 }} />
                                                                            </>
                                                                        }
                                                                        <div style={{ marginBottom: 15 }} />
                                                                        <div className="row">
                                                                            <div className="col-md-5"><span className="form-attribute">Work Type</span> </div>
                                                                            <div className="col-md-7">
                                                                                {
                                                                                    this.state.workTypeEnabled ? (
                                                                                        <Select disabled isSearchable={false} options={this.renderCertWorkTypeOptions(this.state.certLevelsSelectedOption)} styles={this.state.selectStyle.certWorkTypeSelectedOption} value={this.state.certWorkTypeSelectedOption} onChange={this.handleCertWorkTypeChange} placeholder="Choose Work Type" />
                                                                                    ): (
                                                                                    <input className="form-input" disabled name="worktype" value={(!this.state.ideaItem.CertificationWorkTypeName || this.state.ideaItem.CertificationWorkTypeName === "") ? "None" : this.state.ideaItem.CertificationWorkTypeName} />
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 15 }} />

                                                                        <div className="row">
                                                                            <div className="col-md-5"><span className="form-attribute" style={{position:"relative",top:"10px"}}>Tools/Methodology Used</span></div>
                                                                            <div className="col-md-7">
                                                                                {/* <input className="form-input" disabled name="methodology" value={this.state.ideaItem.MethodologyName} /> */}
                                                                                <Select tabIndex={2} isSearchable={true} value={this.state.methodologySelectedOptions} options={this.renderMethodologyOptions(this.state.methodologySelectedOptions)} styles={this.state.selectStyle.methodologySelectedOptions} placeholder="Choose Methodology" onChange={(e) => this.handleMethodologyChange(e)} maxMenuHeight={160} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 25 }} />

                                                                        {
                                                                            this.state.certLevelsSelectedOption && this.state.certLevelsSelectedOption.label==="Lean"
                                                                            ?
                                                                            <>
                                                                                <div className="row">
                                                                                    <div className="col-md-5">
                                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Potential Value Delivery $</label></div>
                                                                                    <div className="col-md-7">
                                                                                        <input type="number" 
                                                                                            className="textarea-css form-attribute hide_numeric_arrows" 
                                                                                            onChange={(e)=>this.handlePotentialDollarChange(e.target.value)}  
                                                                                            style={this.state.selectStyle.potentialDollar} 
                                                                                            value={this.state.potentialDollar} 
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                        }

                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        (this.state.ideaItem.ApprovalStatus && this.state.ideaItem.ProjectPPTFileName)
                                        && (
                                            <>
                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -20 }}>&nbsp;</p>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15, marginTop: 25 }} />
                                                <div id="Upload" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                    <div className="u-container-layout u-container-layout-7">
                                                        <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                            <div className="u-container-layout u-container-layout-8">

                                                                <p id="Upload" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Uploaded documents</p>
                                                                <div className="row" style={{display:"flex",alignItems:"Center"}}> 
                                                                    {
                                                                        this.state.ideaItem.Migration === 'H' ? (
                                                                            <>
                                                                                <div className="col-md-12"><label style={{ verticalAlign: 'bottom' }}>1.</label> </div>
                                                                                <div className="col-md-12">
                                                                                    <button style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0,textAlign:"left" }} onClick={this.showPPTContent}>{this.state.projectDeckFilename}</button>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className="col-md-12"><label style={{ verticalAlign: 'bottom' }}>Project Presentation Deck</label> </div>
                                                                                <div className="col-md-10">
                                                                                    <button style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0,textAlign:"left" }} onClick={this.showPPTContent}>{this.state.projectDeckFilename}</button>
                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    {
                                                                                        this.state.projectDeckFilename
                                                                                        ?
                                                                                        <>
                                                                                            <button onClick={(e)=>this.deleteUploadedDoc(1)} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{marginTop: "6px"}}>&nbsp;&nbsp;<i className="fa fa-trash" aria-hidden="true"></i> &nbsp;Delete&nbsp;&nbsp;</button>
                                                                                        </>
                                                                                        :
                                                                                        <div>
                                                                                            <label htmlFor="file-upload1" className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                                                            <input id="file-upload1" 
                                                                                                type="file" style={{ display: 'none' }} 
                                                                                                onChange={(event) => this.myFIleChange(event, "projectPresentationDeck")} 
                                                                                                accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" />
                                                                                        </div>
                                                                                        }
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }

                                                                </div>

                                                                <div style={{ marginTop: 5, marginBottom: 10 }} />
                                                                {/* <div className="row">
                                                                    {
                                                                        this.state.ideaItem.Migration === 'H' ? (
                                                                            <>
                                                                                <div className="col-md-1"><label style={{ verticalAlign: 'bottom' }}>2.</label> </div>
                                                                                <div className="col-md-11">
                                                                                    <button style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0 }} onClick={this.showSignOffContent}>{this.state.signOffFilename}</button>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className="col-md-6"><label style={{ verticalAlign: 'bottom' }}>Customer/Ops Lead Sign-off</label> </div>
                                                                                <div className="col-md-6">
                                                                                    <button style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0 }} onClick={this.showSignOffContent}>{this.state.signOffFilename}</button>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </div> */}

                                                                <div style={{ marginTop: 5, marginBottom: 5 }} />
                                                                {/* {
                                                                    this.state.ideaItem.TransformationLeverName === "Digital" && (
                                                                        <>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Source ID</label>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <input className="form-input" disabled type="text" value={this.state.sourceId}
                                                                                        onChange={this.handleSourceIdChange} placeholder="Enter Source ID" style={{ border: '1px solid lightgrey' }} />
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ marginTop: 5, marginBottom: -25 }} />
                                                                        </>
                                                                    )
                                                                } */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        (this.state.ideaItem.ApprovalStatus
                                            && this.state.ideaItem.ApprovalStatus !== "NA"
                                            // && this.state.ideaItem.FinalComments !== null
                                            // && this.state.ideaItem.ApprovalStatus !=="Documents Uploaded"
                                            && this.state.ideaItem.ApprovalStatus !== "Awaiting Project Creation"
                                        )
                                        && (
                                            <>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 10 }} />
                                                {
                                                    (this.state.ideaItem.FinalComments !== null
                                                        && this.state.ideaItem.ApprovalStatus !== "Documents Uploaded") && (

                                                        <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                            <div className="u-container-layout u-container-layout-7">
                                                                <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Project Approval details</p>
                                                                <div className="row">
                                                                    <div className="col-md-2"><span className="form-attribute">Approval Status</span> </div>
                                                                    <div className="col-md-2">
                                                                        <input className="form-input" disabled name="finalapprovalstatus" value={this.state.ideaItem.ApprovalStatus} />
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ marginTop: 15 }}>
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Approval Comments</label></div>
                                                                    <div className="col-md-2" style={{ marginLeft: 20 }}>
                                                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ finalCommentLog: !this.state.finalCommentLog })}>Comment Log</button>
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ marginTop: 5 }}>
                                                                    <div className="col-md-12">
                                                                        <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.leanProjectLastComment} />
                                                                    </div>
                                                                </div>
                                                                <div className="text-left mt-4">
                                                                    {
                                                                        this.state.finalCommentLog && this.state.finalReviewComments
                                                                            ?
                                                                            <>
                                                                                <div className="custom_modal">
                                                                                    <div className="modal_body">
                                                                                        <div className="row">
                                                                                            <div className="col-md-8">
                                                                                                <h3 className="modal_title">Lean Approval Comment Log</h3>
                                                                                            </div>
                                                                                            <div className="col-md-4">
                                                                                                <button type="button" className="close text-right" onClick={(e) => this.setState({ finalCommentLog: false })}>&times;</button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                                <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                            </div>
                                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                                <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                            </div>
                                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                                <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                            </div>
                                                                                            <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                                <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                            </div>
                                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                                <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                            </div>
                                                                                            <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                                <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            this.state.finalReviewComments.map((item, index) => {

                                                                                                return (
                                                                                                    <div className="row" key={index}>
                                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                        </div>
                                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                        </div>
                                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                        </div>
                                                                                                        <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === 'Idea was reviewed by MBB' ? "MBB" : (item.Action === 'Idea was reviewed by LDT' ? "LDT" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Owner" : "BB")))}</label>
                                                                                                        </div>
                                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                            {/* <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? item.ApprovalStatus : (item.WorkflowStatus === 'Completed' || item.WorkflowStatus === 'Approve' || item.WorkflowStatus === 'Approved' || item.ApprovalStatus === "Awaiting Quality Check Review") ? 'Approved' : ((item.WorkflowStatus === 'Rejected' || item.WorkflowStatus === 'Reject') ? "Rejected" : (item.WorkflowStatus === REQUEST_CANCELLATION ? REQUEST_CANCELLATION : (item.WorkflowStatus === 'Pending Documents' ? 'Reject Cancellation' : item.WorkflowStatus === 'Cancelled' ? 'Cancelled' : "Sent Back"))))}</label> */}
                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction}</label>
                                                                                                        </div>
                                                                                                        <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment !== null ? item.Comment :'-'}</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {/* ....latest changes to Client n GOL fields starts here.... */}
                                                {
                                                    this.state.IsVDType && !this.state.oldVDType
                                                    ?
                                                    <>
                                                        <div className="" style={{padding: "30px 0px",borderTop:"1px solid lightgray"}}>
                                                            <div className="row" >
                                                                <div className="col-md-8">
                                                                    <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Impact Type</p>
                                                                    <div className="row" style={{display:"flex",alignItems:"center"}}>
                                                                        <div className="col-md-5 text-left"><span className="form-attribute">What is the type of Value Delivery on this project</span> </div>
                                                                        <div className="col-md-5 text-left">
                                                                            <Select isSearchable={false} options={this.renderImpactOptions()} placeholder="Choose Impact Type"  onChange={(e)=>this.handleImpactChange(e)} value={this.state.impactOptionSelected}   />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                }

                                                {
                                                    this.state.oldVDType
                                                    ?
                                                    <>
                                                        <div className="" style={{padding: "30px 0px",borderTop:"1px solid lightgray"}}>
                                                            <div className="row" >
                                                                <div className="col-md-8">
                                                                    <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Impact Type</p>
                                                                    <div className="row" style={{display:"flex",alignItems:"center"}}>
                                                                        <div className="col-md-5 text-left"><span className="form-attribute">What is the type of Value Delivery on this project</span> </div>
                                                                        <div className="col-md-5 text-left">
                                                                            <Select isSearchable={false} options={this.renderImpactOptions()} placeholder="Choose Impact Type"  onChange={(e)=>this.handleImpactChange(e)} value={this.state.impactOptionSelected}   />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                }

                                                


                                                {
                                                    this.state.showClientFields
                                                    ?
                                                    <>
                                                        <div className="gol_fields_block" style={{borderTop:"1px solid lightgray",paddingTop:"15px",borderBottom:"0px"}}>
                                                            <p className="u-align-left u-text u-text-2" style={{margin: "10px 0px 20px"}}>Client Approval Details</p>
                                                            <div className="row u-align-left">
                                                                <div className="col-md-5">
                                                                    <div className="row">
                                                                        <div className="col-md-3 gol_field_div">
                                                                            <span className="form-attribute">Client Name</span> 
                                                                        </div>
                                                                        <div className="col-md-9">
                                                                            <input value={this.state.clientName} className="form-input" type="text" onChange={(e)=>this.handleClientNameChange(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: 15 }} />
                                                                    <div className="row">
                                                                        <div className="col-md-3 gol_field_div">
                                                                            <span className="form-attribute">Client Role</span> 
                                                                        </div>
                                                                        <div className="col-md-9">
                                                                            <input value={this.state.clientRole} className="form-input" type="text" onChange={(e)=>this.handleClientRoleChange(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2"></div>
                                                                <div className="col-md-5">
                                                                    <div className="row">
                                                                        <div className="col-md-5 gol_field_div">
                                                                            <span className="form-attribute">Client Sign-off Date</span> 
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <input onChange={(e)=>this.handleClientSignOffDateChange(e.target.value)} className="form-input" type="date" value={this.state.clientSignOffDate} />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: 15 }} />
                                                                </div>
                                                            </div>

                                                            <div className="gol_uploaded_documents">
                                                                <div className="row u-align-left">
                                                                    <div className="col-md-6">
                                                                        <div className="row gol_uploaded_documents_row">
                                                                            <div className="col-md-12">
                                                                                <label>Client Approval Documents</label>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div className="row" style={{display:"flex",alignItems:"center"}}>
                                                                                    <div className="col-md-10">
                                                                                        <button onClick={this.showSignOffContent} className="file_name">{this.state.signOffFilename}</button>
                                                                                    </div> 
                                                                                    <div className="col-md-2">
                                                                                        {
                                                                                            this.state.signOffFilename
                                                                                            ?
                                                                                            <>
                                                                                                <button onClick={(e)=>this.deleteUploadedDoc(2)} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2">&nbsp;&nbsp;<i className="fa fa-trash" aria-hidden="true"></i> &nbsp;Delete&nbsp;&nbsp;</button>
                                                                                            </>
                                                                                            :
                                                                                            <div>
                                                                                                <label htmlFor="file-upload1" className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                                                                <input id="file-upload1" 
                                                                                                    type="file" style={{ display: 'none' }} 
                                                                                                    onChange={(event) => this.myFIleChange(event, "signofffilename")} 
                                                                                                    accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" />
                                                                                            </div>
                                                                                        }
                                                                                    </div>        
                                                                                </div>
                                                                                
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                }

                                                {
                                                    this.state.showGOLFields
                                                    ?
                                                    <>

                                                        <div className="gol_fields_block" style={{borderBottom:"0px",borderTop:"1px solid lightgray", paddingTop:"8px"}}>
                                                            <p className="u-align-left u-text u-text-2" style={{margin: "10px 0px 20px"}}>GOL / FP&A Approval Details</p>
                                                            <div className="row u-align-left">
                                                                <div className="col-md-5">
                                                                    <div className="row">
                                                                        <div className="col-md-3 gol_field_div">
                                                                            <span className="form-attribute">GOL OHR</span> 
                                                                        </div>
                                                                        <div className="col-md-9">
                                                                            <input style={this.state.selectStyle.golOhr} pattern="\d*" maxLength="9" defaultValue={this.state.golOhr} onChange={(e)=>this.handleGOLOhrChange(e)} onCut={(e)=>this.handleGOLOhronCut(e)} onBlur={(e)=>this.handleGOLOhrChange(e)} className="form-input hide_numeric_arrows" type="number"
                                                                                onKeyDown={ (evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: 15 }} />

                                                                    <div className="row">
                                                                        <div className="col-md-3 gol_field_div">
                                                                            <span className="form-attribute">FP&A OHR</span> 
                                                                        </div>
                                                                        <div className="col-md-9">

                                                                            <input style={this.state.selectStyle.fpnaOhr} pattern="\d*" maxLength="9" onCut={(e)=>this.handleFpnaOhronCut(e)} onChange={(e)=>this.handleFpnaOhrChange(e)}  onBlur={(e)=>this.handleFpnaOhrChange(e)} className="form-input hide_numeric_arrows" type="number" defaultValue={this.state.fpnaOhr }
                                                                                onKeyDown={ (evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() }
                                                                            /> 
                                                                            {/* {
                                                                                this.state.appropriateAttachment
                                                                                ?
                                                                                <>
                                                                                    <input style={this.state.selectStyle.fpnaOhr} pattern="\d*" maxLength="9" onCut={(e)=>this.handleFpnaOhronCut(e)} onChange={(e)=>this.handleFpnaOhrChange(e)}  onBlur={(e)=>this.handleFpnaOhrChange(e)} className="form-input hide_numeric_arrows" type="number" defaultValue={this.state.fpnaOhr }
                                                                                    onKeyDown={ (evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() }
                                                                                    /> 
                                                                                </>
                                                                                :
                                                                                    <input disabled={true} value={this.state.ideaItem.FPnAOHR} className="form-input disabled_input" />
                                                                            } */}
                                                                            
                                                                        </div>
                                                                    </div>

                                                                    <div style={{ marginBottom: 15 }} />
                                                                </div>
                                                                <div className="col-md-2"></div>
                                                                <div className="col-md-5">
                                                                    <div className="row">
                                                                        <div className="col-md-3 gol_field_div">
                                                                            <span className="form-attribute">GOL Full Name</span> 
                                                                        </div>
                                                                        <div className="col-md-9">
                                                                            {
                                                                                this.state.appropriateAttachment
                                                                                ?
                                                                                <>
                                                                                    <input disabled className="form-input disabled_input" type="text" value={this.state.golFullname} />
                                                                                </>
                                                                                :
                                                                                <input disabled value={this.state.golFullname} className="form-input disabled_input" type="text" />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: 15 }} />

                                                                    <div className="row">
                                                                        <div className="col-md-3 gol_field_div">
                                                                            <span className="form-attribute">FP&A Name</span> 
                                                                        </div>
                                                                        <div className="col-md-9">
                                                                            <input style={this.state.selectStyle.fpnaName} disabled className="form-input disabled_input" type="text" value={this.state.fpnaName} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="gol_uploaded_documents">
                                                                <div className="row u-align-left">
                                                                    <div className="col-md-6">
                                                                        <div className="gol_uploaded_documents_row">
                                                                            <label>GOL & FP&A Approval Documents</label>
                                                                            <div className="row" style={{display:"flex",alignItems:"center",margin:"10px 0px"}}>
                                                                                <div className="col-md-10" style={{padding:'0px'}}> 
                                                                                    <button onClick={(e)=>this.showGolOffContent()} className="file_name">{this.state.golFilename}</button>
                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    {
                                                                                        this.state.golFilename
                                                                                        ?
                                                                                        <>
                                                                                            <button onClick={(e)=>this.deleteUploadedDoc(3)} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{marginTop: "6px"}}>&nbsp;&nbsp;<i className="fa fa-trash" aria-hidden="true"></i> &nbsp;Delete&nbsp;&nbsp;</button>
                                                                                        </>
                                                                                        :
                                                                                        <div>
                                                                                            <label htmlFor="file-upload1" className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                                                            <input id="file-upload1" 
                                                                                                type="file" style={{ display: 'none' }} 
                                                                                                onChange={(event) => this.myFIleChange(event, "golfilename")} 
                                                                                                accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div className="row" style={{display:"flex",alignItems:"center"}}>
                                                                                    <div className="col-md-10">
                                                                                        <button onClick={(e)=>this.showFpnaContent()} className="file_name">{this.state.fpnaFilename}</button>
                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        {
                                                                                            this.state.fpnaFilename
                                                                                            ?
                                                                                            <>
                                                                                                <button onClick={(e)=>this.deleteUploadedDoc(4)} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{marginTop: "6px"}}>&nbsp;&nbsp;<i className="fa fa-trash" aria-hidden="true"></i> &nbsp;Delete&nbsp;&nbsp;</button>
                                                                                            </>
                                                                                            :
                                                                                            <div>
                                                                                                <label htmlFor="file-upload1" className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                                                                <input id="file-upload1" 
                                                                                                    type="file" style={{ display: 'none' }} 
                                                                                                    onChange={(event) => this.myFIleChange(event, "fpnaFileName")} 
                                                                                                    accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                }
                                                {/* ....latest changes to Client n GOL fields ends here.... */}



                                                {
                                                    (this.state.ideaItem.ApprovalStatus
                                                        && this.state.ideaItem.ApprovalStatus !== "NA"
                                                        && this.state.ideaItem.ApprovalStatus !=="Pending Documents"
                                                        && this.state.ideaItem.ApprovalStatus !=="Completed"
                                                        && this.state.ideaItem.ApprovalStatus !=="Cancelled"
                                                        && this.state.ideaItem.ApprovalStatus !== "Awaiting Project Creation"
                                                    ) && (
                                                        <>
                                                            <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                                <div className="u-container-layout u-container-layout-7" style={{borderTop:"1px solid lightgray", padding:"35px 0px"}}>
                                                                    <p className="u-align-left u-text u-text-2">Approval details</p>
                                                                    <div className="row">
                                                                        <div className="col-md-2"><span className="form-attribute">Approval Status</span> </div>
                                                                        <div className="col-md-2">
                                                                            {
                                                                                (this.state.ideaItem.ApprovalStatus !== 'Pending Documents' && this.state.ideaItem.ApprovalStatus !== 'Cancelled' && this.state.ideaItem.ApprovalStatus !== 'Completed') ? (
                                                                                    <Select isSearchable={false} options={this.renderApprovalStatusOptions(this.state.approvalSelectedOption)} value={this.state.approvalSelectedOption} placeholder="Choose Status" onChange={(e) => this.handleApprovalStatus(e)} styles={this.state.selectStyle.approvalSelectedOption} />
                                                                                ) :
                                                                                    (
                                                                                        <input className="form-input" disabled name="finalapprovalstatus" value={this.state.ideaItem.ApprovalStatus} />
                                                                                    )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-8" style={{ marginBottom: 5, width: "100%" }} />
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                                                <span className="u-text-black form-attribute">Approval Comments<span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}>(max. 500 chars)</span></span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            {
                                                                                (this.state.ideaItem.ApprovalStatus !== 'Pending Documents' && this.state.ideaItem.ApprovalStatus !== 'Cancelled' && this.state.ideaItem.ApprovalStatus !== 'Completed') ? (
                                                                                    <textarea className="textarea-css form-attribute" rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey', ...this.state.selectStyle.finalcomments }} placeholder="Please provide your comments in support of the Approval decision." onChange={(event) => this.handleFinalCommentChange(event.target.value)} />
                                                                                ) : (
                                                                                    <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.FinalComments} />
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }





                                                {
                                                    (this.state.ideaItem.ApprovalStatus && (this.state.ideaItem.ApprovalStatus === COMPLETED_STATUS || this.state.ideaItem.ApprovalStatus === AWAITING_MBB_APPROVAL || this.state.ideaItem.ApprovalStatus === AWAITING_LDT_APPROVAL || this.state.showFinalAdditionalDetails || this.state.ideaItem.ApprovalStatus === AWAITING_QAULITY_CHECK_REVIEW)) && (
                                                        <>
                                                            {
                                                                this.state.IsVDType === "No Value Delivery"
                                                                ?
                                                                <>
                                                                    <div className="no_value_delivery_border"></div>
                                                                </>
                                                                :
                                                                null
                                                            }
                                                            <div className="gol_fields_block text-left" style={{borderBottom:"0px",paddingTop:"10px", borderTop:"1px solid lightgrey"}}>
                                                                <p className="u-align-left u-text u-text-2" style={{margin: "10px 0px 20px"}}>Approval Section</p>
                                                                <div className="row">
                                                                    <div className="col-md-8">
                                                                        <div className="row">
                                                                            <div className="col-md-7 gol_field_div text-left">
                                                                                <span className="form-attribute">The Owner has marked the project as {this.state.impactOptionSelected ? this.state.impactOptionSelected.label : ""}. Do you agree with it?</span> 
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                {
                                                                                    this.state.bbEditable 
                                                                                    ?
                                                                                    <>
                                                                                        <Select value={this.state.bbReview} options={this.renderClientApproval()} onChange={(e)=>this.handleBBReviewIdeaChange(e)} />    
                                                                                    </>
                                                                                    :
                                                                                    <input type="text" className="form-input disabled_input" disabled value={ this.state.ideaItem.IsFPnAApprovalAttached} />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {
                                                                this.state.showCheckBox && this.state.IsVDType !== "No Value Delivery"
                                                                ?
                                                                <>
                                                                    <div className="text-left" style={{borderTop:"1px solid lightgray",paddingBottom:"30xp",padding:"15px 0px"}}>
                                                                        <p className="u-align-left u-text u-text-2" style={{margin: "0px 0px 20px"}}>Project Document Review</p>
                                                                        <div className="row">
                                                                            <div className="col-md-8">
                                                                                <div className="row">
                                                                                    <div className="col-md-7 gol_field_div text-left">
                                                                                        <span className="form-attribute">I have reviewed the Value Delivery supporting documents and confirm that they have the correct stakeholder signoff</span> 
                                                                                    </div>
                                                                                    <div className="col-md-5">
                                                                                        {
                                                                                            this.state.bbEditable
                                                                                            ?
                                                                                            <>
                                                                                                {/* <input type="checkbox" id="signOffCheckbox" checked={this.state.bbReviewCheckbox.label === "Yes" ? true : false} name="signOffCheckbox" onChange={(e)=>this.handleBBCheckboxChange(e)}  />     */}
                                                                                                <input type="checkbox" id="signOffCheckbox" checked={this.state.bbReviewCheckbox ? this.state.bbReviewCheckbox.label === "Yes" ? true : false:""} name="signOffCheckbox" onChange={(e)=>this.handleBBCheckboxChange(e)}  />    
                                                                                            </>
                                                                                            :
                                                                                            <input type="checkbox" id="signOffCheckbox" checked={this.state.ideaItem.IsFPnAApprovalAttached === "Yes" ? true : false} name="signOffCheckbox"  />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                            }
                                                            


                                                            {/* ........................ */}
                                                                {
                                                                    this.state.IsVDMonetizable && this.state.impactOptionSelected && (this.state.impactOptionSelected.label === "Value Delivered to Genpact" || this.state.impactOptionSelected.label === "Value Delivered to Client + Genpact")
                                                                    ?
                                                                    <>
                                                                        <div style={{margin: "20px 0px",borderTop:"1px solid lightgray"}} />                                                    
                                                                        <div className="gol_fields_block text-left" style={{borderTop:"1px solid ligthgrey",borderBottom:"0px"}}>
                                                                            <p className="u-align-left u-text u-text-2" style={{margin: "10px 0px 20px"}}>Monetizable project ?</p>
                                                                            <div className="row">
                                                                                <div className="col-md-8">
                                                                                    <div className="row">
                                                                                        <div className="col-md-4 gol_field_div text-left">
                                                                                            <span className="form-attribute">Is this a monetizable project?</span> 
                                                                                        </div>
                                                                                        <div className="col-md-3">
                                                                                            <Select style={this.state.selectStyle.IsVDMonetizable} options={this.renderClientApproval()} onChange={(e)=>this.handleMonetizableProjectChange(e)} value={this.state.IsVDMonetizable} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                                
                                                                {/* ..................monetization client approval process starts here.......... */}
                                                                    {
                                                                        this.state.monetizableStatus && (this.state.IsVDType === "Value Delivered to Genpact" || this.state.IsVDType === "Value Delivered to Client + Genpact")
                                                                        ?
                                                                        <>
                                                                            <div className="" style={{borderTop:"1px solid ligthgrey", borderTop:"1px solid lightgray"}} />
                                                                            <div className="text-left" style={{paddingBottom:"20px",borderBottom:"0px",paddingTop:"10px"}}>
                                                                                <p className="u-align-left u-text u-text-2" style={{margin: "10px 0px 20px"}}>Monetizable Status ?</p>
                                                                                <div className="row">
                                                                                    <div className="col-md-8">
                                                                                        <div className="row">
                                                                                            <div className="col-md-4 gol_field_div text-left">
                                                                                                <span className="form-attribute">Monetizable Status</span> 
                                                                                            </div>
                                                                                            <div className="col-md-5 text-left">
                                                                                                <Select options={this.renderMonetizableOptions()} onChange={(e)=>this.handleMonetizableOptionsChange(e)} value={this.state.monetizableStatus} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                    }
                                                                    {
                                                                        this.state.showClientApprovalFields
                                                                        ?
                                                                        <>
                                                                            <div className="gol_fields_block" style={{borderTop:"1px solid lightgray",paddingTop:"10px", borderBottom:"0px" }}>
                                                                                <p className="u-align-left u-text u-text-2" style={{margin: "10px 0px 20px"}}>Monetization Client Approval Details</p>
                                                                                <div className="row u-align-left">
                                                                                    <div className="col-md-5">
                                                                                        <div className="row">
                                                                                            <div className="col-md-6 gol_field_div">
                                                                                                <span className="form-attribute">FP&A OHR for Monetization</span> 
                                                                                            </div>
                                                                                            <div className="col-md-6">
                                                                                                <input pattern="\d*" value={this.state.FPnAOHRMonetization} maxLength="9" 
                                                                                                    onCut={(e)=>this.handleFpnaOhrMonetizationonCut(e)} onBlur={(e)=>this.handleFpnaOHRMonetizationChange(e.target.value)} 
                                                                                                    onChange={(e)=>this.handleFpnaOHRMonetizationChange(e.target.value)} 
                                                                                                    className="form-input hide_numeric_arrows" type="number"
                                                                                                    style={this.state.selectStyle.FPnAOHRMonetizationInput}
                                                                                                    onKeyDown={ (evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() }
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{ marginBottom: 15 }} />
                                                                                        <div className="row">
                                                                                            <div className="col-md-6 gol_field_div">
                                                                                                <span className="form-attribute">SOW ID/SFDC/OLI ID</span> 
                                                                                            </div>
                                                                                            <div className="col-md-6">
                                                                                                <input style={this.state.selectStyle.SowID} value={this.state.SowID} onChange={(e)=>this.handleSowIdChange(e.target.value)} className="form-input" type="text" />
                                                                                            </div> 
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-2"></div>
                                                                                    <div className="col-md-5">
                                                                                        <div className="row">
                                                                                            <div className="col-md-5 gol_field_div">
                                                                                                <span className="form-attribute">FP&A Name for Monetization</span> 
                                                                                            </div>
                                                                                            <div className="col-md-7">
                                                                                                <input style={this.state.selectStyle.FPnANameMonetization} disabled className="form-input disabled_input" type="text" value={this.state.FPnANameMonetization} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{ marginBottom: 15 }} />
                                                                                        
                                                                                    </div>
                                                                                </div>

                                                                                <div className="gol_uploaded_documents">
                                                                                    <div className="row u-align-left">
                                                                                        <div className="col-md-6" style={{marginTop:"20px"}}>

                                                                                            {
                                                                                                this.state.FPnAFileNameMonetization
                                                                                                ?
                                                                                                <>
                                                                                                    <div className="">
                                                                                                        <div className="row">
                                                                                                            <div className="col-md-6">
                                                                                                                <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                                    FP&A Sign-off Document for Monetization <small style={{color:"#939393", fontSize:"12px"}}></small>
                                                                                                                </label> 
                                                                                                            </div>
                                                                                                            
                                                                                                        </div>
                                                                                                        <div className="row"  style={{marginTop:"12px"}}>
                                                                                                            <div className="col-md-10" >
                                                                                                                <button onClick={this.showMonetizationContent} className="file_name">{this.state.FPnAFileNameMonetization}</button>
                                                                                                            </div>
                                                                                                            <div className="col-md-2" >
                                                                                                                <button onClick={(e)=>this.deleteUploadedDoc(5)} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{marginTop: "6px"}}>&nbsp;&nbsp;<i className="fa fa-trash" aria-hidden="true"></i> &nbsp;Delete&nbsp;&nbsp;</button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                
                                                                                                </>
                                                                                                :
                                                                                                <div className="">
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-6">
                                                                                                            <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                                FP&A Sign-off Document <small style={{color:"#939393", fontSize:"12px"}}>(Max Size: 5MB)</small>
                                                                                                            </label> 
                                                                                                        </div>
                                                                                                        <div className="col-md-4">
                                                                                                            <label htmlFor="file-upload2" 
                                                                                                                className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                                                                            <input id="file-upload2" 
                                                                                                                type="file" style={{ display: 'none' }} 
                                                                                                                onChange={(event) => this.myFIleChange(event, "fileForMonetization")} 
                                                                                                                accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-10">
                                                                                                            <label style={{ fontWeight: 'normal', fontStyle: 'italic' }}>&nbsp;&nbsp;{this.state.FPnAFileNameMonetization}</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }


                                                                                            {/* <div className="">
                                                                                                <div className="row">
                                                                                                    <div className="col-md-6">
                                                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                            <span style={{color: "red"}}>*</span> FP&A Sign-off Document <small style={{color:"#939393", fontSize:"12px"}}>(Max Size: 5MB)</small>
                                                                                                        </label> 
                                                                                                    </div>
                                                                                                    <div className="col-md-4">
                                                                                                        <label htmlFor="file-upload2" 
                                                                                                            className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                                                                        <input id="file-upload2" 
                                                                                                            type="file" style={{ display: 'none' }} 
                                                                                                            onChange={(event) => this.myFIleChange(event, "fileForMonetization")} 
                                                                                                            accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col-md-10">
                                                                                                        <label style={{ fontWeight: 'normal', fontStyle: 'italic' }}>&nbsp;&nbsp;{this.state.FPnAFileNameMonetization}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                    }
                                                                {/* ..................monetization client approval process ends here.......... */}
                                                            {/* ........................ */}


                                                            <div className="">
                                                                {
                                                                    this.state.businessImpactValueArray.length > 0
                                                                    ?
                                                                    <>
                                                                        <div style={{ borderBottom: '1px solid lightgrey', marginTop: 10, marginBottom: 5 }} />
                                                                        <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{paddingBottom:"63px"}}>
                                                                            <div className="u-container-layout u-container-layout-7">
                                                                                <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                                                    <div className="u-container-layout u-container-layout-8">
                                                                                        <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Final additional details</p>
                                                                                        <div className="row">
                                                                                            <div className="col-md-6"><span className="form-attribute">Final Impact (# of FTE)</span></div>
                                                                                            <div className="col-md-6">
                                                                                                <input className="form-input" disabled name="finalimpactFTE" value={this.state.FinalImpactFTE} />
                                                                                                {/* <input className = "textarea-css form-attribute"  type="number" value={this.state.fteTotal} style={{ border: '1px solid lightgrey', ...this.state.selectStyle.FinalImpactFTE }} /> */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{ marginBottom: 10 }} />
                                                                                        <div className="row">
                                                                                            <div className="col-md-6"><span className="form-attribute">Final Impact ($)</span> </div>
                                                                                            <div className="col-md-6">
                                                                                                <input className="form-input" disabled name="finalimpactdollar" value={this.state.FinalImpactDollar} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{ marginBottom: 5 }} />
                                                                                        <div className="u-align-left u-text u-text-custom-color-3" style={{ fontSize: 11, fontStyle: 'italic' }}>(If Final Impact $ is greater than 250K OR FTE number is greater than 10, please review thoroughly)
                                                                                        </div>
                                                                                        <div style={{ marginTop: 7, marginBottom: 10 }} />
                                                                                        <div className="row">
                                                                                            <div className="col-md-6">
                                                                                                <span className="form-attribute">Is it a Zero Impact Project</span>
                                                                                            </div>
                                                                                            <div className="col-md-6">
                                                                                                <label className="switch">
                                                                                                    {/* <input disabled type="checkbox" id="isZeroImpactProject" checked={this.state.ideaItem.IsZeroImpactProject === null ? false : this.state.ideaItem.IsZeroImpactProject} /> */}
                                                                                                    <input type="checkbox" defaultChecked={this.state.IsVDType === "No Value Delivery" ? true : false} onChange={() => this.showHideZeroImpactProject()} />
                                                                                                    <span className="slider round" />
                                                                                                </label>
                                                                                                
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{ marginTop: 5, marginBottom: 22 }} />
                                                                                    </div>
                                                                                </div>

                                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 22 }}>&nbsp;</p>

                                                                                <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                                                    <div className="u-container-layout u-container-layout-9" style={{ marginTop: 95 }}>
                                                                                        <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: '-120px' }}>&nbsp;</p>
                                                                                        <div className="row">
                                                                                            <div className="col-md-6">
                                                                                                <span className="form-attribute">Best in Class</span>
                                                                                            </div>
                                                                                            <div className="col-md-6">
                                                                                                <label className="switch best_in_class_slider">
                                                                                                    {/* <input disabled type="checkbox" checked={this.state.ideaItem.IsBestInClass === null ? false : this.state.ideaItem.IsBestInClass} /> */}
                                                                                                    <input type="checkbox" checked={this.state.ideaItem.IsBestInClass === null ? false : this.state.ideaItem.IsBestInClass} onChange={() => this.showHideBestInClass()} />
                                                                                                    <span className="slider round" />
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            (this.state.ideaItem.ReplicabilityIndex !== null) && (
                                                                                                <div className="row" style={{ marginTop: 15 }}>
                                                                                                    <div className="col-md-6"><span className="form-attribute">Replicability Index</span> </div>
                                                                                                    <div className="col-md-6">
                                                                                                        {/* <input className ="form-input" disabled value={this.state.ideaItem.ReplicabilityIndex} /> */}
                                                                                                        <Select tabIndex={2} isSearchable={true} value={this.state.replicaIndex} options={this.renderReplicaIndexOptions(this.state.replicaIndex)} placeholder="Choose replicabilty index" onChange={(e) => this.replicaIndexChangeHandler(e)} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        <div style={{ marginTop: 5, marginBottom: 10 }} />

                                                                                        {/* <div className="row">
                                                                                            <div className="col-md-6"><span className="form-attribute">Other Tools</span> </div>
                                                                                            <div className="col-md-6">
                                                                                                <input value={this.state.otherTools} onChange={this.handleOtherToolsChange} placeholder="Other Tools" style={{ border: '1px solid lightgrey', ...this.state.selectStyle.OtherTools }} />
                                                                                            </div>
                                                                                        </div> */}
                                                                                        <div style={{ marginBottom: 15 }} />
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                            {
                                                                this.state.zeroImpact && this.state.summaryArray.length > 0
                                                                ?
                                                                <>
                                                                    <div className="" style={{paddingTop:"15px",borderTop:"1px solid lightgray",}}>
                                                                        <p id="BI Summary" className="u-align-left u-text u-text-2" style={{ margin: "15px 0px", }}>Value Delivery Summary</p>
                                                                        <div className="row" style={{ marginLeft: 10, marginRight: 10 }}>
                                                                            <div className="col-md-2 col-xs-12" style={{ border: "1px solid lightgrey", backgroundColor: 'gainsboro' }}><label >Impact Type</label></div>
                                                                            <div className="col-md-3 col-xs-6" style={{ border: "1px solid lightgrey", backgroundColor: 'gainsboro' }}><label>Category</label></div>
                                                                            <div className="col-md-2 col-xs-6" style={{ border: "1px solid lightgrey", backgroundColor: 'gainsboro', textAlign: 'center' }}><label>FTE Impact (#)</label></div>
                                                                            <div className="col-md-2 col-xs-6" style={{ border: "1px solid lightgrey", backgroundColor: 'gainsboro', textAlign: 'center' }}><label>$ Impact</label></div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                            }

                                                            { this.state.zeroImpact && this.state.summaryArray.length > 0 && this.state.summaryArray.map((currItem, index) => {
                                                                        
                                                                if ((!isNaN(currItem.FTEImpact) && parseFloat(currItem.FTEImpact) > 0) || (!isNaN(currItem.DollarImpact) && parseFloat(currItem.DollarImpact) > 0)) {
                                                                    return (
                                                                        <>
                                                                            <div className="row" style={{ marginLeft: 10, marginRight: 10 }}>
                                                                                <div className="col-md-2 col-xs-12" style={{ border: "1px solid lightgrey" }}>
                                                                                    <label style={{ fontWeight: 100, verticalAlign: 'bottom' }}>{currItem.ImpactType}</label>
                                                                                </div>
                                                                                <div className="col-md-3 col-xs-6" style={{ border: "1px solid lightgrey" }}>
                                                                                    <label style={{ fontWeight: 100, verticalAlign: 'bottom' }}>{currItem.MonetizableType}</label>
                                                                                </div>
                                                                                <div className="col-md-2 col-xs-6" style={{ border: "1px solid lightgrey", textAlign: 'center' }}>
                                                                                    <label style={{ fontWeight: 100, verticalAlign: 'bottom' }}>
                                                                                        {isNaN(currItem.FTEImpact) ? 0 : (currItem.FTEImpact === 0 ? "" :currItem.FTEImpact )}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-md-2 col-xs-6" style={{ border: "1px solid lightgrey", textAlign: 'center' }}>
                                                                                    <label style={{ fontWeight: 100, verticalAlign: 'bottom' }}>
                                                                                        {isNaN(currItem.DollarImpact) ? 0 : currItem.DollarImpact}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                } else {
                                                                    return <></>;
                                                                }
                                                            })}



                                                            {/* ...........monetization amount starts here......... */}
                                                            {
                                                                    this.state.showClientApprovalFields
                                                                    ?
                                                                    <>
                                                                        <div className="">

                                                                            { this.state.businessImpactValueArray.length > 0 && (
                                                                                <>
                                                                                <div className="" style={{borderTop: "1px solid lightgray", marginTop: "35px"}} />
                                                                                <p id="BI Summary" className="u-align-left u-text u-text-2" style={{ marginBottom: 15, marginTop: 15 }}>Total Monetized Amount</p>
                                                                                    <div className="row" style={{ marginLeft: 10, marginRight: 10 }}>
                                                                                        <div className="col-md-2 col-xs-12" style={{ border: "1px solid lightgrey", backgroundColor: 'gainsboro' }}><label >FTE Impact (#)</label></div>
                                                                                        <div className="col-md-3 col-xs-6" style={{ border: "1px solid lightgrey", backgroundColor: 'gainsboro' }}><label>$ Impact</label></div>
                                                                                    </div>
                                                                                </>   
                                                                            )}
                                                                            {
                                                                                this.state.businessImpactValueArray.length > 0
                                                                                ?
                                                                                <>
                                                                                    <>
                                                                                        <div className="row" style={{ marginLeft: 10, marginRight: 10 }}>
                                                                                            <div className="col-md-2 col-xs-12" style={{ border: "1px solid lightgrey" }}>
                                                                                                <label style={{ fontWeight: 100, verticalAlign: 'bottom' }}>{this.state.totalMonetizedFTE}</label>
                                                                                            </div>
                                                                                            <div className="col-md-3 col-xs-6" style={{ border: "1px solid lightgrey" }}>
                                                                                                <label style={{ fontWeight: 100, verticalAlign: 'bottom' }}>{this.state.totalMonetizedDollar}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                </>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                                
                                                                
                                                            {/* ...........monetization amount ends here......... */}





                                                            {/* .........latest bi changes start here... */}
                                                                {
                                                                    this.state.zeroImpact && this.state.showFinalAdditionalDetails && this.state.impactOptionSelected.label !== "No Value Delivery"
                                                                    ?
                                                                    <>
                                                                        {
                                                                            this.state.businessImpactMasterList && this.state.businessImpactMasterList.length > 0 && (
                                                                                <div className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-radius-6 u-shape-round u-group-7">
                                                                                    <div className="u-container-layout u-container-layout-7">
                                                                                        <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />                                                    
                                                                                        <p className="u-align-left u-text u-text-2" style={{ marginBottom: 15, marginTop: 15 }}>Value Delivery Form</p>
                                                                                        <div className="row">
                                                                                            <div className="col-md-3">
                                                                                                <div className="">
                                                                                                    <div className="row" style={{marginTop:15}}>
                                                                                                        <div className="col-md-1">
                                                                                                            <span  style ={{cursor:'pointer'}} className="form-attribute" title="Select Impact Type"></span> </div>
                                                                                                        <div className="col-md-10">
                                                                                                            <Select options={this.renderFilteredImpactType()} value={this.state.filteredImpactTypeSelectedOption} isSearchable={true} placeholder="Select Impact type" onChange={this.handleImpactTypeChange}  />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-4">
                                                                                                <div className="">
                                                                                                    <div className="row" style={{marginTop:15}}>
                                                                                                        <div className="col-md-1">
                                                                                                            <span  style ={{cursor:'pointer'}} className="form-attribute" title="Select Impact Type"></span> </div>
                                                                                                        <div className="col-md-10">
                                                                                                            <Select options={this.renderFilteredMonetizableType()} value={this.state.filteredMonetizableSelectedOption} isSearchable={false} placeholder="Select Category" onChange={this.handleMonetizableTypeChange.bind(this)}  />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            
                                                                                            <div className="col-md-4">
                                                                                                <div className="">
                                                                                                    <div className="row" style={{marginTop:15}}>
                                                                                                        <div className="col-md-1">
                                                                                                            <span  style ={{cursor:'pointer'}} className="form-attribute" title="Select Impact Type"></span> </div>
                                                                                                        <div className="col-md-10">
                                                                                                            <Select options={this.renderFilteredBiSubCategoryType()} value={this.state.filteredBISubCategorySelectedOption} isSearchable={false} placeholder="Select BI Sub Category" onChange={this.handleBiSubCategoryChange.bind(this)}  />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-1" style={{display:"flex",justifyContent:"center", alignItems:"center"}}>
                                                                                                <div className="" style={{width:"100%",marginTop:"10px"}}>
                                                                                                    <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" style={{width:"100%"}} onClick={this.addSelectedValues}>Add</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="u-container-layout u-container-layout-7 bi_summary_latest" style={{marginBottom:"25px"}}>
                                                                                        <div style={{  marginBottom: 15 }} />                                                    
                                                                                        <div className="row" style={{ marginLeft:10, marginRight: 10, justifyContent:"center"}}>
                                                                                            <div className="col-md-2 col-xs-12 bi_summary_latest_header_div">
                                                                                                <label>Impact Type</label>
                                                                                            </div>
                                                                                            <div className="col-md-2 col-xs-12 bi_summary_latest_header_div ">
                                                                                                <label>Category</label>
                                                                                            </div>
                                                                                            <div className="col-md-2 col-xs-12 bi_summary_latest_header_div ">
                                                                                                <label>Sub Category</label>
                                                                                            </div>
                                                                                            <div className="col-md-2 col-xs-12 bi_summary_latest_header_div">
                                                                                                <label>FTE Impact (#)</label>
                                                                                            </div>
                                                                                            <div className="col-md-2 col-xs-12 bi_summary_latest_header_div">
                                                                                                <label>$ Impact</label>
                                                                                            </div>
                                                                                            <div className="col-md-1 col-xs-12 bi_summary_latest_header_div">
                                                                                                <label>Monetizable</label>
                                                                                            </div>
                                                                                            <div className="col-md-1 col-xs-12 bi_summary_latest_header_div">
                                                                                                <label></label>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            this.state.businessImpactValueArray.map((item,index)=>{
                                                                                                const isFtePresent = this.checkIsFtePresent(item);
                                                                                                return(
                                                                                                    <div key={item.pg_srno} className="row" style={{ marginLeft: 10, marginRight: 10,justifyContent:"center" }}>
                                                                                                        <div className="col-md-2 col-xs-12 bi_summary_latest_body_text_elements">
                                                                                                            <label style={{ fontWeight: 100, verticalAlign: 'bottom' }}>{item.ImpactType}</label>
                                                                                                        </div>
                                                                                                        <div className="col-md-2 col-xs-12 bi_summary_latest_body_text_elements">
                                                                                                            <label style={{ fontWeight: 100, verticalAlign: 'bottom' }}>{item.MonetizableType}</label>
                                                                                                        </div>
                                                                                                        <div className="col-md-2 col-xs-12 bi_summary_latest_body_text_elements">
                                                                                                            <label style={{ fontWeight: 100, verticalAlign: 'bottom' }}>{item.BISubCategory}</label>
                                                                                                        </div>
                                                                                                        <div className="col-md-2 col-xs-12 bi_summary_latest_body_text_elements">
                                                                                                            {
                                                                                                                isFtePresent && (
                                                                                                                    <>
                                                                                                                        <input type="number" defaultValue={item.fteValue} min="0" style={{ border: '1px solid lightgrey' }} placeholder={0} max={99} onBlur={(event) => this.handleBIFTEChange(item.ImpactType, item.MonetizableType, item.BISubCategory, event.target.value, index, event, isFtePresent)}  />    
                                                                                                                    </>
                                                                                                                )
                                                                                                            }

                                                                                                            
                                                                                                        </div>
                                                                                                        
                                                                                                        <div className="col-md-2 col-xs-12 bi_summary_latest_body_text_elements">
                                                                                                            <input defaultValue={item.dollarValue} type="number" min="0" max="500000000" style={{ border: '1px solid lightgrey' }} placeholder={0.0} onChange={(event) => this.handleBIDollarChange(item.ImpactType, item.MonetizableType, item.BISubCategory, event.target.value, index, event,isFtePresent)}  />
                                                                                                        </div>
                                                                                                        <div className="col-md-1 col-xs-12 bi_summary_latest_body_text_elements">
                                                                                                            {
                                                                                                                item.ImpactType === "Impact at Genpact" && item.MonetizableType === "Increase in Revenue" && this.state.IsVDMonetizable && this.state.IsVDMonetizable.label==="Yes" && this.state.monetizableStatus &&  this.state.monetizableStatus.label==="Approved by Client" ? 
                                                                                                                <>
                                                                                                                    {
                                                                                                                        this.state.monetizableValues.some(vendor=>vendor['label'] === item.BISubCategory)
                                                                                                                        ?
                                                                                                                        <>
                                                                                                                            <input type="checkbox" checked={item.IsMonetized==='Yes' } onChange={(event)=>this.handleMonetizableCheckboxChange(item.impactType,item.pg_srno, item.monetizableType, item.BiSubCategory,event.target.value, index,event,isFtePresent)} />
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <input disabled={true} type="checkbox"  />
                                                                                                                    }
                                                                                                                    
                                                                                                                </>
                                                                                                                :
                                                                                                                <input disabled={true} type="checkbox"  />
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div className="col-md-1 col-xs-12 bi_summary_latest_body_text_elements">
                                                                                                            <button onClick={() => this.removeBiFormRow(index,item)} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{marginTop:"6px"}}>&nbsp;&nbsp;<i className="fa fa-trash" aria-hidden="true" /> &nbsp;Delete&nbsp;&nbsp;</button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    
                                                                    </>
                                                                    :
                                                                    null
                                                                }

                                                                {
                                                                    this.state.showHrefModal
                                                                    ?
                                                                    <>
                                                                    <div className="custom_modal href_modal">
                                                                        <div className="modal_body href_modal_body">
                                                                            <div className="href_modal_dialog">
                                                                                <p>To add a new category please raise a Helpmate ticket, Click here to View SOP</p>
                                                                                <div className="href_modal_body_buttons">
                                                                                    <div className="btn-group href_modal_body_buttons">
                                                                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"><a href="https://genpactonline.sharepoint.com/:b:/r/sites/LeanDigitalTransformation/Documents/KM%20Page%20-%202021/Certification%20Guidelines,%20SOPs%20%26%20Handbooks/Certification%20guidelines,%20SOPs%20%26%20Handbooks/Systems%20%26%20Tools/Helpmate-Data%20Related%20Request/ProGEAR%20Value%20Delivery%20Category%20Addition%20SOP.pdf?csf=1&web=1&e=5kKbFg" style={{color:"#FFF"}} target="_blank">Click Here</a></button>
                                                                                        <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={(e)=>this.setState({showHrefModal:false})}>Cancel</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    </>
                                                                    :
                                                                    null
                                                                }

                                                                
                                                            {/* ....latest bi changes completes here.... */}
                                                        </>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        (this.state.ideaItem.CertificationLevelName === 'Lean' && (this.state.ideaItem.TransformationLeverName === 'Data' || this.state.ideaItem.TransformationLeverName === 'Continuous Improvement' || this.state.ideaItem.TransformationLeverName === 'Digital') && this.state.ideaItem.ApprovalStatus === 'Completed') && (
                                            <>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 20, marginTop: 10 }} />
                                                <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                    <div className="u-container-layout" style={{padding: "34px 30px 15px 30px"}}>
                                                        <div className="row">
                                                            <div className="col-md-4"><span className="form-attribute">Six Sigma</span> </div>
                                                            <div className="col-md-8">
                                                                <label className="switch">
                                                                    <input type="checkbox" checked={this.state.ideaItem.IsSharedToSixSigma === null ? false : this.state.ideaItem.IsSharedToSixSigma} onChange={() => this.showHideIsSharedToSixSigma()} />
                                                                    <span className="slider round" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    


                                    {/* ............... */}

                                        {
                                            this.state.ideaItem.QCOHR 
                                            ?
                                            <>
                                                <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                    <div className="u-container-layout u-container-layout-7" style={{borderTop:" 1px solid lightgray",paddingTop: "30px"}}>
                                                        <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Quality Check Details Approval</p>
                                                        <div className="row" style={{marginTop:15}}>
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: "-13px" }}>Approval Comments</label></div>
                                                            <div className="col-md-2" style={{marginLeft:20}}>
                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e)=>this.showCommentModal(3)}>Comment Log</button>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{marginTop:5}}>
                                                            <div className="col-md-12">
                                                                <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.FinalComments} />
                                                            </div>
                                                        </div>
                                                        <div className="text-left mt-4">
                                                            {
                                                                this.state.qcCommentLog && this.state.qcComments
                                                                ?
                                                                <>
                                                                    <div className="custom_modal">
                                                                        <div className="modal_body">
                                                                            <div className="row">
                                                                                <div className="col-md-8">
                                                                                    <h3 className="modal_title">QC Approval Comment Log</h3>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <button type="button" className="close text-right" onClick={(e) => this.setState({ qcCommentLog: false })}>&times;</button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                </div>
                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                </div>
                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                </div>
                                                                                <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                </div>
                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                </div>
                                                                                <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                this.state.qcComments.map((item, index) => {
                                                                                    return (
                                                                                        <div className="row" key={index}>
                                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                            </div>
                                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                            </div>
                                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                            </div>
                                                                                            <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole}</label>
                                                                                            </div>
                                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.Action === "Idea was Documents Uploaded" ? "Sent Back" : "Approved"}</label>
                                                                                            </div>
                                                                                            <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment !== null ? item.Comment :'-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                        }
                                        
                                    {/* ................. */}



                                    {
                                        this.state.ideaItem.QCOHR
                                        ?
                                        <>
                                            <div className="gol_fields_block" style={{borderTop:"1px solid lightgray",paddingTop:"20px",borderBottom:"0px"}}>
                                                <p className="u-align-left u-text u-text-2" style={{margin: "0px 0px 20px"}}>Project Review Form</p>
                                                <div className="row u-align-left">
                                                    <div className="col-md-5">
                                                        <div className="row">
                                                            <div className="col-md-9 gol_field_div">
                                                                <span className="form-attribute">Is the impact correctly tagged to Genpact or Client ?</span> 
                                                            </div>
                                                            <div className="col-md-3">
                                                                <Select options={this.renderClientApproval()} value={this.state.impactTagChangeSelected}  onChange={this.handleImpactTagChange.bind(this)} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-9 gol_field_div">
                                                                <span className="form-attribute">Has the value delivered been correctly categorized ?</span> 
                                                            </div>
                                                            <div className="col-md-3">
                                                                <Select options={this.renderClientApproval()} value={this.state.deliveryCategorizedSelect}  onChange={(e)=>this.handleDeliveryCategorizedChange(e)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2"></div>
                                                    <div className="col-md-5">
                                                        <div className="row">
                                                            <div className="col-md-9 gol_field_div">
                                                                <span className="form-attribute">Has the value delivered been computed correctly ?</span> 
                                                            </div>
                                                            <div className="col-md-3">
                                                                <Select options={this.renderClientApproval()} value={this.state.deliveryComputedCorrectlySelected} onChange={(e)=>this.handledeliveryComputedCorrectlySelectedChange(e)} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-9 gol_field_div">
                                                                <span className="form-attribute">Do the value delivery approvals (applicable as per the value playbook) clearly articulate what improved and for which time-period ?</span> 
                                                            </div>
                                                            <div className="col-md-3">
                                                                <Select options={this.renderClientApproval()} value={this.state.signOffDeliverySelected}  onChange={(e)=>this.handleSignOffDeliveryChange(e)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                    }
                                    <div style={{ borderBottom: '1px solid lightgrey', marginTop: 10, marginBottom: 25 }} />
                                    <div className="btn-group">
                                        <button tabIndex={20} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.UpdateIdea}>Update</button>
                                        <button tabIndex={21} className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.cancelEvent}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default AdminEditPage
