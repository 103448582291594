import React, { Component,useLocation } from 'react'
import { GET_IDEA_URL, UPDATE_IDEA_URL, APPROVED,GET_METADATA,DELEGATE_IDEA_URL, PENDING, SENT_BACK, REJECTED, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, ACCESS_TOKEN, USER_DATA, PROJECT_VIEW, PPT_CONTENT, SIGNOFF_CONTENT, REVIEW_IDEA_URL, REQUEST_CANCELLATION,GOL_APPROVAL_CONTENT,FPnA_APPROVAL_CONTENT } from '../assets/constants/constants';
import Toast from 'light-toast';
import ShowTree from '../utils/ShowTree';


export class Hirearchy extends Component {
  sessionData = JSON.parse(localStorage.getItem(USER_DATA));
  accessToken = localStorage.getItem(ACCESS_TOKEN);
  constructor(props) {
    super(props)
    console.log(props)
    let propsData = this.props.location;
    this.state = {
      ideaItem: {},
      clusterData: [],
      ideaID: null,
      AlphaID:""
    }
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const ID = searchParams.get('ID');
    Toast.loading("Fetching Cluster data...", () => { });
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization":  "Bearer " + this.accessToken,
      }
    };
    // fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}`, requestOptions)
    fetch(`${GET_IDEA_URL}IdeaId=${ID}&RecID=''&isItIdeaOrGenAI='Idea'`, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data)
        this.setState({
          ideaItem: data,
          AlphaID:data.ResultSets.Table1[0].IdeaIDAlpha,
          clusterData: data.ClusterData ? data.ClusterData.Table1 : [],
          ideaID: data.ResultSets ? data.ResultSets.Table1[0].IdeaId : []
        }, () => {
          console.log(this.state.ideaItem)
          Toast.hide()
        });
      })
      .catch(error => {
        Toast.fail("Error while fetching cluster data. Please try again!", 3000, () => { });
        Toast.hide();
      });
  }

  render() {


    return (
      <section className="u-clearfix u-section-1" id="sec-0f7f">
        <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
          <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
            <div className="u-container-layout u-container-layout-1">
              <p className="u-align-left u-text u-text-2">Cluster tree hierarchy {this.state.AlphaID}</p>
              <div className="">
                {
                  this.state.clusterData && this.state.ideaID
                  ?
                  <>
                    <ShowTree ideaID={this.state.ideaID} cluster={this.state.clusterData} />
                  </>
                  :
                  null
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Hirearchy