import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";

function RenewToken() {
    const [token, setToken] = useState(null);
    const { oktaAuth, authState } = useOktaAuth();
    useEffect(() => {
        const renewToken = async () => {
            try {
                const tokens = await oktaAuth.tokenManager.renew("accessToken");
                setToken(tokens.accessToken);
            } catch (error) {
                // Handle token renewal failure (e.g., session expired)
                console.error("Token renewal error:", error);
                // Perform logout and redirect to the login page
                logout();
            }
        };

        // Renew token every hour
        const renewalInterval = setInterval(renewToken, 60 * 60 * 1000);

        // Logout after 4 hours
        const logoutTimeout = setTimeout(() => {
            logout();
        }, 4 * 60 * 60 * 1000);

        const logout = async () => {
            await oktaAuth.signOut();
            oktaAuth.tokenManager.clear();
        };
        // this code is added on 15-01-2024, for missing header links
        if (!authState.isPending && !authState.isAuthenticated) {
            logout()
        }
        // Cleanup intervals on component unmount
        return () => {
            clearInterval(renewalInterval);
            clearTimeout(logoutTimeout);
        };
    }, []);
    return <div></div>;
}

export default RenewToken;
