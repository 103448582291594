import React, { Component } from 'react'
import { GET_IDEA_URL, APPROVED, PENDING, SENT_BACK, REJECTED, DISPLAYPROJECTTHEME, GET_METADATA, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, ACCESS_TOKEN, PROJECT_VIEW, COMPLETED_STATUS, AWAITING_MBB_APPROVAL, AWAITING_LDT_APPROVAL, PPT_CONTENT, SIGNOFF_CONTENT, USER_DATA, REVIEW_IDEA_URL, REQUEST_CANCELLATION, AWAITING_QAULITY_CHECK_REVIEW, YESORNO,REPLICABILITYOPTIONS,DB_PEF_DATE,AI_DOC_LINK, AIFIRST } from '../assets/constants/constants';
import Toast from 'light-toast';
import { selectValidation as selectStyle, textBoxValidation } from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";
import Select from 'react-select';
import UpdatedSpeech from './UpdatedSpeech';
import ValueDelivery from '../utils/ValueDelivery';
import FPNAGOL from '../utils/FPNAGOL';
import DisplayTemplate from '../utils/DisplayTemplates';

export class ViewIdeaPage extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);
    constructor(props) {
        super(props)
        const isApprovalPage = `${this.props.location.approval}`;
        let propsData = this.props.location;
        this.state = {
            ideaItem: {},
            workflowStatus: "",
            Comment: "",
            projectDeckFilename: "",
            signOffFilename: "",
            projectDeckContent: null,
            signOffContent: null,
            signOffFileType: "",
            finalComment: "",
            accountName: "",
            campaignName: "",
            serviceLineName: "",
            showFinalAdditionalDetails: false,
            workFlowStatusList: [],
            workFlowStatusSelectedOption: null,
            certificationLevelsList: [],
            certLevelsSelectedOption: null,
            certificationWorkTypeList: [],
            certWorkTypeSelectedOption: null,
            transformationLeversList: [],
            transSelectedOption: null,
            methodologyList: [],
            methodologySelectedOptions: null,
            expectedDate: new Date(),
            finalApproveOptionsValue: [
                {
                    WorkflowStatusId: 0,
                    WorkflowStatusName: "Approved"
                },
                {
                    WorkflowStatusId: 1,
                    WorkflowStatusName: "Rejected"
                },
                {
                    WorkflowStatusId: 2,
                    WorkflowStatusName: "Send Back"
                },
                {
                    WorkflowStatusId: 3,
                    WorkflowStatusName: "Rework"
                },
            ],
            finalImpactDollar: 0.0,
            finalImpactFTE: 0.0,
            typeOfImpactList: [],
            businessImpactCategoriesList: [],
            businessImpactSubCategoriesList: [],
            typeOfImpactSelectedOption: null,
            businessImpactSelectedOption: null,
            businessSubCatSelectedOption: null,
            finalTransSelectedOption: null,
            otherTools: "",
            showSourceId: false,
            sourceId: "1234",
            bestClass: false,
            approvalSelectedOption: null,
            showBIForm: true,
            businessImpactMasterList: null,
            businessImpactValueArray: [],
            fteTotal: 0,
            dollarTotal: 0.0,
            dataBridgeCampaign: false,
            xPollinationCampaign: false,
            zeroImpact: false,
            summaryArray: [],
            approvalPage: isApprovalPage,
            replicaIndexOption: [1, 2, 3, 4, 5],
            replicaIndex: 0,
            selectStyle: {},
            reviewComments: [],
            propsData: propsData,
            Role: "",
            speechModal: false,
            previousTextForSpeech: "",
            CommentEmpty: false,
            filteredImpactTypeState: [],
            filteredImpactTypeSelectedOption: null,
            filteredMonetizableTypeState: [],
            filteredMonetizableSelectedOption: null,
            filteredBISubCategoryState: [],
            filteredBISubCategorySelectedOption: null,
            BiArray: [],
            ideaCreatedDate: "",
            showHistoricalBI: false,
            setGreyOutButton: false,
            disableAddButton: false,
            zeroImpactForLastestBI: false,
            CompletedDate: "",

            showClientFields: false,
            showGOLFields: false,

            golFilename: "",
            golContent: "",
            golFileType: "",
            golOhr: "",
            golEmail: "",
            golFullname: "",

            fpnaFilename: "",
            fpnaContent: "",
            fpnaFileType: "",
            fpnaName: "",
            fpnaEmail: "",
            fpnaOhr: "",
            fpnaApprovalAttachedSelectedOption: null,

            clientName: "",
            clientRole: "",
            clientSignOffDate: "",
            clientApprovalEmailSelectedOption: null,
            submitIdeaCount: 0,


            impactTagged: [{
                impactTaggedValue: 0,
                impactTaggedLabel: "Yes"
            },
            {
                impactTaggedValue: 1,
                impactTaggedLabel: "No"
            }
            ],

            impactTagChangeSelected: "",
            deliveryCategorizedSelect: "",
            deliveryComputedCorrectlySelected: "",
            signOffDeliverySelected: "",
            finalCommentLog: "",
            QcComment: "",
            qcCommentLog: false,
            qcComments: [],
            adminUpdated: false,
            QCUser: false,
            approvalStatus: "",
            IsAdmin: false,
            qcCommentsWithVD: [],
            qcLastComment: "",
            golFpnaFileDetails: [],
            isFpnaRequired: false,
            clusterData: [],
            isClusterSaveDisabled: true,
            displayParentAlpha: '',
            displayProjectTheme: false,
            ideasDB: [],
            ideasDBDoc: [],
            replicabilityIndex:[],
            displayDBFields:false,

            campaignList: [],
            campaignInsight:"",

            //AI
            ideasAI: [],
            isIdeaReplicable:"",


        }
        this.isExceedlimit = false;
        this.isFtePresent = false;



    }

    async componentDidMount() {
        Toast.loading("Fetching Idea & Metadata...", () => { });
        await this.fetchIdea();
        // Toast.loading("Fetching Metadata...", () => { });
        await this.fetchMetadata();
        Toast.hide();
    }

    formatDateString(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
    }

    formatDateStringWithTime(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        });
    }

    fetchPPTContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let projectFileType = this.state.projectFileType;
            // fetch(`${GET_IDEA_URL}IdeaId=964&${PPT_CONTENT}=true`, requestOptions)
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    const projectDeckMetadata = data.PPTContent ? data.PPTContent : "";
                    const projectContent = "data:" + projectFileType + ";base64," + projectDeckMetadata;
                    this.setState({
                        projectDeckContent: projectContent,
                    }, () => {
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching Project Deck content. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }

    fetchSignoffContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let signoffFileType = this.state.signOffFileType;
            // fetch(`${GET_IDEA_URL}IdeaId=964&${SIGNOFF_CONTENT}=true`, requestOptions)
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${SIGNOFF_CONTENT}=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    const signoffMetadata = data.SignoffContent ? data.SignoffContent : "";
                    const signoffContent = "data:" + signoffFileType + ";base64," + signoffMetadata;
                    this.setState({
                        signOffContent: signoffContent,
                    }, () => {
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching Project Deck content. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }

    // function to fetch gol file data
    fetchGolOffContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let golFileType = this.state.ideaItem.GOLFileType;
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&golcontent=true`, requestOptions)
                .then(response => response.json())
                .then(data => {

                    const golMetadata = data.golContent ? data.golContent : "";
                    const golContent = "data:" + golFileType + ";base64," + golMetadata;
                    this.setState({
                        golContent: golContent,
                    }, () => {
                        resolve();
                        Toast.hide()
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching GOL Approval content. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }
    showGolOffContent = () => {
        Toast.loading("Fetching GOL Approval content...", () => { });
        this.fetchGolOffContent()
            .then(() => {
                var fileData = this.state.golContent;
                var fileName = this.state.ideaItem.GOLFileName;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }


    // function to fetch fpna file data
    fetchFpnaContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let fpnaFileType = this.state.ideaItem.FPnAFileType;
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&fpnacontent=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    const golMetadata = data.fpnaContent ? data.fpnaContent : "";
                    const golContent = "data:" + fpnaFileType + ";base64," + golMetadata;
                    this.setState({
                        fpnaContent: golContent,
                    }, () => {
                        resolve();
                        Toast.hide()
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching FP&A Approval content. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }
    showFpnaContent = () => {
        Toast.loading("Fetching FP&A Approval content...", () => { });
        this.fetchFpnaContent()
            .then(() => {
                var fileData = this.state.fpnaContent;
                var fileName = this.state.ideaItem.FPnAFileName;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    fetchIdea = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };

            // fetch(`${GET_IDEA_URL}IdeaId=944`, requestOptions)
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&RecID=''&isItIdeaOrGenAI='Idea'`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("fetch idea", data)
                    // const projectDeckMetadata = data.ResultSets.Table1[0].ProjectPPTDeck ? data.ResultSets.Table1[0].ProjectPPTDeck : "";
                    // const signOffMetadata = data.ResultSets.Table1[0].CustomerLeadSignOff ? data.ResultSets.Table1[0].CustomerLeadSignOff : "";
                    const projectFilename = data.ResultSets.Table1[0].ProjectPPTFileName ? data.ResultSets.Table1[0].ProjectPPTFileName : "";
                    const signOffFilename = data.ResultSets.Table1[0].CustomerSignoffFileName ? data.ResultSets.Table1[0].CustomerSignoffFileName : "";
                    const projectFileType = data.ResultSets.Table1[0].ProjectPPTType ? data.ResultSets.Table1[0].ProjectPPTType : "";
                    const signOffFileType = data.ResultSets.Table1[0].CustomerSignoffType ? data.ResultSets.Table1[0].CustomerSignoffType : "";

                    // const projectContent = "data:" + projectFileType + ";base64," + projectDeckMetadata;
                    // const signOffContent = "data:" + signOffFileType + ";base64," + signOffMetadata;

                    this.setState({
                        ideaItem: data.ResultSets.Table1[0],
                        WorkflowStatus: data.ResultSets.Table1[0].WorkflowStatus,
                        Comment: data.ResultSets.Table1[0].Comment,
                        showSourceId: data.ResultSets.Table1[0].TransformationLeverName === "Digital" ? true : false,
                        projectDeckFilename: projectFilename,
                        signOffFilename: signOffFilename,
                        projectFileType: projectFileType,
                        signOffFileType: signOffFileType,
                        // projectDeckContent: projectContent,
                        // signOffContent: signOffContent,
                        reviewComments: data.ReviewComments.Table1,
                        finalReviewComments: data.FinalReviewComments.Table1,
                        sourceId: data.ResultSets.Table1[0].SourceId,
                        campaignName: data.ResultSets.Table1[0].CampaignName,
                        dataBridgeCampaign: (data.ResultSets.Table1[0].CampaignName === DATA_BRIDGE_CAMPAIGN),
                        xPollinationCampaign: (data.ResultSets.Table1[0].CampaignName === X_POLLINATION_CAMPAIGN),
                        businessImpactValueArray: data.BIData ? (data.BIData.Table1 ? (data.BIData.Table1.length > 0 ? data.BIData.Table1 : []) : []) : [],
                        ideaCreatedDate: data.ResultSets.Table1[0].CreatedDate,
                        qcComments: data.QcComments.Table1 ? data.QcComments.Table1 : [],
                        qcCommentsWithVD: data.QcComments.Table2 ? data.QcComments.Table2 : [],
                        clusterData: data.ClusterData.Table1 ? data.ClusterData.Table1 : [],

                        // DB
                        ideasDB: data.ResultSets.Table3 && data.ResultSets.Table3[0].Result === "NULL" ? [] : data.ResultSets.Table3[0],
                        ideasDBDoc: data.ResultSets.Table4 && data.ResultSets.Table4[0].Result === "NULL" ? [] : data.ResultSets.Table4[0],
                        ideasAI: data.ResultSets.Table2 ? data.ResultSets.Table2[0] : [],

                    }, () => {

                        if (this.state.ideaItem.CreatedDate) {
                            let db_idea_created = new Date(this.state.ideaItem.CreatedDate);
                            let abc = new Date(DB_PEF_DATE);
                            if (db_idea_created.getTime() < abc.getTime()) {
                              this.setState({displayDBFields:false},()=>{})
                              console.log("created date is lesser")
                            } else {
                              this.setState({displayDBFields:true},()=>{})
                              console.log("created date is greater")
                            }
                        }

                        

                        if(this.state.ideaItem.ReplicabilityIndex){
                            REPLICABILITYOPTIONS.map((item)=>{
                                if(item.value === this.state.ideaItem.ReplicabilityIndex){
                                    this.setState({replicabilityIndex:item},()=>{})
                                }
                            })
                        }
                        if (this.state.clusterData && this.state.clusterData.length > 0) {
                            const targetRecID = this.state.ideaItem.LeanID;
                            const filteredData = this.state.clusterData.filter(item => item.RecAlpha === targetRecID);
                            console.log(filteredData)
                            if (filteredData && filteredData.length > 0 && filteredData !== undefined && filteredData !== null) {
                                this.setState({ displayParentAlpha: filteredData[0].ParentAlpha }, () => { })
                                let options = [];
                                options.push({ value: 0, label: filteredData[0].ParentAlpha })
                                this.setState({ projectIDSelectedOption: options }, () => { })

                                let options1 = [];
                                options1.push({ value: 0, label: "GB" })
                                this.setState({ projectTypeSelectedOption: options1 }, () => { })
                            } else {
                                this.setState({ displayParentAlpha: "" }, () => { })
                            }
                        } else {
                            this.setState({ displayParentAlpha: "" }, () => { })
                        }

                        if (this.state.ideaItem.CreatedDate) {
                            let created = new Date(this.state.ideaItem.CreatedDate);
                            let abc = new Date(DISPLAYPROJECTTHEME);
                            if (created.getTime() < abc.getTime()) {
                                //console.log("created date is lesser")
                                this.setState({ displayProjectTheme: true })
                            } else {
                                this.setState({ displayProjectTheme: false })
                                //console.log("created date is greater ")
                            }
                        }


                        let sortedBiImpactvalueArray = this.state.businessImpactValueArray.sort((a, b) => a.ImpactType.toLowerCase().localeCompare(b.ImpactType.toLowerCase()))
                        this.setState({ businessImpactValueArray: sortedBiImpactvalueArray })

                        if (this.state.reviewComments) {
                            let logAction = this.state.reviewComments.slice(-1)[0].Action
                            this.setState({
                                logAction: logAction
                            });

                        }
                        let localSummaryArray = [];
                        let dataArray = [...this.state.businessImpactValueArray];
                        let localIndex = 0;
                        let dataElement;
                        for (let dataIndex = 0; dataIndex < dataArray.length; dataIndex++) {
                            dataElement = dataArray[dataIndex];
                            localIndex = 0;
                            for (; localIndex < localSummaryArray.length; localIndex++) {
                                const element = localSummaryArray[localIndex];
                                if (element.impacttype === dataElement.ImpactType && element.monetizableType === dataElement.MonetizableType) {
                                    localSummaryArray[localIndex].fteValue = parseFloat(element.fteValue) + parseFloat(dataElement.FTEImpact);
                                    localSummaryArray[localIndex].dollarValue = parseFloat(element.dollarValue) + parseFloat(dataElement.DollarImpact);
                                    break;
                                }
                            }
                            if (localIndex === localSummaryArray.length) {
                                localSummaryArray[localIndex] = {};
                                localSummaryArray[localIndex].impacttype = dataElement.ImpactType;
                                localSummaryArray[localIndex].monetizableType = dataElement.MonetizableType;
                                localSummaryArray[localIndex].fteValue = dataElement.FTEImpact ? parseFloat(dataElement.FTEImpact) : 0.0
                                localSummaryArray[localIndex].dollarValue = dataElement.DollarImpact ? parseFloat(dataElement.DollarImpact) : 0.0;
                            }
                        }
                        let sortedSummaryIndex = localSummaryArray.sort((a, b) => a.impacttype.toLowerCase().localeCompare(b.impacttype.toLowerCase()))

                        this.setState({
                            summaryArray: sortedSummaryIndex,
                        }, () => { });


                        if (this.state.ideaItem.CustomerSignoffFileName !== null && this.state.ideaItem.CustomerSignoffFileName !== undefined && this.state.ideaItem.CustomerSignoffFileName.length !== 0) {
                            this.setState({ showClientFields: true }, () => { })
                        }
                        if (this.state.ideaItem.GOLFileName !== null && this.state.ideaItem.GOLFileName !== undefined && this.state.ideaItem.GOLFileName.length !== 0) {
                            this.setState({ showGOLFields: true }, () => { })
                        }
                        if (this.state.ideaItem.FPnAFileName !== null && this.state.ideaItem.FPnAFileName !== undefined && this.state.ideaItem.FPnAFileName.length !== 0) {
                            this.setState({ showGOLFields: true }, () => { })
                        }


                        if (this.state.ideaItem.IsVDMonetizable === "Yes" && this.state.ideaItem.MonetizationStatus === "Approved by Client") {
                            let MonetizedBiArray = [];
                            this.state.businessImpactValueArray.filter((item) => {
                                if (item.IsMonetized === "Yes") {
                                    MonetizedBiArray.push(item)
                                }
                            })
                            const groupedData = MonetizedBiArray.reduce((accumulator, currentValue) => {
                                const ImpactType = currentValue.ImpactType;
                                const FTEImpact = parseFloat(currentValue.FTEImpact) || 0.0;
                                const DollarImpact = parseFloat(currentValue.DollarImpact) || 0.0;
                                const existingRow = accumulator.find(row => row.ImpactType === ImpactType);
                                if (existingRow) {
                                    existingRow.FTEImpact += FTEImpact
                                    existingRow.DollarImpact += DollarImpact
                                }
                                else {
                                    accumulator.push({ ImpactType, FTEImpact, DollarImpact });
                                }
                                return accumulator;
                            }, []);
                            this.setState({
                                totalMonetizedFTE: groupedData[0].FTEImpact,
                                totalMonetizedDollar: groupedData[0].DollarImpact
                            }, () => { })
                        }



                        if (this.state.ideasAI) {
                            if(this.state.ideasAI.IsIdeaReplicable ){
                                this.setState({ isIdeaReplicable: "Yes" }, () => { })
                            }else{
                                this.setState({ isIdeaReplicable: "No" }, () => { })
                            }
                        }






                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching idea. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }



    fetchMetadata = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "",
                EntityName: ""
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
                .then(response => response.json())
                .then(data => {
                    // console.log("meta data",data)
                    this.setState({
                        adminUserList: data ? data.DataverseUserRole : [],
                        businessImpactMasterList: data ? data.BIMasterDataVerseNew : [],
                        campaignList: data ? data.Campaigns : [],
                    }, () => {
                        if(this.state.ideaItem.CampaignName && this.state.campaignList){
                            this.state.campaignList.map((campaign)=>{
                              if(campaign.CampaignName === this.state.ideaItem.CampaignName){
                                this.setState({campaignInsight:campaign.CampaignDescr},()=>{})
                              }
                            })
                            
                        }
                        this.state.adminUserList.map((item) => {
                            if (parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
                                console.log("this is admin")
                                let userDataInfo = JSON.parse(item.pg_Role)
                                this.setState({ IsAdmin: true, adminOHR: parseInt(item.pg_ohr), userRole: userDataInfo.pg_role, userRoleId: userDataInfo.pg_pgroleid }, () => {
                                    console.log("is admin true/false", this.state.IsAdmin, "admin ohr", this.state.adminOHR)
                                });
                                return false;
                            }
                            return false;
                        });
                        // this.state.adminUserList.filter((item)=>{
                        //     const userRole = JSON.parse(item.pg_Role).pg_role;
                        //     if((userRole === "QC" )&& (parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr))){
                        //         this.setState({QCUser:true},()=>{
                        //             console.log(this.state.QCUser)
                        //         })
                        //     }
                        //     const adminRole = item.pg_roledescr;
                        //     if((adminRole === "Administrator") && (parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr))){
                        //         this.setState({adminUpdated:true},()=>{
                        //             console.log(this.state.adminUpdated)
                        //         })
                        //     }
                        // })



                        // let nonAdminUser = [];
                        // nonAdminUser = data.DataverseUserRole;
                        // nonAdminUser.map((item)=>{
                        //     console.log(item.pg_Role)
                        //     console.log(item.pg_isadmin)
                        //     console.log(item.pg_ohr)
                        //     console.log(item.pg_username)
                        // })
                        resolve();
                    })
                })
                .catch(error => {
                    Toast.fail('Error occured while getting metadata idea. Please try again!', 3000, () => {
                        console.log(JSON.stringify(error));
                        this.setState({
                            businessImpactMasterList: []
                        }, () => {
                            resolve();
                        })
                    });

                });
        });
    }



    showPPTContent = () => {
        Toast.loading("Fetching Project PPT Deck content...", () => { });
        this.fetchPPTContent()
            .then(() => {
                var fileData = this.state.projectDeckContent;
                var fileName = this.state.projectDeckFilename;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    showSignOffContent = () => {
        Toast.loading("Fetching Customer Sign-off content...", () => { });
        this.fetchSignoffContent()
            .then(() => {
                var fileData = this.state.signOffContent;
                var fileName = this.state.signOffFilename;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    validate = () => {
        let isValid = true;
        let styleStateObj = this.state.selectStyle;

        if (this.state.admin && this.state.ideaItem.CertificationLevelName === 'Lean') {
            if (this.state.ideaItem.TransformationLeverName === 'Data' || this.state.ideaItem.TransformationLeverName === 'Continuous Improvement') {
                if (this.state.leanIdeaSelectedOption === null) {
                    Toast.fail("Please select Lean/Lean+idea.", 3000);
                    isValid = false;
                    styleStateObj.leanIdeaSelectedOption = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
                else {
                    styleStateObj.leanIdeaSelectedOption = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
            }
        }
        if (!this.state.QcComment || this.state.QcComment === "") {
            isValid = false;
            styleStateObj.QcComment = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            if (this.state.QcComment.length > 500) {
                Toast.fail("Comment cannot be more than 500 characters, please change and resubmit.", 3000);
                isValid = false;
                styleStateObj.QcComment = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else {
                styleStateObj.QcComment = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                this.setState({
                    selectStyle: styleStateObj
                })
            }
        }

        if (this.state.impactTagChangeSelected === "") {
            isValid = false;
            styleStateObj.impactTagChangeSelected = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.impactTagChangeSelected = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }

        if (this.state.deliveryCategorizedSelect === "") {
            isValid = false;
            styleStateObj.deliveryCategorizedSelect = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.deliveryCategorizedSelect = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }

        if (this.state.deliveryComputedCorrectlySelected === "") {
            isValid = false;
            styleStateObj.deliveryComputedCorrectlySelected = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.deliveryComputedCorrectlySelected = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }


        if (this.state.signOffDeliverySelected === "") {
            isValid = false;
            styleStateObj.signOffDeliverySelected = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.signOffDeliverySelected = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }

        if (this.state.approvalSelectedOption === null || this.state.approvalSelectedOption === "") {
            isValid = false;
            styleStateObj.approvalSelectedOption = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.approvalSelectedOption = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        if (this.state.approvalSelectedOption === null) {

        }

        return isValid;
    }

    handleCommentChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.QcComment = textBoxValidation(true);
        let commentChange = e;
        this.setState({
            QcComment: commentChange
        });
    }


    renderLeanIdeaOptions = () => {
        let options = [];
        this.state.leanIdeaSelectedOptionList.map((item) => {
            options.push({
                value: item.value,
                label: item.label
            })
            return options;
        });
        return options;
    }

    toggleTranscriptFunction = (id) => {
        this.setState({ speechModal: !this.state.speechModal, modalIndex: id })
        if (id === 15) {
            if (this.state.Comment) {
                this.setState({ previousTextForSpeech: this.state.QcComment }, () => console.log(this.state.previousTextForSpeech))
            } else {
                this.setState({ previousTextForSpeech: "" }, () => console.log(this.state.previousTextForSpeech))
            }
        }

    }

    closeModalFunction = () => {
        this.setState({ speechModal: false })
    }


    renderStatusOptions = () => {
        let options = [];
        if (this.state.impactTagChangeSelected.label === "Yes" &&
            this.state.deliveryCategorizedSelect.label === "Yes" &&
            this.state.deliveryComputedCorrectlySelected.label === "Yes" &&
            this.state.signOffDeliverySelected.label === "Yes") {
            this.state.finalApproveOptionsValue.map((item) => {
                options.push({
                    value: item.WorkflowStatusId,
                    label: item.WorkflowStatusName
                })
                return options;
            });
        } else {
            this.state.finalApproveOptionsValue.slice(-3).map((item) => {
                options.push({
                    value: item.WorkflowStatusId,
                    label: item.WorkflowStatusName
                })
                return options;
            });
        }



        return options;
    }

    handleApprovalStatus = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.approvalSelectedOption = selectStyle(true);
        this.setState({
            approvalSelectedOption: {
                value: e.value,
                label: e.label
            }
        }, () => { });
    }


    renderFieldsOptions = () => {
        let options = [];
        this.state.impactTagged.map((item) => {
            options.push({
                value: item.impactTaggedValue,
                label: item.impactTaggedLabel
            })
            return options;
        });
        return options;
    }

    handleImpactTagChange = (e) => {
        if (e !== null && e !== undefined) {
            this.setState({ impactTagChangeSelected: e }, () => {
                this.setState({ approvalSelectedOption: "" }, () => { })
            })
            let styleStateObj = this.state.selectStyle;
            styleStateObj.impactTagChangeSelected = selectStyle(true);
        }
    }
    handleDeliveryCategorizedChange = (e) => {
        if (e !== null && e !== undefined) {
            this.setState({ deliveryCategorizedSelect: e }, () => {
                this.setState({ approvalSelectedOption: "" }, () => { })
            })
            let styleStateObj = this.state.selectStyle;
            styleStateObj.deliveryCategorizedSelect = selectStyle(true);
        }
    }
    handledeliveryComputedCorrectlySelectedChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.deliveryComputedCorrectlySelected = selectStyle(true);
        if (e !== null && e !== undefined) {
            this.setState({ deliveryComputedCorrectlySelected: e }, () => {
                this.setState({ approvalSelectedOption: "" }, () => { })
            })
        }

    }
    handleSignOffDeliveryChange = (e) => {
        if (e !== null && e !== undefined) {
            this.setState({ signOffDeliverySelected: e }, () => {
                this.setState({ approvalSelectedOption: "" }, () => { })
            })
            let styleStateObj = this.state.selectStyle;
            styleStateObj.signOffDeliverySelected = selectStyle(true);
        }
    }
    submitIdea = (e) => {
        e.preventDefault();
        if (this.validate()) {
            this.finalApprover();
            if (this.state.approvalSelectedOption) {
                let warningShown = false;
                if (this.state.approvalSelectedOption.label === "Approved") {
                    if (window.confirm("Are you sure you want to submit the lean project?")) {
                        warningShown = true;
                    }
                }
                if (this.state.approvalSelectedOption.label === "Rework") {
                    if (window.confirm("You are about to return the project for re-work, please ensure that you have clearly indicated the necessary changes  in the comments.")) {
                        warningShown = true;
                    }
                }
                if (this.state.approvalSelectedOption.label === "Send Back") {
                    if (window.confirm("You are about to send back the project for corrections, please ensure that you have clearly indicated the necessary changes  in the comments.")) {
                        warningShown = true;
                    }
                }
                if (this.state.approvalSelectedOption.label === "Rejected") {
                    if (window.confirm("Are you sure you want to reject the project? Please note that once rejected, the project will get cancelled and no further action can be taken on the same. If you are looking for corrections to the Project, please use Send Back or Rework options.")) {
                        warningShown = true;
                    }
                }
                if (warningShown) {
                    Toast.loading("Updating lean project...", () => { });
                    let param = {
                        IdeaName: this.state.ideaItem.IdeaName,
                        CertificationLevelId: this.state.ideaItem.CertificationLevelId,
                        CertificationWorkTypeName: this.state.ideaItem.CertificationWorkTypeName,
                        CertificationLevelName: this.state.ideaItem.CertificationLevelName,
                        DigitalProjectType: this.state.ideaItem.DigitalProjectType,
                        IsDigital: this.state.ideaItem.IsDigital,
                        IsReplicationVerified: this.state.ideaItem.IsReplicationVerified,
                        LDTId: this.state.ideaItem.LDTId,
                        LDTName: this.state.ideaItem.LDTLeadName,
                        MethodologyTypeID: this.state.ideaItem.MethodologyTypeID,
                        MethodologyName: this.state.ideaItem.MethodologyName,
                        PotentialImpactDollar: this.state.ideaItem.PotentialImpactDollar,
                        PotentialImpactFTE: this.state.ideaItem.PotentialImpactFTE,
                        Problem: this.state.ideaItem.Problem,
                        Solution: this.state.ideaItem.Solution,
                        ServiceLineId: this.state.ideaItem.ServiceLineId,
                        ServiceLineName: this.state.ideaItem.ServiceLineName,
                        SubServiceLine: this.state.ideaItem.SubServiceLine,
                        NewOrReplicatedIdea: this.state.ideaItem.NewOrReplicatedIdea,
                        ReplicationProjectId: this.state.ideaItem.ReplicationProjectId,
                        TransformationLeverId: this.state.ideaItem.TransformationLeverId,
                        TransformationLeverName: this.state.ideaItem.TransformationLeverName,
                        UpdaterOHR: this.sessionData.profile.oHRId,
                        UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                        UpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                        ApprovalStatus: this.state.approvalStatus,
                        FinalComments: this.state.QcComment,
                        IdeaId: this.props.location.state,
                        IsBestInClass: this.state.showFinalAdditionalDetails ? this.state.bestClass : null,
                        SourceId: this.state.sourceId,
                        IsSourceInValidated: true,
                        FinalImpactDollar: this.state.ideaItem.FinalImpactDollar,
                        FinalImpactFTE: this.state.ideaItem.FinalImpactFTE,
                        TypeOfImpactName: this.state.showFinalAdditionalDetails ? (this.state.typeOfImpactSelectedOption ? this.state.typeOfImpactSelectedOption.label : null) : null,
                        BusinessImpactName: this.state.showFinalAdditionalDetails ? (this.state.businessImpactSelectedOption ? this.state.businessImpactSelectedOption.label : null) : null,
                        BusinessImpactSubName: this.state.showFinalAdditionalDetails ? (this.state.businessSubCatSelectedOption ? this.state.businessSubCatSelectedOption.label : null) : null,
                        FinalTransformationLeverName: this.state.showFinalAdditionalDetails ? (this.state.finalTransSelectedOption ? this.state.finalTransSelectedOption.label : null) : null,
                        FinalOtherTools: this.state.showFinalAdditionalDetails ? this.state.otherTools : null,
                        IsZeroImpactProject: this.state.zeroImpact,
                        DataBridgeTheme: this.state.ideaItem.DataBridgeTheme,
                        SubCampaignName: this.state.ideaItem.SubCampaignName,
                        BusinessImpactData: [],
                        CampaignName: this.state.ideaItem.CampaignName,
                        Comment: this.state.ideaItem.Comment,
                        ReplicabilityIndex: this.state.ideaItem.ReplicabilityIndex,
                        CompletedDate: new Date().toLocaleString(),
                        FpnaApprovalAttachedSelectedOption: this.state.ideaItem.IsFPnAApprovalAttached,
                        IsVDReviewed: this.state.ideaItem.IsVDReviewed,
                        FpnaName: this.state.ideaItem.FPnAName,
                        FpnaOhr: this.state.ideaItem.FPnAOHR,
                        ImpactTyepChanged: this.state.impactTagChangeSelected.label,
                        DeliveryCategorizedSelect: this.state.deliveryCategorizedSelect.label,
                        DeliveryComputedCorrectlySelected: this.state.deliveryComputedCorrectlySelected.label,
                        SignOffDeliverySelected: this.state.signOffDeliverySelected.label,

                        QcOHR: this.sessionData.profile.oHRId,
                        QcEmail: this.sessionData.profile.oHRId + "@genpact.com",
                        QcUpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                        Role: "Project Reviewer",
                        FPnAOHRMonetization: this.state.ideaItem.FPnAOHRMonetization ? this.state.ideaItem.FPnAOHRMonetization : "",
                        FPnANameMonetization: this.state.ideaItem.FPnANameMonetization ? this.state.ideaItem.FPnANameMonetization : "",
                        SowID: this.state.ideaItem.SowID ? this.state.ideaItem.SowID : "",
                        IsVDMonetizable: this.state.ideaItem.IsVDMonetizable ? this.state.ideaItem.IsVDMonetizable : "",
                        MonetizationStatus: this.state.ideaItem.MonetizationStatus ? this.state.ideaItem.MonetizationStatus : "",
                        IsMbbMonetizable: this.state.ideaItem.IsMbbMonetizable ? this.state.ideaItem.IsMbbMonetizable : "",
                        updaterAction: this.state.approvalSelectedOption ? this.state.approvalSelectedOption.label === "Completed" ? "Approved" : this.state.approvalSelectedOption.label : "",

                        VDType: this.state.ideaItem.VDType ? this.state.ideaItem.VDType : "",

                    }
                    console.log(param)
                    let finalJsonData = Checksum(param);
                    const requestOptions = {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization":  "Bearer " + this.accessToken,
                        },
                        body: JSON.stringify(param)
                    };
                    fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
                        .then(response => response.json())
                        .then(data => {
                            Toast.hide();
                            Toast.success('Updated successfully.', 3000, () => {
                                window.location = "/";
                            });

                        });
                }
            }
        } else {
            if (!this.isExceedlimit) {
                Toast.fail('Required fields are missing or have invalid values. Please correct and try again.', 3000, () => { });
            }
        }
        return false;
    }

    handleTranscriptFinalApprovalComment = (e) => {
        setTimeout(() => {
            if (e !== null && e !== undefined) {
                let finalComment = e;
                this.setState({
                    QcComment: finalComment
                });
            }
        }, 1000)
    }
    toggleTranscriptFunction = (id) => {
        this.setState({ speechModal: !this.state.speechModal, modalIndex: id })
        if (id === 16) {
            if (this.state.QcComment) {
                this.setState({ previousTextForSpeech: this.state.QcComment }, () => console.log(this.state.previousTextForSpeech))
            } else {
                this.setState({ previousTextForSpeech: "" }, () => console.log(this.state.previousTextForSpeech))
            }
        }
    }

    closeModalFunction = () => {
        this.setState({ speechModal: false })
    }


    // function to fetch fpna file for monetization
    showMonetizationContent = () => {
        Toast.loading("Fetching FP&A Sign-off for monetization content...", () => { });
        this.fetchMonetizationContent()
            .then(() => {
                var fileData = this.state.monetizationFileContent;
                var fileName = this.state.ideaItem.FPnAFileNameMonetization;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }
    fetchMonetizationContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let signoffFileType = this.state.ideaItem.FPnAFileTypeMonetization;
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&fpnacontent=true&fpnamonetizationcontent=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    const signoffMetadata = data.fpnaContent ? data.fpnaContent : "";
                    const signoffContent = "data:" + signoffFileType + ";base64," + signoffMetadata;
                    this.setState({
                        monetizationFileContent: signoffContent,
                    }, () => {
                        console.log(this.state.monetizationFileContent)
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching FP&A Sign-off for monetization content. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }


    newBusinessImpactSummary() {
        let myArray = [...this.state.businessImpactValueArray];
        const groupedData = myArray.reduce((accumulator, currentValue) => {
            const impactType = currentValue.impactType;
            const monetizableType = currentValue.monetizableType;
            const fteValue = parseFloat(currentValue.fteValue) || 0.0;
            const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;

            const existingRow = accumulator.find(row => row.impactType === impactType && row.monetizableType === monetizableType);
            if (existingRow) {
                existingRow.fteValue += fteValue
                existingRow.dollarValue += dollarValue
            } else {
                accumulator.push({ impactType, monetizableType, fteValue, dollarValue });
            }
            return accumulator;
        }, []);
        this.setState({ summaryArray: groupedData }, () => { });
        this.setState({ businessImpactValueArray: this.state.businessImpactValueArray }, () => { })
        this.setState({ isFpnaRequired: false }, () => { })
        this.state.businessImpactValueArray && this.state.businessImpactValueArray.map((item) => {
            if (item.ImpactType === "Impact at Genpact") {
                if (item.MonetizableType === "Increase in Revenue" || item.MonetizableType === "Reduction in Cost") {
                    this.setState({ isFpnaRequired: true }, () => { })
                }
            }
        })
    }

    pull_data = ({ businessImpactValueArray, dollarTotal, fteTotal }) => {
        //console.log("bi recieved in pull data function",businessImpactValueArray)
        if (businessImpactValueArray) {
            this.setState({ businessImpactValueArray: businessImpactValueArray }, () => {
                this.newBusinessImpactSummary()
            }, () => { })
        }
        if (dollarTotal) {
            this.setState({ finalImpactDollar: dollarTotal }, () => { })
        }
        if (fteTotal) {
            this.setState({ finalImpactFTE: fteTotal }, () => { })
        }
    }
    pull_data_for_delete = ({ businessImpactValueArray, dollarTotal, fteValue }) => {
        // console.log("fte total abc",fteValue)
        // console.log("bi recieved in pull data function",businessImpactValueArray)
        if (businessImpactValueArray) {
            this.setState({ businessImpactValueArray: businessImpactValueArray }, () => {
                this.newBusinessImpactSummary()
            })

        }
        if (dollarTotal) {
            this.setState({ finalImpactDollar: dollarTotal }, () => {
                console.log("inside call back function $ total", this.state.finalImpactDollar)
            })
        }
        if (fteValue) {
            this.setState({ finalImpactFTE: fteValue }, () => {
                console.log("inside call back function fte value", this.state.finalImpactFTE)
            })
        }

    }
    pull_file_data = ({ fileName, fileType, fileContent, showClientApprovalFields, FPnAOHRMonetization }) => {
        if (fileName) {
            this.setState({ FPnAFileNameMonetization: fileName }, () => { })
        }
        if (fileType) {
            this.setState({ FPnAFileTypeMonetization: fileType }, () => { })
        }
        if (fileContent) {
            this.setState({ FPnAFileMonetization: fileContent }, () => { })
        }
        this.setState({ showClientApprovalFields: showClientApprovalFields }, () => { })
        this.setState({ FPnAOHRMonetization: FPnAOHRMonetization }, () => {
            //console.log("ohr from pull_file_data function =>",this.state.FPnAOHRMonetization)
        })
    }
    remove_bi_data_after_delete = (filteredIdeaArray) => {
        // console.log("bi recieved in pull data for delete function",filteredIdeaArray)
        if (filteredIdeaArray) {
            this.setState({ businessImpactValueArray: filteredIdeaArray }, () => {
                this.newBusinessImpactSummary()
                let dollarTotal = 0.0;
                let fteValue = 0.0;
                this.state.businessImpactValueArray.map((item) => {
                    if (item && item.DollarImpact) {
                        dollarTotal += parseFloat(item.DollarImpact);
                    }
                    if (item && item.dollarValue) {
                        dollarTotal += parseFloat(item.dollarValue);
                    }
                    if (item && item.FTEImpact) {
                        fteValue += parseFloat(item.FTEImpact)
                    }
                    if (item && item.fteValue) {
                        fteValue += parseFloat(item.fteValue)
                    }
                })
                this.setState({ finalImpactDollar: dollarTotal }, () => {
                    //console.log("inside call back function $ total",this.state.finalImpactDollar)
                })
                this.setState({ finalImpactFTE: fteValue }, () => {
                    //console.log("inside call back function fte value",this.state.finalImpactFTE)
                })

            })
        }

    }

    pull_monetization_sowId = (localSowId) => {
        this.setState({ SowID: localSowId }, () => { console.log("sow id from pull_monetization_sowId function =>", this.state.SowID) })
    }
    pull_monetization_fpna_name = (localFpnaName) => {
        //console.log("fpna name from pull file function => ",localFpnaName)
        this.setState({ FPnANameMonetization: localFpnaName }, () => {
            //console.log("fpna name from pull_file_function",this.state.FPnANameMonetization)
        })
    }
    pull_monetization_fpna_ohr = (localFpnaohr) => {
        console.log("fpna ohr from pull function => ", localFpnaohr)
        this.setState({ FPnAOHRMonetization: localFpnaohr }, () => { })
    }
    pull_monetizedTotal = ({ fteValue, dollarValue }) => {
        if (fteValue) {
            this.setState({ totalMonetizedDollar: fteValue }, () => {
                //console.log(this.state.totalMonetizedDollar)
            })
        }
        if (dollarValue) {
            this.setState({ totalMonetizedFTE: dollarValue }, () => {
                //console.log(this.state.totalMonetizedFTE)
            })
        }
    }
    pull_monetizableProject = (e) => {
        if (e) {
            this.setState({ monetizableProjectSelectedOption: e }, () => {
                console.log("monetization project Option =>", this.state.monetizableProjectSelectedOption)
            })

        }
    }
    pull_monetizableStatus = (e) => {
        if (e) {
            this.setState({ monetizationStatusSelectedOption: e }, () => {
                //console.log("monetization Status SelectedOption =>",this.state.monetizationStatusSelectedOption)
            })

        }
    }

    handleVDTypeChange = ({ e }) => {
        if (e) {
            this.setState({ impactOptionSelected: e }, () => {
                //console.log("impact selected option is from review idea page",this.state.impactOptionSelected)
            })
        }
    }
    fetchFPAOHRforIncreaseInRevenue = (e) => {
        if (e === true) {
            this.setState({ appropriateAttachment: true }, () => { })
        } else {
            this.setState({ appropriateAttachment: false }, () => { })
        }
    }
    pullMonetizationGOLName = (localGOLName) => {
        this.setState({ golMonetizationName: localGOLName }, () => {
            //console.log(this.state.golMonetizationName)
        })
    }
    pullMonetizationGOLOhr = (localGOLOhr) => {
        this.setState({ golMonetizationOhr: localGOLOhr }, () => {
            //console.log(this.state.golMonetizationOhr)
        })
    }
  

    // function for FPNAGOL component starts here

    // ppt deck file details
    pullPPTDeck = ({ fileName, fileType, fileContent }) => {
        this.setState({ filename1: fileName, fileType1: fileType, base64File1: fileContent }, () => {
            //console.log("filename ",this.state.filename1, "file type ",this.state.fileType1, "content ",this.state.base64File1)
        })
    }
    pullClientDocName = (fileName) => {
        this.setState({ signOffFilename: fileName, }, () => {
            //console.log("client approval name =>",this.state.signOffFilename)
        })
    }
    pullClientDocType = (fileType) => {
        if (fileType) {
            this.setState({ signOffFileType: fileType }, () => {
                //console.log("client approval type =>",this.state.signOffFileType)
            })
        }
    }
    pullClientDocContent = (fileContent) => {
        if (fileContent) {
            this.setState({ signOffContent: fileContent }, () => {
                //console.log("client approval contnet =>",this.state.signOffContent)
            })
        }
    }

    // gol file details
    pullgolFileName = (fileName) => {
        this.setState({ filename3: fileName }, () => {
            //console.log("gol approval doc name",this.state.filename3)      
        })
    }
    pullgolFileType = (fileType) => {
        this.setState({ fileType3: fileType }, () => {
            //console.log("gol approval doc type",this.state.fileType3)      
        })
    }
    pullgolContent = (fileContent) => {
        this.setState({ base64File3: fileContent }, () => {
            //console.log("gol approval doc content",this.state.base64File3)      
        })
    }

    // fpna file details
    pullfpnafileName = (fileName) => {
        if (fileName) {
            this.setState({ filename4: fileName }, () => {
                //console.log("fpna doc name",this.state.filename4)
            })
        }
    }
    pullfpnafileType = (fileType) => {
        if (fileType) {
            this.setState({ fileType4: fileType }, () => {
                //console.log("fpna doc type",this.state.fileType4)
            })
        }
    }
    pullfpnafileContent = (fileContent) => {
        if (fileContent) {
            this.setState({ base64File4: fileContent }, () => {
                //console.log("fpna doc file content",this.state.base64File4)
            })
        }
    }

    pullClientName = (e) => {
        if (e) {
            this.setState({ clientName: e }, () => {
                //console.log(this.state.clientName)
            })
        }
    }
    pullClientRole = (e) => {
        if (e) {
            this.setState({ clientRole: e }, () => {
                //console.log(this.state.clientRole)
            })
        }
    }
    pullClientSignOff = (e) => {
        if (e) {
            this.setState({ clientSignOffDate: e }, () => {
                //console.log(this.state.clientSignOffDate)
            })
        }
    }
    pullFpnaName = (name) => {
        if (name) {
            this.setState({ fpnaName: name }, () => {
                //console.log(this.state.fpnaName)
            })
        }

    }
    pullGolName = (name) => {
        if (name) {
            this.setState({ golFullname: name }, () => {
                //console.log(this.state.golFullname)
            })
        }

    }
    pullFpnaOhr = (e) => {
        if (e) {
            this.setState({ fpnaOhr: e }, () => {
                //console.log(this.state.fpnaOhr)
            })
        }
    }
    pullGolOhr = (e) => {
        if (e) {
            this.setState({ golOhr: e }, () => {
                //console.log(this.state.golOhr)
            })
        }
    }
    pull_fileDetails = (obj) => {
        this.setState({ golFpnaFileDetails: obj }, () => {
            //console.log(this.state.golFpnaFileDetails)
        })
    }

    // function for FPNAGOL component ends here



    finalApprover = () => {
        let approver = "";
        if (this.state.approvalSelectedOption) {
            if (this.state.approvalSelectedOption.label === 'Send Back') {
                approver = "Documents Uploaded";
            }
            if (this.state.approvalSelectedOption.label === "Approved") {
                approver = COMPLETED_STATUS
            }
            if (this.state.approvalSelectedOption.label === "Rework") {
                approver = "Rework - QC"
            }
            if (this.state.approvalSelectedOption.label === "Rejected") {
                approver = "Rejected"
            }
        }
        this.state.approvalStatus = approver;
        this.setState({ approvalStatus: approver }, () => {
        })

    }

    fetchClientTestimonyContent = () => {
        return new Promise((resolve, reject) => {
          const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization":  "Bearer " + this.accessToken,
            },
          };
          let clientTestimonyFileType = this.state.ideaItem.CTestimonyFileType;
          fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&clientTestimonycontent=true`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              const clientTestimonyFileMetadata = data.fpnaContent ? data.fpnaContent : "";
              const clientTestimonyFileContent = "data:" + clientTestimonyFileType + ";base64," + clientTestimonyFileMetadata;
              this.setState(
                {
                  CTestimony: clientTestimonyFileContent,
                },
                () => {
                  resolve();
                  Toast.hide();
                }
              );
            })
            .catch((error) => {
              Toast.fail(
                "Error while Client Testimony file content. Please try again!",
                3000,
                () => { }
              );
              resolve();
            });
        });
      };
      downloadClientTestimony = () => {
        Toast.loading("Fetching Client Testimony content...", () => { });
        this.fetchClientTestimonyContent().then(() => {
          var fileData = this.state.CTestimony;
          var fileName = this.state.ideaItem.CTestimonyFileName;
          var w = window.open("");
          const downloadLink = w.document.createElement("a");
          downloadLink.href = fileData;
          downloadLink.download = fileName;
          downloadLink.click();
          Toast.hide();
        });
      };


    render() {
        let IDEA_APPROVED = (this.state.ideaItem.WorkflowStatus === "Approve");
        let IDEA_REJECTED = (this.state.ideaItem.WorkflowStatus === "Reject");
        let IDEA_SEND_BACK = (this.state.ideaItem.WorkflowStatus === "Send Back");
        let IDEA_REQUEST_CANCELLATION = (this.state.ideaItem.WorkflowStatus === "Cancellation Requested");
        let IDEA_REQUEST_CANCELLATION1 = (this.state.ideaItem.ApprovalStatus === "Cancellation Requested");
        let IDEA_CANCELLED = (this.state.ideaItem.WorkflowStatus === "Cancelled");
        let IDEA_CANCELLED1 = (this.state.ideaItem.ApprovalStatus === "Cancelled");
        let redirectURL = "/review-page";
        if (this.state.approvalPage) {
            redirectURL = redirectURL + "?page=approval";
        }


        let IsSolnFeasibleValue = "";
        if (this.state.ideasAI.IsSolnFeasible === false) {
            IsSolnFeasibleValue = "No"
        }
        if (this.state.ideasAI.IsSolnFeasible === true) {
            IsSolnFeasibleValue = "Yes"
        }


        return (
            <section className="u-clearfix u-section-1" id="sec-0f7f">
                <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                    <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                        <div className="u-container-layout u-container-layout-1">
                            {
                                (((this.state.ideaItem.WorkflowStatus === PENDING) && (parseInt(this.sessionData.profile.oHRId) === parseInt(this.state.ideaItem.OwnerOHR))) || this.state.admin) && (
                                    <>
                                        <div className="text-left mt-4">
                                            {
                                                this.state.speechModal
                                                    ?
                                                    <UpdatedSpeech modalOpen={true}
                                                        modalId={this.state.modalIndex}
                                                        closeModalFunction={this.closeModalFunction}
                                                        previousText={this.state.previousTextForSpeech}
                                                    />
                                                    :
                                                    null
                                            }
                                        </div>
                                    </>
                                )
                            }
                            <h4 style={{ textAlign: 'center', color: '#00AECF', fontFamily: 'Arial', fontSize: 22 }}>View idea : {this.state.ideaItem.IdeaIDAlpha ? this.state.ideaItem.IdeaIDAlpha : this.state.ideaItem.IdeaId} </h4>
                            <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                                <div className="u-container-layout u-container-layout-2" style={{ textAlign: 'center' }}>
                                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-3">
                                        <div className="u-container-layout u-container-layout-3">
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: 15 }}>Owner details </p>
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner OHR</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerOHR} /></div>
                                            </div>
                                            <div style={{ marginBottom: 15 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner Name</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerName} /></div>
                                            </div>
                                            <div style={{ marginBottom: -10 }} />
                                            <p className="u-align-left u-text u-text-7 u-text-custom-color-3" style={{ fontSize: 11, marginBottom: 10 }}>(If Idea was raised for someone else, their OHR ID is below)</p>
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Request For OHR</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.CreatorOHR} onChange={this.handleCreatorOHRChange} /></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Name</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.CreatorName} onChange={this.handleCreatorNameChange} /></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                            {
                                                (this.state.ideaItem.LeanID !== null && ((this.state.ideaItem.CertificationLevelName === "Lean" || this.state.ideaItem.CertificationLevelName === "YB") && this.state.ideaItem.TransformationLeverName !== "Digital")) ? (
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Project ID</label> </div>
                                                        <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.LeanID} /></div>
                                                    </div>
                                                ) : (
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: 12 }}>&nbsp;</label> </div>
                                                    </div>
                                                )
                                            }
                                            <div style={{ marginBottom: 10 }} />
                                        </div>
                                    </div>

                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 255, marginLeft: -20 }}>&nbsp;</p>

                                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-4" style={{ marginTop: "-305px" }}>
                                        <div className="u-container-layout u-container-layout-4" style={{display:"flex"}}>
                                            <div className="u-container-style u-group u-shape-rectangle u-group-5">
                                                <div className="u-container-layout u-container-layout-5">
                                                    <p className="u-align-left u-text u-text-8" style={{ marginBottom: 15 }}>Account Details</p>
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Account&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled list="accounts" name="accounts" placeholder="Choose Account" value={this.state.ideaItem.AccountManual} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Holding&nbsp;Account</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled type="text" value={this.state.ideaItem.HoldingAccount} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Vertical</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="vertical" placeholder="Choose Vertical" value={this.state.ideaItem.VerticalManual} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Industry&nbsp;Vertical</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="subverticallist" placeholder="Choose Industry&nbsp;Vertical" value={this.state.ideaItem.SubVerticalName} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />


                                                    {/* units and segments starts here */}
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Segment</label>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="segment" value={this.state.ideaItem.Segment ? this.state.ideaItem.Segment : ""} style={{ fontWeight: 'normal !important' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Unit</label>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="unit" value={this.state.ideaItem.Unit ? this.state.ideaItem.Unit : ""} style={{ fontWeight: 'normal !important' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    {/* units and segments ends here */}


                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Campaign&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="ideationtype" placeholder="Choose Campaign Name" value={this.state.ideaItem.CampaignName} />
                                                        </div>
                                                        <div className="">
                                                            <div className="campaign_modal_bulb campaign_tool_tip">
                                                                <img 
                                                                    src={require("../assets/images/question_mark_2.png")}
                                                                    alt=""
                                                                    className=""
                                                                />
                                                                {
                                                                    this.state.campaignInsight
                                                                    ?
                                                                    <>
                                                                        <span className="my_tooltiptext">{this.state.campaignInsight}</span>
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />

                                                    {
                                                        this.state.ideaItem.CampaignName === "Gen AI"
                                                            ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5">
                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Gen AI Theme</label></div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Gen AI Theme" value={this.state.ideaItem.SubCampaignName} />
                                                                    </div>
                                                                </div>

                                                            </>
                                                            :
                                                            null
                                                    }


                                                    {
                                                        this.state.displayProjectTheme && this.state.dataBridgeCampaign && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Data Bridge Project Theme</label></div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled list="databridgetype" name="databridgetype" placeholder="Choose Data Bridge Project Type" value={this.state.ideaItem.DataBridgeTheme} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 26 }} />
                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        this.state.xPollinationCampaign && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Campaign</label></div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Sub Campaign" value={this.state.ideaItem.SubCampaignName} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 26 }} />
                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        this.state.displayParentAlpha && this.state.displayParentAlpha.length > 0 && this.state.displayParentAlpha !== null && this.state.displayParentAlpha !== undefined && this.state.displayParentAlpha !== ""
                                                            ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5">
                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Parent ID</label>
                                                                    </div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled placeholder="Choose Parent ID" value={this.state.displayParentAlpha} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST
                                                            ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5">
                                                                        <label
                                                                            style={{
                                                                                verticalAlign: "bottom",
                                                                                marginBottom: -5,
                                                                            }}
                                                                        >
                                                                            <i>AI First Sample Ideas</i>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-7">
                                                                        <div className="ai_first_doc_link ai_first_doc_red_highlight">
                                                                            <a
                                                                                className="ldt-list1"
                                                                                href={AI_DOC_LINK}
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                                style={{ textAlign: "left", fontSize: 16, color: "red" }}
                                                                            >Document Link </a>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                {
                                                                    this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )
                                                                        ?
                                                                        <>
                                                                            <div style={{ marginBottom: 20 }} />
                                                                            <div className="row d_flex_align_items_center">
                                                                                <div className="col-md-5">
                                                                                    <span className="form-attribute">
                                                                                        AI Recommendation
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-7">
                                                                                    <input
                                                                                        className="form-input"
                                                                                        disabled
                                                                                        name="methodology"
                                                                                        value={this.state.ideaItem.isAI}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null

                                                                }


                                                                {
                                                                    this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )
                                                                        ?
                                                                        <>
                                                                            <div style={{ marginBottom: 20 }} />
                                                                            <div className="row">
                                                                                <div className="col-md-5">
                                                                                    <span className="form-attribute">
                                                                                        AI First BRD
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-7">
                                                                                    <div className="ai_first_doc_link">
                                                                                        <textarea className="ai_first_brd_textarea" disabled value={this.state.ideasAI.LinkBRD} ></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }

                                                            </>
                                                            :
                                                            null
                                                    }
                                                    <div style={{ marginBottom: 18 }}>&nbsp;</div>
                                                </div>
                                            </div>

                                            

                                            <div className="u-container-style u-group u-shape-rectangle">
                                                <div className="u-container-layout u-container-layout-6">
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Country</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="regions" placeholder="Choose Country" value={this.state.ideaItem.Country} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Region</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="regions" placeholder="Choose Region" value={this.state.ideaItem.RegionManual} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub-Region</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="regions" placeholder="Choose Sub-Region" value={this.state.ideaItem.SubRegion} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Blackbelt&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="blackbelt" placeholder="Choose Blackbelt" title={"OHR ID: " + this.state.ideaItem.BlackBeltUserId} value={this.state.ideaItem.BlackBeltUserName} />
                                                        </div>
                                                    </div>
                                                    {
                                                        (this.state.ideaItem.MBBOHR !== null) && (
                                                            // (this.state.ideaItem.WorkflowStatus !== "Pending") && (
                                                            <>
                                                                <div style={{ marginBottom: 15 }} />
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>MBB</label></div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled name="blackbelt" placeholder="Choose MBB" title={"OHR ID: " + this.state.ideaItem.MBBOHR} value={this.state.ideaItem.MBBName} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }

                                                    <div style={{ marginBottom: 15 }} />

                                                    {
                                                        this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST &&
                                                            this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )
                                                            ?
                                                            <>
                                                                <div style={{ marginBottom: 20 }} />
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <span className="form-attribute">
                                                                            AI First Reason
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <textarea
                                                                            disabled
                                                                            value={this.state.ideasAI.AIReason}
                                                                            placeholder="Please provide value"
                                                                            className="form-input"
                                                                            rows={4}
                                                                            style={{
                                                                                width: "100%",
                                                                                border: "1px solid lightgrey",
                                                                                resize: "none"
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                               
                                                            </>
                                                            :
                                                            null
                                                    }

                                                    
                                                    <div style={{ marginBottom: 15 }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="idea1" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ borderTop: "1px solid lightgrey" }}>
                                        <div className="u-container-layout u-container-layout-7">
                                            <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                <div className="u-container-layout u-container-layout-8">
                                                    <p id="idea1title" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Idea details</p>
                                                    <div className="row">
                                                        <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Service Line</label> </div>
                                                        <div className="col-md-8">
                                                            <input className="form-input" disabled name="serviceline" placeholder="Choose Service Line" value={this.state.ideaItem.ServiceLineName} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Service Line</label> </div>
                                                        <div className="col-md-8">
                                                            <input className="form-input" disabled name="serviceline" placeholder="Choose Sub Service Line" value={this.state.ideaItem.SubServiceLine} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                        <span className="u-text-black form-attribute"> Problem</span>&nbsp;
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <textarea className="form-input" disabled rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Problem} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 10 }}>&nbsp;</p>
                                            <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                <div className="u-container-layout u-container-layout-9" style={{ marginTop: 4 }}>
                                                    <div className="row">
                                                        <div className="col-md-3"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Idea Name</label> </div>
                                                        <div className="col-md-9" style={{ alignItems: 'flex-start' }}><input className="form-input" disabled type="text" value={this.state.ideaItem.IdeaName} placeholder="Eg: Cash flow improvement through process restructuring" /></div>
                                                    </div>
                                                    <div style={{ marginBottom: 66 }} />
                                                    <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                        <span className="u-text-black form-attribute"> Solution</span>&nbsp;
                                                    </p>
                                                    <div className="row" style={{ marginBottom: 15 }}>
                                                        <div className="col-md-12">
                                                            <textarea className="form-input" disabled rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Solution} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 15 }}>
                                                <div className="col-md-5"><span className="form-attribute">Are you trying to replicate an existing Idea?</span>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="switch">
                                                        <input disabled type="checkbox" checked={this.state.ideaItem.NewOrReplicatedIdea === null ? false : this.state.ideaItem.NewOrReplicatedIdea} />
                                                        <span className="slider round" />
                                                    </label>
                                                </div>
                                            </div>

                                            {
                                                this.state.ideaItem.NewOrReplicatedIdea && (
                                                    <>
                                                        <div className="col-md-8" style={{ marginBottom: 15, width: "100%" }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><span className="form-attribute">Provide the ID of the executed Idea which you want to replicate?</span>
                                                            </div>
                                                            <div className="col-md-2"><input className="form-input" disabled type="text" value={this.state.ideaItem.ReplicationProjectId} /></div>
                                                        </div>
                                                    </>
                                                )
                                            }

                                            <div className="col-md-8" style={{ marginBottom: 15 }} />
                                        </div>
                                    </div>

                                    {
                                        (this.state.ideaItem.WorkflowStatus && this.state.ideaItem.Comment !== null) && (
                                            <>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />
                                                <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                    <div className="u-container-layout u-container-layout-7">
                                                        <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Idea Approval details</p>
                                                        <div className="row">
                                                            <div className="col-md-2"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Approval Status</label></div>
                                                            <div className="col-md-2">
                                                                {/* <input className="form-input" disabled name="Status" value={IDEA_APPROVED ? APPROVED : (IDEA_REJECTED ? REJECTED : (IDEA_SEND_BACK ? SENT_BACK : ((IDEA_REQUEST_CANCELLATION || IDEA_REQUEST_CANCELLATION1) ? "Cancellation Requested" :( (IDEA_CANCELLED || IDEA_CANCELLED1) ? "Cancelled" :(this.state.ideaItem.Comment !== null ? 'Reject Cancellation' :PENDING)))))}  /> */}
                                                                <input className="form-input" disabled name="Status" value={IDEA_APPROVED ? APPROVED : (IDEA_REJECTED ? REJECTED : (IDEA_SEND_BACK ? SENT_BACK : ((IDEA_REQUEST_CANCELLATION || IDEA_REQUEST_CANCELLATION1) ? "Cancellation Requested" : ((IDEA_CANCELLED || IDEA_CANCELLED1) ? "Cancelled" : (this.state.ideaItem.Comment !== null ? (this.state.logAction === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : 'Discard Cancellation') : PENDING)))))} />
                                                            </div>
                                                            {/* <div className="col-md-3"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Expected timeline for execution</label></div>
                                                        <div className="col-md-2">
                                                            <input disabled name="ExpectedDate" value={this.state.ideaItem.ExpectedFinishDate ? this.formatDateString(new Date(this.state.ideaItem.ExpectedFinishDate)): ""} />
                                                        </div> */}
                                                        </div>
                                                        {/* <div className="col-md-9" style={{ marginBottom: 5 }} /> */}

                                                        <div className="row" style={{ marginTop: 15 }}>
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Comments</label></div>
                                                            <div className="col-md-2" style={{ marginLeft: 20 }}>
                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ commentLog: !this.state.commentLog })}>Comment Log</button>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: 5 }}>
                                                            <div className="col-md-12">
                                                                <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.Comment} />
                                                            </div>
                                                        </div>
                                                        <div className="text-left mt-4">
                                                            {
                                                                this.state.commentLog && this.state.reviewComments
                                                                    ?
                                                                    <>
                                                                        <div className="custom_modal">
                                                                            <div className="modal_body">
                                                                                <div className="row">
                                                                                    <div className="col-md-8">
                                                                                        <h3 className="modal_title">Idea Approval Comment Log</h3>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ commentLog: false })}>&times;</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                    </div>
                                                                                    <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                    </div>
                                                                                    <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    this.state.reviewComments.map((item, index) => {
                                                                                        console.log(item)
                                                                                        return (
                                                                                            <div className="row" key={index}>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                </div>
                                                                                                {/* <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                    {(item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin' ) ? 'Admin' : (item.Action === 'Delegate Action' ? 'BB' : (item.UpdaterOHR === this.state.ideaItem.BlackBeltUserId ? "BB" : (item.UpdaterOHR === this.state.ideaItem.MBBOHR ? "MBB" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Idea Owner" : "LDT"))))}
                                                                                                </label>
                                                                                            </div> */}
                                                                                                <div className="col-md-1 text-center role_over_flow_class" style={{ border: "1px solid lightgrey" }}>
                                                                                                    {/* <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole}</label> */}
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === "Delegate Action" ? 'BB' : (item.UpdaterOHR === this.state.MBBOHR ? 'MBB' : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Idea Owner" : (item.ApprovalStatus === "Pending Documents" && item.WorkflowStatus === "Approved") ? "BB" : (item.ApprovalStatus === "NA" && item.WorkflowStatus === "Pending") ? "BB" : item.UpdaterRole)))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? (item.WorkflowStatus !== 'Pending' ? item.WorkflowStatus : 'Idea Updated') : (item.Action === 'Delegate Action' ? 'Delegated' : (item.WorkflowStatus === 'Pending' ? 'Discard Cancellation' : item.WorkflowStatus)))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment ? item.Comment : '-'}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15, marginTop: 15 }} />

                                                {
                                                    (this.state.ideaItem.WorkflowStatus && (this.state.ideaItem.WorkflowStatus === "Approve" || this.state.ideaItem.WorkflowStatus === "Approved")) && (
                                                        <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                            <div className="u-container-layout u-container-layout-7" style={{display:"flex", alignItems:"flex-start", padding: "30px", marginTop: "20px"}}>
                                                                <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                                    <div className="u-container-layout">
                                                                        <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Additional details</p>
                                                                        <div className="row">
                                                                            <div className="col-md-6"><span className="form-attribute">LDT Lead Name</span></div>
                                                                            <div className="col-md-6">
                                                                                <input className="form-input" disabled list="ldtName" name="ldtName" placeholder="Choose LDT Lead" title={"OHR ID: " + this.state.ideaItem.LDTId} value={this.state.ideaItem.LDTLeadName} />
                                                                            </div>
                                                                        </div>
                                                                        {/* <div style={{ marginBottom: 15 }} />
                                                                        <div className="row">
                                                                            <div className="col-md-6"><span className="form-attribute">Potential Impact (# of FTE)</span></div>
                                                                            <div className="col-md-6">
                                                                                <input className="form-input" disabled type="number" value={this.state.ideaItem.PotentialImpactFTE} placeholder={0} max={10} />
                                                                            </div>
                                                                        </div>
                                                                <div style={{ marginBottom: 15 }} />
                                                                        <div className="row">
                                                                            <div className="col-md-6"><span className="form-attribute">Potential Impact ($)</span></div>
                                                                            <div className="col-md-6">
                                                                                <input className="form-input" disabled type="number" value={this.state.ideaItem.PotentialImpactDollar} placeholder={0.0} />
                                                                            </div>
                                                                        </div>
                                                                <div style={{ marginBottom: 5 }} />
                                                                <div className="u-align-left u-text u-text-custom-color-3" style={{ fontSize: 11, fontStyle: 'italic' }}>(If Potential Impact $ is greater than 250K OR FTE number is greater than 10, please review thoroughly)
                                                                </div> */}
                                                                        
                                                                        <div style={{ marginTop: 10, marginBottom: 20 }} />



                                                                        {
                                                                            this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST
                                                                            && this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )
                                                                                ?
                                                                                <>
                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-6">
                                                                                            <span className="form-attribute">
                                                                                                Is the solution feasible ?
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <input
                                                                                                className="form-input"
                                                                                                disabled
                                                                                                value={IsSolnFeasibleValue}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-6">
                                                                                            <span className="form-attribute">
                                                                                                Solution Complexity
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <input
                                                                                                className="form-input"
                                                                                                disabled
                                                                                                name="methodology"
                                                                                                value={
                                                                                                    this.state.ideasAI.SolnComplexity
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-6">
                                                                                            <span className="form-attribute">
                                                                                                Is the Idea replicable
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <input
                                                                                                className="form-input"
                                                                                                disabled
                                                                                                value={this.state.isIdeaReplicable}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                </>
                                                                                :
                                                                                null
                                                                        }


                                                                        {
                                                                            this.state.ideaItem.NewOrReplicatedIdea && (
                                                                                <div className="row">
                                                                                    <div className="col-md-10">
                                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Have you verified the replication id and confirm this to be replication project?</label>
                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        <label className="switch">
                                                                                            <input disabled type="checkbox" checked={this.state.ideaItem.IsReplicationVerified} />
                                                                                            <span className="slider round" />
                                                                                        </label>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 45 }} />
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            !this.state.ideaItem.NewOrReplicatedIdea && (
                                                                                <div className="row">
                                                                                    <div className="col-md-10">
                                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: 17 }}>&nbsp;</label>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>

                                                                

                                                                <div className="u-container-style u-group u-shape-rectangle">
                                                                    <div className="u-container-layout u-container-layout-9" style={{paddingTop: "15px"}}>
                                                                        <div className="row">
                                                                            <div className="col-md-5"><span className="form-attribute">Certification Level</span> </div>
                                                                            <div className="col-md-7">
                                                                                <input className="form-input" disabled name="certlevel" value={this.state.ideaItem.CertificationLevelName} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 15 }} />
                                                                        <div className="row">
                                                                            <div className="col-md-5"><span className="form-attribute">Transformation Lever</span> </div>
                                                                            <div className="col-md-7">
                                                                                <input className="form-input" disabled name="translever" value={this.state.ideaItem.TransformationLeverName} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 15 }} />
                                                                        {
                                                                            this.state.ideaItem.TransformationLeverName === "Digital" && (
                                                                                <>
                                                                                    <div className="row">
                                                                                        <div className="col-md-5"><span className="form-attribute">Digital Project Type</span> </div>
                                                                                        <div className="col-md-7">
                                                                                            <input className="form-input" disabled name="translever" value={this.state.ideaItem.DigitalProjectType} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            this.state.ideaItem.CertificationWorkTypeName !== null && this.state.ideaItem.CertificationWorkTypeName !== ""
                                                                                ?
                                                                                <>
                                                                                    <div className="row">
                                                                                        <div className="col-md-5"><span className="form-attribute">Work Type</span> </div>
                                                                                        <div className="col-md-7">
                                                                                            <input className="form-input" disabled name="worktype"
                                                                                                value={this.state.ideaItem.CertificationWorkTypeName} />
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                null

                                                                        }
                                                                        <div style={{ marginBottom: 15 }} />

                                                                        <div className="row">
                                                                            <div className="col-md-5"><span className="form-attribute">Tools/Methodology Used</span></div>
                                                                            <div className="col-md-7">
                                                                                <input className="form-input" disabled name="methodology" value={this.state.ideaItem.MethodologyName} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 25 }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }

                                    {/* Final Approval Section  */}
                                    {
                                        (this.state.ideaItem.ApprovalStatus
                                            && this.state.ideaItem.ApprovalStatus !== "NA"
                                            && this.state.ideaItem.FinalComments !== null
                                            && this.state.ideaItem.ApprovalStatus !== "Awaiting Project Creation"
                                        )
                                        && (
                                            <>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 10 }} />

                                                <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ borderBottom: "1px solid lightgray" }}>
                                                    <div className="u-container-layout u-container-layout-7">
                                                        <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Project Approval details</p>
                                                        <div className="row">
                                                            <div className="col-md-2"><span className="form-attribute">Approval Status</span> </div>
                                                            <div className="col-md-3">
                                                                <input className="form-input" disabled name="finalapprovalstatus" value={this.state.ideaItem.ApprovalStatus} />
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: 15 }}>
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Approval Comments</label></div>
                                                            <div className="col-md-2" style={{ marginLeft: 20 }}>
                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ finalCommentLog: !this.state.finalCommentLog }, () => { })}>Comment Log</button>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: 5 }}>
                                                            <div className="col-md-12">
                                                                <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.FinalComments} />
                                                            </div>
                                                        </div>
                                                        <div className="text-left mt-4">
                                                            {
                                                                this.state.finalCommentLog && this.state.finalReviewComments
                                                                    ?
                                                                    <>
                                                                        <div className="custom_modal">
                                                                            <div className="modal_body">
                                                                                <div className="row">
                                                                                    <div className="col-md-8">
                                                                                        <h3 className="modal_title">Lean Approval Comment Log</h3>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ finalCommentLog: false })}>&times;</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                    </div>
                                                                                    <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                    </div>
                                                                                    <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    this.state.finalReviewComments.map((item, index) => {
                                                                                        let actionStatus = "";
                                                                                        if (item.Action === 'Idea Reinitiated by Admin') {
                                                                                            actionStatus = "Idea Reinitiated";
                                                                                        }
                                                                                        if (item.Action === 'Idea Updated by Admin') {
                                                                                            actionStatus = "Idea Updated by Admin";
                                                                                        }
                                                                                        if (item.WorkflowStatus === 'Completed' || item.WorkflowStatus === 'Approve' || item.WorkflowStatus === 'Approved') {
                                                                                            actionStatus = "Approved"
                                                                                        }
                                                                                        if (item.WorkflowStatus === 'Rejected' || item.WorkflowStatus === 'Reject') {
                                                                                            actionStatus = "Rejected"
                                                                                        }
                                                                                        if (item.WorkflowStatus === REQUEST_CANCELLATION) {
                                                                                            actionStatus = REQUEST_CANCELLATION
                                                                                        }
                                                                                        if (item.WorkflowStatus === 'Pending Documents') {
                                                                                            actionStatus = "Discard Cancellation"
                                                                                        }
                                                                                        if (item.Action === "Idea was reviewed by MBB" && item.ApprovalStatus === "Awaiting Quality Check Review") {
                                                                                            actionStatus = "Approved"
                                                                                        }
                                                                                        else {
                                                                                            actionStatus = "Sent Back"
                                                                                        }
                                                                                        return (
                                                                                            <div className="row" key={index}>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                </div>
                                                                                                {/* <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{(item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === 'Idea was reviewed by MBB' ? "MBB" : (item.Action === 'Idea was reviewed by LDT' ? "LDT" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Owner" : "BB")))}</label>
                                                                                                </div> */}
                                                                                                <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    {/* <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole}</label> */}
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : item.UpdaterRole}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    {/* <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? item.ApprovalStatus : (item.WorkflowStatus === 'Completed' || item.WorkflowStatus === 'Approve' || item.WorkflowStatus === 'Approved') ? 'Approved' : ((item.WorkflowStatus === 'Rejected' || item.WorkflowStatus === 'Reject') ? "Rejected" : (item.WorkflowStatus === REQUEST_CANCELLATION ? REQUEST_CANCELLATION : (item.WorkflowStatus === 'Pending Documents' ? 'Discard Cancellation' : item.WorkflowStatus === 'Cancelled' ? 'Cancelled' : "Sent Back"))))}</label> */}
                                                                                                    <label>{item.UpdaterAction ? item.UpdaterAction : item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? item.ApprovalStatus : (item.WorkflowStatus === 'Completed' || item.WorkflowStatus === 'Approve' || item.WorkflowStatus === 'Approved' || item.ApprovalStatus === 'Awaiting Project Review') ? 'Approved' : ((item.WorkflowStatus === 'Rejected' || item.WorkflowStatus === 'Reject') ? "Rejected" : (item.WorkflowStatus === REQUEST_CANCELLATION ? REQUEST_CANCELLATION : (item.WorkflowStatus === 'Pending Documents' ? 'Discard Cancellation' : item.WorkflowStatus === 'Cancelled' ? 'Cancelled' : (item.Action === "Idea was reviewed by MBB" && item.ApprovalStatus === "Awaiting Quality Check Review" ? "Approved" : "Sent Back")))))}</label>



                                                                                                </div>
                                                                                                <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment ? item.Comment : '-'}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>


                                                    </div>
                                                </div>
                                                {
                                                    ((this.state.ideaItem.ApprovalStatus === AWAITING_QAULITY_CHECK_REVIEW || this.state.ideaItem.ApprovalStatus === "Awaiting Project Review") && this.state.ideaItem.WorkflowStatus === "Approve") && (
                                                        <>

                                                            {
                                                                this.state.ideaItem.CertificationLevelName === "Lean"
                                                                    ?
                                                                    <>
                                                                        <FPNAGOL IdeaId={this.state.ideaItem.IdeaId} viewIdea={true}
                                                                            vdSelected={this.state.ideaItem.VDType}
                                                                            fetchPPTDeck={this.pullPPTDeck}
                                                                            fetchClientDocName={this.pullClientDocName}
                                                                            fetchClientDocType={this.pullClientDocType}
                                                                            fetchClientDocContent={this.pullClientDocContent}
                                                                            fetchGolDocName={this.pullgolFileName}
                                                                            fetchGolDocType={this.pullgolFileType}
                                                                            fetchGolDocContent={this.pullgolContent}
                                                                            fetchfpnaDocName={this.pullfpnafileName}
                                                                            fetchfpnaDocType={this.pullfpnafileType}
                                                                            fetchfpnaDocContent={this.pullfpnafileContent}
                                                                            fetchClientName={this.pullClientName}
                                                                            fetchClientRole={this.pullClientRole}
                                                                            fetchClientSignOffDate={this.pullClientSignOff}
                                                                            fetchFpnaName={this.pullFpnaName}
                                                                            fetchGolName={this.pullGolName}
                                                                            fetchFpnaOhr={this.pullFpnaOhr}
                                                                            fetchGolOhr={this.pullGolOhr}
                                                                            fetchFileDetails={this.pull_fileDetails}
                                                                            sendBitoFPNA={this.state.businessImpactValueArray}
                                                                            sendIsFpnaRequired={this.state.isFpnaRequired}
                                                                        />
                                                                    </>

                                                                    :
                                                                    null
                                                            }

                                                        </>
                                                    )
                                                }
                                            </>
                                        )
                                    }


                                    <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                        <div className="u-container-layout u-container-layout-7" style={{ borderBottom: "1px solid lightgray", marginBottom: "30px" }}>
                                            <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: "20px" }}>Final additional details</p>
                                            <div className="row pb-4">
                                                <div className="col-md-5">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <span className="form-attribute">Best in Class</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="switch">
                                                                <input disabled type="checkbox"
                                                                    checked={this.state.ideaItem.IsBestInClass === null ? false : this.state.ideaItem.IsBestInClass} />
                                                                <span className="slider round" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-5">
                                                    {
                                                        (this.state.ideaItem.ReplicabilityIndex !== null) && (
                                                            <div className="row">
                                                                <div className="col-md-6"><span className="form-attribute">Replicability Index</span> </div>
                                                                <div className="col-md-6">
                                                                    <input className="form-input" disabled
                                                                        value={this.state.replicabilityIndex && this.state.replicabilityIndex.label}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        


                                        


                                    </div>

                                    {this.state.ideaItem.VDType === "No Value Delivery" ? (
                                            <div
                                                style={{
                                                    // borderBottom: "1px solid lightgray",
                                                    marginBottom: "30px",
                                                }}
                                            ></div>
                                    ) : null}

                                    {this.state.ideaItem.IdeaId && this.state.ideaItem.VDType ? (
                                        <>
                                            <ValueDelivery func={this.pull_data} viewIdea={true}
                                                vdType={this.handleVDTypeChange}
                                                IdeaId={this.state.ideaItem.IdeaId}
                                                fetchMonetizationFileDetails={this.pull_file_data}
                                                bbMonetizationTotal={this.pull_monetizedTotal}
                                                monetizableProject={this.pull_monetizableProject}
                                                monetizableStatus={this.pull_monetizableStatus}
                                                fetchFPAOHRforIncreaseInRevenue={this.fetchFPAOHRforIncreaseInRevenue}
                                                bbMonetizationApproval={false}
                                                postFpnaValid={false}
                                                fetchMonetizationFpnaOhr={this.pull_monetization_fpna_ohr}
                                                fetchMonetizationFpnaName={this.pull_monetization_fpna_name}
                                                fetchMonetizationGOLName={this.pullMonetizationGOLName}
                                                fetchMonetizationGOLOhr={this.pullMonetizationGOLOhr}
                                                fetchMonetizationSowID={this.pull_monetization_sowId}
                                                fetchDeletedBIRows={this.remove_bi_data_after_delete}
                                            />
                                        </>
                                    ) : null}


                                    


                                    



                                    {/* ............db fields starts here........... */}
                                    {
                                            this.state.ideaItem.TransformationLeverName && this.state.ideaItem.TransformationLeverName === "Data"
                                            && this.state.displayDBFields
                                                ?
                                                <>
                                                    <div className="">
                                                        <div className="" style={{borderTop:"1px solid lightgrey"}}>
                                                            <p className="u-align-left u-text u-text-2" style={{ margin: "15px 0px 0px 0px" }}>Data Bridge Fields</p>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    DB Project Type</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideaItem.DBProjectType} disabled className="form-input  disabled_input" placeholder="Provide Value" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        this.state.ideaItem.DBProjectType
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields" style={{}}>
                                                                                    <div className="row">
                                                                                        <div className="col-md-5">
                                                                                            <span className="form-attribute">
                                                                                                Template Type
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <div className="ai_first_doc_link">
                                                                                                <input className="template_type_input" disabled  value={this.state.ideaItem.DBProjectType} ></input>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }

                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    COO</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideaItem.COO} type="text" placeholder="Provide Value" className="form-input  disabled_input"
                                                                                    disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    MUC ID</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input data-toggle="tooltip" title={this.state.ideaItem.MUCIDTitle ? this.state.ideaItem.MUCIDTitle : ""} value={this.state.ideaItem.MUCIDTitle} placeholder="Please choose" disabled className="form-input  disabled_input" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    Shown to Client</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideasDB.IsSharedClient} placeholder="Please choose" disabled className="form-input disabled_input" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.ideasDB.IsSharedClient && this.state.ideasDB.IsSharedClient === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="">
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className="submit_page_new_fields">
                                                                                                <div className="row d_flex_align_items_center">
                                                                                                    <div className="col-md-5">
                                                                                                        <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                            Client Testimony</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-7">
                                                                                                        <button className={this.state.ideaItem.CTestimonyFileName.length > 50?"upload_file_text_overflow":""} style={{ backgroundColor: 'transparent', textAlign: "left", marginTop: "15px", color: '#337ab7', padding: 0 }} onClick={(e) => this.downloadClientTestimony()}>{this.state.ideaItem.CTestimonyFileName}</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-2"></div>
                                                                                        <div className="col-md-5">

                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    Shown to GOL</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideasDB.IsSharedGOL} placeholder="Provide value" className="form-input disabled_input" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    DB Tool Used</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideasDB.DBTool} className="form-input disabled_input" disabled
                                                                                    placeholder="Provide value" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="col-md-4">

                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{
                                                                                    verticalAlign: 'bottom', marginBottom: -5
                                                                                }}>
                                                                                    Process Owner OHR</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input type="text"
                                                                                    disabled
                                                                                    placeholder="Provide Value"
                                                                                    value={this.state.ideaItem.ProcessOwnerOHR}
                                                                                    className="form-input form-attribute" />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{
                                                                                    verticalAlign: 'bottom', marginBottom: -5
                                                                                }}>
                                                                                    Process Owner Name</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideaItem.ProcessOwner} type="text" placeholder="Provide Value" className="form-input disabled_input"
                                                                                    disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    
                                                                    {
                                                                        this.state.ideasDB.IsSharedClient && this.state.ideasDB.IsSharedClient === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                Client Role</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input value={this.state.ideasDB.ClientRole} type="text" placeholder="Provide value" className="form-input disabled_input"
                                                                                                disabled />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                Client Name</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input value={this.state.ideasDB.ClientName} type="text" disabled className="form-input  disabled_input" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        this.state.ideasDB.IsSharedGOL && this.state.ideasDB.IsSharedGOL === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                GOL OHR</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input value={this.state.ideasDB.GOLOHR} type="text" placeholder="Provide value" className="form-input form-attribute"
                                                                                                disabled />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                GOL Name</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input type="text" placeholder="Provide value" disabled value={this.state.ideasDB.GOL}
                                                                                                className="form-input disabled_input" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        this.state.ideasDB.DBTool && this.state.ideasDB.DBTool === "Others"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                Other DB Tool Used</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input value={this.state.ideasDB.OtherDBTool} type="text" placeholder="Provide value" disabled
                                                                                                className="form-input disabled_input" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }


                                                                </div>
                                                                <div className="col-md-4" style={{ paddingRight: "30px" }}>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-8">
                                                                                <label
                                                                                    className="form-attribute"
                                                                                    style={{
                                                                                        verticalAlign: "bottom",
                                                                                        marginBottom: -5,
                                                                                    }}
                                                                                >
                                                                                    Is Sustenance tracking applicable ? </label>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <input
                                                                                    value={this.state.ideasDB.IsSustenance}
                                                                                    placeholder="Provide value"
                                                                                    className="form-input"
                                                                                    disabled

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-12">
                                                                                <label
                                                                                    className="form-attribute"
                                                                                    style={{
                                                                                        verticalAlign: "bottom",
                                                                                        marginBottom: "0px",
                                                                                    }}
                                                                                >
                                                                                    How is the model operationalized ?
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <input
                                                                                    value={
                                                                                        this.state.ideasDB.ModelSummary
                                                                                    }
                                                                                    placeholder="Please choose"
                                                                                    disabled
                                                                                    className="form-input"

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.ideasDB.IsSustenance && this.state.ideasDB.IsSustenance === "Yes" ? (
                                                                        <>
                                                                            <div className="submit_page_new_fields">
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-8">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: -5,
                                                                                            }}
                                                                                        >

                                                                                            Sustenance tracking frequency ?
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <input
                                                                                            value={this.state.ideasDB.SustenanceFreq}
                                                                                            disabled
                                                                                            placeholder="Please choose"
                                                                                            className="form-input"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="submit_page_new_fields">
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-12">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: "0px",
                                                                                            }}
                                                                                        >

                                                                                            Sustenance metric
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <textarea
                                                                                            className="form-input"
                                                                                            disabled
                                                                                            value={this.state.ideasDB.SustenanceMetric}
                                                                                            placeholder="Please provide sustenance metrics"
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                border: "1px solid lightgrey",

                                                                                            }}
                                                                                            rows={3}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </>
                                                                    ) : null}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="" style={{ borderBottom: "1px solid lightgrey", paddingBottom: "30px" }} /> */}
                                                </>
                                                :
                                                null

                                        }
                                    {/* ............db fields ends here........... */}


                                    {
                                        this.state.ideaItem.TransformationLeverName === "Data" && this.state.ideaItem.IdeaId
                                        && this.state.displayDBFields
                                            ?
                                            <>
                                                <DisplayTemplate IdeaId={this.state.ideaItem.IdeaId} templateType={this.state.ideaItem.DBProjectType} />
                                            </>
                                            :
                                            null
                                    }

                                    {
                                        this.state.ideaItem.TransformationLeverName && this.state.ideaItem.TransformationLeverName === "Data" && this.state.ideasDB.IsSustenance && this.state.ideasDB.IsSustenance === "Yes" && this.state.ideaItem.DBSPocOHR
                                        && this.state.displayDBFields
                                            ?
                                            <>
                                                <div className="" style={{ paddingRight: "30px",borderTop:"1px solid lightgrey",paddingTop:"20px",marginTop:"20px"}}>
                                                    <p className="u-align-left u-text u-text-2">DB questionnaire</p>
                                                    <div className="new_fields_blocks">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Is the MUC ID tagged correctly ?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input
                                                                                value={this.state.ideasDB.IsMUCTag}
                                                                                placeholder='Provide value'
                                                                                disabled
                                                                                className="form-input disabled_input"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Is the DB theme captured correctly?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                disabled
                                                                                value={this.state.ideasDB.IsDBTheme}
                                                                                className="form-input disabled_input"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_fields_blocks">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Is the Tool used captured correctly ?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsToolCorrect}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Are the model details captured appropriately?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsModelCorrect}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_fields_blocks">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Are the model deployment details captured appropriately?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsModelDeploy}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Do the provided signoffs meet value delivery guidelines?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsSignOff}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_fields_blocks">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Are the monetization details captured appropriately?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled={true}
                                                                                value={this.state.ideasDB.IsMonetization}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Are sustenance tracking details OK ?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsSPocSustenance}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_fields_blocks">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Is there any other issue you would like to report ?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsIssue}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Is there any exception applied on the project approval ?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsException}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="" style={{ paddingBottom: "40px", marginBottom: "15px" }} />
                                                </div >
                                            </>
                                            :
                                            null
                                    }
                                    {/* DB SPOC Fields ends here */}



                                    <div className="gol_fields_block" style={{ borderTop: "1px solid lightgray", paddingTop: "20px" }}>
                                        <p className="u-align-left u-text u-text-2" style={{ margin: "0px 0px 20px" }}>Project Review Form</p>
                                        <div className="row u-align-left">
                                            <div className="col-md-5">
                                                <div className="row">
                                                    <div className="col-md-9 gol_field_div">
                                                        <span className="form-attribute"><span style={{ color: 'red' }}>*</span> Is the impact correctly tagged to Genpact or Client ?</span>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <Select styles={this.state.selectStyle.impactTagChangeSelected} options={this.renderFieldsOptions()} onChange={this.handleImpactTagChange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-9 gol_field_div">
                                                        <span className="form-attribute"><span style={{ color: 'red' }}>*</span> Has the Value Delivered been correctly categorized ?</span>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <Select styles={this.state.selectStyle.deliveryCategorizedSelect} options={this.renderFieldsOptions()} onChange={(e) => this.handleDeliveryCategorizedChange(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <div className="row">
                                                    <div className="col-md-9 gol_field_div">
                                                        <span className="form-attribute"><span style={{ color: 'red' }}>*</span> Has the Value Delivered been computed correctly ?</span>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <Select styles={this.state.selectStyle.deliveryComputedCorrectlySelected} options={this.renderFieldsOptions()} onChange={(e) => this.handledeliveryComputedCorrectlySelectedChange(e)} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-9 gol_field_div" style={{display:"flex", alignItems:"flex-start"}}>
                                                        <span style={{ color: 'red',marginRight:"5px" }}>*</span><span className="form-attribute">Do the value delivery approvals (applicable as per the value playbook) clearly articulate what improved and for which time-period ?</span>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <Select styles={this.state.selectStyle.signOffDeliverySelected} options={this.renderFieldsOptions()} onChange={(e) => this.handleSignOffDeliveryChange(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                    <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" >
                                        <div className="u-container-layout" style={{ paddingTop: "20px" }}>
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Quality Check Details</p>
                                            <div className="row" style={{ marginTop: "10px" }}>
                                                <div className="col-md-2"><span className="form-attribute">
                                                    <span style={{ color: 'red' }}>*</span> Approval Status</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <Select styles={this.state.selectStyle.approvalSelectedOption} value={this.state.approvalSelectedOption} isSearchable={false} options={this.renderStatusOptions()} placeholder="Choose Status" onChange={this.handleApprovalStatus} />
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: "10px" }}>
                                                <div className="col-md-7">
                                                    <div className="row" style={{ display: "flex", alignItems: "center" }}>
                                                        <div className="col-md-9">
                                                            <p className="u-align-left" style={{ margin: "0px" }}>
                                                                <span className="u-text-black form-attribute"><span style={{ color: 'red' }}>*</span>&nbsp;Approval Comments<span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}>&nbsp;(max. 500 chars)</span></span>
                                                            </p>
                                                        </div>
                                                        <div className="col-md-2">
                                                            {
                                                                this.state.ideaItem.QCOHR !== ""
                                                                    ?
                                                                    <>
                                                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ qcCommentLog: true })}>Comment Log</button>
                                                                    </>
                                                                    :
                                                                    null
                                                            }

                                                        </div>
                                                        <div className="col-md-1" style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                                            <img onClick={(e) => this.toggleTranscriptFunction(16)} src={require("../assets/images/voice.png")} className="w100" alt="" style={{ width: "24px", cursor: "pointer" }} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className={this.state.ideaItem.QCOHR !== "" ? "col-md-12 mt-2" : "col-md-12 mt-3"}>
                                                    <textarea className="textarea-css form-attribute" rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey', ...this.state.selectStyle.QcComment }} name="Comments" onChange={(e) => this.handleCommentChange(e.target.value)} value={this.state.QcComment} placeholder='Please provide your comments in support of the Approval decision.' />
                                                </div>
                                            </div>

                                            <div className="text-left mt-3">
                                                {
                                                    this.state.qcCommentLog && this.state.qcComments
                                                        ?
                                                        <>
                                                            <div className="custom_modal">
                                                                <div className="modal_body">
                                                                    <div className="row">
                                                                        <div className="col-md-8">
                                                                            <h3 className="modal_title">QC Comment Log</h3>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <button type="button" className="close text-right" onClick={(e) => this.setState({ qcCommentLog: false })}>&times;</button>
                                                                        </div>
                                                                    </div>

                                                                    <div className="qc_responsive_comment_log">
                                                                        <div className="table table-responsive table-bordered">
                                                                            <thead className='text-center'>
                                                                                <tr>
                                                                                    <th className="qcHeaderbg qcVDHeader">
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                    </th>
                                                                                    <th className="qcHeaderbg qcVDHeader">
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                    </th>
                                                                                    <th className="qcHeaderbg qcVDHeader">
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                    </th>
                                                                                    <th className="qcHeaderbg qcVDHeader">
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                    </th>
                                                                                    <th className="qcHeaderbg qcVDHeader">
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                    </th>
                                                                                    <th className="qcHeaderbg" style={{ minWidth: "250px" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                    </th>
                                                                                    <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthOne">
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Is the impact correctly tagged to Genpact or Client ?</label>
                                                                                    </th>
                                                                                    <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthTwo">
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Has the Value Delivery been correctly categorized ?</label>
                                                                                    </th>
                                                                                    <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthThree">
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Has the Value Delivery been computed correctly ?</label>
                                                                                    </th>
                                                                                    <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthFour">
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Do the value delivery approvals (applicable as per the value playbook) clearly articulate what improved and for which time-period ?</label>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    this.state.qcComments.map((item, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === "Delegate Action" ? 'BB' : (item.UpdaterOHR === this.state.MBBOHR ? 'MBB' : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Idea Owner" : (item.ApprovalStatus === "Pending Documents" && item.WorkflowStatus === "Approved") ? "BB" : (item.ApprovalStatus === "NA" && item.WorkflowStatus === "Pending") ? "BB" : item.UpdaterRole)))}</label>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {/* <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.ApprovalStatus==="Documents Uploaded" ? "Send Back" : ((item.WorkflowStatus==="Rejected" || item.WorkflowStatus==="Reject" || item.ApprovalStatus==="Reject" || item.ApprovalStatus==="Rejected" )? "Rejected ":"Approved")}</label> */}
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.ApprovalStatus === "Documents Uploaded" ? "Send Back" : ((item.WorkflowStatus === "Rejected" || item.WorkflowStatus === "Reject" || item.ApprovalStatus === "Reject" || item.ApprovalStatus === "Rejected") ? "Rejected " : (item.Action === "Idea was Rework - QC" ? "Rework" : "Approved"))}</label>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment !== '' ? item.Comment : '-'}</label>
                                                                                                </td>
                                                                                                {
                                                                                                    this.state.qcCommentsWithVD && this.state.qcCommentsWithVD.length > 0
                                                                                                        ?
                                                                                                        <>
                                                                                                            {
                                                                                                                this.state.qcCommentsWithVD.map((pg_item) => {
                                                                                                                    if (Number(pg_item.ProjectTeamID) === item.TrackId) {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                <td>
                                                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>

                                                                                                                                        {JSON.parse(pg_item.AuditDataJSON).pg_isvdimpacttagged ? JSON.parse(pg_item.AuditDataJSON).pg_isvdimpacttagged : "-"}
                                                                                                                                    </label>
                                                                                                                                </td>
                                                                                                                                <td>

                                                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                                                        {JSON.parse(pg_item.AuditDataJSON).pg_isvdcategorized ? JSON.parse(pg_item.AuditDataJSON).pg_isvdcategorized : "-"}

                                                                                                                                    </label>
                                                                                                                                </td>
                                                                                                                                <td>
                                                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                                                        {JSON.parse(pg_item.AuditDataJSON).pg_isvdcomputed ? JSON.parse(pg_item.AuditDataJSON).pg_isvdcomputed : "-"}
                                                                                                                                    </label>
                                                                                                                                </td>
                                                                                                                                <td>
                                                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                                                        {JSON.parse(pg_item.AuditDataJSON).pg_isvdclientsignoff ? JSON.parse(pg_item.AuditDataJSON).pg_isvdclientsignoff : "-"}
                                                                                                                                    </label>
                                                                                                                                </td>

                                                                                                                            </>

                                                                                                                        )
                                                                                                                    }

                                                                                                                })
                                                                                                            }
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                            </td>
                                                                                                        </>
                                                                                                }


                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </tbody>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>

                                        </div>
                                    </div>
                                    {
                                        this.state.speechModal
                                            ?
                                            <UpdatedSpeech modalOpen={true}
                                                onAuditApproval={this.handleTranscriptFinalApprovalComment}
                                                modalId={this.state.modalIndex}
                                                closeModalFunction={this.closeModalFunction}
                                                previousText={this.state.previousTextForSpeech}
                                            />
                                            :
                                            null
                                    }












                                    <div style={{ borderBottom: '1px solid lightgrey', margin: "15px 0px" }} />

                                    <div className="btn-group" style={{ marginTop: 15 }}>
                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.state.submitIdeaCount === 0 ? this.submitIdea(e) : this.setState({ submitIdeaCount: this.state.submitIdeaCount + 1 })}>Submit</button>
                                        <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.cancelEvent}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ViewIdeaPage
