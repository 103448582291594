import React, { Component } from 'react'
import { APPROVAL, LEAN_APPROVAL, GET_ALL_IDEA_URL, GET_METADATA, YESORNO } from '../assets/constants/constants';
import { SORT_DOWN, SORT_UP, SEARCH_ICON, ANGLE_LEFT, ANGLE_RIGHT, ANGLE_DOUBLE_LEFT, ANGLE_DOUBLE_RIGHT, FILTER, CLOSE } from "../assets/constants/Icons.js";
import { VIEW, USER_DATA, ACCESS_TOKEN } from "../assets/constants/constants";
import SortByColumn from "../utils/SortByColumns";
import Toast from 'light-toast';
import Select from 'react-select';

export class ViewAllProjectsPage extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);
    
    constructor(props) {
        super(props)
        let propsData= this.props.location.propsList ? this.props.location.propsList : null;
        if (this.sessionData === null) {
            Toast.loading("This component cannot be accessed directly. Redirecting to application home.", 3000, () => {
            });
            window.location = "/login";
            return;
        }
        const windowUrl = window.location.search;
        const isApprovalPage = windowUrl.indexOf(APPROVAL) > 0;
        const isLeanApprovalPage = windowUrl.indexOf(LEAN_APPROVAL) > 0; 
        this.state = {
            ideaList: [],
            originalIdeaList: [],
            originalProjectList: [],
            ideaNameSortIconRotate: false,
            ownerOHR: this.sessionData === null ? null : this.sessionData.profile.oHRId,
            ownerName: this.sessionData === null ? null : this.sessionData.profile.lastName+ ", " + this.sessionData.profile.firstName,
            approvalPage: isApprovalPage,
            leanApprovalPage: isLeanApprovalPage,
            pageTitle: isApprovalPage ? ( isLeanApprovalPage ? "Review lean projects" : "Review ideas") : "My projects",
            WhereClause:'CertificationLevelName' + '=' + "'" + 'Lean' +"'",
            topCountOption: [5,10,25, 50,75,100],
            topCount:propsData !== null ? parseInt(propsData.topCount) :5,
            skipCount:0,
            isPrevEnabled:false,
            isNextEnabled:true,
            IdeaLength:0,
            totalPages:0,
            currentPage:0,
            filter:false,
            ApprovalStatus:'',
            IdeaId:'',
            AccountManual:'',
            RegionManual:'',
            VerticalManual:'',
            TransformationLeverName:'',
            SubVerticalName:'', 
            ServiceLineName:'', 
            SubServiceLine:'',
            startDate:'', 
            toDate:'',
            isTypedData:false,
            fromFilterSelection:'',
            globalsearchText:'',
            isTypedInFilter:'',
            isTypedInGlobalSearch:false,
            tempApprovalStatus:'',
            tempIdeaId:'',
            tempAccountManual:'',
            tempRegionManual:'',
            tempVerticalManual:'',
            tempTransformationLeverName:'',
            tempSubVerticalName:'', 
            tempServiceLineName:'', 
            tempSubServiceLine:'',
            tempstartDate:'', 
            temptoDate:'',
            accountList: [],
            accountSelectionOption: null,
            workFlowStatusList: [],
            workFlowStatusSelectedOption: null,
            transformationLeversList: [],
            transSelectedOption: null,
            serviceLineList: [],
            subServiceLineList: [],
            serviceLineSelectedOption: null,
            subServiceLineSelectedOption: null,
            regionList: [],
            verticalList: [],
            subVerticalList: [],
            regionSelectedOption: null,
            verticalSelectedOption: null,
            subVerticalSelectedOption: null,
            dateList: ["Created date","Reviewed date"],
            dateSelectedOption:null,
            selectedDate:'',
            bestInClassList: ["Yes","No"],
            bestInClassOption:null,
            selectedBestInClass:'',
            replicaIndexOptionList: [1,2,3,4,5],     
            replicaIndexOption:null,
            selectedReplicaIndex:'',
            monetizableSelectedLabel:"",
            zeroVDSelectedLabel:"",
            propsData:propsData !== null ? propsData: null,
            IdeaIDAlpha:"",
            trueOrFalse:[
                {
                    value:0,
                    label:"True"
                }
                ,
                {
                    value:1,
                    label:"false"
                }
            ],
            searchOwner:"",
            searchMentor:"",
            projectPhase:"",
            approvalDateSortIconRotate:false,
            searchSegment:"",
            searchUnit:"",
            uniqueUnits:[],
            uniqueSegments:[],
            searchSegmentSelectedOption:[],
            searchUnitSelectedOption:[]
        }
    }


    async componentDidMount() {
        if(this.state.propsData){
            if(this.state.propsData.WhereClause !==''){
                if(this.state.propsData.selectedDate === 'CreatedDate'){
                    this.setState({selectedDate:'Created Date'});
                }
                else if(this.state.propsData.selectedDate === 'ModifiedDate'){
                    this.setState({selectedDate:'Reviewed Date'});
                }

                if(this.state.propsData.selectedBestInClass === 1){
                    this.setState({selectedBestInClass:"Yes"});
                }
                else if(this.state.propsData.selectedBestInClass === 0){
                    this.setState({selectedBestInClass:"No"});
                }
                this.setState({
                    WhereClause:this.state.propsData.WhereClause,
                    topCount:this.state.topCount,
                    skipCount:this.state.propsData.skipCount,
                    tempApprovalStatus:this.state.propsData.ApprovalStatus,
                    tempIdeaId:this.state.propsData.IdeaId,
                    tempAccountManual:this.state.propsData.AccountManual,
                    tempRegionManual:this.state.propsData.RegionManual,
                    tempVerticalManual:this.state.propsData.VerticalManual,
                    tempTransformationLeverName:this.state.propsData.TransformationLeverName,
                    tempSubVerticalName:this.state.propsData.SubVerticalName, 
                    tempServiceLineName:this.state.propsData.ServiceLineName, 
                    tempSubServiceLine:this.state.propsData.SubServiceLine,
                    tempstartDate:this.state.propsData.startDate, 
                    temptoDate:this.state.propsData.toDate,
                    selectedReplicaIndex:this.state.propsData.selectedReplicaIndex,
                    monetizableSelectedLabel:this.state.propsData.monetizableSelectedLabel,
                    zeroVDSelectedLabel:this.state.propsData.zeroVDSelectedLabel,
                    filter:!this.state.filter
                }, async() => {
                    await this.filterHandler();
                    await this.fetchIdea();
                })
            }
            else if(!this.state.propsData.WhereClause && this.state.propsData.skipCount){
                this.setState({
                    topCount:this.state.propsData.topCount,
                    skipCount:this.state.propsData.skipCount
                }, async() => {
                    await this.fetchIdea();
                })
            } 
        }
        else{
            await this.fetchIdea();
        }
        await this.fetchMetedata();
        Toast.hide();            
    }

    fetchIdea = () => {
        Toast.loading("Fetching projects...", () => { });
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
            };
            
            if(this.state.skipCount===0){
                this.setState({
                    isPrevEnabled:false
                })
            }
            else{
                this.setState({
                    isPrevEnabled:true
                })
            }
            fetch(`${GET_ALL_IDEA_URL}UserOHR=''&WhereClause=${this.state.WhereClause}&skipCount=${this.state.skipCount}&topCount=${this.state.topCount}&isItIdeaOrGenAI='Idea'&CreatedBy=''`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("fetch data =>",data)
                    this.setState({
                        ideaList: data.IdeaList.ResultSets.Table1 === undefined ? [] : data.IdeaList.ResultSets.Table1,
                        originalIdeaList: data.IdeaList.ResultSets.Table1 === undefined ? [] : data.IdeaList.ResultSets.Table1,
                        IdeaLength:data.TotalIdea.Table1[0].length,
                        fromFilterSelection:data.filter,
                        //ideaIdAlpha: data.IdeaList.ResultSets.Table1.IdeaIDAlpha
                    }, () => {
                        // console.log(this.state.ideaList)
                        console.log(this.state.originalIdeaList)
                        let totalPages = Math.ceil(parseInt(this.state.IdeaLength)/parseInt(this.state.topCount));
                        let currentPage = Math.ceil(parseInt(this.state.skipCount)/(parseInt(this.state.topCount)) +1);
                        let sortedData = SortByColumn(this.state.ideaList, "CreatedDate", "desc");
                       
                        if(totalPages===0){
                            totalPages=1
                        }
                        this.setState({
                            ideaList: sortedData,
                            createdDateSortIconRotate: "desc",
                            totalPages:totalPages,
                            currentPage:currentPage
                        }, () => {
                            // console.log("currentPage",this.state.currentPage);
                            // console.log("totalPages",this.state.totalPages);
                            
                            if(this.state.currentPage === this.state.totalPages){
                                this.setState({
                                    isNextEnabled:false,
                                    // skipCount:0
                                })
                            }
                            else{
                                this.setState({
                                    isNextEnabled:true
                                })
                            }
                        });
                        this.mergeData(); 
                        Toast.hide();
                        resolve();
                    });
                })
                .catch(error => {
                    console.log(error);
                    Toast.hide();
                    resolve();
                }); 
        });
    }

    mergeData = () => {
        return new Promise((resolve, reject) => {
            let ideaConversionArray = [];
            this.state.originalIdeaList.map((ideaItem, index) => {
                //console.log("idea item =>",ideaItem)
                ideaConversionArray.push({
                    "pg_projectid": ideaItem.LeanID ? (ideaItem.LeanID !== "" ? ideaItem.LeanID : ideaItem.IdeaId) : ideaItem.IdeaId,
                    "pg_projowner": ideaItem.OwnerName,
                    "pg_ownerohr" : ideaItem.OwnerOHR,
                    "pg_account": ideaItem.AccountManual,
                    "msdyn_subject": ideaItem.IdeaName,
                    "pg_vertical": ideaItem.VerticalManual,
                    "pg_projectstatus": ideaItem.WorkflowStatus,
                    "pg_worktypetxt": ideaItem.CertificationLevelName + " - " + ideaItem.TransformationLeverName,
                    "pg_projcreatedate": ideaItem.CreatedDate,
                    "pg_projectstate": ideaItem.ApprovalStatus,
                    "IdeaId": ideaItem.IdeaId,
                    "type": "idea",
                    "serviceLine":ideaItem.ServiceLineName,
                    "certificateLevel":ideaItem.CertificationLevelName,
                    "transformationLevel":ideaItem.TransformationLeverName,
                    "IsBestInClass":ideaItem.IsBestInClass,
                    "IdeaIdAlpha":ideaItem.IdeaIDAlpha,
                    "BlackBeltUserName":ideaItem.BlackBeltUserName,
                    "SubRegion":ideaItem.SubRegion,
                    "RegionManual":ideaItem.RegionManual,
                    "SubVerticalName":ideaItem.SubVerticalName,
                    "IsVDMonetizable":ideaItem.IsVDMonetizable,
                    "IsZeroImpactProject":ideaItem.IsZeroImpactProject,
                    "BlackBeltUserId":ideaItem.BlackBeltUserId,
                    "ApprovalDate":ideaItem.ApprovalDate,
                    "Unit":ideaItem.Unit,
                    "Segment":ideaItem.Segment
                });
                return true;
            });
            let sortedData = SortByColumn(ideaConversionArray, "pg_projcreatedate", "desc");
            this.setState({
                ideaList: sortedData,
                originalIdeaList: sortedData
            }, () => {
                //console.log("Idealist:",this.state.ideaList);
                resolve();
            })
        });
    };
    
    fetchMetedata = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "",
                EntityName: ""
            }
            const requestOptions = {
                method: 'POST',
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                Toast.hide();
                this.setState({
                    accountList: data ? data.Accounts.sort((a, b) => { return a.ACCOUNT > b.ACCOUNT ? 1 : -1; }) : [],
                    serviceLineList: data ? data.ServiceLines : [],
                    subServiceLineList: data ? data.ServiceLines : [],
                    workFlowStatusList: data ? data.WorkflowStatus : [],
                    transformationLeversList: data ? data.TransformationLevers : [],
                    regionList:data?data.Region:[],
                    verticalList:data?data.VerticalData:[],
                    subVerticalList: data?data.SubVerticalData:[],
                    uniqueUnits:data ? data.UnitSegment ? data.UnitSegment.ResultSets ? data.UnitSegment.ResultSets.Table1 : []: []:[],
                    uniqueSegments:data ? data.UnitSegment ? data.UnitSegment.ResultSets ? data.UnitSegment.ResultSets.Table2 : []: []:[],

                });
                resolve();
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting Idea Metadata. Please try again!', 3000, () => {
                    console.log(JSON.stringify(error));
                });
            }); 
        });
    }

    renderVerticalOptions = () => {
        let options = [];
        this.state.verticalList.map((item) => {
            if(item.Vertical !== "TS"){
                options.push({
                    value: item.Vertical,
                    label: item.Vertical
                })
            }
            return options;
        });
        return options;
    }

    renderSubVerticalOptions = () => {
        let options = [];
        this.state.subVerticalList.map((item) => {
            if(item.SubVertical !== "corporate" && item.SubVertical !== "Unmapped"){
                options.push({
                    value: item.SubVertical,
                    label: item.SubVertical
                })
            }
            return options;
        });
        return options; 
    }

    renderTopCountOptions = () => {
        if(this.state.topCountOption.length > 0){
            return this.state.topCountOption.map((item) => {
                let isSelected = false;
                if(item === this.state.topCount){
                    isSelected = true;
                }
                return(
                    <option selected= { isSelected }>{ item }</option>
                );
            });
        }
    }

    topCountChangeHandler = async (event) => {
        const index = event.nativeEvent.target.selectedIndex;
        this.setState({
            topCount: event.nativeEvent.target[index].text
        }, async () => { 
            await this.fetchIdea();
        });
    }

    handleGetFirstPage = () => {
        this.setState({
            skipCount:0
        }, async () => {
            await this.fetchIdea();
        })
    }

    handleGetLastPage = () => {
        let skipCount = (parseInt(this.state.totalPages) - 1) * parseInt(this.state.topCount);
        this.setState({
            skipCount:skipCount
        }, async () => {
            await this.fetchIdea();
        })
    }

    handlePrevAction = () => {
        let skipCount = this.state.skipCount;
        let topCount = this.state.topCount;
        this.setState({
            skipCount:parseInt(skipCount)-parseInt(topCount),
        }, async () => {
            console.log("skip prev", this.state.skipCount);
            console.log("top count",this.state.topCount);
            await this.fetchIdea();
        })
    }

    handleNextAction = async() =>{
        let skipCount = this.state.skipCount;
        let topCount = this.state.topCount;
        this.setState({
            skipCount: parseInt(skipCount) + parseInt(topCount)
        }, async () => {
            console.log("skip next", this.state.skipCount);
            console.log("top count",this.state.topCount);
            await this.fetchIdea();
        })
    }

    handleIdeaAction = (idea, action) => {
        console.log(idea)
         this.props.history.push({
                pathname: "/view-idea-page",
                state: idea.IdeaId,
                isViewAllProject: true,
                WhereClause:this.state.WhereClause,
                topCount:this.state.topCount,
                skipCount:this.state.skipCount,
                ApprovalStatus:this.state.ApprovalStatus,
                IdeaId:this.state.IdeaId,
                AccountManual:this.state.AccountManual,
                RegionManual:this.state.RegionManual,
                VerticalManual:this.state.VerticalManual,
                TransformationLeverName:this.state.TransformationLeverName,
                SubVerticalName:this.state.SubVerticalName, 
                ServiceLineName:this.state.ServiceLineName, 
                SubServiceLine:this.state.SubServiceLine,
                startDate:this.state.startDate, 
                toDate:this.state.toDate,
                selectedDate:this.state.selectedDate,
                selectedBestInClass:this.state.selectedBestInClass,
                selectedReplicaIndex:this.state.selectedReplicaIndex,
                monetizableSelectedLabel:this.state.monetizableSelectedLabel,
                zeroVDSelectedLabel:this.state.zeroVDSelectedLabel,
                globalsearchText:this.state.globalsearchText
            })
        
    }
    

    handleAccountSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                accountSortIconRotate: !prevState.accountSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.accountSortIconRotate ? "desc" : "asc";
            let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                ideaList: sortedData
            });
        })
    }

    handleProjectIdSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                projectIdSortIconRotate: !prevState.projectIdSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.projectIdSortIconRotate ? "desc" : "asc";
            let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                ideaList: sortedData
            });
        })
    }

    

    handleProjectNameSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                projectNameSortIconRotate: !prevState.projectNameSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.projectNameSortIconRotate ? "desc" : "asc";
            let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                ideaList: sortedData
            });
        })
    }

    handleServiceLineSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                serviceLineSortIconRotate: !prevState.serviceLineSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.serviceLineSortIconRotate ? "desc" : "asc";
            let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                ideaList: sortedData
            });
        })
    }

    handleStatusSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                statusSortIconRotate: !prevState.statusSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.statusSortIconRotate ? "desc" : "asc";
            let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                ideaList: sortedData
            });
        })
    }

    handleStateSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                stateSortIconRotate: !prevState.stateSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.stateSortIconRotate ? "desc" : "asc";
            let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                ideaList: sortedData
            });
        })
    }

    handleProjectTypeSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                projectTypeSortIconRotate: !prevState.projectTypeSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.projectTypeSortIconRotate ? "desc" : "asc";
            let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                ideaList: sortedData
            });
        })
    }

    handleProjectOwnerSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                projectOwnerSortIconRotate: !prevState.projectOwnerSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.projectOwnerSortIconRotate ? "desc" : "asc";
            let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                ideaList: sortedData
            });
        })
    }

    handleProjectMentorSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                projectMentorSortIconRotate: !prevState.projectMentorSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.projectMentorSortIconRotate ? "desc" : "asc";
            let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                ideaList: sortedData
            });
        })
    }

    


    handleAccountSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                accountSortIconRotate: !prevState.accountSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.accountSortIconRotate ? "desc" : "asc";
            let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                ideaList: sortedData
            });
        })
    }

    handleVerticalSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                verticalSortIconRotate: !prevState.verticalSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.verticalSortIconRotate ? "desc" : "asc";
            let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                ideaList: sortedData
            });
        })
    }

    handleSegmentSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                segmentSortIconRotate: !prevState.segmentSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.segmentSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleUnitSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                unitSortIconRotate: !prevState.unitSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.unitSortIconRotate ? "desc" : "asc";
            this.setState({
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleIdeaIdSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                ideaIdSortIconRotate: !prevState.ideaIdSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.ideaIdSortIconRotate ? "desc" : "asc";
            let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                ideaList: sortedData
            });
        })
    }

    handleRegionSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                regionSortIconRotate: !prevState.regionSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.regionSortIconRotate ? "desc" : "asc";
            let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                ideaList: sortedData
            });
        })
    }

    formatDateString(dateparam){
        if(dateparam){
            let abc = new Date(dateparam);
            return abc.toLocaleDateString("en-GB", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
        }
    }

    globalSearch = () =>{
        if(this.state.currentPage === this.state.totalPages){
            this.setState({
                skipCount:0
            })
        }
        let WhereClause = '';
        if(this.state.globalsearchText !== null && this.state.globalsearchText !== ''){
            console.log("this.state.isTyped:", this.state.isTypedInFilter);
            
            
            WhereClause= this.state.isTypedInFilter !== '' ? this.state.isTypedInFilter + ' and ': 'CertificationLevelName' + '='+"'"+'lean'+"'" + ' and ';
            WhereClause=WhereClause+encodeURIComponent( "Concat(WorkflowStatus,IdeaIDAlpha,AccountManual,VerticalManual,RegionManual,TransformationLeverName,SubVerticalName,ServiceLineName,OwnerName,BlackBeltUserName,BlackBeltUserId,IdeaName,SubServiceLine,LeanID,MBBOHR,LDTId,LDTLeadName,BlackBeltUserEmail,MBBName,Unit,Segment ) like '%"+this.state.globalsearchText+"%'")
            
            // WhereClause=WhereClause+ '('+ 'ApprovalStatus'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or '  +  'WorkflowStatus'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or '  + '('+ 'LeanID'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + ' or ' + 'IdeaId'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + ')' + 
            // ' or '  +  'AccountManual'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'VerticalManual'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'RegionManual'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'TransformationLeverName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'SubVerticalName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'ServiceLineName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'IdeaName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'OwnerName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'BlackBeltUserName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + 
            // ' or ' + 'SubServiceLine'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + ')';

            if(WhereClause !== ''){
                this.setState({
                    WhereClause:WhereClause,
                    filter:false
                }, async() =>{
                    await this.fetchIdea();
                })
            }
        }
        else{
            Toast.fail("Please type something then search.");
            return;
        }
    }

    resetData = () => {
        this.setState({
            ApprovalStatus:'',
            IdeaId:'',
            AccountManual:'',
            RegionManual:'',
            VerticalManual:'',
            TransformationLeverName:'',
            SubVerticalName:'', 
            ServiceLineName:'', 
            SubServiceLine:'',
            startDate:'', 
            toDate:'',
            filter:false
        }, () => {
            window.location.reload();
        })
    }

    searchData = async() =>{
        let WhereClause = '';

        if(this.state.ApprovalStatus !== ''){
            WhereClause = 'WorkflowStatus'+ ' ' + 'like' + " '" + this.state.ApprovalStatus + '%'+"'"+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }
        if(this.state.IdeaId !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + '('+ 'LeanID'+ ' ' + 'like' + " '" + this.state.IdeaId + '%'+"'" + ' or ' + 'IdeaId'+ ' ' + 'like' + " '" + this.state.IdeaId + '%'+"'" + ')' + ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }
        if(this.state.AccountManual !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'AccountManual'+ ' ' + 'like' + " '" + this.state.AccountManual + '%'+"'"+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }
        if(this.state.VerticalManual !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'VerticalManual'+ ' ' + 'like' + " '" + this.state.VerticalManual + '%'+"'"+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }
        if(this.state.RegionManual !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'RegionManual'+ ' ' + 'like' + " '" + this.state.RegionManual + '%'+"'"+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }
        if(this.state.TransformationLeverName !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'TransformationLeverName'+ ' ' + 'like' + " '" + this.state.TransformationLeverName + '%'+"'"+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }
        if(this.state.SubVerticalName !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'SubVerticalName'+ ' ' + 'like' + " '" + this.state.SubVerticalName + '%'+"'"+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }
        if(this.state.ServiceLineName !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'ServiceLineName'+ ' ' + 'like' + " '" + encodeURIComponent(this.state.ServiceLineName) + '%'+"'"+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }
        if(this.state.SubServiceLine !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'SubServiceLine'+ ' ' + 'like' + " '" + encodeURIComponent(this.state.SubServiceLine) + '%'+"'"+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }


        if(this.state.searchOwner !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + '('+ 'OwnerName'+ ' ' + 'like' + " '" + this.state.searchOwner + '%'+"'" + ' or ' + 'OwnerOHR'+ ' ' + 'like' + " '" + this.state.searchOwner + '%'+"'" + ')' + ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }
        if(this.state.searchMentor !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + '('+ 'BlackBeltUserName'+ ' ' + 'like' + " '" + this.state.searchMentor + '%'+"'" + ' or ' + 'BlackBeltUserId'+ ' ' + 'like' + " '" + this.state.searchMentor + '%'+"'" + ')' + ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }

        if(this.state.projectPhase !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + '('+ 'ApprovalStatus'+ ' ' + 'like' + " '" + this.state.projectPhase + '%'+"'" + ')' + ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }

        


        if(this.state.startDate !== '' && this.state.toDate !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            // WhereClause =  WhereClause + '('+ this.state.selectedDate + ' ' + 'between ' + "'"+ this.state.startDate+'T00:00:00.00' +"'"+ ' and ' +"'"+ this.state.toDate+'T23:59:00.00' +"'"+ ')'+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
            if(this.state.selectedDate === "ModifiedDate"){
                WhereClause =  WhereClause + '(' + '('+ this.state.selectedDate + ' ' + 'between ' + "'"+ this.state.startDate+'T00:00:00.00' +"'"+ ' and ' +"'"+ this.state.toDate+'T23:59:00.00' +"'"+ ')' + ' and ' + 'WorkflowStatus' + ' <> '  +"'" +'Pending'+ "'" + ')'+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
            }
            else{
                WhereClause =  WhereClause + '('+ this.state.selectedDate + ' ' + 'between ' + "'"+ this.state.startDate+'T00:00:00.00' +"'"+ ' and ' +"'"+ this.state.toDate+'T23:59:00.00' +"'"+ ')'+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
            }
        }
        if(this.state.selectedBestInClass !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'IsBestInClass'+ ' ' + 'like' + " '" + this.state.selectedBestInClass + '%'+"'"+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }
        if(this.state.selectedReplicaIndex !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'ReplicabilityIndex'+ ' ' + 'like' + " '" + this.state.selectedReplicaIndex + '%'+"'"+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }

        if(this.state.monetizableSelectedLabel !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'IsVDMonetizable'+ ' ' + 'like' + " '" + this.state.monetizableSelectedLabel + '%'+"'"+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
        }

        if(this.state.zeroVDSelectedLabel !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            let convertedBooleanZeroVD = "";
            console.log("zero vd selected label =>",this.state.zeroVDSelectedOption)
            if(this.state.zeroVDSelectedOption){
                if(this.state.zeroVDSelectedOption.label === "Yes"){
                    convertedBooleanZeroVD = "1";
                }
                if(this.state.zeroVDSelectedOption.label === "No"){
                    convertedBooleanZeroVD = "0";
                }
            }
            console.log("convertedBooleanZeroVD",convertedBooleanZeroVD)
            WhereClause =  WhereClause + 'IsZeroImpactProject'+ ' ' + 'like' + " '" + convertedBooleanZeroVD + '%'+"'"+ ' and ' + 'CertificationLevelName' + '='+"'"+'lean'+"'";
            
        }

        if(this.state.startDate !== ''){
            if(!this.state.selectedDate){
                Toast.fail("Please select date type first.");
                return;
            }
            if(this.state.toDate === ''){
                Toast.fail("Please enter both the start date and end date.");
                return;
            }
        }

        if(this.state.toDate !== ''){
            if(!this.state.selectedDate){
                Toast.fail("Please select date type first.");
                return;
            }
            if(this.state.startDate === ''){
                Toast.fail("Please enter both the start date and end date.");
                return;  
            }
        }

        if(this.state.selectedDate && (this.state.startDate === '' || this.state.toDate === '')){
            Toast.fail("Please enter both the start date and end date.");
            return; 
        }

        if(this.state.startDate && this.state.toDate ){
            if(this.state.startDate > this.state.toDate){
                Toast.fail("Start date should not be greater than end date.");
                return; 
            }
        }

        if(this.state.searchSegment !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'Segment'+ ' ' + 'like' + "'" + encodeURIComponent(this.state.searchSegment) + '%'+"'";
        }
        if(this.state.searchUnit !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'Unit'+ ' ' + 'like' + "'" + encodeURIComponent(this.state.searchUnit) + '%'+"'";
        }
        
        if(WhereClause !== ''){
            this.setState({
                WhereClause:WhereClause,
                isTypedInFilter:WhereClause,
                tempApprovalStatus:this.state.ApprovalStatus,
                tempIdeaId:this.state.IdeaId,
                tempAccountManual:this.state.AccountManual,
                tempRegionManual:this.state.RegionManual,
                tempVerticalManual:this.state.VerticalManual,
                tempTransformationLeverName:this.state.TransformationLeverName,
                tempSubVerticalName:this.state.SubVerticalName, 
                tempServiceLineName:this.state.ServiceLineName, 
                tempSubServiceLine:this.state.SubServiceLine,
                tempstartDate:this.state.startDate, 
                temptoDate:this.state.toDate,
            }, async() =>{
                await this.fetchIdea();
            })
        }
        else{
            if(this.state.tempIdeaId){
                this.refreshSearch();
            }
            else{
                Toast.fail("Please type something then search.");
            }
        }
    }

    renderDateOptions = () => {
        let options = [];
        this.state.dateList.map((item) => {
            options.push({
                value: item,
                label: item
            })
            return options;
        });
        return options;
    }

    renderReplicaIndexOption = () => {
        let options = [];
        this.state.replicaIndexOptionList.map((item) => {
            options.push({
                value: item,
                label: item
            })
            return options;
        });
        return options;
    }

    renderBestInClassOptions = () => {
        let options = [];
        this.state.bestInClassList.map((item) => {
            options.push({
                value: item,
                label: item
            })
            return options;
        });
        return options;
    }

    renderStatusOptions = () => {
        let options = [];
        this.state.workFlowStatusList.map((item) => {
            options.push({
                value: item.WorkflowStatusId,
                label: item.WorkflowStatusName
            })
            return options;
        });
        return options;
    }

    renderAccountOptions = () => {
        let options = [];
        let accountName = "";
        this.state.accountList.map((item) => {
            accountName = item.ACCOUNT.toString().toLowerCase();
            if ( !( accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact") ) ){
                options.push({
                    value: item.ACCOUNT_L2,
                    label: item.ACCOUNT
                })
            }
            return true;
        });
        return options;
    }

    renderTransLeversOptions = () => {
        let options = [];
        this.state.transformationLeversList.map((item) => {
            options.push({
                value: item.TransformationLeverId,
                label: item.TransformationLeverName
            });
            return options;
        });
        return options;
    }

    renderRegionOptions  = () => {
        let options = [];
        this.state.regionList.map((item) => {
            options.push({
                value: item.Region,
                label: item.Region
            })
            return options;
        });
        return options;
    }

    renderServiceLineOptions = () => {
        let options = [];
        const uniqueServiceLineList = [...new Map(this.state.serviceLineList.map(item =>
            [item.ServiceLineName, item])).values()];
        uniqueServiceLineList.map((item) => {
            options.push({ value: item.ServiceLineId, label: item.ServiceLineName });
            return true;
        });
        return options;
    }
    
    renderSubServiceLineOptions = () => {
        let options = [];
        this.state.subServiceLineList.map((item) => {
            if (this.state.ServiceLineName === item.ServiceLineName) {
                options.push({ value: item.SubServiceLineId, label: item.SubServiceLineName });
                return true;
            }
            return true;
        });
        return options;
    }

    handleDateOptionChange= (e) => {
        this.setState({
            isTypedData:true,
            dateSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        },() => {
            if(e.label === "Created date"){
                this.setState({
                    selectedDate:"CreatedDate"
                })
            }
            else if(e.label === "Reviewed date"){
                this.setState({
                    selectedDate:"ModifiedDate"
                })
            }
        });
    }

    handleBestInClassOptionChange= (e) => {
        if(e.label === "Yes"){
            this.setState({
                selectedBestInClass:1
            })
        }
        else if(e.label === "No"){
            this.setState({
                selectedBestInClass:0
            })
        }

        this.setState({
            isTypedData:true,
            bestInClassOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }

    handleReplicaIndexOptionChange= (e) => {
        this.setState({
            isTypedData:true,
            selectedReplicaIndex:e.label,
            replicaIndexOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }


    handleMonetizableOptionChange=(e)=>{
        this.setState({
            isTypedData:true,
            monetizableSelectedLabel:e.label,
            monetizableSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }


    handleZeroVDOptionChange=(e)=>{
        if(e.label === "Yes"){
            this.setState({
                isTypedData:true,
                zeroVDSelectedLabel:true,
                zeroVDSelectedOption: {
                    value: e.value,
                    label: e.label
                },
                skipCount:0
            }, async () => {
                await this.searchData();
            })
        }
        if(e.label === "No"){
            this.setState({
                isTypedData:true,
                zeroVDSelectedLabel:false,
                zeroVDSelectedOption: {
                    value: e.value,
                    label: e.label
                },
                skipCount:0
            }, async () => {
                await this.searchData();
            })
        }
        
    }


    handleOwnerSearch= (searchText) => {
        this.setState({
            searchOwner:searchText,
            isTypedData:true
        })
    }
    handleSearchMentor= (searchText) => {
        this.setState({
            searchMentor:searchText,
            isTypedData:true
        },()=>{
            console.log("search owner =>",this.state.searchOwner)
            console.log("search mentor =>",this.state.searchMentor)
        })
    }
    handleProjectPhaseChange= (searchText) => {
        this.setState({
            projectPhase:searchText,
            isTypedData:true
        })
    }


    renderUniqueSegment=()=>{
        let options = [];
        let accountName = "";
        this.state.uniqueSegments.map((item,index) => {
            console.log()
            accountName = item.SEGMENT ? item.SEGMENT.toString().toLowerCase() : "";
            if ( !( accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact") || 
            accountName === "" || accountName === null || accountName === undefined) ){
                options.push({
                    value: index,
                    label: item.SEGMENT
                })
            }
            return true;
        });
                return options;
    }

    renderUniqueUnits=()=>{
        let options = [];
        let accountName = "";
        this.state.uniqueUnits.map((item,index) => {
            accountName = item.UNIT ? item.UNIT.toString().toLowerCase():"";
            if ( !( accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact") || 
            accountName === "" || accountName === null || accountName === undefined) ){
                options.push({
                    value: index,
                    label: item.UNIT
                })
            }
            return true;
        });
        return options;
    }


    handleSegmentOptionChange=(e)=>{
        this.setState({
            isTypedData:true,
            searchSegment:e.label,
            searchSegmentSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }
    handleUnitOptionChange=(e)=>{
        this.setState({
            isTypedData:true,
            searchUnit:e.label,
            searchUnitSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }







    handleApprovalStatusFilterChange = (e) => {
        this.setState({
            ApprovalStatus:e.label,
            isTypedData:true,
            workFlowStatusSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }

    handleIdeaIDFilterChange = (searchText) => {
        this.setState({
            IdeaId:searchText,
            isTypedData:true
        })
    }

    handleAccountFilterChange = (e) => {
        this.setState({
            AccountManual:e.label,
            accountName: e.label,
            isTypedData:true,
            accountSelectionOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        });
    }

    handleVerticalFilterChange = (e) => {
        this.setState({
            subVerticalSelectedOption: null,
            VerticalManual: e.label,
            verticalSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0,
            isTypedData:true
        }, async () => {
            await this.searchData();
        })
    }

    handleRegionFilterChange = (e) => {
        this.setState({
            RegionManual:e.label,
            isTypedData:true,
            regionSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })   
    }

    handleTransformationLeverNameFilterChange = (e) => {
        this.setState({
            TransformationLeverName:e.label,
            isTypedData:true,
            transSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }

    handleSubVerticalNameFilterChange = (e) => {
        this.setState({
            SubVerticalName: e.label,
            subVerticalSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0,
            isTypedData:true
        }, async () => {
            await this.searchData();
        })
    }

    handleService =(e)=>{
        this.setState({
            ServiceLineName:e.label,
            isTypedData:true,
            serviceLineSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0,
            subServiceLineSelectedOption:null,
            SubServiceLine:''
        }, async () => {
            await this.searchData();
        })
    }

    handleSubServiceLine =(e)=>{
        this.setState({
            SubServiceLine:e.label,
            isTypedData:true,
            subServiceLineSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }

    handleCreatedDate=(dates)=>{
        this.setState({
            startDate:dates,
            isTypedData:true
        });
    }

    handleToDate=(dates)=>{
        this.setState({
            toDate:dates,
            isTypedData:true
        });
    
    }

    handleGlobalSearchFilterChange = (searchText) => {
        this.setState({
            globalsearchText:searchText,
            isTypedData:true,
            isTypedInGlobalSearch:true
        })
        if(searchText === ''){
            window.location.reload();
        }
    }

    refreshSearch = () => {
        window.location.reload();
    }

    filterHandler = () => {
        if(this.state.propsData){
            this.setState({
                workFlowStatusSelectedOption: this.state.propsData.ApprovalStatus ? {value: this.state.tempApprovalStatus,label: this.state.tempApprovalStatus} : null,
                accountSelectionOption: this.state.propsData.AccountManual ? {value: this.state.tempAccountManual,label: this.state.tempAccountManual} : null,
                transSelectedOption: this.state.propsData.TransformationLeverName ? {value: this.state.tempTransformationLeverName,label: this.state.tempTransformationLeverName} : null,
                serviceLineSelectedOption: this.state.propsData.ServiceLineName ? {value: this.state.tempServiceLineName,label: this.state.tempServiceLineName} : null,
                subServiceLineSelectedOption: this.state.propsData.SubServiceLine ? {value: this.state.tempSubServiceLine,label: this.state.tempSubServiceLine} : null,
                regionSelectedOption: this.state.propsData.RegionManual ? {value: this.state.tempRegionManual,label: this.state.tempRegionManual} : null,
                verticalSelectedOption: this.state.propsData.VerticalManual ? {value: this.state.tempVerticalManual,label: this.state.tempVerticalManual} : null,
                subVerticalSelectedOption: this.state.propsData.SubVerticalName ? {value: this.state.tempSubVerticalName,label: this.state.tempSubVerticalName} : null,
                dateSelectedOption:this.state.propsData.selectedDate ? {value: this.state.selectedDate,label: this.state.selectedDate} : null,
                bestInClassOption:(this.state.propsData.selectedBestInClass === 0 || this.state.propsData.selectedBestInClass === 1) ? {value: this.state.selectedBestInClass,label: this.state.selectedBestInClass} : null,
                replicaIndexOption:this.state.propsData.selectedReplicaIndex ? {value: this.state.selectedReplicaIndex,label: this.state.selectedReplicaIndex} : null,
                monetizableSelectedOption:this.state.propsData.monetizableSelectedLabel ? {value: this.state.monetizableSelectedLabel,label: this.state.monetizableSelectedLabel} : null,
                zeroVDSelectedLabel:this.state.propsData.zeroVDSelectedLabel ? {value: this.state.zeroVDSelectedLabel,label: this.state.zeroVDSelectedLabel} : null,
                globalsearchText:this.state.propsData.globalsearchText ? this.state.propsData.globalsearchText : null
            });
        }
        this.setState({
            filter:!this.state.filter,
            IdeaId:this.state.tempIdeaId,
            WorkflowStatus:this.state.tempApprovalStatus,
            AccountManual:this.state.tempAccountManual,
            RegionManual:this.state.tempRegionManual,
            VerticalManual:this.state.tempVerticalManual,
            TransformationLeverName:this.state.tempTransformationLeverName,
            SubVerticalName:this.state.tempSubVerticalName, 
            ServiceLineName:this.state.tempServiceLineName, 
            SubServiceLine:this.state.tempSubServiceLine,
            startDate:this.state.tempstartDate, 
            toDate:this.state.temptoDate
        });
    }


    handleApprovalIdSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                approvalDateSortIconRotate: !prevState.approvalDateSortIconRotate
            }
        }, () => {
            // console.log(this.state.approvalDateSortIconRotate)
            let sortOrder = this.state.approvalDateSortIconRotate ? "desc" : "asc";
            console.log("idealist",this.state.ideaList)
            console.log("sord",sortOrder)
            console.log("column name ",columnName)
            let sortedData = SortByColumn(this.state.ideaList,"ApprovalDate", sortOrder);
            this.setState({
                ideaList: sortedData
            });
        })
    }

    render() {

        


        return (
            <>
                <div>
                    <h4 className="u-text" style={{ color: 'white', fontSize: '32px', position: 'absolute', left: '43%', top: '90px' }}>View all lean projects</h4>
                    <img src={require("../assets/images/listbanner.png")} alt="" style={{ width: '100%' }} />
                </div>            
                <section className="u-clearfix u-section-1" id="sec-0f7f">
                    <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20, width: '100%'  }}>
                        <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                            <div className="u-container-layout u-container-layout-1 view-idea-table">
                                <div id="idea1" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                    <div className="u-container-layout u-container-layout-7">
                                        <div className="row">
                                            <div className="col-md-3" style={{marginTop:15}}>
                                                <div className="filter_main_block">
                                                    <i style={{ marginTop: 5, position: 'absolute', fontSize:18}} className={`${ FILTER } filterblock_title`} onClick={this.filterHandler} title="Search" aria-hidden="true"></i>
                                                        {
                                                        this.state.filter
                                                        ?
                                                        <>
                                                            <div className="filter_block">
                                                                <h3><span>Filters:</span> <span style={{float:'right', cursor:'pointer'}}><i className={CLOSE} onClick={()=>this.setState({filter:false})}></i></span></h3>
                                                                <div>
                                                                    <Select isSearchable={true} options={this.renderDateOptions()} placeholder="Choose date" onChange={this.handleDateOptionChange} value={this.state.dateSelectedOption} />
                                                                </div>
                                                                <div className="filter_input">
                                                                    <input type="date" max="2099-01-01" min ="1970-01-01" value={this.state.startDate} onChange={(event) => this.handleCreatedDate(event.target.value)} placeholder="Select from date" required />
                                                                </div>
                                                                <div className="filter_input">
                                                                    <input type="date" max="2099-01-01" min ="1970-01-01" value={this.state.toDate} onChange={(event)=>this.handleToDate(event.target.value)} placeholder="Select to date" required/>
                                                                </div>
                                                                <div className="filter_input">
                                                                    <input type="text" value={this.state.IdeaId} onChange={(event) => this.handleIdeaIDFilterChange(event.target.value)} placeholder="Search project ID" onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.searchData()
                                                                        }
                                                                    } } 
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        <Select isSearchable={true} options={this.renderStatusOptions()} placeholder="Choose Status" onChange={this.handleApprovalStatusFilterChange} value={this.state.workFlowStatusSelectedOption} />
                                                                    </div>
                                                                </div>
                                                                <div style={{marginTop:7}}>
                                                                    <Select tabIndex={1} isSearchable={true} options={this.renderAccountOptions()} placeholder="Choose Account" onChange={this.handleAccountFilterChange} value={this.state.accountSelectionOption} />
                                                                </div>
                                                                <div style={{marginTop:7}}>
                                                                    <Select tabIndex={2} isSearchable={true} value={this.state.verticalSelectedOption} options={this.renderVerticalOptions()} placeholder="Choose Vertical" onChange={this.handleVerticalFilterChange} />
                                                                </div>
                                                                <div style={{marginTop:7}} >
                                                                    <Select tabIndex={3} isSearchable={true} value={this.state.subVerticalSelectedOption} options={this.renderSubVerticalOptions()} placeholder="Choose Industry Vertical" onChange={this.handleSubVerticalNameFilterChange} />
                                                                </div>
                                                                <div style={{marginTop:7}}>
                                                                    <Select isSearchable={true} options={this.renderRegionOptions()} placeholder="Choose Region" onChange={this.handleRegionFilterChange} value={this.state.regionSelectedOption} />
                                                                </div>
                                                                <div style={{marginTop:7}}>
                                                                    <Select isSearchable={true} options={this.renderTransLeversOptions()} onChange={this.handleTransformationLeverNameFilterChange} placeholder="Choose Transformation" value={this.state.transSelectedOption}  />
                                                                </div>
                                                                <div style={{marginTop:7}}>
                                                                    <Select isSearchable={true} options={this.renderServiceLineOptions()} placeholder="Choose Service Line" onChange={this.handleService} value={this.state.serviceLineSelectedOption} />
                                                                </div>
                                                                <div style={{marginTop:7}}>
                                                                    <Select isSearchable={true} options={this.renderSubServiceLineOptions()} placeholder="Choose Sub-Service Line" onChange={this.handleSubServiceLine} value={this.state.subServiceLineSelectedOption}/>
                                                                </div>
                                                                <div style={{marginTop:7}}>
                                                                    <Select isSearchable={true} options={this.renderBestInClassOptions()} placeholder="Choose best in class" onChange={this.handleBestInClassOptionChange} value={this.state.bestInClassOption} />
                                                                </div>
                                                                <div style={{marginTop:7}}>
                                                                    <Select isSearchable={true} options={this.renderReplicaIndexOption()} placeholder="Choose Replicability Index" onChange={this.handleReplicaIndexOptionChange} value={this.state.replicaIndexOption} />
                                                                </div>

                                                                <div style={{marginTop:7}}>
                                                                    <Select isSearchable={true} options={YESORNO} placeholder="Choose Monetizable Project" onChange={this.handleMonetizableOptionChange} value={this.state.monetizableSelectedOption} />
                                                                </div>
                                                                <div style={{marginTop:7}}>
                                                                    <Select isSearchable={true} options={YESORNO} placeholder="Choose Zero Value Delivery Project" onChange={this.handleZeroVDOptionChange} value={this.state.zeroVDSelectedOption} />
                                                                </div>


                                                                <div className="filter_input">
                                                                    <input type="text" value={this.state.searchOwner} onChange={(event) => this.handleOwnerSearch(event.target.value)} placeholder="Search owner" onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.searchData()
                                                                        }
                                                                    } } 
                                                                    />
                                                                </div>
                                                                <div className="filter_input">
                                                                    <input type="text" value={this.state.searchMentor} onChange={(event) => this.handleSearchMentor(event.target.value)} placeholder="Search mentor" onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.searchData()
                                                                        }
                                                                    } } 
                                                                    />
                                                                </div>
                                                                <div className="filter_input">
                                                                    <input type="text" value={this.state.projectPhase} onChange={(event) => this.handleProjectPhaseChange(event.target.value)} placeholder="Search project phase" onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.searchData()
                                                                        }
                                                                    } } 
                                                                    />
                                                                </div>


                                                                    <div className="" style={{ marginTop: "7px" }}>
                                                                        <Select isSearchable={true} options={this.renderUniqueSegment()} placeholder="Choose Segment" onChange={(e) => this.handleSegmentOptionChange(e)} value={this.state.searchSegmentSelectedOption} />
                                                                    </div>

                                                                    <div className="" style={{ marginTop: "7px" }}>
                                                                        <Select isSearchable={true} options={this.renderUniqueUnits()} placeholder="Choose Unit" onChange={(e) => this.handleUnitOptionChange(e)} value={this.state.searchUnitSelectedOption} />
                                                                    </div>



                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        {
                                                                            this.state.isTypedData ? (
                                                                                <button onClick={()=>this.searchData() && this.setState({filter:false}) } className="btn-block u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6">Search</button>
                                                                            ):(
                                                                                <button onClick={(e)=>window.alert("Please type something then search.")} className="btn-block u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6">Search</button>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <button onClick={()=>this.resetData()} className="btn-block u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3">Reset</button>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                        }
                                                </div>
                                            </div>
                                            <div className="col-md-1" style={{marginLeft:'-280px', marginTop:15}}>
                                                <a style={{ fontSize: 13, color:'#122539', textDecoration:'none'}} href="/list-projects-page">{this.state.pageTitle}</a>
                                            </div>
                                            <div className="col-md-6" style={{marginTop:15}}>
                                                <a style={{ fontSize: 13, color:'#122539', borderBottom:'4px solid #259bca', textDecoration:'none'}} href="/view-all-projects-page">All lean projects</a>
                                            </div>
                                            <div className="col-md-2" style={{ marginLeft:60}}>
                                                <div className="filter_search_block">
                                                    <input value={this.state.globalsearchText} onChange={(event) => this.handleGlobalSearchFilterChange(event.target.value)} placeholder="Search here"  onKeyPress={event => {
                                                        if (event.key === 'Enter') {
                                                            this.globalSearch()
                                                        }
                                                    } } 
                                                    />
                                                    <i style={{ marginTop: 9, position: 'absolute', color: '#001A30', marginLeft:-35, cursor:'pointer' }} className={this.state.isTypedInGlobalSearch ? `${ SEARCH_ICON } icon-style` : `${ SEARCH_ICON } fa-disabled`} onClick={this.globalSearch} title="search"></i>
                                                    <i style={{ marginTop: 9, position: 'absolute', color: '#001A30', marginLeft:-15, cursor:'pointer' }} className={this.state.isTypedInGlobalSearch ? `${ CLOSE } icon-style` : `${ CLOSE } fa-disabled`} onClick={this.refreshSearch} title="close"></i>
                                                </div>
                                            </div>
                                            <div className="col-md-2" style={{marginLeft:-15}}>
                                                <select style={{width:60, height:35,border: '1px solid lightgrey'}} onChange={this.topCountChangeHandler} >
                                                    {
                                                        this.renderTopCountOptions()
                                                    }
                                                </select>
                                                <span style={{ verticalAlign: 'center', paddingLeft:4 }}> Rows per page</span>
                                            </div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 10 }} />
                                        <div className="scrollbar_table_container">
                                            <table className="table review-idea-table " style={{ fontSize: 12 }}>
                                                <thead style={{textAlign:'center !important'}} className = "view-idea-thead">
                                                    <tr>
                                                        {/* <th scope="col">#</th> */}
                                                        <th className='' scope="col" style={{ paddingLeft: 25, paddingRight:25 }}>Action</th>

                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={(e) => this.handleApprovalIdSortClick("ApprovalDate")}>
                                                            Approval Date
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.approvalDateSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>


                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleProjectIdSortClick("pg_projectid")}>
                                                            Project ID
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.projectIdSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="col-md-2 hidden-xs hidden-sm" scope="col" style={{width:"250px"}} onClick={() => this.handleProjectNameSortClick("pg_projectid")}>
                                                            Project Name
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.projectNameSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleServiceLineSortClick("serviceLine")}>
                                                            Service Line
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.serviceLineSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleStatusSortClick("pg_projectstatus")}>
                                                            Status
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.statusSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>

                                                        <th className="col-md-1 hidden-xs hidden-sm" scope="col" onClick={() => this.handleStateSortClick("pg_projectstate")}>
                                                            State
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.stateSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>

                                                        <th scope="col" onClick={() => this.handleProjectTypeSortClick("pg_worktypetxt")}>
                                                            Project type
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.projectTypeSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleProjectOwnerSortClick("pg_projowner")}>
                                                            Project owner
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.projectOwnerSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleAccountSortClick("pg_account")}>
                                                            Account name
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.accountSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="col-md-1 hidden-xs hidden-sm" scope="col" onClick={() => this.handleVerticalSortClick("pg_vertical")}>
                                                            Vertical
                                                            <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                                <i
                                                                    className={!this.state.verticalSortIconRotate? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>

                                                        <th className="col-md-1 hidden-xs hidden-sm" scope="col">
                                                            Mentor
                                                        </th>
                                                        <th className="col-md-1 hidden-xs hidden-sm" scope="col">
                                                            Region
                                                        </th>
                                                        <th className="col-md-1 hidden-xs hidden-sm" scope="col">
                                                            Sub Region
                                                        </th>
                                                        <th className="col-md-1 hidden-xs hidden-sm" scope="col">
                                                            Sub Vertical
                                                        </th>
                                                        <th className="col-md-1 hidden-xs hidden-sm" scope="col">
                                                            Monetizable
                                                        </th>
                                                        <th className="col-md-1 hidden-xs hidden-sm" scope="col">
                                                            Zero VD 
                                                        </th>

                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleSegmentSortClick("Segment")}>
                                                            Segment
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.segmentSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        <th className="hidden-xs hidden-sm" scope="col" onClick={() => this.handleUnitSortClick("Unit")}>
                                                            Unit
                                                            <span style={{ marginLeft: "5px", color: '#00AECF' }}>
                                                                <i
                                                                    className={!this.state.unitSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                        </th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody className = "review-idea-tbody">
                                                    {
                                                    
                                                        this.state.ideaList.length > 0 ? (
                                                            this.state.ideaList.map((ideaItem, index) => {
                                                                let formattedApprovalDate = this.formatDateString(ideaItem.ApprovalDate);
                                                                let action = VIEW;
                                                                return (
                                                                    <tr key={ideaItem.IdeaId}>
                                                                        <td className="sticky_column">
                                                                            <button onClick={() => this.handleIdeaAction(ideaItem, action)} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-hover-custom-color-6 u-radius-6 u-btn-2 new-btn-style" style={{ minWidth: 60, borderRadius: 0, height:20, marginLeft: "8px" }}>View</button>
                                                                        </td>
                                                                        <td className="hidden-xs hidden-sm project_id" style={{ textAlign: 'left' }}><label>{formattedApprovalDate ? formattedApprovalDate : ""}</label></td>
                                                                        <td className="hidden-xs hidden-sm project_id" style={{ textAlign: 'left' }}><label>{ideaItem.pg_projectid }</label></td>
                                                                        <td className="hidden-xs hidden-sm project_name_overflow" data-toggle="tooltip" title={ideaItem.msdyn_subject} style={{ textAlign: 'left' }}>{ideaItem.msdyn_subject === null ? "--" : ideaItem.msdyn_subject}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left' }}>{ideaItem.serviceLine === null ? "--" : ideaItem.serviceLine}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left' }}>{ideaItem.pg_projectstatus === null ? "--" : (ideaItem.pg_projectstatus === "Approve" ? "Approved" : ideaItem.pg_projectstatus)}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left' }}>{ideaItem.pg_projectstate === null ? "--" : ideaItem.pg_projectstate }</td>
                                                                        <td style={{ textAlign: 'left' }}>{ideaItem.pg_worktypetxt}</td>
                                                                        <td style={{ textAlign: 'left' }}>{ideaItem.pg_projowner}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left' }}>{ideaItem.pg_account}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left' }}>{ideaItem.pg_vertical}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left' }}>{ideaItem.BlackBeltUserName}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left' }}>{ideaItem.RegionManual}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left' }}>{ideaItem.SubRegion}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left' }}>{ideaItem.SubVerticalName}</td> 

                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left' }}>{ideaItem.IsVDMonetizable}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left' }}>{ideaItem.IsZeroImpactProject && ideaItem.IsZeroImpactProject ? "Yes" : "No"}</td> 


                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left' }}>{ideaItem.Segment}</td>
                                                                        <td className="hidden-xs hidden-sm" style={{ textAlign: 'left' }}>{ideaItem.Unit}</td> 
                                                                        
                                                                    </tr>
                                                                )
                                                            })
                                                        ) : (
                                                            <tr>
                                                                    <td colSpan="100%"> No items found! </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div style={{float:'right'}}>
                                            <div className="col-md-1">
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize:18}} className={this.state.isPrevEnabled ? `${ ANGLE_DOUBLE_LEFT } icon-style` : `${ ANGLE_DOUBLE_LEFT } fa-disabled`} onClick={() => this.state.isPrevEnabled ? this.handleGetFirstPage() :''} title="first" aria-hidden="true"></i>
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize:18, marginLeft:30}} className={this.state.isPrevEnabled ? `${ ANGLE_LEFT } icon-style` : `${ ANGLE_LEFT } fa-disabled`} onClick={() => this.state.isPrevEnabled ? this.handlePrevAction() :''} title="previous" aria-hidden="true"></i>
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize:18, marginLeft:60}} className={this.state.isNextEnabled ? `${ ANGLE_RIGHT } icon-style` : `${ ANGLE_RIGHT } fa-disabled`}  onClick={() => this.state.isNextEnabled ? this.handleNextAction() :''} title="next" aria-hidden="true"></i>
                                                <i style={{ marginTop: 5, position: 'absolute', fontSize:18, marginLeft:90, marginRight:10}} className={this.state.isNextEnabled ? `${ ANGLE_DOUBLE_RIGHT } icon-style` : `${ ANGLE_DOUBLE_RIGHT } fa-disabled`} onClick={() => this.state.isNextEnabled ? this.handleGetLastPage() :''} title="last" aria-hidden="true"></i>
                                            </div>
                                            <div className="col-md-7" style ={{marginLeft:120, marginTop:5}}>Page {this.state.currentPage} of {this.state.totalPages}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default ViewAllProjectsPage
