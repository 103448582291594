import React, { Component } from "react";
import {
    APPROVED,
    GET_ALL_IDEA_URL,
    PENDING,
    AWAITING_AI_BOT_REVIEW,
    SENT_BACK,
    REJECTED,
    APPROVAL,
    LEAN_APPROVAL,
    COMPLETED_STATUS,
    AWAITING_MBB_APPROVAL,
    AWAITING_LDT_APPROVAL,
    GET_METADATA,
} from "../assets/constants/constants.js";
import {
    SORT_DOWN,
    SORT_UP,
    SEARCH_ICON,
    ANGLE_LEFT,
    ANGLE_RIGHT,
    ANGLE_DOUBLE_LEFT,
    ANGLE_DOUBLE_RIGHT,
    FILTER,
    CLOSE,
} from "../assets/constants/Icons.js";
import {
    VIEW,
    DELEGATE,
    UPDATE,
    EDIT,
    USER_DATA,
    ACCESS_TOKEN,
} from "../assets/constants/constants.js";
import SortByColumn from "../utils/SortByColumns.js";
import Toast from "light-toast";
import Select from "react-select";

export class DBApprovalListPage extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);

    constructor(props) {
        console.log(props);
        super(props);
        let propsData = this.props.location.propsList
            ? this.props.location.propsList
            : null;
        if (this.sessionData === null) {
            Toast.loading(
                "This component cannot be accessed directly. Redirecting to application home.",
                3000,
                () => { }
            );
            window.location = "/login";
            return;
        }
        const windowUrl = window.location.search;
        const isApprovalPage = windowUrl.indexOf(APPROVAL) > 0;
        const isLeanApprovalPage = windowUrl.indexOf(LEAN_APPROVAL) > 0;

        this.state = {
            ideaList: [],
            originalIdeaList: [],
            myIdeaList: [],
            accountSortIconRotate: false,
            ideaNameSortIconRotate: false,
            createdDateSortIconRotate: false,
            reviewStatusSortIcon: false,
            ownerOHR:
                this.sessionData === null ? null : this.sessionData.profile.oHRId,
            // ownerOHR: Number(this.sessionData.profile.oHRId), //TODO: Remove before UAT
            ownerName:
                this.sessionData === null
                    ? null
                    : this.sessionData.profile.lastName +
                    ", " +
                    this.sessionData.profile.firstName,
            approvalPage: isApprovalPage,
            leanApprovalPage: isLeanApprovalPage,
            pageTitle: isApprovalPage
                ? isLeanApprovalPage
                    ? "Approve lean projects"
                    : "Approve ideas"
                : "My ideas",
            topCountOption: [5, 10, 25, 50, 75, 100],
            topCount: propsData !== null ? parseInt(propsData.topCount) : 5,
            skipCount: 0,
            isPrevEnabled: false,
            isNextEnabled: true,
            IdeaLength: 0,
            totalPages: 0,
            currentPage: 0,
            pageNo: "",
            filter: false,
            WorkflowStatus: "",
            IdeaId: "",
            AccountManual: "",
            RegionManual: "",
            VerticalManual: "",
            TransformationLeverName: "",
            CertificationLevelName: "",
            SubVerticalName: "",
            ServiceLineName: "",
            SubServiceLine: "",
            startDate: "",
            toDate: "",
            isTypedData: false,
            WhereClause: "",
            fromFilterSelection: "",
            globalsearchText: "",
            isTypedInFilter: "",
            isTypedInGlobalSearch: false,
            tempWorkflowStatus: "",
            tempIdeaId: "",
            tempAccountManual: "",
            tempRegionManual: "",
            tempVerticalManual: "",
            tempTransformationLeverName: "",
            tempCertificationLevelName: "",
            tempSubVerticalName: "",
            tempServiceLineName: "",
            tempSubServiceLine: "",
            tempstartDate: "",
            temptoDate: "",
            accountList: [],
            accountSelectionOption: null,
            // workFlowStatusList: [],
            workFlowStatusList: [
                {
                    WorkflowStatusId: 1,
                    WorkflowStatusName: "Approve",
                },
                {
                    WorkflowStatusId: 2,
                    WorkflowStatusName: "Reject",
                },
                {
                    WorkflowStatusId: 3,
                    WorkflowStatusName: "Send Back",
                },
                {
                    WorkflowStatusId: 4,
                    WorkflowStatusName: "Pending",
                },
            ],
            projectStatusList: [
                {
                    WorkflowStatusId: 1,
                    WorkflowStatusName: "Completed",
                },
                {
                    WorkflowStatusId: 2,
                    WorkflowStatusName: "Rejected",
                },
                {
                    WorkflowStatusId: 3,
                    WorkflowStatusName: "Awaiting LDT Approval",
                },
                {
                    WorkflowStatusId: 4,
                    WorkflowStatusName: "Awaiting MBB Approval",
                },
                {
                    WorkflowStatusId: 5,
                    WorkflowStatusName: "Pending Documents",
                },
                {
                    WorkflowStatusId: 6,
                    WorkflowStatusName: "Documents Uploaded",
                },
            ],
            workFlowStatusSelectedOption: null,
            transformationLeversList: [],
            transSelectedOption: null,
            serviceLineList: [],
            subServiceLineList: [],
            serviceLineSelectedOption: null,
            subServiceLineSelectedOption: null,
            regionList: [],
            verticalList: [],
            subVerticalList: [],
            regionSelectedOption: null,
            verticalSelectedOption: null,
            subVerticalSelectedOption: null,
            dateList: ["Created date", "Reviewed date"],
            dateSelectedOption: null,
            selectedDate: "",
            propsData: propsData !== null ? propsData : null,
            certificationLevelsList: [],
            certLevelsSelectedOption: null,
            bestInClassList: ["Yes", "No"],
            bestInClassOption: null,
            selectedBestInClass: "",
            replicaIndexOptionList: [1, 2, 3, 4, 5],
            replicaIndexOption: null,
            selectedReplicaIndex: "",
            sortColumnName: "CreatedDate",
            sortOrder: "desc",
            QCUser: false,
            admin: false,
            DBApprover: false,
            qcMetaData: [],
            analyticsMetaData: [],
            mbbSortIconRotate: false,
            projectIdSortIconRotate: false,
            regionLineSortIconRotate: false,
            verticalLineSortIconRotate: false,
            searchSegment:"",
            searchUnit:"",
            uniqueUnits:[],
            uniqueSegments:[],
            searchSegmentSelectedOption:[],
            searchUnitSelectedOption:[]
        };
    }

    async componentDidMount() {
        if (this.state.propsData) {
            await this.fetchIdea();
            if (this.state.propsData.WhereClause) {
                console.log(this.state.propsData.WhereClause);
                if (this.state.propsData.selectedDate === "CreatedDate") {
                    this.setState({ selectedDate: "Created Date" });
                } else if (this.state.propsData.selectedDate === "ModifiedDate") {
                    this.setState({ selectedDate: "Reviewed Date" });
                }

                if (this.state.propsData.selectedBestInClass === 1) {
                    this.setState({ selectedBestInClass: "Yes" });
                } else if (this.state.propsData.selectedBestInClass === 0) {
                    this.setState({ selectedBestInClass: "No" });
                }

                this.setState(
                    {
                        WhereClause: this.state.propsData.WhereClause,
                        topCount: this.state.topCount,
                        skipCount: this.state.propsData.skipCount,
                        tempWorkflowStatus: this.state.propsData.WorkflowStatus,
                        tempIdeaId: this.state.propsData.IdeaId,
                        tempAccountManual: this.state.propsData.AccountManual,
                        tempRegionManual: this.state.propsData.RegionManual,
                        tempVerticalManual: this.state.propsData.VerticalManual,
                        tempTransformationLeverName:
                            this.state.propsData.TransformationLeverName,
                        tempCertificationLevelName:
                            this.state.propsData.CertificationLevelName,
                        tempSubVerticalName: this.state.propsData.SubVerticalName,
                        tempServiceLineName: this.state.propsData.ServiceLineName,
                        tempSubServiceLine: this.state.propsData.SubServiceLine,
                        tempstartDate: this.state.propsData.startDate,
                        temptoDate: this.state.propsData.toDate,
                        selectedReplicaIndex: this.state.propsData.selectedReplicaIndex,
                        filter: !this.state.filter,
                    },
                    async () => {
                        await this.filterHandler();
                        if (this.state.QCUser) {
                            await this.fetchIdea();
                        }
                        if (this.state.DBApprover) {
                            await this.fetchIdea();
                        }
                        if (this.state.admin) {
                            await this.fetchIdea();
                        }

                    }
                );
            } else if (
                !this.state.propsData.WhereClause &&
                this.state.propsData.skipCount
            ) {
                this.setState(
                    {
                        topCount: this.state.propsData.topCount,
                        skipCount: this.state.propsData.skipCount,
                    },
                    async () => {
                        if (this.state.DBApprover) {
                            await this.fetchIdea();
                        }
                    }
                );
            } else {
                if (this.state.DBApprover) {
                    await this.fetchIdea();
                }
            }
        } else {
            await this.searchData();
        }
        await this.IsDBApprover();
        Toast.hide();
    }

    IsDBApprover = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "OHR",
                EntityName: this.sessionData
                    ? this.sessionData.profile
                        ? this.sessionData.profile.oHRId
                        : ""
                    : "",
            };
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param),
            };
            fetch(GET_METADATA, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    this.setState({ analyticsMetaData: data.PowerAppUsers }, () => { });
                    this.state.analyticsMetaData.filter((item) => {
                        const userRole = JSON.parse(item.pg_Role).pg_role;
                        console.log("userrle =>",userRole)
                        if (JSON.parse(item.pg_Role).pg_role === "Administrator") {
                            if (parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
                                this.setState({ admin: true }, () => { });
                            }
                        }
                        if (JSON.parse(item.pg_Role).pg_role === "DBSPOC") {
                            if (parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
                                this.setState({ DBApprover: true }, () => { });
                            }
                        }

                    });
                    if (this.state.admin || this.state.DBApprover) {
                        this.fetchIdea();
                        this.fetchMetedata();
                    }
                    resolve();
                })
                .catch((error) => {
                    Toast.hide();
                    Toast.fail(
                        "DB Approval Review list is only enabled for signed in user",
                        3000,
                        () => {
                            console.log(JSON.stringify(error));
                        }
                    );
                });
        });
    };

    IsQCUser = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "OHR",
                EntityName: this.sessionData
                    ? this.sessionData.profile
                        ? this.sessionData.profile.oHRId
                        : ""
                    : "",
            };
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param),
            };
            fetch(GET_METADATA, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    this.setState({ qcMetaData: data.PowerAppUsers }, () => { });
                    this.state.qcMetaData.filter((item) => {
                        const userRole = JSON.parse(item.pg_Role).pg_role;
                        if (
                            userRole === "BB" &&
                            parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr)
                        ) {
                            this.setState({ QCUser: true }, () => { });
                        }
                        const adminRole = item.pg_roledescr;
                        if (
                            adminRole === "Administrator" &&
                            parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr)
                        ) {
                            this.setState({ admin: true }, () => { });
                        }
                    });
                    if (this.state.admin || this.state.QCUser) {
                        this.fetchIdea();
                        this.fetchMetedata();
                    }
                    resolve();
                })
                .catch((error) => {
                    Toast.hide();
                    Toast.fail(
                        "Project Review list is only enabled for signed in user",
                        3000,
                        () => {
                            console.log(JSON.stringify(error));
                        }
                    );
                });
        });
    };

    fetchIdea = () => {
        Toast.loading("Fetching Ideas...", () => { });
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
            };

            if (this.state.skipCount === 0) {
                this.setState({
                    isPrevEnabled: false,
                });
            } else {
                this.setState({
                    isPrevEnabled: true,
                });
            }

            fetch(
                `${GET_ALL_IDEA_URL}UserOHR=${this.state.ownerOHR}&WhereClause=${this.state.WhereClause}&skipCount=${this.state.skipCount}&topCount=${this.state.topCount}&sortingorder=${this.state.sortOrder}&sortingcolumn=${this.state.sortColumnName}&isItIdeaOrGenAI='Idea'&CreatedBy=''`,
                requestOptions
            )
                .then((response) => response.json())
                .then((data) => {
                    console.log("fetch idea => ", data);
                    this.setState(
                        {
                            ideaList:
                                data.IdeaList.ResultSets.Table1 === undefined
                                    ? []
                                    : data.IdeaList.ResultSets.Table1,
                            originalIdeaList:
                                data.IdeaList.ResultSets.Table1 === undefined
                                    ? []
                                    : data.IdeaList.ResultSets.Table1,
                            myIdeaList:
                                data.myIdeaList.ResultSets.Table1 === undefined
                                    ? []
                                    : data.myIdeaList.ResultSets.Table1,
                            IdeaLength: data.TotalIdea.Table1[0].length,
                            myIdeaLength: data.myIdeaLength.Table1[0].length,
                            fromFilterSelection: data.filter,
                        },
                        () => {

                            let ideaList = [];
                            this.state.ideaList.filter((item) => {
                                console.log(item.CampaignName)
                                // if(item.CampaignName === "AI F"){
                                //   ideaList.push(item);
                                // }
                            });
                            this.setState({ ideaList: ideaList }, () => { })

                            let totalPages = Math.ceil(
                                parseInt(this.state.IdeaLength) / parseInt(this.state.topCount)
                            );
                            let currentPage = Math.ceil(
                                parseInt(this.state.skipCount) / parseInt(this.state.topCount) +
                                1
                            );
                            if (totalPages === 0) {
                                totalPages = 1;
                            }
                            this.setState(
                                {
                                    totalPages: totalPages,
                                    currentPage: currentPage,
                                },
                                () => {
                                    if (this.state.currentPage === this.state.totalPages) {
                                        this.setState({
                                            isNextEnabled: false,
                                        });
                                    } else {
                                        this.setState({
                                            isNextEnabled: true,
                                        });
                                    }
                                }
                            );

                            this.handleFilterChange({ value: 0 });
                            Toast.hide();
                            resolve();
                        }
                    );
                })
                .catch((error) => {
                    console.log(error);
                    Toast.hide();
                    resolve();
                });
        });
    };

    fetchMetedata = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "",
                EntityName: "",
            };
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param),
            };
            fetch(GET_METADATA, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log("fetch meta data", data);
                    Toast.hide();
                    this.setState(
                        {
                            accountList: data
                                ? data.Accounts.sort((a, b) => {
                                    return a.ACCOUNT > b.ACCOUNT ? 1 : -1;
                                })
                                : [],
                            serviceLineList: data ? data.ServiceLines : [],
                            subServiceLineList: data ? data.ServiceLines : [],
                            certificationLevelsList: data ? data.CertificationLevels : [],
                            transformationLeversList: data ? data.TransformationLevers : [],
                            regionList: data ? data.Region : [],
                            verticalList: data ? data.VerticalData : [],
                            subVerticalList: data ? data.SubVerticalData : [],
                            uniqueUnits:data ? data.UnitSegment ? data.UnitSegment.ResultSets ? data.UnitSegment.ResultSets.Table1 : []: []:[],
                            uniqueSegments:data ? data.UnitSegment ? data.UnitSegment.ResultSets ? data.UnitSegment.ResultSets.Table2 : []: []:[],

                        },
                        () => { }
                    );
                    resolve();
                })
                .catch((error) => {
                    Toast.hide();
                    Toast.fail(
                        "Error occured while getting Idea Metadata. Please try again!",
                        3000,
                        () => {
                            console.log(JSON.stringify(error));
                        }
                    );
                });
        });
    };

    renderVerticalOptions = () => {
        let options = [];
        this.state.verticalList.map((item) => {
            if (item.Vertical !== "TS") {
                options.push({
                    value: item.Vertical,
                    label: item.Vertical,
                });
            }
            return options;
        });
        return options;
    };

    renderSubVerticalOptions = () => {
        let options = [];
        this.state.subVerticalList.map((item) => {
            if (item.SubVertical !== "corporate" && item.SubVertical !== "Unmapped") {
                options.push({
                    value: item.SubVertical,
                    label: item.SubVertical,
                });
            }
            return options;
        });
        return options;
    };

    renderTopCountOptions = () => {
        if (this.state.topCountOption.length > 0) {
            return this.state.topCountOption.map((item) => {
                let isSelected = false;
                if (item === this.state.topCount) {
                    isSelected = true;
                }
                return <option selected={isSelected}>{item}</option>;
            });
        }
    };

    topCountChangeHandler = async (event) => {
        const index = event.nativeEvent.target.selectedIndex;
        this.setState(
            {
                topCount: event.nativeEvent.target[index].text,
            },
            async () => {
                await this.fetchIdea();
            }
        );
    };

    handleReviewIdea1 = (
        ideaId,
        workflowStatus,
        approvalStatus,
        MonetizationState
    ) => {
        this.props.history.push({
            pathname: "/dbspoc-approval-page",
            state: ideaId,
            approval: this.state.approvalPage
                ? this.state.leanApprovalPage
                    ? LEAN_APPROVAL
                    : APPROVAL
                : "",
            WhereClause: this.state.WhereClause,
            topCount: this.state.topCount,
            skipCount: this.state.skipCount,
            WorkflowStatus: this.state.WorkflowStatus,
            IdeaId: this.state.IdeaId,
            AccountManual: this.state.AccountManual,
            RegionManual: this.state.RegionManual,
            VerticalManual: this.state.VerticalManual,
            TransformationLeverName: this.state.TransformationLeverName,
            CertificationLevelName: this.state.CertificationLevelName,
            SubVerticalName: this.state.SubVerticalName,
            ServiceLineName: this.state.ServiceLineName,
            SubServiceLine: this.state.SubServiceLine,
            startDate: this.state.startDate,
            toDate: this.state.toDate,
            selectedDate: this.state.selectedDate,
            selectedBestInClass: this.state.selectedBestInClass,
            selectedReplicaIndex: this.state.selectedReplicaIndex,
            globalsearchText: this.state.globalsearchText,
        });
    };

    handleIdeaIdSortClick = (columnName) => {
        this.setState(
            (prevState) => {
                return {
                    ideaIdSortIconRotate: !prevState.ideaIdSortIconRotate,
                };
            },
            () => {
                let sortOrder = this.state.ideaIdSortIconRotate ? "desc" : "asc";
                // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
                // this.setState({
                //     ideaList: sortedData
                // });
                this.setState(
                    {
                        // ideaList: sortedData,
                        sortColumnName: columnName,
                        sortOrder: sortOrder,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        );
    };


    handleAccountSortClick = (columnName) => {
        this.setState(
            (prevState) => {
                return {
                    accountSortIconRotate: !prevState.accountSortIconRotate,
                };
            },
            () => {
                let sortOrder = this.state.accountSortIconRotate ? "desc" : "asc";
                // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
                // this.setState({
                //     ideaList: sortedData
                // });
                this.setState(
                    {
                        // ideaList: sortedData
                        sortColumnName: columnName,
                        sortOrder: sortOrder,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        );
    };

    handleIdeaNameSortClick = (columnName) => {
        this.setState(
            (prevState) => {
                return {
                    ideaNameSortIconRotate: !prevState.ideaNameSortIconRotate,
                };
            },
            () => {
                let sortOrder = this.state.ideaNameSortIconRotate ? "desc" : "asc";
                // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
                // this.setState({
                //     ideaList: sortedData
                // });
                // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
                this.setState(
                    {
                        // ideaList: sortedData
                        sortColumnName: columnName,
                        sortOrder: sortOrder,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        );
    };

    handleServiceLineSortClick = (columnName) => {
        this.setState(
            (prevState) => {
                return {
                    serviceLineSortIconRotate: !prevState.serviceLineSortIconRotate,
                };
            },
            () => {
                let sortOrder = this.state.serviceLineSortIconRotate ? "desc" : "asc";
                // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
                // this.setState({
                //     ideaList: sortedData
                // });
                this.setState(
                    {
                        // ideaList: sortedData
                        sortColumnName: columnName,
                        sortOrder: sortOrder,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        );
    };

    handleSegmentSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                segmentSortIconRotate: !prevState.segmentSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.segmentSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleUnitSortClick = (columnName) => {
        this.setState(prevState => {
            return {
                unitSortIconRotate: !prevState.unitSortIconRotate
            }
        }, () => {
            let sortOrder = this.state.unitSortIconRotate ? "desc" : "asc";
            // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
            this.setState({
                // ideaList: sortedData
                sortColumnName:columnName,
                sortOrder:sortOrder
            },async()=>{
                await this.fetchIdea()
            });
        })
    }

    handleRegionSortClick = (columnName) => {
        this.setState(
            (prevState) => {
                return {
                    regionLineSortIconRotate: !prevState.regionLineSortIconRotate,
                };
            },
            () => {
                let sortOrder = this.state.regionLineSortIconRotate ? "desc" : "asc";
                // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
                // this.setState({
                //     ideaList: sortedData
                // });
                this.setState(
                    {
                        // ideaList: sortedData
                        sortColumnName: columnName,
                        sortOrder: sortOrder,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        );
    };
    handleVerticalSortClick = (columnName) => {
        this.setState(
            (prevState) => {
                return {
                    verticalLineSortIconRotate: !prevState.verticalLineSortIconRotate,
                };
            },
            () => {
                let sortOrder = this.state.verticalLineSortIconRotate ? "desc" : "asc";
                // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
                // this.setState({
                //     ideaList: sortedData
                // });
                this.setState(
                    {
                        // ideaList: sortedData
                        sortColumnName: columnName,
                        sortOrder: sortOrder,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        );
    };

    handleApproverSortClick = (columnName) => {
        this.setState(
            (prevState) => {
                return {
                    approverSortIconRotate: !prevState.approverSortIconRotate,
                };
            },
            () => {
                let sortOrder = this.state.approverSortIconRotate ? "desc" : "asc";
                // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
                // this.setState({
                //     ideaList: sortedData
                // });
                this.setState(
                    {
                        // ideaList: sortedData
                        sortColumnName: columnName,
                        sortOrder: sortOrder,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        );
    };
    handleMBBSortClick = (columnName) => {
        this.setState(
            (prevState) => {
                return {
                    mbbSortIconRotate: !prevState.mbbSortIconRotate,
                };
            },
            () => {
                let sortOrder = this.state.mbbSortIconRotate ? "desc" : "asc";
                this.setState(
                    {
                        sortColumnName: columnName,
                        sortOrder: sortOrder,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        );
    };

    handleSubmitterSortClick = (columnName) => {
        this.setState(
            (prevState) => {
                return {
                    submitterSortIconRotate: !prevState.submitterSortIconRotate,
                };
            },
            () => {
                let sortOrder = this.state.submitterSortIconRotate ? "desc" : "asc";
                // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
                // this.setState({
                //     ideaList: sortedData
                // });
                this.setState(
                    {
                        // ideaList: sortedData
                        sortColumnName: columnName,
                        sortOrder: sortOrder,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        );
    };

    handleCreatedDateSortClick = (columnName) => {
        this.setState(
            (prevState) => {
                return {
                    createdDateSortIconRotate: !prevState.createdDateSortIconRotate,
                };
            },
            () => {
                let sortOrder = this.state.createdDateSortIconRotate ? "desc" : "asc";
                // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
                this.setState(
                    {
                        // ideaList: sortedData
                        sortColumnName: columnName,
                        sortOrder: sortOrder,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        );
    };

    handleReviewStatusSortClick = (columnName) => {
        this.setState(
            (prevState) => {
                return {
                    reviewStatusSortIcon: !prevState.reviewStatusSortIcon,
                };
            },
            () => {
                let sortOrder = this.state.reviewStatusSortIcon ? "desc" : "asc";
                // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
                // this.setState({
                //     ideaList: sortedData
                // });
                this.setState(
                    {
                        // ideaList: sortedData
                        sortColumnName: columnName,
                        sortOrder: sortOrder,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        );
    };


    handleProjectSortClick = (columnName) => {
        this.setState(
            (prevState) => {
                return {
                    projectStatusSortIcon: !prevState.projectStatusSortIcon,
                };
            },
            () => {
                let sortOrder = this.state.projectStatusSortIcon ? "desc" : "asc";
                this.setState(
                    {

                        sortColumnName: columnName,
                        sortOrder: sortOrder,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        );
    };



    handleReviewDateSortClick = (columnName) => {
        this.setState(
            (prevState) => {
                return {
                    reviewDateSortIconRotate: !prevState.reviewDateSortIconRotate,
                };
            },
            () => {
                let sortOrder = this.state.reviewDateSortIconRotate ? "desc" : "asc";
                // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
                // this.setState({
                //     ideaList: sortedData
                // });
                this.setState(
                    {
                        // ideaList: sortedData
                        sortColumnName: columnName,
                        sortOrder: sortOrder,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        );
    };

    handleCertTypeSortClick = (columnName) => {
        this.setState(
            (prevState) => {
                return {
                    certTypeSortIconRotate: !prevState.certTypeSortIconRotate,
                };
            },
            () => {
                let sortOrder = this.state.certTypeSortIconRotate ? "desc" : "asc";
                // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
                // this.setState({
                //     ideaList: sortedData
                // });
                this.setState(
                    {
                        // ideaList: sortedData
                        sortColumnName: columnName,
                        sortOrder: sortOrder,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        );
    };

    handleTransformationLeverSortClick = (columnName) => {
        this.setState(
            (prevState) => {
                return {
                    transformationLeverSortIcon: !prevState.transformationLeverSortIcon,
                };
            },
            () => {
                let sortOrder = this.state.transformationLeverSortIcon ? "desc" : "asc";
                // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
                // this.setState({
                //     ideaList: sortedData
                // });
                this.setState(
                    {
                        // ideaList: sortedData
                        sortColumnName: columnName,
                        sortOrder: sortOrder,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        );
    };

    handleProjectStatusSortClick = (columnName) => {
        this.setState(
            (prevState) => {
                return {
                    projectStatusSortIcon: !prevState.projectStatusSortIcon,
                };
            },
            () => {
                let sortOrder = this.state.projectStatusSortIcon ? "desc" : "asc";
                // let sortedData = SortByColumn(this.state.ideaList, columnName, sortOrder);
                // this.setState({
                //     ideaList: sortedData
                // });
                this.setState(
                    {
                        // ideaList: sortedData
                        sortColumnName: columnName,
                        sortOrder: sortOrder,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        );
    };

    renderFilterOptions = () => {
        return [
            { value: 0, label: "All Ideas" },
            { value: 1, label: "My ideas" },
            { value: 2, label: "My Approvals" },
        ];
    };

    handleFilterChange = (e) => {
        let filteredIdeaArray = [];
        if (e.value === 0) {
            filteredIdeaArray = this.state.originalIdeaList;
        }
        this.setState({
            ideaList: filteredIdeaArray,
            originalIdeaList: filteredIdeaArray
        })
    };

    formatDateString(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
    }

    resetData = () => {
        this.setState(
            {
                WorkflowStatus: "",
                IdeaId: "",
                AccountManual: "",
                RegionManual: "",
                VerticalManual: "",
                TransformationLeverName: "",
                CertificationLevelName: "",
                SubVerticalName: "",
                ServiceLineName: "",
                SubServiceLine: "",
                startDate: "",
                toDate: "",
                filter: false,
            },
            () => {
                window.location.reload();
            }
        );
    };

    globalSearch = () => {
        if (this.state.currentPage === this.state.totalPages) {
            this.setState({
                skipCount: 0,
            });
        }
        let WhereClause = "";
        if (
            this.state.globalsearchText !== null &&
            this.state.globalsearchText !== ""
        ) {
            WhereClause =
                this.state.isTypedInFilter !== ""
                    ? this.state.isTypedInFilter + " and "
                    : "";
            WhereClause =
                WhereClause +
                encodeURIComponent(
                    "Concat(WorkflowStatus,IdeaIDAlpha,AccountManual,VerticalManual,RegionManual,TransformationLeverName,SubVerticalName,ServiceLineName,OwnerName,BlackBeltUserName,BlackBeltUserId,IdeaName,SubServiceLine,LeanID,MBBOHR,LDTId,LDTLeadName,BlackBeltUserEmail,MBBName,Unit,Segment ) like '%" +
                    this.state.globalsearchText +
                    "%'"
                );

            // WhereClause=WhereClause+ '('+ 'WorkflowStatus'+ ' ' + 'like' + " '" +  this.state.globalsearchText + '%'+"'" +
            // ' or '  + 'IdeaIDAlpha'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or '  +  'AccountManual'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'VerticalManual'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'RegionManual'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'TransformationLeverName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'CertificationLevelName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'SubVerticalName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'ServiceLineName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'OwnerOHR'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'CreatorOHR'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'BlackBeltUserId'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'OwnerName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'CreatorName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'BlackBeltUserName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'IdeaName'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'ApprovalStatus'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" +
            // ' or ' + 'SubServiceLine'+ ' ' + 'like' + " '" + this.state.globalsearchText + '%'+"'" + ')';

            if (WhereClause !== "") {
                this.setState(
                    {
                        WhereClause: WhereClause,
                        filter: false,
                    },
                    async () => {
                        await this.fetchIdea();
                    }
                );
            }
        } else {
            Toast.fail("Please type something then search.");
            return;
        }
    };


    searchData = async () => {
        this.setState({ filter: false });
        let WhereClause = "";
        if (this.state.approvalPage) {
            if (this.state.leanApprovalPage) {
                WhereClause =
                    "(" +
                    "BlackBeltUserId" +
                    " = " +
                    this.state.ownerOHR +
                    " or " +
                    "MBBOHR" +
                    " = " +
                    this.state.ownerOHR +
                    " or " +
                    "LDTId" +
                    " = " +
                    this.state.ownerOHR +
                    ")" +
                    " and " +
                    "(" +
                    "CertificationLevelName" +
                    " = " +
                    "'" +
                    "Lean" +
                    "'" +
                    " or " +
                    "CertificationLevelName" +
                    " = " +
                    "'" +
                    "YB" +
                    "'" +
                    ")" +
                    " and " +
                    "(" +
                    "TransformationLeverName" +
                    " != " +
                    "'" +
                    "Digital" +
                    "'" +
                    ")";
            } else {
                WhereClause =
                    "(" +
                    "BlackBeltUserId" +
                    " = " +
                    this.state.ownerOHR +
                    " or " +
                    "MBBOHR" +
                    " = " +
                    this.state.ownerOHR +
                    " or " +
                    "LDTId" +
                    " = " +
                    this.state.ownerOHR +
                    ")" +
                    " and " +
                    "(((" +
                    "CertificationLevelName" +
                    " = " +
                    "'" +
                    "Lean" +
                    "'" +
                    " or " +
                    "CertificationLevelName" +
                    " = " +
                    "'" +
                    "YB" +
                    "'" +
                    ")" +
                    " and " +
                    "(" +
                    "TransformationLeverName" +
                    " = " +
                    "'" +
                    "Digital" +
                    "'" +
                    "))" +
                    " or " +
                    "(" +
                    "CertificationLevelName" +
                    " = " +
                    "'" +
                    "GB" +
                    "'" +
                    ")" +
                    " or " +
                    "(" +
                    "WorkflowStatus" +
                    " = " +
                    "'" +
                    "Pending" +
                    "'" +
                    " or " +
                    "WorkflowStatus" +
                    " = " +
                    "'" +
                    "Reject" +
                    "'" +
                    " or " +
                    "ApprovalStatus" +
                    " = " +
                    "'" +
                    "Cancellation Requested" +
                    "'" +
                    " or " +
                    "ApprovalStatus" +
                    " = " +
                    "'" +
                    "Cancelled" +
                    "'" +
                    " or " +
                    "WorkflowStatus" +
                    " = " +
                    "'" +
                    "Cancellation Requested" +
                    "'" +
                    " or " +
                    "WorkflowStatus" +
                    " = " +
                    "'" +
                    "Cancelled" +
                    "'" +
                    "))";
            }
        } else {
            // WhereClause ='(' + 'ApprovalStatus' + ' = ' + "'Awaiting Quality Check Review'" + ' or ' + '(' + 'QCOHR ' +  '<' + '>' + "'"+"'" + ' and ' + 'QCOHR is not null' + ')' + ')' ;
            WhereClause =
                "(CertificationLevelName = 'Lean' and ApprovalStatus = 'Awaiting DBSPOC Approval' )";

        }
        // if(this.state.leanApprovalPage){
        //     if(this.state.WorkflowStatus !== ''){
        //         WhereClause = WhereClause + ' and ' + 'ApprovalStatus'+ ' ' + 'like' + " '" + this.state.WorkflowStatus + '%'+"'";
        //     }
        // }
        // else{
        //     if(this.state.WorkflowStatus !== ''){
        //         WhereClause = WhereClause + ' and ' + 'WorkflowStatus'+ ' ' + 'like' + " '" + this.state.WorkflowStatus + '%'+"'";
        //     }
        // }
        if (this.state.IdeaId !== "") {
            WhereClause = WhereClause !== "" ? WhereClause + " and " : "";
            WhereClause =
                WhereClause +
                "(" +
                "IdeaIDAlpha" +
                " " +
                "like" +
                " '" +
                this.state.IdeaId +
                "%" +
                "'" +
                " or " +
                "LeanID" +
                " " +
                "like" +
                " '" +
                this.state.IdeaId +
                "%" +
                "'" +
                ")";
        }
        if (this.state.AccountManual !== "") {
            WhereClause = WhereClause !== "" ? WhereClause + " and " : "";
            WhereClause =
                WhereClause +
                "AccountManual" +
                " " +
                "like" +
                " '" +
                this.state.AccountManual +
                "%" +
                "'";
        }
        if (this.state.VerticalManual !== "") {
            WhereClause = WhereClause !== "" ? WhereClause + " and " : "";
            WhereClause =
                WhereClause +
                "VerticalManual" +
                " " +
                "like" +
                " '" +
                this.state.VerticalManual +
                "%" +
                "'";
        }
        if (this.state.RegionManual !== "") {
            WhereClause = WhereClause !== "" ? WhereClause + " and " : "";
            WhereClause =
                WhereClause +
                "RegionManual" +
                " " +
                "like" +
                " '" +
                this.state.RegionManual +
                "%" +
                "'";
        }
        if (this.state.CertificationLevelName !== "") {
            WhereClause = WhereClause !== "" ? WhereClause + " and " : "";
            WhereClause =
                WhereClause +
                "CertificationLevelName" +
                " " +
                "like" +
                " '" +
                this.state.CertificationLevelName +
                "%" +
                "'";
        }
        if (this.state.TransformationLeverName !== "") {
            WhereClause = WhereClause !== "" ? WhereClause + " and " : "";
            WhereClause =
                WhereClause +
                "TransformationLeverName" +
                " " +
                "like" +
                " '" +
                this.state.TransformationLeverName +
                "%" +
                "'";
        }
        if (this.state.SubVerticalName !== "") {
            WhereClause = WhereClause !== "" ? WhereClause + " and " : "";
            WhereClause =
                WhereClause +
                "SubVerticalName" +
                " " +
                "like" +
                " '" +
                this.state.SubVerticalName +
                "%" +
                "'";
        }
        if (this.state.ServiceLineName !== "") {
            WhereClause = WhereClause !== "" ? WhereClause + " and " : "";
            // WhereClause =  WhereClause + 'ServiceLineName'+ ' ' + 'like' + "'" + this.state.ServiceLineName.replace("&","%26") + '%'+"'";
            WhereClause =
                WhereClause +
                "ServiceLineName" +
                " " +
                "like" +
                "'" +
                encodeURIComponent(this.state.ServiceLineName) +
                "%" +
                "'";
        }
        if (this.state.SubServiceLine !== "") {
            WhereClause = WhereClause !== "" ? WhereClause + " and " : "";
            WhereClause =
                WhereClause +
                "SubServiceLine" +
                " " +
                "like" +
                "'" +
                encodeURIComponent(this.state.SubServiceLine) +
                "%" +
                "'";
        }
        if (this.state.startDate !== "" && this.state.toDate !== "") {
            WhereClause = WhereClause !== "" ? WhereClause + " and " : "";
            if (this.state.selectedDate === "ModifiedDate") {
                WhereClause =
                    WhereClause +
                    "(" +
                    "(" +
                    this.state.selectedDate +
                    " " +
                    "between " +
                    "'" +
                    this.state.startDate +
                    "T00:00:00.00" +
                    "'" +
                    " and " +
                    "'" +
                    this.state.toDate +
                    "T23:59:00.00" +
                    "'" +
                    ")" +
                    " and " +
                    "WorkflowStatus" +
                    " <> " +
                    "'" +
                    "Pending" +
                    "'" +
                    ")";
            } else {
                WhereClause =
                    WhereClause +
                    "(" +
                    this.state.selectedDate +
                    " " +
                    "between " +
                    "'" +
                    this.state.startDate +
                    "T00:00:00.00" +
                    "'" +
                    " and " +
                    "'" +
                    this.state.toDate +
                    "T23:59:00.00" +
                    "'" +
                    ")";
            }
        }
        if (this.state.selectedBestInClass !== "") {
            WhereClause = WhereClause !== "" ? WhereClause + " and " : "";
            WhereClause =
                WhereClause +
                "IsBestInClass" +
                " " +
                "like" +
                " '" +
                this.state.selectedBestInClass +
                "%" +
                "'" +
                " and " +
                "CertificationLevelName" +
                "=" +
                "'" +
                "lean" +
                "'";
        }
        if (this.state.selectedReplicaIndex !== "") {
            WhereClause = WhereClause !== "" ? WhereClause + " and " : "";
            WhereClause =
                WhereClause +
                "ReplicabilityIndex" +
                " " +
                "like" +
                " '" +
                this.state.selectedReplicaIndex +
                "%" +
                "'" +
                " and " +
                "CertificationLevelName" +
                "=" +
                "'" +
                "lean" +
                "'";
        }
        if (this.state.startDate !== "") {
            if (!this.state.selectedDate) {
                Toast.fail("Please select date type first.");
                return;
            }
            if (this.state.toDate === "") {
                Toast.fail("Please enter both the start date and end date.");
                return;
            }
        }

        if (this.state.toDate !== "") {
            if (!this.state.selectedDate) {
                Toast.fail("Please select date type first.");
                return;
            }
            if (this.state.startDate === "") {
                Toast.fail("Please enter both the start date and end date.");
                return;
            }
        }

        if (
            this.state.selectedDate &&
            (this.state.startDate === "" || this.state.toDate === "")
        ) {
            Toast.fail("Please enter both the start date and end date.");
            return;
        }

        if (this.state.startDate && this.state.toDate) {
            if (this.state.startDate > this.state.toDate) {
                Toast.fail("Start date should not be greater than end date.");
                return;
            }
        }

        if(this.state.searchSegment !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'Segment'+ ' ' + 'like' + "'" + encodeURIComponent(this.state.searchSegment) + '%'+"'";
        }
        if(this.state.searchUnit !== ''){
            WhereClause= WhereClause !== '' ? WhereClause + ' and ': '';
            WhereClause =  WhereClause + 'Unit'+ ' ' + 'like' + "'" + encodeURIComponent(this.state.searchUnit) + '%'+"'";
        }

        if (WhereClause !== "") {
            this.setState(
                {
                    WhereClause: WhereClause,
                    isTypedInFilter: WhereClause,
                    tempWorkflowStatus: this.state.WorkflowStatus,
                    tempIdeaId: this.state.IdeaId,
                    tempAccountManual: this.state.AccountManual,
                    tempRegionManual: this.state.RegionManual,
                    tempVerticalManual: this.state.VerticalManual,
                    tempTransformationLeverName: this.state.TransformationLeverName,
                    tempCertificationLevelName: this.state.CertificationLevelName,
                    tempSubVerticalName: this.state.SubVerticalName,
                    tempServiceLineName: this.state.ServiceLineName,
                    tempSubServiceLine: this.state.SubServiceLine,
                    tempstartDate: this.state.startDate,
                    temptoDate: this.state.toDate,
                },
                async () => {
                    console.log("whereClause", this.state.WhereClause);
                    await this.fetchIdea();
                }
            );
        } else {
            if (this.state.tempIdeaId) {
                this.refreshSearch();
            } else {
                Toast.fail("Please type something then search.");
            }
        }
    };


    

    renderDateOptions = () => {
        let options = [];
        this.state.dateList.map((item) => {
            options.push({
                value: item,
                label: item,
            });
            return options;
        });
        return options;
    };

    renderStatusOptions = () => {
        let options = [];
        this.state.workFlowStatusList.map((item) => {
            if (this.state.approvalPage) {
                if (item.WorkflowStatusName !== "Send Back") {
                    options.push({
                        value: item.WorkflowStatusId,
                        label: item.WorkflowStatusName,
                    });
                }
            } else {
                options.push({
                    value: item.WorkflowStatusId,
                    label: item.WorkflowStatusName,
                });
            }
            return options;
        });
        return options;
    };

    renderProjectStatusOptions = () => {
        let options = [];
        this.state.projectStatusList.map((item) => {
            options.push({
                value: item.WorkflowStatusId,
                label: item.WorkflowStatusName,
            });
            return options;
        });
        return options;
    };

    renderAccountOptions = () => {
        let options = [];
        let accountName = "";
        this.state.accountList.map((item) => {
            accountName = item.ACCOUNT.toString().toLowerCase();
            if (
                !(
                    accountName.startsWith("unidentified") ||
                    accountName.startsWith("unmapped") ||
                    accountName.startsWith("genpact")
                )
            ) {
                options.push({
                    value: item.ACCOUNT_L2,
                    label: item.ACCOUNT,
                });
            }
            return true;
        });
        return options;
    };

    renderTransLeversOptions = () => {
        let options = [];
        this.state.transformationLeversList.map((item) => {
            options.push({
                value: item.TransformationLeverId,
                label: item.TransformationLeverName,
            });
            return options;
        });
        return options;
    };

    renderCertLevelsOptions = () => {
        let options = [];
        this.state.certificationLevelsList.map((item) => {
            options.push({
                value: item.CertificationLevelId,
                label: item.CertificationLevelName,
            });
            return options;
        });
        return options;
    };

    renderRegionOptions = () => {
        let options = [];
        this.state.regionList.map((item) => {
            options.push({
                value: item.Region,
                label: item.Region,
            });
            return options;
        });
        return options;
    };

    renderServiceLineOptions = () => {
        let options = [];
        const uniqueServiceLineList = [
            ...new Map(
                this.state.serviceLineList.map((item) => [item.ServiceLineName, item])
            ).values(),
        ];
        uniqueServiceLineList.map((item) => {
            options.push({ value: item.ServiceLineId, label: item.ServiceLineName });
            return true;
        });
        return options;
    };

    renderSubServiceLineOptions = () => {
        let options = [];
        this.state.subServiceLineList.map((item) => {
            if (this.state.ServiceLineName === item.ServiceLineName) {
                options.push({
                    value: item.SubServiceLineId,
                    label: item.SubServiceLineName,
                });
                return true;
            }
            return true;
        });
        return options;
    };

    renderReplicaIndexOption = () => {
        let options = [];
        this.state.replicaIndexOptionList.map((item) => {
            options.push({
                value: item,
                label: item,
            });
            return options;
        });
        return options;
    };

    renderBestInClassOptions = () => {
        let options = [];
        this.state.bestInClassList.map((item) => {
            options.push({
                value: item,
                label: item,
            });
            return options;
        });
        return options;
    };

    handleDateOptionChange = (e) => {
        this.setState(
            {
                isTypedData: true,
                dateSelectedOption: {
                    value: e.value,
                    label: e.label,
                },
                skipCount: 0,
            },
            () => {
                if (e.label === "Created date") {
                    this.setState({
                        selectedDate: "CreatedDate",
                    });
                } else if (e.label === "Reviewed date") {
                    this.setState({
                        selectedDate: "ModifiedDate",
                    });
                }
            }
        );
    };

    handleAccountNameChange = (e) => {
        this.setState(
            {
                AccountManual: e.label,
                accountName: e.label,
                isTypedData: true,
                accountSelectionOption: {
                    value: e.value,
                    label: e.label,
                },
                skipCount: 0,
            },
            async () => {
                await this.searchData();
            }
        );
    };

    handleReviewStatusFilterChange = (e) => {
        this.setState(
            {
                WorkflowStatus: e.label,
                isTypedData: true,
                workFlowStatusSelectedOption: {
                    value: e.value,
                    label: e.label,
                },
                skipCount: 0,
            },
            async () => {
                await this.searchData();
            }
        );
    };

    handleIdeaIDFilterChange = (searchText) => {
        this.setState({
            IdeaId: searchText,
            isTypedData: true,
        });
    };

    renderUniqueSegment=()=>{
        let options = [];
        let accountName = "";
        this.state.uniqueSegments.map((item,index) => {
            console.log()
            accountName = item.SEGMENT ? item.SEGMENT.toString().toLowerCase() : "";
            if ( !( accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact") || 
            accountName === "" || accountName === null || accountName === undefined) ){
                options.push({
                    value: index,
                    label: item.SEGMENT
                })
            }
            return true;
        });
                return options;
    }

    renderUniqueUnits=()=>{
        let options = [];
        let accountName = "";
        this.state.uniqueUnits.map((item,index) => {
            accountName = item.UNIT ? item.UNIT.toString().toLowerCase():"";
            if ( !( accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact") || 
            accountName === "" || accountName === null || accountName === undefined) ){
                options.push({
                    value: index,
                    label: item.UNIT
                })
            }
            return true;
        });
        return options;
    }


    handleSegmentOptionChange=(e)=>{
        this.setState({
            isTypedData:true,
            searchSegment:e.label,
            searchSegmentSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }
    handleUnitOptionChange=(e)=>{
        this.setState({
            isTypedData:true,
            searchUnit:e.label,
            searchUnitSelectedOption: {
                value: e.value,
                label: e.label
            },
            skipCount:0
        }, async () => {
            await this.searchData();
        })
    }




    handleVerticalFilterChange = (e) => {
        this.setState(
            {
                subVerticalSelectedOption: null,
                VerticalManual: e.label,
                verticalSelectedOption: {
                    value: e.value,
                    label: e.label,
                },
                skipCount: 0,
                isTypedData: true,
            },
            async () => {
                await this.searchData();
            }
        );
    };

    handleRegionFilterChange = (e) => {
        this.setState(
            {
                RegionManual: e.label,
                isTypedData: true,
                regionSelectedOption: {
                    value: e.value,
                    label: e.label,
                },
                skipCount: 0,
            },
            async () => {
                await this.searchData();
            }
        );
    };

    handleTransformationLeverNameFilterChange = (e) => {
        this.setState(
            {
                TransformationLeverName: e.label,
                isTypedData: true,
                transSelectedOption: {
                    value: e.value,
                    label: e.label,
                },
                skipCount: 0,
            },
            async () => {
                await this.searchData();
            }
        );
    };

    handleCertLevelChange = (e) => {
        this.setState(
            {
                CertificationLevelName: e.label,
                isTypedData: true,
                certLevelsSelectedOption: {
                    value: e.value,
                    label: e.label,
                },
                skipCount: 0,
            },
            async () => {
                await this.searchData();
            }
        );
    };

    handleSubVerticalNameFilterChange = (e) => {
        this.setState(
            {
                SubVerticalName: e.label,
                subVerticalSelectedOption: {
                    value: e.value,
                    label: e.label,
                },
                isTypedData: true,
                skipCount: 0,
            },
            async () => {
                await this.searchData();
            }
        );
    };

    handleService = (e) => {
        this.setState(
            {
                ServiceLineName: e.label,
                isTypedData: true,
                serviceLineSelectedOption: {
                    value: e.value,
                    label: e.label,
                },
                skipCount: 0,
                subServiceLineSelectedOption: null,
                SubServiceLine: "",
            },
            async () => {
                await this.searchData();
            }
        );
    };

    handleSubServiceLine = (e) => {
        this.setState(
            {
                SubServiceLine: e.label,
                isTypedData: true,
                subServiceLineSelectedOption: {
                    value: e.value,
                    label: e.label,
                },
                skipCount: 0,
            },
            async () => {
                await this.searchData();
            }
        );
    };

    handleBestInClassOptionChange = (e) => {
        if (e.label === "Yes") {
            this.setState({
                selectedBestInClass: 1,
            });
        } else if (e.label === "No") {
            this.setState({
                selectedBestInClass: 0,
            });
        }

        this.setState(
            {
                isTypedData: true,
                bestInClassOption: {
                    value: e.value,
                    label: e.label,
                },
                skipCount: 0,
            },
            async () => {
                await this.searchData();
            }
        );
    };

    handleReplicaIndexOptionChange = (e) => {
        this.setState(
            {
                isTypedData: true,
                selectedReplicaIndex: e.label,
                replicaIndexOption: {
                    value: e.value,
                    label: e.label,
                },
                skipCount: 0,
            },
            async () => {
                await this.searchData();
            }
        );
    };

    handleCreatedDate = (dates) => {
        this.setState({
            startDate: dates,
            isTypedData: true,
        });
    };

    handleToDate = (dates) => {
        this.setState({
            toDate: dates,
            isTypedData: true,
        });
    };

    handleGlobalSearchFilterChange = (searchText) => {
        this.setState({
            globalsearchText: searchText,
            isTypedData: true,
            isTypedInGlobalSearch: true,
        });
        if (searchText === "") {
            window.location.reload();
        }
    };

    handleGetFirstPage = () => {
        this.setState(
            {
                skipCount: 0,
            },
            async () => {
                await this.fetchIdea();
            }
        );
    };

    handleGetLastPage = () => {
        let skipCount =
            (parseInt(this.state.totalPages) - 1) * parseInt(this.state.topCount);
        this.setState(
            {
                skipCount: skipCount,
            },
            async () => {
                await this.fetchIdea();
            }
        );
    };

    handlePrevAction = () => {
        let skipCount = this.state.skipCount;
        let topCount = this.state.topCount;
        this.setState(
            {
                skipCount: parseInt(skipCount) - parseInt(topCount),
            },
            async () => {
                console.log("skip prev", this.state.skipCount);
                console.log("top count", this.state.topCount);
                await this.fetchIdea();
            }
        );
    };

    handleNextAction = async () => {
        let skipCount = this.state.skipCount;
        let topCount = this.state.topCount;
        this.setState(
            {
                skipCount: parseInt(skipCount) + parseInt(topCount),
            },
            async () => {
                console.log("skip next", this.state.skipCount);
                console.log("top count", this.state.topCount);
                await this.fetchIdea();
            }
        );
    };

    handlePageChangeHandler = (event) => {
        this.setState({
            pageNo: event.target.value,
        });
    };

    goToPage = () => {
        this.setState(
            {
                skipCount:
                    (parseInt(this.state.pageNo) - 1) * parseInt(this.state.topCount),
            },
            async () => {
                await this.fetchIdea();
            }
        );
    };

    refreshSearch = () => {
        window.location.reload();
    };

    filterHandler = () => {
        if (this.state.propsData) {
            this.setState({
                workFlowStatusSelectedOption: this.state.propsData.WorkflowStatus
                    ? {
                        value: this.state.tempWorkflowStatus,
                        label: this.state.tempWorkflowStatus,
                    }
                    : null,
                accountSelectionOption: this.state.propsData.AccountManual
                    ? {
                        value: this.state.tempAccountManual,
                        label: this.state.tempAccountManual,
                    }
                    : null,
                transSelectedOption: this.state.propsData.TransformationLeverName
                    ? {
                        value: this.state.tempTransformationLeverName,
                        label: this.state.tempTransformationLeverName,
                    }
                    : null,
                certLevelsSelectedOption: this.state.propsData.CertificationLevelName
                    ? {
                        value: this.state.tempCertificationLevelName,
                        label: this.state.tempCertificationLevelName,
                    }
                    : null,
                serviceLineSelectedOption: this.state.propsData.ServiceLineName
                    ? {
                        value: this.state.tempServiceLineName,
                        label: this.state.tempServiceLineName,
                    }
                    : null,
                subServiceLineSelectedOption: this.state.propsData.SubServiceLine
                    ? {
                        value: this.state.tempSubServiceLine,
                        label: this.state.tempSubServiceLine,
                    }
                    : null,
                regionSelectedOption: this.state.propsData.RegionManual
                    ? {
                        value: this.state.tempRegionManual,
                        label: this.state.tempRegionManual,
                    }
                    : null,
                verticalSelectedOption: this.state.propsData.VerticalManual
                    ? {
                        value: this.state.tempVerticalManual,
                        label: this.state.tempVerticalManual,
                    }
                    : null,
                subVerticalSelectedOption: this.state.propsData.SubVerticalName
                    ? {
                        value: this.state.tempSubVerticalName,
                        label: this.state.tempSubVerticalName,
                    }
                    : null,
                dateSelectedOption: this.state.propsData.selectedDate
                    ? { value: this.state.selectedDate, label: this.state.selectedDate }
                    : null,
                bestInClassOption:
                    this.state.propsData.selectedBestInClass === 0 ||
                        this.state.propsData.selectedBestInClass === 1
                        ? {
                            value: this.state.selectedBestInClass,
                            label: this.state.selectedBestInClass,
                        }
                        : null,
                replicaIndexOption: this.state.propsData.selectedReplicaIndex
                    ? {
                        value: this.state.selectedReplicaIndex,
                        label: this.state.selectedReplicaIndex,
                    }
                    : null,
                globalsearchText: this.state.propsData.globalsearchText
                    ? this.state.propsData.globalsearchText
                    : null,
            });
        }
        this.setState({
            filter: !this.state.filter,
            IdeaId: this.state.tempIdeaId,
            WorkflowStatus: this.state.tempWorkflowStatus,
            AccountManual: this.state.tempAccountManual,
            RegionManual: this.state.tempRegionManual,
            VerticalManual: this.state.tempVerticalManual,
            TransformationLeverName: this.state.tempTransformationLeverName,
            CertificationLevelName: this.state.tempCertificationLevelName,
            SubVerticalName: this.state.tempSubVerticalName,
            ServiceLineName: this.state.tempServiceLineName,
            SubServiceLine: this.state.tempSubServiceLine,
            startDate: this.state.tempstartDate,
            toDate: this.state.temptoDate,
        });
    };

    render() {
        return (
            <>
                <div>
                    <h4
                        className="u-text"
                        style={{
                            fontFamily: "Georgia, Arial sans-serif",
                            color: "#ffffff",
                            fontSize: "28px",
                            position: "absolute",
                            left: "36%",
                            top: "80px",
                        }}
                    >
                        DB SPOC Approval List Page
                    </h4>
                    <img
                        src={require("../assets/images/listbanner.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                </div>
                <section className="u-clearfix u-section-1" id="sec-0f7f">
                    <div
                        className="u-clearfix u-sheet u-sheet-1"
                        style={{ marginBottom: 20, width: "100%" }}
                    >
                        <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                            <div className="u-container-layout u-container-layout-1 view-idea-table">
                                <div
                                    id="idea1"
                                    className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round"
                                >
                                    <div className="u-container-layout u-container-layout-7">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row" style={{ marginTop: "8px" }}>
                                                    <div className="col-md-1">
                                                        <div className="filter_main_block">
                                                            <i
                                                                style={{
                                                                    marginTop: 5,
                                                                    position: "absolute",
                                                                    fontSize: 18,
                                                                    top: "4px",
                                                                }}
                                                                className={`${FILTER} filterblock_title`}
                                                                onClick={this.filterHandler}
                                                                title="Search"
                                                                aria-hidden="true"
                                                            ></i>
                                                            {this.state.filter ? (
                                                                <>
                                                                    <div className="filter_block">
                                                                        <h3>
                                                                            Filters:
                                                                            <span
                                                                                style={{
                                                                                    float: "right",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            >
                                                                                <i
                                                                                    className={CLOSE}
                                                                                    onClick={() =>
                                                                                        this.setState({ filter: false })
                                                                                    }
                                                                                ></i>
                                                                            </span>
                                                                        </h3>
                                                                        <div>
                                                                            <Select
                                                                                isSearchable={true}
                                                                                options={this.renderDateOptions()}
                                                                                placeholder="Choose date"
                                                                                onChange={this.handleDateOptionChange}
                                                                                value={this.state.dateSelectedOption}
                                                                            />
                                                                        </div>
                                                                        <div className="filter_input">
                                                                            <input
                                                                                type="date"
                                                                                max="2099-01-01"
                                                                                min="1970-01-01"
                                                                                value={this.state.startDate}
                                                                                onChange={(event) =>
                                                                                    this.handleCreatedDate(
                                                                                        event.target.value
                                                                                    )
                                                                                }
                                                                                placeholder="Select from date"
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className="filter_input">
                                                                            <input
                                                                                type="date"
                                                                                max="2099-01-01"
                                                                                min="1970-01-01"
                                                                                value={this.state.toDate}
                                                                                onChange={(event) =>
                                                                                    this.handleToDate(event.target.value)
                                                                                }
                                                                                placeholder="Select to date"
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className="filter_input">
                                                                            <input
                                                                                type="text"
                                                                                value={this.state.IdeaId}
                                                                                onChange={(event) =>
                                                                                    this.handleIdeaIDFilterChange(
                                                                                        event.target.value
                                                                                    )
                                                                                }
                                                                                placeholder="Search Idea ID/ Project ID"
                                                                                onKeyPress={(event) => {
                                                                                    if (event.key === "Enter") {
                                                                                        this.searchData();
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {!this.state.leanApprovalPage && (
                                                                            <div>
                                                                                <Select
                                                                                    isSearchable={true}
                                                                                    options={this.renderStatusOptions()}
                                                                                    placeholder="Choose Status"
                                                                                    onChange={
                                                                                        this.handleReviewStatusFilterChange
                                                                                    }
                                                                                    value={
                                                                                        this.state
                                                                                            .workFlowStatusSelectedOption
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {this.state.leanApprovalPage && (
                                                                            <div>
                                                                                <Select
                                                                                    isSearchable={true}
                                                                                    options={this.renderProjectStatusOptions()}
                                                                                    placeholder="Choose Status"
                                                                                    onChange={
                                                                                        this.handleReviewStatusFilterChange
                                                                                    }
                                                                                    value={
                                                                                        this.state
                                                                                            .workFlowStatusSelectedOption
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        <div style={{ marginTop: 7 }}>
                                                                            <Select
                                                                                tabIndex={1}
                                                                                isSearchable={true}
                                                                                options={this.renderAccountOptions()}
                                                                                placeholder="Choose Account"
                                                                                onChange={this.handleAccountNameChange}
                                                                                value={
                                                                                    this.state.accountSelectionOption
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div style={{ marginTop: 7 }}>
                                                                            <Select
                                                                                tabIndex={2}
                                                                                isSearchable={true}
                                                                                value={
                                                                                    this.state.verticalSelectedOption
                                                                                }
                                                                                options={this.renderVerticalOptions()}
                                                                                placeholder="Choose Vertical"
                                                                                onChange={
                                                                                    this.handleVerticalFilterChange
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div style={{ marginTop: 7 }}>
                                                                            <Select
                                                                                tabIndex={3}
                                                                                isSearchable={true}
                                                                                value={
                                                                                    this.state.subVerticalSelectedOption
                                                                                }
                                                                                options={this.renderSubVerticalOptions()}
                                                                                placeholder="Choose Industry Vertical"
                                                                                onChange={
                                                                                    this.handleSubVerticalNameFilterChange
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div style={{ marginTop: 7 }}>
                                                                            <Select
                                                                                isSearchable={true}
                                                                                options={this.renderRegionOptions()}
                                                                                placeholder="Choose Region"
                                                                                onChange={this.handleRegionFilterChange}
                                                                                value={this.state.regionSelectedOption}
                                                                            />
                                                                        </div>
                                                                        {!this.state.leanApprovalPage && (
                                                                            <div style={{ marginTop: 7 }}>
                                                                                <Select
                                                                                    isSearchable={true}
                                                                                    options={this.renderCertLevelsOptions()}
                                                                                    onChange={this.handleCertLevelChange}
                                                                                    placeholder="Choose Certification Level"
                                                                                    value={
                                                                                        this.state.certLevelsSelectedOption
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        <div style={{ marginTop: 7 }}>
                                                                            <Select
                                                                                isSearchable={true}
                                                                                options={this.renderTransLeversOptions()}
                                                                                onChange={
                                                                                    this
                                                                                        .handleTransformationLeverNameFilterChange
                                                                                }
                                                                                placeholder="Choose Transformation"
                                                                                value={this.state.transSelectedOption}
                                                                            />
                                                                        </div>
                                                                        <div style={{ marginTop: 7 }}>
                                                                            <Select
                                                                                isSearchable={true}
                                                                                options={this.renderServiceLineOptions()}
                                                                                placeholder="Choose Service Line"
                                                                                onChange={this.handleService}
                                                                                value={
                                                                                    this.state.serviceLineSelectedOption
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div style={{ marginTop: 7 }}>
                                                                            <Select
                                                                                isSearchable={true}
                                                                                options={this.renderSubServiceLineOptions()}
                                                                                placeholder="Choose Sub-Service Line"
                                                                                onChange={this.handleSubServiceLine}
                                                                                value={
                                                                                    this.state
                                                                                        .subServiceLineSelectedOption
                                                                                }
                                                                            />
                                                                        </div>
                                                                        {this.state.leanApprovalPage && (
                                                                            <>
                                                                                <div style={{ marginTop: 7 }}>
                                                                                    <Select
                                                                                        isSearchable={true}
                                                                                        options={this.renderBestInClassOptions()}
                                                                                        placeholder="Choose best in class"
                                                                                        onChange={
                                                                                            this.handleBestInClassOptionChange
                                                                                        }
                                                                                        value={this.state.bestInClassOption}
                                                                                    />
                                                                                </div>
                                                                                <div style={{ marginTop: 7 }}>
                                                                                    <Select
                                                                                        isSearchable={true}
                                                                                        options={this.renderReplicaIndexOption()}
                                                                                        placeholder="Choose Replicability Index"
                                                                                        onChange={
                                                                                            this
                                                                                                .handleReplicaIndexOptionChange
                                                                                        }
                                                                                        value={
                                                                                            this.state.replicaIndexOption
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )}


                                                                        <div className="" style={{ marginTop: "7px" }}>
                                                                            <Select isSearchable={true} options={this.renderUniqueSegment()} placeholder="Choose Segment" onChange={(e) => this.handleSegmentOptionChange(e)} value={this.state.searchSegmentSelectedOption} />
                                                                        </div>

                                                                        <div className="" style={{ marginTop: "7px" }}>
                                                                            <Select isSearchable={true} options={this.renderUniqueUnits()} placeholder="Choose Unit" onChange={(e) => this.handleUnitOptionChange(e)} value={this.state.searchUnitSelectedOption} />
                                                                        </div>


                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                {this.state.isTypedData ? (
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            this.searchData() &&
                                                                                            this.setState({ filter: false })
                                                                                        }
                                                                                        className="btn-block u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                                                                    >
                                                                                        Search
                                                                                    </button>
                                                                                ) : (
                                                                                    <button
                                                                                        onClick={(e) =>
                                                                                            window.alert(
                                                                                                "Please enter value to search."
                                                                                            )
                                                                                        }
                                                                                        className="btn-block u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                                                                    >
                                                                                        Search
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <button
                                                                                    onClick={() => this.resetData()}
                                                                                    className="btn-block u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                                                                >
                                                                                    Reset
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-md-10"
                                                        style={{ paddingLeft: "0px" }}
                                                    >
                                                        <div className="project_qc_review_title">
                                                            <ul>
                                                                <li>
                                                                    <a
                                                                        style={{
                                                                            fontSize: 14,
                                                                            color: "#122539",
                                                                            borderBottom: "4px solid #259bca",
                                                                            textDecoration: "none",
                                                                        }}
                                                                        href="/dbspoc-approval-list-page"
                                                                    >
                                                                        DB SPOC Approval List Page
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-md-2"
                                                style={{ marginLeft: "-10px", marginRight: "10px" }}
                                            >
                                                {/* <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"><a href="/approval-history-page" style={{color:"#FFF",textDecoration:"none"}}>Approval History</a></button> */}
                                            </div>
                                            <div className="col-md-2">
                                                <div className="filter_search_block">
                                                    <input
                                                        value={this.state.globalsearchText}
                                                        onChange={(event) =>
                                                            this.handleGlobalSearchFilterChange(
                                                                event.target.value
                                                            )
                                                        }
                                                        placeholder="Search here"
                                                        onKeyPress={(event) => {
                                                            if (event.key === "Enter") {
                                                                this.globalSearch();
                                                            }
                                                        }}
                                                    />
                                                    <i
                                                        style={{
                                                            marginTop: 9,
                                                            position: "absolute",
                                                            color: "#001A30",
                                                            marginLeft: -35,
                                                            cursor: "pointer",
                                                        }}
                                                        className={
                                                            this.state.isTypedInGlobalSearch
                                                                ? `${SEARCH_ICON} icon-style`
                                                                : `${SEARCH_ICON} fa-disabled`
                                                        }
                                                        onClick={this.globalSearch}
                                                        title="search"
                                                    ></i>
                                                    <i
                                                        style={{
                                                            marginTop: 9,
                                                            position: "absolute",
                                                            color: "#001A30",
                                                            marginLeft: -15,
                                                            cursor: "pointer",
                                                        }}
                                                        className={
                                                            this.state.isTypedInGlobalSearch
                                                                ? `${CLOSE} icon-style`
                                                                : `${CLOSE} fa-disabled`
                                                        }
                                                        onClick={this.refreshSearch}
                                                        title="close"
                                                    ></i>
                                                </div>
                                            </div>
                                            <div className="col-md-2" style={{ marginLeft: -15 }}>
                                                <select
                                                    style={{
                                                        width: 60,
                                                        height: 35,
                                                        border: "1px solid lightgrey",
                                                    }}
                                                    onChange={this.topCountChangeHandler}
                                                >
                                                    {this.renderTopCountOptions()}
                                                </select>
                                                <span
                                                    style={{ verticalAlign: "center", paddingLeft: 4 }}
                                                >
                                                    {" "}
                                                    Rows per page
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                borderBottom: "1px solid lightgrey",
                                                margin: "10px 0px",
                                            }}
                                        />

                                        <table
                                            className="table review-idea-table"
                                            style={{ fontSize: 12 }}
                                        >
                                            <thead
                                                style={{ textAlign: "center !important" }}
                                                className="view-idea-thead"
                                            >
                                                <tr>
                                                    {/* <th scope="col">#</th> */}
                                                    <th
                                                        className="hidden-xs hidden-sm"
                                                        scope="col"
                                                        onClick={() => this.handleIdeaIdSortClick("IdeaId")}
                                                    >
                                                        Idea ID
                                                        <span
                                                            style={{ marginLeft: "5px", color: "#00AECF" }}
                                                        >
                                                            <i
                                                                className={
                                                                    !this.state.ideaIdSortIconRotate
                                                                        ? SORT_DOWN
                                                                        : SORT_UP
                                                                }
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>
                                                    </th>
                                                    {this.state.leanApprovalPage && (
                                                        <th className="hidden-xs hidden-sm" scope="col">
                                                            Project ID
                                                        </th>
                                                    )}
                                                    {/* <th className="hidden-xs hidden-sm" scope="col">Project ID</th> */}
                                                    <th
                                                        scope="col"
                                                        onClick={() =>
                                                            this.handleIdeaNameSortClick("IdeaName")
                                                        }
                                                    >
                                                        Idea name
                                                        <span
                                                            style={{ marginLeft: "5px", color: "#00AECF" }}
                                                        >
                                                            <i
                                                                className={
                                                                    !this.state.ideaNameSortIconRotate
                                                                        ? SORT_DOWN
                                                                        : SORT_UP
                                                                }
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>
                                                    </th>
                                                    <th
                                                        className="col-md-1 hidden-xs hidden-sm"
                                                        scope="col"
                                                        onClick={() =>
                                                            this.handleAccountSortClick("AccountManual")
                                                        }
                                                    >
                                                        Account
                                                        <span
                                                            style={{ marginLeft: "5px", color: "#00AECF" }}
                                                        >
                                                            <i
                                                                className={
                                                                    !this.state.accountSortIconRotate
                                                                        ? SORT_DOWN
                                                                        : SORT_UP
                                                                }
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>
                                                    </th>
                                                    <th className="hidden-xs hidden-sm" scope="col">
                                                        Owner
                                                    </th>
                                                    <th
                                                        className="hidden-xs hidden-sm"
                                                        scope="col"
                                                        onClick={() =>
                                                            this.handleSubmitterSortClick("OwnerName")
                                                        }
                                                    >
                                                        Submitter
                                                        <span
                                                            style={{ marginLeft: "5px", color: "#00AECF" }}
                                                        >
                                                            <i
                                                                className={
                                                                    !this.state.submitterSortIconRotate
                                                                        ? SORT_DOWN
                                                                        : SORT_UP
                                                                }
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>
                                                    </th>
                                                    <th
                                                        className="hidden-xs hidden-sm"
                                                        scope="col"
                                                        onClick={() =>
                                                            this.handleApproverSortClick("BlackBeltUserName")
                                                        }
                                                    >
                                                        Approver
                                                        <span
                                                            style={{ marginLeft: "5px", color: "#00AECF" }}
                                                        >
                                                            <i
                                                                className={
                                                                    !this.state.approverSortIconRotate
                                                                        ? SORT_DOWN
                                                                        : SORT_UP
                                                                }
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>
                                                    </th>
                                                    <th
                                                        className="hidden-xs hidden-sm"
                                                        scope="col"
                                                        onClick={() =>
                                                            this.handleCreatedDateSortClick("CreatedDate")
                                                        }
                                                    >
                                                        Create date
                                                        <span
                                                            style={{ marginLeft: "5px", color: "#00AECF" }}
                                                        >
                                                            <i
                                                                className={
                                                                    !this.state.createdDateSortIconRotate
                                                                        ? SORT_DOWN
                                                                        : SORT_UP
                                                                }
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>
                                                    </th>
                                                    <th
                                                        className="hidden-xs hidden-sm"
                                                        scope="col"
                                                        onClick={() =>
                                                            this.handleReviewStatusSortClick("WorkflowStatus")
                                                        }
                                                    >
                                                        Idea review status
                                                        <span
                                                            style={{ marginLeft: "5px", color: "#00AECF" }}
                                                        >
                                                            <i
                                                                className={
                                                                    !this.state.reviewStatusSortIcon
                                                                        ? SORT_DOWN
                                                                        : SORT_UP
                                                                }
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>
                                                    </th>
                                                    <th
                                                        className="hidden-xs hidden-sm"
                                                        scope="col"
                                                        onClick={() =>
                                                            this.handleReviewDateSortClick("ModifiedDate")
                                                        }
                                                    >
                                                        Review date
                                                        <span
                                                            style={{ marginLeft: "5px", color: "#00AECF" }}
                                                        >
                                                            <i
                                                                className={
                                                                    !this.state.reviewDateSortIcon
                                                                        ? SORT_DOWN
                                                                        : SORT_UP
                                                                }
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>
                                                    </th>


                                                    {/* <th
                                                        className="hidden-xs hidden-sm"
                                                        scope="col"
                                                        onClick={() =>
                                                            this.handleProjectSortClick("ApprovalStatus")
                                                        }
                                                    >
                                                        Project status
                                                        <span
                                                            style={{ marginLeft: "5px", color: "#00AECF" }}
                                                        >
                                                            <i
                                                                className={
                                                                    !this.state.projectStatusSortIcon
                                                                        ? SORT_DOWN
                                                                        : SORT_UP
                                                                }
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>
                                                    </th> */}


                                                    <th
                                                        className="hidden-xs hidden-sm"
                                                        scope="col"
                                                        onClick={() =>
                                                            this.handleCertTypeSortClick(
                                                                "CertificationLevelName"
                                                            )
                                                        }
                                                    >
                                                        Certification type
                                                        <span
                                                            style={{ marginLeft: "5px", color: "#00AECF" }}
                                                        >
                                                            <i
                                                                className={
                                                                    !this.state.certTypeSortIcon
                                                                        ? SORT_DOWN
                                                                        : SORT_UP
                                                                }
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>
                                                    </th>
                                                    <th
                                                        className="hidden-xs hidden-sm"
                                                        scope="col"
                                                        onClick={() =>
                                                            this.handleTransformationLeverSortClick(
                                                                "TransformationLeverName"
                                                            )
                                                        }
                                                    >
                                                        Transformation lever
                                                        <span
                                                            style={{ marginLeft: "5px", color: "#00AECF" }}
                                                        >
                                                            <i
                                                                className={
                                                                    !this.state.transformationLeverSortIcon
                                                                        ? SORT_DOWN
                                                                        : SORT_UP
                                                                }
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        onClick={() =>
                                                            this.handleServiceLineSortClick("ServiceLineName")
                                                        }
                                                    >
                                                        Service line
                                                        <span
                                                            style={{ marginLeft: "5px", color: "#00AECF" }}
                                                        >
                                                            <i
                                                                className={
                                                                    !this.state.serviceLineSortIconRotate
                                                                        ? SORT_DOWN
                                                                        : SORT_UP
                                                                }
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>
                                                    </th>


                                                    <th scope="col" onClick={() => this.handleSegmentSortClick("Segment")}>
                                                        Segment
                                                        <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                            <i
                                                                className={!this.state.segmentSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>
                                                    </th>
                                                    <th scope="col" onClick={() => this.handleUnitSortClick("Unit")}>
                                                        Unit
                                                        <span style={{ marginLeft: "5px", color:'#00AECF' }}>
                                                            <i
                                                                className={!this.state.unitSortIconRotate ? SORT_DOWN : SORT_UP}
                                                                aria-hidden="true"
                                                            ></i>
                                                        </span>
                                                    </th>



                                                    <th
                                                        scope="col"
                                                        style={{ paddingLeft: 25, paddingRight: 25 }}
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="review-idea-tbody">
                                                {this.state.ideaList.length > 0 ? (
                                                    this.state.ideaList.map((ideaItem, index) => {
                                                        // console.log(ideaItem);
                                                        let createdDate = new Date(ideaItem.CreatedDate);
                                                        let modifiedDate = new Date(ideaItem.ModifiedDate);
                                                        let formattedCreateDate =
                                                            this.formatDateString(createdDate);
                                                        let formattedModifiedDate =
                                                            this.formatDateString(modifiedDate);

                                                        let IDEA_PENDING =
                                                            ideaItem.WorkflowStatus === "Pending";
                                                        let IS_USER_APPROVER =
                                                            parseInt(this.sessionData.profile.oHRId) ===
                                                            ideaItem.BlackBeltUserId ||
                                                            parseInt(this.sessionData.profile.oHRId) ===
                                                            ideaItem.MBBOHR ||
                                                            parseInt(this.sessionData.profile.oHRId) ===
                                                            parseInt(ideaItem.LDTId);
                                                        let IS_USER_BB =
                                                            parseInt(this.sessionData.profile.oHRId) ===
                                                            ideaItem.BlackBeltUserId;
                                                        let IS_USER_MBB =
                                                            parseInt(this.sessionData.profile.oHRId) ===
                                                            ideaItem.MBBOHR;
                                                        let IS_USER_LDT =
                                                            parseInt(this.sessionData.profile.oHRId) ===
                                                            parseInt(ideaItem.LDTId);
                                                        let IS_USER_SUBMITTER =
                                                            parseInt(this.sessionData.profile.oHRId) ===
                                                            parseInt(ideaItem.OwnerOHR);
                                                        let IDEA_APPROVED =
                                                            ideaItem.WorkflowStatus === "Approve";
                                                        let IDEA_REJECTED =
                                                            ideaItem.WorkflowStatus === "Reject";
                                                        let IDEA_SEND_BACK =
                                                            ideaItem.WorkflowStatus === "Send Back";
                                                        let IDEA_REQUEST_CANCELLATION =
                                                            ideaItem.WorkflowStatus ===
                                                            "Cancellation Requested";
                                                        let IDEA_REQUEST_CANCELLATION1 =
                                                            ideaItem.ApprovalStatus ===
                                                            "Cancellation Requested";
                                                        let IDEA_CANCELLED =
                                                            ideaItem.WorkflowStatus === "Cancelled";
                                                        let IDEA_CANCELLED1 =
                                                            ideaItem.ApprovalStatus === "Cancelled";
                                                        let PENDING_DOCUMENTS =
                                                            ideaItem.ApprovalStatus === "Pending Documents";
                                                        let DOCUMENTS_UPLOADED =
                                                            ideaItem.ApprovalStatus === "Documents Uploaded";
                                                        let MBB_APPROVAL =
                                                            ideaItem.ApprovalStatus === AWAITING_MBB_APPROVAL;
                                                        let LDT_APPROVAL =
                                                            ideaItem.ApprovalStatus === AWAITING_LDT_APPROVAL;
                                                        let COMPLETED =
                                                            ideaItem.ApprovalStatus === COMPLETED_STATUS;
                                                        // let LEAN_IDEA = (ideaItem.CertificationLevelName === "Lean");
                                                        // let YB_IDEA = (ideaItem.CertificationLevelName === "YB");
                                                        let action = VIEW;
                                                        if (
                                                            IDEA_SEND_BACK &&
                                                            !IS_USER_APPROVER &&
                                                            IS_USER_SUBMITTER
                                                        ) {
                                                            action = EDIT;
                                                        } else if (
                                                            PENDING_DOCUMENTS &&
                                                            !IS_USER_APPROVER &&
                                                            IS_USER_SUBMITTER
                                                        ) {
                                                            action = UPDATE;
                                                        }
                                                        let IDEA_AI_BOT_REVIEW =
                                                            ideaItem.WorkflowStatus ===
                                                            AWAITING_AI_BOT_REVIEW;
                                                        return (
                                                            <tr key={ideaItem.IdeaId}>
                                                                <td className="hidden-xs hidden-sm" style={{ width: "6%" }} >
                                                                    {ideaItem.IdeaIDAlpha
                                                                        ? ideaItem.IdeaIDAlpha
                                                                        : ideaItem.IdeaId}{" "}
                                                                </td>
                                                                <td className="idea_name_overFlow_height">
                                                                    {this.state.approvalPage ? (
                                                                        ideaItem.IdeaName
                                                                    ) : (
                                                                        <a data-toggle="tooltip" title={ideaItem.IdeaName} style={{ color: "#001A30" }}>
                                                                            {ideaItem.IdeaName}
                                                                        </a>
                                                                    )}
                                                                </td>
                                                                <td
                                                                    className="hidden-xs hidden-sm"
                                                                    style={{
                                                                        textAlign: "left",
                                                                        wordWrap: "break-word",
                                                                        maxWidth: "80px",
                                                                    }}
                                                                >
                                                                    {ideaItem.AccountManual === null
                                                                        ? "--"
                                                                        : ideaItem.AccountManual}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign: "left",
                                                                        wordWrap: "break-word",
                                                                        maxWidth: "80px",
                                                                    }}
                                                                >
                                                                    {ideaItem.OwnerOHR}
                                                                </td>
                                                                <td
                                                                    className="hidden-xs hidden-sm"
                                                                    style={{
                                                                        textAlign: "left",
                                                                        wordWrap: "break-word",
                                                                        maxWidth: "80px",
                                                                    }}
                                                                    title={"OHR ID: " + ideaItem.OwnerOHR}
                                                                >
                                                                    {ideaItem.OwnerName === null
                                                                        ? "--"
                                                                        : ideaItem.OwnerName}
                                                                </td>

                                                                <td
                                                                    className="hidden-xs hidden-sm"
                                                                    style={{
                                                                        textAlign: "left",
                                                                        wordWrap: "break-word",
                                                                        maxWidth: "80px",
                                                                    }}
                                                                    title={"OHR ID: " + ideaItem.BlackBeltUserId}
                                                                >
                                                                    {ideaItem.BlackBeltUserName === null
                                                                        ? "--"
                                                                        : ideaItem.BlackBeltUserName}
                                                                </td>
                                                                <td className="hidden-xs hidden-sm">
                                                                    {formattedCreateDate}
                                                                </td>
                                                                <td className="hidden-xs hidden-sm">
                                                                    {IDEA_APPROVED
                                                                        ? APPROVED
                                                                        : IDEA_REJECTED
                                                                            ? REJECTED
                                                                            : IDEA_SEND_BACK
                                                                                ? SENT_BACK
                                                                                : IDEA_REQUEST_CANCELLATION
                                                                                    ? "Cancellation Requested"
                                                                                    : IDEA_CANCELLED
                                                                                        ? "Cancelled"
                                                                                        : IDEA_AI_BOT_REVIEW
                                                                                            ? AWAITING_AI_BOT_REVIEW
                                                                                            : PENDING}
                                                                </td>

                                                                <td className="hidden-xs hidden-sm">
                                                                    {IDEA_PENDING ? "NA" : formattedModifiedDate}
                                                                </td>
                                                                {/* <td className="hidden-xs hidden-sm">
                                                                    {ideaItem.ApprovalStatus}
                                                                </td> */}
                                                                <td
                                                                    className="hidden-xs hidden-sm"
                                                                    style={{ textAlign: "left" }}
                                                                >
                                                                    {ideaItem.CertificationLevelName === null
                                                                        ? "NA"
                                                                        : ideaItem.CertificationLevelName}
                                                                </td>
                                                                <td
                                                                    className="hidden-xs hidden-sm"
                                                                    style={{ textAlign: "left" }}
                                                                >
                                                                    {ideaItem.TransformationLeverName === null
                                                                        ? "NA"
                                                                        : ideaItem.TransformationLeverName}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign: "left",
                                                                        wordWrap: "break-word",
                                                                        maxWidth: "80px",
                                                                    }}
                                                                >
                                                                    {ideaItem.ServiceLineName}
                                                                </td>
                                                                <td style={{ textAlign: 'left', wordWrap: 'break-word', maxWidth:'80px' }}>{ideaItem.Segment}</td>
                                                                <td style={{ textAlign: 'left', wordWrap: 'break-word', maxWidth:'80px' }}>{ideaItem.Unit}</td>

                                                                <td>
                                                                    <div className="row">
                                                                        <button
                                                                            onClick={() =>
                                                                                this.handleReviewIdea1(
                                                                                    ideaItem.IdeaId,
                                                                                    ideaItem.WorkflowStatus,
                                                                                    ideaItem.ApprovalStatus,
                                                                                    ideaItem.MonetizationState
                                                                                )
                                                                            }
                                                                            className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-hover-custom-color-6 u-radius-6 u-btn-2 new-btn-style"
                                                                            style={{
                                                                                minWidth: 60,
                                                                                borderRadius: 0,
                                                                                marginLeft: 25,
                                                                                height: 20,
                                                                            }}
                                                                        >
                                                                            Review
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="100%"> No items found! </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        <div style={{ float: "right" }}>
                                            <div className="col-md-1">
                                                <i
                                                    style={{
                                                        marginTop: 5,
                                                        position: "absolute",
                                                        fontSize: 18,
                                                    }}
                                                    className={
                                                        this.state.isPrevEnabled
                                                            ? `${ANGLE_DOUBLE_LEFT} icon-style`
                                                            : `${ANGLE_DOUBLE_LEFT} fa-disabled`
                                                    }
                                                    onClick={() =>
                                                        this.state.isPrevEnabled
                                                            ? this.handleGetFirstPage()
                                                            : ""
                                                    }
                                                    title="first"
                                                    aria-hidden="true"
                                                ></i>
                                                <i
                                                    style={{
                                                        marginTop: 5,
                                                        position: "absolute",
                                                        fontSize: 18,
                                                        marginLeft: 30,
                                                    }}
                                                    className={
                                                        this.state.isPrevEnabled
                                                            ? `${ANGLE_LEFT} icon-style`
                                                            : `${ANGLE_LEFT} fa-disabled`
                                                    }
                                                    onClick={() =>
                                                        this.state.isPrevEnabled
                                                            ? this.handlePrevAction()
                                                            : ""
                                                    }
                                                    title="previous"
                                                    aria-hidden="true"
                                                ></i>
                                                <i
                                                    style={{
                                                        marginTop: 5,
                                                        position: "absolute",
                                                        fontSize: 18,
                                                        marginLeft: 60,
                                                    }}
                                                    className={
                                                        this.state.isNextEnabled
                                                            ? `${ANGLE_RIGHT} icon-style`
                                                            : `${ANGLE_RIGHT} fa-disabled`
                                                    }
                                                    onClick={() =>
                                                        this.state.isNextEnabled
                                                            ? this.handleNextAction()
                                                            : ""
                                                    }
                                                    title="next"
                                                    aria-hidden="true"
                                                ></i>
                                                <i
                                                    style={{
                                                        marginTop: 5,
                                                        position: "absolute",
                                                        fontSize: 18,
                                                        marginLeft: 90,
                                                        marginRight: 10,
                                                    }}
                                                    className={
                                                        this.state.isNextEnabled
                                                            ? `${ANGLE_DOUBLE_RIGHT} icon-style`
                                                            : `${ANGLE_DOUBLE_RIGHT} fa-disabled`
                                                    }
                                                    onClick={() =>
                                                        this.state.isNextEnabled
                                                            ? this.handleGetLastPage()
                                                            : ""
                                                    }
                                                    title="last"
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                            <div
                                                className="col-md-7"
                                                style={{ marginLeft: 120, marginTop: 5 }}
                                            >
                                                Page {this.state.currentPage} of {this.state.totalPages}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default DBApprovalListPage;
