import React, { Component } from 'react'
import { GET_IDEA_URL, APPROVED, PENDING, UPDATE_IDEA_URL, DISPLAYPROJECTTHEME, SENT_BACK, REVIEW_IDEA_URL, REJECTED, GET_METADATA, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, ACCESS_TOKEN, PROJECT_VIEW, COMPLETED_STATUS, AWAITING_MBB_APPROVAL, AWAITING_LDT_APPROVAL, PPT_CONTENT, SIGNOFF_CONTENT, USER_DATA, REQUEST_CANCELLATION, OTHER_REGIONS, SUBMIT_IDEA_URL, BI_FORM_DATE, AWAITING_QAULITY_CHECK_REVIEW, YESORNO,REPLICABILITYOPTIONS,DB_PEF_DATE,AI_DOC_LINK, AIFIRST} from '../assets/constants/constants';
import Toast from 'light-toast';
import Checksum from "../utils/Checksum";
import Select from 'react-select';
import { selectValidation as selectStyle, textBoxValidation } from "../utils/FieldValidation";
import UpdatedSpeech from './UpdatedSpeech';
import ValueDelivery from '../utils/ValueDelivery';
import FPNAGOL from '../utils/FPNAGOL';
import DisplayTemplate from '../utils/DisplayTemplates';

export class BbMonetizationApproval extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);
    constructor(props) {
        super(props)

        const isApprovalPage = `${this.props.location.approval}`;
        let isViewAllIdea = this.props.location.isViewAllIdea;
        let isViewAllProject = this.props.location.isViewAllProject;
        let propsData = this.props.location;
        this.state = {
            ideaItem: {},
            WorkflowStatus: "",
            Comment: "",
            accountName: "",
            campaignName: "",
            serviceLineName: "",
            dataBridgeCampaign: false,
            xPollinationCampaign: false,
            ideaValidation: false,
            showAdditionalDetails: false,
            showFinalAdditionalDetails: false,
            approvalPage: isApprovalPage,
            isViewAllIdea: isViewAllIdea,
            propsData: propsData,
            isViewAllProject: isViewAllProject,
            reviewComments: [],
            businessImpactMasterList: [],
            businessImpactValueArray: [],
            summaryArray: [],
            fteTotal: 0,
            dollarTotal: 0.0,
            commentLog: false,
            leanCommentLog: false,
            qcCommentLog: false,
            cancelRequest: false,
            selectStyle: {},
            adminUserList: [],
            admin: false,
            adminOHR: '',
            accountList: [],
            regionList: [],
            subRegionList: [],
            countryList: [],
            verticalList: [],
            subVerticalList: [],
            blackbeltList: [],
            mbbList: [],
            ldaList: [],
            campaignList: [],
            dataBridgeProjectThemeList: [],
            digitalProjectTypeList: [],
            digitalProjectTypeSelectedOptions: null,
            certificationLevelsList: [],
            certLevelsSelectedOption: null,
            certificationWorkTypeList: [],
            certWorkTypeSelectedOption: null,
            transformationLeversList: [],
            transSelectedOption: null,
            subCampaignList: [],
            serviceLineList: [],
            subServiceLineList: [],
            serviceLineList: [],
            subServiceLineList: [],
            accountId: 111,
            accountName: "",
            blackBeltUserId: 111,
            blackBeltUserName: "",
            blackBeltUserEmail: "",
            mbbName: "",
            mbbOHR: "",
            lda: "",
            ldaOHR: "",
            campaignTypeId: 111,
            campaignTypeText: "",
            dataBridgeProjectTheme: "",
            subCampaignName: "",
            regionId: 111,
            regionManual: "",
            regionManualId: 111,
            regionText: "",
            regionSelectedOption: null,
            subRegionSelectedOption: null,
            countrySelectedOption: null,
            verticalSelectedOption: null,
            subVerticalSelectedOption: null,
            blackBeltSelectedOption: null,
            mbbSelectedOption: null,
            ldaSelectedOption: null,
            databridgeProjectThemeOption: null,
            campaignSelectedOption: null,
            dataBridgeSelectedOption: null,
            subCampaignSelectedOption: null,
            verticalId: 111,
            verticalManual: "",
            verticalManualId: 111,
            subVerticalManual: "",
            requestForIdea: false,
            subRegion: "",
            country: "",
            serviceLineSelectedOption: null,
            subServiceLineSelectedOption: null,
            workFlowStatusList: [],
            workFlowStatusSelectedOption: null,
            approvalSelectedOption: null,
            LDTLeadSelectedOption: null,
            leadNameList: [],
            methodologyList: [],
            methodologySelectedOptions: null,
            otherTools: "",
            finalComments: '',
            comment: '',
            IsBestInClass: false,
            IsZeroImpactProject: false,
            IsSharedToSixSigma: false,
            digitalProjectEnabled: false,
            workTypeEnabled: false,
            sourceId: "",
            flagBIFTE: true,
            FinalImpactFTE: 0.0,
            FinalImpactDollar: 0.0,
            Role: "",
            finalApproveOptionsValue: [{
                WorkflowStatusId: 1,
                WorkflowStatusName: COMPLETED_STATUS
            },
            {
                WorkflowStatusId: 2,
                WorkflowStatusName: "Rejected"
            },
            {
                WorkflowStatusId: 3,
                WorkflowStatusName: "Send Back"
            },
            ],
            showDigital: false,
            rolesUpdated: false,

            filteredImpactTypeState: [],
            filteredImpactTypeSelectedOption: null,
            filteredMonetizableTypeState: [],
            filteredMonetizableSelectedOption: null,
            filteredBISubCategoryState: [],
            filteredBISubCategorySelectedOption: null,
            ideaCreatedDate: "",
            biFormDate: BI_FORM_DATE,
            showHistoricalBI: false,
            sortedBiImpactMasterList: [],
            zeroImpactForLastestBI: false,
            zeroImpact: true,
            biMasterListAll: [],

            showClientFields: false,
            showGOLFields: false,

            QcComment: "",
            IsVDType: "",
            showBBFieldsValue: "",
            showCheckBox: false,
            signOffCheckbox: "",
            checkBoxFalseSelected: false,
            appropriateAttachmentValue: [],
            appropriateAttachment: false,
            qcComments: [],
            finalReviewComments: [],
            biThreshold: "",
            awaitingQC: false,
            vrconditiontype: "",
            vrMapping: "",
            vrRegionMapping: "",
            vrVerticalSplit: "",
            vrResult: "",
            mbbLimit: 49999,
            approvalStatusState: "",
            increaseInRevenue: [{
                value: 0,
                label: "Increase in Revenue"
            }],
            monetizableValues: [
                {
                    value: 0,
                    label: "Improvement in transaction based billing"
                },
                {
                    value: 1,
                    label: "Increase in billable HC"
                },
                {
                    value: 2,
                    label: "Increase in billable hours"
                },
                {
                    value: 3,
                    label: "Revenue from digital deployment"
                },
                {
                    value: 4,
                    label: "Revenue from special projects"
                },
                {
                    value: 5,
                    label: "Value share from improvement project"
                },
                {
                    value: 6,
                    label: "Fixed Fee"
                },
            ],
            monetizableCheckbox: false,
            postMonetizableOptions: [
                {
                    value: 1,
                    label: "Discussion to be initiated with Client"
                },
                {
                    value: 2,
                    label: "Discussion ongoing with Client"
                },
                {
                    value: 3,
                    label: "Rejected by Client"
                },
                {
                    value: 4,
                    label: "Approved by Client"
                },

            ],
            showClientApprovalFields: false,
            monetizationStatusSelectedOption: null,
            FPnANameMonetization: "",
            FPnAOHRMonetization: "",

            FPnANameMonetizationInput: "",
            FPnAOHRMonetizationInput: "",

            SowID: "",
            SowIDInput: "",

            FPnAOHREmail: "",
            FPnAFileNameMonetization: "",
            FPnAFileTypeMonetization: "",
            FPnAFileMonetization: "",
            MonetizationState: "",
            monetizableOptionChanged: false,
            totalMonetizedFTE: 0,
            totalMonetizedDollar: 0,
            FPnAFileNameMonetizationNew: "",
            FPnAFileTypeMonetizationNew: "",
            FPnAFileMonetizationNew: "",
            golMonetizationName: "",
            golMonetizationOhr: "",
            qcCommentsWithVD: [],
            qcLastComment: "",
            golFpnaFileDetails: [],
            isFpnaRequired: false,
            displayProjectTheme: false,
            clusterData: [],
            isClusterSaveDisabled: true,
            displayParentAlpha: '',
            projectTypeSelectedOption: null,
            projectIDSelectedOption: null,
            ideasDB: [],
            ideasDBDoc: [],
            showHrefModal:false,

            displayDBFields:false,

            campaignList: [],
            campaignInsight:"",


            //AI
            ideasAI: [],
            isIdeaReplicable:"",
        }
        this.isExceedlimit = false;
    }

    async componentDidMount() {
        Toast.loading("Fetching Idea & Metadata...", () => { });
        await this.fetchIdea();
        await this.fetchMetadata();
        Toast.hide();
    }

    formatDateString(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });

    }

    formatDateStringWithTime(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        });
    }




    fetchIdea = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };

            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&RecID=''&isItIdeaOrGenAI='Idea'`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("fetch idea", data)
                    const projectFilename = data.ResultSets.Table1[0].ProjectPPTFileName ? data.ResultSets.Table1[0].ProjectPPTFileName : "";
                    const signOffFilename = data.ResultSets.Table1[0].CustomerSignoffFileName ? data.ResultSets.Table1[0].CustomerSignoffFileName : "";
                    const projectFileType = data.ResultSets.Table1[0].ProjectPPTType ? data.ResultSets.Table1[0].ProjectPPTType : "";
                    const signOffFileType = data.ResultSets.Table1[0].CustomerSignoffType ? data.ResultSets.Table1[0].CustomerSignoffType : "";
                    this.setState({
                        ideaItem: data.ResultSets.Table1[0],
                        WorkflowStatus: data.ResultSets.Table1[0].WorkflowStatus,
                        Comment: data.ResultSets.Table1[0].Comment,
                        showSourceId: data.ResultSets.Table1[0].TransformationLeverName === "Digital" ? true : false,
                        projectDeckFilename: projectFilename,
                        signOffFilename: signOffFilename,
                        projectFileType: projectFileType,
                        signOffFileType: signOffFileType,
                        reviewComments: data.ReviewComments.Table1,
                        finalReviewComments: data.FinalReviewComments.Table1,
                        sourceId: data.ResultSets.Table1[0].SourceId,
                        campaignName: data.ResultSets.Table1[0].CampaignName,
                        dataBridgeCampaign: (data.ResultSets.Table1[0].CampaignName === DATA_BRIDGE_CAMPAIGN),
                        xPollinationCampaign: (data.ResultSets.Table1[0].CampaignName === X_POLLINATION_CAMPAIGN),
                        businessImpactValueArray: data.BIData ? (data.BIData.Table1 ? (data.BIData.Table1.length > 0 ? data.BIData.Table1 : []) : []) : [],
                        ideaCreatedDate: data.ResultSets.Table1[0].CreatedDate,
                        FinalImpactFTE: this.state.finalImpactFTE ? this.state.finalImpactFTE : data.ResultSets.Table1[0].FinalImpactFTE,
                        FinalImpactDollar: this.state.finalImpactDollar ? this.state.finalImpactDollar : data.ResultSets.Table1[0].FinalImpactDollar,

                        clientName: data.ResultSets.Table1[0].CustName,
                        clientRole: data.ResultSets.Table1[0].CustDesgn,
                        clientSignOffDate: new Date(data.ResultSets.Table1[0].CustSignOffDate).toISOString().split("T")[0],
                        clientApprovalEmailSelectedOption: data.ResultSets.Table1[0].IsClientApprovalAttached,


                        golOhr: data.ResultSets.Table1[0].GOLOHR,

                        golMonetizationOhr: data.ResultSets.Table1[0].GOLOHRMonetization ? data.ResultSets.Table1[0].GOLOHRMonetization : data.ResultSets.Table1[0].GOLOHR,
                        golMonetizationName: data.ResultSets.Table1[0].GOLNameMonetization ? data.ResultSets.Table1[0].GOLNameMonetization : data.ResultSets.Table1[0].GOLName,
                        fpnaOhr: data.ResultSets.Table1[0].FPnAOHR,

                        golFullname: data.ResultSets.Table1[0].GOLName,
                        fpnaName: data.ResultSets.Table1[0].FPnAName,
                        FPnAOHRMonetization: data.ResultSets.Table1[0].FPnAOHRMonetization ? data.ResultSets.Table1[0].FPnAOHRMonetization : data.ResultSets.Table1[0].FPnAOHR,
                        FPnANameMonetization: data.ResultSets.Table1[0].FPnANameMonetization ? data.ResultSets.Table1[0].FPnANameMonetization : data.ResultSets.Table1[0].FPnAName,

                        golFilename: data.ResultSets.Table1[0].GOLFileName,
                        golFileType: data.ResultSets.Table1[0].GOLFileType,

                        fpnaFilename: data.ResultSets.Table1[0].FPnAFileName,
                        fpnaFileType: data.ResultSets.Table1[0].FPnAFileType,
                        IsVDType: data.ResultSets.Table1[0].VDType,
                        qcComments: data.QcComments.Table1 ? data.QcComments.Table1 : [],
                        qcCommentsWithVD: data.QcComments.Table2 ? data.QcComments.Table2 : [],

                        monetizationStatusSelectedOption: data.ResultSets.Table1[0].MonetizationStatus,

                        SowID: data.ResultSets.Table1[0].SowID ? data.ResultSets.Table1[0].SowID : "",
                        // FPnAOHRMonetization:data.ResultSets.Table1[0].FPnAOHRMonetization?data.ResultSets.Table1[0].FPnAOHRMonetization:"",
                        //FPnANameMonetization:data.ResultSets.Table1[0].FPnANameMonetization?data.ResultSets.Table1[0].FPnANameMonetization:"",
                        FPnAFileNameMonetization: data.ResultSets.Table1[0].FPnAFileNameMonetization ? data.ResultSets.Table1[0].FPnAFileNameMonetization : "",
                        clusterData: data.ClusterData.Table1 ? data.ClusterData.Table1 : [],
                        // DB
                        ideasDB: data.ResultSets.Table3 && data.ResultSets.Table3[0].Result === "NULL" ? [] : data.ResultSets.Table3[0],
                        ideasDBDoc: data.ResultSets.Table4 && data.ResultSets.Table4[0].Result === "NULL" ? [] : data.ResultSets.Table4[0],

                        ideasAI: data.ResultSets.Table2 ? data.ResultSets.Table2[0] : [],
                    }, () => {

                        if (this.state.ideaItem.CreatedDate) {
                            let db_idea_created = new Date(this.state.ideaItem.CreatedDate);
                            let abc = new Date(DB_PEF_DATE);
                            if (db_idea_created.getTime() < abc.getTime()) {
                              this.setState({displayDBFields:false},()=>{})
                              console.log("created date is lesser")
                            } else {
                              this.setState({displayDBFields:true},()=>{})
                              console.log("created date is greater")
                            }
                        }


                        

                        console.log("replicability index =>",this.state.ideaItem.ReplicabilityIndex)
                        if(this.state.ideaItem.ReplicabilityIndex){
                            REPLICABILITYOPTIONS.map((item)=>{
                                console.log(item)
                                if(item.value === this.state.ideaItem.ReplicabilityIndex){
                                    this.setState({replicabilityIndex:item.label},()=>{
                                        console.log("replicability index selected option is=>",this.state.replicabilityIndex)
                                    })
                                }
                            })
                        }


                        if (this.state.clusterData && this.state.clusterData.length > 0) {
                            const targetRecID = this.state.ideaItem.LeanID;
                            const filteredData = this.state.clusterData.filter(item => item.RecAlpha === targetRecID);
                            console.log(filteredData)
                            if (filteredData && filteredData.length > 0 && filteredData !== undefined && filteredData !== null) {
                                this.setState({ displayParentAlpha: filteredData[0].ParentAlpha }, () => { })
                                let options = [];
                                options.push({ value: 0, label: filteredData[0].ParentAlpha })
                                this.setState({ projectIDSelectedOption: options }, () => { })

                                let options1 = [];
                                options1.push({ value: 0, label: "GB" })
                                this.setState({ projectTypeSelectedOption: options1 }, () => { })
                            } else {
                                this.setState({ displayParentAlpha: "" }, () => { })
                            }
                        } else {
                            this.setState({ displayParentAlpha: "" }, () => { })
                        }


                        if (this.state.ideaItem.CreatedDate) {
                            let created = new Date(this.state.ideaItem.CreatedDate);
                            let abc = new Date(DISPLAYPROJECTTHEME);
                            if (created.getTime() < abc.getTime()) {
                                //console.log("created date is lesser")
                                this.setState({ displayProjectTheme: true })
                            } else {
                                this.setState({ displayProjectTheme: false })
                                //console.log("created date is greater ")
                            }
                        }
                        this.state.postMonetizableOptions.map(item => {
                            if (item.label === this.state.monetizationStatusSelectedOption) {
                                this.setState({ monetizationStatusSelectedOption: item }, () => { })
                            }
                        })
                        this.state.businessImpactValueArray = this.state.businessImpactValueArray.map(item => ({
                            ...item,
                            pg_srno: item.SrNo,
                            dollarValue: item.DollarImpact,
                            fteValue: item.FTEImpact
                        })).map(item => {
                            return item;

                        });
                        let sortedBiImpactvalueArray = this.state.businessImpactValueArray.sort((a, b) => a.ImpactType.toLowerCase().localeCompare(b.ImpactType.toLowerCase()))
                        this.setState({ businessImpactValueArray: sortedBiImpactvalueArray })
                        if (this.state.IsVDType === "Value Delivered to Client + Genpact") {
                            this.setState({ showClientFields: true, showGOLFields: true }, () => { })
                        }
                        if (this.state.IsVDType === "No Value Delivery") {
                            this.setState({ showClientFields: false, showGOLFields: false, showBIForm: false }, () => { })
                        }
                        if (this.state.IsVDType === "Value Delivered to Client") {
                            this.setState({ showClientFields: true, showGOLFields: false }, () => { })
                        }
                        if (this.state.IsVDType === "Value Delivered to Genpact") {
                            this.setState({ showClientFields: false, showGOLFields: true }, () => { })
                        }

                        let myArray = [...this.state.businessImpactValueArray];
                        const groupedData = myArray.reduce((accumulator, currentValue) => {
                            const ImpactType = currentValue.ImpactType;
                            const MonetizableType = currentValue.MonetizableType;
                            const FTEImpact = parseFloat(currentValue.FTEImpact) || 0.0;
                            const DollarImpact = parseFloat(currentValue.DollarImpact) || 0.0;

                            const existingRow = accumulator.find(row => row.ImpactType === ImpactType && row.MonetizableType === MonetizableType);
                            if (existingRow) {
                                existingRow.FTEImpact += FTEImpact
                                existingRow.DollarImpact += DollarImpact
                            } else {
                                accumulator.push({ ImpactType, MonetizableType, FTEImpact, DollarImpact });
                            }

                            return accumulator;
                        }, []);


                        let sortedSummaryIndex = groupedData.sort((a, b) => a.ImpactType.toLowerCase().localeCompare(b.ImpactType.toLowerCase()))
                        this.setState({ summaryArray: sortedSummaryIndex }, () => { });


                        if (this.state.ideaItem.MonetizationStatus === "Approved by Client") {
                            this.setState({ showClientApprovalFields: true }, () => { })
                        } else {
                            this.setState({ showClientApprovalFields: false }, () => { })
                        }


                        console.log(this.state.qcComments)
                        if (this.state.qcComments.length > 0) {
                            var keys = Object.keys(this.state.qcComments);
                            var last = keys[keys.length - 1];
                            let varQCComment = this.state.qcComments[last].Comment;
                            this.setState({ qcLastComment: varQCComment }, () => {

                            })
                        }


                        if (this.state.ideasAI) {
                            if(this.state.ideasAI.IsIdeaReplicable ){
                                this.setState({ isIdeaReplicable: "Yes" }, () => { })
                            }else{
                                this.setState({ isIdeaReplicable: "No" }, () => { })
                            }
                        }
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching idea. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }

    fetchMetadata = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "",
                EntityName: ""
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("meta data ", data)
                    this.setState({
                        businessImpactMasterList: data ? data.BIMasterDataVerseNew : [],
                        biMasterListAll: data ? data.BIMasterDataVerse : [],
                        adminUserList: data ? data.DataverseUserRole : [],
                        accountList: data ? data.Accounts.sort((a, b) => { return a.ACCOUNT > b.ACCOUNT ? 1 : -1; }) : [],
                        campaignList: data ? data.Campaigns : [],
                        serviceLineList: data ? data.ServiceLines : [],
                        subServiceLineList: data ? data.ServiceLines : [],
                        countryList: data ? data.Countries.sort((a, b) => { return a.COUNTRY > b.COUNTRY ? 1 : -1; }) : [],
                        dataBridgeProjectThemeList: data ? data.DataBridgeThemes : [],
                        subCampaignList: data ? data.SubCampaigns : [],
                        workFlowStatusList: data ? data.WorkflowStatus : [],
                        certificationLevelsList: data ? data.CertificationLevels : [],
                        certificationWorkTypeList: data ? data.WorkType : [],
                        transformationLeversList: data ? data.TransformationLevers : [],
                        methodologyList: data ? data.Methodologies : [],
                        digitalProjectTypeList: data ? data.DigitalProjectTypes : [],
                        biThreshold: data.BIQCThreshold ? data.BIQCThreshold[0] ? data.BIQCThreshold[0].pg_threshold : 0 : 0,
                        vrconditiontype: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_vrconditiontype : [] : [],
                        vrVerticalMapping: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_vertical ? data.BIQCVRMapping[0].pg_vertical.split("|") : "ALL" : "ALL" : "ALL",
                        vrRegionMapping: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_region ? data.BIQCVRMapping[0].pg_region.split("|") : "ALL" : "ALL" : "ALL",
                        
                    }, () => {
                        this.state.adminUserList.map((item) => {
                            if (parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
                                this.setState({ admin: true, adminOHR: parseInt(item.pg_ohr) });
                                return false;
                            }
                            return false;
                        });

                        if(this.state.ideaItem.CampaignName && this.state.campaignList){
                            this.state.campaignList.map((campaign)=>{
                              if(campaign.CampaignName === this.state.ideaItem.CampaignName){
                                this.setState({campaignInsight:campaign.CampaignDescr},()=>{})
                              }
                            })
                           
                        }
                        let filteredImpactType = this.state.businessImpactMasterList.map(item => item.pg_impacttype).filter(
                            (value, index, self) => self.indexOf(value) === index
                        )
                        filteredImpactType = Object.entries(filteredImpactType)
                        this.setState({ filteredImpactTypeState: filteredImpactType }, () => { })

                        let filtereedMonetizabletype = this.state.businessImpactMasterList.map(item => item.pg_monetizabletype).filter(
                            (value, index, self) => self.indexOf(value) === index
                        )
                        filtereedMonetizabletype = Object.entries(filtereedMonetizabletype)
                        this.setState({ filteredMonetizableTypeState: filtereedMonetizabletype }, () => { })

                        let filteredBiSubCategory = this.state.businessImpactMasterList.map(item => item.pg_bisubcategory).filter(
                            (value, index, self) => self.indexOf(value) === index
                        )
                        filteredBiSubCategory = Object.entries(filteredBiSubCategory)
                        this.setState({ filteredBISubCategoryState: filteredBiSubCategory }, () => { })
                        resolve();
                    })
                })
                .catch(error => {
                    Toast.fail('Error occured while getting metadata idea. Please try again!', 3000, () => {
                        console.log(JSON.stringify(error));
                        this.setState({
                            businessImpactMasterList: [],
                            serviceLineList: [],
                            subServiceLineList: [],
                        }, () => {
                            resolve();
                        })
                    });

                });
        });
    }
    saveEvent = () => {
        let redirectURL = "/review-page";
        if (this.state.approvalPage) {
            if (this.state.isViewAllProject) {
                redirectURL = "/view-all-projects-page";
                this.props.history.push({
                    pathname: redirectURL,
                    propsList: this.state.propsData
                })
            }
            else if (this.state.isViewAllIdea) {
                redirectURL = "/view-all-ideas-page";
                this.props.history.push({
                    pathname: redirectURL,
                    propsList: this.state.propsData
                })
            }
            else if (this.state.approvalPage === PROJECT_VIEW) {
                redirectURL = "/list-projects-page";
                this.props.history.push({
                    pathname: redirectURL,
                    propsList: this.state.propsData
                })
            } else {
                this.props.history.push({
                    pathname: redirectURL,
                    search: '?page=' + this.state.approvalPage,
                    propsList: this.state.propsData
                })
            }
        }
        else {
            this.props.history.push({
                pathname: redirectURL,
                propsList: this.state.propsData
            })
        }
    }

    showHideDiv = () => {
        let ideaItem = this.state.ideaItem;
        ideaItem.NewOrReplicatedIdea = !ideaItem.NewOrReplicatedIdea;
        this.setState({
            ideaItem
        }, () => { });
    }

    showHideBestInClass = () => {
        let ideaItem = this.state.ideaItem;
        ideaItem.IsBestInClass = !ideaItem.IsBestInClass;
        this.setState({
            ideaItem
        });
    }

    showHideZeroImpactProject = (event) => {
        this.setState({
            dollarTotal: 0.0,
            fteTotal: 0.0,
            zeroImpact: !this.state.zeroImpact,
            summaryArray: [],
            businessImpactValueArray: [],
            duplicateBIValueArray: [],
            zeroImpactForLastestBI: true,
            FinalImpactDollar: 0.0,
            FinalImpactFTE: 0.0
        }, () => { });
    }

    showHideIsSharedToSixSigma = () => {
        let ideaItem = this.state.ideaItem;
        ideaItem.IsSharedToSixSigma = !ideaItem.IsSharedToSixSigma;
        this.setState({
            ideaItem
        });
    }



    renderApprovalStatusOptions = () => {
        let options = [];
        this.state.finalApproveOptionsValue.map((item) => {
            if (this.state.ideaItem.ApprovalStatus === 'Rejected') {
                if (item.WorkflowStatusName !== 'Rejected') {
                    options.push({
                        value: item.WorkflowStatusId,
                        label: item.WorkflowStatusName
                    })
                }
            }
            else {
                options.push({
                    value: item.WorkflowStatusId,
                    label: item.WorkflowStatusName
                })
            }
            return options;
        });
        return options;
    }


    showAdditionalDetails = (e) => {
        if (e.value === 1) {
            this.setState({
                showAdditionalDetails: true
            });
        }
        else {
            this.setState({
                showAdditionalDetails: false
            });
        }
        this.setState({
            workFlowStatusSelectedOption: {
                value: e.value,
                label: e.label
            }
        });
    }

    cancelEvent = () => {
        this.props.history.push("/view-all-ideas-page");
    }

    validate = () => {

        let isValid = true;
        let styleStateObj = this.state.selectStyle;
        this.isExceedlimit = false;

        if (this.state.QcComment === "") {
            isValid = false;
            styleStateObj.QcComment = { ...textBoxValidation(false), border: "1px solid red" };
            this.setState({ selectStyle: styleStateObj })
        }
        else {
            if (this.state.QcComment.length > 500) {
                Toast.fail("Comment cannot be more than 500 characters, please change and resubmit.", 3000);
                isValid = false;
                this.isExceedlimit = true;
                styleStateObj.QcComment = textBoxValidation(false);
                this.setState({ selectStyle: styleStateObj })
            }
            else {
                styleStateObj.QcComment = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                this.setState({
                    selectStyle: styleStateObj
                })
            }
        }

        if (this.state.businessImpactValueArray.length === 0) {
            Toast.fail("Value Delivery cannot be empty, please fill and resubmit.", 3000, () => { });
            isValid = false;
        }

        if (this.state.businessImpactValueArray.length > 0) {
            let element, currFte, currDollar, multiplier;
            for (let checkIndex = 0; checkIndex < this.state.businessImpactValueArray.length; checkIndex++) {

                element = this.state.businessImpactValueArray[checkIndex];
                if (element && element.isFTEPresent) {
                    currFte = element ? element.fteValue ? element.fteValue : 0.0 : 0.0;
                    currDollar = element ? element.dollarValue ? element.dollarValue : 0.0 : 0.0;
                    if (currFte > 0 || currDollar > 0) {
                        multiplier = currFte * currDollar;
                        if (multiplier === 0) {
                            Toast.fail("Impact FTE & Impact Dollar, both need to be set. Please check and resubmit", 3000, () => { });
                            isValid = false;
                            break;
                        }
                    }
                }
            }

            if ((this.state.FinalImpactDollar === null || parseFloat(this.state.FinalImpactDollar) === 0.0)) {
                Toast.fail("Total Dollar Impact cannot be zero. Please check and resubmit.", 3000, () => { });
                isValid = false;
            }

            if (this.state.FinalImpactFTE > 0 && this.state.FinalImpactDollar <= 0) {
                Toast.fail("Total Impact Dollar cannot be zero when FTE is non-zero. Please check and resubmit.", 3000, () => { });
                isValid = false;
            }
            this.state.businessImpactValueArray.filter((item) => {
                console.log(item)
                if (parseFloat(item.DollarImpact) === 0 || parseFloat(item.DollarImpact) === "" || !item.DollarImpact || item.DollarImpact === null) {
                    isValid = false;
                    Toast.fail("Dollar Impact value cannot be zero.", 3000, () => { })
                }
            });

        }

        if (this.state.appropriateAttachment) {
            if (this.state.fpnaOhr === "" || this.state.fpnaOhr === null || this.state.fpnaOhr === undefined || this.state.fpnaOhr.length === 0 || !this.state.fpnaOhr) {
                isValid = false;
                styleStateObj.fpnaOhr = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else {
                styleStateObj.fpnaOhr = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            if (this.state.appropriateAttachment === true && (this.state.fpnaName === "" || this.state.fpnaName === null || this.state.fpnaName === undefined || this.state.fpnaName.length === 0 || !this.state.fpnaName)) {
                isValid = false;
                styleStateObj.fpnaName = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else {
                styleStateObj.fpnaName = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
        }

        if (this.state.monetizationStatusSelectedOption && this.state.monetizationStatusSelectedOption.label === "Approved by Client") {
            if (this.state.FPnAOHRMonetization === null || this.state.FPnAOHRMonetization === "") {
                isValid = false;
                Toast.fail("Please fill up FPnA OHR Monetization for Monetization", 3000, () => { })
            }

            if (this.state.SowID === "" || this.state.SowID === null || this.state.SowID === undefined || this.state.SowID.length === 0) {
                isValid = false;
                Toast.fail("Please fill SOW ID/SFDC/OLI ID for monetization.", 3000, () => { })
            }
            if (this.state.FPnANameMonetization === "" || this.state.FPnANameMonetization === null) {
                isValid = false;
                Toast.fail("Please fill up FPNA OHR to fetch FPNA Name for Monetization", 3000, () => { })
            }
            if (this.state.golMonetizationName === "" || this.state.golMonetizationName === null) {
                isValid = false;
                Toast.fail("Please fill up GOL OHR for Monetization", 3000, () => { })
            }
            if (this.state.golMonetizationOhr === "" || this.state.golMonetizationOhr === null) {
                isValid = false;
                Toast.fail("Please fill up GOL OHR to fetch FPNA Name for Monetization", 3000, () => { })
            }


            if (this.state.monetizationStatusSelectedOption) {
                if (this.state.monetizationStatusSelectedOption.label === "Approved by Client") {
                    if (this.state.FPnAFileNameMonetization === "" || this.state.FPnAFileNameMonetization === null) {
                        isValid = false;
                        Toast.fail("Please upload FP&A Sign-off document for monetization", 3000, () => { })
                    }
                }
            }


        }
        console.log("monetization Status Selected Option=>",this.state.monetizationStatusSelectedOption)
        if (this.state.monetizationStatusSelectedOption && this.state.monetizationStatusSelectedOption.label === "Approved by Client") {
            if ((this.state.businessImpactValueArray.filter(item => item.IsMonetized === "Yes")).length > 0) { }
            else {
                isValid = false;
                Toast.fail("Since this is a monetizable project, there needs to be at least one impact type as ‘Impact at Genpact’ selected and category selected as a ‘Increase in Revenue’ .", 5000, () => { })
            }
        }

        this.FinalApproverCheck()
        return isValid;
    }
    newBusinessImpactSummary() {
        let myArray = this.state.businessImpactValueArray;
        const groupedData = myArray.reduce((accumulator, currentValue) => {
            const ImpactType = currentValue.ImpactType;
            const MonetizableType = currentValue.MonetizableType;
            const FTEImpact = parseFloat(currentValue.FTEImpact) || 0.0;
            const DollarImpact = parseFloat(currentValue.DollarImpact) || 0.0;

            const existingRow = accumulator.find(row => row.ImpactType === ImpactType && row.MonetizableType === MonetizableType);
            if (existingRow) {
                existingRow.FTEImpact += FTEImpact
                existingRow.DollarImpact += DollarImpact
            }
            else {
                accumulator.push({ ImpactType, MonetizableType, FTEImpact, DollarImpact });
            }
            return accumulator;
        }, []);
        this.setState({ summaryArray: groupedData }, () => { });
        this.setState({ businessImpactValueArray: this.state.businessImpactValueArray }, () => { })
        this.setState({ isFpnaRequired: false }, () => { })
        this.state.businessImpactValueArray && this.state.businessImpactValueArray.map((item) => {
            if (item.ImpactType === "Impact at Genpact") {
                if (item.MonetizableType === "Increase in Revenue" || item.MonetizableType === "Reduction in Cost") {
                    this.setState({ isFpnaRequired: true }, () => { })
                }
            }
        })
    }

    FinalApproverCheck() {
        let Approver = "Active";
        console.log("monetization status selected option => ",this.state.monetizationStatusSelectedOption )
        if (this.state.monetizationStatusSelectedOption && this.state.monetizationStatusSelectedOption.label === "Approved by Client") {
            Approver = AWAITING_MBB_APPROVAL
        }
        this.state.MonetizationState = Approver
        this.setState({
            MonetizationState: Approver
        }, () => { console.log(this.state.MonetizationState) })
    }

    updateIdea = (event) => {
        let param = {
            IdeaId: this.props.location.state,
            ModifiedByOHR: this.sessionData.profile.oHRId,
            Role: "Mentor",
            UpdaterRole: "Mentor",
            FPnAFileNameMonetization: this.state.FPnAFileNameMonetizationNew ? this.state.FPnAFileNameMonetizationNew : this.state.FPnAFileNameMonetization,
            FPnAFileTypeMonetization: this.state.FPnAFileTypeMonetizationNew ? this.state.FPnAFileTypeMonetizationNew : this.state.FPnAFileTypeMonetization,
            FPnAFileMonetization: this.state.FPnAFileMonetizationNew ? this.state.FPnAFileMonetizationNew : this.state.FPnAFileMonetization,
        }
        console.log(param)
        let finalJsonData = Checksum(param);
        let str1 = finalJsonData.slice(0, 15);
        let str2 = finalJsonData.slice(15, 30);
        let str3 = finalJsonData.slice(30, 40);
        let str4 = finalJsonData.slice(-10);
        let finalData = str3 + str1 + str4 + str2;

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(`${UPDATE_IDEA_URL}Checksum=${finalData}&monetizationdocumentsonly=true`, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("upload document succesfull", data)
            });
    }

    submitIdea = () => {
        if (this.validate()) {
            if (window.confirm("Are you sure you want to update the project?")) {
                this.updateIdea();
                Toast.loading("Updating lean project...", () => { });

                this.state.businessImpactValueArray = this.state.businessImpactValueArray.map(item => ({
                    ...item,
                    dollarValue: item.DollarImpact,
                    fteValue: item.FTEImpact
                })).map(item => {
                    return item;

                });
                let filteredBIData = this.state.businessImpactValueArray.filter(function (e) {
                    return (e !== null && e !== undefined && parseFloat(e.DollarImpact) > 0 &&
                        ((e.isFTEPresent && parseFloat(e.FTEImpact) > 0) || (!e.isFTEPresent)));
                });

                let param = {};
                param = {
                    IdeaName: this.state.ideaItem.IdeaName,
                    CertificationLevelId: this.state.ideaItem.CertificationLevelId,
                    CertificationWorkTypeName: this.state.ideaItem.CertificationWorkTypeName,
                    CertificationLevelName: this.state.ideaItem.CertificationLevelName,
                    DigitalProjectType: this.state.ideaItem.DigitalProjectType,
                    IsDigital: this.state.ideaItem.IsDigital,
                    IsReplicationVerified: this.state.ideaItem.IsReplicationVerified,
                    LDTId: this.state.ideaItem.LDTId,
                    LDTName: this.state.ideaItem.LDTLeadName,
                    MethodologyTypeID: this.state.ideaItem.MethodologyTypeID,
                    MethodologyName: this.state.ideaItem.MethodologyName,
                    PotentialImpactDollar: this.state.ideaItem.PotentialImpactDollar,
                    PotentialImpactFTE: this.state.ideaItem.PotentialImpactFTE,
                    Problem: this.state.ideaItem.Problem,
                    Solution: this.state.ideaItem.Solution,
                    ServiceLineId: this.state.ideaItem.ServiceLineId,
                    ServiceLineName: this.state.ideaItem.ServiceLineName,
                    SubServiceLine: this.state.ideaItem.SubServiceLine,
                    NewOrReplicatedIdea: this.state.ideaItem.NewOrReplicatedIdea,
                    ReplicationProjectId: this.state.ideaItem.ReplicationProjectId,
                    TransformationLeverId: this.state.ideaItem.TransformationLeverId,
                    TransformationLeverName: this.state.ideaItem.TransformationLeverName,
                    UpdaterOHR: this.sessionData.profile.oHRId,
                    UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                    UpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                    WorkflowStatus: this.state.approvalSelectedOption !== null ? this.state.approvalSelectedOption.label : "",
                    ApprovalStatus: COMPLETED_STATUS,
                    FinalComments: this.state.QcComment,
                    IdeaId:this.state.ideaItem.IdeaId,
                    IsBestInClass: this.state.ideaItem.IsBestInClass,
                    SourceId: this.state.sourceId,
                    IsSourceInValidated: true,
                    FinalImpactDollar: this.state.FinalImpactDollar ? this.state.FinalImpactDollar : null,
                    FinalImpactFTE: this.state.FinalImpactFTE ? this.state.FinalImpactFTE : null,
                    TypeOfImpactName: this.state.showFinalAdditionalDetails ? (this.state.typeOfImpactSelectedOption ? this.state.typeOfImpactSelectedOption.label : null) : null,
                    BusinessImpactName: this.state.showFinalAdditionalDetails ? (this.state.businessImpactSelectedOption ? this.state.businessImpactSelectedOption.label : null) : null,
                    BusinessImpactSubName: this.state.showFinalAdditionalDetails ? (this.state.businessSubCatSelectedOption ? this.state.businessSubCatSelectedOption.label : null) : null,
                    FinalTransformationLeverName: this.state.showFinalAdditionalDetails ? (this.state.finalTransSelectedOption ? this.state.finalTransSelectedOption.label : null) : null,
                    FinalOtherTools: this.state.showFinalAdditionalDetails ? this.state.otherTools : null,
                    IsZeroImpactProject: this.state.ideaItem.IsZeroImpactProject,
                    DataBridgeTheme: this.state.ideaItem.DataBridgeTheme,
                    SubCampaignName: this.state.ideaItem.SubCampaignName,
                    BusinessImpactData: filteredBIData,
                    CampaignName: this.state.ideaItem.CampaignName,
                    Comment: this.state.QcComment,
                    ReplicabilityIndex: this.state.ideaItem.ReplicabilityIndex,
                    CompletedDate: new Date().toLocaleString(),
                    IsVDReviewed: this.state.ideaItem.IsVDReviewed,
                    FpnaApprovalAttachedSelectedOption: this.state.ideaItem.IsFPnAApprovalAttached,
                    FPnAOHR: this.state.fpnaOhr ? this.state.fpnaOhr : this.state.ideaItem.FPnAOHR,
                    FPnAName: this.state.fpnaName ? this.state.fpnaName : this.state.ideaItem.FPnAName,
                    Monetizable: this.state.monetizableMBBApproval ? "Yes" : "No",
                    // QcOHR: this.sessionData.profile.oHRId,
                    // QcEmail: this.sessionData.profile.oHRId + "@genpact.com",
                    // QcUpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,

                    QcOHR: this.state.ideaItem.QCOHR,
                    QcEmail: this.state.ideaItem.QCOHR + "@genpact.com",
                    QcUpdaterName: this.state.ideaItem.QCName,

                    Role: "Mentor",
                    UpdaterRole: "Mentor",
                    IsFPnAApprovalAttached: this.state.ideaItem.IsFPnAApprovalAttached,

                    ImpactTyepChanged: this.state.ideaItem.IsVDImpactTagged,
                    DeliveryCategorizedSelect: this.state.ideaItem.IsVDCategorized,
                    DeliveryComputedCorrectlySelected: this.state.ideaItem.IsVDComputed,
                    SignOffDeliverySelected: this.state.ideaItem.IsVDClientSignOff,
                    IsVDReviewed: this.state.ideaItem.IsVDReviewed,

                    FPnAOHRMonetization: this.state.FPnAOHRMonetization ? this.state.FPnAOHRMonetization : this.state.ideaItem.FPnAOHR,
                    FPnANameMonetization: this.state.FPnANameMonetization ? this.state.FPnANameMonetization : this.state.ideaItem.FPnAName,
                    SowID: this.state.SowIDInput ? this.state.SowIDInput : this.state.SowID,

                    IsVDMonetizable: this.state.ideaItem.IsVDMonetizable ? this.state.ideaItem.IsVDMonetizable : "",
                    MonetizationStatus: this.state.monetizationStatusSelectedOption ? this.state.monetizationStatusSelectedOption.label : "",
                    IsMbbMonetizable: this.state.ideaItem.IsMbbMonetizable ? this.state.ideaItem.IsMbbMonetizable : "",
                    case: "new",
                    casetype: "BBMonetization",
                    MonetizationState: this.state.MonetizationState,

                    GOLNameMonetization: this.state.golMonetizationName ? this.state.golMonetizationName : this.state.ideaItem.GOLName,
                    GOLOHRMonetization: this.state.golMonetizationOhr ? this.state.golMonetizationOhr : this.state.ideaItem.GOLOHR,
                    updaterAction: "Approved",

                }


                console.log(param)
                let finalJsonData = Checksum(param);
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization":  "Bearer " + this.accessToken,
                    },
                    body: JSON.stringify(param)
                };
                fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        Toast.hide();
                        Toast.success('Updated successfully.', 3000, () => {
                            console.log(JSON.stringify(data));
                            // window.location = "/review-page?page=approval";
                            let redirectURL = "/review-page";
                            if (this.state.approvalPage) {
                                redirectURL = redirectURL + "?page=" + this.state.approvalPage;
                            }
                            window.location = redirectURL;
                        });

                    });
            }
        } else {
            if (!this.isExceedlimit) {
                Toast.fail('Required fields are missing or have invalid values. Please correct and try again.', 3000, () => { });
            }
        }
        return false;
    }
    handleCommentChange = (e) => {
        if (e !== null && e !== undefined) {
            this.setState({ QcComment: e }, () => { })
            let styleStateObj = this.state.selectStyle;
            styleStateObj.QcComment = textBoxValidation(true);
            this.setState({ selectStyle: styleStateObj })
        }

    }

    showBBFields = (e) => {
        if (e !== null && e !== undefined) {
            this.setState({ showBBFieldsValue: e }, () => {
                if (this.state.showBBFieldsValue.label === "No") {
                    this.setState({ signOffCheckbox: false, appropriateAttachmentValue: false })
                }
                if (this.state.showBBFieldsValue.label === "Yes") {
                    this.setState({ showCheckBox: true })
                }
            })
        }
    }
    handleCheckBoxChange = (e) => {
        if (e.target.checked) {
            this.setState({ checkBoxFalseSelected: true }, () => { })
        } else {
            this.setState({ checkBoxFalseSelected: false }, () => { })
        }
    }
    fpnaAttachmentChange = (e) => {
        if (e !== null && e !== undefined) {
            this.setState({ appropriateAttachmentValue: e }, () => { })
        }
    }
    handleTranscriptFinalApprovalComment = (e) => {
        setTimeout(() => {
            if (e !== null && e !== undefined) {
                let finalComment = e;
                this.setState({
                    QcComment: finalComment
                });
            }
        }, 1000)
    }
    toggleTranscriptFunction = (id) => {
        this.setState({ speechModal: !this.state.speechModal, modalIndex: id })
        if (id === 15) {
            if (this.state.QcComment) {
                this.setState({ previousTextForSpeech: this.state.QcComment }, () => console.log(this.state.previousTextForSpeech))
            } else {
                this.setState({ previousTextForSpeech: "" }, () => console.log(this.state.previousTextForSpeech))
            }
        }
    }

    closeModalFunction = () => {
        this.setState({ speechModal: false })
    }

    showCommentModal = (modalId) => {
        if (modalId === 1) {
            this.setState({ commentLog: !this.state.commentLog })
        }
        if (modalId === 2) {
            this.setState({ leanCommentLog: !this.state.leanCommentLog })
        }
        if (modalId === 3) {
            this.setState({ qcCommentLog: !this.state.qcCommentLog })
        }

    }


    pull_data = ({ businessImpactValueArray }) => {
        // console.log(businessImpactValueArray)
        this.setState({ businessImpactValueArray: businessImpactValueArray }, () => {
            this.newBusinessImpactSummary()
        })
        let dollarTotal = 0.0;
        let fteValue = 0.0;
        this.state.businessImpactValueArray.map((item) => {
            if (item && item.DollarImpact) {
                dollarTotal += parseFloat(item.DollarImpact);
            }
            if (item && item.dollarValue) {
                dollarTotal += parseFloat(item.dollarValue);
            }
            if (item && item.FTEImpact) {
                fteValue += parseFloat(item.FTEImpact)
            }
            if (item && item.fteValue) {
                fteValue += parseFloat(item.fteValue)
            }
        })
        this.setState({ finalImpactDollar: dollarTotal }, () => { })
        this.setState({ finalImpactFTE: fteValue }, () => { })
    }
    remove_bi_data_after_delete = (filteredIdeaArray) => {
        console.log("bi recieved in pull data for delete function", filteredIdeaArray)
        if (filteredIdeaArray) {
            this.setState({ businessImpactValueArray: filteredIdeaArray }, () => {
                this.newBusinessImpactSummary()
                let dollarTotal = 0.0;
                let fteValue = 0.0;
                this.state.businessImpactValueArray.map((item) => {
                    if (item && item.DollarImpact) {
                        dollarTotal += parseFloat(item.DollarImpact);
                    }
                    if (item && item.dollarValue) {
                        dollarTotal += parseFloat(item.dollarValue);
                    }
                    if (item && item.FTEImpact) {
                        fteValue += parseFloat(item.FTEImpact)
                    }
                    if (item && item.fteValue) {
                        fteValue += parseFloat(item.fteValue)
                    }
                })
                this.setState({ finalImpactDollar: dollarTotal }, () => {
                    console.log("inside call back function $ total", this.state.finalImpactDollar)
                })
                this.setState({ finalImpactFTE: fteValue }, () => {
                    console.log("inside call back function fte value", this.state.finalImpactFTE)
                })

            })
        }

    }

    pull_file_data = ({ fileName, fileType, fileContent, showClientApprovalFields }) => {
        if (fileName) {
            this.setState({ FPnAFileNameMonetization: fileName }, () => { })
        }
        if (fileType) {
            this.setState({ FPnAFileTypeMonetization: fileType }, () => { })
        }
        if (fileContent) {
            this.setState({ FPnAFileMonetization: fileContent }, () => { })
        }
        this.setState({ showClientApprovalFields: showClientApprovalFields }, () => { })
    }


    pull_monetization_sowId = (localSowId) => {
        this.setState({ SowID: localSowId }, () => {
            // console.log("sow id from pull_monetization_sowId function =>",this.state.SowID)
        })
    }
    pull_monetization_fpna_name = (localFpnaName) => {
        // console.log("fpna name from from pull function => ",localFpnaName)
        this.setState({ FPnANameMonetization: localFpnaName }, () => { })
    }
    pull_monetization_fpna_ohr = (localFpnaohr) => {
        // console.log("fpna ohr from pull function => ",localFpnaohr)
        this.setState({ FPnAOHRMonetization: localFpnaohr }, () => { })
    }

    pullMonetizationGOLName = (localGOLName) => {
        this.setState({ golMonetizationName: localGOLName }, () => {
            //console.log(this.state.golMonetizationName)
        })
    }
    pullMonetizationGOLOhr = (localGOLOhr) => {
        this.setState({ golMonetizationOhr: localGOLOhr }, () => {
            // console.log(this.state.golMonetizationOhr)
        })
    }

    pull_monetizedTotal = ({ fteValue, dollarValue }) => {
        if (fteValue) {
            this.setState({ totalMonetizedDollar: fteValue }, () => { })
        }
        if (dollarValue) {
            this.setState({ totalMonetizedFTE: dollarValue }, () => { })
        }
    }
    pull_monetizableProject = (e) => {
        if (e) {
            this.setState({ monetizableProjectSelectedOption: e }, () => {
                //console.log("monetization project Option =>",this.state.monetizableProjectSelectedOption)
            })

        }
    }
    pull_monetizableStatus = (e) => {
        if (e) {
            this.setState({ monetizationStatusSelectedOption: e }, () => {
                //console.log("monetization Status SelectedOption =>",this.state.monetizationStatusSelectedOption)
            })

        }
    }

    handleVDTypeChange = ({ e }) => {
        if (e) {
            this.setState({ impactOptionSelected: e }, () => {
                //console.log("impact selected option is from review idea page",this.state.impactOptionSelected)
            })
        }
    }
    fetchFPAOHRforIncreaseInRevenue = (e) => {
        if (e === true) {
            this.setState({ appropriateAttachment: true }, () => { })
        } else {
            this.setState({ appropriateAttachment: false }, () => { })
        }
    }

    pullCategoryNotListed = (localShowHref) => {
        this.setState({ showHrefModal: localShowHref }, () => {
          console.log("hrf modal =>",this.state.showHrefModal)
        });
    };




    // function for FPNAGOL component starts here

    // ppt deck file details
    pullPPTDeck = ({ fileName, fileType, fileContent }) => {
        this.setState({ filename1: fileName, fileType1: fileType, base64File1: fileContent }, () => {
            //console.log("filename ",this.state.filename1, "file type ",this.state.fileType1, "content ",this.state.base64File1)
        })
    }
    pullClientDocName = (fileName) => {
        this.setState({ signOffFilename: fileName, }, () => {
            //console.log("client approval name =>",this.state.signOffFilename)
        })
    }
    pullClientDocType = (fileType) => {
        if (fileType) {
            this.setState({ signOffFileType: fileType }, () => {
                //console.log("client approval type =>",this.state.signOffFileType)
            })
        }
    }
    pullClientDocContent = (fileContent) => {
        if (fileContent) {
            this.setState({ signOffContent: fileContent }, () => {
                //console.log("client approval contnet =>",this.state.signOffContent)
            })
        }
    }

    // gol file details
    pullgolFileName = (fileName) => {
        this.setState({ filename3: fileName }, () => {
            //console.log("gol approval doc name",this.state.filename3)      
        })
    }
    pullgolFileType = (fileType) => {
        this.setState({ fileType3: fileType }, () => {
            //console.log("gol approval doc type",this.state.fileType3)      
        })
    }
    pullgolContent = (fileContent) => {
        this.setState({ base64File3: fileContent }, () => {
            //console.log("gol approval doc content",this.state.base64File3)      
        })
    }

    // fpna file details
    pullfpnafileName = (fileName) => {
        if (fileName) {
            this.setState({ filename4: fileName }, () => {
                //console.log("fpna doc name",this.state.filename4)
            })
        }
    }
    pullfpnafileType = (fileType) => {
        if (fileType) {
            this.setState({ fileType4: fileType }, () => {
                //console.log("fpna doc type",this.state.fileType4)
            })
        }
    }
    pullfpnafileContent = (fileContent) => {
        if (fileContent) {
            this.setState({ base64File4: fileContent }, () => {
                //console.log("fpna doc file content",this.state.base64File4)
            })
        }
    }

    pullClientName = (e) => {
        if (e) {
            this.setState({ clientName: e }, () => {
                //console.log(this.state.clientName)
            })
        }
    }
    pullClientRole = (e) => {
        if (e) {
            this.setState({ clientRole: e }, () => {
                //console.log(this.state.clientRole)
            })
        }
    }
    pullClientSignOff = (e) => {
        if (e) {
            this.setState({ clientSignOffDate: e }, () => {
                //console.log(this.state.clientSignOffDate)
            })
        }
    }
    pullFpnaName = (name) => {
        if (name) {
            this.setState({ fpnaName: name }, () => {
                //console.log(this.state.fpnaName)
            })
        }

    }
    pullGolName = (name) => {
        if (name) {
            this.setState({ golFullname: name }, () => {
                //console.log(this.state.golFullname)
            })
        }

    }
    pullFpnaOhr = (e) => {
        if (e) {
            this.setState({ fpnaOhr: e }, () => {
                //console.log(this.state.fpnaOhr)
            })
        }
    }
    pullGolOhr = (e) => {
        if (e) {
            this.setState({ golOhr: e }, () => {
                //console.log(this.state.golOhr)
            })
        }
    }
    pull_fileDetails = (obj) => {
        this.setState({ golFpnaFileDetails: obj }, () => {
            //console.log(this.state.golFpnaFileDetails)
        })
    }

    // function for FPNAGOL component ends here
    fetchClientTestimonyContent = () => {
        return new Promise((resolve, reject) => {
          const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization":  "Bearer " + this.accessToken,
            },
          };
          let clientTestimonyFileType = this.state.ideaItem.CTestimonyFileType;
          fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&clientTestimonycontent=true`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              const clientTestimonyFileMetadata = data.fpnaContent ? data.fpnaContent : "";
              const clientTestimonyFileContent = "data:" + clientTestimonyFileType + ";base64," + clientTestimonyFileMetadata;
              this.setState(
                {
                  CTestimony: clientTestimonyFileContent,
                },
                () => {
                  resolve();
                  Toast.hide();
                }
              );
            })
            .catch((error) => {
              Toast.fail(
                "Error while Client Testimony file content. Please try again!",
                3000,
                () => { }
              );
              resolve();
            });
        });
      };
      downloadClientTestimony = () => {
        Toast.loading("Fetching Client Testimony content...", () => { });
        this.fetchClientTestimonyContent().then(() => {
          var fileData = this.state.CTestimony;
          var fileName = this.state.ideaItem.CTestimonyFileName;
          var w = window.open("");
          const downloadLink = w.document.createElement("a");
          downloadLink.href = fileData;
          downloadLink.download = fileName;
          downloadLink.click();
          Toast.hide();
        });
      };

    render() {
        let IDEA_APPROVED = (this.state.ideaItem.WorkflowStatus === "Approve");
        let IDEA_REJECTED = (this.state.ideaItem.WorkflowStatus === "Reject");
        let IDEA_SEND_BACK = (this.state.ideaItem.WorkflowStatus === "Send Back");
        let IDEA_REQUEST_CANCELLATION = (this.state.ideaItem.WorkflowStatus === "Cancellation Requested");
        let IDEA_REQUEST_CANCELLATION1 = (this.state.ideaItem.ApprovalStatus === "Cancellation Requested");
        let IDEA_CANCELLED = (this.state.ideaItem.WorkflowStatus === "Cancelled");
        let IDEA_CANCELLED1 = (this.state.ideaItem.ApprovalStatus === "Cancelled");

        let redirectURL = "/review-page";
        if (this.state.approvalPage) {
            redirectURL = redirectURL + "?page=approval";
        }

        let IsSolnFeasibleValue = "";
        if (this.state.ideasAI.IsSolnFeasible === false) {
            IsSolnFeasibleValue = "No"
        }
        if (this.state.ideasAI.IsSolnFeasible === true) {
            IsSolnFeasibleValue = "Yes"
        }

        return (
            <section className="u-clearfix u-section-1" id="sec-0f7f">
                <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                    <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                        <div className="u-container-layout u-container-layout-1">
                            <h4 style={{ textAlign: 'center', color: '#00AECF', fontFamily: 'Arial', fontSize: 22 }}>Edit idea : {this.state.ideaItem.IdeaIDAlpha ? this.state.ideaItem.IdeaIDAlpha : this.state.ideaItem.IdeaId} </h4>
                            <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                                <div className="u-container-layout u-container-layout-2" style={{ textAlign: 'center' }}>
                                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-3">
                                        <div className="u-container-layout u-container-layout-3">
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: 15 }}>Owner details </p>
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner OHR</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerOHR} /></div>
                                            </div>
                                            <div style={{ marginBottom: 15 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner Name</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerName} /></div>
                                            </div>
                                            <div style={{ marginBottom: -10 }} />
                                            <p className="u-align-left u-text u-text-7 u-text-custom-color-3" style={{ fontSize: 11, marginBottom: 10 }}>(If Idea was raised for someone else, their OHR ID is below)</p>
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Request For OHR</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.CreatorOHR} onChange={this.handleCreatorOHRChange} /></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Name</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.CreatorName} onChange={this.handleCreatorNameChange} /></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                            {
                                                (this.state.ideaItem.LeanID !== null && ((this.state.ideaItem.CertificationLevelName === "Lean" || this.state.ideaItem.CertificationLevelName === "YB") && this.state.ideaItem.TransformationLeverName !== "Digital")) ? (
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Project ID</label> </div>
                                                        <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.LeanID} /></div>
                                                    </div>
                                                ) : (
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: 12 }}>&nbsp;</label> </div>
                                                    </div>
                                                )
                                            }
                                            <div style={{ marginBottom: 10 }} />
                                        </div>
                                    </div>

                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 255, marginLeft: -20 }}>&nbsp;</p>

                                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-4" style={{ marginTop: "-305px" }}>
                                        <div className="" style={{ display: "flex", marginTop: "30px" }}>
                                            <div className="u-container-style u-group u-shape-rectangle u-group-5">
                                                <div className="u-container-layout u-container-layout-5">
                                                    <p className="u-align-left u-text u-text-8" style={{ marginBottom: 15 }}>Account Details</p>
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Account&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled list="accounts" name="accounts" placeholder="Choose Account" value={this.state.ideaItem.AccountManual} />

                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Holding&nbsp;Account</label></div>
                                                        <div className="col-md-7">
                                                            {/* <input className ="form-input" disabled type="text" value={this.state.ideaItem.HoldingAccount} /> */}
                                                            <input className="form-input" disabled list="holdingaccount" name="holdingaccount" value={this.state.ideaItem.HoldingAccount} style={{ fontWeight: 'normal !important' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Vertical</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="vertical" placeholder="Choose Vertical" value={this.state.ideaItem.VerticalManual} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Industry&nbsp;Vertical</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="subverticallist" placeholder="Choose Industry&nbsp;Vertical" value={this.state.ideaItem.SubVerticalName} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />

                                                    {/* units and segments starts here */}
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Segment</label>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="segment" value={this.state.ideaItem.Segment ? this.state.ideaItem.Segment : ""} style={{ fontWeight: 'normal !important' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Unit</label>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="unit" value={this.state.ideaItem.Unit ? this.state.ideaItem.Unit : ""} style={{ fontWeight: 'normal !important' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    {/* units and segments ends here */}


                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Campaign&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="ideationtype" placeholder="Choose Campaign Name" value={this.state.ideaItem.CampaignName} />
                                                        </div>
                                                        <div className="">
                                                            <div className="campaign_modal_bulb campaign_tool_tip">
                                                                <img 
                                                                    src={require("../assets/images/question_mark_2.png")}
                                                                    alt=""
                                                                    className=""
                                                                />
                                                                {
                                                                    this.state.campaignInsight
                                                                    ?
                                                                    <>
                                                                        <span className="my_tooltiptext">{this.state.campaignInsight}</span>
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    {
                                                        this.state.ideaItem.CampaignName === "Gen AI"
                                                            ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5">
                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Gen AI Theme</label></div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Gen AI Theme" value={this.state.ideaItem.SubCampaignName} />
                                                                    </div>
                                                                </div>

                                                            </>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        this.state.displayProjectTheme && this.state.dataBridgeCampaign && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Data Bridge Project Theme</label></div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled list="databridgetype" name="databridgetype" placeholder="Choose Data Bridge Project Type" value={this.state.ideaItem.DataBridgeTheme} />
                                                                        {/* <Select tabIndex={4} isSearchable={true} options={this.renderDataBridgeProjectThemeOptions()} styles={this.state.selectStyle.dataBridgeProjectTheme} placeholder="Choose Project Theme" onChange={this.handleDataBridgeProjectThemeChange} /> */}
                                                                        {/* <Select tabIndex={2} isSearchable={true} value={this.state.dataBridgeSelectedOption} options={this.renderDataBridgeProjectThemeOptions(this.state.dataBridgeSelectedOption)} styles={this.state.selectStyle.dataBridgeProjectTheme} placeholder="Choose Project Theme" onChange={(e) => this.handleDataBridgeProjectThemeChange(e)} /> */}
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 26 }} />
                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        this.state.xPollinationCampaign && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Campaign</label></div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Sub Campaign" value={this.state.ideaItem.SubCampaignName} />
                                                                        {/* <Select tabIndex={2} isSearchable={true} value={this.state.subCampaignSelectedOption} options={this.renderSubCampaignOptions(this.state.subCampaignSelectedOption)} styles={this.state.selectStyle.subCampaignName} placeholder="Choose Sub Campaign" onChange={(e) => this.handleSubCampaignChange(e)} /> */}
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 26 }} />
                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        this.state.displayParentAlpha && this.state.displayParentAlpha.length > 0 && this.state.displayParentAlpha !== null && this.state.displayParentAlpha !== undefined && this.state.displayParentAlpha !== ""
                                                            ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5">
                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Parent ID</label>
                                                                    </div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled placeholder="Choose Parent ID" value={this.state.displayParentAlpha} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    
                                                    <div style={{ marginTop: 15 }}>&nbsp;</div>

                                                    {
                                                        this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST
                                                            ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5">
                                                                        <label
                                                                            style={{
                                                                                verticalAlign: "bottom",
                                                                                marginBottom: -5,
                                                                            }}
                                                                        >
                                                                            <i>AI First Sample Ideas</i>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-7">
                                                                        <div className="ai_first_doc_link ai_first_doc_red_highlight">
                                                                            <a
                                                                                className="ldt-list1"
                                                                                href={AI_DOC_LINK}
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                                style={{ textAlign: "left", fontSize: 16, color: "red" }}
                                                                            >Document Link </a>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                {
                                                                    this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )
                                                                        ?
                                                                        <>
                                                                            <div style={{ marginBottom: 20 }} />
                                                                            <div className="row d_flex_align_items_center">
                                                                                <div className="col-md-5">
                                                                                    <span className="form-attribute">
                                                                                        AI Recommendation
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-7">
                                                                                    <input
                                                                                        className="form-input"
                                                                                        disabled
                                                                                        name="methodology"
                                                                                        value={this.state.ideaItem.isAI}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null

                                                                }


                                                                {
                                                                    this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )
                                                                        ?
                                                                        <>
                                                                            <div style={{ marginBottom: 20 }} />
                                                                            <div className="row">
                                                                                <div className="col-md-5">
                                                                                    <span className="form-attribute">
                                                                                        AI First BRD
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-7">
                                                                                    <div className="ai_first_doc_link">
                                                                                        <textarea className="ai_first_brd_textarea" disabled value={this.state.ideasAI.LinkBRD} ></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }

                                                            </>
                                                            :
                                                            null
                                                    }
                                                    <div style={{ marginBottom: 18 }}>&nbsp;</div>
                                                </div>
                                            </div>
                                            <div className="u-container-layout u-container-layout-6">
                                                <div className="row" style={{ marginTop: "10px" }}>
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Country</label></div>
                                                    <div className="col-md-7">
                                                        <input className="form-input" disabled name="regions" placeholder="Choose Country" value={this.state.ideaItem.Country} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Region</label></div>
                                                    <div className="col-md-7">
                                                        <input className="form-input" disabled name="regions" placeholder="Choose Region" value={this.state.ideaItem.RegionManual} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub-Region</label></div>
                                                    <div className="col-md-7">
                                                        <input className="form-input" disabled name="regions" placeholder="Choose Sub-Region" value={this.state.ideaItem.SubRegion} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Blackbelt&nbsp;Name</label></div>
                                                    <div className="col-md-7">
                                                        <input className="form-input" disabled name="blackbelt" placeholder="Choose Blackbelt" title={"OHR ID: " + this.state.ideaItem.BlackBeltUserId} value={this.state.ideaItem.BlackBeltUserName} />
                                                    </div>
                                                </div>
                                                {
                                                    (this.state.ideaItem.MBBOHR !== null || this.state.showAdditionalDetails || this.state.workFlowStatusSelectedOption) && (
                                                        // (this.state.ideaItem.WorkflowStatus !== "Pending") && (
                                                        <>
                                                            <div style={{ marginBottom: 15 }} />
                                                            <div className="row">
                                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>MBB</label></div>
                                                                <div className="col-md-7">
                                                                    <input className="form-input" disabled name="blackbelt" placeholder="Choose MBB" title={"OHR ID: " + this.state.ideaItem.MBBOHR} value={this.state.ideaItem.MBBName} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }

                                                <div style={{ marginBottom: 15 }} />

                                                {
                                                    this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST &&
                                                    this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )
                                                    ?
                                                    <>
                                                        <div style={{ marginBottom: 20 }} />
                                                        <div className="row">
                                                        <div className="col-md-12">
                                                            <span className="form-attribute">
                                                            AI First Reason
                                                            </span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <textarea
                                                            disabled
                                                            value={this.state.ideasAI.AIReason}
                                                            placeholder="Please provide value"
                                                            className="form-input"
                                                            rows={4}
                                                            cols={80}
                                                            style={{
                                                                width: "100%",
                                                                border: "1px solid lightgrey",
                                                                resize: "none"
                                                            }}
                                                            />
                                                        </div>
                                                        </div>
                                                        <div style={{ marginBottom: 20 }} />
                                                    </>
                                                    :
                                                    null
                                                }


                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />

                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -30 }}>&nbsp;</p>

                                    <div id="idea1" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ marginTop: 15 }}>
                                        <div className="u-container-layout u-container-layout-7">
                                            <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                <div className="u-container-layout u-container-layout-8">
                                                    <p id="idea1title" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Idea details</p>
                                                    <div className="row">
                                                        <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Service Line</label> </div>
                                                        <div className="col-md-8">
                                                            <input className="form-input" disabled value={this.state.ideaItem.ServiceLineName} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Service Line</label> </div>
                                                        <div className="col-md-8">
                                                            <input className="form-input" disabled value={this.state.ideaItem.SubServiceLine} />

                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                        <span className="u-text-black form-attribute"> What is the Problem ?</span>&nbsp;
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <textarea className="form-input" disabled rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Problem} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 10 }}>&nbsp;</p>
                                            <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                <div className="u-container-layout u-container-layout-9" style={{ marginTop: 4 }}>
                                                    <div className="row">
                                                        <div className="col-md-3"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Idea Name</label> </div>
                                                        <div className="col-md-9" style={{ alignItems: 'flex-start' }}>
                                                            <input className="form-input" disabled type="text" value={this.state.ideaItem.IdeaName} placeholder="Eg: Cash flow improvement through process restructuring" /></div>
                                                        {/* <div className="col-md-8" style={{ alignItems: 'flex-start' }}><input className="textarea-css form-attribute" type="text" defaultValue={this.state.ideaItem.IdeaName} style={this.state.selectStyle.IdeaName} placeholder="Eg: Cash flow improvement through process restructuring" onChange={(event) => this.handleIdeaNameChange(event.target.value)} /></div> */}
                                                    </div>
                                                    <div style={{ marginBottom: 66 }} />
                                                    <p className="u-align-left u-text u-text-custom-color-4 u-text-25" style={{ width: "177px" }}>
                                                        <span className="u-text-black form-attribute"> How to resolve the problem</span>&nbsp;
                                                    </p>
                                                    <div className="row" style={{ marginBottom: 15 }}>
                                                        <div className="col-md-12">
                                                            <textarea className="form-input" disabled rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Solution} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 15 }}>
                                                <div className="col-md-5"><span className="form-attribute">Are you trying to replicate an existing Idea?</span>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="switch">
                                                        {/* <input disabled type="checkbox" checked={this.state.ideaItem.NewOrReplicatedIdea === null ? false : this.state.ideaItem.NewOrReplicatedIdea} /> */}
                                                        <input type="checkbox" checked={this.state.ideaItem.NewOrReplicatedIdea === null ? false : this.state.ideaItem.NewOrReplicatedIdea} onChange={() => this.showHideDiv()} />
                                                        <span className="slider round" />
                                                    </label>
                                                </div>
                                            </div>

                                            {
                                                this.state.ideaItem.NewOrReplicatedIdea && (
                                                    <>
                                                        <div className="col-md-8" style={{ marginBottom: 15, width: "100%" }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><span className="form-attribute">Provide the ID of the executed Idea which you want to replicate?</span>
                                                            </div>
                                                            {/* <div className="col-md-2"><input className ="form-input" disabled type="text" value={this.state.ideaItem.ReplicationProjectId} /></div> */}
                                                            <div className="col-md-2">
                                                                <input className="textarea-css form-attribute" type="text" value={this.state.ideaItem.ReplicationProjectId} style={{ border: '1px solid lightgrey', ...this.state.selectStyle.ReplicationProjectId }} onChange={event => { this.handleReplicationIdChange(event.target.value) }} onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.validateReplicationId();
                                                                    }
                                                                }} />
                                                            </div>
                                                            <div className="col-md-1"><i className="fa fa-check-square-o" style={{ marginTop: 5, color: '#FF555F', fontSize: 24 }} onClick={event => { this.validateReplicationId() }} /></div>
                                                            <div className="col-md-8" style={{ marginBottom: 15 }} />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <div className="col-md-8" style={{ marginBottom: 15 }} />
                                        </div>
                                    </div>

                                    {
                                        (this.state.ideaItem.WorkflowStatus) && (
                                            <>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />
                                                <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                    <div className="u-container-layout u-container-layout-7">
                                                        <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Idea Approval details</p>
                                                        <div className="row">
                                                            <div className="col-md-2"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Approval Status</label></div>
                                                            <div className="col-md-2">
                                                                {
                                                                    (this.state.ideaItem.WorkflowStatus !== 'Pending' && this.state.ideaItem.WorkflowStatus !== 'Reject') && (
                                                                        <input className="form-input" disabled name="Status" value={IDEA_APPROVED ? APPROVED : (IDEA_REJECTED ? REJECTED : (IDEA_SEND_BACK ? SENT_BACK : ((IDEA_REQUEST_CANCELLATION || IDEA_REQUEST_CANCELLATION1) ? "Cancellation Requested" : ((IDEA_CANCELLED || IDEA_CANCELLED1) ? "Cancelled" : PENDING))))} />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: 15 }}>
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Comments</label></div>
                                                            <div className="col-md-2" style={{ marginLeft: 20 }}>
                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ commentLog: !this.state.commentLog })}>Comment Log</button>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: 5 }}>
                                                            <div className="col-md-12">
                                                                {
                                                                    (this.state.ideaItem.WorkflowStatus === 'Pending' || this.state.ideaItem.WorkflowStatus === 'Reject') && (
                                                                        <textarea className="textarea-css form-attribute" rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey', ...this.state.selectStyle.comment }} placeholder="Please provide your comments in support of the Approval decision." onChange={(event) => this.handleCommentChange(event.target.value)} />
                                                                    )
                                                                }
                                                                {
                                                                    (this.state.ideaItem.WorkflowStatus !== 'Pending' && this.state.ideaItem.WorkflowStatus !== 'Reject') && (
                                                                        <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.Comment} />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="text-left mt-4">
                                                            {
                                                                this.state.commentLog && this.state.reviewComments
                                                                    ?
                                                                    <>
                                                                        <div className="custom_modal">
                                                                            <div className="modal_body">
                                                                                <div className="row">
                                                                                    <div className="col-md-8">
                                                                                        <h3 className="modal_title">Idea Approval Comment Log</h3>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ commentLog: false })}>&times;</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                    </div>
                                                                                    <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                    </div>
                                                                                    <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    this.state.reviewComments.map((item, index) => {
                                                                                        return (
                                                                                            <div className="row" key={index}>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                </div>
                                                                                                <div className="col-md-1 text-center role_over_flow_class" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === 'Delegate Action' ? 'BB' : (item.UpdaterOHR === this.state.ideaItem.BlackBeltUserId ? "BB" : (item.UpdaterOHR === this.state.ideaItem.MBBOHR ? "MBB" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Owner" : item.UpdaterRole))))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? (item.WorkflowStatus !== 'Pending' ? item.WorkflowStatus : 'Idea Updated') : (item.Action === 'Delegate Action' ? 'Delegated' : (item.WorkflowStatus === 'Pending' ? 'Reject Cancellation' : item.WorkflowStatus)))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment ? item.Comment : '-'}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15, marginTop: 15 }} />

                                                {
                                                    (this.state.ideaItem.WorkflowStatus && (this.state.ideaItem.WorkflowStatus === "Approve" || this.state.ideaItem.WorkflowStatus === "Approved" || this.state.showAdditionalDetails)) && (
                                                        <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ borderBottom: "1px solid lightgray", marginBottom: "10px" }}>
                                                            <div className="u-container-layout u-container-layout-7" style={{display:"flex", alignItems:"flex-start", padding: "30px", marginTop: "20px"}}>
                                                                <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                                    <div className="u-container-layout">
                                                                        <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: "30px" }}>Additional details</p>
                                                                        <div className="row">
                                                                            <div className="col-md-6"><span className="form-attribute">LDT Lead Name</span></div>
                                                                            <div className="col-md-6">
                                                                                <input className="form-input" disabled list="ldtName" name="ldtName" placeholder="Choose LDT Lead" title={"OHR ID: " + this.state.ideaItem.LDTId} value={this.state.ideaItem.LDTLeadName} />
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div style={{ marginTop: 15, marginBottom: 15 }} />


                                                                        {
                                                                            this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST
                                                                            && this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" ) 
                                                                            ?
                                                                                <>
                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-6">
                                                                                            <span className="form-attribute">
                                                                                                Is the solution feasible ?
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <input
                                                                                                className="form-input"
                                                                                                disabled
                                                                                                value={IsSolnFeasibleValue}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-6">
                                                                                            <span className="form-attribute">
                                                                                                Solution Complexity
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <input
                                                                                                className="form-input"
                                                                                                disabled
                                                                                                name="methodology"
                                                                                                value={
                                                                                                    this.state.ideasAI.SolnComplexity
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-6">
                                                                                            <span className="form-attribute">
                                                                                                Is the Idea replicable
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <input
                                                                                                className="form-input"
                                                                                                disabled
                                                                                                value={this.state.isIdeaReplicable}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                </>
                                                                                :
                                                                                null
                                                                        }



                                                                        {
                                                                            this.state.ideaItem.NewOrReplicatedIdea && (
                                                                                <div className="row">
                                                                                    <div className="col-md-10">
                                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Have you verified the replication id and confirm this to be replication project?</label>
                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        <label className="switch">
                                                                                            <input disabled type="checkbox" checked={this.state.ideaItem.IsReplicationVerified} />
                                                                                            <span className="slider round" />
                                                                                        </label>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 45 }} />
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            !this.state.ideaItem.NewOrReplicatedIdea && (
                                                                                <div className="row">
                                                                                    <div className="col-md-10">
                                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: 17 }}>&nbsp;</label>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>

                                                               

                                                                <div className="u-container-style u-group u-shape-rectangle">
                                                                    <div className="u-container-layout u-container-layout-9" style={{ paddingTop: "15px" }}>
                                                                        <div className="row">
                                                                            <div className="col-md-5"><span className="form-attribute">Certification Level</span> </div>
                                                                            <div className="col-md-7">
                                                                                {
                                                                                    this.state.showAdditionalDetails ? (
                                                                                        <Select isSearchable={false} options={this.renderCertLevelsOptions()} styles={this.state.selectStyle.certLevelsSelectedOption} value={this.state.certLevelsSelectedOption} onChange={this.handleCertLevelChange} placeholder="Choose Certification Level" />
                                                                                    ) : (
                                                                                        <input className="form-input" disabled name="certlevel" value={this.state.ideaItem.CertificationLevelName} />
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 15 }} />
                                                                        <div className="row">
                                                                            <div className="col-md-5"><span className="form-attribute">Transformation Lever</span> </div>
                                                                            <div className="col-md-7">
                                                                                <input className="form-input" disabled name="translever" value={this.state.ideaItem.TransformationLeverName} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 15 }} />
                                                                        {
                                                                            this.state.ideaItem.TransformationLeverName === "Digital" && (
                                                                                <>
                                                                                    <div className="row">
                                                                                        <div className="col-md-5"><span className="form-attribute">Digital Project Type</span> </div>
                                                                                        <div className="col-md-7">
                                                                                            <input className="form-input" disabled name="translever" value={this.state.ideaItem.DigitalProjectType} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            this.state.ideaItem.CertificationWorkTypeName !== null && this.state.ideaItem.CertificationWorkTypeName !== ""
                                                                                ?
                                                                                <>
                                                                                    <div className="row">
                                                                                        <div className="col-md-5"><span className="form-attribute">Work Type</span> </div>
                                                                                        <div className="col-md-7">
                                                                                            {
                                                                                                this.state.workTypeEnabled ? (
                                                                                                    <Select disabled isSearchable={false} options={this.renderCertWorkTypeOptions(this.state.certLevelsSelectedOption)} styles={this.state.selectStyle.certWorkTypeSelectedOption} value={this.state.certWorkTypeSelectedOption} onChange={this.handleCertWorkTypeChange} placeholder="Choose Work Type" />
                                                                                                ) : (
                                                                                                    <input className="form-input" disabled name="worktype" value={(!this.state.ideaItem.CertificationWorkTypeName || this.state.ideaItem.CertificationWorkTypeName === "") ? "None" : this.state.ideaItem.CertificationWorkTypeName} />
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                null
                                                                        }
                                                                        <div style={{ marginBottom: 15 }} />

                                                                        <div className="row">
                                                                            <div className="col-md-5"><span className="form-attribute">Tools/Methodology Used</span></div>
                                                                            <div className="col-md-7">
                                                                                <input className="form-input" disabled name="methodology" value={this.state.ideaItem.MethodologyName} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 25 }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }


                                    {
                                        this.state.ideaItem.CertificationLevelName === "Lean"
                                            ?
                                            <>
                                                <FPNAGOL IdeaId={this.state.ideaItem.IdeaId} viewIdea={true}
                                                    vdSelected={this.state.ideaItem.VDType}
                                                    fetchPPTDeck={this.pullPPTDeck}
                                                    fetchClientDocName={this.pullClientDocName}
                                                    fetchClientDocType={this.pullClientDocType}
                                                    fetchClientDocContent={this.pullClientDocContent}
                                                    fetchGolDocName={this.pullgolFileName}
                                                    fetchGolDocType={this.pullgolFileType}
                                                    fetchGolDocContent={this.pullgolContent}
                                                    fetchfpnaDocName={this.pullfpnafileName}
                                                    fetchfpnaDocType={this.pullfpnafileType}
                                                    fetchfpnaDocContent={this.pullfpnafileContent}
                                                    fetchClientName={this.pullClientName}
                                                    fetchClientRole={this.pullClientRole}
                                                    fetchClientSignOffDate={this.pullClientSignOff}
                                                    fetchFpnaName={this.pullFpnaName}
                                                    fetchGolName={this.pullGolName}
                                                    fetchFpnaOhr={this.pullFpnaOhr}
                                                    fetchGolOhr={this.pullGolOhr}
                                                    fetchFileDetails={this.pull_fileDetails}
                                                    sendBitoFPNA={this.state.businessImpactValueArray}
                                                    sendIsFpnaRequired={this.state.isFpnaRequired}
                                                />
                                            </>

                                            :
                                            null
                                    }





                                    {
                                        this.state.IsVDType !== "No Value Delivery"
                                            ?
                                            <>
                                                <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                    <div className="u-container-layout u-container-layout-7" style={{ borderBottom: "1px solid lightgrey", marginBottom: "30px" }}>
                                                        <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: "20px" }}>Final additional details</p>
                                                        <div className="row pb-4">
                                                            <div className="col-md-6">
                                                                <div className="row d_flex_align_items_center">
                                                                    <div className="col-md-6">
                                                                        <span className="form-attribute">Best in Class</span>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label className="switch">
                                                                            <input disabled type="checkbox"
                                                                                checked={this.state.ideaItem.IsBestInClass === null ? false : this.state.ideaItem.IsBestInClass} />
                                                                            <span className="slider round" />
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="col-md-6">
                                                                {
                                                                    (this.state.ideaItem.ReplicabilityIndex !== null) && (
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-6">
                                                                                <span className="form-attribute">Replicability Index</span>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <input className="form-input" disabled
                                                                                    value={this.state.replicabilityIndex}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }

                                    {
                                        this.state.ideaItem.IdeaId && this.state.ideaItem.VDType
                                            ?
                                            <>
                                                <ValueDelivery func={this.pull_data} viewIdea={false}
                                                    vdType={this.handleVDTypeChange}
                                                    IdeaId={this.state.ideaItem.IdeaId}
                                                    fetchMonetizationFileDetails={this.pull_file_data}
                                                    fetchMonetizationFpnaOhr={this.pull_monetization_fpna_ohr}
                                                    fetchMonetizationFpnaName={this.pull_monetization_fpna_name}
                                                    fetchMonetizationSowID={this.pull_monetization_sowId}
                                                    bbMonetizationTotal={this.pull_monetizedTotal}
                                                    monetizableProject={this.pull_monetizableProject}
                                                    monetizableStatus={this.pull_monetizableStatus}
                                                    fetchFPAOHRforIncreaseInRevenue={this.fetchFPAOHRforIncreaseInRevenue}
                                                    bbMonetizationApproval={true}
                                                    postFpnaValid={true}
                                                    fetchDeletedBIRows={this.remove_bi_data_after_delete}
                                                    fetchMonetizationGOLName={this.pullMonetizationGOLName}
                                                    fetchMonetizationGOLOhr={this.pullMonetizationGOLOhr}
                                                    fetchCategoryNotListed={this.pullCategoryNotListed}

                                                />
                                            </>
                                            :
                                            null
                                    }



                                    {/* .......qc approval details starts here........ */}
                                    {
                                        this.state.ideaItem.QCOHR !== null && this.state.ideaItem.QCOHR !== ""
                                            ?
                                            <>
                                                <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                    <div className="u-container-layout u-container-layout-7" style={{ borderTop: " 1px solid lightgray", paddingTop: "40px" }}>
                                                        <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Quality Check Details Approval</p>
                                                        <div className="row" style={{ marginTop: 15 }}>
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: "-13px" }}>Approval Comments</label></div>
                                                            <div className="col-md-2" style={{ marginLeft: 20 }}>
                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.showCommentModal(3)}>Comment Log</button>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: 5 }}>
                                                            <div className="col-md-12">
                                                                <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.qcLastComment} />
                                                            </div>
                                                        </div>
                                                        <div className="text-left mt-4">
                                                            {
                                                                this.state.qcCommentLog && this.state.qcComments
                                                                    ?
                                                                    <>
                                                                        <div className="custom_modal">
                                                                            <div className="modal_body">
                                                                                <div className="row">
                                                                                    <div className="col-md-8">
                                                                                        <h3 className="modal_title">QC Comment Log</h3>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ qcCommentLog: false })}>&times;</button>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="qc_responsive_comment_log">
                                                                                    <div className="table table-responsive table-bordered">
                                                                                        <thead className='text-center'>
                                                                                            <tr>
                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg" style={{ minWidth: "250px" }}>
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthOne">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Is the impact correctly tagged to Genpact or Client ?</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthTwo">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Has the Value Delivery been correctly categorized ?</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthThree">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Has the Value Delivery been computed correctly ?</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthFour">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Do the value delivery approvals (applicable as per the value playbook) clearly articulate what improved and for which time-period ?</label>
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                this.state.qcComments.map((item, index) => {
                                                                                                    return (
                                                                                                        <tr key={index}>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === "Delegate Action" ? 'BB' : (item.UpdaterOHR === this.state.MBBOHR ? 'MBB' : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Idea Owner" : (item.ApprovalStatus === "Pending Documents" && item.WorkflowStatus === "Approved") ? "BB" : (item.ApprovalStatus === "NA" && item.WorkflowStatus === "Pending") ? "BB" : item.UpdaterRole)))}</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {/* <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.ApprovalStatus==="Documents Uploaded" ? "Send Back" : ((item.WorkflowStatus==="Rejected" || item.WorkflowStatus==="Reject" || item.ApprovalStatus==="Reject" || item.ApprovalStatus==="Rejected" )? "Rejected ":"Approved")}</label> */}
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.ApprovalStatus === "Documents Uploaded" ? "Send Back" : ((item.WorkflowStatus === "Rejected" || item.WorkflowStatus === "Reject" || item.ApprovalStatus === "Reject" || item.ApprovalStatus === "Rejected") ? "Rejected " : (item.Action === "Idea was Rework - QC" ? "Rework" : "Approved"))}</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment !== '' ? item.Comment : '-'}</label>
                                                                                                            </td>
                                                                                                            {
                                                                                                                this.state.qcCommentsWithVD && this.state.qcCommentsWithVD.length > 0
                                                                                                                    ?
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            this.state.qcCommentsWithVD.map((pg_item) => {
                                                                                                                                if (Number(pg_item.ProjectTeamID) === item.TrackId) {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <td>
                                                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>

                                                                                                                                                    {JSON.parse(pg_item.AuditDataJSON).pg_isvdimpacttagged ? JSON.parse(pg_item.AuditDataJSON).pg_isvdimpacttagged : "-"}
                                                                                                                                                </label>
                                                                                                                                            </td>
                                                                                                                                            <td>

                                                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                                                                    {JSON.parse(pg_item.AuditDataJSON).pg_isvdcategorized ? JSON.parse(pg_item.AuditDataJSON).pg_isvdcategorized : "-"}

                                                                                                                                                </label>
                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                                                                    {JSON.parse(pg_item.AuditDataJSON).pg_isvdcomputed ? JSON.parse(pg_item.AuditDataJSON).pg_isvdcomputed : "-"}
                                                                                                                                                </label>
                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                                                                    {JSON.parse(pg_item.AuditDataJSON).pg_isvdclientsignoff ? JSON.parse(pg_item.AuditDataJSON).pg_isvdclientsignoff : "-"}
                                                                                                                                                </label>
                                                                                                                                            </td>

                                                                                                                                        </>

                                                                                                                                    )
                                                                                                                                }

                                                                                                                            })
                                                                                                                        }
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                        <td>
                                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                                        </td>
                                                                                                                    </>
                                                                                                            }


                                                                                                        </tr>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </tbody>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }

                                    {/* .........qc approval details ends here......... */}




                                    {/* ............db fields starts here........... */}
                                    {
                                            this.state.ideaItem.TransformationLeverName && this.state.ideaItem.TransformationLeverName === "Data"
                                            && this.state.displayDBFields
                                                ?
                                                <>
                                                    <div className="">
                                                        <div className="" style={{borderTop:"1px solid lightgrey"}}>
                                                            <p className="u-align-left u-text u-text-2" style={{ margin: "15px 0px 0px 0px" }}>Data Bridge Fields</p>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    DB Project Type</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideaItem.DBProjectType} disabled className="form-input  disabled_input" placeholder="Provide Value" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        this.state.ideaItem.DBProjectType
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields" style={{}}>
                                                                                    <div className="row">
                                                                                        <div className="col-md-5">
                                                                                            <span className="form-attribute">
                                                                                                Template Type
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <div className="ai_first_doc_link">
                                                                                                <input className="template_type_input" disabled  value={this.state.ideaItem.DBProjectType} ></input>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }

                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    COO</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideaItem.COO} type="text" placeholder="Provide Value" className="form-input  disabled_input"
                                                                                    disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    MUC ID</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input data-toggle="tooltip" title={this.state.ideaItem.MUCIDTitle ? this.state.ideaItem.MUCIDTitle : ""} value={this.state.ideaItem.MUCIDTitle} placeholder="Please choose" disabled className="form-input  disabled_input" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    Shown to Client</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideasDB.IsSharedClient} placeholder="Please choose" disabled className="form-input disabled_input" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.ideasDB.IsSharedClient && this.state.ideasDB.IsSharedClient === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="">
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className="submit_page_new_fields">
                                                                                                <div className="row d_flex_align_items_center">
                                                                                                    <div className="col-md-5">
                                                                                                        <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                            Client Testimony</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-7">
                                                                                                        <button className={this.state.ideaItem.CTestimonyFileName.length > 50?"upload_file_text_overflow":""} style={{ backgroundColor: 'transparent', textAlign: "left", marginTop: "15px", color: '#337ab7', padding: 0 }} onClick={(e) => this.downloadClientTestimony()}>{this.state.ideaItem.CTestimonyFileName}</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-2"></div>
                                                                                        <div className="col-md-5">

                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    Shown to GOL</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideasDB.IsSharedGOL} placeholder="Provide value" className="form-input disabled_input" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    DB Tool Used</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideasDB.DBTool} className="form-input disabled_input" disabled
                                                                                    placeholder="Provide value" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="col-md-4">

                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{
                                                                                    verticalAlign: 'bottom', marginBottom: -5
                                                                                }}>
                                                                                    Process Owner OHR</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input type="text"
                                                                                    disabled
                                                                                    placeholder="Provide Value"
                                                                                    value={this.state.ideaItem.ProcessOwnerOHR}
                                                                                    className="form-input form-attribute" />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{
                                                                                    verticalAlign: 'bottom', marginBottom: -5
                                                                                }}>
                                                                                    Process Owner Name</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideaItem.ProcessOwner} type="text" placeholder="Provide Value" className="form-input disabled_input"
                                                                                    disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        this.state.ideasDB.IsSharedClient && this.state.ideasDB.IsSharedClient === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                Client Role</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input value={this.state.ideasDB.ClientRole} type="text" placeholder="Provide value" className="form-input disabled_input"
                                                                                                disabled />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                Client Name</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input value={this.state.ideasDB.ClientName} type="text" disabled className="form-input  disabled_input" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        this.state.ideasDB.IsSharedGOL && this.state.ideasDB.IsSharedGOL === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                GOL OHR</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input value={this.state.ideasDB.GOLOHR} type="text" placeholder="Provide value" className="form-input form-attribute"
                                                                                                disabled />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                GOL Name</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input type="text" placeholder="Provide value" disabled value={this.state.ideasDB.GOL}
                                                                                                className="form-input disabled_input" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    
                                                                    {
                                                                        this.state.ideasDB.DBTool && this.state.ideasDB.DBTool === "Others"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                Other DB Tool Used</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input data-toggle="tooltip" title={this.state.ideasDB.OtherDBTool} value={this.state.ideasDB.OtherDBTool} type="text" placeholder="Provide value" disabled
                                                                                                className="form-input disabled_input" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }


                                                                </div>
                                                                <div className="col-md-4" style={{ paddingRight: "30px" }}>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-8">
                                                                                <label
                                                                                    className="form-attribute"
                                                                                    style={{
                                                                                        verticalAlign: "bottom",
                                                                                        marginBottom: -5,
                                                                                    }}
                                                                                >
                                                                                    Is Sustenance tracking applicable ? </label>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <input
                                                                                    value={this.state.ideasDB.IsSustenance}
                                                                                    placeholder="Provide value"
                                                                                    className="form-input"
                                                                                    disabled

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-12">
                                                                                <label
                                                                                    className="form-attribute"
                                                                                    style={{
                                                                                        verticalAlign: "bottom",
                                                                                        marginBottom: "0px",
                                                                                    }}
                                                                                >
                                                                                    How is the model operationalized ?
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <input
                                                                                    value={
                                                                                        this.state.ideasDB.ModelSummary
                                                                                    }
                                                                                    placeholder="Please choose"
                                                                                    disabled
                                                                                    className="form-input"

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.ideasDB.IsSustenance && this.state.ideasDB.IsSustenance === "Yes" ? (
                                                                        <>
                                                                            <div className="submit_page_new_fields">
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-8">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: -5,
                                                                                            }}
                                                                                        >

                                                                                            Sustenance tracking frequency ?
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <input
                                                                                            value={this.state.ideasDB.SustenanceFreq}
                                                                                            disabled
                                                                                            placeholder="Please choose"
                                                                                            className="form-input"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="submit_page_new_fields">
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-12">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: "0px",
                                                                                            }}
                                                                                        >

                                                                                            Sustenance metric
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <textarea
                                                                                            className="form-input"
                                                                                            disabled
                                                                                            value={this.state.ideasDB.SustenanceMetric}
                                                                                            placeholder="Please provide sustenance metrics"
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                border: "1px solid lightgrey",

                                                                                            }}
                                                                                            rows={3}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </>
                                                                    ) : null}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="" style={{ borderBottom: "1px solid lightgrey", paddingBottom: "30px" }} /> */}
                                                </>
                                                :
                                                null

                                        }
                                    {/* ............db fields ends here........... */}


                                    {
                                        this.state.ideaItem.TransformationLeverName === "Data" && this.state.ideaItem.IdeaId
                                        && this.state.displayDBFields
                                            ?
                                            <>
                                                <DisplayTemplate IdeaId={this.state.ideaItem.IdeaId} templateType={this.state.ideaItem.DBProjectType} />
                                            </>
                                            :
                                            null
                                    }

                                    {
                                        this.state.ideaItem.TransformationLeverName && this.state.ideaItem.TransformationLeverName === "Data" && this.state.ideasDB.IsSustenance === "Yes" && this.state.ideaItem.DBSPocOHR
                                        && this.state.displayDBFields
                                            ?
                                            <>
                                                <div className="" style={{ paddingRight: "30px", paddingTop: "30px", borderTop:"1px solid lightgrey" }}>
                                                    <p className="u-align-left u-text u-text-2">DB questionnaire</p>
                                                    <div className="new_fields_blocks">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Is the MUC ID tagged correctly ?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input
                                                                                value={this.state.ideasDB.IsMUCTag}
                                                                                placeholder='Provide value'
                                                                                disabled
                                                                                className="form-input disabled_input"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Is the DB theme captured correctly?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                disabled
                                                                                value={this.state.ideasDB.IsDBTheme}
                                                                                className="form-input disabled_input"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_fields_blocks">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Is the Tool used captured correctly ?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsToolCorrect}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Are the model details captured appropriately?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsModelCorrect}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_fields_blocks">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Are the model deployment details captured appropriately?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsModelDeploy}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Do the provided signoffs meet value delivery guidelines?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsSignOff}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_fields_blocks">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Are the monetization details captured appropriately?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled={true}
                                                                                value={this.state.ideasDB.IsMonetization}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Are sustenance tracking details OK ?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsSPocSustenance}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_fields_blocks">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Is there any other issue you would like to report ?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsIssue}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Is there any exception applied on the project approval ?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsException}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="" style={{ paddingBottom: "40px", marginBottom: "15px" }} />
                                                </div >
                                            </>
                                            :
                                            null
                                    }
                                    {/* DB SPOC Fields ends here */}






                                    {
                                        this.state.ideaItem.ApprovalStatus === "Completed"
                                            ?
                                            <>
                                                <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                    <div className="u-container-layout" style={{ paddingTop: "40px", borderTop: "1px solid lightgrey" }}>
                                                        <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Approval Details</p>
                                                        <div className="row" style={{ marginTop: "10px" }}>
                                                            <div className="col-md-7">
                                                                <div className="row" style={{ marginTop: "9px", display: "flex", alignItems: "center", marginBottom: '10px' }}>
                                                                    <div className="col-md-9">
                                                                        <p className="u-align-left" style={{ margin: "0px" }}>
                                                                            <span className="u-text-black form-attribute"><span style={{ color: 'red' }}>*</span>&nbsp;Approval Comments<span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}>&nbsp;(max. 500 chars)</span></span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-md-3" style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.showCommentModal(2)}>Comment Log</button>
                                                                        <img onClick={(e) => this.toggleTranscriptFunction(15)} src={require("../assets/images/voice.png")} className="w100" alt="" style={{ width: "24px", cursor: "pointer" }} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ paddingBottom: "20px" }}>
                                                            <div className="col-md-12">
                                                                {/* <textarea className="textarea-css form-attribute" rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey',...this.state.selectStyle.QcComment }} name="Comments" onChange={(e)=>this.handleCommentChange(e.target.value)} value={this.state.QcComment} placeholder='Please provide your comments in support of the Approval decision.'  /> */}
                                                                <textarea className="textarea-css form-attribute" rows={3} cols={88} name="Comments" style={{ maxWidth: '100%', border: '1px solid lightgrey', ...this.state.selectStyle.QcComment }} onChange={(e) => this.handleCommentChange(e.target.value)} value={this.state.QcComment} placeholder='Please provide your comments in support of the Approval decision.' />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {
                                                        this.state.leanCommentLog
                                                            ?
                                                            <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                                <div className="u-container-layout u-container-layout-7" >
                                                                    <div className="text-left mt-4">
                                                                        {
                                                                            this.state.leanCommentLog && this.state.finalReviewComments
                                                                                ?
                                                                                <>
                                                                                    <div className="custom_modal">
                                                                                        <div className="modal_body">
                                                                                            <div className="row">
                                                                                                <div className="col-md-8">
                                                                                                    <h3 className="modal_title">Lean Approval Comment Log</h3>
                                                                                                </div>
                                                                                                <div className="col-md-4">
                                                                                                    <button type="button" className="close text-right" onClick={(e) => this.setState({ leanCommentLog: false })}>&times;</button>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                                </div>
                                                                                                <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                                </div>
                                                                                                <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                this.state.finalReviewComments.map((item, index) => {

                                                                                                    return (
                                                                                                        <div className="row" key={index}>
                                                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                            </div>
                                                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                            </div>
                                                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                            </div>
                                                                                                            <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === 'Idea was reviewed by MBB' ? "MBB" : (item.Action === 'Idea was reviewed by LDT' ? "LDT" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Owner" : "BB")))}</label>
                                                                                                            </div>
                                                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? item.ApprovalStatus : (item.WorkflowStatus === 'Completed' || item.WorkflowStatus === 'Approve' || item.WorkflowStatus === 'Approved' || item.ApprovalStatus === 'Awaiting Quality Check Review') ? 'Approved' : ((item.WorkflowStatus === 'Rejected' || item.WorkflowStatus === 'Reject') ? "Rejected" : (item.WorkflowStatus === REQUEST_CANCELLATION ? REQUEST_CANCELLATION : (item.WorkflowStatus === 'Pending Documents' ? 'Reject Cancellation' : item.WorkflowStatus === 'Cancelled' ? 'Cancelled' : "Sent Back"))))}</label>
                                                                                                            </div>
                                                                                                            <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment ? item.Comment : '-'}</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            null

                                                    }

                                                    {
                                                        this.state.speechModal
                                                            ?
                                                            <UpdatedSpeech modalOpen={true}
                                                                onAuditFinalApproval={this.handleTranscriptFinalApprovalComment}
                                                                modalId={this.state.modalIndex}
                                                                closeModalFunction={this.closeModalFunction}
                                                                previousText={this.state.previousTextForSpeech}
                                                            />
                                                            :
                                                            null
                                                    }



                                                    {this.state.showHrefModal ? (
                                                        <>
                                                            <div className="custom_modal href_modal">
                                                            <div className="modal_body href_modal_body">
                                                                <div className="href_modal_dialog">
                                                                <p>
                                                                    To add a new category please raise a Helpmate
                                                                    ticket, Click here to View SOP
                                                                </p>
                                                                <div className="href_modal_body_buttons">
                                                                    <div className="btn-group href_modal_body_buttons">
                                                                    <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3">
                                                                        <a
                                                                        href="https://genpactonline.sharepoint.com/:b:/r/sites/LeanDigitalTransformation/Documents/KM%20Page%20-%202021/Certification%20Guidelines,%20SOPs%20%26%20Handbooks/Certification%20guidelines,%20SOPs%20%26%20Handbooks/Systems%20%26%20Tools/Helpmate-Data%20Related%20Request/ProGEAR%20Value%20Delivery%20Category%20Addition%20SOP.pdf?csf=1&web=1&e=5kKbFg"
                                                                        style={{ color: "#FFF" }}
                                                                        target="_blank"
                                                                        >
                                                                        Click Here
                                                                        </a>
                                                                    </button>
                                                                    <button
                                                                        className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                                                        onClick={(e) =>
                                                                        this.setState({ showHrefModal: false })
                                                                        }
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </>
                                                    ) : null}




                                                </div>

                                                {/* .........latest bi changes start here... */}


                                                

                                                {/* ....latest bi changes completes here.... */}
                                            </>
                                            :
                                            null
                                    }

                                    <div style={{ marginTop: 10, marginBottom: 20, borderBottom: "1px solid lightgray" }} />
                                    <div className="btn-group">
                                        <button tabIndex={20} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.submitIdea}>Update</button>
                                        <button tabIndex={21} className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.cancelEvent}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default BbMonetizationApproval




