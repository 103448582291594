import React, { Component } from "react";
import {
  GET_IDEA_URL,
  APPROVED,
  PENDING,
  SENT_BACK,
  REJECTED,
  DELEGATE_IDEA_URL,
  GET_METADATA,
  DATA_BRIDGE_CAMPAIGN,
  X_POLLINATION_CAMPAIGN,
  ACCESS_TOKEN,
  PROJECT_VIEW,
  COMPLETED_STATUS,
  AWAITING_MBB_APPROVAL,
  PPT_CONTENT,
  SIGNOFF_CONTENT,
  USER_DATA,
  REVIEW_IDEA_URL,
  REQUEST_CANCELLATION,
  SUBMIT_IDEA_URL,
  AWAITING_QAULITY_CHECK_REVIEW,
  DISPLAYPROJECTTHEME,
  AIFIRST,
  AI_DOC_LINK, DATABRIDGETYPES, YESORNO, MUCID,
  modelOperationOptions, sustenanceMetricFreqOptions, SOLUTIONCOMPLEXITY, REPLICABILITYOPTIONS,
  DB_PEF_DATE
} from "../assets/constants/constants";
import Toast from "light-toast";
import {
  selectValidation as selectStyle,
  textBoxValidation,
} from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";
import Select from "react-select";
import UpdatedSpeech from "./UpdatedSpeech";
import ValueDelivery from "../utils/ValueDelivery";
import FPNAGOL from "../utils/FPNAGOL";
import ShowTree from "../utils/ShowTree";
import { Link } from "react-router-dom";
import DisplayTemplate from '../utils/DisplayTemplates'

const projectType = [
  {
    value: 0,
    label: "GB",
  },
  {
    value: 1,
    label: "BB",
  },
  {
    value: 2,
    label: "MBB",
  },
];

export class ViewIdeaPage extends Component {
  sessionData = JSON.parse(localStorage.getItem(USER_DATA));
  accessToken = localStorage.getItem(ACCESS_TOKEN);
  constructor(props) {
    super(props);

    const isApprovalPage = `${this.props.location.approval}`;
    let isViewAllIdea = this.props.location.isViewAllIdea;
    let isViewAllProject = this.props.location.isViewAllProject;
    let propsData = this.props.location;

    this.state = {
      ideaItem: {},
      WorkflowStatus: "",
      Comment: "",
      accountName: "",
      campaignName: "",
      serviceLineName: "",
      dataBridgeCampaign: false,
      xPollinationCampaign: false,
      approvalPage: isApprovalPage,
      isViewAllIdea: isViewAllIdea,
      propsData: propsData,
      isViewAllProject: isViewAllProject,
      reviewComments: [],
      businessImpactMasterList: [],
      businessImpactValueArray: [],
      summaryArray: [],
      fteTotal: 0,
      dollarTotal: 0.0,
      commentLog: false,
      cancelRequest: false,
      activeIdea: false,
      selectStyle: {},
      adminUserList: [],
      admin: false,
      adminOHR: "",
      isLeanIdea: false,
      leanIdeaSelectedOption: null,
      leanIdeaSelectedOptionList: [
        {
          value: 1,
          label: "Lean",
        },
        {
          value: 2,
          label: "Lean+Idea",
        },
      ],
      userRole: "",
      userRoleId: "",
      ifBBUpdaterNull: "",
      ifMBBUpdaterNull: "",
      Role: "",
      speechModal: false,
      transcriptValue: "",
      modalIndex: "",
      previousTextForSpeech: "",
      ideaCreatedDate: "",
      showHistoricalBI: false,

      showClientFields: false,
      showGOLFields: false,

      golFilename: "",
      golContent: "",
      golFileType: "",
      golOhr: "",
      golEmail: "",
      golFullname: "",

      fpnaFilename: "",
      fpnaContent: "",
      fpnaFileType: "",
      fpnaName: "",
      fpnaEmail: "",
      fpnaOhr: "",
      clientName: "",
      clientRole: "",
      clientSignOffDate: "",
      signOffFilename: "",
      IsVDType: "",
      monetizableValues: [
        {
          value: 0,
          label: "Improvement in transaction based billing",
        },
        {
          value: 1,
          label: "Increase in billable HC",
        },
        {
          value: 2,
          label: "Increase in billable hours",
        },
        {
          value: 3,
          label: "Revenue from digital deployment",
        },
        {
          value: 4,
          label: "Revenue from special projects",
        },
        {
          value: 5,
          label: "Value share from improvement project",
        },
      ],
      postMonetizableOptions: [
        {
          value: 0,
          label: "Non-Monetizable",
        },
        {
          value: 1,
          label: "Discussion to be initiated with Client",
        },
        {
          value: 2,
          label: "Discussion ongoing with Client",
        },
        {
          value: 3,
          label: "Rejected by Client",
        },
        {
          value: 4,
          label: "Approved by Client",
        },
      ],
      qcComments: [],
      qcCommentLog: false,
      QCOHR: "",
      qcCommentsWithVD: [],
      lastFinalComment: "",
      lastApprovalStatus: "",
      lastQCCOmment: "",
      FinalImpactDollar: "",
      FinalImpactFTE: "",
      finalReviewComments: [],
      golFpnaFileDetails: [],
      isFpnaRequired: false,
      displayProjectTheme: false,
      //clustor states starts here
      clusterModal: false,
      projectTypeSelectedOption: null,
      projectIDSelectedOption: null,
      clustorProjects: [],
      guidSelectedOption: null,
      showHirearchy: true,
      showTreeHirearchy: false,
      clusterData: [],
      isClusterSaveDisabled: true,
      displayParentAlpha: "",
      clusterOptionSearched: false,
      enableClusterAction: false,
      ideasAI: [],
      ideasDB: [],
      ideasDBDoc: [],


      // ....DB states start here...
      dbProjectType: [],
      mucIDSelectedOption: [],
      dbToolUsed: [],
      otherDBToolUsedText: "",

      
      replicabilityIndex: [],


      //LDT approval
      IsQBR: [],
      QBRDate: "",
      LDTApproverName: "",
      LDTApproverOHR: "",
      LDTApproverEmail: "",
      ldtLeadApprovalSelectedOption: [],
      displayLDTCommentLog: false,
      isLDTApprover: false,
      ldtApprovalComment: "",
      LDTLogs: [],
      qbrChanged: false,
      qbrLastSubmittedDate:"",

      //sustenance survey
      IdeasSSSurvey: [],
      displaySurvey1: false,
      displaySurvey2: false,
      displaySurvey3: false,
      sustenanceObject: [],

      displayDBFields:false,

      //AI
      isIdeaReplicable:"",

      campaignList: [],
      campaignInsight:""

    };
  }

  async componentDidMount() {
    Toast.loading("Fetching Idea & Metadata...", () => { });
    await this.fetchIdea();
    // Toast.loading("Fetching Metadata...", () => { });
    await this.fetchMetadata();
    Toast.hide();
  }

  formatDateString(dateparam) {
    return dateparam.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  formatDateStringWithTime(dateparam) {
    return dateparam.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  fetchPPTContent = () => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: { 
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + this.accessToken,
      },
      };
      let projectFileType = this.state.projectFileType;
      // fetch(`${GET_IDEA_URL}IdeaId=964&${PPT_CONTENT}=true`, requestOptions)
      fetch(
        `${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("fetch ppt content data ", data);
          const projectDeckMetadata = data.PPTContent ? data.PPTContent : "";
          const projectContent =
            "data:" + projectFileType + ";base64," + projectDeckMetadata;
          this.setState(
            {
              projectDeckContent: projectContent,
            },
            () => {
              resolve();
            }
          );
        })
        .catch((error) => {
          Toast.fail(
            "Error while fetching Project Deck content. Please try again!",
            3000,
            () => { }
          );
          resolve();
        });
    });
  };

  fetchSignoffContent = () => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: { 
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + this.accessToken,
      },
      };
      let signoffFileType = this.state.signOffFileType;
      // fetch(`${GET_IDEA_URL}IdeaId=964&${SIGNOFF_CONTENT}=true`, requestOptions)
      fetch(
        `${GET_IDEA_URL}IdeaId=${this.props.location.state}&${SIGNOFF_CONTENT}=true`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          const signoffMetadata = data.SignoffContent
            ? data.SignoffContent
            : "";
          const signoffContent =
            "data:" + signoffFileType + ";base64," + signoffMetadata;
          this.setState(
            {
              signOffContent: signoffContent,
            },
            () => {
              resolve();
            }
          );
        })
        .catch((error) => {
          Toast.fail(
            "Error while fetching Project Deck content. Please try again!",
            3000,
            () => { }
          );
          resolve();
        });
    });
  };

  // function to fetch gol file data
  fetchGolOffContent = () => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: { 
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + this.accessToken,
      },
      };
      let golFileType = this.state.ideaItem.GOLFileType;
      fetch(
        `${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&golcontent=true`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          const golMetadata = data.golContent ? data.golContent : "";
          const golContent = "data:" + golFileType + ";base64," + golMetadata;
          this.setState(
            {
              golContent: golContent,
            },
            () => {
              resolve();
              Toast.hide();
            }
          );
        })
        .catch((error) => {
          Toast.fail(
            "Error while fetching GOL Approval content. Please try again!",
            3000,
            () => { }
          );
          resolve();
        });
    });
  };
  showGolOffContent = () => {
    Toast.loading("Fetching GOL Approval content...", () => { });
    this.fetchGolOffContent().then(() => {
      var fileData = this.state.golContent;
      var fileName = this.state.ideaItem.GOLFileName;
      var w = window.open("");
      const downloadLink = w.document.createElement("a");
      downloadLink.href = fileData;
      downloadLink.download = fileName;
      downloadLink.click();
      Toast.hide();
    });
  };

  // function to fetch fpna file data
  fetchFpnaContent = () => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: { 
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + this.accessToken,
      },
      };
      let fpnaFileType = this.state.ideaItem.FPnAFileType;
      fetch(
        `${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&fpnacontent=true`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          const golMetadata = data.fpnaContent ? data.fpnaContent : "";
          const golContent = "data:" + fpnaFileType + ";base64," + golMetadata;
          this.setState(
            {
              fpnaContent: golContent,
            },
            () => {
              resolve();
              Toast.hide();
            }
          );
        })
        .catch((error) => {
          Toast.fail(
            "Error while fetching FP&A Approval content. Please try again!",
            3000,
            () => { }
          );
          resolve();
        });
    });
  };
  showFpnaContent = () => {
    Toast.loading("Fetching FP&A Approval content...", () => { });
    this.fetchFpnaContent().then(() => {
      var fileData = this.state.fpnaContent;
      var fileName = this.state.ideaItem.FPnAFileName;
      var w = window.open("");
      const downloadLink = w.document.createElement("a");
      downloadLink.href = fileData;
      downloadLink.download = fileName;
      downloadLink.click();
      Toast.hide();
    });
  };

  // function to fetch fpna file for monetization
  showMonetizationContent = () => {
    Toast.loading(
      "Fetching FP&A Sign-off for monetization content...",
      () => { }
    );
    this.fetchMonetizationContent().then(() => {
      var fileData = this.state.monetizationFileContent;
      var fileName = this.state.ideaItem.FPnAFileNameMonetization;
      var w = window.open("");
      const downloadLink = w.document.createElement("a");
      downloadLink.href = fileData;
      downloadLink.download = fileName;
      downloadLink.click();
      Toast.hide();
    });
  };
  fetchMonetizationContent = () => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: { 
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + this.accessToken,
      },
      };
      let signoffFileType = this.state.ideaItem.FPnAFileTypeMonetization;
      fetch(
        `${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&fpnacontent=true&fpnamonetizationcontent=true`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          const signoffMetadata = data.fpnaContent ? data.fpnaContent : "";
          const signoffContent =
            "data:" + signoffFileType + ";base64," + signoffMetadata;
          this.setState(
            {
              monetizationFileContent: signoffContent,
            },
            () => {
              console.log(this.state.monetizationFileContent);
              resolve();
            }
          );
        })
        .catch((error) => {
          Toast.fail(
            "Error while fetching FP&A Sign-off for monetization content. Please try again!",
            3000,
            () => { }
          );
          resolve();
        });
    });
  };


  



  fetchIdea = () => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: { 
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + this.accessToken,
          
      },
      };
      fetch(
        `${GET_IDEA_URL}IdeaId=${this.props.location.state}&RecID=''&isItIdeaOrGenAI='Idea'`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("fetch idea", data);
          // const projectDeckMetadata = data.ResultSets.Table1[0].ProjectPPTDeck ? data.ResultSets.Table1[0].ProjectPPTDeck : "";
          // const signOffMetadata = data.ResultSets.Table1[0].CustomerLeadSignOff ? data.ResultSets.Table1[0].CustomerLeadSignOff : "";
          const projectFilename = data.ResultSets.Table1[0].ProjectPPTFileName
            ? data.ResultSets.Table1[0].ProjectPPTFileName
            : "";
          const signOffFilename = data.ResultSets.Table1[0]
            .CustomerSignoffFileName
            ? data.ResultSets.Table1[0].CustomerSignoffFileName
            : "";
          const projectFileType = data.ResultSets.Table1[0].ProjectPPTType
            ? data.ResultSets.Table1[0].ProjectPPTType
            : "";
          const signOffFileType = data.ResultSets.Table1[0].CustomerSignoffType
            ? data.ResultSets.Table1[0].CustomerSignoffType
            : "";

          // const projectContent = "data:" + projectFileType + ";base64," + projectDeckMetadata;
          // const signOffContent = "data:" + signOffFileType + ";base64," + signOffMetadata;

          this.setState(
            {
              ideaItem: data.ResultSets.Table1[0],
              WorkflowStatus: data.ResultSets.Table1[0].WorkflowStatus,
              Comment: data.ResultSets.Table1[0].Comment,
              showSourceId:
                data.ResultSets.Table1[0].TransformationLeverName === "Digital"
                  ? true
                  : false,
              projectDeckFilename: projectFilename,
              signOffFilename: signOffFilename,
              projectFileType: projectFileType,
              signOffFileType: signOffFileType,
              // projectDeckContent: projectContent,
              // signOffContent: signOffContent,
              reviewComments: data.ReviewComments.Table1,
              finalReviewComments: data.FinalReviewComments.Table1,
              sourceId: data.ResultSets.Table1[0].SourceId,
              campaignName: data.ResultSets.Table1[0].CampaignName,
              dataBridgeCampaign:
                data.ResultSets.Table1[0].CampaignName === DATA_BRIDGE_CAMPAIGN,
              xPollinationCampaign:
                data.ResultSets.Table1[0].CampaignName ===
                X_POLLINATION_CAMPAIGN,
              businessImpactValueArray: data.BIData
                ? data.BIData.Table1
                  ? data.BIData.Table1.length > 0
                    ? data.BIData.Table1
                    : []
                  : []
                : [],
              ideaCreatedDate: data.ResultSets.Table1[0].CreatedDate,
              IsVDType: data ? data.ResultSets.Table1[0].VDType : "",
              // monetizableStatus: data.ResultSets.Table1[0].monetizable === "Yes" ? "Yes" : "No"
              monetizableStatus: "Yes",
              qcComments: data.QcComments.Table1,
              qcCommentsWithVD: data.QcComments.Table2
                ? data.QcComments.Table2
                : [],
              QCOHR: data.ResultSets.Table1[0].QCOHR,
              clusterData: data.ClusterData.Table1
                ? data.ClusterData.Table1
                : [],
              ideasAI: data.ResultSets.Table2 ? data.ResultSets.Table2[0] : [],
              ideasDB: data.ResultSets.Table3 && data.ResultSets.Table3[0].Result === "NULL" ? [] : data.ResultSets.Table3[0],
              ideasDBDoc: data.ResultSets.Table4 && data.ResultSets.Table4[0].Result === "NULL" ? [] : data.ResultSets.Table4[0],
              // LDT
              LDTLogs: data.LDTLogs ? data.LDTLogs.ResultSets ? data.LDTLogs.ResultSets.Table1 : [] : [],
              IdeasSSSurvey: data.SustenaceSurvey ? data.SustenaceSurvey.ResultSets ? data.SustenaceSurvey.ResultSets.Table1 ? data.SustenaceSurvey.ResultSets.Table1 : [] : [] : [],
            },
            () => {

              

              console.log("survey details=>",this.state.IdeasSSSurvey)


              if (this.state.ideaItem.CreatedDate) {
                let db_idea_created = new Date(this.state.ideaItem.CreatedDate);
                let abc = new Date(DB_PEF_DATE);
                if (db_idea_created.getTime() < abc.getTime()) {
                  this.setState({displayDBFields:false},()=>{})
                  console.log("created date is lesser")
                } else {
                  this.setState({displayDBFields:true},()=>{})
                  console.log("created date is greater")
                }
              }

             
              if (this.state.ideasAI) {
                if(this.state.ideasAI.IsIdeaReplicable ){
                    this.setState({ isIdeaReplicable: "Yes" }, () => { })
                }else{
                    this.setState({ isIdeaReplicable: "No" }, () => { })
                }
              }
              
              if (this.state.LDTLogs) {
                var keys = Object.keys(this.state.LDTLogs);
                console.log("keys => ", keys)
                var last = keys[keys.length - 1];
                if (last) {
                  let varLDTLastComment = this.state.LDTLogs[last].LDTComments;
                  let varLDTLastQBRValue = this.state.LDTLogs[last].IsQBR;
                  let varLDTLastUserName = this.state.LDTLogs[last].UserName;
                  let varLDTLastUserOHR = this.state.LDTLogs[last].UserOHR;
                  let varLDTLastCreatedData = this.state.LDTLogs[last].QBRDate;
                  let formatDate = this.formatDateLatest(varLDTLastCreatedData);
                  let lastSubmittedDate = this.state.LDTLogs[last].CreatedDate
                  this.setState(
                    {
                      QBRDate: formatDate,
                      LDTApproverName: varLDTLastUserName,
                      LDTApproverOHR: varLDTLastUserOHR,
                      ldtApprovalComment: varLDTLastComment,
                      qbrLastSubmittedDate : lastSubmittedDate
                    },
                    () => {
                      console.log("qbr last selected date =>",this.state.qbrLastSubmittedDate)
                      if (varLDTLastQBRValue) {
                        if (varLDTLastQBRValue === "Yes") {
                          this.setState({ IsQBR: { value: 0, label: "Yes" } }, () => { })
                        }
                        if (varLDTLastQBRValue === "No") {
                          this.setState({ IsQBR: { value: 0, label: "No" } }, () => { })
                        }

                      }

                    }
                  );
                }

              }
              if (this.state.ideaItem.ReplicabilityIndex) {
                REPLICABILITYOPTIONS.map((item) => {
                  if (item.value === this.state.ideaItem.ReplicabilityIndex) {
                    this.setState({ replicabilityIndex: item }, () => { })
                  }
                })
              }


              if (this.state.clusterData && this.state.clusterData.length > 0) {
                const targetRecID = this.state.ideaItem.LeanID;
                const filteredData = this.state.clusterData.filter(
                  (item) => item.RecAlpha === targetRecID
                );

                let getParentAlpa = "";
                this.state.clusterData.map((item) => {
                  if (item.RecAlpha === targetRecID) {
                    getParentAlpa = item.ParentAlpha;
                  }
                });
                if (getParentAlpa) {
                  this.state.clusterData.map((item) => {
                    if (getParentAlpa === item.RecAlpha) {
                      const selectedOption = {
                        value: 0,
                        label: item.ProjectType,
                      };
                      this.state.projectTypeSelectedOption = selectedOption;
                      this.setState(
                        { projectTypeSelectedOption: selectedOption },
                        () => {
                          console.log(
                            "project type selected option is at callback=>",
                            this.state.projectTypeSelectedOption
                          );
                        }
                      );
                    }
                  });
                }
                console.log("filtered data", filteredData);

                if (
                  filteredData &&
                  filteredData.length > 0 &&
                  filteredData !== undefined &&
                  filteredData !== null
                ) {
                  this.setState(
                    { displayParentAlpha: filteredData[0].ParentAlpha },
                    () => { }
                  );

                  const selectedOption = {
                    value: 0,
                    label: filteredData[0].ParentAlpha,
                  };
                  this.setState(
                    { projectIDSelectedOption: selectedOption },
                    () => {
                      console.log(
                        "project type selected option is ",
                        this.state.projectTypeSelectedOption
                      );
                      if (
                        this.state.projectTypeSelectedOption &&
                        (this.state.projectTypeSelectedOption.label === null ||
                          this.state.projectTypeSelectedOption.label === "")
                      ) {
                        if (
                          this.state.projectIDSelectedOption.label.startsWith(
                            "GB"
                          )
                        ) {
                          const selectedOptionGB = {
                            value: 0,
                            label: "GB",
                          };
                          this.state.projectTypeSelectedOption =
                            selectedOptionGB;
                          this.setState(
                            { projectTypeSelectedOption: selectedOptionGB },
                            () => {
                              //console.log(this.state.projectTypeSelectedOption)
                            }
                          );
                        } else {
                          const selectedOptionBB = {
                            value: 0,
                            label: "BB",
                          };
                          this.state.projectTypeSelectedOption =
                            selectedOptionBB;
                          this.setState(
                            { projectTypeSelectedOption: selectedOptionBB },
                            () => {
                              //console.log(this.state.projectTypeSelectedOption)
                            }
                          );
                        }
                      }
                    }
                  );
                } else {
                  this.setState({ displayParentAlpha: "" }, () => { });
                }
              } else {
                this.setState({ displayParentAlpha: "" }, () => { });
              }

              if (
                parseInt(this.state.ideaItem.OwnerOHR) ===
                parseInt(this.sessionData.profile.oHRId) ||
                parseInt(this.state.ideaItem.BlackBeltUserId) ===
                parseInt(this.sessionData.profile.oHRId)
              ) {
                this.setState({ enableClusterAction: true }, () => { });
              }

              if (this.state.ideaItem.CreatedDate) {
                let created = new Date(this.state.ideaItem.CreatedDate);
                let abc = new Date(DISPLAYPROJECTTHEME);
                if (created.getTime() < abc.getTime()) {
                  //console.log("created date is lesser")
                  this.setState({ displayProjectTheme: true });
                } else {
                  this.setState({ displayProjectTheme: false });
                  //console.log("created date is greater ")
                }
              }

              //console.log("qcCommentsWithVD",this.state.qcCommentsWithVD)
              if (this.state.finalReviewComments) {
                var keys = Object.keys(this.state.finalReviewComments);
                var last = keys[keys.length - 1];
                let varFinalLastComment =
                  this.state.finalReviewComments[last].Comment;
                let varLastStatus =
                  this.state.finalReviewComments[last].ApprovalStatus;
                this.setState(
                  {
                    lastFinalComment: varFinalLastComment,
                    lastApprovalStatus: varLastStatus,
                  },
                  () => {
                    if (
                      this.state.lastApprovalStatus ===
                      "Awaiting Project Review"
                    ) {
                      this.setState({ lastApprovalStatus: "Approved" });
                    } else {
                      this.setState({ lastApprovalStatus: varLastStatus });
                    }
                    console.log(
                      "last approval status =>",
                      this.state.lastApprovalStatus
                    );
                  }
                );
              }
              if (this.state.qcComments) {
                var keys = Object.keys(this.state.qcComments);
                var last = keys[keys.length - 1];
                let varFinalLastComment = this.state.qcComments[last].Comment;
                this.setState({ lastQCCOmment: varFinalLastComment }, () => {
                  // console.log("last comment for qc=>",this.state.lastQCCOmment);
                });
              }

              if (this.state.reviewComments) {
                let logAction = this.state.reviewComments.slice(-1)[0].Action;
                this.setState(
                  {
                    logAction: logAction,
                  },
                  () => {
                    // console.log("log action ", this.state.logAction);
                  }
                );
              }

              if (
                this.state.IsVDType === "" ||
                this.state.IsVDType === null ||
                this.state.IsVDType === undefined
              ) {
                this.setState(
                  { showClientFields: false, showGOLFields: false },
                  () => { }
                );
              }

              if (
                this.state.IsVDType === "Value Delivered to Client + Genpact"
              ) {
                this.setState(
                  { showClientFields: true, showGOLFields: true },
                  () => { }
                );
              }
              if (this.state.IsVDType === "No Value Delivery") {
                this.setState(
                  { showClientFields: false, showGOLFields: false },
                  () => { }
                );
              }
              if (this.state.IsVDType === "Value Delivered to Client") {
                this.setState(
                  { showClientFields: true, showGOLFields: false },
                  () => { }
                );
              }
              if (this.state.IsVDType === "Value Delivered to Genpact") {
                this.setState(
                  { showClientFields: false, showGOLFields: true },
                  () => { }
                );
              }

              let dollarTotal = 0.0;
              let fteValue = 0.0;
              this.state.businessImpactValueArray.map((item) => {
                if (item && item.DollarImpact) {
                  dollarTotal += parseFloat(item.DollarImpact);
                }
                if (item && item.FTEImpact) {
                  fteValue += parseFloat(item.FTEImpact);
                }
                this.setState(
                  { FinalImpactDollar: dollarTotal, FinalImpactFTE: fteValue },
                  () => { }
                );
              });

              let sortedBiImpactvalueArray =
                this.state.businessImpactValueArray.sort((a, b) =>
                  a.ImpactType.toLowerCase().localeCompare(
                    b.ImpactType.toLowerCase()
                  )
                );
              this.setState(
                { businessImpactValueArray: sortedBiImpactvalueArray },
                () => { }
              );

              let myArray = [...this.state.businessImpactValueArray];
              const groupedData = myArray.reduce(
                (accumulator, currentValue) => {
                  const ImpactType = currentValue.ImpactType;
                  const MonetizableType = currentValue.MonetizableType;
                  const FTEImpact = parseFloat(currentValue.FTEImpact) || 0.0;
                  const DollarImpact =
                    parseFloat(currentValue.DollarImpact) || 0.0;

                  const existingRow = accumulator.find(
                    (row) =>
                      row.ImpactType === ImpactType &&
                      row.MonetizableType === MonetizableType
                  );
                  if (existingRow) {
                    existingRow.FTEImpact += FTEImpact;
                    existingRow.DollarImpact += DollarImpact;
                  } else {
                    accumulator.push({
                      ImpactType,
                      MonetizableType,
                      FTEImpact,
                      DollarImpact,
                    });
                  }
                  return accumulator;
                },
                []
              );

              let sortedSummaryIndex = groupedData.sort((a, b) =>
                a.ImpactType.toLowerCase().localeCompare(
                  b.ImpactType.toLowerCase()
                )
              );
              this.setState({ summaryArray: sortedSummaryIndex }, () => { });

              // if(this.state.ideaItem.ApprovalStatus !== "Pending Documents" && this.state.ideaItem.IsVDMonetizable==="Yes" && this.state.ideaItem.MonetizationStatus==="Approved by Client"){
              //     if(this.state.ideaItem.MonetizationStatus==="Approved by Client"){
              //         let MonetizedBiArray =[];
              //         this.state.businessImpactValueArray.filter((item)=>{
              //             if(item.IsMonetized==="Yes" ){
              //                 MonetizedBiArray.push(item)
              //             }
              //         })
              //         if(MonetizedBiArray){
              //             const monetizedGroup = MonetizedBiArray.reduce((accumulator, currentValue) =>
              //             {
              //                 const ImpactType = currentValue.ImpactType;
              //                 const FTEImpact = parseFloat(currentValue.FTEImpact) || 0.0;
              //                 const DollarImpact = parseFloat(currentValue.DollarImpact) || 0.0;
              //                 const existingRow = accumulator.find(row => row.ImpactType === ImpactType );
              //                 if (existingRow)
              //                 {
              //                     existingRow.FTEImpact += FTEImpact
              //                     existingRow.DollarImpact += DollarImpact
              //                 }
              //                 else
              //                 {
              //                     accumulator.push({ ImpactType, FTEImpact, DollarImpact });
              //                 }
              //                 return accumulator;
              //             }, []);
              //             this.setState({
              //                         totalMonetizedFTE:monetizedGroup[0].FTEImpact,
              //                         totalMonetizedDollar:monetizedGroup[0].DollarImpact
              //                     },()=>{})
              //                     // console.log(this.state.totalMonetizedFTE,this.state.totalMonetizedDollar)
              //             resolve();
              //         }

              //     }
              // }



              resolve();
            }
          );
        })
        .catch((error) => {
          console.log(error)
          Toast.fail(
            "Error while fetching idea. Please try again!",
            3000,
            () => { }
          );
          resolve();
        });
    });
  };

  fetchMetadata = () => {
    return new Promise((resolve, reject) => {
      const param = {
        EntityType: "",
        EntityName: "",
      };
      const requestOptions = {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + this.accessToken,
        },
        body: JSON.stringify(param),
      };
      fetch(GET_METADATA, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          //console.log("meta data",data)
          this.setState(
            {
              adminUserList: data ? data.DataverseUserRole : [],
              businessImpactMasterList: data ? data.BIMasterDataVerseNew : [],
              campaignList: data ? data.Campaigns : [],
            },
            () => {
              this.state.adminUserList.map((item) => {
                if (
                  parseInt(this.sessionData.profile.oHRId) ===
                  parseInt(item.pg_ohr)
                ) {
                  //console.log("this is admin")
                  let userDataInfo = JSON.parse(item.pg_Role);
                  this.setState({
                    admin: true,
                    adminOHR: parseInt(item.pg_ohr),
                    userRole: userDataInfo.pg_role,
                    userRoleId: userDataInfo.pg_pgroleid,
                  });
                  return false;
                }
                return false;
              });

              if(this.state.ideaItem.CampaignName && this.state.campaignList){
                this.state.campaignList.map((campaign)=>{
                  if(campaign.CampaignName === this.state.ideaItem.CampaignName){
                    this.setState({campaignInsight:campaign.CampaignDescr},()=>{})
                  }
                })
                
              }



              // let nonAdminUser = [];
              // nonAdminUser = data.DataverseUserRole;
              // nonAdminUser.map((item)=>{
              //     console.log(item.pg_Role)
              //     console.log(item.pg_isadmin)
              //     console.log(item.pg_ohr)
              //     console.log(item.pg_username)
              // })
              resolve();
            }
          );
        })
        .catch((error) => {
          Toast.fail(
            "Error occured while getting metadata idea. Please try again!",
            3000,
            () => {
              console.log(JSON.stringify(error));
              this.setState(
                {
                  businessImpactMasterList: [],
                },
                () => {
                  resolve();
                }
              );
            }
          );
        });
    });
  };

  saveEvent = () => {
    let redirectURL = "/review-page";
    if (this.state.approvalPage) {
      if (this.state.isViewAllProject) {
        redirectURL = "/view-all-projects-page";
        this.props.history.push({
          pathname: redirectURL,
          propsList: this.state.propsData,
        });
      } else if (this.state.isViewAllIdea) {
        redirectURL = "/view-all-ideas-page";
        this.props.history.push({
          pathname: redirectURL,
          propsList: this.state.propsData,
        });
      } else if (this.state.approvalPage === PROJECT_VIEW) {
        redirectURL = "/list-projects-page";
        // this.props.history.push(redirectURL);
        this.props.history.push({
          pathname: redirectURL,
          propsList: this.state.propsData,
        });
      } else {
        // redirectURL = redirectURL +"?page=" +this.state.approvalPage;
        // this.props.history.push(redirectURL);
        this.props.history.push({
          pathname: redirectURL,
          search: "?page=" + this.state.approvalPage,
          propsList: this.state.propsData,
        });
      }
    } else {
      // this.props.history.push(redirectURL);
      this.props.history.push({
        pathname: redirectURL,
        propsList: this.state.propsData,
      });
    }
  };

  // showPPTContent = () => {
  //     Toast.loading("Fetching Project PPT Deck content...", () => { });
  //     this.fetchPPTContent()
  //     .then(()=>{
  //         var fileData = this.state.projectDeckContent;
  //         var fileName = this.state.projectDeckFilename;
  //         var w = window.open("");
  //         const downloadLink = w.document.createElement("a");
  //         downloadLink.href = fileData;
  //         downloadLink.download = fileName;
  //         downloadLink.click();
  //         Toast.hide();
  //     });
  // }

  showPPTContent = async () => {
    Toast.loading("Fetching Project PPT Deck content...", () => { });
    try {
      await this.fetchPPTContent();
      const fileData = this.state.projectDeckContent;
      const fileName = this.state.projectDeckFilename;
      const newWindow = window.open("");
      const downloadLink = newWindow.document.createElement("a");
      downloadLink.href = fileData;
      downloadLink.download = fileName;
      downloadLink.click();
      Toast.hide();
    } catch (error) {
      // Handle error appropriately
      console.error("Error fetching or displaying PPT content:", error);
      Toast.hide();
    }
  };

  showSignOffContent = () => {
    Toast.loading("Fetching Customer Sign-off content...", () => { });
    this.fetchSignoffContent().then(() => {
      var fileData = this.state.signOffContent;
      var fileName = this.state.signOffFilename;
      var w = window.open("");
      const downloadLink = w.document.createElement("a");
      downloadLink.href = fileData;
      downloadLink.download = fileName;
      downloadLink.click();
      Toast.hide();
    });
  };

  validate = () => {
    let isValid = true;
    let styleStateObj = this.state.selectStyle;

    if (
      this.state.admin &&
      this.state.ideaItem.CertificationLevelName === "Lean"
    ) {
      if (
        this.state.ideaItem.TransformationLeverName === "Data" ||
        this.state.ideaItem.TransformationLeverName === "Continuous Improvement"
      ) {
        if (this.state.leanIdeaSelectedOption === null) {
          // Toast.fail("Please select Lean/Lean+idea.", 3000);
          // isValid = false;
          styleStateObj.leanIdeaSelectedOption = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.leanIdeaSelectedOption = {
            ...textBoxValidation(true),
            border: "1px solid lightgrey",
          };
          this.setState({
            selectStyle: styleStateObj,
          });
        }
      }
    }
    if (!this.state.Comment || this.state.Comment === "") {
      isValid = false;
      styleStateObj.comment = textBoxValidation(false);
      this.setState({
        selectStyle: styleStateObj,
      });
    } else {
      if (this.state.Comment.length > 500) {
        Toast.fail(
          "Comment cannot be more than 500 characters, please change and resubmit.",
          3000
        );
        isValid = false;
        styleStateObj.comment = textBoxValidation(false);
        this.setState({
          selectStyle: styleStateObj,
        });
      } else {
        styleStateObj.comment = {
          ...textBoxValidation(true),
          border: "1px solid lightgrey",
        };
        this.setState({
          selectStyle: styleStateObj,
        });
      }
    }

    // if(this.state.showCustomerClient){
    //     if(this.state.sowID === ""){
    //         isValid = false;
    //         styleStateObj.sowID = textBoxValidation(false);
    //         this.setState({selectStyle: styleStateObj})
    //     }else{
    //         styleStateObj.sowID = textBoxValidation(true);
    //         this.setState({selectStyle: styleStateObj})
    //     }

    //     if ((this.state.fpnaOhr !== null && this.state.fpnaOhr !== "") && (this.state.fpnaName === "" || this.state.fpnaName === null)) {
    //         isValid = false;
    //         styleStateObj.fpnaName = textBoxValidation(false);
    //         this.setState({ selectStyle: styleStateObj })
    //     } else {
    //         styleStateObj.fpnaName = textBoxValidation(true);
    //         this.setState({ selectStyle: styleStateObj })
    //     }

    //     if(this.state.filename1 === null || this.state.filename1 === undefined || this.state.filename1 === ""){
    //         isValid = false;
    //         Toast.fail("Please upload the GOL / FP&A Approval Document",3000,()=>{})
    //     }
    // }


    return isValid;
  };

  handleCommentChange = (e) => {
    let commentChange = e;
    this.setState({
      Comment: commentChange,
    });
  };
  handleTranscriptCancelRequest = (e) => {
    console.log(e);
    let commentChange = e.trim();
    this.setState({
      Comment: commentChange,
    });
  };
  handleTranscriptReinitiateRequest = (e) => {
    let commentChange = e;
    this.setState({
      Comment: commentChange,
    });
  };

  submitEvent = () => {
    if (this.validate()) {
      let param = {};
      if (window.confirm("Are you sure you want to cancel this Idea?")) {
        Toast.loading("Cancellation is in process...", () => { });
        if (this.state.admin) {
          param = {
            IdeaId: this.props.location.state,
            ApprovalStatus: this.state.isLeanIdea
              ? "Cancelled"
              : this.state.ideaItem.ApprovalStatus === "NA"
                ? this.state.ideaItem.ApprovalStatus
                : "Cancelled",
            UpdaterOHR: this.sessionData.profile.oHRId,
            UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
            UpdaterName:
              this.sessionData.profile.lastName +
              ", " +
              this.sessionData.profile.firstName,
            comment: this.state.Comment,
            WorkflowStatus: this.state.isLeanIdea
              ? "Cancelled"
              : this.state.ideaItem.ApprovalStatus === "NA"
                ? "Cancelled"
                : this.state.ideaItem.WorkflowStatus,
            ProjectStatus: "Admin Cancel",
            Role: this.state.userRole,
            userRoleId: this.state.userRoleId,
            updaterAction: "Cancellation Requested",
          };
        } else {
          param = {
            IdeaId: this.props.location.state,
            ApprovalStatus:
              this.state.ideaItem.ApprovalStatus === "NA"
                ? this.state.ideaItem.ApprovalStatus
                : REQUEST_CANCELLATION,
            UpdaterOHR: this.sessionData.profile.oHRId,
            UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
            UpdaterName:
              this.sessionData.profile.lastName +
              ", " +
              this.sessionData.profile.firstName,
            comment: this.state.Comment,
            WorkflowStatus:
              this.state.ideaItem.ApprovalStatus === "NA"
                ? REQUEST_CANCELLATION
                : this.state.ideaItem.WorkflowStatus,
            //userRole:"User",
            //userRoleId:this.state.userRoleId,
            Role: "Idea Owner",
            updaterAction: "Cancellation Requested",
          };
        }
        console.log(param);
        let finalJsonData = Checksum(param);
        //console.log("final Json Data",finalJsonData );
        const requestOptions = {
          method: "PUT",
          headers: { 
            "Content-Type": "application/json",
            "Authorization":  "Bearer " + this.accessToken,
        },
          body: JSON.stringify(param),
        };
        fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            Toast.hide();
            Toast.success(
              this.state.admin
                ? "Idea has been cancelled successfully."
                : "Cancel request sent successfully.",
              3000,
              () => {
                console.log(JSON.stringify(data));
                let redirectURL = "/review-page";
                if (this.state.approvalPage) {
                  redirectURL =
                    redirectURL + "?page=" + this.state.approvalPage;
                }
                window.location = this.state.admin
                  ? "/view-all-ideas-page"
                  : redirectURL;
              }
            );
          });
      }
    } else {
      Toast.fail("Please add reason for cancel request.", 3000, () => { });
    }
  };

  submitIdea = () => {
    if (this.validate()) {
      let param = {};
      if (
        window.confirm("Are you sure you want to update the Client documents?")
      ) {
        Toast.loading("Updating idea...", () => { });
        param = {
          IdeaId: this.props.location.state,
          Role: "Idea Owner",
          sowID: this.state.sowID,
          fpnaOHR: this.state.fpnaOhr,
          fpnaName: this.state.fpnaName,
          filename1: this.state.filename1,
        };
        console.log(param);
        let finalJsonData = Checksum(param);
        const requestOptions = {
          method: "PUT",
          headers: { 
            "Content-Type": "application/json",
            "Authorization":  "Bearer " + this.accessToken,
        },
          body: JSON.stringify(param),
        };
        fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            Toast.hide();
            Toast.success("Idea added successfully", 3000, () => {
              console.log(JSON.stringify(data));
              let redirectURL = "/review-page";
              if (this.state.approvalPage) {
                redirectURL = redirectURL + "?page=" + this.state.approvalPage;
              }
              window.location = redirectURL;
            });
          });
      }
    }
  };

  cancelRequest = () => {
    Toast.info(
      "Once cancelled you cannot have the project reinitiated. You will need to start the project from scratch.",
      5000,
      () => { }
    );
    this.setState({
      cancelRequest: !this.state.cancelRequest,
      Comment: "",
    });
  };

  handleIdeaAction = (ideaId) => {
    this.props.history.push({
      pathname: "/admin-data-edit",
      state: ideaId,
    });
  };

  handleActiveIdeaAction = () => {
    this.setState({
      activeIdea: !this.state.activeIdea,
      Comment: "",
    });
  };

  activeIdeaEvent = () => {
    if (window.confirm("Are you sure you want to reinitiate this Idea?")) {
      Toast.loading("Reinitiating Idea...", () => { });
      let param = {
        IdeaId: this.props.location.state,
        IdeaName: this.state.ideaItem.IdeaName,
        UpdaterOHR: this.sessionData.profile.oHRId,
        UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
        UpdaterName:
          this.sessionData.profile.lastName +
          ", " +
          this.sessionData.profile.firstName,
        WorkflowStatus: this.state.ideaItem.PrevWorkflowStatus,
        ApprovalStatus: this.state.ideaItem.PrevApprovalStatus,
        SaveType: "Update",
        Action: "Active",
        Comment: this.state.Comment,
        updaterAction: "Active",
      };
      console.log("Param", param);
      // return;
      let finalJsonData = Checksum(param);
      let str1 = finalJsonData.slice(0, 15);
      let str2 = finalJsonData.slice(15, 30);
      let str3 = finalJsonData.slice(30, 40);
      let str4 = finalJsonData.slice(-10);
      let finalData = str3 + str1 + str4 + str2;
      console.log("final data", finalData);
      const requestOptions = {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + this.accessToken,
      },
        body: JSON.stringify(param),
      };
      fetch(`${SUBMIT_IDEA_URL}Checksum=${finalData}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          Toast.hide();
          Toast.success("Idea reinitiated successfully.", 3000, () => {
            console.log(JSON.stringify(data));
            window.location = "/view-all-ideas-page";
          });
        });
      return false;
    }
  };

  renderLeanIdeaOptions = () => {
    let options = [];
    this.state.leanIdeaSelectedOptionList.map((item) => {
      options.push({
        value: item.value,
        label: item.label,
      });
      return options;
    });
    return options;
  };

  handleLeanIdea = (e) => {
    this.setState(
      {
        leanIdea: e.label,
        leanIdeaSelectedOption: {
          value: e.value,
          label: e.label,
        },
      },
      () => {
        if (this.state.leanIdea === "Lean+Idea") {
          this.setState({ isLeanIdea: true });
        }
      }
    );
  };

  toggleTranscriptFunction = (id) => {
    this.setState({ speechModal: !this.state.speechModal, modalIndex: id });
    if (id === 3) {
      // On cancel request comment
      if (this.state.Comment) {
        this.setState({ previousTextForSpeech: this.state.Comment }, () =>
          console.log(this.state.previousTextForSpeech)
        );
      } else {
        this.setState({ previousTextForSpeech: "" }, () =>
          console.log(this.state.previousTextForSpeech)
        );
      }
    }

    if (id === 4) {
      // On Reinitiate idea
      if (this.state.Comment) {
        this.setState({ previousTextForSpeech: this.state.Comment }, () =>
          console.log(this.state.previousTextForSpeech)
        );
      } else {
        this.setState({ previousTextForSpeech: "" }, () =>
          console.log(this.state.previousTextForSpeech)
        );
      }
    }
  };

  closeModalFunction = () => {
    this.setState({ speechModal: false });
  };
  renderMonetizableOptions = () => {
    let options = [];
    this.state.postMonetizableOptions.map((item) => {
      options.push({
        value: item.value,
        label: item.label,
      });
      return options;
    });
    return options;
  };

  newBusinessImpactSummary() {
    let myArray = [...this.state.businessImpactValueArray];
    const groupedData = myArray.reduce((accumulator, currentValue) => {
      const impactType = currentValue.impactType;
      const monetizableType = currentValue.monetizableType;
      const fteValue = parseFloat(currentValue.fteValue) || 0.0;
      const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;

      const existingRow = accumulator.find(
        (row) =>
          row.impactType === impactType &&
          row.monetizableType === monetizableType
      );
      if (existingRow) {
        existingRow.fteValue += fteValue;
        existingRow.dollarValue += dollarValue;
      } else {
        accumulator.push({
          impactType,
          monetizableType,
          fteValue,
          dollarValue,
        });
      }
      return accumulator;
    }, []);
    this.setState({ summaryArray: groupedData }, () => { });
    this.setState(
      { businessImpactValueArray: this.state.businessImpactValueArray },
      () => { }
    );
    this.setState({ isFpnaRequired: false }, () => { });
    this.state.businessImpactValueArray &&
      this.state.businessImpactValueArray.map((item) => {
        if (item.ImpactType === "Impact at Genpact") {
          if (
            item.MonetizableType === "Increase in Revenue" ||
            item.MonetizableType === "Reduction in Cost"
          ) {
            this.setState({ isFpnaRequired: true }, () => { });
          }
        }
      });
  }

  pull_data = ({ businessImpactValueArray, dollarTotal, fteTotal }) => {
    if (businessImpactValueArray) {
      this.setState(
        { businessImpactValueArray: businessImpactValueArray },
        () => {
          this.newBusinessImpactSummary();
        },
        () => { }
      );
    }
    if (dollarTotal) {
      this.setState({ finalImpactDollar: dollarTotal }, () => { });
    }
    if (fteTotal) {
      this.setState({ finalImpactFTE: fteTotal }, () => { });
    }
  };
  pull_data_for_delete = ({
    businessImpactValueArray,
    dollarTotal,
    fteValue,
  }) => {
    if (businessImpactValueArray) {
      this.setState(
        { businessImpactValueArray: businessImpactValueArray },
        () => {
          this.newBusinessImpactSummary();
        }
      );
    }
    if (dollarTotal) {
      this.setState({ finalImpactDollar: dollarTotal }, () => {
        console.log(
          "inside call back function $ total",
          this.state.finalImpactDollar
        );
      });
    }
    if (fteValue) {
      this.setState({ finalImpactFTE: fteValue }, () => {
        console.log(
          "inside call back function fte value",
          this.state.finalImpactFTE
        );
      });
    }
  };
  pull_file_data = ({
    fileName,
    fileType,
    fileContent,
    showClientApprovalFields,
    FPnAOHRMonetization,
  }) => {
    if (fileName) {
      this.setState({ FPnAFileNameMonetization: fileName }, () => { });
    }
    if (fileType) {
      this.setState({ FPnAFileTypeMonetization: fileType }, () => { });
    }
    if (fileContent) {
      this.setState({ FPnAFileMonetization: fileContent }, () => { });
    }
    this.setState(
      { showClientApprovalFields: showClientApprovalFields },
      () => { }
    );
    this.setState({ FPnAOHRMonetization: FPnAOHRMonetization }, () => {
      // console.log("ohr from pull_file_data function =>",this.state.FPnAOHRMonetization)
    });
  };
  remove_bi_data_after_delete = (filteredIdeaArray) => {
    //console.log("bi recieved in pull data for delete function",filteredIdeaArray)
    if (filteredIdeaArray) {
      this.setState({ businessImpactValueArray: filteredIdeaArray }, () => {
        this.newBusinessImpactSummary();
        let dollarTotal = 0.0;
        let fteValue = 0.0;
        this.state.businessImpactValueArray.map((item) => {
          if (item && item.DollarImpact) {
            dollarTotal += parseFloat(item.DollarImpact);
          }
          if (item && item.dollarValue) {
            dollarTotal += parseFloat(item.dollarValue);
          }
          if (item && item.FTEImpact) {
            fteValue += parseFloat(item.FTEImpact);
          }
          if (item && item.fteValue) {
            fteValue += parseFloat(item.fteValue);
          }
        });
        this.setState({ finalImpactDollar: dollarTotal }, () => {
          //console.log("inside call back function $ total",this.state.finalImpactDollar)
        });
        this.setState({ finalImpactFTE: fteValue }, () => {
          // console.log("inside call back function fte value",this.state.finalImpactFTE)
        });
      });
    }
  };

  pull_monetization_sowId = (localSowId) => {
    this.setState({ SowID: localSowId }, () => {
      //console.log("sow id from pull_monetization_sowId function =>",this.state.SowID)
    });
  };
  pull_monetization_fpna_name = (localFpnaName) => {
    //console.log("fpna name from pull file function => ",localFpnaName)
    this.setState({ FPnANameMonetization: localFpnaName }, () => {
      //console.log("fpna name from pull_file_function",this.state.FPnANameMonetization)
    });
  };
  pull_monetization_fpna_ohr = (localFpnaohr) => {
    //console.log("fpna ohr from pull function => ",localFpnaohr)
    this.setState({ FPnAOHRMonetization: localFpnaohr }, () => { });
  };
  pull_monetizedTotal = ({ fteValue, dollarValue }) => {
    if (fteValue) {
      this.setState({ totalMonetizedDollar: fteValue }, () => {
        //console.log(this.state.totalMonetizedDollar)
      });
    }
    if (dollarValue) {
      this.setState({ totalMonetizedFTE: dollarValue }, () => {
        //console.log(this.state.totalMonetizedFTE)
      });
    }
  };
  pull_monetizableProject = (e) => {
    if (e) {
      this.setState({ monetizableProjectSelectedOption: e }, () => {
        //console.log("monetization project Option =>",this.state.monetizableProjectSelectedOption)
      });
    }
  };
  pull_monetizableStatus = (e) => {
    //console.log("monetizable status is=> ",e)
    this.setState({ monetizationStatusSelectedOption: e }, () => {
      //console.log("monetization Status SelectedOption =>",this.state.monetizationStatusSelectedOption)
    });
  };

  handleVDTypeChange = ({ e }) => {
    if (e) {
      this.setState({ impactOptionSelected: e }, () => {
        console.log("impact selected option is from review idea page",this.state.impactOptionSelected)
      });
    }
  };
  fetchFPAOHRforIncreaseInRevenue = (e) => {
    if (e === true) {
      this.setState({ appropriateAttachment: true }, () => { });
    } else {
      this.setState({ appropriateAttachment: false }, () => { });
    }
  };

  pullMonetizationGOLName = (localGOLName) => {
    this.setState({ golMonetizationName: localGOLName }, () => {
      //console.log(this.state.golMonetizationName)
    });
  };
  pullMonetizationGOLOhr = (localGOLOhr) => {
    this.setState({ golMonetizationOhr: localGOLOhr }, () => {
      //console.log(this.state.golMonetizationOhr)
    });
  };

  // function for FPNAGOL component starts here

  // ppt deck file details
  pullPPTDeck = ({ fileName, fileType, fileContent }) => {
    this.setState(
      { filename1: fileName, fileType1: fileType, base64File1: fileContent },
      () => {
        //console.log("filename ",this.state.filename1, "file type ",this.state.fileType1, "content ",this.state.base64File1)
      }
    );
  };
  pullClientDocName = (fileName) => {
    this.setState({ signOffFilename: fileName }, () => {
      //console.log("client approval name =>",this.state.signOffFilename)
    });
  };
  pullClientDocType = (fileType) => {
    if (fileType) {
      this.setState({ signOffFileType: fileType }, () => {
        //console.log("client approval type =>",this.state.signOffFileType)
      });
    }
  };
  pullClientDocContent = (fileContent) => {
    if (fileContent) {
      this.setState({ signOffContent: fileContent }, () => {
        //console.log("client approval contnet =>",this.state.signOffContent)
      });
    }
  };

  // gol file details
  pullgolFileName = (fileName) => {
    this.setState({ filename3: fileName }, () => {
      //console.log("gol approval doc name",this.state.filename3)
    });
  };
  pullgolFileType = (fileType) => {
    this.setState({ fileType3: fileType }, () => {
      //console.log("gol approval doc type",this.state.fileType3)
    });
  };
  pullgolContent = (fileContent) => {
    this.setState({ base64File3: fileContent }, () => {
      //console.log("gol approval doc content",this.state.base64File3)
    });
  };

  // fpna file details
  pullfpnafileName = (fileName) => {
    if (fileName) {
      this.setState({ filename4: fileName }, () => {
        //console.log("fpna doc name",this.state.filename4)
      });
    }
  };
  pullfpnafileType = (fileType) => {
    if (fileType) {
      this.setState({ fileType4: fileType }, () => {
        //console.log("fpna doc type",this.state.fileType4)
      });
    }
  };
  pullfpnafileContent = (fileContent) => {
    if (fileContent) {
      this.setState({ base64File4: fileContent }, () => {
        //console.log("fpna doc file content",this.state.base64File4)
      });
    }
  };

  pullClientName = (e) => {
    if (e) {
      this.setState({ clientName: e }, () => {
        //console.log(this.state.clientName)
      });
    }
  };
  pullClientRole = (e) => {
    if (e) {
      this.setState({ clientRole: e }, () => {
        //console.log(this.state.clientRole)
      });
    }
  };
  pullClientSignOff = (e) => {
    if (e) {
      this.setState({ clientSignOffDate: e }, () => {
        //console.log(this.state.clientSignOffDate)
      });
    }
  };
  pullFpnaName = (name) => {
    if (name) {
      this.setState({ fpnaName: name }, () => {
        //console.log(this.state.fpnaName)
      });
    }
  };
  pullGolName = (name) => {
    if (name) {
      this.setState({ golFullname: name }, () => {
        //console.log(this.state.golFullname)
      });
    }
  };
  pullFpnaOhr = (e) => {
    if (e) {
      this.setState({ fpnaOhr: e }, () => {
        //console.log(this.state.fpnaOhr)
      });
    }
  };
  pullGolOhr = (e) => {
    if (e) {
      this.setState({ golOhr: e }, () => {
        //console.log(this.state.golOhr)
      });
    }
  };
  pull_fileDetails = (obj) => {
    this.setState({ golFpnaFileDetails: obj }, () => {
      //console.log(this.state.golFpnaFileDetails)
    });
  };

  // function for FPNAGOL component ends here

  // clustering function starts here

  showClusterModal = () => {
    if (
      this.state.ideaItem.LeanID !== "" ||
      this.state.ideaItem.LeanID !== null ||
      this.state.ideaItem.LeanID !== undefined ||
      !this.state.ideaItem.LeanID
    ) {
      this.setState({
        clusterModal: !this.state.clusterModal,
      });
    }
  };
  changeProjectType = (e) => {
    this.setState({ projectIDSelectedOption: null }, () => { });
    if (e) {
      this.setState({ projectTypeSelectedOption: e }, () => {
        Toast.loading("Fetching Cluster Projects...", () => { });
        const requestOptions = {
          method: "GET",
          headers: { 
            "Content-Type": "application/json",
            "Authorization":  "Bearer " + this.accessToken,
        },
        };
        let encodedAccount = encodeURIComponent(
          this.state.ideaItem.AccountManual
        );
        let encodedVertical = encodeURIComponent(
          this.state.ideaItem.VerticalManual
        );
        fetch(
          `${GET_IDEA_URL}Account=${encodedAccount}&Vertical=${encodedVertical}&ProjectType=${this.state.projectTypeSelectedOption.label}&case=new&casetype=fetchclusterprojects&mucid=muc001`,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("fetch cluster data on project type change ", data);
            this.setState(
              {
                clustorProjects: data.ClusterProjects,
              },
              () => { }
            );
            Toast.hide();
          })
          .catch((error) => {
            Toast.fail(
              "Error while fetching cluster projects. Please try again!",
              3000,
              () => { }
            );
            Toast.hide();
            console.log(error);
          });
      });
    }
  };

  displayProjectID = () => {
    if (this.state.clustorProjects) {
      let options = [];
      if (this.state.clusterOptionSearched) {
        this.state.clustorProjects.map((item, index) => {
          options.push({
            value: index,
            Display: item.pg_projectid,
            label: item.pg_projectid + " - " + item.pg_projowner,
          });
        });
      } else {
        this.state.clustorProjects.map((item, index) => {
          if (index < 19) {
            options.push({
              value: index,
              Display: item.pg_projectid,
              label: item.pg_projectid + " - " + item.pg_projowner,
            });
          }
        });
      }
      return options;
    }
  };

  handleClusterProjectInputChange = (inputValue) => {
    if (inputValue && inputValue.length > 0) {
      this.setState({ clusterOptionSearched: true }, () => { });
    } else {
      this.setState({ clusterOptionSearched: false }, () => { });
    }
  };

  changeProjectID = (e) => {
    if (e) {
      // let splitingValue = e.label;
      // const [id, name] = splitingValue.split(' - ');
      // let options = [];
      // options.push({
      //     value: e.value,
      //     Display: id,
      //     label: e.label,
      // });
      this.setState(
        { projectIDSelectedOption: e, isClusterSaveDisabled: false },
        () => {
          console.log(
            "selected project type at change project ID",
            this.state.projectIDSelectedOption
          );
          this.state.clustorProjects.map((item, index) => {
            if (
              item.pg_projectid === this.state.projectIDSelectedOption.Display
            ) {
              console.log(
                "matched item at change project ID",
                item.pg_projectid
              );
              this.setState({ guid: item.msdyn_projectid }, () => { });
            }
          });
        }
      );
    }
  };

  saveParentLink = async () => {
    const createdDate = new Date(this.state.ideaItem.CompleteDate);
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate - createdDate;
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    if (
      differenceInDays > 90 &&
      this.state.ideaItem.ApprovalStatus &&
      this.state.ideaItem.ApprovalStatus === "Completed"
    ) {
      Toast.fail(
        "The parent project cannot be updated because it was completed more than three months ago",
        3000,
        () => { }
      );
      return false;
    } else {
      if (
        this.state.ideaItem.ApprovalStatus &&
        (this.state.ideaItem.ApprovalStatus !== "Rejected" ||
          this.state.ideaItem.ApprovalStatus !== "Cancelled") &&
        this.state.projectTypeSelectedOption &&
        this.state.projectIDSelectedOption
      ) {
        if (
          window.confirm(
            "Are you sure you'd like to add Project ID: " +
            this.state.projectIDSelectedOption.Display +
            " as the Parent Project ID?"
          )
        ) {
          if (
            parseInt(this.state.ideaItem.OwnerOHR) ===
            parseInt(this.sessionData.profile.oHRId) ||
            parseInt(this.state.ideaItem.BlackBeltUserId) ===
            parseInt(this.sessionData.profile.oHRId)
          ) {
            if (
              this.state.projectTypeSelectedOption &&
              this.state.projectIDSelectedOption
            ) {
              Toast.loading("Linking...", () => { });
              let param = {
                case: "new",
                casetype: "updatecluster",
                ParentProjectType: this.state.projectTypeSelectedOption
                  ? this.state.projectTypeSelectedOption.label
                  : "",
                RecID: this.props.location.state,
                RecAlpha: this.state.ideaItem.LeanID,
                ParentRecID: this.state.guid,
                ParentAlpha: this.state.projectIDSelectedOption
                  ? this.state.projectIDSelectedOption.Display
                  : "",
                UpdatedByName: this.sessionData
                  ? this.sessionData.profile.lastName +
                  ", " +
                  this.sessionData.profile.firstName
                  : "",
                UpdatedByOHR: this.sessionData
                  ? this.sessionData.profile.oHRId
                  : " ",
                Vertical: this.state.ideaItem
                  ? this.state.ideaItem.VerticalManual
                  : "",
                Account: this.state.ideaItem
                  ? this.state.ideaItem.AccountManual
                  : "",
              };
              console.log(param);
              let finalJsonData = Checksum(param);
              const requestOptions = {
                method: "PUT",
                headers: { 
                  "Content-Type": "application/json",
                  "Authorization":  "Bearer " + this.accessToken,
              },
                body: JSON.stringify(param),
              };
              fetch(
                `${DELEGATE_IDEA_URL}Checksum=${finalJsonData}`,
                requestOptions
              )
                .then((response) => response.json())
                .then((data) => {
                  Toast.success("Parent linked successfully!", 5000, () => {
                    this.setState({
                      clusterModal: !this.state.clusterModal,
                    });
                    this.updateClusterData();
                  });
                  this.setState({ isClusterSaveDisabled: true }, () => { });
                })
                .catch((error) => {
                  console.log(error);
                  Toast.fail("Parent linked failed", 3000, () => { });
                });
            } else {
              Toast.fail(
                "Please select project type & project ID",
                3000,
                () => { }
              );
            }
          } else {
            Toast.fail(
              "Only idea owner & idea BB can link the ideas",
              3000,
              () => { }
            );
          }
        }
      } else {
        Toast.fail("Rejected ideas cannot be linked.", 3000, () => { });
      }
    }
  };
  removeParentFunc = () => {
    const createdDate = new Date(this.state.ideaItem.CompleteDate);
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate - createdDate;
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    if (
      differenceInDays > 90 &&
      this.state.ideaItem.ApprovalStatus &&
      this.state.ideaItem.ApprovalStatus === "Completed"
    ) {
      Toast.fail(
        "The parent project cannot be updated because it was completed more than three months ago",
        3000,
        () => { }
      );
      return false;
    } else {
      if (
        this.state.ideaItem.ApprovalStatus &&
        (this.state.ideaItem.ApprovalStatus !== "Rejected" ||
          this.state.ideaItem.ApprovalStatus !== "Cancelled")
      ) {
        if (
          parseInt(this.state.ideaItem.OwnerOHR) ===
          parseInt(this.sessionData.profile.oHRId) ||
          parseInt(this.state.ideaItem.BlackBeltUserId) ===
          parseInt(this.sessionData.profile.oHRId)
        ) {
          if (!this.state.clusterData || this.state.clusterData.length === 0) {
            if (
              (this.state.projectTypeSelectedOption === null ||
                this.state.projectTypeSelectedOption === undefined ||
                !this.state.projectTypeSelectedOption) &&
              (this.state.projectIDSelectedOption === null ||
                this.state.projectIDSelectedOption === undefined ||
                !this.state.projectIDSelectedOption)
            ) {
              Toast.fail(
                "Only linked ideas can only be removed",
                3000,
                () => { }
              );
            }
          }
          if (this.state.clusterData && this.state.clusterData.length > 0) {
            if (
              this.state.projectTypeSelectedOption &&
              this.state.projectIDSelectedOption
            ) {
              if (
                window.confirm(
                  "Are you sure you'd like to remove Project ID: " +
                  this.state.projectIDSelectedOption.label +
                  " as the Parent Project ID?"
                )
              ) {
                Toast.loading("Removing parent link...", () => { });
                let param = {
                  case: "new",
                  casetype: "updatecluster",
                  RecID: this.props.location.state,
                  RecAlpha: this.state.ideaItem.LeanID,
                  ParentRecID: "",
                  ParentAlpha: "",
                  ParentProjectType: "",
                  UpdatedByName: this.sessionData
                    ? this.sessionData.profile.lastName +
                    ", " +
                    this.sessionData.profile.firstName
                    : "",
                  UpdatedByOHR: this.sessionData
                    ? this.sessionData.profile.oHRId
                    : " ",
                  Vertical: this.state.ideaItem
                    ? this.state.ideaItem.VerticalManual
                    : "",
                  Account: this.state.ideaItem
                    ? this.state.ideaItem.AccountManual
                    : "",
                };
                console.log(param);
                let finalJsonData = Checksum(param);
                const requestOptions = {
                  method: "PUT",
                  headers: { 
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
                  body: JSON.stringify(param),
                };
                fetch(
                  `${DELEGATE_IDEA_URL}Checksum=${finalJsonData}`,
                  requestOptions
                )
                  .then((response) => response.json())
                  .then((data) => {
                    Toast.success("Parent unlinked successfully!", 3000, () => {
                      this.setState({
                        clusterModal: !this.state.clusterModal,
                      });
                    });
                    this.updateClusterData();
                    this.setState(
                      {
                        projectTypeSelectedOption: null,
                        projectIDSelectedOption: null,
                      },
                      () => { }
                    );
                  })
                  .catch((error) => {
                    console.log(error);
                    Toast.fail("Parent unlinked failed", 3000, () => { });
                  });
              }
            }
          }
        } else {
          Toast.fail(
            "Only idea owner & idea BB can unlink the ideas",
            3000,
            () => { }
          );
        }
      } else {
        Toast.fail(
          "Rejected ideas cannot be unlinked / removed.",
          3000,
          () => { }
        );
      }
    }
  };
  updateClusterData() {
    const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json",
        "Authorization":  "Bearer " + this.accessToken,
    },
    };
    fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState(
          {
            ideaItem: data.ResultSets.Table1[0],
            clusterData: data.ClusterData.Table1 ? data.ClusterData.Table1 : [],
          },
          () => {
            if (this.state.clusterData && this.state.clusterData.length > 0) {
              const targetRecID = this.state.ideaItem.LeanID;
              const filteredData = this.state.clusterData.filter(
                (item) => item.RecAlpha === targetRecID
              );

              let getParentAlpa = "";
              this.state.clusterData.map((item) => {
                if (item.RecAlpha === targetRecID) {
                  getParentAlpa = item.ParentAlpha;
                }
              });

              if (getParentAlpa) {
                this.state.clusterData.map((item) => {
                  if (getParentAlpa === item.RecAlpha) {
                    const selectedOption = {
                      value: 0,
                      label: item.ProjectType,
                    };
                    this.state.projectTypeSelectedOption = selectedOption;
                    this.setState(
                      { projectTypeSelectedOption: selectedOption },
                      () => { }
                    );
                  }
                });
              }
              if (
                filteredData &&
                filteredData.length > 0 &&
                filteredData !== undefined &&
                filteredData !== null &&
                filteredData !== ""
              ) {
                this.setState(
                  { displayParentAlpha: filteredData[0].ParentAlpha },
                  () => { }
                );
                const selectedOption = {
                  value: 0,
                  label: filteredData[0].ParentAlpha,
                };
                this.setState(
                  { projectIDSelectedOption: selectedOption },
                  () => {
                    if (
                      this.state.projectTypeSelectedOption &&
                      (this.state.projectTypeSelectedOption.label === null ||
                        this.state.projectTypeSelectedOption.label === "")
                    ) {
                      if (
                        this.state.projectIDSelectedOption.label.startsWith(
                          "GB"
                        )
                      ) {
                        const selectedOptionGB = {
                          value: 0,
                          label: "GB",
                        };
                        this.state.projectTypeSelectedOption = selectedOptionGB;
                        this.setState(
                          { projectTypeSelectedOption: selectedOptionGB },
                          () => { }
                        );
                      } else {
                        const selectedOptionBB = {
                          value: 0,
                          label: "BB",
                        };
                        this.state.projectTypeSelectedOption = selectedOptionBB;
                        this.setState(
                          { projectTypeSelectedOption: selectedOptionBB },
                          () => { }
                        );
                      }
                    }
                  }
                );
              } else {
                this.setState({ displayParentAlpha: "" }, () => { });
              }
            } else {
              this.setState({ displayParentAlpha: "" }, () => { });
            }
          }
        );
      })
      .catch((error) => {
        Toast.fail(
          "Error while fetching idea. Please try again!",
          3000,
          () => { }
        );
        Toast.hide();
      });
  }
  // clustering function ends here

  getStatus = () => {
    if (this.state.ideaItem.WorkflowStatus) {
      let IDEA_APPROVED = this.state.ideaItem.WorkflowStatus === "Approve";
      let IDEA_REJECTED = this.state.ideaItem.WorkflowStatus === "Reject";
      let IDEA_SEND_BACK = this.state.ideaItem.WorkflowStatus === "Send Back";
      let IDEA_REQUEST_CANCELLATION =
        this.state.ideaItem.WorkflowStatus === "Cancellation Requested";
      let IDEA_REQUEST_CANCELLATION1 =
        this.state.ideaItem.ApprovalStatus === "Cancellation Requested";
      let IDEA_CANCELLED = this.state.ideaItem.WorkflowStatus === "Cancelled";
      let IDEA_CANCELLED1 = this.state.ideaItem.ApprovalStatus === "Cancelled";

      if (IDEA_APPROVED) return APPROVED;
      if (IDEA_REJECTED) return REJECTED;
      if (IDEA_SEND_BACK) return SENT_BACK;
      if (IDEA_REQUEST_CANCELLATION || IDEA_REQUEST_CANCELLATION1) {
        return "Cancellation Requested";
      }
      if (IDEA_CANCELLED || IDEA_CANCELLED1) return "Cancelled";
      if (this.state.ideaItem.Comment !== null) {
        if (this.state.logAction === "Idea Reinitiated by Admin") {
          return "Idea Reinitiated";
        } else {
          if (this.state.logAction === "Idea was reviewed") {
            if (this.state.reviewComments) {
              let updaterActionStatus = this.state.reviewComments.slice(-1)[0].UpdaterAction;
              return updaterActionStatus;
            }
          } 
          return this.state.ideaItem.WorkflowStatus
          // else {
          //   return "Discard Cancellation";
          // }
        }
      }

      return PENDING;
    }
  };

  handleAIDocumentClick = (link) => {
    window.open(link, "_blank");
  }

  // .... DB functions starts here......

  handledbProjectTypeChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.dbProjectType = selectStyle(true);
    this.setState(
      {
        dbProjectType: {
          value: e.value,
          label: e.label,
        },
        selectStyle: styleStateObj,
      },
      () => { }
    );
  };
  handleMUCIDChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.mucIDSelectedOption = selectStyle(true);
    this.setState(
      {
        mucIDSelectedOption: {
          value: e.value,
          label: e.label,
        },
        selectStyle: styleStateObj,
      },
      () => { }
    );
  };
  handledbToolUsedChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.dbToolUsed = selectStyle(true);
    this.setState(
      {
        dbToolUsed: {
          value: e.value,
          label: e.label,
        },
        selectStyle: styleStateObj,
        otherDBToolUsedText: []
      },
      () => { }
    );
  };
  handleOtherDBToolUsedChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    styleStateObj.otherDBToolUsedText = {
      ...textBoxValidation(true),
      border: "1px solid lightgray",
    };
    this.setState({ selectStyle: styleStateObj, otherDBToolUsedText: e });
  };

  fetchClientTestimonyContent = () => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: { 
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + this.accessToken,
      },
      };
      let clientTestimonyFileType = this.state.ideaItem.CTestimonyFileType;
      fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&clientTestimonycontent=true`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          const clientTestimonyFileMetadata = data.fpnaContent ? data.fpnaContent : "";
          const clientTestimonyFileContent = "data:" + clientTestimonyFileType + ";base64," + clientTestimonyFileMetadata;
          this.setState(
            {
              CTestimony: clientTestimonyFileContent,
            },
            () => {
              resolve();
              Toast.hide();
            }
          );
        })
        .catch((error) => {
          Toast.fail(
            "Error while Client Testimony file content. Please try again!",
            3000,
            () => { }
          );
          resolve();
        });
    });
  };
  downloadClientTestimony = () => {
    Toast.loading("Fetching Client Testimony content...", () => { });
    this.fetchClientTestimonyContent().then(() => {
      var fileData = this.state.CTestimony;
      var fileName = this.state.ideaItem.CTestimonyFileName;
      var w = window.open("");
      const downloadLink = w.document.createElement("a");
      downloadLink.href = fileData;
      downloadLink.download = fileName;
      downloadLink.click();
      Toast.hide();
    });
  };





  // LDT lead function starts here
  handleIsQBRChange = (e) => {
    return new Promise((resolve, reject) => {
      let showWarningMessageForOthers = false;
      Toast.loading("Fetching LDT Details", () => { })
      let sessionData = localStorage.getItem(USER_DATA) ? JSON.parse(localStorage.getItem(USER_DATA)) : "";
      const param = {
        EntityType: "OHR",
        EntityName: sessionData ? sessionData.profile ? sessionData.profile.oHRId : "" : "",
      };
      const requestOptions = {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Authorization":  "Bearer " + this.accessToken,
      },
        body: JSON.stringify(param),
      };
      fetch(GET_METADATA, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          const filteredData = data.PowerAppUsers.filter((item) => {
            const userRole = JSON.parse(item.pg_Role).pg_role;
            return userRole === "LDT Lead" || this.state.admin;
          });
          if(filteredData.length === 0){
            showWarningMessageForOthers=true
          }
          data.PowerAppUsers.filter((item) => {
            const userRole = JSON.parse(item.pg_Role).pg_role;
            if (userRole === "LDT Lead" || this.state.admin) {
              if (parseInt(sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
                this.setState({ QBRDate: "", LDTApproverName: "", LDTApproverOHR: "", LDTApproverEmail: "", ldtLeadApprovalSelectedOption: [], ldtApprovalComment: "", qbrChanged: true }, () => { })
                this.setState({ isLDTApprover: true, LDTApproverName: item.pg_username, LDTApproverOHR: item.pg_ohr }, () => {
                  let styleStateObj = this.state.selectStyle;
                  styleStateObj.IsQBR = selectStyle(true);
                  this.setState(
                    {
                      IsQBR: e,
                      selectStyle: styleStateObj,
                    },
                    () => {
                      
                    }
                  );
                  resolve();
                })
              }
            }
          });
          if (showWarningMessageForOthers === true){
            Toast.fail("User assigned with role of LDT Lead can only be allowed to change the Client QBR value.", 3000, () => { })
          }
          Toast.hide();
        })
        .catch((error) => {
          Toast.fail(
            "Error occured while getting LDT Details. Please try again!",
            3000,
            () => {
              console.log(JSON.stringify(error));
              this.setState(
                {

                },
                () => {
                  resolve();
                }
              );
            }
          );
        });
    });
    
  }
  handleQBRDateChange = (e) => {
    let styleStateObj = this.state.selectStyle;
    if (e !== null && e !== undefined) {
      if (new Date(e) > new Date()) {
        window.alert("QBR date cannot be greater than current date")
        this.setState({ QBRDate: "" }, () => { })
        styleStateObj.otherDBToolUsedText = selectStyle(false);
      } else {
        this.setState({ QBRDate: e }, () => {

        })
        styleStateObj.otherDBToolUsedText = selectStyle(true);
      }
    }
    this.setState({
      selectStyle: styleStateObj
    });



  }

  


  validateLDTApproval = () => {
    let isValid = true;
    let styleStateObj = this.state.selectStyle;

    if (this.state.ideaItem.ApprovalStatus && this.state.ideaItem.ApprovalStatus === "Completed") {
      if (this.state.qbrChanged === false) {
        isValid = false;
        Toast.fail("Please make Client QBR Field changes to approve", 3000, () => { })
      }
      if (this.state.IsQBR && this.state.IsQBR.label === "Yes") {
        if (this.state.ldtApprovalComment && this.state.ldtApprovalComment.length > 500) {
          Toast.fail(
            "Project Lead Comment cannot be more than 500 characters, please change and resubmit.",
            3000
          );
          isValid = false;
        }

        if (this.state.isLDTApprover === false && !this.state.admin) {
          isValid = false;
          Toast.fail("Client QBR is permitted only for User with role of LDT Lead", 3000, () => { })
        }
        if (
          this.state.QBRDate === "" ||
          this.state.QBRDate === null ||
          this.state.QBRDate === undefined ||
          !this.state.QBRDate
        ) {
          isValid = false;
          styleStateObj.QBRDate = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.QBRDate = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }

        if (
          this.state.LDTApproverName === "" ||
          this.state.LDTApproverName === null ||
          this.state.LDTApproverName === undefined ||
          !this.state.LDTApproverName || this.state.LDTApproverName.length === 0
        ) {
          isValid = false;
          styleStateObj.LDTApproverName = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.LDTApproverName = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }
        if (
          this.state.LDTApproverOHR === "" ||
          this.state.LDTApproverOHR === null ||
          this.state.LDTApproverOHR === undefined ||
          !this.state.LDTApproverOHR || this.state.LDTApproverOHR.length === 0
        ) {
          isValid = false;
          styleStateObj.LDTApproverOHR = textBoxValidation(false);
          this.setState({
            selectStyle: styleStateObj,
          });
        } else {
          styleStateObj.LDTApproverOHR = textBoxValidation(true);
          this.setState({
            selectStyle: styleStateObj,
          });
        }


      }
    }
    return isValid;
  }

  saveLDTApprovalDetails = () => {
    if (this.validateLDTApproval()) {
      const createdDate = new Date(this.state.ideaItem.CompleteDate);
      const currentDate = new Date();
      const differenceInMilliseconds = currentDate - createdDate;
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      if (differenceInDays > 100 && this.state.ideaItem.ApprovalStatus && this.state.ideaItem.ApprovalStatus === "Completed") {
        Toast.fail("The LDT Lead cannot approve because Idea was completed more than 100 days ago", 3000, () => { });
        return false;
      } else {
        if (window.confirm("Are you sure you want to save this entry")) {
          Toast.loading("Updating LDT Approval Details...", () => { });
          let param = {};
          param = {
            IdeaId: this.state.ideaItem.IdeaId,
            case: "new",
            casetype: "LDTApprovalForCompleted",
            UpdaterRole: this.state.admin ? "Admin" : "LDT Lead",
            UserOHR: this.state.LDTApproverOHR ? this.state.LDTApproverOHR : "",
            UserName: this.state.LDTApproverName ? this.state.LDTApproverName : "",
            IsQBR: this.state.IsQBR ? this.state.IsQBR.label : "",
            QBRDate: this.state.IsQBR && this.state.IsQBR.label === "Yes" ? (this.state.QBRDate ? this.state.QBRDate : "") : "",
            LDTComments: this.state.ldtApprovalComment ? this.state.ldtApprovalComment : "",
            updaterAction: this.state.ldtLeadApprovalSelectedOption
              ? this.state.ldtLeadApprovalSelectedOption.label === "Yes"
                ? "Approved"
                : ""
              : "",
            UpdaterEmail: this.state.LDTApproverOHR + "@genpact.com",
          }

          console.log(param)
          let finalJsonData = Checksum(param);
          const requestOptions = {
            method: 'PUT',
            headers: { 
              "Content-Type": "application/json",
              "Authorization":  "Bearer " + this.accessToken,
          },
            body: JSON.stringify(param)
          };
          fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
            .then(response => response.json())
          Toast.hide();
          Toast.success('Updated successfully.', 3000, () => { window.location = "/view-all-ideas-page"; })

        }
      }
    } else {
      Toast.fail('Required fields are missing or have invalid values. Please correct and try again.', 3000, () => { });
    }
    return false;
  }
  cancelLDTApproval = () => {
    this.setState({
      IsQBR: [],
      QBRDate: "",
      LDTApproverName: "",
      LDTApproverOHR: ""
    })
  }

  toggleLDTApprovalCommentLogModal = () => {
    this.setState({ displayLDTCommentLog: true }, () => {
      console.log("ldt comment log", this.state.displayLDTCommentLog)
    })
  }

  handleLDTApprovalCommentChange = (e) => {
    this.setState({ ldtApprovalComment: e }, () => { })
  }

  formatDateLatest = (e) => {
    let localClientSignOffDate = new Date(e).toLocaleDateString('en-CA').split('/').reverse().join('-');
    return localClientSignOffDate
  }

  calculateCompliance=(index)=>{
    // let sustenanceObject = [...this.state.sustenanceObject];
    // let localCompliance = (sustenanceObject[index].actualRunsInMonth / sustenanceObject[index].sustenceMetricsValueRecord)*100;
    // let convertCompliance = Number(Math.floor(localCompliance+'e2')+'e-2');
    // sustenanceObject[index].compliance = convertCompliance;
    // this.setState({
    //     sustenanceObject: sustenanceObject
    // },()=>{
    //     console.log(this.state.sustenanceObject)
    // });
  }

  // leanApprovalCommentLogUpdaterRole=()=>{
  //   let updaterRole;
  // }

  // leanApprovalCommentLogUpdaterAction=()=>{
  //   let updaterAction;
  //   if (item.UpdaterAction) {
  //     updaterAction = item.UpdaterAction;
  //   } else if (item.Action === "Idea Reinitiated by Admin") {
  //     updaterAction = "Idea Reinitiated";
  //   } else if (item.Action === "Idea Updated by Admin") {
  //       updaterAction = item.ApprovalStatus;
  //   } else if (
  //     item.WorkflowStatus === "Completed" ||
  //     item.WorkflowStatus === "Approve" ||
  //     item.WorkflowStatus === "Approved" ||
  //     item.ApprovalStatus === "Awaiting Quality Check Review" ||
  //     item.ApprovalStatus === "Awaiting Project Review" ||
  //     item.ApprovalStatus === "Completed"
  //   ) {
  //     updaterAction = "Approved";
  //   } else if (
  //     item.WorkflowStatus === "Rejected" ||
  //     item.WorkflowStatus === "Reject" ||
  //     item.ApprovalStatus === "Rejected" ||
  //     item.ApprovalStatus === "Reject"
  //   ) {
  //     updaterAction = "Rejected";
  //   } else if (item.WorkflowStatus === REQUEST_CANCELLATION) {
  //     updaterAction = REQUEST_CANCELLATION;
  //   } else if (item.WorkflowStatus === "Pending Documents") {
  //     updaterAction = "Discard Cancellation";
  //   } else if (item.WorkflowStatus === "Cancelled") {
  //     updaterAction = "Cancelled";
  //   }
  //   else {
  //     updaterAction = "Sent Back";
  //   }
  //   return updaterAction;
  // }







  render() {
    let IDEA_APPROVED = this.state.ideaItem.WorkflowStatus === "Approve";
    let IDEA_REJECTED = this.state.ideaItem.WorkflowStatus === "Reject";
    let IDEA_SEND_BACK = this.state.ideaItem.WorkflowStatus === "Send Back";
    let IDEA_REQUEST_CANCELLATION =
      this.state.ideaItem.WorkflowStatus === "Cancellation Requested";
    let IDEA_REQUEST_CANCELLATION1 =
      this.state.ideaItem.ApprovalStatus === "Cancellation Requested";
    let IDEA_CANCELLED = this.state.ideaItem.WorkflowStatus === "Cancelled";
    let IDEA_CANCELLED1 = this.state.ideaItem.ApprovalStatus === "Cancelled";
    let redirectURL = "/review-page";
    if (this.state.approvalPage) {
      redirectURL = redirectURL + "?page=approval";
    }

    let IsSolnFeasibleValue = "";
    if (this.state.ideasAI.IsSolnFeasible === false) {
      IsSolnFeasibleValue = "No"
    }
    if (this.state.ideasAI.IsSolnFeasible === true) {
      IsSolnFeasibleValue = "Yes"
    }

    // this is for idea approval details -> Idea approval status
    const status = this.getStatus();

    return (
      <section className="u-clearfix u-section-1" id="sec-0f7f">
        <div
          className="u-clearfix u-sheet u-sheet-1"
          style={{ marginBottom: 20 }}
        >
          <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
            <div className="u-container-layout u-container-layout-1">
              <div className="view_idea_top_header_btn_block">
                {this.state.ideaItem.LeanID &&
                  (this.state.ideaItem.LeanID !== null ||
                    this.state.ideaItem.LeanID !== undefined) ? (
                  <>
                    <button
                      style={{ marginRight: "15px" }}
                      className="u-active-custom-color-6 u-border-2 u-btn u-btn-round u-radius-6 update_parent_btn"
                      onClick={this.showClusterModal}
                    >
                      Update Parent
                    </button>
                  </>
                ) : null}
                {((this.state.ideaItem.WorkflowStatus === PENDING &&
                  parseInt(this.sessionData.profile.oHRId) ===
                  parseInt(this.state.ideaItem.OwnerOHR)) ||
                  this.state.admin) && (
                    <>
                      <div className="" style={{ display: "flex" }}>
                        {this.state.admin && (
                          <>
                            {this.state.ideaItem.WorkflowStatus !== "Cancelled" &&
                              this.state.ideaItem.ApprovalStatus !== "Cancelled" &&
                              this.state.ideaItem.ApprovalStatus !==
                              "Awaiting Project Creation" ? (
                              <div className="">
                                <button
                                  tabIndex={21}
                                  className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                  onClick={this.cancelRequest}
                                >
                                  Cancel Idea
                                </button>
                              </div>
                            ) : this.state.ideaItem.WorkflowStatus ===
                              "Cancelled" ||
                              this.state.ideaItem.ApprovalStatus ===
                              "Cancelled" ? (
                              <div className="">
                                <button
                                  tabIndex={21}
                                  className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                  onClick={this.handleActiveIdeaAction}
                                >
                                  Active Idea
                                </button>
                              </div>
                            ) : (
                              <div className="">&nbsp;</div>
                            )}

                            <div className="">
                              <button
                                tabIndex={21}
                                className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                onClick={() =>
                                  this.handleIdeaAction(
                                    this.state.ideaItem.IdeaId
                                  )
                                }
                              >
                                Edit Idea
                              </button>
                            </div>
                          </>
                        )}
                      </div>

                      {this.state.ideaItem.WorkflowStatus === PENDING &&
                        !this.state.admin ? (
                        <>
                          <div className="">
                            <div className="">
                              <button
                                tabIndex={21}
                                className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                onClick={this.cancelRequest}
                              >
                                Cancel Request
                              </button>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  )}
              </div>

              <div className="text-left mt-4">
                {this.state.cancelRequest ? (
                  <>
                    <div className="custom_modal">
                      <div
                        className="modal_body"
                        style={{ width: "700px", overflow: "hidden" }}
                      >
                        <div className="row">
                          <div className="col-md-8">
                            <h3 className="modal_title">
                              {this.state.admin
                                ? "Cancel Idea"
                                : "Cancel Request"}
                            </h3>
                          </div>
                          <div className="col-md-4">
                            <button
                              type="button"
                              className="close text-right"
                              onClick={(e) =>
                                this.setState({ cancelRequest: false })
                              }
                            >
                              &times;
                            </button>
                          </div>
                        </div>

                        <div className="cancellation_alert_msg">
                          <div className="">
                            <label style={{color:"red"}}>Note: Once cancelled you cannot have the project reinitiated. You will need to start the project from scratch.</label>
                          </div>
                        </div>
                        

                        {this.state.admin &&
                          this.state.ideaItem.CertificationLevelName ===
                          "Lean" &&
                          (this.state.ideaItem.TransformationLeverName ===
                            "Data" ||
                            this.state.ideaItem.TransformationLeverName ===
                            "Continuous Improvement") && (
                            <>
                              <div className="row">
                                <div className="col-md-2">
                                  <span className="form-attribute">
                                    <span style={{ color: "red" }}>*&nbsp;</span>
                                    Select Type
                                  </span>{" "}
                                </div>
                                <div className="col-md-3">
                                  <Select
                                    isSearchable={false}
                                    options={this.renderLeanIdeaOptions()}
                                    value={this.state.leanIdeaSelectedOption}
                                    placeholder="Select"
                                    onChange={this.handleLeanIdea}
                                    styles={
                                      this.state.selectStyle
                                        .leanIdeaSelectedOption
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}

                        <div className="row">
                          <div className="col-md-10">
                            <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                              <span className="u-text-black form-attribute">
                                <span style={{ color: "red" }}>*</span>
                                &nbsp;Comments
                                <span
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: 12,
                                    fontStyle: "italic",
                                    color: "grey",
                                  }}
                                >
                                  {" "}
                                  (max. 500 chars)
                                </span>
                              </span>
                            </p>
                          </div>
                          <div
                            className="col-md-2"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <img
                              onClick={(e) => this.toggleTranscriptFunction(3)}
                              src={require("../assets/images/voice.png")}
                              className="w100"
                              alt=""
                              style={{ width: "24px", cursor: "pointer" }}
                            />
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: 5 }}>
                          <div className="col-md-12">
                            <textarea
                              className="textarea-css form-attribute"
                              style={{
                                maxWidth: "100%",
                                border: "1px solid lightgrey",
                                ...this.state.selectStyle.comment,
                              }}
                              rows={3}
                              cols={88}
                              placeholder="Please provide your comments on reason for cancellation."
                              onChange={(e) =>
                                this.handleCommentChange(e.target.value)
                              }
                              value={this.state.Comment}
                            />
                          </div>
                        </div>
                        <div className="btn-group" style={{ marginTop: 10 }}>
                          <button
                            className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                            onClick={this.submitEvent}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {this.state.activeIdea ? (
                  <>
                    <div className="custom_modal">
                      <div
                        className="modal_body"
                        style={{ width: "700px", overflow: "hidden" }}
                      >
                        <div className="row">
                          <div className="col-md-8">
                            <h3 className="modal_title">Reinitiate Idea</h3>
                          </div>
                          <div className="col-md-4">
                            <button
                              type="button"
                              className="close text-right"
                              onClick={(e) =>
                                this.setState({ activeIdea: false })
                              }
                            >
                              &times;
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-10">
                            <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                              <span className="u-text-black form-attribute">
                                <span style={{ color: "red" }}>*</span>
                                &nbsp;Comments
                                <span
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: 12,
                                    fontStyle: "italic",
                                    color: "grey",
                                  }}
                                >
                                  {" "}
                                  (max. 500 chars)
                                </span>
                              </span>
                            </p>
                          </div>
                          <div
                            className="col-md-2"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <img
                              onClick={(e) => this.toggleTranscriptFunction(4)}
                              src={require("../assets/images/voice.png")}
                              className="w100"
                              alt=""
                              style={{ width: "24px", cursor: "pointer" }}
                            />
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: 5 }}>
                          <div className="col-md-12">
                            <textarea
                              className="textarea-css form-attribute"
                              style={{
                                maxWidth: "100%",
                                border: "1px solid lightgrey",
                                ...this.state.selectStyle.comment,
                              }}
                              placeholder="Please provide your comments on reason for reinitiate idea."
                              onChange={(e) =>
                                this.handleCommentChange(e.target.value)
                              }
                              rows={3}
                              cols={88}
                              value={this.state.Comment}
                            />
                          </div>
                        </div>
                        <div className="btn-group" style={{ marginTop: 10 }}>
                          <button
                            className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                            onClick={this.activeIdeaEvent}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {this.state.speechModal ? (
                  <UpdatedSpeech
                    modalOpen={true}
                    onCancelRequest={this.handleTranscriptCancelRequest}
                    modalId={this.state.modalIndex}
                    closeModalFunction={this.closeModalFunction}
                    onReinitiateRequest={this.handleTranscriptReinitiateRequest}
                    previousText={this.state.previousTextForSpeech}
                  />
                ) : null}
              </div>

              <div className="text-left mt-4">
                {this.state.clusterModal ? (
                  <>
                    <div className="cluster_modal_main_block">
                      <div className="custom_modal cluster_modal_block">
                        <div className="modal_body" style={{ width: "400px" }}>
                          <div className="row">
                            <div className="col-md-11">
                              <h3 className="modal_title text-center">
                                Update Parent
                              </h3>
                            </div>
                            <div className="col-md-1">
                              <button
                                type="button"
                                className="close text-right"
                                onClick={(e) =>
                                  this.setState({ clusterModal: false })
                                }
                              >
                                &times;
                              </button>
                            </div>
                          </div>
                          <div className="clustor_modal_fields_block">
                            <div className="clustor_modal_field">
                              <label className="form-attribute">
                                <span style={{ color: "red" }}>*</span> Project
                                Type:
                              </label>
                              {this.state.displayParentAlpha ? (
                                <>
                                  <input
                                    disabled={true}
                                    value={
                                      this.state.projectTypeSelectedOption
                                        ? this.state.projectTypeSelectedOption
                                          .label
                                        : ""
                                    }
                                    className="form-input"
                                    placeholder="Choose project type"
                                  />
                                </>
                              ) : (
                                <Select
                                  isDisabled={
                                    this.state.enableClusterAction
                                      ? false
                                      : true
                                  }
                                  isSearchable={false}
                                  options={projectType}
                                  value={this.state.projectTypeSelectedOption}
                                  styles={this.state.selectStyle.projectType}
                                  placeholder="Choose project type"
                                  onChange={this.changeProjectType}
                                />
                              )}
                            </div>
                            <div className="clustor_modal_field">
                              <label className="form-attribute">
                                <span style={{ color: "red" }}>*</span> Project
                                ID:
                              </label>

                              {this.state.displayParentAlpha ? (
                                <>
                                  <input
                                    value={
                                      this.state.projectIDSelectedOption &&
                                        this.state.projectIDSelectedOption.label
                                        ? this.state.projectIDSelectedOption
                                          .label
                                        : ""
                                    }
                                    disabled={true}
                                    className="form-input"
                                    placeholder="Choose project ID"
                                  />
                                </>
                              ) : (
                                <Select
                                  isSearchable
                                  onInputChange={
                                    this.handleClusterProjectInputChange
                                  }
                                  options={this.displayProjectID()}
                                  value={this.state.projectIDSelectedOption}
                                  styles={
                                    this.state.selectStyle
                                      .projectIDSelectedOption
                                  }
                                  isDisabled={
                                    this.state.enableClusterAction
                                      ? false
                                      : true
                                  }
                                  placeholder="Choose project ID"
                                  onChange={this.changeProjectID}
                                />
                              )}
                            </div>
                          </div>
                          <div className="cluster_modal_btn_group">
                            <button
                              className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                              disabled={
                                this.state.isClusterSaveDisabled ? true : false
                              }
                              onClick={this.saveParentLink}
                            >
                              Save
                            </button>
                            <button
                              className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                              onClick={this.removeParentFunc}
                              style={{ marginRight: "15px" }}
                              disabled={
                                this.state.enableClusterAction ? false : true
                              }
                            >
                              Remove Parent
                            </button>
                            {this.state.displayParentAlpha ? (
                              <>
                                <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6 show_hierarchy_link_btn">
                                  <Link
                                    className="show_hierarchy_link_btn_anchor"
                                    to={`/showTree?ID=${this.state.ideaItem.IdeaId}`}
                                    target="_blank"
                                  >
                                    Show Hierarchy
                                  </Link>
                                </button>
                              </>
                            ) : (
                              <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6 show_hierarchy_link_btn">
                                Show Hierarchy
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <h4
                style={{
                  textAlign: "center",
                  color: "#00AECF",
                  fontFamily: "Arial",
                  fontSize: 22,
                }}
              >
                View idea :{" "}
                {this.state.ideaItem.IdeaIDAlpha
                  ? this.state.ideaItem.IdeaIDAlpha
                  : this.state.ideaItem.IdeaId}{" "}
              </h4>
              <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                <div
                  className="u-container-layout u-container-layout-2"
                  style={{ textAlign: "center" }}
                >
                  <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-3">
                    <div className="u-container-layout u-container-layout-3">
                      <p
                        className="u-align-left u-text u-text-2"
                        style={{ marginBottom: 15 }}
                      >
                        Owner details{" "}
                      </p>
                      <div className="row">
                        <div className="col-md-5">
                          <label
                            style={{
                              verticalAlign: "bottom",
                              marginBottom: -5,
                            }}
                          >
                            Owner OHR
                          </label>{" "}
                        </div>
                        <div className="col-md-6">
                          <input
                            className="form-input"
                            disabled
                            type="text"
                            value={this.state.ideaItem.OwnerOHR}
                          />
                        </div>
                      </div>
                      <div style={{ marginBottom: 15 }} />
                      <div className="row">
                        <div className="col-md-5">
                          <label
                            style={{
                              verticalAlign: "bottom",
                              marginBottom: -5,
                            }}
                          >
                            Owner Name
                          </label>{" "}
                        </div>
                        <div className="col-md-6">
                          <input
                            className="form-input"
                            disabled
                            type="text"
                            value={this.state.ideaItem.OwnerName}
                          />
                        </div>
                      </div>
                      <div style={{ marginBottom: -10 }} />
                      <p
                        className="u-align-left u-text u-text-7 u-text-custom-color-3"
                        style={{ fontSize: 11, marginBottom: 10 }}
                      >
                        (If Idea was raised for someone else, their OHR ID is
                        below)
                      </p>
                      <div className="row">
                        <div className="col-md-5">
                          <label
                            style={{
                              verticalAlign: "bottom",
                              marginBottom: -5,
                            }}
                          >
                            Request For OHR
                          </label>{" "}
                        </div>
                        <div className="col-md-6">
                          <input
                            className="form-input"
                            disabled
                            type="text"
                            defaultValue={this.state.ideaItem.CreatorOHR}
                            onChange={this.handleCreatorOHRChange}
                          />
                        </div>
                      </div>
                      <div style={{ marginBottom: 10 }} />
                      <div className="row">
                        <div className="col-md-5">
                          <label
                            style={{
                              verticalAlign: "bottom",
                              marginBottom: -5,
                            }}
                          >
                            Name
                          </label>{" "}
                        </div>
                        <div className="col-md-6">
                          <input
                            className="form-input"
                            disabled
                            type="text"
                            defaultValue={this.state.ideaItem.CreatorName}
                            onChange={this.handleCreatorNameChange}
                          />
                        </div>
                      </div>
                      <div style={{ marginBottom: 10 }} />
                      {this.state.ideaItem.LeanID !== null &&
                        (this.state.ideaItem.CertificationLevelName === "Lean" ||
                          this.state.ideaItem.CertificationLevelName === "YB") ? (
                        <div className="row">
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Project ID
                            </label>{" "}
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-input"
                              disabled
                              type="text"
                              defaultValue={this.state.ideaItem.LeanID}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: 12,
                              }}
                            >
                              &nbsp;
                            </label>{" "}
                          </div>
                        </div>
                      )}
                      <div style={{ marginBottom: 10 }} />
                    </div>
                  </div>

                  <p
                    className="visible-md-block visible-sm-block visible-xs-block"
                    style={{ marginTop: 255, marginLeft: -20 }}
                  >
                    &nbsp;
                  </p>

                  <div
                    className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-4"
                    style={{ marginTop: "-305px" }}
                  >
                    <div
                      className=""
                      style={{ display: "flex", marginTop: "30px" }}
                    >
                      <div className="u-container-style u-group u-shape-rectangle u-group-5">
                        <div className="u-container-layout u-container-layout-5" style={{padding:"20px 20px 0px 20px"}}>
                          <p
                            className="u-align-left u-text u-text-8"
                            style={{ marginBottom: 15 }}
                          >
                            Account Details
                          </p>
                          <div className="row">
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Account&nbsp;Name
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                list="accounts"
                                name="accounts"
                                placeholder="Choose Account"
                                value={this.state.ideaItem.AccountManual}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />
                          <div className="row">
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Holding&nbsp;Account
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                type="text"
                                value={this.state.ideaItem.HoldingAccount}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />
                          <div className="row">
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Vertical
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                name="vertical"
                                placeholder="Choose Vertical"
                                value={this.state.ideaItem.VerticalManual}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />
                          <div className="row">
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Industry&nbsp;Vertical
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                name="subverticallist"
                                placeholder="Choose Industry&nbsp;Vertical"
                                value={this.state.ideaItem.SubVerticalName}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />

                          {/* units and segments starts here */}
                            <div className="row">
                              <div className="col-md-5">
                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Segment</label>
                              </div>
                              <div className="col-md-7">
                                <input className="form-input" disabled name="segment" value={this.state.ideaItem.Segment ? this.state.ideaItem.Segment : ""} style={{ fontWeight: 'normal !important' }} />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-5">
                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Unit</label>
                              </div>
                              <div className="col-md-7">
                                <input className="form-input" disabled name="unit" value={this.state.ideaItem.Unit ? this.state.ideaItem.Unit : ""} style={{ fontWeight: 'normal !important' }} />
                              </div>
                            </div>
                            <div style={{ marginBottom: 15 }} />
                            {/* units and segments ends here */}


                          <div className="row">
                            <div className="col-md-5">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Campaign&nbsp;Name
                              </label>
                            </div>
                            <div className="col-md-7">
                              <input
                                className="form-input"
                                disabled
                                name="ideationtype"
                                placeholder="Choose Campaign Name"
                                value={this.state.ideaItem.CampaignName}
                              />
                            </div>
                            <div className="">
                              <div className="campaign_modal_bulb campaign_tool_tip">
                                <img 
                                    src={require("../assets/images/question_mark_2.png")}
                                    alt=""
                                    className=""
                                />
                                {
                                  this.state.campaignInsight
                                  ?
                                  <>
                                      <span className="my_tooltiptext">{this.state.campaignInsight}</span>
                                  </>
                                  :
                                  null
                                }
                              </div>
                            </div>

                          </div>
                          <div style={{ marginBottom: 15 }} />
                          {this.state.ideaItem.CampaignName ===
                            "Gen AI" ? (
                            <>
                              <div className="row">
                                <div className="col-md-5">
                                  <label
                                    style={{
                                      verticalAlign: "bottom",
                                      marginBottom: -5,
                                    }}
                                  >
                                    Gen AI Theme
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    className="form-input"
                                    disabled
                                    list="subcampaign"
                                    name="subcampaign"
                                    placeholder="Choose Gen AI Theme"
                                    value={this.state.ideaItem.SubCampaignName}
                                  />
                                </div>
                              </div>
                            </>
                          ) : null}
                          {this.state.displayProjectTheme &&
                            this.state.dataBridgeCampaign && (
                              <>
                                <div className="row">
                                  <div className="col-md-5">
                                    <label
                                      style={{
                                        verticalAlign: "bottom",
                                        marginBottom: -5,
                                      }}
                                    >
                                      Data Bridge Project Theme
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <input
                                      className="form-input"
                                      disabled
                                      list="databridgetype"
                                      name="databridgetype"
                                      placeholder="Choose Data Bridge Project Type"
                                      value={
                                        this.state.ideaItem.DataBridgeTheme
                                      }
                                    />
                                  </div>
                                </div>
                                <div style={{ marginBottom: 26 }} />
                                <p
                                  className="visible-md-block visible-sm-block visible-xs-block"
                                  style={{ marginBottom: 30 }}
                                >
                                  &nbsp;
                                </p>
                              </>
                            )}
                          {this.state.xPollinationCampaign && (
                            <>
                              <div className="row">
                                <div className="col-md-5">
                                  <label
                                    style={{
                                      verticalAlign: "bottom",
                                      marginBottom: -5,
                                    }}
                                  >
                                    Sub Campaign
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    className="form-input"
                                    disabled
                                    list="subcampaign"
                                    name="subcampaign"
                                    placeholder="Choose Sub Campaign"
                                    value={this.state.ideaItem.SubCampaignName}
                                  />
                                </div>
                              </div>
                              <div style={{ marginBottom: 26 }} />
                              <p
                                className="visible-md-block visible-sm-block visible-xs-block"
                                style={{ marginBottom: 30 }}
                              >
                                &nbsp;
                              </p>
                            </>
                          )}

                          {this.state.displayParentAlpha &&
                            this.state.displayParentAlpha.length > 0 &&
                            this.state.displayParentAlpha !== null &&
                            this.state.displayParentAlpha !== undefined &&
                            this.state.displayParentAlpha !== "" ? (
                            <>
                              <div className="row">
                                <div className="col-md-5">
                                  <label
                                    style={{
                                      verticalAlign: "bottom",
                                      marginBottom: -5,
                                    }}
                                  >
                                    Parent ID
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    className="form-input"
                                    disabled
                                    placeholder="Choose Parent ID"
                                    value={this.state.displayParentAlpha}
                                  />
                                </div>
                              </div>
                            </>
                          ) : null}


                          {
                            this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST
                              ?
                              <>
                                <div className="row" style={{ marginTop: "20px" }}>
                                  <div className="col-md-5">
                                    <label
                                      style={{
                                        verticalAlign: "bottom",
                                        marginBottom: -5,
                                      }}
                                    >
                                      <i>AI First Sample Ideas</i>
                                    </label>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="ai_first_doc_link ai_first_doc_red_highlight">
                                      <a
                                        className="ldt-list1"
                                        href={AI_DOC_LINK}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ textAlign: "left", fontSize: 16, color: "red" }}
                                      >Document Link </a>
                                    </div>

                                  </div>
                                </div>

                                {
                                  this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )
                                  ?
                                  <>
                                      <div style={{ marginBottom: 20 }} />
                                      <div className="row d_flex_align_items_center">
                                        <div className="col-md-5">
                                          <span className="form-attribute">
                                            AI Recommendation
                                          </span>
                                        </div>
                                        <div className="col-md-7">
                                          <input
                                            className="form-input"
                                            disabled
                                            name="methodology"
                                            value={this.state.ideaItem.isAI}
                                          />
                                        </div>
                                      </div>
                                  </>
                                  :
                                  null

                                }


                                {
                                  this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )
                                  ?
                                  <>
                                      <div style={{ marginBottom: 20 }} />
                                      <div className="row">
                                        <div className="col-md-5">
                                          <span className="form-attribute">
                                            AI First BRD
                                          </span>
                                        </div>
                                        <div className="col-md-7">
                                          <div className="ai_first_doc_link">
                                            <textarea className="ai_first_brd_textarea" onClick={(e) => this.handleAIDocumentClick(this.state.ideasAI.LinkBRD)} value={this.state.ideasAI.LinkBRD} ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                  </>
                                  :
                                  null
                                }

                              </>
                              :
                              null
                          }




                          <div style={{ marginBottom: 18 }}>&nbsp;</div>
                        </div>
                      </div>
                      <div className="u-container-layout u-container-layout-6">
                        <div className="row" style={{ marginTop: "10px" }}>
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Country
                            </label>
                          </div>
                          <div className="col-md-7">
                            <input
                              className="form-input"
                              disabled
                              name="regions"
                              placeholder="Choose Country"
                              value={this.state.ideaItem.Country}
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: 15 }} />
                        <div className="row">
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Region
                            </label>
                          </div>
                          <div className="col-md-7">
                            <input
                              className="form-input"
                              disabled
                              name="regions"
                              placeholder="Choose Region"
                              value={this.state.ideaItem.RegionManual}
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: 15 }} />
                        <div className="row">
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Sub-Region
                            </label>
                          </div>
                          <div className="col-md-7">
                            <input
                              className="form-input"
                              disabled
                              name="regions"
                              placeholder="Choose Sub-Region"
                              value={this.state.ideaItem.SubRegion}
                            />
                          </div>
                        </div>
                        <div style={{ marginBottom: 15 }} />
                        <div className="row">
                          <div className="col-md-5">
                            <label
                              style={{
                                verticalAlign: "bottom",
                                marginBottom: -5,
                              }}
                            >
                              Blackbelt&nbsp;Name
                            </label>
                          </div>
                          <div className="col-md-7">
                            <input
                              className="form-input"
                              disabled
                              name="blackbelt"
                              placeholder="Choose Blackbelt"
                              title={
                                "OHR ID: " + this.state.ideaItem.BlackBeltUserId
                              }
                              value={this.state.ideaItem.BlackBeltUserName}
                            />
                          </div>
                        </div>
                        {this.state.ideaItem.MBBOHR !== null && (
                          // (this.state.ideaItem.WorkflowStatus !== "Pending") && (
                          <>
                            <div style={{ marginBottom: 15 }} />
                            <div className="row">
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  MBB
                                </label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  className="form-input"
                                  disabled
                                  name="blackbelt"
                                  placeholder="Choose MBB"
                                  title={
                                    "OHR ID: " + this.state.ideaItem.MBBOHR
                                  }
                                  value={this.state.ideaItem.MBBName}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {
                          this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST &&
                          this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )
                            ?
                            <>
                              <div style={{ marginBottom: 20 }} />
                              <div className="row">
                                <div className="col-md-12">
                                  <span className="form-attribute">
                                    AI First Reason
                                  </span>
                                </div>
                                <div className="col-md-12">
                                  <textarea
                                    disabled
                                    value={this.state.ideasAI.AIReason}
                                    placeholder="Please provide value"
                                    className="form-input"
                                    rows={4}
                                    cols={80}
                                    style={{
                                      width: "100%",
                                      border: "1px solid lightgrey",
                                      resize: "none"
                                    }}
                                  />
                                </div>
                              </div>
                              <div style={{ marginBottom: 20 }} />
                            </>
                            :
                            null
                        }






                        <div style={{ marginBottom: 15 }} />
                        {/* <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>LDA</label></div>
                                                    <div className="col-md-7">
                                                        <input className ="form-input" disabled name="blackbelt" placeholder="Choose LDA" title={"OHR ID: " +  this.state.ideaItem.LDAOHR} value={this.state.ideaItem.LDA} />
                                                    </div>
                                                </div> */}
                        <div style={{ marginBottom: 15 }} />
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      borderBottom: "1px solid lightgrey",
                      marginBottom: 15,
                    }}
                  />

                  <p
                    className="visible-md-block visible-sm-block visible-xs-block"
                    style={{ marginTop: -30 }}
                  >
                    &nbsp;
                  </p>

                  <div
                    id="idea1"
                    className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                    style={{ marginTop: 15 }}
                  >
                    <div className="u-container-layout u-container-layout-7">
                      <div className="u-container-style u-group u-shape-rectangle u-group-8">
                        <div className="u-container-layout u-container-layout-8">
                          <p
                            id="idea1title"
                            className="u-align-left u-text u-text-2"
                            style={{ marginBottom: 10 }}
                          >
                            Idea details
                          </p>
                          <div className="row">
                            <div className="col-md-4">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Service Line
                              </label>{" "}
                            </div>
                            <div className="col-md-8">
                              <input
                                className="form-input"
                                disabled
                                name="serviceline"
                                placeholder="Choose Service Line"
                                value={this.state.ideaItem.ServiceLineName}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />
                          <div className="row">
                            <div className="col-md-4">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Sub Service Line
                              </label>{" "}
                            </div>
                            <div className="col-md-8">
                              <input
                                className="form-input"
                                disabled
                                name="serviceline"
                                placeholder="Choose Sub Service Line"
                                value={this.state.ideaItem.SubServiceLine}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 15 }} />
                          <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                            <span className="u-text-black form-attribute">
                              What is the Problem?
                            </span>
                            &nbsp;
                          </p>
                          <div className="row">
                            <div className="col-md-12">
                              <textarea
                                className="form-input"
                                disabled
                                rows={6}
                                cols={80}
                                style={{
                                  maxWidth: "100%",
                                  border: "1px solid lightgrey",
                                }}
                                value={this.state.ideaItem.Problem}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <p
                        className="visible-md-block visible-sm-block visible-xs-block"
                        style={{ marginTop: 10 }}
                      >
                        &nbsp;
                      </p>
                      <div className="u-container-style u-group u-shape-rectangle u-group-9">
                        <div
                          className="u-container-layout u-container-layout-9"
                          style={{ marginTop: 4 }}
                        >
                          <div className="row">
                            <div className="col-md-3">
                              <label
                                style={{
                                  verticalAlign: "bottom",
                                  marginBottom: -5,
                                }}
                              >
                                Idea Name
                              </label>{" "}
                            </div>
                            <div
                              className="col-md-9"
                              style={{ alignItems: "flex-start" }}
                            >
                              <input
                                className="form-input"
                                disabled
                                type="text"
                                value={this.state.ideaItem.IdeaName}
                                placeholder="Eg: Cash flow improvement through process restructuring"
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 66 }} />
                          <p
                            className="u-align-left u-text u-text-custom-color-4 u-text-25"
                            style={{ width: "177px" }}
                          >
                            <span className="u-text-black form-attribute">
                              {" "}
                              How to resolve the problem
                            </span>
                            &nbsp;
                          </p>
                          <div className="row" style={{ marginBottom: 15 }}>
                            <div className="col-md-12">
                              <textarea
                                className="form-input"
                                disabled
                                rows={6}
                                cols={80}
                                style={{
                                  maxWidth: "100%",
                                  border: "1px solid lightgrey",
                                }}
                                value={this.state.ideaItem.Solution}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: 15 }}>
                        <div className="col-md-5">
                          <span className="form-attribute">
                            Are you trying to replicate an existing Idea?
                          </span>
                        </div>
                        <div className="col-md-4">
                          <label className="switch">
                            <input
                              disabled
                              type="checkbox"
                              checked={
                                this.state.ideaItem.NewOrReplicatedIdea === null
                                  ? false
                                  : this.state.ideaItem.NewOrReplicatedIdea
                              }
                            />
                            <span className="slider round" />
                          </label>
                        </div>
                      </div>

                      {this.state.ideaItem.NewOrReplicatedIdea && (
                        <>
                          <div
                            className="col-md-8"
                            style={{ marginBottom: 15, width: "100%" }}
                          />
                          <div className="row">
                            <div className="col-md-5">
                              <span className="form-attribute">
                                Provide the ID of the executed Idea which you
                                want to replicate?
                              </span>
                            </div>
                            <div className="col-md-2">
                              <input
                                className="form-input"
                                disabled
                                type="text"
                                value={this.state.ideaItem.ReplicationProjectId}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-md-8" style={{ marginBottom: 15 }} />
                    </div>
                  </div>













                  {this.state.ideaItem.WorkflowStatus &&
                    this.state.ideaItem.Comment !== null && (
                      <>
                        <div
                          style={{
                            borderBottom: "1px solid lightgrey",
                            marginBottom: 15,
                          }}
                        />
                        <div
                          id="Approval Status"
                          className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                        >
                          <div className="u-container-layout u-container-layout-7">
                            <p
                              className="u-align-left u-text u-text-2"
                              style={{ marginBottom: 10 }}
                            >
                              Idea Approval details
                            </p>
                            <div className="row">
                              <div className="col-md-2">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  Approval Status
                                </label>
                              </div>
                              <div className="col-md-2">
                                {/* <input className="form-input" disabled name="Status" value={IDEA_APPROVED ? APPROVED : (IDEA_REJECTED ? REJECTED : (IDEA_SEND_BACK ? SENT_BACK : ((IDEA_REQUEST_CANCELLATION || IDEA_REQUEST_CANCELLATION1) ? "Cancellation Requested" :( (IDEA_CANCELLED || IDEA_CANCELLED1) ? "Cancelled" :(this.state.ideaItem.Comment !== null ? (this.state.logAction ==='Idea Reinitiated by Admin' ? 'Idea Reinitiated' : 'Discard Cancellation') :PENDING)))))}  /> */}
                                <input
                                  className="form-input"
                                  disabled
                                  name="Status"
                                  value={status}
                                />
                              </div>
                              {/* <div className="col-md-3"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Expected timeline for execution</label></div>
                                                        <div className="col-md-2">
                                                            <input disabled name="ExpectedDate" value={this.state.ideaItem.ExpectedFinishDate ? this.formatDateString(new Date(this.state.ideaItem.ExpectedFinishDate)): ""} />
                                                        </div> */}
                            </div>
                            {/* <div className="col-md-9" style={{ marginBottom: 5 }} /> */}

                            <div className="row" style={{ marginTop: 15 }}>
                              <div className="col-md-5">
                                <label
                                  style={{
                                    verticalAlign: "bottom",
                                    marginBottom: -5,
                                  }}
                                >
                                  Comments
                                </label>
                              </div>
                              <div
                                className="col-md-2"
                                style={{ marginLeft: 20 }}
                              >
                                <button
                                  className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                  onClick={(e) =>
                                    this.setState({
                                      commentLog: !this.state.commentLog,
                                    })
                                  }
                                >
                                  Comment Log
                                </button>
                              </div>
                            </div>
                            <div className="row" style={{ marginTop: 5 }}>
                              <div className="col-md-12">
                                <textarea
                                  className="form-input"
                                  disabled
                                  rows={3}
                                  cols={88}
                                  style={{
                                    maxWidth: "100%",
                                    border: "1px solid lightgrey",
                                  }}
                                  name="Comments"
                                  value={this.state.ideaItem.Comment}
                                />
                              </div>
                            </div>
                            <div className="text-left mt-4">
                              {this.state.commentLog &&
                                this.state.reviewComments ? (
                                <>
                                  <div className="custom_modal">
                                    <div className="modal_body">
                                      <div className="row">
                                        <div className="col-md-8">
                                          <h3 className="modal_title">
                                            Idea Approval Comment Log
                                          </h3>
                                        </div>
                                        <div className="col-md-4">
                                          <button
                                            type="button"
                                            className="close text-right"
                                            onClick={(e) =>
                                              this.setState({
                                                commentLog: false,
                                              })
                                            }
                                          >
                                            &times;
                                          </button>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div
                                          className="col-md-2 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            Date & Time
                                          </label>
                                        </div>
                                        <div
                                          className="col-md-2 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            OHR
                                          </label>
                                        </div>
                                        <div
                                          className="col-md-2 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            Name
                                          </label>
                                        </div>
                                        <div
                                          className="col-md-1 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            Role
                                          </label>
                                        </div>
                                        <div
                                          className="col-md-2 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            Action
                                          </label>
                                        </div>
                                        <div
                                          className="col-md-3 text-center"
                                          style={{
                                            border: "1px solid lightgrey",
                                            backgroundColor: "gainsboro",
                                          }}
                                        >
                                          <label
                                            style={{
                                              fontWeight: 100,
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            Comment
                                          </label>
                                        </div>
                                      </div>
                                      {this.state.reviewComments.map(
                                        (item, index) => {
                                          console.log(item);
                                          return (
                                            <div className="row" key={index}>
                                              <div
                                                className="col-md-2 text-center"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {this.formatDateStringWithTime(
                                                    new Date(item.CreatedDate)
                                                  )}
                                                </label>
                                              </div>
                                              <div
                                                className="col-md-2 text-center"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {item.UpdaterOHR}
                                                </label>
                                              </div>
                                              <div
                                                className="col-md-2 text-center"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {item.UpdaterName}
                                                </label>
                                              </div>
                                              {/* <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                    {(item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin' ) ? 'Admin' : (item.Action === 'Delegate Action' ? 'BB' : (item.UpdaterOHR === this.state.ideaItem.BlackBeltUserId ? "BB" : (item.UpdaterOHR === this.state.ideaItem.MBBOHR ? "MBB" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Idea Owner" : "LDT"))))}
                                                                                                </label>
                                                                                            </div> */}
                                              <div
                                                className="col-md-1 text-center role_over_flow_class"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                {/* <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole}</label> */}
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {item.UpdaterRole
                                                    ? item.UpdaterRole
                                                    : item.Action ===
                                                      "Idea Updated by Admin" ||
                                                      item.Action ===
                                                      "Idea Reinitiated by Admin"
                                                      ? "Admin"
                                                      : item.Action ===
                                                        "Delegate Action"
                                                        ? "BB"
                                                        : item.UpdaterOHR ===
                                                          this.state.MBBOHR
                                                          ? "MBB"
                                                          : item.UpdaterOHR ===
                                                            this.state.ideaItem
                                                              .OwnerOHR
                                                            ? "Idea Owner"
                                                            : item.ApprovalStatus ===
                                                              "Pending Documents" &&
                                                              item.WorkflowStatus ===
                                                              "Approved"
                                                              ? "BB"
                                                              : item.ApprovalStatus ===
                                                                "NA" &&
                                                                item.WorkflowStatus ===
                                                                "Pending"
                                                                ? "BB"
                                                                : item.UpdaterRole}
                                                </label>
                                              </div>
                                              <div
                                                className="col-md-2 text-center"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {item.UpdaterAction
                                                    ? item.UpdaterAction
                                                    : item.Action ===
                                                      "Idea Reinitiated by Admin"
                                                      ? "Idea Reinitiated"
                                                      : item.Action ===
                                                        "Idea Updated by Admin"
                                                        ? item.WorkflowStatus !==
                                                          "Pending"
                                                          ? item.WorkflowStatus
                                                          : "Idea Updated"
                                                        : item.Action ===
                                                          "Delegate Action"
                                                          ? "Delegated"
                                                          : item.WorkflowStatus ===
                                                            "Pending"
                                                            ? "Discard Cancellation"
                                                            : item.WorkflowStatus}
                                                </label>
                                              </div>
                                              <div
                                                className="col-md-3 text-center"
                                                style={{
                                                  border: "1px solid lightgrey",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    lineheight: 2,
                                                    verticalAlign: "bottom",
                                                  }}
                                                >
                                                  {item.Comment
                                                    ? item.Comment
                                                    : "-"}
                                                </label>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid lightgrey",
                            marginBottom: 15,
                            marginTop: 15,
                          }}
                        />

                        {this.state.ideaItem.WorkflowStatus &&
                          (this.state.ideaItem.WorkflowStatus === "Approve" ||
                            this.state.ideaItem.WorkflowStatus ===
                            "Approved") && (
                            <div
                              id="Potential Impact"
                              className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                              style={{
                                borderBottom: "1px solid lightgray",
                                marginBottom: "10px",
                              }}
                            >
                              <div className="u-container-layout u-container-layout-7" style={{ display: 'flex' }}>
                                <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                  <div className="u-container-layout u-container-layout-8">
                                    <p
                                      id="Potential Impact"
                                      className="u-align-left u-text u-text-2"
                                      style={{ marginBottom: 10 }}
                                    >
                                      Additional details
                                    </p>
                                    <div className="row d_flex_align_items_center">
                                      <div className="col-md-6">
                                        <span className="form-attribute">
                                          LDT Lead Name
                                        </span>
                                      </div>
                                      <div className="col-md-6">
                                        <input
                                          className="form-input"
                                          disabled
                                          list="ldtName"
                                          name="ldtName"
                                          placeholder="Choose LDT Lead"
                                          title={
                                            "OHR ID: " +
                                            this.state.ideaItem.LDTId
                                          }
                                          value={
                                            this.state.ideaItem.LDTLeadName
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div style={{ marginBottom: 5 }} />



                                    {
                                      this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST
                                      && this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )  
                                      ?
                                        <>
                                          <div style={{ marginBottom: 15 }} />
                                          <div className="row d_flex_align_items_center">
                                            <div className="col-md-6">
                                              <span className="form-attribute">
                                                Is the solution feasible ?
                                              </span>
                                            </div>
                                            <div className="col-md-6">
                                              <input
                                                className="form-input"
                                                disabled
                                                value={IsSolnFeasibleValue}
                                              />
                                            </div>
                                          </div>
                                          <div style={{ marginBottom: 15 }} />
                                          <div className="row d_flex_align_items_center">
                                            <div className="col-md-6">
                                              <span className="form-attribute">
                                                Solution Complexity
                                              </span>
                                            </div>
                                            <div className="col-md-6">
                                              <input
                                                className="form-input"
                                                disabled
                                                name="methodology"
                                                value={
                                                  this.state.ideasAI.SolnComplexity
                                                }
                                              />
                                            </div>
                                          </div>


                                          <div style={{ marginBottom: 15 }} />
                                          <div className="row d_flex_align_items_center">
                                            <div className="col-md-6">
                                              <span className="form-attribute">
                                                Is the Idea replicable
                                              </span>
                                            </div>
                                            <div className="col-md-6">
                                              <input
                                                className="form-input"
                                                disabled
                                                value={this.state.isIdeaReplicable}
                                              />
                                            </div>
                                          </div>
                                          <div style={{ marginBottom: 15 }} />
                                        </>
                                        :
                                        null
                                    }
                                    {this.state.ideaItem.NewOrReplicatedIdea && (
                                        <div className="row">
                                          <div className="col-md-10" style={{
                                            marginTop: 10,
                                            marginBottom: 14,
                                          }}>
                                            <label
                                              style={{
                                                verticalAlign: "bottom",
                                                marginBottom: -5,
                                              }}
                                            >
                                              Have you verified the replication id
                                              and confirm this to be replication
                                              project?
                                            </label>
                                          </div>
                                          <div className="col-md-2">
                                            <label className="switch">
                                              <input
                                                disabled
                                                type="checkbox"
                                                checked={
                                                  this.state.ideaItem
                                                    .IsReplicationVerified
                                                }
                                              />
                                              <span className="slider round" />
                                            </label>
                                          </div>
                                          <div style={{ marginBottom: 45 }} />
                                        </div>
                                      )}
                                    {!this.state.ideaItem
                                      .NewOrReplicatedIdea && (
                                        <div className="row">
                                          <div className="col-md-10">
                                            <label
                                              style={{
                                                verticalAlign: "bottom",
                                                marginBottom: 17,
                                              }}
                                            >
                                              &nbsp;
                                            </label>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>

                                <div className="u-container-style u-group u-shape-rectangle u-group-9" style={{ marginTop: "0px" }}>
                                  <div className="u-container-layout u-container-layout-9" style={{ paddingTop: "20px" }}>
                                    <div className="row d_flex_align_items_center">
                                      <div className="col-md-5">
                                        <span className="form-attribute">
                                          Certification Level
                                        </span>{" "}
                                      </div>
                                      <div className="col-md-7">
                                        <input
                                          className="form-input"
                                          disabled
                                          name="certlevel"
                                          value={this.state.ideaItem.CertificationLevelName}
                                        />
                                      </div>
                                    </div>
                                    <div style={{ marginBottom: 15 }} />
                                    <div className="row d_flex_align_items_center">
                                      <div className="col-md-5">
                                        <span className="form-attribute">
                                          Transformation Lever
                                        </span>{" "}
                                      </div>
                                      <div className="col-md-7">
                                        <input
                                          className="form-input"
                                          disabled
                                          name="translever"
                                          value={this.state.ideaItem.TransformationLeverName}
                                        />
                                      </div>
                                    </div>
                                    <div style={{ marginBottom: 15 }} />
                                    {this.state.ideaItem
                                      .TransformationLeverName ===
                                      "Digital" && (
                                        <>
                                          <div className="row d_flex_align_items_center">
                                            <div className="col-md-5">
                                              <span className="form-attribute">
                                                Digital Project Type
                                              </span>{" "}
                                            </div>
                                            <div className="col-md-7">
                                              <input
                                                className="form-input"
                                                disabled
                                                name="translever"
                                                value={this.state.ideaItem.DigitalProjectType}
                                              />
                                            </div>
                                          </div>
                                          <div style={{ marginBottom: 15 }} />
                                        </>
                                      )}
                                    {this.state.ideaItem
                                      .CertificationWorkTypeName !== null &&
                                      this.state.ideaItem
                                        .CertificationWorkTypeName !== "" ? (
                                      <>
                                        <div className="row">
                                          <div className="col-md-5">
                                            <span className="form-attribute">
                                              Work Type
                                            </span>{" "}
                                          </div>
                                          <div className="col-md-7">
                                            <input
                                              className="form-input"
                                              disabled
                                              name="worktype"
                                              value={this.state.ideaItem.CertificationWorkTypeName
                                              }
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                    <div style={{ marginBottom: 15 }} />

                                    <div className="row">
                                      <div className="col-md-5">
                                        <span className="form-attribute">
                                          Tools/Methodology Used
                                        </span>
                                      </div>
                                      <div className="col-md-7">
                                        <input
                                          className="form-input"
                                          disabled
                                          name="methodology"
                                          value={
                                            this.state.ideaItem.MethodologyName
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div style={{ marginBottom: 25 }} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </>
                    )}

                  {(this.state.ideaItem.CertificationLevelName === "Lean") ? (
                    <>
                      <FPNAGOL
                        IdeaId={this.state.ideaItem.IdeaId}
                        viewIdea={true}
                        vdSelected={this.state.ideaItem.VDType}
                        fetchPPTDeck={this.pullPPTDeck}
                        fetchClientDocName={this.pullClientDocName}
                        fetchClientDocType={this.pullClientDocType}
                        fetchClientDocContent={this.pullClientDocContent}
                        fetchGolDocName={this.pullgolFileName}
                        fetchGolDocType={this.pullgolFileType}
                        fetchGolDocContent={this.pullgolContent}
                        fetchfpnaDocName={this.pullfpnafileName}
                        fetchfpnaDocType={this.pullfpnafileType}
                        fetchfpnaDocContent={this.pullfpnafileContent}
                        fetchClientName={this.pullClientName}
                        fetchClientRole={this.pullClientRole}
                        fetchClientSignOffDate={this.pullClientSignOff}
                        fetchFpnaName={this.pullFpnaName}
                        fetchGolName={this.pullGolName}
                        fetchFpnaOhr={this.pullFpnaOhr}
                        fetchGolOhr={this.pullGolOhr}
                        fetchFileDetails={this.pull_fileDetails}
                        sendBitoFPNA={this.state.businessImpactValueArray}
                        sendIsFpnaRequired={this.state.isFpnaRequired}
                      />
                    </>
                  ) : null}

                  {/* {
                                        this.state.ideaItem.VDType
                                        ?
                                        <>
                                            <div className="gol_fields_block" style={{borderBottom: "0px",borderTop: "1px solid lightgray",paddingBottom:"0px"}}>
                                                <p className="u-align-left u-text u-text-2" style={{margin: "17px 0px 20px"}}>Impact Type</p>
                                                <div className="row u-align-left">
                                                    <div className="col-md-7">
                                                        <div className="row">
                                                            <div className="col-md-6 gol_field_div">
                                                                <span className="form-attribute">What is the type of Value Delivery on this project</span> 
                                                            </div>
                                                            <div className="col-md-5">
                                                                <input disabled value={this.state.ideaItem.VDType}  className="textarea-css form-attribute disabled_input" type="text" />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null

                                    } */}

                  {/* ....latest changes to GOL starts here.... */}

                  {/* ....latest changes to GOL ends here.... */}

                  {/* Final Approval Section  */}
                  {this.state.ideaItem.ApprovalStatus &&
                    this.state.ideaItem.ApprovalStatus !== "NA" &&
                    // && (this.state.ideaItem.ApprovalStatus === "Pending Documents" && this.state.ideaItem.FinalComments !== null)
                    // && this.state.ideaItem.FinalComments !== null
                    // && this.state.ideaItem.ApprovalStatus !=="Documents Uploaded"
                    this.state.ideaItem.ApprovalStatus !==
                    "Awaiting Project Creation" && (
                      <>
                        {this.state.finalReviewComments ? (
                          <>
                            <div
                              id="Approval Status"
                              className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                            >
                              <div className="u-container-layout"  style={{ paddingTop:"15px"}}>
                                <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }} >
                                  Project Approval details
                                </p>
                                <div className="row">
                                  <div className="col-md-2">
                                    <span className="form-attribute">
                                      Approval Status
                                    </span>{" "}
                                  </div>
                                  <div className="col-md-2">
                                    <input
                                      data-toggle="tooltip"
                                      title={this.state.ideaItem.ApprovalStatus ? this.state.ideaItem.ApprovalStatus : this.state.lastApprovalStatus}
                                      className="form-input"
                                      disabled
                                      name="finalapprovalstatus"
                                      value={
                                        this.state.ideaItem.ApprovalStatus
                                          ? this.state.ideaItem.ApprovalStatus
                                          : this.state.lastApprovalStatus
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row" style={{ marginTop: 15 }}>
                                  <div className="col-md-5">
                                    <label
                                      style={{
                                        verticalAlign: "bottom",
                                        marginBottom: -5,
                                      }}
                                    >
                                      Approval Comments
                                    </label>
                                  </div>
                                  <div
                                    className="col-md-2"
                                    style={{ marginLeft: 20 }}
                                  >
                                    <button
                                      className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                      onClick={(e) =>
                                        this.setState({
                                          finalCommentLog:
                                            !this.state.finalCommentLog,
                                        })
                                      }
                                    >
                                      Comment Log
                                    </button>
                                  </div>
                                </div>
                                <div className="row" style={{ marginTop: 5 }}>
                                  <div className="col-md-12">
                                    {/* <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.FinalComments} /> */}
                                    <textarea
                                      className="form-input"
                                      disabled
                                      rows={3}
                                      cols={88}
                                      style={{
                                        maxWidth: "100%",
                                        border: "1px solid lightgrey",
                                      }}
                                      name="Comments"
                                      value={this.state.lastFinalComment}
                                    />
                                  </div>
                                </div>
                                
                                <div className="text-left mt-4">
                                  {this.state.finalCommentLog &&
                                    this.state.finalReviewComments ? (
                                    <>
                                      <div className="custom_modal">
                                        <div className="modal_body">
                                          <div className="row">
                                            <div className="col-md-8">
                                              <h3 className="modal_title">
                                                Lean Approval Comment Log
                                              </h3>
                                            </div>
                                            <div className="col-md-4">
                                              <button
                                                type="button"
                                                className="close text-right"
                                                onClick={(e) =>
                                                  this.setState({
                                                    finalCommentLog: false,
                                                  })
                                                }
                                              >
                                                &times;
                                              </button>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div
                                              className="col-md-2 text-center"
                                              style={{
                                                border: "1px solid lightgrey",
                                                backgroundColor: "gainsboro",
                                              }}
                                            >
                                              <label
                                                style={{
                                                  fontWeight: 100,
                                                  verticalAlign: "bottom",
                                                }}
                                              >
                                                Date & Time
                                              </label>
                                            </div>
                                            <div
                                              className="col-md-2 text-center"
                                              style={{
                                                border: "1px solid lightgrey",
                                                backgroundColor: "gainsboro",
                                              }}
                                            >
                                              <label
                                                style={{
                                                  fontWeight: 100,
                                                  verticalAlign: "bottom",
                                                }}
                                              >
                                                OHR
                                              </label>
                                            </div>
                                            <div
                                              className="col-md-2 text-center"
                                              style={{
                                                border: "1px solid lightgrey",
                                                backgroundColor: "gainsboro",
                                              }}
                                            >
                                              <label
                                                style={{
                                                  fontWeight: 100,
                                                  verticalAlign: "bottom",
                                                }}
                                              >
                                                Name
                                              </label>
                                            </div>
                                            <div
                                              className="col-md-1 text-center"
                                              style={{
                                                border: "1px solid lightgrey",
                                                backgroundColor: "gainsboro",
                                              }}
                                            >
                                              <label
                                                style={{
                                                  fontWeight: 100,
                                                  verticalAlign: "bottom",
                                                }}
                                              >
                                                Role
                                              </label>
                                            </div>
                                            <div
                                              className="col-md-2 text-center"
                                              style={{
                                                border: "1px solid lightgrey",
                                                backgroundColor: "gainsboro",
                                              }}
                                            >
                                              <label
                                                style={{
                                                  fontWeight: 100,
                                                  verticalAlign: "bottom",
                                                }}
                                              >
                                                Action
                                              </label>
                                            </div>
                                            <div
                                              className="col-md-3 text-center"
                                              style={{
                                                border: "1px solid lightgrey",
                                                backgroundColor: "gainsboro",
                                              }}
                                            >
                                              <label
                                                style={{
                                                  fontWeight: 100,
                                                  verticalAlign: "bottom",
                                                }}
                                              >
                                                Comment
                                              </label>
                                            </div>
                                          </div>
                                          {this.state.finalReviewComments.map(
                                            (item, index) => {
                                              return (
                                                <div
                                                  className="row"
                                                  key={index}
                                                >
                                                  <div
                                                    className="col-md-2 text-center"
                                                    style={{
                                                      border:
                                                        "1px solid lightgrey",
                                                    }}
                                                  >
                                                    <label
                                                      style={{
                                                        lineheight: 2,
                                                        verticalAlign: "bottom",
                                                      }}
                                                    >
                                                      {this.formatDateStringWithTime(
                                                        new Date(
                                                          item.CreatedDate
                                                        )
                                                      )}
                                                    </label>
                                                  </div>
                                                  <div
                                                    className="col-md-2 text-center"
                                                    style={{
                                                      border:
                                                        "1px solid lightgrey",
                                                    }}
                                                  >
                                                    <label
                                                      style={{
                                                        lineheight: 2,
                                                        verticalAlign: "bottom",
                                                      }}
                                                    >
                                                      {item.UpdaterOHR}
                                                    </label>
                                                  </div>
                                                  <div
                                                    className="col-md-2 text-center"
                                                    style={{
                                                      border:
                                                        "1px solid lightgrey",
                                                    }}
                                                  >
                                                    <label
                                                      style={{
                                                        lineheight: 2,
                                                        verticalAlign: "bottom",
                                                      }}
                                                    >
                                                      {item.UpdaterName}
                                                    </label>
                                                  </div>
                                                  {/* <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{(item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === 'Idea was reviewed by MBB' ? "MBB" : (item.Action === 'Idea was reviewed by LDT' ? "LDT" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Owner" : "BB")))}</label>
                                                                                                        </div> */}
                                                  <div
                                                    className="col-md-1 text-center"
                                                    style={{
                                                      border:
                                                        "1px solid lightgrey",
                                                    }}
                                                  >
                                                    {/* <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole}</label> */}
                                                    <label
                                                      style={{
                                                        lineheight: 2,
                                                        verticalAlign: "bottom",
                                                      }}
                                                    >
                                                      {item.UpdaterRole
                                                        ? item.UpdaterRole
                                                        : item.Action ===
                                                          "Idea Updated by Admin" ||
                                                          item.Action ===
                                                          "Idea Reinitiated by Admin"
                                                          ? "Admin"
                                                          : item.UpdaterRole}
                                                    </label>
                                                  </div>
                                                  <div
                                                    className="col-md-2 text-center"
                                                    style={{
                                                      border:
                                                        "1px solid lightgrey",
                                                    }}
                                                  >
                                                    {/* <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? item.ApprovalStatus : (item.WorkflowStatus === 'Completed' || item.WorkflowStatus === 'Approve' || item.WorkflowStatus === 'Approved') ? 'Approved' : ((item.WorkflowStatus === 'Rejected' || item.WorkflowStatus === 'Reject') ? "Rejected" : (item.WorkflowStatus === REQUEST_CANCELLATION ? REQUEST_CANCELLATION : (item.WorkflowStatus === 'Pending Documents' ? 'Discard Cancellation' : item.WorkflowStatus === 'Cancelled' ? 'Cancelled' : "Sent Back"))))}</label> */}
                                                    <label>
                                                      {item.UpdaterAction
                                                        ? item.UpdaterAction
                                                        : item.Action ===
                                                          "Idea Reinitiated by Admin"
                                                          ? "Idea Reinitiated"
                                                          : item.Action ===
                                                            "Idea Updated by Admin"
                                                            ? item.ApprovalStatus
                                                            : item.WorkflowStatus ===
                                                              "Completed" ||
                                                              item.WorkflowStatus ===
                                                              "Approve" ||
                                                              item.WorkflowStatus ===
                                                              "Approved" ||
                                                              item.ApprovalStatus ===
                                                              "Awaiting Quality Check Review" ||
                                                              item.ApprovalStatus ===
                                                              "Awaiting Project Review" ||
                                                              item.ApprovalStatus ===
                                                              "Completed"
                                                              ? "Approved"
                                                              : item.WorkflowStatus ===
                                                                "Rejected" ||
                                                                item.WorkflowStatus ===
                                                                "Reject" ||
                                                                item.ApprovalStatus ===
                                                                "Rejected" ||
                                                                item.ApprovalStatus ===
                                                                "Reject"
                                                                ? "Rejected"
                                                                : item.WorkflowStatus ===
                                                                  REQUEST_CANCELLATION
                                                                  ? REQUEST_CANCELLATION
                                                                  : item.WorkflowStatus ===
                                                                    "Pending Documents"
                                                                    ? "Discard Cancellation"
                                                                    : item.WorkflowStatus ===
                                                                      "Cancelled"
                                                                      ? "Cancelled"
                                                                      : "Sent Back"}
                                                    </label>

                                                    {/* <label>{item.UpdaterAction}</label> */}
                                                  </div>
                                                  <div
                                                    className="col-md-3 text-center"
                                                    style={{
                                                      border:
                                                        "1px solid lightgrey",
                                                    }}
                                                  >
                                                    <label
                                                      style={{
                                                        lineheight: 2,
                                                        verticalAlign: "bottom",
                                                      }}
                                                    >
                                                      {item.Comment
                                                        ? item.Comment
                                                        : "-"}
                                                    </label>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}

                        {
                          this.state.ideaItem.IsFPnAApprovalAttached === "Yes" || this.state.ideaItem.IsMBBValueDely==="Yes"
                          ?
                          <>
                            <div className="">
                              <div className="gol_fields_block text-left" style={{ borderTop: "1px solid lightgrey", marginTop: "20px",padding: "10px 0px 20px 0px" }}>
                                <p className="u-align-left u-text u-text-2" style={{ margin: "10px 0px 10px" }}>Value Delivery Approval</p>
                                <div className="row">
                                  <div className="col-md-8 gol_field_div text-left">
                                    <span className="form-attribute">
                                      I acknowledge that I have reviewed the value delivery and I agree with the value apportioning between Client/Genpact, categorization and computation along with the necessary approvals recorded on this project ?
                                    </span>
                                  </div>
                                  <div className="col-md-2">
                                    {
                                      this.state.ideaItem.IsFPnAApprovalAttached
                                      ?
                                      <>
                                        <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: "5px" }}>BlackBelt Approval</label>
                                        <input disabled value={this.state.ideaItem.IsFPnAApprovalAttached} className="form-input disabled_input" />
                                      </>
                                      :
                                      null
                                    }
                                    
                                  </div>
                                  <div className="col-md-2">
                                    {
                                      this.state.ideaItem.IsMBBValueDely
                                      ?
                                      <>
                                        <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: "5px" }}>MBB Approval</label>
                                        <input disabled value={this.state.ideaItem.IsMBBValueDely} className="form-input disabled_input" />
                                      </>
                                      :
                                      null
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>

                          </>
                          :
                          null
                        }




                        {this.state.ideaItem.ApprovalStatus &&
                          (this.state.ideaItem.ApprovalStatus ===
                            COMPLETED_STATUS ||
                            this.state.ideaItem.ApprovalStatus ===
                            AWAITING_MBB_APPROVAL ||
                            this.state.ideaItem.ApprovalStatus ===
                            AWAITING_QAULITY_CHECK_REVIEW ||
                            this.state.ideaItem.ApprovalStatus ===
                            "Documents Uploaded" ||
                            this.state.ideaItem.ApprovalStatus ===
                            "Awaiting Project Review") && (
                            <>
                              {/* {
                                                                this.state.ideaItem.IsFPnAApprovalAttached === "Yes" && this.state.IsVDType !== "No Value Delivery"
                                                                ?
                                                                <>
                                                                    <div style={{ marginBottom: 15,borderTop:"1px solid lightgray" }} />
                                                                    <div className="text-left" style={{borderTop:"1px solid ligthgray",padding:"0px 0px 30px 0px"}}>
                                                                        <p className="u-align-left u-text u-text-2" style={{margin: "10px 0px 20px"}}>Approval Section</p>
                                                                        <div className="row">
                                                                            <div className="col-md-8">
                                                                                <div className="row">
                                                                                    <div className="col-md-7 gol_field_div text-left">
                                                                                        <span className="form-attribute">The Owner has marked the project as {this.state.IsVDType}. Do you agree with it?</span> 
                                                                                    </div>
                                                                                    <div className="col-md-3">
                                                                                        <input disabled value={this.state.ideaItem.IsFPnAApprovalAttached === "Yes" ? "Yes" : "No"} className="form-input disabled_input" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="gol_fields_block text-left" style={{paddingTop: "10px",borderTop: "1px solid lightgray",borderBottom:"0px",paddingBottom:"0px"}}>
                                                                        <p className="u-align-left u-text u-text-2" style={{margin: "10px 0px 20px"}}>Project Document Review</p>
                                                                        <div className="row">
                                                                            <div className="col-md-8">
                                                                                <div className="row">
                                                                                    <div className="col-md-7 gol_field_div text-left">
                                                                                        <span className="form-attribute">I have reviewed the Value Delivery supporting documents and confirm that they have the correct stakeholder signoff</span> 
                                                                                    </div>
                                                                                    <div className="col-md-5">
                                                                                        <input type="checkbox" id="signOffCheckbox" checked={true} name="signOffCheckbox" value={this.state.signOffCheckbox} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                            } */}
                              {this.state.businessImpactValueArray.length > 0 ? (
                                <>
                                  <div
                                    id="Potential Impact"
                                    className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                                  >
                                    <div
                                      className="u-container-layout u-container-layout-7"
                                      style={{
                                        // padding: "34px 0px 5px 0px",
                                        // borderTop: "1px solid lightgrey",
                                        // borderBottom: "1px solid lightgrey",
                                        // marginBottom: "30px",

                                        padding: "15px 0px 5px 0px"
                                      }}
                                    >
                                      <p
                                        id="Potential Impact"
                                        className="u-align-left u-text u-text-2"
                                        style={{ marginBottom: "20px" }}
                                      >
                                        Final additional details
                                      </p>
                                      <div className="row pb-4">
                                        <div className="col-md-5">
                                          <div className="row d_flex_align_items_center">
                                            <div className="col-md-6">
                                              <span className="form-attribute">
                                                Best in Class
                                              </span>
                                            </div>
                                            <div className="col-md-6">
                                              <label className="switch">
                                                <input
                                                  disabled
                                                  type="checkbox"
                                                  checked={
                                                    this.state.ideaItem
                                                      .IsBestInClass === null
                                                      ? false
                                                      : this.state.ideaItem
                                                        .IsBestInClass
                                                  }
                                                />
                                                <span className="slider round" />
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-5">
                                          {this.state.ideaItem
                                            .ReplicabilityIndex !== null && (
                                              <div className="row d_flex_align_items_center">
                                                <div className="col-md-6">
                                                  <span className="form-attribute">
                                                    Replicability Index
                                                  </span>
                                                </div>
                                                <div className="col-md-6">
                                                  <input
                                                    className="form-input"
                                                    disabled
                                                    value={this.state.replicabilityIndex && this.state.replicabilityIndex.label}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </>
                          )}

                        {this.state.ideaItem.VDType && this.state.ideaItem.VDType === "No Value Delivery" ? (
                          <></>
                        ) : <div className="" style={{ borderTop: "1px solid lightgray",marginBottom: "30px"}}></div>}
                        {this.state.ideaItem.IdeaId && this.state.ideaItem.VDType ? (
                          <>
                            <ValueDelivery
                              func={this.pull_data}
                              viewIdea={true}
                              vdType={this.handleVDTypeChange}
                              IdeaId={this.state.ideaItem.IdeaId}
                              fetchMonetizationFileDetails={this.pull_file_data}
                              fetchMonetizationFpnaOhr={
                                this.pull_monetization_fpna_ohr
                              }
                              fetchMonetizationFpnaName={
                                this.pull_monetization_fpna_name
                              }
                              fetchMonetizationSowID={
                                this.pull_monetization_sowId
                              }
                              bbMonetizationTotal={this.pull_monetizedTotal}
                              monetizableProject={this.pull_monetizableProject}
                              monetizableStatus={this.pull_monetizableStatus}
                              fetchFPAOHRforIncreaseInRevenue={
                                this.fetchFPAOHRforIncreaseInRevenue
                              }
                              bbMonetizationApproval={false}
                              postFpnaValid={
                                this.state.ideaItem.MonetizationState ===
                                  null ||
                                  this.state.ideaItem.MonetizationState ===
                                  undefined
                                  ? false
                                  : this.state.ideaItem.MonetizationState.trim() ===
                                    "" ||
                                    this.state.ideaItem.MonetizationState ===
                                    null
                                    ? false
                                    : true
                              }
                              fetchDeletedBIRows={
                                this.remove_bi_data_after_delete
                              }
                              fetchMonetizationGOLName={
                                this.pullMonetizationGOLName
                              }
                              fetchMonetizationGOLOhr={
                                this.pullMonetizationGOLOhr
                              }
                            />
                             
                          </>
                        ) : null}

                        {/* ...............qc approval detail start here.......... */}
                        {this.state.QCOHR ? (
                          <>
                            <div
                              id="Approval Status"
                              className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7"
                            >
                              <div
                                className="u-container-layout u-container-layout-7"
                                style={{
                                  margin: "10px 0px",
                                  borderBottom: "1px solid lightgray",
                                  borderTop: "1px solid lightgray",
                                  paddingTop: "35px",
                                }}
                              >
                                <p
                                  className="u-align-left u-text u-text-2"
                                  style={{ marginBottom: 10 }}
                                >
                                  QC Approval details
                                </p>
                                <div className="row">
                                  <div className="col-md-2">
                                    <span className="form-attribute">
                                      Approval Status
                                    </span>{" "}
                                  </div>
                                  <div className="col-md-3">
                                    <input
                                      className="form-input"
                                      disabled
                                      name="finalapprovalstatus"
                                      value={this.state.ideaItem.ApprovalStatus}
                                    />
                                  </div>
                                </div>

                                {
                                  this.state.ideaItem.QCName ?
                                  <>
                                      <div className="row" style={{ marginTop: 15 }}>
                                        <div className="col-md-2">
                                          <span className="form-attribute">
                                            QC Approver
                                          </span>{" "}
                                        </div>
                                        <div className="col-md-3">
                                          <input
                                            className="form-input disabled_input"
                                            disabled
                                            value={this.state.ideaItem.QCName ? this.state.ideaItem.QCName : ""}
                                          />
                                        </div>
                                      </div>
                                  </>
                                  :
                                  null
                                }

                                


                                <div className="row" style={{ marginTop: 15 }}>
                                  <div className="col-md-5">
                                    <label
                                      style={{
                                        verticalAlign: "bottom",
                                        marginBottom: -5,
                                      }}
                                    >
                                      Approval Comments
                                    </label>
                                  </div>
                                  <div
                                    className="col-md-2"
                                    style={{ marginLeft: 20 }}
                                  >
                                    <button
                                      className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                      onClick={(e) =>
                                        this.setState({
                                          qcCommentLog:
                                            !this.state.qcCommentLog,
                                        })
                                      }
                                    >
                                      Comment Log
                                    </button>
                                  </div>
                                </div>
                                <div className="row" style={{ marginTop: 5 }}>
                                  <div className="col-md-12">
                                    <textarea
                                      className="form-input"
                                      disabled
                                      rows={3}
                                      cols={88}
                                      style={{
                                        maxWidth: "100%",
                                        border: "1px solid lightgrey",
                                      }}
                                      name="Comments"
                                      value={this.state.lastQCCOmment}
                                    />
                                  </div>
                                </div>
                                <div className="text-left mt-4">
                                  {this.state.qcCommentLog &&
                                    this.state.qcComments ? (
                                    <>
                                      <div className="custom_modal">
                                        <div className="modal_body">
                                          <div className="row">
                                            <div className="col-md-8">
                                              <h3 className="modal_title">
                                                QC Comment Log
                                              </h3>
                                            </div>
                                            <div className="col-md-4">
                                              <button
                                                type="button"
                                                className="close text-right"
                                                onClick={(e) =>
                                                  this.setState({
                                                    qcCommentLog: false,
                                                  })
                                                }
                                              >
                                                &times;
                                              </button>
                                            </div>
                                          </div>

                                          <div className="qc_responsive_comment_log">
                                            <div className="table table-responsive table-bordered">
                                              <thead className="text-center">
                                                <tr>
                                                  <th className="qcHeaderbg qcVDHeader">
                                                    <label
                                                      style={{
                                                        fontWeight: 100,
                                                        verticalAlign: "bottom",
                                                      }}
                                                    >
                                                      Date & Time
                                                    </label>
                                                  </th>
                                                  <th className="qcHeaderbg qcVDHeader">
                                                    <label
                                                      style={{
                                                        fontWeight: 100,
                                                        verticalAlign: "bottom",
                                                      }}
                                                    >
                                                      OHR
                                                    </label>
                                                  </th>
                                                  <th className="qcHeaderbg qcVDHeader">
                                                    <label
                                                      style={{
                                                        fontWeight: 100,
                                                        verticalAlign: "bottom",
                                                      }}
                                                    >
                                                      Name
                                                    </label>
                                                  </th>
                                                  <th className="qcHeaderbg qcVDHeader">
                                                    <label
                                                      style={{
                                                        fontWeight: 100,
                                                        verticalAlign: "bottom",
                                                      }}
                                                    >
                                                      Role
                                                    </label>
                                                  </th>
                                                  <th className="qcHeaderbg qcVDHeader">
                                                    <label
                                                      style={{
                                                        fontWeight: 100,
                                                        verticalAlign: "bottom",
                                                      }}
                                                    >
                                                      Action
                                                    </label>
                                                  </th>
                                                  <th
                                                    className="qcHeaderbg"
                                                    style={{
                                                      minWidth: "250px",
                                                    }}
                                                  >
                                                    <label
                                                      style={{
                                                        fontWeight: 100,
                                                        verticalAlign: "bottom",
                                                      }}
                                                    >
                                                      Comment
                                                    </label>
                                                  </th>
                                                  <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthOne">
                                                    <label
                                                      style={{
                                                        fontWeight: 100,
                                                        verticalAlign: "bottom",
                                                      }}
                                                    >
                                                      Is the impact correctly
                                                      tagged to Genpact or
                                                      Client ?
                                                    </label>
                                                  </th>
                                                  <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthTwo">
                                                    <label
                                                      style={{
                                                        fontWeight: 100,
                                                        verticalAlign: "bottom",
                                                      }}
                                                    >
                                                      Has the Value Delivery
                                                      been correctly categorized
                                                      ?
                                                    </label>
                                                  </th>
                                                  <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthThree">
                                                    <label
                                                      style={{
                                                        fontWeight: 100,
                                                        verticalAlign: "bottom",
                                                      }}
                                                    >
                                                      Has the Value Delivery
                                                      been computed correctly ?
                                                    </label>
                                                  </th>
                                                  <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthFour">
                                                    <label
                                                      style={{
                                                        fontWeight: 100,
                                                        verticalAlign: "bottom",
                                                      }}
                                                    >
                                                      Do the value delivery approvals (applicable as per the value playbook) clearly articulate what improved and for which time-period ?
                                                    </label>
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.qcComments.map(
                                                  (item, index) => {
                                                    return (
                                                      <tr key={index}>
                                                        <td>
                                                          <label
                                                            style={{
                                                              lineheight: 2,
                                                              verticalAlign:
                                                                "bottom",
                                                            }}
                                                          >
                                                            {this.formatDateStringWithTime(
                                                              new Date(
                                                                item.CreatedDate
                                                              )
                                                            )}
                                                          </label>
                                                        </td>
                                                        <td>
                                                          <label
                                                            style={{
                                                              lineheight: 2,
                                                              verticalAlign:
                                                                "bottom",
                                                            }}
                                                          >
                                                            {item.UpdaterOHR}
                                                          </label>
                                                        </td>
                                                        <td>
                                                          <label
                                                            style={{
                                                              lineheight: 2,
                                                              verticalAlign:
                                                                "bottom",
                                                            }}
                                                          >
                                                            {item.UpdaterName}
                                                          </label>
                                                        </td>
                                                        <td>
                                                          <label
                                                            style={{
                                                              lineheight: 2,
                                                              verticalAlign:
                                                                "bottom",
                                                            }}
                                                          >
                                                            {item.UpdaterRole
                                                              ? item.UpdaterRole
                                                              : item.Action ===
                                                                "Idea Updated by Admin" ||
                                                                item.Action ===
                                                                "Idea Reinitiated by Admin"
                                                                ? "Admin"
                                                                : item.Action ===
                                                                  "Delegate Action"
                                                                  ? "BB"
                                                                  : item.UpdaterOHR ===
                                                                    this.state
                                                                      .MBBOHR
                                                                    ? "MBB"
                                                                    : item.UpdaterOHR ===
                                                                      this.state
                                                                        .ideaItem
                                                                        .OwnerOHR
                                                                      ? "Idea Owner"
                                                                      : item.ApprovalStatus ===
                                                                        "Pending Documents" &&
                                                                        item.WorkflowStatus ===
                                                                        "Approved"
                                                                        ? "BB"
                                                                        : item.ApprovalStatus ===
                                                                          "NA" &&
                                                                          item.WorkflowStatus ===
                                                                          "Pending"
                                                                          ? "BB"
                                                                          : item.UpdaterRole}
                                                          </label>
                                                        </td>
                                                        <td>
                                                          {/* <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.ApprovalStatus==="Documents Uploaded" ? "Send Back" : ((item.WorkflowStatus==="Rejected" || item.WorkflowStatus==="Reject" || item.ApprovalStatus==="Reject" || item.ApprovalStatus==="Rejected" )? "Rejected ":"Approved")}</label> */}
                                                          {/* <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.ApprovalStatus==="Documents Uploaded" ? "Send Back" : ((item.WorkflowStatus==="Rejected" || item.WorkflowStatus==="Reject" || item.ApprovalStatus==="Reject" || item.ApprovalStatus==="Rejected" )? "Rejected ":(item.Action === "Idea was Rework - QC" ? "Rework" : "Approved"))}</label> */}

                                                          <label
                                                            style={{
                                                              lineheight: 2,
                                                              verticalAlign:
                                                                "bottom",
                                                            }}
                                                          >
                                                            {item.UpdaterAction
                                                              ? item.UpdaterAction
                                                              : item.ApprovalStatus ===
                                                                "Documents Uploaded"
                                                                ? "Send Back"
                                                                : item.WorkflowStatus ===
                                                                  "Rejected" ||
                                                                  item.WorkflowStatus ===
                                                                  "Reject" ||
                                                                  item.ApprovalStatus ===
                                                                  "Reject" ||
                                                                  item.ApprovalStatus ===
                                                                  "Rejected"
                                                                  ? "Rejected "
                                                                  : item.Action ===
                                                                    "Idea was Rework - QC"
                                                                    ? "Rework"
                                                                    : "Approved"}
                                                          </label>
                                                        </td>
                                                        <td>
                                                          <label
                                                            style={{
                                                              lineheight: 2,
                                                              verticalAlign:
                                                                "bottom",
                                                            }}
                                                          >
                                                            {item.Comment !== ""
                                                              ? item.Comment
                                                              : "-"}
                                                          </label>
                                                        </td>
                                                        {this.state
                                                          .qcCommentsWithVD &&
                                                          this.state
                                                            .qcCommentsWithVD
                                                            .length > 0 ? (
                                                          <>
                                                            {this.state.qcCommentsWithVD.map(
                                                              (pg_item) => {
                                                                if (
                                                                  Number(
                                                                    pg_item.ProjectTeamID
                                                                  ) ===
                                                                  item.TrackId
                                                                ) {
                                                                  const replacedJSONString = pg_item.AuditDataJSON ?  pg_item.AuditDataJSON.replace(/'/g, '"') : "";
                                                                  console.log(replacedJSONString)
                                                                  const auditData = JSON.parse(replacedJSONString)
                                                                  return (
                                                                    <>
                                                                      <td>
                                                                        <label
                                                                          style={{
                                                                            lineheight: 2,
                                                                            verticalAlign:
                                                                              "bottom",
                                                                          }}
                                                                        >{auditData.pg_isvdimpacttagged ? auditData.pg_isvdimpacttagged : "-"}
                                                                        </label>
                                                                      </td>
                                                                      <td>
                                                                        <label
                                                                          style={{
                                                                            lineheight: 2,
                                                                            verticalAlign:
                                                                              "bottom",
                                                                          }}
                                                                        >
                                                                          {auditData.pg_isvdcategorized ? auditData.pg_isvdcategorized : "-"}
                                                                        </label>
                                                                      </td>
                                                                      <td>
                                                                        <label
                                                                          style={{
                                                                            lineheight: 2,
                                                                            verticalAlign:
                                                                              "bottom",
                                                                          }}
                                                                        >
                                                                          {auditData.pg_isvdcomputed ? auditData.pg_isvdcomputed : "-"}
                                                                        </label>
                                                                      </td>
                                                                      <td>
                                                                        <label
                                                                          style={{
                                                                            lineheight: 2,
                                                                            verticalAlign:
                                                                              "bottom",
                                                                          }}
                                                                        >
                                                                          {auditData.pg_isvdclientsignoff ? auditData.pg_isvdclientsignoff : "-"}
                                                                        </label>
                                                                      </td>
                                                                    </>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                          </>
                                                        ) : (
                                                          <>
                                                            <td>
                                                              <label
                                                                style={{
                                                                  lineheight: 2,
                                                                  verticalAlign:
                                                                    "bottom",
                                                                }}
                                                              >
                                                                -
                                                              </label>
                                                            </td>
                                                            <td>
                                                              <label
                                                                style={{
                                                                  lineheight: 2,
                                                                  verticalAlign:
                                                                    "bottom",
                                                                }}
                                                              >
                                                                -
                                                              </label>
                                                            </td>
                                                            <td>
                                                              <label
                                                                style={{
                                                                  lineheight: 2,
                                                                  verticalAlign:
                                                                    "bottom",
                                                                }}
                                                              >
                                                                -
                                                              </label>
                                                            </td>
                                                            <td>
                                                              <label
                                                                style={{
                                                                  lineheight: 2,
                                                                  verticalAlign:
                                                                    "bottom",
                                                                }}
                                                              >
                                                                -
                                                              </label>
                                                            </td>
                                                          </>
                                                        )}
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </div>
                                          </div>

                                          {/* <div className="row">
                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                        </div>
                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                        </div>
                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                        </div>
                                                                                        <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                        </div>
                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                        </div>
                                                                                        <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        this.state.qcComments.map((item, index) => {
                                                                                            return (
                                                                                                <div className="row" key={index}>
                                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{(item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === "Delegate Action" ? 'BB' : (item.UpdaterOHR === this.state.MBBOHR ? 'MBB' : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Idea Owner" : (item.ApprovalStatus ===  "Pending Documents" && item.WorkflowStatus === "Approved") ? "BB" : (item.ApprovalStatus ===  "NA" && item.WorkflowStatus === "Pending") ? "BB" : item.UpdaterRole )))}</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.ApprovalStatus==="Documents Uploaded" ? "Send Back" : ((item.WorkflowStatus==="Rejected" || item.WorkflowStatus==="Reject" || item.ApprovalStatus==="Reject" || item.ApprovalStatus==="Rejected" )? "Rejected ":"Approved")}</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment !== '' ? item.Comment:'-'}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    } */}
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>

                            <div
                              className="gol_fields_block"
                              style={{ borderBottom: "0px" }}
                            >
                              <p
                                className="u-align-left u-text u-text-2"
                                style={{ margin: "10px 0px 20px" }}
                              >
                                Quality Check Details
                              </p>
                              <div className="row u-align-left">
                                <div className="col-md-5">
                                  <div className="row">
                                    <div className="col-md-9 gol_field_div">
                                      <span className="form-attribute">
                                        Is the impact correctly tagged to
                                        Genpact or Client ?
                                      </span>
                                    </div>
                                    <div className="col-md-3">
                                      <input
                                        className="form-input disabled_input"
                                        disabled
                                        value={
                                          this.state.ideaItem.IsVDImpactTagged
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginBottom: 15 }} />

                                  <div className="row">
                                    <div className="col-md-9 gol_field_div">
                                      <span className="form-attribute">
                                        Has the value delivered been correctly
                                        categorized ?
                                      </span>
                                    </div>
                                    <div className="col-md-3">
                                      <input
                                        className="form-input disabled_input"
                                        disabled
                                        value={
                                          this.state.ideaItem.IsVDCategorized
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div style={{ marginBottom: 15 }} />

                                  <div className="row">
                                    <div className="col-md-5 gol_field_div">
                                      <span className="form-attribute">
                                        Project Reviewed By
                                      </span>
                                    </div>
                                    <div className="col-md-7">
                                      <input
                                        className="form-input disabled_input"
                                        disabled
                                        value={this.state.ideaItem.QCName}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-5">
                                  <div className="row">
                                    <div className="col-md-9 gol_field_div">
                                      <span className="form-attribute">
                                        Has the value delivered been computed
                                        correctly ?
                                      </span>
                                    </div>
                                    <div className="col-md-3">
                                      <input
                                        disabled
                                        value={this.state.ideaItem.IsVDComputed}
                                        className="form-input disabled_input"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginBottom: 15 }} />

                                  <div className="row">
                                    <div className="col-md-9 gol_field_div">
                                      <span className="form-attribute">
                                        Do the value delivery approvals (applicable as per the value playbook) clearly articulate what improved and for which time-period?
                                      </span>
                                    </div>
                                    <div className="col-md-3">
                                      <input
                                        disabled
                                        className="form-input disabled_input"
                                        type="text"
                                        value={
                                          this.state.ideaItem.IsVDClientSignOff
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                        {/* ..................qc approval details end here.......... */}
                      </>
                    )}

                  {this.state.ideaItem.ApprovalStatus !==
                    "Awaiting Project Creation" &&
                    this.state.ideaItem.ApprovalStatus !== "NA" &&
                    this.state.ideaItem.ApprovalStatus !== "Pending Documents" ? (
                    <>
                      {this.state.ideaItem.VDType === "" ||
                        this.state.ideaItem.VDType === null ? (
                        <>
                          <div
                            className="gol_uploaded_documents"
                            style={{
                              borderTop: "1px solid lightgray",
                              paddingTop: "35px",
                            }}
                          >
                            <p className="u-align-left u-text u-text-2">
                              Project Documents
                            </p>
                            <div className="">
                              <div className="row">
                                <div className="col-md-6">
                                  {/* <div className="mt-3 text-left">
                                                                        {
                                                                            this.state.ideaItem.ProjectPPTFileName
                                                                            ?
                                                                            <>
                                                                                <div className="row">
                                                                                    <div className="col-md-12">
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <button className="file_name" onClick={this.showPPTContent}>{this.state.ideaItem.ProjectPPTFileName}</button>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div> */}
                                  <div className="mt-3 text-left">
                                    {this.state.ideaItem
                                      .CustomerSignoffFileName ? (
                                      <>
                                        <div className="row">
                                          <div className="col-md-12"></div>
                                          <div className="col-md-12">
                                            <button
                                              className="file_name"
                                              onClick={this.showSignOffContent}
                                            >
                                              {
                                                this.state.ideaItem
                                                  .CustomerSignoffFileName
                                              }
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                  </div>

                                  <div className="mt-3 text-left">
                                    {this.state.ideaItem.GOLFileName ? (
                                      <>
                                        <div className="row">
                                          <div className="col-md-12"></div>
                                          <div className="col-md-12">
                                            <button
                                              className="file_name"
                                              onClick={(e) =>
                                                this.showGolOffContent()
                                              }
                                            >
                                              {this.state.ideaItem.GOLFileName}
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                  <div className="mt-3 text-left">
                                    {this.state.ideaItem.FPnAFileName ? (
                                      <>
                                        <div className="row">
                                          <div className="col-md-12"></div>
                                          <div className="col-md-12">
                                            <button
                                              className="file_name"
                                              onClick={(e) =>
                                                this.showFpnaContent()
                                              }
                                            >
                                              {this.state.ideaItem.FPnAFileName}
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : null}

                  {/* <div>
                    <a href={redirectURL} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" style={{ marginTop: 15 }}>Done</a>
                  </div> */}



                 
                  {
                    this.state.ideaItem.TransformationLeverName &&
                      this.state.ideaItem.TransformationLeverName === "Data" &&
                      this.state.ideaItem.DBProjectType
                      && this.state.displayDBFields
                      ?
                      <>
                        <div className="">
                          <div className="" style={{ marginTop: "15px" }}>
                            {
                              this.state.ideaItem.VDType && this.state.ideaItem.VDType === "No Value Delivery"
                              ?
                              <></>
                              :
                              <div className="" style={{borderTop:"1px solid lightgrey"}} />
                            }
                            
                            <p className="u-align-left u-text u-text-2" style={{ margin: "15px 0px 0px 0px" }}>Data Bridge Fields</p>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="submit_page_new_fields">
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-5">
                                      <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                        DB Project Type</label>
                                    </div>
                                    <div className="col-md-7">
                                      <input value={this.state.ideaItem.DBProjectType} disabled className="form-input  disabled_input" placeholder="Provide Value" />
                                    </div>
                                  </div>
                                </div>

                                {
                                  this.state.ideaItem.DBProjectType
                                    ?
                                    <>
                                      <div className="submit_page_new_fields" style={{}}>
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-5">
                                            <span className="form-attribute">
                                              Template Type
                                            </span>
                                          </div>
                                          <div className="col-md-7">
                                            <div className="ai_first_doc_link">
                                              <input className="template_type_input" disabled value={this.state.ideaItem.DBProjectType} ></input>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                    :
                                    null
                                }
                                <div className="submit_page_new_fields">
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-5">
                                      <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                        COO</label>
                                    </div>
                                    <div className="col-md-7">
                                      <input value={this.state.ideaItem.COO} type="text" placeholder="Provide Value" className="form-input  disabled_input"
                                        disabled />
                                    </div>
                                  </div>
                                </div>
                                <div className="submit_page_new_fields">
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-5">
                                      <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                        MUC ID</label>
                                    </div>
                                    <div className="col-md-7">
                                      <input data-toggle="tooltip" title={this.state.ideaItem.MUCIDTitle ? this.state.ideaItem.MUCIDTitle :""} value={this.state.ideaItem.MUCIDTitle} placeholder="Please choose" disabled className="form-input  disabled_input" />
                                    </div>
                                  </div>
                                </div>

                                <div className="submit_page_new_fields">
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-5">
                                      <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                        Shown to Client</label>
                                    </div>
                                    <div className="col-md-7">
                                      <input value={this.state.ideasDB.IsSharedClient} placeholder="Please choose" disabled className="form-input disabled_input" />
                                    </div>
                                  </div>
                                </div>
                                {
                                  this.state.ideasDB.IsSharedClient && this.state.ideasDB.IsSharedClient === "Yes"
                                    ?
                                    <>
                                      <div className="">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="submit_page_new_fields">
                                              <div className="row d_flex_align_items_center">
                                                <div className="col-md-5">
                                                  <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                    Client Testimony</label>
                                                </div>
                                                <div className="col-md-7">
                                                  <button className={this.state.ideaItem.CTestimonyFileName.length > 50?"upload_file_text_overflow":""} style={{ backgroundColor: 'transparent', textAlign: "left", marginTop: "15px", color: '#337ab7', padding: 0 }} onClick={(e) => this.downloadClientTestimony()}>{this.state.ideaItem.CTestimonyFileName}</button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-2"></div>
                                          <div className="col-md-5">

                                          </div>
                                        </div>
                                      </div>


                                    </>
                                    :
                                    null
                                }
                                <div className="submit_page_new_fields">
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-5">
                                      <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                        Shown to GOL</label>
                                    </div>
                                    <div className="col-md-7">
                                      <input value={this.state.ideasDB.IsSharedGOL} placeholder="Provide value" className="form-input disabled_input" disabled />
                                    </div>
                                  </div>
                                </div>
                                <div className="submit_page_new_fields">
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-5">
                                      <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                        DB Tool Used</label>
                                    </div>
                                    <div className="col-md-7">
                                      <input value={this.state.ideasDB.DBTool} className="form-input disabled_input" disabled
                                        placeholder="Provide value" />
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="col-md-4">

                                <div className="submit_page_new_fields">
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-5">
                                      <label className="form-attribute" style={{
                                        verticalAlign: 'bottom', marginBottom: -5
                                      }}>
                                        Process Owner OHR</label>
                                    </div>
                                    <div className="col-md-7">
                                      <input type="text"
                                        disabled
                                        placeholder="Provide Value"
                                        value={this.state.ideaItem.ProcessOwnerOHR}
                                        className="form-input form-attribute" />

                                    </div>
                                  </div>
                                </div>
                                <div className="submit_page_new_fields">
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-5">
                                      <label className="form-attribute" style={{
                                        verticalAlign: 'bottom', marginBottom: -5
                                      }}>
                                        Process Owner Name</label>
                                    </div>
                                    <div className="col-md-7">
                                      <input value={this.state.ideaItem.ProcessOwner} type="text" placeholder="Provide Value" className="form-input disabled_input"
                                        disabled />
                                    </div>
                                  </div>
                                </div>



                                {
                                  this.state.ideasDB.IsSharedClient && this.state.ideasDB.IsSharedClient === "Yes"
                                    ?
                                    <>
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-5">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Client Role</label>
                                          </div>
                                          <div className="col-md-7">
                                            <input value={this.state.ideasDB.ClientRole} type="text" placeholder="Provide value" className="form-input disabled_input"
                                              disabled />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-5">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Client Name</label>
                                          </div>
                                          <div className="col-md-7">
                                            <input value={this.state.ideasDB.ClientName} type="text" disabled className="form-input  disabled_input" />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                    :
                                    null
                                }
                                {
                                  this.state.ideasDB.IsSharedGOL && this.state.ideasDB.IsSharedGOL === "Yes"
                                    ?
                                    <>
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-5">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              GOL OHR</label>
                                          </div>
                                          <div className="col-md-7">
                                            <input value={this.state.ideasDB.GOLOHR} type="text" placeholder="Provide value" className="form-input form-attribute"
                                              disabled />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-5">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              GOL Name</label>
                                          </div>
                                          <div className="col-md-7">
                                            <input type="text" placeholder="Provide value" disabled value={this.state.ideasDB.GOL}
                                              className="form-input disabled_input" />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                    :
                                    null
                                }
                                {
                                  this.state.ideasDB.DBTool && this.state.ideasDB.DBTool === "Others"
                                    ?
                                    <>
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-5">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Other DB Tool Used</label>
                                          </div>
                                          <div className="col-md-7">
                                            <input data-toggle="tooltip" title={this.state.ideasDB.OtherDBTool} value={this.state.ideasDB.OtherDBTool} type="text" placeholder="Provide value" disabled
                                              className="form-input disabled_input" />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                    :
                                    null
                                }


                              </div>
                              <div className="col-md-4" style={{ paddingRight: "30px" }}>
                                {
                                  this.state.ideasDB.IsSustenance && this.state.ideasDB.IsSustenance === "Yes"
                                    ?
                                    <>
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-8">
                                            <label
                                              className="form-attribute"
                                              style={{
                                                verticalAlign: "bottom",
                                                marginBottom: -5,
                                              }}
                                            >
                                              Is Sustenance tracking applicable ? </label>
                                          </div>
                                          <div className="col-md-4">
                                            <input
                                              value={this.state.ideasDB.IsSustenance}
                                              placeholder="Provide value"
                                              className="form-input"
                                              disabled

                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                    :
                                    null
                                }

                                {
                                  this.state.ideasDB.ModelSummary
                                    ?
                                    <>
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-12">
                                            <label
                                              className="form-attribute"
                                              style={{
                                                verticalAlign: "bottom",
                                                marginBottom: 5,
                                              }}
                                            >
                                              How is the model operationalized ?
                                            </label>
                                          </div>
                                          <div className="col-md-12">
                                            <input
                                              value={
                                                this.state.ideasDB.ModelSummary
                                              }
                                              placeholder="Please choose"
                                              disabled
                                              className="form-input"

                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                    :
                                    null
                                }


                                {this.state.ideasDB.IsSustenance && this.state.ideasDB.IsSustenance === "Yes" ? (
                                  <>
                                    <div className="submit_page_new_fields">
                                      <div className="row d_flex_align_items_center">
                                        <div className="col-md-8">
                                          <label
                                            className="form-attribute"
                                            style={{
                                              verticalAlign: "bottom",
                                              marginBottom: -5,
                                            }}
                                          >

                                            Sustenance tracking frequency ?
                                          </label>
                                        </div>
                                        <div className="col-md-4">
                                          <input
                                            value={this.state.ideasDB.SustenanceFreq}
                                            disabled
                                            placeholder="Please choose"
                                            className="form-input"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="submit_page_new_fields">
                                      <div className="row d_flex_align_items_center">
                                        <div className="col-md-12">
                                          <label
                                            className="form-attribute"
                                            style={{
                                              verticalAlign: "bottom",
                                              marginBottom: 5,
                                            }}
                                          >

                                            Sustenance metric
                                          </label>
                                        </div>
                                        <div className="col-md-12">
                                          <textarea
                                            className="form-input"
                                            disabled
                                            value={this.state.ideasDB.SustenanceMetric}
                                            placeholder="Please provide sustenance metrics"
                                            style={{
                                              width: "100%",
                                              border: "1px solid lightgrey",

                                            }}
                                            rows={3}
                                          />
                                        </div>
                                      </div>
                                    </div>


                                  </>
                                ) : null}

                              </div>
                            </div>
                          </div>
                        </div>

                        {
                          this.state.ideaItem.TransformationLeverName === "Data" && this.state.ideaItem.IdeaId
                          && this.state.displayDBFields
                            ?
                            <>
                              <DisplayTemplate IdeaId={this.state.ideaItem.IdeaId} templateType={this.state.ideaItem.DBProjectType} />
                            </>
                            :
                            null
                        }



                        {/* DB SPOC Fields starts here */}
                        {
                          this.state.ideaItem.TransformationLeverName === "Data" && this.state.ideasDB.IsSustenance === "Yes"
                          && this.state.ideaItem.DBSPocOHR && this.state.displayDBFields
                            ?
                            <>
                              <div className="" style={{ paddingRight: "30px", paddingTop: "30px", borderTop: "1px solid lightgrey", marginTop: "30px" }}>
                                <p className="u-align-left u-text u-text-2">DB questionnaire</p>
                                <div className="new_fields_blocks">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Is the MUC ID tagged correctly ?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input
                                              value={this.state.ideasDB.IsMUCTag}
                                              placeholder='Provide value'
                                              disabled
                                              className="form-input disabled_input"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Is the DB theme captured correctly?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              disabled
                                              value={this.state.ideasDB.IsDBTheme}
                                              className="form-input disabled_input"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="new_fields_blocks">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Is the Tool used captured correctly ?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              className="form-input disabled_input"
                                              disabled
                                              value={this.state.ideasDB.IsToolCorrect}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Are the model details captured appropriately?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              className="form-input disabled_input"
                                              disabled
                                              value={this.state.ideasDB.IsModelCorrect}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="new_fields_blocks">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Are the model deployment details captured appropriately?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              className="form-input disabled_input"
                                              disabled
                                              value={this.state.ideasDB.IsModelDeploy}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Do the provided signoffs meet value delivery guidelines?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              className="form-input disabled_input"
                                              disabled
                                              value={this.state.ideasDB.IsSignOff}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="new_fields_blocks">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Are the monetization details captured appropriately?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              className="form-input disabled_input"
                                              disabled={true}
                                              value={this.state.ideasDB.IsMonetization}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Are sustenance tracking details OK ?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              className="form-input disabled_input"
                                              disabled
                                              value={this.state.ideasDB.IsSPocSustenance}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="new_fields_blocks">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Is there any other issue you would like to report ?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              className="form-input disabled_input"
                                              disabled
                                              value={this.state.ideasDB.IsIssue}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5">
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-7">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Is there any exception applied on the project approval ?</label>
                                          </div>
                                          <div className="col-md-5">
                                            <input placeholder='Provide value'
                                              className="form-input disabled_input"
                                              disabled
                                              value={this.state.ideasDB.IsException}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="" style={{ paddingBottom: "40px", marginBottom: "15px" }} />
                              </div >
                            </>
                            :
                            null
                        }
                        {/* DB SPOC Fields ends here */}


                        {/* ..... Sustenance metric performance Survey starts here..... */}
                        {
                          this.state.ideaItem.ApprovalStatus && this.state.ideaItem.ApprovalStatus === COMPLETED_STATUS &&
                            this.state.ideaItem.TransformationLeverName === "Data" &&
                            this.state.ideasDB.IsSustenance && this.state.ideasDB.IsSustenance === "Yes" 
                            && this.state.IdeasSSSurvey && this.state.displayDBFields
                            ?
                            <>
                              <div className="" style={{ marginTop: "40px", }}>
                                {/* <p className="u-align-left u-text u-text-2">Sustenance metric performance Survey Details</p> */}
                                <div className="">
                                  {/* <div className="row">
                                    <div className="col-md-4">
                                      <div className="submit_page_new_fields">
                                        <div className="row">
                                          <div className="col-md-5">
                                            <label
                                              className="form-attribute"
                                              style={{
                                                verticalAlign: "bottom",
                                                marginBottom: -5,
                                              }}
                                            >
                                              DB Project Type
                                            </label>
                                          </div>
                                          <div className="col-md-7">
                                            <input value={this.state.ideaItem.DBProjectType} type="text" placeholder="Provide Value" className="form-input  disabled_input"
                                              disabled />
                                          </div>
                                        </div>
                                      </div>


                                      {
                                        this.state.dbProjectType
                                          ?
                                          <>
                                            <div className="submit_page_new_fields" style={{}}>
                                              <div className="row">
                                                <div className="col-md-5">
                                                  <span className="form-attribute">
                                                    Template Type
                                                  </span>
                                                </div>
                                                <div className="col-md-7">
                                                  <div className="ai_first_doc_link">
                                                    <input className="template_type_input" disabled value={this.state.ideaItem.DBProjectType} ></input>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                          :
                                          null
                                      }

                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-5">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              COO</label>
                                          </div>
                                          <div className="col-md-7">
                                            <input value={this.state.ideaItem.COO} type="text" placeholder="Provide Value" className="form-input  disabled_input"
                                              disabled />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="submit_page_new_fields">
                                        <div className="row">
                                          <div className="col-md-5">
                                            <label
                                              className="form-attribute"
                                              style={{
                                                verticalAlign: "bottom",
                                                marginBottom: -5,
                                              }}
                                            >
                                              MUC ID
                                            </label>
                                          </div>
                                          <div className="col-md-7">
                                            <input type="text"
                                              disabled
                                              placeholder="Provide Value"
                                              value={this.state.ideaItem.MUCIDTitle}
                                              className="form-input disabled_input" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-5">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Shown to GOL</label>
                                          </div>
                                          <div className="col-md-7">
                                            <input value={this.state.ideasDB.IsSharedGOL} placeholder="Provide value" className="form-input disabled_input" disabled />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-5">
                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                              Shown to Client</label>
                                          </div>
                                          <div className="col-md-7">
                                            <input value={this.state.ideasDB.IsSharedClient} placeholder="Please choose" disabled className="form-input disabled_input" />
                                          </div>
                                        </div>
                                      </div>
                                      {
                                        this.state.ideasDB.IsSharedClient && this.state.ideasDB.IsSharedClient === "Yes"
                                          ?
                                          <>
                                            <div className="">
                                              <div className="row">
                                                <div className="col-md-12">
                                                  <div className="submit_page_new_fields">
                                                    <div className="row d_flex_align_items_center">
                                                      <div className="col-md-5">
                                                        <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                          Client Testimony</label>
                                                      </div>
                                                      <div className="col-md-7">
                                                        <button style={{ backgroundColor: 'transparent', textAlign: "left", marginTop: "15px", color: '#337ab7', padding: 0 }} onClick={(e) => this.downloadClientTestimony()}>{this.state.ideaItem.CTestimonyFileName}</button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-5">

                                                </div>
                                              </div>
                                            </div>


                                          </>
                                          :
                                          null
                                      }
                                      <div className="submit_page_new_fields">
                                        <div className="" style={{ textAlign: "left", margin: "15px 0px" }}>
                                          <div className="row d_flex_align_items_center">
                                            <div className="col-md-5">
                                              <label
                                                className="form-attribute"
                                                style={{
                                                  verticalAlign: "bottom",
                                                  marginBottom: -5,
                                                }}
                                              >
                                                DB Tool Used
                                              </label>
                                            </div>
                                            <div className="col-md-7">
                                              <input type="text"
                                                disabled
                                                placeholder="Provide Value"
                                                value={this.state.ideasDB.DBTool}
                                                className="form-input disabled_input" />

                                            </div>
                                          </div>
                                        </div>
                                      </div>



                                    </div>
                                    <div className="col-md-4">

                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-5">
                                            <label className="form-attribute" style={{
                                              verticalAlign: 'bottom', marginBottom: -5
                                            }}>
                                              Process Owner OHR</label>
                                          </div>
                                          <div className="col-md-7">
                                            <input type="text"
                                              disabled
                                              placeholder="Provide Value"
                                              value={this.state.ideaItem.ProcessOwnerOHR}
                                              className="form-input form-attribute" />

                                          </div>
                                        </div>
                                      </div>
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-5">
                                            <label className="form-attribute" style={{
                                              verticalAlign: 'bottom', marginBottom: -5
                                            }}>
                                              Process Owner Name</label>
                                          </div>
                                          <div className="col-md-7">
                                            <input value={this.state.ideaItem.ProcessOwner} type="text" placeholder="Provide Value" className="form-input disabled_input"
                                              disabled />
                                          </div>
                                        </div>
                                      </div>

                                      {
                                        this.state.ideasDB.IsSharedGOL && this.state.ideasDB.IsSharedGOL === "Yes"
                                          ?
                                          <>
                                            <div className="submit_page_new_fields">
                                              <div className="row d_flex_align_items_center">
                                                <div className="col-md-5">
                                                  <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                    GOL OHR</label>
                                                </div>
                                                <div className="col-md-7">
                                                  <input value={this.state.ideasDB.GOLOHR} type="text" placeholder="Provide value" className="form-input form-attribute"
                                                    disabled />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="submit_page_new_fields">
                                              <div className="row d_flex_align_items_center">
                                                <div className="col-md-5">
                                                  <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                    GOL Name</label>
                                                </div>
                                                <div className="col-md-7">
                                                  <input type="text" placeholder="Provide value" disabled value={this.state.ideasDB.GOL}
                                                    className="form-input disabled_input" />
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                          :
                                          null
                                      }
                                      {
                                        this.state.ideasDB.IsSharedClient && this.state.ideasDB.IsSharedClient === "Yes"
                                          ?
                                          <>
                                            <div className="submit_page_new_fields">
                                              <div className="row d_flex_align_items_center">
                                                <div className="col-md-5">
                                                  <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                    Client Role</label>
                                                </div>
                                                <div className="col-md-7">
                                                  <input value={this.state.ideasDB.ClientRole} type="text" placeholder="Provide value" className="form-input disabled_input"
                                                    disabled />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="submit_page_new_fields">
                                              <div className="row d_flex_align_items_center">
                                                <div className="col-md-5">
                                                  <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                    Client Name</label>
                                                </div>
                                                <div className="col-md-7">
                                                  <input value={this.state.ideasDB.ClientName} type="text" disabled className="form-input  disabled_input" />
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                          :
                                          null
                                      }
                                      {
                                        this.state.dbToolUsed && this.state.dbToolUsed.label === "Yes" ?
                                          <>
                                            <div className="submit_page_new_fields">
                                              <div className="row">
                                                <div className="col-md-5">
                                                  <label
                                                    className="form-attribute"
                                                    style={{
                                                      verticalAlign: "bottom",
                                                      marginBottom: -5,
                                                    }}
                                                  >
                                                    Other DB Tool Used
                                                  </label>
                                                </div>
                                                <div className="col-md-7">
                                                  <input
                                                    type="text"
                                                    value={this.state.ideaItem.OtherDBTool}
                                                    placeholder="Provide value"
                                                    className="form-input disabled_input"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                          :
                                          null
                                      }
                                    </div>
                                    <div className="col-md-4 " style={{ paddingRight: "30px" }}>
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-8">
                                            <label
                                              className="form-attribute"
                                              style={{
                                                verticalAlign: "bottom",
                                                marginBottom: -5,
                                              }}
                                            >
                                              Is Sustenance tracking applicable ? </label>
                                          </div>
                                          <div className="col-md-4">
                                            <input
                                              value={this.state.ideasDB.IsSustenance}
                                              placeholder="Please provide value"
                                              disabled
                                              className="form-input disabled_input"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="submit_page_new_fields">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-8">
                                            <label
                                              className="form-attribute"
                                              style={{
                                                verticalAlign: "bottom",
                                                marginBottom: "5px",
                                              }}
                                            >
                                              How is the model operationalized ?
                                            </label>
                                          </div>
                                          <div className="col-md-12">
                                            <input
                                              value={this.state.ideasDB.ModelSummary}
                                              placeholder="Please provide value"
                                              disabled
                                              className="form-input disabled_input"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {this.state.ideasDB.IsSustenance && this.state.ideasDB.IsSustenance === "Yes" ? (
                                        <>
                                          <div className="submit_page_new_fields">
                                            <div className="row d_flex_align_items_center">
                                              <div className="col-md-8">
                                                <label
                                                  className="form-attribute"
                                                  style={{
                                                    verticalAlign: "bottom",
                                                    marginBottom: -5,
                                                  }}
                                                >

                                                  Sustenance tracking frequency
                                                </label>
                                              </div>
                                              <div className="col-md-4">
                                                <input
                                                  value={this.state.ideasDB.SustenanceFreq}
                                                  placeholder="Please provide value"
                                                  disabled
                                                  className="form-input disabled_input"
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="submit_page_new_fields">
                                            <div className="row d_flex_align_items_center">
                                              <div className="col-md-12">
                                                <label
                                                  className="form-attribute"
                                                  style={{
                                                    verticalAlign: "bottom",
                                                    marginBottom: "5px",
                                                  }}
                                                >
                                                  Sustenance metric
                                                </label>
                                              </div>
                                              <div className="col-md-12">
                                                <textarea
                                                  className="form-input"
                                                  disabled
                                                  value={this.state.ideasDB.SustenanceMetric}
                                                  placeholder="Please provide sustenance metrics"
                                                  style={{
                                                    width: "100%",
                                                    border: "1px solid lightgrey",

                                                  }}
                                                  rows={3}
                                                />
                                              </div>
                                            </div>
                                          </div>


                                        </>
                                      ) : null}

                                    </div>
                                  </div> */}


                                  {
                                    this.state.IdeasSSSurvey.map((item, index) => {
                                      console.log(item)
                                      if (this.state.ideasDB.SustenanceFreq) {
                                        if (this.state.ideasDB.SustenanceFreq === "Daily") {
                                          item.ExpectedRuns = 30
                                        }
                                        if (this.state.ideasDB.SustenanceFreq === "Weekly") {
                                          item.ExpectedRuns = 4;
                                        }
                                        if (this.state.ideasDB.SustenanceFreq === "Fortnightly") {
                                          item.ExpectedRuns = 2;
                                        }
                                        if (this.state.ideasDB.SustenanceFreq === "Monthly") {
                                          item.ExpectedRuns = 1;
                                        }
                                      }

                                      return (
                                        <>
                                          <div className="new_fields_blocks" style={{ paddingRight: "15px", marginTop: "20px", borderTop: "1px solid lightgrey", paddingTop: '36px' }}>
                                            <p className="u-align-left u-text u-text-2">Sustenance metric performance Survey {index + 1}</p>
                                            <div className="row">
                                              <div className="col-md-4">
                                                <div className="submit_page_new_fields">
                                                  <div className="row d_flex_align_items_center">
                                                    <div className="col-md-7">
                                                      <label className="form-attribute" style={{ verticalAlign: "bottom", marginBottom: -5, }}>
                                                        Sustenance Tracking Month
                                                      </label>
                                                    </div>
                                                    <div className="col-md-5">
                                                      <input
                                                        value={item.SOrd+1}
                                                        placeholder="Provide value"
                                                        className="form-input disabled_input"
                                                        disabled
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-md-4">
                                                <div className="submit_page_new_fields">
                                                  <div className="row d_flex_align_items_center">
                                                    <div className="col-md-7">
                                                      <label className="form-attribute" style={{ verticalAlign: "bottom", marginBottom: -5, }}>
                                                        <span style={{ color: "red" }}>* </span>
                                                        Is the model still Active ?
                                                      </label>
                                                    </div>
                                                    <div className="col-md-5">
                                                      <input
                                                        type="text"
                                                        min="0"
                                                        value={item.IsModelActive}
                                                        placeholder="Provide value"
                                                        className="form-input"
                                                        disabled
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-md-4">
                                                {
                                                  item.IsModelActive &&
                                                    item.IsModelActive === "Yes"
                                                    ?
                                                    <>
                                                      <div className="submit_page_new_fields">
                                                        <div className="row d_flex_align_items_center">
                                                          <div className="col-md-7">
                                                            <label className="form-attribute" style={{ verticalAlign: "bottom", marginBottom: -5, }}>
                                                              Sustenance metric value recorded for this month
                                                            </label>
                                                          </div>
                                                          <div className="col-md-5">
                                                            <input
                                                              type="number"
                                                              min="0"
                                                              value={item.MetricsValue}
                                                              placeholder="Provide value"
                                                              className=" form-input hide_numeric_input_arrows"
                                                              disabled
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                    :
                                                    null
                                                }
                                              </div>
                                            </div>
                                          </div>

                                          <div className="new_fields_blocks" style={{ borderTop: "1px solid lightgrey", paddingRight: "15px", paddingTop: "30px", marginTop: "30px" }}>
                                            <p className='u-align-left u-text u-text-2'>Model Run Compliance {index + 1}</p>
                                            <div className="">
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <div className="submit_page_new_fields">
                                                    <div className="row d_flex_align_items_center">
                                                      <div className="col-md-7">
                                                        <label className="form-attribute" style={{ verticalAlign: "bottom", marginBottom: -5, }}>Expected runs in a month</label>
                                                      </div>
                                                      <div className="col-md-5">
                                                        <input
                                                          value={item.ExpectedRuns}
                                                          placeholder="Provide value"
                                                          disabled
                                                          className="form-input disabled_input"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>


                                                  {
                                                    item.IsModelActive && item.IsModelActive === "Yes"
                                                      ?
                                                      <>
                                                        <div className="submit_page_new_fields">
                                                          <div className="row d_flex_align_items_center">
                                                            <div className="col-md-7">
                                                              <label className="form-attribute" style={{ verticalAlign: "bottom", marginBottom: -5, }}>
                                                                Remarks</label>
                                                            </div>
                                                            <div className="col-md-12" style={{ marginTop: "10px" }}>
                                                              <textarea
                                                                value={item.Remarks}
                                                                placeholder="Provide value"
                                                                className="form-input text_area_full_width"
                                                                disabled
                                                                rows={4}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>


                                                      </>
                                                      :
                                                      null
                                                  }
                                                </div>
                                                <div className="col-md-4">
                                                  {
                                                    item.IsModelActive && item.IsModelActive === "Yes"
                                                      ?
                                                      <>
                                                        <div className="submit_page_new_fields">
                                                          <div className="row d_flex_align_items_center">
                                                            <div className="col-md-7">
                                                              <label className="form-attribute" style={{ verticalAlign: "bottom", marginBottom: -5, }}>
                                                                Actual Runs in this month
                                                              </label>
                                                            </div>
                                                            <div className="col-md-5">
                                                              <input
                                                                value={item.ActualRuns}
                                                                type="number"
                                                                min="0"
                                                                disabled
                                                                placeholder="Provide value"
                                                                className="form-input"

                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                      :
                                                      null
                                                  }

                                                  <div className="submit_page_new_fields">
                                                    {
                                                      item.IsModelActive && item.IsModelActive === "Yes"
                                                        ?
                                                        <>
                                                          <div className="row d_flex_align_items_center">
                                                            <div className="col-md-7">
                                                              <label className="form-attribute" style={{ verticalAlign: "bottom", marginBottom: -5, }}>
                                                                <span style={{ color: "red" }}>* </span>
                                                                What is the model accuracy ?
                                                              </label>
                                                            </div>
                                                            <div className="col-md-5">

                                                              <input
                                                                type="text"
                                                                value={item.ModelAccuracy}
                                                                placeholder="Provide value"
                                                                className="form-input disabled_input"
                                                                disabled

                                                              />

                                                            </div>
                                                          </div>
                                                        </>
                                                        :
                                                        null
                                                    }

                                                  </div>
                                                </div>
                                                <div className="col-md-4">
                                                  {
                                                    item.IsModelActive && item.IsModelActive === "Yes"
                                                      ?
                                                      <>
                                                        <div className="submit_page_new_fields">
                                                          <div className="row d_flex_align_items_center">
                                                            <div className="col-md-7">
                                                              <label className="form-attribute" style={{ verticalAlign: "bottom", marginBottom: -5, }}>
                                                                Compliance
                                                              </label>
                                                            </div>
                                                            <div className="col-md-5">
                                                              <input
                                                                value={parseFloat((item.ActualRuns/item.MetricsValue)*100).toFixed(2)}
                                                                placeholder="Provide value"
                                                                disabled
                                                                className="form-input disabled_input"
                                                                type="number"
                                                                min="0"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                      :
                                                      null
                                                  }

                                                </div>

                                              </div>
                                            </div>
                                          </div>


                                          <div className="" style={{ paddingTom: "20px", paddingRight: "30px", marginBottom: "40px" }}>
                                            <div className="row">
                                              <div className="col-md-4">
                                                {

                                                  item.IsModelActive && item.IsModelActive === "Yes"
                                                    ?
                                                    <>
                                                      <div className="submit_page_new_fields">
                                                        <div className="row d_flex_align_items_center">
                                                          <div className="col-md-7">

                                                            <label className="form-attribute" style={{ verticalAlign: "bottom", marginBottom: -5, }}>
                                                              Has the model predictions been accurate consistently ?
                                                            </label>
                                                          </div>
                                                          <div className="col-md-5">

                                                            <input
                                                              type="text"
                                                              value={item.IsModelPrediction}
                                                              placeholder="Provide value"
                                                              className="form-input disabled_input"
                                                              disabled

                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                    :
                                                    null
                                                }
                                              </div>
                                              <div className="col-md-4">
                                                {
                                                  item.IsModelActive && item.IsModelActive === "Yes"
                                                    ?
                                                    <>
                                                      <div className="submit_page_new_fields">
                                                        <div className="row d_flex_align_items_center">
                                                          <div className="col-md-7">
                                                            <label className="form-attribute" style={{ verticalAlign: "bottom", marginBottom: -5, }}>
                                                              Has the model been re-trained post project completion ?
                                                            </label>
                                                          </div>
                                                          <div className="col-md-5">
                                                            <input
                                                              type="text"
                                                              value={item.IsRetrained}
                                                              placeholder="Provide value"
                                                              className="form-input disabled_input"
                                                              disabled

                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                    :
                                                    null
                                                }
                                              </div>
                                              <div className="col-md-4"></div>
                                            </div>

                                          </div>
                                        </>

                                      )
                                    })
                                  }




                                </div>

                              </div>

                            </>
                            :
                            null

                        }
                        {/* ..... Sustenance metric performance Survey ends here..... */}












                      </>
                      :
                      null
                  }





                  {/* LDT Approval starts here */}
                  {
                    this.state.ideaItem.ApprovalStatus === COMPLETED_STATUS 
                      ?
                      <>
                        <div className="" style={{ borderTop: "1px solid lightgrey", paddingRight: "30px", paddingTop: "10px" }}>
                          <div className="">
                            <div className="row">
                              <div className="col-md-10" style={{ paddingBottom: "0px" }}>
                                <h3 className="idea_form_header">Client QBR</h3>
                              </div>
                              <div className="col-md-2" >
                                <div className="project_lead_comment_log_button" style={{}}>
                                  <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.toggleLDTApprovalCommentLogModal()}>LDT Lead Log</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="" style={{ paddingBottom: "30px" }}>
                            <h5 style={{ textAlign: "Left", color: "red" }}>This section is to be filled by  LDT Leads and only if this project was demonstrated during a Client Quarterly Business Review.</h5>
                          </div>

                          <div className="" style={{ marginBottom: "20px" }}>
                            <div className="row">
                              <div className="col-md-5">
                                <div className="">
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-8 text-left">
                                      <div className="">
                                        <span className="form-attribute">
                                          <span style={{ color: "red" }}>*&nbsp;</span>
                                      {/* Project demonstrated in Client QBR */}
                                      Shown in Client QBR
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-md-4 text-left">
                                      <Select
                                        options={YESORNO}
                                        onChange={(e) => this.handleIsQBRChange(e)}
                                        value={this.state.IsQBR}
                                        style={
                                          this.state.selectStyle.IsQBR
                                        }
                                      ></Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2"></div>
                              <div className="col-md-5">
                                {
                                  this.state.IsQBR && this.state.IsQBR.label === "Yes"
                                    ?
                                    <>
                                      <div className="">
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-5 text-left">
                                            <div className="">
                                              <span className="form-attribute">
                                                <span style={{ color: "red" }}>*&nbsp;</span>
                                                Date of QBR
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-md-7">
                                            <input
                                              className="textarea-css form-attribute" type="date"
                                              value={this.state.QBRDate}
                                              style={
                                                this.state.selectStyle.QBRDate
                                              }
                                              onChange={(e) => this.handleQBRDateChange(e.target.value)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                    :
                                    null
                                }

                              </div>
                            </div>
                          </div>
                          {
                            this.state.IsQBR && this.state.IsQBR.label === "Yes"
                              ?
                              <div className="" style={{ marginBottom: "20px" }}>
                                <div className="row">
                                  <div className="col-md-5">
                                    <div className="">
                                      <div className="row d_flex_align_items_center">
                                        <div className="col-md-5 text-left">
                                          <span className="form-attribute">
                                            LDT Lead Name
                                          </span>
                                        </div>
                                        <div className="col-md-7 text-left">
                                          <input type="text" disabled value={this.state.LDTApproverName} className="textarea-css form-attribute"
                                            style={
                                              this.state.selectStyle.LDTApproverName
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2"></div>
                                  <div className="col-md-5">
                                    <div className="">
                                      <div className="row d_flex_align_items_center">
                                        <div className="col-md-5 text-left">
                                          <span className="form-attribute">
                                            LDT Lead OHR
                                          </span>
                                        </div>
                                        <div className="col-md-7 text-left">
                                          <input
                                            type="number"
                                            disabled
                                            value={this.state.LDTApproverOHR}
                                            style={
                                              this.state.selectStyle.LDTApproverOHR
                                            }
                                            className="textarea-css form-attribute" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              :
                              null
                          }
                          <div className="" style={{ marginBottom: "20px" }}>
                            <div className="row">
                              <div className="col-md-5">
                                {
                                  this.state.IsQBR && this.state.IsQBR.label === "No"
                                  ?
                                <div className="">
                                  <div className="row d_flex_align_items_center">
                                    <div className="col-md-12 text-left" style={{ marginBottom: "5px" }}>
                                      <div className="">
                                        <span className="form-attribute">
                                          LDT Lead Comment
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <textarea
                                        style={{ width: "100%", resize: "none" }}
                                        type="text"
                                        className="textarea-css form-attribute"
                                        value={this.state.ldtApprovalComment}
                                        onChange={(e) => this.handleLDTApprovalCommentChange(e.target.value)}
                                        rows={4}
                                      />
                                    </div>
                                  </div>
                                </div>
                                :
                                null
                              }
                              </div>
                                
                              <div className="col-md-2"></div>
                              <div className="col-md-5">
                                <div className="">
                                  {
                                    this.state.IsQBR && this.state.IsQBR.label === "Yes"
                                      ?
                                      <>
                                        <div className="row d_flex_align_items_center">
                                          <div className="col-md-5 text-left">
                                            <span className="form-attribute">
                                              Last Submitted on
                                            </span>
                                          </div>
                                          <div className="col-md-7 text-left">
                                            <input disabled value={this.formatDateLatest(this.state.QBRDate)} style={{ color: "#122539" }} type="date" className="form-input disabled_input" />
                                          </div>
                                        </div>
                                      </>
                                      :
                                      null
                                  }

                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="" style={{ marginBottom: "20px" }}>
                            <div className="row text-left">
                              <div className="col-md-5">

                              </div>
                              <div className="col-md-2"></div>
                              <div className="col-md-5">

                                {
                                  this.state.displayLDTCommentLog
                                    ?
                                    <>
                                      {
                                        this.state.LDTLogs
                                          ?
                                          <>
                                            <div className="custom_modal ldt_lead_approval_modal">
                                              <div className="modal_body">
                                                <div className="row">
                                                  <div className="col-md-8">
                                                    <h3 className="modal_title">
                                                      LDT Lead Approval Log
                                                    </h3>
                                                  </div>
                                                  <div className="col-md-4">
                                                    <button
                                                      type="button"
                                                      className="close text-right"
                                                      onClick={(e) =>
                                                        this.setState({
                                                          displayLDTCommentLog: false,
                                                        })
                                                      }
                                                    >
                                                      &times;
                                                    </button>
                                                  </div>
                                                </div>

                                                <div className="qc_responsive_comment_log">
                                                  <div className="table table-responsive table-bordered">
                                                    <thead className="text-center">
                                                      <tr>

                                                        <th className="qcHeaderbg qcVDHeader LDTHeaderFullWidthOne">
                                                          <label
                                                            style={{
                                                              fontWeight: 100,
                                                              verticalAlign: "bottom",
                                                            }}
                                                          >
                                                            Project demonstrated in Client QBR
                                                          </label>
                                                        </th>
                                                        <th className="qcHeaderbg qcVDHeader LDTHeaderFullWidthTwo">
                                                          <label
                                                            style={{
                                                              fontWeight: 100,
                                                              verticalAlign: "bottom",
                                                            }}
                                                          >
                                                            QBR Submitted Date
                                                          </label>
                                                        </th>
                                                        <th className="qcHeaderbg qcVDHeader">
                                                          <label
                                                            style={{
                                                              fontWeight: 100,
                                                              verticalAlign: "bottom",
                                                            }}
                                                          >
                                                            Name
                                                          </label>
                                                        </th>

                                                        <th className="qcHeaderbg qcVDHeader">
                                                          <label
                                                            style={{
                                                              fontWeight: 100,
                                                              verticalAlign: "bottom",
                                                            }}
                                                          >
                                                            Role
                                                          </label>
                                                        </th>
                                                        <th className="qcHeaderbg qcVDHeader">
                                                          <label
                                                            style={{
                                                              fontWeight: 100,
                                                              verticalAlign: "bottom",
                                                            }}
                                                          >
                                                            OHR
                                                          </label>
                                                        </th>
                                                        <th
                                                          className="qcHeaderbg"
                                                          style={{
                                                            minWidth: "400px",
                                                          }}
                                                        >
                                                          <label
                                                            style={{
                                                              fontWeight: 100,
                                                              verticalAlign: "bottom",
                                                            }}
                                                          >
                                                            Comment
                                                          </label>
                                                        </th>
                                                        <th className="qcHeaderbg qcVDHeader">
                                                          <label
                                                            style={{
                                                              fontWeight: 100,
                                                              verticalAlign: "bottom",
                                                            }}
                                                          >
                                                            Date & Time
                                                          </label>
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {this.state.LDTLogs.map(
                                                        (item, index) => {
                                                          return (
                                                            <tr key={index}>

                                                              <td>
                                                                <label
                                                                  style={{
                                                                    lineheight: 2,
                                                                    verticalAlign:
                                                                      "bottom",
                                                                  }}
                                                                >
                                                                  <label
                                                                    style={{
                                                                      lineheight: 2,
                                                                      verticalAlign: "bottom",
                                                                    }}
                                                                  >
                                                                    {item.IsQBR
                                                                      ? item.IsQBR
                                                                      : ""}
                                                                  </label>
                                                                </label>
                                                              </td>

                                                              <td>
                                                                <label
                                                                  style={{
                                                                    lineheight: 2,
                                                                    verticalAlign:
                                                                      "bottom",
                                                                  }}
                                                                >
                                                                  <label
                                                                    style={{
                                                                      lineheight: 2,
                                                                      verticalAlign: "bottom",
                                                                    }}
                                                                  >
                                                                    {item.IsQBR === "Yes" ? this.formatDateString(new Date(item.QBRDate)) : ""}

                                                                  </label>
                                                                </label>
                                                              </td>
                                                              <td>
                                                                <label
                                                                  style={{
                                                                    lineheight: 2,
                                                                    verticalAlign:
                                                                      "bottom",
                                                                  }}
                                                                >
                                                                  {item.UserName}
                                                                </label>
                                                              </td>
                                                              <td>
                                                                <label
                                                                  style={{
                                                                    lineheight: 2,
                                                                    verticalAlign:
                                                                      "bottom",
                                                                  }}
                                                                >
                                                                  LDT Lead
                                                                </label>
                                                              </td>

                                                              <td>
                                                                <label
                                                                  style={{
                                                                    lineheight: 2,
                                                                    verticalAlign:
                                                                      "bottom",
                                                                  }}
                                                                >
                                                                  {item.UserOHR}
                                                                </label>
                                                              </td>

                                                              <td>
                                                                <label
                                                                  style={{
                                                                    lineheight: 2,
                                                                    verticalAlign:
                                                                      "bottom",
                                                                  }}
                                                                >
                                                                  <label
                                                                    style={{
                                                                      lineheight: 2,
                                                                      verticalAlign: "bottom",
                                                                    }}
                                                                  >
                                                                    {item.LDTComments
                                                                      ? item.LDTComments
                                                                      : "-"}
                                                                  </label>
                                                                </label>
                                                              </td>


                                                              <td>
                                                                <label
                                                                  style={{
                                                                    lineheight: 2,
                                                                    verticalAlign:
                                                                      "bottom",
                                                                  }}
                                                                >
                                                                  {this.formatDateStringWithTime(
                                                                    new Date(
                                                                      item.CreatedDate
                                                                    )
                                                                  )}
                                                                </label>
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )}
                                                    </tbody>
                                                  </div>
                                                </div>

                                              </div>
                                            </div>
                                          </>
                                          :
                                          null

                                      }


                                    </>
                                    :
                                    null
                                }

                              </div>
                            </div>
                          </div>
                          <div className="">
                            <div className="btn-group" style={{ marginTop: 10, marginBottom: "20px" }}>
                              <button
                                className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                                onClick={this.saveLDTApprovalDetails}
                              >
                                Submit LDT Lead Approval
                              </button>
                              <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.cancelLDTApproval}>Cancel</button>

                            </div>
                          </div>

                        </div>

                      </>
                      :
                      null
                  }
                  {/* LDT Approval ends here */}



                  {
                    this.state.ideaItem.VDType && this.state.ideaItem.VDType === 'No Value Delivery'
                    && (this.state.ideaItem.ApprovalStatus === "Awaiting Analytics Review")
                    ?
                    <></>
                    :
                    <div className="" style={{borderTop: "1px solid lightgray",margin: "20px 0px",}}></div>
                  }
                
                  <div className="btn-group" style={{ marginTop: 10 }}>
                    <button
                      href={redirectURL}
                      className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"
                      onClick={this.saveEvent}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ViewIdeaPage;
