import React, { Component } from 'react'
import { SUBMIT_IDEA_URL, AWAITING_AI_BOT_REVIEW,GET_METADATA, OTHER_REGIONS, GET_IDEA_URL, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, USER_DATA, ACCESS_TOKEN,AI_DOC_LINK,AWAITING_E360_SPOC_APPROVAL } from '../assets/constants/constants';
import Toast from 'light-toast';
import Select from 'react-select';
import { selectValidation as selectStyle, textBoxValidation } from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";
import SpeechToTextSubmitPage from './SpeechToTextSubmitPage'

export class SubmitPage extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);
    constructor(props) {
        super(props);
        console.log(props)
        if (this.sessionData === null) {
            Toast.loading("This component cannot be accessed directly. Redirecting to application home.", 3000, () => {
            });
            window.location = "/login";
            return;
        }
        this.state = {
            accountList: [],
            regionList: [],
            subRegionList: [],
            countryList: [],
            verticalList: [],
            subVerticalList: [],
            blackbeltList: [],
            mbbList: [],
            ldaList: [],
            campaignList: [],
            dataBridgeProjectThemeList: [],
            subCampaignList: [],
            serviceLineList: [],
            subServiceLineList: [],
            accountId: 111,
            accountName: "",
            blackBeltUserId: 111,
            blackBeltUserName: "",
            blackBeltUserEmail: "",
            mbbName: "",
            mbbOHR: "",
            lda: "",
            ldaOHR: "", 
            campaignTypeId: 111,
            campaignTypeText: "",
            dataBridgeCampaign: false,
            xPollinationCampaign: false,
            ideaValidation: false,
            dataBridgeProjectTheme: "",
            subCampaignName: "",
            createdDate: "",
            creatorEmail: "",
            creatorName: "",
            creatorOHR: "",
            ownerEmail: this.sessionData.profile.oHRId + "@genpact.com",
            ownerName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
            ownerOHR: this.sessionData.profile.oHRId,
            regionId: 111,
            regionManual: "",
            regionManualId: 111,
            regionText: "",
            regionSelectedOption: null,
            subRegionSelectedOption: null,
            countrySelectedOption: null,
            verticalSelectedOption: null,
            subVerticalSelectedOption: null,
            blackBeltSelectedOption: null,
            mbbSelectedOption: null,
            databridgeProjectThemeOption: null,
            subCampaignOption: null,
            verticalId: 111,
            verticalManual: "",
            verticalManualId: 111,
            subVerticalManual: "",
            requestForIdea: false,
            subRegion: "",
            country: "",
            ideaArray: [{
                problem: "",
                solution: "",
                ideaName: "",
                serviceLineId: 111,
                serviceLineText: "",
                subServiceLineId: 0,
                subServiceLineText: "",
                subServiceLineSelectedOption: null,
                newOrReplicatedIdea: false,
                replicationProjectId: "",
                replicationIDValidated: false,
                removeIdea: false
            }],
            selectStyle: {
                ideaArray: [{
                    problem: { maxWidth: '100%', border: '1px solid lightgrey' },
                    solution: { maxWidth: '100%', border: '1px solid lightgrey' },
                    ideaName: { maxWidth: '100%', border: '1px solid transparent' },
                    serviceLineId: 111,
                    serviceLineText: "",
                    newOrReplicatedIdea: false,
                    replicationProjectId: { maxWidth: '100%', border: '1px solid lightgrey' },
                    removeIdea: false
                }]
            },
            Role:"Idea Owner",
            errorIcon:false,
            speechModal:false,
            transcriptValue:"",
            modalIndex:"",
            previousTextForSpeech:"",
            ideaArrayIndex:"",
            chatGPTTheme:false,
            chatGptOptions:[
                {
                    value:0,
                    label:"Predict The Next Best Action"
                },
                {
                    value:1,
                    label:"Automated Narrative Building and Translation"
                },
                {
                    value:2,
                    label:"Data Extraction and Leakage Prevention"
                },
                {
                    value:3,
                    label:"Coding Effort Reduction"
                },
            ],
            chatGptSelectedOption:"",
            // AI First states starts here
            DisplayAIFirst:false,

            // Unit and Segment
            Unit:"",
            Segment:"",
            unitsAndSegmentsList:[]



        }
        this.isExceedlimit= false;
    }

    componentDidMount() {
        Toast.loading("Fetching data. Please wait...", () => { });
        const param = {
            EntityType: "",
            EntityName: ""
        }
        const requestOptions = {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json",
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("meta data ", data)
                Toast.hide();
                this.setState({
                    accountList: data ? data.Accounts.sort((a, b) => { return a.ACCOUNT > b.ACCOUNT ? 1 : -1; }) : [],
                    campaignList: data ? data.Campaigns : [],
                    serviceLineList: data ? data.ServiceLines : [],
                    subServiceLineList: data ? data.ServiceLines : [],
                    countryList: data ? data.Countries.sort((a, b) => { return a.COUNTRY > b.COUNTRY ? 1 : -1;}) : [],
                    dataBridgeProjectThemeList: data? data.DataBridgeThemes : [],
                    subCampaignList: data ? data.SubCampaigns : []
                },()=>{

                })
                // console.log("campgain list ",this.state.campaignList)
            })
            .catch(error => {
                console.log("error =>",error)

                Toast.hide();
                Toast.fail('Error occured while getting Idea Metadata. Please try again!', 3000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }

    renderAccountOptions = () => {
        let options = [];
        let accountName = "";
        this.state.accountList.map((item) => {
            accountName = item.ACCOUNT.toString().toLowerCase();
            //[CR-UAT & CR-POD : 12 Apr 22 : Shweta] Account list filter out starts with unidentified, unmapped, genpact
            if ( !( accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact") ) ){
                options.push({
                    value: item.ACCOUNT_L2,
                    label: item.ACCOUNT
                })
            }
            return true;
        });
        return options;
    }

    renderVerticalOptions = () => {
        let options = [];
        const uniqueVerticals = [...new Map(this.state.verticalList.map(item => [item["VERTICAL"], item])).values()]
        uniqueVerticals.map((item) => {
            //[CR-UAT & CR-POD : 12 Apr 22 : Shweta] Vertical shoud filter out "TS"
            if (!(item.VERTICAL.toString().toLowerCase() === "ts") ) {
                options.push({ value: item.VERTICAL, label: item.VERTICAL });
            }
            return true;
        });
        return options;
    }

    renderSubVerticalOptions = () => {
        let options = [];
        this.state.subVerticalList = [];
        this.state.verticalList.map((item) => {
            if (this.state.verticalSelectedOption.label === item.VERTICAL) {
                //[CR-UAT & CR-POD : 12 Apr 22 : Shweta] Industry Vertical shoud filter out "Corporate"
                //[CR-UAT & CR-POD : 29 Apr 22 : Shweta] Industry Vertical shoud filter out "Corporate" except when support is ANYWHERE in account
                // if (item.SUB_VERTICAL.toString().toLowerCase() !== "corporate" || this.state.accountName.toLowerCase().includes("support") )   {
                //     options.push({ value: item.SUB_VERTICAL, label: item.SUB_VERTICAL });
                //     this.state.subVerticalList.push(item.SUB_VERTICAL);
                // }
                options.push({ value: item.SUB_VERTICAL, label: item.SUB_VERTICAL });
                this.state.subVerticalList.push(item.SUB_VERTICAL);
            }
            return true;
        });
        return options;
    }

    renderBlackBeltOptions = () => {
        // let options = [];
        // const filteredData = this.state.blackbeltList && this.state.blackbeltList.filter((item) => {
        //     const userRole = JSON.parse(item.pg_Role).pg_role;
        //     if (this.state.ownerOHR !== item.pg_ohr){
        //         return userRole === "BB" || userRole === "QA";
        //     }
        // });
        // options = filteredData.reduce((acc, item) => {
        //     if (!acc.some(option => option.value === item.pg_ohr)) {
        //         acc.push({ value: item.pg_ohr, label: item.pg_username });
        //     }
        //     return acc;
        // }, []);


        let options = [];
        this.state.blackbeltList.map((item) => {
            const userRole = JSON.parse(item.pg_Role).pg_role;
            if (userRole === "BB" || userRole === "QA") {
                if (this.state.ownerOHR !== item.pg_ohr){
                    options.push({ value: item.pg_ohr, label: item.pg_username });
                }
            }
            return true;
        });
        return options;
    }

    renderMBBOptions = () => {
        let options = [];
        this.state.mbbList.map((item) => {
            const userRole = JSON.parse(item.pg_Role).pg_role;
            if (userRole === "MBB") {
                if (this.state.ownerOHR !== item.pg_ohr) {
                    options.push({ value: item.pg_ohr, label: item.pg_username });
                }
            }
            return true;
        });
        return options;
    }

    renderLDAOptions = () => {
        let options = [];
        this.state.ldaList.map((item) => {
            const userRole = JSON.parse(item.pg_Role).pg_role;
            if (userRole === "LDA") {
                if(this.state.creatorOHR){
                    if (this.state.creatorOHR !== item.pg_ohr) {
                        options.push({ value: item.pg_ohr, label: item.pg_username });
                    }
                }
                else if (this.state.ownerOHR !== item.pg_ohr) {
                    options.push({ value: item.pg_ohr, label: item.pg_username });
                }
            }
            return true;
        });
        return options;
    }
    
    renderRegionOptions = () => {
        let options = [];
        if(this.state.regionList.length > 0){
            this.state.regionList.map((item) => {
                options.push({ value: item.Region, label: item.Region });
                return true;
            });
        }
        return options;
    }
    renderSubRegionOptions = () => {
        let options = [];
        if (this.state.subRegionList.length > 0) {
            this.state.subRegionList.map((item) => {
                options.push({ value: item.Region, label: item.Region });
                return true;
            });
        }
        return options;
    }
    
    renderCountryOptions = () => {
        let options = [];
        this.state.countryList.map((item) => {
            options.push({ value: item.COUNTRY, label: item.COUNTRY });
            return true;
        });
        return options;
    }    
    
    renderCampaignOptions = () => {
        let options = [];
        this.state.campaignList.map((item) => {
            options.push({ value: item.CampaignId, label: item.CampaignName });
            return true;
        });
        return options;
    }

    renderDataBridgeProjectThemeOptions = () => {
        let options = [];
        this.state.dataBridgeProjectThemeList.map((item) => {
            options.push({ value: item.ThemeId, label: item.DBProjectThemeName });
            return true;
        });
        return options;
    }    

    renderSubCampaignOptions = () => {
        let options = [];
        this.state.subCampaignList.map((item) => {
            options.push({ value: item.SubCampaignId, label: item.SubCampaignName });
            return true;
        });
        return options;
    }      
    
    renderServiceLineOptions = () => {
        let options = [];
        const uniqueServiceLineList = [...new Map(this.state.serviceLineList.map(item =>
            [item.ServiceLineName, item])).values()];
        uniqueServiceLineList.map((item) => {
            options.push({ value: item.ServiceLineId, label: item.ServiceLineName });
            return true;
        });
        return options;
    }

    renderSubServiceLineOptions = (index) => {
        let ideaItem = [...this.state.ideaArray];
        let options = [];
        this.state.subServiceLineList.map((item) => {
            if (ideaItem[index].serviceLineText === item.ServiceLineName){
                options.push({ value: item.SubServiceLineId, label: item.SubServiceLineName });
                return true;
            }
            return true;
        });
        return options;
    }
        
    handleOwnerOHRChange = (event) => {
        this.setState({
            ownerOHR: event.target.value
        });
    }

    handleOwnerNameChange = (event) => {
        this.setState({
            ownerName: event.target.value
        });
    }

    handleCreatorOHRChange = (event) => {
        var numbers = /^[0-9]+$/;
        // this.setState({errorIcon:true})
        if(event.target.value === this.state.ownerOHR){
            Toast.fail('You cannot select logged-in user as requester. The Idea is already owned by you. ', 3000, () => {
                return;
            });
            this.setState({creatorName:""})
        }else{
            if(event.target.value.match(numbers) && event.target.value.length !== 0){
                if(event.target.value.length === 9){
                    this.setState({
                        creatorOHR: event.target.value.trim(),
                        // errorIcon:false
                    });
                    this.handleSubmitterOHRChange()
                }
                else{
                    this.setState({creatorName:""})
                }
            }
        }
        
    }


    handleCreatorOHRChangeOnCut = (event) => {
        if (event.target.value.length === 9) {
            this.handleCreatorOHRChange()
        }else{this.setState({ creatorName: "" })}
        
    }
    // handleCreatorOHRChange = (event) => {
    //     this.setState({
    //         creatorOHR: event.target.value.trim()
    //     });
    // }


    handleCreatorNameChange = (event) => {
        this.setState({
            creatorName: event.target.value
        });
    }

    handleAccountNameChange = (e) => {
        // this.state.verticalList = [];
        // this.state.verticalSelectedOption = null;
        // this.state.subVerticalSelectedOption = null;
        this.setState({
            verticalList: [],
            verticalSelectedOption: null,
            subVerticalSelectedOption: null,
            regionSelectedOption: null,
            subRegionSelectedOption: null,
            countrySelectedOption: null,
            blackBeltSelectedOption: null,
            mbbSelectedOption: null,
            ldaSelectedOption: null,
            //accountId: e.value,
            accountName: e.label,
            holdingAccount: e.value
        }, () => {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.accountName = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            });
            //console.log("Vertical:" + this.state.verticalSelectedOption.label);
            this.fetchVerticalOptions();
            this.fetchCountryOptions();
            //this.fetchBlackBeltOptions();
        }, () => {
            this.renderVerticalOptions();
            this.renderCountryOptions();
        });
    }

    handleBlackBeltNameChange = (e) => {
        //let testOHR = (parseInt(this.sessionData.profile.oHRId) === 850051497) ? 850051496 : 850051497;
        if (parseInt(e.value) === parseInt(this.state.creatorOHR)) {
            Toast.fail('Mentor user cannot be the same as Idea Owner/Requester. Please select a different Mentor user.', 3000, () => {
                return;
            });
            return;
        }
        let styleStateObj = this.state.selectStyle;
        styleStateObj.blackBeltUserName = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            blackBeltUserId: e.value,
            //TEST_MODE ? testOHR : e.value,
            blackBeltUserName: e.label,
            blackBeltUserEmail: e.value + "@genpact.com",
            //TEST_MODE ? testOHR + "@genpact.com" : e.value + "@genpact.com",
            blackBeltSelectedOption: {
                value: e.value,
                //TEST_MODE ? testOHR : e.value,
                label: e.label
            }
        });
    }

    handleMBBChange = (e) => {
        if (parseInt(e.value) === parseInt(this.state.creatorOHR)) {
            Toast.fail('MBB user cannot be the same as Idea Owner/Requester. Please select a different Mentor user.', 3000, () => {
                return;
            });
            return;
        }        
        let styleStateObj = this.state.selectStyle;
        styleStateObj.mbbUserName = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            mbbName: e.label,
            mbbOHR: e.value,
            mbbSelectedOption: {
                value: e.value,
                label: e.label
            }
        });
    }
 
    handleRegionChange = (e) => {
        if (e.label === OTHER_REGIONS && (!this.state.verticalManual || this.state.verticalManual === "")) {
            this.setState({
                regionSelectedOption: null
            });
            Toast.fail('Please select Account & Vertical to fetch BlackBelt users for Other Regions.', 3000, () => {
                return;
            });
        } else {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.regionManual = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj,
                // regionId: e.value,
                regionManual: e.label,
                regionText: e.label,
               //regionManualId: e.value,
                regionSelectedOption: {
                    value: e.value,
                    label: e.label
                },
                blackBeltSelectedOption: null,
                mbbSelectedOption: null,
            }, () => {
                //this.fetchVerticalOptions();
                this.fetchBlackBeltOptions(e);
            });
        }
    }
    
    handleSubRegionChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.subRegion = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            subRegion: e.label,
            subRegionSelectedOption: {
                value: e.value,
                label: e.label
            }
        });
    }    

    handleCountryChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.country = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            country: e.value,
            regionSelectedOption: null,
            subRegionSelectedOption: null,
            blackBeltSelectedOption: null,
            mbbSelectedOption: null,
            countrySelectedOption: {
                value: e.value,
                label: e.label
            }
        }, () => {
            //this.fetchVerticalOptions();
            this.fetchRegionOptions(e);
        });
    } 

    handleIdeaUnique = (ideaName) => {
        Toast.loading("Checking Idea details... Please wait...", () => { });
        const param = {
            IdeaName: ideaName,
            SaveType: "Check"
        }
        console.log(JSON.stringify(param))
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.accessToken
            },
            body: JSON.stringify(param)
        };
        fetch(SUBMIT_IDEA_URL, requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    ideaValidation: true
                });
                if(data.message){
                    Toast.success('Idea name confirmed as unique!', 3000, () => {
                        console.log(JSON.stringify(data));
                    });
                }
                else{
                    Toast.fail('Idea name already exists. Please change Idea Name to make it unique!', 3000, () => {
                        console.log(JSON.stringify(data));
                    });
                }
               
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting idea details. Please try again!.', 5000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }

    handleSubmitterOHRChange = () => {
        if (parseInt(this.state.ownerOHR) === parseInt(this.state.creatorOHR)) {
            Toast.fail('You cannot select logged-in user as requester. The Idea is already owned by you. ', 3000, () => {
                return;
            });
            this.setState({creatorName:""})
        }else{
            this.setState({
                creatorEmail: this.state.creatorOHR + "@genpact.com"
            }, () => {
                this.fetchSubmitterOHRDetails();
            });
        }
    }
   
    //error warning icon funcitonaltiy
    warningError=()=>{
        //window.alert("OHR ID should be exactly of 9 digits")
    }

    fetchSubmitterOHRDetails = () => {
        // if(this.state.creatorOHR){
        //     if(this.state.creatorOHR === this.state.ldaOHR){
        //         this.setState({
        //             ldaSelectedOption:null
        //         })
        //     }
        // }
        Toast.loading("Fetching Submitter Details. Please wait...", () => { });
        const param = {
            EntityType: "OHR",
            EntityName: this.state.creatorOHR
        }
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.accessToken
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                let requestByName = data.DNAUsers ? (data.DNAUsers.Table1 ? (data.DNAUsers.Table1.length > 0 ? data.DNAUsers.Table1[0].pg_username : "") : "" ) : "";
                if(requestByName === ""){
                    requestByName = data.PowerAppUsers ? (data.PowerAppUsers.length > 0 ? data.PowerAppUsers[0].pg_username : "") : "";
                }
                Toast.hide();
                if (requestByName === "") {
                    Toast.fail("We could not find Submitter details based on the entered OHR. Please check and reenter.", 5000, () => { });
                    this.setState({
                        creatorName: ""
                    });
                }else{
                    this.setState({
                        creatorName: requestByName
                    });
                }
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting Submitter details. Please try again!', 5000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }


    fetchBlackBeltOptions = () => {
        Toast.loading("Fetching Blackbelts. Please wait...", () => { });
        let entityType = "BB";
        let entityName = this.state.regionText;
        if (this.state.regionText === OTHER_REGIONS) {
            entityType = "BBOTHER";
            //DNA table has vertical with space around dash (' - ') whereas User Role table has NO space around dash ('-')
            entityName = this.state.verticalManual.replace(" - ", "-");
        }
        const param = {
            EntityType: entityType,
            EntityName: entityName
        }
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' + this.accessToken
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                Toast.hide();
                this.setState({
                    blackbeltList: data.DataverseUserRoles !== undefined ? data.DataverseUserRoles : [],
                    mbbList: data.DataverseUserRoles !== undefined ? data.DataverseUserRoles : [],
                    ldaList: data.DataverseUserRoles !== undefined ? data.DataverseUserRoles : [],
                });
                console.log("black belt list => ",this.state.blackbeltList)
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting BlackBelt details. Please try again!', 15000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }

    fetchRegionOptions = () => {
        Toast.loading("Fetching Regions. Please wait...", () => { });
        let entityType = "REGION";
        let entityName = this.state.country+"::"+this.state.accountName;
        // if (this.state.regionText === OTHER_REGIONS) {
        //     entityType = "BBOTHER";
        //     //DNA table has vertical with space around dash (' - ') whereas User Role table has NO space around dash ('-')
        //     entityName = this.state.verticalManual.replace(" - ", "-");
        // }
        const param = {
            EntityType: entityType,
            EntityName: entityName
        }
        const requestOptions = {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json",
                Authorization:  "Bearer " + this.accessToken,
              
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                Toast.hide();
                let styleStateObj = this.state.selectStyle;
                styleStateObj.regionManual = selectStyle(true);
                styleStateObj.subRegionManual = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                    regionList: data.Region.Table1 !== undefined ? data.Region.Table1 : [],
                    regionSelectedOption: {
                        label: data.Region.Table1[0].Region,
                        value: data.Region.Table1[0].Region
                    },
                    subRegionList: data.Region.Table1 !== undefined ? data.Region.Table1 : [],
                    subRegionSelectedOption: {
                        label: data.Region.Table1[0].SubRegion,
                        value: data.Region.Table1[0].SubRegion
                    },
                    regionText: data.Region.Table1[0].Region,
                    regionManual: data.Region.Table1[0].Region,
                    subRegion: data.Region.Table1[0].SubRegion
                });
                console.log("Region List:" + JSON.stringify(this.state.regionList));
            })
            .then(() => {
                //this.fetchVerticalOptions();
                this.fetchBlackBeltOptions();
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting Region details. Please try again!', 15000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }    
    
    fetchVerticalOptions = () => {
        Toast.loading("Fetching Verticals. Please wait...", () => { });
        const param = {
            EntityType: "Vertical",
            EntityName: this.state.accountName
        }
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.accessToken
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log("fetch verticals => response", data)
            Toast.hide();
            let styleStateObj = this.state.selectStyle;
            styleStateObj.verticalManual = selectStyle(true);
            styleStateObj.subVerticalManual = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj,
                verticalList: data.Table1 !== undefined ? data.Table1 : [],
                verticalSelectedOption: {
                    label: data.Table1[0].VERTICAL,
                    value: data.Table1[0].VERTICAL
                },
                verticalManual: data.Table1[0].VERTICAL,
                // subVerticalSelectedOption: {
                //     label: data.Table1[0].SUB_VERTICAL,
                //     value: data.Table1[0].SUB_VERTICAL
                // },
                subVerticalManual: data.Table1[0].SUB_VERTICAL,
                unitsAndSegmentsList:data.Table2 !== undefined ? data.Table2 : [],
            });
        }).then(() =>{
            if(this.state.unitsAndSegmentsList){
                let localUnit="";
                let localSegment = "";
                this.state.unitsAndSegmentsList.map((item)=>{
                    if (!(item.VERTICAL.toString().toLowerCase() === "ts") ) {
                        if(this.state.verticalSelectedOption && this.state.verticalSelectedOption.label === item.VERTICAL){
                            if(item){
                                localUnit = item.UNIT;
                                localSegment = item.SEGMENT;
                            }
                        }
                    }
                })
                this.setState({Unit:localUnit, Segment:localSegment },()=>{})
            }

            this.renderVerticalOptions();
            this.renderSubVerticalOptions();
            this.setState({
                // subVerticalSelectedOption: {
                //     value: (this.state.subVerticalList.length > 0) ? this.state.subVerticalList[0] : "",
                //     label: (this.state.subVerticalList.length > 0) ? this.state.subVerticalList[0] : ""
                // },
                subVerticalManual: (this.state.subVerticalList.length > 0) ? this.state.subVerticalList[0] : ""
            });                
        })
        .catch(error => {
            Toast.hide();
            Toast.fail('Error occured while getting Vertical details. Please try again!', 10000, () => {
                console.log(JSON.stringify(error));
            });
        });
    }

    fetchCountryOptions = () => {
        // Toast.loading("Fetching Account specific Countries. Please wait...", () => { });
        const param = {
            EntityType: "COUNTRY",
            EntityName: this.state.accountName
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' + this.accessToken
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                // Toast.hide();
                let styleStateObj = this.state.selectStyle;
                styleStateObj.country = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                    countryList: data.Countries.Table1 !== undefined ? data.Countries.Table1 : [],
                    // countrySelectedOption: {
                    //     label: data.Countries.Table1[0].Country,
                    //     value: data.Countries.Table1[0].Country
                    // },
                    // country: data.Countries.Table1[0].Country,
                });
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting Account specific Country details. Please try again!', 10000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }

    fetchReplicationIdeaDetails = (IdeaId, index) => {
        let replicaID=IdeaId;
        console.log("length ideaID",IdeaId.length);
        if(IdeaId.length > 7){
            replicaID=IdeaId.slice(2,11)
        }
        Toast.loading("Fetching Replication Idea Details. Please wait...", () => { });
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.accessToken
            }
        };
        return fetch(`${GET_IDEA_URL}IdeaId=`+replicaID, requestOptions)
        // return fetch(`${GET_IDEA_URL}IdeaId=-1&IdeaIDAlpha=`+IdeaId, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.ResultSets.Table1 === undefined) {
                    Toast.fail("We could not find the Idea based on the entered replication Idea ID. Please check and reenter.", 5000, () => { });
                    let ideaItem = [...this.state.ideaArray];
                    ideaItem[index].replicationProjectId = "";
                    ideaItem[index].replicationIDValidated = false;
                    this.setState({
                        ideaArray: ideaItem
                    });
                    return false;
                }else{
                    Toast.hide();
                    let ideaItem = [...this.state.ideaArray];
                    ideaItem[index].replicationIDValidated = true;
                    this.setState({
                        ideaArray: ideaItem
                    });
                    Toast.success('Replication Idea ID validated successfully!', 3000, () => {});
                    return true;
                }
                })
            .catch(error => {
                Toast.hide();
                Toast.fail("We could not find the Idea based on the entered replication Idea ID. Please check and reenter.", 5000, () => { });
                let ideaItem = [...this.state.ideaArray];
                ideaItem[index].replicationProjectId = "";
                ideaItem[index].replicationIDValidated = false;
                this.setState({
                    ideaArray: ideaItem
                });
                return false;
            });        
    }    

    handleCampaignNameChange = (e) => {
        if(e.label === "Gen AI"){
            this.setState({chatGPTTheme:true},()=>{
                console.log(this.state.chatGPTTheme)
            })
        }else{
            this.setState({chatGPTTheme:false},()=>{})
        }
        if(e.label === "AI First"){
            this.setState({DisplayAIFirst:true},()=>{})
        }else{
            this.setState({DisplayAIFirst:false},()=>{})
        }
        
        let styleStateObj = this.state.selectStyle;
        styleStateObj.campaignTypeText = selectStyle(true);
        
        let flagStatus = (e.label === DATA_BRIDGE_CAMPAIGN) ? true : false;
        let xPollinationflagStatus = (e.label === X_POLLINATION_CAMPAIGN) ? true : false;
       
        this.setState({
            dataBridgeCampaign: flagStatus,
            xPollinationCampaign: xPollinationflagStatus,
            dataBridgeProjectTheme: "",
            subCampaignName: "",
            selectStyle: styleStateObj,
            //campaignTypeId: e.value,//e.target.value,
            campaignTypeText: e.label,
            chatGptSelectedOption:[]
        },()=>{});
        this.state.campaignList.map((item,index)=>{
            if(e.value===item.CampaignId){
                this.setState({campaignInsight:item.CampaignDescr},()=>{
                    //console.log("campaign insights => ",this.state.campaignInsight)
                })
            }
        })
    }

    handleSubCampaignChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.subCampaignName = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            subCampaignName: e.label,
        });
    }    
        
    handleVerticalChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.verticalManual = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            subVerticalSelectedOption: null,
            //verticalId: e.value,
            verticalManual: e.label,
            //verticalManualId: e.value,
            verticalSelectedOption: {
                value: e.value,
                label: e.label
            }
        }, () => {

            if(this.state.unitsAndSegmentsList){
                let localUnit="";
                let localSegment = "";
                this.state.unitsAndSegmentsList.map((item)=>{
                    if (!(item.VERTICAL.toString().toLowerCase() === "ts") ) {
                        if(e && e.label === item.VERTICAL){
                            if(item){
                                localUnit = item.UNIT;
                                localSegment = item.SEGMENT;
                            }
                        }
                    }
                })
                this.setState({Unit:localUnit, Segment:localSegment },()=>{})
            }

            this.setState({ subVerticalList: [] });
            this.renderSubVerticalOptions();
            this.setState({
                subVerticalManual: (this.state.subVerticalList.length > 0) ? this.state.subVerticalList[0] : "",
                subVerticalSelectedOption: {
                    value: (this.state.subVerticalList.length > 0) ? this.state.subVerticalList[0] : "",
                    label: (this.state.subVerticalList.length > 0) ? this.state.subVerticalList[0] : ""
                }
            });
            this.fetchBlackBeltOptions();
        });
    }

    handleSubVerticalChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.subVerticalManual = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            subVerticalManual: e.label,
            subVerticalSelectedOption: {
                value: e.value,
                label: e.label
            }
        });
    }

    handleServiceLineChange = (e, index) => {
        let ideaItem = [...this.state.ideaArray];
        ideaItem[index].serviceLineId = e.value;
        ideaItem[index].serviceLineText = e.label;
        ideaItem[index].subServiceLineId = "";
        ideaItem[index].subServiceLineText = "";
        ideaItem[index].subServiceLineSelectedOption = null;
        
        let styleStateObj = this.state.selectStyle;
        styleStateObj.ideaArray[index].serviceLineText = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            ideaArray: ideaItem     
        });
        this.renderSubServiceLineOptions(index);
    }

    handleSubServiceLineChange = (e, index) => {
        let ideaItem = [...this.state.ideaArray];
        ideaItem[index].subServiceLineId = e.value;
        ideaItem[index].subServiceLineText = e.label;
        ideaItem[index].subServiceLineSelectedOption = {
            label: e.label,
            value: e.value
        };
        let styleStateObj = this.state.selectStyle;
        styleStateObj.ideaArray[index].subServiceLineText = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            ideaArray: ideaItem
        });
    }
        
    handleProblemChange = (text, index) => {
        let ideaItem = [...this.state.ideaArray];
        ideaItem[index].problem = text;
        let styleStateObj = this.state.selectStyle;
        styleStateObj.ideaArray[index].problem = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            ideaArray: ideaItem
        });
    }
    handleTranscriptProblemChange=(text,index,modalId)=>{
        let ideaItem = [...this.state.ideaArray];
        ideaItem[index].problem = text.trim();
        this.setState({
            ideaArray: ideaItem            
        });
    }
    handleTranscriptSolutionChange=(text,index,modalId)=>{
        let ideaItem = [...this.state.ideaArray];
        ideaItem[index].solution = text.trim();
        this.setState({
            ideaArray: ideaItem            
        });
        
        
    }

    handleIdeaNameChange = (text, index) => {
        let ideaItem = [...this.state.ideaArray];
        ideaItem[index].ideaName = text;
        let styleStateObj = this.state.selectStyle;
        styleStateObj.ideaArray[index].ideaName = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            ideaArray: ideaItem
        });
    }

    handleSolutionChange = (text, index) => {
        let ideaItem = [...this.state.ideaArray];
        ideaItem[index].solution = text;
        let styleStateObj = this.state.selectStyle;
        styleStateObj.ideaArray[index].solution = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            ideaArray: ideaItem
        });
        console.log(this.state.ideaArray)
    }


    showHideDiv = (index) => {
        let ideaItem = [...this.state.ideaArray];
        ideaItem[index].newOrReplicatedIdea = !ideaItem[index].newOrReplicatedIdea;
        if (!ideaItem[index].newOrReplicatedIdea) {
            ideaItem[index].replicationProjectId = "";
        }
        this.setState({
            ideaArray: ideaItem
        });
    }

    showHideSubmitter = () => {
        let flagStatus = !this.state.requestForIdea;
        this.setState({
            requestForIdea: flagStatus,
            creatorOHR: "",
            creatorEmail: "",
            creatorName: ""
        });
        // if(this.state.creatorOHR){
        //     if(this.state.ownerOHR === this.state.ldaOHR){
        //         this.setState({
        //             ldaSelectedOption:null
        //         })
        //     }
        // }
    }
    
    handleReplicationIdChange = (text, index) => {
        let ideaItem = [...this.state.ideaArray];
        ideaItem[index].replicationProjectId = text;
        ideaItem[index].replicationIDValidated = false;
        this.setState({
            ideaArray: ideaItem
        });   
    }

    validateReplicationId = (index) => {
        if (this.state.ideaArray[index].replicationProjectId !== ""){
            if (!this.fetchReplicationIdeaDetails(this.state.ideaArray[index].replicationProjectId, index)){
                let ideaItem = [...this.state.ideaArray];
                ideaItem[index].replicationProjectId = "";
                ideaItem[index].replicationIDValidated = false;
                this.setState({
                    ideaArray: ideaItem
                });
                return false;
            }else{
                let ideaItem = [...this.state.ideaArray];
                ideaItem[index].replicationIDValidated = true;
                let styleStateObj = this.state.selectStyle;
                styleStateObj.ideaArray[index].replicationProjectId = textBoxValidation(true);
                this.setState({
                    ideaArray: ideaItem,
                    selectStyle: styleStateObj
                });
                return true;
            }
        }
    }

    cancelEvent = () => {
        if (window.confirm("You will lose all entered data on this page if you click on cancel. In case you want to remove additional idea, click on 'Remove Idea' button.")) {
            this.props.history.push("/home");
        }
    }

    sameAccountWarning = (event) => {
        if (this.state.ideaArray.length >= 5){
            Toast.info("Only 5 Ideas can be submitted at a time.", 3000, () => { });
            return;
        }
        if (window.confirm("The next idea you are adding will be associated with the same Account. If you want to create an Idea for a different Account, please Submit this idea and create a new one. Do you want to continue for same Account?")) {
            let ideaArray = this.state.ideaArray;
            let selectStyleArray = this.state.selectStyle;
            ideaArray.push({
                problem: "",
                solution: "",
                ideaName: "",
                serviceLineId: 0,
                serviceLineText: "",
                subServiceLineText: "",
                newOrReplicatedIdea: false,
                replicationProjectId: "",
                replicationIDValidated: false,
                removeIdea: true
            });
            selectStyleArray.ideaArray.push({
                problem: { maxWidth: '100%', border: '1px solid lightgrey' },
                solution: { maxWidth: '100%', border: '1px solid lightgrey' },
                ideaName: { maxWidth: '100%', border: '1px solid transparent' },
                serviceLineId: 0,
                serviceLineText: "",
                subServiceLineText: "",
                newOrReplicatedIdea: false,
                replicationProjectId: { maxWidth: '100%', border: '1px solid lightgrey' },
                removeIdea: true
            });
            this.setState({
                ideaArray,
                selectStyle: selectStyleArray
            })
        }
    }

    removeIdea = (index) => {
        let filteredIdeaArray = this.state.ideaArray.filter((value, filterindex, arr) => {
            return filterindex !== index;
        });

        this.setState({
            ideaArray: filteredIdeaArray
        })
    }

    validate = () => {
        let isValid = true;
        this.isExceedlimit=false;
        let styleStateObj = this.state.selectStyle;

        if(this.state.blackBeltUserId === this.state.creatorOHR){
            Toast.fail("Owner of idea and approver can not be the same. Please change and resubmit.", 5000);
            isValid = false;
        }
        
        if (this.state.accountName === "") {
            isValid = false;
            styleStateObj.accountName = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.accountName = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }

        if (this.state.verticalManual === "") {
            isValid = false;
            styleStateObj.verticalManual = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.verticalManual = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }

        if (this.state.subVerticalManual === "") {
            isValid = false;
            styleStateObj.subVerticalManual = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.subVerticalManual = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }

        if (this.state.regionManual === "") {
            isValid = false;
            styleStateObj.regionManual = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.regionManual = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }

        if (this.state.subRegion === "") {
            isValid = false;
            styleStateObj.subRegionManual = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.subRegionManual = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        
        if (this.state.country === "") {
            isValid = false;
            styleStateObj.country = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.country = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }
                
        if (this.state.blackBeltUserName === "") {
            isValid = false;
            styleStateObj.blackBeltUserName = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.blackBeltUserName = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        
        // if (this.state.mbbOHR === "") {
        //     isValid = false;
        //     styleStateObj.mbbUserName = selectStyle(false);
        //     this.setState({
        //         selectStyle: styleStateObj
        //     })
        // }
        // else {
        //     styleStateObj.mbbUserName = selectStyle(true);
        //     this.setState({
        //         selectStyle: styleStateObj
        //     })
        // }
        
        // if (this.state.ldaOHR === "") {
        //     isValid = false;
        //     styleStateObj.ldaName = selectStyle(false);
        //     this.setState({
        //         selectStyle: styleStateObj
        //     })
        // }
        // else {
        //     styleStateObj.ldaName = selectStyle(true);
        //     this.setState({
        //         selectStyle: styleStateObj
        //     })
        // }             

        if (this.state.campaignTypeText === "") {
            isValid = false;
            styleStateObj.campaignTypeText = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.campaignTypeText = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            });
            if (this.state.campaignTypeText === X_POLLINATION_CAMPAIGN) {
                if (this.state.subCampaignName === "") {
                    isValid = false;
                    styleStateObj.subCampaignName = selectStyle(false);
                    this.setState({
                        selectStyle: styleStateObj
                    });
                } else {
                    styleStateObj.subCampaignName = selectStyle(true);
                    this.setState({
                        selectStyle: styleStateObj
                    });
                }
            }
        }
        if(this.state.chatGPTTheme === true){
            console.log("chatgpt =>",this.state.chatGptSelectedOption)

            if(this.state.chatGptSelectedOption === "" || 
                this.state.chatGptSelectedOption === null || 
                this.state.chatGptSelectedOption === undefined || 
                this.state.chatGptSelectedOption.length === 0 || 
                !this.state.chatGptSelectedOption
            ){
                isValid = false;
                styleStateObj.chatgptOption = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj
                });
            }
            else {
                styleStateObj.chatgptOption = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj
                });
            }
        }
        

        this.state.ideaArray.map((item, index) => {
            if (item.serviceLineText === "") {
                isValid = false;
                styleStateObj.ideaArray[index].serviceLineText = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else {
                styleStateObj.ideaArray[index].serviceLineText = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj
                })
            }

            if (item.subServiceLineText === "") {
                isValid = false;
                styleStateObj.ideaArray[index].subServiceLineText = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else {
                styleStateObj.ideaArray[index].subServiceLineText = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
                        
            if (item.problem === "") {
                isValid = false;
                styleStateObj.ideaArray[index].problem = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else {
                if (item.problem.length > 2000){
                    Toast.fail("Problem statement cannot be more than 2000 characters, please change and resubmit.", 3000);
                    isValid = false;
                    this.isExceedlimit=true;
                    styleStateObj.ideaArray[index].problem = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }else{
                    styleStateObj.ideaArray[index].problem = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
            }

            if (item.solution === "") {
                isValid = false;
                styleStateObj.ideaArray[index].solution = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else {
                if (item.solution.length > 2000) {
                    Toast.fail("Solution statement cannot be more than 2000 characters, please change and resubmit.", 3000);
                    isValid = false;
                    this.isExceedlimit=true;
                    styleStateObj.ideaArray[index].solution = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                } else {
                    styleStateObj.ideaArray[index].solution = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
            }

            // if (item.ideaName === "" || this.state.ideaValidation === false) {
            if (item.ideaName === "") {
                isValid = false;
                styleStateObj.ideaArray[index].ideaName = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else {
                if(item.ideaName.length > 200){
                    Toast.fail("The Idea name cannot exceed 200 characters", 3000);
                    isValid = false;
                    this.isExceedlimit=true;
                    styleStateObj.ideaArray[index].ideaName = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
                else{
                    styleStateObj.ideaArray[index].ideaName = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
            }

            if (item.newOrReplicatedIdea && item.replicationProjectId === "") {
                isValid = false;
                styleStateObj.ideaArray[index].replicationProjectId = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }else {
                if (item.newOrReplicatedIdea && item.replicationProjectId !== "" && !item.replicationIDValidated){
                    // if(!this.validateReplicationId(index)){
                        isValid = false;
                    //     styleStateObj.ideaArray[index].replicationProjectId = textBoxValidation(false);
                    //     this.setState({
                    //         selectStyle: styleStateObj
                    //     })
                    // } else {
                        styleStateObj.ideaArray[index].replicationProjectId = textBoxValidation(false);
                        this.setState({
                            selectStyle: styleStateObj
                        })
                    // }
                }else{
                    styleStateObj.ideaArray[index].replicationProjectId = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
            }

            if (this.state.requestForIdea && (this.state.creatorOHR === "" || this.state.creatorName === "")) {
                isValid = false;
                styleStateObj.requestFor = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else {
                styleStateObj.requestFor = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj
                })
            }

            return isValid
        })

        return isValid;
    }

    timeout = (delay)=> {
        return new Promise( res => setTimeout(res, delay) );
    }

    finalStatus=()=>{
        let status="Pending";
        if(this.state.DisplayAIFirst){
            status = AWAITING_AI_BOT_REVIEW
        }
        if(this.state.campaignTypeText === "AI First - Enterprise 360"){
            status = AWAITING_E360_SPOC_APPROVAL
        }
        return status;
    }

    submitIdea = async () => {
        if (this.validate()) {
            if (window.confirm("Are you sure you want to submit the Idea?")) {
                Toast.loading("Submitting Idea...", () => { });
                let currentPromise =null;
                let ideaSubmitPromise =[];
                for (let index = 0; index < this.state.ideaArray.length; index++) {
                    const ideaItem = this.state.ideaArray[index];
                    currentPromise = new Promise((resolve, reject) => {
                        let param = {
                            AccountId: this.state.accountId,
                            AccountManual: this.state.accountName,
                            HoldingAccount: this.state.holdingAccount,
                            BlackBeltUserId: Number(this.state.blackBeltUserId),
                            BlackBeltUserName: this.state.blackBeltUserName,
                            BlackBeltUserEmail: this.state.blackBeltUserEmail,
                            CampaignTypeId: Number(this.state.campaignTypeId),
                            CampaignName: this.state.campaignTypeText, 
                            CreatedDate: new Date().toISOString(),
                            CreatorEmail: (this.state.creatorOHR !== "") ? this.state.creatorOHR + "@genpact.com" : "",
                            CreatorName: this.state.creatorName,
                            CreatorOHR: this.state.creatorOHR,
                            IdeaName: ideaItem.ideaName,
                            NewOrReplicatedIdea: ideaItem.newOrReplicatedIdea,
                            IsNewReplication: ideaItem.newOrReplicatedIdea,
                            IsReplicationVerified: false,
                            OwnerEmail: this.state.ownerOHR + "@genpact.com",
                            OwnerName: this.state.ownerName,
                            OwnerOHR: this.state.ownerOHR,
                            Problem: ideaItem.problem,
                            RegionId: Number(this.state.regionId),
                            RegionManual: this.state.regionManual,
                            RegionManualId: Number(this.state.regionManualId),
                            SubRegion: this.state.subRegion,
                            Country: this.state.country,
                            ReplicationProjectId: ideaItem.replicationProjectId,
                            ServiceLineId: 111,
                            ServiceLineName: ideaItem.serviceLineText,
                            SubServiceLine: ideaItem.subServiceLineText,
                            Solution: ideaItem.solution,
                            VerticalId: Number(this.state.verticalId),
                            VerticalManual: this.state.verticalManual,
                            VerticalManualId: Number(this.state.verticalManualId),
                            SubVerticalManual: this.state.subVerticalManual,
                            LDAOHR: Number(this.state.ldaOHR),
                            LDA: this.state.lda,
                            DataBridgeTheme: this.state.dataBridgeProjectTheme,
                            SubCampaignName: this.state.subCampaignName,
                            PrevWorkflowStatus: this.finalStatus(),
                            PrevApprovalStatus:'NA',
                            SaveType: "New",
                            WorkflowStatus : this.finalStatus(),
                            Unit: this.state.Unit ? this.state.Unit :"",
                            Segment: this.state.Segment ? this.state.Segment : "",
                            Role: "Idea Owner",
                            isItIdeaOrGenAI:"Idea"
                        }
                        console.log(param)
                        // console.log(JSON.stringify(param))
                        let finalJsonData = Checksum(param);
                        let str1 = finalJsonData.slice(0, 15);
                        let str2 = finalJsonData.slice(15, 30);
                        let str3 = finalJsonData.slice(30, 40);
                        let str4 = finalJsonData.slice(-10);
                        let finalData = str3+str1+str4+str2;
                        const requestOptions = {
                            method: 'POST',
                            headers: { 
                                'Content-Type': 'application/json',
                                'Authorization':  'Bearer ' + this.accessToken
                            },
                            body: JSON.stringify(param)
                        };
                        fetch(`${SUBMIT_IDEA_URL}Checksum=${finalData}`, requestOptions)
                            .then(response => response.json())
                            .then(data => {
                                resolve(data);
                            })
                            .catch(error => {
                                reject(error);
                            });
                    });
                    ideaSubmitPromise.push(currentPromise);
                    await this.timeout(500);
                }                
                let submitSuccessFlag = false;
                const submitResult = await Promise.all(ideaSubmitPromise);
                if (submitResult && submitResult.length > 0) {
                    Toast.hide();
                    //
                    const showMessagePromise = submitResult.map((item) => {
                        return new Promise((resolve, reject) => {
                            if (item.error !== undefined) {
                                Toast.fail('New Idea creation failed: '+item.error, 5000, () => {
                                    console.log(JSON.stringify(submitResult));
                                    resolve()
                                });
                            }
                            else {
                                Toast.success('New Idea created successfully!', 3000, () => {
                                    console.log(JSON.stringify(submitResult));
                                    submitSuccessFlag = true;
                                    resolve()
                                });
                            }
                        })
                    });
                    Promise.all(showMessagePromise).then((value) => {
                        if (submitSuccessFlag){
                            window.location = "/home";
                        }
                    });
                }
            }
        }else{
            if(!this.isExceedlimit){
                Toast.fail('Required fields are missing or have invalid values. Please correct and try again.', 3000, () => {});            
            }
        }
    }

    toggleTranscriptFunction=(id,index)=>{
        this.setState({
            speechModal: !this.state.speechModal,
            modalIndex:id,
            ideaArrayIndex:index
        },()=>{})
        let ideaItem = [...this.state.ideaArray];
        if(id === 0){
            if(ideaItem[index].problem){
                this.setState({previousTextForSpeech:ideaItem[index].problem},()=>{})
            }
            else{
                this.setState({previousTextForSpeech:""},()=>{})
            }
        }
        if(id === 1){
            if(ideaItem[index].solution){
                this.setState({previousTextForSpeech:ideaItem[index].solution},()=>{})
            }
            else{
                this.setState({previousTextForSpeech:""},()=>{})
            }
        }
        
    }

    closeModalFunction=()=>{
        this.setState({speechModal:false})
    }


    handleChatGptOptionsChange=(e)=>{
        console.log(e)
        if(e !== null || e !== undefined){
            this.setState({
                subCampaignName: e.label,
                chatGptSelectedOption:e.label
            },()=>{
                console.log("text",this.state.subCampaignName)
            })
        }
        
    }

    renderChatGptOptions=()=>{
        let options = [];
        this.state.chatGptOptions.map((item) => {
            options.push({ value: item.value, label: item.label });
            return true;
        });
        return options;
    }

    

    render() {
       
        

        return (
            <>
                <section className="u-clearfix u-section-1" id="sec-0f7f">
                    <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                        <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                            <div className="u-container-layout u-container-layout-1">
                                {/* <h4 className="u-text u-text-custom-color-3" style={{ fontWeight: 'bold' }}><a href="/home"><i className="fa fa-home" style={{ color: '#FF555F', fontSize: 40, paddingRight: 10 }} /></a>New Idea</h4> */}
                                <h4 style={{  marginTop: 10,marginBottom: -35, textAlign:'center', color:'#00AECF', fontFamily:'Arial', fontSize:22 }}>Submit your idea</h4>
                                <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                                    <div style = {{textAlign:'center'}} className="u-container-layout u-container-layout-2">
                                        <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-3">
                                            <div className="u-container-layout u-container-layout-3">
                                                <p className="u-align-left u-text u-text-2" style={{ marginBottom: 20 }}>Owner details</p>
                                                <div className="row">
                                                    <div className="col-md-5"><label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Submitter OHR</label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input className ="form-input" type="text" value={this.state.ownerOHR} disabled onChange={this.handleOwnerOHRChange} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 10 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Submitter Name</label></div>
                                                    <div className="col-md-6"><input className ="form-input" type="text" value={this.state.ownerName} disabled onChange={this.handleOwnerNameChange} /></div>
                                                </div>
                                                <div style={{ marginBottom: 10 }} />

                                                <div className="row">
                                                    <div className="col-md-9"><span className="form-attribute">Are you raising idea for someone else?</span></div>
                                                    <div className="col-md-3" style={{marginLeft:-15}}>
                                                        <label className="switch">
                                                            <input type="checkbox" id="isRequestForIdeaBtn" checked={this.state.requestForIdea} onChange={() => this.showHideSubmitter()} />
                                                            <span className="slider round" />
                                                        </label>
                                                    </div>

                                                </div>
                                                <div style={{ marginTop: 5, marginBottom: 10 }} />
                                                {
                                                    this.state.requestForIdea && (
                                                        <>
                                                            <div className="row">
                                                                <div className="col-md-5"><label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Owner OHR</label> </div>
                                                                <div className="col-md-6"><input className ="form-input textarea-css ohr_input" type="number" maxLength="9" onBlur={(e)=>this.handleCreatorOHRChangeOnCut(e)}  onCut={(e)=>this.handleCreatorOHRChangeOnCut(e)}  onChange={(e)=>this.handleCreatorOHRChange(e)} style={this.state.selectStyle.requestFor} placeholder="Enter OHR" onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.handleSubmitterOHRChange();
                                                                    }
                                                                }} />
                                                                </div>
                                                                {/* <div className="col-md-1" style={{ marginLeft: -20 }}><i className="fa fa-check-square-o" style={{ marginTop: 7, color: '#FF555F', fontSize: 24 }} onClick={this.handleSubmitterOHRChange} /></div> */}
                                                                {
                                                                    this.state.errorIcon
                                                                    ?
                                                                    <div className="col-md-1" style={{ marginLeft: -20 }}><i className="fa fa-check-square-o" style={{ marginTop: 7, color: '#FF555F', fontSize: 24 }} onClick={this.warningError()} /></div>
                                                                    :
                                                                    ""
                                                                }
                                                                


                                                            </div>
                                                            <div style={{ marginBottom: 10 }} />
                                                            <div className="row">
                                                                <div className="col-md-5"><label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner Name</label> </div>
                                                                <div className="col-md-6"><input disabled className ="form-input" type="text" value={this.state.creatorName} onChange={this.handleCreatorNameChange} placeholder="Auto Load Name" /></div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                {
                                                    !this.state.requestForIdea && (
                                                        <>
                                                            <div className="row" style={{ marginBottom: 82 }}>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 235 }}>&nbsp;</p>

                                        <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-4" style={{ marginTop: "-255px" }}>
                                            <div className="u-container-layout u-container-layout-4" style={{ display:"flex"}}>
                                                <div className="u-container-style u-group u-shape-rectangle u-group-5">
                                                    <div className="u-container-layout u-container-layout-5">
                                                        <p className="u-align-left u-text u-text-8" style={{ marginBottom: 20 }} >Account details</p>
                                                        <div className="row">
                                                            <div className="col-md-5"><label className="form-attribute" title="Please type your function name (for e.g. HR, FPnA, Legal etc) if you are not a part of Digital Operations" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Account&nbsp;Name</label></div>
                                                            <div className="col-md-7">
                                                                <Select tabIndex={1} isSearchable={true} options={this.renderAccountOptions()} styles={this.state.selectStyle.accountName} placeholder="Choose Account" onChange={this.handleAccountNameChange} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Holding&nbsp;Account</label></div>
                                                            <div className="col-md-7">
                                                                <input className ="form-input" disabled list="holdingaccount" name="holdingaccount" value={this.state.holdingAccount} style={{fontWeight:'normal !important'}}/>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 6, marginBottom: 15 }} />                                                        
                                                        <div className="row">
                                                            <div className="col-md-5"><label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Vertical</label></div>
                                                            <div className="col-md-7">
                                                                <Select tabIndex={2} isSearchable={true} value={this.state.verticalSelectedOption} options={this.renderVerticalOptions()} styles={this.state.selectStyle.verticalManual} placeholder="Choose Vertical" onChange={this.handleVerticalChange} />
                                                            </div>

                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Industry&nbsp;Vertical</label></div>
                                                            <div className="col-md-7">
                                                                <Select tabIndex={3} isSearchable={true} value={this.state.subVerticalSelectedOption} options={this.renderSubVerticalOptions()} styles={this.state.selectStyle.subVerticalManual} placeholder="Choose Industry&nbsp;Vertical" onChange={this.handleSubVerticalChange} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />

                                                        {/* units and segments starts here */}
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Segment</label>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <input className ="form-input" disabled name="Segment" value={this.state.Segment ? this.state.Segment : ""} style={{fontWeight:'normal !important'}}/>
                                                            </div>
                                                        </div>

                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Unit</label>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <input className ="form-input" disabled  name="Unit" value={this.state.Unit ? this.state.Unit : ""} style={{fontWeight:'normal !important'}}/>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        {/* units and segments ends here */}


                                                        <div className="row">
                                                            <div className="col-md-5"><label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Campaign</label></div>
                                                            <div className="col-md-7">
                                                                <Select tabIndex={4} isSearchable={true} options={this.renderCampaignOptions()} styles={this.state.selectStyle.campaignTypeText} placeholder="Choose Campaign" onChange={(e)=>this.handleCampaignNameChange(e)} />
                                                            </div>
                                                            <div className="">
                                                                <div className="campaign_modal_bulb campaign_tool_tip">
                                                                    <img 
                                                                        src={require("../assets/images/question_mark_2.png")}
                                                                        alt=""
                                                                        className=""
                                                                    />
                                                                    {
                                                                        this.state.campaignInsight
                                                                        ?
                                                                        <>
                                                                            <span className="my_tooltiptext">{this.state.campaignInsight}</span>
                                                                        </>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: "15px" }} />
                                                        {
                                                            this.state.xPollinationCampaign && (
                                                                <>
                                                                    <div className="row" > 
                                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Sub Campaign</label></div>
                                                                        <div className="col-md-7">
                                                                            <Select tabIndex={5} isSearchable={true} options={this.renderSubCampaignOptions()} styles={this.state.selectStyle.subCampaignName} placeholder="Choose Sub Campaign" onChange={this.handleSubCampaignChange} />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            this.state.chatGPTTheme
                                                            ?
                                                            <>
                                                                <div className="row" > 
                                                                    <div className="col-md-5">
                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Gen AI Theme</label></div>
                                                                    <div className="col-md-7">
                                                                        <Select tabIndex={5} isSearchable={true} options={this.renderChatGptOptions()} styles={this.state.selectStyle.chatgptOption} placeholder="Choose Gen AI Theme" onChange={this.handleChatGptOptionsChange} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                        } 

                                                        {
                                                            this.state.DisplayAIFirst
                                                            ?
                                                            <>  
                                                                <div className="row" style={{marginTop:"20px"}}>
                                                                <div className="col-md-5">
                                                                    <label
                                                                    style={{
                                                                        verticalAlign: "bottom",
                                                                        marginBottom: -5,
                                                                    }}
                                                                    >
                                                                    <i>AI First Sample Ideas</i>
                                                                    </label>
                                                                </div>
                                                                <div className="col-md-7">
                                                                    <div className="ai_first_doc_link ai_first_doc_red_highlight">
                                                                    <a
                                                                        className="ldt-list1"
                                                                        href={AI_DOC_LINK}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        style={{ textAlign: "left", fontSize: 16,color:"red" }}
                                                                    >Document Link </a>
                                                                    </div>
                                                                    
                                                                </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                        }



                                                    </div>
                                                </div>
                                                <div className="u-container-style u-group u-shape-rectangle custom_u-group-6">
                                                    <div className="u-container-layout u-container-layout-6">
                                                        <p className="u-align-left u-text u-text-8" style={{ marginBottom: '-6px' }}>&nbsp;</p>
                                                        <div className="row ms-view">
                                                            <div className="col-md-5"><label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Country</label></div>
                                                            <div className="col-md-7">
                                                                <Select tabIndex={6} isSearchable={true} value={this.state.countrySelectedOption} options={this.renderCountryOptions()} styles={this.state.selectStyle.country} placeholder="Choose Country" onChange={this.handleCountryChange} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Region</label></div>
                                                            <div className="col-md-7">
                                                                <Select tabIndex={7} isSearchable={true} value={this.state.regionSelectedOption} options={this.renderRegionOptions()} styles={this.state.selectStyle.regionManual} placeholder="Choose Region" 
                                                                onChange={this.handleRegionChange} 
                                                                />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Sub-Region</label></div>
                                                            <div className="col-md-7">
                                                                <Select tabIndex={8} isSearchable={true} value={this.state.subRegionSelectedOption} options={this.renderSubRegionOptions()} styles={this.state.selectStyle.subRegionManual} placeholder="Choose Sub-Region" 
                                                                onChange={this.handleSubRegionChange} 
                                                                />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Blackbelt&nbsp;Name</label></div>
                                                            <div className="col-md-7">
                                                                <Select tabIndex={9} isSearchable={true} value={this.state.blackBeltSelectedOption} options={this.renderBlackBeltOptions()} styles={this.state.selectStyle.blackBeltUserName} placeholder="Choose Blackbelt" onChange={this.handleBlackBeltNameChange} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        {/* <div className="row">
                                                            <div className="col-md-5"><label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> MBB</label></div>
                                                            <div className="col-md-7">
                                                                <Select tabIndex={10} isSearchable={true} value={this.state.mbbSelectedOption} options={this.renderMBBOptions()} styles={this.state.selectStyle.mbbUserName} placeholder="Choose MBB" onChange={this.handleMBBChange} />
                                                            </div>
                                                        </div>      */}
                                                                                                               
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} /> */}

                                        {
                                            this.state.ideaArray.map((ideaItem, index) => {
                                                return (
                                                    <>
                                                        {
                                                            (ideaItem.removeIdea && index !== 0) && (
                                                                <span href="#" id="removeIdea1" className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" onClick={() => this.removeIdea(index)} style={{ marginTop: 20, marginBottom: '-40px', marginRight: 10, zIndex: 10 }}><i className="fa fa-trash" aria-hidden="true" />&nbsp;&nbsp;Remove idea</span>
                                                            )
                                                        }

                                                        
                                                        {
                                                            this.state.dataBridgeCampaign || this.state.xPollinationCampaign || this.state.chatGPTTheme
                                                            ?
                                                            <>
                                                                <div style={{ borderBottom: '1px solid lightgrey', marginTop: "15px" }} />
                                                            </>
                                                            :
                                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 , marginTop:"0px"}} />
                                                        }

                                                        <div id="idea1" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ marginTop: "15px" }}>
                                                            <div className="u-container-layout u-container-layout-7">
                                                                <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                                    <div className="u-container-layout u-container-layout-8">
                                                                        <p id="idea1title" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Idea details</p>
                                                                        <div className="row">
                                                                            <div className="col-md-4"><label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Service&nbsp;Line</label></div>
                                                                            <div className="col-md-8">
                                                                                <Select tabIndex={index} isSearchable={true} options={this.renderServiceLineOptions()} styles={this.state.selectStyle.ideaArray[index].serviceLineText} placeholder="Choose Service Line" onChange={(e) => this.handleServiceLineChange(e, index)} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 15 }} />
                                                                        <div className="row">
                                                                            <div className="col-md-4"><label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Sub-Service&nbsp;Line</label></div>
                                                                            <div className="col-md-8">
                                                                                <Select tabIndex={index} isSearchable={true} value={ideaItem.subServiceLineSelectedOption} options={this.renderSubServiceLineOptions(index)} styles={this.state.selectStyle.ideaArray[index].subServiceLineText} placeholder="Choose Sub Service Line" onChange={(e) => this.handleSubServiceLineChange(e, index)} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 15 }} />  
                                                                        <div className="row">
                                                                            <div className="col-md-7">
                                                                                <p className="u-align-left u-text u-text-custom-color-4 u-text-8">
                                                                                    <span className="u-text-black form-attribute" style={{ fontWeight: 'normal' }}><span style={{ color: 'red' }}>* </span>What is the Problem ?<span style={{ fontWeight: 'normal', fontSize:12, fontStyle: 'italic', color: 'grey' }}>(max. 2000 chars)</span></span>
                                                                                </p> 
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <div className="" style={{display:'flex',justifyContent:"flex-end"}}>
                                                                                    <img onClick={(e)=>this.toggleTranscriptFunction(0,index)} src={require("../assets/images/voice.png")} className="w100" alt="" style={{width:"24px",cursor:"pointer"}} />
                                                                                </div>
                                                                            </div>
                                                                        </div>   
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <textarea tabIndex={index} className = "textarea-css form-attribute" rows={6} cols={80} style={{ padding: 10, maxWidth: '100%', ...this.state.selectStyle.ideaArray[index].problem }} 
                                                                                placeholder= {this.state.DisplayAIFirst ? "Share your problem statement briefly, set clear goals, and give some background. Explain why the problem matters and how it affects things. Tell us what you hope to achieve. Make sure your input is clear and helps us understand your problem statement better." : "Eg:- Increased manual interventions in the equipment finance business leading to manual processing of ~70% payments by Genpact - Higher wire/automated clearing house (ACH) cycle time due to delay in solving the cases under suspense bucket - $16MM existing in different risk & suspense buckets, where we did not have a resolution and depended on client for further action"} 
                                                                                    value={ideaItem.problem} onChange={(event) => this.handleProblemChange(event.target.value, index)} />
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>

                                                                    {
                                                                        this.state.speechModal
                                                                        ?
                                                                            <SpeechToTextSubmitPage modalOpen={true} 
                                                                                onTranscriptProblem ={this.handleTranscriptProblemChange} 
                                                                                onTranscriptSolution={this.handleTranscriptSolutionChange} 
                                                                                modalId={this.state.modalIndex} 
                                                                                closeModalFunction={this.closeModalFunction} 
                                                                                ideaArrayIndex={this.state.ideaArrayIndex} 
                                                                                previousText={this.state.previousTextForSpeech}
                                                                            />
                                                                        :
                                                                        null
                                                                    } 


                                                                </div>

                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 30 }}>&nbsp;</p>

                                                                <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                                    <div className="u-container-layout u-container-layout-9" style={{ marginTop: -20 }}>
                                                                        <div className="row">
                                                                            <div className="col-md-3"><label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}><label style={{ color: 'red', verticalAlign: 'bottom' }}>*</label> Idea&nbsp;Name<span style={{ fontWeight: 'normal', fontSize:12, fontStyle: 'italic', color: 'grey' }}> (max. 200 chars)</span></label></div>
                                                                            <div className="col-md-9" style={{ alignItems: 'flex-start' }}>
                                                                                <input tabIndex={index} className = "textarea-css form-attribute" type="text" value={ideaItem.ideaName} style={this.state.selectStyle.ideaArray[index].ideaName} onChange={(event) => this.handleIdeaNameChange(event.target.value, index)} placeholder="Eg: Cash flow improvement by restructuring processes" /></div>
                                                                                {/* <div className="col-md-1" style={{ marginLeft: -5 }}><i className="fa fa-check-square-o" title= "Validate Idea" style={{ marginTop: 7, color: '#FF555F', fontSize: 24 }} onClick={() => this.handleIdeaUnique (ideaItem.ideaName)} /></div> */}
                                                                        </div>
                                                                        <div style={{ marginBottom: 38 }} />
                                                                        <div style={{marginTop:0}}>&nbsp;</div>
                                                                        <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -90 }}>&nbsp;</p>

                                                                        <div className="row">
                                                                            <div className="col-md-7">
                                                                                <p className="u-align-left u-text u-text-custom-color-4 u-text-8">
                                                                                    <span className="u-text-black form-attribute" style={{ fontWeight: 'normal' }}><span style={{ color: 'red' }}>* </span>How to resolve the problem <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}>(max. 2000 chars)</span></span>
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <div className="" style={{display:'flex',justifyContent:"flex-end"}}>
                                                                                    <img onClick={(e)=>this.toggleTranscriptFunction(1,index)} src={require("../assets/images/voice.png")} className="w100" alt="" style={{width:"24px",cursor:"pointer"}} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <textarea tabIndex={index} className = "textarea-css form-attribute" rows={6} cols={80} style={{ padding: 10, maxWidth: '100%', ...this.state.selectStyle.ideaArray[index].solution }} 
                                                                                placeholder={this.state.DisplayAIFirst ? "Please provide a clear and concise outline of the automated process, detailing the steps, technologies, and differences compared to the current process. Highlight the expected improvements and efficiencies to ensure a thorough understanding of the proposed changes." : "Eg:- Deployed data analysis, value stream mapping, and process restructuring for AML cases (anti money laundering) - Set up a proactive 'end customer' education program through detailed data analysis to reduce suspense bucket - Reduced touch time at various steps in the cash application process through quick automation leading to improvement in cycle time for wire payments"}
                                                                                value={ideaItem.solution} onChange={(event) => this.handleSolutionChange(event.target.value, index)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 17 }} />
                                                                <div className="row">
                                                                    <div className="col-md-4"><span className="form-attribute" style={{ fontWeight: 'normal' }}><span style={{ color: 'red' }}>*</span>Are you trying to replicate an existing Idea?</span>
                                                                        </div>
                                                                    <div className="col-md-4" style={{textAlign: 'left !important'}}>
                                                                        <label className="switch">
                                                                            <input tabIndex={index} type="checkbox" id="isReplicationIdeaBtn" checked={ideaItem.newOrReplicatedIdea} onChange={() => this.showHideDiv(index)} />
                                                                            <span className="slider round" />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    ideaItem.newOrReplicatedIdea && (
                                                                        <div className="row" id="ReplicatedProjectId">
                                                                            <div className="col-md-12" style={{ marginBottom: 15 }} />
                                                                            <div className="col-md-4"><span className="form-attribute" style={{ fontWeight: 'normal' }}>Provide the ID of the executed Idea which you want to replicate?</span> <span style={{ color: 'red' }}>*</span></div>
                                                                            <div className="col-md-2">
                                                                                <input tabIndex={18} className="form-input textarea-css" type="text" value={ideaItem.replicationProjectId} style={this.state.selectStyle.ideaArray[index].replicationProjectId} 
                                                                                    onChange={event => { this.handleReplicationIdChange(event.target.value, index) }} 
                                                                                    // onBlur={event => { this.validateReplicationId(index) }} 
                                                                                    onKeyPress={event => {
                                                                                        if (event.key === 'Enter') {
                                                                                            this.validateReplicationId(index);
                                                                                        }
                                                                                }} />
                                                                            </div>
                                                                            <div className="col-md-1" style={{ marginLeft: -20 }}><i className="fa fa-check-square-o" style={{ marginTop: 7, color: '#FF555F', fontSize: 24 }} onClick={event => { this.validateReplicationId(index)}} /></div>
                                                                            <div className="col-md-8" style={{ marginBottom: 15 }} />
                                                                        </div>
                                                                    )
                                                                }

                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                        <div style={{ marginTop: 10, marginBottom:5 }}>
                                            <div className="u-container-layout u-container-layout-7">
                                                <button tabIndex={19} href="#" className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" 
                                                style={{padding:5}}
                                                onClick={this.sameAccountWarning}><i className="fa fa-plus" aria-hidden="true" />&nbsp;&nbsp;Add more idea</button>
                                            </div>
                                        </div>
                                        <div className="btn-group">
                                            <button tabIndex={20} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.submitIdea}>Submit</button>
                                            <button tabIndex={21} className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.cancelEvent}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default SubmitPage