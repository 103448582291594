import {Buffer} from 'buffer';

function Checksum(param) {
    let objJsonStr = JSON.stringify(param);
    let objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    console.log("encoded objJsonB64 :",objJsonB64);

    let str1 = objJsonB64.slice(0, 23);
    let str2 = objJsonB64.slice(23, 51);
    let str3 = objJsonB64.slice(51, 58);
    let str4 = objJsonB64.slice(58);
    let finalJsonData =str3+str1+str4+str2;
    return finalJsonData;
  }
  
  export default Checksum;