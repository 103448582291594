import React, { Component } from 'react'
import { GET_IDEA_URL, APPROVED, PENDING, DISPLAYPROJECTTHEME, SENT_BACK, REJECTED, GET_METADATA, REVIEW_IDEA_URL, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, ACCESS_TOKEN, COMPLETED_STATUS, AWAITING_MBB_APPROVAL, USER_DATA, SIGNOFF_CONTENT, PPT_CONTENT, REQUEST_CANCELLATION, BI_FORM_DATE, AWAITING_QAULITY_CHECK_REVIEW, YESORNO, SOLUTIONCOMPLEXITY,REPLICABILITYOPTIONS,DB_PEF_DATE,AIFIRST,AI_DOC_LINK } from '../assets/constants/constants';
import Select from 'react-select';
import Toast from 'light-toast';
import { selectValidation as selectStyle, textBoxValidation } from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";
import UpdatedSpeech from './UpdatedSpeech';
import ValueDelivery from '../utils/ValueDelivery';
import FPNAGOL from '../utils/FPNAGOL';
import DisplayTemplate from '../utils/DisplayTemplates'

export class MBBReviewIdeaPage extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);
    constructor(props) {
        super(props)

        const isApprovalPage = `${this.props.location.approval}`;
        let propsData = this.props.location;
        this.state = {
            ideaItem: {},
            WorkflowStatus: "",
            Comment: "",
            accountName: "",
            campaignName: "",
            serviceLineName: "",
            dataBridgeCampaign: false,
            xPollinationCampaign: false,
            approvalPage: isApprovalPage,
            businessImpactMasterList: [],
            businessImpactValueArray: [],
            summaryArray: [],
            fteTotal: 0,
            dollarTotal: 0.0,
            selectStyle: {},
            mbbComment: "",
            finalApproveOptionsValue: [{
                WorkflowStatusId: 1,
                WorkflowStatusName: "Approved"
            },
            {
                WorkflowStatusId: 2,
                WorkflowStatusName: "Rejected"
            },
            {
                WorkflowStatusId: 3,
                WorkflowStatusName: "Send Back"
            },
            ],
            approvalSelectedOption: null,
            propsData: propsData,
            Role: "",
            speechModal: false,
            previousTextForSpeech: "",
            showHistoricalBI: false,
            ideaCreatedDate: "",
            showClientFields: false,
            showGOLFields: false,

            golFilename: "",
            golContent: "",
            golFileType: "",
            golOhr: "",
            golEmail: "",
            golFullname: "",

            fpnaFilename: "",
            fpnaContent: "",
            fpnaFileType: "",
            fpnaName: "",
            fpnaEmail: "",
            fpnaOhr: "",
            fpnaApprovalAttachedSelectedOption: null,

            clientName: "",
            clientRole: "",
            clientSignOffDate: "",
            clientApprovalEmailSelectedOption: null,
            signOffFilename: "",


            vrconditiontype: "",
            vrMapping: "",
            vrRegionMapping: "",
            vrVerticalSplit: "",
            vrResult: "",
            IsVDType: "",
            mbbLimit: 49999,
            approvalStatusState: "",
            awaitingQC: false,
            monetizableProjectSelectedOption: null,
            mbbMonetizableOptions: [
                { value: '0', label: 'Yes' },
                { value: '1', label: 'No' },
            ],
            monetizableStatusOptions: "Approved by Clients",
            finalCommentLog: false,
            commentLog: false,
            qcCommentLog: false,
            qcComments: {},
            monetizationFileContent: null,
            IsSampleCheck: false,
            FinalImpactFTE: '',
            FinalImpactDollar: '',
            sortedBiImpactvalueArray: [],

            totalMonetizedFTE: 0,
            totalMonetizedDollar: 0,
            mbbMonetizationApproval: null,
            mbbReviewMontization: null,
            qcCommentsWithVD: [],
            golFpnaFileDetails: [],
            isFpnaRequired: false,
            displayProjectTheme: false,
            clusterData: [],
            isClusterSaveDisabled: true,
            displayParentAlpha: '',
            projectTypeSelectedOption: null,
            projectIDSelectedOption: null,
            ideasDB: [],
            ideasDBDoc: [],
            replicabilityIndex:[],
            displayDBFields:false,
            mbbVDApproval:[],
            campaignList: [],
            campaignInsight:"",

            //AI
            ideasAI: [],
            isIdeaReplicable:"",

        }
        this.isExceedlimit = false;
    }

    async componentDidMount() {
        Toast.loading("Fetching Idea & Metadata...", () => { });
        await this.fetchIdea();
        // Toast.loading("Fetching Metadata...", () => { });
        await this.fetchMetadata();
        Toast.hide();
    }

    formatDateString(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
    }

    fetchIdea = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            // fetch(`${GET_IDEA_URL}IdeaId=944`, requestOptions)
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&RecID=''&isItIdeaOrGenAI='Idea'`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    // const projectDeckMetadata = data.ResultSets.Table1[0].ProjectPPTDeck ? data.ResultSets.Table1[0].ProjectPPTDeck : "";
                    // const signOffMetadata = data.ResultSets.Table1[0].CustomerLeadSignOff ? data.ResultSets.Table1[0].CustomerLeadSignOff : "";


                    const projectFilename = data.ResultSets.Table1[0].ProjectPPTFileName ? data.ResultSets.Table1[0].ProjectPPTFileName : "";
                    const signOffFilename = data.ResultSets.Table1[0].CustomerSignoffFileName ? data.ResultSets.Table1[0].CustomerSignoffFileName : "";
                    const projectFileType = data.ResultSets.Table1[0].ProjectPPTType ? data.ResultSets.Table1[0].ProjectPPTType : "";
                    const signOffFileType = data.ResultSets.Table1[0].CustomerSignoffType ? data.ResultSets.Table1[0].CustomerSignoffType : "";

                    // const projectContent = "data:" + projectFileType + ";base64," + projectDeckMetadata;
                    // const signOffContent = "data:" + signOffFileType + ";base64," + signOffMetadata;
                    this.setState({
                        ideaItem: data.ResultSets.Table1[0],
                        reviewComments: data.ReviewComments.Table1,
                        finalReviewComments: data.FinalReviewComments.Table1,
                        WorkflowStatus: data.ResultSets.Table1[0].WorkflowStatus,
                        Comment: data.ResultSets.Table1[0].Comment,
                        showSourceId: data.ResultSets.Table1[0].TransformationLeverName === "Digital" ? true : false,
                        projectDeckFilename: projectFilename,
                        signOffFilename: signOffFilename,
                        projectFileType: projectFileType,
                        signOffFileType: signOffFileType,
                        // projectDeckContent: projectContent,
                        // signOffContent: signOffContent,
                        sourceId: data.ResultSets.Table1[0].SourceId,
                        dataBridgeCampaign: (data.ResultSets.Table1[0].CampaignName === DATA_BRIDGE_CAMPAIGN),
                        xPollinationCampaign: (data.ResultSets.Table1[0].CampaignName === X_POLLINATION_CAMPAIGN),
                        businessImpactValueArray: data.BIData ? (data.BIData.Table1 ? (data.BIData.Table1.length > 0 ? data.BIData.Table1 : []) : []) : [],
                        ideaCreatedDate: data.ResultSets.Table1[0].CreatedDate,
                        IsVDType: data.ResultSets.Table1[0].VDType,
                        qcComments: data.QcComments.Table1,
                        qcCommentsWithVD: data.QcComments.Table2 ? data.QcComments.Table2 : [],
                        IsSampleCheck: data.SampleCheck.Table1[0].IsSampleCheck,
                        FinalImpactFTE: data.ResultSets.Table1[0].FinalImpactFTE ? data.ResultSets.Table1[0].FinalImpactFTE : 0,
                        FinalImpactDollar: data.ResultSets.Table1[0].FinalImpactDollar ? data.ResultSets.Table1[0].FinalImpactDollar : 0,
                        clusterData: data.ClusterData.Table1 ? data.ClusterData.Table1 : [],

                        ideasDB: data.ResultSets.Table3 && data.ResultSets.Table3[0].Result === "NULL" ? [] : data.ResultSets.Table3[0],
                        ideasDBDoc: data.ResultSets.Table4 && data.ResultSets.Table4[0].Result === "NULL" ? [] : data.ResultSets.Table4[0],
                        ideasAI: data.ResultSets.Table2 ? data.ResultSets.Table2[0] : [],

                    }, () => {

                        if (this.state.ideaItem.CreatedDate) {
                            let db_idea_created = new Date(this.state.ideaItem.CreatedDate);
                            let abc = new Date(DB_PEF_DATE);
                            if (db_idea_created.getTime() < abc.getTime()) {
                              this.setState({displayDBFields:false},()=>{})
                            } else {
                              this.setState({displayDBFields:true},()=>{})
                            }
                        }

                        

                        if(this.state.ideaItem.ReplicabilityIndex){
                            REPLICABILITYOPTIONS.map((item)=>{
                                if(item.value === this.state.ideaItem.ReplicabilityIndex){
                                    this.setState({replicabilityIndex:item},()=>{})
                                }
                            })
                        }

                        if (this.state.clusterData && this.state.clusterData.length > 0) {
                            const targetRecID = this.state.ideaItem.LeanID;
                            const filteredData = this.state.clusterData.filter(item => item.RecAlpha === targetRecID);
                            //console.log(filteredData)
                            if (filteredData && filteredData.length > 0 && filteredData !== undefined && filteredData !== null) {
                                this.setState({ displayParentAlpha: filteredData[0].ParentAlpha }, () => { })
                                let options = [];
                                options.push({ value: 0, label: filteredData[0].ParentAlpha })
                                this.setState({ projectIDSelectedOption: options }, () => { })

                                let options1 = [];
                                options1.push({ value: 0, label: "GB" })
                                this.setState({ projectTypeSelectedOption: options1 }, () => { })
                            } else {
                                this.setState({ displayParentAlpha: "" }, () => { })
                            }
                        } else {
                            this.setState({ displayParentAlpha: "" }, () => { })
                        }


                        if (this.state.ideaItem.CreatedDate) {
                            let created = new Date(this.state.ideaItem.CreatedDate);
                            let abc = new Date(DISPLAYPROJECTTHEME);
                            if (created.getTime() < abc.getTime()) {
                                //console.log("created date is lesser")
                                this.setState({ displayProjectTheme: true })
                            } else {
                                this.setState({ displayProjectTheme: false })
                                //console.log("created date is greater ")
                            }
                        }
                        let sortedBiImpactvalueArray = [...this.state.businessImpactValueArray].sort((a, b) => a.ImpactType.toLowerCase().localeCompare(b.ImpactType.toLowerCase()))
                        this.state.businessImpactValueArray = sortedBiImpactvalueArray
                        this.state.sortedBiImpactvalueArray = sortedBiImpactvalueArray
                        this.setState({
                            businessImpactValueArray: sortedBiImpactvalueArray,
                            sortedBiImpactvalueArray: sortedBiImpactvalueArray
                        })
                        //console.log("sortedBiImpactvalueArray", sortedBiImpactvalueArray);
                        //console.log("businessImpactValueArray", this.state.businessImpactValueArray);

                        // let localSummaryArray = [];
                        // let dataArray = [...this.state.businessImpactValueArray];
                        // let localIndex = 0;
                        // let dataElement;
                        // for (let dataIndex = 0; dataIndex < dataArray.length; dataIndex++) {
                        //     dataElement = dataArray[dataIndex];
                        //     localIndex = 0;
                        //     for (; localIndex < localSummaryArray.length; localIndex++) {
                        //         const element = localSummaryArray[localIndex];
                        //         if (element.impacttype === dataElement.ImpactType && element.monetizableType === dataElement.MonetizableType) {
                        //             localSummaryArray[localIndex].fteValue = parseFloat(element.fteValue) + parseFloat(dataElement.FTEImpact);
                        //             localSummaryArray[localIndex].dollarValue = parseFloat(element.dollarValue) + parseFloat(dataElement.DollarImpact);
                        //             break;
                        //         }
                        //     }
                        //     if (localIndex === localSummaryArray.length) {
                        //         localSummaryArray[localIndex] = {};
                        //         localSummaryArray[localIndex].impacttype = dataElement.ImpactType;
                        //         localSummaryArray[localIndex].monetizableType = dataElement.MonetizableType;
                        //         localSummaryArray[localIndex].fteValue = dataElement.FTEImpact ? parseFloat(dataElement.FTEImpact) : 0.0
                        //         localSummaryArray[localIndex].dollarValue = dataElement.DollarImpact ? parseFloat(dataElement.DollarImpact) : 0.0;
                        //     }
                        // }


                        let myArray = [...this.state.businessImpactValueArray];
                        const groupedData = myArray.reduce((accumulator, currentValue) => {
                            const ImpactType = currentValue.ImpactType;
                            const MonetizableType = currentValue.MonetizableType;
                            const FTEImpact = parseFloat(currentValue.FTEImpact) || 0.0;
                            const DollarImpact = parseFloat(currentValue.DollarImpact) || 0.0;

                            const existingRow = accumulator.find(row => row.ImpactType === ImpactType && row.MonetizableType === MonetizableType);
                            if (existingRow) {
                                existingRow.FTEImpact += FTEImpact
                                existingRow.DollarImpact += DollarImpact
                            } else {
                                accumulator.push({ ImpactType, MonetizableType, FTEImpact, DollarImpact });
                            }
                            return accumulator;
                        }, []);

                        //console.log("grouped data", groupedData)

                        let sortedSummaryIndex = groupedData.sort((a, b) => a.ImpactType.toLowerCase().localeCompare(b.ImpactType.toLowerCase()))
                        this.setState({
                            summaryArray: sortedSummaryIndex,
                        }, () => {
                            console.log(this.state.summaryArray)

                            if (this.state.IsVDType === "Value Delivered to Client + Genpact") {
                                this.setState({ showClientFields: true, showGOLFields: true }, () => { })
                            }
                            if (this.state.IsVDType === "No Value Delivery") {
                                this.setState({ showClientFields: false, showGOLFields: false, summaryArray: [], businessImpactMasterList: [], businessImpactValueArray: [], }, () => { })
                            }
                            if (this.state.IsVDType === "Value Delivered to Client") {
                                this.setState({ showClientFields: true, showGOLFields: false }, () => { })
                            }
                            if (this.state.IsVDType === "Value Delivered to Genpact") {
                                this.setState({ showClientFields: false, showGOLFields: true }, () => { })
                            }

                        });



                        let dollarTotal = 0.0;
                        let fteValue = 0.0;
                        this.state.businessImpactValueArray.map((item) => {
                            if (item && item.DollarImpact) {
                                dollarTotal += parseFloat(item.DollarImpact);
                            }
                            if (item && item.FTEImpact) {
                                fteValue += parseFloat(item.FTEImpact);
                            }
                            this.setState({ FinalImpactDollar: dollarTotal, FinalImpactFTE: fteValue, fteTotal: fteValue, dollarTotal: dollarTotal }, () => { })

                        });


                        if (this.state.ideaItem.IsVDMonetizable === "Yes" && this.state.ideaItem.MonetizationStatus === "Approved by Client") {
                            let MonetizedBiArray = [];
                            this.state.businessImpactValueArray.filter((item) => {
                                if (item.IsMonetized === "Yes") {
                                    MonetizedBiArray.push(item)
                                }
                            })
                            if (MonetizedBiArray) {
                                const groupedData = MonetizedBiArray.reduce((accumulator, currentValue) => {
                                    const ImpactType = currentValue.ImpactType;
                                    const FTEImpact = parseFloat(currentValue.FTEImpact) || 0.0;
                                    const DollarImpact = parseFloat(currentValue.DollarImpact) || 0.0;
                                    const existingRow = accumulator.find(row => row.ImpactType === ImpactType);
                                    if (existingRow) {
                                        existingRow.FTEImpact += FTEImpact
                                        existingRow.DollarImpact += DollarImpact
                                    }
                                    else {
                                        accumulator.push({ ImpactType, FTEImpact, DollarImpact });
                                    }
                                    return accumulator;
                                }, []);
                                this.setState({
                                    totalMonetizedFTE: groupedData[0].FTEImpact,
                                    totalMonetizedDollar: groupedData[0].DollarImpact
                                }, () => { })
                            }
                        }


                        if (this.state.ideasAI) {
                            if(this.state.ideasAI.IsIdeaReplicable ){
                                this.setState({ isIdeaReplicable: "Yes" }, () => { })
                            }else{
                                this.setState({ isIdeaReplicable: "No" }, () => { })
                            }
                        }


                        resolve();
                    });

                })
                .catch(error => {
                    console.log("idea failed")
                    Toast.fail("Error while fetching idea. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }

    fetchMetadata = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "",
                EntityName: ""
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("fetch Metadata", data)
                    this.setState({
                        businessImpactMasterList: data ? data.BIMasterDataVerseNew : [],
                        biThreshold: data.BIQCThreshold ? data.BIQCThreshold[0] ? data.BIQCThreshold[0].pg_threshold : 0 : 0,
                        vrconditiontype: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_vrconditiontype : [] : [],
                        vrVerticalMapping: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_vertical ? data.BIQCVRMapping[0].pg_vertical.split("|") : "ALL" : "ALL" : "ALL",
                        vrRegionMapping: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_region ? data.BIQCVRMapping[0].pg_region.split("|") : "ALL" : "ALL" : "ALL",
                        campaignList: data ? data.Campaigns : [],

                    }, () => {

                        if(this.state.ideaItem.CampaignName && this.state.campaignList){
                            this.state.campaignList.map((campaign)=>{
                              if(campaign.CampaignName === this.state.ideaItem.CampaignName){
                                this.setState({campaignInsight:campaign.CampaignDescr},()=>{})
                              }
                            })
                            
                        }

                          

                        resolve();
                    })
                })
                .catch(error => {
                    console.log("meta data failed")
                    Toast.fail('Error occured while getting metadata idea. Please try again!', 3000, () => {
                        console.log(JSON.stringify(error));
                        this.setState({
                            businessImpactMasterList: []
                        }, () => {
                            resolve();
                        })
                    });

                });
        });
    }



    saveEvent = () => {
        let redirectURL = "/review-page";
        if (this.state.approvalPage) {
            redirectURL = redirectURL + "?page=" + this.state.approvalPage;
            this.props.history.push(redirectURL);
        }
        else {
            this.props.history.push(redirectURL);
        }
    }
    renderStatusOptions = () => {
        let options = [];
        if(this.state.ideaItem.IsVDMonetizable === "Yes"){
            if(this.state.mbbVDApproval && this.state.mbbVDApproval.label === "Yes"){
                if(this.state.mbbReviewMontization && this.state.mbbReviewMontization.label === "No"){
                    this.state.finalApproveOptionsValue.slice(-2).map((item) => {
                        options.push({
                          value: item.WorkflowStatusId,
                          label: item.WorkflowStatusName,
                        });
                        return options;
                    });
                }
                if(this.state.mbbReviewMontization && this.state.mbbReviewMontization.label === "Yes" 
                ){
                    this.state.finalApproveOptionsValue.map((item) => {
                        options.push({
                            value: item.WorkflowStatusId,
                            label: item.WorkflowStatusName
                        })
                        return options;
                    });
                }
            }
            if(this.state.mbbVDApproval && this.state.mbbVDApproval.label === "No"){
                this.state.finalApproveOptionsValue.slice(-2).map((item) => {
                    options.push({
                        value: item.WorkflowStatusId,
                        label: item.WorkflowStatusName,
                    });
                    return options;
                });
            }
            
        }else{
            if(this.state.mbbVDApproval && this.state.mbbVDApproval.label === "Yes"){
                this.state.finalApproveOptionsValue.map((item) => {
                    options.push({
                        value: item.WorkflowStatusId,
                        label: item.WorkflowStatusName
                    })
                    return options;
                });
            }
            if(this.state.mbbVDApproval && this.state.mbbVDApproval.label === "No")
            {
                this.state.finalApproveOptionsValue.slice(-2).map((item) => {
                    options.push({
                      value: item.WorkflowStatusId,
                      label: item.WorkflowStatusName,
                    });
                    return options;
                });
            }
        }
        return options;
    }

    

    handleApprovalStatus = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.approvalSelectedOption = selectStyle(true);
        this.setState({
            approvalSelectedOption: {
                value: e.value,
                label: e.label
            },
            selectStyle: styleStateObj,
            finalComment: ""
        }, () => { });
    }

    submitIdea = () => {
        if (this.validate()) {
            if (window.confirm("Are you sure you want to submit the lean project?")) {
                Toast.loading("Lean project updating...", () => { });
                let param = {};
                if (this.state.approvalSelectedOption.label === "Approved") {
                    param = {
                        IdeaId: this.props.location.state,
                        IdeaName: this.state.ideaItem.IdeaName,
                        // ApprovalStatus: this.state.approvalSelectedOption.label,
                        ApprovalStatus: this.state.approvalStatusState,
                        FinalComments: this.state.finalComment,
                        IsMBBApproval: "MBB",
                        UpdaterOHR: this.sessionData.profile.oHRId,
                        UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                        UpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                        comment: this.state.finalComment,
                        Role: "MBB",
                        CompletedDate: new Date().toLocaleString(),
                        IsMbbMonetizable: this.state.mbbReviewMontization ? this.state.mbbReviewMontization.label : "",
                        updaterAction: this.state.approvalSelectedOption ? this.state.approvalSelectedOption.label === "Completed" ? "Approved" : this.state.approvalSelectedOption.label : "",
                        FpnaApprovalAttachedSelectedOption: this.state.mbbVDApproval ?  this.state.mbbVDApproval.label:""
                    }
                }
                else {
                    param = {
                        IdeaId: this.props.location.state,
                        IdeaName: this.state.ideaItem.IdeaName,
                        ApprovalStatus: this.state.approvalSelectedOption.label,
                        FinalComments: this.state.finalComment,
                        IsMBBApproval: "MBB",
                        UpdaterOHR: this.sessionData.profile.oHRId,
                        UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                        UpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                        comment: this.state.finalComment,
                        Role: "MBB",
                        IsMbbMonetizable: this.state.mbbReviewMontization ? this.state.mbbReviewMontization.label : "",
                        updaterAction: this.state.approvalSelectedOption ? this.state.approvalSelectedOption.label === "Completed" ? "Approved" : this.state.approvalSelectedOption.label : "",
                        FpnaApprovalAttachedSelectedOption: this.state.mbbVDApproval ?  this.state.mbbVDApproval.label:""
                    }
                }
                console.log(param)
                let finalJsonData = Checksum(param);
                //console.log("final Json Data",finalJsonData );
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization":  "Bearer " + this.accessToken,
                    },
                    body: JSON.stringify(param)
                };
                fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        Toast.hide();
                        Toast.success('Updated successfully.', 3000, () => {
                            console.log(JSON.stringify(data));
                            let redirectURL = "/review-page";
                            if (this.state.approvalPage) {
                                redirectURL = redirectURL + "?page=" + this.state.approvalPage;
                            }
                            window.location = redirectURL;
                        });

                    });
            }
        } else {
            if (!this.isExceedlimit) {
                Toast.fail('Required fields are missing or have invalid values. Please correct and try again.', 3000, () => { });
            }
        }
        return false;
    }
    FinalApproverCheck() {
        let Approver = COMPLETED_STATUS;
        if (this.state.ideaItem.VDType && this.state.ideaItem.VDType !== "No Value Delivery") {
            if (this.state.biThreshold !== 0) {
                if (this.state.ideaItem.FinalImpactDollar >= this.state.biThreshold) {
                    if (this.state.vrconditiontype === "AND") {
                        if ((this.state.vrVerticalMapping.includes(this.state.ideaItem.VerticalManual) || this.state.vrVerticalMapping === "ALL") && (this.state.vrRegionMapping.includes(this.state.ideaItem.RegionManual) || this.state.vrRegionMapping === "ALL") && this.state.IsSampleCheck) {
                            Approver = AWAITING_QAULITY_CHECK_REVIEW
                        }
                    } else {
                        if ((this.state.vrVerticalMapping.includes(this.state.ideaItem.VerticalManual) || this.state.vrVerticalMapping === "ALL") || (this.state.vrRegionMapping.includes(this.state.ideaItem.RegionManual) || this.state.vrRegionMapping === "ALL") && this.state.IsSampleCheck) {
                            Approver = AWAITING_QAULITY_CHECK_REVIEW
                        }
                    }
                }
            }
        }
        if (this.state.ideaItem.IsVDMonetizable && this.state.ideaItem.IsVDMonetizable === "Yes" && this.state.ideaItem.MonetizationStatus && this.state.ideaItem.MonetizationStatus === "Approved by Client") {
            Approver = AWAITING_QAULITY_CHECK_REVIEW
        }
        if (this.state.ideaItem.QCOHR !== null && this.state.ideaItem.QCOHR !== undefined && this.state.ideaItem.QCOHR !== '') {
            Approver = 'Awaiting Project Review'
        }

        if (this.state.approvalSelectedOption && this.state.approvalSelectedOption.label === 'Send Back') {
            Approver = "Pending Documents"
        } else if (this.state.approvalSelectedOption && this.state.approvalSelectedOption.label === 'Rejected') {
            Approver = "Rejected"
        }
        this.state.approvalStatusState = Approver
        this.setState({
            approvalStatusState: Approver
        }, () => { })
    }

    validate = () => {
        this.FinalApproverCheck()
        let isValid = true;
        this.isExceedlimit = false;
        let styleStateObj = this.state.selectStyle;
        if (this.state.approvalSelectedOption === null || this.state.approvalSelectedOption === undefined || this.state.approvalSelectedOption.length === 0 || !this.state.approvalSelectedOption) {
            isValid = false;
            styleStateObj.approvalSelectedOption = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.approvalSelectedOption = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }

        if (!this.state.finalComment || this.state.finalComment === "") {
            isValid = false;
            styleStateObj.comment = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            if (this.state.finalComment.length > 500) {
                Toast.fail("Comment cannot be more than 500 characters, please change and resubmit.", 3000);
                isValid = false;
                this.isExceedlimit = true;
                styleStateObj.comment = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else {
                styleStateObj.comment = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                this.setState({
                    selectStyle: styleStateObj
                })
            }
        }
        

        if (this.state.mbbVDApproval === null || this.state.mbbVDApproval === undefined || this.state.mbbVDApproval.length === 0 || !this.state.mbbVDApproval) {
            isValid = false;
            styleStateObj.mbbVDApproval = selectStyle(false);
            this.setState({ selectStyle: styleStateObj }, () => { })
        }
        else {
            styleStateObj.mbbVDApproval = selectStyle(true);
            this.setState({ selectStyle: styleStateObj }, () => { })
        }

        if (this.state.ideaItem.IsVDMonetizable === "Yes") {
            if (this.state.mbbReviewMontization === null || this.state.mbbReviewMontization === undefined || !this.state.mbbReviewMontization || this.state.mbbReviewMontization.length === 0) {
                isValid = false;
                styleStateObj.mbbApprovalStyle = selectStyle(false);
                this.setState({ selectStyle: styleStateObj }, () => { })
            }
            else {
                styleStateObj.mbbApprovalStyle = selectStyle(true);
                this.setState({ selectStyle: styleStateObj }, () => { })
            }

            if (this.state.mbbReviewMontization !== null) {
                // if(this.state.monetizableProjectSelectedOption.label === "No"){
                //     isValid = false;
                //     Toast.fail("Since the project has been marked as monetized, MBB has to select Yes for Monetizable Project Review", 3000);
                //     styleStateObj.monetizableProjectSelectedOption = selectStyle(false);
                //     this.setState({selectStyle: styleStateObj})
                // }
                if (this.state.mbbReviewMontization.label === "No" && this.state.approvalSelectedOption && this.state.approvalSelectedOption.label === "Approved") {
                    isValid = false;
                    Toast.fail("Since the project has been marked as monetized, MBB has to select Yes for Monetizable Project Review", 3000);
                    styleStateObj.mbbApprovalStyle = selectStyle(false);
                    this.setState({ selectStyle: styleStateObj })
                }
            }
        }


        return isValid;
    }

    cancelEvent = () => {
        // this.props.history.push("/review-page?page=approval");
        let redirectURL = "/review-page";
        if (this.state.approvalPage) {
            // redirectURL = redirectURL + "?page=" + this.state.approvalPage;
            // this.props.history.push(redirectURL);
            this.props.history.push({
                pathname: redirectURL,
                search: '?page=' + this.state.approvalPage,
                propsList: this.state.propsData
            })
        }
        else {
            // this.props.history.push(redirectURL);
            this.props.history.push({
                pathname: redirectURL,
                propsList: this.state.propsData
            })
        }
    }

    handleFinalCommentChange = (event) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.comment = textBoxValidation(true);
        this.setState({
            finalComment: event.target.value,
            selectStyle: styleStateObj
        });

    }
    handleTranscriptMBBComment = (e) => {
        this.setState({
            finalComment: e
        });
    }

    formatDateStringWithTime(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        });
    }

    toggleTranscriptFunction = (id) => {
        this.setState({ speechModal: !this.state.speechModal, modalIndex: id })
        if (id === 8) {
            if (this.state.finalComment) {
                this.setState({ previousTextForSpeech: this.state.finalComment }, () => console.log(this.state.previousTextForSpeech))
            } else {
                this.setState({ previousTextForSpeech: "" }, () => console.log(this.state.previousTextForSpeech))
            }
        }
    }

    closeModalFunction = () => {
        this.setState({ speechModal: false })
    }
    renderMonetizableOptions = () => {
        let options = [];
        this.state.mbbMonetizableOptions.map((item) => {
            options.push({
                value: item.value,
                label: item.label
            })
            return options;
        });
        return options;
    }
    handleMonetizableMBBApproval = (e) => {
        this.setState({approvalSelectedOption:[]},()=>{})
        let styleStateObj = this.state.selectStyle;
        styleStateObj.mbbApprovalStyle = selectStyle(true);
        this.setState({ mbbReviewMontization: e, selectStyle: styleStateObj }, () => {
            //console.log("mbb montizable approval", this.state.mbbReviewMontization)
        })
    }

    newBusinessImpactSummary() {
        let myArray = [...this.state.businessImpactValueArray];
        const groupedData = myArray.reduce((accumulator, currentValue) => {
            const impactType = currentValue.impactType;
            const monetizableType = currentValue.monetizableType;
            const fteValue = parseFloat(currentValue.fteValue) || 0.0;
            const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;

            const existingRow = accumulator.find(row => row.impactType === impactType && row.monetizableType === monetizableType);
            if (existingRow) {
                existingRow.fteValue += fteValue
                existingRow.dollarValue += dollarValue
            } else {
                accumulator.push({ impactType, monetizableType, fteValue, dollarValue });
            }
            return accumulator;
        }, []);
        this.setState({ summaryArray: groupedData }, () => { });
        this.setState({ businessImpactValueArray: this.state.businessImpactValueArray }, () => { })
        this.setState({ isFpnaRequired: false }, () => { })
        this.state.businessImpactValueArray && this.state.businessImpactValueArray.map((item) => {
            if (item.ImpactType === "Impact at Genpact") {
                if (item.MonetizableType === "Increase in Revenue" || item.MonetizableType === "Reduction in Cost") {
                    this.setState({ isFpnaRequired: true }, () => { })
                }
            }
        })

    }

    pull_data = ({ businessImpactValueArray, dollarTotal, fteTotal }) => {
        if (businessImpactValueArray) {
            this.setState({ businessImpactValueArray: businessImpactValueArray }, () => {
                this.newBusinessImpactSummary()
            })

        }
        if (dollarTotal) {
            this.setState({ finalImpactDollar: dollarTotal }, () => { })
        }
        if (fteTotal) {
            this.setState({ finalImpactFTE: fteTotal }, () => { })
        }

    }
    pull_file_data = ({ fileName, fileType, fileContent, showClientApprovalFields
        , SowID, FPnAOHRMonetization, FPnANameMonetization }) => {
        if (fileName) {
            this.setState({ FPnAFileNameMonetization: fileName }, () => { })
        }
        if (fileType) {
            this.setState({ FPnAFileTypeMonetization: fileType }, () => { })
        }
        if (fileContent) {
            this.setState({ FPnAFileMonetization: fileContent }, () => { })
        }

        this.setState({ showClientApprovalFields: showClientApprovalFields }, () => { })
        this.setState({
            SowID: SowID,
            FPnAOHRMonetization: FPnAOHRMonetization,
            FPnANameMonetization: FPnANameMonetization
        }, () => { })
    }
    pull_monetizedTotal = ({ fteValue, dollarValue }) => {
        if (fteValue) {
            this.setState({ totalMonetizedDollar: fteValue }, () => { })
        }
        if (dollarValue) {
            this.setState({ totalMonetizedFTE: dollarValue }, () => { })
        }
    }
    pull_monetizableProject = (e) => {
        if (e) {
            this.setState({ monetizableProjectSelectedOption: e }, () => { })
        }
    }
    pull_monetizableStatus = (e) => {
        if (e) {
            this.setState({ monetizationStatusSelectedOption: e }, () => { })
        }
    }

    handleVDTypeChange = ({ e }) => {
        if (e) {
            this.setState({ impactOptionSelected: e }, () => { })
        }
    }
    fetchFPAOHRforIncreaseInRevenue = (e) => {
        if (e === true) {
            this.setState({ appropriateAttachment: true }, () => { })
        } else {
            this.setState({ appropriateAttachment: false }, () => { })
        }
    }
    pull_monetization_fpna_name = (localFpnaName) => {
        this.setState({ FPnANameMonetization: localFpnaName }, () => {
            //console.log("fpna name from pull_file_function",this.state.FPnANameMonetization)
        })
    }

    pull_monetization_fpna_ohr = (localFpnaohr) => {
        //console.log("fpna ohr from pull function => ", localFpnaohr)
        this.setState({ FPnAOHRMonetization: localFpnaohr }, () => { })
    }
    pullMonetizationGOLName = (localGOLName) => {
        this.setState({ golMonetizationName: localGOLName }, () => {
            //console.log(this.state.golMonetizationName)
        })
    }
    pullMonetizationGOLOhr = (localGOLOhr) => {
        this.setState({ golMonetizationOhr: localGOLOhr }, () => {
            //console.log(this.state.golMonetizationOhr)
        })
    }
    pull_monetization_sowId = (localSowId) => {
        this.setState({ SowID: localSowId }, () => { console.log("sow id from pull_monetization_sowId function =>", this.state.SowID) })
    }
    remove_bi_data_after_delete = (filteredIdeaArray) => {
        //console.log("bi recieved in pull data for delete function", filteredIdeaArray)
        if (filteredIdeaArray) {
            this.setState({ businessImpactValueArray: filteredIdeaArray }, () => {
                this.newBusinessImpactSummary()
                let dollarTotal = 0.0;
                let fteValue = 0.0;
                this.state.businessImpactValueArray.map((item) => {
                    if (item && item.DollarImpact) {
                        dollarTotal += parseFloat(item.DollarImpact);
                    }
                    if (item && item.dollarValue) {
                        dollarTotal += parseFloat(item.dollarValue);
                    }
                    if (item && item.FTEImpact) {
                        fteValue += parseFloat(item.FTEImpact)
                    }
                    if (item && item.fteValue) {
                        fteValue += parseFloat(item.fteValue)
                    }
                })
                this.setState({ finalImpactDollar: dollarTotal }, () => {
                    //console.log("inside call back function $ total", this.state.finalImpactDollar)
                })
                this.setState({ finalImpactFTE: fteValue }, () => {
                    //console.log("inside call back function fte value", this.state.finalImpactFTE)
                })

            })
        }

    }

    // function for FPNAGOL component starts here

    // ppt deck file details
    pullPPTDeck = ({ fileName, fileType, fileContent }) => {
        this.setState({ filename1: fileName, fileType1: fileType, base64File1: fileContent }, () => {
            //console.log("filename ",this.state.filename1, "file type ",this.state.fileType1, "content ",this.state.base64File1)
        })
    }
    pullClientDocName = (fileName) => {
        this.setState({ signOffFilename: fileName, }, () => {
            //console.log("client approval name =>",this.state.signOffFilename)
        })
    }
    pullClientDocType = (fileType) => {
        if (fileType) {
            this.setState({ signOffFileType: fileType }, () => {
                //console.log("client approval type =>",this.state.signOffFileType)
            })
        }
    }
    pullClientDocContent = (fileContent) => {
        if (fileContent) {
            this.setState({ signOffContent: fileContent }, () => {
                //console.log("client approval contnet =>",this.state.signOffContent)
            })
        }
    }

    // gol file details
    pullgolFileName = (fileName) => {
        this.setState({ filename3: fileName }, () => {
            //console.log("gol approval doc name",this.state.filename3)      
        })
    }
    pullgolFileType = (fileType) => {
        this.setState({ fileType3: fileType }, () => {
            //console.log("gol approval doc type",this.state.fileType3)      
        })
    }
    pullgolContent = (fileContent) => {
        this.setState({ base64File3: fileContent }, () => {
            //console.log("gol approval doc content",this.state.base64File3)      
        })
    }

    // fpna file details
    pullfpnafileName = (fileName) => {
        if (fileName) {
            this.setState({ filename4: fileName }, () => {
                //console.log("fpna doc name",this.state.filename4)
            })
        }
    }
    pullfpnafileType = (fileType) => {
        if (fileType) {
            this.setState({ fileType4: fileType }, () => {
                //console.log("fpna doc type",this.state.fileType4)
            })
        }
    }
    pullfpnafileContent = (fileContent) => {
        if (fileContent) {
            this.setState({ base64File4: fileContent }, () => {
                //console.log("fpna doc file content",this.state.base64File4)
            })
        }
    }

    pullClientName = (e) => {
        if (e) {
            this.setState({ clientName: e }, () => {
                //console.log(this.state.clientName)
            })
        }
    }
    pullClientRole = (e) => {
        if (e) {
            this.setState({ clientRole: e }, () => {
                //console.log(this.state.clientRole)
            })
        }
    }
    pullClientSignOff = (e) => {
        if (e) {
            this.setState({ clientSignOffDate: e }, () => {
                //console.log(this.state.clientSignOffDate)
            })
        }
    }
    pullFpnaName = (name) => {
        if (name) {
            this.setState({ fpnaName: name }, () => {
                //console.log(this.state.fpnaName)
            })
        }

    }
    pullGolName = (name) => {
        if (name) {
            this.setState({ golFullname: name }, () => {
                //console.log(this.state.golFullname)
            })
        }

    }
    pullFpnaOhr = (e) => {
        if (e) {
            this.setState({ fpnaOhr: e }, () => {
                //console.log(this.state.fpnaOhr)
            })
        }
    }
    pullGolOhr = (e) => {
        if (e) {
            this.setState({ golOhr: e }, () => {
                //console.log(this.state.golOhr)
            })
        }
    }
    pull_fileDetails = (obj) => {
        this.setState({ golFpnaFileDetails: obj }, () => {
            //console.log(this.state.golFpnaFileDetails)
        })
    }

    // function for FPNAGOL component ends here
    fetchClientTestimonyContent = () => {
        return new Promise((resolve, reject) => {
          const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization":  "Bearer " + this.accessToken,
            },
          };
          let clientTestimonyFileType = this.state.ideaItem.CTestimonyFileType;
          fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&clientTestimonycontent=true`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              const clientTestimonyFileMetadata = data.fpnaContent ? data.fpnaContent : "";
              const clientTestimonyFileContent = "data:" + clientTestimonyFileType + ";base64," + clientTestimonyFileMetadata;
              this.setState(
                {
                  CTestimony: clientTestimonyFileContent,
                },
                () => {
                  resolve();
                  Toast.hide();
                }
              );
            })
            .catch((error) => {
              Toast.fail(
                "Error while Client Testimony file content. Please try again!",
                3000,
                () => { }
              );
              resolve();
            });
        });
      };
      downloadClientTestimony = () => {
        Toast.loading("Fetching Client Testimony content...", () => { });
        this.fetchClientTestimonyContent().then(() => {
          var fileData = this.state.CTestimony;
          var fileName = this.state.ideaItem.CTestimonyFileName;
          var w = window.open("");
          const downloadLink = w.document.createElement("a");
          downloadLink.href = fileData;
          downloadLink.download = fileName;
          downloadLink.click();
          Toast.hide();
        });
    };


    handleMBBVDApproval=(e)=>{
        this.setState({approvalSelectedOption:[], mbbReviewMontization:[],finalComment:""},()=>{})
        let styleStateObj = this.state.selectStyle;
        styleStateObj.mbbVDApproval = selectStyle(true);
        this.setState({mbbVDApproval:e, selectStyle: styleStateObj }, () => {})
    }


    render() {
        let IDEA_APPROVED = (this.state.ideaItem.WorkflowStatus === "Approve");
        let IDEA_REJECTED = (this.state.ideaItem.WorkflowStatus === "Reject");
        let IDEA_SEND_BACK = (this.state.ideaItem.WorkflowStatus === "Send Back");

        let IsSolnFeasibleValue = "";
        if (this.state.ideasAI.IsSolnFeasible === false) {
            IsSolnFeasibleValue = "No"
        }
        if (this.state.ideasAI.IsSolnFeasible === true) {
            IsSolnFeasibleValue = "Yes"
        }


        // let redirectURL = "/review-page";
        // if( this.state.approvalPage) {
        //     redirectURL = redirectURL + "?page=approval";
        // }

        return (
            <section className="u-clearfix u-section-1" id="sec-0f7f">
                <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                    <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                        <div className="u-container-layout u-container-layout-1">
                            <h4 style={{ textAlign: 'center', color: '#00AECF', fontFamily: 'Arial', fontSize: 22 }}>MBB Review idea : {this.state.ideaItem.IdeaIDAlpha ? this.state.ideaItem.IdeaIDAlpha : this.state.ideaItem.IdeaId} </h4>
                            <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                                <div className="u-container-layout u-container-layout-2" style={{ textAlign: 'center' }}>
                                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-3">
                                        <div className="u-container-layout u-container-layout-3">
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: 15 }}>Owner details </p>
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner OHR</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerOHR} /></div>
                                            </div>
                                            <div style={{ marginBottom: 15 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner Name</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerName} /></div>
                                            </div>
                                            <div style={{ marginBottom: -10 }} />
                                            <p className="u-align-left u-text u-text-7 u-text-custom-color-3" style={{ fontSize: 11, marginBottom: 10 }}>(If Idea was raised for someone else, their OHR ID is below)</p>
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Request For OHR</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.CreatorOHR} onChange={this.handleCreatorOHRChange} /></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Name</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.CreatorName} onChange={this.handleCreatorNameChange} /></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Project ID</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.LeanID} /></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                        </div>
                                    </div>

                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 235 }}>&nbsp;</p>

                                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-4" style={{ marginTop: "-305px" }}>
                                        <div className="" style={{ display: "flex", marginTop: '30px' }}>
                                            <div className="u-container-style u-group u-shape-rectangle u-group-5">
                                                <div className="u-container-layout u-container-layout-5">
                                                    <p className="u-align-left u-text u-text-8" style={{ marginBottom: 15 }}>Account Details</p>
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Account&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled list="accounts" name="accounts" placeholder="Choose Account" value={this.state.ideaItem.AccountManual} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Holding&nbsp;Account</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled type="text" value={this.state.ideaItem.HoldingAccount} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Vertical</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="vertical" placeholder="Choose Vertical" value={this.state.ideaItem.VerticalManual} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Industry&nbsp;Vertical</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="subverticallist" placeholder="Choose Industry&nbsp;Vertical" value={this.state.ideaItem.SubVerticalName} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />

                                                    {/* units and segments starts here */}
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Segment</label>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="segment" value={this.state.ideaItem.Segment ? this.state.ideaItem.Segment : ""} style={{ fontWeight: 'normal !important' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Unit</label>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="unit" value={this.state.ideaItem.Unit ? this.state.ideaItem.Unit : ""} style={{ fontWeight: 'normal !important' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    {/* units and segments ends here */}


                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Campaign&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="ideationtype" placeholder="Choose Idea Type" value={this.state.ideaItem.CampaignName} />
                                                        </div>
                                                        <div className="">
                                                            <div className="campaign_modal_bulb campaign_tool_tip">
                                                                <img 
                                                                    src={require("../assets/images/question_mark_2.png")}
                                                                    alt=""
                                                                    className=""
                                                                />
                                                                {
                                                                    this.state.campaignInsight
                                                                    ?
                                                                    <>
                                                                        <span className="my_tooltiptext">{this.state.campaignInsight}</span>
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />

                                                    {
                                                        this.state.ideaItem.CampaignName === "Gen AI"
                                                            ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5">
                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Gen AI Theme</label></div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Gen AI Theme" value={this.state.ideaItem.SubCampaignName} />
                                                                    </div>
                                                                </div>

                                                            </>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        this.state.displayProjectTheme && this.state.dataBridgeCampaign && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Data Bridge Project Theme</label></div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled list="databridgetype" name="databridgetype" placeholder="Choose Data Bridge Project Type" value={this.state.ideaItem.DataBridgeTheme} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 26 }} />
                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        this.state.xPollinationCampaign && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Campaign</label></div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Sub Campaign" value={this.state.ideaItem.SubCampaignName} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 26 }} />
                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        this.state.displayParentAlpha && this.state.displayParentAlpha.length > 0 && this.state.displayParentAlpha !== null && this.state.displayParentAlpha !== undefined && this.state.displayParentAlpha !== ""
                                                            ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5">
                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Parent ID</label>
                                                                    </div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled placeholder="Choose Parent ID" value={this.state.displayParentAlpha} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST
                                                            ?
                                                            <>
                                                                <div className="row" style={{ marginTop: "20px" }}>
                                                                    <div className="col-md-5">
                                                                        <label
                                                                            style={{
                                                                                verticalAlign: "bottom",
                                                                                marginBottom: -5,
                                                                            }}
                                                                        >
                                                                            <i>AI First Sample Ideas</i>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-7">
                                                                        <div className="ai_first_doc_link ai_first_doc_red_highlight">
                                                                            <a
                                                                                className="ldt-list1"
                                                                                href={AI_DOC_LINK}
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                                style={{ textAlign: "left", fontSize: 16, color: "red" }}
                                                                            >Document Link </a>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                {
                                                                    this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )
                                                                        ?
                                                                        <>
                                                                            <div style={{ marginBottom: 20 }} />
                                                                            <div className="row d_flex_align_items_center">
                                                                                <div className="col-md-5">
                                                                                    <span className="form-attribute">
                                                                                        AI Recommendation
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-7">
                                                                                    <input
                                                                                        className="form-input"
                                                                                        disabled
                                                                                        name="methodology"
                                                                                        value={this.state.ideaItem.isAI}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null

                                                                }


                                                                {
                                                                    this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )
                                                                        ?
                                                                        <>
                                                                            <div style={{ marginBottom: 20 }} />
                                                                            <div className="row">
                                                                                <div className="col-md-5">
                                                                                    <span className="form-attribute">
                                                                                        AI First BRD
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-7">
                                                                                    <div className="ai_first_doc_link">
                                                                                        <textarea className="ai_first_brd_textarea" disabled value={this.state.ideasAI.LinkBRD} ></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }

                                                            </>
                                                            :
                                                            null
                                                        }

                                                    <div style={{ marginBottom: 18 }}>&nbsp;</div>
                                                </div>
                                            </div>


                                            <div className="u-container-layout u-container-layout-6">
                                                <div className="row" style={{ marginTop: "10px" }}>
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Country</label></div>
                                                    <div className="col-md-7">
                                                        <input className="form-input" disabled name="regions" placeholder="Choose Country" value={this.state.ideaItem.Country} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Region</label></div>
                                                    <div className="col-md-7">
                                                        <input className="form-input" disabled name="regions" placeholder="Choose Region" value={this.state.ideaItem.RegionManual} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub-Region</label></div>
                                                    <div className="col-md-7">
                                                        <input className="form-input" disabled name="regions" placeholder="Choose Sub-Region" value={this.state.ideaItem.SubRegion} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Blackbelt&nbsp;Name</label></div>
                                                    <div className="col-md-7">
                                                        <input className="form-input" disabled name="blackbelt" placeholder="Choose Blackbelt" title={"OHR ID: " + this.state.ideaItem.BlackBeltUserId} value={this.state.ideaItem.BlackBeltUserName} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>MBB</label></div>
                                                    <div className="col-md-7">
                                                        <input className="form-input" disabled name="blackbelt" placeholder="Choose MBB" title={"OHR ID: " + this.state.ideaItem.MBBOHR} value={this.state.ideaItem.MBBName} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                {/* <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>LDA</label></div>
                                                    <div className="col-md-7">
                                                        <input className ="form-input" disabled name="blackbelt" placeholder="Choose LDA" title={"OHR ID: " +  this.state.ideaItem.LDAOHR} value={this.state.ideaItem.LDA} />
                                                    </div>
                                                </div> */}
                                                <div style={{ marginBottom: 15 }} />


                                                {
                                                    this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST &&
                                                    this.state.ideaItem.isAI && (this.state.ideaItem.isAI==="Yes" || this.state.ideaItem.isAI==="No")
                                                        ?
                                                        <>
                                                        <div style={{ marginBottom: 20 }} />
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                <span className="form-attribute">
                                                                    AI First Reason
                                                                </span>
                                                                </div>
                                                                <div className="col-md-12">
                                                                <textarea
                                                                    disabled
                                                                    value={this.state.ideasAI.AIReason}
                                                                    placeholder="Please provide value"
                                                                    className="form-input"
                                                                    rows={4}
                                                                    cols={80}
                                                                    style={{
                                                                    width: "100%",
                                                                    border: "1px solid lightgrey",
                                                                    resize: "none"
                                                                    }}
                                                                />
                                                                </div>
                                                            </div>
                                                        <div style={{ marginBottom: 20 }} />
                                                        </>
                                                        :
                                                        null
                                                    }


                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />

                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -30 }}>&nbsp;</p>

                                    <div id="idea1" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ marginTop: 15 }}>
                                        <div className="u-container-layout u-container-layout-7">
                                            <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                <div className="u-container-layout u-container-layout-8">
                                                    <p id="idea1title" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Idea details</p>
                                                    <div className="row">
                                                        <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Service Line</label> </div>
                                                        <div className="col-md-8">
                                                            <input className="form-input" disabled name="serviceline" placeholder="Choose Service Line" value={this.state.ideaItem.ServiceLineName} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Service Line</label> </div>
                                                        <div className="col-md-8">
                                                            <input className="form-input" disabled name="serviceline" placeholder="Choose Sub Service Line" value={this.state.ideaItem.SubServiceLine} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                        <span className="u-text-black form-attribute"> What is the Problem ?</span>&nbsp;
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <textarea className="form-input" disabled rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Problem} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                <div className="u-container-layout u-container-layout-9" style={{ marginTop: 4 }}>
                                                    <div className="row">
                                                        <div className="col-md-3"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Idea Name</label> </div>
                                                        <div className="col-md-9" style={{ alignItems: 'flex-start' }}><input className="form-input" disabled type="text" value={this.state.ideaItem.IdeaName} placeholder="Eg: Cash flow improvement through process restructuring" /></div>
                                                    </div>
                                                    <div style={{ marginBottom: 66 }} />
                                                    <p className="u-align-left u-text u-text-custom-color-4 u-text-25" style={{ width: "177px" }}>
                                                        <span className="u-text-black form-attribute"> How to resolve the problem</span>&nbsp;
                                                    </p>
                                                    <div className="row" style={{ marginBottom: 15 }}>
                                                        <div className="col-md-12">
                                                            <textarea className="form-input" disabled rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Solution} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 15 }}>
                                                <div className="col-md-5"><span className="form-attribute">Are you trying to replicate an existing Idea?</span>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="switch">
                                                        <input disabled type="checkbox" checked={this.state.ideaItem.NewOrReplicatedIdea === null ? false : this.state.ideaItem.NewOrReplicatedIdea} />
                                                        <span className="slider round" />
                                                    </label>
                                                </div>
                                            </div>

                                            {
                                                this.state.ideaItem.NewOrReplicatedIdea && (
                                                    <>
                                                        <div className="col-md-8" style={{ marginBottom: 15, width: "100%" }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><span className="form-attribute">Provide the ID of the executed Idea which you want to replicate?</span>
                                                            </div>
                                                            <div className="col-md-2"><input className="form-input" disabled type="text" value={this.state.ideaItem.ReplicationProjectId} /></div>
                                                        </div>
                                                    </>
                                                )
                                            }

                                            <div className="col-md-8" style={{ marginBottom: 15 }} />
                                        </div>
                                    </div>

                                    {
                                        (this.state.ideaItem.WorkflowStatus && this.state.ideaItem.WorkflowStatus !== "Pending") && (
                                            <>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />
                                                <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                    <div className="u-container-layout u-container-layout-7">
                                                        <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Idea Approval details</p>
                                                        <div className="row">
                                                            <div className="col-md-2"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Approval Status</label></div>
                                                            <div className="col-md-2">
                                                                <input className="form-input" disabled name="Status" value={IDEA_APPROVED ? APPROVED : (IDEA_REJECTED ? REJECTED : (IDEA_SEND_BACK ? SENT_BACK : PENDING))} />
                                                            </div>
                                                            {/* <div className="col-md-3"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Expected timeline for execution</label></div>
                                                        <div className="col-md-2">
                                                            <input disabled name="ExpectedDate" value={this.state.ideaItem.ExpectedFinishDate ? this.formatDateString(new Date(this.state.ideaItem.ExpectedFinishDate)): ""} />
                                                        </div> */}
                                                        </div>
                                                        <div className="row" style={{ marginTop: 15 }}>
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Comments</label></div>
                                                            <div className="col-md-2" style={{ marginLeft: 20 }}>
                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ commentLog: !this.state.commentLog })}>Comment Log</button>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: 5 }}>
                                                            <div className="col-md-12">
                                                                <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.Comment} />
                                                            </div>
                                                        </div>
                                                        <div className="text-left mt-4">
                                                            {
                                                                this.state.commentLog && this.state.reviewComments
                                                                    ?
                                                                    <>
                                                                        <div className="custom_modal">
                                                                            <div className="modal_body">
                                                                                <div className="row">
                                                                                    <div className="col-md-8">
                                                                                        <h3 className="modal_title">Idea Approval Comment Log</h3>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ commentLog: false })}>&times;</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                    </div>
                                                                                    <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                    </div>
                                                                                    <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    this.state.reviewComments.map((item, index) => {

                                                                                        return (
                                                                                            <div className="row" key={index}>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                </div>
                                                                                                <div className="col-md-1 text-center role_over_flow_class" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === 'Delegate Action' ? 'BB' : (item.UpdaterOHR === this.state.ideaItem.BlackBeltUserId ? "BB" : (item.UpdaterOHR === this.state.ideaItem.MBBOHR ? "MBB" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Owner" : item.UpdaterRole))))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? (item.WorkflowStatus !== 'Pending' ? item.WorkflowStatus : 'Idea Updated') : (item.Action === 'Delegate Action' ? 'Delegated' : (item.WorkflowStatus === 'Pending' ? 'Reject Cancellation' : item.WorkflowStatus)))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment ? item.Comment : '-'}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        {/* <div className="col-md-9" style={{ marginBottom: 5, width: '100%' }} />
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                                <span className="u-text-black form-attribute">Comments</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.Comment} />
                                                        </div>
                                                    </div> */}
                                                    </div>
                                                </div>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15, marginTop: 15 }} />

                                                {
                                                    (this.state.ideaItem.WorkflowStatus && (this.state.ideaItem.WorkflowStatus === "Approve" || this.state.ideaItem.WorkflowStatus === "Approved")) && (
                                                        <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                            <div className="u-container-layout u-container-layout-7" style={{display:"flex", alignItems:"flex-start", padding: "30px", marginTop: "20px"}}>
                                                                <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                                    <div className="u-container-layout ">
                                                                        <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: "30px" }}>Additional details</p>
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-6"><span className="form-attribute">LDT Lead Name</span></div>
                                                                            <div className="col-md-6">
                                                                                <input className="form-input" disabled list="ldtName" name="ldtName" placeholder="Choose LDT Lead" title={"OHR ID: " + this.state.ideaItem.LDTId} value={this.state.ideaItem.LDTLeadName} />
                                                                            </div>
                                                                        </div>
                                                                        {/* <div style={{ marginBottom: 15 }} />
                                                                        <div className="row">
                                                                            <div className="col-md-6"><span className="form-attribute">Potential Impact (# of FTE)</span></div>
                                                                            <div className="col-md-6">
                                                                                <input className="form-input" disabled type="number" value={this.state.ideaItem.PotentialImpactFTE} placeholder={0} max={10} />
                                                                            </div>
                                                                        </div>
                                                                <div style={{ marginBottom: 15 }} />
                                                                        <div className="row">
                                                                            <div className="col-md-6"><span className="form-attribute">Potential Impact ($)</span></div>
                                                                            <div className="col-md-6">
                                                                                <input className="form-input" disabled type="number" value={this.state.ideaItem.PotentialImpactDollar} placeholder={0.0} />
                                                                            </div>
                                                                        </div>
                                                                <div style={{ marginBottom: 5 }} />
                                                                <div className="u-align-left u-text u-text-custom-color-3" style={{ fontSize: 11, fontStyle: 'italic' }}>(If Potential Impact $ is greater than 250K OR FTE number is greater than 10, please review thoroughly)
                                                                </div> */}
                                                                        <div style={{ marginBottom: 15 }} />


                                                                        {
                                                                            this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST
                                                                            && this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )    
                                                                            ?
                                                                                <>
                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-6">
                                                                                            <span className="form-attribute">
                                                                                                Is the solution feasible ?
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <input
                                                                                                className="form-input"
                                                                                                disabled
                                                                                                value={IsSolnFeasibleValue}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-6">
                                                                                            <span className="form-attribute">
                                                                                                Solution Complexity
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <input
                                                                                                className="form-input"
                                                                                                disabled
                                                                                                name="methodology"
                                                                                                value={
                                                                                                    this.state.ideasAI.SolnComplexity
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-6">
                                                                                            <span className="form-attribute">
                                                                                                Is the Idea replicable
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <input
                                                                                                className="form-input"
                                                                                                disabled
                                                                                                value={this.state.isIdeaReplicable}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                </>
                                                                                :
                                                                                null
                                                                        }



                                                                        {
                                                                            this.state.ideaItem.NewOrReplicatedIdea && (
                                                                                <div className="row">
                                                                                    <div className="col-md-10">
                                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}><span style={{ color: 'red' }}>*</span> Have you verified the replication id and confirm this to be replication project?</label>
                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        <label className="switch">
                                                                                            <input disabled type="checkbox" checked={this.state.ideaItem.IsReplicationVerified} />
                                                                                            <span className="slider round" />
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>

                                                                

                                                                <div className="u-container-style u-group u-shape-rectangle">
                                                                    <div className="u-container-layout u-container-layout-9" style={{paddingTop: "15px"}}>
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5"><span className="form-attribute">Certification Level</span> </div>
                                                                            <div className="col-md-7">
                                                                                <input className="form-input" disabled name="certlevel" value={this.state.ideaItem.CertificationLevelName} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 15 }} />
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5"><span className="form-attribute">Transformation Lever</span> </div>
                                                                            <div className="col-md-7">
                                                                                <input className="form-input" disabled name="translever" value={this.state.ideaItem.TransformationLeverName} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 15 }} />
                                                                        {
                                                                            this.state.ideaItem.TransformationLeverName === "Digital" && (
                                                                                <>
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5"><span className="form-attribute">Digital Project Type</span> </div>
                                                                                        <div className="col-md-7">
                                                                                            <input className="form-input" disabled name="translever" value={this.state.ideaItem.DigitalProjectType} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            this.state.ideaItem.CertificationWorkTypeName !== null && this.state.ideaItem.CertificationWorkTypeName !== ""
                                                                                ?
                                                                                <>
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5"><span className="form-attribute">Work Type</span> </div>
                                                                                        <div className="col-md-7">
                                                                                            <input className="form-input" disabled name="worktype"
                                                                                                value={this.state.ideaItem.CertificationWorkTypeName} />
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                null
                                                                        }
                                                                        <div style={{ marginBottom: 15 }} />

                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5"><span className="form-attribute">Tools/Methodology Used</span></div>
                                                                            <div className="col-md-7">
                                                                                <input className="form-input" disabled name="methodology" value={this.state.ideaItem.MethodologyName} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 20 }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }

                                    {/* Final Approval Section  */}
                                    {
                                        (this.state.ideaItem.ApprovalStatus
                                            && this.state.ideaItem.ApprovalStatus !== "NA"
                                            && this.state.ideaItem.ApprovalStatus !== "Pending Documents"
                                            && this.state.ideaItem.ApprovalStatus !== "Documents Uploaded"
                                        )
                                        && (
                                            <>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 10 }} />

                                                <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ borderBottom: "1px solid lightgray", marginBottom: "10px" }}>
                                                    <div className="u-container-layout u-container-layout-7">
                                                        <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Lean Approval details</p>
                                                        <div className="row">
                                                            <div className="col-md-2"><span className="form-attribute">Approval Status</span> </div>
                                                            <div className="col-md-2">
                                                                <input className="form-input" disabled name="finalapprovalstatus" value={this.state.ideaItem.ApprovalStatus} />
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: 15 }}>
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Approval Comments</label></div>
                                                            <div className="col-md-2" style={{ marginLeft: 20 }}>
                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ finalCommentLog: !this.state.finalCommentLog })}>Comment Log</button>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: 5 }}>
                                                            <div className="col-md-12">
                                                                <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.FinalComments} />
                                                            </div>
                                                        </div>
                                                        <div className="text-left mt-4">
                                                            {
                                                                this.state.finalCommentLog && this.state.finalReviewComments
                                                                    ?
                                                                    <>
                                                                        <div className="custom_modal">
                                                                            <div className="modal_body">
                                                                                <div className="row">
                                                                                    <div className="col-md-8">
                                                                                        <h3 className="modal_title">Lean Approval Comment Log</h3>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ finalCommentLog: false })}>&times;</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                    </div>
                                                                                    <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                    </div>
                                                                                    <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    this.state.finalReviewComments.map((item, index) => {

                                                                                        return (
                                                                                            <div className="row" key={index}>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                </div>
                                                                                                <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === 'Idea was reviewed by MBB' ? "MBB" : (item.Action === 'Idea was reviewed by LDT' ? "LDT" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Owner" : "BB")))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? item.ApprovalStatus : (item.WorkflowStatus === 'Completed' || item.WorkflowStatus === 'Approve' || item.WorkflowStatus === 'Approved' || item.ApprovalStatus === 'Awaiting Quality Check Review') ? 'Approved' : ((item.WorkflowStatus === 'Rejected' || item.WorkflowStatus === 'Reject') ? "Rejected" : (item.WorkflowStatus === REQUEST_CANCELLATION ? REQUEST_CANCELLATION : (item.WorkflowStatus === 'Pending Documents' ? 'Reject Cancellation' : item.WorkflowStatus === 'Cancelled' ? 'Cancelled' : "Sent Back"))))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment ? item.Comment : '-'}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        {/* <div className="col-md-8" style={{ marginBottom: 5, width: "100%" }} />
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                                    <span className="u-text-black form-attribute"> Approval Comments</span>&nbsp;
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.FinalComments} />
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                {
                                                    (this.state.ideaItem.ApprovalStatus && (this.state.ideaItem.ApprovalStatus === "Approved" || this.state.ideaItem.ApprovalStatus === AWAITING_MBB_APPROVAL || this.state.ideaItem.ApprovalStatus === AWAITING_QAULITY_CHECK_REVIEW)) && (
                                                        <>

                                                            {/* ....latest changes to GOL starts here.... */}





                                                            {/* ....latest changes to GOL ends here.... */}
                                                        </>
                                                    )
                                                }
                                            </>
                                        )
                                    }

                                    {
                                        this.state.ideaItem.CertificationLevelName === "Lean"
                                            ?
                                            <>
                                                <FPNAGOL IdeaId={this.state.ideaItem.IdeaId} viewIdea={true}
                                                    vdSelected={this.state.ideaItem.VDType}
                                                    fetchPPTDeck={this.pullPPTDeck}
                                                    fetchClientDocName={this.pullClientDocName}
                                                    fetchClientDocType={this.pullClientDocType}
                                                    fetchClientDocContent={this.pullClientDocContent}
                                                    fetchGolDocName={this.pullgolFileName}
                                                    fetchGolDocType={this.pullgolFileType}
                                                    fetchGolDocContent={this.pullgolContent}
                                                    fetchfpnaDocName={this.pullfpnafileName}
                                                    fetchfpnaDocType={this.pullfpnafileType}
                                                    fetchfpnaDocContent={this.pullfpnafileContent}
                                                    fetchClientName={this.pullClientName}
                                                    fetchClientRole={this.pullClientRole}
                                                    fetchClientSignOffDate={this.pullClientSignOff}
                                                    fetchFpnaName={this.pullFpnaName}
                                                    fetchGolName={this.pullGolName}
                                                    fetchFpnaOhr={this.pullFpnaOhr}
                                                    fetchGolOhr={this.pullGolOhr}
                                                    fetchFileDetails={this.pull_fileDetails}
                                                    sendBitoFPNA={this.state.businessImpactValueArray}
                                                    sendIsFpnaRequired={this.state.isFpnaRequired}
                                                />
                                            </>

                                            :
                                            null
                                    }




                                    <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                        <div className="u-container-layout u-container-layout-7" style={{ padding: "13px 0px 20px 0px", borderBottom: "1px solid lightgrey", marginBottom: "30px" }}>
                                            <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: "20px" }}>Final additional details</p>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="row d_flex_align_items_center">
                                                        <div className="col-md-6">
                                                            <span className="form-attribute">Best in Class</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="switch">
                                                                <input disabled type="checkbox"
                                                                    checked={this.state.ideaItem.IsBestInClass === null ? false : this.state.ideaItem.IsBestInClass} />
                                                                <span className="slider round" />
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-5">
                                                    {
                                                        (this.state.ideaItem.ReplicabilityIndex !== null) && (
                                                            <div className="row d_flex_align_items_center">
                                                                <div className="col-md-6">
                                                                    <span className="form-attribute">Replicability Index</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <input className="form-input" disabled
                                                                        value={this.state.replicabilityIndex && this.state.replicabilityIndex.label}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {
                                        this.state.ideaItem.IdeaId && this.state.ideaItem.VDType
                                            ?
                                            <>
                                                <ValueDelivery func={this.pull_data} viewIdea={true}
                                                    vdType={this.handleVDTypeChange}
                                                    IdeaId={this.state.ideaItem.IdeaId}
                                                    fetchMonetizationFileDetails={this.pull_file_data}
                                                    bbMonetizationTotal={this.pull_monetizedTotal}
                                                    monetizableProject={this.pull_monetizableProject}
                                                    monetizableStatus={this.pull_monetizableStatus}
                                                    fetchFPAOHRforIncreaseInRevenue={this.fetchFPAOHRforIncreaseInRevenue}
                                                    bbMonetizationApproval={false}
                                                    postFpnaValid={false}
                                                    fetchMonetizationFpnaOhr={this.pull_monetization_fpna_ohr}
                                                    fetchMonetizationFpnaName={this.pull_monetization_fpna_name}
                                                    fetchMonetizationGOLName={this.pullMonetizationGOLName}
                                                    fetchMonetizationGOLOhr={this.pullMonetizationGOLOhr}
                                                    fetchMonetizationSowID={this.pull_monetization_sowId}
                                                    fetchDeletedBIRows={this.remove_bi_data_after_delete}
                                                />
                                            </>
                                            :
                                            null
                                    }



                                    {/* ............db fields starts here........... */}
                                    {
                                            this.state.ideaItem.TransformationLeverName && this.state.ideaItem.TransformationLeverName === "Data" && this.state.displayDBFields
                                                ?
                                                <>
                                                    <div className="">
                                                        <div className="" style={{borderTop:"1px solid lightgrey"}}>
                                                            <p className="u-align-left u-text u-text-2" style={{ margin: "15px 0px 0px 0px" }}>Data Bridge Fields</p>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    DB Project Type</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideaItem.DBProjectType} disabled className="form-input  disabled_input" placeholder="Provide Value" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        this.state.ideaItem.DBProjectType
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields" style={{}}>
                                                                                    <div className="row">
                                                                                        <div className="col-md-5">
                                                                                            <span className="form-attribute">
                                                                                                Template Type
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <div className="ai_first_doc_link">
                                                                                                <input data-toggle="tooltip" title={this.state.ideaItem.MUCIDTitle} className="form-input disabled_input" disabled value={this.state.ideaItem.DBProjectType} ></input>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }

                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    COO</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideaItem.COO} type="text" placeholder="Provide Value" className="form-input  disabled_input"
                                                                                    disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    MUC ID</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input data-toggle="tooltip" title={this.state.ideaItem.MUCIDTitle ? this.state.ideaItem.MUCIDTitle :""} value={this.state.ideaItem.MUCIDTitle} placeholder="Please choose" disabled className="form-input  disabled_input" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    Shown to Client</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideasDB.IsSharedClient} placeholder="Please choose" disabled className="form-input disabled_input" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.ideasDB.IsSharedClient && this.state.ideasDB.IsSharedClient === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="">
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className="submit_page_new_fields">
                                                                                                <div className="row d_flex_align_items_center">
                                                                                                    <div className="col-md-5">
                                                                                                        <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                            Client Testimony</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-7">
                                                                                                        <button className={this.state.ideaItem.CTestimonyFileName.length > 50?"upload_file_text_overflow":""} style={{ backgroundColor: 'transparent', textAlign: "left", marginTop: "15px", color: '#337ab7', padding: 0 }} onClick={(e) => this.downloadClientTestimony()}>{this.state.ideaItem.CTestimonyFileName}</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-2"></div>
                                                                                        <div className="col-md-5">

                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    Shown to GOL</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideasDB.IsSharedGOL} placeholder="Provide value" className="form-input disabled_input" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    DB Tool Used</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideasDB.DBTool} className="form-input disabled_input" disabled
                                                                                    placeholder="Provide value" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="col-md-4">

                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{
                                                                                    verticalAlign: 'bottom', marginBottom: -5
                                                                                }}>
                                                                                    Process Owner OHR</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input type="text"
                                                                                    disabled
                                                                                    placeholder="Provide Value"
                                                                                    value={this.state.ideaItem.ProcessOwnerOHR}
                                                                                    className="form-input form-attribute" />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{
                                                                                    verticalAlign: 'bottom', marginBottom: -5
                                                                                }}>
                                                                                    Process Owner Name</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideaItem.ProcessOwner} type="text" placeholder="Provide Value" className="form-input disabled_input"
                                                                                    disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    {
                                                                        this.state.ideasDB.IsSharedGOL && this.state.ideasDB.IsSharedGOL === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                GOL OHR</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input value={this.state.ideasDB.GOLOHR} type="text" placeholder="Provide value" className="form-input form-attribute"
                                                                                                disabled />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                GOL Name</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input type="text" placeholder="Provide value" disabled value={this.state.ideasDB.GOL}
                                                                                                className="form-input disabled_input" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        this.state.ideasDB.IsSharedClient && this.state.ideasDB.IsSharedClient === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                Client Role</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input value={this.state.ideasDB.ClientRole} type="text" placeholder="Provide value" className="form-input disabled_input"
                                                                                                disabled />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                Client Name</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input value={this.state.ideasDB.ClientName} type="text" disabled className="form-input  disabled_input" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        this.state.ideasDB.DBTool && this.state.ideasDB.DBTool === "Others"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                Other DB Tool Used</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input data-toggle="tooltip" title={this.state.ideasDB.OtherDBTool} value={this.state.ideasDB.OtherDBTool} type="text" placeholder="Provide value" disabled
                                                                                                className="form-input disabled_input" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }


                                                                </div>
                                                                <div className="col-md-4" style={{ paddingRight: "30px" }}>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-8">
                                                                                <label
                                                                                    className="form-attribute"
                                                                                    style={{
                                                                                        verticalAlign: "bottom",
                                                                                        marginBottom: -5,
                                                                                    }}
                                                                                >
                                                                                    Is Sustenance tracking applicable ? </label>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <input
                                                                                    value={this.state.ideasDB.IsSustenance}
                                                                                    placeholder="Provide value"
                                                                                    className="form-input"
                                                                                    disabled

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-12">
                                                                                <label
                                                                                    className="form-attribute"
                                                                                    style={{
                                                                                        verticalAlign: "bottom",
                                                                                        marginBottom: "0px",
                                                                                    }}
                                                                                >
                                                                                    How is the model operationalized ?
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <input
                                                                                    value={
                                                                                        this.state.ideasDB.ModelSummary
                                                                                    }
                                                                                    placeholder="Please choose"
                                                                                    disabled
                                                                                    className="form-input"

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.ideasDB.IsSustenance && this.state.ideasDB.IsSustenance === "Yes" ? (
                                                                        <>
                                                                            <div className="submit_page_new_fields">
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-8">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: -5,
                                                                                            }}
                                                                                        >

                                                                                            Sustenance tracking frequency ?
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <input
                                                                                            value={this.state.ideasDB.SustenanceFreq}
                                                                                            disabled
                                                                                            placeholder="Please choose"
                                                                                            className="form-input"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="submit_page_new_fields">
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-12">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: "0px",
                                                                                            }}
                                                                                        >

                                                                                            Sustenance metric
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <textarea
                                                                                            className="form-input"
                                                                                            disabled
                                                                                            value={this.state.ideasDB.SustenanceMetric}
                                                                                            placeholder="Please provide sustenance metrics"
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                border: "1px solid lightgrey",

                                                                                            }}
                                                                                            rows={3}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </>
                                                                    ) : null}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="" style={{ borderBottom: "1px solid lightgrey", paddingBottom: "30px" }} /> */}
                                                </>
                                                :
                                                null

                                        }
                                    {/* ............db fields ends here........... */}


                                    {
                                        this.state.ideaItem.TransformationLeverName === "Data" && this.state.ideaItem.IdeaId && this.state.displayDBFields
                                            ?
                                            <>
                                                <DisplayTemplate IdeaId={this.state.ideaItem.IdeaId} templateType={this.state.ideaItem.DBProjectType} />
                                            </>
                                            :
                                            null
                                    }

                                    {
                                        this.state.ideaItem.TransformationLeverName && this.state.ideaItem.TransformationLeverName === "Data" && this.state.ideasDB.IsSustenance && this.state.ideasDB.IsSustenance === "Yes" && this.state.ideaItem.DBSPocOHR
                                        && this.state.displayDBFields
                                            ?
                                            <>
                                                <div className="" style={{borderTop:"1px solid lightgrey", paddingRight: "30px", paddingTop: "40px",marginTop:"20px" }}>
                                                    <p className="u-align-left u-text u-text-2">DB questionnaire</p>
                                                    <div className="new_fields_blocks">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Is the MUC ID tagged correctly ?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input
                                                                                value={this.state.ideasDB.IsMUCTag}
                                                                                placeholder='Provide value'
                                                                                disabled
                                                                                className="form-input disabled_input"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Is the DB theme captured correctly?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                disabled
                                                                                value={this.state.ideasDB.IsDBTheme}
                                                                                className="form-input disabled_input"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_fields_blocks">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Is the Tool used captured correctly ?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsToolCorrect}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Are the model details captured appropriately?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsModelCorrect}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_fields_blocks">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Are the model deployment details captured appropriately?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsModelDeploy}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Do the provided signoffs meet value delivery guidelines?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsSignOff}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_fields_blocks">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Are the monetization details captured appropriately?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled={true}
                                                                                value={this.state.ideasDB.IsMonetization}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Are sustenance tracking details OK ?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsSPocSustenance}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_fields_blocks">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Is there any other issue you would like to report ?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsIssue}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                Is there any exception applied on the project approval ?</label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <input placeholder='Provide value'
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                                value={this.state.ideasDB.IsException}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="" style={{ paddingBottom: "40px", marginBottom: "15px" }} />
                                                </div >
                                            </>
                                            :
                                            null
                                    }
                                    {/* DB SPOC Fields ends here */}

                                    <div className=" text-left" style={{ borderTop: "1px solid lightgrey", padding: "15px 0px 30px 0px", marginTop: "11px" }}>
                                        <p className="u-align-left u-text u-text-2" style={{ margin: "10px 0px 20px" }}>Value Delivery Approval </p>
                                        <div className="row">
                                            <div className="col-md-10">
                                                <div className="row">
                                                    <div className="col-md-10 gol_field_div text-left">
                                                        <span className="form-attribute"><span style={{ color: "red" }}>*</span> I acknowledge that I have reviewed the value delivery and I agree with the value apportioning between Client/Genpact, categorization and computation along with the necessary approvals recorded on this project ?</span>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <Select styles={this.state.selectStyle.mbbVDApproval} options={YESORNO} onChange={(e) => this.handleMBBVDApproval(e)} value={this.state.mbbVDApproval} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            this.state.ideaItem.IsVDMonetizable && this.state.ideaItem.IsVDMonetizable === "No"
                                            ?
                                            <>
                                                <div className="" style={{borderBottom:"1px solid lightgrey",paddingTop:"30px"}}></div>
                                            </>
                                            :
                                            null
                                        }
                                    </div>


                                    {/* ....monetization latest code starts here...... */}
                                    {
                                        this.state.ideaItem.IsVDMonetizable === "Yes"
                                            ?
                                            <>
                                                <div className="gol_fields_block text-left" style={{ borderTop: "1px solid lightgrey", padding: "15px 0px 30px 0px", marginTop: "11px" }}>
                                                    <p className="u-align-left u-text u-text-2" style={{ margin: "10px 0px 20px" }}>Monetizable Project Review?</p>
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <div className="row">
                                                                <div className="col-md-6 gol_field_div text-left">
                                                                    <span className="form-attribute"><span style={{ color: "red" }}>*</span> This is a Monetizable Project, do you agree ?</span>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <Select styles={this.state.selectStyle.mbbApprovalStyle} options={this.renderMonetizableOptions()} onChange={(e) => this.handleMonetizableMBBApproval(e)} value={this.state.mbbReviewMontization} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }
                                    {/* ......monetization latest code ends here...... */}

                                    <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                        <div className="u-container-layout u-container-layout-7" style={{ paddingBottom: "25px" }}>
                                            <p className="u-align-left u-text u-text-2">Approval details</p>
                                            <div className="row d_flex_align_items_center">
                                                <div className="col-md-2"><span className="form-attribute"><span style={{ color: 'red' }}>*</span> Approval Status</span> </div>
                                                <div className="col-md-2">
                                                    <Select isSearchable={false} options={this.renderStatusOptions()} value={this.state.approvalSelectedOption} placeholder="Choose Status" onChange={this.handleApprovalStatus} styles={this.state.selectStyle.approvalSelectedOption} />
                                                </div>
                                            </div>
                                            <div className="col-md-8" style={{ marginBottom: 5, width: "100%" }} />
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <div className="">
                                                        <div className="row" style={{ marginTop: "9px", display: "flex", alignItems: "center", marginBottom: '10px' }}>
                                                            <div className="col-md-11">
                                                                <p className="u-align-left" style={{ margin: "0px" }}>
                                                                    <span className="u-text-black form-attribute"><span style={{ color: 'red' }}>*</span>&nbsp;Approval Comments<span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}>&nbsp;(max. 500 chars)</span></span>
                                                                </p>
                                                            </div>
                                                            <div className="col-md-1" style={{ display: 'flex', justifyContent: "flex-end", alignItems: "center" }}>
                                                                <img onClick={(e) => this.toggleTranscriptFunction(8)} src={require("../assets/images/voice.png")} className="w100" alt="" style={{ width: "24px", cursor: "pointer" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <textarea className="textarea-css form-attribute" style={{ maxWidth: '100%', border: '1px solid lightgrey', ...this.state.selectStyle.comment }} placeholder="Please provide your comments in support of the Approval decision."
                                                        onChange={this.handleFinalCommentChange}
                                                        rows={3} cols={88}
                                                        value={this.state.finalComment}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            this.state.speechModal
                                                ?
                                                <UpdatedSpeech modalOpen={true}
                                                    onMBBApprovalComment={this.handleTranscriptMBBComment}
                                                    modalId={this.state.modalIndex}
                                                    closeModalFunction={this.closeModalFunction}
                                                    previousText={this.state.previousTextForSpeech}
                                                />
                                                :
                                                null
                                        }
                                    </div>



                                    {/* ..........qc approval details starts here........    */}
                                    {
                                        this.state.ideaItem.QCOHR !== "" && this.state.ideaItem.QCOHR !== null
                                            ?
                                            <>
                                                <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ borderTop: "1px solid lightgray" }}>
                                                    <div className="u-container-layout u-container-layout-7" style={{ margin: "10px 0px", borderBottom: "1px solid lightgray", paddingTop: "35px" }}>
                                                        <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>QC Approval details</p>
                                                        <div className="row">
                                                            <div className="col-md-2"><span className="form-attribute">Approval Status</span> </div>
                                                            <div className="col-md-3">
                                                                <input className="form-input" disabled name="finalapprovalstatus" value={this.state.ideaItem.ApprovalStatus} />
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: 15 }}>
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Approval Comments</label></div>
                                                            <div className="col-md-2" style={{ marginLeft: 20 }}>
                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ qcCommentLog: !this.state.qcCommentLog })}>Comment Log</button>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: 5 }}>
                                                            <div className="col-md-12">
                                                                <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.FinalComments} />
                                                            </div>
                                                        </div>
                                                        <div className="text-left mt-4">







                                                            {
                                                                this.state.qcCommentLog && this.state.qcComments
                                                                    ?
                                                                    <>
                                                                        <div className="custom_modal">
                                                                            <div className="modal_body">
                                                                                <div className="row">
                                                                                    <div className="col-md-8">
                                                                                        <h3 className="modal_title">QC Comment Log</h3>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ qcCommentLog: false })}>&times;</button>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="qc_responsive_comment_log">
                                                                                    <div className="table table-responsive table-bordered">
                                                                                        <thead className='text-center'>
                                                                                            <tr>
                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg" style={{ minWidth: "250px" }}>
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthOne">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Is the impact correctly tagged to Genpact or Client ?</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthTwo">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Has the Value Delivery been correctly categorized ?</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthThree">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Has the Value Delivery been computed correctly ?</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthFour">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Do the value delivery approvals (applicable as per the value playbook) clearly articulate what improved and for which time-period ?</label>
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                this.state.qcComments && this.state.qcComments.map((item, index) => {
                                                                                                    return (
                                                                                                        <tr key={index}>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === "Delegate Action" ? 'BB' : (item.UpdaterOHR === this.state.MBBOHR ? 'MBB' : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Idea Owner" : (item.ApprovalStatus === "Pending Documents" && item.WorkflowStatus === "Approved") ? "BB" : (item.ApprovalStatus === "NA" && item.WorkflowStatus === "Pending") ? "BB" : item.UpdaterRole)))}</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.ApprovalStatus === "Documents Uploaded" ? "Send Back" : (item.WorkflowStatus === "Completed" ? "Approved" : "Rejected")}</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment !== '' ? item.Comment : '-'}</label>
                                                                                                            </td>
                                                                                                            {
                                                                                                                this.state.qcCommentsWithVD && this.state.qcCommentsWithVD.length > 0
                                                                                                                    ?
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            this.state.qcCommentsWithVD.map((pg_item) => {
                                                                                                                                if (Number(pg_item.ProjectTeamID) === item.TrackId) {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <td>
                                                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>

                                                                                                                                                    {JSON.parse(pg_item.AuditDataJSON).pg_isvdimpacttagged ? JSON.parse(pg_item.AuditDataJSON).pg_isvdimpacttagged : "-"}
                                                                                                                                                </label>
                                                                                                                                            </td>
                                                                                                                                            <td>

                                                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                                                                    {JSON.parse(pg_item.AuditDataJSON).pg_isvdcategorized ? JSON.parse(pg_item.AuditDataJSON).pg_isvdcategorized : "-"}

                                                                                                                                                </label>
                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                                                                    {JSON.parse(pg_item.AuditDataJSON).pg_isvdcomputed ? JSON.parse(pg_item.AuditDataJSON).pg_isvdcomputed : "-"}
                                                                                                                                                </label>
                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                                                                    {JSON.parse(pg_item.AuditDataJSON).pg_isvdclientsignoff ? JSON.parse(pg_item.AuditDataJSON).pg_isvdclientsignoff : "-"}
                                                                                                                                                </label>
                                                                                                                                            </td>

                                                                                                                                        </>

                                                                                                                                    )
                                                                                                                                }

                                                                                                                            })
                                                                                                                        }
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                        <td>
                                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                                        </td>
                                                                                                                    </>
                                                                                                            }


                                                                                                        </tr>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </tbody>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }


                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="gol_fields_block" style={{ borderBottom: "0px" }}>
                                                    <p className="u-align-left u-text u-text-2" style={{ margin: "10px 0px 20px" }}>Quality Check Details</p>
                                                    <div className="row u-align-left">
                                                        <div className="col-md-5">
                                                            <div className="row">
                                                                <div className="col-md-9 gol_field_div">
                                                                    <span className="form-attribute">Is the impact correctly tagged to Genpact or Client ?</span>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <input className="form-input disabled_input" disabled value={this.state.ideaItem.IsVDImpactTagged} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginBottom: 15 }} />

                                                            <div className="row">
                                                                <div className="col-md-9 gol_field_div">
                                                                    <span className="form-attribute">Has the Value Delivered been correctly categorized ?</span>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <input className="form-input disabled_input" disabled value={this.state.ideaItem.IsVDCategorized} />
                                                                </div>
                                                            </div>

                                                            <div style={{ marginBottom: 15 }} />

                                                            <div className="row">
                                                                <div className="col-md-5 gol_field_div">
                                                                    <span className="form-attribute">Project Reviewed By</span>
                                                                </div>
                                                                <div className="col-md-7">
                                                                    <input className="form-input disabled_input" disabled value={this.state.ideaItem.QCName} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-5">
                                                            <div className="row">
                                                                <div className="col-md-9 gol_field_div">
                                                                    <span className="form-attribute">Has the Value Delivered been computed correctly ?</span>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <input disabled value={this.state.ideaItem.IsVDComputed} className="form-input disabled_input" type="text" />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginBottom: 15 }} />

                                                            <div className="row">
                                                                <div className="col-md-9 gol_field_div">
                                                                    <span className="form-attribute">Do the value delivery approvals (applicable as per the value playbook) clearly articulate what improved and for which time-period ?</span>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <input disabled className="form-input disabled_input" type="text" value={this.state.ideaItem.IsVDClientSignOff} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }
                                    {/* ...........qc approval details ends here........... */}


                                    







                                    <div style={{ borderBottom: '1px solid lightgrey', marginTop: 10, marginBottom: 15 }} />
                                    <div className="btn-group" style={{ marginTop: 15 }}>
                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.submitIdea}>Submit</button>
                                        <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.cancelEvent}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default MBBReviewIdeaPage
