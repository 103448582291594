import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignInWidget from './OktaSignInWidget';
import { OKTA_BASE_URL, OKTA_CLIENT_ID, USER_DATA } from '../assets/constants/constants';

function Login() {
  const { oktaAuth, authState } = useOktaAuth();

  const onSuccess = function (res) {
    console.log("Logged in successfully");
    if (res.status === 'SUCCESS') {
      return oktaAuth.signInWithRedirect({
        sessionToken: res.session.token,
        scopes: ['openid', 'profile', 'email', 'offline_access']
      });
    }
  }

  const onError = function (err) {
    console.log('error logging in', err);
  }

  const oktaBaseURL = `${OKTA_BASE_URL}`;
  const oktaClientId = `${OKTA_CLIENT_ID}`;
  const sessionData = JSON.parse(localStorage.getItem(USER_DATA));

  useEffect(() => {
    const sessionExpiredHandler = () => {
      // Redirect the user to the login page
      window.location.href = '/login';
    };

    if (authState.isAuthenticated && sessionData !== null) {
      oktaAuth.session.on('expired', sessionExpiredHandler);
    }

    return () => {
      if (authState.isAuthenticated && sessionData !== null) {
        oktaAuth.session.off('expired', sessionExpiredHandler);
      }
    };
  }, [oktaAuth, authState.isAuthenticated, sessionData]);

  return (!authState.isPending && authState.isAuthenticated && sessionData !== null) ?
    <Redirect to={{ pathname: '/home' }} /> :
    <OktaSignInWidget
      baseUrl={oktaBaseURL}
      clientId={oktaClientId}
      onSuccess={onSuccess}
      onError={onError} />;
}

export default Login;
