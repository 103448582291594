import React, { Component } from 'react';
import { GET_IDEA_URL,SUBMIT_IDEA_URL,DISPLAYPROJECTTHEME, GET_METADATA,AWAITING_AI_BOT_REVIEW, APPROVED, PENDING, SENT_BACK, REJECTED, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, ACCESS_TOKEN, USER_DATA,AIFIRST,AI_DOC_LINK,AWAITING_E360_SPOC_APPROVAL } from '../assets/constants/constants';
import Toast from 'light-toast';
import Select from 'react-select';
import { selectValidation as selectStyle, textBoxValidation } from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";
import UpdatedSpeech from './UpdatedSpeech'

export class EditIdeaPage extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);

    constructor(props) {
        super(props)
        let propsData= this.props.location;
        this.state = {
            ideaItem: {},
            campaignName: "",
            serviceLineSelectedOption: null,
            subServiceLineSelectedOption: null,           
            showAdditionalDetails: false,
            serviceLineList: [],
            subServiceLineList: [],
            selectStyle: {},
            dataBridgeCampaign: false,
            xPollinationCampaign: false,
            reviewComments:[],
            propsData:propsData,
            speechModal:false,
            transcriptValue:"",
            modalIndex:"",
            previousTextForSpeech:"",
            ServiceLineName:'',
            SubServiceLine:"",
            IdeaName:"",
            Problem:"",
            Solution:"",
            displayProjectTheme:false,
            clusterData:[],
            isClusterSaveDisabled:true,
            displayParentAlpha:'',
            projectTypeSelectedOption:null,
            projectIDSelectedOption:null,

            campaignList: [],
            campaignInsight:"",
            subCampaignList:[],
            chatGptOptions: [
                {
                  value: 0,
                  label: "Predict The Next Best Action",
                },
                {
                  value: 1,
                  label: "Automated Narrative Building and Translation",
                },
                {
                  value: 2,
                  label: "Data Extraction and Leakage Prevention",
                },
                {
                  value: 3,
                  label: "Coding Effort Reduction",
                },
            ],
            chatGPTTheme: false,
            DisplayAIFirst:false,
            subCampaignName:"",

            ideasAI:[]


        }
    }

    async componentDidMount() {
        Toast.loading("Fetching Idea...", () => { });
        await this.fetchIdea();
        Toast.hide();
        Toast.loading("Fetching Metadata...", () => { });
        await this.fetchMetadata();
        Toast.hide();
    }

    fetchMetadata = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "",
                EntityName: ""
            }
            const requestOptions = {
                method: 'POST',
                headers: { 
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        serviceLineList: data ? data.ServiceLines : [],
                        subServiceLineList: data ? data.ServiceLines : [],
                        campaignList: data ? data.Campaigns : [],
                        subCampaignList: data ? data.SubCampaigns : [],
                    }, () => {
                        if(this.state.ideaItem.CampaignName && this.state.campaignList){
                            let campaignID = "";
                            this.state.campaignList.map((campaign)=>{
                              if(campaign.CampaignName === this.state.ideaItem.CampaignName){
                                campaignID = campaign.CampaignId;
                                this.setState({campaignInsight:campaign.CampaignDescr},()=>{})
                              }
                            })
                            let option={value:campaignID,label:this.state.ideaItem.CampaignName};
                            this.setState({campaignSelectedOption:option},()=>{})
                        }
            
                        this.setSelectedOptions();
                        resolve();
                    })
                })
                .catch(error => {
                    Toast.fail('Error occured while getting metadata idea. Please try again!', 3000, () => {
                        console.log(JSON.stringify(error));
                        this.setState({
                            serviceLineList: [],
                            subServiceLineList: [],
                            subCampaignList:[]
                        }, () => {
                            resolve();
                        })
                    });

                });
        });
    }

    setSelectedOptions = () => {
        this.state.serviceLineList.map((item) => {
            if (this.state.ideaItem.ServiceLineName === item.ServiceLineName) {
                this.setState({
                    serviceLineSelectedOption: {
                        value: item.ServiceLineId,
                        label: item.ServiceLineName
                    }
                })
            }
            return 1;
        });
        this.state.subServiceLineList.map((item) => {
            if (this.state.ideaItem.SubServiceLine === item.SubServiceLineName) {
                this.setState({
                    subServiceLineSelectedOption: {
                        value: item.SubServiceLineId,
                        label: item.SubServiceLineName
                    }
                })
            }
            return 1;
        });  
        this.state.subCampaignList.map((item) => {
            if (this.state.ideaItem.SubCampaignName === item.SubCampaignName) {
              this.setState({
                subCampaignOption: {
                  value: item.SubCampaignId,
                  label: item.SubCampaignName,
                },subCampaignName:item.SubCampaignName
              },()=>{});
            }
            return 1;
        });     
    }

    fetchIdea = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            // fetch(`${GET_IDEA_URL}IdeaId=964`, requestOptions)
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&RecID=''&isItIdeaOrGenAI='Idea'`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("data",data);
                    this.setState({
                        ideaItem: data.ResultSets.Table1[0],
                        reviewComments: data.ReviewComments.Table1,
                        dataBridgeCampaign: (data.ResultSets.Table1[0].CampaignName === DATA_BRIDGE_CAMPAIGN),
                        xPollinationCampaign: (data.ResultSets.Table1[0].CampaignName === X_POLLINATION_CAMPAIGN),

                        ServiceLineName:data.ResultSets.Table1[0].SubServiceLine ? data.ResultSets.Table1[0].ServiceLineName : "",
                        SubServiceLine:data.ResultSets.Table1[0].SubServiceLine ? data.ResultSets.Table1[0].SubServiceLine : "",
                        IdeaName:data.ResultSets.Table1[0].IdeaName ? data.ResultSets.Table1[0].IdeaName : "",
                        Problem:data.ResultSets.Table1[0].Problem ? data.ResultSets.Table1[0].Problem : "",
                        Solution:data.ResultSets.Table1[0].Solution ? data.ResultSets.Table1[0].Solution : "",
                        clusterData: data.ClusterData.Table1 ? data.ClusterData.Table1 : [],
                        ideasAI: data.ResultSets.Table2 ? data.ResultSets.Table2[0] : [],

                    }, () => {

                        


                        if(this.state.clusterData && this.state.clusterData.length > 0){
                            const targetRecID = this.state.ideaItem.LeanID;
                            const filteredData = this.state.clusterData.filter(item => item.RecAlpha === targetRecID);
                            console.log(filteredData)
                            if(filteredData && filteredData.length > 0 && filteredData !== undefined && filteredData !== null){
                                this.setState({displayParentAlpha:filteredData[0].ParentAlpha},()=>{})
                                let options = [];
                                options.push({value: 0,label: filteredData[0].ParentAlpha})
                                this.setState({projectIDSelectedOption:options},()=>{})
                        
                                let options1 = [];
                                options1.push({value: 0,label: "GB"})
                                this.setState({projectTypeSelectedOption:options1},()=>{})
                            }else{
                                this.setState({displayParentAlpha:""},()=>{})
                            }
                        }else{
                            this.setState({displayParentAlpha:""},()=>{})
                        }
                        if(this.state.ideaItem.CreatedDate){
                            let created = new Date(this.state.ideaItem.CreatedDate);
                            let abc = new Date(DISPLAYPROJECTTHEME);
                            if(created.getTime() < abc.getTime()){
                                //console.log("created date is lesser")
                                this.setState({displayProjectTheme:true})
                            }   else{
                                this.setState({displayProjectTheme:false})
                                //console.log("created date is greater ")
                            }
                        }
                        if (this.state.ideaItem.CampaignName === "Gen AI") {
                            this.setState({ chatGPTTheme: true }, () => {
                              if (this.state.chatGPTTheme) {
                                let options = [];
                                this.state.chatGptOptions.map((item) => {
                                  if (item.label === this.state.ideaItem.SubCampaignName) {
                                    options.push({
                                      value: item.value,
                                      label: item.label,
                                    });
                                    this.setState({ chatGptSelectedOption: options, subCampaignName:item.label });
                                  }
                                });
                              }
                            });
                          }
                        resolve();
                    });
                })
                .catch(error => {
                    Toast.fail("Error while fetching idea. Please try again!", 3000, () => { });
                    reject();
                });
        });
    }

    validate = () => {
        let isValid = true;
        let styleStateObj = this.state.selectStyle;


        if (this.state.ideaItem.NewOrReplicatedIdea && this.state.ideaItem.ReplicationProjectId === "") {
            isValid = false;
            styleStateObj.ReplicationProjectId = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            if (this.state.ideaItem.NewOrReplicatedIdea && this.state.ideaItem.ReplicationProjectId !== "") {
                if (!this.validateReplicationId(this.state.ideaItem.ReplicationProjectId)) {
                    isValid = false;
                    styleStateObj.ReplicationProjectId = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                } else {
                    styleStateObj.ReplicationProjectId = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
            } else {
                styleStateObj.ReplicationProjectId = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                this.setState({
                    selectStyle: styleStateObj
                })
            }
        }              
        
        if (this.state.ideaItem.ServiceLineName === "") {
            isValid = false;
            styleStateObj.ServiceLineName = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.ServiceLineName = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        
        if (this.state.ideaItem.SubServiceLine === "") {
            isValid = false;
            styleStateObj.subServiceLine = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.subServiceLine = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }        
        
        if (this.state.IdeaName === "") {
            isValid = false;
            styleStateObj.IdeaName = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.IdeaName = textBoxValidation(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }

        if (this.state.Problem === "") {
            isValid = false;
            styleStateObj.Problem = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            if (this.state.Problem.length > 2000) {
                Toast.fail("Problem statement cannot be more than 2000 characters, please change and resubmit.", 3000);
                isValid = false;
                styleStateObj.Problem = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            } else {
                styleStateObj.Problem = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                this.setState({
                    selectStyle: styleStateObj
                })
            }
        }

        if (this.state.Solution === "") {
            isValid = false;
            styleStateObj.Solution = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            if (this.state.Solution.length > 2000) {
                Toast.fail("Solution statement cannot be more than 2000 characters, please change and resubmit.", 3000);
                isValid = false;
                styleStateObj.Solution = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            } else {
                styleStateObj.Solution = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                this.setState({
                    selectStyle: styleStateObj
                })
            }
        }


        // if (this.state.chatGPTTheme) {
        //     if (this.state.chatGptSelectedOption.length === 0 || this.state.chatGptSelectedOption == [] ||
        //       this.state.chatGptSelectedOption === null ||this.state.chatGptSelectedOption === "" ||
        //       this.state.chatGptSelectedOption === undefined
        //     ) {
        //       isValid = false;
        //       styleStateObj.chatGptSelectedOption = selectStyle(false);
        //       this.setState({
        //         selectStyle: styleStateObj,
        //       });
        //     } else {
        //       styleStateObj.chatGptSelectedOption = selectStyle(true);
        //       this.setState({
        //         selectStyle: styleStateObj,
        //       });
        //     }
        // }


        // if (!this.state.campaignSelectedOption ||  this.state.campaignSelectedOption === null || this.state.campaignSelectedOption === undefined || this.state.campaignSelectedOption.length === 0) {
        //     isValid = false;
        //     styleStateObj.campaignSelectedOption = selectStyle(false);
        //     this.setState({
        //         selectStyle: styleStateObj,
        //     });
        // } else {
        //     styleStateObj.campaignSelectedOption = selectStyle(true);
        //     this.setState({
        //         selectStyle: styleStateObj,
        //     });
        //     if (this.state.campaignSelectedOption && this.state.campaignSelectedOption.label === X_POLLINATION_CAMPAIGN) {
        //         if (this.state.subCampaignOption === null || this.state.subCampaignOption === undefined || this.state.subCampaignOption.length === 0 || !this.state.subCampaignOption) {
        //             isValid = false;
        //             styleStateObj.subCampaignOption = selectStyle(false);
        //             this.setState({
        //                 selectStyle: styleStateObj,
        //             });
        //         } else {
        //             styleStateObj.subCampaignName = selectStyle(true);
        //             this.setState({
        //                 selectStyle: styleStateObj,
        //             });
        //         }
        //     }
        // }




       
        return isValid;
    }

    submitIdea = () => {
        let preworkflowstatus = "Pending";
        let workflowstatus= "Pending"
        if(this.state.DisplayAIFirst){
            preworkflowstatus = AWAITING_AI_BOT_REVIEW;
            workflowstatus = AWAITING_AI_BOT_REVIEW;
        }
        if(this.state.campaignSelectedOption && this.state.campaignSelectedOption.label === "AI First - Enterprise 360"){
            preworkflowstatus = AWAITING_E360_SPOC_APPROVAL;
            workflowstatus = AWAITING_E360_SPOC_APPROVAL
        }
        
        if (this.validate()) {
            if (window.confirm("Are you sure you want to update the Idea?")) {
                Toast.loading("Updating Idea...", () => { });
                let param = {
                    IdeaName: this.state.IdeaName,
                    Problem: this.state.Problem,
                    Solution: this.state.Solution,
                    ServiceLineId: this.state.ideaItem.ServiceLineId,
                    ServiceLineName: this.state.ideaItem.ServiceLineName,
                    SubServiceLine: this.state.ideaItem.SubServiceLine,
                    NewOrReplicatedIdea: this.state.ideaItem.NewOrReplicatedIdea,
                    ReplicationProjectId: (this.state.ideaItem.NewOrReplicatedIdea)? this.state.ideaItem.ReplicationProjectId : "",
                    IdeaId: this.props.location.state,
                    ModifiedByOHR: this.sessionData.profile.oHRId,
                    SaveType: "Edit",

                    PrevWorkflowStatus: preworkflowstatus,
                    WorkflowStatus :workflowstatus ,

                    CampaignTypeId: this.state.campaignSelectedOption ? this.state.campaignSelectedOption.value : "",
                    CampaignName: this.state.campaignSelectedOption ? this.state.campaignSelectedOption.label : "",
                    SubCampaignName:this.state.subCampaignName ? this.state.subCampaignName : "",
                    // isItIdeaOrGenAI:"Idea",
                    DBSPocOHR: this.state.campaignSelectedOption ? (this.state.campaignSelectedOption.label === "AI First - Enterprise 360" ? (this.state.ideaItem.DBSPocOHR ? this.state.ideaItem.DBSPocOHR+"@genpact.com" : "") : ""): "",
                    
                }
                console.log(param)
                console.log(JSON.stringify(param))
                let finalJsonData = Checksum(param);
                let str1 = finalJsonData.slice(0, 15);
                let str2 = finalJsonData.slice(15, 30);
                let str3 = finalJsonData.slice(30, 40);
                let str4 = finalJsonData.slice(-10);
                let finalData = str3+str1+str4+str2;
                
                const requestOptions = {
                    method: 'POST',
                    headers: { 
                        'Content-Type': 'application/json',
                        "Authorization":  "Bearer " + this.accessToken,
                    },
                    body: JSON.stringify(param)
                };
                fetch(`${SUBMIT_IDEA_URL}Checksum=${finalData}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        Toast.hide();
                        Toast.success('Updated successfully.', 3000, () => {
                            console.log(JSON.stringify(data));
                            window.location = "/review-page";
                        });

                    });
            }
        }else {
            Toast.fail('Required fields are missing or have invalid values. Please correct and try again.', 3000, () => { });
        }
        return false;
    }

    handleIdeaNameChange = (text) => {
        this.setState({IdeaName:text},()=>{
            console.log("idea name at onchange function",this.state.IdeaName)
        })
    }

    handleProblemChange = (text) => {
        this.setState({Problem:text},()=>{console.log("Problem text updated",this.state.Problem)})
    }

    handleSolutionChange = (text) => {
        this.setState({
            Solution:text
        },()=>{
            console.log("solution text updated",this.state.Solution)
        });
    }

    showHideDiv = () => {
        let ideaItem = this.state.ideaItem;
        ideaItem.NewOrReplicatedIdea = !ideaItem.NewOrReplicatedIdea;
        this.setState({
            ideaItem
        });
    }

    handleReplicationIdChange = (text) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.ReplicationProjectId = text;
        this.setState({
            ideaArray: ideaItem
        });
    }
   
    renderServiceLineOptions = () => {
        let options = [];
        const uniqueServiceLineList = [...new Map(this.state.serviceLineList.map(item =>
            [item.ServiceLineName, item])).values()];
        uniqueServiceLineList.map((item) => {
            options.push({ value: item.ServiceLineId, label: item.ServiceLineName });
            return true;
        });
        return options;
    }

    renderSubServiceLineOptions = () => {
        let options = [];

        this.state.subServiceLineList.map((item) => {
            if (this.state.ideaItem.ServiceLineName === item.ServiceLineName) {
                options.push({ value: item.SubServiceLineId, label: item.SubServiceLineName });
                return true;
            }
            return true;
        });
        return options;
    }

    handleServiceLineChange = (e) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.ServiceLineId = e.value;
        ideaItem.ServiceLineName = e.label;
        ideaItem.SubServiceLineId = "";
        ideaItem.SubServiceLine = "";
        this.setState({
            subServiceLineSelectedOption: null,
            serviceLineSelectedOption: {
                value: e.value,
                label: e.label
            },
            ideaItem
        });
        this.renderSubServiceLineOptions(e);
    }

    handleSubServiceLineChange = (e, index) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.SubServiceLine = e.label;
        this.setState({
            subServiceLineSelectedOption: {
                value: e.value,
                label: e.label
            },
            ideaItem
        });

    }    
    
    fetchReplicationIdeaDetails = (IdeaId) => {
        Toast.loading("Fetching Replication Idea Details. Please wait...", () => { });
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            }
        };
        return fetch(`${GET_IDEA_URL}IdeaId=-1&IdeaIDAlpha=` + IdeaId, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.ResultSets.Table1 === undefined) {
                    Toast.fail("We could not find Idea details based on the entered Idea ID. Please check and reenter.", 5000, () => { });
                    // let ideaItem = this.state.ideaItem;
                    // ideaItem.ReplicationProjectId = "";
                    // this.setState({
                    //     ideaItem: ideaItem
                    // });
                    return false;
                } else {
                    Toast.hide();
                    Toast.success('Replication Idea ID validated successfully!', 3000, () => { });
                    return true;
                }
            })
            .catch(error => {
                Toast.hide();
                Toast.fail("Could not find the Idea, please check and re-enter correct Idea ID.", 5000, () => { });
                return false;
            });
    }    
        
    validateReplicationId = () => {
        if (!this.fetchReplicationIdeaDetails(this.state.ideaItem.ReplicationProjectId)) {
            let ideaItem = this.state.ideaItem;
            ideaItem.ReplicationProjectId = "";
            this.setState({
                ideaItem: ideaItem
            });
            return false;
        } else {
            return true;
        }
    }    

    cancelEvent = () => {
        // this.props.history.push("/review-page");
        let redirectURL = "/review-page";
        this.props.history.push({
            pathname: redirectURL,
            propsList:this.state.propsData
        })
    }

    formatDateStringWithTime(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        });
    }

    handleEditTranscriptProblemChange=(text)=>{
        if(text !== null && text !== undefined){
            this.setState({Problem:text},()=>{
                console.log("at handle transcript problem",this.state.Problem)
            })
        }
    }
    handleEditTranscriptSolutionChange=(text)=>{
        if(text !== null && text !== undefined){
            this.setState({
                Solution:text
            },()=>{});
        }
    }
    toggleTranscriptFunction=(id)=>{
        this.setState({speechModal: !this.state.speechModal,modalIndex:id})
        this.setState({previousTextForSpeech:this.state.Problem},()=>console.log(this.state.previousTextForSpeech))
        if(id===5){
            this.setState({previousTextForSpeech:this.state.Problem},()=>console.log(this.state.previousTextForSpeech))
        }
        if(id===6){
            this.setState({previousTextForSpeech:this.state.Solution},()=>console.log(this.state.previousTextForSpeech))
        }
    }

    closeModalFunction=()=>{
        this.setState({speechModal:false})
    }

    renderCampaignOptions = () => {
        let options = [];
        this.state.campaignList.map((item) => {
            options.push({ value: item.CampaignId, label: item.CampaignName });
            return true;
        });
        return options;
    };


    handleCampaignNameChange = (e) => {
        this.state.campaignList.map((item,index)=>{
            if(e.value===item.CampaignId){
                this.setState({campaignInsight:item.CampaignDescr},()=>{})
            }
        })
        if (e.label === "Gen AI") {
          this.setState({ chatGPTTheme: true }, () => {});
        } else {
          this.setState({ chatGPTTheme: false }, () => {});
        }
        this.setState({
          chatGptSelectedOption: [],
          IsIdeaReplicableSelectedOption:[],
          replicabilitySelectedOption: [],
          solutionFeasibleSelectedOption:[],
          solutionComplexitySelectedOption:[],
          subCampaignOption:[],
        });
    
        let styleStateObj = this.state.selectStyle;
        styleStateObj.campaignTypeText = selectStyle(true);
    
        let flagStatus = e.label === DATA_BRIDGE_CAMPAIGN ? true : false;
        let xPollinationflagStatus =
          e.label === X_POLLINATION_CAMPAIGN ? true : false;
    
        this.setState({
          dataBridgeCampaign: flagStatus,
          xPollinationCampaign: xPollinationflagStatus,
          dataBridgeProjectTheme: "",
          subCampaignName: "",
          selectStyle: styleStateObj,
          campaignSelectedOption: {
            value: e.value,
            label: e.label,
          },
          //campaignTypeId: e.value,//e.target.value,
          campaignTypeText: e.label,
        });
    
        if (e && e.label === "Data Bridge") {
          this.setState({
            transSelectedOption: {
              value: 0,
              label: "Data",
            },
          });
        } else if (e && (e.label === "AI First" || e.label === "AI First - C&H Gen AI hackathon" || e.label === "AI First - Enterprise 360")) {
          this.setState({
            transSelectedOption: {
              value: 0,
              label: "AI",
            },
          });
        } else {
          this.setState({
            transSelectedOption: null,
          });
        }


        if(e.label === "AI First"){
            this.setState({DisplayAIFirst:true},()=>{})
        }else{
            this.setState({DisplayAIFirst:false},()=>{})
        }


    };

    renderSubCampaignOptions = () => {
        let options = [];
        this.state.subCampaignList.map((item) => {
          options.push({ value: item.SubCampaignId, label: item.SubCampaignName });
          return true;
        });
        return options;
    };


    handleSubCampaignChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.subCampaignOption = selectStyle(true);
        this.setState({
          selectStyle: styleStateObj,
          subCampaignName: e.label,
          subCampaignOption: {
            value: e.label,
            label: e.label,
          },
        },()=>{
            console.log("sub campaign name change =>",this.state.subCampaignName)
            console.log("sub campaign option selected is =>",this.state.subCampaignOption)
        });
    };

    renderChatGPToptions = () => {
        let options = [];
        this.state.chatGptOptions.map((item) => {
          options.push({ value: item.value, label: item.label });
          return true;
        });
        return options;
    };
    handleChatGptOptionsChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.chatGptSelectedOption = selectStyle(true);
        if (e !== null || e !== undefined) {
          this.setState(
            {
              subCampaignName: e.label,
              chatGptSelectedOption: e
            },
            () => {
                console.log("sub campaign name change =>",this.state.subCampaignName)
            }
          );
        }
    };






    
    render() {
        let IDEA_APPROVED = (this.state.ideaItem.WorkflowStatus === "Approve");
        let IDEA_REJECTED = (this.state.ideaItem.WorkflowStatus === "Reject");
        let IDEA_SEND_BACK = (this.state.ideaItem.WorkflowStatus === "Send Back");
        return (
            <>
                <section className="u-clearfix u-section-1" id="sec-0f7f">
                    <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                        <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                            <div className="u-container-layout u-container-layout-1">
                                <h4 style={{ textAlign:'center', color:'#00AECF', fontFamily:'Arial', fontSize:22 }}>Edit  idea : {this.state.ideaItem.IdeaIDAlpha ? this.state.ideaItem.IdeaIDAlpha : this.state.ideaItem.IdeaId} </h4>
                                <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                                    <div className="u-container-layout u-container-layout-2" style={{textAlign:'center'}}>
                                        <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-3">
                                            <div className="u-container-layout u-container-layout-3">
                                                <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Owner details</p>
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner OHR</label> </div>
                                                    <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerOHR} /></div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner Name</label> </div>
                                                    <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerName} /></div>
                                                </div>
                                                <div style={{ marginBottom: -10 }} />
                                                <p className="u-align-left u-text u-text-7 u-text-custom-color-3" style={{ fontSize: 11, marginBottom: 10 }}>(If Idea was raised for someone else, their OHR ID is below)</p>
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Request For OHR</label> </div>
                                                    <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.CreatorOHR} /></div>
                                                </div>
                                                <div style={{ marginBottom: 10 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Name</label> </div>
                                                    <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.CreatorName} /></div>
                                                </div>
                                                <div style={{ marginBottom: 10 }} />
                                                {/* <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Project ID</label> </div>
                                                    <div className="col-md-6"><input className ="form-input" disabled type="text" defaultValue={this.state.ideaItem.LeanID}/></div>
                                                </div> */}
                                            <div style={{ marginBottom: 10 }} />
                                            </div>
                                        </div>

                                        <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 235 }}>&nbsp;</p>

                                        <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-4" style={{ marginTop: "-251px" }}>
                                            <div className="" style={{display:"flex",marginTop:"30px"}}>
                                                <div className="u-container-style u-group u-shape-rectangle u-group-5">
                                                    <div className="u-container-layout u-container-layout-5">
                                                        <p className="u-align-left u-text u-text-8" style={{ marginBottom: 15 }}>Account Details</p>
                                                        <div className="row">
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Account&nbsp;Name</label></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled list="accounts" name="accounts" placeholder="Choose Account" value={this.state.ideaItem.AccountManual} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Holding&nbsp;Account</label></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled type="text" value={this.state.ideaItem.HoldingAccount} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Vertical</label></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled list="vertical" name="vertical" placeholder="Choose Vertical" value={this.state.ideaItem.VerticalManual} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Industry&nbsp;Vertical</label></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled list="ideationtype" name="ideationtype" placeholder="Choose Industry&nbsp;Vertical" value={this.state.ideaItem.SubVerticalName} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />

                                                        {/* units and segments starts here */}
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Segment</label>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled name="segment" value={this.state.ideaItem.Segment ? this.state.ideaItem.Segment : ""} style={{ fontWeight: 'normal !important' }} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Unit</label>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled name="unit" value={this.state.ideaItem.Unit ? this.state.ideaItem.Unit : ""} style={{ fontWeight: 'normal !important' }} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        {/* units and segments ends here */}


                                                        <div className="row">
                                                            <div className="col-md-5"><span className="form-attribute">Campaign&nbsp;Name</span></div>
                                                            <div className="col-md-7">
                                                                <Select
                                                                    isSearchable={true}
                                                                    options={this.renderCampaignOptions()}
                                                                    styles={
                                                                        this.state.selectStyle.campaignTypeText
                                                                    }
                                                                    placeholder="Choose Campaign"
                                                                    onChange={this.handleCampaignNameChange}
                                                                    value={this.state.campaignSelectedOption}
                                                                />
                                                                {/* <input className="form-input" disabled list="ideationtype" name="ideationtype" placeholder="Choose Idea Type" value={this.state.ideaItem.CampaignName} /> */}
                                                            </div>
                                                            <div className="">
                                                                <div className="campaign_modal_bulb campaign_tool_tip">
                                                                    <img 
                                                                        src={require("../assets/images/question_mark_2.png")}
                                                                        alt=""
                                                                        className=""
                                                                    />
                                                                    {
                                                                        this.state.campaignInsight
                                                                        ?
                                                                        <>
                                                                            <span className="my_tooltiptext">{this.state.campaignInsight}</span>
                                                                        </>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            </div>
                                                                
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />

                                                        {this.state.chatGPTTheme ? (
                                                        <>
                                                            <div className="row">
                                                            <div className="col-md-5">
                                                                <label style={{verticalAlign: "bottom", marginBottom: -5,}}>
                                                                <label style={{color: "red",verticalAlign: "bottom",}}> *</label>{" "}
                                                                    Gen AI Theme
                                                                </label>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <Select
                                                                    tabIndex={5}
                                                                    isSearchable={true}
                                                                    options={this.renderChatGPToptions()}
                                                                    styles={this.state.selectStyle.chatGptSelectedOption}
                                                                    placeholder="Choose Gen AI Theme"
                                                                    onChange={this.handleChatGptOptionsChange}
                                                                    value={this.state.chatGptSelectedOption}
                                                                />
                                                            </div>
                                                            </div>
                                                        </>
                                                        ) : null}

                                                        {
                                                            this.state.displayProjectTheme && this.state.dataBridgeCampaign && (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Data Bridge Project Theme</label></div>
                                                                        <div className="col-md-7">
                                                                            <input className="form-input" disabled list="databridgetype" name="databridgetype" placeholder="Choose Data Bridge Project Type" value={this.state.ideaItem.DataBridgeTheme} />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: 26 }} />
                                                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            this.state.xPollinationCampaign && (
                                                                <>
                                                                    <div className="row">
                                                                        {/* <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Campaign</label></div> */}
                                                                        <div className="col-md-5">
                                                                            <label style={{verticalAlign: "bottom", marginBottom: -5,}}>
                                                                            {/* <label style={{color: "red",verticalAlign: "bottom",}}> *</label>{" "} */}
                                                                                Sub Campaign
                                                                            </label>
                                                                        </div>


                                                                        <div className="col-md-7">
                                                                            {/* <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Sub Campaign" value={this.state.ideaItem.SubCampaignName} /> */}
                                                                                <Select
                                                                                    isSearchable={true}
                                                                                    options={this.renderSubCampaignOptions()}
                                                                                    styles={this.state.selectStyle.subCampaignOption}
                                                                                    placeholder="Choose Sub Campaign"
                                                                                    value={this.state.subCampaignOption}
                                                                                    onChange={this.handleSubCampaignChange}
                                                                                />


                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: 26 }} />
                                                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                                </>
                                                            )
                                                        }  

                                                        {
                                                            this.state.displayParentAlpha && this.state.displayParentAlpha.length > 0 && this.state.displayParentAlpha !== null  && this.state.displayParentAlpha !== undefined && this.state.displayParentAlpha !== "" 
                                                            ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5">
                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Parent ID</label>
                                                                    </div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled placeholder="Choose Parent ID" value={this.state.displayParentAlpha} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                        } 


                                                        {
                                                            this.state.campaignSelectedOption && this.state.campaignSelectedOption.label === AIFIRST
                                                            ?
                                                            <>  
                                                                <div className="row" style={{marginTop:"20px"}}>
                                                                <div className="col-md-5">
                                                                    <label
                                                                    style={{
                                                                        verticalAlign: "bottom",
                                                                        marginBottom: -5,
                                                                    }}
                                                                    >
                                                                    <i>AI First Sample Ideas</i>
                                                                    </label>
                                                                </div>
                                                                <div className="col-md-7">
                                                                    <div className="ai_first_doc_link ai_first_doc_red_highlight">
                                                                    <a
                                                                        className="ldt-list1"
                                                                        href={AI_DOC_LINK}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        style={{ textAlign: "left", fontSize: 16 ,color:"red"}}
                                                                    >Document Link </a>
                                                                    </div>
                                                                    
                                                                </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                        }


                                                        {
                                                            this.state.campaignSelectedOption && this.state.campaignSelectedOption.label === AIFIRST
                                                            && this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No")
                                                            ?
                                                            <>
                                                                <div style={{ marginBottom: 20 }} />
                                                                <div className="row d_flex_align_items_center">
                                                                <div className="col-md-5">
                                                                    <span className="form-attribute">
                                                                    AI Recommendation
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-7">
                                                                    <input
                                                                    className="form-input"
                                                                    disabled
                                                                    name="methodology"
                                                                    value={this.state.ideaItem.isAI}
                                                                    />
                                                                </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null

                                                        }


                                                        {
                                                            this.state.campaignSelectedOption && this.state.campaignSelectedOption.label === AIFIRST &&
                                                            this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No")
                                                            ?
                                                            <>
                                                                <div style={{ marginBottom: 20 }} />
                                                                <div className="row">
                                                                <div className="col-md-5">
                                                                    <span className="form-attribute">
                                                                    AI First BRD
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-7">
                                                                    <div className="ai_first_doc_link">
                                                                    <textarea className="ai_first_brd_textarea" disabled value={this.state.ideasAI.LinkBRD} ></textarea>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                        }


                                                    </div>
                                                </div>
                                                <div className="u-container-layout u-container-layout-6">
                                                       
                                                    <div className="row" style={{marginTop:"10px"}}>
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Country</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled list="country" name="country" placeholder="Choose Country" value={this.state.ideaItem.Country} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Region</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled list="regions" name="regions" placeholder="Choose Region" value={this.state.ideaItem.RegionManual} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub-Region</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled list="subregions" name="subregions" placeholder="Choose Sub-Region" value={this.state.ideaItem.SubRegion} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />                                                        
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Blackbelt&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled list="blackbelt" name="blackbelt" placeholder="Choose Blackbelt" title={"OHR ID: " +  this.state.ideaItem.BlackBeltUserId} value={this.state.ideaItem.BlackBeltUserName} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>MBB</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="blackbelt" placeholder="Choose MBB" title={"OHR ID: " +  this.state.ideaItem.MBBOHR} value={this.state.ideaItem.MBBName} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    {
                                                        this.state.campaignSelectedOption && this.state.campaignSelectedOption.label === AIFIRST  &&
                                                            this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No")
                                                            ?
                                                            <>
                                                                <div style={{ marginBottom: 20 }} />
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <span className="form-attribute">
                                                                            AI First Reason
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <textarea
                                                                            disabled
                                                                            value={this.state.ideasAI.AIReason}
                                                                            placeholder="Please provide value"
                                                                            className="form-input"
                                                                            rows={4}
                                                                            style={{
                                                                                width: "100%",
                                                                                border: "1px solid lightgrey",
                                                                                resize: "none"
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 20 }} />
                                                            </>
                                                            :
                                                            null
                                                     }
                                                    <div style={{ marginBottom: 15 }} />                                                        
                                                </div>
                                            </div>
                                        </div>

                                        

                                        {
                                            (this.state.ideaItem.WorkflowStatus && this.state.ideaItem.WorkflowStatus !== "Pending") && (
                                                <>
                                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -40 }}>&nbsp;</p>

                                                    <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ marginTop: 15 }}>
                                                        <div className="u-container-layout u-container-layout-7" style={{borderTop:"1px solid lightgrey",borderBottom:"1px solid lightgrey"}}>
                                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}> Idea Approval details</p>
                                                            <div className="row">
                                                                <div className="col-md-2"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Approval Status</label></div>
                                                                <div className="col-md-2">
                                                                    <input className="form-input" disabled name="Status" value={IDEA_APPROVED ? APPROVED : (IDEA_REJECTED ? REJECTED : (IDEA_SEND_BACK ? SENT_BACK : PENDING))} />
                                                                </div>
                                                                {/* <div className="col-md-3"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Expected timeline for execution</label></div>
                                                                <div className="col-md-2">
                                                                    <input disabled name="ExpectedDate" value={this.state.ideaItem.ExpectedFinishDate ? this.formatDateString(new Date(this.state.ideaItem.ExpectedFinishDate)) : ""} />
                                                                </div> */}
                                                            </div>
                                                            <div className="col-md-9" style={{ marginBottom: 5, width: "100%"  }} />
                                                            {/* <div className="row">
                                                                <div className="col-md-12">
                                                                    <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                                        <span className="u-text-black form-attribute">Comments</span>
                                                                    </p>
                                                                </div>
                                                            </div> */}
                                                            {/* <div className="row">
                                                                <div className="col-md-12">
                                                                    <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.Comment} />
                                                                </div>
                                                            </div> */}
                                                            <div className="row" style={{marginTop:15}}>
                                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Comments</label></div>
                                                                <div className="col-md-2" style={{marginLeft:20}}>
                                                                    <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e)=>this.setState({commentLog:!this.state.commentLog})}>Comment Log</button>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{marginTop:5}}>
                                                                <div className="col-md-12">
                                                                    <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.Comment} />
                                                                </div>
                                                            </div>
                                                            <div className="text-left mt-4">
                                                                {
                                                                    this.state.commentLog && this.state.reviewComments
                                                                        ?
                                                                        <>
                                                                            <div className="custom_modal">
                                                                                <div className="modal_body">
                                                                                    <div className="row">
                                                                                        <div className="col-md-8">
                                                                                            <h3 className="modal_title">Idea Approval Comment Log</h3>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <button type="button" className="close text-right" onClick={(e) => this.setState({ commentLog: false })}>&times;</button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                        </div>
                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                        </div>
                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                        </div>
                                                                                        <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                        </div>
                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                        </div>
                                                                                        <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        this.state.reviewComments.map((item, index) => {

                                                                                            return (
                                                                                                <div className="row" key={index}>
                                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-1 text-center role_over_flow_class" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin' ) ? 'Admin' : (item.Action === 'Delegate Action' ? 'BB' : (item.UpdaterOHR === this.state.ideaItem.BlackBeltUserId ? "BB" : (item.UpdaterOHR === this.state.ideaItem.MBBOHR ? "MBB" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Owner" : "LDT"))))}</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? (item.WorkflowStatus !== 'Pending' ? item.WorkflowStatus : 'Idea Updated') : (item.Action === 'Delegate Action' ? 'Delegated' : (item.WorkflowStatus==='Pending' ? 'Reject Cancellation' : item.WorkflowStatus)))}</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment !== '' ? item.Comment:'-'}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 15 }}>&nbsp;</p>
                                                </>
                                            )
                                        }

                                        <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -80 }}>&nbsp;</p>

                                        <div id="idea1" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ marginTop: 15 }}>
                                            <div className="u-container-layout u-container-layout-7">
                                                <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                    <div className="u-container-layout u-container-layout-8">
                                                        <p id="idea1title" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Idea details</p>
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}><span style={{ color: 'red' }}>*</span> Service&nbsp;Line</label></div>
                                                            <div className="col-md-8">
                                                                <Select isSearchable={true} options={this.renderServiceLineOptions(this.state.serviceLineSelectedOption)} styles={this.state.selectStyle.serviceLineText} placeholder="Choose Service Line" value={this.state.serviceLineSelectedOption} onChange={(e) => this.handleServiceLineChange(e)} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}><span style={{ color: 'red' }}>*</span> Sub-Service&nbsp;Line</label></div>
                                                            <div className="col-md-8">
                                                                <Select isSearchable={true} options={this.renderSubServiceLineOptions()} styles={this.state.selectStyle.subServiceLine} placeholder="Choose Sub Service Line" value={this.state.subServiceLineSelectedOption} onChange={(e) => this.handleSubServiceLineChange(e)} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />  

                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <p className="u-align-left u-text u-text-custom-color-4 u-text-8">
                                                                    <span className="u-text-black form-attribute" style={{ fontWeight: 'normal' }}><span style={{ color: 'red' }}>*</span>&nbsp; What is the Problem ?&nbsp;<span style={{ fontWeight: 'normal', fontSize:12, fontStyle: 'italic', color: 'grey' }}>(max. 2000 chars)</span></span>
                                                                </p>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <div className="" style={{display:'flex',justifyContent:"flex-end"}}>
                                                                    <img onClick={(e)=>this.toggleTranscriptFunction(5)} src={require("../assets/images/voice.png")} className="w100" alt="" style={{width:"24px",cursor:"pointer"}} />
                                                                </div>
                                                            </div>
                                                        </div>  

                                                        {/* <p className="u-align-left u-text u-text-custom-color-4 u-text-8">
                                                            <span className="u-text-black form-attribute"><span style={{ color: 'red' }}>*</span>&nbsp;Problem&nbsp;<span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}>(max. 2000 chars)</span></span>
                                                        </p> */}
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <textarea className="textarea-css form-attribute" rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey', ...this.state.selectStyle.Problem }} value={this.state.Problem} onChange={(event) => this.handleProblemChange(event.target.value)}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.speechModal
                                                        ?
                                                            // <SpeechToText modalOpen={true} onData={ this.handleTranscriptProblemChange} modalId={this.state.modalIndex} closeModalFunction={this.closeModalFunction} ideaArrayIndex={index}  />
                                                            <UpdatedSpeech modalOpen={true} 
                                                                onEditTranscriptProblem ={this.handleEditTranscriptProblemChange} 
                                                                onEditTranscriptSolution={this.handleEditTranscriptSolutionChange} 
                                                                modalId={this.state.modalIndex} 
                                                                closeModalFunction={this.closeModalFunction}
                                                                previousText={this.state.previousTextForSpeech} 
                                                            />
                                                        :
                                                        null
                                                    } 
                                                </div>
                                                <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                    <div className="u-container-layout u-container-layout-9" style={{ marginTop: -5 }}>
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}><span style={{ color: 'red' }}>*</span> Idea Name</label> </div>
                                                            <div className="col-md-8" style={{ alignItems: 'flex-start' }}><input className="textarea-css form-attribute" type="text" value={this.state.IdeaName} style={this.state.selectStyle.IdeaName} placeholder="Eg: Cash flow improvement through process restructuring" onChange={(event) => this.handleIdeaNameChange(event.target.value)} /></div>
                                                        </div>
                                                        <div style={{ marginBottom: 17 }} />
                                                        <div style={{marginTop:50}}>&nbsp;</div>

                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <p className="u-align-left u-text u-text-custom-color-4 u-text-8">
                                                                    <span className="u-text-black form-attribute" style={{ fontWeight: 'normal' }}><span style={{ color: 'red' }}>*</span>&nbsp; How to resolve the problem&nbsp;<span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}>(max. 2000 chars)</span></span>
                                                                </p>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <div className="" style={{display:'flex',justifyContent:"flex-end"}}>
                                                                    <img onClick={(e)=>this.toggleTranscriptFunction(6)} src={require("../assets/images/voice.png")} className="w100" alt="" style={{width:"24px",cursor:"pointer"}} />
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* <p className="u-align-left u-text u-text-custom-color-4 u-text-8">
                                                            <span className="u-text-black form-attribute"><span style={{ color: 'red' }}>*</span>&nbsp;Solution&nbsp;<span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}>(max. 2000 chars)</span></span>
                                                        </p> */}
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <textarea className="textarea-css form-attribute" rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey', ...this.state.selectStyle.Solution }} value={this.state.Solution} onChange={(event) => this.handleSolutionChange(event.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 17 }} />
                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginTop: 30 }} >
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom' }}><label style={{ color: 'red' }}>*</label> Are you trying to replicate an existing Idea?</label>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="switch">
                                                            <input type="checkbox" checked={this.state.ideaItem.NewOrReplicatedIdea === null ? false : this.state.ideaItem.NewOrReplicatedIdea} onChange={() => this.showHideDiv()}/>
                                                            <span className="slider round" />
                                                        </label>
                                                    </div>
                                                </div>

                                                {
                                                    this.state.ideaItem.NewOrReplicatedIdea && (
                                                        <>
                                                        
                                                            <div className="row" id="ReplicatedProjectId">
                                                                <div className="col-md-8" style={{ marginBottom: 15 }} />
                                                                <div className="col-md-5"><span className="form-attribute"> <span style={{ color: 'red' }}>*</span> Provide the ID of the executed Idea which you
                                                                    want to replicate?</span></div>
                                                                <div className="col-md-2">
                                                                    <input className = "textarea-css form-attribute"  type="text" value={this.state.ideaItem.ReplicationProjectId} style={{ border: '1px solid lightgrey', ...this.state.selectStyle.ReplicationProjectId }} onChange={event => { this.handleReplicationIdChange(event.target.value) }} onKeyPress={event => {
                                                                        if (event.key === 'Enter') {
                                                                            this.validateReplicationId();
                                                                        }
                                                                    }} />
                                                                </div>
                                                                <div className="col-md-1"><i className="fa fa-check-square-o" style={{marginTop:5, color: '#FF555F', fontSize: 24 }} onClick={event => { this.validateReplicationId() }} /></div>
                                                                <div className="col-md-8" style={{ marginBottom: 15 }} />
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                <div className="col-md-8" style={{ marginTop:5, marginBottom: 15 }} />
                                            </div>
                                        </div>

                                        <div className="" style={{borderTop:"1px solid lightgrey",margin:"15px 0px"}}/>
                                       
                                        <div className="btn-group">
                                            <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.submitIdea}>Save</button>
                                            <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.cancelEvent}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default EditIdeaPage
