
import React, { Component } from 'react'
import {
    GET_IDEA_URL, REVIEW_IDEA_URL, UPDATE_IDEA_URL, AWAITING_MBB_APPROVAL, DISPLAYPROJECTTHEME, DELEGATE_IDEA_URL, GET_METADATA, APPROVED, PENDING, SENT_BACK, REJECTED, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, ACCESS_TOKEN, COMPLETED_STATUS, USER_DATA, SIGNOFF_CONTENT, PPT_CONTENT, REQUEST_CANCELLATION, BI_FORM_DATE, GOL_APPROVAL_CONTENT, AWAITING_QAULITY_CHECK_REVIEW,REPLICABILITYOPTIONS
    , modelOperationOptions, dbSpocApprovalOptions,
    sustenanceMetricFreqOptions, DATABRIDGETYPES, MUCID,
    YESORNO, SOLUTIONCOMPLEXITY, AI_DOC_LINK, AIFIRST 
} from '../assets/constants/constants';
import Toast from 'light-toast';
import Select from 'react-select';
import { selectValidation as selectStyle, textBoxValidation } from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";
import UpdatedSpeech from './UpdatedSpeech';
import SortByColumn from "../utils/SortByColumns";
import { withRouter } from "react-router-dom";
import ValueDelivery from '../utils/ValueDelivery';
import FPNAGOL from '../utils/FPNAGOL';
import { Link } from 'react-router-dom';
import DisplayTemplate from '../utils/DisplayTemplates'

const projectType = [
    {
        value: 0,
        label: "GB"
    },
    {
        value: 1,
        label: "BB"
    },
    {
        value: 2,
        label: "MBB"
    },
]

export class SustenanceMetricsApprovalPage extends Component {

    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);
    constructor(props) {
        super(props)
        const isApprovalPage = `${this.props.location.approval}`;
        let propsData = this.props.location;
        this.state = {
            ideaItem: {},
            workflowStatus: "",
            Comment: "",
            finalComment: "",
            accountName: "",
            campaignName: "",
            serviceLineName: "",
            showFinalAdditionalDetails: false,
            workFlowStatusList: [],
            workFlowStatusSelectedOption: null,
            certificationLevelsList: [],
            certLevelsSelectedOption: null,
            certificationWorkTypeList: [],
            certWorkTypeSelectedOption: null,
            transformationLeversList: [],
            transSelectedOption: null,
            methodologyList: [],
            methodologySelectedOptions: null,
            expectedDate: new Date(),
            finalApproveOptionsValue: [{
                value: 0,
                label: "Approved"
            },
            {
                value: 1,
                label: "Send Back"
            },
            ],
            finalImpactDollar: 0.0,
            finalImpactFTE: 0.0,
            typeOfImpactList: [],
            businessImpactCategoriesList: [],
            businessImpactSubCategoriesList: [],
            typeOfImpactSelectedOption: null,
            businessImpactSelectedOption: null,
            businessSubCatSelectedOption: null,
            finalTransSelectedOption: null,
            otherTools: "",
            showSourceId: false,
            sourceId: "1234",
            bestClass: false,
            selectStyle: {},
            businessImpactMasterList: null,
            businessImpactValueArray: [],
            fteTotal: 0,
            dollarTotal: 0.0,
            dataBridgeCampaign: false,
            xPollinationCampaign: false,
            zeroImpact: false,
            summaryArray: [],
            approvalPage: isApprovalPage,
            selectStyle: {},
            reviewComments: [],
            propsData: propsData,
            Role: "",
            speechModal: false,
            previousTextForSpeech: "",
            CommentEmpty: false,
            filteredImpactTypeState: [],
            filteredImpactTypeSelectedOption: null,
            filteredMonetizableTypeState: [],
            filteredMonetizableSelectedOption: null,
            filteredBISubCategoryState: [],
            filteredBISubCategorySelectedOption: null,
            ideaCreatedDate: "",
            setGreyOutButton: false,
            disableAddButton: false,
            showHrefModal: false,
            zeroImpactForLastestBI: false,
            CompletedDate: "",
            showClientFields: false,
            showGOLFields: false,

            // PPT deck 
            filename1: "",
            fileType1: "",
            base64File1: "",

            // client sign-off
            signOffFilename: "",
            signOffFileType: "",
            signOffContent: "",

            // gol file
            filename3: "",
            fileType3: "",
            base64File3: "",

            // fpna file
            filename4: "",
            fileType4: "",
            base64File4: "",

            clientName: "",
            clientRole: "",
            clientSignOffDate: "",

            golFilename: "",
            golContent: "",
            golFileType: "",
            golOhr: "",
            golEmail: "",
            golFullname: "",

            fpnaOhr: "",
            fpnaFilename: "",
            fpnaContent: "",
            fpnaFileType: "",
            fpnaName: "",

            submitIdeaCount: 0,
            biThreshold: "",
            awaitingQC: false,



            vrconditiontype: "",
            vrMapping: "",
            vrRegionMapping: "",
            vrVerticalSplit: "",
            vrResult: "",
            IsVDType: "",
            showBBFieldsValue: [],
            appropriateAttachment: false,
            mbbLimit: 50000,
            approvalStatusState: "",
            bbFieldValidationState: "",
            inputDollarValue: "",
            monetizableValues: [
                {
                    value: 0,
                    label: "Improvement in transaction based billing"
                },
                {
                    value: 1,
                    label: "Increase in billable HC"
                },
                {
                    value: 2,
                    label: "Increase in billable hours"
                },
                {
                    value: 3,
                    label: "Revenue from digital deployment"
                },
                {
                    value: 4,
                    label: "Revenue from special projects"
                },
                {
                    value: 5,
                    label: "Value share from improvement project"
                },
                {
                    value: 6,
                    label: "Fixed Fee"
                },

            ],
            postMonetizableOptions: [
                {
                    value: 0,
                    label: "Discussion to be initiated with Client"
                },
                {
                    value: 1,
                    label: "Discussion ongoing with Client"
                },
                {
                    value: 2,
                    label: "Rejected by Client"
                },
                {
                    value: 4,
                    label: "Approved by Client"
                },

            ],
            monetizableProjectSelectedOption: null,
            monetizableCheckbox: null,
            monetizableStatus: false,
            showClientApprovalFields: false,

            monetizationStatusSelectedOption: null,
            SowID: "",
            IsVDMonetizable: "",
            MonetizationStatus: "",
            FPnAOHRMonetization: "",
            FPnANameMonetization: "",
            FPnAEmailMonetization: "",
            FPnAFileNameMonetization: "",
            FPnAFileTypeMonetization: "",
            FPnAFileMonetization: "",
            IsSampleCheck: false,

            totalMonetizedFTE: 0,
            totalMonetizedDollar: 0,
            qcCommentLog: false,
            qcComments: [],
            impactChanged: false,
            qcCommentsWithVD: [],
            golFpnaFileDetails: [],
            approvalMatrix: false,
            isFpnaRequired: false,
            displayProjectTheme: false,
            // clustor states starts here
            clusterModal: false,
            projectTypeSelectedOption: null,
            projectIDSelectedOption: null,
            clustorProjects: [],
            guidSelectedOption: null,
            showHirearchy: true,
            showTreeHirearchy: false,
            clusterData: [],
            isClusterSaveDisabled: true,


            ideasDB: [],
            ideasDBDoc: [],
            approvalSelectedOption: [],


            // ....DB states start here...
            dbProjectType: [],
            mucIDSelectedOption: [],
            dbToolUsed: [],
            otherDBToolUsedText: "",
            modalsOperationsSelectedOption: { value: 0, label: "Deployed in Client Environment" },
            trackingApplicableSelectedOption:
                { value: 0, label: 'Yes' },
            sustenanceMetricFreq: [],
            dbspocComments: "",
            muciIDTaggedSelectedOption: [],
            dbThemeCapturedCorrectlySelectedOption: [],
            toolsCapturedCorrectlySelectedOption: [],
            modelDetailsCapturedSelectedOption: [],
            modelDeploymentDetailsSelectedOption: [],
            signOffMeetSelectedOption: [],
            monetizationDetailsCapturedSelectedOption: [],
            susDetailsOkSelectedOption: [],
            anyOtherIssueSelectedOption: [],
            exceptionAppliedSelectedOption: [],
            dbSpocApprovalSelectedOption: [],
            replicabilityIndex: [],



            IdeasSSSurvey:[],
            displaySurvey1:false,
            displaySurvey2:false,
            displaySurvey3:false,

            surveyFieldsDisabled1:false,
            surveyFieldsDisabled2:false,
            surveyFieldsDisabled3:false,


            ActualRuns1:"",
            Remarks1 :"",
            IsRetrained1 : [],
            ModelAccuracy1 : "",
            compliance1 :"",
            IsModelPrediction1 : [],
            MetricsValue1 :"",
            IsModelActive1:[],


            ActualRuns2:"",
            Remarks2 :"",
            IsRetrained2 : [],
            ModelAccuracy2 : "",
            compliance2 :"",
            IsModelPrediction2 : [],
            MetricsValue2 :"",
            IsModelActive2:[],


            ActualRuns3:"",
            Remarks3 :"",
            IsRetrained3 : [],
            ModelAccuracy3 : "",
            compliance3 :"",
            IsModelPrediction3 : [],
            MetricsValue3 :"",
            IsModelActive3:[],

            ExpectedRuns:"",
            validateForm1:false,
            validateForm2:false,
            validateForm3:false,


            campaignList: [],
            campaignInsight:"",

            
            //AI
            ideasAI: [],
            isIdeaReplicable:"",
        }
        this.isExceedlimit = false;
        this.isFtePresent = false;
    }



    async componentDidMount() {
        Toast.loading("Fetching Idea...", () => { });
        await this.fetchIdea();
        Toast.hide();
        Toast.loading("Fetching Metadata...", () => { });
        await this.fetchMetadata();
        Toast.hide();
    }

    fetchMetadata = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "",
                EntityName: ""
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("fetch metadata", data)
                    this.setState({
                        workFlowStatusList: data ? data.WorkflowStatus : [],
                        certificationLevelsList: data ? data.CertificationLevels : [],
                        certificationWorkTypeList: data ? data.WorkType : [],
                        transformationLeversList: data ? data.TransformationLevers : [],
                        methodologyList: data ? data.Methodologies : [],
                        businessImpactMasterList: data ? data.BIMasterDataVerseNew : [],

                        biThreshold: data.BIQCThreshold ? data.BIQCThreshold[0] ? data.BIQCThreshold[0].pg_threshold : 0 : 0,
                        vrconditiontype: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_vrconditiontype : [] : [],
                        vrVerticalMapping: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_vertical ? data.BIQCVRMapping[0].pg_vertical.split("|") : "ALL" : "ALL" : "ALL",
                        vrRegionMapping: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_region ? data.BIQCVRMapping[0].pg_region.split("|") : "ALL" : "ALL" : "ALL",
                        campaignList: data ? data.Campaigns : [],

                    }, () => {
                        this.setSelectedOptions();

                        if(this.state.ideaItem.CampaignName && this.state.campaignList){
                            this.state.campaignList.map((campaign)=>{
                              if(campaign.CampaignName === this.state.ideaItem.CampaignName){
                                this.setState({campaignInsight:campaign.CampaignDescr},()=>{})
                              }
                            })
                        }
                        // let filteredImpactType = this.state.businessImpactMasterList.map(item=>item.pg_impacttype).filter(
                        //     (value,index,self)=>self.indexOf(value)===index
                        // )
                        // filteredImpactType = Object.entries(filteredImpactType)
                        // this.setState({filteredImpactTypeState:filteredImpactType},()=>{})

                        // let filtereedMonetizabletype = this.state.businessImpactMasterList.map(item=>item.pg_monetizabletype).filter(
                        //     (value,index,self)=>self.indexOf(value)===index
                        // )
                        // filtereedMonetizabletype = Object.entries(filtereedMonetizabletype)
                        // this.setState({filteredMonetizableTypeState:filtereedMonetizabletype},()=>{})

                        // let filteredBiSubCategory = this.state.businessImpactMasterList.map(item=>item.pg_bisubcategory).filter(
                        //     (value,index,self)=>self.indexOf(value)===index
                        // )
                        // filteredBiSubCategory = Object.entries(filteredBiSubCategory)
                        // this.setState({filteredBISubCategoryState:filteredBiSubCategory},()=>{})

                        resolve();
                    })
                })
                .catch(error => {
                    Toast.fail('Error occured while getting metadata idea. Please try again!', 3000, () => {
                        console.log(JSON.stringify(error));
                        this.setState({
                            workFlowStatusList: [],
                            certificationLevelsList: [],
                            certificationWorkTypeList: [],
                            transformationLeversList: [],
                            methodologyList: [],
                            typeOfImpactOriginalList: [],
                            typeOfImpactList: [],
                            businessImpactCategoriesList: [],
                            businessImpactSubCategoriesList: [],
                            businessImpactMasterList: []
                        }, () => {
                            resolve();
                        })
                    });

                });
        });
    }

    setSelectedOptions = () => {
        this.state.workFlowStatusList.map((item, index) => {
            if (this.state.ideaItem.WorkflowStatus === item.WorkflowStatusName) {
                this.setState({
                    workFlowStatusSelectedOption: {
                        value: item.WorkflowStatusId,
                        label: item.WorkflowStatusName
                    }
                })
            }
            return 1;
        });



        this.state.certificationLevelsList.map((item) => {
            if (this.state.ideaItem.CertificationLevelId === item.CertificationLevelId) {
                this.setState({
                    certLevelsSelectedOption: {
                        value: item.CertificationLevelId,
                        label: item.CertificationLevelName
                    }
                })
            }
            return 1;
        });

        this.state.certificationWorkTypeList.map((item) => {
            if (this.state.ideaItem.CertificationWorkTypeName === item.WorkTypeName) {
                this.setState({
                    certWorkTypeSelectedOption: {
                        value: item.WorkTypeName,
                        label: item.WorkTypeName
                    }
                })
            }
            return 1;
        });

        this.state.transformationLeversList.map((item) => {
            if (this.state.ideaItem.TransformationLeverId === item.TransformationLeverId) {
                this.setState({
                    transSelectedOption: {
                        value: item.TransformationLeverId,
                        label: item.TransformationLeverName
                    }
                })
            }
            return 1;
        });

        // this.state.transformationLeversList.map((item) => {
        //     if (this.state.ideaItem.FinalTransformationLeverName === item.TransformationLeverName) {
        //         this.setState({
        //             finalTransSelectedOption: {
        //                 value: item.TransformationLeverId,
        //                 label: item.TransformationLeverName
        //             }
        //         })
        //     }
        //     return 1;
        // });

        // this.state.methodologyList.map((item) => {
        //     if (this.state.ideaItem.MethodologyTypeID === item.MethodologyId) {
        //         this.setState({
        //             methodologySelectedOptions: {
        //                 value: item.MethodologyId,
        //                 label: item.MethodologyName
        //             }
        //         })
        //     }
        //     return 1;
        // });

        this.state.typeOfImpactList.map((item) => {

            if (this.state.ideaItem.ImpactName === item.ImpactName) {
                this.setState({
                    typeOfImpactSelectedOption: {
                        value: item.ImpactId,
                        label: item.ImpactName
                    }
                });
            }
            return 1;
        });

        this.state.businessImpactCategoriesList.map((item) => {
            if (this.state.ideaItem.BusinessImpactName === item.BusinessImpactName) {
                this.setState({
                    businessImpactSelectedOption: {
                        value: item.BusinessImpactId,
                        label: item.BusinessImpactName
                    }
                })
            }
            return 1;
        });

        this.state.businessImpactSubCategoriesList.map((item) => {
            if (this.state.ideaItem.BusinessImpactSubName === item.BusinessImpactSubName) {
                this.setState({
                    businessSubCatSelectedOption: {
                        value: item.BusinessImpactSubId,
                        label: item.BusinessImpactSubName
                    }
                })
            }
            return 1;
        });
    }

    fetchIdea = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&RecID=''&isItIdeaOrGenAI='Idea'`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("fetch idea", data)
                    // const projectDeckMetadata = data.ResultSets.Table1[0].ProjectPPTDeck ? data.ResultSets.Table1[0].ProjectPPTDeck : "";
                    // const signOffMetadata = data.ResultSets.Table1[0].CustomerLeadSignOff ? data.ResultSets.Table1[0].CustomerLeadSignOff : "";
                    const projectFilename = data.ResultSets.Table1[0].ProjectPPTFileName ? data.ResultSets.Table1[0].ProjectPPTFileName : "";
                    const signOffFilename = data.ResultSets.Table1[0].CustomerSignoffFileName ? data.ResultSets.Table1[0].CustomerSignoffFileName : "";
                    const projectFileType = data.ResultSets.Table1[0].ProjectPPTType ? data.ResultSets.Table1[0].ProjectPPTType : "";
                    const signOffFileType = data.ResultSets.Table1[0].CustomerSignoffType ? data.ResultSets.Table1[0].CustomerSignoffType : "";

                    // const projectContent = "data:" + projectFileType + ";base64," + projectDeckMetadata;
                    // const signOffContent = "data:" + signOffFileType + ";base64," + signOffMetadata;
                    this.setState({
                        ideaItem: data.ResultSets.Table1[0],
                        reviewComments: data.ReviewComments.Table1,
                        finalReviewComments: data.FinalReviewComments.Table1,
                        workflowStatus: data.ResultSets.Table1[0].WorkflowStatus,
                        Comment: data.ResultSets.Table1[0].Comment,
                        projectDeckFilename: projectFilename,
                        signOffFilename: signOffFilename,
                        projectFileType: projectFileType,
                        signOffFileType: signOffFileType,
                        // projectDeckContent: projectContent,
                        // signOffContent: signOffContent,
                        finalComment: data.ResultSets.Table1[0].FinalComments,
                        finalImpactDollar: data.ResultSets.Table1[0].FinalImpactDollar,
                        finalImpactFTE: data.ResultSets.Table1[0].FinalImpactFTE,
                        expectedDate: data.ResultSets.Table1[0].ExpectedFinishDate === null ? new Date() : new Date(data.ResultSets.Table1[0].ExpectedFinishDate),
                        otherTools: data.ResultSets.Table1[0].FinalOtherTools,
                        bestClass: data.ResultSets.Table1[0].IsBestInClass,
                        showSourceId: data.ResultSets.Table1[0].FinalTransformationLeverName === "Digital" ? true : false,
                        dataBridgeCampaign: (data.ResultSets.Table1[0].CampaignName === DATA_BRIDGE_CAMPAIGN),
                        xPollinationCampaign: (data.ResultSets.Table1[0].CampaignName === X_POLLINATION_CAMPAIGN),
                        ideaCreatedDate: data.ResultSets.Table1[0].CreatedDate,
                        golFilename: data.ResultSets.Table1[0].GOLFileName ? data.ResultSets.Table1[0].GOLFileName : "",
                        //golContent:,
                        golFileType: data.ResultSets.Table1[0].GOLFileType,
                        fpnaFilename: data.ResultSets.Table1[0].FPnAFileName,
                        //fpnaContent:,
                        fpnaFileType: data.ResultSets.Table1[0].FPnAFileType,
                        IsVDType: data.ResultSets.Table1[0].VDType,
                        fpnaOhr: data.ResultSets.Table1[0] ? data.ResultSets.Table1[0].FPnAOHR : null,
                        fpnaName: data.ResultSets.Table1[0] ? data.ResultSets.Table1[0].FPnAName : null,
                        IsSampleCheck: data.SampleCheck.Table1[0].IsSampleCheck,
                        qcComments: data.QcComments.Table1 ? data.QcComments.Table1 : [],
                        qcCommentsWithVD: data.QcComments.Table2 ? data.QcComments.Table2 : [],
                        filename1: data.ResultSets.Table1[0].ProjectPPTFileName ? data.ResultSets.Table1[0].ProjectPPTFileName : "",
                        clusterData: data.ClusterData.Table1 ? data.ClusterData.Table1 : [],

                        // DB
                        ideasDB: data.ResultSets.Table3 && data.ResultSets.Table3[0].Result === "NULL" ? [] : data.ResultSets.Table3[0],
                        ideasDBDoc: data.ResultSets.Table4 && data.ResultSets.Table4[0].Result === "NULL" ? [] : data.ResultSets.Table4[0],
                        dbProjectType: data.ResultSets.Table1[0].DBProjectType,
                        mucIDSelectedOption: data.ResultSets.Table1[0].MUCIDTitle,
                        IdeasSSSurvey:data.SustenaceSurvey ? data.SustenaceSurvey.ResultSets ? data.SustenaceSurvey.ResultSets.Table1 ? data.SustenaceSurvey.ResultSets.Table1 : []:[]:[],

                    }, () => {
                        if (this.state.ideaItem.ReplicabilityIndex) {
                            REPLICABILITYOPTIONS.map((item) => {
                                if (item.value === this.state.ideaItem.ReplicabilityIndex) {
                                    this.setState({ replicabilityIndex: item }, () => { })
                                }
                            })
                        }
                        if (this.state.clusterData && this.state.clusterData.length > 0) {
                            const targetRecID = this.state.ideaItem.LeanID;
                            const filteredData = this.state.clusterData.filter(item => item.RecAlpha === targetRecID);
                            let getParentAlpa = "";
                            this.state.clusterData.map(item => {
                                if (item.RecAlpha === targetRecID) {
                                    getParentAlpa = item.ParentAlpha;
                                }
                            })
                            if (getParentAlpa) {
                                this.state.clusterData.map(item => {
                                    if (getParentAlpa === item.RecAlpha) {
                                        const selectedOption = {
                                            value: 0,
                                            label: item.ProjectType
                                        };
                                        this.setState({ projectTypeSelectedOption: selectedOption }, () => { })
                                    }
                                })
                            }

                            if (filteredData && filteredData.length > 0 && filteredData !== undefined && filteredData !== null) {
                                const selectedOption = {
                                    value: 0,
                                    label: filteredData[0].ParentAlpha
                                };
                                this.setState({ projectIDSelectedOption: selectedOption }, () => {
                                    console.log("project type selected option", this.state.projectTypeSelectedOption)
                                    if (this.state.projectTypeSelectedOption === null || this.state.projectTypeSelectedOption.label === "") {
                                        if (this.state.projectIDSelectedOption.label.startsWith("GB")) {
                                            const selectedOption = {
                                                value: 0,
                                                label: "GB"
                                            };
                                            this.setState({ projectTypeSelectedOption: selectedOption }, () => { })
                                        } else {
                                            const selectedOption = {
                                                value: 0,
                                                label: "BB"
                                            };
                                            this.setState({ projectTypeSelectedOption: selectedOption }, () => { })
                                        }
                                    }
                                })

                                this.setState({ isClusterSaveDisabled: true, displayParentAlpha: filteredData[0].ParentAlpha }, () => { })
                            } else {
                                this.setState({ displayParentAlpha: "" }, () => { })
                            }
                        } else {
                            this.setState({ displayParentAlpha: "" }, () => { })
                        }


                        if (this.state.ideaItem.CreatedDate) {
                            let created = new Date(this.state.ideaItem.CreatedDate);
                            let abc = new Date(DISPLAYPROJECTTHEME);
                            if (created.getTime() < abc.getTime()) {
                                this.setState({ displayProjectTheme: true })
                            } else {
                                this.setState({ displayProjectTheme: false })
                            }
                        }

                        //this.setCampaignName();
                        //this.setServiceLineName();
                        resolve();
                        if (this.state.ideaItem.PrevApprovalStatus === "Documents Uploaded" && this.state.ideaItem.ApprovalStatus === "Documents Uploaded") {
                            this.setState({ CommentEmpty: true, finalComment: "" }, () => { })
                        }
                        if (this.state.IsVDType === "Value Delivered to Client + Genpact") {
                            this.setState({ showClientFields: true, showGOLFields: true }, () => { })
                        }
                        if (this.state.IsVDType === "No Value Delivery") {
                            this.setState({ showClientFields: false, showGOLFields: false }, () => { })
                        }
                        if (this.state.IsVDType === "Value Delivered to Client") {
                            this.setState({ showClientFields: true, showGOLFields: false }, () => { })
                        }
                        if (this.state.IsVDType === "Value Delivered to Genpact") {
                            this.setState({ showClientFields: false, showGOLFields: true }, () => { })
                        }


                        if(this.state.IdeasSSSurvey){
                            this.state.IdeasSSSurvey.map((item,index)=>{
                                if(item.SOrd === 1){
                                    this.setState({
                                        ActualRuns1:item.ActualRuns,
                                        Remarks1 :item.Remarks,
                                        MetricsValue1 :item.MetricsValue,
                                        ModelAccuracy1 : item.ModelAccuracy,
                                        compliance1 :(item.ActualRuns/item.MetricsValue)*100,
                                        surveyFieldsDisabled1:true,
                                    })
                                    YESORNO.map(yes_or_no_item=>{
                                        if(yes_or_no_item.label === item.IsRetrained){
                                            this.setState({
                                                IsRetrained1:yes_or_no_item    
                                            },()=>{})
                                            
                                        }
                                        if(yes_or_no_item.label === item.IsModelPrediction){
                                            this.setState({
                                                IsModelPrediction1:yes_or_no_item    
                                            },()=>{})
                                            
                                        }
                                        if(yes_or_no_item.label === item.IsModelActive){
                                            this.setState({
                                                IsModelActive1:yes_or_no_item    
                                            },()=>{})
                                            
                                        }
                                    })
                                    this.calculateCompliance1()
                                }
                                if(item.SOrd === 2){
                                    this.setState({
                                        ActualRuns2:item.ActualRuns,
                                        Remarks2 :item.Remarks,
                                        MetricsValue2 :item.MetricsValue,
                                        ModelAccuracy2 : item.ModelAccuracy,
                                        compliance2 :(item.ActualRuns/item.MetricsValue)*100,
                                        surveyFieldsDisabled2:true,
                                    })
                                    YESORNO.map(yes_or_no_item=>{
                                        if(yes_or_no_item.label === item.IsRetrained){
                                            this.setState({
                                                IsRetrained2:yes_or_no_item    
                                            },()=>{})
                                            
                                        }
                                        if(yes_or_no_item.label === item.IsModelPrediction){
                                            this.setState({
                                                IsModelPrediction2:yes_or_no_item    
                                            },()=>{})
                                            
                                        }
                                        if(yes_or_no_item.label === item.IsModelActive){
                                            this.setState({
                                                IsModelActive2:yes_or_no_item    
                                            },()=>{})
                                            
                                        }
                                    })
                                    this.calculateCompliance2()
                                }
                                if(item.SOrd === 3){
                                    this.setState({
                                        ActualRuns3:item.ActualRuns,
                                        Remarks3 :item.Remarks,
                                        MetricsValue3 :item.MetricsValue,
                                        ModelAccuracy3 : item.ModelAccuracy,
                                        compliance3 :(item.ActualRuns/item.MetricsValue)*100,
                                        surveyFieldsDisabled3:true,
                                    })
                                    YESORNO.map(yes_or_no_item=>{
                                        if(yes_or_no_item.label === item.IsRetrained){
                                            this.setState({
                                                IsRetrained3:yes_or_no_item    
                                            },()=>{})
                                            
                                        }
                                        if(yes_or_no_item.label === item.IsModelPrediction){
                                            this.setState({
                                                IsModelPrediction3:yes_or_no_item    
                                            },()=>{})
                                            
                                        }
                                        if(yes_or_no_item.label === item.IsModelActive){
                                            this.setState({
                                                IsModelActive3:yes_or_no_item    
                                            },()=>{})
                                            
                                        }
                                    })
                                    this.calculateCompliance3()
                                }
                            })
                        }
                        if(this.state.ideasDB.SustenanceFreq){
                            if(this.state.ideasDB.SustenanceFreq === "Daily"){
                                this.setState({ExpectedRuns:30},()=>{})
                            }
                            if(this.state.ideasDB.SustenanceFreq === "Weekly"){
                                this.setState({ExpectedRuns:4},()=>{})
                            }
                            if(this.state.ideasDB.SustenanceFreq === "Fortnightly"){
                                this.setState({ExpectedRuns:2},()=>{})
                            }
                            if(this.state.ideasDB.SustenanceFreq === "Monthly"){
                                this.setState({ExpectedRuns:1},()=>{})
                            }
                        }


                        if(this.state.ideaItem.ApprovalStatus && this.state.ideaItem.ApprovalStatus === "Completed"
                            && this.state.ideaItem.TransformationLeverName==="Data" && this.state.ideasDB.IsSustenance === "Yes"
                        ){
                            if(this.state.ideaItem.CompleteDate){
                                const CompleteDate = new Date(this.state.ideaItem.CompleteDate);
                                //const CompleteDate = new Date("2024-02-01T08:03:49");
                                const currentDate = new Date();
                                const differenceInMilliseconds = currentDate - CompleteDate;
                                const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
                                console.log("difference in days =>",differenceInDays)
                                if (differenceInDays > 30 && differenceInDays < 60){
                                    this.setState({displaySurvey1:true, validateForm1:true},()=>{})
                                } 
                                if (differenceInDays > 60 && differenceInDays < 90){
                                    this.setState({displaySurvey1:true,displaySurvey2:true, validateForm2:true},()=>{})
                                } 
                                if (differenceInDays > 90 && differenceInDays < 120){
                                    this.setState({
                                        displaySurvey1:true,
                                        displaySurvey2:true,
                                        displaySurvey3:true,
                                        validateForm3:true
                                    },()=>{})
                                } 
                            }
                            
                        }
                    });
                })
                .catch(error => {
                    Toast.fail("Error while fetching idea. Please try again!", 3000, () => { });
                    reject();
                });
        });
    }

    

    validate = () => {
        let isValid = true;
        this.isExceedlimit = false;
        let styleStateObj = this.state.selectStyle;



        // Sustenance metrix 1 validations starts here
        if (this.state.ideaItem.ApprovalStatus && this.state.ideaItem.ApprovalStatus === COMPLETED_STATUS &&
            this.state.ideasDB.IsSustenance && this.state.ideasDB.IsSustenance === "Yes"
        ) {

            if(this.state.validateForm1){
                if(!this.state.IsModelActive1 || this.state.IsModelActive1.length === 0 ||
                    this.state.IsModelActive1 === null || this.state.IsModelActive1 === undefined ){
                        isValid = false;
                        styleStateObj.IsModelActive1 = selectStyle(false);
                        this.setState({selectStyle: styleStateObj})
                    }
                if (this.state.IsModelActive1 && this.state.IsModelActive1.label === "Yes") {
                    if (
                        !this.state.IsModelPrediction1 ||
                        this.state.IsModelPrediction1.length === 0 ||
                        this.state.IsModelPrediction1 === null ||
                        this.state.IsModelPrediction1 === undefined
                    ) {
                        isValid = false;
                        styleStateObj.IsModelPrediction1 = selectStyle(false);
                        this.setState({selectStyle: styleStateObj})
                    }else{
                        styleStateObj.IsModelPrediction1 = selectStyle(true);
                        this.setState({selectStyle: styleStateObj})
                    }

                    if (
                        !this.state.IsRetrained1 ||
                        this.state.IsRetrained1.length === 0 ||
                        this.state.IsRetrained1 === null ||
                        this.state.IsRetrained1 === undefined
                    ) {
                        isValid = false;
                        styleStateObj.IsRetrained1 = selectStyle(false);
                        this.setState({selectStyle: styleStateObj})
                    } else{
                        styleStateObj.IsRetrained1 = selectStyle(true);
                        this.setState({selectStyle: styleStateObj})
                    }
                    if (
                        !this.state.ActualRuns1 ||
                        this.state.ActualRuns1 === "" ||
                        this.state.ActualRuns1 === undefined ||
                        this.state.ActualRuns1 === null
                    ) {
                        isValid = false;
                        styleStateObj.ActualRuns1 = textBoxValidation(false);
                        this.setState({selectStyle: styleStateObj})
                    } else{
                        styleStateObj.ActualRuns1 = textBoxValidation(true);
                        this.setState({selectStyle: styleStateObj})
                    }

                    if (
                        !this.state.Remarks1 ||
                        this.state.Remarks1 === "" ||
                        this.state.Remarks1 === undefined ||
                        this.state.Remarks1 === null
                    ) {
                        isValid = false;
                        styleStateObj.Remarks1 = textBoxValidation(false);
                        this.setState({selectStyle: styleStateObj})
                    } else {
                        if(this.state.Remarks1.length > 1000){
                            isValid = false;
                            styleStateObj.Remarks1 = textBoxValidation(false);
                            this.setState({selectStyle: styleStateObj})
                            Toast.fail("Sustenance Remark comments cannot be more than 1000 characters, please change and resubmit.",3000);
                        }else{
                            styleStateObj.Remarks1 = selectStyle(true);
                            this.setState({selectStyle: styleStateObj})
                        }
                    }
                    if (
                        !this.state.ModelAccuracy1 ||
                        this.state.ModelAccuracy1 === "" ||
                        this.state.ModelAccuracy1 === undefined ||
                        this.state.ModelAccuracy1 === null
                    ) {
                        isValid = false;
                        styleStateObj.ModelAccuracy1 = textBoxValidation(false);
                        this.setState({selectStyle: styleStateObj})
                    } else{
                        styleStateObj.ModelAccuracy1 = textBoxValidation(true);
                            this.setState({selectStyle: styleStateObj})
                    }
                    if (
                        !this.state.MetricsValue1 ||
                        this.state.MetricsValue1 === "" ||
                        this.state.MetricsValue1 === undefined ||
                        this.state.MetricsValue1 === null
                    ) {
                        isValid = false;
                        styleStateObj.MetricsValue1 = textBoxValidation(false);
                        this.setState({selectStyle: styleStateObj})
                    } else{
                        styleStateObj.MetricsValue1 = textBoxValidation(true);
                        this.setState({selectStyle: styleStateObj})
                    }
                }
            }


            if(this.state.validateForm2){
                if(!this.state.IsModelActive2 || this.state.IsModelActive2.length === 0 ||
                    this.state.IsModelActive2 === null || this.state.IsModelActive2 === undefined ){
                        isValid = false;
                        styleStateObj.IsModelActive2 = selectStyle(false);
                        this.setState({selectStyle: styleStateObj})
                    }
                if (this.state.IsModelActive2 && this.state.IsModelActive2.label === "Yes") {
                    if (
                        !this.state.IsModelPrediction2 ||
                        this.state.IsModelPrediction2.length === 0 ||
                        this.state.IsModelPrediction2 === null ||
                        this.state.IsModelPrediction2 === undefined
                    ) {
                        isValid = false;
                        styleStateObj.IsModelPrediction2 = selectStyle(false);
                        this.setState({selectStyle: styleStateObj})
                    }else{
                        styleStateObj.IsModelPrediction2 = selectStyle(true);
                        this.setState({selectStyle: styleStateObj})
                    }

                    if (
                        !this.state.IsRetrained2 ||
                        this.state.IsRetrained2.length === 0 ||
                        this.state.IsRetrained2 === null ||
                        this.state.IsRetrained2 === undefined
                    ) {
                        isValid = false;
                        styleStateObj.IsRetrained2 = selectStyle(false);
                        this.setState({selectStyle: styleStateObj})
                    } else{
                        styleStateObj.IsRetrained2 = selectStyle(true);
                        this.setState({selectStyle: styleStateObj})
                    }
                    if (
                        !this.state.ActualRuns2 ||
                        this.state.ActualRuns2 === "" ||
                        this.state.ActualRuns2 === undefined ||
                        this.state.ActualRuns2 === null
                    ) {
                        isValid = false;
                        styleStateObj.ActualRuns2 = textBoxValidation(false);
                        this.setState({selectStyle: styleStateObj})
                    } else{
                        styleStateObj.ActualRuns2 = textBoxValidation(true);
                        this.setState({selectStyle: styleStateObj})
                    }

                    if (
                        !this.state.Remarks2 ||
                        this.state.Remarks2 === "" ||
                        this.state.Remarks2 === undefined ||
                        this.state.Remarks2 === null
                    ) {
                        isValid = false;
                        styleStateObj.Remarks2 = textBoxValidation(false);
                        this.setState({selectStyle: styleStateObj})
                    } else {
                        if(this.state.Remarks2.length > 1000){
                            isValid = false;
                            styleStateObj.Remarks2 = textBoxValidation(false);
                            this.setState({selectStyle: styleStateObj})
                            Toast.fail("Sustenance Remark comments cannot be more than 1000 characters, please change and resubmit.",3000);
                        }else{
                            styleStateObj.Remarks2 = selectStyle(true);
                            this.setState({selectStyle: styleStateObj})
                        }
                    }
                    if (
                        !this.state.ModelAccuracy2 ||
                        this.state.ModelAccuracy2 === "" ||
                        this.state.ModelAccuracy2 === undefined ||
                        this.state.ModelAccuracy2 === null
                    ) {
                        isValid = false;
                        styleStateObj.ModelAccuracy2 = textBoxValidation(false);
                        this.setState({selectStyle: styleStateObj})
                    } else{
                        styleStateObj.ModelAccuracy2 = textBoxValidation(true);
                            this.setState({selectStyle: styleStateObj})
                    }
                    if (
                        !this.state.MetricsValue2 ||
                        this.state.MetricsValue2 === "" ||
                        this.state.MetricsValue2 === undefined ||
                        this.state.MetricsValue2 === null
                    ) {
                        isValid = false;
                        styleStateObj.MetricsValue2 = textBoxValidation(false);
                        this.setState({selectStyle: styleStateObj})
                    } else{
                        styleStateObj.MetricsValue2 = textBoxValidation(true);
                        this.setState({selectStyle: styleStateObj})
                    }
                }
            }


            if(this.state.validateForm3){
                if(!this.state.IsModelActive3 || this.state.IsModelActive3.length === 0 ||
                    this.state.IsModelActive3 === null || this.state.IsModelActive3 === undefined ){
                        isValid = false;
                        styleStateObj.IsModelActive3 = selectStyle(false);
                        this.setState({selectStyle: styleStateObj})
                    }
                if (this.state.IsModelActive3 && this.state.IsModelActive3.label === "Yes") {
                    if (
                        !this.state.IsModelPrediction3 ||
                        this.state.IsModelPrediction3.length === 0 ||
                        this.state.IsModelPrediction3 === null ||
                        this.state.IsModelPrediction3 === undefined
                    ) {
                        isValid = false;
                        styleStateObj.IsModelPrediction3 = selectStyle(false);
                        this.setState({selectStyle: styleStateObj})
                    }else{
                        styleStateObj.IsModelPrediction3 = selectStyle(true);
                        this.setState({selectStyle: styleStateObj})
                    }

                    if (
                        !this.state.IsRetrained3 ||
                        this.state.IsRetrained3.length === 0 ||
                        this.state.IsRetrained3 === null ||
                        this.state.IsRetrained3 === undefined
                    ) {
                        isValid = false;
                        styleStateObj.IsRetrained3 = selectStyle(false);
                        this.setState({selectStyle: styleStateObj})
                    } else{
                        styleStateObj.IsRetrained3 = selectStyle(true);
                        this.setState({selectStyle: styleStateObj})
                    }
                    if (
                        !this.state.ActualRuns3 ||
                        this.state.ActualRuns3 === "" ||
                        this.state.ActualRuns3 === undefined ||
                        this.state.ActualRuns3 === null
                    ) {
                        isValid = false;
                        styleStateObj.ActualRuns3 = textBoxValidation(false);
                        this.setState({selectStyle: styleStateObj})
                    } else{
                        styleStateObj.ActualRuns3 = textBoxValidation(true);
                        this.setState({selectStyle: styleStateObj})
                    }

                    if (
                        !this.state.Remarks3 ||
                        this.state.Remarks3 === "" ||
                        this.state.Remarks3 === undefined ||
                        this.state.Remarks3 === null
                    ) {
                        isValid = false;
                        styleStateObj.Remarks3 = textBoxValidation(false);
                        this.setState({selectStyle: styleStateObj})
                    } else {
                        if(this.state.Remarks3.length > 1000){
                            isValid = false;
                            styleStateObj.Remarks3 = textBoxValidation(false);
                            this.setState({selectStyle: styleStateObj})
                            Toast.fail("Sustenance Remark comments cannot be more than 1000 characters, please change and resubmit.",3000);
                        }else{
                            styleStateObj.Remarks3 = selectStyle(true);
                            this.setState({selectStyle: styleStateObj})
                        }
                    }
                    if (
                        !this.state.ModelAccuracy3 ||
                        this.state.ModelAccuracy3 === "" ||
                        this.state.ModelAccuracy3 === undefined ||
                        this.state.ModelAccuracy3 === null
                    ) {
                        isValid = false;
                        styleStateObj.ModelAccuracy3 = textBoxValidation(false);
                        this.setState({selectStyle: styleStateObj})
                    } else{
                        styleStateObj.ModelAccuracy3 = textBoxValidation(true);
                            this.setState({selectStyle: styleStateObj})
                    }
                    if (
                        !this.state.MetricsValue3 ||
                        this.state.MetricsValue3 === "" ||
                        this.state.MetricsValue3 === undefined ||
                        this.state.MetricsValue3 === null
                    ) {
                        isValid = false;
                        styleStateObj.MetricsValue3 = textBoxValidation(false);
                        this.setState({selectStyle: styleStateObj})
                    } else{
                        styleStateObj.MetricsValue3 = textBoxValidation(true);
                        this.setState({selectStyle: styleStateObj})
                    }
                }
            }
        }
        return isValid;
    }






    submitIdea = () => {
        if (this.validate()) {
            if (window.confirm("Are you sure you want to submit the lean project?")) {
                Toast.loading("Updating lean project...", () => { });
                const {validateForm1,validateForm2,validateForm3}=this.state;
                let localSord="";
                let localIsModelActive = [];
                let localActualRuns = "";
                let localRemarks = ""
                let localIsRetrained = [];
                let localModelAccuracy = "";
                let localIsModelPrediction = [];
                let localMetricsValue = "";

                if(validateForm1){
                    localSord=1;
                    localIsModelActive = this.state.IsModelActive1 ? this.state.IsModelActive1.label :"";
                    localActualRuns = this.state.ActualRuns1 ? this.state.ActualRuns1 :"";
                    localRemarks = this.state.Remarks1 ? this.state.Remarks1 :"";
                    localIsRetrained = this.state.IsRetrained1 ? this.state.IsRetrained1.label :"";;
                    localModelAccuracy = this.state.ModelAccuracy1 ? this.state.ModelAccuracy1 :"";
                    localIsModelPrediction = this.state.IsModelPrediction1 ? this.state.IsModelPrediction1.label :"";;;
                    localMetricsValue = this.state.MetricsValue1 ? this.state.MetricsValue1 : "";
                }
                if(validateForm2){
                    localSord=2;
                    localIsModelActive = this.state.IsModelActive2 ? this.state.IsModelActive2.label :"";
                    localActualRuns = this.state.ActualRuns2 ? this.state.ActualRuns2 :"";
                    localRemarks = this.state.Remarks2 ? this.state.Remarks2 :"";
                    localIsRetrained = this.state.IsRetrained2 ? this.state.IsRetrained2.label :"";;
                    localModelAccuracy = this.state.ModelAccuracy2 ? this.state.ModelAccuracy2 :"";
                    localIsModelPrediction = this.state.IsModelPrediction2 ? this.state.IsModelPrediction2.label :"";;;
                    localMetricsValue = this.state.MetricsValue2 ? this.state.MetricsValue2 : "";
                }
                if(validateForm3){
                    localSord=3;
                    localIsModelActive = this.state.IsModelActive3 ? this.state.IsModelActive3.label :"";
                    localActualRuns = this.state.ActualRuns3 ? this.state.ActualRuns3 :"";
                    localRemarks = this.state.Remarks3 ? this.state.Remarks3 :"";
                    localIsRetrained = this.state.IsRetrained3 ? this.state.IsRetrained3.label :"";;
                    localModelAccuracy = this.state.ModelAccuracy3 ? this.state.ModelAccuracy3 :"";
                    localIsModelPrediction = this.state.IsModelPrediction3 ? this.state.IsModelPrediction3.label :"";;;
                    localMetricsValue = this.state.MetricsValue3 ? this.state.MetricsValue3 : "";
                }

                this.state.businessImpactValueArray =
                    this.state.businessImpactValueArray
                        .map((item) => ({
                            ...item,
                            pg_srno: item.SrNo,
                            dollarValue: item.DollarImpact,
                            fteValue: item.FTEImpact,
                        }))
                        .map((item) => {
                            return item;
                        });

                let filteredBIData = this.state.businessImpactValueArray.filter(
                    function (e) {
                        return (
                            e !== null &&
                            e !== undefined &&
                            parseFloat(e.DollarImpact) > 0 &&
                            ((e.isFTEPresent && parseFloat(e.FTEImpact) > 0) ||
                                !e.isFTEPresent)
                        );
                    }
                );

                let param = {};
                param = {
                    IdeaName: this.state.ideaItem.IdeaName,
                    CertificationLevelId: this.state.ideaItem.CertificationLevelId,
                    CertificationWorkTypeName: this.state.ideaItem.CertificationWorkTypeName,
                    CertificationLevelName: this.state.ideaItem.CertificationLevelName,
                    DigitalProjectType: this.state.ideaItem.DigitalProjectType,
                    IsDigital: this.state.ideaItem.IsDigital,
                    IsReplicationVerified: this.state.ideaItem.IsReplicationVerified,
                    LDTId: this.state.ideaItem.LDTId,
                    LDTName: this.state.ideaItem.LDTLeadName,
                    MethodologyTypeID: this.state.ideaItem.MethodologyTypeID,
                    MethodologyName: this.state.ideaItem.MethodologyName,
                    PotentialImpactDollar: this.state.ideaItem.PotentialImpactDollar,
                    PotentialImpactFTE: this.state.ideaItem.PotentialImpactFTE,
                    Problem: this.state.ideaItem.Problem,
                    Solution: this.state.ideaItem.Solution,
                    ServiceLineId: this.state.ideaItem.ServiceLineId,
                    ServiceLineName: this.state.ideaItem.ServiceLineName,
                    SubServiceLine: this.state.ideaItem.SubServiceLine,
                    NewOrReplicatedIdea: this.state.ideaItem.NewOrReplicatedIdea,
                    ReplicationProjectId: this.state.ideaItem.ReplicationProjectId,
                    TransformationLeverId: this.state.ideaItem.TransformationLeverId,
                    TransformationLeverName: this.state.ideaItem.TransformationLeverName,
                    UpdaterOHR: this.sessionData.profile.oHRId,
                    UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                    UpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                    WorkflowStatus: this.state.ideaItem.WorkflowStatus,
                    ApprovalStatus: this.state.ideaItem.ApprovalStatus,
                    FinalComments: this.state.ideaItem.FinalComments,
                    IdeaId: this.props.location.state,
                    IsBestInClass: this.state.ideaItem.IsBestInClass,
                    SourceId: "1234",
                    IsSourceInValidated: true,
                    FinalImpactDollar: this.state.ideaItem.FinalImpactDollar,
                    FinalImpactFTE: this.state.ideaItem.FinalImpactFTE,
                    TypeOfImpactName: null,
                    BusinessImpactName: null,
                    BusinessImpactSubName: null,
                    FinalTransformationLeverName: null,
                    FinalOtherTools: null,
                    IsZeroImpactProject: this.state.ideaItem.IsZeroImpactProject,
                    DataBridgeTheme: this.state.ideaItem.DataBridgeTheme,
                    SubCampaignName: this.state.ideaItem.SubCampaignName,
                    BusinessImpactData: filteredBIData,
                    CampaignName: this.state.ideaItem.CampaignName,
                    Comment: this.state.ideaItem.Comment,
                    ReplicabilityIndex: this.state.ideaItem.ReplicabilityIndex,
                    Role: "BB",
                    CompletedDate: new Date().toLocaleString(),
                    FpnaApprovalAttachedSelectedOption: this.state.showBBFieldsValue ? "Yes" : "No",
                    IsFPnAApprovalAttached: this.state.ideaItem.IsFPnAApprovalAttached,
                    ImpactTyepChanged: this.state.ideaItem.IsVDImpactTagged,
                    DeliveryCategorizedSelect: this.state.ideaItem.IsVDCategorized,
                    DeliveryComputedCorrectlySelected: this.state.ideaItem.IsVDComputed,
                    SignOffDeliverySelected: this.state.ideaItem.IsVDClientSignOff,
                    IsVDReviewed: this.state.ideaItem.IsVDReviewed,
                    QcOHR: this.state.ideaItem.QCOHR,
                    QcEmail: this.state.ideaItem.QCOHR + "@genpact.com",
                    QcUpdaterName: this.state.ideaItem.QCName,
                    FPnAOHR: this.state.ideaItem.FPnAOHR,
                    FPnAName: this.state.ideaItem.FPnAName,

                    FPnAOHRMonetization: this.state.ideaItem.FPnAOHRMonetization,
                    FPnANameMonetization: this.state.ideaItem.FPnANameMonetization,
                    SowID: this.state.ideaItem.SowID,
                    IsVDMonetizable: this.state.ideaItem.IsVDMonetizable,
                    MonetizationStatus: this.state.ideaItem.MonetizationStatus,
                    IsMbbMonetizable: this.state.ideaItem.IsMbbMonetizable,
                    VDType: this.state.ideaItem.VDType,

                    updaterAction: this.state.approvalSelectedOption? this.state.approvalSelectedOption.label === "Completed"? "Approved": this.state.approvalSelectedOption.label: "",
                    GOLNameMonetization: this.state.ideaItem.GOLName,
                    GOLOHRMonetization: this.state.ideaItem.GOLOHRMonetization,

                    // IsVDMonetizable: this.state.impactOptionSelected ? (this.state.impactOptionSelected.label=== "No Value Delivery" ? "No" : ""):"",

                    // ...DB api mapping starts here...
                    case: "new",
                    casetype: "sustenanceSurvey",
                    UpdaterRole: "BB",

                   

                    SOrd:localSord,
                    IsModelActive: localIsModelActive,
                    MetricsValue: localMetricsValue,
                    ExpectedRuns: this.state.ExpectedRuns,
                    ActualRuns: localActualRuns,
                    Remarks: localRemarks,
                    IsModelRetrained: localIsRetrained,
                    ModelAccuracy: localModelAccuracy,
                    IsModelPrediction: localIsModelPrediction,

                };

                console.log(param);
                let finalJsonData = Checksum(param);
                const requestOptions = {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization":  "Bearer " + this.accessToken,
                    },
                    body: JSON.stringify(param),
                };
                fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        Toast.hide();
                        Toast.success("Updated successfully.", 3000, () => {
                            this.props.history.push("/");
                            console.log(JSON.stringify(data));
                        });
                    });
            }
        } else {
            if (!this.isExceedlimit) {
                Toast.fail(
                    "Required fields are missing or have invalid values. Please correct and try again.",
                    3000,
                    () => { }
                );
            }
        }
        return false;
    };







    newBusinessImpactSummary() {
        let myArray = [...this.state.businessImpactValueArray];
        const groupedData = myArray.reduce((accumulator, currentValue) => {
            const impactType = currentValue.impactType;
            const monetizableType = currentValue.monetizableType;
            const fteValue = parseFloat(currentValue.fteValue) || 0.0;
            const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;

            const existingRow = accumulator.find(row => row.impactType === impactType && row.monetizableType === monetizableType);
            if (existingRow) {
                existingRow.fteValue += fteValue
                existingRow.dollarValue += dollarValue
            } else {
                accumulator.push({ impactType, monetizableType, fteValue, dollarValue });
            }
            return accumulator;
        }, []);
        this.setState({ summaryArray: groupedData }, () => { });
        this.setState({ businessImpactValueArray: this.state.businessImpactValueArray }, () => { })
        this.setState({ isFpnaRequired: false }, () => { })
        this.state.businessImpactValueArray && this.state.businessImpactValueArray.map((item) => {
            if (item.ImpactType === "Impact at Genpact") {
                if (item.MonetizableType === "Increase in Revenue" || item.MonetizableType === "Reduction in Cost") {
                    this.setState({ isFpnaRequired: true }, () => { })
                }
            }
        })
    }



    handleClassChange = (event) => {
        this.setState((previousState, currentProps) => {
            return {
                bestClass: !previousState.bestClass
            };
        });
    }

    formatDateString(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
    }

    cancelEvent = () => {
        this.props.history.push("/review-page?page=leanapproval");
    }

    renderReplicaIndexOptions = () => {
        let options = [];
        this.state.replicaIndexOption.map((item) => {
            options.push({
                value: item,
                label: item
            })
            return options;
        });
        return options;
    }

    formatDateStringWithTime(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        });
    }

    toggleTranscriptFunction = (id) => {
        this.setState({ speechModal: !this.state.speechModal, modalIndex: id })
        if (id === 7) {
            if (this.state.finalComment) {
                this.setState({ previousTextForSpeech: this.state.finalComment }, () => console.log(this.state.previousTextForSpeech))
            } else {
                this.setState({ previousTextForSpeech: "" }, () => console.log(this.state.previousTextForSpeech))
            }
        }
    }

    closeModalFunction = () => {
        this.setState({ speechModal: false })
    }




    pull_data = ({ businessImpactValueArray }) => {
        this.setState({ businessImpactValueArray: businessImpactValueArray }, () => {
            this.newBusinessImpactSummary()
        })
        let dollarTotal = 0.0;
        let fteValue = 0.0;
        this.state.businessImpactValueArray.map((item) => {
            if (item && item.DollarImpact) {
                dollarTotal += parseFloat(item.DollarImpact);
            }
            if (item && item.dollarValue) {
                dollarTotal += parseFloat(item.dollarValue);
            }
            if (item && item.FTEImpact) {
                fteValue += parseFloat(item.FTEImpact)
            }
            if (item && item.fteValue) {
                fteValue += parseFloat(item.fteValue)
            }
        })
        this.setState({ finalImpactDollar: dollarTotal }, () => { })
        this.setState({ finalImpactFTE: fteValue }, () => { })
    }
    remove_bi_data_after_delete = (filteredIdeaArray) => {
        if (filteredIdeaArray) {
            this.setState({ businessImpactValueArray: filteredIdeaArray }, () => {
                this.newBusinessImpactSummary()
                let dollarTotal = 0.0;
                let fteValue = 0.0;
                this.state.businessImpactValueArray.map((item) => {
                    if (item && item.DollarImpact) {
                        dollarTotal += parseFloat(item.DollarImpact);
                    }
                    if (item && item.dollarValue) {
                        dollarTotal += parseFloat(item.dollarValue);
                    }
                    if (item && item.FTEImpact) {
                        fteValue += parseFloat(item.FTEImpact)
                    }
                    if (item && item.fteValue) {
                        fteValue += parseFloat(item.fteValue)
                    }
                })
                this.setState({ finalImpactDollar: dollarTotal }, () => {
                    //console.log("inside call back function $ total",this.state.finalImpactDollar)
                })
                this.setState({ finalImpactFTE: fteValue }, () => {
                    //console.log("inside call back function fte value",this.state.finalImpactFTE)
                })

            })
        }

    }

    pull_file_data = ({ fileName, fileType, fileContent, showClientApprovalFields
        , SowID, FPnAOHRMonetization, FPnANameMonetization }) => {
        if (fileName) {
            this.setState({ FPnAFileNameMonetization: fileName }, () => { })
        }
        if (fileType) {
            this.setState({ FPnAFileTypeMonetization: fileType }, () => { })
        }
        if (fileContent) {
            this.setState({ FPnAFileMonetization: fileContent }, () => { })
        }

        this.setState({ showClientApprovalFields: showClientApprovalFields }, () => { })
        this.setState({
            SowID: SowID,
            FPnAOHRMonetization: FPnAOHRMonetization,
            FPnANameMonetization: FPnANameMonetization
        }, () => { })
    }
    pull_monetizedTotal = ({ fteValue, dollarValue }) => {
        if (fteValue) {
            this.setState({ totalMonetizedDollar: fteValue }, () => { })
        }
        if (dollarValue) {
            this.setState({ totalMonetizedFTE: dollarValue }, () => { })
        }
    }
    pull_monetizableProject = (e) => {
        this.setState({ monetizableProjectSelectedOption: e }, () => { })
    }
    pull_monetizableStatus = (e) => {
        this.setState({ monetizationStatusSelectedOption: e }, () => { })
    }
    pull_monetization_fpna_name = (localFpnaName) => {
        this.setState({ FPnANameMonetization: localFpnaName }, () => {
        })
    }
    pull_monetization_fpna_ohr = (localFpnaohr) => {
        this.setState({ FPnAOHRMonetization: localFpnaohr }, () => { })
    }
    handleVDTypeChange = ({ e }) => {
        // console.log(e)
        // if (e) {
        //     this.setState({ impactOptionSelected: e, impactChanged: true }, () => {
        //         //console.log("after on change set state",this.state.impactOptionSelected)
        //         if (this.state.impactOptionSelected.label === "No Value Delivery") {
        //             this.setState({
        //                 showClientFields: false,
        //                 showGOLFields: false,
        //                 finalImpactDollar: 0,
        //                 monetizableProjectSelectedOption: null,
        //             }, () => { })
        //         }
        //         if (this.state.impactOptionSelected.label === "Value Delivered to Client") {
        //             this.setState({ showClientFields: true, showGOLFields: false })
        //         }
        //         if (this.state.impactOptionSelected.label === "Value Delivered to Genpact") {
        //             this.setState({ showClientFields: false, showGOLFields: true })
        //         }
        //         if (this.state.impactOptionSelected.label === "Value Delivered to Client + Genpact") {
        //             this.setState({ showClientFields: true, showGOLFields: true })
        //         }
        //     })
        // }
    }

    fetchFPAOHRforIncreaseInRevenue = (e) => {
        if (e === true) {
            this.setState({ appropriateAttachment: true }, () => { })
        } else {
            this.setState({ appropriateAttachment: false }, () => { })
        }
    }
    pull_monetization_sowId = (localSowId) => {
        this.setState({ SowID: localSowId }, () => {
            //console.log("sow id from pull_monetization_sowId function =>",this.state.SowID)
        })
    }

    pullMonetizationGOLName = (localGOLName) => {
        this.setState({ golMonetizationName: localGOLName }, () => {
            //console.log(this.state.golMonetizationName)
        })
    }
    pullMonetizationGOLOhr = (localGOLOhr) => {
        this.setState({ golMonetizationOhr: localGOLOhr }, () => {
            //console.log(this.state.golMonetizationOhr)
        })
    }


    // function for FPNAGOL component starts here

    // ppt deck file details
    pullPPTDeck = ({ fileName, fileType, fileContent }) => {
        this.setState({ filename1: fileName, fileType1: fileType, base64File1: fileContent }, () => {
            //console.log("filename ",this.state.filename1, "file type ",this.state.fileType1, "content ",this.state.base64File1)
        })
    }
    pullClientDocName = (fileName) => {
        this.setState({ signOffFilename: fileName, }, () => {
            //console.log("client approval name =>",this.state.signOffFilename)
        })
    }
    pullClientDocType = (fileType) => {
        this.setState({ signOffFileType: fileType }, () => {
            //console.log("client approval type =>",this.state.signOffFileType)
        })
    }
    pullClientDocContent = (fileContent) => {
        this.setState({ signOffContent: fileContent }, () => {
            //console.log("client approval contnet =>",this.state.signOffContent)
        })
    }

    // gol file details
    pullgolFileName = (fileName) => {
        this.setState({ filename3: fileName }, () => {
            //console.log("gol approval doc name",this.state.filename3)      
        })
    }
    pullgolFileType = (fileType) => {
        this.setState({ fileType3: fileType }, () => {
            //console.log("gol approval doc type",this.state.fileType3)      
        })
    }
    pullgolContent = (fileContent) => {
        this.setState({ base64File3: fileContent }, () => {
            //console.log("gol approval doc content",this.state.base64File3)      
        })
    }

    // fpna file details
    pullfpnafileName = (fileName) => {
        this.setState({ filename4: fileName }, () => {
            //console.log("fpna doc name",this.state.filename4)
        })
    }
    pullfpnafileType = (fileType) => {
        this.setState({ fileType4: fileType }, () => {
            //console.log("fpna doc type",this.state.fileType4)
        })
    }
    pullfpnafileContent = (fileContent) => {
        this.setState({ base64File4: fileContent }, () => {
            //console.log("fpna doc file content",this.state.base64File4)
        })
    }

    pullClientName = (e) => {
        this.setState({ clientName: e }, () => {
            //console.log(this.state.clientName)
        })
    }
    pullClientRole = (e) => {
        this.setState({ clientRole: e }, () => {
            // console.log(this.state.clientRole)
        })
    }
    pullClientSignOff = (e) => {
        this.setState({ clientSignOffDate: e }, () => {
            //console.log(this.state.clientSignOffDate)
        })
    }
    pullFpnaName = (name) => {
        this.setState({ fpnaName: name }, () => {
            //console.log(this.state.fpnaName)
        })

    }
    pullGolName = (name) => {
        this.setState({ golFullname: name }, () => {
            //console.log(this.state.golFullname)
        })
    }
    pullFpnaOhr = (e) => {
        this.setState({ fpnaOhr: e }, () => {
            //console.log(this.state.fpnaOhr)
        })
    }
    pullGolOhr = (e) => {
        this.setState({ golOhr: e }, () => {
            //console.log(this.state.golOhr)
        })
    }
    pull_fileDetails = (obj) => {
        this.setState({ golFpnaFileDetails: obj }, () => {
            //console.log(this.state.golFpnaFileDetails)
        })
    }

    // function for FPNAGOL component ends here

    // survey 1 form function starts here
    handleModelStillActiveChange1 = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsModelActive = selectStyle(true);
        this.setState({
            ActualRuns1:"",
            Remarks1 :"",
            IsRetrained1 : [],
            ModelAccuracy1 : "",
            compliance1 :"",
            IsModelPrediction1 : [],
            MetricsValue1 :"",
            IsModelActive1:{value:e.value,label:e.label},
            selectStyle: styleStateObj
        },()=>{})
    }
    handleActualRunsInaMonthChange1 = (e) => {
        if(e < 1000000000){
            let styleStateObj = this.state.selectStyle;
            styleStateObj.ActualRuns1 = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj,
                ActualRuns1:e
            },()=>{
                this.calculateCompliance1();
            })
        }else{
            window.alert("Actual runs value in a month cannot be more than 100,000,0000");
            return;
        }
        
    }

    handleSustenanceRemarksChange1 = (e) => {
        let nonWhiteSpace= e.replace(/^\s+|\s+$/gm,'');
        let styleStateObj = this.state.selectStyle;
        styleStateObj.Remarks1 = selectStyle(true);
        this.setState({selectStyle: styleStateObj})
        if(nonWhiteSpace.length < 1000){
            this.setState({
                Remarks1 : e,
            });
        }else{
            const trimmedValue = nonWhiteSpace.slice(0, 1000);
            this.setState({Remarks1 : trimmedValue});
        }
    }

    retrainedPostCompletionChange1 = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsRetrained1 = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            IsRetrained1: e
        },()=>{ });

    };
    predictionsAccurateChange1 = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsModelPrediction1 = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            IsModelPrediction1: e
        },()=>{});
    };

    handleModelAccuracyChange1=( ModelAccuracy, event,index)=>{
        let styleStateObj = this.state.selectStyle;
        let showWarning=false;
        if (/^\d{0,3}(\.\d{0,2})?$/.test(ModelAccuracy)) {
            if (ModelAccuracy <= 100) {
                styleStateObj.ModelAccuracy = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                    ModelAccuracy1: ModelAccuracy
                },()=>{ });
            }
        }
        else{
            showWarning=true;
            return;
        }
        if(showWarning){
            Toast.fail("Model accuracy value cannot be not be greater than 100. Please check and resubmit",3000,()=>{})
        }
        
    }

    handleMetricsValueRecordChange1 = (e) => {
        if (/^\d{0,9}(\.\d{0,3})?$/.test(e)){
            let styleStateObj = this.state.selectStyle;
            styleStateObj.MetricsValue1 = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj,
                MetricsValue1: e
            },()=>{
                this.calculateCompliance1()
            });
        }else{
            window.alert("You cannot enter value more than 100,000,0000");
            return;
        }
        
    }

    calculateCompliance1=()=>{
        let localCompliance = (this.state.ActualRuns1 / this.state.MetricsValue1)*100;
        let convertCompliance = Number(Math.floor(localCompliance+'e2')+'e-2');
        this.setState({
            compliance1: convertCompliance
        },()=>{
            console.log(this.state.compliance1)
        });
    }

    // survey 2 form functions starts here
    handleModelStillActiveChange2 = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsModelActive2 = selectStyle(true);
        this.setState({
            ActualRuns2:"",
            Remarks2 :"",
            IsRetrained2 : [],
            ModelAccuracy2 : "",
            compliance2 :"",
            IsModelPrediction2 : [],
            MetricsValue2 :"",
            IsModelActive2:{value:e.value,label:e.label},
            selectStyle: styleStateObj
        },()=>{})
    }
    handleActualRunsInaMonthChange2 = (e) => {
        if(e < 1000000000){
            let styleStateObj = this.state.selectStyle;
            styleStateObj.ActualRuns2 = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj,
                ActualRuns2:e
            },()=>{
                this.calculateCompliance2();
            })
        }else{
            window.alert("Actual runs value in a month cannot be more than 100,000,0000");
            return;
        }
        
    }

    handleSustenanceRemarksChange2 = (e) => {
        let nonWhiteSpace= e.replace(/^\s+|\s+$/gm,'');
        let styleStateObj = this.state.selectStyle;
        styleStateObj.Remarks2 = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
        });
        if(nonWhiteSpace.length < 1000){
            this.setState({ Remarks2 : e},()=>{})
        }else{
            const trimmedValue = nonWhiteSpace.slice(0, 1000);
            this.setState({ Remarks2 : trimmedValue},()=>{})
        }
        
    }

    retrainedPostCompletionChange2 = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsRetrained2 = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            IsRetrained2: e
        },()=>{ });

    };
    predictionsAccurateChange2 = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsModelPrediction2 = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            IsModelPrediction2: e
        },()=>{});
    };

    handleModelAccuracyChange2=( ModelAccuracy, event,index)=>{
        let styleStateObj = this.state.selectStyle;
        let showWarning=false;
        if (/^\d{0,3}(\.\d{0,2})?$/.test(ModelAccuracy)) {
            if (ModelAccuracy <= 100) {
                styleStateObj.ModelAccuracy2 = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                    ModelAccuracy2: ModelAccuracy
                },()=>{ });
            }
        }
        else{
            showWarning=true;
            return;
        }
        if(showWarning){
            Toast.fail("Model accuracy value cannot be not be greater than 100. Please check and resubmit",3000,()=>{})
        }
    }

    handleMetricsValueRecordChange2 = (e) => {
        if (/^\d{0,9}(\.\d{0,3})?$/.test(e)){
            let styleStateObj = this.state.selectStyle;
            styleStateObj.MetricsValue2 = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj,
                MetricsValue2: e
            },()=>{
                this.calculateCompliance2()
            });
        }else{
            window.alert("You cannot enter value more than 100,000,0000");
            return;
        }
        
    }

    calculateCompliance2=()=>{
        let localCompliance = (this.state.ActualRuns2 / this.state.MetricsValue2)*100;
        let convertCompliance = Number(Math.floor(localCompliance+'e2')+'e-2');
        this.setState({
            compliance2: convertCompliance
        },()=>{
            console.log(this.state.compliance2)
        });
    }

    // survey 3 form function starts here
    handleModelStillActiveChange3 = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsModelActive = selectStyle(true);
        this.setState({
            ActualRuns3:"",
            Remarks3 :"",
            IsRetrained3 : [],
            ModelAccuracy3 : "",
            compliance3 :"",
            IsModelPrediction3 : [],
            MetricsValue3 :"",
            IsModelActive3:{value:e.value,label:e.label},
            selectStyle: styleStateObj
        },()=>{})
    }
    handleActualRunsInaMonthChange3 = (e) => {
        if(e < 1000000000){
            let styleStateObj = this.state.selectStyle;
            styleStateObj.ActualRuns3 = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj,
                ActualRuns3:e
            },()=>{
                this.calculateCompliance3();
            })
        }else{
            window.alert("Actual runs value in a month cannot be more than 1000000000");
            return;
        }
        
    }

    handleSustenanceRemarksChange3 = (e) => {
        let nonWhiteSpace= e.replace(/^\s+|\s+$/gm,'');
        let styleStateObj = this.state.selectStyle;
        this.setState({selectStyle: styleStateObj,})
        styleStateObj.Remarks3 = selectStyle(true);
        if(nonWhiteSpace.length < 1000){
            this.setState({Remarks3 : e });
        }else{
            const trimmedValue = nonWhiteSpace.slice(0, 1000);
            this.setState({Remarks3 : trimmedValue});
            
        }
        
    }

    retrainedPostCompletionChange3 = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsRetrained3 = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            IsRetrained3: e
        },()=>{ });

    };
    predictionsAccurateChange3 = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsModelPrediction3 = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            IsModelPrediction3: e
        },()=>{});
    };

    handleModelAccuracyChange3=( ModelAccuracy, event,index)=>{
        let styleStateObj = this.state.selectStyle;
        let showWarning=false;
        if (/^\d{0,3}(\.\d{0,2})?$/.test(ModelAccuracy)) {
            if (ModelAccuracy <= 100) {
                styleStateObj.ModelAccuracy3 = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                    ModelAccuracy3: ModelAccuracy
                },()=>{ });
            }
        }
        else{
            showWarning=true;
            return;
        }
        if(showWarning){
            Toast.fail("Model accuracy value cannot be not be greater than 100. Please check and resubmit",3000,()=>{})
        }
    }

    handleMetricsValueRecordChange3 = (e) => {
        if (/^\d{0,9}(\.\d{0,3})?$/.test(e)){
            let styleStateObj = this.state.selectStyle;
            styleStateObj.MetricsValue3 = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj,
                MetricsValue3: e
            },()=>{
                this.calculateCompliance3()
            });
        }else{
            window.alert("You cannot enter value more than 100,000,0000");
            return;
        }
        
    }

    calculateCompliance3=()=>{
        let localCompliance = (this.state.ActualRuns3 / this.state.MetricsValue3)*100;
        let convertCompliance = Number(Math.floor(localCompliance+'e2')+'e-2');
        this.setState({
            compliance3: convertCompliance
        },()=>{
            console.log(this.state.compliance3)
        });
    }

    fetchClientTestimonyContent = () => {
        return new Promise((resolve, reject) => {
          const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization":  "Bearer " + this.accessToken,
            },
          };
          let clientTestimonyFileType = this.state.ideaItem.CTestimonyFileType;
          fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&clientTestimonycontent=true`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              const clientTestimonyFileMetadata = data.fpnaContent ? data.fpnaContent : "";
              const clientTestimonyFileContent = "data:" + clientTestimonyFileType + ";base64," + clientTestimonyFileMetadata;
              this.setState(
                {
                  CTestimony: clientTestimonyFileContent,
                },
                () => {
                  resolve();
                  Toast.hide();
                }
              );
            })
            .catch((error) => {
              Toast.fail(
                "Error while Client Testimony file content. Please try again!",
                3000,
                () => { }
              );
              resolve();
            });
        });
      };
      downloadClientTestimony = () => {
        Toast.loading("Fetching Client Testimony content...", () => { });
        this.fetchClientTestimonyContent().then(() => {
          var fileData = this.state.CTestimony;
          var fileName = this.state.ideaItem.CTestimonyFileName;
          var w = window.open("");
          const downloadLink = w.document.createElement("a");
          downloadLink.href = fileData;
          downloadLink.download = fileName;
          downloadLink.click();
          Toast.hide();
        });
      };


    
    render() {
        let IDEA_APPROVED = (this.state.ideaItem.WorkflowStatus === "Approve");
        let IDEA_REJECTED = (this.state.ideaItem.WorkflowStatus === "Reject");
        let IDEA_SEND_BACK = (this.state.ideaItem.WorkflowStatus === "Send Back");
        return (
            <>
                <section className="u-clearfix u-section-1" id="sec-0f7f">
                    <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                        <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                            <div className="u-container-layout u-container-layout-1">
                                <h4 style={{ fontWeight: 'bold', textAlign: 'center', color: '#00AECF', fontFamily: 'Arial', fontSize: 22 }}>Sustenance Metrics Performance Survey : {this.state.ideaItem.IdeaIDAlpha ? this.state.ideaItem.IdeaIDAlpha : this.state.ideaItem.IdeaId} </h4>
                                <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                                    <div className="u-container-layout u-container-layout-2" style={{ textAlign: 'center' }}>
                                        <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-3">
                                            <div className="u-container-layout u-container-layout-3">
                                                <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Owner details</p>
                                                <div className="row">
                                                    <div className="col-md-5"><span className="form-attribute">Owner OHR</span> </div>
                                                    <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerOHR} /></div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><span className="form-attribute">Owner Name</span> </div>
                                                    <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerName} /></div>
                                                </div>
                                                <div style={{ marginBottom: -10 }} />
                                                <p className="u-align-left u-text u-text-7 u-text-custom-color-3" style={{ fontSize: 11, marginBottom: 10 }}>(If Idea was raised for someone else, their OHR ID is below)</p>
                                                <div className="row">
                                                    <div className="col-md-5"><span className="form-attribute">Request For OHR</span> </div>
                                                    <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.CreatorOHR} onChange={this.handleCreatorOHRChange} placeholder="Enter OHR" /></div>
                                                </div>
                                                <div style={{ marginBottom: 10 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><span className="form-attribute">Name</span> </div>
                                                    <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.CreatorName} onChange={this.handleCreatorNameChange} placeholder="Auto Load Name" /></div>
                                                </div>
                                                <div style={{ marginBottom: 10 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Project ID</label> </div>
                                                    <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.LeanID} /></div>
                                                </div>
                                                <div style={{ marginBottom: 10 }} />
                                            </div>
                                        </div>

                                        <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 235 }}>&nbsp;</p>

                                        <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-4" style={{ marginTop: "-303px" }}>
                                            <div className="" style={{ display: "flex", marginTop: "30px" }}>
                                                <div className="u-container-style u-group u-shape-rectangle u-group-5">
                                                    <div className="u-container-layout u-container-layout-5">
                                                        <p className="u-align-left u-text u-text-8" style={{ marginBottom: 13 }}>Account Details</p>
                                                        <div className="row">
                                                            <div className="col-md-5"><span className="form-attribute">Account&nbsp;Name</span></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled list="accounts" name="accounts" placeholder="Choose Account" value={this.state.ideaItem.AccountManual} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Holding&nbsp;Account</label></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled type="text" value={this.state.ideaItem.HoldingAccount} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><span className="form-attribute">Vertical</span></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled list="vertical" name="vertical" placeholder="Choose Vertical" value={this.state.ideaItem.VerticalManual} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><span className="form-attribute">Industry&nbsp;Vertical</span></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled list="ideationtype" name="ideationtype" placeholder="Choose Industry&nbsp;Vertical" value={this.state.ideaItem.SubVerticalName} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />

                                                        {/* units and segments starts here */}
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Segment</label>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled name="segment" value={this.state.ideaItem.Segment ? this.state.ideaItem.Segment : ""} style={{ fontWeight: 'normal !important' }} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Unit</label>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled name="unit" value={this.state.ideaItem.Unit ? this.state.ideaItem.Unit : ""} style={{ fontWeight: 'normal !important' }} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        {/* units and segments ends here */}


                                                        <div className="row">
                                                            <div className="col-md-5"><span className="form-attribute">Campaign&nbsp;Name</span></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled list="campaign" name="campaign" placeholder="Choose Idea Type" value={this.state.ideaItem.CampaignName} />
                                                            </div>
                                                            <div className="">
                                                                <div className="campaign_modal_bulb campaign_tool_tip">
                                                                    <img 
                                                                        src={require("../assets/images/question_mark_2.png")}
                                                                        alt=""
                                                                        className=""
                                                                    />
                                                                    {
                                                                        this.state.campaignInsight
                                                                        ?
                                                                        <>
                                                                            <span className="my_tooltiptext">{this.state.campaignInsight}</span>
                                                                        </>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />

                                                        {
                                                            this.state.ideaItem.CampaignName === "Gen AI"
                                                                ?
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-5">
                                                                            <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Gen AI Theme</label></div>
                                                                        <div className="col-md-7">
                                                                            <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Gen AI Theme" value={this.state.ideaItem.SubCampaignName} />
                                                                        </div>
                                                                    </div>

                                                                </>
                                                                :
                                                                null
                                                        }


                                                        {
                                                            this.state.displayProjectTheme && this.state.dataBridgeCampaign && (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Data Bridge Project Theme</label></div>
                                                                        <div className="col-md-7">
                                                                            <input className="form-input" disabled list="databridgetype" name="databridgetype" placeholder="Choose Data Bridge Project Type" value={this.state.ideaItem.DataBridgeTheme} />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: 26 }} />
                                                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            this.state.xPollinationCampaign && (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Campaign</label></div>
                                                                        <div className="col-md-7">
                                                                            <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Sub Campaign" value={this.state.ideaItem.SubCampaignName} />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: 26 }} />
                                                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                                </>
                                                            )
                                                        }

                                                        {
                                                            this.state.displayParentAlpha && this.state.displayParentAlpha.length > 0 && this.state.displayParentAlpha !== null && this.state.displayParentAlpha !== undefined && this.state.displayParentAlpha !== ""
                                                                ?
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-5">
                                                                            <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Parent ID</label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <input className="form-input" disabled placeholder="Choose Parent ID" value={this.state.displayParentAlpha} />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                        <div style={{ marginBottom: 18 }}>&nbsp;</div>
                                                    </div>
                                                </div>
                                                <div className="u-container-layout u-container-layout-6">
                                                    <div className="row" style={{ marginTop: "10px" }}>
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Country</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled list="country" name="country" placeholder="Choose Country" value={this.state.ideaItem.Country} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><span className="form-attribute">Region</span></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled list="regions" name="regions" placeholder="Choose Region" value={this.state.ideaItem.RegionManual} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub-Region</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled list="subregions" name="subregions" placeholder="Choose Sub-Region" value={this.state.ideaItem.SubRegion} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><span className="form-attribute">Blackbelt&nbsp;Name</span></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled list="blackbelt" name="blackbelt" placeholder="Choose Blackbelt" title={"OHR ID: " + this.state.ideaItem.BlackBeltUserId} value={this.state.ideaItem.BlackBeltUserName} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>MBB</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="blackbelt" placeholder="Choose MBB" title={"OHR ID: " + this.state.ideaItem.MBBOHR} value={this.state.ideaItem.MBBName} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    {/* <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>LDA</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="blackbelt" placeholder="Choose LDA" title={"OHR ID: " +  this.state.ideaItem.LDAOHR} value={this.state.ideaItem.LDA} />
                                                        </div>
                                                    </div> */}
                                                    <div style={{ marginBottom: 15 }} />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />

                                        <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -25 }}>&nbsp;</p>

                                        <div id="idea1" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ marginTop: 15 }}>
                                            <div className="u-container-layout u-container-layout-7">
                                                <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                    <div className="u-container-layout u-container-layout-8">
                                                        <p id="idea1title" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Idea details</p>
                                                        <div className="row">
                                                            <div className="col-md-4"><span className="form-attribute">Service Line</span></div>
                                                            <div className="col-md-8">
                                                                <input className="form-input" disabled list="serviceline" name="serviceline" placeholder="Choose Service Line" value={this.state.ideaItem.ServiceLineName} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Service Line</label> </div>
                                                            <div className="col-md-8">
                                                                <input className="form-input" disabled name="serviceline" placeholder="Choose Sub Service Line" value={this.state.ideaItem.SubServiceLine} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><span className="form-attribute">What is the Problem ?</span></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <textarea className="form-input" disabled rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Problem} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                    <div className="u-container-layout u-container-layout-9" style={{ marginTop: 4 }}>
                                                        <div className="row">
                                                            <div className="col-md-4"><span className="form-attribute">Idea Name</span></div>
                                                            <div className="col-md-8" style={{ alignItems: 'flex-start' }}>
                                                                <input className="form-input" disabled type="text" value={this.state.ideaItem.IdeaName} placeholder="Eg: Cash flow improvement through process restructuring" />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 66 }} />
                                                        <p className="u-align-left u-text u-text-custom-color-4 u-text-25" style={{ width: "177px" }}>
                                                            <span className="u-text-black form-attribute"> How to resolve the problem</span>&nbsp;
                                                        </p>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <textarea className="form-input" disabled rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Solution} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ marginTop: 20 }}>
                                                    <div className="col-md-5"><span className="form-attribute">Are you trying to replicate an existing Idea?</span></div>
                                                    <div className="col-md-4">
                                                        <label className="switch">
                                                            <input disabled type="checkbox" checked={this.state.ideaItem.NewOrReplicatedIdea === null ? false : this.state.ideaItem.NewOrReplicatedIdea} />
                                                            <span className="slider round" />
                                                        </label>
                                                    </div>
                                                </div>

                                                {
                                                    this.state.ideaItem.NewOrReplicatedIdea && (
                                                        <>
                                                            <div className="col-md-8" style={{ marginBottom: 15, width: '100%' }} />
                                                            <div className="row">
                                                                <div className="col-md-5"><span className="form-attribute">Provide the ID of the executed Idea which you want to replicate?</span></div>
                                                                <div className="col-md-2"><input className="form-input" disabled type="text" value={this.state.ideaItem.ReplicationProjectId} /></div>
                                                            </div>
                                                        </>
                                                    )
                                                }

                                                <div className="col-md-8" style={{ marginBottom: 15 }} />
                                            </div>
                                        </div>

                                        <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />

                                        <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                            <div className="u-container-layout u-container-layout-7">
                                                <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Approval details</p>
                                                <div className="row">
                                                    <div className="col-md-2"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Approval Status</label></div>
                                                    <div className="col-md-2">
                                                        <input className="form-input" disabled name="Status" value={IDEA_APPROVED ? APPROVED : (IDEA_REJECTED ? REJECTED : (IDEA_SEND_BACK ? SENT_BACK : PENDING))} />
                                                    </div>
                                                    {/* <div className="col-md-3"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Expected timeline for execution</label></div>
                                                    <div className="col-md-2">
                                                        <input disabled name="ExpectedDate" value={this.state.ideaItem.ExpectedFinishDate ? this.formatDateString(new Date(this.state.ideaItem.ExpectedFinishDate)) : ""} />
                                                    </div> */}
                                                </div>
                                                <div className="col-md-9" style={{ marginBottom: 5, width: '100%' }} />
                                                {/* <div className="row">
                                                    <div className="col-md-12">
                                                        <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                            <span className="u-text-black form-attribute">Comments</span>
                                                        </p>
                                                    </div>
                                                </div> */}
                                                {/* <div className="row" style={{marginBottom:15}}>
                                                    <div className="col-md-12">
                                                        <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.Comment} />
                                                    </div>
                                                </div> */}
                                                <div className="row" style={{ marginTop: 15 }}>
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Comments</label></div>
                                                    <div className="col-md-2" style={{ marginLeft: 20 }}>
                                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ commentLog: !this.state.commentLog })}>Comment Log</button>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ marginTop: 5 }}>
                                                    <div className="col-md-12">
                                                        <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.Comment} />
                                                    </div>
                                                </div>
                                                <div className="text-left mt-4">
                                                    {
                                                        this.state.commentLog && this.state.reviewComments
                                                            ?
                                                            <>
                                                                <div className="custom_modal">
                                                                    <div className="modal_body">
                                                                        <div className="row">
                                                                            <div className="col-md-8">
                                                                                <h3 className="modal_title">Idea Approval Comment Log</h3>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <button type="button" className="close text-right" onClick={(e) => this.setState({ commentLog: false })}>&times;</button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                            </div>
                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                            </div>
                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                            </div>
                                                                            <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                            </div>
                                                                            <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                            </div>
                                                                            <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            this.state.reviewComments.map((item, index) => {

                                                                                return (
                                                                                    <div className="row" key={index}>
                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                        </div>
                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                        </div>
                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                        </div>
                                                                                        <div className="col-md-1 text-center role_over_flow_class" style={{ border: "1px solid lightgrey" }}>
                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === 'Delegate Action' ? 'BB' : (item.UpdaterOHR === this.state.ideaItem.BlackBeltUserId ? "BB" : (item.UpdaterOHR === this.state.ideaItem.MBBOHR ? "MBB" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Idea Owner" : "LDT"))))}</label>
                                                                                        </div>
                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? (item.WorkflowStatus !== 'Pending' ? item.WorkflowStatus : 'Idea Updated') : (item.Action === 'Delegate Action' ? 'Delegated' : (item.WorkflowStatus === 'Pending' ? 'Discard Cancellation' : item.WorkflowStatus)))}</label>
                                                                                        </div>
                                                                                        <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment ? item.Comment : '-'}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ borderBottom: '1px solid lightgrey', marginTop: 15, marginBottom: 15 }} />

                                        <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                            <div className="u-container-layout u-container-layout-7">
                                                <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                    <div className="u-container-layout u-container-layout-8">
                                                        <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Additional details</p>
                                                        <div className="row">
                                                            <div className="col-md-6"><span className="form-attribute">LDT Lead Name</span></div>
                                                            <div className="col-md-6">
                                                                <input className="form-input" disabled list="ldtName" name="ldtName" placeholder="Choose LDT Lead" title={"OHR ID: " + this.state.ideaItem.LDTId} value={this.state.ideaItem.LDTLeadName} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        {/* <div className="row">
                                                            <div className="col-md-6"><span className="form-attribute">Potential Impact (# of FTE)</span></div>
                                                            <div className="col-md-6">
                                                                <input className="form-input" disabled type="number" value={this.state.ideaItem.PotentialImpactFTE} placeholder={0} max={10} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-6"><span className="form-attribute">Potential Impact ($)</span></div>
                                                            <div className="col-md-6">
                                                                <input className="form-input" disabled type="number" value={this.state.ideaItem.PotentialImpactDollar} placeholder={0.0} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 5 }} />
                                                        <div className="u-align-left u-text u-text-custom-color-3" style={{ fontSize: 11, fontStyle: 'italic' }}>(If Potential Impact $ is greater than 250K OR FTE number is greater than 10, please review thoroughly)
                                                                </div> */}
                                                        <div style={{ marginBottom: 5 }} />
                                                        <div style={{ marginTop: 10, marginBottom: 14 }} />
                                                        {
                                                            this.state.ideaItem.NewOrReplicatedIdea && (
                                                                <div className="row">
                                                                    <div className="col-md-10">
                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}><span style={{ color: 'red' }}>*</span> Have you verified the replication id and confirm this to be replication project?</label>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label className="switch">
                                                                            <input disabled type="checkbox" checked={this.state.ideaItem.IsReplicationVerified} />
                                                                            <span className="slider round" />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 25 }}>&nbsp;</p>

                                                <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                    <div className="u-container-layout u-container-layout-9" style={{ marginTop: '180px' }}>
                                                        <p className="visible-md-block visible-sm-block visible-xs-block">&nbsp;</p>
                                                        <div className="row">
                                                            <div className="col-md-5"><span className="form-attribute">Certification Level</span> </div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled name="certlevel" value={this.state.ideaItem.CertificationLevelName} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><span className="form-attribute">Transformation Lever</span> </div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled name="translever" value={this.state.ideaItem.TransformationLeverName} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 15 }} />

                                                        {
                                                            this.state.ideaItem.DigitalProjectType
                                                                ?
                                                                <>
                                                                    <div style={{ marginBottom: 15 }} />
                                                                    <div className="row">
                                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Digital Project Type</label> </div>
                                                                        <div className="col-md-7">
                                                                            <input className="form-input" disabled value={this.state.ideaItem.DigitalProjectType} list="transformationLever" name="transformationLever" placeholder="Transformation Lever Level" />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                        <div style={{ marginBottom: 15 }} />

                                                        {
                                                            this.state.ideaItem.CertificationWorkTypeName !== null && this.state.ideaItem.CertificationWorkTypeName !== ""
                                                                ?
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Work Type</label></div>
                                                                        <div className="col-md-7">
                                                                            <input className="form-input" disabled list="certificationWorkType"
                                                                                value={this.state.ideaItem.CertificationWorkTypeName} name="certificationWorkType" />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                        <div style={{ marginBottom: 15 }} />


                                                        <div className="row">
                                                            <div className="col-md-5"><span className="form-attribute">Tools/Methodology Used</span></div>
                                                            <div className="col-md-7">
                                                                <input className="form-input" disabled name="methodology" value={this.state.ideaItem.MethodologyName} />
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: 5 }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>





                                        {
                                            (this.state.ideaItem.ApprovalStatus && this.state.finalReviewComments)
                                            && (
                                                <>
                                                    <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />
                                                    <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                        <div className="u-container-layout u-container-layout-7">
                                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Lean Approval details</p>
                                                            <div className="row">
                                                                <div className="col-md-2"><span className="form-attribute">Approval Status</span> </div>
                                                                <div className="col-md-2">
                                                                    <input className="form-input" disabled name="finalapprovalstatus" value={this.state.ideaItem.ApprovalStatus} />
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 15 }}>
                                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Approval Comments</label></div>
                                                                <div className="col-md-2" style={{ marginLeft: 20 }}>
                                                                    <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ finalCommentLog: !this.state.finalCommentLog })}>Comment Log</button>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 5 }}>
                                                                <div className="col-md-12">
                                                                    <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.FinalComments} />
                                                                </div>
                                                            </div>
                                                            <div className="text-left mt-4">
                                                                {
                                                                    this.state.finalCommentLog && this.state.finalReviewComments
                                                                        ?
                                                                        <>
                                                                            <div className="custom_modal">
                                                                                <div className="modal_body">
                                                                                    <div className="row">
                                                                                        <div className="col-md-8">
                                                                                            <h3 className="modal_title">Lean Approval Comment Log</h3>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <button type="button" className="close text-right" onClick={(e) => this.setState({ finalCommentLog: false })}>&times;</button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                        </div>
                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                        </div>
                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                        </div>
                                                                                        <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                        </div>
                                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                        </div>
                                                                                        <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        this.state.finalReviewComments.map((item, index) => {

                                                                                            return (
                                                                                                <div className="row" key={index}>
                                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === 'Idea was reviewed by MBB' ? "MBB" : (item.Action === 'Idea was reviewed by LDT' ? "LDT" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Idea Owner" : "BB")))}</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? item.ApprovalStatus : (item.WorkflowStatus === 'Completed' || item.WorkflowStatus === 'Approve' || item.WorkflowStatus === 'Approved') ? 'Approved' : ((item.WorkflowStatus === 'Rejected' || item.WorkflowStatus === 'Reject') ? "Rejected" : (item.WorkflowStatus === REQUEST_CANCELLATION ? REQUEST_CANCELLATION : (item.WorkflowStatus === 'Pending Documents' ? 'Discard Cancellation' : item.WorkflowStatus === 'Cancelled' ? 'Cancelled' : "Sent Back"))))}</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment ? item.Comment : '-'}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />

                                        {
                                            this.state.ideaItem.CertificationLevelName === "Lean"
                                                ?
                                                <>
                                                    <FPNAGOL IdeaId={this.state.ideaItem.IdeaId} viewIdea={true}
                                                        vdSelected={this.state.impactOptionSelected ? this.state.impactOptionSelected.label : this.state.ideaItem.VDType}
                                                        fetchPPTDeck={this.pullPPTDeck}
                                                        fetchClientDocName={this.pullClientDocName}
                                                        fetchClientDocType={this.pullClientDocType}
                                                        fetchClientDocContent={this.pullClientDocContent}
                                                        fetchGolDocName={this.pullgolFileName}
                                                        fetchGolDocType={this.pullgolFileType}
                                                        fetchGolDocContent={this.pullgolContent}
                                                        fetchfpnaDocName={this.pullfpnafileName}
                                                        fetchfpnaDocType={this.pullfpnafileType}
                                                        fetchfpnaDocContent={this.pullfpnafileContent}
                                                        fetchClientName={this.pullClientName}
                                                        fetchClientRole={this.pullClientRole}
                                                        fetchClientSignOffDate={this.pullClientSignOff}
                                                        fetchFpnaName={this.pullFpnaName}
                                                        fetchGolName={this.pullGolName}
                                                        fetchFpnaOhr={this.pullFpnaOhr}
                                                        fetchGolOhr={this.pullGolOhr}
                                                        fetchFileDetails={this.pull_fileDetails}
                                                        sendBitoFPNA={this.state.businessImpactValueArray}
                                                        sendIsFpnaRequired={this.state.isFpnaRequired}
                                                    />
                                                </>

                                                :
                                                null
                                        }

                                        {
                                            this.state.impactOptionSelected
                                                ?
                                                <>
                                                    <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                        <div className="u-container-layout u-container-layout-7" style={{ borderBottom: "1px solid lightgray", paddingBottom: "35px", marginBottom: "20px" }}>
                                                            <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                                <div className="u-container-layout u-container-layout-8">
                                                                    <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Final additional details</p>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <span className="form-attribute">Final Impact (# of FTE)</span> </div>
                                                                        <div className="col-md-6">
                                                                            <input className="form-input" disabled type="number"
                                                                                value={this.state.finalImpactFTE}
                                                                                placeholder={0} max={10} style={{ ...this.state.selectStyle.FinalImpactFTE }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: 15 }} />
                                                                    <div className="row">
                                                                        <div className="col-md-6"><span className="form-attribute">Final Impact ($)</span> </div>
                                                                        <div className="col-md-6">
                                                                            <input className="form-input" disabled type="number"
                                                                                value={this.state.finalImpactDollar}
                                                                                placeholder={0.0}
                                                                                style={{ ...this.state.selectStyle.FinalImpactDollar }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: 5 }} />
                                                                    <div className="u-align-left u-text u-text-custom-color-3" style={{ fontSize: 11, fontStyle: 'italic' }}>(If Final Impact $ is greater than 250K OR Final FTE number is greater than 10, please review thoroughly)</div>
                                                                    <div style={{ marginTop: 5, marginBottom: 15 }} />
                                                                    <div className="row">
                                                                        <div className="col-md-5">
                                                                            <span className="form-attribute">Is it a Zero Impact Project</span>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label className="switch">
                                                                                <input type="checkbox" id="isZeroImpactProject" checked={this.state.impactOptionSelected && this.state.impactOptionSelected.label === "No Value Delivery" ? true : false} />
                                                                                <span className="slider round" />
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: 22 }} />
                                                                </div>
                                                            </div>
                                                            <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 22 }}>&nbsp;</p>
                                                            <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                                <div className="u-container-layout u-container-layout-9" style={{ marginTop: 90, marginBottom: 36 }}>
                                                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: '-100px' }}>&nbsp;</p>
                                                                    <div className="row">
                                                                        <div className="col-md-5">
                                                                            <span className="form-attribute">Best in Class</span>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label className="switch best_in_class_slider">
                                                                                <input disabled type="checkbox" id="isReplicationIdeaBtn" checked={this.state.bestClass} value={this.state.bestClass} onClick={this.handleClassChange} />
                                                                                <span className="slider round" />
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ marginTop: 15 }}>
                                                                        <div className="col-md-5"><span style={{ cursor: 'pointer' }} className="form-attribute" title="Replicability Index is the measure of ‘ease of replication’ or ‘how easily can this same project be implemented’ in another Account. The higher the rating, the easier it is to replicate in another account and vice versa.">Replicability Index</span> </div>
                                                                        <div className="col-md-6">
                                                                            <input className="form-input disabled_input" disabled
                                                                                value={this.state.replicabilityIndex ? this.state.replicabilityIndex.label : ""}
                                                                                placeholder={0.0}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ marginBottom: 15 }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                null
                                        }


                                        {
                                            this.state.ideaItem.CertificationLevelName === "Lean" && this.state.ideaItem.IdeaId
                                                ?
                                                <>
                                                    <ValueDelivery func={this.pull_data} viewIdea={true}
                                                        vdType={this.handleVDTypeChange}
                                                        IdeaId={this.state.ideaItem.IdeaId}
                                                        fetchMonetizationFileDetails={this.pull_file_data}
                                                        bbMonetizationTotal={this.pull_monetizedTotal}
                                                        monetizableProject={this.pull_monetizableProject}
                                                        monetizableStatus={this.pull_monetizableStatus}
                                                        fetchFPAOHRforIncreaseInRevenue={this.fetchFPAOHRforIncreaseInRevenue}
                                                        bbMonetizationApproval={false}
                                                        postFpnaValid={false}
                                                        fetchMonetizationSowID={this.pull_monetization_sowId}
                                                        fetchDeletedBIRows={this.remove_bi_data_after_delete}
                                                        fetchMonetizationFpnaOhr={this.pull_monetization_fpna_ohr}
                                                        fetchMonetizationFpnaName={this.pull_monetization_fpna_name}
                                                        fetchMonetizationGOLName={this.pullMonetizationGOLName}
                                                        fetchMonetizationGOLOhr={this.pullMonetizationGOLOhr}
                                                    />
                                                    {
                                                        this.state.ideaItem.VDType && this.state.ideaItem.VDType === "No Value Delivery"
                                                        ?
                                                        <>
                                                        
                                                        </>
                                                        :
                                                        <div className="" style={{ borderBottom: "1px solid lightgrey" }}/> 
                                                    }
                                                </>
                                                :
                                                null
                                        }



                                        {
                                            this.state.ideaItem.QCName && this.state.ideaItem.QCOHR
                                                ?
                                                <>
                                                    <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                        <div className="u-container-layout u-container-layout-7" style={{ borderBottom: " 1px solid lightgray", paddingTop: "40px" }}>
                                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Quality Check Details Approval</p>
                                                            <div className="row" style={{ marginTop: 15 }}>
                                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: "-13px" }}>Approval Comments</label></div>
                                                                <div className="col-md-2" style={{ marginLeft: 20 }}>
                                                                    <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ qcCommentLog: true })}>Comment Log</button>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: 5 }}>
                                                                <div className="col-md-12">
                                                                    <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.FinalComments} />
                                                                </div>
                                                            </div>
                                                            <div className="text-left mt-4">
                                                                {
                                                                    this.state.qcCommentLog && this.state.qcComments
                                                                        ?
                                                                        <>
                                                                            <div className="custom_modal">
                                                                                <div className="modal_body">
                                                                                    <div className="row">
                                                                                        <div className="col-md-8">
                                                                                            <h3 className="modal_title">QC Comment Log</h3>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <button type="button" className="close text-right" onClick={(e) => this.setState({ qcCommentLog: false })}>&times;</button>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="qc_responsive_comment_log">
                                                                                        <div className="table table-responsive table-bordered">
                                                                                            <thead className='text-center'>
                                                                                                <tr>
                                                                                                    <th className="qcHeaderbg qcVDHeader">
                                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                                    </th>
                                                                                                    <th className="qcHeaderbg qcVDHeader">
                                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                                    </th>
                                                                                                    <th className="qcHeaderbg qcVDHeader">
                                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                                    </th>
                                                                                                    <th className="qcHeaderbg qcVDHeader">
                                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                                    </th>
                                                                                                    <th className="qcHeaderbg qcVDHeader">
                                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                                    </th>
                                                                                                    <th className="qcHeaderbg" style={{ minWidth: "250px" }}>
                                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                                    </th>
                                                                                                    <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthOne">
                                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Is the impact correctly tagged to Genpact or Client ?</label>
                                                                                                    </th>
                                                                                                    <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthTwo">
                                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Has the Value Delivery been correctly categorized ?</label>
                                                                                                    </th>
                                                                                                    <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthThree">
                                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Has the Value Delivery been computed correctly ?</label>
                                                                                                    </th>
                                                                                                    <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthFour">
                                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Do the value delivery approvals (applicable as per the value playbook) clearly articulate what improved and for which time-period ?</label>
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    this.state.qcComments.map((item, index) => {
                                                                                                        return (
                                                                                                            <tr key={index}>
                                                                                                                <td>
                                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === "Delegate Action" ? 'BB' : (item.UpdaterOHR === this.state.MBBOHR ? 'MBB' : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Idea Owner" : (item.ApprovalStatus === "Pending Documents" && item.WorkflowStatus === "Approved") ? "BB" : (item.ApprovalStatus === "NA" && item.WorkflowStatus === "Pending") ? "BB" : item.UpdaterRole)))}</label>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {/* <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.ApprovalStatus==="Documents Uploaded" ? "Send Back" : ((item.WorkflowStatus==="Rejected" || item.WorkflowStatus==="Reject" || item.ApprovalStatus==="Reject" || item.ApprovalStatus==="Rejected" )? "Rejected ":"Approved")}</label> */}
                                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.ApprovalStatus === "Documents Uploaded" ? "Send Back" : ((item.WorkflowStatus === "Rejected" || item.WorkflowStatus === "Reject" || item.ApprovalStatus === "Reject" || item.ApprovalStatus === "Rejected") ? "Rejected " : (item.Action === "Idea was Rework - QC" ? "Rework" : "Approved"))}</label>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment !== '' ? item.Comment : '-'}</label>
                                                                                                                </td>
                                                                                                                {
                                                                                                                    this.state.qcCommentsWithVD && this.state.qcCommentsWithVD.length > 0
                                                                                                                        ?
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                this.state.qcCommentsWithVD.map((pg_item) => {
                                                                                                                                    if (Number(pg_item.ProjectTeamID) === item.TrackId) {
                                                                                                                                        return (
                                                                                                                                            <>
                                                                                                                                                <td>
                                                                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>

                                                                                                                                                        {JSON.parse(pg_item.AuditDataJSON).pg_isvdimpacttagged ? JSON.parse(pg_item.AuditDataJSON).pg_isvdimpacttagged : "-"}
                                                                                                                                                    </label>
                                                                                                                                                </td>
                                                                                                                                                <td>

                                                                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                                                                        {JSON.parse(pg_item.AuditDataJSON).pg_isvdcategorized ? JSON.parse(pg_item.AuditDataJSON).pg_isvdcategorized : "-"}

                                                                                                                                                    </label>
                                                                                                                                                </td>
                                                                                                                                                <td>
                                                                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                                                                        {JSON.parse(pg_item.AuditDataJSON).pg_isvdcomputed ? JSON.parse(pg_item.AuditDataJSON).pg_isvdcomputed : "-"}
                                                                                                                                                    </label>
                                                                                                                                                </td>
                                                                                                                                                <td>
                                                                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                                                                        {JSON.parse(pg_item.AuditDataJSON).pg_isvdclientsignoff ? JSON.parse(pg_item.AuditDataJSON).pg_isvdclientsignoff : "-"}
                                                                                                                                                    </label>
                                                                                                                                                </td>

                                                                                                                                            </>

                                                                                                                                        )
                                                                                                                                    }

                                                                                                                                })
                                                                                                                            }
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                            <td>
                                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                                            </td>
                                                                                                                        </>
                                                                                                                }


                                                                                                            </tr>
                                                                                                        )
                                                                                                    })
                                                                                                }

                                                                                            </tbody>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                null
                                        }

                                        {
                                            this.state.showHrefModal
                                                ?
                                                <>
                                                    <div className="custom_modal href_modal">
                                                        <div className="modal_body href_modal_body">
                                                            <div className="href_modal_dialog">
                                                                <p>To add a new category please raise a Helpmate ticket, Click here to View SOP</p>
                                                                <div className="href_modal_body_buttons">
                                                                    <div className="btn-group href_modal_body_buttons">
                                                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"><a href="https://genpactonline.sharepoint.com/:b:/r/sites/LeanDigitalTransformation/Documents/KM%20Page%20-%202021/Certification%20Guidelines,%20SOPs%20%26%20Handbooks/Certification%20guidelines,%20SOPs%20%26%20Handbooks/Systems%20%26%20Tools/Helpmate-Data%20Related%20Request/ProGEAR%20Value%20Delivery%20Category%20Addition%20SOP.pdf?csf=1&web=1&e=5kKbFg" style={{ color: "#FFF" }} target="_blank">Click Here</a></button>
                                                                        <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={(e) => this.setState({ showHrefModal: false })}>Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </>
                                                :
                                                null
                                        }

                                        {/* {
                                            this.state.ideaItem.TransformationLeverName && this.state.ideaItem.TransformationLeverName === "Data"
                                                ?
                                                <>
                                                    <div className="" style={{ paddingTop: "40px" }}>
                                                        <p className="u-align-left u-text u-text-2">DB SPOC Approval Details</p>
                                                        <div className="new_fields_blocks">
                                                            <div className="submit_page_new_fields">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="row">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    DB SPOC Approval Status</label>
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <input
                                                                                    value={this.state.dbSpocApprovalSelectedOption.label}
                                                                                    className="form-input"
                                                                                    disabled={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="submit_page_new_fields">
                                                                <div className="">
                                                                    <div className="">
                                                                        <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: 5 }}>
                                                                            DB SPOC Comments
                                                                        </label>
                                                                    </div>
                                                                    <div className="">
                                                                        <textarea className="form-input" rows={3} cols={88}
                                                                            style={{ maxWidth: '100%', border: '1px solid lightgrey' }}
                                                                            placeholder="Please provide your comments in support of the Approval decision."
                                                                            value={this.state.dbspocComments} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                null
                                        } */}




                                        


                                        {
                                            this.state.ideaItem.ApprovalStatus && this.state.ideaItem.ApprovalStatus === COMPLETED_STATUS &&
                                                this.state.ideaItem.TransformationLeverName === "Data" &&
                                                this.state.ideasDB.IsSustenance && this.state.ideasDB.IsSustenance === "Yes"
                                                ?
                                                <>
                                                    <div className="" style={{marginTop: "40px",}}>
                                                        <p className="u-align-left u-text u-text-2">Sustenance metric performance Survey Details</p>
                                                        <div className="new_fields_blocks">
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row">
                                                                            <div className="col-md-5">
                                                                                <label
                                                                                    className="form-attribute"
                                                                                    style={{
                                                                                        verticalAlign: "bottom",
                                                                                        marginBottom: -5,
                                                                                    }}
                                                                                >
                                                                                    DB Project Type
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideaItem.DBProjectType} type="text" placeholder="Provide Value" className="form-input  disabled_input"
                                                                                    disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    {
                                                                        this.state.dbProjectType
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields" style={{}}>
                                                                                    <div className="row">
                                                                                        <div className="col-md-5">
                                                                                            <span className="form-attribute">
                                                                                                Template Type
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <div className="ai_first_doc_link">
                                                                                                <input className="template_type_input" disabled value={this.state.ideaItem.DBProjectType} ></input>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }

                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    COO</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideaItem.COO} type="text" placeholder="Provide Value" className="form-input  disabled_input"
                                                                                    disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row">
                                                                            <div className="col-md-5">
                                                                                <label
                                                                                    className="form-attribute"
                                                                                    style={{
                                                                                        verticalAlign: "bottom",
                                                                                        marginBottom: -5,
                                                                                    }}
                                                                                >
                                                                                    MUC ID
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input type="text"
                                                                                   data-toggle="tooltip" title={this.state.ideaItem.MUCIDTitle ? this.state.ideaItem.MUCIDTitle :""}
                                                                                    disabled
                                                                                    placeholder="Provide Value"
                                                                                    value={this.state.ideaItem.MUCIDTitle}
                                                                                    className="form-input disabled_input" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    Shown to GOL</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideasDB.IsSharedGOL} placeholder="Provide value" className="form-input disabled_input" disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                    Shown to Client</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideasDB.IsSharedClient} placeholder="Please choose" disabled className="form-input disabled_input" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.ideasDB.IsSharedClient && this.state.ideasDB.IsSharedClient === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="">
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className="submit_page_new_fields">
                                                                                                <div className="row d_flex_align_items_center">
                                                                                                    <div className="col-md-5">
                                                                                                        <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                            Client Testimony</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-7">
                                                                                                        <button className={this.state.ideaItem.CTestimonyFileName.length > 50?"upload_file_text_overflow":""} style={{ backgroundColor: 'transparent', textAlign: "left", marginTop: "15px", color: '#337ab7', padding: 0 }} onClick={(e) => this.downloadClientTestimony()}>{this.state.ideaItem.CTestimonyFileName}</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-2"></div>
                                                                                        <div className="col-md-5">

                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="" style={{ textAlign: "left", margin: "15px 0px" }}>
                                                                            <div className="row d_flex_align_items_center">
                                                                                <div className="col-md-5">
                                                                                    <label
                                                                                        className="form-attribute"
                                                                                        style={{
                                                                                            verticalAlign: "bottom",
                                                                                            marginBottom: -5,
                                                                                        }}
                                                                                    >
                                                                                        DB Tool Used
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-md-7">
                                                                                    <input type="text"
                                                                                        disabled
                                                                                        placeholder="Provide Value"
                                                                                        value={this.state.ideasDB.DBTool}
                                                                                        className="form-input disabled_input" />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                                <div className="col-md-4">

                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{
                                                                                    verticalAlign: 'bottom', marginBottom: -5
                                                                                }}>
                                                                                    Process Owner OHR</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input type="text"
                                                                                    disabled
                                                                                    placeholder="Provide Value"
                                                                                    value={this.state.ideaItem.ProcessOwnerOHR}
                                                                                    className="form-input form-attribute" />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-5">
                                                                                <label className="form-attribute" style={{
                                                                                    verticalAlign: 'bottom', marginBottom: -5
                                                                                }}>
                                                                                    Process Owner Name</label>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input value={this.state.ideaItem.ProcessOwner} type="text" placeholder="Provide Value" className="form-input disabled_input"
                                                                                    disabled />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        this.state.ideasDB.IsSharedGOL && this.state.ideasDB.IsSharedGOL === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                GOL OHR</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input value={this.state.ideasDB.GOLOHR} type="text" placeholder="Provide value" className="form-input form-attribute"
                                                                                                disabled />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                GOL Name</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input type="text" placeholder="Provide value" disabled value={this.state.ideasDB.GOL}
                                                                                                className="form-input disabled_input" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        this.state.ideasDB.IsSharedClient && this.state.ideasDB.IsSharedClient === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                Client Role</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input value={this.state.ideasDB.ClientRole} type="text" placeholder="Provide value" className="form-input disabled_input"
                                                                                                disabled />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                Client Name</label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input value={this.state.ideasDB.ClientName} type="text" disabled className="form-input  disabled_input" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        this.state.dbToolUsed && this.state.dbToolUsed.label === "Yes" ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row">
                                                                                        <div className="col-md-5">
                                                                                            <label
                                                                                                className="form-attribute"
                                                                                                style={{
                                                                                                    verticalAlign: "bottom",
                                                                                                    marginBottom: -5,
                                                                                                }}
                                                                                            >
                                                                                                Other DB Tool Used
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <input
                                                                                                data-toggle="tooltip" 
                                                                                                title={this.state.ideasDB.OtherDBTool}
                                                                                                type="text"
                                                                                                value={this.state.ideaItem.OtherDBTool}
                                                                                                placeholder="Provide value"
                                                                                                className="form-input disabled_input"
                                                                                                disabled
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                                <div className="col-md-4 " style={{ paddingRight: "30px" }}>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-8">
                                                                                <label
                                                                                    className="form-attribute"
                                                                                    style={{
                                                                                        verticalAlign: "bottom",
                                                                                        marginBottom: -5,
                                                                                    }}
                                                                                >
                                                                                    Is Sustenance tracking applicable ? </label>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <input
                                                                                    value={this.state.ideasDB.IsSustenance}
                                                                                    placeholder="Please provide value"
                                                                                    disabled
                                                                                    className="form-input disabled_input"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="submit_page_new_fields">
                                                                        <div className="row d_flex_align_items_center">
                                                                            <div className="col-md-8">
                                                                                <label
                                                                                    className="form-attribute"
                                                                                    style={{
                                                                                        verticalAlign: "bottom",
                                                                                        marginBottom: "5px",
                                                                                    }}
                                                                                >
                                                                                    How is the model operationalized ?
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <input
                                                                                    value={this.state.ideasDB.ModelSummary}
                                                                                    placeholder="Please provide value"
                                                                                    disabled
                                                                                    className="form-input disabled_input"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.trackingApplicableSelectedOption && this.state.trackingApplicableSelectedOption.label === "Yes" ? (
                                                                        <>
                                                                            <div className="submit_page_new_fields">
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-8">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: -5,
                                                                                            }}
                                                                                        >

                                                                                            Sustenance tracking frequency
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <input
                                                                                            value={this.state.ideasDB.SustenanceFreq}
                                                                                            placeholder="Please provide value"
                                                                                            disabled
                                                                                            className="form-input disabled_input"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="submit_page_new_fields">
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-12">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: "5px",
                                                                                            }}
                                                                                        >
                                                                                            Sustenance metric
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <textarea
                                                                                            className="form-input"
                                                                                            disabled
                                                                                            value={this.state.ideasDB.SustenanceMetric}
                                                                                            placeholder="Please provide sustenance metrics"
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                border: "1px solid lightgrey",

                                                                                            }}
                                                                                            rows={3}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </>
                                                                    ) : null}

                                                                </div>
                                                            </div>


                                                            {
                                                                this.state.ideaItem.TransformationLeverName === "Data" && this.state.ideaItem.IdeaId
                                                                ?
                                                                <>
                                                                    <DisplayTemplate IdeaId={this.state.ideaItem.IdeaId} templateType={this.state.ideaItem.DBProjectType} />
                                                                    {/* <div className="" style={{borderBottom:"1px solid lightgrey"}} /> */}
                                                                </>
                                                                :
                                                                null
                                                            }


                                                            {
                                                                this.state.ideaItem.TransformationLeverName && this.state.ideaItem.TransformationLeverName === "Data" && this.state.ideasDB.IsSustenance && this.state.ideasDB.IsSustenance === "Yes" && this.state.ideaItem.DBSPocOHR
                                                                    ?
                                                                    <>
                                                                        <div className="" style={{ borderTop: "1px solid lightgrey", paddingRight: "30px", marginTop: "30px", paddingTop: "30px" }}>
                                                                            <p className="u-align-left u-text u-text-2">DB questionnaire</p>
                                                                            <div className="new_fields_blocks">
                                                                                <div className="row">
                                                                                    <div className="col-md-5">
                                                                                        <div className="submit_page_new_fields">
                                                                                            <div className="row d_flex_align_items_center">
                                                                                                <div className="col-md-7">
                                                                                                    <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                        Is the MUC ID tagged correctly ?</label>
                                                                                                </div>
                                                                                                <div className="col-md-5">
                                                                                                    <input
                                                                                                        value={this.state.ideasDB.IsMUCTag}
                                                                                                        placeholder='Provide value'
                                                                                                        disabled
                                                                                                        className="form-input disabled_input"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-2"></div>
                                                                                    <div className="col-md-5">
                                                                                        <div className="submit_page_new_fields">
                                                                                            <div className="row d_flex_align_items_center">
                                                                                                <div className="col-md-7">
                                                                                                    <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                        Is the DB theme captured correctly?</label>
                                                                                                </div>
                                                                                                <div className="col-md-5">
                                                                                                    <input placeholder='Provide value'
                                                                                                        disabled
                                                                                                        value={this.state.ideasDB.IsDBTheme}
                                                                                                        className="form-input disabled_input"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="new_fields_blocks">
                                                                                <div className="row">
                                                                                    <div className="col-md-5">
                                                                                        <div className="submit_page_new_fields">
                                                                                            <div className="row d_flex_align_items_center">
                                                                                                <div className="col-md-7">
                                                                                                    <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                        Is the Tool used captured correctly ?</label>
                                                                                                </div>
                                                                                                <div className="col-md-5">
                                                                                                    <input placeholder='Provide value'
                                                                                                        className="form-input disabled_input"
                                                                                                        disabled
                                                                                                        value={this.state.ideasDB.IsToolCorrect}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-2"></div>
                                                                                    <div className="col-md-5">
                                                                                        <div className="submit_page_new_fields">
                                                                                            <div className="row d_flex_align_items_center">
                                                                                                <div className="col-md-7">
                                                                                                    <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                        Are the model details captured appropriately?</label>
                                                                                                </div>
                                                                                                <div className="col-md-5">
                                                                                                    <input placeholder='Provide value'
                                                                                                        className="form-input disabled_input"
                                                                                                        disabled
                                                                                                        value={this.state.ideasDB.IsModelCorrect}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="new_fields_blocks">
                                                                                <div className="row">
                                                                                    <div className="col-md-5">
                                                                                        <div className="submit_page_new_fields">
                                                                                            <div className="row d_flex_align_items_center">
                                                                                                <div className="col-md-7">
                                                                                                    <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                        Are the model deployment details captured appropriately?</label>
                                                                                                </div>
                                                                                                <div className="col-md-5">
                                                                                                    <input placeholder='Provide value'
                                                                                                        className="form-input disabled_input"
                                                                                                        disabled
                                                                                                        value={this.state.ideasDB.IsModelDeploy}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-2"></div>
                                                                                    <div className="col-md-5">
                                                                                        <div className="submit_page_new_fields">
                                                                                            <div className="row d_flex_align_items_center">
                                                                                                <div className="col-md-7">
                                                                                                    <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                        Do the provided signoffs meet value delivery guidelines?</label>
                                                                                                </div>
                                                                                                <div className="col-md-5">
                                                                                                    <input placeholder='Provide value'
                                                                                                        className="form-input disabled_input"
                                                                                                        disabled
                                                                                                        value={this.state.ideasDB.IsSignOff}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="new_fields_blocks">
                                                                                <div className="row">
                                                                                    <div className="col-md-5">
                                                                                        <div className="submit_page_new_fields">
                                                                                            <div className="row d_flex_align_items_center">
                                                                                                <div className="col-md-7">
                                                                                                    <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                        Are the monetization details captured appropriately?</label>
                                                                                                </div>
                                                                                                <div className="col-md-5">
                                                                                                    <input placeholder='Provide value'
                                                                                                        className="form-input disabled_input"
                                                                                                        disabled={true}
                                                                                                        value={this.state.ideasDB.IsMonetization}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-2"></div>
                                                                                    <div className="col-md-5">
                                                                                        <div className="submit_page_new_fields">
                                                                                            <div className="row d_flex_align_items_center">
                                                                                                <div className="col-md-7">
                                                                                                    <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                        Are sustenance tracking details OK ?</label>
                                                                                                </div>
                                                                                                <div className="col-md-5">
                                                                                                    <input placeholder='Provide value'
                                                                                                        className="form-input disabled_input"
                                                                                                        disabled
                                                                                                        value={this.state.ideasDB.IsSPocSustenance}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="new_fields_blocks">
                                                                                <div className="row">
                                                                                    <div className="col-md-5">
                                                                                        <div className="submit_page_new_fields">
                                                                                            <div className="row d_flex_align_items_center">
                                                                                                <div className="col-md-7">
                                                                                                    <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                        Is there any other issue you would like to report ?</label>
                                                                                                </div>
                                                                                                <div className="col-md-5">
                                                                                                    <input placeholder='Provide value'
                                                                                                        className="form-input disabled_input"
                                                                                                        disabled
                                                                                                        value={this.state.ideasDB.IsIssue}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-2"></div>
                                                                                    <div className="col-md-5">
                                                                                        <div className="submit_page_new_fields">
                                                                                            <div className="row d_flex_align_items_center">
                                                                                                <div className="col-md-7">
                                                                                                    <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>
                                                                                                        Is there any exception applied on the project approval ?</label>
                                                                                                </div>
                                                                                                <div className="col-md-5">
                                                                                                    <input placeholder='Provide value'
                                                                                                        className="form-input disabled_input"
                                                                                                        disabled
                                                                                                        value={this.state.ideasDB.IsException}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="" style={{ paddingBottom: "40px", marginBottom: "15px" }} />
                                                                        </div >
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            {/* DB SPOC Fields starts here */}

                                                            {
                                                                this.state.displaySurvey1
                                                                ?
                                                                <>
                                                                    <div className="new_fields_blocks" style={{ paddingRight: "15px", marginTop:"20px",borderTop:"1px solid lightgrey", paddingTop:'36px' }}>
                                                                                <p className="u-align-left u-text u-text-2">Sustenance metric performance Survey 1</p>
                                                                                <div className="row">
                                                                                    <div className="col-md-4">
                                                                                        <div className="submit_page_new_fields">
                                                                                            <div className="row d_flex_align_items_center">
                                                                                                <div className="col-md-7">
                                                                                                    <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                                        Sustenance Tracking Month
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-md-5">
                                                                                                    <input
                                                                                                        value={1}
                                                                                                        placeholder="Provide value"
                                                                                                        className="form-input disabled_input"
                                                                                                        disabled
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <div className="submit_page_new_fields">
                                                                                            <div className="row d_flex_align_items_center">
                                                                                                <div className="col-md-7">
                                                                                                    <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                                        <span style={{ color: "red" }}>* </span>
                                                                                                        Is the model still Active ?
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-md-5">
                                                                                                    <Select
                                                                                                        options={YESORNO}
                                                                                                        onChange={(e) => this.handleModelStillActiveChange1(e)}
                                                                                                        value={this.state.IsModelActive1}
                                                                                                        placeholder="Please choose"
                                                                                                        styles={this.state.selectStyle.IsModelActive1}
                                                                                                        isDisabled={this.state.surveyFieldsDisabled1 ? true : false}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        {
                                                                                            this.state.IsModelActive1 &&
                                                                                            this.state.IsModelActive1.label === "Yes"
                                                                                            ?
                                                                                            <>
                                                                                                <div className="submit_page_new_fields">
                                                                                                    <div className="row d_flex_align_items_center">
                                                                                                        <div className="col-md-7">
                                                                                                            <label className="form-attribute" style={{ verticalAlign: "bottom", marginBottom: -5,}}>
                                                                                                                <span style={{ color: "red" }}>* </span>
                                                                                                                Sustenance metric value recorded for this month
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div className="col-md-5">
                                                                                                            <input
                                                                                                                type="number"
                                                                                                                min="0"
                                                                                                                value={this.state.MetricsValue1}
                                                                                                                placeholder="Provide value"
                                                                                                                onChange={(e) => this.handleMetricsValueRecordChange1(e.target.value)}
                                                                                                                onKeyDown={(evt) =>
                                                                                                                    (evt.key === "." ||
                                                                                                                        evt.key === "e" ||
                                                                                                                        evt.key === "E" ||
                                                                                                                        evt.key === "+" ||
                                                                                                                        evt.key === "-") &&
                                                                                                                    evt.preventDefault()
                                                                                                                }
                                                                                                                onWheel={(e) => e.target.blur()}
                                                                                                                style={{ maxWidth: '100%', ...this.state.selectStyle.MetricsValue1}}
                                                                                                                className={this.state.surveyFieldsDisabled1 ? "form-input disabled_input" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                                                                                disabled={this.state.surveyFieldsDisabled1 ? true : false}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="new_fields_blocks" style={{borderTop:"1px solid lightgrey", paddingRight: "15px", paddingTop: "30px",marginTop:"30px" }}>
                                                                                <p className='u-align-left u-text u-text-2'>Model Run Compliance 1</p>
                                                                                <div className="">
                                                                                    <div className="row">
                                                                                        <div className="col-md-4">
                                                                                            <div className="submit_page_new_fields">
                                                                                                <div className="row d_flex_align_items_center">
                                                                                                    <div className="col-md-7">
                                                                                                        <label className="form-attribute non_mandatory_field_alignment">Expected runs in a month</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-5">
                                                                                                        <input
                                                                                                            value={this.state.ExpectedRuns}
                                                                                                            placeholder="Provide value"
                                                                                                            disabled
                                                                                                            className="form-input disabled_input"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>


                                                                                            {
                                                                                                this.state.IsModelActive1 && this.state.IsModelActive1.label === "Yes"
                                                                                                    ?
                                                                                                    <>
                                                                                                        <div className="submit_page_new_fields">
                                                                                                            <div className="row d_flex_align_items_center">
                                                                                                                <div className="col-md-7">
                                                                                                                    <label className="form-attribute" style={{ verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                                                        <span style={{ color: "red" }}>* </span>
                                                                                                                        Remarks
                                                                                                                        <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 1000 chars)</span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                                <div className="col-md-12" style={{ marginTop: "10px" }}>
                                                                                                                    <textarea
                                                                                                                        value={this.state.Remarks1}
                                                                                                                        placeholder="Provide value"
                                                                                                                        className={this.state.surveyFieldsDisabled1 ? "form-input disabled_input textarea_resize_none text_area_full_width" :"textarea-css form-attribute textarea_resize_none text_area_full_width" }
                                                                                                                        onChange={(e) => this.handleSustenanceRemarksChange1(e.target.value)}
                                                                                                                        rows={4}
                                                                                                                        style={{ maxWidth: '100%', ...this.state.selectStyle.MetricsValue1 }}
                                                                                                                        disabled={this.state.surveyFieldsDisabled1 ? true : false}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>


                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            {
                                                                                                this.state.IsModelActive1 && this.state.IsModelActive1.label === "Yes"
                                                                                                ?
                                                                                                <>
                                                                                                    <div className="submit_page_new_fields">
                                                                                                        <div className="row d_flex_align_items_center">
                                                                                                            <div className="col-md-7">
                                                                                                                <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                                                    <span style={{ color: "red" }}>* </span>
                                                                                                                    Actual Runs in this month
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            <div className="col-md-5">
                                                                                                                <input
                                                                                                                    value={this.state.ActualRuns1}
                                                                                                                    type="number"
                                                                                                                    min="0"
                                                                                                                    placeholder="Provide value"
                                                                                                                    className={this.state.surveyFieldsDisabled1 ? "form-input disabled_input" : "textarea-css form-attribute textarea_resize_none hide_numeric_input_arrows"}
                                                                                                                    onChange={(e) => this.handleActualRunsInaMonthChange1(e.target.value)}
                                                                                                                    onKeyDown={(evt) =>
                                                                                                                        (evt.key === "." ||
                                                                                                                            evt.key === "e" ||
                                                                                                                            evt.key === "E" ||
                                                                                                                            evt.key === "+" ||
                                                                                                                            evt.key === "-") &&
                                                                                                                        evt.preventDefault()
                                                                                                                    }
                                                                                                                    onWheel={(e) => e.target.blur()}
                                                                                                                    style={{ maxWidth: '100%', ...this.state.selectStyle.actualRunsInMonth }}
                                                                                                                    disabled={this.state.surveyFieldsDisabled1 ? true : false}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                            
                                                                                            <div className="submit_page_new_fields">
                                                                                                {
                                                                                                    this.state.IsModelActive1 && this.state.IsModelActive1.label === "Yes"
                                                                                                    ?
                                                                                                    <>
                                                                                                            <div className="row d_flex_align_items_center">
                                                                                                            <div className="col-md-7">
                                                                                                                <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                                                    <span style={{ color: "red" }}>* </span>
                                                                                                                    What is the model accuracy ?
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            <div className="col-md-5">
                                                                                                                <input type="number" min="0" 
                                                                                                                    placeholder={0} 
                                                                                                                    onChange={(event) =>this.handleModelAccuracyChange1(event.target.value, event)}
                                                                                                                    value={this.state.ModelAccuracy1}
                                                                                                                    className={this.state.surveyFieldsDisabled1 ? "form-input disabled_input" : "textarea-css form-attribute hide_numeric_input_arrows"}
                                                                                                                    style={{ maxWidth: '100%', ...this.state.selectStyle.ModelAccuracy1 }}
                                                                                                                    disabled={this.state.surveyFieldsDisabled1 ? true : false}
                                                                                                                />  

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                                
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            {
                                                                                                this.state.IsModelActive1 && this.state.IsModelActive1.label === "Yes"
                                                                                                ?
                                                                                                <>
                                                                                                    <div className="submit_page_new_fields">
                                                                                                        <div className="row d_flex_align_items_center">
                                                                                                            <div className="col-md-7">
                                                                                                                <label className="form-attribute non_mandatory_field_alignment">
                                                                                                                Compliance
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            <div className="col-md-5">
                                                                                                                <input
                                                                                                                    value={this.state.compliance1 + " %"}
                                                                                                                    placeholder="Provide value"
                                                                                                                    disabled
                                                                                                                    className="form-input disabled_input"
                                                                                                                    type="text"
                                                                                                                    min="0"
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                            
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            

                                                                            <div className="" style={{paddingTom: "20px",paddingRight:"30px",marginBottom:"40px"}}>
                                                                                <div className="row">
                                                                                    <div className="col-md-4">
                                                                                        {
                                                                                            
                                                                                            this.state.IsModelActive1 && this.state.IsModelActive1.label === "Yes"
                                                                                            ?
                                                                                            <>
                                                                                                <div className="submit_page_new_fields">
                                                                                                    <div className="row d_flex_align_items_center">
                                                                                                        <div className="col-md-7">

                                                                                                            <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                                                <span style={{ color: "red" }}>* </span>
                                                                                                                Has the model predictions been accurate consistently ?
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div className="col-md-5">
                                                                                                            <Select
                                                                                                                options={YESORNO}
                                                                                                                value={this.state.IsModelPrediction1}
                                                                                                                placeholder="Please choose"
                                                                                                                onChange={(e) => this.predictionsAccurateChange1(e)}
                                                                                                                styles={this.state.selectStyle.IsModelPrediction1}
                                                                                                                isDisabled={this.state.surveyFieldsDisabled1 ? true : false}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        {
                                                                                             this.state.IsModelActive1 && this.state.IsModelActive1.label === "Yes"
                                                                                            ?
                                                                                            <>
                                                                                                <div className="submit_page_new_fields">
                                                                                                <div className="row d_flex_align_items_center">
                                                                                                    <div className="col-md-7">

                                                                                                        <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                                            <span style={{ color: "red" }}>*</span>
                                                                                                            Has the model been re-trained post project completion ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className="col-md-5">
                                                                                                        <Select
                                                                                                            options={YESORNO}
                                                                                                            value={this.state.IsRetrained1}
                                                                                                            placeholder="Please choose"
                                                                                                            onChange={(e) =>this.retrainedPostCompletionChange1(e)}
                                                                                                            styles={this.state.selectStyle.IsRetrained1}
                                                                                                            isDisabled={this.state.surveyFieldsDisabled1 ? true : false}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                    </div>
                                                                                    <div className="col-md-4"></div>
                                                                                </div>

                                                                            </div>
                                                                </>
                                                                :
                                                                null
                                                            }
                                                            {
                                                                this.state.displaySurvey2
                                                                ?
                                                                <>
                                                                     <div className="new_fields_blocks" style={{ paddingRight: "15px", marginTop:"20px",borderTop:"1px solid lightgrey", paddingTop:'36px' }}>
                                                                <p className="u-align-left u-text u-text-2">Sustenance metric performance Survey 2</p>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <div className="submit_page_new_fields">
                                                                            <div className="row d_flex_align_items_center">
                                                                                <div className="col-md-7">
                                                                                    <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                        Sustenance Tracking Month
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-md-5">
                                                                                    <input
                                                                                        value={2}
                                                                                        placeholder="Provide value"
                                                                                        className="form-input disabled_input"
                                                                                        disabled
                                                                                        
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="submit_page_new_fields">
                                                                            <div className="row d_flex_align_items_center">
                                                                                <div className="col-md-7">
                                                                                    <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                        <span style={{ color: "red" }}>* </span>
                                                                                        Is the model still Active ?
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-md-5">
                                                                                    <Select
                                                                                        options={YESORNO}
                                                                                        onChange={(e) => this.handleModelStillActiveChange2(e)}
                                                                                        value={this.state.IsModelActive2}
                                                                                        placeholder="Please choose"
                                                                                        styles={this.state.selectStyle.IsModelActive2}
                                                                                        isDisabled={this.state.surveyFieldsDisabled2 ? true : false}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        {
                                                                            this.state.IsModelActive2 && this.state.IsModelActive2.label === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-7">
                                                                                            <label className="form-attribute" style={{ verticalAlign: "bottom", marginBottom: -5,}}>
                                                                                                <span style={{ color: "red" }}>* </span>
                                                                                                Sustenance metric value recorded for this month
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="col-md-5">
                                                                                            <input
                                                                                                type="number"
                                                                                                min="0"
                                                                                                value={this.state.MetricsValue2}
                                                                                                placeholder="Provide value"
                                                                                                onChange={(e) => this.handleMetricsValueRecordChange2(e.target.value)}
                                                                                                onKeyDown={(evt) =>
                                                                                                    (evt.key === "." ||
                                                                                                        evt.key === "e" ||
                                                                                                        evt.key === "E" ||
                                                                                                        evt.key === "+" ||
                                                                                                        evt.key === "-") &&
                                                                                                    evt.preventDefault()
                                                                                                }
                                                                                                onWheel={(e) => e.target.blur()}
                                                                                                style={{ maxWidth: '100%', ...this.state.selectStyle.MetricsValue2 }}
                                                                                                className={this.state.surveyFieldsDisabled2 ? "form-attribute hide_numeric_input_arrows form-input disabled_input" : "textarea-css form-attribute hide_numeric_input_arrows"} 
                                                                                                disabled={this.state.surveyFieldsDisabled2 ? true : false}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="new_fields_blocks" style={{borderTop:"1px solid lightgrey", paddingRight: "15px", paddingTop: "30px",marginTop:"30px" }}>
                                                                <p className='u-align-left u-text u-text-2'>Model Run Compliance 2</p>
                                                                <div className="">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="submit_page_new_fields">
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-7">
                                                                                        <label className="form-attribute non_mandatory_field_alignment">Expected runs in a month</label>
                                                                                    </div>
                                                                                    <div className="col-md-5">
                                                                                        <input
                                                                                            value={this.state.ExpectedRuns}
                                                                                            placeholder="Provide value"
                                                                                            disabled
                                                                                            className="form-input disabled_input"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            {
                                                                                this.state.IsModelActive2 && this.state.IsModelActive2.label === "Yes"
                                                                                    ?
                                                                                    <>
                                                                                        <div className="submit_page_new_fields">
                                                                                            <div className="row d_flex_align_items_center">
                                                                                                <div className="col-md-7">
                                                                                                    <label className="form-attribute" style={{ verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                                        <span style={{ color: "red" }}>* </span>
                                                                                                        Remarks
                                                                                                        <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 1000 chars)</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-md-12" style={{ marginTop: "10px" }}>
                                                                                                    <textarea
                                                                                                        value={this.state.Remarks2}
                                                                                                        placeholder="Provide value"
                                                                                                        className={this.state.surveyFieldsDisabled2 ? "form-input disabled_input textarea_resize_none text_area_full_width" : "textarea-css form-attribute textarea_resize_none text_area_full_width"} 
                                                                                                        onChange={(e) => this.handleSustenanceRemarksChange2(e.target.value)}
                                                                                                        rows={4}
                                                                                                        style={{ maxWidth: '100%', ...this.state.selectStyle.Remarks2 }}
                                                                                                        disabled={this.state.surveyFieldsDisabled2 ? true : false}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                    </>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            {
                                                                                this.state.IsModelActive2 && this.state.IsModelActive2.label === "Yes"
                                                                                ?
                                                                                <>
                                                                                    <div className="submit_page_new_fields">
                                                                                        <div className="row d_flex_align_items_center">
                                                                                            <div className="col-md-7">
                                                                                                <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                                    <span style={{ color: "red" }}>* </span>
                                                                                                    Actual Runs in this month
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-md-5">
                                                                                                <input
                                                                                                    value={this.state.ActualRuns2}
                                                                                                    type="number"
                                                                                                    min="0"
                                                                                                    placeholder="Provide value"
                                                                                                    className={this.state.surveyFieldsDisabled2 ? "form-input disabled_input textarea_resize_none hide_numeric_input_arrows" : "textarea-css form-attribute textarea_resize_none hide_numeric_input_arrows"}  
                                                                                                    onChange={(e) => this.handleActualRunsInaMonthChange2(e.target.value)}
                                                                                                    onKeyDown={(evt) =>
                                                                                                        (evt.key === "." ||
                                                                                                            evt.key === "e" ||
                                                                                                            evt.key === "E" ||
                                                                                                            evt.key === "+" ||
                                                                                                            evt.key === "-") &&
                                                                                                        evt.preventDefault()
                                                                                                    }
                                                                                                    onWheel={(e) => e.target.blur()}
                                                                                                    style={{ maxWidth: '100%', ...this.state.selectStyle.ActualRuns2 }}
                                                                                                    disabled={this.state.surveyFieldsDisabled2 ? true : false}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                null
                                                                            }
                                                                            
                                                                            <div className="submit_page_new_fields">
                                                                                {
                                                                                    this.state.IsModelActive2 && this.state.IsModelActive2.label === "Yes"
                                                                                    ?
                                                                                    <>
                                                                                            <div className="row d_flex_align_items_center">
                                                                                            <div className="col-md-7">
                                                                                                <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                                    <span style={{ color: "red" }}>* </span>
                                                                                                    What is the model accuracy ?
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-md-5">
                                                                                                <input type="number" min="0" 
                                                                                                    placeholder={0} 
                                                                                                    onChange={(event) =>this.handleModelAccuracyChange2(event.target.value, event)}
                                                                                                    value={this.state.ModelAccuracy2}
                                                                                                    className={this.state.surveyFieldsDisabled2 ? "form-input disabled_input hide_numeric_input_arrows" : "textarea-css form-attribute hide_numeric_input_arrows"} 
                                                                                                    style={{ maxWidth: '100%', ...this.state.selectStyle.ModelAccuracy2}}
                                                                                                    disabled={this.state.surveyFieldsDisabled2 ? true : false}
                                                                                                />  

                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            {
                                                                                this.state.IsModelActive2 && this.state.IsModelActive2.label === "Yes"
                                                                                ?
                                                                                <>
                                                                                    <div className="submit_page_new_fields">
                                                                                        <div className="row d_flex_align_items_center">
                                                                                            <div className="col-md-7">
                                                                                                <label className="form-attribute non_mandatory_field_alignment">
                                                                                                Compliance
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-md-5">
                                                                                                <input
                                                                                                    value={this.state.compliance2 + " %"}
                                                                                                    placeholder="Provide value"
                                                                                                    disabled
                                                                                                    className="form-input disabled_input"
                                                                                                    type="text"
                                                                                                    min="0"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                null
                                                                            }
                                                                            
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            

                                                            <div className="" style={{paddingTom: "20px",paddingRight:"30px",marginBottom:"40px"}}>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        {
                                                                            
                                                                            this.state.IsModelActive2 && this.state.IsModelActive2.label === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-7">

                                                                                            <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                                <span style={{ color: "red" }}>* </span>
                                                                                                Has the model predictions been accurate consistently ?
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="col-md-5">
                                                                                            <Select
                                                                                                options={YESORNO}
                                                                                                value={this.state.IsModelPrediction2}
                                                                                                placeholder="Please choose"
                                                                                                onChange={(e) => this.predictionsAccurateChange2(e)}
                                                                                                styles={this.state.selectStyle.IsModelPrediction2}
                                                                                                isDisabled={this.state.surveyFieldsDisabled2 ? true : false}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        {
                                                                            this.state.IsModelActive2 && this.state.IsModelActive2.label === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-7">

                                                                                        <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                            <span style={{ color: "red" }}>*</span>
                                                                                            Has the model been re-trained post project completion ?
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-5">
                                                                                        <Select
                                                                                            options={YESORNO}
                                                                                            value={this.state.IsRetrained2}
                                                                                            placeholder="Please choose"
                                                                                            onChange={(e) =>this.retrainedPostCompletionChange2(e)}
                                                                                            styles={this.state.selectStyle.IsRetrained2}
                                                                                            isDisabled={this.state.surveyFieldsDisabled2 ? true : false}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-4"></div>
                                                                </div>

                                                            </div>
                                                                </>
                                                                :
                                                                null
                                                            }



                                                            {
                                                                this.state.displaySurvey3
                                                                ?
                                                                <>
                                                                     <div className="new_fields_blocks" style={{ paddingRight: "15px", marginTop:"20px",borderTop:"1px solid lightgrey", paddingTop:'36px' }}>
                                                                <p className="u-align-left u-text u-text-2">Sustenance metric performance Survey 3</p>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <div className="submit_page_new_fields">
                                                                            <div className="row d_flex_align_items_center">
                                                                                <div className="col-md-7">
                                                                                    <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                        Sustenance Tracking Month
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-md-5">
                                                                                    <input
                                                                                        value={3}
                                                                                        placeholder="Provide value"
                                                                                        className="form-input disabled_input"
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="submit_page_new_fields">
                                                                            <div className="row d_flex_align_items_center">
                                                                                <div className="col-md-7">
                                                                                    <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                        <span style={{ color: "red" }}>* </span>
                                                                                        Is the model still Active ?
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-md-5">
                                                                                    <Select
                                                                                        options={YESORNO}
                                                                                        onChange={(e) => this.handleModelStillActiveChange3(e)}
                                                                                        value={this.state.IsModelActive3}
                                                                                        placeholder="Please choose"
                                                                                        styles={this.state.selectStyle.IsModelActive3}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        {
                                                                            this.state.IsModelActive3 && this.state.IsModelActive3.label === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-7">
                                                                                            <label className="form-attribute" style={{ verticalAlign: "bottom", marginBottom: -5,}}>
                                                                                                <span style={{ color: "red" }}>* </span>
                                                                                                Sustenance metric value recorded for this month
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="col-md-5">
                                                                                            <input
                                                                                                type="number"
                                                                                                min="0"
                                                                                                value={this.state.MetricsValue3}
                                                                                                placeholder="Provide value"
                                                                                                onChange={(e) => this.handleMetricsValueRecordChange3(e.target.value)}
                                                                                                onKeyDown={(evt) =>
                                                                                                    (evt.key === "." ||
                                                                                                        evt.key === "e" ||
                                                                                                        evt.key === "E" ||
                                                                                                        evt.key === "+" ||
                                                                                                        evt.key === "-") &&
                                                                                                    evt.preventDefault()
                                                                                                }
                                                                                                onWheel={(e) => e.target.blur()}
                                                                                                style={{ maxWidth: '100%', ...this.state.selectStyle.MetricsValue3 }}
                                                                                                className="textarea-css form-attribute hide_numeric_input_arrows"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="new_fields_blocks" style={{borderTop:"1px solid lightgrey", paddingRight: "15px", paddingTop: "30px",marginTop:"30px" }}>
                                                                <p className='u-align-left u-text u-text-2'>Model Run Compliance 3</p>
                                                                <div className="">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="submit_page_new_fields">
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-7">
                                                                                        <label className="form-attribute non_mandatory_field_alignment">Expected runs in a month</label>
                                                                                    </div>
                                                                                    <div className="col-md-5">
                                                                                        <input
                                                                                            value={this.state.ExpectedRuns}
                                                                                            placeholder="Provide value"
                                                                                            disabled
                                                                                            className="form-input disabled_input"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            {
                                                                                this.state.IsModelActive3 && this.state.IsModelActive3.label === "Yes"
                                                                                    ?
                                                                                    <>
                                                                                        <div className="submit_page_new_fields">
                                                                                            <div className="row d_flex_align_items_center">
                                                                                                <div className="col-md-7">
                                                                                                    <label className="form-attribute" style={{ verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                                        <span style={{ color: "red" }}>* </span>
                                                                                                        Remarks
                                                                                                        <span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 1000 chars)</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-md-12" style={{ marginTop: "10px" }}>
                                                                                                    <textarea
                                                                                                        value={this.state.Remarks3}
                                                                                                        placeholder="Provide value"
                                                                                                        className="textarea-css form-attribute textarea_resize_none text_area_full_width"
                                                                                                        onChange={(e) => this.handleSustenanceRemarksChange3(e.target.value)}
                                                                                                        rows={4}
                                                                                                        style={{ maxWidth: '100%', ...this.state.selectStyle.Remarks3 }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                    </>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            {
                                                                                this.state.IsModelActive3 && this.state.IsModelActive3.label === "Yes"
                                                                                ?
                                                                                <>
                                                                                    <div className="submit_page_new_fields">
                                                                                        <div className="row d_flex_align_items_center">
                                                                                            <div className="col-md-7">
                                                                                                <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                                    <span style={{ color: "red" }}>* </span>
                                                                                                    Actual Runs in this month
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-md-5">
                                                                                                <input
                                                                                                    value={this.state.ActualRuns3}
                                                                                                    type="number"
                                                                                                    min="0"
                                                                                                    placeholder="Provide value"
                                                                                                    className="textarea-css form-attribute textarea_resize_none hide_numeric_input_arrows"
                                                                                                    onChange={(e) => this.handleActualRunsInaMonthChange3(e.target.value)}
                                                                                                    onKeyDown={(evt) =>
                                                                                                        (evt.key === "." ||
                                                                                                            evt.key === "e" ||
                                                                                                            evt.key === "E" ||
                                                                                                            evt.key === "+" ||
                                                                                                            evt.key === "-") &&
                                                                                                        evt.preventDefault()
                                                                                                    }
                                                                                                    onWheel={(e) => e.target.blur()}
                                                                                                    style={{ maxWidth: '100%', ...this.state.selectStyle.ActualRuns3 }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                null
                                                                            }
                                                                            
                                                                            <div className="submit_page_new_fields">
                                                                                {
                                                                                    this.state.IsModelActive3 && this.state.IsModelActive3.label === "Yes"
                                                                                    ?
                                                                                    <>
                                                                                            <div className="row d_flex_align_items_center">
                                                                                            <div className="col-md-7">
                                                                                                <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                                    <span style={{ color: "red" }}>* </span>
                                                                                                    What is the model accuracy ?
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-md-5">
                                                                                                <input type="number" min="0" 
                                                                                                    placeholder={0} 
                                                                                                    onChange={(event) =>this.handleModelAccuracyChange3(event.target.value, event)}
                                                                                                    value={this.state.ModelAccuracy2}
                                                                                                    className="textarea-css form-attribute hide_numeric_input_arrows"
                                                                                                    style={{ maxWidth: '100%', ...this.state.selectStyle.ModelAccuracy2}}
                                                                                                />  

                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            {
                                                                                this.state.IsModelActive3 && this.state.IsModelActive3.label === "Yes"
                                                                                ?
                                                                                <>
                                                                                    <div className="submit_page_new_fields">
                                                                                        <div className="row d_flex_align_items_center">
                                                                                            <div className="col-md-7">
                                                                                                <label className="form-attribute non_mandatory_field_alignment">
                                                                                                    Compliance
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-md-5">
                                                                                                <input
                                                                                                    value={this.state.compliance3 + " %"}
                                                                                                    placeholder="Provide value"
                                                                                                    disabled
                                                                                                    className="form-input disabled_input"
                                                                                                    type="text"
                                                                                                    min="0" 
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                null
                                                                            }
                                                                            
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            

                                                            <div className="" style={{paddingTom: "20px",paddingRight:"30px",marginBottom:"40px"}}>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        {
                                                                            
                                                                            this.state.IsModelActive3 && this.state.IsModelActive3.label === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-7">

                                                                                            <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                                <span style={{ color: "red" }}>* </span>
                                                                                                Has the model predictions been accurate consistently ?
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="col-md-5">
                                                                                            <Select
                                                                                                options={YESORNO}
                                                                                                value={this.state.IsModelPrediction2}
                                                                                                placeholder="Please choose"
                                                                                                onChange={(e) => this.predictionsAccurateChange3(e)}
                                                                                                styles={this.state.selectStyle.IsModelPrediction2}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        {
                                                                            this.state.IsModelActive3 && this.state.IsModelActive3.label === "Yes"
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields">
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-7">

                                                                                        <label className="form-attribute" style={{verticalAlign: "bottom",marginBottom: -5,}}>
                                                                                            <span style={{ color: "red" }}>*</span>
                                                                                            Has the model been re-trained post project completion ?
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-5">
                                                                                        <Select
                                                                                            options={YESORNO}
                                                                                            value={this.state.IsRetrained3}
                                                                                            placeholder="Please choose"
                                                                                            onChange={(e) =>this.retrainedPostCompletionChange3(e)}
                                                                                            styles={this.state.selectStyle.IsRetrained3}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-4"></div>
                                                                </div>

                                                            </div>
                                                                </>
                                                                :
                                                                null
                                                            }





                                                            
                                                               
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                null

                                        }


                                        
                                        
                                        

                                        


                                        <div style={{ borderBottom: '1px solid lightgrey', marginTop: 10, marginBottom: 15 }} />
                                        <div className="btn-group" style={{ marginTop: 15 }}>
                                            <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.state.submitIdeaCount === 0 ? this.submitIdea() : this.setState({ submitIdeaCount: this.state.submitIdeaCount + 1 })}>Submit</button>
                                            <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.cancelEvent}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </>
        )
    }
}

export default SustenanceMetricsApprovalPage
