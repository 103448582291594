import React, { Component, useState } from 'react'
import { GET_IDEA_URL, UPDATE_IDEA_URL, APPROVED, GET_METADATA, DISPLAYPROJECTTHEME,AI_DOC_LINK, AIFIRST,DELEGATE_IDEA_URL,PENDING, SENT_BACK, REJECTED, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, ACCESS_TOKEN, USER_DATA, PROJECT_VIEW, PPT_CONTENT, SIGNOFF_CONTENT, REVIEW_IDEA_URL, REQUEST_CANCELLATION, GOL_APPROVAL_CONTENT, FPnA_APPROVAL_CONTENT, DATABRIDGETYPES, MUCID, YESORNO, DOBTOOLS,DB_PEF_DATE } from '../assets/constants/constants';
import Toast from 'light-toast';
import { selectValidation as selectStyle, textBoxValidation } from "../utils/FieldValidation";
import Checksum from "../utils/Checksum";
import UpdatedSpeech from './UpdatedSpeech';
import Select from 'react-select';
import FPNAGOL from '../utils/FPNAGOL';
import ValueDelivery from '../utils/ValueDelivery'
import ShowTree from '../utils/ShowTree';
import { Link } from 'react-router-dom'
import DisplayTemplate from '../utils/DisplayTemplates';


const projectType = [
    {
        value: 0,
        label: "GB"
    },
    {
        value: 1,
        label: "BB"
    },
    {
        value: 2,
        label: "MBB"
    },
]


export class UpdateIdeaPage extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);
    constructor(props) {
        super(props)
        const isApprovalPage = `${this.props.location.approval}`;
        let propsData = this.props.location;
        this.state = {
            ideaItem: {},
            WorkflowStatus: "",
            approvalPage: isApprovalPage,
            Comment: "",
            accountName: "",
            campaignName: "",
            serviceLineName: "",
            base64File1: "",
            base64File2: "",
            base64File1: "",
            base64File2: "",
            base64File3: "",
            base64File4: "",
            filename1: "",
            filename2: "",
            filename3: "",
            filename4: "",
            fileType1: "",
            fileType2: "",
            fileType3: "",
            fileType4: "",
            selectStyle: selectStyle,
            dataBridgeCampaign: false,
            xPollinationCampaign: false,
            reviewComments: [],
            propsData: propsData,
            commentLog: false,
            cancelRequest: false,
            Comments: '',
            Role: "Idea Owner",
            speechModal: false,
            modalIndex: "",
            previousTextForSpeech: "",
            impactOptions: [
                {
                    value: 0,
                    label: "No Value Delivery"
                },
                {
                    value: 1,
                    label: "Value Delivered to Client"
                },
                {
                    value: 2,
                    label: "Value Delivered to Genpact"
                },
                {
                    value: 3,
                    label: "Value Delivered to Client + Genpact"
                }
            ],
            impactOptionSelected: null,
            showClientFields: false,
            showGOLFields: false,

            clientApprovalOptions: [
                { value: '0', label: 'Yes' },
                { value: '1', label: 'No' },
            ],
            signOffFilename: "",
            signOffFileType: "",
            signOffContent: "",

            fpnaUpload: false,
            replaceFpnaDoc: false,
            fpnaOhr: "",
            golOhr: "",
            businessImpactValueArray: [],
            summaryArray: [],
            qcCommentLog: false,
            qcComments: [],
            IsVDMonetizable: "",
            MonetizationStatus: "",
            duplicateQCOHR: "",
            SubCampaignName: "",
            qcCommentsWithVD: [],
            qcLastComment: "",
            golFpnaFileDetails: [],
            isFpnaRequired: false,
            displayProjectTheme: false,
            //clustor states starts here
            clusterModal: false,
            projectTypeSelectedOption: null,
            projectIDSelectedOption: null,
            clustorProjects: [],
            guidSelectedOption: null,
            showHirearchy: true,
            showTreeHirearchy: false,
            clusterData: [],
            isClusterSaveDisabled: true,
            displayParentAlpha: '',
            clusterOptionSearched: false,


            // DB variables starts here
            dataBridgeType: null,

            processOwnerName: "",
            processOwnerOHR: "",
            processOnwerEmail: "",

            mucIDSelectedOption: null,

            sharedToClient: [],
            DB_clientName: "",
            DB_clientEmail: "",
            DB_clientRole: "",


            sharedToGOL: [],
            DB_GOLOHR: "",
            DB_GOLNAME: "",
            DB_GOLEmail: "",

            dbToolUsed: null,
            otherDBToolUsedText: "",

            CTestimonyFileName: "",
            CTestimonyFileType: "",
            CTestimony: "",

            RecType: "",
            DPA: [],
            displayPredictiveTemplate: false,
            COOMapping: [],
            COOMappingSelected: "",
            COOOHR: "",
            mucIDOptionsfromMetaData: [],
            mucIdTrimmed:"",


            // state variables used for validate of template data
            toolsUsed: "",
            insightsDrawn: "",
            metricsHighlighted: "",
            notesOrComments: "",
            additionalSolutionsDeployed: "",
            DescriptivePrescirptiveAnlytics: [],

            classificationModule: [],
            confusionMatrixData1: [],
            regressionData: [],
            regressionOtherFields: [],
            anomolyData: [],
            sentimentData: {},
            convertIdeaID:"",

            navigatetoDiagnostic:false,
            showHrefModal:false,
            showMUCIDToolTip:false,
            showMUCIDToolTipValue:"",

            displayDBFields:false,

            campaignList: [],
            campaignInsight:"",

            //AI
            ideasAI: [],
            isIdeaReplicable:"",

           

        }
    }


    fetchMetadata = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "",
                EntityName: "",
            };
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param),
            };
            fetch(GET_METADATA, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    //console.log("fetch metadata1", data);
                    this.setState(
                        {
                            workFlowStatusList: data ? data.WorkflowStatus : [],
                            certificationLevelsList: data ? data.CertificationLevels : [],
                            certificationWorkTypeList: data ? data.WorkType : [],
                            transformationLeversList: data ? data.TransformationLevers : [],
                            methodologyList: data ? data.Methodologies : [],
                            businessImpactMasterList: data ? data.BIMasterDataVerseNew : [],
                            biThreshold: data.BIQCThreshold ? data.BIQCThreshold[0] ? data.BIQCThreshold[0].pg_threshold : 0 : 0,
                            vrconditiontype: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_vrconditiontype : [] : [],
                            vrVerticalMapping: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_vertical ? data.BIQCVRMapping[0].pg_vertical.split("|") : "ALL" : "ALL" : "ALL",
                            vrRegionMapping: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_region ? data.BIQCVRMapping[0].pg_region.split("|") : "ALL" : "ALL" : "ALL",
                            COOMapping: data.COO ? data.COO : [],
                            mucIDOptionsfromMetaData: data.MUCID ? data.MUCID : [],
                            campaignList: data ? data.Campaigns : [],
                            
                        },
                        () => {
                           

                              

                            if (this.state.mucIDOptionsfromMetaData) {
                                const filteredData = this.state.mucIDOptionsfromMetaData.filter(item => item.pg_databridgeusecasecalculated !== null && item.pg_databridgeusecasecalculated !== undefined && item.pg_databridgeusecasecalculated !== '');
                                this.state.mucIDOptionsfromMetaData.filter((item) => {
                                    //console.log("muc =>",item.pg_databridgeusecasecalculated)
                                })

                                this.setState({ mucIDOptionsfromMetaData: filteredData }, () => {
                                    // console.log("muc =>",this.state.mucIDOptionsfromMetaData)
                                })

                            }
                            if(this.state.COOMapping){
                                this.loadCOO();
                            }
                            

                            resolve();
                        }
                    );
                })
                .catch((error) => {
                    Toast.fail(
                        "Error occured while getting metadata idea. Please try again!",
                        3000,
                        () => {
                            console.log(JSON.stringify(error));
                            this.setState(
                                {
                                    workFlowStatusList: [],
                                    certificationLevelsList: [],
                                    certificationWorkTypeList: [],
                                    transformationLeversList: [],
                                    methodologyList: [],
                                    typeOfImpactOriginalList: [],
                                    typeOfImpactList: [],
                                    businessImpactCategoriesList: [],
                                    businessImpactSubCategoriesList: [],
                                    businessImpactMasterList: [],
                                },
                                () => {
                                    resolve();
                                }
                            );
                        }
                    );
                });
        });
    };

    // componentDidUpdate(prevProps) {
    //     console.log("my global data =>", window.myGlobalVariableForDB);
    // }



    async componentDidMount() {
        Toast.loading("Fetching Metadata...", () => { });
        await this.fetchMetadata();
        Toast.hide();
        Toast.loading("Fetching Idea...", () => { });
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            }
        };
        // fetch(`${GET_IDEA_URL}IdeaId=392`, requestOptions)
        fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&RecID=''&isItIdeaOrGenAI='Idea'`, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("fetch idea", data)
                // console.log(JSON.stringify(data.ResultSets.Table1))
                this.setState({
                    ideaItem: data.ResultSets.Table1[0],
                    reviewComments: data.ReviewComments.Table1,
                    finalReviewComments: data.FinalReviewComments.Table1,
                    WorkflowStatus: data.ResultSets.Table1[0].WorkflowStatus,
                    Comment: data.ResultSets.Table1[0].Comment,
                    dataBridgeCampaign: (data.ResultSets.Table1[0].CampaignName === DATA_BRIDGE_CAMPAIGN),
                    xPollinationCampaign: (data.ResultSets.Table1[0].CampaignName === X_POLLINATION_CAMPAIGN),
                    clientSignOffDate: data.ResultSets.Table1[0].CustSignOffDate ? new Date(data.ResultSets.Table1[0].CustSignOffDate).toLocaleDateString('en-CA').split('/').reverse().join('-') : null,
                    clientName: data ? data.ResultSets.Table1[0].CustName : null,
                    clientRole: data ? data.ResultSets.Table1[0].CustDesgn : null,
                    golOhr: data ? data.ResultSets.Table1[0].GOLOHR : "",
                    fpnaOhr: data ? data.ResultSets.Table1[0].FPnAOHR : null,
                    golFullname: data ? data.ResultSets.Table1[0].GOLName : null,
                    fpnaName: data ? data.ResultSets.Table1[0].FPnAName : null,
                    businessImpactValueArray: data.BIData ? (data.BIData.Table1 ? (data.BIData.Table1.length > 0 ? data.BIData.Table1 : []) : []) : [],
                    qcComments: data.QcComments.Table1 ? data.QcComments.Table1 : [],
                    IsVDMonetizable: data.ResultSets.Table1[0].IsVDMonetizable === "Yes" ? true : false,
                    MonetizationStatus: data.ResultSets.Table1[0].MonetizationStatus ? data.ResultSets.Table1[0].MonetizationStatus : "",
                    duplicateQCOHR: data.ResultSets.Table1[0].QCOHR ? data.ResultSets.Table1[0].QCOHR : "",
                    SubCampaignName: data.ResultSets.Table1[0].SubCampaignName ? data.ResultSets.Table1[0].SubCampaignName : "",
                    qcCommentsWithVD: data.QcComments.Table2 ? data.QcComments.Table2 : [],
                    filename1: data.ResultSets.Table1 ? data.ResultSets.Table1[0].ProjectPPTFileName : "",
                    clusterData: data.ClusterData.Table1 ? data.ClusterData.Table1 : [],

                    dataBridgeType: data.ResultSets.Table1[0].DBProjectType,
                    mucIDSelectedOption: data.ResultSets.Table1[0].MUCIDTitle,

                    processOwnerName: data.ResultSets.Table1[0].ProcessOwner,
                    processOwnerOHR: data.ResultSets.Table1[0].ProcessOwnerOHR,

                    dbToolUsed: data.ResultSets.Table3[0].DBTool,
                    otherDBToolUsedText: data.ResultSets.Table3[0].OtherDBTool,

                    sharedToGOL: data.ResultSets.Table3[0].IsSharedGOL,
                    DB_GOLNAME: data.ResultSets.Table3[0].GOL,
                    DB_GOLOHR: data.ResultSets.Table3[0].GOLOHR,

                    sharedToClient: data.ResultSets.Table3[0].IsSharedClient,
                    DB_clientName: data.ResultSets.Table3[0].ClientName,
                    DB_clientRole: data.ResultSets.Table3[0].ClientRole,

                    CTestimonyFileName: data.ResultSets.Table1[0].CTestimonyFileName,
                    CTestimonyFileType: data.ResultSets.Table1[0].CTestimonyFileType,

                    DPA: data.Templates.ResultSets.Table2 && data.Templates.ResultSets.Table2[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table2,
                    RecType: data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? "" : data.Templates.ResultSets.Table1[0].RecType,


                    // these values are being fetched to validate whether the user has filled up the template data or no
                    toolsUsed: data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table1[0].Tools,
                    insightsDrawn: data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table1[0].Insights,
                    metricsHighlighted: data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table1[0].Metrics,
                    notesOrComments: data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table1[0].Comment,
                    additionalSolutionsDeployed: data.Templates.ResultSets.Table1[0] ? data.Templates.ResultSets.Table1[0].AddSolution : "",

                    SOrdTable: data.Templates.ResultSets.Table5 && data.Templates.ResultSets.Table5[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table5,
                    DescriptivePrescirptiveAnlytics: data.Templates.ResultSets.Table2 && data.Templates.ResultSets.Table2[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table2,

                    ideasAI: data.ResultSets.Table2 ? data.ResultSets.Table2[0] : [],
                }, () => {
                    if(this.state.ideaItem.CampaignName && this.state.campaignList){
                        this.state.campaignList.map((campaign)=>{
                          if(campaign.CampaignName === this.state.ideaItem.CampaignName){
                            this.setState({campaignInsight:campaign.CampaignDescr},()=>{})
                          }
                        })
                    }
                    if(this.state.ideaItem.VerticalManual){
                        this.loadCOO();
                    }
                    if (this.state.ideaItem && this.state.ideaItem.DBProjectType) {
                        let localDBProjectType = this.state.ideaItem.DBProjectType;
                        if (localDBProjectType) {
                            DATABRIDGETYPES.map((item) => {
                                if (item.label === this.state.dataBridgeType) {
                                    this.setState({ dataBridgeType: item }, () => { })
                                }
                            })
                        }

                    }
                    if (this.state.mucIDSelectedOption) {
                        let selectedOption = [];
                        let databridgeuseCase = "";
                        let mucIdUseCase = "";
                        this.state.mucIDOptionsfromMetaData.map((item, index) => {
                            if(item.pg_databridgeusecasecalculated === this.state.mucIDSelectedOption){
                                selectedOption = {
                                    value: index,
                                    label: item.pg_databridgeusecasecalculated,
                                };
                            }
                            // databridgeuseCase = item.pg_databridgeusecasecalculated.toString().toLowerCase();
                            mucIdUseCase = item.pg_usecasetitle;
                            return true;
                        });
                        this.setState({ mucIDSelectedOption: selectedOption, mucIdTrimmed:mucIdUseCase }, () => {
                            console.log("muc id trimmed =>",this.state.mucIdTrimmed)
                            console.log("mucid selected option=> ",this.state.mucIDSelectedOption)
                        })


                    }
                    if (data.ResultSets.Table3 && data.ResultSets.Table3[0].DBTool) {
                        let localDBTool = data.ResultSets.Table3[0].DBTool;
                        if (localDBTool) {
                            DOBTOOLS.map((item) => {
                                if (item.label === localDBTool) {
                                    this.setState({ dbToolUsed: item }, () => { })
                                }
                            })
                        }
                    }

                    if (this.state.sharedToClient) {
                        YESORNO.map((item) => {
                            if (item.label === this.state.sharedToClient) {
                                this.setState({ sharedToClient: item }, () => { })
                            }
                        })
                    }

                    if (this.state.sharedToGOL) {
                        YESORNO.map((item) => {
                            if (item.label === this.state.sharedToGOL) {
                                this.setState({ sharedToGOL: item }, () => { })
                            }
                        })
                    }
                    if (this.state.clusterData && this.state.clusterData.length > 0) {
                        const targetRecID = this.state.ideaItem.LeanID;
                        const filteredData = this.state.clusterData.filter(item => item.RecAlpha === targetRecID);

                        let getParentAlpa = "";
                        this.state.clusterData.map(item => {
                            if (item.RecAlpha === targetRecID) {
                                getParentAlpa = item.ParentAlpha;
                            }
                        })
                        if (getParentAlpa) {
                            this.state.clusterData.map(item => {
                                if (getParentAlpa === item.RecAlpha) {
                                    const selectedOption = {
                                        value: 0,
                                        label: item.ProjectType
                                    };
                                    this.state.projectTypeSelectedOption = selectedOption;
                                    this.setState({ projectTypeSelectedOption: selectedOption }, () => { })
                                }
                            })
                        }

                        if (filteredData && filteredData.length > 0 && filteredData !== undefined && filteredData !== null) {
                            this.setState({ displayParentAlpha: filteredData[0].ParentAlpha }, () => { })
                            const selectedOption = {
                                value: 0,
                                label: filteredData[0].ParentAlpha
                            };
                            this.setState({ projectIDSelectedOption: selectedOption }, () => {
                                this.setState({ projectIDSelectedOption: selectedOption }, () => {
                                    if (this.state.projectTypeSelectedOption && (this.state.projectTypeSelectedOption.label === null || this.state.projectTypeSelectedOption.label === "")) {
                                        if (this.state.projectIDSelectedOption.label.startsWith("GB")) {
                                            const selectedOptionGB = {
                                                value: 0,
                                                label: "GB"
                                            };
                                            this.state.projectTypeSelectedOption = selectedOptionGB;
                                            this.setState({ projectTypeSelectedOption: selectedOptionGB }, () => {
                                                console.log(this.state.projectTypeSelectedOption)
                                            })
                                        } else {
                                            const selectedOptionBB = {
                                                value: 0,
                                                label: "BB"
                                            };
                                            this.state.projectTypeSelectedOption = selectedOptionBB;
                                            this.setState({ projectTypeSelectedOption: selectedOptionBB }, () => {
                                                console.log(this.state.projectTypeSelectedOption)
                                            })
                                        }
                                    }
                                })
                            })
                        } else {
                            this.setState({ displayParentAlpha: "", hideParentID: true }, () => { })
                        }
                    } else {
                        this.setState({ displayParentAlpha: "", hideParentID: true }, () => { })
                    }


                    this.state.businessImpactValueArray = this.state.businessImpactValueArray.map(item => ({
                        ...item,
                        pg_srno: item.SrNo,
                        dollarValue: item.DollarImpact,
                        fteValue: item.FTEImpact,
                        impactType: item.ImpactType,
                        monetizableType: item.MonetizableType
                    })).map(item => {
                        return item;

                    });
                    let myArray = [...this.state.businessImpactValueArray];
                    const groupedData = myArray.reduce((accumulator, currentValue) => {
                        const impactType = currentValue.impactType;
                        const monetizableType = currentValue.monetizableType;
                        const fteValue = parseFloat(currentValue.fteValue) || 0.0;
                        const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;

                        const existingRow = accumulator.find(row => row.impactType === impactType && row.monetizableType === monetizableType);
                        if (existingRow) {
                            existingRow.fteValue += fteValue
                            existingRow.dollarValue += dollarValue
                        } else {
                            accumulator.push({ impactType, monetizableType, fteValue, dollarValue });
                        }
                        return accumulator;
                    }, []);
                    this.setState({ summaryArray: groupedData }, () => { });


                    let sortedSummaryIndex = groupedData.sort((a, b) => a.impactType.toLowerCase().localeCompare(b.impactType.toLowerCase()))
                    this.setState({ summaryArray: sortedSummaryIndex }, () => { });


                    if (this.state.clientSignOffDate === "1900-01-01") {
                        this.setState({ clientSignOffDate: "" }, () => { console.log(this.state.clientSignOffDate) })
                    }
                    // this.setCampaignName();
                    this.setServiceLineName();
                    Toast.hide();
                    if (this.state.ideaItem.VDType === "Value Delivered to Client + Genpact") {
                        this.setState({ showClientFields: true, showGOLFields: true }, () => { })
                    }
                    if (this.state.ideaItem.VDType === "No Value Delivery") {
                        this.setState({
                            showClientFields: false, showGOLFields: false, IsVDMonetizable: false, MonetizationStatus: "",
                            summaryArray: "", businessImpactValueArray: "", duplicateQCOHR: ""
                        }, () => { })
                    }
                    if (this.state.ideaItem.VDType === "Value Delivered to Client") {
                        this.setState({ showClientFields: true, showGOLFields: false }, () => { })
                    }
                    if (this.state.ideaItem.VDType === "Value Delivered to Genpact") {
                        this.setState({ showClientFields: false, showGOLFields: true }, () => { })
                    }
                    this.state.impactOptions.map(item => {
                        if (item.label === this.state.ideaItem.VDType) {
                            this.setState({ impactOptionSelected: item }, () => { })
                        }
                    })

                    if (this.state.ideaItem.CreatedDate) {
                        let created = new Date(this.state.ideaItem.CreatedDate);
                        let abc = new Date(DISPLAYPROJECTTHEME);
                        if (created.getTime() < abc.getTime()) {
                            //console.log("created date is lesser")
                            this.setState({ displayProjectTheme: true })
                        } else {
                            this.setState({ displayProjectTheme: false })
                            // console.log("created date is greater ")
                        }
                    }


                    if (this.state.ideaItem.CreatedDate) {
                        let db_idea_created = new Date(this.state.ideaItem.CreatedDate);
                        let abc = new Date(DB_PEF_DATE);
                        if (db_idea_created.getTime() < abc.getTime()) {
                          this.setState({displayDBFields:false},()=>{})
                          //console.log("created date is lesser")
                        } else {
                          this.setState({displayDBFields:true},()=>{})
                          //console.log("created date is greater")
                        }
                    }



                    if (this.state.ideasAI) {
                        if(this.state.ideasAI.IsIdeaReplicable ){
                            this.setState({ isIdeaReplicable: "Yes" }, () => { })
                        }else{
                            this.setState({ isIdeaReplicable: "No" }, () => { })
                        }
                    }
                      
                      
                });
            })
            .catch(error => {
                Toast.fail("Error while fetching idea. Please try again!", 3000, () => { });
                Toast.hide();
            });
    }

    fetchPPTContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let projectFileType = this.state.projectFileType;
            // fetch(`${GET_IDEA_URL}IdeaId=964&${PPT_CONTENT}=true`, requestOptions)
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    const projectDeckMetadata = data.PPTContent ? data.PPTContent : "";
                    const projectContent = "data:" + projectFileType + ";base64," + projectDeckMetadata;
                    this.setState({
                        projectDeckContent: projectContent,
                    }, () => {
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching Project Deck content. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }

    fetchSignoffContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            console.log("sign off file type in fetch sign off", this.state.signOffFileType)
            let signoffFileType = this.state.signOffFileType;
            // fetch(`${GET_IDEA_URL}IdeaId=964&${SIGNOFF_CONTENT}=true`, requestOptions)
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${SIGNOFF_CONTENT}=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    const signoffMetadata = data.SignoffContent ? data.SignoffContent : "";
                    const signoffContent = "data:" + signoffFileType + ";base64," + signoffMetadata;
                    this.setState({
                        signOffContent: signoffContent,
                    }, () => {
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching Customer sign-off content. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }

    fetchGOLApprovalContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };

            let signoffFileType = this.state.signOffFileType;
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${GOL_APPROVAL_CONTENT}=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    const signoffMetadata = data.SignoffContent ? data.SignoffContent : "";
                    const signoffContent = "data:" + signoffFileType + ";base64," + signoffMetadata;
                    this.setState({
                        signOffContent: signoffContent,
                    }, () => {
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching GOL approval content. Please try again!", 3000, () => { });
                    resolve();
                });
        })
    }
    fetchFpnaApprovalContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let signoffFileType = this.state.signOffFileType;
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${FPnA_APPROVAL_CONTENT}=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    const signoffMetadata = data.SignoffContent ? data.SignoffContent : "";
                    const signoffContent = "data:" + signoffFileType + ";base64," + signoffMetadata;
                    this.setState({
                        signOffContent: signoffContent,
                    }, () => {
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching FP&A approval content. Please try again!", 3000, () => { });
                    resolve();
                });
        })
    }

    setServiceLineName = () => {
        var opts = ["Accounts Payable", "Claims", "Commercial Leasing & Lending", "Customer Care", "F&A Multi-tower Consulting", "Insurance Actuarial", "Invoice To Cash", "Master Data Not Found", "Mortgage Origination & Servicing", "Non Prioritized Service Line", "Record to Report", "Regulatory Affairs", "Retail Brokerage/Wealth Management", "Risk Management", "Sales & Commercial", "Sourcing & Procurement", "Supply Chain Management", "Trust & Safety", "Underwriting"];
        for (var i = 0; i < opts.length; i++) {
            if (i === this.state.ideaItem.ServiceLineId - 1) {
                this.setState({
                    serviceLineName: opts[i]
                });
            }
        }
    }

    setCampaignName = () => {
        var opts = document.getElementById('ideationtype').childNodes;
        for (var i = 0; i < opts.length; i++) {
            if (i === this.state.ideaItem.CampaignTypeId - 1) {
                this.setState({
                    campaignName: opts[i].innerText
                });
            }
        }
    }



    validate = () => {

        let isValid = true;
        let styleStateObj = this.state.selectStyle;

        if (this.state.filename1 === "" || this.state.filename1 === undefined || this.state.filename1 === null) {
            console.log("filename 1", this.state.filename1)
            isValid = false
            Toast.fail("Please upload the Project Presentation Deck file.", 3000, () => { });
        }

        // if(this.state.ideaItem.ProjectPPTFileName === null || this.state.ideaItem.ProjectPPTFileName == undefined || this.state.ideaItem.ProjectPPTFileName === "" ){
        //     console.log("PPT Deck filename",this.state.ProjectPPTFileName)
        //     if (this.state.filename1 === "" || this.state.filename1 === undefined || this.state.filename1 === null) {
        //         isValid = false
        //         Toast.fail("Please upload the Project Presentation Deck file.", 3000, () => { });
        //     }
        // }
        if (this.state.showClientFields) {
            if (this.state.ideaItem.CustomerSignoffFileName === null || this.state.ideaItem.CustomerSignoffFileName === undefined || this.state.ideaItem.CustomerSignoffFileName === "") {
                if (this.state.signOffFilename === null || this.state.signOffFilename === "" || this.state.signOffFilename === undefined || this.state.signOffFilename.length === 0 || !this.state.signOffFilename) {
                    isValid = false
                    Toast.fail("Please upload the Client Approval Document.", 3000, () => { });
                }

                if (!this.state.clientRole) {
                    isValid = false
                    Toast.fail("Please fill Client Role.", 3000, () => { });
                }
                if (!this.state.clientSignOffDate) {
                    isValid = false
                    Toast.fail("Please fill Client Sign-off date.", 3000, () => { });
                }
            }
            if (this.state.ideaItem.CustName === "" || this.state.ideaItem.CustName === undefined || this.state.ideaItem.CustName === null) {
                if (!this.state.clientName) {
                    isValid = false
                    Toast.fail("Please fill Client Name.", 3000, () => { });
                }
            }

        }

        if (this.state.showGOLFields) {
            if (this.state.golOhr === "" || this.state.golOhr === null || this.state.golOhr === undefined) {
                isValid = false;
                Toast.fail("Please fill GOL OHR.", 3000, () => { })
            }

            if (this.state.ideaItem.GOLName === null || this.state.ideaItem.GOLName === undefined || this.state.ideaItem.GOLName === "") {
                if (this.state.golFullname === "" || this.state.golFullname === null || this.state.golFullname === undefined || this.state.golFullname.length === 0 || !this.state.golFullname) {
                    isValid = false;
                    Toast.fail("Please fill GOL OHR to fetch GOL Name.")
                }

            }

            if (this.state.golFpnaFileDetails !== null || !this.state.golFpnaFileDetails) {
                if (this.state.golFpnaFileDetails[0].label === "" && this.state.golFpnaFileDetails[1].label === "") {
                    isValid = false;
                    Toast.fail("Please upload the GOL / FP&A Approval Document", 3000, () => { })
                }
            }
            this.state.businessImpactValueArray && this.state.businessImpactValueArray.map((item) => {
                if (item.ImpactType === "Impact at Genpact") {
                    if (item.MonetizableType === "Increase in Revenue" || item.MonetizableType === "Reduction in Cost") {
                        if (!this.state.fpnaOhr) {
                            isValid = false;
                            Toast.fail("Please fill FP&A OHR.", 3000, () => { })
                        }
                        if (this.state.fpnaOhr) {
                            if (!this.state.fpnaName) {
                                isValid = false;
                                Toast.fail("Please fill FP&A OHR to fetch FP&A Name.", 3000, () => { })
                            }
                        }
                    }
                }
            });



        }

        // validations copied from final-review-idea-page starts here
        if (this.state.ideaItem.CertificationLevelName === "Lean") {
            if (this.state.impactOptionSelected === null) {
                isValid = false;
                Toast.fail("Please select Value Delivery Type", 3000, () => { });
            }
        }


        if (this.state.ideaItem.CertificationLevelName === "Lean") {
            if (this.state.impactOptionSelected && this.state.impactOptionSelected.label !== "No Value Delivery") {
                if (this.state.businessImpactValueArray.length === 0 || !this.state.businessImpactValueArray) {
                    Toast.fail("Value Delivery cannot be empty, please fill and resubmit.", 3000, () => { });
                    isValid = false;
                }
                if (this.state.businessImpactValueArray) {
                    if (this.state.finalImpactDollar <= 0) {
                        Toast.fail("Total Dollar Impact cannot be zero. Please check and resubmit.", 3000, () => { });
                        isValid = false;
                    }
                    if (this.state.finalImpactFTE > 0 && this.state.finalImpactDollar <= 0) {
                        Toast.fail("Total Impact Dollar cannot be zero when FTE is non-zero. Please check and resubmit.", 3000, () => { });
                        isValid = false;
                    }
                    this.state.businessImpactValueArray.filter((item) => {
                        if (parseFloat(item.DollarImpact) === 0 || parseFloat(item.DollarImpact) === "" || !item.DollarImpact || item.DollarImpact === null) {
                            isValid = false;
                            Toast.fail("Dollar Impact value cannot be zero.", 3000, () => { })
                        }
                        if (item && item.isFTEPresent === true) {
                            if (parseFloat(item.FTEImpact)) { }
                            else {
                                isValid = false;
                                Toast.fail("FTE Impact value cannot be zero", 3000, () => { })
                            }
                            if (parseFloat(item.FTEImpact) === 0) {
                                isValid = false;
                                Toast.fail("FTE Impact value cannot be zero", 3000, () => { })
                            }
                            if (parseFloat(item.FTEImpact) > parseFloat(item.DollarImpact)) {
                                isValid = false;
                                Toast.fail("FTE impact value cannot be greater than dollar value", 3000, () => { })
                            }
                        }
                    });
                }

            }

            if (this.state.impactOptionSelected && this.state.impactOptionSelected.label !== "No Value Delivery") {
                // if (this.state.monetizableProjectSelectedOption === null) {
                //     isValid = false;
                //     Toast.fail("Please select monetizatable project", 3000, () => { })
                // }
                if (this.state.monetizableProjectSelectedOption === null || 
                    this.state.monetizableProjectSelectedOption.length === 0 ||
                    this.state.monetizableProjectSelectedOption === undefined 
                  ) {
                    isValid = false;
                    Toast.fail("Please select monetizatable project", 5000, () => { });
                }
            }

            if (this.state.impactOptionSelected.label === "Value Delivered to Client + Genpact") {
                if (this.state.businessImpactValueArray.length > 0) {
                    let impactGenpactTrue = this.state.businessImpactValueArray.some(vendor => vendor['ImpactType'] === 'Impact at Genpact')
                    let impactClientTrue = this.state.businessImpactValueArray.some(vendor => vendor['ImpactType'] === 'Impact at Client')
                    if (impactGenpactTrue === false) {
                        isValid = false;
                        Toast.fail('Please add both Impact types.', 3000, () => { })
                    }
                    if (impactClientTrue === false) {
                        isValid = false;
                        Toast.fail('Please add both Impact types.', 3000, () => { })
                    }
                }
            }


            if (this.state.impactOptionSelected) {
                if (this.state.monetizableProjectSelectedOption) {
                    if (this.state.monetizableProjectSelectedOption.label === "Yes") {
                        if (!this.state.monetizationStatusSelectedOption || this.state.monetizationStatusSelectedOption === null || this.state.monetizationStatusSelectedOption === undefined || this.state.monetizationStatusSelectedOption.length === 0) {
                            isValid = false;
                            Toast.fail("Since this project has been marked as Monetizable, monetizable status has to be selected", 5000, () => { })
                        }
                    }
                }
                if (this.state.monetizableProjectSelectedOption && this.state.monetizableProjectSelectedOption.label === "Yes" && this.state.monetizationStatusSelectedOption !== null) {
                    if ((this.state.businessImpactValueArray.filter(item => item.IsMonetized === "Yes")).length > 0) { }
                    else {
                        isValid = false;
                        Toast.fail("Since this is a monetizable project, there needs to be at least one impact type as ‘Impact at Genpact’ selected and category selected as a ‘Increase in Revenue’ .", 5000, () => { })
                    }
                }
                if (this.state.monetizableProjectSelectedOption && this.state.monetizableProjectSelectedOption.label === "Yes") {
                    if (this.state.monetizationStatusSelectedOption && this.state.monetizationStatusSelectedOption.label === "Approved by Client") {
                        if (this.state.SowID === "" || this.state.SowID === null || this.state.SowID === undefined || this.state.SowID.length === 0 || !this.state.SowID) {
                            isValid = false;
                            Toast.fail("Please fill SOW ID/SFDC/OLI ID for monetization", 3000, () => { })
                        }
                    }
                }

            }
        }



        // ... DB validations starts here...

        if (this.state.ideaItem.TransformationLeverName && this.state.ideaItem.TransformationLeverName === "Data" && this.state.displayDBFields) {
            if (
                this.state.dataBridgeType === null ||
                this.state.dataBridgeType === undefined ||
                !this.state.dataBridgeType ||
                this.state.dataBridgeType.length === 0
            ) {
                isValid = false;
                styleStateObj.dataBridgeType = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.dataBridgeType = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }

            if (
                this.state.mucIDSelectedOption === null ||
                this.state.mucIDSelectedOption === undefined ||
                !this.state.mucIDSelectedOption ||
                this.state.mucIDSelectedOption.length === 0
            ) {
                isValid = false;
                styleStateObj.mucIDSelectedOption = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.mucIDSelectedOption = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }

            if (
                this.state.sharedToGOL === null ||
                this.state.sharedToGOL === undefined ||
                !this.state.sharedToGOL ||
                this.state.sharedToGOL.length === 0
            ) {
                isValid = false;
                styleStateObj.sharedToGOL = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.sharedToGOL = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }

            if (this.state.sharedToGOL && this.state.sharedToGOL.label === "Yes") {
                if (
                    this.state.DB_GOLNAME === "" ||
                    this.state.DB_GOLNAME === null ||
                    this.state.DB_GOLNAME === undefined ||
                    !this.state.DB_GOLNAME
                ) {
                    isValid = false;
                    styleStateObj.DB_GOLNAME = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.DB_GOLNAME = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }

                if (
                    !this.state.DB_GOLOHR ||
                    this.state.DB_GOLOHR === null ||
                    this.state.DB_GOLOHR === undefined ||
                    this.state.DB_GOLOHR === ""
                ) {
                    isValid = false;
                    styleStateObj.DB_GOLOHR = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.DB_GOLOHR = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
            }
            if (
                this.state.sharedToClient === null ||
                this.state.sharedToClient === undefined ||
                !this.state.sharedToClient ||
                this.state.sharedToClient.length === 0
            ) {
                isValid = false;
                styleStateObj.sharedToClient = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.sharedToClient = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }
            if (this.state.sharedToClient && this.state.sharedToClient.label === "Yes") {
                if (
                    this.state.DB_clientName === "" ||
                    this.state.DB_clientName === null ||
                    this.state.DB_clientName === undefined ||
                    !this.state.DB_clientName
                ) {
                    isValid = false;
                    styleStateObj.DB_clientName = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.DB_clientName = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
                if (
                    this.state.DB_clientRole === "" ||
                    this.state.DB_clientRole === null ||
                    this.state.DB_clientRole === undefined ||
                    !this.state.DB_clientRole
                ) {
                    isValid = false;
                    styleStateObj.DB_clientRole = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.DB_clientRole = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }


            }


            if (
                this.state.dbToolUsed === null ||
                this.state.dbToolUsed === undefined ||
                !this.state.dbToolUsed ||
                this.state.dbToolUsed.length === 0
            ) {
                isValid = false;
                styleStateObj.dbToolUsed = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.dbToolUsed = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }

            if (this.state.dbToolUsed && this.state.dbToolUsed.label === "Yes") {
                if (
                    this.state.otherDBToolUsedText === "" ||
                    this.state.otherDBToolUsedText === null ||
                    this.state.otherDBToolUsedText === undefined ||
                    !this.state.otherDBToolUsedText
                ) {
                    isValid = false;
                    styleStateObj.otherDBToolUsedText = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.otherDBToolUsedText = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
            }

            if (
                this.state.processOwnerOHR === "" ||
                this.state.processOwnerOHR === null ||
                this.state.processOwnerOHR === undefined ||
                !this.state.processOwnerOHR
            ) {
                isValid = false;
                styleStateObj.processOwnerOHR = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj,
                });
            } else {
                styleStateObj.processOwnerOHR = textBoxValidation(true);
                this.setState({
                    selectStyle: styleStateObj,
                });
            }

            if (this.state.processOwnerOHR) {
                if (
                    this.state.processOwnerName === "" ||
                    this.state.processOwnerName === null ||
                    this.state.processOwnerName === undefined ||
                    !this.state.processOwnerName
                ) {
                    isValid = false;
                    styleStateObj.processOwnerName = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                } else {
                    styleStateObj.processOwnerName = textBoxValidation(true);
                    this.setState({
                        selectStyle: styleStateObj,
                    });
                }
            }

            if (this.state.sharedToClient && this.state.sharedToClient.label === "Yes") {
                // if (
                //     this.state.CTestimonyFileName === "" ||
                //     this.state.CTestimonyFileName === null ||
                //     this.state.CTestimonyFileName === undefined ||
                //     !this.state.CTestimonyFileName
                // ) {
                //     isValid = false;
                //     Toast.fail("Please upload Client testimony file", 3000, () => { });
                // }
            }

            if(this.state.dataBridgeType){
                if(this.state.dataBridgeType.label==="Descriptive"){
                    //console.log("rec type =>",this.state.RecType)
                    if(this.state.RecType === undefined || this.state.RecType=== "" || this.state.RecType=== null || !this.state.RecType){
                        isValid = false;
                        Toast.fail("Please fill up the data of Descriptive template",3000,()=>{})
                    }

                    if(this.state.RecType && this.state.RecType === "DPA"){
                        isValid = false;
                        Toast.fail("Please fill up the data of Descriptive template",3000,()=>{})
                    }
                }
                if(this.state.dataBridgeType.label==="Diagnostic" || this.state.dataBridgeType.label==="Prescriptive"){
                    if(this.state.ideaItem.DBProjectType){
                        if(this.state.dataBridgeType.label !== this.state.ideaItem.DBProjectType){
                            isValid = false;
                            Toast.fail("Please fill up the data of selected data bridge template",3000,()=>{})
                        }
                    }

                    if(this.state.DescriptivePrescirptiveAnlytics === null || this.state.DescriptivePrescirptiveAnlytics===undefined || 
                        this.state.DescriptivePrescirptiveAnlytics.length === undefined || this.state.DescriptivePrescirptiveAnlytics.length === 0
                        )  {
                            isValid = false;
                            Toast.fail("Please fill up the data of " + this.state.dataBridgeType.label + " template",3000,()=>{})
                    } 
                    if(this.state.RecType && this.state.RecType === "DA"){
                        isValid = false;
                        Toast.fail("Please fill up the data of " + this.state.dataBridgeType.label + " template",3000,()=>{})
                    }
                }
                if(this.state.dataBridgeType.label==="Predictive"){
                    if(this.state.SOrdTable.length === 0 ){
                        isValid = false;
                        Toast.fail("Please fill up atleast one model from Predictive template",3000,()=>{})
                    }else{
                        this.state.SOrdTable.map((item, index) => {
                            if (item.SOrd === 0) {
                                if(item.ModelType=== null|| item.ModelType === undefined || item.ModelType.length ===0){
                                    isValid = false;
                                    Toast.fail("Please fill up atleast one model from Predictive template",3000,()=>{})
                                }
                            }
                        });
                    }
                }
            }

        }
        // ... DB validations ends here...
        return isValid;
    }

    submitIdea = () => {
        let param = {};
        this.state.businessImpactValueArray = this.state.businessImpactValueArray.map(item => ({
            ...item,
            pg_srno: item.SrNo,
            dollarValue: item.DollarImpact,
            fteValue: item.FTEImpact
        })).map(item => {
            return item;

        });
        let filteredBIData = this.state.businessImpactValueArray.filter(function (e) {
            return (e !== null && e !== undefined);
        });
        param = {
            IdeaId: this.props.location.state,
            BusinessImpactData: filteredBIData,
            FinalImpactDollar: this.state.finalImpactDollar ? this.state.finalImpactDollar : null,
            FinalImpactFTE: this.state.finalImpactFTE ? this.state.finalImpactFTE : null,

            VDType: this.state.impactOptionSelected ? this.state.impactOptionSelected.label : this.state.ideaItem.VDType,
            SowID: this.state.SowID ? this.state.SowID : (this.state.ideaItem.SowID ? this.state.ideaItem.SowID : ""),
            IsVDMonetizable: this.state.monetizableProjectSelectedOption ? this.state.monetizableProjectSelectedOption.label : (this.state.IsVDMonetizable ? this.state.IsVDMonetizable : ""),
            MonetizationStatus: this.state.monetizationStatusSelectedOption ? this.state.monetizationStatusSelectedOption.label : this.state.ideaItem.MonetizationStatus,
            case: "new",
            casetype: "OwnerUploadDocuments",
            updaterRole: "Idea Owner",
            CampaignName: this.state.ideaItem.CampaignName,
            TransformationLeverName: this.state.ideaItem.TransformationLeverName,
            // IsVDMonetizable: this.state.impactOptionSelected ? (this.state.impactOptionSelected.label=== "No Value Delivery" ? "No" : ""):"",
            // DB api mappings starts here
            DBProjectType: this.state.dataBridgeType ? this.state.dataBridgeType.label : "",

            MUCID: this.state.mucIDSelectedOption && this.state.mucIDSelectedOption.value ? this.state.mucIDSelectedOption.value : this.state.ideaItem.MUCID,
            MUCIDTitle: this.state.mucIDSelectedOption && this.state.mucIDSelectedOption.label ? this.state.mucIDSelectedOption.label : this.state.ideaItem.MUCIDTitle,

            DB_COO: this.state.COOMappingSelected ? this.state.COOMappingSelected : "",
            DB_COOOHR: this.state.COOOHR ? this.state.COOOHR : "",

            ProcessOwner: this.state.processOwnerName ? this.state.processOwnerName : "",
            ProcessOwnerOHR: this.state.processOwnerOHR ? this.state.processOwnerOHR : "",
            processOnwerEmail: this.state.processOnwerEmail ? this.state.processOnwerEmail : "",

            DBTool: this.state.dbToolUsed ? this.state.dbToolUsed.label : "",
            OtherDBTool: this.state.dbToolUsed && this.state.dbToolUsed.label === "Others" ? (this.state.otherDBToolUsedText ? this.state.otherDBToolUsedText : "") : "",

            IsSharedGOL: this.state.sharedToGOL ? this.state.sharedToGOL.label : "",
            DB_GOL: this.state.sharedToGOL && this.state.sharedToGOL.label === "Yes" ? (this.state.DB_GOLNAME ? this.state.DB_GOLNAME : "") : "",
            DB_GOLOHR: this.state.sharedToGOL && this.state.sharedToGOL.label === "Yes" ? (this.state.DB_GOLOHR ? this.state.DB_GOLOHR : "") : "",

            IsSharedClient: this.state.sharedToClient ? this.state.sharedToClient.label : "",
            DB_ClientName: this.state.sharedToClient && this.state.sharedToClient.label === "Yes" ? (this.state.DB_clientName ? this.state.DB_clientName : "") : "",
            DB_ClientRole: this.state.sharedToClient && this.state.sharedToClient.label === "Yes" ? (this.state.DB_clientRole ? this.state.DB_clientRole : "") : "",

            // CTestimonyFileName: this.state.sharedToClient && this.state.sharedToClient.label === "Yes" ? (this.state.CTestimonyFileName ? this.state.CTestimonyFileName : "") : "",
            // CTestimonyFileType: this.state.sharedToClient && this.state.sharedToClient.label === "Yes" ? (this.state.CTestimonyFileType ? this.state.CTestimonyFileType : "") : "",

        }
        console.log(param)
        let finalJsonData = Checksum(param);
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
            .then(response => response.json())

    }

    updateIdea = (e) => {
        if (this.validate()) {
            if (window.confirm("Are you sure you want to update the Project?")) {
                this.submitIdea()
                Toast.loading("Updating Lean Project...", () => { });
                let param = {
                    ProjectPPTDeck: this.state.base64File1,
                    ProjectPPTFilename: this.state.filename1 ? this.state.filename1 : this.state.ideaItem.ProjectPPTFileName,
                    ProjectPPTType: this.state.fileType1 ? this.state.fileType1 : this.state.ideaItem.ProjectPPTType,

                    CustomerLeadSignOff: this.state.signOffContent,
                    CustomerSignoffFilename: this.state.signOffFilename ? this.state.signOffFilename : this.state.ideaItem.CustomerSignoffFileName,
                    CustomerSignoffType: this.state.signOffFileType ? this.state.signOffFileType : this.state.ideaItem.CustomerSignoffType,

                    // golApproval:this.state.base64File3,
                    // golApprovalFileName:this.state.filename3 ? this.state.filename3 : this.state.ideaItem.GOLFileName,
                    // golApprovalType:this.state.fileType3 ? this.state.fileType3 : this.state.ideaItem.GOLFileType,

                    // fpnaApproval:this.state.base64File4,
                    // fpnaApprovalFileName:this.state.filename4 ? this.state.filename4 : this.state.ideaItem.FPnAFileName,
                    // fpnaApprovalType:this.state.fileType4 ? this.state.fileType4 : this.state.ideaItem.FPnAFileType,


                    golApproval: this.state.base64File3,
                    golApprovalFileName: this.state.filename3,
                    golApprovalType: this.state.fileType3,

                    fpnaApproval: this.state.base64File4,
                    fpnaApprovalFileName: this.state.filename4,
                    fpnaApprovalType: this.state.fileType4,

                    IdeaId: this.props.location.state,
                    ModifiedByOHR: this.sessionData.profile.oHRId,
                    Role: "Idea Owner",
                    // VDType:this.state.impactOptionSelected.label,

                    ClientName: this.state.clientName,
                    ClientRole: this.state.clientRole,
                    ClientSignOffDate: this.state.clientSignOffDate,
                    GolOhr: this.state.golFullname ? this.state.golOhr : "",
                    FpnaName: this.state.fpnaName,
                    GolFullname: this.state.golFullname,
                    FpnaOhr: this.state.fpnaName ? this.state.fpnaOhr : "",

                    
                    // DB Client testimony
                    CTestimony: this.state.CTestimony ? this.state.CTestimony : "",
                    CTestimonyFileName: this.state.sharedToClient && this.state.sharedToClient.label === "Yes" ? (this.state.CTestimonyFileName ? this.state.CTestimonyFileName : this.state.ideaItem.CTestimonyFileName) : this.state.ideaItem.CTestimonyFileName,
                    CTestimonyFileType: this.state.sharedToClient && this.state.sharedToClient.label === "Yes" ? (this.state.CTestimonyFileType ? this.state.CTestimonyFileType : this.state.ideaItem.CTestimonyFileType) : this.state.ideaItem.CTestimonyFileType,

                    updaterRole: "Idea Owner",
                    CampaignName: this.state.ideaItem.CampaignName,
                    TransformationLeverName: this.state.ideaItem.TransformationLeverName,


                    


                }
                console.log(param)
                let finalJsonData = Checksum(param);
                let str1 = finalJsonData.slice(0, 15);
                let str2 = finalJsonData.slice(15, 30);
                let str3 = finalJsonData.slice(30, 40);
                let str4 = finalJsonData.slice(-10);
                let finalData = str3 + str1 + str4 + str2;

                console.log("final data", finalData);
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization":  "Bearer " + this.accessToken,
                    },
                    body: JSON.stringify(param)
                };
                fetch(`${UPDATE_IDEA_URL}Checksum=${finalData}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        Toast.hide();
                        Toast.success('Updated successfully.', 3000, () => {
                            let redirectURL = "/review-page";
                            if (this.state.approvalPage) {
                                if (this.state.approvalPage === PROJECT_VIEW) {
                                    redirectURL = "/list-projects-page";
                                    this.props.history.push(redirectURL);
                                } else {
                                    redirectURL = redirectURL + "?page=" + this.state.approvalPage;
                                    this.props.history.push(redirectURL);
                                }
                            }
                            else {
                                this.props.history.push(redirectURL);
                            }
                        });
                    });
            }
        } else {
            Toast.fail('Required fields are missing or have invalid values. Please correct and try again.', 3000, () => { });
        }
        return false;
    }

    cancelEvent = () => {
        let redirectURL = "/review-page";
        if (this.state.approvalPage) {
            if (this.state.approvalPage === PROJECT_VIEW) {
                redirectURL = "/list-projects-page";
                // this.props.history.push(redirectURL);
                this.props.history.push({
                    pathname: redirectURL,
                    propsList: this.state.propsData
                })
            } else {
                redirectURL = redirectURL + "?page=" + this.state.approvalPage;
                this.props.history.push(redirectURL);
            }
        }
        else {
            // this.props.history.push(redirectURL);
            this.props.history.push({
                pathname: redirectURL,
                propsList: this.state.propsData
            })
        }
    }

    showPPTContent = () => {
        Toast.loading("Fetching Project PPT Deck content...", () => { });
        this.fetchPPTContent()
            .then(() => {
                var fileData = this.state.projectDeckContent;
                var fileName = this.state.ideaItem.ProjectPPTFileName;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    showSignOffContent = () => {
        Toast.loading("Fetching Customer Sign-off content...", () => { });
        this.fetchSignoffContent()
            .then(() => {
                var fileData = this.state.signOffContent;
                var fileName = this.state.ideaItem.CustomerSignoffFileName;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    showGOLContent = () => {
        Toast.loading("Fetching GOL Approval content...", () => { });
        this.fetchGOLApprovalContent()
            .then(() => {
                var fileData = this.state.signOffContent;
                var fileName = this.state.ideaItem.CustomerSignoffFileName;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    showFPnAContent = () => {
        Toast.loading("Fetching FP&A content...", () => { });
        this.fetchFpnaApprovalContent()
            .then(() => {
                var fileData = this.state.signOffContent;
                var fileName = this.state.ideaItem.CustomerSignoffFileName;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    formatDateStringWithTime(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        });
    }
    formatDateString(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
    }

    validateComment = () => {
        let isValid = true;
        let styleStateObj = this.state.selectStyle;

        if (!this.state.Comments || this.state.Comments === "") {
            isValid = false;
            styleStateObj.comment = textBoxValidation(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            if (this.state.Comments.length > 500) {
                Toast.fail("Comment cannot be more than 500 characters, please change and resubmit.", 3000);
                isValid = false;
                styleStateObj.comment = textBoxValidation(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else {
                styleStateObj.comment = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                this.setState({
                    selectStyle: styleStateObj
                })
            }
        }
        return isValid;
    }

    handleCommentChange = (event) => {
        this.setState({
            Comments: event.target.value
        });
    }
    handleUpdateTranscriptCancelRequest = (e) => {
        let commentChange = e.trim();
        this.setState({
            Comments: commentChange
        });
    }

    submitEvent = () => {
        if (this.validateComment()) {
            if (window.confirm("Are you sure you want to cancel this Project?")) {
                Toast.loading("Cancellation is in process...", () => { });
                let param = {
                    IdeaId: this.props.location.state,
                    ApprovalStatus: REQUEST_CANCELLATION,
                    UpdaterOHR: this.sessionData.profile.oHRId,
                    UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                    UpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                    comment: this.state.Comments,
                    WorkflowStatus: this.state.ideaItem.WorkflowStatus,
                    ProjectStatus: this.state.ideaItem.ApprovalStatus
                }
                console.log(param)
                let finalJsonData = Checksum(param);
                console.log("final Json Data", finalJsonData);
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization":  "Bearer " + this.accessToken,
                    },
                    body: JSON.stringify(param)
                };
                fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        console.log(data)
                        Toast.hide();
                        Toast.success('Cancel request sent successfully.', 3000, () => {
                            console.log(JSON.stringify(data));
                            let redirectURL = "/review-page";
                            if (this.state.approvalPage) {
                                redirectURL = redirectURL + "?page=" + this.state.approvalPage;
                            }
                            window.location = redirectURL;
                        });

                    });
            }
        }
        else {
            Toast.fail("Please enter comment.", 3000, () => { });
        }
    }

    cancelRequest = () => {
        Toast.info("Once cancelled you cannot have the project reinitiated. You will need to start the project from scratch", 3000, () => { });
        this.setState({
            cancelRequest: !this.state.cancelRequest,
        })
    }


    toggleTranscriptFunction = (id) => {
        this.setState({ speechModal: !this.state.speechModal, modalIndex: id })
    }

    closeModalFunction = () => {
        this.setState({ speechModal: false })
    }



    showPPTContent = () => {
        Toast.loading("Fetching Project PPT Deck content...", () => { });
        this.fetchPPTContent()
            .then(() => {
                var fileData = this.state.projectDeckContent;
                var fileName = this.state.ideaItem.ProjectPPTFileName;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    showSignOffContent = () => {
        Toast.loading("Fetching Customer Sign-off content...", () => { });
        this.fetchSignoffContent()
            .then(() => {
                var fileData = this.state.signOffContent;
                var fileName = this.state.ideaItem.CustomerSignoffFileName;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    showGOLContent = () => {
        Toast.loading("Fetching GOL Approval content...", () => { });
        this.fetchGOLApprovalContent()
            .then(() => {
                var fileData = this.state.signOffContent;
                var fileName = this.state.ideaItem.CustomerSignoffFileName;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    showFPnAContent = () => {
        Toast.loading("Fetching FP&A content...", () => { });
        this.fetchFpnaApprovalContent()
            .then(() => {
                var fileData = this.state.signOffContent;
                var fileName = this.state.ideaItem.CustomerSignoffFileName;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    fetchPPTContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let projectFileType = this.state.projectFileType;
            // fetch(`${GET_IDEA_URL}IdeaId=964&${PPT_CONTENT}=true`, requestOptions)
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    const projectDeckMetadata = data.PPTContent ? data.PPTContent : "";
                    const projectContent = "data:" + projectFileType + ";base64," + projectDeckMetadata;
                    this.setState({
                        projectDeckContent: projectContent,
                    }, () => {
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching Project Deck content. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }

    fetchSignoffContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            console.log("sign off file type in fetch sign off", this.state.signOffFileType)
            let signoffFileType = this.state.signOffFileType;
            // fetch(`${GET_IDEA_URL}IdeaId=964&${SIGNOFF_CONTENT}=true`, requestOptions)
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${SIGNOFF_CONTENT}=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    const signoffMetadata = data.SignoffContent ? data.SignoffContent : "";
                    const signoffContent = "data:" + signoffFileType + ";base64," + signoffMetadata;
                    this.setState({
                        signOffContent: signoffContent,
                    }, () => {
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching Project Deck content. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }

    fetchGOLApprovalContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };

            let signoffFileType = this.state.signOffFileType;
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${GOL_APPROVAL_CONTENT}=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    const signoffMetadata = data.SignoffContent ? data.SignoffContent : "";
                    const signoffContent = "data:" + signoffFileType + ";base64," + signoffMetadata;
                    this.setState({
                        signOffContent: signoffContent,
                    }, () => {
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching Project Deck content. Please try again!", 3000, () => { });
                    resolve();
                });
        })
    }
    fetchFpnaApprovalContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            let signoffFileType = this.state.signOffFileType;
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${FPnA_APPROVAL_CONTENT}=true`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    const signoffMetadata = data.SignoffContent ? data.SignoffContent : "";
                    const signoffContent = "data:" + signoffFileType + ";base64," + signoffMetadata;
                    this.setState({
                        signOffContent: signoffContent,
                    }, () => {
                        resolve();
                    });

                })
                .catch(error => {
                    Toast.fail("Error while fetching Project Deck content. Please try again!", 3000, () => { });
                    resolve();
                });
        })
    }




    newBusinessImpactSummary() {
        let myArray = [...this.state.businessImpactValueArray];
        const groupedData = myArray.reduce((accumulator, currentValue) => {
            const impactType = currentValue.impactType;
            const monetizableType = currentValue.monetizableType;
            const fteValue = parseFloat(currentValue.fteValue) || 0.0;
            const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;

            const existingRow = accumulator.find(row => row.impactType === impactType && row.monetizableType === monetizableType);
            if (existingRow) {
                existingRow.fteValue += fteValue
                existingRow.dollarValue += dollarValue
            } else {
                accumulator.push({ impactType, monetizableType, fteValue, dollarValue });
            }
            return accumulator;
        }, []);
        this.setState({ summaryArray: groupedData }, () => { });
        this.setState({ businessImpactValueArray: this.state.businessImpactValueArray }, () => { })
        this.setState({ isFpnaRequired: false }, () => { })
        this.state.businessImpactValueArray && this.state.businessImpactValueArray.map((item) => {
            if (item.ImpactType === "Impact at Genpact") {
                if (item.MonetizableType === "Increase in Revenue" || item.MonetizableType === "Reduction in Cost") {
                    this.setState({ isFpnaRequired: true }, () => { })
                }
            }
        })
    }

    pull_data = ({ businessImpactValueArray }) => {
        this.setState({ businessImpactValueArray: businessImpactValueArray }, () => {
            this.newBusinessImpactSummary()
        })
        let dollarTotal = 0.0;
        let fteValue = 0.0;
        this.state.businessImpactValueArray.map((item) => {
            if (item && item.DollarImpact) {
                dollarTotal += parseFloat(item.DollarImpact);
            }
            if (item && item.dollarValue) {
                dollarTotal += parseFloat(item.dollarValue);
            }
            if (item && item.FTEImpact) {
                fteValue += parseFloat(item.FTEImpact)
            }
            if (item && item.fteValue) {
                fteValue += parseFloat(item.fteValue)
            }
        })
        this.setState({ finalImpactDollar: dollarTotal }, () => {
            // console.log("inside call back function $ total",this.state.finalImpactDollar)
        })
        this.setState({ finalImpactFTE: fteValue }, () => {
            // console.log("inside call back function fte value",this.state.finalImpactFTE)
        })
    }
    remove_bi_data_after_delete = (filteredIdeaArray) => {
        // console.log("bi recieved in pull data for delete function",filteredIdeaArray)
        if (filteredIdeaArray) {
            this.setState({ businessImpactValueArray: filteredIdeaArray }, () => {
                this.newBusinessImpactSummary()
                let dollarTotal = 0.0;
                let fteValue = 0.0;
                this.state.businessImpactValueArray.map((item) => {
                    if (item && item.DollarImpact) {
                        dollarTotal += parseFloat(item.DollarImpact);
                    }
                    if (item && item.dollarValue) {
                        dollarTotal += parseFloat(item.dollarValue);
                    }
                    if (item && item.FTEImpact) {
                        fteValue += parseFloat(item.FTEImpact)
                    }
                    if (item && item.fteValue) {
                        fteValue += parseFloat(item.fteValue)
                    }
                })
                this.setState({ finalImpactDollar: dollarTotal }, () => {
                    //console.log("inside call back function $ total",this.state.finalImpactDollar)
                })
                this.setState({ finalImpactFTE: fteValue }, () => {
                    // console.log("inside call back function fte value",this.state.finalImpactFTE)
                })

            })
        }

    }

    pull_file_data = ({ fileName, fileType, fileContent, showClientApprovalFields, FPnAOHRMonetization }) => {
        if (fileName) {
            this.setState({ FPnAFileNameMonetization: fileName }, () => { })
        }
        if (fileType) {
            this.setState({ FPnAFileTypeMonetization: fileType }, () => { })
        }
        if (fileContent) {
            this.setState({ FPnAFileMonetization: fileContent }, () => { })
        }
        this.setState({ showClientApprovalFields: showClientApprovalFields }, () => { })
        this.setState({ FPnAOHRMonetization: FPnAOHRMonetization }, () => { })
    }
    pull_monetization_sowId = (localSowId) => {
        this.setState({ SowID: localSowId }, () => { })
    }
    pull_monetization_fpna_name = (localFpnaName) => {
        this.setState({ FPnANameMonetization: localFpnaName }, () => { })
    }

    pullMonetizedTotal = ({ fteValue, dollarValue }) => {
        if (fteValue) {
            this.setState({ totalMonetizedDollar: fteValue }, () => { })
        }
        if (dollarValue) {
            this.setState({ totalMonetizedFTE: dollarValue }, () => { })
        }
    }
    pull_monetizableProject = (e) => {
        if (e) {
            this.setState({ monetizableProjectSelectedOption: e }, () => {
                if (this.state.monetizableProjectSelectedOption.label === "No") {
                    this.setState({ monetizationStatusSelectedOption: null }, () => { })
                }
                //console.log("monetization project selected opiton is =>",this.state.monetizableProjectSelectedOption)
            })
        }
    }
    pull_monetizableStatus = (e) => {
        this.setState({ monetizationStatusSelectedOption: e }, () => {
            //console.log("selected monetization status is ",this.state.monetizationStatusSelectedOption)
        })
    }
    handleVDTypeChange = ({ e }) => {
        if (e) {
            this.setState({ impactOptionSelected: e, impactChanged: true }, () => {
                if (this.state.impactOptionSelected.label === "No Value Delivery") {
                    this.setState({
                        signOffFilename: "",
                        signOffFileType: "",
                        signOffContent: "",
                        filename3: "",
                        fileType3: "",
                        base64File3: "",
                        filename4: "",
                        fileType4: "",
                        base64File4: "",
                        clientName: "",
                        clientRole: "",
                        clientSignOffDate: "",
                        fpnaName: "",
                        golFullname: "",
                        fpnaOhr: "",
                        golOhr: "",
                        showClientFields: false,
                        showGOLFields: false,
                        finalImpactDollar: 0,
                        monetizableProjectSelectedOption: null,
                        businessImpactValueArray: [],
                        IsVDMonetizable: "",
                        MonetizationStatus: ""
                    })
                }
                if (this.state.impactOptionSelected.label === "Value Delivered to Client") {
                    this.setState({ showClientFields: true, showGOLFields: true })
                }
                if (this.state.impactOptionSelected.label === "Value Delivered to Genpact") {
                    this.setState({ showClientFields: false, showGOLFields: true })
                }
                if (this.state.impactOptionSelected.label === "Value Delivered to Client + Genpact") {
                    this.setState({ showClientFields: true, showGOLFields: true })
                }
            })
        }
    }
    fetchFPAOHRforIncreaseInRevenue = (e) => {
        if (e === true) {
            this.setState({ appropriateAttachment: true }, () => { })
        } else {
            this.setState({ appropriateAttachment: false }, () => { })
        }
    }

    // function for FPNAGOL component starts here

    // ppt deck file details
    pullPPTDeck = ({ fileName, fileType, fileContent }) => {
        //console.log(fileName,fileType,fileContent)
        this.setState({ filename1: fileName, fileType1: fileType, base64File1: fileContent }, () => { })
    }
    pullClientDocName = (fileName) => {
        this.setState({ signOffFilename: fileName, }, () => { })
    }
    pullClientDocType = (fileType) => {
        if (fileType) {
            this.setState({ signOffFileType: fileType }, () => { })
        }
    }
    pullClientDocContent = (fileContent) => {
        if (fileContent) {
            this.setState({ signOffContent: fileContent }, () => { })
        }
    }

    // gol file details
    pullgolFileName = (fileName) => {
        this.setState({ filename3: fileName }, () => {
            //console.log("gol approval doc name",this.state.filename3)      
        })
    }
    pullgolFileType = (fileType) => {
        this.setState({ fileType3: fileType }, () => {
            //console.log("gol approval doc type",this.state.fileType3)      
        })
    }
    pullgolContent = (fileContent) => {
        this.setState({ base64File3: fileContent }, () => {
            //console.log("gol approval doc content",this.state.base64File3)      
        })
    }

    // fpna file details
    pullfpnafileName = (fileName) => {
        if (fileName) {
            this.setState({ filename4: fileName }, () => {
                // console.log("fpna doc name",this.state.filename4)
            })
        }
    }
    pullfpnafileType = (fileType) => {
        if (fileType) {
            this.setState({ fileType4: fileType }, () => {
                // console.log("fpna doc type",this.state.fileType4)
            })
        }
    }
    pullfpnafileContent = (fileContent) => {
        this.setState({ base64File4: fileContent }, () => {
            // console.log("fpna doc file content",this.state.base64File4)
        })
    }
    pullClientName = (e) => {
        this.setState({ clientName: e }, () => {
            //console.log(this.state.clientName)
        })
    }
    pullClientRole = (e) => {
        this.setState({ clientRole: e }, () => {
            //console.log(this.state.clientRole)
        })
    }
    pullClientSignOff = (e) => {
        this.setState({ clientSignOffDate: e }, () => {
            //console.log(this.state.clientSignOffDate)
        })
    }
    pullFpnaName = (name) => {
        //console.log("fpna name",name)
        this.setState({ fpnaName: name }, () => {
            //console.log(this.state.fpnaName)
        })
    }
    pullGolName = (name) => {
        //console.log("gol name",name)
        this.setState({ golFullname: name }, () => {
            //console.log(this.state.golFullname)
        })
    }
    pullFpnaOhr = (e) => {

        this.setState({ fpnaOhr: e }, () => {
            //console.log(this.state.fpnaOhr)
        })
    }
    pullGolOhr = (e) => {
        this.setState({ golOhr: e }, () => {
            //console.log(this.state.golOhr)
        })
    }
    pull_fileDetails = (obj) => {
        this.setState({ golFpnaFileDetails: obj }, () => {
            //console.log(this.state.golFpnaFileDetails)
        })
    }

    pull_monetization_fpna_ohr = (localFpnaohr) => {
        //console.log("fpna ohr from pull function => ",localFpnaohr)
        this.setState({ FPnAOHRMonetization: localFpnaohr }, () => { })
    }
    pullCategoryNotListed = (localShowHref) => {
        this.setState({ showHrefModal: localShowHref }, () => {
          console.log("hrf modal =>",this.state.showHrefModal)
        });
      };
    pullMonetizationGOLName = (localGOLName) => {
        //console.log("local gol name from pull function",localGOLName)
        this.setState({ golMonetizationName: localGOLName }, () => {
        })
    }

    pullMonetizationGOLOhr = (localGOLOhr) => {
        this.setState({ golMonetizationOhr: localGOLOhr }, () => {
            //console.log("gol ohr monetization",this.state.golMonetizationOhr)
        })
    }
    // function for FPNAGOL component ends here



    // clustering function starts here

    showClusterModal = () => {
        if (this.state.ideaItem.LeanID !== "" || this.state.ideaItem.LeanID !== null || this.state.ideaItem.LeanID !== undefined || !this.state.ideaItem.LeanID) {
            this.setState({
                clusterModal: !this.state.clusterModal,
            })

        }
    }
    changeProjectType = (e) => {
        this.setState({ projectIDSelectedOption: null }, () => { })
        if (e) {
            this.setState({ projectTypeSelectedOption: e }, () => {
                Toast.loading("Fetching Cluster Projects...", () => { });
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization":  "Bearer " + this.accessToken,
                    }
                };
                let encodedAccount = encodeURIComponent(this.state.ideaItem.AccountManual)
                let encodedVertical = encodeURIComponent(this.state.ideaItem.VerticalManual)
                fetch(`${GET_IDEA_URL}Account=${encodedAccount}&Vertical=${encodedVertical}&ProjectType=${this.state.projectTypeSelectedOption.label}&case=new&casetype=fetchclusterprojects&mucid=muc001`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        Toast.hide();
                        this.setState({
                            clustorProjects: data.ClusterProjects
                        }, () => {
                            this.state.clustorProjects.map((item) => {
                                console.log(item.pg_projectid)
                            })

                        });
                    })
                    .catch(error => {
                        Toast.fail("Error while fetching cluster projects. Please try again!", 3000, () => { });
                        Toast.hide();
                        console.log(error)
                    });
            })
        }
    }

    displayProjectID = () => {
        if (this.state.clustorProjects) {
            let options = [];
            if (this.state.clusterOptionSearched) {
                this.state.clustorProjects.map((item, index) => {
                    options.push({
                        value: index,
                        Display: item.pg_projectid,
                        label: item.pg_projectid + " - " + item.pg_projowner,
                    });
                });
            } else {
                this.state.clustorProjects.map((item, index) => {
                    if (index < 19) {
                        options.push({
                            value: index,
                            Display: item.pg_projectid,
                            label: item.pg_projectid + " - " + item.pg_projowner,
                        });
                    }
                });
            }
            return options;
        }
    };

    handleClusterProjectInputChange = (inputValue) => {
        if (inputValue && inputValue.length > 0) {
            this.setState({ clusterOptionSearched: true }, () => { })
        } else {
            this.setState({ clusterOptionSearched: false }, () => { })
        }
    }


    changeProjectID = (e) => {
        this.setState({ projectIDSelectedOption: e, isClusterSaveDisabled: false }, () => {
            this.state.clustorProjects.map((item, index) => {
                if (item.pg_projectid === this.state.projectIDSelectedOption.Display) {
                    this.setState({ guid: item.msdyn_projectid }, () => { })
                }
            })
        })
    }
    saveParentLink = async () => {
        if (this.state.ideaItem.ApprovalStatus && this.state.ideaItem.ApprovalStatus !== "Rejected" && this.state.projectTypeSelectedOption && this.state.projectIDSelectedOption) {
            if (window.confirm("Are you sure you'd like to add Project ID: " + this.state.projectIDSelectedOption.Display + " as the Parent Project ID?")) {
                if ((parseInt(this.state.ideaItem.OwnerOHR) === parseInt(this.sessionData.profile.oHRId)) ||
                    (parseInt(this.state.ideaItem.BlackBeltUserId) === parseInt(this.sessionData.profile.oHRId))) {
                    if (this.state.projectTypeSelectedOption && this.state.projectIDSelectedOption) {
                        Toast.loading("Linking...", () => { });
                        let param = {
                            case: "new",
                            casetype: "updatecluster",
                            RecID: this.props.location.state,
                            RecAlpha: this.state.ideaItem.LeanID,
                            ParentRecID: this.state.guid,
                            ParentAlpha: this.state.projectIDSelectedOption ? this.state.projectIDSelectedOption.Display : "",
                            UpdatedByName: this.sessionData ? this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName : "",
                            UpdatedByOHR: this.sessionData ? this.sessionData.profile.oHRId : " ",
                            ParentProjectType: this.state.projectTypeSelectedOption ? this.state.projectTypeSelectedOption.label : "",
                            Vertical: this.state.ideaItem ? this.state.ideaItem.VerticalManual : "",
                            Account: this.state.ideaItem ? this.state.ideaItem.AccountManual : "",
                        }
                        console.log(param)
                        let finalJsonData = Checksum(param);
                        const requestOptions = {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                                "Authorization":  "Bearer " + this.accessToken,
                            },
                            body: JSON.stringify(param)
                        };
                        fetch(`${DELEGATE_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
                            .then(response => response.json())
                            .then(data => {
                                Toast.success('Parent linked successfully!', 5000, () => {
                                    this.setState({
                                        clusterModal: !this.state.clusterModal,
                                    })
                                    this.updateClusterData();
                                })
                                this.setState({ isClusterSaveDisabled: true }, () => { })
                            })
                            .catch(error => {
                                console.log(error)
                                Toast.fail("Parent linked failed", 3000, () => { })
                            });


                    } else {
                        Toast.fail("Please select project type & project ID", 3000, () => { })
                    }
                } else {
                    Toast.fail("Only idea owner & idea BB can link the ideas", 3000, () => { })
                }
            }
        } else {
            Toast.fail("Rejected ideas cannot be linked.", 3000, () => { })
        }

    }
    removeParentFunc = () => {
        if (this.state.ideaItem.ApprovalStatus && this.state.ideaItem.ApprovalStatus !== "Rejected") {
            if ((parseInt(this.state.ideaItem.OwnerOHR) === parseInt(this.sessionData.profile.oHRId)) ||
                (parseInt(this.state.ideaItem.BlackBeltUserId) === parseInt(this.sessionData.profile.oHRId))) {
                if (!this.state.clusterData || this.state.clusterData.length === 0) {
                    if ((this.state.projectTypeSelectedOption === null || this.state.projectTypeSelectedOption === undefined || !this.state.projectTypeSelectedOption) &&
                        (this.state.projectIDSelectedOption === null || this.state.projectIDSelectedOption === undefined || !this.state.projectIDSelectedOption)
                    ) {
                        Toast.fail("Only linked ideas can only be removed", 3000, () => { })
                    }
                }
                if (this.state.clusterData && this.state.clusterData.length > 0) {
                    console.log("project id", this.state.projectIDSelectedOption)
                    if (this.state.projectTypeSelectedOption && this.state.projectIDSelectedOption) {
                        if (window.confirm("Are you sure you'd like to remove Project ID: " + this.state.projectIDSelectedOption.label + " as the Parent Project ID?")) {
                            Toast.loading("Removing parent link...", () => { });
                            let param = {
                                case: "new",
                                casetype: "updatecluster",
                                RecID: this.props.location.state,
                                RecAlpha: this.state.ideaItem.LeanID,
                                ParentRecID: "",
                                ParentAlpha: "",
                                UpdatedByName: this.sessionData ? this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName : "",
                                UpdatedByOHR: this.sessionData ? this.sessionData.profile.oHRId : " ",
                                Vertical: this.state.ideaItem ? this.state.ideaItem.VerticalManual : "",
                                Account: this.state.ideaItem ? this.state.ideaItem.AccountManual : "",
                            }
                            console.log(param)
                            let finalJsonData = Checksum(param);
                            const requestOptions = {
                                method: 'PUT',
                                headers: {
                                    'Content-Type': 'application/json',
                                    "Authorization":  "Bearer " + this.accessToken,
                                },
                                body: JSON.stringify(param)
                            };
                            fetch(`${DELEGATE_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
                                .then(response => response.json())
                                .then(data => {
                                    Toast.success('Parent unlinked successfully!', 3000, () => {
                                        this.setState({
                                            clusterModal: !this.state.clusterModal,
                                        })
                                    })
                                    this.updateClusterData();
                                    this.setState({ projectTypeSelectedOption: null, projectIDSelectedOption: null }, () => { })
                                })
                                .catch(error => {
                                    console.log(error)
                                    Toast.fail("Parent unlinked failed", 3000, () => { })
                                });
                        }
                    }
                }

            }
            else {
                Toast.fail("Only idea owner & idea BB can unlink the Parent", 3000, () => { })
            }
        }
        else {
            Toast.fail("Rejected ideas cannot be unlinked / removed.", 3000, () => { })
        }

    }
    updateClusterData() {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            }
        };
        fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("fetch idea for cluster data", data)
                this.setState({
                    ideaItem: data.ResultSets.Table1[0],
                    clusterData: data.ClusterData.Table1 ? data.ClusterData.Table1 : [],
                }, () => {
                    if (this.state.clusterData && this.state.clusterData.length > 0) {
                        const targetRecID = this.state.ideaItem.LeanID;
                        const filteredData = this.state.clusterData.filter(item => item.RecAlpha === targetRecID);

                        let getParentAlpa = "";
                        this.state.clusterData.map(item => {
                            if (item.RecAlpha === targetRecID) {
                                console.log("matched rec apha", item.RecAlpha)
                                getParentAlpa = item.ParentAlpha;
                            }
                        })
                        console.log("get parent alpha", getParentAlpa)
                        if (getParentAlpa) {
                            this.state.clusterData.map(item => {
                                if (getParentAlpa === item.RecAlpha) {
                                    const selectedOption = {
                                        value: 0,
                                        label: item.ProjectType
                                    };
                                    this.state.projectTypeSelectedOption = selectedOption;
                                    this.setState({ projectTypeSelectedOption: selectedOption }, () => { })
                                }
                            })
                        }

                        if (filteredData && filteredData.length > 0 && filteredData !== undefined && filteredData !== null && filteredData !== "") {
                            this.setState({ displayParentAlpha: filteredData[0].ParentAlpha }, () => { })
                            const selectedOption = {
                                value: 0,
                                label: filteredData[0].ParentAlpha
                            };
                            this.setState({ projectIDSelectedOption: selectedOption }, () => {
                                console.log("project type selected option after parent alpha ", this.state.projectIDSelectedOption)
                                this.setState({ projectIDSelectedOption: selectedOption }, () => {
                                    console.log("abc", this.state.projectTypeSelectedOption)
                                    if (this.state.projectTypeSelectedOption && (this.state.projectTypeSelectedOption.label === null || this.state.projectTypeSelectedOption.label === "")) {
                                        if (this.state.projectIDSelectedOption.label.startsWith("GB")) {
                                            const selectedOptionGB = {
                                                value: 0,
                                                label: "GB"
                                            };
                                            this.state.projectTypeSelectedOption = selectedOptionGB;
                                            this.setState({ projectTypeSelectedOption: selectedOptionGB }, () => {
                                                console.log(this.state.projectTypeSelectedOption)
                                            })
                                        } else {
                                            const selectedOptionBB = {
                                                value: 0,
                                                label: "BB"
                                            };
                                            this.state.projectTypeSelectedOption = selectedOptionBB;
                                            this.setState({ projectTypeSelectedOption: selectedOptionBB }, () => {
                                                console.log(this.state.projectTypeSelectedOption)
                                            })
                                        }
                                    }
                                })
                            })
                        } else {
                            this.setState({ displayParentAlpha: "" }, () => { })
                        }
                    } else {
                        this.setState({ displayParentAlpha: "" }, () => { })
                    }

                });
            })
            .catch(error => {
                Toast.fail("Error while fetching idea. Please try again!", 3000, () => { });
                Toast.hide();
            });
    }



    // clustering function ends here



    // ... DB function starts here....
    handleDataBridgeTypeChange = (e) => {
        let checkEmptyTemplate = false;
        if (e.label === "Descriptive" || e.label === "Diagnostic" || e.label === "Prescriptive") {
            if (this.state.RecType !== "DA" || this.state.RecType !== "DPA" || this.state.RecType === undefined || this.state.RecType === null || !this.state.RecType || this.state.RecType === "") {
                checkEmptyTemplate = true;
            }

        }
        if (e.label === "Predictive") {
            if (this.state.SOrdTable.length === 0) {
                checkEmptyTemplate = true;
            } else {
                this.state.SOrdTable.map((item, index) => {
                    if (item.SOrd === 0) {
                        if (item.ModelType === null || item.ModelType === undefined || item.ModelType.length === 0) {
                            checkEmptyTemplate = true;
                        }
                    }
                });
            }
        }
        let styleStateObj = this.state.selectStyle;
        styleStateObj.dataBridgeType = selectStyle(true);
        this.setState(
            {
                dataBridgeType: {
                    value: e.value,
                    label: e.label,
                },
                selectStyle: styleStateObj,
            },
            () => { 
                
               
            }
        );
    };

    handleMUCIDChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.mucIDSelectedOption = selectStyle(true);
        this.setState(
            {
                mucIDSelectedOption: {
                    value: e.value,
                    label: e.label,
                },
                selectStyle: styleStateObj,
            },
            () => {
                this.state.mucIDOptionsfromMetaData && this.state.mucIDOptionsfromMetaData.filter((item)=>{
                    if(item.pg_databridgeusecasecalculated === e.label){
                      this.setState({mucIdTrimmed:item.pg_usecasetitle},()=>{
                        console.log(this.state.mucIdTrimmed)
                      })
                    }
                })
                
            }
        );

        
        
    };
    handleSharedToClientChange = (e) => {
        this.setState({
            DB_clientRole: "",
            DB_clientName: "",
            CTestimonyFileName: ""
        }, () => { })
        let styleStateObj = this.state.selectStyle;
        styleStateObj.sharedToClient = selectStyle(true);
        this.setState(
            {
                sharedToClient: {
                    value: e.value,
                    label: e.label,
                },
                selectStyle: styleStateObj,
            },
            () => { console.log("shared to client =>", this.state.sharedToClient) }
        );
    };
    handleSharedToGOLChange = (e) => {
        this.setState({
            DB_GOLOHR: "",
            DB_GOLNAME: ""
        }, () => { })
        let styleStateObj = this.state.selectStyle;
        styleStateObj.sharedToGOL = selectStyle(true);
        this.setState(
            {
                sharedToGOL: {
                    value: e.value,
                    label: e.label,
                },
                selectStyle: styleStateObj,
            },
            () => { }
        );
    };
    handledbToolUsedChange = (e) => {
        this.setState({ otherDBToolUsedText: "" })
        let styleStateObj = this.state.selectStyle;
        styleStateObj.dbToolUsed = selectStyle(true);
        this.setState(
            {
                dbToolUsed: {
                    value: e.value,
                    label: e.label,
                },
                selectStyle: styleStateObj,
            },
            () => { 
                console.log(this.state.dbToolUsed)
            }
        );
    };

    handleSharedToClientRoleTextChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.DB_clientRole = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            DB_clientRole: e,
        });
    };

    handleOtherDBToolUsedChange = (e) => {
        let nonWhiteSpace= e.replace(/^\s+|\s+$/gm,'');
        let styleStateObj = this.state.selectStyle;
        this.setState({selectStyle: styleStateObj},()=>{})
        styleStateObj.otherDBToolUsedText = selectStyle(true);
        if(nonWhiteSpace.length < 250){
            this.setState({otherDBToolUsedText: e});
        }else{
            const trimmedValue = nonWhiteSpace.slice(0, 250);
            this.setState({otherDBToolUsedText:trimmedValue},()=>{});
        }
    };
    
    isMailFile(filename) {
        const fileExtension = filename.split('.').pop();
        return fileExtension.toLowerCase() === 'msg';
    }

    uploadClientAttachment = (event, fileType) => {
        var file = event.target.files[0];
        
        if(file){
            console.log("file =>",file)
            var currFilename = file.name;
            var currFilesize = file.size;
            var currFileType = file.type;

            if (fileType === "clientTestimony") {
                if (currFilesize > 5000000) {
                    alert(
                        "The size of the document you are uploading, is more than 5MB. Please upload a smaller document."
                    );
                    return;
                }
            }
            if(currFilename === this.state.filename1){
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if( currFilename === this.state.signOffFilename){
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if(currFilename === this.state.filename3){
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if(currFilename ===this.state.filename4){
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            var validExtensions = ['.xls', '.xlsx', '.xlsm', '.csv', '.doc', '.docx', '.pdf', '.ppt', '.pptx', '.pdf', '.jpeg', '.jpg', '.png', '.msg', '.png', '.mpp'];
            var currFileExtension = currFilename.substring(currFilename.lastIndexOf("."));
            var isFileTypeValid = validExtensions.filter((val) => val.toLowerCase().includes(currFileExtension.toLowerCase()));
            if (!isFileTypeValid) {
                alert("The type of document you are uploading is not supported. Please upload only supported file types.");
                return;
            }
            var reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = () => {
                let fileContents = btoa(reader.result);

                if (fileType === "clientTestimony") {
                    let fileName = file.name;
                    let fileType = file.type ? file.type :".msg";
                    let fileContent = fileContents;
                    this.setState(
                        {
                            CTestimonyFileName: fileName,
                            CTestimonyFileType: fileType,
                            CTestimony: fileContent,
                        },
                        () => { 
                            console.log("ct file type =>",this.state.CTestimonyFileType)
                        }
                    );
                }
            };
            reader.onerror = function () {
                console.log("There are some problems loading document contents.");
            };
        
        }
    };

    handleDeleteFile = (fileName) => {
        if (
            window.confirm(
                "Are you sure you want to delete the file " + fileName + "?"
            )
        ) {
            this.setState((prevState) => ({
                uploadAttachments: prevState.uploadAttachments.filter(
                    (file) => file.name !== fileName
                ),
            }));
        } else {
            return;
        }
    };

    // ..auto fetch process owner function starts here....

    handleProcessOwnerOhronCut = (event) => {
        if (event.target.value.length === 9) {
            this.handleProcessOwnerChange();
        } else if (event.target.value.length === 0) {
            this.setState({ processOwnerOHR: "", processOwnerName: "" }, () => { });
            return null;
        } else {
            Toast.fail("Process owner OHR should be exactly of 9 digits", 3000, () => { });
            this.setState({ processOwnerName: "" }, () => { });
        }
    };

    handleProcessOwnerChange = (event) => {
        var numbers = /^[0-9]+$/;
        if (event !== null && event !== undefined) {
            if (
                event.target.value.match(numbers) &&
                event.target.value.length !== 0
            ) {
                if (event.target.value.length === 9) {
                    let styleStateObj = this.state.selectStyle;
                    styleStateObj.processOwnerOHR = textBoxValidation(true);
                    this.setState(
                        {
                            selectStyle: styleStateObj,
                            processOwnerOHR: event.target.value,
                        },
                        () => {
                            if(this.state.processOwnerOHR){
                                this.handleSubmitProcessOHRChange();
                            }
                        }
                    );
                    
                } else {
                    this.setState(
                        { processOwnerName: "", processOwnerOHR: "" },
                        () => { }
                    );
                   
                    
                }
            } else {
                this.setState({ processOwnerName: "", processOwnerOHR: "" }, () => { });
                
            }
        }
    };

    handleSubmitProcessOHRChange = () => {
        let golEmail = this.state.processOwnerOHR + "@genpact.com";
        this.setState({ processOnwerEmail: golEmail }, () => { 
            this.fetchSubmitProcessOHRDetails();
        });
        
    };

    fetchSubmitProcessOHRDetails = async () => {
        Toast.loading("Fetching Process Owner Full Name. Please wait...", () => { });
        try {
            const param = {
                EntityType: "SearchOHR",
                EntityName: this.state.processOwnerOHR,
            };
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param),
            };
            const response = await fetch(GET_METADATA, requestOptions);
            const data = await response.json();
            console.log(data);
            let requestByName = data.oUser ? data.oUser[0].DisplayName : null;
            if (requestByName === "") {
                requestByName = data.PowerAppUsers
                    ? data.PowerAppUsers.length > 0
                        ? data.PowerAppUsers[0].pg_username
                        : ""
                    : "";
            }
            console.log("request by name",requestByName)

            if (requestByName === "" || requestByName === null || requestByName === undefined) {
                Toast.fail(
                    "We could not find Process Owner name based on the entered OHR. Please check and reenter.",
                    5000,
                    () => { }
                );
                this.setState({ processOwnerName: "" }, () => { });
            } else {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.processOwnerOHR = textBoxValidation(true);
                styleStateObj.processOwnerName = textBoxValidation(true);

                this.setState(
                    {
                        processOwnerName: requestByName,
                        selectStyle: styleStateObj,
                    },
                    () => {
                        console.log(
                            "process owner name is =>",
                            this.state.processOwnerName
                        );
                    }
                );
            }
            Toast.hide();
        } catch (error) {
            console.log(error)
            Toast.hide();
            Toast.fail("Please enter a valid Owner OHR", 5000, () => {
                console.log(JSON.stringify(error));
            });
            this.setState({ processOwnerOHR: "", processOwnerName: "" }, () => { });
        }
    };
    // ..auto fetch process owner function ends here....

    // ..auto fetch GOLOHR function starts here....
    handleDBGOLOhronCut = (event) => {
        if (event.target.value.length === 9) {
            this.handleDBGOLOHRChange();
        } else if (event.target.value.length === 0) {
            this.setState({ DB_GOLOHR: "", DB_GOLNAME: "" }, () => { });
            return null;
        } else {
            Toast.fail("GOL OHR should be exactly of 9 digits", 3000, () => { });
            this.setState({ DB_GOLNAME: "" }, () => { });
        }
    };
    handleDBGOLOHRChange = (event) => {
        var numbers = /^[0-9]+$/;
        if (event !== null && event !== undefined) {
            if (
                event.target.value.match(numbers) &&
                event.target.value.length !== 0
            ) {
                if (event.target.value.length === 9) {
                    let styleStateObj = this.state.selectStyle;
                    styleStateObj.DB_GOLOHR = textBoxValidation(true);
                    this.setState(
                        {
                            selectStyle: styleStateObj,
                            DB_GOLOHR: event.target.value,
                        },
                        () => {
                            if(this.state.DB_GOLOHR){
                                this.handleSubmitDBGOLOHRChange();
                            }
                        }
                    );
                    
                } else {
                    this.setState({ DB_GOLOHR: "", DB_GOLNAME: "" }, () => { });
                }
            } else {
                this.setState({ DB_GOLOHR: "", DB_GOLNAME: "" }, () => { });
            }
        }
    };

    handleSubmitDBGOLOHRChange = () => {
        let golEmail = this.state.DB_GOLOHR + "@genpact.com";
        this.setState({ DB_GOLEmail: golEmail }, () => { 
            this.fetchSubmitDBGOLOHRDetails();
        });
        
    };

    fetchSubmitDBGOLOHRDetails = async () => {
        Toast.loading("Fetching GOL OHR. Please wait...", () => { });
        try {
            const param = {
                EntityType: "SearchOHR",
                EntityName: this.state.DB_GOLOHR,
            };
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param),
            };
            const response = await fetch(GET_METADATA, requestOptions);
            const data = await response.json();
            console.log(data);
            let requestByName = data.oUser ? data.oUser[0].DisplayName : null;
            console.log(requestByName);
            if (requestByName === "") {
                requestByName = data.PowerAppUsers
                    ? data.PowerAppUsers.length > 0
                        ? data.PowerAppUsers[0].pg_username
                        : ""
                    : "";
            }

            if (requestByName === "" || requestByName === undefined || requestByName === null) {
                Toast.fail(
                    "We could not find GOL name based on the entered OHR. Please check and reenter.",
                    5000,
                    () => { }
                );
                this.setState({ DB_GOLNAME: "" }, () => { });
            } else {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.DB_GOLOHR = textBoxValidation(true);
                styleStateObj.DB_GOLNAME = textBoxValidation(true);
                this.setState(
                    {
                        DB_GOLNAME: requestByName,
                        selectStyle: styleStateObj,
                    },
                    () => {
                        console.log("gol name after set function ", this.state.DB_GOLNAME);
                    }
                );
            }
            Toast.hide();
        } catch (error) {
            Toast.hide();
            Toast.fail("Please enter a valid GOL OHR", 5000, () => {
                console.log(JSON.stringify(error));
            });
            this.setState({ DB_GOLOHR: "", DB_GOLNAME: "" }, () => { });
        }
    };
    // ..auto fetch GOLOHR function ends here....

    handleDBClientNameChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.DB_clientRole = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            DB_clientName: e,
        });
    }

    deleteClientTestimonyFile = () => {
        this.setState({
            CTestimonyFileName: "",
            CTestimonyFileType: ""
        }, () => { })
    }


    templateRedirect = (template) => {
        if (this.state.ideaItem.LeanID !== "" || this.state.ideaItem.LeanID !== null || this.state.ideaItem.LeanID !== undefined || !this.state.ideaItem.LeanID) {
            if ((parseInt(this.state.ideaItem.OwnerOHR) === parseInt(this.sessionData.profile.oHRId)) || (parseInt(this.state.ideaItem.BlackBeltUserId) === parseInt(this.sessionData.profile.oHRId))) {
                console.log("db project type =>",this.state.ideaItem.DBProjectType)
                if (template === "Descriptive") {
                    if(template === this.state.ideaItem.DBProjectType ){
                        setTimeout(() => {
                            this.savePartialDBData();
                        }, 1000);
                        this.props.history.push({
                            pathname: "/descriptive-analytics-template",
                            state: this.state.ideaItem.IdeaId,
                        });
                    }
                    if(template !== this.state.ideaItem.DBProjectType )
                    {
                        if(this.state.ideaItem.DBProjectType === null || this.state.ideaItem.DBProjectType === undefined || this.state.ideaItem.DBProjectType === "") {
                            setTimeout(() => {
                                this.savePartialDBData();
                            }, 1000);
                            this.props.history.push({
                                pathname: "/descriptive-analytics-template",
                                state: this.state.ideaItem.IdeaId,
                            });
                        }else{
                            if (window.confirm("User can only save one template among all the templates. If you wish to save another template the previous template data will be erased. Do you wish to continue ?")) {
                                setTimeout(() => {
                                    this.deletePartialDBData(template);
                                }, 2000);
                                setTimeout(() => {
                                    this.savePartialDBData();
                                }, 2000);
                            }
                        }
                        
                    }
                }
                if (template === "Diagnostic") {
                    if(template === this.state.ideaItem.DBProjectType ){
                        setTimeout(() => {
                            this.savePartialDBData();
                        }, 1000);
                        this.props.history.push({
                            pathname: "/diagnostic-or-prescriptive-template",
                            state: this.state.ideaItem.IdeaId,
                            templateType: template
                        });
                    }
                    if(template !== this.state.ideaItem.DBProjectType )
                    {
                        if(this.state.ideaItem.DBProjectType === null || this.state.ideaItem.DBProjectType === undefined || this.state.ideaItem.DBProjectType === ""){
                            setTimeout(() => {
                                this.savePartialDBData();
                            }, 1000);
                            this.props.history.push({
                                pathname: "/diagnostic-or-prescriptive-template",
                                state: this.state.ideaItem.IdeaId,
                                templateType: template
                            });
                        }else{
                            if (window.confirm("User can only save one template among all the templates. If you wish to save another template the previous template data will be erased. Do you wish to continue ?")) {
                                setTimeout(() => {
                                    this.deletePartialDBData(template);
                                }, 2000);
                                setTimeout(() => {
                                    this.savePartialDBData();
                                }, 2000);
                            }
                        }
                        
                    }
                }
                if (template === "Prescriptive") {
                    
                    if(template === this.state.ideaItem.DBProjectType ){
                        setTimeout(() => {
                            this.savePartialDBData();
                        }, 1000);
                        this.props.history.push({
                            pathname: "/diagnostic-or-prescriptive-template",
                            state: this.state.ideaItem.IdeaId,
                            templateType: template
                        });
                    }
                    if(template !== this.state.ideaItem.DBProjectType )
                    {
                        if(this.state.ideaItem.DBProjectType === null || this.state.ideaItem.DBProjectType === undefined || this.state.ideaItem.DBProjectType === ""){
                            setTimeout(() => {
                                this.savePartialDBData();
                            }, 1000);
                            this.props.history.push({
                                pathname: "/diagnostic-or-prescriptive-template",
                                state: this.state.ideaItem.IdeaId,
                                templateType: template
                            });
                        }else{
                            if (window.confirm("User can only save one template among all the templates. If you wish to save another template the previous template data will be erased. Do you wish to continue ?")) {
                                setTimeout(() => {
                                    this.deletePartialDBData(template);
                                }, 2000);
                                setTimeout(() => {
                                    this.savePartialDBData();
                                }, 2000);
                            }
                        }
                        
                    }
                }
                
                if (template === "Predictive") {
                   
                    if(template === this.state.ideaItem.DBProjectType ){
                        setTimeout(() => {
                            this.savePartialDBData();
                        }, 1000);
                        this.props.history.push({
                            pathname: "/predictive-template",
                            state: this.state.ideaItem.IdeaId,
                        });
                    }
                    if(template !== this.state.ideaItem.DBProjectType )
                    {
                        if(this.state.ideaItem.DBProjectType === null || this.state.ideaItem.DBProjectType === undefined || this.state.ideaItem.DBProjectType === ""){
                            setTimeout(() => {
                                this.savePartialDBData();
                            }, 1000);
                            this.props.history.push({
                                pathname: "/predictive-template",
                                state: this.state.ideaItem.IdeaId,
                            });
                        }else{
                            if (window.confirm("User can only save one template among all the templates. If you wish to save another template the previous template data will be erased. Do you wish to continue ?")) {
                                setTimeout(() => {
                                    this.deletePartialDBData(template);
                                }, 2000);
                                setTimeout(() => {
                                    this.savePartialDBData();
                                }, 2000);
                            }
                        }
                        
                    }
                }
            }
            else {
                Toast.fail("Only Project owner and project Mentor can fill templates", 3000, () => { })
            }
        }
        else {
            Toast.fail("Only Projects which have been converted into lean can only be viewed", 3000, () => { })
        }
    }



    renderMUCIDOptions = () => {
        let options = [];
        let databridgeuseCase = "";
        this.state.mucIDOptionsfromMetaData.map((item, index) => {
            databridgeuseCase = item.pg_databridgeusecasecalculated.toString().toLowerCase();
            options.push({
                value: index,
                label: item.pg_databridgeusecasecalculated,
            });
            return true;
        });
        return options;
    }

    handleDiagnosticNavigate=()=>{
        this.setState({navigatetoDiagnostic:true},()=>{})
    }


    savePartialDBData=async()=>{
        return new Promise((resolve, reject) => {
            let param = {};

            param = {
                IdeaId: this.props.location.state,
                case: "new",
                casetype: "DBPartialSave",
                updaterRole: "Idea Owner",
                DBProjectType: this.state.dataBridgeType ? this.state.dataBridgeType.label : "",

                MUCID: this.state.mucIDSelectedOption ? this.state.mucIDSelectedOption.value : this.state.ideaItem.MUCID,
                MUCIDTitle: this.state.mucIDSelectedOption ? this.state.mucIDSelectedOption.label :this.state.ideaItem.MUCIDTitle,

                DB_COO: this.state.COOMappingSelected ? this.state.COOMappingSelected : "",
                DB_COOOHR: this.state.COOOHR ? this.state.COOOHR : "",

                ProcessOwner: this.state.processOwnerName ? this.state.processOwnerName : "",
                ProcessOwnerOHR: this.state.processOwnerOHR ? this.state.processOwnerOHR : "",
                processOnwerEmail: this.state.processOnwerEmail ? this.state.processOnwerEmail : "",

                DBTool: this.state.dbToolUsed ? this.state.dbToolUsed.label : "",
                OtherDBTool: this.state.dbToolUsed && this.state.dbToolUsed.label === "Others" ? (this.state.otherDBToolUsedText ? this.state.otherDBToolUsedText : "") : "",

                IsSharedGOL: this.state.sharedToGOL ? this.state.sharedToGOL.label : "",
                DB_GOL: this.state.sharedToGOL && this.state.sharedToGOL.label === "Yes" ? (this.state.DB_GOLNAME ? this.state.DB_GOLNAME : "") : "",
                DB_GOLOHR: this.state.sharedToGOL && this.state.sharedToGOL.label === "Yes" ? (this.state.DB_GOLOHR ? this.state.DB_GOLOHR : "") : "",

                IsSharedClient: this.state.sharedToClient ? this.state.sharedToClient.label : "",
                DB_ClientName: this.state.sharedToClient && this.state.sharedToClient.label === "Yes" ? (this.state.DB_clientName ? this.state.DB_clientName : "") : "",
                DB_ClientRole: this.state.sharedToClient && this.state.sharedToClient.label === "Yes" ? (this.state.DB_clientRole ? this.state.DB_clientRole : "") : "",

                CTestimonyFileName: this.state.sharedToClient && this.state.sharedToClient.label === "Yes" ? (this.state.CTestimonyFileName ? this.state.CTestimonyFileName : this.state.ideaItem.CTestimonyFileName) : this.state.ideaItem.CTestimonyFileName,
                CTestimonyFileType: this.state.sharedToClient && this.state.sharedToClient.label === "Yes" ? (this.state.CTestimonyFileType ? this.state.CTestimonyFileType : this.state.ideaItem.CTestimonyFileType) : this.state.ideaItem.CTestimonyFileType,
            }
            console.log(param);
            let finalJsonData = Checksum(param);
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };
            fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
            .then(response => response.json())
            resolve()
        })
        
    }



    deletePartialDBData=async(template)=>{
        try{
            Toast.loading("Deleting template data...", () => { });
            let param = {};
            param = {
                IdeaId: this.props.location.state,
                case: "new",
                casetype: "partialDeleteDPA",
                updaterRole: "Idea Owner",
                ProjectID: this.state.ideaItem.LeanID ? this.state.ideaItem.LeanID : "",
                DBProjectType: this.state.dataBridgeType ? this.state.dataBridgeType.label : "",
            }
            console.log(param);
            let finalJsonData = Checksum(param);
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };

            const response = await fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
            const data = await response.json();
            Toast.hide();
            Toast.success('Template data deleted successfully!', 3000, () => {
                if(template === "Descriptive"){
                    this.props.history.push({
                        pathname: "/descriptive-analytics-template",
                        state: this.state.ideaItem.IdeaId,
                    });
                }
                if(template === "Diagnostic"){
                    this.props.history.push({
                        pathname: "/diagnostic-or-prescriptive-template",
                        state: this.state.ideaItem.IdeaId,
                        templateType: template
                    })
                }
                if(template === "Prescriptive"){
                    this.props.history.push({
                        pathname: "/diagnostic-or-prescriptive-template",
                        state: this.state.ideaItem.IdeaId,
                        templateType: template
                    })
                }
                if(template === "Predictive"){
                    this.props.history.push({
                        pathname: "/predictive-template",
                        state: this.state.ideaItem.IdeaId
                    })
                }
            });
            
        }catch (error) {
            console.log(error);
            Toast.fail("Error while deleting template data. Please try again!", 3000, () => { });
        }
        
        
    }


    loadCOO=()=>{
        if(this.state.ideaItem.TransformationLeverName==="Data"){
            this.state.COOMapping && this.state.COOMapping.map((item, index) => {
                if (item.pg_title === this.state.ideaItem.VerticalManual) {
                    this.setState({ COOMappingSelected: item.pg_value }, () => { })
                } 
                if(item.pg_title === this.state.ideaItem.VerticalManual){
                    this.setState({ COOOHR: item.pg_value2 }, () => { })
                }
            })
        }
    }


    fetchDBType=()=>{
        if(this.state.dataBridgeType){
            return this.state.dataBridgeType.label;
        }
    }
    
    // handleMUCIDOptionHover=(e)=>{
    //     console.log("mover on hover")
    //     this.setState({showMUCIDToolTip:!this.state.showMUCIDToolTip,showMUCIDToolTipValue:e.label})
    // }
    // handleMUCIOptionBlur = () => {
    //     this.setState({showMUCIDToolTip:!this.state.showMUCIDToolTip,showMUCIDToolTipValue:""})
    // };

    render() {

        let templateType = this.state.dataBridgeType ? this.state.dataBridgeType.label :"";
       
        let convertIdeaID = window.btoa(this.state.ideaItem.IdeaId);
        let IDEA_APPROVED = (this.state.ideaItem.WorkflowStatus === "Approve");
        let IDEA_REJECTED = (this.state.ideaItem.WorkflowStatus === "Reject");
        let IDEA_SEND_BACK = (this.state.ideaItem.WorkflowStatus === "Send Back");

        let IsSolnFeasibleValue = "";
        if (this.state.ideasAI.IsSolnFeasible === false) {
            IsSolnFeasibleValue = "No"
        }
        if (this.state.ideasAI.IsSolnFeasible === true) {
            IsSolnFeasibleValue = "Yes"
        }


        return (
            <section className="u-clearfix u-section-1" id="sec-0f7f">
                <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                    <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                        <div className="u-container-layout u-container-layout-1">

                            {
                                this.state.ideaItem.ApprovalStatus === 'Pending Documents' && (
                                    <>
                                        <div className="row">
                                            <div className="col-md-8">&nbsp;</div>
                                            <div className="col-md-4">
                                                <div className="cluster_modal_btn_group">
                                                    <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-white u-btn u-btn-round  u-radius-6 u-btn-3 update_parent_btn" onClick={this.showClusterModal}>Update Parent</button>
                                                    <button tabIndex={21} className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.cancelRequest}>Cancel Request</button>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="text-left mt-4">
                                            {
                                                this.state.cancelRequest
                                                    ?
                                                    <>
                                                        <div className="custom_modal">
                                                            <div className="modal_body" style={{ width: '700px', overflow: 'hidden' }}>
                                                                <div className="row">
                                                                    <div className="col-md-8">
                                                                        <h3 className="modal_title">Cancel Request</h3>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ cancelRequest: false })}>&times;</button>
                                                                    </div>
                                                                </div>

                                                                <div className="cancellation_alert_msg">
                                                                    <div className="">
                                                                        <label style={{color:"red"}}>Note: Once cancelled you cannot have the project reinitiated. You will need to start the project from scratch.</label>
                                                                    </div>
                                                                </div>


                                                                <div className="row">
                                                                    <div className="col-md-10">
                                                                        <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                                            <span className="u-text-black form-attribute"><span style={{ color: 'red' }}>*</span>&nbsp;Comments<span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}> (max. 500 chars)</span></span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-md-2" style={{ display: 'flex', justifyContent: "flex-end", alignItems: "center" }}>
                                                                        <img onClick={(e) => this.toggleTranscriptFunction(14)} src={require("../assets/images/voice.png")} className="w100" alt="" style={{ width: "24px", cursor: "pointer" }} />
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ marginTop: 5 }}>
                                                                    <div className="col-md-12">
                                                                        <textarea className="textarea-css form-attribute" rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey', ...this.state.selectStyle.comment }} placeholder="Please provide your comments on reason for cancellation." onChange={this.handleCommentChange} value={this.state.Comments} />
                                                                    </div>
                                                                </div>
                                                                <div className="btn-group" style={{ marginTop: 10 }}>
                                                                    <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.submitEvent}>Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }


                                            {/* ....cluster modal starts here.... */}

                                            {
                                                this.state.clusterModal
                                                    ?
                                                    <>
                                                        <div className="cluster_modal_main_block">
                                                            <div className="custom_modal cluster_modal_block">
                                                                <div className="modal_body" style={{ width: '400px' }}>
                                                                    <div className="row">
                                                                        <div className="col-md-11">
                                                                            <h3 className="modal_title text-center" >Update Parent</h3>
                                                                        </div>
                                                                        <div className="col-md-1">
                                                                            <button type="button" className="close text-right" onClick={(e) => this.setState({ clusterModal: false })}>&times;</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="clustor_modal_fields_block">
                                                                        <div className="clustor_modal_field">
                                                                            <label className="form-attribute"><span style={{ color: "red" }}>*</span> Project Type:</label>
                                                                            {
                                                                                this.state.displayParentAlpha
                                                                                    ?
                                                                                    <>
                                                                                        <input disabled={true} value={this.state.projectTypeSelectedOption ? this.state.projectTypeSelectedOption.label : ""} className="form-input" placeholder="Choose project type" />
                                                                                    </>
                                                                                    :
                                                                                    <Select isSearchable={false} options={projectType} value={this.state.projectTypeSelectedOption} styles={this.state.selectStyle.projectType} placeholder="Choose project type" onChange={this.changeProjectType} />
                                                                            }
                                                                        </div>
                                                                        <div className="clustor_modal_field">
                                                                            <label className="form-attribute"><span style={{ color: "red" }}>*</span> Project ID:</label>
                                                                            {
                                                                                this.state.displayParentAlpha
                                                                                    ?
                                                                                    <>
                                                                                        <input value={this.state.projectIDSelectedOption && this.state.projectIDSelectedOption.label ? this.state.projectIDSelectedOption.label : ""} disabled={true} className="form-input" placeholder="Choose project ID" />
                                                                                    </>
                                                                                    :
                                                                                    <Select isSearchable onInputChange={this.handleClusterProjectInputChange} options={this.displayProjectID()} value={this.state.projectIDSelectedOption} styles={this.state.selectStyle.projectIDSelectedOption} isDisabled={this.state.projectTypeSelectedOption ? false : true} placeholder="Choose project ID" onChange={this.changeProjectID} />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="cluster_modal_btn_group">
                                                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" disabled={this.state.isClusterSaveDisabled ? true : false} onClick={this.saveParentLink}>Save</button>
                                                                        <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.removeParentFunc} style={{ marginRight: "15px" }}>Remove Parent</button>
                                                                        {
                                                                            this.state.displayParentAlpha
                                                                                ?
                                                                                <>
                                                                                    <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6 show_hierarchy_link_btn"><Link className="show_hierarchy_link_btn_anchor" to={`/showTree?ID=${this.state.ideaItem.IdeaId}`} target='_blank'>Show Hierarchy</Link></button>
                                                                                </>
                                                                                :
                                                                                <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6 show_hierarchy_link_btn">Show Hierarchy</button>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </>
                                                    :
                                                    null
                                            }
                                            {/* ....cluster modal ends here.... */}


                                        </div>
                                    </>
                                )
                            }
                            <h4 style={{ textAlign: 'center', color: '#00AECF', fontFamily: 'Arial', fontSize: 22 }}>Update idea : {this.state.ideaItem.IdeaIDAlpha ? this.state.ideaItem.IdeaIDAlpha : this.state.ideaItem.IdeaId} </h4>
                            <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                                <div className="u-container-layout u-container-layout-2" style={{ textAlign: 'center' }}>
                                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-3">
                                        <div className="u-container-layout u-container-layout-3">
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: 15 }}>Owner details</p>
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner OHR</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerOHR} /></div>
                                            </div>
                                            <div style={{ marginBottom: 15 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner Name</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerName} /></div>
                                            </div>
                                            <div style={{ marginBottom: -10 }} />
                                            <p className="u-align-left u-text u-text-7 u-text-custom-color-3" style={{ fontSize: 11, marginBottom: 10 }}>(If Idea was raised for someone else, their OHR ID is below)</p>
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Request For OHR</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.CreatorOHR} onChange={this.handleCreatorOHRChange} /></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Name</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.CreatorName} onChange={this.handleCreatorNameChange} /></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Project ID</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.LeanID} /></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                        </div>
                                    </div>

                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 235 }}>&nbsp;</p>

                                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-4" style={{ marginTop: -305 }}>
                                        <div className="" style={{ display: "flex", marginTop: "30px" }}>
                                            <div className="u-container-style u-group u-shape-rectangle u-group-5">
                                                <div className="u-container-layout u-container-layout-5">
                                                    <p className="u-align-left u-text u-text-8" style={{ marginBottom: 15 }}>Account Details</p>
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Account&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled list="accounts" name="accounts" placeholder="Choose Account" value={this.state.ideaItem.AccountManual} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Holding&nbsp;Account</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled type="text" value={this.state.ideaItem.HoldingAccount} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Vertical</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled list="vertical" name="vertical" placeholder="Choose Vertical" value={this.state.ideaItem.VerticalManual} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Industry&nbsp;Vertical</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled list="subverticallist" name="subverticallist" placeholder="Choose Industry&nbsp;Vertical" value={this.state.ideaItem.SubVerticalName} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />

                                                    {/* units and segments starts here */}
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Segment</label>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="segment" value={this.state.ideaItem.Segment ? this.state.ideaItem.Segment : ""} style={{ fontWeight: 'normal !important' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Unit</label>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="unit" value={this.state.ideaItem.Unit ? this.state.ideaItem.Unit : ""} style={{ fontWeight: 'normal !important' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    {/* units and segments ends here */}


                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Campaign&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled list="ideationtype" name="ideationtype" placeholder="Choose Idea Type" value={this.state.ideaItem.CampaignName} />
                                                        </div>
                                                        <div className="">
                                                            <div className="campaign_modal_bulb campaign_tool_tip">
                                                                <img 
                                                                    src={require("../assets/images/question_mark_2.png")}
                                                                    alt=""
                                                                    className=""
                                                                />
                                                                {
                                                                    this.state.campaignInsight
                                                                    ?
                                                                    <>
                                                                        <span className="my_tooltiptext">{this.state.campaignInsight}</span>
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />

                                                    {
                                                        this.state.ideaItem.CampaignName === "Gen AI"
                                                            ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5">
                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Gen AI Hackathon Theme</label></div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Gen AI Theme" value={this.state.ideaItem.SubCampaignName} />
                                                                    </div>
                                                                </div>

                                                            </>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        this.state.displayProjectTheme && this.state.dataBridgeCampaign && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Data Bridge Project Theme</label></div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled list="databridgetype" name="databridgetype" placeholder="Choose Data Bridge Project Type" value={this.state.ideaItem.DataBridgeTheme} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 26 }} />
                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        this.state.xPollinationCampaign && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Campaign</label></div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Sub Campaign" value={this.state.ideaItem.SubCampaignName} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 26 }} />
                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        this.state.displayParentAlpha
                                                            ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5">
                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Parent ID</label>
                                                                    </div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled placeholder="Choose Parent ID" value={this.state.displayParentAlpha} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }


                                                    {
                                                        this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST
                                                            ?
                                                            <>
                                                                <div className="row" style={{ marginTop: "20px" }}>
                                                                    <div className="col-md-5">
                                                                        <label
                                                                            style={{
                                                                                verticalAlign: "bottom",
                                                                                marginBottom: -5,
                                                                            }}
                                                                        >
                                                                            <i>AI First Sample Ideas</i>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-7">
                                                                        <div className="ai_first_doc_link ai_first_doc_red_highlight">
                                                                            <a
                                                                                className="ldt-list1"
                                                                                href={AI_DOC_LINK}
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                                style={{ textAlign: "left", fontSize: 16, color: "red" }}
                                                                            >Document Link </a>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                {
                                                                    this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )
                                                                        ?
                                                                        <>
                                                                            <div style={{ marginBottom: 20 }} />
                                                                            <div className="row d_flex_align_items_center">
                                                                                <div className="col-md-5">
                                                                                    <span className="form-attribute">
                                                                                        AI Recommendation
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-7">
                                                                                    <input
                                                                                        className="form-input"
                                                                                        disabled
                                                                                        name="methodology"
                                                                                        value={this.state.ideaItem.isAI}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null

                                                                }


                                                                {
                                                                    this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )
                                                                        ?
                                                                        <>
                                                                            <div style={{ marginBottom: 20 }} />
                                                                            <div className="row">
                                                                                <div className="col-md-5">
                                                                                    <span className="form-attribute">
                                                                                        AI First BRD
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-7">
                                                                                    <div className="ai_first_doc_link">
                                                                                        <textarea className="ai_first_brd_textarea" disabled value={this.state.ideasAI.LinkBRD} ></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }

                                                            </>
                                                            :
                                                            null
                                                        }



                                                </div>
                                            </div>
                                            <div className="u-container-layout u-container-layout-6">
                                                <div className="row" style={{ marginTop: "10px" }}>
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Country</label></div>
                                                    <div className="col-md-7">
                                                        <input className="form-input" disabled list="country" name="country" placeholder="Choose Country" value={this.state.ideaItem.Country} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Region</label></div>
                                                    <div className="col-md-7">
                                                        <input className="form-input" disabled list="regions" name="regions" placeholder="Choose Region" value={this.state.ideaItem.RegionManual} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub-Region</label></div>
                                                    <div className="col-md-7">
                                                        <input className="form-input" disabled list="subregions" name="subregions" placeholder="Choose Sub Region" value={this.state.ideaItem.SubRegion} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Blackbelt&nbsp;Name</label></div>
                                                    <div className="col-md-7">
                                                        <input className="form-input" disabled list="blackbelt" name="blackbelt" placeholder="Choose Blackbelt" title={"OHR ID: " + this.state.ideaItem.BlackBeltUserId} value={this.state.ideaItem.BlackBeltUserName} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>MBB</label></div>
                                                    <div className="col-md-7">
                                                        <input className="form-input" disabled name="blackbelt" placeholder="Choose MBB" title={"OHR ID: " + this.state.ideaItem.MBBOHR} value={this.state.ideaItem.MBBName} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />

                                                    {
                                                        this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST &&
                                                        this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )
                                                            ?
                                                            <>
                                                            <div style={{ marginBottom: 20 }} />
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                    <span className="form-attribute">
                                                                        AI First Reason
                                                                    </span>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                    <textarea
                                                                        disabled
                                                                        value={this.state.ideasAI.AIReason}
                                                                        placeholder="Please provide value"
                                                                        className="form-input"
                                                                        rows={4}
                                                                        cols={80}
                                                                        style={{
                                                                        width: "100%",
                                                                        border: "1px solid lightgrey",
                                                                        resize: "none"
                                                                        }}
                                                                    />
                                                                    </div>
                                                                </div>
                                                            <div style={{ marginBottom: 20 }} />
                                                            </>
                                                            :
                                                            null
                                                        }


                                            </div>
                                        </div>
                                    </div>



                                    {
                                        this.state.dataBridgeCampaign || this.state.xPollinationCampaign
                                            ?
                                            <>
                                                <div style={{ borderBottom: '1px solid lightgrey' }} />
                                            </>
                                            :
                                            <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />
                                    }


                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -30 }}>&nbsp;</p>

                                    <div id="idea1" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ marginTop: 30 }}>
                                        <div className="u-container-layout u-container-layout-7">
                                            <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                <div className="u-container-layout u-container-layout-8">
                                                    <p id="idea1title" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Idea details</p>
                                                    <div className="row">
                                                        <div className="col-md-3"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Service Line</label> </div>
                                                        <div className="col-md-9"><input className="form-input" disabled type="text" value={this.state.ideaItem.ServiceLineName} placeholder="Eg: Retail Banking, Commercial Lending, Collections" /></div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Service Line</label> </div>
                                                        <div className="col-md-8">
                                                            <input className="form-input" disabled name="serviceline" placeholder="Choose Sub Service Line" value={this.state.ideaItem.SubServiceLine} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                        <span className="u-text-black form-attribute"> What is the Problem ?</span>&nbsp;</p>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <textarea className="form-input" disabled rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Problem} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 20 }}>&nbsp;</p>
                                            <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                <div className="u-container-layout u-container-layout-9" style={{ marginTop: 4 }}>
                                                    <div className="row">
                                                        <div className="col-md-3"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Idea Name</label> </div>
                                                        <div className="col-md-9" style={{ alignItems: 'flex-start' }}><input className="form-input" disabled type="text" value={this.state.ideaItem.IdeaName} placeholder="Eg: Cash flow improvement through process restructuring" /></div>
                                                    </div>
                                                    <div style={{ marginBottom: 65 }} />
                                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -80 }}>&nbsp;</p>
                                                    <p className="u-align-left u-text u-text-custom-color-4 u-text-25" style={{ width: "177px" }}>
                                                        <span className="u-text-black form-attribute"> How to resolve the problem</span>&nbsp;</p>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <textarea className="form-input" disabled rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Solution} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 15 }}>
                                                <div className="col-md-5"><span className="form-attribute">Are you trying to replicate an existing Idea?</span>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="switch">
                                                        <input disabled type="checkbox" checked={this.state.ideaItem.NewOrReplicatedIdea === null ? false : this.state.ideaItem.NewOrReplicatedIdea} />
                                                        <span className="slider round" />
                                                    </label>
                                                </div>
                                            </div>

                                            {
                                                this.state.ideaItem.NewOrReplicatedIdea && (
                                                    <>
                                                        <div className="col-md-8" style={{ marginBottom: 15, width: "100%" }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><span className="form-attribute">Provide the ID of the executed Idea which you want to replicate?</span>
                                                            </div>
                                                            <div className="col-md-2"><input className="form-input" disabled type="text" value={this.state.ideaItem.ReplicationProjectId} /></div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div style={{ borderBottom: '1px solid lightgrey', marginTop: 40 }} />

                                    <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                        <div className="u-container-layout u-container-layout-7" style={{display:"flex", alignItems:"flexStart"}}>
                                            <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                <div className="u-container-layout u-container-layout-8" style={{paddingBottom:"0px"}}>
                                                    <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>More Details</p>
                                                    <div className="row d_flex_align_items_center">
                                                        <div className="col-md-6"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>LDT Lead Name</label> </div>
                                                        <div className="col-md-6">
                                                            <input className="form-input" disabled list="ldtName" name="ldtName" value={this.state.ideaItem.LDTLeadName} title={"OHR ID: " + this.state.ideaItem.LDTId} placeholder="LDT Lead" />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    {
                                                        this.state.ideaItem.CampaignName && this.state.ideaItem.CampaignName === AIFIRST
                                                        && this.state.ideaItem.isAI && (this.state.ideaItem.isAI === "Yes" || this.state.ideaItem.isAI === "No" )
                                                            ?
                                                            <>
                                                                <div style={{ marginBottom: 15 }} />
                                                                <div className="row d_flex_align_items_center">
                                                                    <div className="col-md-6">
                                                                        <span className="form-attribute">
                                                                            Is the solution feasible ?
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <input
                                                                            className="form-input"
                                                                            disabled
                                                                            value={IsSolnFeasibleValue}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 15 }} />
                                                                <div className="row d_flex_align_items_center">
                                                                    <div className="col-md-6">
                                                                        <span className="form-attribute">
                                                                            Solution Complexity
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <input
                                                                            className="form-input"
                                                                            disabled
                                                                            name="methodology"
                                                                            value={
                                                                                this.state.ideasAI.SolnComplexity
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>


                                                                <div style={{ marginBottom: 15 }} />
                                                                <div className="row d_flex_align_items_center">
                                                                    <div className="col-md-6">
                                                                        <span className="form-attribute">
                                                                            Is the Idea replicable
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <input
                                                                            className="form-input"
                                                                            disabled
                                                                            value={this.state.isIdeaReplicable}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 15 }} />
                                                            </>
                                                            :
                                                            null
                                                    }



                                                    {/* <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Potential Impact (# of FTE)</label> </div>
                                                        <div className="col-md-6"><input className="form-input" disabled type="number" value={this.state.ideaItem.PotentialImpactFTE} placeholder={0} max={10} /></div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Potential Impact ($)</label> </div>
                                                        <div className="col-md-6"><input className="form-input" disabled type="number" value={this.state.ideaItem.PotentialImpactDollar} placeholder={0.0} /></div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <p className="u-align-left u-text u-text-7 u-text-custom-color-3" style={{ fontSize: 11, marginBottom: 10 }}>(If
                                                        Potential Impact $ is greater than 250K OR FTE number is greater than 10, please review thoroughly)</p> */}
                                                    <div style={{ marginTop: 10, marginBottom: 14 }} />
                                                    {
                                                        this.state.ideaItem.NewOrReplicatedIdea && (
                                                            <div className="row">
                                                                <div className="col-md-10">
                                                                    <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Have you verified the replication id and confirm this to be replication project?</label>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <label className="switch">
                                                                        <input disabled type="checkbox" checked={this.state.ideaItem.IsReplicationVerified} />
                                                                        <span className="slider round" />
                                                                    </label>
                                                                </div>
                                                                <div style={{ marginBottom: 15 }} />
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        !this.state.ideaItem.NewOrReplicatedIdea && (
                                                            <div className="row">
                                                                <div className="col-md-10">
                                                                    <label style={{ verticalAlign: 'bottom', marginBottom: 17 }}>&nbsp;</label>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>


                                            <div className="u-container-style u-group u-shape-rectangle">
                                                <div className="u-container-layout u-container-layout-9" style={{padding: "30px 0px 0px 0px"}}>
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Certification Level</label> </div>
                                                        <div className="col-md-6">
                                                            <input className="form-input" disabled list="certificationLevel" value={this.state.ideaItem.CertificationLevelName} name="certificationLevel" placeholder="Certification Level" />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />

                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Transformation Lever</label> </div>
                                                        <div className="col-md-6">
                                                            <input className="form-input" disabled value={this.state.ideaItem.TransformationLeverName} list="transformationLever" name="transformationLever" placeholder="Transformation Lever Level" />
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.ideaItem.DigitalProjectType
                                                            ?
                                                            <>
                                                                <div style={{ marginBottom: 15 }} />
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Digital Project Type</label> </div>
                                                                    <div className="col-md-6">
                                                                        <input className="form-input" disabled value={this.state.ideaItem.DigitalProjectType} list="transformationLever" name="transformationLever" placeholder="Transformation Lever Level" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        this.state.ideaItem.CertificationWorkTypeName !== null && this.state.ideaItem.CertificationWorkTypeName !== ""
                                                            ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Work Type</label></div>
                                                                    <div className="col-md-6">
                                                                        <input className="form-input" disabled list="certificationWorkType"
                                                                            value={this.state.ideaItem.CertificationWorkTypeName} name="certificationWorkType" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }


                                                    <div style={{ marginBottom: 15 }} />


                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Tools/Methodology Used</label> </div>
                                                        <div className="col-md-6">
                                                            <input className="form-input" disabled value={this.state.ideaItem.MethodologyName} list="methodology" name="methodology" placeholder="Tools/Methodology" />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 50 }} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ borderBottom: '1px solid lightgrey' }} />

                                    <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                        <div className="u-container-layout u-container-layout-7">
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Idea Approval details</p>
                                            <div className="row">
                                                <div className="col-md-2"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Approval Status</label> </div>
                                                <div className="col-md-2">
                                                    <input className="form-input" disabled value={IDEA_APPROVED ? APPROVED : (IDEA_REJECTED ? REJECTED : (IDEA_SEND_BACK ? SENT_BACK : PENDING))} list="approvalStatus" name="approvalStatus" placeholder="Choose Status" />
                                                    <datalist id="approvalStatus">
                                                        <option value="Approve" />
                                                        <option value="Send Back" />
                                                        <option value="Rejecte" />
                                                    </datalist>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{ marginBottom: 15 }} />

                                            <div className="row" style={{ marginTop: 15 }}>
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Comments</label></div>
                                                <div className="col-md-2" style={{ marginLeft: 20 }}>
                                                    <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ commentLog: !this.state.commentLog })}>Comment Log</button>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 5 }}>
                                                <div className="col-md-12">
                                                    <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.Comment} />
                                                </div>
                                            </div>
                                            <div className="text-left mt-4">
                                                {
                                                    this.state.commentLog && this.state.reviewComments
                                                        ?
                                                        <>
                                                            <div className="custom_modal">
                                                                <div className="modal_body">
                                                                    <div className="row">
                                                                        <div className="col-md-8">
                                                                            <h3 className="modal_title">Idea Approval Comment Log</h3>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <button type="button" className="close text-right" onClick={(e) => this.setState({ commentLog: false })}>&times;</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                        </div>
                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                        </div>
                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                        </div>
                                                                        <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                        </div>
                                                                        <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                        </div>
                                                                        <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                            <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.reviewComments.map((item, index) => {

                                                                            return (
                                                                                <div className="row" key={index}>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                    </div>
                                                                                    <div className="col-md-1 text-center role_over_flow_class" style={{ border: "1px solid lightgrey" }}>
                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === 'Delegate Action' ? 'BB' : (item.UpdaterOHR === this.state.ideaItem.BlackBeltUserId ? "BB" : (item.UpdaterOHR === this.state.ideaItem.MBBOHR ? "MBB" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Idea Owner" : "LDT"))))}</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? (item.WorkflowStatus !== 'Pending' ? item.WorkflowStatus : 'Idea Updated') : (item.Action === 'Delegate Action' ? 'Delegated' : (item.WorkflowStatus === 'Pending' ? 'Discard Cancellation' : item.WorkflowStatus)))}</label>
                                                                                    </div>
                                                                                    <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                        <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment ? item.Comment : '-'}</label>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ borderBottom: '1px solid lightgrey', marginTop: 15 }} />
                                    {
                                        this.state.ideaItem.ProjectPPTFileName && (
                                            <>
                                                <div className="u-container-layout u-container-layout-7">
                                                    <div className="">
                                                        <div className="u-align-left">
                                                            <p id="Upload" className="u-text u-text-2" style={{ marginBottom: 10 }}>Uploaded documents</p>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <label style={{ verticalAlign: 'bottom' }}>Project Presentation Deck</label>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <button className={this.state.ideaItem.ProjectPPTFileName && this.state.ideaItem.ProjectPPTFileName.length>50 ? "upload_file_text_overflow":""} style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0 }} onClick={this.showPPTContent}>{this.state.ideaItem.ProjectPPTFileName}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    {
                                                                        this.state.ideaItem.CustomerSignoffFileName
                                                                            ?
                                                                            <>
                                                                                <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <label style={{ verticalAlign: 'bottom' }}>Client Approval</label> </div>
                                                                                    <div className="col-md-12">
                                                                                        <button className={this.state.ideaItem.CustomerSignoffFileName && this.state.ideaItem.CustomerSignoffFileName.length>50 ? "upload_file_text_overflow":""}  style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0 }} onClick={this.showSignOffContent}>{this.state.ideaItem.CustomerSignoffFileName}</button>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div style={{ marginBottom: 10 }} />
                                                            <div className="row">
                                                                {
                                                                    this.state.ideaItem.FPnAName || this.state.ideaItem.GOLFileName
                                                                        ?
                                                                        <>
                                                                            <div className="col-md-12">
                                                                                <label style={{ verticalAlign: 'bottom', marginTop: "20px" }}>GOL / FP&A Approval</label>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <button className={this.state.ideaItem.GOLFileName && this.state.ideaItem.GOLFileName.length>50 ? "upload_file_text_overflow":""} style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0 }} onClick={this.showGOLContent}>{this.state.ideaItem.GOLFileName}</button>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <button className={this.state.ideaItem.FPnAFileName && this.state.ideaItem.FPnAFileName.length>50 ? "upload_file_text_overflow":""} style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0, marginTop: "10px" }} onClick={this.showFPnAContent}>{this.state.ideaItem.FPnAFileName}</button>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div style={{ marginBottom: 10 }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ borderBottom: '1px solid lightgrey' }} />
                                            </>
                                        )
                                    }
                                    {
                                        (this.state.ideaItem.ApprovalStatus
                                            && this.state.ideaItem.FinalComments !== null
                                        )
                                        && (
                                            <>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 10 }} />

                                                <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ borderBottom: '1px solid lightgrey' }}>
                                                    <div className="u-container-layout u-container-layout-7">
                                                        <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Project Approval details</p>
                                                        <div className="row">
                                                            <div className="col-md-2"><span className="form-attribute">Approval Status</span> </div>
                                                            <div className="col-md-2">
                                                                <input className="form-input" disabled name="finalapprovalstatus" value={this.state.ideaItem.ApprovalStatus} />
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: 15 }}>
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Approval Comments</label></div>
                                                            <div className="col-md-2" style={{ marginLeft: 20 }}>
                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ finalCommentLog: !this.state.finalCommentLog })}>Comment Log</button>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: 5 }}>
                                                            <div className="col-md-12">
                                                                <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.FinalComments} />
                                                            </div>
                                                        </div>
                                                        <div className="text-left mt-4">
                                                            {
                                                                this.state.finalCommentLog && this.state.finalReviewComments
                                                                    ?
                                                                    <>
                                                                        <div className="custom_modal">
                                                                            <div className="modal_body">
                                                                                <div className="row">
                                                                                    <div className="col-md-8">
                                                                                        <h3 className="modal_title">Lean Approval Comment Log</h3>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ finalCommentLog: false })}>&times;</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                    </div>
                                                                                    <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                    </div>
                                                                                    <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                    </div>
                                                                                    <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey", backgroundColor: "gainsboro" }}>
                                                                                        <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    this.state.finalReviewComments.map((item, index) => {

                                                                                        return (
                                                                                            <div className="row" key={index}>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                </div>
                                                                                                <div className="col-md-1 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === 'Idea was reviewed by MBB' ? "MBB" : (item.Action === 'Idea was reviewed by LDT' ? "LDT" : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Idea Owner" : "BB")))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-2 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.Action === 'Idea Reinitiated by Admin' ? 'Idea Reinitiated' : (item.Action === 'Idea Updated by Admin' ? item.ApprovalStatus : (item.WorkflowStatus === 'Completed' || item.WorkflowStatus === 'Approve' || item.WorkflowStatus === 'Approved' || item.ApprovalStatus === "Awaiting Quality Check Review") ? 'Approved' : ((item.WorkflowStatus === 'Rejected' || item.WorkflowStatus === 'Reject') ? "Rejected" : (item.WorkflowStatus === REQUEST_CANCELLATION ? REQUEST_CANCELLATION : (item.WorkflowStatus === 'Pending Documents' ? 'Discard Cancellation' : item.WorkflowStatus === 'Cancelled' ? 'Cancelled' : "Sent Back"))))}</label>
                                                                                                </div>
                                                                                                <div className="col-md-3 text-center" style={{ border: "1px solid lightgrey" }}>
                                                                                                    <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment !== null ? item.Comment : '-'}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }








                                    {/* ......qc details and comment logs starts here.............. */}

                                    {/* ............... */}
                                    {
                                        this.state.duplicateQCOHR
                                            ?
                                            <>
                                                <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                    <div className="u-container-layout u-container-layout-7" style={{ borderTop: " 1px solid lightgray", paddingTop: "40px" }}>
                                                        <p className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Quality Check Details Approval</p>
                                                        <div className="row" style={{ marginTop: 15 }}>
                                                            <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: "-13px" }}>Approval Comments</label></div>
                                                            <div className="col-md-2" style={{ marginLeft: 20 }}>
                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.setState({ qcCommentLog: true })}>Comment Log</button>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: 5 }}>
                                                            <div className="col-md-12">
                                                                <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.FinalComments} />
                                                            </div>
                                                        </div>
                                                        <div className="text-left mt-4">
                                                            {
                                                                this.state.qcCommentLog && this.state.qcComments
                                                                    ?
                                                                    <>
                                                                        <div className="custom_modal">
                                                                            <div className="modal_body">
                                                                                <div className="row">
                                                                                    <div className="col-md-8">
                                                                                        <h3 className="modal_title">QC Comment Log</h3>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <button type="button" className="close text-right" onClick={(e) => this.setState({ qcCommentLog: false })}>&times;</button>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="qc_responsive_comment_log">
                                                                                    <div className="table table-responsive table-bordered">
                                                                                        <thead className='text-center'>
                                                                                            <tr>
                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Date & Time</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>OHR</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Name</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Role</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Action</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg" style={{ minWidth: "250px" }}>
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Comment</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthOne">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Is the impact correctly tagged to Genpact or Client ?</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthTwo">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Has the Value Delivery been correctly categorized ?</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthThree">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Has the Value Delivery been computed correctly ?</label>
                                                                                                </th>
                                                                                                <th className="qcHeaderbg qcVDHeader qcHeaderFullWidthFour">
                                                                                                    <label style={{ fontWeight: 100, verticalAlign: "bottom" }}>Do the value delivery approvals (applicable as per the value playbook) clearly articulate what improved and for which time-period</label>
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                this.state.qcComments.map((item, index) => {
                                                                                                    return (
                                                                                                        <tr key={index}>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{this.formatDateStringWithTime(new Date(item.CreatedDate))}</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterOHR}</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterName}</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterRole ? item.UpdaterRole : (item.Action === 'Idea Updated by Admin' || item.Action === 'Idea Reinitiated by Admin') ? 'Admin' : (item.Action === "Delegate Action" ? 'BB' : (item.UpdaterOHR === this.state.MBBOHR ? 'MBB' : (item.UpdaterOHR === this.state.ideaItem.OwnerOHR ? "Idea Owner" : (item.ApprovalStatus === "Pending Documents" && item.WorkflowStatus === "Approved") ? "BB" : (item.ApprovalStatus === "NA" && item.WorkflowStatus === "Pending") ? "BB" : item.UpdaterRole)))}</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {/* <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.ApprovalStatus==="Documents Uploaded" ? "Send Back" : ((item.WorkflowStatus==="Rejected" || item.WorkflowStatus==="Reject" || item.ApprovalStatus==="Reject" || item.ApprovalStatus==="Rejected" )? "Rejected ":"Approved")}</label> */}
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.UpdaterAction ? item.UpdaterAction : item.ApprovalStatus === "Documents Uploaded" ? "Send Back" : ((item.WorkflowStatus === "Rejected" || item.WorkflowStatus === "Reject" || item.ApprovalStatus === "Reject" || item.ApprovalStatus === "Rejected") ? "Rejected " : (item.Action === "Idea was Rework - QC" ? "Rework" : "Approved"))}</label>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>{item.Comment !== '' ? item.Comment : '-'}</label>
                                                                                                            </td>
                                                                                                            {
                                                                                                                this.state.qcCommentsWithVD && this.state.qcCommentsWithVD.length > 0
                                                                                                                    ?
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            this.state.qcCommentsWithVD.map((pg_item) => {
                                                                                                                                if (Number(pg_item.ProjectTeamID) === item.TrackId) {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <td>
                                                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>

                                                                                                                                                    {JSON.parse(pg_item.AuditDataJSON).pg_isvdimpacttagged ? JSON.parse(pg_item.AuditDataJSON).pg_isvdimpacttagged : "-"}
                                                                                                                                                </label>
                                                                                                                                            </td>
                                                                                                                                            <td>

                                                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                                                                    {JSON.parse(pg_item.AuditDataJSON).pg_isvdcategorized ? JSON.parse(pg_item.AuditDataJSON).pg_isvdcategorized : "-"}

                                                                                                                                                </label>
                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                                                                    {JSON.parse(pg_item.AuditDataJSON).pg_isvdcomputed ? JSON.parse(pg_item.AuditDataJSON).pg_isvdcomputed : "-"}
                                                                                                                                                </label>
                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                <label style={{ lineheight: 2, verticalAlign: "bottom" }}>
                                                                                                                                                    {JSON.parse(pg_item.AuditDataJSON).pg_isvdclientsignoff ? JSON.parse(pg_item.AuditDataJSON).pg_isvdclientsignoff : "-"}
                                                                                                                                                </label>
                                                                                                                                            </td>

                                                                                                                                        </>

                                                                                                                                    )
                                                                                                                                }

                                                                                                                            })
                                                                                                                        }
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                        <td>
                                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <label style={{ lineheight: 2, verticalAlign: "bottom" }}>-</label>
                                                                                                                        </td>
                                                                                                                    </>
                                                                                                            }


                                                                                                        </tr>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </tbody>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="gol_fields_block" style={{ borderBottom: "0px", borderTop: "1px solid lightgray", paddingTop: "10px" }}>
                                                    <p className="u-align-left u-text u-text-2" style={{ margin: "10px 0px 20px" }}>Quality Check Details</p>
                                                    <div className="row u-align-left">
                                                        <div className="col-md-5">
                                                            <div className="row">
                                                                <div className="col-md-9 gol_field_div">
                                                                    <span className="form-attribute">Is the impact correctly tagged to Genpact or Client ?</span>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <input className="form-input disabled_input" disabled value={this.state.ideaItem.IsVDImpactTagged} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginBottom: 15 }} />

                                                            <div className="row">
                                                                <div className="col-md-9 gol_field_div">
                                                                    <span className="form-attribute">Has the value delivered been correctly categorized ?</span>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <input className="form-input disabled_input" disabled value={this.state.ideaItem.IsVDCategorized} />
                                                                </div>
                                                            </div>

                                                            <div style={{ marginBottom: 15 }} />

                                                            <div className="row">
                                                                <div className="col-md-5 gol_field_div">
                                                                    <span className="form-attribute">Project Reviewed By</span>
                                                                </div>
                                                                <div className="col-md-7">
                                                                    <input className="form-input disabled_input" disabled value={this.state.ideaItem.QCName} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-5">
                                                            <div className="row">
                                                                <div className="col-md-9 gol_field_div">
                                                                    <span className="form-attribute">Has the value delivered been computed correctly ?</span>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <input disabled value={this.state.ideaItem.IsVDComputed} className="form-input disabled_input" type="text" />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginBottom: 15 }} />

                                                            <div className="row">
                                                                <div className="col-md-9 gol_field_div">
                                                                    <span className="form-attribute">Do the value delivery approvals (applicable as per the value playbook) clearly articulate what improved and for which time-period ?</span>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <input disabled className="form-input disabled_input" type="text" value={this.state.ideaItem.IsVDClientSignOff} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }


                                    {/* ......qc details and comment logs ends here.............. */}





                                    {/* ........DB Fields starts here............ */}
                                    {
                                        this.state.ideaItem.TransformationLeverName &&
                                            this.state.ideaItem.TransformationLeverName === "Data" && 
                                            (parseInt(this.state.ideaItem.OwnerOHR) === parseInt(this.sessionData.profile.oHRId))
                                            && this.state.displayDBFields
                                            ?
                                            <>
                                                <div className="" style={{ paddingRight: "30px" }}>
                                                    <div className="">
                                                        <p className="u-align-left u-text u-text-2" style={{ margin: "15px 0px" }}>
                                                            Data Bridge Fields
                                                        </p>

                                                        <p className="db_warning_note"><i>Note: Post selecting DB Project type, click on the template type and fill up the template and then proceed further.</i></p>

                                                        
                                                        <div className="row">
                                                            <div className="col-md-5" >
                                                                <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                    <div className="row">
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                <label
                                                                                    style={{
                                                                                        color: "red",
                                                                                        verticalAlign: "bottom",
                                                                                    }}
                                                                                >
                                                                                    *
                                                                                </label>{" "}
                                                                                DB Project Type
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <Select
                                                                                // isDisabled={this.state.ideaItem.DBProjectType ? true : false}
                                                                                isSearchable={true}
                                                                                value={this.state.dataBridgeType}
                                                                                options={DATABRIDGETYPES}
                                                                                placeholder="Please choose"
                                                                                onChange={(e) =>this.handleDataBridgeTypeChange(e)}
                                                                                styles={this.state.selectStyle.dataBridgeType}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="">

                                                                    {
                                                                        this.state.dataBridgeType
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields" style={{ marginBottom: "20px" }}>
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <label
                                                                                                className="form-attribute"
                                                                                                style={{
                                                                                                    verticalAlign: "bottom",
                                                                                                    marginBottom: -5,
                                                                                                }}
                                                                                            >
                                                                                                <label
                                                                                                    style={{
                                                                                                        color: "red",
                                                                                                        verticalAlign: "bottom",
                                                                                                    }}
                                                                                                >
                                                                                                    *
                                                                                                </label>{" "}
                                                                                                Template Type
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <div className="ai_first_doc_link">
                                                                                                {
                                                                                                    this.state.dataBridgeType
                                                                                                    ?
                                                                                                    <>
                                                                                                        <label onClick={(e)=>this.templateRedirect(this.state.dataBridgeType.label)} className="template_type_label" style={{ marginBottom: "0px" }}>{this.state.dataBridgeType.label}</label>    
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                                }

                                                                                                {/* {
                                                                                                    this.state.dataBridgeType && this.state.dataBridgeType.label === "Descriptive"
                                                                                                    ?
                                                                                                    <>
                                                                                                        <label className="template_type_label" style={{ marginBottom: "0px" }}><Link target='_blank' to={`/descriptive-analytics-template?SUQ=${convertIdeaID}`} >Descriptive</Link></label>
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                                {
                                                                                                    this.state.dataBridgeType && this.state.dataBridgeType.label === "Diagnostic"
                                                                                                    ?
                                                                                                    <>
                                                                                                        <label onClick={(e)=>this.templateRedirect("Diagnostic")} className="template_type_label" style={{ marginBottom: "0px" }}>Diagnostic</label>    
                                                                                                        
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                                {
                                                                                                    this.state.dataBridgeType && this.state.dataBridgeType.label === "Prescriptive" 
                                                                                                    ?
                                                                                                    <>
                                                                                                        <label className="template_type_label" style={{ marginBottom: "0px" }}><Link target='_blank' to={`/diagnostic-or-prescriptive-template?SUQ=${convertIdeaID}`} >Prescriptive</Link></label>
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                                {
                                                                                                    this.state.dataBridgeType && this.state.dataBridgeType.label === "Predictive"
                                                                                                    ?
                                                                                                    <>
                                                                                                        <label className="template_type_label" style={{ marginBottom: "0px" }}><Link target='_blank' to={`/predictive-template?SUQ=${convertIdeaID}`} >Predictive</Link></label>
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                                } */}
                                                                                                
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                                <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-5">
                                                                            <label className="form-attribute non_mandatory_field_alignment">
                                                                                COO
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <input
                                                                                type="text"
                                                                                disabled
                                                                                placeholder="Provide Value"
                                                                                className="form-input disabled_input"
                                                                                value={this.state.COOMappingSelected}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                    <div className="row">
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                <label
                                                                                    style={{
                                                                                        color: "red",
                                                                                        verticalAlign: "bottom",
                                                                                    }}
                                                                                >
                                                                                    *
                                                                                </label>{" "}
                                                                                MUC ID
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <div className="custom_mucID_tool_tip_container">
                                                                                <Select
                                                                                    className=""
                                                                                    isSearchable={true}
                                                                                    value={this.state.mucIDSelectedOption}
                                                                                    options={this.renderMUCIDOptions()}
                                                                                    placeholder="Please choose"
                                                                                    onChange={(e) =>
                                                                                        this.handleMUCIDChange(e)
                                                                                    }
                                                                                    styles={
                                                                                        this.state.selectStyle
                                                                                            .mucIDSelectedOption
                                                                                    }
                                                                                    // onMouseOver={(e) => this.handleMUCIDOptionHover(e)}
                                                                                    // onFocus={(e) => e.target.blur()}
                                                                                    // onBlur={(e)=>this.handleMUCIOptionBlur()}
                                                                                />
                                                                                {/* {this.state.showMUCIDToolTipValue && <div className="custom_mucID_tool_tip">{this.state.showMUCIDToolTipValue}</div>} */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                    <div className="row">
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                <label
                                                                                    style={{
                                                                                        color: "red",
                                                                                        verticalAlign: "bottom",
                                                                                    }}
                                                                                >
                                                                                    *
                                                                                </label>{" "}
                                                                                Shown to Client
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={this.state.sharedToClient}
                                                                                options={YESORNO}
                                                                                placeholder="Please choose"
                                                                                onChange={(e) => this.handleSharedToClientChange(e)}
                                                                                styles={this.state.selectStyle.sharedToClient}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    this.state.sharedToClient && this.state.sharedToClient.label === "Yes"
                                                                        ?
                                                                        <>

                                                                            <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-5" style={{ textAlign: "left" }}>
                                                                                        <div className="">
                                                                                            <label className="form-attribute" style={{verticalAlign: "bottom", marginBottom: 0,marginLeft:"10px"}}>Client Testimony&nbsp;</label>
                                                                                        </div>
                                                                                        <div className="">
                                                                                            <small style={{color:" rgb(147, 147, 147)", fontSize: "12px",marginLeft:"10px"}}>(Max Size: 5MB)</small>
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                    <div className="col-md-7">
                                                                                        <div className="">
                                                                                            <label style={{fontWeight: "normal", fontSize: "10px", fontStyle: "italic",color: "red"}}>[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                                                                        </div>
                                                                                        <div className="row d_flex_align_items_center">
                                                                                            {
                                                                                                this.state.CTestimonyFileName
                                                                                                    ?
                                                                                                    <>
                                                                                                        <div className="col-md-2">
                                                                                                            <div className="">
                                                                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "5px 8px" }} onClick={(e) => this.deleteClientTestimonyFile()}><i className="fa fa-trash" aria-hidden="true"></i></button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    <div className="col-md-3">
                                                                                                        <label htmlFor="client-attachment" className="client_testimony_upload_btn">
                                                                                                            &nbsp;&nbsp;+&nbsp;&nbsp;
                                                                                                        </label>
                                                                                                        <input
                                                                                                            style={{ display: "none" }}
                                                                                                            id="client-attachment"
                                                                                                            type="file"
                                                                                                            accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                                                                            onChange={(event) => this.uploadClientAttachment(event, "clientTestimony")}
                                                                                                        />
                                                                                                        
                                                                                                    </div>
                                                                                            }

                                                                                            <div className="col-md-9 client_testimoney_filename">
                                                                                                <label className={this.state.CTestimonyFileName && this.state.CTestimonyFileName.length > 50 ? "upload_file_text_overflow":""}>{this.state.CTestimonyFileName}</label>
                                                                                            </div>


                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }


                                                                <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                    <div className="row">
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                <label
                                                                                    style={{
                                                                                        color: "red",
                                                                                        verticalAlign: "bottom",
                                                                                    }}
                                                                                >
                                                                                    *
                                                                                </label>{" "}
                                                                                Shown to GOL
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={this.state.sharedToGOL}
                                                                                options={YESORNO}
                                                                                placeholder="Please choose"
                                                                                onChange={(e) => this.handleSharedToGOLChange(e)}
                                                                                styles={this.state.selectStyle.sharedToGOL}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="" style={{ textAlign: "left", margin: "15px 0px" }}>
                                                                    <div className="row">
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                <label
                                                                                    style={{
                                                                                        color: "red",
                                                                                        verticalAlign: "bottom",
                                                                                    }}
                                                                                >
                                                                                    *
                                                                                </label>{" "}
                                                                                DB Tool Used
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={this.state.dbToolUsed}
                                                                                options={DOBTOOLS}
                                                                                placeholder="Please choose"
                                                                                onChange={(e) => this.handledbToolUsedChange(e)}
                                                                                styles={this.state.selectStyle.dbToolUsed}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                    <div className="row">
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{ verticalAlign: "bottom", marginBottom: -5, }}
                                                                            >
                                                                                <label
                                                                                    style={{
                                                                                        color: "red",
                                                                                        verticalAlign: "bottom",
                                                                                    }}
                                                                                >
                                                                                    *
                                                                                </label>{" "}
                                                                                Process Owner OHR
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <input
                                                                                type="number"
                                                                                defaultValue={
                                                                                    this.state.processOwnerOHR
                                                                                }
                                                                                placeholder="Provide Value"
                                                                                onChange={(e) =>
                                                                                    this.handleProcessOwnerChange(e)
                                                                                }
                                                                                onCut={(e) =>
                                                                                    this.handleProcessOwnerOhronCut(e)
                                                                                }
                                                                                // onBlur={(e) =>
                                                                                //     this.handleProcessOwnerChange(e)
                                                                                // }
                                                                                className="textarea-css form-attribute hide_numeric_input_arrows"
                                                                                style={
                                                                                    this.state.selectStyle.processOwnerOHR
                                                                                }
                                                                                onKeyDown={(evt) =>
                                                                                    (evt.key === "." ||
                                                                                        evt.key === "e" ||
                                                                                        evt.key === "E" ||
                                                                                        evt.key === "+" ||
                                                                                        evt.key === "-") &&
                                                                                    evt.preventDefault()
                                                                                }
                                                                                onWheel={(e) => e.target.blur()}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                    <div className="row">
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                <label
                                                                                    style={{
                                                                                        color: "red",
                                                                                        verticalAlign: "bottom",
                                                                                    }}
                                                                                >
                                                                                    *
                                                                                </label>{" "}
                                                                                Process Owner Name
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <input
                                                                                type="text"
                                                                                value={this.state.processOwnerName}
                                                                                placeholder="Provide Value"
                                                                                className="form-input disabled_input"
                                                                                style={
                                                                                    this.state.selectStyle
                                                                                        .processOwnerName
                                                                                }
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    this.state.sharedToClient && this.state.sharedToClient.label === "Yes"
                                                                        ?
                                                                        <>

                                                                            <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                                <div className="row">
                                                                                    <div className="col-md-5">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: -5,
                                                                                            }}
                                                                                        >
                                                                                            <label
                                                                                                style={{
                                                                                                    color: "red",
                                                                                                    verticalAlign: "bottom",
                                                                                                }}
                                                                                            >
                                                                                                *
                                                                                            </label>{" "}
                                                                                            Client Name
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-7">
                                                                                        <input
                                                                                            type="text"
                                                                                            onChange={(e) => this.handleDBClientNameChange(e.target.value)}
                                                                                            value={this.state.DB_clientName}
                                                                                            className="textarea-css form-attribute"
                                                                                            style={this.state.selectStyle.DB_clientName}
                                                                                            placeholder="Provide value"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                                <div className="row">
                                                                                    <div className="col-md-5">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: -5,
                                                                                            }}
                                                                                        >
                                                                                            <label
                                                                                                style={{
                                                                                                    color: "red",
                                                                                                    verticalAlign: "bottom",
                                                                                                }}
                                                                                            >
                                                                                                *
                                                                                            </label>{" "}
                                                                                            Client Role
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-7">
                                                                                        <input
                                                                                            type="text"
                                                                                            value={this.state.DB_clientRole}
                                                                                            placeholder="Provide value"
                                                                                            onChange={(e) => this.handleSharedToClientRoleTextChange(e.target.value)}
                                                                                            className="textarea-css form-attribute"
                                                                                            style={this.state.selectStyle.DB_clientRole}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    this.state.sharedToGOL && this.state.sharedToGOL.label === "Yes"
                                                                        ?
                                                                        <>
                                                                            <div className="" style={{ textAlign: "left", marginBottom: "20px" }}>
                                                                                <div className="row">
                                                                                    <div className="col-md-5">
                                                                                        <label className="form-attribute" style={{ verticalAlign: "bottom", marginBottom: -5, }}>
                                                                                            <label style={{ color: "red", verticalAlign: "bottom" }}>*</label>{" "}
                                                                                            GOL OHR
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-7">
                                                                                        <input
                                                                                            type="number"
                                                                                            defaultValue={this.state.DB_GOLOHR}
                                                                                            placeholder="Provide value"
                                                                                            onChange={(e) => this.handleDBGOLOHRChange(e)}
                                                                                            // onBlur={(e) => this.handleDBGOLOHRChange(e)}
                                                                                            onCut={(e) => this.handleDBGOLOhronCut(e)}
                                                                                            className="textarea-css form-attribute hide_numeric_input_arrows"
                                                                                            style={this.state.selectStyle.DB_GOLOHR}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                                <div className="row">
                                                                                    <div className="col-md-5">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: -5,
                                                                                            }}
                                                                                        >
                                                                                            <label
                                                                                                style={{
                                                                                                    color: "red",
                                                                                                    verticalAlign: "bottom",
                                                                                                }}
                                                                                            >
                                                                                                *
                                                                                            </label>{" "}
                                                                                            GOL Name
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-7">
                                                                                        <input
                                                                                            type="text"
                                                                                            value={this.state.DB_GOLNAME}
                                                                                            placeholder="Provide value"
                                                                                            disabled={true}
                                                                                            className="form-input disabled_input"
                                                                                            style={this.state.selectStyle.DB_GOLNAME}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </>
                                                                        :
                                                                        null
                                                                }


                                                                {
                                                                    this.state.dbToolUsed && this.state.dbToolUsed.label === "Others" ?
                                                                        <>
                                                                            <div className="">
                                                                                <div className="row">
                                                                                    <div className="col-md-5 text-left">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: -5,
                                                                                            }}
                                                                                        >
                                                                                            <label
                                                                                                style={{
                                                                                                    color: "red",
                                                                                                    verticalAlign: "bottom",
                                                                                                }}
                                                                                            >
                                                                                                *
                                                                                            </label>{" "}
                                                                                            Other DB Tool Used
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-7">
                                                                                        <input
                                                                                            type="text"
                                                                                            value={this.state.otherDBToolUsedText}
                                                                                            placeholder="Provide value"
                                                                                            onChange={(e) =>
                                                                                                this.handleOtherDBToolUsedChange(
                                                                                                    e.target.value
                                                                                                )
                                                                                            }
                                                                                            className="textarea-css form-attribute"
                                                                                            style={
                                                                                                this.state.selectStyle
                                                                                                    .otherDBToolUsedText
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }





                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    
                                                    this.state.ideaItem.TransformationLeverName === "Data" && this.state.ideaItem.IdeaId && templateType
                                                    && this.state.displayDBFields
                                                    ?
                                                    <>
                                                        <DisplayTemplate IdeaId={this.state.ideaItem.IdeaId} templateType={templateType} />
                                                    </>
                                                    :
                                                    null
                                                }

                                            </>

                                            :
                                            null
                                    }
                                    {/* .............DB Fields starts here ends here............. */}





                                    {
                                        this.state.ideaItem.CertificationLevelName === "Lean"
                                            ?
                                            <>
                                                <div style={{borderTop:"1px solid lightgrey", marginTop:"20px",paddingTop:"20px"}} />
                                                <FPNAGOL IdeaId={this.state.ideaItem.IdeaId} viewIdea={false}
                                                    vdSelected={this.state.impactOptionSelected ? this.state.impactOptionSelected.label : this.state.ideaItem.VDType}
                                                    fetchPPTDeck={this.pullPPTDeck}
                                                    fetchClientDocName={this.pullClientDocName}
                                                    fetchClientDocType={this.pullClientDocType}
                                                    fetchClientDocContent={this.pullClientDocContent}
                                                    fetchGolDocName={this.pullgolFileName}
                                                    fetchGolDocType={this.pullgolFileType}
                                                    fetchGolDocContent={this.pullgolContent}
                                                    fetchfpnaDocName={this.pullfpnafileName}
                                                    fetchfpnaDocType={this.pullfpnafileType}
                                                    fetchfpnaDocContent={this.pullfpnafileContent}
                                                    fetchClientName={this.pullClientName}
                                                    fetchClientRole={this.pullClientRole}
                                                    fetchClientSignOffDate={this.pullClientSignOff}
                                                    fetchFpnaName={this.pullFpnaName}
                                                    fetchGolName={this.pullGolName}
                                                    fetchFpnaOhr={this.pullFpnaOhr}
                                                    fetchGolOhr={this.pullGolOhr}
                                                    fetchFileDetails={this.pull_fileDetails}
                                                    sendBitoFPNA={this.state.businessImpactValueArray}
                                                    sendIsFpnaRequired={this.state.isFpnaRequired}
                                                />
                                            </>

                                            :
                                            null
                                    }

                                    {
                                        this.state.ideaItem.CertificationLevelName === "Lean"
                                            ?
                                            <>
                                                <ValueDelivery func={this.pull_data} viewIdea={false}
                                                    vdType={this.handleVDTypeChange}
                                                    IdeaId={this.state.ideaItem.IdeaId}
                                                    fetchMonetizationFileDetails={this.pull_file_data}
                                                    fetchMonetizationFpnaName={this.pull_monetization_fpna_name}
                                                    fetchMonetizationSowID={this.pull_monetization_sowId}
                                                    bbMonetizationTotal={this.pullMonetizedTotal}
                                                    monetizableProject={this.pull_monetizableProject}
                                                    monetizableStatus={this.pull_monetizableStatus}
                                                    fetchFPAOHRforIncreaseInRevenue={this.fetchFPAOHRforIncreaseInRevenue}
                                                    bbMonetizationApproval={false}
                                                    postFpnaValid={false}
                                                    fetchDeletedBIRows={this.remove_bi_data_after_delete}
                                                    fetchMonetizationGOLName={this.pullMonetizationGOLName}
                                                    fetchMonetizationGOLOhr={this.pullMonetizationGOLOhr}
                                                    fetchMonetizationFpnaOhr={this.pull_monetization_fpna_ohr}
                                                    fetchCategoryNotListed={this.pullCategoryNotListed}
                                                />
                                                
                                            </>
                                            :
                                            null
                                    }



                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 0 }}>&nbsp;</p>
                                    <div style={{ borderBottom: '1px solid lightgrey', marginTop: -10, marginBottom: 15 }} />
                                    <div className="btn-group">
                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e)=>this.updateIdea(e)}>Submit</button>
                                        <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.cancelEvent}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            this.state.speechModal
                                ?
                                <UpdatedSpeech modalOpen={true}
                                    onUpdateCancelRequest={this.handleUpdateTranscriptCancelRequest}
                                    modalId={this.state.modalIndex}
                                    closeModalFunction={this.closeModalFunction}
                                    previousText={this.state.Comments}
                                />
                                :
                                null
                        }


                        {this.state.showHrefModal ? (
                            <>
                                <div className="custom_modal href_modal">
                                <div className="modal_body href_modal_body">
                                    <div className="href_modal_dialog">
                                    <p>
                                        To add a new category please raise a Helpmate
                                        ticket, Click here to View SOP
                                    </p>
                                    <div className="href_modal_body_buttons">
                                        <div className="btn-group href_modal_body_buttons">
                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3">
                                            <a
                                            href="https://genpactonline.sharepoint.com/:b:/r/sites/LeanDigitalTransformation/Documents/KM%20Page%20-%202021/Certification%20Guidelines,%20SOPs%20%26%20Handbooks/Certification%20guidelines,%20SOPs%20%26%20Handbooks/Systems%20%26%20Tools/Helpmate-Data%20Related%20Request/ProGEAR%20Value%20Delivery%20Category%20Addition%20SOP.pdf?csf=1&web=1&e=5kKbFg"
                                            style={{ color: "#FFF" }}
                                            target="_blank"
                                            >
                                            Click Here
                                            </a>
                                        </button>
                                        <button
                                            className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6"
                                            onClick={(e) =>
                                            this.setState({ showHrefModal: false })
                                            }
                                        >
                                            Cancel
                                        </button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </>
                        ) : null}







                    </div>
                </div>
            </section>
        )




    }
}

export default UpdateIdeaPage;






