import React, { Component } from 'react'
import { GET_IDEA_URL, APPROVED, UPDATE_IDEA_URL, PENDING, SENT_BACK, REJECTED, GET_METADATA, DATA_BRIDGE_CAMPAIGN,REPLICABILITYOPTIONS, X_POLLINATION_CAMPAIGN, ACCESS_TOKEN, PROJECT_VIEW, COMPLETED_STATUS, AWAITING_MBB_APPROVAL, AWAITING_LDT_APPROVAL, PPT_CONTENT, SIGNOFF_CONTENT, USER_DATA, REQUEST_CANCELLATION, OTHER_REGIONS, SUBMIT_IDEA_URL, BI_FORM_DATE, AWAITING_QAULITY_CHECK_REVIEW, DISPLAYPROJECTTHEME, YESORNO, SOLUTIONCOMPLEXITY, AIFIRST, AI_DOC_LINK, MUCID, modelOperationOptions, sustenanceMetricFreqOptions, DATABRIDGETYPES, DOBTOOLS, REVIEW_IDEA_URL } from '../assets/constants/constants';
import Toast from 'light-toast';
import Checksum from "../utils/Checksum";
import Select from 'react-select';
import { selectValidation as selectStyle, textBoxValidation } from "../utils/FieldValidation";
import ValueDelivery from '../utils/ValueDelivery';
import FPNAGOL from '../utils/FPNAGOL';
import DisplayTemplate from '../utils/DisplayTemplates';
import { Link } from "react-router-dom";

export class AdminEditPage extends Component {
    sessionData = JSON.parse(localStorage.getItem(USER_DATA));
    accessToken = localStorage.getItem(ACCESS_TOKEN);
    constructor(props) {
        super(props)

        const isApprovalPage = `${this.props.location.approval}`;
        let isViewAllIdea = this.props.location.isViewAllIdea;
        let isViewAllProject = this.props.location.isViewAllProject;
        let propsData = this.props.location;
        this.state = {
            ideaItem: {},
            WorkflowStatus: "",
            Comment: "",
            accountName: "",
            campaignName: "",
            serviceLineName: "",
            dataBridgeCampaign: false,
            xPollinationCampaign: false,
            ideaValidation: false,
            showAdditionalDetails: false,
            showFinalAdditionalDetails: false,
            approvalPage: isApprovalPage,
            isViewAllIdea: isViewAllIdea,
            propsData: propsData,
            isViewAllProject: isViewAllProject,
            reviewComments: [],
            businessImpactMasterList: [],
            businessImpactValueArray: [],
            summaryArray: [],
            fteTotal: 0,
            dollarTotal: 0.0,
            commentLog: false,
            cancelRequest: false,
            selectStyle: {},
            adminUserList: [],
            admin: false,
            adminOHR: '',
            accountList: [],
            regionList: [],
            subRegionList: [],
            countryList: [],
            verticalList: [],
            subVerticalList: [],
            blackbeltList: [],
            mbbList: [],
            ldaList: [],
            campaignList: [],
            dataBridgeProjectThemeList: [],
            digitalProjectTypeList: [],
            digitalProjectTypeSelectedOptions: null,
            certificationLevelsList: [],
            certLevelsSelectedOption: null,
            certificationWorkTypeList: [],
            certWorkTypeSelectedOption: null,
            transformationLeversList: [],
            transSelectedOption: null,
            subCampaignList: [],
            serviceLineList: [],
            subServiceLineList: [],
            serviceLineList: [],
            subServiceLineList: [],
            accountId: 111,
            accountName: "",
            blackBeltUserId: 111,
            blackBeltUserName: "",
            blackBeltUserEmail: "",
            mbbName: "",
            mbbOHR: "",
            lda: "",
            ldaOHR: "",
            campaignTypeId: 111,
            campaignTypeText: "",
            dataBridgeProjectTheme: "",
            subCampaignName: "",
            regionId: 111,
            regionManual: "",
            regionManualId: 111,
            regionText: "",
            regionSelectedOption: null,
            subRegionSelectedOption: null,
            countrySelectedOption: null,
            verticalSelectedOption: null,
            subVerticalSelectedOption: null,
            blackBeltSelectedOption: null,
            mbbSelectedOption: null,
            ldaSelectedOption: null,
            databridgeProjectThemeOption: null,
            campaignSelectedOption: null,
            dataBridgeSelectedOption: null,
            subCampaignSelectedOption: null,
            verticalId: 111,
            verticalManual: "",
            verticalManualId: 111,
            subVerticalManual: "",
            requestForIdea: false,
            subRegion: "",
            country: "",
            serviceLineSelectedOption: null,
            subServiceLineSelectedOption: null,
            workFlowStatusList: [],
            workFlowStatusSelectedOption: null,
            approvalSelectedOption: null,
            LDTLeadSelectedOption: null,
            leadNameList: [],
            methodologyList: [],
            methodologySelectedOptions: null,
            replicaIndexOption: [
                {
                    value: "1",
                    label: "1"
                },
                {
                    value: "2",
                    label: "2"
                },
                {
                    value: "3",
                    label: "3"
                },
                {
                    value: "4",
                    label: "4"
                },
                {
                    value: "5",
                    label: "5"
                }
            ],
            replicaIndex: 0,
            otherTools: "",
            finalComments: '',
            comment: '',
            IsBestInClass: false,
            IsZeroImpactProject: false,
            IsSharedToSixSigma: false,
            digitalProjectEnabled: false,
            workTypeEnabled: false,
            base64File1: "",
            base64File2: "",
            base64File1: "",
            base64File2: "",
            base64File3: "",
            base64File4: "",
            filename1: "",
            filename2: "",
            filename3: "",
            filename4: "",
            fileType1: "",
            fileType2: "",
            fileType3: "",
            fileType4: "",
            sourceId: "",
            flagBIFTE: true,
            FinalImpactFTE: 0.0,
            FinalImpactDollar: 0.0,
            Role: "",
            finalApproveOptionsValue: [{
                WorkflowStatusId: 1,
                WorkflowStatusName: COMPLETED_STATUS
            },
            {
                WorkflowStatusId: 2,
                WorkflowStatusName: "Rejected"
            },
            {
                WorkflowStatusId: 3,
                WorkflowStatusName: "Send Back"
            },
            ],
            showDigital: false,
            rolesUpdated: false,
            filteredImpactTypeState: [],
            filteredImpactTypeSelectedOption: null,
            filteredMonetizableTypeState: [],
            filteredMonetizableSelectedOption: null,
            filteredBISubCategoryState: [],
            filteredBISubCategorySelectedOption: null,
            ideaCreatedDate: "",
            biFormDate: BI_FORM_DATE,
            showHistoricalBI: false,
            sortedBiImpactMasterList: [],
            showHrefModal: false,
            zeroImpactForLastestBI: false,
            zeroImpact: true,
            biMasterListAll: [],

            showClientFields: false,
            showGOLFields: false,

            golFilename: "",
            golContent: "",
            golFileType: "",
            golOhr: "",
            golEmail: "",
            golFullname: "",

            fpnaFilename: "",
            fpnaContent: "",
            fpnaFileType: "",
            fpnaName: "",
            fpnaEmail: "",
            fpnaOhr: "",
            fpnaApprovalAttachedSelectedOption: null,
            fpnaApprovalSelectedOptionObj: "",

            clientName: "",
            clientRole: "",
            clientSignOffDate: "",
            clientApprovalEmailSelectedOption: null,
            clientApprovalEmailSelectedOptionObj: null,
            signOffFilename: "",
            signOffFileType: "",

            IsVDType: "",
            showBBFieldsValue: "",
            showCheckBox: false,
            signOffCheckbox: "",
            checkBoxFalseSelected: false,
            appropriateAttachmentValue: [],
            appropriateAttachment: false,
            clientApprovalOptions: [
                { value: '0', label: 'Yes' },
                { value: '1', label: 'No' },
            ],
            monetizableValues: [
                {
                    value: 0,
                    label: "Improvement in transaction based billing"
                },
                {
                    value: 1,
                    label: "Increase in billable HC"
                },
                {
                    value: 2,
                    label: "Increase in billable hours"
                },
                {
                    value: 3,
                    label: "Revenue from digital deployment"
                },
                {
                    value: 4,
                    label: "Revenue from special projects"
                },
                {
                    value: 5,
                    label: "Value share from improvement project"
                },
                {
                    value: 6,
                    label: "Fixed Fee"
                },
            ],
            postMonetizableOptions: [
                {
                    value: 1,
                    label: "Discussion to be initiated with Client"
                },
                {
                    value: 2,
                    label: "Discussion ongoing with Client"
                },
                {
                    value: 3,
                    label: "Rejected by Client"
                },
                {
                    value: 4,
                    label: "Approved by Client"
                },

            ],
            monetizableProjectSelectedOption: null,
            monetizableCheckbox: null,
            monetizableStatus: false,
            showClientApprovalFields: false,


            SowID: "",
            IsVDMonetizable: [],
            monetizableStatus: "",
            FPnAOHRMonetization: "",
            FPnANameMonetization: "",
            FPnAEmailMonetization: "",
            FPnAFileNameMonetization: "",
            FPnAFileTypeMonetization: "",
            FPnAFileMonetization: "",
            IsSampleCheck: false,
            chatGPTTheme: false,
            chatGptOptions: [
                {
                    value: 0,
                    label: "Predict The Next Best Action"
                },
                {
                    value: 1,
                    label: "Automated Narrative Building and Translation"
                },
                {
                    value: 2,
                    label: "Data Extraction and Leakage Prevention"
                },
                {
                    value: 3,
                    label: "Coding Effort Reduction"
                },
            ],
            chatGptSelectedOption: [],

            FPnAOHRMonetizationInput: "",
            FPnANameMonetizationInput: "",
            SowIDInput: "",
            digitalSelected: [],
            impactTagChangeSelected: "",
            deliveryCategorizedSelect: "",
            deliveryComputedCorrectlySelected: "",
            signOffDeliverySelected: "",
            qcCommentLog: false,
            qcComments: [],
            impactOptions: [
                {
                    value: 0,
                    label: "No Value Delivery"
                },
                {
                    value: 1,
                    label: "Value Delivered to Client"
                },
                {
                    value: 2,
                    label: "Value Delivered to Genpact"
                },
                {
                    value: 3,
                    label: "Value Delivered to Client + Genpact"
                }
            ],
            impactOptionSelected: [],
            potentialDollar: '',
            bbReview: [],
            bbReviewCheckbox: [],
            bbEditable: false,
            biThreshold: "",
            vrconditiontype: "",
            vrMapping: "",
            vrRegionMapping: "",
            leanProjectLastComment: "",
            lastQCComment: "",
            finalReviewComments: [],
            isAccountChange: false,
            qcCommentState: '',
            golFpnaFileDetails: [],
            impactTagged: [{
                impactTaggedValue: 0,
                impactTaggedLabel: "Yes"
            },
            {
                impactTaggedValue: 1,
                impactTaggedLabel: "No"
            }
            ],
            isFpnaRequired: false,
            displayProjectTheme: false,
            clusterData: [],
            isClusterSaveDisabled: true,
            displayParentAlpha: '',
            projectTypeSelectedOption: null,
            projectIDSelectedOption: null,

            // AI First states starts here
            IsIdeaReplicableSelectedOption: [],
            replicabilityIndexOptions: [
                {
                    value: 1,
                    label: "1",
                },
                {
                    value: 2,
                    label: "2",
                },
                {
                    value: 3,
                    label: "3",
                },
                {
                    value: 4,
                    label: "4",
                },
                {
                    value: 5,
                    label: "5",
                },
            ],
            solutionFeasibleSelectedOption: [],
            solutionComplexitySelectedOption: [],
            isAISelectedOption: [],
            AIBRD: "",
            AIReason: "",
            // ....DB states start here...
            ideasDB: [],
            ideasDBDoc: [],

            dataBridgeType: null,
            processOwnerName: "",
            processOwnerOHR: "",
            processOnwerEmail: "",
            mucIDSelectedOption: null,
            sharedToClient: [],
            DB_clientName: "",
            DB_clientEmail: "",
            DB_clientRole: "",
            sharedToGOL: [],
            DB_GOLOHR: "",
            DB_GOLNAME: "",
            DB_GOLEmail: "",
            dbToolUsed: null,
            otherDBToolUsedText: "",
            CTestimonyFileName: "",
            CTestimonyFileType: "",
            CTestimony: "",
            modalsOperationsSelectedOption: { value: 0, label: "Deployed in Client Environment" },
            trackingApplicableSelectedOption: { value: 0, label: 'Yes' },
            sustenanceMetricFreq: [],
            sustenanceMetrics:"",


            muciIDTaggedSelectedOption: [],
            dbThemeCapturedCorrectlySelectedOption: [],
            toolsCapturedCorrectlySelectedOption: [],
            modelDetailsCapturedSelectedOption: [],
            modelDeploymentDetailsSelectedOption: [],
            signOffMeetSelectedOption: [],
            monetizationDetailsCapturedSelectedOption: [],
            susDetailsOkSelectedOption: [],
            anyOtherIssueSelectedOption: [],
            exceptionAppliedSelectedOption: [],


            COOMapping: [],
            COOMappingSelected: "",
            mucIDOptionsfromMetaData: [],

            //LDT approval
            displayLDTCommentLog: false,
            LDTLogs: [],

            RecType: "",
            DPA: [],

            campaignInsight:"",

             // units and Segments
             Unit:"",
             Segment:"",
             unitsAndSegmentsList:[]


        }
        this.isExceedlimit = false;

    }

    async componentDidMount() {
        Toast.loading("Fetching Idea & Metadata...", () => { });
        await this.fetchIdea();
        await this.fetchMetadata();
        Toast.hide();
    }

    formatDateString(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });

    }

    formatDateStringWithTime(dateparam) {
        return dateparam.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        });
    }

    fetchIdea = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                }
            };
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&RecID=''&isItIdeaOrGenAI='Idea'`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("fetch idea", data)
                    const projectFilename = data.ResultSets.Table1[0].ProjectPPTFileName ? data.ResultSets.Table1[0].ProjectPPTFileName : "";
                    const signOffFilename = data.ResultSets.Table1[0].CustomerSignoffFileName ? data.ResultSets.Table1[0].CustomerSignoffFileName : "";
                    const projectFileType = data.ResultSets.Table1[0].ProjectPPTType ? data.ResultSets.Table1[0].ProjectPPTType : "";
                    const signOffFileType = data.ResultSets.Table1[0].CustomerSignoffType ? data.ResultSets.Table1[0].CustomerSignoffType : "";
                    this.setState({
                        ideaItem: data.ResultSets.Table1[0],
                        WorkflowStatus: data.ResultSets.Table1[0].WorkflowStatus,
                        Comment: data.ResultSets.Table1[0].Comment,
                        showSourceId: data.ResultSets.Table1[0].TransformationLeverName === "Digital" ? true : false,
                        projectDeckFilename: projectFilename,
                        signOffFilename: signOffFilename,
                        projectFileType: projectFileType,
                        signOffFileType: signOffFileType,
                        reviewComments: data.ReviewComments.Table1,
                        finalReviewComments: data.FinalReviewComments.Table1,
                        sourceId: data.ResultSets.Table1[0].SourceId,
                        campaignName: data.ResultSets.Table1[0].CampaignName,
                        dataBridgeCampaign: (data.ResultSets.Table1[0].CampaignName === DATA_BRIDGE_CAMPAIGN),
                        xPollinationCampaign: (data.ResultSets.Table1[0].CampaignName === X_POLLINATION_CAMPAIGN),
                        businessImpactValueArray: data.BIData ? (data.BIData.Table1 ? (data.BIData.Table1.length > 0 ? data.BIData.Table1 : []) : []) : [],
                        ideaCreatedDate: data.ResultSets.Table1[0].CreatedDate,
                        FinalImpactFTE: data.ResultSets.Table1[0].FinalImpactFTE,
                        FinalImpactDollar: data.ResultSets.Table1[0].FinalImpactDollar,

                        clientSignOffDate: data.ResultSets.Table1[0].CustSignOffDate ? new Date(data.ResultSets.Table1[0].CustSignOffDate).toLocaleDateString('en-CA').split('/').reverse().join('-') : null,
                        golOhr: data ? data.ResultSets.Table1[0].GOLOHR : "",
                        fpnaOhr: data ? data.ResultSets.Table1[0].FPnAOHR : null,
                        golFilename: data.ResultSets.Table1[0].GOLFileName,
                        golFileType: data.ResultSets.Table1[0].GOLFileType,
                        fpnaFileType: data.ResultSets.Table1[0].FPnAFileType,
                        fpnaName: data.ResultSets.Table1[0].FPnAFileName,
                        fpnaApprovalAttachedSelectedOption: data.ResultSets.Table1[0].IsFPnAApprovalAttached,
                        clientApprovalEmailSelectedOption: data.ResultSets.Table1[0].IsClientApprovalAttached,
                        clientName: data ? data.ResultSets.Table1[0].CustName : null,
                        clientRole: data ? data.ResultSets.Table1[0].CustDesgn : null,
                        golFullname: data ? data.ResultSets.Table1[0].GOLName : null,
                        fpnaName: data ? data.ResultSets.Table1[0].FPnAName : null,
                        replicaIndex: data ? data.ResultSets.Table1[0].ReplicabilityIndex : null,
                        IsVDType: data.ResultSets.Table1[0].VDType,
                        IsVDMonetizable: data.ResultSets.Table1[0].IsVDMonetizable,
                        showCheckBox: data.ResultSets.Table1[0].IsVDReviewed,
                        bbReviewCheckbox: data.ResultSets.Table1[0].IsVDReviewed,
                        bbReview: data.ResultSets.Table1[0].IsFPnAApprovalAttached,
                        monetizableStatus: data.ResultSets.Table1[0].MonetizationStatus,

                        digitalSelected: data.ResultSets.Table1[0].DigitalProjectType ? data.ResultSets.Table1[0].DigitalProjectType : "",
                        qcComments: data.QcComments.Table1 ? data.QcComments.Table1 : [],

                        impactTagChangeSelected: data.ResultSets.Table1[0].IsVDImpactTagged,
                        deliveryCategorizedSelect: data.ResultSets.Table1[0].IsVDCategorized,
                        deliveryComputedCorrectlySelected: data.ResultSets.Table1[0].IsVDComputed,
                        signOffDeliverySelected: data.ResultSets.Table1[0].IsVDClientSignOff,
                        FPnAFileNameMonetization: data.ResultSets.Table1[0].FPnAFileNameMonetization ? data.ResultSets.Table1[0].FPnAFileNameMonetization : "",
                        FPnAOHRMonetization: data.ResultSets.Table1[0].FPnAOHRMonetization,
                        FPnANameMonetization: data.ResultSets.Table1[0].FPnANameMonetization,
                        SowID: data.ResultSets.Table1[0].SowID,
                        potentialDollar: data.ResultSets.Table1[0].PotentialImpactDollar,
                        holdingAccount: data.ResultSets.Table1[0].HoldingAccount,
                        subVerticalSelectedOption: data.ResultSets.Table1[0].SubVerticalName,
                        qcCommentState: data.ResultSets.Table1[0].FinalComments,
                        clusterData: data.ClusterData.Table1 ? data.ClusterData.Table1 : [],

                        // AI First
                        isAI: data.ResultSets ? data.ResultSets.Table1[0].isAI : [],
                        IsIdeaReplicableSelectedOption: data.ResultSets.Table2 ? data.ResultSets.Table2[0].IsIdeaReplicable : "",
                        solutionFeasibleSelectedOption: data.ResultSets.Table2 ? data.ResultSets.Table2[0].IsSolnFeasible : "",
                        solutionComplexitySelectedOption: data.ResultSets.Table2 ? data.ResultSets.Table2[0].SolnComplexity : "",
                        AIBRD: data.ResultSets.Table2 ? data.ResultSets.Table2[0].LinkBRD : "",
                        AIReason: data.ResultSets.Table2 ? data.ResultSets.Table2[0].AIReason : "",


                        // DB
                        ideasDB: data.ResultSets.Table3 && data.ResultSets.Table3[0].Result === "NULL" ? [] : data.ResultSets.Table3[0],
                        ideasDBDoc: data.ResultSets.Table4 && data.ResultSets.Table4[0].Result === "NULL" ? [] : data.ResultSets.Table4[0],

                        dataBridgeType: data.ResultSets.Table1 ? data.ResultSets.Table1[0].DBProjectType : [],
                        mucIDSelectedOption: data.ResultSets.Table1 ? data.ResultSets.Table1[0].MUCIDTitle : "",

                        processOwnerName: data.ResultSets.Table1 ? data.ResultSets.Table1[0].ProcessOwner : "",
                        processOwnerOHR: data.ResultSets.Table1 ? data.ResultSets.Table1[0].ProcessOwnerOHR : "",

                        dbToolUsed: data.ResultSets ? data.ResultSets.Table3 ? data.ResultSets.Table3[0].DBTool : [] : [],
                        otherDBToolUsedText: data.ResultSets ? data.ResultSets.Table3 ? data.ResultSets.Table3[0].OtherDBTool : "" : "",

                        sharedToGOL: data.ResultSets ? data.ResultSets.Table3 ? data.ResultSets.Table3[0].IsSharedGOL : [] : [],
                        DB_GOLNAME: data.ResultSets ? data.ResultSets.Table3 ? data.ResultSets.Table3[0].GOL : [] : [],
                        DB_GOLOHR: data.ResultSets ? data.ResultSets.Table3 ? data.ResultSets.Table3[0].GOLOHR : [] : [],

                        sharedToClient: data.ResultSets ? data.ResultSets.Table3 ? data.ResultSets.Table3[0].IsSharedClient : [] : [],
                        DB_clientName: data.ResultSets ? data.ResultSets.Table3 ? data.ResultSets.Table3[0].ClientName : "" : "",
                        DB_clientRole: data.ResultSets ? data.ResultSets.Table3 ? data.ResultSets.Table3[0].ClientRole : "" : "",

                        CTestimonyFileName: data.ResultSets ? data.ResultSets.Table1 ? data.ResultSets.Table1[0].CTestimonyFileName : "" : "",
                        CTestimonyFileType: data.ResultSets.Table1 ? data.ResultSets.Table1[0].CTestimonyFileType : [],

                        trackingApplicableSelectedOption: data.ResultSets ? data.ResultSets.Table3 ? data.ResultSets.Table3[0].IsSustenance : [] : [],
                        sustenanceMetricFreq: data.ResultSets ? data.ResultSets.Table3 ? data.ResultSets.Table3[0].SustenanceFreq : [] : [],
                        modalsOperationsSelectedOption: data.ResultSets ? data.ResultSets.Table3 ? data.ResultSets.Table3[0].ModelSummary : [] : [],


                        //LDT
                        LDTLogs: data.LDTLogs ? data.LDTLogs.ResultSets ? data.LDTLogs.ResultSets.Table1 : [] : [],

                        
                        RecType: data.Templates.ResultSets.Table1 && data.Templates.ResultSets.Table1[0].Result === "NULL" ? "" : data.Templates.ResultSets.Table1[0].RecType,
                        DPA: data.Templates.ResultSets.Table2 && data.Templates.ResultSets.Table2[0].Result === "NULL" ? [] : data.Templates.ResultSets.Table2,
                    }, () => {

                        console.log("other db tools used  =>",this.state.otherDBToolUsedText)
                        if (this.state.ideaItem.ReplicabilityIndex) {
                            REPLICABILITYOPTIONS.map((item) => {
                              if (item.value === this.state.ideaItem.ReplicabilityIndex) {
                                console.log(item)
                                this.setState({ replicaIndex: {label:item.label, value:item.value} }, () => { 
                                    console.log("selected replicaindex =>",this.state.replicaIndex)
                                })
                              }
                            })
                        }


                        

                        if (data.ResultSets.Table3 && data.ResultSets.Table3[0].SustenanceMetric) {
                            let localSustenanceMetric = data.ResultSets.Table3[0].SustenanceMetric;
                            if (localSustenanceMetric) {
                              this.setState({ sustenanceMetrics: localSustenanceMetric }, () => { })
                            }
                        }
                        

                        if (this.state.ideaItem.VerticalManual) {
                            this.loadCOO();
                        }


                        if (this.state.LDTLogs) {
                            let LDTPostData = [];
                            this.state.LDTLogs.map((item, index) => {
                                if (item.IsQBR) {
                                    let localData = [...this.state.LDTLogs];
                                    localData[index].IsQBR = {
                                        value: 0,
                                        label: localData[index].IsQBR,
                                    };
                                    LDTPostData = localData;
                                }
                            });
                            this.setState({ LDTLogs: LDTPostData }, () => {
                                console.log(this.state.LDTLogs)
                            })
                        }
                        // from update-idea-page
                        if (this.state.dataBridgeType) {
                            DATABRIDGETYPES.map((item) => {
                                if (item.label === this.state.dataBridgeType) {
                                    this.setState({ dataBridgeType: item }, () => {
                                        console.log("db type =>", this.state.dataBridgeType)
                                    })
                                }
                            })
                        }

                        if (this.state.mucIDSelectedOption) {
                            MUCID.map((item) => {
                                if (item.label === this.state.mucIDSelectedOption) {
                                    this.setState({ mucIDSelectedOption: item }, () => { })
                                }
                            })
                        }
                        if (data.ResultSets.Table3 && data.ResultSets.Table3[0].DBTool) {
                            let localDBTool = data.ResultSets.Table3[0].DBTool;
                            if (localDBTool) {
                                DOBTOOLS.map((item) => {
                                    if (item.label === localDBTool) {
                                        this.setState({ dbToolUsed: item }, () => { })
                                    }
                                })
                            }
                        }
                        if (this.state.trackingApplicableSelectedOption) {
                            YESORNO.map((item) => {
                                if (item.label === this.state.trackingApplicableSelectedOption) {
                                    this.setState({ trackingApplicableSelectedOption: item }, () => { })
                                }
                            })
                        }
                        if (this.state.sharedToClient) {
                            YESORNO.map((item) => {
                                if (item.label === this.state.sharedToClient) {
                                    this.setState({ sharedToClient: item }, () => { })
                                }
                            })
                        }

                        if (this.state.sharedToGOL) {
                            YESORNO.map((item) => {
                                if (item.label === this.state.sharedToGOL) {
                                    this.setState({ sharedToGOL: item }, () => { })
                                }
                            })
                        }
                        if (this.state.modalsOperationsSelectedOption) {
                            modelOperationOptions.map((item) => {
                                if (item.label === this.state.modalsOperationsSelectedOption) {
                                    this.setState({ modalsOperationsSelectedOption: item }, () => { })
                                }
                            })
                        }
                        if (this.state.sustenanceMetricFreq) {
                            sustenanceMetricFreqOptions.map((item) => {
                                if (item.label === this.state.sustenanceMetricFreq) {
                                    this.setState({ sustenanceMetricFreq: item }, () => { })
                                }
                            })
                        }
                    

                       

                        // from dbspoc approval page
                        YESORNO.map((item) => {
                            if (item.label === this.state.ideasDB.IsMUCTag) {
                                this.setState({ muciIDTaggedSelectedOption: item }, () => { })
                            }
                            if (item.label === this.state.ideasDB.IsDBTheme) {
                                this.setState({ dbThemeCapturedCorrectlySelectedOption: item }, () => { })
                            }
                            if (item.label === this.state.ideasDB.IsToolCorrect) {
                                this.setState({ toolsCapturedCorrectlySelectedOption: item }, () => { })
                            }
                            if (item.label === this.state.ideasDB.IsModelCorrect) {
                                this.setState({ modelDetailsCapturedSelectedOption: item }, () => { })
                            }
                            if (item.label === this.state.ideasDB.IsModelDeploy) {
                                this.setState({ modelDeploymentDetailsSelectedOption: item }, () => { })
                            }
                            if (item.label === this.state.ideasDB.IsSignOff) {
                                this.setState({ signOffMeetSelectedOption: item }, () => { })
                            }
                            if (item.label === this.state.ideasDB.IsMonetization) {
                                this.setState({ monetizationDetailsCapturedSelectedOption: item }, () => { })
                            }
                            if (item.label === this.state.ideasDB.IsSPocSustenance) {
                                this.setState({ susDetailsOkSelectedOption: item }, () => { })
                            }
                            if (item.label === this.state.ideasDB.IsIssue) {
                                this.setState({ anyOtherIssueSelectedOption: item }, () => { })
                            }
                            if (item.label === this.state.ideasDB.IsException) {
                                this.setState({ exceptionAppliedSelectedOption: item }, () => { })
                            }
                        })


                        if (this.state.ideaItem.TransformationLeverName === "AI") {
                            this.setState({
                                transSelectedOption: {
                                    value: 0,
                                    label: "AI"
                                }
                            }, () => { })
                        }

                        if (this.state.isAI) {
                            YESORNO.map((item) => {
                                if (item.label.toLowerCase() === this.state.isAI.toLowerCase()) {
                                    this.setState({
                                        isAISelectedOption: {
                                            value: item.value,
                                            label: item.label,
                                        },
                                    }, () => { });
                                }
                            })
                        }


                       

                        if (this.state.IsIdeaReplicableSelectedOption === 0 || this.state.IsIdeaReplicableSelectedOption === 1 ||
                            this.state.IsIdeaReplicableSelectedOption === false
                            || this.state.IsIdeaReplicableSelectedOption === true
                        ) {
                            if (this.state.IsIdeaReplicableSelectedOption === 0 || this.state.IsIdeaReplicableSelectedOption === "0" || this.state.IsIdeaReplicableSelectedOption === false) {
                                this.setState({
                                    IsIdeaReplicableSelectedOption: {
                                        value: 0,
                                        label: "No",
                                    },
                                }, () => { });
                            }
                            if (this.state.IsIdeaReplicableSelectedOption === 1 || this.state.IsIdeaReplicableSelectedOption === "1" || this.state.IsIdeaReplicableSelectedOption === true) {
                                this.setState({
                                    IsIdeaReplicableSelectedOption: {
                                        value: 0,
                                        label: "Yes",
                                    },
                                }, () => { });
                            }
                        }

                        

                        this.state.solutionComplexitySelectedOption && SOLUTIONCOMPLEXITY.map((item, index) => {
                            if (item.label === this.state.solutionComplexitySelectedOption) {
                                this.setState({
                                    solutionComplexitySelectedOption: {
                                        value: item.value,
                                        label: item.label,
                                    },
                                }, () => { });
                            }
                        })


                        if (this.state.solutionFeasibleSelectedOption === 0 || this.state.solutionFeasibleSelectedOption === 1
                            || this.state.solutionFeasibleSelectedOption === false
                            || this.state.solutionFeasibleSelectedOption === true
                        ) {
                            if (this.state.solutionFeasibleSelectedOption === 0 || this.state.solutionFeasibleSelectedOption === "0" || this.state.solutionFeasibleSelectedOption === false) {
                                this.setState({
                                    solutionFeasibleSelectedOption: {
                                        value: 0,
                                        label: "No",
                                    },
                                }, () => { });
                            }
                            if (this.state.solutionFeasibleSelectedOption === 1 || this.state.solutionFeasibleSelectedOption === "1" || this.state.solutionFeasibleSelectedOption === true) {
                                this.setState({
                                    solutionFeasibleSelectedOption: {
                                        value: 0,
                                        label: "Yes",
                                    },
                                }, () => { });
                            }
                        }



                        if (this.state.clusterData && this.state.clusterData.length > 0) {
                            const targetRecID = this.state.ideaItem.LeanID;
                            const filteredData = this.state.clusterData.filter(item => item.RecAlpha === targetRecID);
                            console.log(filteredData)
                            if (filteredData && filteredData.length > 0 && filteredData !== undefined && filteredData !== null) {
                                this.setState({ displayParentAlpha: filteredData[0].ParentAlpha }, () => { })
                                let options = [];
                                options.push({ value: 0, label: filteredData[0].ParentAlpha })
                                this.setState({ projectIDSelectedOption: options }, () => { })

                                let options1 = [];
                                options1.push({ value: 0, label: "GB" })
                                this.setState({ projectTypeSelectedOption: options1 }, () => { })
                            } else {
                                this.setState({ displayParentAlpha: "" }, () => { })
                            }
                        } else {
                            this.setState({ displayParentAlpha: "" }, () => { })
                        }

                        if (this.state.ideaItem.CreatedDate) {
                            let created = new Date(this.state.ideaItem.CreatedDate);
                            let abc = new Date(DISPLAYPROJECTTHEME);
                            if (created.getTime() < abc.getTime()) {
                                //console.log("created date is lesser")
                                this.setState({ displayProjectTheme: true })
                            } else {
                                this.setState({ displayProjectTheme: false })
                                //console.log("created date is greater ")
                            }
                        }

                        if (this.state.finalReviewComments) {
                            var keys = Object.keys(this.state.finalReviewComments);
                            var last = keys[keys.length - 1];
                            let varFinalLastComment = this.state.finalReviewComments[last].Comment
                            this.setState({ leanProjectLastComment: varFinalLastComment }, () => { })
                        }

                        if (this.state.qcComments !== null && this.state.qcComments !== undefined) {
                            if (this.state.qcComments.length !== 0) {


                                var keys = Object.keys(this.state.qcComments);
                                //console.log("last comment for qc=>",this.state.qcComments);
                                var last = keys[keys.length - 1];
                                let varFinalLastComment = this.state.qcComments[this.state.qcComments.length - 1].Comment
                                this.setState({ lastQCComment: varFinalLastComment }, () => {
                                    //console.log("last comment for qc=>",this.state.lastQCComment);
                                })

                            }
                        }

                        this.state.clientApprovalOptions.map(item => {
                            if (item.label === this.state.IsVDMonetizable) {
                                this.setState({ IsVDMonetizable: item }, () => { })
                            }
                            if (item.label === this.state.impactTagChangeSelected) {
                                this.setState({ impactTagChangeSelected: item }, () => { })
                            }
                            if (item.label === this.state.deliveryCategorizedSelect) {
                                this.setState({ deliveryCategorizedSelect: item }, () => { })
                            }
                            if (item.label === this.state.deliveryComputedCorrectlySelected) {
                                this.setState({ deliveryComputedCorrectlySelected: item }, () => { })
                            }
                            if (item.label === this.state.signOffDeliverySelected) {
                                this.setState({ signOffDeliverySelected: item }, () => { })
                            }
                            if (item.label === this.state.bbReview) {
                                this.setState({ bbReview: item }, () => { })
                            }
                            if (item.label === this.state.bbReviewCheckbox) {
                                this.setState({ bbReviewCheckbox: item }, () => { })
                            }
                        })
                        if (this.state.ideaItem.TransformationLeverName === "Digital") {
                            this.setState({ digitalProjectEnabled: true })
                        }

                        // this.state.replicaIndexOption.map(item => {
                        //     if (parseInt(item.value) === parseInt(this.state.replicaIndex)) {
                        //         let options = {
                        //             value: item.value,
                        //             label: item.label
                        //         }
                        //         this.setState({ replicaIndex: options }, () => { })
                        //     }
                        // })

                        this.state.postMonetizableOptions.map(item => {
                            if (item.label === this.state.monetizableStatus) {
                                this.setState({ monetizableStatus: item }, () => {
                                    if (this.state.monetizableStatus.label === "Approved by Client") {
                                        this.setState({ showClientApprovalFields: true }, () => { })
                                    }
                                })
                            }
                        })

                        if (this.state.IsVDType === "Value Delivered to Client + Genpact") {
                            this.setState({ showClientFields: true, showGOLFields: true }, () => { })
                        }
                        if (this.state.IsVDType === "No Value Delivery") {
                            this.setState({ showClientFields: false, showGOLFields: false }, () => { })
                        }
                        if (this.state.IsVDType === "Value Delivered to Client") {
                            this.setState({ showClientFields: true, showGOLFields: false }, () => { })
                        }
                        if (this.state.IsVDType === "Value Delivered to Genpact") {
                            this.setState({ showClientFields: false, showGOLFields: true }, () => { })
                        }

                        this.state.impactOptions.map((item) => {
                            if (item.label === this.state.IsVDType) {
                                this.setState({ impactOptionSelected: item })
                            }
                        })

                        if (this.state.ideaItem.CampaignName === "Gen AI") {
                            this.setState({ chatGPTTheme: true }, () => {
                                if (this.state.chatGPTTheme) {
                                    let options = [];
                                    this.state.chatGptOptions.map(item => {
                                        if (item.label === this.state.ideaItem.SubCampaignName) {
                                            options.push({
                                                value: item.value,
                                                label: item.label
                                            })
                                            this.setState({ chatGptSelectedOption: options })
                                        }
                                    })

                                }
                            })
                        }

                        resolve();
                    });

                })
                .catch(error => {
                    console.log("error =>", error)
                    Toast.fail("Error while fetching idea. Please try again!", 3000, () => { });
                    resolve();
                });
        });
    }

    fetchMetadata = () => {
        return new Promise((resolve, reject) => {
            const param = {
                EntityType: "",
                EntityName: ""
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        businessImpactMasterList: data ? data.BIMasterDataVerseNew : [],
                        biMasterListAll: data ? data.BIMasterDataVerse : [],
                        adminUserList: data ? data.DataverseUserRole : [],
                        accountList: data ? data.Accounts.sort((a, b) => { return a.ACCOUNT > b.ACCOUNT ? 1 : -1; }) : [],
                        campaignList: data ? data.Campaigns : [],
                        serviceLineList: data ? data.ServiceLines : [],
                        subServiceLineList: data ? data.ServiceLines : [],
                        countryList: data ? data.Countries.sort((a, b) => { return a.COUNTRY > b.COUNTRY ? 1 : -1; }) : [],
                        dataBridgeProjectThemeList: data ? data.DataBridgeThemes : [],
                        subCampaignList: data ? data.SubCampaigns : [],
                        workFlowStatusList: data ? data.WorkflowStatus : [],
                        certificationLevelsList: data ? data.CertificationLevels : [],
                        certificationWorkTypeList: data ? data.WorkType : [],
                        transformationLeversList: data ? data.TransformationLevers : [],
                        methodologyList: data ? data.Methodologies : [],
                        digitalProjectTypeList: data ? data.DigitalProjectTypes : [],

                        biThreshold: data.BIQCThreshold ? data.BIQCThreshold[0] ? data.BIQCThreshold[0].pg_threshold : 0 : 0,
                        vrconditiontype: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_vrconditiontype : [] : [],
                        vrVerticalMapping: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_vertical ? data.BIQCVRMapping[0].pg_vertical.split("|") : "ALL" : "ALL" : "ALL",
                        vrRegionMapping: data.BIQCVRMapping ? data.BIQCVRMapping[0] ? data.BIQCVRMapping[0].pg_region ? data.BIQCVRMapping[0].pg_region.split("|") : "ALL" : "ALL" : "ALL",
                        COOMapping: data.COO ? data.COO : [],
                        mucIDOptionsfromMetaData: data.MUCID ? data.MUCID : []

                    }, () => {

                        if (this.state.ideaItem.CampaignName && this.state.campaignList) {
                            this.state.campaignList.map((campaign) => {
                                if (campaign.CampaignName === this.state.ideaItem.CampaignName) {
                                    this.setState({ campaignInsight: campaign.CampaignDescr }, () => { })
                                }
                            })
                        }
                        if (this.state.mucIDOptionsfromMetaData) {
                            const filteredData = this.state.mucIDOptionsfromMetaData.filter(item => item.pg_databridgeusecasecalculated !== null && item.pg_databridgeusecasecalculated !== undefined && item.pg_databridgeusecasecalculated !== '');
                            this.setState({ mucIDOptionsfromMetaData: filteredData }, () => { })
                            if (this.state.ideaItem.MUCIDTitle) {
                                let localMUCIDSelected = this.state.ideaItem.MUCIDTitle;
                                if (localMUCIDSelected) {
                                    this.state.mucIDOptionsfromMetaData && this.state.mucIDOptionsfromMetaData.map(item => {
                                        if (item.pg_databridgeusecasecalculated === localMUCIDSelected) {
                                            let option = { value: 0, label: item.pg_databridgeusecasecalculated }
                                            this.setState({ mucIDSelectedOption: option }, () => { })
                                        }
                                    })
                                }
                            }


                        }

                        if (this.state.COOMapping) {
                            this.loadCOO();
                        }

                        this.state.digitalProjectTypeList.map((item) => {
                            if (item.DigitalProjectTypeName === this.state.ideaItem.DigitalProjectType) {
                                const options = {
                                    value: item.ProjectTypeId,
                                    label: item.DigitalProjectTypeName
                                }
                                this.setState({ digitalSelected: options }, () => {
                                    console.log(this.state.digitalSelected)
                                })
                            }
                        })


                        this.state.adminUserList.map((item) => {
                            if (parseInt(this.sessionData.profile.oHRId) === parseInt(item.pg_ohr)) {
                                this.setState({ admin: true, adminOHR: parseInt(item.pg_ohr) });
                                return false;
                            }
                            return false;
                        });
                        this.setSelectedOptions();

                        let filteredImpactType = this.state.businessImpactMasterList.map(item => item.pg_impacttype).filter(
                            (value, index, self) => self.indexOf(value) === index
                        )
                        filteredImpactType = Object.entries(filteredImpactType)
                        this.setState({ filteredImpactTypeState: filteredImpactType }, () => { })

                        let filtereedMonetizabletype = this.state.businessImpactMasterList.map(item => item.pg_monetizabletype).filter(
                            (value, index, self) => self.indexOf(value) === index
                        )
                        filtereedMonetizabletype = Object.entries(filtereedMonetizabletype)
                        this.setState({ filteredMonetizableTypeState: filtereedMonetizabletype }, () => { })

                        let filteredBiSubCategory = this.state.businessImpactMasterList.map(item => item.pg_bisubcategory).filter(
                            (value, index, self) => self.indexOf(value) === index
                        )
                        filteredBiSubCategory = Object.entries(filteredBiSubCategory)
                        this.setState({ filteredBISubCategoryState: filteredBiSubCategory }, () => { })


                        let data = [];
                        this.state.businessImpactMasterList.map((biItem, index) => {
                            data.push({});
                            this.state.businessImpactValueArray.map((item) => {
                                if (biItem.pg_impacttype === item.ImpactType && biItem.pg_monetizabletype === item.MonetizableType && biItem.pg_bisubcategory === item.BISubCategory) {
                                    data[index] = item;
                                }
                            })
                        });


                        resolve();
                    })
                })
                .catch(error => {
                    Toast.fail('Error occured while getting metadata idea. Please try again!', 3000, () => {
                        console.log(error);
                        console.log(JSON.stringify(error));
                        this.setState({
                            businessImpactMasterList: [],
                            serviceLineList: [],
                            subServiceLineList: [],
                        }, () => {
                            resolve();
                        })
                    });

                });
        });
    }

    fetchLDTLeadOptions = () => {
        return new Promise((resolve, reject) => {
            let entityType = "BB";
            let entityName = this.state.regionText;
            if (this.state.regionText === OTHER_REGIONS) {
                entityType = "BBOTHER";
                entityName = this.state.verticalManual.replace(" - ", "-");
            }
            const param = {
                EntityType: entityType,
                EntityName: entityName
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };
            fetch(GET_METADATA, requestOptions)
                .then(response => response.json())
                .then(data => {
                    Toast.hide();
                    this.setState({
                        leadNameList: data.DataverseUserRoles !== undefined ? data.DataverseUserRoles : [],
                    })
                }, () => {
                    let filteredLDTLeadList = this.state.leadNameList.filter((item) => {
                        const userRole = JSON.parse(item.pg_Role).pg_role;
                        return ((userRole === "LDT Lead") && (parseInt(this.state.ideaItem.OwnerOHR) !== parseInt(item.pg_ohr)));
                    });
                    if (this.state.ideaItem.WorkflowStatus === 'Pending') {
                        let ideaItem = this.state.ideaItem;
                        ideaItem.LDTId = filteredLDTLeadList[0].pg_ohr;
                        ideaItem.LDTLeadName = filteredLDTLeadList[0].pg_username;
                        this.setState({
                            ideaItem,
                            LDTLeadSelectedOption: {
                                label: filteredLDTLeadList[0].pg_username,
                                value: filteredLDTLeadList[0].pg_ohr
                            }
                        })
                    }
                    this.setState({
                        leadNameList: filteredLDTLeadList,
                    });
                    resolve();
                })
                .catch(error => {
                    Toast.hide();
                    Toast.fail('Error while getting LDT Lead metadata idea. Please try again!', 3000, () => {
                        console.log(JSON.stringify(error));
                    });
                });
        });
    }

    setSelectedOptions = () => {
        if (this.state.ideaItem.BlackBeltUserId) {
            this.setState({
                blackBeltSelectedOption: {
                    value: this.state.ideaItem.BlackBeltUserId,
                    label: this.state.ideaItem.BlackBeltUserName
                },
                blackBeltUserId: this.state.ideaItem.BlackBeltUserId,
                blackBeltUserName: this.state.ideaItem.BlackBeltUserName
            })
        }
        if (this.state.ideaItem.MBBOHR) {
            this.setState({
                mbbSelectedOption: {
                    value: this.state.ideaItem.MBBOHR,
                    label: this.state.ideaItem.MBBName
                },
                mbbOHR: this.state.ideaItem.MBBOHR,
                mbbName: this.state.ideaItem.MBBName
            })
        }

        if (this.state.ideaItem.LDAOHR) {
            this.setState({
                ldaSelectedOption: {
                    value: this.state.ideaItem.LDAOHR,
                    label: this.state.ideaItem.LDA
                },
                ldaOHR: this.state.ideaItem.LDAOHR,
                lda: this.state.ideaItem.LDA
            }, () => { })
        }
        this.state.accountList.map((item) => {
            if (this.state.ideaItem.AccountManual === item.ACCOUNT) {
                this.setState({
                    accountSelectedOption: {
                        label: item.ACCOUNT
                    },
                    //holdingAccount: item.ACCOUNT,
                    accountName: item.ACCOUNT
                }, () => {
                    this.fetchVerticalOptions();
                })
            }
            return 1;
        });
        this.state.campaignList.map((item) => {
            if (this.state.ideaItem.CampaignName === item.CampaignName) {
                this.setState({
                    campaignTypeText: item.CampaignName,
                    campaignSelectedOption: {
                        value: item.CampaignId,
                        label: item.CampaignName
                    }
                })
            }
            return 1;
        });
        if (this.state.dataBridgeProjectThemeList) {
            this.state.dataBridgeProjectThemeList.map((item) => {
                if (this.state.ideaItem.DataBridgeTheme === item.DBProjectThemeName) {
                    this.setState({
                        dataBridgeSelectedOption: {
                            value: item.ThemeId,
                            label: item.DBProjectThemeName
                        }
                    }, () => { })
                }
                return 1;
            });
        }
        this.state.subCampaignList.map((item) => {
            if (this.state.ideaItem.SubCampaignName === item.SubCampaignName) {
                this.setState({
                    subCampaignSelectedOption: {
                        value: item.SubCampaignId,
                        label: item.SubCampaignName
                    }
                })
            }
            return 1;
        });
        this.state.countryList.map((item) => {
            if (this.state.ideaItem.Country === item.COUNTRY) {
                this.setState({
                    countrySelectedOption: { label: item.COUNTRY },
                    country: item.COUNTRY,
                }, () => {
                    this.fetchRegionOptions();
                })
            }
            return 1;
        });
        //console.log("service line list =>",this.state.serviceLineList)
        this.state.serviceLineList.map((item) => {
            //console.log("idea item service line name =>",this.state.ideaItem.ServiceLineName)
            if (this.state.ideaItem.ServiceLineName === item.ServiceLineName) {
                this.setState({
                    serviceLineSelectedOption: {
                        value: item.ServiceLineId,
                        label: item.ServiceLineName
                    }
                },()=>{console.log("service line selected option is => ",this.state.serviceLineSelectedOption)})
            }
            return 1;
        });
        this.state.subServiceLineList.map((item) => {
            if (this.state.ideaItem.SubServiceLine === item.SubServiceLineName) {
                this.setState({
                    subServiceLineSelectedOption: {
                        value: item.SubServiceLineId,
                        label: item.SubServiceLineName
                    }
                })
            }
            return 1;
        });
        this.state.methodologyList.map((item) => {
            if (this.state.ideaItem.MethodologyName === item.MethodologyName) {
                this.setState({
                    methodologySelectedOptions: {
                        value: item.MethodologyTypeID,
                        label: item.MethodologyName
                    }
                })
            }
            return 1;
        });
        this.state.certificationLevelsList.map((item) => {
            if (this.state.ideaItem.CertificationLevelName === item.CertificationLevelName) {
                this.setState({
                    certLevelsSelectedOption: {
                        value: item.CertificationLevelId,
                        label: item.CertificationLevelName
                    }
                })
            }
            return 1;
        });
        this.state.transformationLeversList.map((item) => {
            if (this.state.ideaItem.TransformationLeverName === item.TransformationLeverName) {
                this.setState({
                    transSelectedOption: {
                        value: item.TransformationLeverId,
                        label: item.TransformationLeverName
                    }
                })
            }
            return 1;
        });

        this.state.workFlowStatusList.map((item) => {
            if (this.state.ideaItem.WorkflowStatus === item.WorkflowStatusName) {
                this.setState({
                    workFlowStatusSelectedOption: {
                        value: item.WorkflowStatusId,
                        label: item.WorkflowStatusName
                    }
                })
            }
            return 1;
        });
        this.state.finalApproveOptionsValue.map((item, index) => {
            if (this.state.ideaItem.ApprovalStatus === item.WorkflowStatusName) {
                this.setState({
                    approvalSelectedOption: {
                        value: item.WorkflowStatusId,
                        label: item.WorkflowStatusName
                    }
                })
            }
            return 1;
        });
        if (this.state.ideaItem) {
            this.setState({
                // replicaIndex: { value: this.state.ideaItem.ReplicabilityIndex, label: this.state.ideaItem.ReplicabilityIndex },
                otherTools: this.state.ideaItem.FinalOtherTools,
                Problem: this.state.ideaItem.Problem,
                Solution: this.state.ideaItem.Solution,
                ReplicationProjectId: this.state.ideaItem.ReplicationProjectId,
                IsBestInClass: this.state.ideaItem.IsBestInClass,
                IsZeroImpactProject: this.state.ideaItem.IsZeroImpactProject,
                IsSharedToSixSigma: this.state.ideaItem.IsSharedToSixSigma,
                LDTLeadSelectedOption: { label: this.state.ideaItem.LDTLeadName, value: this.state.ideaItem.LDTId }
            })
        }
    }

    saveEvent = () => {
        let redirectURL = "/review-page";
        if (this.state.approvalPage) {
            if (this.state.isViewAllProject) {
                redirectURL = "/view-all-projects-page";
                this.props.history.push({
                    pathname: redirectURL,
                    propsList: this.state.propsData
                })
            }
            else if (this.state.isViewAllIdea) {
                redirectURL = "/view-all-ideas-page";
                this.props.history.push({
                    pathname: redirectURL,
                    propsList: this.state.propsData
                })
            }
            else if (this.state.approvalPage === PROJECT_VIEW) {
                redirectURL = "/list-projects-page";
                this.props.history.push({
                    pathname: redirectURL,
                    propsList: this.state.propsData
                })
            } else {
                this.props.history.push({
                    pathname: redirectURL,
                    search: '?page=' + this.state.approvalPage,
                    propsList: this.state.propsData
                })
            }
        }
        else {
            this.props.history.push({
                pathname: redirectURL,
                propsList: this.state.propsData
            })
        }
    }

    showPPTContent = () => {
        Toast.loading("Fetching Project PPT Deck content...", () => { });
        this.fetchPPTContent()
            .then(() => {
                var fileData = this.state.projectDeckContent;
                var fileName = this.state.projectDeckFilename;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    showSignOffContent = () => {
        Toast.loading("Fetching Customer Sign-off content...", () => { });
        this.fetchSignoffContent()
            .then(() => {
                var fileData = this.state.signOffContent;
                var fileName = this.state.signOffFilename;
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = fileData;
                downloadLink.download = fileName;
                downloadLink.click();
                Toast.hide();
            });
    }

    renderAccountOptions = () => {
        let options = [];
        let accountName = "";
        this.state.accountList.map((item) => {
            accountName = item.ACCOUNT.toString().toLowerCase();
            if (!(accountName.startsWith("unidentified") || accountName.startsWith("unmapped") || accountName.startsWith("genpact"))) {
                options.push({
                    value: item.ACCOUNT_L2,
                    label: item.ACCOUNT
                })
            }
            return true;
        });
        return options;
    }

    renderVerticalOptions = () => {
        let options = [];
        const uniqueVerticals = [...new Map(this.state.verticalList.map(item => [item["VERTICAL"], item])).values()]
        uniqueVerticals.map((item) => {
            if (!(item.VERTICAL.toString().toLowerCase() === "ts")) {
                options.push({ value: item.VERTICAL, label: item.VERTICAL });
            }
            return true;
        });
        return options;
    }

    renderSubVerticalOptions = () => {
        let options = [];
        this.state.subVerticalList = [];
        this.state.verticalList.map((item) => {
            if (this.state.verticalSelectedOption.label === item.VERTICAL) {
                if (item.SUB_VERTICAL.toString().toLowerCase() !== "corporate" || this.state.accountName.toLowerCase().includes("support")) {
                    options.push({ value: item.SUB_VERTICAL, label: item.SUB_VERTICAL });
                    this.state.subVerticalList.push(item.SUB_VERTICAL);
                }
            }
            return true;
        });

        return options;
    }

    renderBlackBeltOptions = () => {
        // let options = [];
        // const filteredData = this.state.blackbeltList && this.state.blackbeltList.filter((item) => {
        //     const userRole = JSON.parse(item.pg_Role).pg_role;
        //     if (this.state.ownerOHR !== item.pg_ohr){
        //         return userRole === "BB" || userRole === "QA";
        //     }
        // });
        // options = filteredData.reduce((acc, item) => {
        //     if (!acc.some(option => option.value === item.pg_ohr)) {
        //         acc.push({ value: item.pg_ohr, label: item.pg_username });
        //     }
        //     return acc;
        // }, []);
        let options = [];
        this.state.blackbeltList.map((item) => {
            const userRole = JSON.parse(item.pg_Role).pg_role;
            if (userRole === "BB" || userRole === "QA") {
                if (this.state.ownerOHR !== item.pg_ohr) {
                    options.push({ value: item.pg_ohr, label: item.pg_username });
                }
            }
            return true;
        });
        return options;
    }

    renderMBBOptions = () => {
        let options = [];
        this.state.mbbList.map((item) => {
            const userRole = JSON.parse(item.pg_Role).pg_role;
            if (userRole === "MBB") {
                if (this.state.ownerOHR !== item.pg_ohr) {
                    options.push({ value: item.pg_ohr, label: item.pg_username });
                }
            }
            return true;
        });
        return options;
    }
    renderLeadOptions = () => {
        let options = [];
        const uniqueLeadList = [...new Map(this.state.leadNameList.map(item => [item.pg_ohr, item])).values()];

        uniqueLeadList.map((item) => {
            options.push({ value: item.pg_ohr, label: item.pg_username });
            return options;
        });
        return options;
    }

    renderMethodologyOptions = () => {
        let options = [];
        this.state.methodologyList.map((item) => {
            options.push({
                value: item.MethodologyId,
                label: item.MethodologyName
            })
            return options;
        });
        return options;
    }

    renderRegionOptions = () => {
        let options = [];
        if (this.state.regionList.length > 0) {
            this.state.regionList.map((item) => {
                options.push({ value: item.Region, label: item.Region });
                return true;
            });
        }
        return options;
    }
    renderSubRegionOptions = () => {
        let options = [];
        if (this.state.subRegionList.length > 0) {
            this.state.subRegionList.map((item) => {
                options.push({ value: item.Region, label: item.Region });
                return true;
            });
        }
        return options;
    }

    renderCountryOptions = () => {
        let options = [];
        this.state.countryList.map((item) => {
            options.push({ value: item.COUNTRY, label: item.COUNTRY });
            return true;
        });
        return options;
    }

    renderCampaignOptions = () => {
        let options = [];
        this.state.campaignList.map((item) => {
            options.push({ value: item.CampaignId, label: item.CampaignName });
            return true;
        });
        return options;
    }

    renderDataBridgeProjectThemeOptions = (e) => {
        let options = [];
        this.state.dataBridgeProjectThemeList.map((item) => {
            options.push({ value: item.ThemeId, label: item.DBProjectThemeName });
            return true;
        });
        return options;
    }

    renderSubCampaignOptions = () => {
        let options = [];
        this.state.subCampaignList.map((item) => {
            options.push({ value: item.SubCampaignId, label: item.SubCampaignName });
            return true;
        });
        return options;
    }

    renderServiceLineOptions = () => {
        let options = [];
        const uniqueServiceLineList = [...new Map(this.state.serviceLineList.map(item =>
            [item.ServiceLineName, item])).values()];
        uniqueServiceLineList.map((item) => {
            options.push({ value: item.ServiceLineId, label: item.ServiceLineName });
            return true;
        });
        return options;
    }

    renderSubServiceLineOptions = () => {
        let options = [];

        this.state.subServiceLineList.map((item) => {
            if (this.state.ideaItem.ServiceLineName === item.ServiceLineName) {
                options.push({ value: item.SubServiceLineId, label: item.SubServiceLineName });
                return true;
            }
            return true;
        });
        return options;
    }

    renderReplicaIndexOptions = () => {
        let options = [];
        this.state.replicaIndexOption.map((item) => {
            options.push({
                value: item.value,
                label: item.label
            })
            return options;
        });
        return options;
    }

    replicaIndexChangeHandler = (e) => {
        this.setState({
            replicaIndex: {
                value: e.value,
                label: e.label
            }
        });
    }

    handleAccountNameChange = (e) => {

        this.setState({
            verticalList: [],
            isAccountChange: true,
            verticalSelectedOption: null,
            subVerticalSelectedOption: null,
            regionSelectedOption: null,
            subRegionSelectedOption: null,
            countrySelectedOption: null,
            blackBeltSelectedOption: null,
            mbbSelectedOption: null,
            accountName: e.label,
            holdingAccount: e.value,
            accountSelectedOption: { label: e.label },
        }, () => {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.accountName = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj,
                isAccountChange: true
            });
            this.fetchVerticalOptions();
            this.fetchCountryOptions();
        }, () => {
            this.renderVerticalOptions();
            this.renderCountryOptions();
        });
    }

    handleVerticalChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.verticalManual = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            subVerticalSelectedOption: null,
            verticalManual: e.label,
            verticalSelectedOption: {
                value: e.value,
                label: e.label
            }
        }, () => {
            if(this.state.unitsAndSegmentsList){
                let localUnit="";
                let localSegment = "";
                this.state.unitsAndSegmentsList.map((item)=>{
                    if (!(item.VERTICAL.toString().toLowerCase() === "ts") ) {
                        if(e && e.label === item.VERTICAL){
                            if(item){
                                localUnit = item.UNIT;
                                localSegment = item.SEGMENT;
                            }
                        }
                    }
                })
                this.setState({Unit:localUnit, Segment:localSegment },()=>{})
            }
            this.setState({ subVerticalList: [] });
            this.renderSubVerticalOptions();
            this.setState({
                subVerticalSelectedOption: {
                    value: (this.state.subVerticalList.length > 0) ? this.state.subVerticalList[0] : "",
                    label: (this.state.subVerticalList.length > 0) ? this.state.subVerticalList[0] : ""
                }
            });
            this.fetchBlackBeltOptions();
        });
    }

    handleSubVerticalChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.subVerticalManual = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            subVerticalManual: e.label,
            subVerticalSelectedOption: {
                value: e.value,
                label: e.label
            }
        });
    }

    handleCampaignNameChange = (e) => {
        this.setState({
            solutionComplexitySelectedOption: [],
            solutionFeasibleSelectedOption: [],
            IsIdeaReplicableSelectedOption: []
        })
        if (e.label === "Gen AI") {
            this.setState({ chatGPTTheme: true }, () => { })
        } else {
            this.setState({ chatGPTTheme: false }, () => {
                this.setState({ chatGptSelectedOption: [] })
            })
        }

        if (e.label === AIFIRST || e.label === "AI First - Enterprise 360" || e.label === "AI First - C&H Gen AI hackathon") {
            this.setState({
                transSelectedOption: {
                    value: 0,
                    label: "AI"
                }
            }, () => { })
        }



        let styleStateObj = this.state.selectStyle;
        styleStateObj.campaignTypeText = selectStyle(true);

        let flagStatus = (e.label === DATA_BRIDGE_CAMPAIGN) ? true : false;
        let xPollinationflagStatus = (e.label === X_POLLINATION_CAMPAIGN) ? true : false;

        this.setState({
            dataBridgeCampaign: flagStatus,
            xPollinationCampaign: xPollinationflagStatus,
            dataBridgeProjectTheme: "",
            subCampaignName: "",
            selectStyle: styleStateObj,
            campaignTypeText: e.label,
            campaignSelectedOption: { label: e.label }
        });

        this.state.campaignList.map((item,index)=>{
            if(e.value===item.CampaignId){
                this.setState({campaignInsight:item.CampaignDescr},()=>{
                    //console.log("campaign insights => ",this.state.campaignInsight)
                })
            }
        })
    }

    handleDataBridgeProjectThemeChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.dataBridgeProjectTheme = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            dataBridgeProjectTheme: e.label,
            dataBridgeSelectedOption: { label: e.label, value: e.value, }
        });
    }

    handleSubCampaignChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.subCampaignName = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            subCampaignName: e.label,
            subCampaignSelectedOption: { label: e.label }
        });
    }

    renderChatGPToptions = () => {
        let options = [];
        this.state.chatGptOptions.map((item) => {
            options.push({ value: item.value, label: item.label });
            return true;
        });
        return options;
    }
    handleChatGptOptionsChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.campaignTypeText = selectStyle(true);
        if (e !== null || e !== undefined) {
            this.setState({
                subCampaignName: e.label,
            }, () => { })
            this.setState({
                chatGptSelectedOption: {
                    value: e.value,
                    label: e.label
                }
            }, () => { })
        }

    }

    handleBlackBeltNameChange = (e) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.blackBeltUserName = e.label;
        if ((parseInt(e.value) === parseInt(this.state.ideaItem.OwnerOHR)) || (parseInt(e.value) === parseInt(this.state.ideaItem.CreatorOHR))) {
            Toast.fail('Mentor user cannot be the same as Idea Owner/Requester. Please select a different Mentor user.', 3000, () => {
                return;
            });
            return;
        }
        let styleStateObj = this.state.selectStyle;
        styleStateObj.blackBeltUserName = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            blackBeltUserId: e.value,
            blackBeltUserName: e.label,
            blackBeltUserEmail: e.value + "@genpact.com",
            blackBeltSelectedOption: {
                value: e.value,
                label: e.label
            },
            rolesUpdated: true
        });
    }

    handleMBBChange = (e) => {
        if ((parseInt(e.value) === parseInt(this.state.ideaItem.OwnerOHR)) || (parseInt(e.value) === parseInt(this.state.ideaItem.CreatorOHR))) {
            Toast.fail('MBB user cannot be the same as Idea Owner/Requester. Please select a different Mentor user.', 3000, () => {
                return;
            });
            return;
        }
        let styleStateObj = this.state.selectStyle;
        styleStateObj.mbbUserName = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            mbbName: e.label,
            mbbOHR: e.value,
            mbbSelectedOption: {
                value: e.value,
                label: e.label
            },
            rolesUpdated: true
        });
    }

    handleLDTLeadOptionChange = (e) => {
        if ((parseInt(e.value) === parseInt(this.state.ideaItem.OwnerOHR)) || (parseInt(e.value) === parseInt(this.state.ideaItem.CreatorOHR))) {
            Toast.fail('LDT user cannot be the same as Idea Owner/Requester. Please select a different LDT user.', 3000, () => {
                return;
            });
            return;
        }
        let ideaItem = this.state.ideaItem;
        ideaItem.LDTId = e.value;
        ideaItem.LDTLeadName = e.label;
        this.setState({
            ideaItem,
            LDTLeadSelectedOption: {
                value: e.value,
                label: e.label
            },
            rolesUpdated: true
        });
    }

    handleRegionChange = (e) => {
        if (e.label === OTHER_REGIONS && (!this.state.verticalManual || this.state.verticalManual === "")) {
            this.setState({
                regionSelectedOption: null
            });
            Toast.fail('Please select Account & Vertical to fetch BlackBelt users for Other Regions.', 3000, () => {
                return;
            });
        } else {
            let styleStateObj = this.state.selectStyle;
            styleStateObj.regionManual = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj,
                regionManual: e.label,
                regionText: e.label,
                regionSelectedOption: {
                    value: e.value,
                    label: e.label
                },
                blackBeltSelectedOption: null,
                mbbSelectedOption: null,
            }, () => {
                this.fetchBlackBeltOptions(e);
            });
        }
    }

    handleMethodologyChange = (e) => {
        this.setState({
            methodologySelectedOptions: {
                value: e.value,
                label: e.label
            }
        });
    }

    handleSubRegionChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.subRegion = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            subRegion: e.label,
            subRegionSelectedOption: {
                value: e.value,
                label: e.label
            }
        });
    }

    handleCountryChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.country = selectStyle(true);
        this.setState({
            selectStyle: styleStateObj,
            country: e.value,
            regionSelectedOption: null,
            subRegionSelectedOption: null,
            blackBeltSelectedOption: null,
            mbbSelectedOption: null,
            ldaSelectedOption: null,
            countrySelectedOption: {
                value: e.value,
                label: e.label
            }
        }, () => {
            this.fetchRegionOptions(e);
        });
    }

    handleServiceLineChange = (e) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.ServiceLineId = e.value;
        ideaItem.ServiceLineName = e.label;
        ideaItem.SubServiceLineId = "";
        ideaItem.SubServiceLine = "";
        this.setState({
            subServiceLineSelectedOption: null,
            serviceLineSelectedOption: {
                value: e.value,
                label: e.label
            },
            ideaItem
        });
        this.renderSubServiceLineOptions(e);
    }

    handleSubServiceLineChange = (e, index) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.SubServiceLine = e.label;
        this.setState({
            subServiceLineSelectedOption: {
                value: e.value,
                label: e.label
            },
            ideaItem
        });
    }

    handleProblemChange = (text) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.Problem = text;
        this.setState({
            ideaItem
        }, () => { });
    }

    handleSolutionChange = (text) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.Solution = text;
        this.setState({
            ideaItem: ideaItem
        }, () => { });
    }

    handleCommentChange = (text) => {
        if (text) {
            this.setState({ comment: text }, () => { console.log(this.state.comment) })
        }
        let styleStateObj = this.state.selectStyle;
        styleStateObj.comment = textBoxValidation(true);
        this.setState({
            selectStyle: styleStateObj
        })

        let ideaItem = this.state.ideaItem;
        ideaItem.Comment = text;
        this.setState({ ideaItem: ideaItem }, () => { });
    }

    handleFinalCommentChange = (text) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.FinalComments = text;
        this.setState({
            ideaItem: ideaItem,
            finalComments: text
        }, () => { });
    }

    showHideDiv = () => {
        let ideaItem = this.state.ideaItem;
        ideaItem.NewOrReplicatedIdea = !ideaItem.NewOrReplicatedIdea;
        this.setState({
            ideaItem
        }, () => { });
    }

    showHideBestInClass = () => {
        let ideaItem = this.state.ideaItem;
        ideaItem.IsBestInClass = !ideaItem.IsBestInClass;
        this.setState({
            ideaItem
        });
    }

    showHideZeroImpactProject = (event) => {
        this.setState({
            dollarTotal: 0.0,
            fteTotal: 0.0,
            zeroImpact: !this.state.zeroImpact,
            summaryArray: [],
            businessImpactValueArray: [],
            zeroImpactForLastestBI: true,
            FinalImpactDollar: 0.0,
            FinalImpactFTE: 0.0
        }, () => { });
    }

    showHideIsSharedToSixSigma = () => {
        let ideaItem = this.state.ideaItem;
        ideaItem.IsSharedToSixSigma = !ideaItem.IsSharedToSixSigma;
        this.setState({
            ideaItem
        });
    }

    handleReplicationIdChange = (text) => {
        let ideaItem = this.state.ideaItem;
        ideaItem.ReplicationProjectId = text;
        this.setState({
            ideaArray: ideaItem
        });
    }

    handleOtherToolsChange = (event) => {
        this.setState({
            otherTools: event.target.value
        });
    }

    validateReplicationId = () => {
        if (!this.fetchReplicationIdeaDetails(this.state.ideaItem.ReplicationProjectId)) {
            let ideaItem = this.state.ideaItem;
            ideaItem.ReplicationProjectId = "";
            this.setState({
                ideaItem: ideaItem
            });
            return false;
        } else {
            return true;
        }
    }

    fetchRegionOptions = () => {
        Toast.loading("Fetching Regions. Please wait...", () => { });
        let entityType = "REGION";
        let entityName = this.state.country + "::" + this.state.accountName;

        const param = {
            EntityType: entityType,
            EntityName: entityName
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                Toast.hide();
                let styleStateObj = this.state.selectStyle;
                styleStateObj.regionManual = selectStyle(true);
                styleStateObj.subRegionManual = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                    regionList: data.Region.Table1 !== undefined ? data.Region.Table1 : [],
                    regionSelectedOption: {
                        label: data.Region.Table1[0].Region,
                        value: data.Region.Table1[0].Region
                    },
                    subRegionList: data.Region.Table1 !== undefined ? data.Region.Table1 : [],
                    subRegionSelectedOption: {
                        label: data.Region.Table1[0].SubRegion,
                        value: data.Region.Table1[0].SubRegion
                    },
                    regionText: data.Region.Table1[0].Region,
                    regionManual: data.Region.Table1[0].Region,
                    subRegion: data.Region.Table1[0].SubRegion
                });
                // console.log("Region List:" + JSON.stringify(this.state.regionList));
            })
            .then(() => {
                this.fetchBlackBeltOptions();
                this.fetchLDTLeadOptions();
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting Region details. Please try again!', 15000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }

    fetchVerticalOptions = () => {
        Toast.loading("Fetching Verticals. Please wait...", () => { });
        const param = {
            EntityType: "Vertical",
            EntityName: this.state.accountName
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                Toast.hide();
                let styleStateObj = this.state.selectStyle;
                styleStateObj.verticalManual = selectStyle(true);
                styleStateObj.subVerticalManual = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                    verticalList: data.Table1 !== undefined ? data.Table1 : [],
                    // verticalSelectedOption: {
                    //     label: data.Table1[0].VERTICAL,
                    //     value: data.Table1[0].VERTICAL
                    // },
                    // verticalManual: data.Table1[0].VERTICAL,
                    // subVerticalManual: data.Table1[0].SUB_VERTICAL
                    verticalSelectedOption: {
                        label: this.state.ideaItem.VerticalManual,
                        value: this.state.ideaItem.VerticalId
                    },
                    verticalManual: this.state.ideaItem.VerticalManual,
                    subVerticalManual: this.state.ideaItem.SubVerticalName,
                    unitsAndSegmentsList:data.Table2 !== undefined ? data.Table2 : [],
                }

                );
            }).then(() => {

               
                if(this.state.unitsAndSegmentsList){
                    let localUnit="";
                    let localSegment = "";
                    this.state.unitsAndSegmentsList.map((item)=>{
                        if (!(item.VERTICAL.toString().toLowerCase() === "ts") ) {
                            if(this.state.verticalSelectedOption && this.state.verticalSelectedOption.label === item.VERTICAL){
                                if(item){
                                    localUnit = item.UNIT;
                                    localSegment = item.SEGMENT;
                                }
                            }
                        }
                    })
                    this.setState({Unit:localUnit, Segment:localSegment },()=>{})
                }


                if (this.state.isAccountChange) {
                    this.setState({
                        verticalSelectedOption: {
                            label: null,
                            value: null
                        },
                        isAccountChange: false

                    })

                }
                this.renderVerticalOptions();
                this.renderSubVerticalOptions();
                this.setState({
                    subVerticalSelectedOption: {
                        value: (this.state.subVerticalList.length > 0) ? this.state.subVerticalList[0] : "",
                        label: (this.state.subVerticalList.length > 0) ? this.state.subVerticalList[0] : ""
                    },
                    subVerticalManual: (this.state.subVerticalList.length > 0) ? this.state.subVerticalList[0] : ""
                });
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting Vertical details. Please try again!', 10000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }

    fetchCountryOptions = () => {
        const param = {
            EntityType: "COUNTRY",
            EntityName: this.state.accountName
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.country = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj,
                    countryList: data.Countries.Table1 !== undefined ? data.Countries.Table1 : [],
                });
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting Account specific Country details. Please try again!', 10000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }

    fetchBlackBeltOptions = () => {
        // Toast.loading("Fetching Blackbelts. Please wait...", () => { });
        let entityType = "BB";
        let entityName = this.state.regionText;
        if (this.state.regionText === OTHER_REGIONS) {
            entityType = "BBOTHER";
            entityName = this.state.verticalManual.replace(" - ", "-");
        }
        const param = {
            EntityType: entityType,
            EntityName: entityName
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(GET_METADATA, requestOptions)
            .then(response => response.json())
            .then(data => {
                Toast.hide();
                this.setState({
                    blackbeltList: data.DataverseUserRoles !== undefined ? data.DataverseUserRoles : [],
                    mbbList: data.DataverseUserRoles !== undefined ? data.DataverseUserRoles : [],
                    ldaList: data.DataverseUserRoles !== undefined ? data.DataverseUserRoles : [],
                });
            })
            .catch(error => {
                Toast.hide();
                Toast.fail('Error occured while getting BlackBelt details. Please try again!', 15000, () => {
                    console.log(JSON.stringify(error));
                });
            });
    }

    fetchReplicationIdeaDetails = (IdeaId) => {
        let replicaID = IdeaId;
        console.log("length ideaID", IdeaId.length);
        if (IdeaId.length > 7) {
            replicaID = IdeaId.slice(2, 11)
        }
        Toast.loading("Fetching Replication Idea Details. Please wait...", () => { });
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            }
        };
        return fetch(`${GET_IDEA_URL}IdeaId=` + replicaID, requestOptions)
            // return fetch(`${GET_IDEA_URL}IdeaId=-1&IdeaIDAlpha=` + IdeaId, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.ResultSets.Table1 === undefined) {
                    Toast.fail("We could not find Idea details based on the entered Idea ID. Please check and reenter.", 5000, () => { });
                    return false;
                } else {
                    Toast.hide();
                    Toast.success('Replication Idea ID validated successfully!', 3000, () => { });
                    return true;
                }
            })
            .catch(error => {
                Toast.hide();
                Toast.fail("Could not find the Idea, please check and re-enter correct Idea ID.", 5000, () => { });
                return false;
            });
    }

    renderStatusOptions = () => {
        let options = [];
        this.state.workFlowStatusList.map((item) => {
            if (this.state.ideaItem.WorkflowStatus === 'Reject') {
                if (item.WorkflowStatusName !== 'Reject') {
                    options.push({
                        value: item.WorkflowStatusId,
                        label: item.WorkflowStatusName
                    })
                }
            }
            else {
                options.push({
                    value: item.WorkflowStatusId,
                    label: item.WorkflowStatusName
                })
            }
            return options;
        });
        return options;
    }

    renderApprovalStatusOptions = () => {
        let options = [];
        this.state.finalApproveOptionsValue.map((item) => {
            if (this.state.ideaItem.ApprovalStatus === 'Rejected') {
                if (item.WorkflowStatusName !== 'Rejected') {
                    options.push({
                        value: item.WorkflowStatusId,
                        label: item.WorkflowStatusName
                    })
                }
            }
            else {
                options.push({
                    value: item.WorkflowStatusId,
                    label: item.WorkflowStatusName
                })
            }

            return options;
        });
        return options;
    }

    renderCertLevelsOptions = () => {
        let options = [];
        this.state.certificationLevelsList.map((item) => {
            options.push({
                value: item.CertificationLevelId,
                label: item.CertificationLevelName
            });
            return options;
        });
        return options;
    }

    handleCertLevelChange = (e) => {
        this.setState({
            certLevelsSelectedOption: {
                value: e.value,
                label: e.label
            }
        });
        this.setState({ potentialDollar: "" }, () => { })


        // if(this.state.certLevelsSelectedOption.label === "Lean" && this.state.certification){
        //     this.setState({showDigital:true})
        // }

        //Reset the Work Type upon change of Certification Level
        this.setState({
            certWorkTypeSelectedOption: null,
            digitalProjectTypeSelectedOptions: null,
            transSelectedOption: null
        });

        if (this.state.showAdditionalDetails && this.state.transSelectedOption && this.state.transSelectedOption.label === "Digital") {
            this.setState({
                workTypeEnabled: false
            });
        }
        if (e.label === "Lean" || e.label === "YB") {
            this.setState({
                workTypeEnabled: false
            });
        }

        if (e.label === "GB" && this.state.transSelectedOption && this.state.transSelectedOption.label === "Data") {
            this.setState({
                certWorkTypeSelectedOption: {
                    value: "GB ProDG",
                    label: "GB ProDG"
                },
                workTypeEnabled: false
            });
        } else {
            this.setState({
                digitalProjectTypeSelectedOptions: null,
                workTypeEnabled: false
            });
        }
        if (e.label === "GB" && this.state.transSelectedOption && this.state.transSelectedOption.label === "Continuous Improvement") {
            this.setState({
                workTypeEnabled: true
            });
        } else {
            this.setState({
                digitalProjectTypeSelectedOptions: null
            });
        }
        // if (e.label === "Lean"){
        //     this.setState({showDigital:true})
        // }
        // if (e.label === "GB"){
        //     this.setState({
        //         showDigital:false,
        //         transSelectedOption:null
        //     })
        // }          
    }

    renderTransLeversOptions = () => {
        let options = [];
        this.state.transformationLeversList.map((item) => {
            options.push({
                value: item.TransformationLeverId,
                label: item.TransformationLeverName
            });
        });
        return options;
    }
    renderTransLeversOptionsForLeanApproval = () => {
        let options = [];
        // if(this.state.ideaItem.ApprovalStatus === "Pending Documents"){
        //     if(this.state.certLevelsSelectedOption !== "GB" ){
        //         this.state.transformationLeversList.map((item) => {
        //             options.push({
        //                 value: item.TransformationLeverId,
        //                 label: item.TransformationLeverName
        //             });
        //             options = options.slice(0,2);
        //         });
        //     }else{
        //         this.state.transformationLeversList.map((item) => {
        //             options.push({
        //                 value: item.TransformationLeverId,
        //                 label: item.TransformationLeverName
        //             });
        //         });
        //     }
        //     return options;
        // }
        if (this.state.ideaItem.CertificationLevelName === "Lean") {
            if (this.state.certLevelsSelectedOption !== "GB") {
                this.state.transformationLeversList.map((item) => {
                    options.push({
                        value: item.TransformationLeverId,
                        label: item.TransformationLeverName
                    });
                    // options = options.slice(0,2);
                });
            } else {
                this.state.transformationLeversList.map((item) => {
                    options.push({
                        value: item.TransformationLeverId,
                        label: item.TransformationLeverName
                    });
                });
            }
            return options;
        }

        // this.state.transformationLeversList.map((item) => {
        //     options.push({
        //         value: item.TransformationLeverId,
        //         label: item.TransformationLeverName
        //     });
        // });
        // return options;
    }


    handleTransLeversChange = (e) => {
        if (e !== null || e !== undefined) {
            const options = {
                value: e.value,
                label: e.label
            }
            this.setState({ transSelectedOption: options }, () => {
                if (this.state.transSelectedOption.label === "Digital") {
                    this.setState({ digitalProjectEnabled: true })
                }
                if (this.state.transSelectedOption.label === "Data" || this.state.transSelectedOption.label == "Continuous Improvement") {
                    this.setState({ digitalProjectTypeSelectedOptions: null, digitalSelected: null, digitalProjectEnabled: false })
                }
            });
        }
    }

    renderDigitalProjectTypeOptions = () => {
        let options = [];
        this.state.digitalProjectTypeList.map((item) => {
            let skipOption = false;
            if (this.state.certLevelsSelectedOption && this.state.certLevelsSelectedOption.label === "GB") {
                if (item.DigitalProjectTypeName === "QPA" || item.DigitalProjectTypeName === "Other Digital") {
                    skipOption = true;
                }
            }
            if (this.state.certLevelsSelectedOption && this.state.certLevelsSelectedOption.label === "Lean") {
                if (item.DigitalProjectTypeName === "RPA") {
                    skipOption = true;
                }
            }
            if (!skipOption) {
                options.push({
                    value: item.ProjectTypeId,
                    label: item.DigitalProjectTypeName
                })
            }
            return options;
        });
        return options;
    }

    handleDigitalProjectTypeChange = (e) => {
        // this.setState({
        //     digitalProjectTypeSelectedOptions: {
        //         value: e.value,
        //         label: e.label
        //     }
        // });
        this.setState({
            digitalSelected: {
                value: e.value,
                label: e.label
            }
        }, () => {
            console.log(this.state.digitalSelected)
        });
    }

    renderCertWorkTypeOptions = (selectedCert) => {
        let options = [];
        this.state.certificationWorkTypeList.map((item) => {
            if (!selectedCert || selectedCert.label === item.CertifiationLevelName) {
                options.push({
                    value: item.WorkTypeName,
                    label: item.WorkTypeName
                });
            }
            return options;
        });
        return options;
    }

    handleCertWorkTypeChange = (e) => {
        this.setState({
            certWorkTypeSelectedOption: {
                value: e.value,
                label: e.label
            }
        });
        const BIFormApplicableProjects = ["GB ProDG", "GB BPMS LIVE", "GB POV 3D"];
        if (this.state.certLevelsSelectedOption.label === "GB" &&
            BIFormApplicableProjects.includes(e.label)) {
            this.setState({
                showBIForm: true
            });
        } else {
            this.setState({
                showBIForm: false
            });
        }
    }

    showAdditionalDetails = (e) => {
        if (e.value === 1) {
            this.setState({
                showAdditionalDetails: true
            });
        }
        else {
            this.setState({
                showAdditionalDetails: false
            });
        }
        this.setState({
            workFlowStatusSelectedOption: {
                value: e.value,
                label: e.label
            }
        }, () => {
            console.log("work flow selected option is ", this.state.workFlowStatusSelectedOption)
        });
    }

    handleApprovalStatus = (e) => {
        if (this.state.ideaItem.ApprovalStatus === 'Documents Uploaded' || this.state.ideaItem.ApprovalStatus === 'Rejected') {
            if (e.label === COMPLETED_STATUS) {
                this.setState({
                    showFinalAdditionalDetails: true,
                    bbEditable: true,
                    IsVDMonetizable: [{
                        value: 0,
                        label: "Choose monetizable project"
                    }]
                });
            }
            else {
                this.setState({
                    showFinalAdditionalDetails: false,
                    finalImpactDollar: 0,
                    FinalImpactDollar: 0,
                    finalImpactFTE: 0,
                    fteTotal: 0,
                    dollarTotal: 0.0,
                    zeroImpact: false,
                    bestClass: false,
                    summaryArray: [],
                    otherTools: null,
                    replicaIndex: 0,
                    showBIForm: true,
                    businessImpactValueArray: [],
                    IsVDMonetizable: null
                });
            }
        }
        this.setState({
            approvalSelectedOption: {
                value: e.value,
                label: e.label
            }
        });
    }





    cancelEvent = () => {
        this.props.history.push("/view-all-ideas-page");
    }

    FinalApproverCheck = () => {
        console.log("approval status is=>", this.state.ideaItem.ApprovalStatus)
        if (this.state.ideaItem.QCOHR && this.state.ideaItem.QCName) {
            let Approver = "Awaiting Project Review"
            if (this.state.finalImpactDollar >= 50000) {
                Approver = AWAITING_MBB_APPROVAL
            }
            if (this.state.monetizableCheckbox === true) {
                Approver = AWAITING_MBB_APPROVAL
            }
            if (this.state.ideaItem.ApprovalStatus === "Rework - QC") {
                Approver = "Awaiting Project Review"
            }
            if (this.state.approvalSelectedOption !== null) {
                if (this.state.approvalSelectedOption.label === 'Send Back') {
                    Approver = "Pending Documents"
                } else if (this.state.approvalSelectedOption.label === 'Rejected') {
                    Approver = "Rejected"
                }
            }
            this.state.approvalStatusState = Approver
            this.setState({
                approvalStatusState: Approver
            }, () => { console.log(this.state.approvalStatusState) })
        } else {
            let Approver = COMPLETED_STATUS;
            if (this.state.biThreshold !== 0 && this.state.finalImpactDollar !== 0 && this.state.impactOptionSelected && this.state.impactOptionSelected.label !== "No Value Delivery") {
                if (this.state.finalImpactDollar >= this.state.biThreshold) {
                    if (this.state.vrconditiontype === "AND") {
                        if ((this.state.vrVerticalMapping.includes(this.state.ideaItem.VerticalManual) || this.state.vrVerticalMapping === "ALL") && (this.state.vrRegionMapping.includes(this.state.ideaItem.RegionManual) || this.state.vrRegionMapping === "ALL") && this.state.IsSampleCheck) {
                            Approver = AWAITING_QAULITY_CHECK_REVIEW
                        }
                    } else {
                        if ((this.state.vrVerticalMapping.includes(this.state.ideaItem.VerticalManual) || this.state.vrVerticalMapping === "ALL") || (this.state.vrRegionMapping.includes(this.state.ideaItem.RegionManual) || this.state.vrRegionMapping === "ALL") && this.state.IsSampleCheck) {
                            Approver = AWAITING_QAULITY_CHECK_REVIEW
                        }
                    }
                }
            }
            if (this.state.finalImpactDollar >= 50000) {
                Approver = AWAITING_MBB_APPROVAL
            }
            if (this.state.monetizableProjectSelectedOption !== null) {
                if (this.state.monetizableProjectSelectedOption.label === "Yes") {
                    Approver = AWAITING_MBB_APPROVAL
                }
            }
            if (this.state.ideaItem.ApprovalStatus === "Rework - QC") {
                Approver = "Awaiting Project Review"
            }
            if (this.state.approvalSelectedOption !== null) {
                if (this.state.approvalSelectedOption.label === 'Send Back') {
                    Approver = "Pending Documents"
                } else if (this.state.approvalSelectedOption.label === 'Rejected') {
                    Approver = "Rejected"
                }
            }
            this.state.approvalStatusState = Approver
            this.setState({
                approvalStatusState: Approver
            }, () => { console.log(this.state.approvalStatusState) })
        }



    }

    validate = () => {
        this.FinalApproverCheck()
        let isValid = true;
        let styleStateObj = this.state.selectStyle;
        this.isExceedlimit = false;

        if (this.state.approvalSelectedOption && this.state.ideaItem.ApprovalStatus !== 'Completed') {
            if (this.state.approvalSelectedOption.label !== this.state.ideaItem.ApprovalStatus) {
                if (this.state.finalComments.length > 500) {
                    Toast.fail("Comment cannot be more than 500 characters, please change and resubmit.", 3000);
                    isValid = false;
                    console.log("approval selected option")
                    this.isExceedlimit = true;
                    styleStateObj.finalcomments = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
                else {
                    styleStateObj.finalcomments = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
            }
            else {
                if (this.state.approvalSelectedOption.label === this.state.ideaItem.ApprovalStatus) {
                    Toast.fail("Please select other Idea Approval Status", 3000);
                    isValid = false;
                    styleStateObj.approvalSelectedOption = selectStyle(false);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
            }
        }


        if (this.state.comment && this.state.workFlowStatusSelectedOption) {
            if (this.state.workFlowStatusSelectedOption.label === this.state.ideaItem.WorkflowStatus) {
                Toast.fail("Please select other Idea Approval Status", 3000);
                isValid = false;
                styleStateObj.workFlowStatusSelectedOption = selectStyle(false);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
            else {
                styleStateObj.workFlowStatusSelectedOption = selectStyle(true);
                this.setState({
                    selectStyle: styleStateObj
                })
            }
        }

        if (this.state.ideaItem.WorkflowStatus !== 'Approve') {
            if (this.state.workFlowStatusSelectedOption) {
                if (this.state.comment.length > 500) {
                    Toast.fail("Comment cannot be more than 500 characters, please change and resubmit.", 3000);
                    isValid = false;
                    this.isExceedlimit = true;
                    styleStateObj.comment = textBoxValidation(false);
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
                else {
                    styleStateObj.comment = { ...textBoxValidation(true), border: "1px solid lightgrey" };
                    this.setState({
                        selectStyle: styleStateObj
                    })
                }
            }
        }



        if (this.state.showAdditionalDetails && this.state.certLevelsSelectedOption === null) {
            isValid = false;
            styleStateObj.certLevelsSelectedOption = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.certLevelsSelectedOption = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }



        if (this.state.showAdditionalDetails && this.state.transSelectedOption === null) {
            isValid = false;
            styleStateObj.transSelectedOption = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.transSelectedOption = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }


        if (this.state.showAdditionalDetails && this.state.certWorkTypeSelectedOption === null) {
            if (this.state.certLevelsSelectedOption && this.state.certLevelsSelectedOption.label === "GB") {
                isValid = false;
                Toast.fail("GB projects are not allowed to be updated by admin", 3000, () => { })
                styleStateObj.certWorkTypeSelectedOption = selectStyle(false);
                this.setState({ selectStyle: styleStateObj })
            } else {
                styleStateObj.certWorkTypeSelectedOption = selectStyle(true);
                this.setState({ selectStyle: styleStateObj })
            }
        }
        else {
            styleStateObj.certWorkTypeSelectedOption = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }


        // if(this.state.showAdditionalDetails && this.state.certWorkTypeSelectedOption === null){
        //     if (this.state.certLevelsSelectedOption && this.state.transSelectedOption &&
        //         (this.state.certLevelsSelectedOption.label === "GB" && (this.state.transSelectedOption.label === "Continuous Improvement" || this.state.transSelectedOption.label === "Data"))){
        //         isValid = false;
        //         Toast.fail("GB and CI n Data selected", 5000,()=>{})
        //         styleStateObj.certWorkTypeSelectedOption = selectStyle(false);
        //         this.setState({
        //             selectStyle: styleStateObj
        //         })
        //     }else{
        //         styleStateObj.certWorkTypeSelectedOption = selectStyle(true);
        //         this.setState({
        //             selectStyle: styleStateObj
        //         })
        //     }
        // }
        // else{
        //     styleStateObj.certWorkTypeSelectedOption = selectStyle(true);
        //     this.setState({
        //         selectStyle: styleStateObj
        //     })
        // }


        // if (this.state.showAdditionalDetails && this.state.digitalProjectTypeSelectedOptions === null) {
        //     if (this.state.transSelectedOption && this.state.transSelectedOption.label === "Digital" ) {
        //         isValid = false;
        //         Toast.fail("digital project type", 5000,()=>{})
        //         styleStateObj.digitalProjectTypeSelectedOptions = selectStyle(false);
        //         this.setState({
        //             selectStyle: styleStateObj
        //         })
        //     } else {
        //         styleStateObj.digitalProjectTypeSelectedOptions = selectStyle(true);
        //         this.setState({
        //             selectStyle: styleStateObj
        //         })
        //     }
        // }
        // else {
        //     styleStateObj.digitalProjectTypeSelectedOptions = selectStyle(true);
        //     this.setState({
        //         selectStyle: styleStateObj
        //     })
        // }


        if (this.state.showAdditionalDetails && this.state.methodologySelectedOptions === null) {
            isValid = false;
            styleStateObj.methodologySelectedOptions = selectStyle(false);
            this.setState({
                selectStyle: styleStateObj
            })
        }
        else {
            styleStateObj.methodologySelectedOptions = selectStyle(true);
            this.setState({
                selectStyle: styleStateObj
            })
        }

        // if((this.state.showAdditionalDetails || this.state.workFlowStatusSelectedOption) && this.state.mbbSelectedOption === null){
        //     isValid = false;
        //     styleStateObj.mbbUserName = selectStyle(false);
        //     this.setState({
        //         selectStyle: styleStateObj
        //     })
        // }else {
        //     styleStateObj.mbbUserName = selectStyle(true);
        //     this.setState({
        //         selectStyle: styleStateObj
        //     })
        // }

        if (this.state.rolesUpdated) {
            if (this.state.approvalSelectedOption === null && this.state.finalComments === '' && this.state.comment === '') {
                isValid = true;
            }
        }



        // VD validation starts here....
        if(this.state.ideaItem.ApprovalStatus && this.state.ideaItem.ApprovalStatus=== "Completed" && this.state.ideaItem.CertificationLevelName && this.state.ideaItem.CertificationLevelName=== "Lean" ){
            if (this.state.impactOptionSelected === null || this.state.impactOptionSelected === undefined || this.state.impactOptionSelected === "" || this.state.impactOptionSelected.length === 0 || !this.state.impactOptionSelected) {
                isValid = false;
                Toast.fail("Please select Value Delivery Type.", 3000, () => { });
            }
            if (this.state.impactOptionSelected && this.state.impactOptionSelected.label !== "No Value Delivery") {
                if (this.state.impactOptionSelected.label === "Value Delivered to Client + Genpact") {
                    if (this.state.businessImpactValueArray.length > 0) {
                        let impactGenpactTrue = this.state.businessImpactValueArray.some(vendor => vendor['ImpactType'] === 'Impact at Genpact')
                        let impactClientTrue = this.state.businessImpactValueArray.some(vendor => vendor['ImpactType'] === 'Impact at Client')
                        if (impactGenpactTrue === false) {
                            isValid = false;
                            Toast.fail('Please add both impact types.', 3000, () => { })
                        }
                        if (impactClientTrue === false) {
                            isValid = false;
                            Toast.fail('Please add both impact types.', 3000, () => { })
                        }
                    }
                }
                if (!this.state.monetizableProjectSelectedOption || this.state.monetizableProjectSelectedOption === null || this.state.monetizableProjectSelectedOption.length === 0 || this.state.monetizableProjectSelectedOption === undefined) {
                    isValid = false;
                    Toast.fail("Please select monetizatable project.", 5000, () => { });
                }
        
                if (this.state.monetizableProjectSelectedOption && this.state.monetizableProjectSelectedOption.label === "Yes") {
                    if (this.state.monetizationStatusSelectedOption === undefined || this.state.monetizationStatusSelectedOption === "" || this.state.monetizationStatusSelectedOption.length === 0 || this.state.monetizationStatusSelectedOption === null || !this.state.monetizationStatusSelectedOption) {
                        isValid = false;
                        Toast.fail("Since this project has been marked as Monetizable, monetizable status has to be selected.", 5000, () => { })
                    }
                }
        
                if (this.state.monetizableProjectSelectedOption && this.state.monetizableProjectSelectedOption.label === "Yes" && this.state.monetizationStatusSelectedOption !== null) {
                    if ((this.state.businessImpactValueArray.filter(item => item.IsMonetized === "Yes")).length > 0) { }
                    else {
                        isValid = false;
                        Toast.fail("Since, this is a monetizable project, there needs to be at least one ‘Monetizable’ check box ticked in the Value Delivery category", 5000, () => { })
                    }
                }
        
                if ((this.state.businessImpactValueArray.length === 0 || this.state.businessImpactValueArray === null || !this.state.businessImpactValueArray || this.state.businessImpactValueArray === undefined || this.state.businessImpactValueArray === "" )) {
                    Toast.fail("Value Delivery cannot be empty, please fill and resubmit.", 3000, () => { });
                    isValid = false;
                }
                if (this.state.businessImpactValueArray && this.state.businessImpactValueArray !== null) {
                    if (parseInt(this.state.finalImpactDollar) <= 0) {
                        Toast.fail("Total $ Impact cannot be zero. Please check and resubmit.", 3000, () => { });
                        isValid = false;
                    }
                    if (this.state.finalImpactFTE > 0 && this.state.finalImpactDollar <= 0) {
                        Toast.fail("Total $ Impact cannot be zero when FTE is non-zero. Please check and resubmit.", 3000, () => { });
                        isValid = false;
                    }
                    let showDollarZeroError = false;
                    let showFTEZeroError = false;
                    let showFTEGreaterError = false;
                    this.state.businessImpactValueArray.filter((item) => {
                        if (parseFloat(item.DollarImpact) === 0 || parseFloat(item.DollarImpact) === "" || !item.DollarImpact || item.DollarImpact === null) {
                            isValid = false;
                            showDollarZeroError = true;
                        }
                        if (item && item.isFTEPresent === true) {
                            if (parseFloat(item.FTEImpact)) { }
                            else {
                                isValid = false;
                                showFTEZeroError = true;
                            }
                            if (parseFloat(item.FTEImpact) === 0) {
                                isValid = false;
                                showFTEZeroError = true;
                            }
                            if (parseFloat(item.FTEImpact) > parseFloat(item.DollarImpact)) {
                                isValid = false;
                                showFTEGreaterError = true;
                            }
                        }
                    });
    
                    if(showDollarZeroError){
                        Toast.fail("$ Impact value cannot be zero.", 3000, () => { })
                    }
                    if(showFTEZeroError){
                        Toast.fail("FTE Impact value cannot be zero.", 3000, () => { })
                    }
                    if(showFTEGreaterError){
                        Toast.fail("FTE impact value cannot be greater than $ Impact value.", 3000, () => { })
                    }
                }
            }
        }
        // VD validation ends here....

        




        return isValid;
    }
    newBusinessImpactSummary() {
        let myArray = this.state.businessImpactValueArray;
        const groupedData = myArray.reduce((accumulator, currentValue) => {
            const ImpactType = currentValue.ImpactType;
            const MonetizableType = currentValue.MonetizableType;
            const FTEImpact = parseFloat(currentValue.fteValue) || 0.0;
            const DollarImpact = parseFloat(currentValue.dollarValue) || 0.0;

            const existingRow = accumulator.find(row => row.ImpactType === ImpactType && row.MonetizableType === MonetizableType);
            if (existingRow) {
                existingRow.FTEImpact += FTEImpact
                existingRow.DollarImpact += DollarImpact
            }
            else {
                accumulator.push({ ImpactType, MonetizableType, FTEImpact, DollarImpact });
            }
            return accumulator;
        }, []);
        this.setState({ summaryArray: groupedData }, () => { });
        this.addMonetizableValues()

        this.setState({ businessImpactValueArray: this.state.businessImpactValueArray }, () => { })
        this.setState({ isFpnaRequired: false }, () => { })
        this.state.businessImpactValueArray && this.state.businessImpactValueArray.map((item) => {
            if (item.ImpactType === "Impact at Genpact") {
                if (item.MonetizableType === "Increase in Revenue" || item.MonetizableType === "Reduction in Cost") {
                    this.setState({ isFpnaRequired: true }, () => { })
                }
            }
        })


    }
    updateIdeaDocuments = () => {
        let param = {
            IdeaId: this.props.location.state,
            ModifiedByOHR: this.sessionData.profile.oHRId,
            Role: "Admin",


            // ProjectPPTDeck: this.state.base64File1,
            // ProjectPPTFilename: this.state.projectDeckFilename ? this.state.projectDeckFilename : this.state.ideaItem.ProjectPPTFileName,
            // ProjectPPTType: this.state.fileType1 ? this.state.fileType1 :  this.state.ideaItem.ProjectPPTType,

            ProjectPPTDeck: this.state.base64File1,
            ProjectPPTFilename: this.state.filename1 ? this.state.filename1 : this.state.ideaItem.ProjectPPTFileName,
            ProjectPPTType: this.state.fileType1 ? this.state.fileType1 : this.state.ideaItem.ProjectPPTType,



            CustomerLeadSignOff: this.state.signOffContent,
            CustomerSignoffFilename: this.state.signOffFilename ? this.state.signOffFilename : this.state.ideaItem.CustomerSignoffFileName,
            CustomerSignoffType: this.state.signOffFileType ? this.state.signOffFileType : this.state.ideaItem.CustomerSignoffType,

            // golApproval:this.state.golContent,
            // golApprovalFileName:this.state.golFilename ? this.state.golFilename : this.state.ideaItem.GOLFileName,
            // golApprovalType:this.state.golFileType ? this.state.golFileType : this.state.ideaItem.GOLFileType,
            golApproval: this.state.base64File3,
            golApprovalFileName: this.state.filename3 ? this.state.filename3 : this.state.ideaItem.GOLFileName,
            golApprovalType: this.state.fileType3 ? this.state.fileType3 : this.state.ideaItem.GOLFileType,


            // fpnaApproval:this.state.fpnaContent,
            // fpnaApprovalFileName:this.state.fpnaFilename ? this.state.fpnaFilename : this.state.ideaItem.FPnAFileName,
            // fpnaApprovalType:this.state.fpnaFileType ? this.state.fpnaFileType : this.state.ideaItem.FPnAFileType,
            fpnaApproval: this.state.base64File4,
            fpnaApprovalFileName: this.state.filename4 ? this.state.filename4 : this.state.ideaItem.FPnAFileName,
            fpnaApprovalType: this.state.fileType4 ? this.state.fileType4 : this.state.ideaItem.FPnAFileType,



            FPnAFileNameMonetization: this.state.FPnAFileNameMonetization ? this.state.FPnAFileNameMonetization : "",
            FPnAFileTypeMonetization: this.state.FPnAFileTypeMonetization ? this.state.FPnAFileTypeMonetization : "",
            FPnAFileMonetization: this.state.FPnAFileMonetization ? this.state.FPnAFileMonetization : "",

            // DB Client testimony
            CTestimony: this.state.CTestimony ? this.state.CTestimony : "",
            CTestimonyFileName: this.state.sharedToClient && this.state.sharedToClient.label === "Yes" ? (this.state.CTestimonyFileName ? this.state.CTestimonyFileName : this.state.ideaItem.CTestimonyFileName) : this.state.ideaItem.CTestimonyFileName,
            CTestimonyFileType: this.state.sharedToClient && this.state.sharedToClient.label === "Yes" ? (this.state.CTestimonyFileType ? this.state.CTestimonyFileType : this.state.ideaItem.CTestimonyFileType) : this.state.ideaItem.CTestimonyFileType,

        }
        console.log(param)
        let finalJsonData = Checksum(param);
        let str1 = finalJsonData.slice(0, 15);
        let str2 = finalJsonData.slice(15, 30);
        let str3 = finalJsonData.slice(30, 40);
        let str4 = finalJsonData.slice(-10);
        let finalData = str3 + str1 + str4 + str2;

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(`${UPDATE_IDEA_URL}Checksum=${finalData}&monetizationdocumentsonly=true`, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("upload document succesfull", data)
            });
    }


    UpdateIdea = async () => {
        if (this.validate()) {
            this.updateIdeaDocuments();
            Toast.loading("Updating Idea...", () => { });

            this.state.businessImpactValueArray = this.state.businessImpactValueArray.map(item => ({
                ...item,
                pg_srno: item.SrNo,
                dollarValue: item.DollarImpact,
                fteValue: item.FTEImpact
            })).map(item => {
                return item;

            });

            let filteredBIData = this.state.businessImpactValueArray.filter(function (e) {
                return (
                    e !== null && e !== undefined && parseFloat(e.DollarImpact) > 0 &&
                    ((e.isFTEPresent && parseFloat(e.FTEImpact) > 0) || (!e.isFTEPresent))
                );
            });

            let LDTLogsPostData = [];
            this.state.LDTLogs && this.state.LDTLogs.map((item, index) => {
                if (item.IsQBR) {
                    let localData = [...this.state.LDTLogs];
                    localData[index].IsQBR = localData[index].IsQBR.label;
                    LDTLogsPostData = localData;
                }
            });
            console.log(LDTLogsPostData)


            console.log(filteredBIData)

            let param = {
                IdeaId: this.props.location.state,
                OwnerEmail: this.state.ideaItem.OwnerEmail,
                OwnerName: this.state.ideaItem.OwnerName,
                OwnerOHR: this.state.ideaItem.OwnerOHR,
                CreatorEmail: this.state.ideaItem.CreatorEmail,
                CreatorName: this.state.ideaItem.CreatorName,
                CreatorOHR: this.state.ideaItem.CreatorOHR,
                // AccountId: this.state.accountId,

                AccountManual: this.state.accountName,

                HoldingAccount: this.state.holdingAccount,
                // VerticalId: Number(this.state.verticalId),
                VerticalManual: this.state.verticalManual,
                // VerticalManualId: Number(this.state.verticalManualId),
                SubVerticalManual: this.state.subVerticalManual,
                // CampaignTypeId: Number(this.state.campaignTypeId),
                CampaignName: this.state.campaignTypeText,
                DataBridgeTheme: this.state.dataBridgeProjectTheme ? this.state.dataBridgeProjectTheme : this.state.ideaItem.DataBridgeTheme,
                SubCampaignName: this.state.subCampaignName ? this.state.subCampaignName : this.state.ideaItem.SubCampaignName,
                Country: this.state.country,
                // RegionId: Number(this.state.regionId),

                RegionManual: this.state.regionManual,

                // RegionManualId: Number(this.state.regionManualId),
                SubRegion: this.state.subRegion,
                BlackBeltUserId: Number(this.state.blackBeltUserId),
                BlackBeltUserName: this.state.blackBeltUserName,
                BlackBeltUserEmail: this.state.blackBeltUserEmail,
                MBBOHR: Number(this.state.mbbOHR) ? Number(this.state.mbbOHR) : this.state.ideaItem.MBBOHR,
                MBBName: this.state.mbbName,
                IdeaName: this.state.ideaItem.IdeaName,
                ServiceLineId: 111,
                ServiceLineName: this.state.serviceLineSelectedOption !== null ? this.state.serviceLineSelectedOption.label : null,
                SubServiceLine: this.state.subServiceLineSelectedOption !== null ? this.state.subServiceLineSelectedOption.label : null,
                Problem: this.state.ideaItem.Problem,
                Solution: this.state.ideaItem.Solution,
                ReplicationProjectId: this.state.ideaItem.ReplicationProjectId,
                NewOrReplicatedIdea: this.state.ideaItem.NewOrReplicatedIdea,
                IsReplicationVerified: false,
                LDTId: this.state.ideaItem.LDTId,
                LDTName: this.state.ideaItem.LDTLeadName,
                CertificationLevelId: (this.state.showAdditionalDetails && this.state.certLevelsSelectedOption !== null) ? this.state.certLevelsSelectedOption.value : this.state.ideaItem.CertificationLevelId,
                CertificationLevelName: (this.state.showAdditionalDetails && this.state.certLevelsSelectedOption !== null) ? this.state.certLevelsSelectedOption.label : this.state.ideaItem.CertificationLevelName,
                CertificationWorkTypeName: (this.state.showAdditionalDetails && this.state.certWorkTypeSelectedOption !== null) ? this.state.certWorkTypeSelectedOption.value : this.state.ideaItem.CertificationWorkTypeName,
                TransformationLeverId: (this.state.showAdditionalDetails && this.state.transSelectedOption !== null) ? this.state.transSelectedOption.value : (this.state.transSelectedOption !== null ? this.state.transSelectedOption.value : this.state.ideaItem.TransformationLeverId),
                TransformationLeverName: (this.state.showAdditionalDetails && this.state.transSelectedOption !== null) ? this.state.transSelectedOption.label : (this.state.transSelectedOption !== null ? this.state.transSelectedOption.label : this.state.ideaItem.TransformationLeverName),
                // DigitalProjectType: (this.state.showAdditionalDetails && this.state.digitalProjectTypeSelectedOptions !== null) ? this.state.digitalProjectTypeSelectedOptions.label : this.state.ideaItem.DigitalProjectType,

                DigitalProjectType: this.state.digitalSelected ? this.state.digitalSelected.label : this.state.ideaItem.DigitalProjectType,

                MethodologyTypeID: this.state.methodologySelectedOptions !== null ? this.state.methodologySelectedOptions.value : null,
                MethodologyName: this.state.methodologySelectedOptions !== null ? this.state.methodologySelectedOptions.label : null,
                IsZeroImpactProject: this.state.ideaItem.IsZeroImpactProject,
                IsSharedToSixSigma: this.state.ideaItem.IsSharedToSixSigma,
                IsBestInClass: this.state.ideaItem.IsBestInClass,
                ReplicabilityIndex: this.state.replicaIndex ? this.state.replicaIndex.value : "",
                FinalOtherTools: this.state.otherTools,
                UpdaterOHR: this.sessionData.profile.oHRId,
                UpdaterEmail: this.sessionData.profile.oHRId + "@genpact.com",
                UpdaterName: this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName,
                WorkflowStatusId: this.state.workFlowStatusSelectedOption !== null ? this.state.workFlowStatusSelectedOption.value : null,
                WorkflowStatus: this.state.ideaItem.WorkflowStatus,
                ApprovalStatus: this.state.ideaItem.ApprovalStatus,


                BusinessImpactData: filteredBIData,
                FinalImpactDollar: this.state.finalImpactDollar,
                FinalImpactFTE: this.state.finalImpactFTE,
                SaveType: "Update",
                Comment: this.state.ideaItem.Comment,
                FinalComments: this.state.ideaItem.FinalComments,
                LogComments: this.state.comment !== '' ? this.state.comment : (this.state.finalComments !== '' ? this.state.finalComments : null),
                Role: "Admin",
                CompletedDate: new Date().toLocaleString(),
                VDType: this.state.impactOptionSelected ? this.state.impactOptionSelected.label : this.state.ideaItem.VDType,

                // IsVDReviewed: this.state.showCheckBox ? "Yes" : "No",
                IsVDReviewed: this.state.bbReviewCheckbox ? this.state.bbReviewCheckbox.label : this.state.ideaItem.IsVDReviewed,
                FpnaApprovalAttachedSelectedOption: this.state.bbReview ? this.state.bbReview.label : this.state.ideaItem.IsFPnAApprovalAttached,
                // FpnaApprovalAttachedSelectedOption:this.state.appropriateAttachmentValue.label,
                // FpnaApprovalAttachedSelectedOption:this.state.ideaItem.IsFPnAApprovalAttached,


                ClientName: this.state.clientName,
                ClientRole: this.state.clientRole,
                ClientSignOffDate: this.state.clientSignOffDate,
                GolOhr: this.state.golFullname ? this.state.golOhr : "",
                GolFullname: this.state.golFullname,
                FpnaOhr: this.state.fpnaName ? this.state.fpnaOhr : "",
                FpnaName: this.state.fpnaName,


                FPnAOHRMonetization: this.state.FPnAOHRMonetization ? this.state.FPnAOHRMonetization : "",
                FPnANameMonetization: this.state.FPnANameMonetization ? this.state.FPnANameMonetization : this.state.FPnANameMonetization,
                SowID: this.state.Sow ? this.state.Sow : this.state.SowID,
                IsVDMonetizable: this.state.monetizableProjectSelectedOption ? this.state.monetizableProjectSelectedOption.label : (this.state.ideaItem.IsVDMonetizable ? this.state.ideaItem.IsVDMonetizable : ""),
                MonetizationStatus: this.state.monetizationStatusSelectedOption ? this.state.monetizationStatusSelectedOption.label : (this.state.ideaItem.MonetizationStatus ? this.state.ideaItem.MonetizationStatus : ""),
                IsMbbMonetizable: this.state.ideaItem.IsMbbMonetizable ? this.state.ideaItem.IsMbbMonetizable : "",


                ImpactTyepChanged: this.state.impactTagChangeSelected ? this.state.impactTagChangeSelected.label : "",
                DeliveryCategorizedSelect: this.state.deliveryCategorizedSelect ? this.state.deliveryCategorizedSelect.label : "",
                DeliveryComputedCorrectlySelected: this.state.deliveryComputedCorrectlySelected ? this.state.deliveryComputedCorrectlySelected.label : "",
                SignOffDeliverySelected: this.state.signOffDeliverySelected ? this.state.signOffDeliverySelected.label : null,

                PotentialImpactDollar: this.state.potentialDollar,
                updaterAction: "Update",

                //AI First
                IsIdeaReplicable: this.state.IsIdeaReplicableSelectedOption ? this.state.IsIdeaReplicableSelectedOption.label === "Yes" ? 1 : 0 : "",
                SolnComplexity: this.state.solutionComplexitySelectedOption ? this.state.solutionComplexitySelectedOption.label : "",
                IsSolnFeasible: this.state.solutionFeasibleSelectedOption ? this.state.solutionFeasibleSelectedOption.label === "Yes" ? 1 : 0 : "",
                IsAI: this.state.isAISelectedOption ? this.state.isAISelectedOption.label : "",
                LinkBRD: this.state.AIBRD ? this.state.AIBRD : "",
                AIReason: this.state.AIReason ? this.state.AIReason : "",


                // DB Fields
                DBProjectType: this.state.dataBridgeType ? this.state.dataBridgeType.label : this.state.ideaItem.DBProjectType,
                MUCID: this.state.mucIDSelectedOption ? this.state.mucIDSelectedOption.value : this.state.ideaItem.MUCID,
                MUCIDTitle: this.state.mucIDSelectedOption ? this.state.mucIDSelectedOption.label : this.state.ideaItem.MUCIDTitle,
                
                DB_COO: this.state.COOMappingSelected ? this.state.COOMappingSelected : "",
                DB_COOOHR: this.state.COOOHR ? this.state.COOOHR : "",
                
                ProcessOwner: this.state.processOwnerName ? this.state.processOwnerName : "",
                ProcessOwnerOHR: this.state.processOwnerOHR ? this.state.processOwnerOHR : "",
                processOnwerEmail: this.state.processOnwerEmail ? this.state.processOnwerEmail : "",

                DBTool: this.state.dbToolUsed ? this.state.dbToolUsed.label : this.state.ideasDB.DBTool,
                OtherDBTool: this.state.dbToolUsed && this.state.dbToolUsed.label === "Others" ? (this.state.otherDBToolUsedText ? this.state.otherDBToolUsedText : this.state.ideasDB.OtherDBTool) : this.state.ideasDB.OtherDBTool,

                IsSharedGOL: this.state.sharedToGOL ? this.state.sharedToGOL.label : this.state.ideasDB.IsSharedGOL,
                DB_GOL: this.state.sharedToGOL && this.state.sharedToGOL.label === "Yes" ? (this.state.DB_GOLNAME ? this.state.DB_GOLNAME : this.state.ideasDB.GOL) : this.state.ideasDB.GOL,
                DB_GOLOHR: this.state.sharedToGOL && this.state.sharedToGOL.label === "Yes" ? (this.state.DB_GOLOHR ? this.state.DB_GOLOHR : this.state.ideasDB.GOLOHR) : this.state.ideasDB.GOLOHR,

                IsSharedClient: this.state.sharedToClient ? this.state.sharedToClient.label : this.state.ideasDB.IsSharedClient,
                DB_ClientName: this.state.sharedToClient && this.state.sharedToClient.label === "Yes" ? (this.state.DB_clientName ? this.state.DB_clientName : this.state.ideasDB.ClientName) : this.state.ideasDB.ClientName,
                DB_ClientRole: this.state.sharedToClient && this.state.sharedToClient.label === "Yes" ? (this.state.DB_clientRole ? this.state.DB_clientRole : this.state.ideasDB.ClientRole) : this.state.ideasDB.ClientRole,

                IsSustenance: this.state.trackingApplicableSelectedOption ? this.state.trackingApplicableSelectedOption.label : this.state.ideasDB.IsSustenance,
                SustenanceFreq: this.state.trackingApplicableSelectedOption && this.state.trackingApplicableSelectedOption.label === "Yes" ? (this.state.sustenanceMetricFreq ? this.state.sustenanceMetricFreq.label : this.state.ideasDB.SustenanceFreq) : this.state.ideasDB.SustenanceFreq,
                SustenanceMetric: this.state.trackingApplicableSelectedOption && this.state.trackingApplicableSelectedOption.label === "Yes" ? (this.state.sustenanceMetrics ? this.state.sustenanceMetrics : this.state.ideasDB.SustenanceMetric) : this.state.ideasDB.SustenanceMetric,
                ModelSummary: this.state.modalsOperationsSelectedOption ? this.state.modalsOperationsSelectedOption.label : this.state.ideasDB.ModelSummary,

                // DB SPOC Questionnarie
                IsMUCTag: this.state.muciIDTaggedSelectedOption ? this.state.muciIDTaggedSelectedOption.label : this.state.ideasDB.IsMUCTag,
                IsDBTheme: this.state.dbThemeCapturedCorrectlySelectedOption ? this.state.dbThemeCapturedCorrectlySelectedOption.label : this.state.ideasDB.IsDBTheme,
                IsToolCorrect: this.state.toolsCapturedCorrectlySelectedOption ? this.state.toolsCapturedCorrectlySelectedOption.label : this.state.ideasDB.IsToolCorrect,
                IsModelCorrect: this.state.modelDetailsCapturedSelectedOption ? this.state.modelDetailsCapturedSelectedOption.label : this.state.ideasDB.IsModelCorrect,
                IsModelDeploy: this.state.modelDeploymentDetailsSelectedOption ? this.state.modelDeploymentDetailsSelectedOption.label : this.state.ideasDB.IsModelDeploy,
                IsSignOff: this.state.signOffMeetSelectedOption ? this.state.signOffMeetSelectedOption.label : this.state.ideasDB.IsSignOff,
                IsMonetization: this.state.monetizationDetailsCapturedSelectedOption ? this.state.monetizationDetailsCapturedSelectedOption.label : this.state.ideasDB.IsMonetization,
                IsSPocSustenance: this.state.susDetailsOkSelectedOption ? this.state.susDetailsOkSelectedOption.label : this.state.ideasDB.IsSPocSustenance,
                IsIssue: this.state.anyOtherIssueSelectedOption ? this.state.anyOtherIssueSelectedOption.label : this.state.ideasDB.IsIssue,
                IsException: this.state.exceptionAppliedSelectedOption ? this.state.exceptionAppliedSelectedOption.label : this.state.ideasDB.IsException,

                LDTLogs: LDTLogsPostData,

                Unit: this.state.Unit ? this.state.Unit :"",
                Segment: this.state.Segment ? this.state.Segment : ""


            }
            console.log("Param", param);
            // return;
            let finalJsonData = Checksum(param);
            let str1 = finalJsonData.slice(0, 15);
            let str2 = finalJsonData.slice(15, 30);
            let str3 = finalJsonData.slice(30, 40);
            let str4 = finalJsonData.slice(-10);
            let finalData = str3 + str1 + str4 + str2;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };
            fetch(`${SUBMIT_IDEA_URL}Checksum=${finalData}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    Toast.hide();
                    Toast.success('Updated successfully.', 3000, () => {
                        console.log(JSON.stringify(data));
                        window.location = "/view-all-ideas-page";
                    });

                });
        }
        else {
            if (!this.isExceedlimit) {
                Toast.fail('Some of the required fields for Idea are not set. Please set and resubmit.', 3000, () => { });
            }
        }
        return false;
    }














    renderClientApproval = () => {
        let options = [];
        this.state.clientApprovalOptions.map((item) => {
            options.push({
                value: item.value,
                label: item.label
            })
            return options;
        });
        return options;

    }

    addMonetizableValues = (e) => {
        let MonetizedBiArray = [];
        let fteValue = 0;
        let dollarValue = 0;
        this.state.businessImpactValueArray.filter((item) => {
            if (item.IsMonetized === "Yes") {
                MonetizedBiArray.push(item)
            }
        })
        //console.log(MonetizedBiArray)
        if (MonetizedBiArray) {
            const groupedData = MonetizedBiArray.reduce((accumulator, currentValue) => {
                const ImpactType = currentValue.ImpactType;
                const fteValue = parseFloat(currentValue.fteValue) || 0.0;
                const dollarValue = parseFloat(currentValue.dollarValue) || 0.0;
                const existingRow = accumulator.find(row => row.ImpactType === ImpactType);
                if (existingRow) {
                    existingRow.fteValue += fteValue
                    existingRow.dollarValue += dollarValue
                }
                else {
                    accumulator.push({ ImpactType, fteValue, dollarValue });
                }
                return accumulator;
            }, []);
            if (groupedData.length === 0) {
                fteValue = 0
                dollarValue = 0
            } else {
                fteValue = groupedData[0].fteValue
                dollarValue = groupedData[0].dollarValue
            }
            this.setState({
                totalMonetizedFTE: fteValue,
                totalMonetizedDollar: dollarValue
            }, () => { })
        }
    }


    handleImpactTagChange = (e) => {
        if (e !== null && e !== undefined) {
            this.setState({ impactTagChangeSelected: e }, () => { })
        }
    }
    handleDeliveryCategorizedChange = (e) => {
        if (e !== null && e !== undefined) {
            this.setState({ deliveryCategorizedSelect: e }, () => { })
        }
    }
    handledeliveryComputedCorrectlySelectedChange = (e) => {
        if (e !== null && e !== undefined) {
            this.setState({ deliveryComputedCorrectlySelected: e }, () => { })
        }
    }
    handleSignOffDeliveryChange = (e) => {
        if (e !== null && e !== undefined) {
            this.setState({ signOffDeliverySelected: e }, () => { })
        }
    }

    showCommentModal = (modalId) => {
        if (modalId === 3) {
            this.setState({ qcCommentLog: !this.state.qcCommentLog })
        }

    }

    renderImpactOptions = () => {
        let options = [];
        this.state.impactOptions.map((item) => {
            options.push({
                value: item.value,
                label: item.label
            })
            return options;
        });
        return options;
    }
    handleleanProjectLastCommentChange = (text) => {
        this.setState({ leanProjectLastComment: text }, () => {
            console.log(text)
        })
    }


    // value delivery function starts
    pull_data = ({ businessImpactValueArray }) => {
        //console.log(businessImpactValueArray)
        this.setState({ businessImpactValueArray: businessImpactValueArray }, () => {
            this.newBusinessImpactSummary()
        })
        let dollarTotal = 0.0;
        let fteValue = 0.0;
        this.state.businessImpactValueArray.map((item) => {
            if (item && item.DollarImpact) {
                dollarTotal += parseFloat(item.DollarImpact);
            }
            if (item && item.dollarValue) {
                dollarTotal += parseFloat(item.dollarValue);
            }
            if (item && item.FTEImpact) {
                fteValue += parseFloat(item.FTEImpact)
            }
            if (item && item.fteValue) {
                fteValue += parseFloat(item.fteValue)
            }
        })
        this.setState({ finalImpactDollar: dollarTotal }, () => { })
        this.setState({ finalImpactFTE: fteValue }, () => { })
    }
    remove_bi_data_after_delete = (filteredIdeaArray) => {
        //console.log("bi recieved in pull data for delete function",filteredIdeaArray)
        if (filteredIdeaArray) {
            this.setState({ businessImpactValueArray: filteredIdeaArray }, () => {
                this.newBusinessImpactSummary()
                let dollarTotal = 0.0;
                let fteValue = 0.0;
                this.state.businessImpactValueArray.map((item) => {
                    if (item && item.DollarImpact) {
                        dollarTotal += parseFloat(item.DollarImpact);
                    }
                    if (item && item.dollarValue) {
                        dollarTotal += parseFloat(item.dollarValue);
                    }
                    if (item && item.FTEImpact) {
                        fteValue += parseFloat(item.FTEImpact)
                    }
                    if (item && item.fteValue) {
                        fteValue += parseFloat(item.fteValue)
                    }
                })
                this.setState({ finalImpactDollar: dollarTotal }, () => {
                    //console.log("inside call back function $ total",this.state.finalImpactDollar)
                })
                this.setState({ finalImpactFTE: fteValue }, () => {
                    //console.log("inside call back function fte value",this.state.finalImpactFTE)
                })

            })
        }

    }

    pull_file_data = ({ fileName, fileType, fileContent, showClientApprovalFields
        , SowID, FPnAOHRMonetization, FPnANameMonetization }) => {
        if (fileName) {
            this.setState({ FPnAFileNameMonetization: fileName }, () => { })
        }
        if (fileType) {
            this.setState({ FPnAFileTypeMonetization: fileType }, () => { })
        }
        if (fileContent) {
            this.setState({ FPnAFileMonetization: fileContent }, () => { })
        }

        this.setState({ showClientApprovalFields: showClientApprovalFields }, () => { })
        this.setState({
            SowID: SowID,
            FPnAOHRMonetization: FPnAOHRMonetization,
            FPnANameMonetization: FPnANameMonetization
        }, () => { })
    }
    pull_monetizedTotal = ({ fteValue, dollarValue }) => {
        if (fteValue) {
            this.setState({ totalMonetizedDollar: fteValue }, () => { })
        }
        if (dollarValue) {
            this.setState({ totalMonetizedFTE: dollarValue }, () => { })
        }
    }
    pull_monetizableProject = (e) => {
        if (e) {
            this.setState({ monetizableProjectSelectedOption: e }, () => { })
        }
    }
    pull_monetizableStatus = (e) => {
        this.setState({ monetizationStatusSelectedOption: e }, () => { })
    }
    pull_monetization_fpna_name = (localFpnaName) => {
        this.setState({ FPnANameMonetization: localFpnaName }, () => {
            //console.log("fpna name from pull_file_function",this.state.FPnANameMonetization)
        })
    }
    pull_monetization_fpna_ohr = (localFpnaohr) => {
        //console.log("fpna ohr from pull function => ",localFpnaohr)
        this.setState({ FPnAOHRMonetization: localFpnaohr }, () => { })
    }
    handleVDTypeChange = ({ e }) => {
        // console.log(e)
        if (e) {
            this.setState({ impactOptionSelected: e, impactChanged: true }, () => {
                //console.log("after on change set state",this.state.impactOptionSelected)
                if (this.state.impactOptionSelected.label === "No Value Delivery") {
                    this.setState({
                        showClientFields: false,
                        showGOLFields: false,
                        finalImpactDollar: 0,
                        monetizableProjectSelectedOption: null,
                    }, () => { })
                }
                if (this.state.impactOptionSelected.label === "Value Delivered to Client") {
                    this.setState({ showClientFields: true, showGOLFields: false })
                }
                if (this.state.impactOptionSelected.label === "Value Delivered to Genpact") {
                    this.setState({ showClientFields: false, showGOLFields: true })
                }
                if (this.state.impactOptionSelected.label === "Value Delivered to Client + Genpact") {
                    this.setState({ showClientFields: true, showGOLFields: true })
                }
            })
        }
    }

    fetchFPAOHRforIncreaseInRevenue = (e) => {
        if (e === true) {
            this.setState({ appropriateAttachment: true }, () => { })
        } else {
            this.setState({ appropriateAttachment: false }, () => { })
        }
    }
    pull_monetization_sowId = (localSowId) => {
        this.setState({ SowID: localSowId }, () => {
            //console.log("sow id from pull_monetization_sowId function =>",this.state.SowID)
        })
    }

    pullMonetizationGOLName = (localGOLName) => {
        this.setState({ golMonetizationName: localGOLName }, () => {
            //console.log(this.state.golMonetizationName)
        })
    }
    pullMonetizationGOLOhr = (localGOLOhr) => {
        this.setState({ golMonetizationOhr: localGOLOhr }, () => {
            //console.log(this.state.golMonetizationOhr)
        })
    }
    pullCategoryNotListed = (localShowHref) => {
        this.setState({ showHrefModal: localShowHref }, () => {
            console.log("hrf modal =>", this.state.showHrefModal)
        });
    };
    // value delivery function ends



    // function for FPNAGOL component starts here

    // ppt deck file details
    pullPPTDeck = ({ fileName, fileType, fileContent }) => {
        this.setState({ filename1: fileName, fileType1: fileType, base64File1: fileContent }, () => {
            //console.log("filename ",this.state.filename1, "file type ",this.state.fileType1, "content ",this.state.base64File1)
        })
    }
    pullClientDocName = (fileName) => {
        this.setState({ signOffFilename: fileName, }, () => {
            //console.log("client approval name =>",this.state.signOffFilename)
        })
    }
    pullClientDocType = (fileType) => {
        this.setState({ signOffFileType: fileType }, () => {
            //console.log("client approval type =>",this.state.signOffFileType)
        })
    }
    pullClientDocContent = (fileContent) => {
        this.setState({ signOffContent: fileContent }, () => {
            //console.log("client approval contnet =>",this.state.signOffContent)
        })
    }

    // gol file details
    pullgolFileName = (fileName) => {
        this.setState({ filename3: fileName }, () => {
            //console.log("gol approval doc name",this.state.filename3)      
        })
    }
    pullgolFileType = (fileType) => {
        this.setState({ fileType3: fileType }, () => {
            //console.log("gol approval doc type",this.state.fileType3)      
        })
    }
    pullgolContent = (fileContent) => {
        this.setState({ base64File3: fileContent }, () => {
            //console.log("gol approval doc content",this.state.base64File3)      
        })
    }

    // fpna file details
    pullfpnafileName = (fileName) => {
        this.setState({ filename4: fileName }, () => {
            //console.log("fpna doc name",this.state.filename4)
        })
    }
    pullfpnafileType = (fileType) => {
        this.setState({ fileType4: fileType }, () => {
            //console.log("fpna doc type",this.state.fileType4)
        })
    }
    pullfpnafileContent = (fileContent) => {
        this.setState({ base64File4: fileContent }, () => {
            //console.log("fpna doc file content",this.state.base64File4)
        })
    }

    pullClientName = (e) => {
        this.setState({ clientName: e }, () => {
            //console.log(this.state.clientName)
        })
    }
    pullClientRole = (e) => {
        this.setState({ clientRole: e }, () => {
            //console.log(this.state.clientRole)
        })
    }
    pullClientSignOff = (e) => {
        this.setState({ clientSignOffDate: e }, () => {
            // console.log(this.state.clientSignOffDate)
        })
    }
    pullFpnaName = (name) => {
        this.setState({ fpnaName: name }, () => {
            //console.log(this.state.fpnaName)
        })

    }
    pullGolName = (name) => {
        this.setState({ golFullname: name }, () => {
            //console.log(this.state.golFullname)
        })
    }
    pullFpnaOhr = (e) => {
        this.setState({ fpnaOhr: e }, () => {
            //console.log(this.state.fpnaOhr)
        })
    }
    pullGolOhr = (e) => {
        this.setState({ golOhr: e }, () => {
            //console.log(this.state.golOhr)
        })
    }
    pull_fileDetails = (obj) => {
        this.setState({ golFpnaFileDetails: obj }, () => {
            //console.log(this.state.golFpnaFileDetails)
        })
    }

    // function for FPNAGOL component ends here


    renderFieldsOptions = () => {
        let options = [];
        this.state.impactTagged.map((item) => {
            options.push({
                value: item.impactTaggedValue,
                label: item.impactTaggedLabel
            })
            return options;
        });
        return options;
    }


    // AI First function starts here
    handleIsIdeaReplicableChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.IsIdeaReplicableSelectedOption = selectStyle(true);
        this.setState({ selectStyle: styleStateObj, IsIdeaReplicableSelectedOption: e })
    };

    

    handleSolutionFeasibleChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.solutionFeasibleSelectedOption = selectStyle(true);
        this.setState({ selectStyle: styleStateObj, solutionFeasibleSelectedOption: e })
    };
    handleSolutionComplexityChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.solutionComplexitySelectedOption = selectStyle(true);
        this.setState({ selectStyle: styleStateObj, solutionComplexitySelectedOption: e })
    };

    handleIsAIChange = (e) => {
        this.setState({ AIReason: "", AIBRD: "" }, () => { });
        let styleStateObj = this.state.selectStyle;
        styleStateObj.isAISelectedOption = selectStyle(true);
        this.setState({ selectStyle: styleStateObj, isAISelectedOption: e })
    };

    handleAIReasonChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.AIReason = selectStyle(true);
        this.setState({ selectStyle: styleStateObj, AIReason: e })
    }
    handleAIBRDChange = (e) => {
        let styleStateObj = this.state.selectStyle;
        styleStateObj.AIBRD = selectStyle(true);
        this.setState({ selectStyle: styleStateObj, AIBRD: e })
    }


    // LDT lead function starts here
    handleIsQBRChange = (e, receievedIndex) => {
        if (this.state.LDTLogs) {
            let LDTLogs = [...this.state.LDTLogs];
            LDTLogs[receievedIndex].IsQBR = e;
            LDTLogs[receievedIndex].UserName = this.sessionData.profile.lastName + ", " + this.sessionData.profile.firstName;
            LDTLogs[receievedIndex].UserOHR = this.sessionData.profile.oHRId;
            this.setState({
                LDTLogs: LDTLogs
            }, () => {
                console.log(this.state.LDTLogs)
            });
        }
    }
    handleQBRDateChange = (e, receievedIndex) => {
        console.log("date receieved", e)
        console.log("index receieved", receievedIndex)
        if (e !== null && e !== undefined) {
            if (new Date(e) > new Date()) {
                window.alert("QBR date cannot be greater than current date")
                return;
            } else {
                if (this.state.LDTLogs) {
                    let LDTLogs = [...this.state.LDTLogs];
                    LDTLogs[receievedIndex].QBRDate = e;
                    this.setState({
                        LDTLogs: LDTLogs
                    }, () => { });
                    console.log(this.state.LDTLogs)
                }
            }
        }
    }
    handleLDTApprovalCommentChange = (e, receievedIndex) => {
        if (this.state.LDTLogs) {
            let LDTLogs = [...this.state.LDTLogs];
            LDTLogs[receievedIndex].LDTComments = e;
            this.setState({
                LDTLogs: LDTLogs
            });
        }
    }
    toggleLDTApprovalCommentLogModal = () => {
        this.setState({ displayLDTCommentLog: true }, () => { })
    }
    formatDateLatest = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = currentDate.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    // ... DB function starts here....

    handleDataBridgeTypeChange = (e) => {
        this.setState(
            {
                dataBridgeType: e
            },
            () => { }
        );
    };

    handleMUCIDChange = (e) => {
        this.setState(
            {
                mucIDSelectedOption: e
            },
            () => { }
        );
    };
    handleSharedToClientChange = (e) => {
        this.setState({
            DB_clientRole: "",
            DB_clientName: "",
            CTestimonyFileName: ""
        }, () => { })
        this.setState(
            {
                sharedToClient: e

            },
            () => { }
        );
    };
    handleSharedToGOLChange = (e) => {
        this.setState({
            DB_GOLOHR: "",
            DB_GOLNAME: ""
        }, () => { })
        this.setState(
            {
                sharedToGOL: e
            },
            () => { }
        );
    };
    handledbToolUsedChange = (e) => {
        this.setState({ otherDBToolUsedText: "" })
        this.setState(
            {
                dbToolUsed: e
            },
            () => { }
        );
    };

    handleSharedToClientRoleTextChange = (e) => {
        this.setState({
            DB_clientRole: e,
        });
    };

    handleOtherDBToolUsedChange = (e) => {
        let nonWhiteSpace= e.replace(/^\s+|\s+$/gm,'');
        if(nonWhiteSpace.length < 250){
            this.setState({otherDBToolUsedText: e},()=>{
                
            });
        }else{
            const trimmedValue = nonWhiteSpace.slice(0, 250);
            this.setState({otherDBToolUsedText:trimmedValue},()=>{
                console.log("other db tools used =>",this.state.otherDBToolUsedText)
            });
        }
    };

    uploadClientAttachment = (event, fileType) => {
        var file = event.target.files[0];
        if (file) {
            var currFilename = file.name;
            var currFilesize = file.size;

            if (fileType === "clientTestimony") {
                if (currFilesize > 5000000) {
                    alert(
                        "The size of the document you are uploading, is more than 5MB. Please upload a smaller document."
                    );
                    return;
                }
            }

            if (currFilename === this.state.filename1) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === this.state.signOffFilename) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === this.state.filename3) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === this.state.filename4) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }



            var validExtensions = ['.xls', '.xlsx', '.xlsm', '.csv', '.doc', '.docx', '.pdf', '.ppt', '.pptx', '.pdf', '.jpeg', '.jpg', '.png', '.msg', '.png', '.mpp'];
            var currFileExtension = currFilename.substring(currFilename.lastIndexOf("."));
            var isFileTypeValid = validExtensions.filter((val) => val.toLowerCase().includes(currFileExtension.toLowerCase()));
            if (!isFileTypeValid) {
                alert("The type of document you are uploading is not supported. Please upload only supported file types.");
                return;
            }
            var reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = () => {
                let fileContents = btoa(reader.result);

                if (fileType === "clientTestimony") {
                    let fileName = file.name;
                    let fileType = file.type;
                    let fileContent = fileContents;
                    this.setState(
                        {
                            CTestimonyFileName: fileName,
                            CTestimonyFileType: fileType,
                            CTestimony: fileContent,
                        },
                        () => { }
                    );
                }
            };
            reader.onerror = function () {
                console.log("There are some problems loading document contents.");
            };
        }
    };

    deleteClientTestimonyFile = () => {
        this.setState({
            CTestimonyFileName: "",
            CTestimonyFileType: ""
        }, () => { })
    }



    // ..auto fetch process owner function starts here....

    handleProcessOwnerOhronCut = (event) => {
        if (event.target.value.length === 9) {
            this.handleProcessOwnerChange();
        } else if (event.target.value.length === 0) {
            this.setState({ processOwnerOHR: "", processOwnerName: "" }, () => { });
            return null;
        } else {
            Toast.fail("Process owner OHR should be exactly of 9 digits", 3000, () => { });
            this.setState({ processOwnerName: "" }, () => { });
        }
    };

    handleProcessOwnerChange = (event) => {
        var numbers = /^[0-9]+$/;
        if (event !== null && event !== undefined) {
            if (
                event.target.value.match(numbers) &&
                event.target.value.length !== 0
            ) {
                if (event.target.value.length === 9) {
                    let styleStateObj = this.state.selectStyle;
                    styleStateObj.processOwnerOHR = textBoxValidation(true);
                    this.setState(
                        {
                            selectStyle: styleStateObj,
                            processOwnerOHR: event.target.value,
                        },
                        () => {
                            if (this.state.processOwnerOHR) {
                                this.handleSubmitProcessOHRChange();
                            }
                        }
                    );

                } else {
                    this.setState(
                        { processOwnerName: "", processOwnerOHR: "" },
                        () => { }
                    );
                }
            } else {
                this.setState({ processOwnerName: "", processOwnerOHR: "" }, () => { });
            }
        }
    };

    handleSubmitProcessOHRChange = () => {
        let golEmail = this.state.processOwnerOHR + "@genpact.com";
        this.setState({ processOnwerEmail: golEmail }, () => {
            this.fetchSubmitProcessOHRDetails();
        });

    };

    fetchSubmitProcessOHRDetails = async () => {
        Toast.loading("Fetching Process Owner Name. Please wait...", () => { });
        try {
            const param = {
                EntityType: "SearchOHR",
                EntityName: this.state.processOwnerOHR,
            };
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param),
            };
            const response = await fetch(GET_METADATA, requestOptions);
            const data = await response.json();
            console.log(data);
            let requestByName = data.oUser ? data.oUser[0].DisplayName : null;
            if (requestByName === "") {
                requestByName = data.PowerAppUsers
                    ? data.PowerAppUsers.length > 0
                        ? data.PowerAppUsers[0].pg_username
                        : ""
                    : "";
            }

            if (requestByName === "" || requestByName === undefined || requestByName === null) {
                Toast.fail(
                    "We could not find Process Owner name based on the entered OHR. Please check and reenter.",
                    5000,
                    () => { }
                );
                this.setState({ processOwnerName: "" }, () => { });
            } else {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.processOwnerOHR = textBoxValidation(true);
                styleStateObj.processOwnerName = textBoxValidation(true);

                this.setState(
                    {
                        processOwnerName: requestByName,
                        selectStyle: styleStateObj,
                    },
                    () => {
                        console.log(
                            "process owner name is =>",
                            this.state.processOwnerName
                        );
                    }
                );
            }
            Toast.hide();
        } catch (error) {
            Toast.hide();
            Toast.fail("Please enter a valid Owner OHR", 5000, () => {
                console.log(JSON.stringify(error));
            });
            this.setState({ processOwnerOHR: "", processOwnerName: "" }, () => { });
        }
    };
    // ..auto fetch process owner function ends here....

    // ..auto fetch GOLOHR function starts here....
    handleDBGOLOhronCut = (event) => {
        if (event.target.value.length === 9) {
            this.handleDBGOLOHRChange();
        } else if (event.target.value.length === 0) {
            this.setState({ DB_GOLOHR: "", DB_GOLNAME: "" }, () => { });
            return null;
        } else {
            Toast.fail("GOL OHR should be exactly of 9 digits", 3000, () => { });
            this.setState({ DB_GOLNAME: "" }, () => { });
        }
    };
    handleDBGOLOHRChange = (event) => {
        var numbers = /^[0-9]+$/;
        if (event !== null && event !== undefined) {
            if (
                event.target.value.match(numbers) &&
                event.target.value.length !== 0
            ) {
                if (event.target.value.length === 9) {
                    let styleStateObj = this.state.selectStyle;
                    styleStateObj.DB_GOLOHR = textBoxValidation(true);
                    this.setState(
                        {
                            selectStyle: styleStateObj,
                            DB_GOLOHR: event.target.value,
                        },
                        () => {
                            if (this.state.DB_GOLOHR) {
                                this.handleSubmitDBGOLOHRChange();
                            }
                        }
                    );

                } else {
                    this.setState({ DB_GOLOHR: "", DB_GOLNAME: "" }, () => { });
                }
            } else {
                this.setState({ DB_GOLOHR: "", DB_GOLNAME: "" }, () => { });
            }
        }
    };

    handleSubmitDBGOLOHRChange = () => {
        let golEmail = this.state.DB_GOLOHR + "@genpact.com";
        this.setState({ DB_GOLEmail: golEmail }, () => {
            this.fetchSubmitDBGOLOHRDetails();
        });

    };

    fetchSubmitDBGOLOHRDetails = async () => {
        Toast.loading("Fetching GOL name. Please wait...", () => { });
        try {
            const param = {
                EntityType: "SearchOHR",
                EntityName: this.state.DB_GOLOHR,
            };
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param),
            };
            const response = await fetch(GET_METADATA, requestOptions);
            const data = await response.json();
            console.log(data);
            let requestByName = data.oUser ? data.oUser[0].DisplayName : null;
            console.log(requestByName);
            if (requestByName === "") {
                requestByName = data.PowerAppUsers
                    ? data.PowerAppUsers.length > 0
                        ? data.PowerAppUsers[0].pg_username
                        : ""
                    : "";
            }

            if (requestByName === "" || requestByName === undefined || requestByName === null) {
                Toast.fail(
                    "We could not find GOL name based on the entered OHR. Please check and reenter.",
                    5000,
                    () => { }
                );
                this.setState({ DB_GOLNAME: "" }, () => { });
            } else {
                let styleStateObj = this.state.selectStyle;
                styleStateObj.DB_GOLOHR = textBoxValidation(true);
                styleStateObj.DB_GOLNAME = textBoxValidation(true);
                this.setState(
                    {
                        DB_GOLNAME: requestByName,
                        selectStyle: styleStateObj,
                    },
                    () => {
                        console.log("gol name after set function ", this.state.DB_GOLNAME);
                    }
                );
            }
            Toast.hide();
        } catch (error) {
            Toast.hide();
            Toast.fail("Please enter a valid GOL OHR", 5000, () => {
                console.log(JSON.stringify(error));
            });
            this.setState({ DB_GOLOHR: "", DB_GOLNAME: "" }, () => { });
        }
    };
    // ..auto fetch GOLOHR function ends here....

    handleDBClientNameChange = (e) => {
        this.setState({
            DB_clientName: e,
        });
    }

    handleModalsOperationsChange = (e) => {
        this.setState(
            {
                modalsOperationsSelectedOption: e
            },
            () => { }
        );
    };
    handleTrackingChange = (e) => {
        this.setState({ sustenanceMetricFreq: [] })
        this.setState(
            {
                trackingApplicableSelectedOption: e
            },
            () => {

            }
        );
    };
    handleSusFreqChange = (e) => {
        this.setState(
            {
                sustenanceMetricFreq: e,
            },
            () => { }
        );
    };
    handleExceptionAppliedChange = (e) => {
        this.setState(
            {
                exceptionAppliedSelectedOption: e
            },
            () => { }
        );
    };
    handleAnyOtherIssueChange = (e) => {
        this.setState(
            {
                anyOtherIssueSelectedOption: e
            },
            () => { }
        );
    };

    handleSusDetailsOkChange = (e) => {
        this.setState(
            {
                susDetailsOkSelectedOption: e
            },
            () => { }
        );
    };
    handleMonetizationDetailsCapturedChange = (e) => {
        this.setState(
            {
                monetizationDetailsCapturedSelectedOption: e
            },
            () => { }
        );
    };

    handleSignOffMeetChange = (e) => {
        this.setState(
            {
                signOffMeetSelectedOption: e
            },
            () => { }
        );
    };

    handleModeldeploymentDetailsChange = (e) => {
        this.setState(
            {
                modelDeploymentDetailsSelectedOption: e
            },
            () => { }
        );


    };
    handleModelDetailsCapturedChange = (e) => {
        this.setState(
            {
                modelDetailsCapturedSelectedOption: e
            },
            () => { }
        );

    };


    handletoolsCapturedCorrectlyCorrectlyChange = (e) => {
        this.setState(
            {
                toolsCapturedCorrectlySelectedOption: e
            },
            () => { }
        );


    };

    handledbThemeCorrectlyCapturedChange = (e) => {
        this.setState(
            {
                dbThemeCapturedCorrectlySelectedOption: e
            },
            () => { }
        );
    };

    handlemuicIDTaggedCorrectlyChange = (e) => {
        this.setState(
            {
                muciIDTaggedSelectedOption: e
            },
            () => { }
        );
    };

    templateRedirect = (template) => {
        if (this.state.ideaItem.LeanID !== "" || this.state.ideaItem.LeanID !== null || this.state.ideaItem.LeanID !== undefined || !this.state.ideaItem.LeanID) {
            if (template === "Descriptive") {
                if (this.state.RecType === "" || this.state.RecType === "DA") {
                    setTimeout(() => {
                        this.savePartialDBData();
                    }, 2000);
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: "/descriptive-analytics-template",
                            state: this.state.ideaItem.IdeaId,
                        });
                    }, 2000);

                }
                if (this.state.RecType && this.state.RecType === "DPA") {
                    if (window.confirm("User can only save one template among Descriptive, Diagnostic or prescriptive template. If you wish to save another template the previous template data will be erased. Do you wish to continue ?")) {
                        setTimeout(() => {
                            this.deletePartialDBData(template);
                        }, 1000);

                        setTimeout(() => {
                            this.savePartialDBData();
                        }, 1000);

                    }
                }
            }
            if (template === "Diagnostic") {
                if (this.state.RecType === "" || (this.state.ideaItem.DBProjectType && this.state.ideaItem.DBProjectType === "Diagnostic")) {
                    setTimeout(() => {
                        this.savePartialDBData();
                    }, 1000);
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: "/diagnostic-or-prescriptive-template",
                            state: this.state.ideaItem.IdeaId,
                            templateType: template
                        })
                    }, 1000);

                }
                if (this.state.RecType && (this.state.RecType === "DPA" || this.state.RecType === "DA") && (this.state.ideaItem.DBProjectType && this.state.ideaItem.DBProjectType !== "Diagnostic")) {
                    if (window.confirm("User can only save one template among Descriptive, Diagnostic or prescriptive template. If you wish to save another template the previous template data will be erased. Do you wish to continue ?")) {
                        setTimeout(() => {
                            this.deletePartialDBData(template);
                        }, 1000);
                        setTimeout(() => { this.savePartialDBData(); }, 1000);
                    }
                }
            }
            if (template === "Prescriptive") {
                if (this.state.RecType === "" || (this.state.ideaItem.DBProjectType && this.state.ideaItem.DBProjectType === "Prescriptive")) {
                    setTimeout(() => {
                        this.savePartialDBData();
                    }, 1000);
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: "/diagnostic-or-prescriptive-template",
                            state: this.state.ideaItem.IdeaId,
                            templateType: template
                        })
                    }, 1000);

                }
                if (this.state.RecType && (this.state.RecType === "DPA" || this.state.RecType === "DA") && (this.state.ideaItem.DBProjectType && this.state.ideaItem.DBProjectType !== "Prescriptive")) {
                    if (window.confirm("User can only save one template among Descriptive, Diagnostic or prescriptive template. If you wish to save another template the previous template data will be erased. Do you wish to continue ?")) {
                        setTimeout(() => {
                            this.deletePartialDBData(template);
                        }, 1000);
                        setTimeout(() => { this.savePartialDBData(); }, 1000);


                    }
                }
            }

            if (template === "Predictive") {
                this.savePartialDBData();
                this.props.history.push({
                    pathname: "/predictive-template",
                    state: this.state.ideaItem.IdeaId,
                });

            }
        }
        else {
            Toast.fail("Only Projects which have been converted into lean can only be viewed", 3000, () => { })
        }
    }

    renderMUCIDOptions = () => {
        let options = [];
        let databridgeuseCase = "";
        this.state.mucIDOptionsfromMetaData.map((item, index) => {
            databridgeuseCase = item.pg_databridgeusecasecalculated.toString().toLowerCase();
            options.push({
                value: index,
                label: item.pg_databridgeusecasecalculated,
            });
            return true;
        });
        return options;
    }

    handleSustenanceMetricsTextChange = (e) => {
        let nonWhiteSpace= e.replace(/^\s+|\s+$/gm,'');
        if(e.length < 50){
            this.setState({ sustenanceMetrics: e });
        }else{
            const trimmedValue = nonWhiteSpace.slice(0,50);
            this.setState({ sustenanceMetrics: trimmedValue });
        }
        
    }

    handleSustenanceTrackingChange = (e) => {
        this.setState({ sustenanceTrackingMonth: e })
    }

    fetchClientTestimonyContent = () => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + this.accessToken,
                },
            };
            let clientTestimonyFileType = this.state.ideaItem.CTestimonyFileType;
            fetch(`${GET_IDEA_URL}IdeaId=${this.props.location.state}&${PPT_CONTENT}=true&clientTestimonycontent=true`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    const clientTestimonyFileMetadata = data.fpnaContent ? data.fpnaContent : "";
                    const clientTestimonyFileContent = "data:" + clientTestimonyFileType + ";base64," + clientTestimonyFileMetadata;
                    this.setState(
                        {
                            CTestimony: clientTestimonyFileContent,
                        },
                        () => {
                            resolve();
                            Toast.hide();
                        }
                    );
                })
                .catch((error) => {
                    Toast.fail(
                        "Error while Client Testimony file content. Please try again!",
                        3000,
                        () => { }
                    );
                    resolve();
                });
        });
    };
    downloadClientTestimony = () => {
        Toast.loading("Fetching Client Testimony content...", () => { });
        this.fetchClientTestimonyContent().then(() => {
            var fileData = this.state.CTestimony;
            var fileName = this.state.ideaItem.CTestimonyFileName;
            var w = window.open("");
            const downloadLink = w.document.createElement("a");
            downloadLink.href = fileData;
            downloadLink.download = fileName;
            downloadLink.click();
            Toast.hide();
        });
    };


    loadCOO = () => {
        if (this.state.ideaItem.TransformationLeverName === "Data") {
            this.state.COOMapping && this.state.COOMapping.map((item, index) => {
                if (item.pg_title === this.state.ideaItem.VerticalManual) {
                    this.setState({ COOMappingSelected: item.pg_value }, () => { })
                }
                if (item.pg_title === this.state.ideaItem.VerticalManual) {
                    this.setState({ COOOHR: item.pg_value2 }, () => { })
                }
            })
        }
    }


    savePartialDBData = () => {
        let param = {};
        param = {
            IdeaId: this.props.location.state,
            case: "new",
            casetype: "DBPartialSave",
            updaterRole: "Admin",

            DB_COO: this.state.COOMappingSelected ? this.state.COOMappingSelected : "",
            DB_COOOHR: this.state.COOOHR ? this.state.COOOHR : "",

            ProcessOwner: this.state.processOwnerName ? this.state.processOwnerName : "",
            ProcessOwnerOHR: this.state.processOwnerOHR ? this.state.processOwnerOHR : "",
            processOnwerEmail: this.state.processOnwerEmail ? this.state.processOnwerEmail : "",

            IsSharedGOL: this.state.sharedToGOL ? this.state.sharedToGOL.label : this.state.ideasDB.IsSharedGOL,
            DB_GOL: this.state.sharedToGOL && this.state.sharedToGOL.label === "Yes" ? (this.state.DB_GOLNAME ? this.state.DB_GOLNAME : this.state.ideasDB.GOL) : this.state.ideasDB.GOL,
            DB_GOLOHR: this.state.sharedToGOL && this.state.sharedToGOL.label === "Yes" ? (this.state.DB_GOLOHR ? this.state.DB_GOLOHR : this.state.ideasDB.GOLOHR) : this.state.ideasDB.GOLOHR,

            IsSharedClient: this.state.sharedToClient ? this.state.sharedToClient.label : this.state.ideasDB.IsSharedClient,
            DB_ClientName: this.state.sharedToClient && this.state.sharedToClient.label === "Yes" ? (this.state.DB_clientName ? this.state.DB_clientName : this.state.ideasDB.ClientName) : this.state.ideasDB.ClientName,
            DB_ClientRole: this.state.sharedToClient && this.state.sharedToClient.label === "Yes" ? (this.state.DB_clientRole ? this.state.DB_clientRole : this.state.ideasDB.ClientRole) : this.state.ideasDB.ClientRole,


            DBProjectType: this.state.dataBridgeType ? this.state.dataBridgeType.label : "",
            MUCID: this.state.mucIDSelectedOption ? this.state.mucIDSelectedOption.value : this.state.ideaItem.MUCID,
            MUCIDTitle: this.state.mucIDSelectedOption ? this.state.mucIDSelectedOption.label : this.state.ideaItem.MUCIDTitle,
            DBTool: this.state.dbToolUsed ? this.state.dbToolUsed.label : "",
            OtherDBTool: this.state.dbToolUsed && this.state.dbToolUsed.label === "Others" ? (this.state.otherDBToolUsedText ? this.state.otherDBToolUsedText : "") : "",

            IsSustenance: this.state.trackingApplicableSelectedOption ? this.state.trackingApplicableSelectedOption.label : "",
            SustenanceFreq: this.state.trackingApplicableSelectedOption && this.state.trackingApplicableSelectedOption.label === "Yes" ? (this.state.sustenanceMetricFreq ? this.state.sustenanceMetricFreq.label : "") : "",
            SustenanceMetric: this.state.trackingApplicableSelectedOption && this.state.trackingApplicableSelectedOption.label === "Yes" ? (this.state.sustenanceMetrics ? this.state.sustenanceMetrics : "") : "",
            ModelSummary: this.state.modalsOperationsSelectedOption ? this.state.modalsOperationsSelectedOption.label : "",


        }
        console.log(param);
        let finalJsonData = Checksum(param);
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + this.accessToken,
            },
            body: JSON.stringify(param)
        };
        fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
            .then(response => response.json())

    }


    deletePartialDBData = async (template) => {
        try {
            Toast.loading("Deleting template data...", () => { });
            let param = {};
            param = {
                IdeaId: this.props.location.state,
                case: "new",
                casetype: "partialDeleteDPA",
                updaterRole: "Idea Owner",
                ProjectID: this.state.ideaItem.LeanID ? this.state.ideaItem.LeanID : "",
                DBProjectType: this.state.dataBridgeType ? this.state.dataBridgeType.label : "",
            }
            console.log(param);
            let finalJsonData = Checksum(param);
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization":  "Bearer " + this.accessToken,
                },
                body: JSON.stringify(param)
            };

            const response = await fetch(`${REVIEW_IDEA_URL}Checksum=${finalJsonData}`, requestOptions)
            const data = await response.json();
            Toast.hide();
            Toast.success('Template data deleted successfully!', 3000, () => {
                if (template === "Descriptive") {
                    this.props.history.push({
                        pathname: "/descriptive-analytics-template",
                        state: this.state.ideaItem.IdeaId,
                    });
                }
                if (template === "Diagnostic") {
                    this.props.history.push({
                        pathname: "/diagnostic-or-prescriptive-template",
                        state: this.state.ideaItem.IdeaId,
                        templateType: template
                    })
                }
                if (template === "Prescriptive") {
                    this.props.history.push({
                        pathname: "/diagnostic-or-prescriptive-template",
                        state: this.state.ideaItem.IdeaId,
                        templateType: template
                    })
                }
            });

        } catch (error) {
            console.log(error);
            Toast.fail("Error while deleting template data. Please try again!", 3000, () => { });
        }

    }


   
    render() {
        let convertIdeaID = window.btoa(this.state.ideaItem.IdeaId);
        let IDEA_APPROVED = (this.state.ideaItem.WorkflowStatus === "Approve");
        let IDEA_REJECTED = (this.state.ideaItem.WorkflowStatus === "Reject");
        let IDEA_SEND_BACK = (this.state.ideaItem.WorkflowStatus === "Send Back");
        let IDEA_REQUEST_CANCELLATION = (this.state.ideaItem.WorkflowStatus === "Cancellation Requested");
        let IDEA_REQUEST_CANCELLATION1 = (this.state.ideaItem.ApprovalStatus === "Cancellation Requested");
        let IDEA_CANCELLED = (this.state.ideaItem.WorkflowStatus === "Cancelled");
        let IDEA_CANCELLED1 = (this.state.ideaItem.ApprovalStatus === "Cancelled");

        let redirectURL = "/review-page";
        if (this.state.approvalPage) {
            redirectURL = redirectURL + "?page=approval";
        }

        return (
            <section className="u-clearfix u-section-1" id="sec-0f7f">
                <div className="u-clearfix u-sheet u-sheet-1" style={{ marginBottom: 20 }}>
                    <div className="u-align-left u-border-3 u-border-white u-container-style u-expanded-width u-group u-radius-10 u-shape-round u-white u-group-1">
                        <div className="u-container-layout u-container-layout-1">
                            <h4 style={{ textAlign: 'center', color: '#00AECF', fontFamily: 'Arial', fontSize: 22 }}>Edit idea : {this.state.ideaItem.IdeaIDAlpha ? this.state.ideaItem.IdeaIDAlpha : this.state.ideaItem.IdeaId} </h4>
                            <div className="u-align-left u-container-style u-expanded-width u-group u-shape-rectangle u-group-2">
                                <div className="u-container-layout u-container-layout-2" style={{ textAlign: 'center' }}>
                                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-3">
                                        <div className="u-container-layout u-container-layout-3">
                                            <p className="u-align-left u-text u-text-2" style={{ marginBottom: 15 }}>Owner details </p>
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner OHR</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerOHR} /></div>
                                            </div>
                                            <div style={{ marginBottom: 15 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Owner Name</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" value={this.state.ideaItem.OwnerName} /></div>
                                            </div>
                                            <div style={{ marginBottom: -10 }} />
                                            <p className="u-align-left u-text u-text-7 u-text-custom-color-3" style={{ fontSize: 11, marginBottom: 10 }}>(If Idea was raised for someone else, their OHR ID is below)</p>
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Request For OHR</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.CreatorOHR} onChange={this.handleCreatorOHRChange} /></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                            <div className="row">
                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Name</label> </div>
                                                <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.CreatorName} onChange={this.handleCreatorNameChange} /></div>
                                            </div>
                                            <div style={{ marginBottom: 10 }} />
                                            {
                                                (this.state.ideaItem.LeanID !== null && ((this.state.ideaItem.CertificationLevelName === "Lean" || this.state.ideaItem.CertificationLevelName === "YB") && this.state.ideaItem.TransformationLeverName !== "Digital")) ? (
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Project ID</label> </div>
                                                        <div className="col-md-6"><input className="form-input" disabled type="text" defaultValue={this.state.ideaItem.LeanID} /></div>
                                                    </div>
                                                ) : (
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: 12 }}>&nbsp;</label> </div>
                                                    </div>
                                                )
                                            }
                                            <div style={{ marginBottom: 10 }} />
                                        </div>
                                    </div>

                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 255, marginLeft: -20 }}>&nbsp;</p>

                                    <div className="u-border-1 u-border-palette-5-base u-container-style u-group u-radius-6 u-shape-round u-group-4" style={{ marginTop: "-305px" }}>
                                        <div className="" style={{ display: "flex", marginTop: "30px" }}>
                                            <div className="u-container-style u-group u-shape-rectangle u-group-5">
                                                <div className="u-container-layout u-container-layout-5">
                                                    <p className="u-align-left u-text u-text-8" style={{ marginBottom: 15 }}>Account Details</p>
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Account&nbsp;Name</label></div>
                                                        <div className="col-md-7">
                                                            {/* <input className ="form-input" disabled list="accounts" name="accounts" placeholder="Choose Account" value={this.state.ideaItem.AccountManual} /> */}
                                                            <Select isSearchable={true} options={this.renderAccountOptions(this.state.accountSelectedOption)} styles={this.state.selectStyle.accountName} placeholder="Choose Account" value={this.state.accountSelectedOption} onChange={(e) => this.handleAccountNameChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Holding&nbsp;Account</label></div>
                                                        <div className="col-md-7">
                                                            {/* <input className ="form-input" disabled type="text" value={this.state.ideaItem.HoldingAccount} /> */}
                                                            <input className="form-input" disabled list="holdingaccount" name="holdingaccount" value={this.state.holdingAccount} style={{ fontWeight: 'normal !important' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Vertical</label></div>
                                                        <div className="col-md-7">
                                                            <Select tabIndex={2} isSearchable={true} value={this.state.verticalSelectedOption} options={this.renderVerticalOptions(this.state.verticalSelectedOption)} styles={this.state.selectStyle.verticalManual} placeholder="Choose Vertical" onChange={(e) => this.handleVerticalChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Industry Vertical</label></div>
                                                        <div className="col-md-7">
                                                            <Select tabIndex={2} isSearchable={true} value={this.state.subVerticalSelectedOption} options={this.renderSubVerticalOptions(this.state.subVerticalSelectedOption)} styles={this.state.selectStyle.subVerticalManual} placeholder="Choose Industry&nbsp;Vertical" onChange={(e) => this.handleSubVerticalChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />

                                                    {/* units and Segments starts here */}
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Segment</label>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="Segment" value={this.state.Segment ? this.state.Segment : ""} style={{ fontWeight: 'normal !important' }} />
                                                        </div>
                                                    </div>

                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <label className="form-attribute" style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Unit</label>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <input className="form-input" disabled name="Unit" value={this.state.Unit ? this.state.Unit : ""} style={{ fontWeight: 'normal !important' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    {/* units and Segments ends here */}


                                                    <div className="row">
                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Campaign Name</label></div>
                                                        <div className="col-md-7">
                                                            <Select tabIndex={2} isSearchable={true} value={this.state.campaignSelectedOption} options={this.renderCampaignOptions(this.state.campaignSelectedOption)} styles={this.state.selectStyle.campaignTypeText} placeholder="Choose Campaign" onChange={(e) => this.handleCampaignNameChange(e)} />
                                                        </div>
                                                        <div className="">
                                                            <div className="campaign_modal_bulb campaign_tool_tip">
                                                                <img 
                                                                    src={require("../assets/images/question_mark_2.png")}
                                                                    alt=""
                                                                    className=""
                                                                />
                                                                {
                                                                    this.state.campaignInsight
                                                                    ?
                                                                    <>
                                                                        <span className="my_tooltiptext">{this.state.campaignInsight}</span>
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />

                                                    {
                                                        this.state.chatGPTTheme
                                                            ?
                                                            <>
                                                                <div className="row" >
                                                                    <div className="col-md-5">
                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Gen AI Theme</label></div>
                                                                    <div className="col-md-7">
                                                                        <Select tabIndex={5} isSearchable={true} options={this.renderChatGPToptions()} placeholder="Choose Gen AI Theme" onChange={this.handleChatGptOptionsChange} value={this.state.chatGptSelectedOption} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }



                                                    {
                                                        this.state.displayProjectTheme && this.state.dataBridgeCampaign && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Data Bridge Project Theme</label></div>
                                                                    <div className="col-md-7">
                                                                        <Select tabIndex={2} isSearchable={true} value={this.state.dataBridgeSelectedOption} options={this.renderDataBridgeProjectThemeOptions(this.state.dataBridgeSelectedOption)} styles={this.state.selectStyle.dataBridgeProjectTheme} placeholder="Choose Project Theme" onChange={(e) => this.handleDataBridgeProjectThemeChange(e)} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 26 }} />
                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        this.state.xPollinationCampaign && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Campaign</label></div>
                                                                    <div className="col-md-7">
                                                                        {/* <input className="form-input" disabled list="subcampaign" name="subcampaign" placeholder="Choose Sub Campaign" value={this.state.ideaItem.SubCampaignName} /> */}
                                                                        <Select tabIndex={2} isSearchable={true} value={this.state.subCampaignSelectedOption} options={this.renderSubCampaignOptions(this.state.subCampaignSelectedOption)} styles={this.state.selectStyle.subCampaignName} placeholder="Choose Sub Campaign" onChange={(e) => this.handleSubCampaignChange(e)} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginBottom: 26 }} />
                                                                <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginBottom: 30 }}>&nbsp;</p>
                                                            </>
                                                        )
                                                    }

                                                    {
                                                        this.state.displayParentAlpha && this.state.displayParentAlpha.length > 0 && this.state.displayParentAlpha !== null && this.state.displayParentAlpha !== undefined && this.state.displayParentAlpha !== ""
                                                            ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5">
                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Parent ID</label>
                                                                    </div>
                                                                    <div className="col-md-7">
                                                                        <input className="form-input" disabled placeholder="Choose Parent ID" value={this.state.displayParentAlpha} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }


                                                    {
                                                        this.state.campaignSelectedOption && this.state.campaignSelectedOption.label === AIFIRST
                                                            ?
                                                            <>
                                                                <div className="row" style={{ marginTop: "30px" }}>
                                                                    <div className="col-md-5">
                                                                        <label
                                                                            style={{
                                                                                verticalAlign: "bottom",
                                                                                marginBottom: -5,
                                                                            }}
                                                                        >
                                                                            <i>AI First Sample Ideas</i>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-7">
                                                                        <div className="ai_first_doc_link ai_first_doc_red_highlight">
                                                                            <a
                                                                                className="ldt-list1"
                                                                                href={AI_DOC_LINK}
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                                style={{ textAlign: "left", fontSize: 16, color: "red" }}
                                                                            >Document Link </a>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                <div style={{ marginBottom: 20 }} />
                                                                <div className="row d_flex_align_items_center">
                                                                    <div className="col-md-5">
                                                                        <label
                                                                            style={{
                                                                                verticalAlign: "bottom",
                                                                                marginBottom: -5,
                                                                            }}
                                                                        >

                                                                            AI Recommendation
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-7">
                                                                        <Select
                                                                            styles={
                                                                                this.state.selectStyle
                                                                                    .isAISelectedOption
                                                                            }
                                                                            value={this.state.isAISelectedOption}
                                                                            placeholder="Please choose value"
                                                                            options={YESORNO}
                                                                            onChange={(e) => this.handleIsAIChange(e)}

                                                                        />
                                                                    </div>
                                                                </div>


                                                                {
                                                                    this.state.isAISelectedOption && (this.state.isAISelectedOption.label === "Yes" || this.state.isAISelectedOption.label === "No")
                                                                        ?
                                                                        <>
                                                                            <div style={{ marginBottom: 20 }} />
                                                                            <div className="row d_flex_align_items_center">
                                                                                <div className="col-md-5">
                                                                                    <label style={{ verticalAlign: "bottom", marginBottom: 5, }}>
                                                                                        AI First BRD
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-md-7">
                                                                                    <textarea
                                                                                        value={this.state.AIBRD}
                                                                                        placeholder="Please provide value"
                                                                                        className="textarea-css form-attribute"
                                                                                        onChange={(e) => this.handleAIBRDChange(e.target.value)}
                                                                                        rows={4}
                                                                                        cols={80}
                                                                                        style={{
                                                                                            maxWidth: "100%",
                                                                                            border: "1px solid lightgrey",
                                                                                            ...this.state.selectStyle.AIBRD,
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>



                                                                        </>
                                                                        :
                                                                        null
                                                                }

                                                            </>
                                                            :
                                                            null
                                                    }



                                                    <div style={{ marginBottom: 18 }}>&nbsp;</div>
                                                </div>
                                            </div>
                                            <div className="u-container-layout u-container-layout-6">
                                                <div style={{ marginTop: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Country</label></div>
                                                    <div className="col-md-7">
                                                        <Select tabIndex={2} isSearchable={true} value={this.state.countrySelectedOption} options={this.renderCountryOptions(this.state.countrySelectedOption)} styles={this.state.selectStyle.country} placeholder="Choose Country" onChange={(e) => this.handleCountryChange(e)} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Region</label></div>
                                                    <div className="col-md-7">
                                                        {/* <input className ="form-input" disabled name="regions" placeholder="Choose Region" value={this.state.ideaItem.RegionManual} /> */}
                                                        <Select tabIndex={2} isSearchable={true} value={this.state.regionSelectedOption} options={this.renderRegionOptions(this.state.regionSelectedOption)} styles={this.state.selectStyle.regionManual} placeholder="Choose Region" onChange={(e) => this.handleRegionChange(e)} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub-Region</label></div>
                                                    <div className="col-md-7">
                                                        {/* <input className ="form-input" disabled name="regions" placeholder="Choose Sub-Region" value={this.state.ideaItem.SubRegion} /> */}
                                                        <Select tabIndex={2} isSearchable={true} value={this.state.subRegionSelectedOption} options={this.renderSubRegionOptions(this.state.subRegionSelectedOption)} styles={this.state.selectStyle.subRegionManual} placeholder="Choose Sub-Region" onChange={(e) => this.handleSubRegionChange(e)} />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 15 }} />
                                                <div className="row">
                                                    <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Blackbelt&nbsp;Name</label></div>
                                                    <div className="col-md-7">
                                                        {/* <input className ="form-input" disabled name="blackbelt" placeholder="Choose Blackbelt" title={"OHR ID: " +  this.state.ideaItem.BlackBeltUserId} value={this.state.ideaItem.BlackBeltUserName} /> */}
                                                        <Select tabIndex={2} isSearchable={true} value={this.state.blackBeltSelectedOption} options={this.renderBlackBeltOptions(this.state.blackBeltSelectedOption)} styles={this.state.selectStyle.blackBeltUserName} placeholder="Choose Blackbelt" onChange={(e) => this.handleBlackBeltNameChange(e)} />
                                                    </div>
                                                </div>
                                                {
                                                    (this.state.ideaItem.MBBOHR !== null || this.state.showAdditionalDetails || this.state.workFlowStatusSelectedOption) && (
                                                        // (this.state.ideaItem.WorkflowStatus !== "Pending") && (
                                                        <>
                                                            <div style={{ marginBottom: 15 }} />
                                                            <div className="row">
                                                                <div className="col-md-5"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>MBB</label></div>
                                                                <div className="col-md-7">
                                                                    {/* <input className ="form-input" disabled name="blackbelt" placeholder="Choose MBB" title={"OHR ID: " +  this.state.ideaItem.MBBOHR} value={this.state.ideaItem.MBBName} /> */}
                                                                    <Select tabIndex={2} isSearchable={true} value={this.state.mbbSelectedOption} options={this.renderMBBOptions(this.state.mbbSelectedOption)} styles={this.state.selectStyle.mbbUserName} placeholder="Choose MBB" onChange={(e) => this.handleMBBChange(e)} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }

                                                {
                                                    this.state.campaignSelectedOption && this.state.campaignSelectedOption.label === AIFIRST && 
                                                    this.state.isAISelectedOption && (this.state.isAISelectedOption.label === "Yes" || this.state.isAISelectedOption.label === "No")
                                                        ?
                                                        <>
                                                            <div style={{ marginBottom: 20 }} />
                                                            <div className="row d_flex_align_items_center">
                                                                <div className="col-md-12">
                                                                    <label style={{ verticalAlign: "bottom", marginBottom: 5, }}>
                                                                        AI First Reason
                                                                    </label>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <textarea
                                                                        value={this.state.AIReason}
                                                                        placeholder="Please provide value"
                                                                        className="textarea-css form-attribute"
                                                                        onChange={(e) => this.handleAIReasonChange(e.target.value)}
                                                                        rows={4}
                                                                        cols={80}
                                                                        style={{
                                                                            width: "100%",
                                                                            resize: "none",
                                                                            border: "1px solid lightgrey",
                                                                            ...this.state.selectStyle.AIReason,
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }


                                                <div style={{ marginBottom: 15 }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />

                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -30 }}>&nbsp;</p>

                                    <div id="idea1" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7" style={{ marginTop: 15 }}>
                                        <div className="u-container-layout u-container-layout-7">
                                            <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                <div className="u-container-layout u-container-layout-8">
                                                    <p id="idea1title" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Idea details</p>
                                                    <div className="row">
                                                        <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Service Line</label> </div>
                                                        <div className="col-md-8">
                                                            {/* <input className ="form-input" disabled name="serviceline" placeholder="Choose Service Line" value={this.state.ideaItem.ServiceLineName} /> */}
                                                            <Select isSearchable={true} options={this.renderServiceLineOptions(this.state.serviceLineSelectedOption)} styles={this.state.selectStyle.serviceLineText} placeholder="Choose Service Line" value={this.state.serviceLineSelectedOption} onChange={(e) => this.handleServiceLineChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <div className="row">
                                                        <div className="col-md-4"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Sub Service Line</label> </div>
                                                        <div className="col-md-8">
                                                            {/* <input className ="form-input" disabled name="serviceline" placeholder="Choose Sub Service Line" value={this.state.ideaItem.SubServiceLine} /> */}
                                                            <Select isSearchable={true} options={this.renderSubServiceLineOptions()} styles={this.state.selectStyle.subServiceLine} placeholder="Choose Sub Service Line" value={this.state.subServiceLineSelectedOption} onChange={(e) => this.handleSubServiceLineChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: 15 }} />
                                                    <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                        <span className="u-text-black form-attribute"> What is the Problem ?</span>&nbsp;
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            {/* <textarea className ="form-input" disabled rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Problem} /> */}
                                                            <textarea className="textarea-css form-attribute" rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Problem} onChange={(event) => this.handleProblemChange(event.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 10 }}>&nbsp;</p>
                                            <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                <div className="u-container-layout u-container-layout-9" style={{ marginTop: 4 }}>
                                                    <div className="row">
                                                        <div className="col-md-3"><label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Idea Name</label> </div>
                                                        <div className="col-md-9" style={{ alignItems: 'flex-start' }}><input className="form-input" disabled type="text" value={this.state.ideaItem.IdeaName} placeholder="Eg: Cash flow improvement through process restructuring" /></div>
                                                        {/* <div className="col-md-8" style={{ alignItems: 'flex-start' }}><input className="textarea-css form-attribute" type="text" defaultValue={this.state.ideaItem.IdeaName} style={this.state.selectStyle.IdeaName} placeholder="Eg: Cash flow improvement through process restructuring" onChange={(event) => this.handleIdeaNameChange(event.target.value)} /></div> */}
                                                    </div>
                                                    <div style={{ marginBottom: 66 }} />
                                                    <p className="u-align-left u-text u-text-custom-color-4 u-text-25" style={{ width: "177px" }}>
                                                        <span className="u-text-black form-attribute"> How to resolve the problem</span>&nbsp;
                                                    </p>
                                                    <div className="row" style={{ marginBottom: 15 }}>
                                                        <div className="col-md-12">
                                                            {/* <textarea className ="form-input" disabled rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Solution} /> */}
                                                            <textarea className="textarea-css form-attribute" rows={6} cols={80} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} value={this.state.ideaItem.Solution} onChange={(event) => this.handleSolutionChange(event.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: 15 }}>
                                                <div className="col-md-5"><span className="form-attribute">Are you trying to replicate an existing Idea?</span>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="switch">
                                                        {/* <input disabled type="checkbox" checked={this.state.ideaItem.NewOrReplicatedIdea === null ? false : this.state.ideaItem.NewOrReplicatedIdea} /> */}
                                                        <input type="checkbox" checked={this.state.ideaItem.NewOrReplicatedIdea === null ? false : this.state.ideaItem.NewOrReplicatedIdea} onChange={() => this.showHideDiv()} />
                                                        <span className="slider round" />
                                                    </label>
                                                </div>
                                            </div>

                                            {
                                                this.state.ideaItem.NewOrReplicatedIdea && (
                                                    <>
                                                        <div className="col-md-8" style={{ marginBottom: 15, width: "100%" }} />
                                                        <div className="row">
                                                            <div className="col-md-5"><span className="form-attribute">Provide the ID of the executed Idea which you want to replicate?</span>
                                                            </div>
                                                            {/* <div className="col-md-2"><input className ="form-input" disabled type="text" value={this.state.ideaItem.ReplicationProjectId} /></div> */}
                                                            <div className="col-md-2">
                                                                <input className="textarea-css form-attribute" type="text" value={this.state.ideaItem.ReplicationProjectId} style={{ border: '1px solid lightgrey', ...this.state.selectStyle.ReplicationProjectId }} onChange={event => { this.handleReplicationIdChange(event.target.value) }} onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.validateReplicationId();
                                                                    }
                                                                }} />
                                                            </div>
                                                            <div className="col-md-1"><i className="fa fa-check-square-o" style={{ marginTop: 5, color: '#FF555F', fontSize: 24 }} onClick={event => { this.validateReplicationId() }} /></div>
                                                            <div className="col-md-8" style={{ marginBottom: 15 }} />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <div className="col-md-8" style={{ marginBottom: 15 }} />
                                        </div>
                                    </div>

                                    {
                                        (this.state.ideaItem.WorkflowStatus) && (
                                            <>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 15 }} />

                                                {
                                                    (this.state.ideaItem.WorkflowStatus && (this.state.ideaItem.WorkflowStatus === "Approve" || this.state.ideaItem.WorkflowStatus === "Approved" || this.state.showAdditionalDetails)) && (
                                                        <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                            <div className="u-container-layout u-container-layout-7" style={{ display: "flex" }}>
                                                                <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                                    <div className="u-container-layout u-container-layout-8">
                                                                        <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Additional details</p>
                                                                        <div className="row">
                                                                            <div className="col-md-6"><span className="form-attribute">LDT Lead Name</span></div>
                                                                            <div className="col-md-6">
                                                                                <Select tabIndex={2} isSearchable={true} value={this.state.LDTLeadSelectedOption} options={this.state.showAdditionalDetails ? this.renderLeadOptions() : this.renderLeadOptions(this.state.LDTLeadSelectedOption)} styles={this.state.selectStyle.LDTLeadName} placeholder="Choose LDT Lead" onChange={(e) => this.handleLDTLeadOptionChange(e)} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 5 }} />
                                                                        <div style={{ marginTop: 10, marginBottom: 14 }} />


                                                                        {this.state.campaignSelectedOption &&
                                                                            this.state.campaignSelectedOption.label ===
                                                                            AIFIRST ? (
                                                                            <>
                                                                                <div style={{ marginBottom: 20 }} />
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-6">
                                                                                        <label
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: -5,
                                                                                            }}
                                                                                        >

                                                                                            Is the solution feasible ?
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <Select
                                                                                            isSearchable={false}
                                                                                            options={
                                                                                                YESORNO
                                                                                            }
                                                                                            value={
                                                                                                this.state.solutionFeasibleSelectedOption
                                                                                            }
                                                                                            styles={
                                                                                                this.state.selectStyle
                                                                                                    .solutionFeasibleSelectedOption
                                                                                            }
                                                                                            onChange={(e) =>
                                                                                                this.handleSolutionFeasibleChange(e)
                                                                                            }
                                                                                            placeholder="Please Choose"
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div style={{ marginBottom: 20 }} />
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-6">
                                                                                        <label
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: -5,
                                                                                            }}
                                                                                        >Solution Complexity
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <Select
                                                                                            isSearchable={false}
                                                                                            options={
                                                                                                SOLUTIONCOMPLEXITY
                                                                                            }
                                                                                            value={
                                                                                                this.state.solutionComplexitySelectedOption
                                                                                            }
                                                                                            styles={
                                                                                                this.state.selectStyle
                                                                                                    .solutionComplexitySelectedOption
                                                                                            }
                                                                                            onChange={(e) =>
                                                                                                this.handleSolutionComplexityChange(e)
                                                                                            }
                                                                                            placeholder="Please Choose"
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div style={{ marginBottom: 20 }} />
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-6">
                                                                                        <label style={{ verticalAlign: "bottom", marginBottom: -5, }}>
                                                                                            Is the Idea replicable
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <Select
                                                                                            isSearchable={false}
                                                                                            options={YESORNO}
                                                                                            value={this.state.IsIdeaReplicableSelectedOption}
                                                                                            styles={this.state.selectStyle.IsIdeaReplicableSelectedOption}
                                                                                            onChange={this.handleIsIdeaReplicableChange}
                                                                                            placeholder="Choose replicability Index"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ marginBottom: 20 }} />
                                                                            </>
                                                                        ) : null}



                                                                        {
                                                                            this.state.ideaItem.NewOrReplicatedIdea && (
                                                                                <div className="row">
                                                                                    <div className="col-md-10">
                                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Have you verified the replication id and confirm this to be replication project?</label>
                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        <label className="switch">
                                                                                            <input disabled type="checkbox" checked={this.state.ideaItem.IsReplicationVerified} />
                                                                                            <span className="slider round" />
                                                                                        </label>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 45 }} />
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            !this.state.ideaItem.NewOrReplicatedIdea && (
                                                                                <div className="row">
                                                                                    <div className="col-md-10">
                                                                                        <label style={{ verticalAlign: 'bottom', marginBottom: 17 }}>&nbsp;</label>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="u-container-style u-group u-shape-rectangle u-group-9" style={{ marginTop: "0px" }}>
                                                                    <div className="u-container-layout u-container-layout-9" >
                                                                        <div className="row">
                                                                            <div className="col-md-5"><span className="form-attribute">Certification Level</span> </div>
                                                                            <div className="col-md-7">
                                                                                {
                                                                                    this.state.showAdditionalDetails ? (
                                                                                        <Select isSearchable={false} options={this.renderCertLevelsOptions()} styles={this.state.selectStyle.certLevelsSelectedOption} value={this.state.certLevelsSelectedOption} onChange={this.handleCertLevelChange} placeholder="Choose Certification Level" />
                                                                                    ) : (
                                                                                        <input className="form-input" disabled name="certlevel" value={this.state.ideaItem.CertificationLevelName} />
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 15 }} />
                                                                        <div className="row">
                                                                            <div className="col-md-5"><span className="form-attribute">Transformation Lever</span> </div>
                                                                            <div className="col-md-7">
                                                                                {
                                                                                    this.state.showAdditionalDetails ? (
                                                                                        <Select isSearchable={false} options={this.renderTransLeversOptions()} value={this.state.transSelectedOption} styles={this.state.selectStyle.transSelectedOption} onChange={(e) => this.handleTransLeversChange(e)} placeholder="Choose Transformation Lever" />

                                                                                    ) : (
                                                                                        this.state.ideaItem.CertificationLevelName === "Lean" ? (
                                                                                            <Select isSearchable={false} options={this.renderTransLeversOptionsForLeanApproval()} value={this.state.transSelectedOption} styles={this.state.selectStyle.transSelectedOption} onChange={(e) => this.handleTransLeversChange(e)} placeholder="Choose Transformation Lever" />
                                                                                        ) : (
                                                                                            <input className="form-input" disabled name="translever" value={this.state.ideaItem.TransformationLeverName} />
                                                                                        )
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 15 }} />
                                                                        {
                                                                            this.state.digitalProjectEnabled
                                                                                ?
                                                                                <>
                                                                                    <div className="row">
                                                                                        <div className="col-md-5">
                                                                                            <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Digital Project Type</label></div>
                                                                                        <div className="col-md-7">
                                                                                            <Select isSearchable={false}
                                                                                                options={this.renderDigitalProjectTypeOptions()}
                                                                                                value={this.state.digitalSelected}
                                                                                                onChange={this.handleDigitalProjectTypeChange}
                                                                                                placeholder="Choose Project Type"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <div className="row">
                                                                                        <div className="col-md-5"><label style={{ verticalAlign: 'bottom', color: 'lightgrey' }}>Digital Project Type</label></div>
                                                                                        <div className="col-md-7">
                                                                                            <input className="form-input" disabled type="text" value="Not Applicable" style={{ fontWeight: 100, color: 'lightgrey' }} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginBottom: 15 }} />
                                                                                </>
                                                                        }
                                                                        <div style={{ marginBottom: 15 }} />
                                                                        <div className="row">
                                                                            <div className="col-md-5"><span className="form-attribute">Work Type</span> </div>
                                                                            <div className="col-md-7">
                                                                                {
                                                                                    this.state.workTypeEnabled ? (
                                                                                        <Select disabled isSearchable={false} options={this.renderCertWorkTypeOptions(this.state.certLevelsSelectedOption)} styles={this.state.selectStyle.certWorkTypeSelectedOption} value={this.state.certWorkTypeSelectedOption} onChange={this.handleCertWorkTypeChange} placeholder="Choose Work Type" />
                                                                                    ) : (
                                                                                        <input className="form-input" disabled name="worktype" value={(!this.state.ideaItem.CertificationWorkTypeName || this.state.ideaItem.CertificationWorkTypeName === "") ? "None" : this.state.ideaItem.CertificationWorkTypeName} />
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 15 }} />

                                                                        <div className="row">
                                                                            <div className="col-md-5"><span className="form-attribute" style={{ position: "relative", top: "10px" }}>Tools/Methodology Used</span></div>
                                                                            <div className="col-md-7">
                                                                                {/* <input className="form-input" disabled name="methodology" value={this.state.ideaItem.MethodologyName} /> */}
                                                                                <Select tabIndex={2} isSearchable={true} value={this.state.methodologySelectedOptions} options={this.renderMethodologyOptions(this.state.methodologySelectedOptions)} styles={this.state.selectStyle.methodologySelectedOptions} placeholder="Choose Methodology" onChange={(e) => this.handleMethodologyChange(e)} maxMenuHeight={160} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginBottom: 15 }} />


                                                                        <div style={{ marginBottom: 25 }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }


                                    {
                                        (this.state.ideaItem.ApprovalStatus
                                            && this.state.ideaItem.ApprovalStatus !== "NA"
                                            && this.state.ideaItem.ApprovalStatus !== "Awaiting Project Creation"
                                        )
                                        && (
                                            <>
                                                <div style={{ borderBottom: '1px solid lightgrey', marginBottom: 10 }} />
                                                {
                                                    (this.state.ideaItem.FinalComments !== null
                                                        && this.state.ideaItem.ApprovalStatus !== "Documents Uploaded") && (

                                                        <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">

                                                        </div>
                                                    )
                                                }


                                                {
                                                    (this.state.ideaItem.CertificationLevelName === "Lean")
                                                        ?
                                                        <>
                                                            <FPNAGOL IdeaId={this.state.ideaItem.IdeaId} viewIdea={false}
                                                                vdSelected={this.state.impactOptionSelected ? this.state.impactOptionSelected.label : this.state.ideaItem.VDType}
                                                                fetchPPTDeck={this.pullPPTDeck}
                                                                fetchClientDocName={this.pullClientDocName}
                                                                fetchClientDocType={this.pullClientDocType}
                                                                fetchClientDocContent={this.pullClientDocContent}
                                                                fetchGolDocName={this.pullgolFileName}
                                                                fetchGolDocType={this.pullgolFileType}
                                                                fetchGolDocContent={this.pullgolContent}
                                                                fetchfpnaDocName={this.pullfpnafileName}
                                                                fetchfpnaDocType={this.pullfpnafileType}
                                                                fetchfpnaDocContent={this.pullfpnafileContent}
                                                                fetchClientName={this.pullClientName}
                                                                fetchClientRole={this.pullClientRole}
                                                                fetchClientSignOffDate={this.pullClientSignOff}
                                                                fetchFpnaName={this.pullFpnaName}
                                                                fetchGolName={this.pullGolName}
                                                                fetchFpnaOhr={this.pullFpnaOhr}
                                                                fetchGolOhr={this.pullGolOhr}
                                                                fetchFileDetails={this.pull_fileDetails}
                                                                sendBitoFPNA={this.state.businessImpactValueArray}
                                                                sendIsFpnaRequired={this.state.isFpnaRequired}
                                                            />
                                                        </>

                                                        :
                                                        null
                                                }



                                                {
                                                    this.state.IsVDType === "No Value Delivery"
                                                        ?
                                                        <>
                                                            <div id="Potential Impact" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                                <div className="u-container-layout u-container-layout-7" style={{ borderBottom: "1px solid lightgray", paddingBottom: "35px", marginBottom: "20px" }}>
                                                                    <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                                        <div className="u-container-layout u-container-layout-8">
                                                                            <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Final additional details</p>
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <span className="form-attribute"><span style={{ color: 'red' }}> *</span> Final Impact (# of FTE)</span> </div>
                                                                                <div className="col-md-6">
                                                                                    <input className="form-input" disabled type="number"
                                                                                        value={this.state.fteTotal}
                                                                                        placeholder={0} max={10} style={{ ...this.state.selectStyle.FinalImpactFTE }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ marginBottom: 15 }} />
                                                                            <div className="row">
                                                                                <div className="col-md-6"><span className="form-attribute"><span style={{ color: 'red' }}>*</span> Final Impact ($)</span> </div>
                                                                                <div className="col-md-6">
                                                                                    <input className="form-input" disabled type="number"
                                                                                        value={this.state.dollarTotal}
                                                                                        placeholder={0.0}
                                                                                        style={{ ...this.state.selectStyle.FinalImpactDollar }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ marginBottom: 5 }} />
                                                                            <div className="u-align-left u-text u-text-custom-color-3" style={{ fontSize: 11, fontStyle: 'italic' }}>(If Final Impact $ is greater than 250K OR Final FTE number is greater than 10, please review thoroughly)</div>
                                                                            <div style={{ marginTop: 5, marginBottom: 15 }} />
                                                                            <div className="row">
                                                                                <div className="col-md-5">
                                                                                    <span className="form-attribute"><span style={{ color: 'red' }}>*</span> Is it a Zero Impact Project</span>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <label className="switch">
                                                                                        <input type="checkbox" id="isZeroImpactProject" checked={true} />
                                                                                        <span className="slider round" />
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ marginBottom: 22 }} />
                                                                        </div>
                                                                    </div>
                                                                    <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: 22 }}>&nbsp;</p>
                                                                    <div className="u-container-style u-group u-shape-rectangle u-group-9">
                                                                        <div className="u-container-layout u-container-layout-9" style={{ marginTop: 90, marginBottom: 36 }}>
                                                                            <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: '-100px' }}>&nbsp;</p>
                                                                            <div className="row">
                                                                                <div className="col-md-5">
                                                                                    <span className="form-attribute"><span style={{ color: 'red' }}>*</span> Best in Class</span>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <label className="switch best_in_class_slider">
                                                                                        <input type="checkbox" id="isReplicationIdeaBtn" checked={this.state.bestClass} value={this.state.bestClass} onClick={this.handleClassChange} />
                                                                                        <span className="slider round" />
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row" style={{ marginTop: 15 }}>
                                                                                <div className="col-md-5"><span style={{ cursor: 'pointer' }} className="form-attribute" title="Replicability Index is the measure of ‘ease of replication’ or ‘how easily can this same project be implemented’ in another Account. The higher the rating, the easier it is to replicate in another account and vice versa."><span style={{ color: 'red' }}>*</span> Replicability Index</span> </div>
                                                                                <div className="col-md-6">
                                                                                    <Select isSearchable={false} options={REPLICABILITYOPTIONS} value={this.state.replicaIndex} styles={this.state.selectStyle.replicaIndex} placeholder="Choose replicabilty index" onChange={this.replicaIndexChangeHandler} />
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ marginBottom: 15 }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }

                                                {
                                                    this.state.impactOptionSelected && this.state.impactOptionSelected.label !== "No Value Delivery"
                                                        ?
                                                        <>
                                                            <div className="" style={{ marginBottom: "20px" }}>
                                                                <div className="" style={{ borderBottom: "1px solid lightgray", padding: "10px 0px 20px 0px", marginTop: "30px", marginBottom: "30px" }}>
                                                                    <p id="Potential Impact" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Final additional details</p>
                                                                    <div className="">
                                                                        <div className="row mt-4">
                                                                            <div className="col-md-3">
                                                                                <div className="row" style={{ display: "flex", alignItems: "center" }}>
                                                                                    <div className="col-md-5">
                                                                                        <span className="form-attribute"><span style={{ color: 'red' }}>*</span> Best in Class</span>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <label className="switch best_in_class_slider">
                                                                                            <input type="checkbox" id="isReplicationIdeaBtn" checked={this.state.bestClass} value={this.state.bestClass} onClick={this.handleClassChange} />
                                                                                            <span className="slider round" />
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4"></div>

                                                                            <div className="col-md-5">
                                                                                <div className="row" style={{ display: "flex", alignItems: "center", textAlign: "left" }}>
                                                                                    <div className="col-md-5"><span style={{ cursor: 'pointer' }} className="form-attribute" title="Replicability Index is the measure of ‘ease of replication’ or ‘how easily can this same project be implemented’ in another Account. The higher the rating, the easier it is to replicate in another account and vice versa."><span style={{ color: 'red' }}>*</span> Replicability Index</span> </div>
                                                                                    <div className="col-md-6">
                                                                                        <Select isSearchable={false} options={REPLICABILITYOPTIONS} value={this.state.replicaIndex} styles={this.state.selectStyle.replicaIndex} placeholder="Choose replicabilty index" onChange={this.replicaIndexChangeHandler} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null

                                                }



                                                {
                                                    this.state.ideaItem.CertificationLevelName === "Lean" && this.state.ideaItem.IdeaId
                                                        ?
                                                        <>
                                                            <ValueDelivery func={this.pull_data} viewIdea={false}
                                                                vdType={this.handleVDTypeChange}
                                                                IdeaId={this.state.ideaItem.IdeaId}
                                                                fetchMonetizationFileDetails={this.pull_file_data}
                                                                bbMonetizationTotal={this.pull_monetizedTotal}
                                                                monetizableProject={this.pull_monetizableProject}
                                                                monetizableStatus={this.pull_monetizableStatus}
                                                                fetchFPAOHRforIncreaseInRevenue={this.fetchFPAOHRforIncreaseInRevenue}
                                                                bbMonetizationApproval={false}
                                                                postFpnaValid={false}
                                                                fetchMonetizationSowID={this.pull_monetization_sowId}
                                                                fetchDeletedBIRows={this.remove_bi_data_after_delete}
                                                                fetchMonetizationFpnaOhr={this.pull_monetization_fpna_ohr}
                                                                fetchMonetizationFpnaName={this.pull_monetization_fpna_name}
                                                                fetchMonetizationGOLName={this.pullMonetizationGOLName}
                                                                fetchMonetizationGOLOhr={this.pullMonetizationGOLOhr}
                                                                fetchCategoryNotListed={this.pullCategoryNotListed}
                                                            />
                                                                {
                                                                    this.state.impactOptionSelected && this.state.impactOptionSelected.label === "No Value Delivery"
                                                                    ?
                                                                    <>
                                                                    
                                                                    </>
                                                                    :
                                                                    <div className="" style={{ borderBottom: "1px solid lightgrey" }}/> 
                                                                }
                                                        </>
                                                        :
                                                        null
                                                }












                                                {
                                                    (this.state.ideaItem.ApprovalStatus
                                                        && this.state.ideaItem.ApprovalStatus !== "NA"
                                                        && this.state.ideaItem.ApprovalStatus !== "Pending Documents"
                                                        && this.state.ideaItem.ApprovalStatus !== "Completed"
                                                        && this.state.ideaItem.ApprovalStatus !== "Cancelled"
                                                        && this.state.ideaItem.ApprovalStatus !== "Awaiting Project Creation"
                                                    ) && (
                                                        <>
                                                            {/* <div id="Approval Status" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                                                                <div className="u-container-layout u-container-layout-7" style={{borderTop:"1px solid lightgray", padding:"35px 0px"}}>
                                                                    <p className="u-align-left u-text u-text-2">Approval details</p>
                                                                    <div className="row">
                                                                        <div className="col-md-2"><span className="form-attribute">Approval Status</span> </div>
                                                                        <div className="col-md-2">
                                                                            {
                                                                                (this.state.ideaItem.ApprovalStatus !== 'Pending Documents' && this.state.ideaItem.ApprovalStatus !== 'Cancelled' && this.state.ideaItem.ApprovalStatus !== 'Completed') ? (
                                                                                    <Select isSearchable={false} options={this.renderApprovalStatusOptions(this.state.approvalSelectedOption)} value={this.state.approvalSelectedOption} placeholder="Choose Status" onChange={(e) => this.handleApprovalStatus(e)} styles={this.state.selectStyle.approvalSelectedOption} />
                                                                                ) :
                                                                                    (
                                                                                        <input className="form-input" disabled name="finalapprovalstatus" value={this.state.ideaItem.ApprovalStatus} />
                                                                                    )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-8" style={{ marginBottom: 5, width: "100%" }} />
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <p className="u-align-left u-text u-text-custom-color-4 u-text-25">
                                                                                <span className="u-text-black form-attribute">Approval Comments<span style={{ fontWeight: 'normal', fontSize: 12, fontStyle: 'italic', color: 'grey' }}>(max. 500 chars)</span></span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            {
                                                                                (this.state.ideaItem.ApprovalStatus !== 'Pending Documents' && this.state.ideaItem.ApprovalStatus !== 'Cancelled' && this.state.ideaItem.ApprovalStatus !== 'Completed') ? (
                                                                                    <textarea className="textarea-css form-attribute" rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey', ...this.state.selectStyle.finalcomments }} placeholder="Please provide your comments in support of the Approval decision." onChange={(event) => this.handleFinalCommentChange(event.target.value)} />
                                                                                ) : (
                                                                                    <textarea className="form-input" disabled rows={3} cols={88} style={{ maxWidth: '100%', border: '1px solid lightgrey' }} name="Comments" value={this.state.ideaItem.FinalComments} />
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </>
                                                    )
                                                }

                                                {
                                                    this.state.showHrefModal
                                                        ?
                                                        <>
                                                            <div className="custom_modal href_modal">
                                                                <div className="modal_body href_modal_body">
                                                                    <div className="href_modal_dialog">
                                                                        <p>To add a new category please raise a Helpmate ticket, Click here to View SOP</p>
                                                                        <div className="href_modal_body_buttons">
                                                                            <div className="btn-group href_modal_body_buttons">
                                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3"><a href="https://genpactonline.sharepoint.com/:b:/r/sites/LeanDigitalTransformation/Documents/KM%20Page%20-%202021/Certification%20Guidelines,%20SOPs%20%26%20Handbooks/Certification%20guidelines,%20SOPs%20%26%20Handbooks/Systems%20%26%20Tools/Helpmate-Data%20Related%20Request/ProGEAR%20Value%20Delivery%20Category%20Addition%20SOP.pdf?csf=1&web=1&e=5kKbFg" style={{ color: "#FFF" }} target="_blank">Click Here</a></button>
                                                                                <button className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={(e) => this.setState({ showHrefModal: false })}>Cancel</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </>
                                                        :
                                                        null
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        (this.state.ideaItem.CertificationLevelName === 'Lean' && (this.state.ideaItem.TransformationLeverName === 'Data' || this.state.ideaItem.TransformationLeverName === 'Continuous Improvement' || this.state.ideaItem.TransformationLeverName === 'Digital') && this.state.ideaItem.ApprovalStatus === 'Completed') && (
                                            <>
                                                <div style={{ marginBottom: 20, marginTop: 10 }} />
                                                <div className="u-container-style u-group u-shape-rectangle u-group-8">
                                                    <div className="u-container-layout" style={{ padding: "34px 30px 15px 30px" }}>
                                                        <div className="row">
                                                            <div className="col-md-4"><span className="form-attribute">Six Sigma</span> </div>
                                                            <div className="col-md-8">
                                                                <label className="switch">
                                                                    <input type="checkbox" checked={this.state.ideaItem.IsSharedToSixSigma === null ? false : this.state.ideaItem.IsSharedToSixSigma} onChange={() => this.showHideIsSharedToSixSigma()} />
                                                                    <span className="slider round" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        this.state.ideaItem.TransformationLeverName &&
                                            this.state.ideaItem.TransformationLeverName === "Data"
                                            ?
                                            <>

                                                <div className="" style={{ marginBottom: "20px", borderTop: "1px solid lightgrey", paddingRight: "30px" }}>
                                                    <div className="">
                                                        <p className="u-align-left u-text u-text-2" style={{ margin: "15px 0px" }}>
                                                            Data Bridge Fields
                                                        </p>
                                                        <div className="row">
                                                            <div className="col-md-4" >
                                                                <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                DB Project Type
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={this.state.dataBridgeType}
                                                                                options={DATABRIDGETYPES}
                                                                                placeholder="Please choose"
                                                                                onChange={(e) =>
                                                                                    this.handleDataBridgeTypeChange(e)
                                                                                }
                                                                                styles={
                                                                                    this.state.selectStyle.dataBridgeType
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="">

                                                                    {
                                                                        this.state.dataBridgeType
                                                                            ?
                                                                            <>
                                                                                <div className="submit_page_new_fields" style={{ marginBottom: "20px" }}>
                                                                                    <div className="row d_flex_align_items_center">
                                                                                        <div className="col-md-5">
                                                                                            <span className="form-attribute">
                                                                                                Template Type
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-7">
                                                                                            <div className="ai_first_doc_link">

                                                                                                {
                                                                                                    this.state.dataBridgeType
                                                                                                        ?
                                                                                                        <>
                                                                                                            <label onClick={(e) => this.templateRedirect(this.state.dataBridgeType.label)} className="template_type_label" style={{ marginBottom: "0px" }}>{this.state.dataBridgeType.label}</label>
                                                                                                        </>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                                <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                COO
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <input
                                                                                type="text"
                                                                                disabled
                                                                                placeholder="Provide Value"
                                                                                className="form-input disabled_input"
                                                                                value={this.state.COOMappingSelected}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                MUC ID
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={this.state.mucIDSelectedOption}
                                                                                options={this.renderMUCIDOptions()}
                                                                                placeholder="Please choose"
                                                                                onChange={(e) =>
                                                                                    this.handleMUCIDChange(e)
                                                                                }

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                Shown to Client
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={this.state.sharedToClient}
                                                                                options={YESORNO}
                                                                                placeholder="Please choose"
                                                                                onChange={(e) => this.handleSharedToClientChange(e)}

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    this.state.sharedToClient && this.state.sharedToClient.label === "Yes"
                                                                        ?
                                                                        <>

                                                                            <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                                <div className="row">
                                                                                    <div className="col-md-5" style={{ textAlign: "left" }}>
                                                                                        <div className="">
                                                                                            <label className="form-attribute" style={{ verticalAlign: "bottom", marginBottom: 0, }}>Client Testimony&nbsp;</label>
                                                                                        </div>
                                                                                        <div className="">
                                                                                            <small style={{ color: " rgb(147, 147, 147)", fontSize: "12px" }}>(Max Size: 5MB)</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-7">
                                                                                        <div className="">
                                                                                            <label style={{ fontWeight: "normal", fontSize: "10px", fontStyle: "italic", color: "red" }}>[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                                                                        </div>
                                                                                        <div className="row d_flex_align_items_center">
                                                                                            {
                                                                                                this.state.CTestimonyFileName
                                                                                                    ?
                                                                                                    <>
                                                                                                        <div className="col-md-2">
                                                                                                            <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{ padding: "5px 8px" }} onClick={(e) => this.deleteClientTestimonyFile()}><i className="fa fa-trash" aria-hidden="true"></i></button>
                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    <div className="col-md-9">
                                                                                                        <label htmlFor="client-attachment" className="client_testimony_upload_btn">
                                                                                                            &nbsp;&nbsp;+&nbsp;&nbsp;
                                                                                                        </label>
                                                                                                        <input
                                                                                                            style={{ display: "none" }}
                                                                                                            id="client-attachment"
                                                                                                            type="file"
                                                                                                            accept=".msg"
                                                                                                            onChange={(event) => this.uploadClientAttachment(event, "clientTestimony")}
                                                                                                        />
                                                                                                    </div>
                                                                                            }

                                                                                            <div className="col-md-10 client_testimoney_filename">
                                                                                                <label className={this.state.CTestimonyFileName && this.state.CTestimonyFileName.length > 50 ? "upload_file_text_overflow":""} onClick={(e) => this.downloadClientTestimony()}>{this.state.CTestimonyFileName}</label>
                                                                                            </div>


                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }


                                                                <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                Shown to GOL
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={this.state.sharedToGOL}
                                                                                options={YESORNO}
                                                                                placeholder="Please choose"
                                                                                onChange={(e) => this.handleSharedToGOLChange(e)}

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="" style={{ textAlign: "left", margin: "15px 0px" }}>
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                DB Tool Used
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={this.state.dbToolUsed}
                                                                                options={DOBTOOLS}
                                                                                placeholder="Please choose"
                                                                                onChange={(e) => this.handledbToolUsedChange(e)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{ verticalAlign: "bottom", marginBottom: -5, }}
                                                                            >
                                                                                Process Owner OHR
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <input
                                                                                type="number"
                                                                                defaultValue={
                                                                                    this.state.processOwnerOHR
                                                                                }
                                                                                placeholder="Provide Value"
                                                                                onChange={(e) =>
                                                                                    this.handleProcessOwnerChange(e)
                                                                                }
                                                                                onCut={(e) =>
                                                                                    this.handleProcessOwnerOhronCut(e)
                                                                                }
                                                                                onBlur={(e) =>
                                                                                    this.handleProcessOwnerChange(e)
                                                                                }
                                                                                className="textarea-css form-attribute hide_numeric_input_arrows"
                                                                                onKeyDown={(evt) =>
                                                                                    (evt.key === "." ||
                                                                                        evt.key === "e" ||
                                                                                        evt.key === "E" ||
                                                                                        evt.key === "+" ||
                                                                                        evt.key === "-") &&
                                                                                    evt.preventDefault()
                                                                                }
                                                                                onWheel={(e) => e.target.blur()}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >

                                                                                Process Owner Name
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <input
                                                                                type="text"
                                                                                value={this.state.processOwnerName}
                                                                                placeholder="Provide Value"
                                                                                className="form-input disabled_input"
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    this.state.sharedToClient && this.state.sharedToClient.label === "Yes"
                                                                        ?
                                                                        <>

                                                                            <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-5">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: -5,
                                                                                            }}
                                                                                        >
                                                                                            Client Name
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-7">
                                                                                        <input
                                                                                            type="text"
                                                                                            onChange={(e) => this.handleDBClientNameChange(e.target.value)}
                                                                                            value={this.state.DB_clientName}
                                                                                            className="textarea-css form-attribute"
                                                                                            placeholder="Provide value"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-5">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: -5,
                                                                                            }}
                                                                                        >
                                                                                            Client Role
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-7">
                                                                                        <input
                                                                                            type="text"
                                                                                            value={this.state.DB_clientRole}
                                                                                            placeholder="Provide value"
                                                                                            onChange={(e) => this.handleSharedToClientRoleTextChange(e.target.value)}
                                                                                            className="textarea-css form-attribute"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    this.state.sharedToGOL && this.state.sharedToGOL.label === "Yes"
                                                                        ?
                                                                        <>
                                                                            <div className="" style={{ textAlign: "left", marginBottom: "20px" }}>
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-5">
                                                                                        <label className="form-attribute" style={{ verticalAlign: "bottom", marginBottom: -5, }}>
                                                                                            GOL OHR
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-7">
                                                                                        <input
                                                                                            type="number"
                                                                                            defaultValue={this.state.DB_GOLOHR}
                                                                                            placeholder="Provide value"
                                                                                            onChange={(e) => this.handleDBGOLOHRChange(e)}
                                                                                            // onBlur={(e) => this.handleDBGOLOHRChange(e)}
                                                                                            onCut={(e) => this.handleDBGOLOhronCut(e)}
                                                                                            className="textarea-css form-attribute hide_numeric_input_arrows"
                                                                                            onKeyDown={(evt) =>
                                                                                                (evt.key === "." ||
                                                                                                    evt.key === "e" ||
                                                                                                    evt.key === "E" ||
                                                                                                    evt.key === "+" ||
                                                                                                    evt.key === "-") &&
                                                                                                evt.preventDefault()
                                                                                            }
                                                                                            onWheel={(e) => e.target.blur()}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="" style={{ marginBottom: "20px", textAlign: "left" }}>
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-5">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: -5,
                                                                                            }}
                                                                                        >
                                                                                            GOL Name
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-7">
                                                                                        <input
                                                                                            type="text"
                                                                                            value={this.state.DB_GOLNAME}
                                                                                            placeholder="Provide value"
                                                                                            disabled={true}
                                                                                            className="form-input disabled_input"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </>
                                                                        :
                                                                        null
                                                                }


                                                                {
                                                                    this.state.dbToolUsed && this.state.dbToolUsed.label === "Others" ?
                                                                        <>
                                                                            <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-5">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: -5,
                                                                                            }}
                                                                                        >

                                                                                            Other DB Tool Used
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-7">
                                                                                        <input
                                                                                            type="text"
                                                                                            value={this.state.otherDBToolUsedText}
                                                                                            placeholder="Provide value"
                                                                                            onChange={(e) =>
                                                                                                this.handleOtherDBToolUsedChange(
                                                                                                    e.target.value
                                                                                                )
                                                                                            }
                                                                                            className="textarea-css form-attribute"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }


                                                            </div>
                                                            <div className="col-md-4 " style={{ paddingRight: "30px" }}>
                                                                {
                                                                    this.state.ideasDB.IsSustenance === "Yes"
                                                                        ?
                                                                        <>
                                                                            <div className="">
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-8 text-left">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: -5,
                                                                                            }}
                                                                                        >
                                                                                            Is Sustenance tracking applicable ? </label>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <Select
                                                                                            value={this.state.trackingApplicableSelectedOption}
                                                                                            options={YESORNO}
                                                                                            placeholder="Provide choose"
                                                                                            styles={this.state.selectStyle.trackingApplicableSelectedOption}
                                                                                            onChange={(e) => this.handleTrackingChange(e)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }

                                                                {
                                                                    this.state.modalsOperationsSelectedOption
                                                                        ?
                                                                        <>
                                                                            <div className="submit_page_new_fields">
                                                                                <div className="row d_flex_align_items_center">
                                                                                    <div className="col-md-12">
                                                                                        <label
                                                                                            className="form-attribute"
                                                                                            style={{
                                                                                                verticalAlign: "bottom",
                                                                                                marginBottom: "5px",
                                                                                            }}
                                                                                        >
                                                                                            How is the model operationalized ?
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <Select
                                                                                            value={
                                                                                                this.state.modalsOperationsSelectedOption
                                                                                            }
                                                                                            options={modelOperationOptions}
                                                                                            placeholder="Please choose"
                                                                                            onChange={(e) => this.handleModalsOperationsChange(e)}

                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        null
                                                                }


                                                                {this.state.trackingApplicableSelectedOption && this.state.trackingApplicableSelectedOption.label === "Yes" ? (
                                                                    <>
                                                                        <div className="submit_page_new_fields">
                                                                            <div className="row d_flex_align_items_center">
                                                                                <div className="col-md-8">
                                                                                    <label
                                                                                        className="form-attribute"
                                                                                        style={{
                                                                                            verticalAlign: "bottom",
                                                                                            marginBottom: -5,
                                                                                        }}
                                                                                    >

                                                                                        Sustenance tracking frequency
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <Select
                                                                                        value={this.state.sustenanceMetricFreq}
                                                                                        options={sustenanceMetricFreqOptions}
                                                                                        placeholder="Please choose"
                                                                                        onChange={(e) =>
                                                                                            this.handleSusFreqChange(e)
                                                                                        }

                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="submit_page_new_fields">
                                                                            <div className="row d_flex_align_items_center">
                                                                                <div className="col-md-12">
                                                                                    <label
                                                                                        className="form-attribute"
                                                                                        style={{
                                                                                            verticalAlign: "bottom",
                                                                                            marginBottom: "5px",
                                                                                        }}
                                                                                    >
                                                                                        Sustenance metric
                                                                                        <span style={{fontWeight: "normal",fontSize: 12,fontStyle: "italic",color: "grey"}}>&nbsp;(max. 50 chars)</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <textarea
                                                                                        className="textarea-css form-attribute"
                                                                                        value={this.state.sustenanceMetrics}
                                                                                        placeholder="Please provide sustenance metrics"
                                                                                        onChange={(e) => this.handleSustenanceMetricsTextChange(e.target.value)}
                                                                                        rows={3}
                                                                                        style={{ width: "100%" }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>

                                            :
                                            null
                                    }
                                    {/* .............DB Fields starts here ends here............. */}



                                    {
                                        this.state.ideaItem.TransformationLeverName === "Data" && this.state.ideaItem.IdeaId
                                            ?
                                            <>
                                                <DisplayTemplate IdeaId={this.state.ideaItem.IdeaId} templateType={this.state.ideaItem.DBProjectType} />
                                                <div className="" style={{ borderBottom: "1px solid lightgrey" }} />
                                            </>
                                            :
                                            null
                                    }

                                    {
                                        this.state.transSelectedOption && this.state.transSelectedOption.label === "Data"
                                            ?
                                            <>
                                                <div
                                                    className=""
                                                    style={{
                                                        paddingBottom: "40px",
                                                        marginTop: "40px",
                                                    }}
                                                >
                                                    <p className="u-align-left u-text u-text-2">
                                                        DB questionnaire
                                                    </p>
                                                    <div className="new_fields_blocks" style={{ paddingRight: "30px" }}>
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >Is the MUC ID tagged correctly ?
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={
                                                                                    this.state.muciIDTaggedSelectedOption
                                                                                }
                                                                                options={YESORNO}
                                                                                placeholder="Please Choose"
                                                                                onChange={(e) =>
                                                                                    this.handlemuicIDTaggedCorrectlyChange(e)
                                                                                }

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                Is
                                                                                the DB theme captured correctly?
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={
                                                                                    this.state
                                                                                        .dbThemeCapturedCorrectlySelectedOption
                                                                                }
                                                                                options={YESORNO}
                                                                                placeholder="Please Choose"
                                                                                onChange={(e) =>
                                                                                    this.handledbThemeCorrectlyCapturedChange(
                                                                                        e
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_fields_blocks" style={{ paddingRight: "30px" }}>
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >Is the Tool used captured correctly ?
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={
                                                                                    this.state
                                                                                        .toolsCapturedCorrectlySelectedOption
                                                                                }
                                                                                options={YESORNO}
                                                                                placeholder="Please Choose"
                                                                                onChange={(e) =>
                                                                                    this.handletoolsCapturedCorrectlyCorrectlyChange(
                                                                                        e
                                                                                    )
                                                                                }

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >

                                                                                Are the model details captured
                                                                                appropriately?
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={
                                                                                    this.state
                                                                                        .modelDetailsCapturedSelectedOption
                                                                                }
                                                                                options={YESORNO}
                                                                                placeholder="Please Choose"
                                                                                onChange={(e) =>
                                                                                    this.handleModelDetailsCapturedChange(e)
                                                                                }

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_fields_blocks" style={{ paddingRight: "30px" }}>
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                Are the model deployment details captured
                                                                                appropriately?
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={
                                                                                    this.state
                                                                                        .modelDeploymentDetailsSelectedOption
                                                                                }
                                                                                options={YESORNO}
                                                                                placeholder="Please Choose"
                                                                                onChange={(e) =>
                                                                                    this.handleModeldeploymentDetailsChange(e)
                                                                                }

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                Do
                                                                                the provided signoffs meet value delivery
                                                                                guidelines?
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={this.state.signOffMeetSelectedOption}
                                                                                options={YESORNO}
                                                                                placeholder="Please Choose"
                                                                                onChange={(e) =>
                                                                                    this.handleSignOffMeetChange(e)
                                                                                }

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_fields_blocks" style={{ paddingRight: "30px" }}>
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                Are the monetization details captured
                                                                                appropriately?
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={
                                                                                    this.state
                                                                                        .monetizationDetailsCapturedSelectedOption
                                                                                }
                                                                                options={YESORNO}
                                                                                placeholder="Please Choose"
                                                                                onChange={(e) =>
                                                                                    this.handleMonetizationDetailsCapturedChange(
                                                                                        e
                                                                                    )
                                                                                }

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >

                                                                                Are sustenance tracking details OK ?
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={
                                                                                    this.state.susDetailsOkSelectedOption
                                                                                }
                                                                                options={YESORNO}
                                                                                placeholder="Please Choose"
                                                                                onChange={(e) =>
                                                                                    this.handleSusDetailsOkChange(e)
                                                                                }

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="new_fields_blocks" style={{ paddingRight: "30px" }}>
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                Is
                                                                                there any other issue you would like to
                                                                                report ?
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={
                                                                                    this.state.anyOtherIssueSelectedOption
                                                                                }
                                                                                options={YESORNO}
                                                                                placeholder="Please Choose"
                                                                                onChange={(e) =>
                                                                                    this.handleAnyOtherIssueChange(e)
                                                                                }

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                            <div className="col-md-5">
                                                                <div className="submit_page_new_fields">
                                                                    <div className="row d_flex_align_items_center">
                                                                        <div className="col-md-7">
                                                                            <label
                                                                                className="form-attribute"
                                                                                style={{
                                                                                    verticalAlign: "bottom",
                                                                                    marginBottom: -5,
                                                                                }}
                                                                            >
                                                                                Is
                                                                                there any exception applied on the project
                                                                                approval ?
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <Select
                                                                                isSearchable={true}
                                                                                value={
                                                                                    this.state.exceptionAppliedSelectedOption
                                                                                }
                                                                                options={YESORNO}
                                                                                placeholder="Please Choose"
                                                                                onChange={(e) =>
                                                                                    this.handleExceptionAppliedChange(e)
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }



                                    {/* .....LDT Lead Approval starts here........ */}
                                    {
                                        this.state.ideaItem.ApprovalStatus === "Completed"
                                            ?
                                            <>
                                                <div className="">
                                                    <div className="" style={{ borderTop: "1px solid lightgrey", paddingRight: "30px", paddingTop: "10px" }}>
                                                        <div className="">
                                                            <div className="row">
                                                                <div className="col-md-8">
                                                                    <h3 className="idea_form_header">Client QBR</h3>
                                                                </div>
                                                                <div className="col-md-4" >
                                                                    <div className="project_lead_comment_log_button" style={{}}>
                                                                        <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={(e) => this.toggleLDTApprovalCommentLogModal()}>LDT Lead Approval Log</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                {
                                                                    this.state.displayLDTCommentLog
                                                                        ?
                                                                        <>
                                                                            {
                                                                                this.state.LDTLogs
                                                                                    ?
                                                                                    <>
                                                                                        <div className="custom_modal ldt_lead_approval_modal admin_ldt_approval_modal">
                                                                                            <div className="modal_body">
                                                                                                <div className="modal_title_block">
                                                                                                    <div className="row d_flex_align_items_center">
                                                                                                        <div className="col-md-8">
                                                                                                            <h3 className="modal_title">
                                                                                                                LDT Lead Approval Log
                                                                                                            </h3>
                                                                                                        </div>
                                                                                                        <div className="col-md-4">
                                                                                                            <div className=" ldt_approval_modal_btn_block">
                                                                                                                <button className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.UpdateIdea}>Update</button>
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="close text-right"
                                                                                                                    onClick={(e) =>
                                                                                                                        this.setState({
                                                                                                                            displayLDTCommentLog: false,
                                                                                                                        })
                                                                                                                    }
                                                                                                                >
                                                                                                                    &times;
                                                                                                                </button>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>


                                                                                                <div className="qc_responsive_comment_log">
                                                                                                    <div className="table table-responsive table-bordered">
                                                                                                        <thead className="text-center">
                                                                                                            <tr>

                                                                                                                <th className="qcHeaderbg qcVDHeader LDTHeaderFullWidthOne">
                                                                                                                    <label
                                                                                                                        style={{
                                                                                                                            fontWeight: 100,
                                                                                                                            verticalAlign: "bottom",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        Project demonstrated in Client QBR
                                                                                                                    </label>
                                                                                                                </th>
                                                                                                                <th className="qcHeaderbg qcVDHeader LDTHeaderFullWidthTwo">
                                                                                                                    <label
                                                                                                                        style={{
                                                                                                                            fontWeight: 100,
                                                                                                                            verticalAlign: "bottom",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        QBR Submitted Date
                                                                                                                    </label>
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    className="qcHeaderbg"
                                                                                                                    style={{
                                                                                                                        minWidth: "400px",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <label
                                                                                                                        style={{
                                                                                                                            fontWeight: 100,
                                                                                                                            verticalAlign: "bottom",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        Comment
                                                                                                                    </label>
                                                                                                                </th>
                                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                                    <label
                                                                                                                        style={{
                                                                                                                            fontWeight: 100,
                                                                                                                            verticalAlign: "bottom",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        Name
                                                                                                                    </label>
                                                                                                                </th>

                                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                                    <label
                                                                                                                        style={{
                                                                                                                            fontWeight: 100,
                                                                                                                            verticalAlign: "bottom",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        Role
                                                                                                                    </label>
                                                                                                                </th>
                                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                                    <label
                                                                                                                        style={{
                                                                                                                            fontWeight: 100,
                                                                                                                            verticalAlign: "bottom",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        OHR
                                                                                                                    </label>
                                                                                                                </th>

                                                                                                                <th className="qcHeaderbg qcVDHeader">
                                                                                                                    <label
                                                                                                                        style={{
                                                                                                                            fontWeight: 100,
                                                                                                                            verticalAlign: "bottom",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        Date & Time
                                                                                                                    </label>
                                                                                                                </th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody style={{ textAlign: "left" }}>
                                                                                                            {this.state.LDTLogs.map(
                                                                                                                (item, index) => {
                                                                                                                    return (
                                                                                                                        <tr key={index}>
                                                                                                                            <td style={{ textAlign: "left" }}>
                                                                                                                                <Select value={item.IsQBR} options={YESORNO} onChange={(e) => this.handleIsQBRChange(e, index)} />
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <input
                                                                                                                                    defaultValue={this.formatDateLatest(new Date(item.QBRDate))}
                                                                                                                                    className="textarea-css form-attribute" type="date"
                                                                                                                                    onChange={(e) => this.handleQBRDateChange(e.target.value, index)}
                                                                                                                                />
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <input
                                                                                                                                    value={item.LDTComments}
                                                                                                                                    type="text"
                                                                                                                                    className="textarea-css form-attribute"
                                                                                                                                    onChange={(e) => this.handleLDTApprovalCommentChange(e.target.value, index)}
                                                                                                                                />
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <label
                                                                                                                                    style={{
                                                                                                                                        lineheight: 2,
                                                                                                                                        verticalAlign:
                                                                                                                                            "bottom",
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {item.UserName}
                                                                                                                                </label>
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                <label
                                                                                                                                    style={{
                                                                                                                                        lineheight: 2,
                                                                                                                                        verticalAlign:
                                                                                                                                            "bottom",
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    LDT Lead
                                                                                                                                </label>
                                                                                                                            </td>

                                                                                                                            <td>
                                                                                                                                <label
                                                                                                                                    style={{
                                                                                                                                        lineheight: 2,
                                                                                                                                        verticalAlign:
                                                                                                                                            "bottom",
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {item.UserOHR}
                                                                                                                                </label>
                                                                                                                            </td>




                                                                                                                            <td>
                                                                                                                                <label
                                                                                                                                    style={{
                                                                                                                                        lineheight: 2,
                                                                                                                                        verticalAlign:
                                                                                                                                            "bottom",
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {this.formatDateStringWithTime(
                                                                                                                                        new Date(
                                                                                                                                            item.CreatedDate
                                                                                                                                        )
                                                                                                                                    )}
                                                                                                                                </label>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    );
                                                                                                                }
                                                                                                            )}
                                                                                                        </tbody>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    null

                                                                            }


                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            null
                                    }
                                    
                                    {/* .......LDT lead approval ends here...... */}


                                    <div style={{ borderBottom: '1px solid lightgrey', marginTop: 10, marginBottom: 25 }} />
                                    <div className="btn-group">
                                        <button tabIndex={20} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-3" onClick={this.UpdateIdea}>Update</button>
                                        <button tabIndex={21} className="u-border-2 u-border-active-black u-border-blue u-btn-cancel u-btn-round u-button-style u-radius-6" onClick={this.cancelEvent}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default AdminEditPage
