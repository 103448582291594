import React,{useState,useEffect} from 'react';
import { GET_IDEA_URL, REVIEW_IDEA_URL,UPDATE_IDEA_URL,AWAITING_MBB_APPROVAL, GET_METADATA, APPROVED, PENDING, SENT_BACK, REJECTED, DATA_BRIDGE_CAMPAIGN, X_POLLINATION_CAMPAIGN, ACCESS_TOKEN, COMPLETED_STATUS, USER_DATA, SIGNOFF_CONTENT, PPT_CONTENT, REQUEST_CANCELLATION,BI_FORM_DATE,GOL_APPROVAL_CONTENT,AWAITING_QAULITY_CHECK_REVIEW } from '../assets/constants/constants';
import Toast from 'light-toast';
import Select from 'react-select';

function FPNAGOL(props) {
    const [accessToken] = useState(localStorage.getItem(ACCESS_TOKEN))
    const [data,setData]= useState({})
    const [vdSelectedState,setVDSelectedState]=useState(props.vdSelected)
    
    
    // vd type states
    const [impactOptionSelected,setImpactOptionSelected]=useState(null)
    const [impactOptions]=useState([
        {
            value:0,
            label:"No Value Delivery"
        },
        {
            value:1,
            label:"Value Delivered to Client"
        },
        {
            value:2,
            label:"Value Delivered to Genpact"
        },
        {
            value:3,
            label:"Value Delivered to Client + Genpact"
        }
    ])

    // client states
    const [showClientFields,setShowClientFields]=useState(false)
    const [clientName,setClientName]=useState("")
    const [clientRole,setClientRole]=useState("")
    const [clientSignOffDate,setClientSignOffDate]=useState("")

    // gol states
    const [showGOLFields,setShowGOLFields]=useState(false)
    const [golOhr,setGolOhr]=useState("")
    const [golFullname,setGolFullname]=useState("")
    const [golEmail,setGolEmail]=useState("")
    const [fpnaOhr,setFpnaOhr]=useState("")
    const [fpnaEmail,setFpnaEmail]=useState("")
    const [fpnaName,setFpnaName]=useState("")
    
    // project presentation deck filename
    const [filename1,setFilename1]=useState("")
    const [fileType1,setFileType1]=useState("")
    const [base64File1,setBase64File1]=useState("")

    // signoff filename
    const [filename2,setFilename2]=useState("")
    const [fileType2,setFileType2]=useState("")
    const [base64File2,setBase64File2]=useState("")
    
    // gol file
    const [filename3,setFilename3]=useState("")
    const [base64File3,setBase64File3]=useState("")
    const [fileType3,setFileType3]=useState("")

    // fpna file
    const [filename4,setFilename4]=useState("")
    const [fileType4,setFileType4]=useState("")
    const [base64File4,setBase64File4]=useState("")

    const [golContent,setGolContent]=useState()
    const [fpnaContent,setFpnaContent]=useState()
    // const [fpnaUpload,setFpnaUpload]=useState(false)
    const [replaceFpnaDoc, setReplaceFpnaDoc] = useState(false)

    const [replaceByBB,setReplaceGolByBB]=useState(false)


    
        const [myObj, setMyObj] = useState([{
            id:0,
            label:""
        },
        {
            id:1,
            label:""
        }
    ]);
        
    
    useEffect(() => {
        setVDSelectedState(props.vdSelected)
        const fetchIdea = async () => {
            Toast.loading("Fetching Idea...", () => { });
            try {
                // if (!props.ideaId) {
                //     return; 
                // }
                const requestOptions = {
                    method: 'GET',
                    headers: {
                       "Content-Type": "application/json",
                "Authorization":  "Bearer " + accessToken,
                    }
                };
                const response = await fetch(`${GET_IDEA_URL}IdeaId=${props.IdeaId}&RecID=''&isItIdeaOrGenAI='Idea'`, requestOptions);
                const data = await response.json();
                //console.log("fetch idea from GOL/FPNA component",data)
                setData(data);

                if(data.ResultSets.Table1[0].CustName){
                    let localClientName = data.ResultSets.Table1[0].CustName;
                    props.fetchClientName(localClientName)
                    setClientName(localClientName)
                }
                if(data.ResultSets.Table1[0].CustDesgn){
                    let localClientDesignation = data.ResultSets.Table1[0].CustDesgn;
                    props.fetchClientRole(localClientDesignation)
                    setClientRole(localClientDesignation)
                }
                if(data.ResultSets.Table1[0].CustSignOffDate){
                    let localClientSignOffDate = new Date(data.ResultSets.Table1[0].CustSignOffDate).toLocaleDateString('en-CA').split('/').reverse().join('-');
                    props.fetchClientSignOffDate(localClientSignOffDate)
                    setClientSignOffDate(localClientSignOffDate)
                    // let localClientSignOffDate =  data.ResultSets.Table1[0].CustSignOffDate;
                    // let convertedDateFormat = formatDateForDisplay(new Date(localClientSignOffDate))
                    // props.fetchClientSignOffDate(convertedDateFormat)
                    // setClientSignOffDate(convertedDateFormat)

                }
                if(data.ResultSets.Table1[0].ProjectPPTFileName){
                    let localfileName1 = data.ResultSets.Table1[0].ProjectPPTFileName;
                    setFilename1(localfileName1)
                }
                if(data.ResultSets.Table1[0].ProjectPPTType){
                    let localfileType1 = data.ResultSets.Table1[0].ProjectPPTType
                    setFileType1(localfileType1)
                }
                if(data.ResultSets.Table1[0].CustomerSignoffFileName){
                    let localfileName2 = data.ResultSets.Table1[0].CustomerSignoffFileName;
                    props.fetchClientDocName(localfileName2)
                    setFilename2(localfileName2)
                }
                if(data.ResultSets.Table1[0].CustomerSignoffType){
                    let localfileType2 = data.ResultSets.Table1[0].CustomerSignoffType
                    setFileType2(localfileType2)
                }

                if(data.ResultSets.Table1[0].GOLFileName){
                    let localfileName3 = data.ResultSets.Table1[0].GOLFileName;
                    props.fetchGolDocName(localfileName3)
                    setFilename3(localfileName3)
                }
                if(data.ResultSets.Table1[0].GOLFileType){
                    let localfileType3 = data.ResultSets.Table1[0].GOLFileType
                    setFileType3(localfileType3)
                }

                if(data.ResultSets.Table1[0].GOLName){
                    let localgolName = data.ResultSets.Table1[0].GOLName;
                    props.fetchGolName(localgolName)
                    setGolFullname(localgolName)
                }
                if(data.ResultSets.Table1[0].GOLOHR){
                    let localgolOhr = data.ResultSets.Table1[0].GOLOHR;
                    props.fetchGolOhr(localgolOhr)
                    setGolOhr(localgolOhr)
                }

                if(data.ResultSets.Table1[0].FPnAName){
                    let localFpnaName = data.ResultSets.Table1[0].FPnAName;
                    props.fetchFpnaName(localFpnaName)
                    setFpnaName(localFpnaName)
                }
                if(data.ResultSets.Table1[0].FPnAOHR){
                    let localfpnaOhr = data.ResultSets.Table1[0].FPnAOHR;
                    props.fetchFpnaOhr(localfpnaOhr)
                    setFpnaOhr(localfpnaOhr)
                }
                
                if(data.ResultSets.Table1[0].FPnAFileName){
                    let localfileName4 = data.ResultSets.Table1[0].FPnAFileName;
                    setFilename4(localfileName4)
                    setTimeout(() => {
                        setFileType4(localfileName4)
                    }, 2000);
                }
                if(data.ResultSets.Table1[0].FPnAFileType){
                    let localfileType4 = data.ResultSets.Table1[0].FPnAFileType
                    setFileType4(localfileType4)
                    setTimeout(() => {
                        setFileType4(localfileType4)
                    }, 2000);
                }

                if(data){
                    let localabc = data.ResultSets.Table1[0].GOLFileName === null || data.ResultSets.Table1[0].GOLFileName === undefined? "": data.ResultSets.Table1[0].GOLFileName;
                    let localxyz = data.ResultSets.Table1[0].FPnAFileName === null || data.ResultSets.Table1[0].FPnAFileName === undefined? "": data.ResultSets.Table1[0].FPnAFileName;

                    const selectedOption = [
                        {
                            id: 0,
                            label: localabc
                        }
                        ,
                        {
                            id: 1,
                            label: localxyz
                        }
                    ];
                    // console.log("selection option latest=>",selectedOption)
                    setMyObj(selectedOption)
                }
                Toast.hide();
            } catch (error) {
                console.log(error);
                Toast.fail("Error while fetching idea. Please try again!", 3000, () => { });
            }
        };

        const fetchMetadata =async()=> {
            const param = {
                EntityType: "",
                EntityName: ""
            }
            const requestOptions = {
                method: 'POST',
                headers: { 
                'Content-Type': 'application/json',
                "Authorization":  "Bearer " + accessToken,
            },
                body: JSON.stringify(param)
            };
            try{
                const response = await fetch(GET_METADATA, requestOptions)
                if (response.ok){
                    const data = await response.json();
                }
           
            }
            catch(error){
                Toast.fail('Error occured while getting metadata idea. Please try again!', 3000, () => {
                    console.log(JSON.stringify(error));
                });
            }
        }
        fetchIdea();
        fetchMetadata();

    }, [props.ideaId]);

    useEffect(()=>{
        // let localAbc = data.ResultSets;
        // if(localAbc){
        //     let localfileName4 = localAbc.Table1[0].FPnAFileName;
        //     let localfileType4 = localAbc.Table1[0].FPnAFileType
        //     setFilename4(localfileName4)
        //     setFileType4(localfileType4)
        // }

        const handleBlocks=()=>{
            return new Promise((resolve,reject)=>{
                if(props.vdSelected){
                    impactOptions.map((item)=>{
                        if(props.vdSelected === item.label){
                            const selectedOption = {
                                value: item.value,
                                label: item.label
                            };
                            setImpactOptionSelected(selectedOption)
                            if(selectedOption.label === "No Value Delivery"){
                                setShowClientFields(false)
                                setShowGOLFields(false)
                                setClientName("")
                                setClientRole("")
                                setClientSignOffDate("")
                                setGolOhr("")
                                setGolFullname("")
                                setGolEmail("")
                                setFpnaOhr("")
                                setFpnaEmail("")
                                setFpnaName("")
                                setFilename2("")
                                setFileType2("")
                                setBase64File2("")
                                setFilename3("")
                                setBase64File3("")
                                setFileType3("")
                                setFilename4("")
                                setFileType4("")
                                setBase64File4("")
                                
                            }
                            if(selectedOption.label === "Value Delivered to Client"){
                                setShowClientFields(true)
                                setShowGOLFields(true)
                            }
                            if(selectedOption.label === "Value Delivered to Genpact"){
                                setShowClientFields(false)
                                setShowGOLFields(true)
                            }
                            if(selectedOption.label === "Value Delivered to Client + Genpact"){
                                setShowClientFields(true)
                                setShowGOLFields(true)
                            }
                            
                        }
                    })
                }
                resolve()
            })
        }



        handleBlocks();
        
        // setTimeout(() => {
        //     props.fetchGolName(golFullname)
        //     props.fetchFpnaName(fpnaName)
        //     props.fetchGolOhr(golOhr)
        //     props.fetchFpnaOhr(fpnaOhr)
        // }, 3000);
       
        // props.fetchGolName(golFullname)
        // props.fetchFpnaName(fpnaName)
        // props.fetchGolOhr(golOhr)
        // props.fetchFpnaOhr(fpnaOhr)
        
        props.fetchClientDocName(filename2)
        props.fetchClientDocType(fileType2)
        props.fetchClientDocContent(base64File2)
        
        props.fetchGolDocName(filename3)
        props.fetchGolDocType(fileType3)
        props.fetchGolDocContent(base64File3)
        
        props.fetchfpnaDocName(filename4)
        props.fetchfpnaDocType(fileType4)
        props.fetchfpnaDocContent(base64File4)
        

        if(golFullname){
            props.fetchGolName(golFullname)
        }
        
        props.fetchGolOhr(golOhr)
        props.fetchFpnaOhr(fpnaOhr)
        
        // if(golOhr){
        //     props.fetchGolOhr(golOhr)
        // }

        // if(fpnaOhr){
        //     props.fetchFpnaOhr(fpnaOhr)
        // }
        //akarsh added on 12-10-2023 bcz fpnaName was updating, prod changes
        // if(fpnaName){
        //     props.fetchFpnaName(fpnaName)
        // }
        props.fetchFpnaName(fpnaName)
        

        // if(filename2){
        //     props.fetchClientDocName(filename2)
        // }
        // if(fileType2){
        //     props.fetchClientDocType(fileType2)
        // }
        // if(base64File2){
        //     props.fetchClientDocContent(base64File2)
        // }

        // if(filename3){
        //     props.fetchGolDocName(filename3)
        // }
        // if(fileType3){
        //     props.fetchGolDocType(fileType3)
        // }
        // if(base64File3){
        //     props.fetchGolDocContent(base64File3)
        // }

        // if(filename4){
        //     props.fetchfpnaDocName(filename4)
        // }
        // if(fileType4){
        //     props.fetchfpnaDocType(fileType4)
        // }
        // if(base64File4){
        //     props.fetchfpnaDocContent(base64File4)
        // }
        props.fetchFileDetails(myObj)
        
        
        
    },[fpnaName,fpnaOhr,golFullname,golOhr,filename2
        ,fileType2,
        base64File2,
        filename3,
        fileType3,
        base64File3,
        filename4,
        fileType4,
        base64File4,props.vdSelected,
        base64File1,myObj
    ])

    const uploadFile=(event, fileType)=>{
        var file = event.target.files[0];
        var currFilename = file.name;
        var currFilesize = file.size;
       
        if(fileType === "file1"){
            if(currFilesize > 10000000){
                alert("The size of the document you are uploading, is more than 10MB. Please upload a smaller document.");
                return;  
            }
        }
        
        var validExtensions = ['.xls', '.xlsx', '.xlsm', '.csv', '.doc', '.docx', '.pdf', '.ppt', '.pptx', '.pdf', '.jpeg', '.jpg', '.png', '.msg', '.png', '.mpp'];
        var currFileExtension = currFilename.substring(currFilename.lastIndexOf("."));
        var isFileTypeValid = validExtensions.filter((val) => val.toLowerCase().includes(currFileExtension.toLowerCase()));
        if (isFileTypeValid.length <= 0) {
            alert("The type of document you are uploading is not supported. Please upload only supported file types.");
            return;
        }
        
        if (currFilename === filename2) {
            alert("You are trying to upload the same document. Please upload a different document.");
            return;
        }
        if (currFilename === filename3) {
            alert("You are trying to upload the same document. Please upload a different document.");
            return;
        }
        if (currFilename === filename4) {
            alert("You are trying to upload the same document. Please upload a different document.");
            return;
        } 
        var reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = function () {
            let fileContents = btoa(reader.result);
            if (fileType === "file1"){
                let fileName = file.name;
                let fileType = file.type;
                let fileContent = fileContents;
                setFilename1(fileName)
                setFileType1(fileType)
                setBase64File1(fileContent)
                props.fetchPPTDeck({fileName,fileType,fileContent})
            }
        };
        reader.onerror = function () {
            console.log('There are some problems loading document contents.');
        };
    }


    const uploadFileForClient=(event, fileType)=>{
        var file = event.target.files[0];
        var currFilename = file.name;
        var currFilesize = file.size;
       
        if(currFilesize > 5000000){
            alert("The size of the document you are uploading, is more than 5MB. Please upload a smaller document.");
            return;            
        }
        
        var validExtensions = ['.xls', '.xlsx', '.xlsm', '.csv', '.doc', '.docx', '.pdf', '.ppt', '.pptx', '.pdf', '.jpeg', '.jpg', '.png', '.msg', '.png', '.mpp'];
        var currFileExtension = currFilename.substring(currFilename.lastIndexOf("."));
        var isFileTypeValid = validExtensions.filter((val) => val.toLowerCase().includes(currFileExtension.toLowerCase()));
        if (isFileTypeValid.length <= 0) {
            alert("The type of document you are uploading is not supported. Please upload only supported file types.");
            return;
        }
        // validation for 2nd file
        if (currFilename === filename1) {
            alert("You are trying to upload the same document. Please upload a different document.");
            return;
        }
        if (currFilename === filename3) {
            alert("You are trying to upload the same document. Please upload a different document.");
            return;
        }
        if (currFilename === filename4) {
            alert("You are trying to upload the same document. Please upload a different document.");
            return;
        }
        var reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = function () {
            let fileContents = btoa(reader.result);
            if (fileType === "file2"){
                let localgolFileName = file.name;
                let localgolFileType = file.type;
                let localgolFileBase60 = fileContents;
                setFilename2(localgolFileName)
                setFileType2(localgolFileType)
                setBase64File2(localgolFileBase60)
                
            }
            
        };
        reader.onerror = function () {
            console.log('There are some problems loading document contents.');
        };
    }

    const uploadFileForGol=(event,fileType)=>{
        if(filename3)
        {
            if(window.confirm("Uploading GOL Approval document would replace the '" + filename3 + "' document. Do you wish to continue ?")){
                var file = event.target.files[0];
                var currFilename = file.name;
                var currFilesize = file.size;
                if(fileType === "file3"){
                    if(currFilesize > 2621440){
                        alert("The size of the document you are uploading, is more than 2.5MB. Please upload a smaller document.");
                        return;            
                    }
                    
                }
                var validExtensions = ['.xls', '.xlsx', '.xlsm', '.csv', '.doc', '.docx', '.pdf', '.ppt', '.pptx', '.pdf', '.jpeg', '.jpg', '.png', '.msg', '.png', '.mpp'];
                var currFileExtension = currFilename.substring(currFilename.lastIndexOf("."));
                var isFileTypeValid = validExtensions.filter((val) => val.toLowerCase().includes(currFileExtension.toLowerCase()));
                if (isFileTypeValid.length <= 0) {
                    alert("The type of document you are uploading is not supported. Please upload only supported file types.");
                    return;
                }

                // validation for 3rd file
                if (currFilename === filename1) {
                    alert("You are trying to upload the same document. Please upload a different document.");
                    return;
                }
                if (currFilename === filename2) {
                    alert("You are trying to upload the same document. Please upload a different document.");
                    return;
                }
                if (currFilename === filename4) {
                    alert("You are trying to upload the same document. Please upload a different document.");
                    return;
                }
                
                
                var reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = function () {
                    let fileContents = btoa(reader.result);
                    if (fileType === "file3"){
                        let localgolFileName = file.name;
                        let localgolFileType = file.type;
                        let localgolBase60 = fileContents;
                        setFilename3(localgolFileName)
                        setFileType3(localgolFileType)
                        setBase64File3(localgolBase60)
                        // setFpnaUpload(true)

                        
                    }
                };
                reader.onerror = function () {
                    console.log('There are some problems loading document contents.');
                };
            }
        }
        else{
            var file = event.target.files[0];
            var currFilename = file.name;
            var currFilesize = file.size;
            if(fileType === "file3"){
                if(currFilesize > 2621440){
                    alert("The size of the document you are uploading, is more than 2.5MB. Please upload a smaller document.");
                    return;            
                }
                
            }
            var validExtensions = ['.xls', '.xlsx', '.xlsm', '.csv', '.doc', '.docx', '.pdf', '.ppt', '.pptx', '.pdf', '.jpeg', '.jpg', '.png', '.msg', '.png', '.mpp'];
            var currFileExtension = currFilename.substring(currFilename.lastIndexOf("."));
            var isFileTypeValid = validExtensions.filter((val) => val.toLowerCase().includes(currFileExtension.toLowerCase()));
            if (isFileTypeValid.length <= 0) {
                alert("The type of document you are uploading is not supported. Please upload only supported file types.");
                return;
            }

            // validation for 3rd file
            if (currFilename === filename1) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === filename2) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === filename4) {
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            
            
            var reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = function () {
                let fileContents = btoa(reader.result);
                if (fileType === "file3"){
                    let localgolFileName = file.name;
                    let localgolFileType = file.type;
                    let localgolBase60 = fileContents;
                    setFilename3(localgolFileName)
                    setFileType3(localgolFileType)
                    setBase64File3(localgolBase60)
                    // setFpnaUpload(true)


                    const updatedItems = myObj.map((item, i) => {
                        if (i === 0) {
                            return { ...item, label: localgolFileName };
                        }
                        return item;
                    });
                    setMyObj(updatedItems);



                }
            };
            reader.onerror = function () {
                console.log('There are some problems loading document contents.');
            };
        }
    }
    const uploadFileForFpna=(event,fileType)=>{
        if(filename4){
            if(window.confirm("Uploading FP&A Approval document would replace the '" + filename4 + "' document. Do you wish to continue ?")){
                var file = event.target.files[0];
                var currFilename = file.name;
                var currFilesize = file.size;
                if(fileType === "file4" || fileType === "replaceFpnaDoc"){
                    if(currFilesize > 2621440){
                        alert("The size of the document you are uploading, is more than 2.5MB. Please upload a smaller document.");
                        return;            
                    }
                }
                var validExtensions = ['.xls', '.xlsx', '.xlsm', '.csv', '.doc', '.docx', '.pdf', '.ppt', '.pptx', '.pdf', '.jpeg', '.jpg', '.png', '.msg', '.png', '.mpp'];
                var currFileExtension = currFilename.substring(currFilename.lastIndexOf("."));
                var isFileTypeValid = validExtensions.filter((val) => val.toLowerCase().includes(currFileExtension.toLowerCase()));
                if (isFileTypeValid.length <= 0) {
                    alert("The type of document you are uploading is not supported. Please upload only supported file types.");
                    return;
                }
        
                // validation for 4th file
                if (currFilename === filename1) {
                    console.log(filename1)
                    alert("You are trying to upload the same document. Please upload a different document.");
                    return;
                }
                if (currFilename === filename2) {
                    console.log(filename2)
                    alert("You are trying to upload the same document. Please upload a different document.");
                    return;
                }
                console.log(filename3)
                if (currFilename === filename3) {
                    console.log(filename3)
                    alert("You are trying to upload the same document. Please upload a different document.");
                    return;
                }
        
                if(replaceFpnaDoc){
                   // validation for replaced fpna document
                    if (currFilename === filename1) {
                        console.log(filename1)
                        alert("You are trying to upload the same document. Please upload a different document.");
                        return;
                    }
                    if (currFilename === filename2) {
                        console.log(filename2)
                        alert("You are trying to upload the same document. Please upload a different document.");
                        return;
                    }
                    if (currFilename === filename3) {
                        console.log(filename3)
                        alert("You are trying to upload the same document. Please upload a different document.");
                        return;
                    } 
                    if (currFilename === filename4) {
                        alert("You are trying to upload the same document. Please upload a different document.");
                        return;
                    } 
                }
                var reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = function () {
                    let fileContents = btoa(reader.result);
                    if (fileType === "file4"){
                        let localfileName = file.name;
                        let localfileType = file.type;
                        let localBase60 = fileContents;
                        
                        setFilename4(localfileName)
                        setFileType4(localfileType)
                        setBase64File4(localBase60)
        
                        console.log("fpna filename",filename4)
                        console.log("fpna type",fileType4)
                        console.log("fpna base60",base64File4)


                        


        
                        setReplaceFpnaDoc(true)
                    }
        
                    if(fileType === "replaceFpnaDoc"){
                        let localfileName = file.name;
                        let localfileType = file.type;
                        let localBase60 = fileContents;
                        setFilename4(localfileName)
                        setFileType4(localfileType)
                        setBase64File4(localBase60)
                    }
                };
                reader.onerror = function () {
                    console.log('There are some problems loading document contents.');
                };
            }
        }
        else
        {
            var file = event.target.files[0];
            var currFilename = file.name;
            var currFilesize = file.size;
            if(fileType === "file4" || fileType === "replaceFpnaDoc"){
                if(currFilesize > 2621440){
                    alert("The size of the document you are uploading, is more than 2.5MB. Please upload a smaller document.");
                    return;            
                }
            }
            var validExtensions = ['.xls', '.xlsx', '.xlsm', '.csv', '.doc', '.docx', '.pdf', '.ppt', '.pptx', '.pdf', '.jpeg', '.jpg', '.png', '.msg', '.png', '.mpp'];
            var currFileExtension = currFilename.substring(currFilename.lastIndexOf("."));
            var isFileTypeValid = validExtensions.filter((val) => val.toLowerCase().includes(currFileExtension.toLowerCase()));
            if (isFileTypeValid.length <= 0) {
                alert("The type of document you are uploading is not supported. Please upload only supported file types.");
                return;
            }

            // validation for 4th file
            if (currFilename === filename1) {
                console.log(filename1)
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            if (currFilename === filename2) {
                console.log(filename2)
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }
            console.log(filename3)
            if (currFilename === filename3) {
                console.log(filename3)
                alert("You are trying to upload the same document. Please upload a different document.");
                return;
            }

            if(replaceFpnaDoc){
            // validation for replaced fpna document
                if (currFilename === filename1) {
                    console.log(filename1)
                    alert("You are trying to upload the same document. Please upload a different document.");
                    return;
                }
                if (currFilename === filename2) {
                    console.log(filename2)
                    alert("You are trying to upload the same document. Please upload a different document.");
                    return;
                }
                if (currFilename === filename3) {
                    console.log(filename3)
                    alert("You are trying to upload the same document. Please upload a different document.");
                    return;
                } 
                if (currFilename === filename4) {
                    alert("You are trying to upload the same document. Please upload a different document.");
                    return;
                } 
            }
            var reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = function () {
                let fileContents = btoa(reader.result);
                if (fileType === "file4"){
                    let localfileName = file.name;
                    let localfileType = file.type;
                    let localBase60 = fileContents;
                    
                    setFilename4(localfileName)
                    setFileType4(localfileType)
                    setBase64File4(localBase60)

                    console.log("fpna filename",filename4)
                    console.log("fpna type",fileType4)
                    console.log("fpna base60",base64File4)

                    const updatedItems = myObj.map((item, i) => {
                        if (i === 1) {
                            return { ...item, label: localfileName };
                        }
                        return item;
                    });
                    setMyObj(updatedItems);



                    setReplaceFpnaDoc(true)
                }

                if(fileType === "replaceFpnaDoc"){
                    if(window.confirm("FP&A Approval document will be replaced. Do you still wish to continue?")){
                        let localfileName = file.name;
                        let localfileType = file.type;
                        let localBase60 = fileContents;
                        setFilename4(localfileName)
                        setFileType4(localfileType)
                        setBase64File4(localBase60)
                        
                    }
                }
            };
            reader.onerror = function () {
                console.log('There are some problems loading document contents.');
            };
        }
    }

    // Gol client functions starts here

    const handleClientNameChange = (e) =>{
        props.fetchClientName(e)
        if(e){
            setClientName(e)
        }
    }
    const handleClientRoleChange = (e) =>{
        props.fetchClientRole(e)
        if(e){
            setClientRole(e)
        }
    }
    const handleClientSignOffDateChange = (e) =>{
        if(e !== null && e !== undefined){
            if(new Date(e) > new Date()){
                window.alert("Sign off date cannot be greater than current date")
                setClientSignOffDate("")
                return;
            }else{
                props.fetchClientSignOffDate(e)
                setClientSignOffDate(e)
            }
            
        }
    }
    // auto fetch gol full name starts here 

    const handleGOLOhronCut=(event)=>{
        if(event.target.value.length === 9) 
        {
            handleGOLOhrChange()
        }else if(event.target.value.length === 0){
            setGolOhr("")
            setGolFullname("")
            return null
        }
        else
        {
            Toast.fail("GOL OHR should be exactly of 9 digits",3000,()=>{})
            setGolFullname("")
        }
    }

    const handleGOLOhrChange=(event)=>{
        var numbers = /^[0-9]+$/;
        if(event !== null && event !== undefined){
            if(event.target.value.match(numbers) && event.target.value.length !== 0){
                console.log("inside gol length condition")
                if(event.target.value.length === 9){
                    let localGolOhr = event.target.value.trim();
                    setGolOhr(localGolOhr)
                    if(localGolOhr){
                        fetchSubmitGolOHRDetails(localGolOhr)
                    }
                }
                else{
                    setGolFullname("")
                    setGolOhr("")
                }
            }else{
                setGolOhr("")
                setGolFullname("")
            }
        }
    }
    const fetchSubmitGolOHRDetails = async (localGolOhr) => {
        Toast.loading("Fetching GOL Full Name Details. Please wait...", () => { });
        try {
            const param = {
                EntityType: "SearchOHR",
                EntityName: localGolOhr
            }
            const requestOptions = {
                method: 'POST',
                headers: { 
                    "Content-Type": "application/json",
                "Authorization":  "Bearer " + accessToken,

                },
                body: JSON.stringify(param)
            };
            const response = await fetch(GET_METADATA, requestOptions);
            const data = await response.json();
            console.log(data)
            let requestByName = data.oUser ? data.oUser[0].DisplayName : null
            if(requestByName === ""){
                requestByName = data.PowerAppUsers ? (data.PowerAppUsers.length > 0 ? data.PowerAppUsers[0].pg_username : "") : "";
            }
            if (requestByName === "" || requestByName===null || !requestByName)
            {
                Toast.fail("We could not find GOL full name details based on the entered OHR. Please check and reenter.", 5000, () => { });
                setGolFullname("")
            }
            else
            {
                setTimeout(() => {}, 2000);
                setGolFullname(requestByName)
                let golEmail = localGolOhr + "@genpact.com"
                setGolEmail(golEmail);

            }
            Toast.hide();
        } catch (error) {
            Toast.hide();
            Toast.fail('Please enter a valid GOL OHR', 5000, () => {
                console.log(JSON.stringify(error));
            });
            setGolFullname("")
            setGolOhr("")
        }
    };
    // auto fetch gol full name ends here 

    // auto fetch fpna full name starts here 
    
    const handleFpnaOhrChange=(event)=>{
        var numbers = /^[0-9]+$/;
        if(event !== null && event !== undefined){
            if(event.target.value.match(numbers) && event.target.value.length !== 0){
                if(event.target.value.length === 9){
                    let localFpnaOhr = event.target.value.trim()
                    setFpnaOhr(localFpnaOhr)
                    if(localFpnaOhr){
                        fetchSubmitFpnaOHRDetails(localFpnaOhr)
                    }
                }
                else{
                    setFpnaName("")
                    setFpnaOhr("")
                }
            }else{
                setFpnaOhr("")
                setFpnaName("")
            }
        }
    }
    const handleFpnaOhronCut=(event)=>{
        if(event.target.value.length === 9)
        {
            handleFpnaOhrChange()
        }else if(event.target.value.length === 0){
            setFpnaOhr("")
            setFpnaName("")
            return null
        }
        else
        {
            Toast.fail("FP&A OHR should be exactly of 9 digits",3000,()=>{})
            setFpnaName("")
        }
    }
    const fetchSubmitFpnaOHRDetails = async(localFpnaOhr) => {
        Toast.loading("Fetching FP&A Full Name Details. Please wait...", () => { });
        try
        {
            const param = {
                EntityType: "SearchOHR",
                EntityName: localFpnaOhr
            }
            const requestOptions = {
                method: 'POST',
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + accessToken,

                },
                body: JSON.stringify(param)
            };
            const response = await fetch(GET_METADATA, requestOptions);
            const data = await response.json();
            let requestByName = data.oUser ? data.oUser[0].DisplayName : null;
            console.log("request by name =>",requestByName)
            console.log("request by name typeof  =>",typeof requestByName)
            if(requestByName === ""){
                requestByName = data.PowerAppUsers ? (data.PowerAppUsers.length > 0 ? data.PowerAppUsers[0].pg_username : "") : "";
            }
            Toast.hide();
            if (requestByName === "" || requestByName===null || !requestByName) {
                Toast.fail("We could not find FP&A full name details based on the entered OHR. Please check and reenter.", 5000, () => { });
                setFpnaName("")
                
            }else{
                setTimeout(() => {}, 2000);
                setFpnaName(requestByName)
                let abc = localFpnaOhr + "@genpact.com";
                setFpnaEmail(abc)
            }

        }
        catch(error){
            Toast.hide();
            Toast.fail('Please enter a valid FP&A OHR', 5000, () => { });
            setFpnaName("")
        };
    }
    // auto fetch fpna full name ends here 



    // function to download PPT content

    const showPPTContent = async () => {
        Toast.loading("Fetching Project PPT Deck content...", () => { });
        try {
            const requestOptions = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + accessToken,
                }
            };
            let projectFileType = fileType1;
            const response = await fetch(`${GET_IDEA_URL}IdeaId=${props.IdeaId}&${PPT_CONTENT}=true`, requestOptions)
            const data = await response.json();
            console.log("fetch ppt data",data)
            const projectDeckMetadata = data.PPTContent ? data.PPTContent : "";
            const projectContent = "data:" + projectFileType + ";base64," + projectDeckMetadata;

            var w = window.open("");
            const downloadLink = w.document.createElement("a");
            downloadLink.href = projectContent;
            downloadLink.download = filename1;
            downloadLink.click();
            Toast.hide();

        } catch (error) {
            console.log(error);
            Toast.fail("Error while fetching Project Deck content. Please try again!", 3000, () => { });
        }
    };

    // function to download client sign-off file
    const showSignOffContent = async() => {
        Toast.loading("Fetching Customer Sign-off content...", () => { });
        return new Promise((resolve,reject)=>{
            const requestOptions = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization":  "Bearer " + accessToken,
                }
            };
            let signoffFileType = fileType2;
            fetch(`${GET_IDEA_URL}IdeaId=${props.IdeaId}&${SIGNOFF_CONTENT}=true`, requestOptions)
            .then(response=>response.json())
            .then(data=>{
                console.log("fetch sign-off content",data)
                const signoffMetadata = data.SignoffContent ? data.SignoffContent : "";
                const signoffContent = "data:" + signoffFileType + ";base64," + signoffMetadata;

                console.log("sign-off content",signoffContent)
                console.log("filename 2", filename2)

                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = signoffContent;
                downloadLink.download = filename2;
                downloadLink.click();
                Toast.hide();
                resolve();

            })
            .catch=(error)=>{
                console.log(error)
                Toast.fail("Error while fetching Customer Sign-off content. Please try again!", 3000, () => { });
            }
        })
    }
    
    // function to download gol file data
    const showGolOffContent = async() => {
        Toast.loading("Fetching GOL Approval content...", () => { });
        return new Promise((resolve,reject)=>{
            const requestOptions = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                "Authorization":  "Bearer " + accessToken,
                }
            };
            let golFileType = fileType3;
            fetch(`${GET_IDEA_URL}IdeaId=${props.IdeaId}&${PPT_CONTENT}=true&golcontent=true`, requestOptions)
            .then(response=>response.json())
            .then(data=>{
                console.log("fetch gol content",data)
                const golMetadata = data.golContent ? data.golContent : "";
                const golContent = "data:" + golFileType + ";base64," + golMetadata;
                console.log("gol content",golContent)
                console.log("filename 3", filename3)
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = golContent;
                downloadLink.download = filename3;
                downloadLink.click();
                Toast.hide();
                resolve();
            })
        })
        .catch=(error)=>{
            console.log(error)
            Toast.fail("Error while fetching GOL Approval content. Please try again!", 3000, () => { });
        }
    }

    // function to download fpna file data
    const showFpnaContent = async() => {
        Toast.loading("Fetching FP&A Approval content...", () => { });
        return new Promise((resolve,reject)=>{
            const requestOptions = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                "Authorization":  "Bearer " + accessToken,
                }
            };
            let fpnaFileType = fileType4;
            fetch(`${GET_IDEA_URL}IdeaId=${props.IdeaId}&${PPT_CONTENT}=true&fpnacontent=true`, requestOptions)
            .then(response=>response.json())
            .then(data=>{
                console.log("fetch fpna content",data)
                const golMetadata = data.fpnaContent ? data.fpnaContent : "";
                const golContent = "data:" + fpnaFileType + ";base64," + golMetadata;
                console.log(golContent)
                console.log(filename4)
                var w = window.open("");
                const downloadLink = w.document.createElement("a");
                downloadLink.href = golContent;
                downloadLink.download = filename4;
                downloadLink.click();
                Toast.hide()
                resolve();
            })
        })
        .catch=(error)=>{
            console.log(error)
            Toast.fail("Error while fetching FP&A Approval content. Please try again!", 3000, () => { });
        }
    }
    
    const deleteUploadedDoc = (id) =>{
        if(id===1){
            setFileType1("");
            setFilename1("");
            setBase64File1("")
            props.fetchPPTDeck({filename1,fileType1,base64File1})
        }
        if(id===2){
            setFileType2("");
            setFilename2("");
            setBase64File2("")
        }
        if(id===3){
            setFileType3("");
            setFilename3("");
            setBase64File3("")
            const updatedItems = myObj.map((item, i) => {
                if (i === 0) {
                    return { ...item, label: "" };
                }
                return item;
            });
            console.log(updatedItems)
            setMyObj(updatedItems);

        }
        if(id===4){
            setFileType4("");
            setFilename4("");
            setBase64File4("")

            const updatedItems = myObj.map((item, i) => {
                if (i === 1) {
                    return { ...item, label: "" };
                }
                return item;
            });
            console.log(updatedItems)
            setMyObj(updatedItems);
        }
    }


    const updateValueAtIndex = (index,) => {
        // Create a new array with the updated value
        
      };


    const formatDateForDisplay = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth()).toString() // Months are zero-based
        const day = currentDate.getDate().toString();
        return `${year}-${month}-${day}`;
    }
    
    
   

    return (
        <div className="">
            <div id="Upload" className="u-border-1 u-border-palette-5-base u-container-style u-expanded-width u-group u-radius-6 u-shape-round u-group-7">
                <div className="u-container-layout u-container-layout-7" style={{borderBottom:"1px solid lightgray",marginBottom:"20px"}}>
                    <div className="u-container-style u-group u-shape-rectangle u-group-8">
                        <div className="u-container-layout u-container-layout-8">
                            {
                                !props.viewIdea
                                ?
                                <>
                                    <p id="Upload" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Upload Documents</p>
                                </>
                                :
                                    <p id="Upload" className="u-align-left u-text u-text-2" style={{ marginBottom: 10 }}>Uploaded Documents</p>
                            }

                            {
                                !props.viewIdea
                                ?
                                <>
                                    <label style={{ fontWeight: 'normal', fontSize: 10, fontStyle: 'italic', color: 'red' }}>[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                </>
                                :
                                null
                            }
                            <label style={{ verticalAlign: 'bottom', marginBottom: -5 }}>Project Presentation Deck</label>  {!props.viewIdea ? <><span style={{ color: 'red' }}>*</span> <small style={{color:"#939393", fontSize:"12px"}}>(Max Size: 10MB)</small></>  : ""}
                            <div className="row">
                                <div className="col-md-10" style={{marginTop:'10px'}}>
                                    {
                                        filename1
                                        ?
                                        <>
                                            <button className={filename1 && filename1.length > 50 ? "upload_file_text_overflow" :""} style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0 }} onClick={(e)=>showPPTContent()}>{filename1}</button>
                                        </>
                                        :
                                            <label style={{ fontWeight: 'normal', fontStyle: 'italic' }}>&nbsp;&nbsp;{filename1}</label>
                                    }
                                    
                                </div>
                                <div className="col-md-2">
                                    {
                                        !props.viewIdea
                                        ?
                                        <>
                                            {
                                                filename1
                                                ?
                                                <>
                                                    <button onClick={(e)=>deleteUploadedDoc(1)} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{marginTop: "6px"}}>&nbsp;&nbsp;<i className="fa fa-trash" aria-hidden="true"></i> &nbsp;Delete&nbsp;&nbsp;</button>
                                                </>
                                                :
                                                <>
                                                    <label htmlFor="file-upload1" className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                    <input id="file-upload1" 
                                                        type="file" style={{ display: 'none' }} 
                                                        onChange={(event) => uploadFile(event, "file1")} 
                                                        accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                    />
                                                </> 
                                            }
                                               
                                        </>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ..............gol starts here............... */}
                {
                    showClientFields
                    //props.vdSelected && (props.vdSelected === "Value Delivered to Client + Genpact" || props.vdSelected === "Value Delivered to Client")
                    ?
                    <>
                        <div className="gol_fpna_approval_field_block" style={{paddingTop:"20px",marginTop:"0px"}}>
                            <p className="u-align-left u-text u-text-2">Client Approval Details</p>
                            <div className="monetization_client_field_block">
                                <div className="row u-align-left">
                                    <div className="col-md-5">
                                        <div className="monetization_client_field">
                                            <div className="row gol_field_div">
                                                <div className="col-md-6 ">
                                                    <div className="">
                                                        <span className="form-attribute">
                                                            {!props.viewIdea ? <><span style={{ color: 'red' }}>* </span> </> : null }
                                                            Client Name
                                                        </span> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        props.viewIdea 
                                                        ?
                                                        <>
                                                            <input disabled value={clientName} className="form-input disabled_input" />
                                                        </>
                                                        :
                                                        <input 
                                                            onChange={(e)=>handleClientNameChange(e.target.value)}  
                                                            className="textarea-css form-attribute hide_numeric_arrows" 
                                                            defaultValue={clientName}
                                                        /> 
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="monetization_client_field">
                                            <div className="row gol_field_div">
                                                <div className="col-md-6 ">
                                                    <div className="">
                                                        <span className="form-attribute">
                                                            {!props.viewIdea ? <><span style={{ color: 'red' }}>* </span> </> : null }
                                                            Client Role
                                                        </span> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        props.viewIdea
                                                        ?
                                                        <>
                                                            <input value={clientRole} className="form-input disabled_input" disabled />
                                                        </>
                                                        :
                                                        <input defaultValue={clientRole} onChange={(e)=>handleClientRoleChange(e.target.value)} 
                                                            className="textarea-css form-attribute" type="text" 
                                                        />

                                                    }
                                                    
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5">
                                        <div className="monetization_client_field">
                                            <div className="row gol_field_div">
                                                <div className="col-md-5">
                                                    <div className="">
                                                        <span className="form-attribute">
                                                            {!props.viewIdea ? <><span style={{ color: 'red' }}>* </span> </> : null }
                                                            Client Sign-off Date
                                                        </span> 
                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    {
                                                        props.viewIdea 
                                                        ?
                                                        <input type="date" value={clientSignOffDate} className="form-input disabled_input"  disabled style={{color:"#122539"}} />   
                                                        :
                                                        <input onChange={(e)=>handleClientSignOffDateChange(e.target.value)} className="textarea-css form-attribute" type="date" value={clientSignOffDate} />    
                                                    }

                                                    {/* <input onChange={(e)=>handleClientSignOffDateChange(e.target.value)} className="textarea-css form-attribute" type="date" value={clientSignOffDate} />     */}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="row u-align-left">
                                        <div className="col-md-6">
                                            {/* <div className="">
                                                <label>
                                                    {!props.viewIdea ? <><span style={{ color: 'red' }}>* </span> </> : null }
                                                    Client Approval
                                                    {!props.viewIdea ? <><small style={{color:"#939393", fontSize:"12px"}}>&nbsp;(Max Size: 5MB)</small> </> : null }
                                                </label> 
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <div className="row">
                                                            <div className="col-md-10" style={{marginTop:'10px'}}>
                                                                <button style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0 }} onClick={(e)=>showSignOffContent()}>{filename2}</button>
                                                            </div>
                                                            <div className="col-md-2">
                                                                {
                                                                    !props.viewIdea
                                                                    ?
                                                                    <>
                                                                        {
                                                                            filename2
                                                                            ?
                                                                            <>
                                                                                <button onClick={(e)=>deleteUploadedDoc(2)} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{marginTop: "6px"}}>&nbsp;&nbsp;<i className="fa fa-trash" aria-hidden="true"></i> &nbsp;Delete&nbsp;&nbsp;</button>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <label htmlFor="file-upload2" 
                                                                                    className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                                                <input id="file-upload2" 
                                                                                    type="file" style={{ display: 'none' }} 
                                                                                    onChange={(event) => uploadFileForClient(event, "file2")} 
                                                                                    accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                                                />
                                                                            </>
                                                                        }
                                                                    
                                                                        
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div> */}


                                            <div className="">
                                                
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <div className="row">
                                                            <div className="col-md-10" style={{marginTop:'10px'}}>
                                                                <label>
                                                                    {!props.viewIdea ? <><span style={{ color: 'red' }}>* </span> </> : null }
                                                                        Client Approval
                                                                    {!props.viewIdea ? <><small style={{color:"#939393", fontSize:"12px"}}>&nbsp;(Max Size: 5MB)</small> </> : null }
                                                                </label> 
                                                                <label style={{ fontWeight: 'normal', fontSize: 10, fontStyle: 'italic', color: 'red' }}>[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                                                <button className={filename2 && filename2.length > 50 ? "upload_file_text_overflow":"" } style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0 }} onClick={(e)=>showSignOffContent()}>{filename2}</button>
                                                            </div>
                                                            <div className="col-md-2">
                                                                {
                                                                    !props.viewIdea
                                                                    ?
                                                                    <>
                                                                        {
                                                                            filename2
                                                                            ?
                                                                            <>
                                                                                <button onClick={(e)=>deleteUploadedDoc(2)} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{marginTop: "6px"}}>&nbsp;&nbsp;<i className="fa fa-trash" aria-hidden="true"></i> &nbsp;Delete&nbsp;&nbsp;</button>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <label htmlFor="file-upload2" 
                                                                                    className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                                                <input id="file-upload2" 
                                                                                    type="file" style={{ display: 'none' }} 
                                                                                    onChange={(event) => uploadFileForClient(event, "file2")} 
                                                                                    accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                                                />
                                                                            </>
                                                                        }
                                                                    
                                                                        
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    null
                }
            {/* ..............gol ends here................. */}



            {/* ..............fpna starts here............... */}
                {
                    showGOLFields
                    //props.vdSelected && (props.vdSelected === "Value Delivered to Client + Genpact" || props.vdSelected === "Value Delivered to Genpact" )
                    ?
                    <>
                        <div className="gol_fpna_approval_field_block">
                            <p className="u-align-left u-text u-text-2">GOL / FP&A Approval Details</p>
                            <div className="monetization_client_field_block">
                                <div className="row u-align-left">
                                    <div className="col-md-5">
                                        <div className="monetization_client_field">
                                            <div className="row gol_field_div">
                                                <div className="col-md-6 ">
                                                    <div className="">
                                                        <span className="form-attribute">
                                                            {!props.viewIdea ? <><span style={{ color: 'red' }}>* </span> </> : null }
                                                            GOL OHR
                                                        </span> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        props.viewIdea 
                                                        ?
                                                        <>
                                                            <input disabled value={golOhr} className="form-input disabled_input" />
                                                        </>
                                                        :
                                                        <input pattern="\d*" maxLength="9" 
                                                            defaultValue={golOhr} 
                                                            onChange={(e)=>handleGOLOhrChange(e)} 
                                                            onCut={(e)=>handleGOLOhronCut(e)} 
                                                            className="textarea-css form-attribute hide_numeric_arrows" type="number"
                                                            onKeyDown={ (evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() }
                                                            onWheel={(e) => e.target.blur()}
                                                        />
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="monetization_client_field">
                                            <div className="row gol_field_div">
                                                <div className="col-md-6 ">
                                                    <div className="">
                                                        <span className="form-attribute">
                                                            {!props.viewIdea && props.sendIsFpnaRequired ? <><span style={{ color: 'red' }}>* </span> </> : null }
                                                            FP&A OHR
                                                        </span> 
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        props.viewIdea
                                                        ?
                                                        <>
                                                            <input value={fpnaOhr} className="form-input disabled_input" disabled />
                                                        </>
                                                        :
                                                        <input pattern="\d*" maxLength="9" 
                                                            defaultValue={fpnaOhr} 
                                                            onChange={(e)=>handleFpnaOhrChange(e)} 
                                                            onCut={(e)=>handleFpnaOhronCut(e)} 
                                                            className="textarea-css form-attribute hide_numeric_arrows" type="number"
                                                            onKeyDown={ (evt) => (evt.key === '.' || evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() }
                                                            onWheel={(e) => e.target.blur()}
                                                        />

                                                    }
                                                    
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-5">
                                        <div className="monetization_client_field">
                                            <div className="row gol_field_div">
                                                <div className="col-md-5">
                                                    <div className="">
                                                        <span className="form-attribute">
                                                            {!props.viewIdea ? <><span style={{ color: 'red' }}>* </span> </> : null }
                                                            GOL Full Name
                                                        </span> 
                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    <input disabled value={golFullname} className="form-input disabled_input" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="monetization_client_field">
                                            <div className="row gol_field_div">
                                                <div className="col-md-5">
                                                    <div className="">
                                                        <span className="form-attribute">
                                                            {!props.viewIdea && props.sendIsFpnaRequired ? <><span style={{ color: 'red' }}>* </span> </> : null }
                                                            FP&A Name
                                                        </span> 
                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    <input disabled value={fpnaName} className="form-input disabled_input" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="row u-align-left">
                                        <div className="col-md-5">
                                            <div className="">
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <label>
                                                            {!props.viewIdea ? <><span style={{ color: 'red' }}>* </span> </> : null }
                                                            GOL / FP&A Approval
                                                            {!props.viewIdea ? <><small style={{color:"#939393", fontSize:"12px"}}>&nbsp;(Max Size: 5MB)</small> </> : null }
                                                        </label>
                                                        <label style={{ fontWeight: 'normal', fontSize: 10, fontStyle: 'italic', color: 'red' }}>[Note] Supported File Types : .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .ppt, .pptx, .pdf, .jpeg, .jpg, .png, .msg, .png, .mpp.</label>
                                                    </div>
                                                    <div className="col-md-2">
                                                        {
                                                            !props.viewIdea
                                                            ?
                                                            <>
                                                                {
                                                                    // !fpnaUpload
                                                                    (true)
                                                                    ?
                                                                    <>
                                                                        <label htmlFor="file-upload3" 
                                                                            className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" >&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                                        <input id="file-upload3" 
                                                                            type="file" style={{ display: 'none' }} 
                                                                            // onChange={(event)=>uploadFileForGol(event,"file3")} 
                                                                            // onChange={(event)=> filename3 && (!filename4 || filename4 === "" || filename4 === undefined) ? uploadFileForFpna(event, "file4") :  uploadFileForGol(event,"file3")} 
                                                                            onChange={(event)=> myObj ? myObj[0].label !== "" && myObj[1].label !== "" ? window.alert("please delete an existing doc and then upload ") :  myObj[0].label === "" ? uploadFileForGol(event,"file3") : uploadFileForFpna(event, "file4") : null  } 
                                                                            accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                                        />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <label htmlFor="file-upload3" 
                                                                            className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2">&nbsp;&nbsp;+&nbsp;&nbsp;</label>
                                                                        <input id="file-upload3" 
                                                                            type="file" style={{ display: 'none' }} 
                                                                            // onChange={(event)=>   uploadFileForFpna(event,"file4")} 
                                                                            onChange={(event) =>  replaceFpnaDoc ? uploadFileForFpna(event, "replaceFpnaDoc") : uploadFileForFpna(event, "file4")} 
                                                                            accept=".xls,.xlsx,.xlsm,.csv,.doc,.docx,.pdf,.pptx,.jpeg,.jpg,.png,.msg,.mpp,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msproject,application/vnd.ms-outlook" 
                                                                        />
                                                                    </>
                                                                    
                                                                }
                                                            </>
                                                            :
                                                            null
                                                        }
                                                        
                                                        
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row d_flex_align_items_center">
                                                            <div className="col-md-10">
                                                                {/* <button style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0 }} onClick={(e)=>showGolOffContent()}>{filename3}</button> */}

                                                                <button className={myObj ? (myObj[0].label ? (myObj[0].label.length > 50 ? "upload_file_text_overflow" :""):""):""} style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0 }} onClick={(e)=>showGolOffContent()}>{myObj ? myObj[0].label : ""}</button>
                                                            </div>
                                                            <div className="col-md-2">
                                                                {
                                                                    !props.viewIdea
                                                                    ?
                                                                    <>
                                                                        {
                                                                            filename3
                                                                            ?
                                                                            <>
                                                                                <button onClick={(e)=>deleteUploadedDoc(3)} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2">&nbsp;&nbsp;<i className="fa fa-trash" aria-hidden="true"></i> &nbsp;Delete&nbsp;&nbsp;</button>
                                                                            </>
                                                                            :
                                                                            null
                                                                        }
                                                                        
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                            </div>

                                                            
                                                        </div>
                                                        <div className="">
                                                            {
                                                                <div className="row d_flex_align_items_center">
                                                                    <div className="col-md-10">
                                                                        <button className={myObj ? (myObj[1].label ? (myObj[1].label.length > 50 ? "upload_file_text_overflow" :""):""):""} style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0 }} onClick={(e)=>showFpnaContent()}>{myObj ? myObj[1].label : ""}</button>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        {
                                                                            filename4 && !props.viewIdea
                                                                            ?
                                                                                <button onClick={(e)=>deleteUploadedDoc(4)} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{marginTop: "6px"}}>&nbsp;&nbsp;<i className="fa fa-trash" aria-hidden="true"></i> &nbsp;Delete&nbsp;&nbsp;</button>
                                                                            :
                                                                            null
                                                                        }
                                                                        
                                                                    </div>
                                                                </div>
                                                            }
                                                            
                                                            {/* {
                                                                !fpnaUpload
                                                                ?
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md-10">
                                                                            <button style={{ backgroundColor: 'transparent', color: '#337ab7', padding: 0 }} onClick={(e)=>showFpnaContent()}>{filename4}</button>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            {
                                                                                filename4 && !props.viewIdea
                                                                                ?
                                                                                <button onClick={(e)=>deleteUploadedDoc(4)} className="u-active-custom-color-6 u-border-2 u-border-active-black u-border-hover-black u-border-white u-btn u-btn-round u-button-style u-custom-color-3 u-hover-custom-color-6 u-radius-6 u-btn-2" style={{marginTop: "6px"}}>&nbsp;&nbsp;<i className="fa fa-trash" aria-hidden="true"></i> &nbsp;Delete&nbsp;&nbsp;</button>
                                                                                :
                                                                                null
                                                                            }
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    
                                                                </>
                                                                :
                                                                null
                                                            } */}
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    null
                }
            {/* ..............Fpna ends here................. */}
    </div>
            
       
  )
}

export default FPNAGOL